import React from 'react';

const ConnectionSuccessMsg = ({onClick}) => {
    return (
        <div >
            <h2 className="connectionSuccess">Connection Successful</h2>
            <div className="connectivitySrlBar mCustomScrollbar">
                <div className="connectivitySubList">
                    <ul>
                        <li>Connection successful, your Device is connected to KitchenConnect.</li>
                        <li>If you are the owner of the Device follow the KitchenConnect Unit Registration process to get your User Account and start monitoring your Device remotely.</li>
                    </ul>
                </div>
            </div>
            <button className="btn verifyOtherDeviceBtn" type="button" onClick={onClick}>Verify Other Device</button>
        </div>
    )
}

export default ConnectionSuccessMsg;