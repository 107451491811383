import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import * as moment from 'moment';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import $ from 'jquery';
import { capitalCase } from "change-case";
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class FrymasterRecipeReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            ordered_steps: [],
            conditional_steps: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            disableAddStep: false,
            isChanged: false,
            showDiscardPopup: false,
            time_mask: [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            recipeName: '',
            temperature: 0,
            totalCookTime: 0,
            sensitivity: 0,
            filterCountValue: 0,
            filterCountType: 'Global',
            drainTimer: 'false',
            drainTimerValue: 0,
            holdTimer: 'false',
            holdTimerValue: 0,
            instantOnValue: 0,
            weightedcookcount: 0,
            exit_mode: '',
            schedule: 'false',
            startDate: '',
            endDate: '',
            menu: '',
            recipeSegmentType: 'Single',
            imageDatab64: null,
            popuptitle: '',
            popupTemperature: '',
            popupDuration: '',
            popupSensitivity: 0
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.onSaveRecipe = this.onSaveRecipe.bind(this);
        this.onShowPopup = this.onShowPopup.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".reBodyWrapper").mCustomScrollbar();
        // $(".reviewOptionsTwoS").mCustomScrollbar({axis:"x", scrollbarPosition:"inside", advanced:{autoExpandHorizontalScroll:true}});
        try {
            window.addEventListener('resize', this.updateDimensions);
            let jsonRecipeFile = KCStore.getItem("libraryData");
            let ObjRecipe = JSON.parse(jsonRecipeFile);
            let resourceDetails = KCStore.getItem("resourceDetails");
            let resourceDetailsObj = JSON.parse(resourceDetails);
            let libraryImageDetail = resourceDetailsObj.find(resource => resource.type="image");

            this.setState({
                recipeName: ObjRecipe.name.en_US,
                temperature: ObjRecipe.recipeSegmentType === "Single" ? ObjRecipe.ordered_steps[0] ? ObjRecipe.ordered_steps[0].settings.set_point.value + ' F' : ' F' : ' F',
                totalCookTime: ObjRecipe.settings.quality_timer ? ObjRecipe.settings.quality_timer : '',
                sensitivity: ObjRecipe.recipeSegmentType === "Single" ? ObjRecipe.ordered_steps[0] ? ObjRecipe.ordered_steps[0].settings.sensitivity : 0 : 0,
                weightedcookcount: ObjRecipe.settings.weighted_cook_count,
                drainTimer: ObjRecipe.settings.drain_timer ? "true" : "false",
                drainTimerValue: ObjRecipe.settings.drain_timer,
                holdTimer: ObjRecipe.settings.hold_timer ? "true" : "false",
                holdTimerValue: ObjRecipe.settings.hold_timer,
                instantOnValue: ObjRecipe.settings.instant_on,
                basket_lift: ObjRecipe.settings.basketLiftSize,
                push_start: ObjRecipe.settings.push_start,
                exit_mode: ObjRecipe.settings.exit_mode,
                schedule: ObjRecipe.settings.schedule.mode,
                startDate: ObjRecipe.settings.schedule.start_date,
                endDate: ObjRecipe.settings.schedule.end_date,
                imageDatab64: libraryImageDetail ? libraryImageDetail.thumbnailData : null,
                ordered_steps: ObjRecipe.ordered_steps,
                conditional_steps: ObjRecipe.conditional_steps,
                recipeSegmentType: ObjRecipe.recipeSegmentType
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions() {
        $("#scrollDownButton").on("click", function () {
            $(".recipeCreationWrapper").animate({ scrollTop: $('.recipeCreationWrapper').prop("scrollHeight") }, 1000);
        });
    }
    
    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        if (this.state.isEdit) {
            this.props.history.push(`/libraryList`);
        }
        else {
            this.props.history.push(`/recipeEditor`);
        }
    }

    onSaveRecipe = () => {
        this.props.history.push(`/assignFrymasterRecipeToTopology`);
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
    }

    onShowPopup = (item, title) => {
        this.setState({ 
            popuptitle: title,
            popupTemperature: item.settings.set_point.value,
            popupDuration: item.duration,
            popupSensitivity: item.settings.sensitivity
        });
        $('#popupTrigger').trigger('click');
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    onBackButtonClick = () => {
        this.props.history.push(`/frymasterRecipeCreation`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: basedataList, ordered_steps, conditional_steps, success_modal, error_modal, loaderIconVisible, showDiscardPopup, basket_lift, push_start, menu, startDate, endDate, recipeSegmentType } = this.state;
        let { recipeName, imageDatab64, totalCookTime, sensitivity, weightedcookcount, exit_mode, temperature, drainTimer, drainTimerValue, holdTimer, holdTimerValue, instantOnValue } = this.state;
        let { popuptitle, popupTemperature, popupDuration, popupSensitivity} = this.state;
        basedataList = basedataList && basedataList.length > 0 ? basedataList : [];
        basedataList = basedataList.filter(b => b.PARAMETER_NAME !== "Instruction");
        let brandid = KCStore.getItem("selectedbrandID");
        let stepsLength = ordered_steps.length + conditional_steps.length;

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt="" /></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1381"/>: {recipeName}
				                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
                                            <div class="frymasterRCReview">
                                                <ul> 
                                                    <li>
                                                        <div class="frymasterRCR">
                                                            <ul>
                                                                <li>
                                                                    <label><FormattedMessage id="KC1382"/></label>
                                                                    <span class="labelVal">{totalCookTime}</span>
                                                                </li>
                                                                {recipeSegmentType === "Single" && <li>
                                                                <label><FormattedMessage id="KC1351"/></label>
                                                                <span class="labelVal">{sensitivity}</span>
                                                                </li>}
                                                                <li>
                                                                    <label><FormattedMessage id="KC1356"/></label>
                                                                    <span class="labelVal">{instantOnValue ? instantOnValue : "00:00"}</span>
                                                                </li>
                                                                <li>
                                                                    <label><FormattedMessage id="KC1357"/></label>
                                                                    <span class="labelVal">{basket_lift ? capitalCase(basket_lift) : "None"}</span>
                                                                </li>
                                                                {recipeSegmentType !== "Single" && <li>&nbsp;</li>}
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <label><FormattedMessage id="KC1383"/> <br/><FormattedMessage id="KC0172"/></label>
                                                                    <span class="labelVal">{weightedcookcount}</span>
                                                                </li>
                                                                <li>
                                                                    <label><FormattedMessage id="KC1384"/> <br/><FormattedMessage id="KC1493"/></label>
                                                                    <span class="labelVal">{exit_mode}</span>
                                                                </li>
                                                                <li>&nbsp;</li>
                                                                <li>
                                                                    <label><FormattedMessage id="KC1385"/> <br/><FormattedMessage id="KC0577"/></label>
                                                                    <span class="labelVal">{push_start === "true" ? 2 : 1}</span>
                                                                </li>
                                                            </ul>
                                                            </div>
										                    <div class="frymasterSRCR"> 
                                                            <ul class="tHead">
                                                                <li>
                                                                    <label><FormattedMessage id="KC1361"/></label> 
                                                                </li> 
                                                                <li>
                                                                    {menu && <label><FormattedMessage id="KC0676"/></label> }
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <div class="frymasterSRCR">
                                                                        <ul class="borderNone">
                                                                            <li>
                                                                                <label><FormattedMessage id="KC0577"/></label>
                                                                                <span class="labelVal">{startDate ? moment(startDate, "MM/DD/YYYY").format('MMMM DD, YYYY') : ""}</span>
                                                                            </li>
                                                                            <li>
                                                                                <label><FormattedMessage id="KC0578"/></label>
                                                                                <span class="labelVal">{endDate ? moment(endDate, "MM/DD/YYYY").format('MMMM DD, YYYY') : ""}</span>
                                                                            </li>  
                                                                        </ul>
                                                                    </div>
                                                                </li> 
                                                                <li> 
                                                                {menu && <span class="labelVal"><FormattedMessage id="KC1386"/></span>}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span class="productsImg"><img src={imageDatab64 ? `data:${'image/png'};base64,${imageDatab64}` : require("../../../../../images/recipecreation/noImageSelected.png")}  alt="" /></span>
                                                        <span class="productsTitle">{recipeName}</span>
                                                    </li>
                                                </ul> 
                                            </div>
                                            {recipeSegmentType === "Single" ? 
                                                <div class="frymasterRCF">
                                                    <ul>
                                                        <li>
                                                            <label><FormattedMessage id="KC1494"/></label>
                                                            <div class="labelImgDiv">
                                                                <span class="labelImg"><img src={require("../../../../../images/recipecreation/startCookBtn.png")}  alt="" /></span>
                                                            </div>
                                                            <span class="labelVal">{totalCookTime}<br /> {temperature} </span>
                                                        </li>
                                                        {
                                                            conditional_steps.map((item, i) => {
                                                                return (
                                                                <li>
                                                                    <label>{item.actions.prompt.message.en_US}</label>
                                                                    <div class="labelImgDiv">
                                                                        <span class="labelImg"><img src={require("../../../../../images/recipecreation/timerClockIcon.png")}  alt="" /></span>
                                                                    </div>
                                                                    <span class="labelVal">{item.actions.prompt.duration}</span>
                                                                </li>
                                                                )
                                                            })
                                                        }
                                                        <li class="endCook">
                                                            <label><FormattedMessage id="KC1495"/></label>
                                                            <div class="labelImgDiv">
                                                                <span class="labelImg"><img src={require("../../../../../images/recipecreation/endCookBtn.png")}  alt="" /></span>
                                                            </div>
                                                            <span class="labelVal">00:00</span>
                                                        </li>
                                                       {drainTimer === "true" && <li class="drain">
                                                            <label><FormattedMessage id="KC1387"/></label>
                                                            <div class="labelImgDiv">
                                                                <span class="labelImg"><img src={require("../../../../../images/recipecreation/timerClockIcon.png")} alt=""  /></span>
                                                            </div>
                                                            <span class="labelVal">{drainTimerValue ? drainTimerValue : "00.00"}</span>
                                                        </li>}
                                                        {holdTimer === "true" && <li class="hold">
                                                            <label><FormattedMessage id="KC1388"/></label>
                                                            <div class="labelImgDiv">
                                                                <span class="labelImg"><img src={require("../../../../../images/recipecreation/timerClockIcon.png")}  alt="" /></span>
                                                            </div>
                                                            <span class="labelVal">{holdTimerValue ? holdTimerValue : "00.00"}</span>
                                                        </li>}
                                                    </ul>
                                                </div> 
                                                :
                                                <div class="reviewOptionsTwoS">
                                                    <div class="frymasterRCFT" style={stepsLength > 10 ? {width:'1150px'} : stepsLength > 6 ? {width:'850px'} : {width:''}}>
                                                        <ul>
                                                            <li class="startCook">
                                                                <label><FormattedMessage id="KC1494"/></label>
                                                                <span class="labelVal">{totalCookTime}</span>
                                                                <div class="labelImgDiv">
                                                                    <span class="labelImg"><img src={require("../../../../../images/recipecreation/startCookBtn.png")} alt=""  /></span>
                                                                    <button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#detailsInfoModal" onClick={() => { }}>&nbsp;</button>
                                                                </div>
                                                            </li>
                                                            {
                                                                ordered_steps.map((item, i) => {
                                                                    return (
                                                                    <li>
                                                                        <label>&nbsp;</label>
                                                                        <span class="labelVal">&nbsp;</span>
                                                                        <div class="labelImgDiv">
                                                                        <button type="button" class="btn labelImgText" onClick={() => {this.onShowPopup(item, "Segment " + (i+1))}}>{i + 1}</button>
                                                                        </div>  
                                                                    </li>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                conditional_steps.map((item, i) => {
                                                                    return (
                                                                    <li class="stir">
                                                                        <label>{item.actions.prompt.message.en_US}</label>
                                                                        <span class="labelVal">{item.actions.prompt.duration}</span>
                                                                        <div class="labelImgDiv">
                                                                            <span class="labelImg"><img src={require("../../../../../images/recipecreation/timerClockIcon.png")}  alt="" /></span>
                                                                        </div>
                                                                    </li>
                                                                    )
                                                                })
                                                            }
                                                            <li class="endCook">
                                                                <label><FormattedMessage id="KC1495"/></label>
                                                                <span class="labelVal">00:00</span>
                                                                <div class="labelImgDiv">
                                                                    <span class="labelImg"><img src={require("../../../../../images/recipecreation/endCookBtn.png")}  alt="" /></span>
                                                                </div>
                                                            </li>
                                                        {drainTimer === "true" && <li class="drain">
                                                                <label><FormattedMessage id="KC1387"/></label>
                                                                <span class="labelVal">{drainTimerValue ? drainTimerValue : "00.00"}</span>
                                                                <div class="labelImgDiv">
                                                                    <span class="labelImg"><img src={require("../../../../../images/recipecreation/timerClockIcon.png")}  alt="" /></span>
                                                                </div>
                                                            </li>}
                                                            {holdTimer === "true" && <li class="hold">
                                                                <label><FormattedMessage id="KC1388"/></label>
                                                                <span class="labelVal">{holdTimerValue ? holdTimerValue : "00.00"}</span>
                                                                <div class="labelImgDiv">
                                                                    <span class="labelImg"><img src={require("../../../../../images/recipecreation/timerClockIcon.png")}  alt="" /></span>
                                                                </div>
                                                            </li>}
                                                        </ul>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onSaveRecipe() }} class="btn saveButton rightAlign">&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Info Modal --> */}
                <div class="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="detailsInfoModal" tabindex="-1" role="dialog" aria-labelledby="detailsInfoModalLabel">
                    <div class="modal-dialog modal-md infoModal">
                        <div class="modal-content">  
                            <div class="modal-header modal-header-danger">
                                {popuptitle} <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
                            </div>
                            <div class="modal-body"> 
                                <div class="detailsInfoContent">  
                                    <div class="frymasterRCR modelView">
                                        <ul>
                                            <li>
                                                <label><FormattedMessage id="KC0197"/></label>
                                                    <span class="labelVal">{popupDuration}</span>
                                            </li>
                                            <li>
                                                <label><FormattedMessage id="KC1389"/></label>
                                                <span class="labelVal">{popupTemperature} F</span>
                                            </li>
                                            <li>
                                                <label><FormattedMessage id="KC1351"/></label>
                                                <span class="labelVal">{popupSensitivity}</span>
                                            </li> 
                                        </ul> 
                                    </div>
                                </div> 
                            </div> 
                        </div>
                    </div>
                </div>
                {/* <!-- //Info Modal --> */}
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterRecipeReview)
