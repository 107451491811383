import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format, addDays } from 'date-fns';
import PeakHoursChart from '../../unitDashboard/peakHourDashboard';
import PeakHour from '../../unitDashboard/peakHour';
import { getpeakHourChartInfo } from '../../../actions/reportManagement/peakHourChartAction';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { HolderList } from './holderList';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../utils/kcStorage';

// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;

class PeakHourWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            peakHourType: 'Month',
            sdPeakHour: '',
            edPeakHour: '',
            startDatePeakHour: "",
            endDatePeakHour: "",
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            holder_id: "",
            holder_name: "Fryer",
            datapickflag: false
        }
        this.handleChangeStartPeakHour = this.handleChangeStartPeakHour.bind(this);
        this.handleChangeEndPeakHour = this.handleChangeEndPeakHour.bind(this);
    }

    componentDidMount() {
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = KCStore.getItem("brandName");
        this.props.getpeakHourChartInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, "Unit");
    }

    componentDidUpdate = async (prevProp, prevState) => { 
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.peakHourType !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange ) {
            this.setState({ peakHourType: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.fetchPeakHour(this.state.holderid, this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDatePeakHour !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEndPeakHour(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDatePeakHour !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStartPeakHour(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //PeakHour Chart    
    handleChangeStartPeakHour(datePeakHour) {
        if(this.state.startDatePeakHour === datePeakHour){
            return;
        }
        this.setState({
            startDatePeakHour: datePeakHour,
            peakHourChanged: false,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.fetchPeakHour(this.state.holder_id,selectedtext);
        });
    }

    handleChangeEndPeakHour(datePeakHour) {
        if(this.state.endDatePeakHour === datePeakHour){
            return;
        }
        this.setState({
            endDatePeakHour: datePeakHour,
            peakHourChanged: false,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.fetchPeakHour(this.state.holder_id,selectedtext);
        });
    }

    fetchPeakHour(holder_id, text) {
        currentUnit = this.props.peakHourData.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;

        if (KCStore.getItem("brandName") === "CREM") {
            brandID = "CREM";
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDatePeakHour, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDatePeakHour, 'YYYY-MM-DD');
            }
            else {
                todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
                weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
            }

            if (todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") {
                this.setState({ peakHourType: text });
                this.props.getpeakHourChartInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
            }
        }
        else if (KCStore.getItem("brandName") === "FRYMASTER") {
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDatePeakHour, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDatePeakHour, 'YYYY-MM-DD');
            }
            else {
                this.state.peakHourChanged = false;
                todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
                weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
            }
        }
        else {
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDatePeakHour, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDatePeakHour, 'YYYY-MM-DD');
            }
            else if (text === 'Week') {
                weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
                todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

                var startDateMonth = getMonth(new Date(weekDateFormatted));
                var endDateMonth = getMonth(new Date(todaysDateFormatted));

                if (startDateMonth !== endDateMonth) {
                    weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
                }
            }
            else {
                this.state.peakHourChanged = false;
                todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
                weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
            }
        }

        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            if (KCStore.getItem("brandName") === "FRYMASTER") {
                this.props.peakHourData.getpeakHourChartInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, "Unit", holder_id);
            }
            else {
                this.props.peakHourData.getpeakHourChartInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, "Unit");
            }
        }
    }

    onHolderSelection(e, type = '') {
        const holderid = e.target.text.replace("Frypot ", "").replace("Fryer", "")
        this.setState({
            holder_id: holderid,
            holder_name: e.target.text
        });
        this.fetchPeakHour(holderid, type);
    }

    onDropDownHandler(e) {
        const {nodeText: text } = e;
        this.setState({peakHourType:text,datapickflag: text === 'Date Range', startDatePeakHour: '', endDatePeakHour: ''});
        this.fetchPeakHour(this.state.holder_id, text);
    }

    render() {
        const { peakHourChartInfo, deviceInformation } = this.props;
        const totalDrinksInformation = this.props.peakHourData;
        const { formatMessage } = this.props.intl;
        const { holder_name } = this.state;
        let _peakHour = 0;
        if (peakHourChartInfo.length > 0) {
            _peakHour = this.findPeakHour(peakHourChartInfo);
            this.setState({
                peakHour: _peakHour
            })
        }

        return (
            <div className="colm4D colm4D1">
                <div className="colmDi" >
                    <h4><FormattedMessage id='KC0311' />
                            &nbsp; {KCStore.getItem("excludedProductList") === "true" ? <span class="blueDot" title={formatMessage({id:"KC1037"})}></span> : ""}
                        <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDatePeakHour} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDatePeakHour} handleChangeStartProps={(date) => this.handleChangeStartPeakHour(date)} handleChangeEndProps={(date) => this.handleChangeEndPeakHour(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.peakHourType} />
                        {(KCStore.getItem("brandName") === "FRYMASTER") && <HolderList OnHolderChange={(e) => this.onHolderSelection(e, this.state.peakHourType)} />}
                    </h4>
                    <div>
                        <div class="colmDiVal" >
                            <PeakHour peakHourChartInfo={peakHourChartInfo} />
                        </div>
                        {<PeakHoursChart peakHourChartInfo={peakHourChartInfo} />}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        unitdashboardInfo: state.unitdashboardInformation,
        unitdashboardproductInfo: state.unitdashboardProductInformation,
        unitdashboardProductInformationdata: {},
        locationInfo: state.locationInformation,
        deviceInfo: state.deviceInformation,
        totalDrinksInfo: state.totalDrinksInformation,
        averageDrinksInfo: state.averageDrinksInformation,
        peakHourChartInfo: state.peakHourChartInformation,
        topTenTableInfo: state.topTenTableInformation,
        currentAppEntity: state.entityReducer.commonentity,
        errorsCountInfo: state.ErrorsCountInformation,
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getpeakHourChartInfo }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PeakHourWidget));