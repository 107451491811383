// get brand list
export const GET_BRAND_LIST = 'GET_BRAND_LIST';
export const GET_BRAND_LIST_SUCCESS = 'GET_BRAND_LIST_SUCCESS';
export const GET_BRAND_LIST_FAIL = 'GET_BRAND_LIST_FAIL';

// device connectivity status
export const GET_DEVICE_CONNECTIVITY_STATUS = 'GET_DEVICE_CONNECTIVITY_STATUS';
export const GET_DEVICE_CONNECTIVITY_STATUS_SUCCESS = 'GET_DEVICE_CONNECTIVITY_STATUS_SUCCESS';
export const GET_DEVICE_CONNECTIVITY_STATUS_FAIL = 'GET_DEVICE_CONNECTIVITY_STATUS_FAIL';
export const CLEAR = 'CLEAR';
export const SERIAL_NUMBER_CHANGED = 'SERIAL_NUMBER_CHANGED';
export const BRAND_CHANGED = 'BRAND_CHANGED';
