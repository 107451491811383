import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { getDeviceAPIUrl, getDeviceInfo } from '../../service/unitManagement/deviceinfo';
import { getLocationAPIUrl, getLocationInfo } from '../../service/unitManagement/locationinfo';
import MainUnitManagementWidget from './widget/unitmanagementmainwidget';

import KCStore from '../../utils/kcStorage';

am4core.useTheme(am4themes_animated);


class UnitManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			locationInformation: {},
			deviceInformation: {},
			loaderIconVisible: true
		}
	}

	componentDidMount = async () => {
		let currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		let deviceUrl = getDeviceAPIUrl(currentUnit.UNITID, KCStore.getItem("brandName"));
		let deviceInfo = await getDeviceInfo(deviceUrl);

		let locationUrl = getLocationAPIUrl(currentUnit.UNITID, KCStore.getItem("brandName"));
		let locationInfo = await getLocationInfo(locationUrl);

		if (deviceInfo && locationInfo) {
			this.setState({
				locationInformation: locationInfo,
				deviceInformation: deviceInfo
			});
		}
	}

	render() {		
		return (
			<MainUnitManagementWidget currentUnit={this.props}  currentLocation={this.props}/>
		)		
	}
};



function mapStateToProps(state) {
	return {
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getCurrentLocation, getCurrentUnit }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitManagement);
