import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import _ from 'lodash';
import LibraryListPopup  from '../../libraryListPopup';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../../../utils/appConstants'; 

import http from '../../../../../service/httpService';
import { MenuUploadAPI, organizationNode } from '../../../../../service/api';
import { UserProfile } from '../../../../../service/api';

import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';


var cookbookJSON = require('../../Templates/CookbookJSON');

var AdmZip = require('adm-zip');
var request = require('request');
var beautify = require("json-beautify");

var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

class FrymasterMenuEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            categoryList: [],
            isChanged: true,
            cookbook_name: '',
            nodeCaptionDetails: "",
            selectedUnitDetails: "",
            modalSettingValue: 'Save'
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.menuRedirect = this.menuRedirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onCookbookNameChange = this.onCookbookNameChange.bind(this);
        this.OnImport = this.OnImport.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        try {
            let categoryDetails = KCStore.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
            this.setState({
                categoryList: categoryDetailsObj,
                isChanged: categoryDetailsObj.length > 0 ? true : false,
                isEdit: libraryDetails.isEdit,
                isImport: libraryDetails.Import,
                cookbook_name: libraryDetails.cookbookName,
                selectedModel: parseInt(KCStore.getItem("selectedProductID"))
            });
            if (this.props.history.location.state.getSelectedDynasty) {
                let node = this.props.history.location.state.getSelectedDynasty;
                let selectedUnitDetails = this.props.history.location.state.getSelectedUnitDetails;
                await this.getNodeCaptionDetails(node, selectedUnitDetails);
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    getNodeCaptionDetails = async (val, val1) => {
        this.setState({ loaderIconVisible: true });
        const url = organizationNode.nodeDetails + "96";

        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'cid': KCStore.getItem("customerId"),
                'dynasty': val,
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                let result = response.data;
                let nodeDetails;
                result.map((item, i) => {
                    if (nodeDetails) {
                        if ((result.length - 1) === i) {
                            nodeDetails = nodeDetails + item.node_name;
                        } else {
                            nodeDetails = nodeDetails + item.node_name + "/";
                        }
                    } else {
                        nodeDetails = item.node_name + "/";
                    }
                })

                this.setState({
                    loaderIconVisible: false,
                    nodeCaptionDetails: nodeDetails,
                    selectedUnitDetails: val1,
                });
                // await this.getThingDetails();
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    selectTestDeleteButtonClick = () => {
        this.setState({
            nodeCaptionDetails: null,
            selectedUnitDetails: {},
        });
    }

    menuRedirect = () => {
        this.props.history.push({
            pathname: "/frymasterMenuList",
            isEdit: false,
            previouspath: "/frymasterMenuEditor"
        });
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            isDeleteCategory: false,
            errorCookbookName: ""
        })
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        let path = "/frymasterRecipeEditor";
        if (libraryDetails.isEdit) {
            path = "/libraryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/frymasterMenuEditor"
        });
    }

    onBackButtonClick = () => {
        if (!this.state.isChanged) {
            let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
            let path = "/frymasterRecipeEditor";
            if (libraryDetails.isEdit) {
                path = "/libraryList";
            }
            this.props.history.push({
                pathname: path,
                isEdit: false,
                previouspath: "/frymasterMenuEditor"
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onCookbookNameChange = (e) => {
        if (e.target.value.match(/^[a-zA-Z0-9 ]*$/)) {
            this.setState({
                cookbook_name: e.target.value,
                errorCookbookName: ""
            });
        }
        else {
            this.setState({
                errorCookbookName: "KC1391"
            });
        }
    }

    async OnImport(item) {
        this.setState({
            loaderIconVisible: true
        });
        await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
            headers: {
                'Content-Type': 'application/json',
                'library_id': item.LIBRARY_ID,
                'library_isedit': true
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                let file_path = response.data.presignedURL;
                var libraryFiles = [];
                var b64 = "";
                var imageBuffer = null;
                KCStore.setItem("selectedGroupID", item.CGID);
                await http.get(MenuUploadAPI.getLibraryList,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'customerid': CUSTOMER_ID,
                            'cgids': KCStore.getItem("custGroupID"),
                            'brandid': KCStore.getItem("selectedbrandID"),
                            'productid': KCStore.getItem("selectedProductID"),
                            'basecountry': BASECOUNTRY_ID,
                            'Type': "Recipe"
                        },
                        data: {}
                    }).then(response => {
                        let recipeData = response ? response.data ? response.data.libraryList ? response.data.libraryList : [] : [] : [];
                        try {
                            request.get({ url: file_path, encoding: null }, (err, res, body) => {
                                var zip = new AdmZip(body);
                                var zipEntries = zip.getEntries();

                                zipEntries.forEach((entry, i) => {
                                    if (item.TYPE === "CookBook") {
                                        libraryFiles[i] = {};
                                        libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '');
                                        imageBuffer = zip.readFile(entry);

                                        if (!entry.entryName.includes('.json')) {
                                            libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
                                        }
                                        else {
                                            libraryFiles[i].fileBuffer = imageBuffer;
                                            b64 = imageBuffer.toString('base64');
                                        }
                                    }
                                    else {
                                        libraryFiles[i] = zip.readFile(entry);

                                        if (!entry.entryName.includes('.json')) {
                                            imageBuffer = Buffer.from(libraryFiles[i]);
                                            b64 = imageBuffer.toString('base64');
                                        }
                                    }
                                });

                                KCStore.setItem("selectedFamilyID", item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : '');
                                let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
                                let md5 = require('md5');
                                let libraryDetails = {};
                                libraryDetails.id = UpdatedJSONObj.id;
                                libraryDetails.version = UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.'));
                                libraryDetails.library_id = item.LIBRARY_ID;
                                libraryDetails.cookbookName = item.LIBRARY_NAME;
                                libraryDetails.crc = md5(b64, "base64");
                                libraryDetails.isEdit = false;
                                libraryDetails.autoMenuChange = UpdatedJSONObj.autoMenuChange;
                                libraryDetails.limitedTimeOffer = UpdatedJSONObj.limitedTimeOffer;
                                libraryDetails.initialMethod = "Add";
                                //CookBook
                                let categoryDetailsObj = [];
                                UpdatedJSONObj && UpdatedJSONObj.menus && UpdatedJSONObj.menus.map((item, i) => {
                                    let category = {};
                                    category.ID = item.id;
                                    category.categoryName = item.name ? item.name.en_US ? item.name.en_US : "" : "";
                                    category.type = "Category";
                                    category.recipe_items = [];
                                    item.recipe_items && item.recipe_items.map((recipeitem, k) => {
                                        let recipe = recipeData.filter(d => d.LIBRARY_ID === recipeitem);
                                        category.recipe_items[k] = recipe && recipe[0] ? recipe[0] : [];
                                    });
                                    categoryDetailsObj.push(category);
                                });

                                categoryDetailsObj.Import = true;
                                KCStore.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
                                KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));

                                let categoryDetails = KCStore.getItem("resourceDetails");
                                categoryDetailsObj = JSON.parse(categoryDetails);
                                this.setState({
                                    categoryList: categoryDetailsObj,
                                    isChanged: true,
                                    loaderIconVisible: false
                                });
                            });
                        }
                        catch (ex) {
                            this.setState({
                                loaderIconVisible: false
                            });
                        }
                    });
            }
        })
            .catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data, stringID } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: stringID ? stringID : data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    } 
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message:  stringID ? stringID :data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    selectTestUnitButtonClick = (e) => {
        e.preventDefault();
        let _this = this;
        let categoryDetails = KCStore.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let httpMethod;

        if (!categoryDetailsObj && !categoryDetailsObj.length > 0) {
            _this.props.history.push(`/frymasterMenuEditor`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = KCStore.getItem("brandName");
            let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
            let loggedinUser = loginUser.username;
            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'base_country': KCStore.getItem("basecountry"),
					'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(async response => {
                let libraryId;
                let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
                if (libraryDetails.isEdit) {
                    httpMethod = "PUT";
                    libraryId = libraryDetails.library_id;
                } else {
                    httpMethod = "POST";
                    libraryId = libraryDetails.cookbookName + '_' + Date.now();
                    libraryId = libraryId.replace(/\s+/g, '');
                    libraryDetails.library_id = libraryId;
                    KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
                }

                let jsonTemplateFile = cookbookJSON;
                let jsonTemplateObj = jsonTemplateFile.default;
                let categoryDetailsData = KCStore.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetailsData);
                jsonTemplateObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.version = libraryDetails.isEdit ? parseInt(libraryDetails.version) + 1 + ".0.0" : "1.0.0";
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;

                jsonTemplateObj.autoMenuChange = libraryDetails.autoMenuChange ? libraryDetails.autoMenuChange : [];
                jsonTemplateObj.limitedTimeOffer = libraryDetails.limitedTimeOffer ? libraryDetails.limitedTimeOffer : [];

                let imageSize = 0.00;
                jsonTemplateObj.menus = [];
                categoryDetailsObj.map((item, i) => {
                    jsonTemplateObj.menus[i] = {};
                    jsonTemplateObj.menus[i].id = item.ID;
                    jsonTemplateObj.menus[i].version = libraryDetails.isEdit ? parseInt(libraryDetails.version) + 1 + ".0.0" : "1.0.0";
                    jsonTemplateObj.menus[i].edit_date = todaysDateUTCFormatted;
                    jsonTemplateObj.menus[i].author = response.data.name;
                    jsonTemplateObj.menus[i].email = loginUser.username;
                    jsonTemplateObj.menus[i].name = {};
                    jsonTemplateObj.menus[i].name.en_US = item.categoryName;
                    // jsonTemplateObj.menus[i].image = "";
                    jsonTemplateObj.menus[i].recipe_items = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    jsonTemplateObj.menus[i].imageS3Url = "";
                    if (item.recipe_items) {
                        item.recipe_items.map((subitem, j) => {
                            imageSize = imageSize + subitem.IMAGE_FILE_SIZE ? parseFloat(subitem.IMAGE_FILE_SIZE) : 0.00;
                        })
                    }
                })
                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);
                let md5 = require('md5');
                let crc = md5(jsonBuffer, "base64");

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": libraryDetails.cookbookName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + imageSize).toFixed(2),
                    "libraryS3Url": "",
                    "type": "CookBook",
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": "",
                    "imageFileFormat": "",
                    "imageFileSize": (imageSize).toFixed(2),
                    "imageS3Url": "",
                    "cgids": [parseInt(KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : 0)],
                    "json": JSON.stringify(jsonTemplateObj),
                    "categories": categoryDetailsObj ? categoryDetailsObj : [],
                    "libraryGuid": jsonTemplateObj.id
                };
                if (libraryDetails.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }
                else {
                    libraryPostData.status = "DRAFT";
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': "",
                    'jsonfilekey': libraryId + '.json',
                    'audiofilekey': "",
                    'isthumbnail': false
                }
                uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(async response => {
                    let { selectedUnitDetails } = this.state;

                    const unitList = [];
                    unitList.push(
                        {
                            "thingArn": selectedUnitDetails.THING,
                            "productId": selectedUnitDetails.ProductId,
                            "cloudUnitName": selectedUnitDetails.CLOUD_UNIT_NAME,
                            "unitTimeZone": selectedUnitDetails.TimeZone_Standard
                        }
                    )

                    let postData = {
                        "customer": CUSTOMER_ID,
                        "brand": brandName,
                        "brandId": BRAND_ID_VALUE[brandName],
                        "user": loggedinUser,
                        "libraryIds": [libraryId],
                        "country": BASECOUNTRY_ID,
                        "type": "Recipe",
                        "thingList": unitList,
                        "userName": loggedinUser,
                        "isTestPush": true
                    };

                    let url = MenuUploadAPI.getLibraryUpdateStatus;
                    await http.post(url, postData,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                        .then(response => {
                            let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
                            libraryDetails.isEdit = true;
                            KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
                            _this.setState({
                                loaderIconVisible: false,
                                isEdit: true,
                                success_modal: {
                                    open: true,
                                    message: "KC1227"
                                },
                                nodeCaptionDetails: null,
                                selectedUnitDetails: {},
                            });
                        }).catch(err => {
                            _this.showError(err);
                        });


                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
            });
        }
    }

    onSaveButtonClick = () => {
        if (this.state.isChanged) {
            let cookbookName = this.state.cookbook_name.trim();
            if (cookbookName.length < 4 || cookbookName.length > 20) {
                this.setState({
                    errorCookbookName: "KC1392"
                })
            }
            else {
                let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
                libraryDetails.cookbookName = cookbookName;
                KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
                if (this.state.modalSettingValue === "Test") {
                    this.props.history.push({
                        pathname: "/recipeAssignTopology",
                        previouspath: "/frymasterMenuEditor",
                    });
                    return;
                }
                this.props.history.push({
                    pathname: "/assignFrymasterMenuToTopology",
                    isEdit: false,
                    previouspath: "/frymasterMenuList"
                });
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1393"
                }
            });
        }
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { success_modal, loaderIconVisible, categoryList, error_modal, isChanged, showDiscardPopup, cookbook_name, errorCookbookName, selectedModel } = this.state;
        categoryList = _.orderBy(categoryList, [categoryList => categoryList.categoryName], ["asc"]);
        let brandid = KCStore.getItem("selectedbrandID");
        let loginInfo = JSON.parse(KCStore.getItem("loginInfo"));

        return (
            <React.Fragment>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt="" /></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button> <FormattedMessage id="KC1394"/>
                                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <div class="rcSettingsList02">
                                                        <ul onClick={() => { this.menuRedirect() }}>
                                                            <li><FormattedMessage id="KC1394"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} alt="" /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList02">
                                                        <ul onClick={() => {
                                                            if (this.state.categoryList.length) {
                                                                this.props.history.push('/frymasterMenuAutoChange')
                                                            }
                                                        }}>
                                                            <li><FormattedMessage id="KC1395"/></li>
                                                            <li><img class={this.state.categoryList.length ? "" : "disabled"} src={require("../../../../../images/recipecreation/rightArrow.png")} alt="" /></li>
                                                            {/* <li class="arrowBtn"><button type="button" onClick={()=>this.props.history.push('/frymasterEcoModeScheduled')} class={scheduleTime ? "btn nextButton" : "btn nextButton disabled"}>&nbsp;</button></li> */}
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList02">
                                                        <ul onClick={() => {
                                                            if (this.state.categoryList.length) {
                                                                this.props.history.push('/frymasterMenuLimitedOffer')
                                                            }
                                                        }}>
                                                            <li><FormattedMessage id="KC1396"/></li>
                                                            <li><img class={this.state.categoryList.length ? "" : "disabled"} src={require("../../../../../images/recipecreation/rightArrow.png")} alt="" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" data-toggle="modal" data-target="#saveNameModal" onClick={() => this.setState({ modalSettingValue: 'Save' })} className={isChanged ? "btn btn-default-text saveButton" : "btn btn-default-text saveButton disabled"} title={formatMessage({ id: 'KC1243' })}>&nbsp;</button>
                                    <button type="button" data-toggle="modal" data-target="#saveNameModal" onClick={() => this.setState({ modalSettingValue: 'Test' })} className={loginInfo.rolename === "Public Admin" ? "displaynone" : isChanged ? "btn btn-default-text testButton" : "btn btn-default-text testButton disabled"} title={formatMessage({ id: 'KC1972' })}><FormattedMessage id="KC0698"/></button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                            <div className={loginInfo.rolename === "Public Admin" ? "displaynone" : "testOnUnitTable"}>
                                <ul>
                                    <li><img src={require("../../../../../images/recipecreation/assetD.svg").default} alt="" /></li>
                                    <li>
                                        <input type="text" className="form-control" value={this.state.nodeCaptionDetails ? this.state.selectedUnitDetails.UNITID + "_" + this.state.selectedUnitDetails.BRAND_NAME + "     " + this.state.nodeCaptionDetails : ""} placeholder={formatMessage({ id: 'KC1276' })}></input>
                                    </li>
                                    <li>
                                        <button type="button" className="btn btn-default-text deleteButton" onClick={() => { this.selectTestDeleteButtonClick() }}>&nbsp;</button>
                                    </li>
                                    <li>
                                        <button type="button" className={this.state.nodeCaptionDetails ? "btn btn-default-text" : "btn btn-default-text disabled"} onClick={(e) => { this.selectTestUnitButtonClick(e) }}><FormattedMessage id="KC1231"/></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={isChanged && showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isChanged && showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                {/* <!-- Save Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="saveNameModal" tabindex="-1" role="dialog" aria-labelledby="saveNameModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmationYesNo">
                                    <h5><FormattedMessage id="KC1142"/></h5>
                                    <div class="input-group">
                                        <input class="form-control" type="text" placeholder={formatMessage({ id: 'KC1142' })} value={cookbook_name} onChange={(e) => this.onCookbookNameChange(e)} />
                                        <div className="errorMsg">{errorCookbookName &&  errorCookbookName != '' ? <FormattedMessage id={errorCookbookName}/> : ''}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button onClick={() => { this.OnCancel() }} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
                                <button onClick={() => { this.onSaveButtonClick() }} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id="KC0123"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Save Modal --> */}
                {/* <!-- Import Modal --> */}
                <LibraryListPopup libraryType={'CookBook'} OnImportLibrary={(item) => this.OnImport(item)} modelID={selectedModel} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} /> {/* <!-- //Import Modal --> */}
                <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterMenuEditor)
