import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import ViewIndividualTopology from '../../controls/viewIndividualTopology';
import { injectIntl,FormattedMessage } from 'react-intl';

class RecipeAssignTopology extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            previouspath: '',
        };
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            let { KC_ERR_400, body } = data;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: KC_ERR_400 ? KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: body.stringID ? body.stringID : body.message ? body.message : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1065"
                }
            });
        }
    }

    onSuccessCloseModal = () => {
        this.props.history.push(this.state.previouspath);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(this.state.previouspath);
    }

    changeLoadingStatus = (value) => {
        this.setState({ loaderIconVisible: value })
    }

    render() {
        let { success_modal, error_modal } = this.state;
        const { formatMessage } = this.props.intl;
        const { loaderIconVisible } = this.state;

        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewIndividualTopology
                    title={`KC2078`}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    history={this.props.history}
                    location={this.props.location} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="mediaNav" />
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RecipeAssignTopology));
