import {LOCATION_REQUEST,LOCATION_SUCCESS,LOCATION_FAIL} from '../../../actions/unitManagement/locationAction';

const initialState = {
    loaded: false,
    loading: false,
    locationInformation: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case LOCATION_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
               
            }
        case LOCATION_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                locationInformation: action.locationInformation
            }
        case LOCATION_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',

            }
        default:
            return state
    }
}