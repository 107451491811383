
import { MenuUploadAPI } from '../api';
import http from '../httpService';

export const getMenuAPIUrl = () => {
    let apiUrl = `${MenuUploadAPI.getLatestMenuFileName}`;
    return apiUrl;
}

export const getMenuFileInfo = async (url, unitId, brandId) => {
    let response = {};
    try {
        let result = await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'unitId': unitId,
                'brandId': brandId
            },
            data: {}
        });
        response = result.data;
        return response;
    }
    catch (err) {
        return response;
    }
}
