import { snackbarClosingSecond } from '../../../components/navigation/organizationManagement/organizationManagement';
import { GET_SNACKBAR_DATA, SET_SNACKBAR_DATA } from '../../../actions/snackbar/type';

const initialState = {
    snackbarValue: {
        message: '',
        isActive: false,
        state: '',
        seconds: snackbarClosingSecond
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SNACKBAR_DATA:
            return {
                ...state,
            }
            debugger;
        case SET_SNACKBAR_DATA:
            return {
                ...state,
                snackbarValue: action.payload
            }

        default:
            return state;
    }
}