import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuUploadAPI } from '../../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../../service/httpService';
import { setUserDataModel } from '../../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../../modal/success-model';
import ErrorMoal from '../../../modal/error-model';
import UserManagementHeader from '../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewTopology from '../../../../components/controls/viewTopology';
import { createDBFile, uploadIntoS3, createRCtoCB } from "../../../../utils/convothermDB";
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../utils/kcStorage';

const HTTP_METHOD ={
	GET:'GET',
	PUT:'PUT',
	POST:'POST',
	DELETE:'DELETE'
}

class AssignLegacyCookbookToTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/legacyLibraryList`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            linkListValue: {},
            findTreeLevel: "",
            selectValues: {},
            pageLoad: true,
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        $("body").removeClass("loginBg");
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        this.props.history.push({
            pathname: this.props.location.previouspath ? this.props.location.previouspath : "/legacyReadyRecipeLibraryList",
            isEdit: false,
            previouspath: "/assignLegacyCookbookToTopology",
        });
    }

    submitAddLibrary = (e,_customIds) => {
        e.preventDefault();
        let kcbrandsitedata = KCStore.getItem("kcbrandsitedata");
        let objbrandsitedata = JSON.parse(kcbrandsitedata);
        let cgids = _customIds;
        if (objbrandsitedata && objbrandsitedata.readyrecipeids) {
            if (objbrandsitedata.name) {
                this.setState({
                    loaderIconVisible: true
                })
                this.getLibraryInfo(objbrandsitedata.readyrecipeids,true).then(async (res) => {
                    if (res.httpCode === "HTTP_404") {
                        this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: "KC1199"
                            },
                        });
                    }
                    else {
                        let { recipeDetails } = res;
                        let familyarrids = recipeDetails && recipeDetails.length > 0 ? recipeDetails[0].modelFamilyIds : [];
                        KCStore.setItem("selectedFamilyID", familyarrids ? familyarrids.map(a => a).join(',') : "");
                        let DB = await createDBFile(res,objbrandsitedata.name);
                        if(DB) {
                            let LIBRARY_NAME =  (objbrandsitedata.name + '_' + Date.now()).replace(/\s+/g, '')
                            let url = await this.getPresignedUrl(LIBRARY_NAME);
                            await uploadIntoS3(DB,url.presignedURLs);
                            await this.onAddCookbook(LIBRARY_NAME, objbrandsitedata.name, DB, url.presignedURLs,cgids).then((res) => {
                                this.setState({
                                    loaderIconVisible: false,
                                    success_modal: {
                                        open: true,
                                        message: "KC1200"
                                    },
                                });
                            });
                        }
                    }
                }).catch(err => {
                    console.log(err);
                    this.setState({
                        loaderIconVisible: false
                    });
                });
            }
            else {
                this.setState({
                    loaderIconVisible: true
                });
                this.getLibraryInfo(objbrandsitedata.readyrecipeids,true).then(async (res) => {
                    if (res.httpCode === "HTTP_404") {
                        this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: "KC1201"
                            },
                        });
                    }
                    else {
                        let { recipeDetails } = res;
                        let familyarrids = recipeDetails && recipeDetails.length > 0 ? recipeDetails[0].modelFamilyIds : [];
                        KCStore.setItem("selectedFamilyID", familyarrids ? familyarrids.map(a => a).join(',') : "");
                        await http({
                            method: HTTP_METHOD.POST,
                            url: MenuUploadAPI.addConvoLibrary,
                            headers: {
                                "Content-Type": "application/json",
                            },
                            data: {
                                customer_id: CUSTOMER_ID,
                                modelFamilyIds: KCStore.getItem("selectedFamilyID"),
                                ready_recipe_id: objbrandsitedata.readyrecipeids,
                                created_by: KCStore.getItem("user"),
                                country: BASECOUNTRY_ID,
                                cgids: cgids.toString(),
                            },
                        })
                        .then((res) => {
                            this.setState({
                                loaderIconVisible: false,
                                success_modal: {
                                    open: true,
                                    message: "KC1202"
                                },
                                });
                            })
                            .catch((err) => {
                                if (err.response) {
                                    // Request made and server responded
                                    console.log(err.response.data.message);
                                } else if (err.request) {
                                    // The request was made but no response was received
                                    console.log(err.request);
                                } else {
                                    // Something happened in setting up the request that triggered an Error
                                    console.log('Error', err.message);
                                }
                                this.setState({
                                    loaderIconVisible: false,
                                    error_modal: {
                                        open: true,
                                        message: err.response && err.response.data.message ? err.response.data.message : ""
                                    },
                                });
                            });
                    }
                })
            }
        } 
        else if( objbrandsitedata && objbrandsitedata.libraryList && objbrandsitedata.cookbookName) {
            this.setState({
                loaderIconVisible: true
            })
            let objCookbookdata = objbrandsitedata;
            const libraries = objCookbookdata.libraryList;
            let libraryids;
            let selectedCookbook = libraries.filter(c => c.TYPE === "CookBook" && c.checked === true);
            let selectedReadyRecipes = libraries.filter(c => c.TYPE === 'ReadyRecipe' && c.checked === true);
    
            if(selectedCookbook.length === 1 && selectedReadyRecipes.length > 0 ) {
                libraryids = [...selectedReadyRecipes.map(item => item.ID),...selectedCookbook.map(item => item.ID)];
            }
            else if(selectedCookbook.length === 0 && selectedReadyRecipes.length > 0){
                libraryids = selectedReadyRecipes.map(item => item.ID);
            }
            this.getLibraryInfo(libraryids,false).then(async (res) => {
                let DB;
                if(selectedCookbook.length === 1 && selectedReadyRecipes.length > 0 ) {
                    DB = await createRCtoCB(res,objCookbookdata.cookbookName);
                }
                else if(selectedCookbook.length === 0 && selectedReadyRecipes.length > 0){
                    DB = await createDBFile(res,objCookbookdata.cookbookName);
                }
                if(DB){
                    let LIBRARY_NAME =  (objCookbookdata.cookbookName + '_' + Date.now()).replace(/\s+/g, '')
                    let url = await this.getPresignedUrl(LIBRARY_NAME);
                    await uploadIntoS3(DB,url.presignedURLs);
                    await this.onAddCookbook(LIBRARY_NAME, objCookbookdata.cookbookName , DB, url.presignedURLs,cgids).then((res) => {
                        this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: "KC1200"
                            },
                        });
                    });
                }
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false,
                    cookbook_name:'',
                    
                });
            });
        }
    }

    getLibraryInfo = (ids,kcbrandsiteflag) => {
        let headers = {
            'Content-Type': "application/json",
            'customerid': CUSTOMER_ID,
            'kcbrandsiteflag': kcbrandsiteflag.toString(),
        };
        kcbrandsiteflag ?  headers.readyrecipeids = ids.toString() : headers.libraryids = ids.toString();
		return new Promise((resolve, reject) => {
			http.get(MenuUploadAPI.getConvoLibraryInfo, {
                headers: headers,
                data: {},
            })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
		});
	}

    getPresignedUrl = (LIBRARY_NAME) => {
        return new Promise((resolve, reject) => {
            http.get(MenuUploadAPI.getConvoUploadpresignedUrl, {
                headers: {
                    'Content-Type': "application/json",
                    'customerid': CUSTOMER_ID,
                    'cookbookfilekey' : `${LIBRARY_NAME}.db`,
                },
                data: {},
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    onAddCookbook = (LIBRARY_NAME, name, DB, URL,cgids) => {
        let _buffer = DB.export();
        let totalfilesize = Buffer.byteLength(_buffer);
        totalfilesize = (totalfilesize / 1048576);
        let S3Url = URL.split('?')[0];
        let md5 = require('md5');
        return new Promise((resolve, reject) => {
            http({
                method: HTTP_METHOD.POST,
                url: MenuUploadAPI.createConvoCB,
                headers: {},
                data: {
                    "customerId": CUSTOMER_ID,
                    "brandId": KCStore.getItem("selectedbrandID"),
                    "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                    "libraryId": LIBRARY_NAME,
                    "libraryName": name,
                    "libraryFileName": `${LIBRARY_NAME}.db`,/* 'db' handled only for convotherm. need to put condition for other brands */
                    "libraryFileFormat": "db",
                    "libraryFileSize": totalfilesize,
                    "libraryS3Url": S3Url,
                    "type": "CookBook",
                    "createdUser":KCStore.getItem("user"),
                    "crc": md5(_buffer, "base64"),
                    "baseCountry": BASECOUNTRY_ID,
                    "cgids": cgids
                }
            }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }

    changeLoadingStatus=(value)=>{
        this.setState({loaderIconVisible:value})
    }

    render() {
        let { success_modal, error_modal, } = this.state;
        const { loaderIconVisible } = this.state;
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology 
                    title={`KC1677`} 
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus} 
                    onBackClickHandler={this.onBackClickHandler} 
                    modelFamilyIds={KCStore.getItem("selectedFamilyID")} 
                    start_level_id={1}
                    selected_cgid={true} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignLegacyCookbookToTopology));
