import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
//import SideBarTree from "./sideBarTree";
import SampleMap from './sampleMap';
import WoopraEvents from '../../woopraConfig/WoopraEvents';


class SiteTree extends Component {

    trackEvent = (e) => {
        WoopraEvents(`${e.target.title}`);
    }

	render() {
		return (
			<div>
                <div id="wrapper">
                    <div className="loadingProgress">
                        <div className="loadingProgressIn"></div>
                    </div>
                    <nav className="navbar navbar-expand-lg navbar-dark bg-Welbilt">
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="navbar-toggler-icon"></span>
							</button>
							<div className="collapse navbar-collapse" id="navbarContent">
								<ul className="navbar-nav mr-auto titlebarLeft">
									<li className="nav-item">
										<Link className="nav-link" to="#" title="All Locations">All Locations</Link>
									</li> 
								</ul> 
								<ul className="navbar-nav mr-auto navbarRight">
									<li className="nav-item">
										<Link className="nav-link " title="Sorting" to="#">&nbsp;</Link>
									</li>
									<li className="nav-item"> 
										<Link className="nav-link navFilter" title="Filter" to="/locationsView/filterBy">&nbsp;</Link> 
									</li>
									<li className="nav-item">
										<Link className="nav-link navSearch" title="Search" to="#">&nbsp;</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link navAlarm" title="Notification" to="#">&nbsp;</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link navUser" title="User Name" to="#">&nbsp;</Link>
									</li>
								</ul>
							</div>
						</nav>


                    <div id="sidebar-wrapper">
                    <ul className="sidebarNav">
                        <li className="brandName">
                            <Link to="/dashBoardLocations" title="Welbilt - Kitchen Connect" className="homeNav">&nbsp;</Link>
                        </li>
                        <li>
                            <Link to="/dashBoardLocations" title="All Locations" className="dashboardNav" onClick={(e)=> this.trackEvent(e)}>&nbsp;</Link>
                        </li>
                        <li>
                            <Link to="/locationsView" title="List View" className="ListviewA" onClick={(e)=> this.trackEvent(e)}>&nbsp;</Link>
                        </li>
                        <li>
                            <Link to="/locationsMap" title="Map View" className="MapViewA active" onClick={(e)=> this.trackEvent(e)}>&nbsp;</Link>
                        </li>
                        <li>
                            <Link to="#" title="Site Map" className="SidetreeA " onClick={(e)=> this.trackEvent(e)}>&nbsp;</Link>
                        </li> 
                    </ul>
                        <ul className="sidebarNav bottomNav">
                            <li>
                                <Link to="#" /*className="uploadNav"*/ title="Upload">&nbsp;</Link>
                            </li>
                            <li>
                                <Link to="#" className="menuNav" title="Navigation">&nbsp;</Link>
                            </li>
                        </ul>
                    </div>

                    <div id="page-content-wrapper">
                        <div className="pageTabHeader marginTop2px">
                        <ul>
                            {/* <li class="siteTreeIcon">
                                <Link to="/locationsMap" title="Site Tree" id="sitetreeInfoToggle" class="sitetreeInfoHide">&nbsp;</Link>
                            </li> */}
                            <li>
                                <Link to="/locationsMap" title="Locations" class="locationsView active">Locations</Link>
                            </li>
                            <li>
                                <Link to="/unitsMap" title="Units" class="unitsView">Units</Link>
                            </li>
                            <li class="selectedBox">
                                <div class="selectedValBox">
                                    <span class="errorBoxXXXXXXXX "></span>
                                    <span class="warningBoxXXXXXXXX"></span>
                                </div>
                                {/*<select class="form-control selectedDuration">
                                    <option value="All Errors">Units</option>
                                    <option value="Warning">Warning</option>
                                    <option value="Error">Error</option>
                                    <option value="Offline">Offline</option>
                                </select>*/}
                            </li>
                        </ul>
                        </div>
    
                        <div className="storeMapWrapper">
                           <div id="mapSidebarWrapper">
                                <ul className="mapSidebarList">
                                    <li className="mapSidebarListBrand">
                                        <Link to="#">
                                            
                                        </Link>
                                        <Link to="#"><span className="countryName">Countries</span><span className="numberOfCounts">26</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#pageSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><span className="countryFlag"><img src={require('../../images/flag/usa.png')} /></span><span className="countryName">USA</span><span className="numberOfCounts">26</span></Link>
                                        <ul className="collapse list-unstyled" id="pageSubmenu">
                                            <li>
                                                <Link to="#"><span className="countryName">Regions</span><span className="numberOfCounts">26</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="#"><span className="countryFlag"><img src={require('../../images/flag/germany.png')} /></span><span className="countryName">Germany</span><span className="numberOfCounts">4</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#"><span className="countryFlag"><img src={require('../../images/flag/sweden.png')} /></span><span className="countryName">Sweden</span><span className="numberOfCounts">10</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#"><span className="countryFlag"><img src={require('../../images/flag/england.png')} /></span><span className="countryName">Great Britain</span><span className="numberOfCounts">4</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#"><span className="countryFlag"><img src={require('../../images/flag/france.png')} /></span><span className="countryName">France</span><span className="numberOfCounts">2</span></Link>
                                    </li>
                                </ul>
                        </div> 
                            <div className="mapContentWrapper">
                                <div id="googleMap">
                                <SampleMap/>
                                </div>
                            </div>	
                        </div>
                    
                    </div>
                </div>
            </div>
        )
    }
};

export default SiteTree;
