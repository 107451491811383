import React, { Component } from 'react';
import ErrorMoal from '../modal/error-model';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FormattedMessage } from 'react-intl';
import { filterTypeOptionStringIDMap,datePickerLocale } from '../../locale/constant';
import { reactIntl } from '../../locale/locale-provider';

var date = new Date();
var maxd = date;
let savestartDate, saveendDate;
export class DropDownListLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error_modal: {
                open: false,
                message: ""
            },

        }
        this.onDropDownHandler = this.onDropDownHandler.bind(this);
        this.toggleDatePicker = this.toggleDatePicker.bind(this);
        this.handleChangeStarts = this.handleChangeStarts.bind(this);
        this.handleChangeEnds = this.handleChangeEnds.bind(this);
        this.closeModel = this.closeModel.bind(this);
    }

    componentDidUpdate=(_,prevState)=>{
        if(prevState.datapickflag !== this.props.datapickflag){
            this.setState({datapickflag:this.props.datapickflag})
        }
    }

    handleChangeStarts(date) {
        savestartDate = date;
        this.props.handleChangeStartProps(date, maxd);
        if (savestartDate > saveendDate) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1036"
                }
            });
        }
    }

    handleChangeEnds(date) {
        saveendDate = date;
        this.props.handleChangeEndProps(date);
        if (savestartDate > saveendDate) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1027"
                }
            });
        }
    }
    handleChangeRaw = (e) => {
        e.preventDefault();
    }

    toggleDatePicker = (e) => {
        e.nodeText = 'Date Range';
        this.props.OnChange(e);
        this.setState({ datapickflag: true })
    }

    onDropDownHandler = (e) => {
        this.props.OnChange(e);
        this.setState({ datapickflag: false })
    }
    closeModel() {
        this.setState({ error_modal: { open: false } })
    }
    render() {

        let { filterType, translation = false } = this.props;
        filterType = translation ? filterTypeOptionStringIDMap[filterType] : filterType;
        let { error_modal } = this.state;

        const CustomInput = ({ value, onClick, stringID }) => (
            <span className="datePickerSpan" onClick={onClick}>
                {value ? value : stringID ? reactIntl.formatMessage({id: stringID}) : <FormattedMessage id='KC0835'/> }
            </span>
        );

        return (

            <div className="dropdown datepickerBtnGroup">

                <button className="btn btn-secondary dropdown-toggle dateRangeSelection" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <FormattedMessage id={filterType} />
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <Link className="dropdown-item" to="#" onClick={(e) => {e.nodeText = 'Day'; this.onDropDownHandler(e)}}>{translation ? <FormattedMessage id='KC0085' /> : 'Day'}</Link>
                    <Link className="dropdown-item" to="#" onClick={(e) => {e.nodeText = 'Week'; this.onDropDownHandler(e)}}>{translation ? <FormattedMessage id='KC0086' /> : 'Week'}</Link>
                    <Link className="dropdown-item" to="#" onClick={(e) => {e.nodeText = 'Month'; this.onDropDownHandler(e)}}>{translation ? <FormattedMessage id='KC0087' /> : 'Month'}</Link>
                    <Link className="dropdown-item" to="#" onClick={(e) => {e.nodeText = 'Quarter'; this.onDropDownHandler(e)}}>{translation ? <FormattedMessage id='KC0088' /> : 'Quarter'}</Link>
                    <Link className="dropdown-item" to="#" onClick={(e) => {e.nodeText = 'Year'; this.onDropDownHandler(e)}}>{translation ? <FormattedMessage id='KC0089' /> : 'Year'}</Link>
                    {this.props.disableDatePicker ? "" : <div className="dropdown-divider"></div>}
                    {this.props.disableDatePicker ? "" :
                        <Link className="dropdown-item widthDateIcon" to="#" onClick={this.toggleDatePicker}><img src={require("../../images/icons/DateT.svg").default} alt="" />{translation ? <FormattedMessage id='KC0090'/> : 'Date Range'}</Link>}
                </div>
                <div className={this.state.datapickflag ? "showHideDate showdp" : "showHideDate"}>
                    <FormattedMessage id="KC0091" />&nbsp;
                    <DatePicker locale={datePickerLocale} customInput={<CustomInput stringID="KC0835"/>} placeholderText={reactIntl.formatMessage({ id: 'KC0835' })} onChangeRaw={(e) => this.handleChangeRaw(e)} id="date_picker_start_id" showMonthDropdown showYearDropdown dropdownMode="select" selected={this.props.startDatepara} selectsStart startDate={this.props.startDatepara} onSelect={this.handleChangeStarts} minDate={this.props.maxDateDisable} maxDate={this.props.minDateDisable} />
                    &nbsp; <FormattedMessage id="KC0092" /> &nbsp;
                     <DatePicker locale={datePickerLocale} customInput={<CustomInput stringID="KC0835"/>} placeholderText={reactIntl.formatMessage({ id: 'KC0835' })} onChangeRaw={(e) => this.handleChangeRaw(e)} id="date_picker_end_id" showMonthDropdown showYearDropdown dropdownMode="select" selected={this.props.endDatepara} selectsEnd endDate={this.props.endDatepara} minDate={this.props.endminDateDisable} maxDate={this.props.endmaxDateDisable} onSelect={this.handleChangeEnds} />
                </div>
                <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} /> 
            </div>

        )
    }
}
function mapStateToProps(state) {

    return {
        currentDate: state.showDefaultDateReducer.currentDate
    }
}

export default connect(mapStateToProps)(DropDownListLink);

