import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import ErrorMoal from '../../../../modal/error-model';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';

class FrymasterMenuSelectFrypots extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            selected_frypot: [1],
            menuName: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.menuRedirect = this.menuRedirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSaveFrypotButtonClick = this.onSaveFrypotButtonClick.bind(this);
        this.onFrypotSelect = this.onFrypotSelect.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let selectedCategory = KCStore.getItem("selectedCategory");
            let selectedCategoryObj = JSON.parse(selectedCategory);

            let categoryDetails = KCStore.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            let categoryDetail = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);

            this.setState({
                menuName: selectedCategoryObj.categoryName,
                selected_frypot: categoryDetail.shared_frypots ? categoryDetail.shared_frypots : [1]
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    onFrypotSelect = (e, frypots) => {
        let selected_frypot = [];
        selected_frypot = this.state.selected_frypot;
        if (frypots !== 1) {
            if (e.target.className === "") {
                selected_frypot.push(frypots);
            }
            else {
                selected_frypot = selected_frypot.filter(d => d !== frypots);
            }
        }
        this.setState({
            selected_frypot: selected_frypot
        });
    }

    menuRedirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            isDeleteCategory: false,
            errorCookbookName: ""
        })
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        let path = "/frymasterRecipeEditor";
        if (libraryDetails.isEdit) {
            path = "/libraryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/frymasterMenuEditor"
        });
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: this.props.history.location.previouspath,
            isEdit: false,
            previouspath: "/frymasterMenuSelectFrypots"
        });
    }

    onSaveFrypotButtonClick = () => {
        let selectedCategory = KCStore.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);

        let categoryDetails = KCStore.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
       
        categoryDetailsObj[foundIndex].shared_frypots = this.state.selected_frypot;
        KCStore.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));

        this.props.history.push({
            pathname: "/frymasterMenuAddFinalReview",
            isEdit: false,
            previouspath: "/frymasterMenuSelectFrypots"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { loaderIconVisible, menuName, selected_frypot, error_modal } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");

        return (
            <React.Fragment>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>{menuName}
                                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01 fullWidth"> 
                                                    <ul> 
                                                        <li>
                                                            <div class="rcTypeConfrmatn">
                                                            <p><FormattedMessage id="KC1398"/></p>
                                                            <div class="rcSchedulerList"> 
                                                                <span className={selected_frypot.includes(1) ? "active" : ""} onClick={(e) => this.onFrypotSelect(e, 1)}>1</span>
                                                                <span className={selected_frypot.includes(2) ? "active" : ""} onClick={(e) => this.onFrypotSelect(e, 2)}>2</span>
                                                                <span className={selected_frypot.includes(3) ? "active" : ""} onClick={(e) => this.onFrypotSelect(e, 3)}>3</span>
                                                                <span className={selected_frypot.includes(4) ? "active" : ""} onClick={(e) => this.onFrypotSelect(e, 4)}>4</span> 
                                                            </div> 
                                                            </div>
                                                        </li>
                                                    </ul>   
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onSaveFrypotButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterMenuSelectFrypots)
