import {ORG_CATEGORY_DETAILS_REQUEST,ORG_CATEGORY_DETAILS_SUCCESS,ORG_CATEGORY_DETAILS_FAIL} from '../../../../actions/navigation/organizationmanagement/organizationCategoryDetailsActions';
const initialState = {
    loaded: false,
    loading: false,
    OrganizationCategoryDetailsInformation: {'customer_group_category': []},
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case ORG_CATEGORY_DETAILS_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                
            }
        case ORG_CATEGORY_DETAILS_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                OrganizationCategoryDetailsInformation: action.OrganizationCategoryDetailsInformation,
               
            }
        case ORG_CATEGORY_DETAILS_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: staticSingleElementData,

            }
        default:
            return state
    }
}

const staticSingleElementData = {
    "customer-group": {
        "cust_gp_id": 6,
        "parent_group_id": 17,
        "group_name": "Welbilt Kitchencare ",
        "category_id": 5,
        "unit_assignable": 1,
        "dynasty": "2,20,23,17,6",
        "dynastyAttributes": "{\r\n  \"location_id\": \"103\",\r\n  \"location_name\": \"Welbilt Kitchencare\",\r\n  \"location_type\": \"Hotel\",\r\n  \"region\": \"East\",\r\n  \"country\": \"United States\",\r\n\"state\":\"Florida\",\r\n  \"city\": \"Tampa\",\r\n  \"street\": \"1505 N Dale Mabry Hwy\",\r\n  \"lat\": 27.94742,\r\n  \"long\": -82.45878,\r\n  \"subLocation\": \"reception\"\r\n}"
    },
    "image-path": "1"
}
