import axios from 'axios';
import { MediaManagementAPI, BaseService} from '../../../service/api';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import  http from '../../../service/httpService';
import KCStore from '../../../utils/kcStorage';
const HTTP_METHOD = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE'
}

function uploadMediaToCloud(url, postData, uploadedfile, mediaId, mediaIds) {

  return new Promise((resolve, reject) => {
    let token = KCStore.getItem('Access-Token');
    http({
      url: BaseService.root + url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        mediaId : mediaIds
      },
      data: {}
    }).then(response => {
      let s3url  = response.data.message.url;
      let Uploadeds3url = s3url.split('?');
      Uploadeds3url = Uploadeds3url[0];
      

      // var xhr = new XMLHttpRequest();
      // xhr.open("PUT", s3url);
      // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
      // xhr.onloadend =  (res) => {
        //  if(res.target.status == 200){
           

          let arr = response.data.message.key.split('.');   
          let fileExt = arr && arr.pop();       
          let mediaids = `${arr.join('')}`;
           postData.s3Url  = Uploadeds3url;
           postData.mediaId = mediaids;
           postData.crc = response.data.message.CRCForPackage ? response.data.message.CRCForPackage : "0";

                http({
                method:HTTP_METHOD.POST,
                url:`${BaseService.root}${MediaManagementAPI.getMediaBrandCustomer}`,
                data: postData,
                headers: {
                  'Content-Type': 'application/json', 
                  'Authorization': token,
                  'basecountry': BASECOUNTRY_ID
                }
              }).then(res =>{
                let { data } = res;
                let { httpCode, responseCode, message } = data;
              if(httpCode === "HTTP_200"){                          
                resolve(message);
                }
                else{
                  reject(res);
                }
              }).catch(err => {
                reject(err);
              });
            // }
      // };
      // xhr.onerror = (err) =>{
      //           reject(err);
      // };
      // xhr.send(uploadedfile);
    }).catch(err => {
      reject(err);
    });
  });
}

export default uploadMediaToCloud;
