import React, { useEffect, useRef } from 'react';


const LiveDiagnosticModal = ({ children, show, backdropStyle, status }) => {
    const modalRef = useRef(null);
    useEffect(
        () => {
            if (show) {
                modalRef.current.classList.add('kc-ui-custom-live-visible');
            }
            else {
                modalRef.current.classList.remove('kc-ui-custom-live-visible');
            }
        },
        [
            show
        ]
    );
    return (
        <React.Fragment>
            <div ref={modalRef} style={backdropStyle} className={`kc-ui-custom-live-modal-wrap kc-ui-custom-live-modal-wrap-${status}`}>
                {children}
            </div>
        </React.Fragment>
    );
};

export default LiveDiagnosticModal;