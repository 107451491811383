import {LOCATION_REQUEST,LOCATION_SUCCESS,LOCATION_FAIL} from '../../../../actions/listView/locations/withinlocationAction';

const initialState = {
    loaded: false,
    loading: false,
    withinlocationInformation: { }    
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
                loaded: false,
                isServerActionComplete : action.isCascadeActionRequest
            }
        case LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                withinlocationInformation: action.withinlocationInformation,
                isServerActionComplete : true
            }
        case LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                errormsg: 'Server Error',
                isServerActionComplete : true
            }
        default:
            // return {state:[]}
            return state
    }
}