import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import { CustomDropDownList } from '../../controls/customDropDownList';
import { getOverallPanUtilization } from '../../../actions/unitDashboard/overAllPanUtilizationAction';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';
import KCStore from '../../../utils/kcStorage';


// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'MM-DD-YYYY');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'MM-DD-YYYY');
let unitID = "";
let brandID = "";
let currentUnit;

class OverallPanUtilization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            locationInformation: {},
            deviceInformation: {},
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            startDateOnTime: "",
            endDateOnTime: "",
            datapickflag: false
        }
        this.handleChangeStartOnTime = this.handleChangeStartOnTime.bind(this);
        this.handleChangeEndOnTime = this.handleChangeEndOnTime.bind(this);
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        this.props.getOverallPanUtilization("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);

    }

    componentDidUpdate = async (prevProp, prevState) => {
        if (this.props.globalDropdownFilter !== "Year" && this.props.globalDropdownFilter !== "Quarter") {
            if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
                this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
                this.handleUtilization(this.props.globalDropdownFilter,'OverallPan');
                await this.props.handleDropdownChangeEnd();
            } else {
                if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                    if (prevState.endDateOnTime !== this.props.globalDropdownFilterEndDate) {
                        this.handleChangeEndOnTime(this.props.globalDropdownFilterEndDate);
                        await this.props.handleDropdownChangeEnd();
                    }
                    if (prevState.startDateOnTime !== this.props.globalDropdownFilterStartDate) {
                        this.handleChangeStartOnTime(this.props.globalDropdownFilterStartDate);
                        await this.props.handleDropdownChangeEnd();
                    }
                }
            }
        }
    }

    //start Date Change Handler
    handleChangeStartOnTime(onTimeDate) {
        if(this.state.startDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            startDateOnTime: onTimeDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleUtilization(selectedtext, 'OverallPan');
        });
    }

    //end Date Change Handler
    handleChangeEndOnTime(onTimeDate) {
        if(this.state.endDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            endDateOnTime: onTimeDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleUtilization(selectedtext, 'OverallPan');
        });
    }

    //dropdown Handler
    onDropDownHandler(e, type = '') {
        const {nodeText: text } = e;
        this.setState({type : text, datapickflag: text === 'Date Range', startDateOnTime: '', endDateOnTime: ''})
        this.handleUtilization(text, type);
    }

    handleUtilization(text, type) {
        currentUnit = this.props.currentUnit;
        const { startDateOnTime, endDateOnTime } = this.state;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            if (type === "OverallPan") {
                todaysDateFormatted = format(endDateOnTime, 'YYYY-MM-DD');
                weekDateFormatted = format(startDateOnTime, 'YYYY-MM-DD');
            }
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }

        if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") &&
            (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.props.getOverallPanUtilization(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
        }
    }

    render() {

        const { overallPanUtilizationInfo } = this.props;
        const overAllPanInformation = overallPanUtilizationInfo.overAllPanUtilizationResponse ? overallPanUtilizationInfo.overAllPanUtilizationResponse : {};
        let overAllPanModel = overAllPanInformation.content;
        let utilizationValue = 0;
        let commonFilterConsumptionValue = null;
        let customFilterWidget = null;
        const isTrend = _.isUndefined(overAllPanModel) ? false : _.isObject(_.find(overAllPanModel, el => el.key === 'trend'));
        utilizationValue = overAllPanModel && overAllPanModel[0] ? overAllPanModel[0].value : "0.0%";

        let overAllPanWidget = null;
        if (Array.isArray(overAllPanModel) && overAllPanModel.length) {

            commonFilterConsumptionValue = (<div className="valH1Div"> {overAllPanModel[0].value + "%"} {(isTrend && this.state.type !== "Date Range") && (<span className={overAllPanModel[2].value === '1' ? "valH1Span valHigh" : overAllPanModel[2].value === '0' ? "valH1Span valLow" : "valH1Span"}> &nbsp;</span>)}</div>);

            overAllPanWidget = (
                <div class="colmDiVal">
                    { commonFilterConsumptionValue}
                    { (isTrend && this.state.type !== "Date Range") && (<div class="valH5Div">
                        { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }: {overAllPanModel[1].value + "%"}
                    </div>)}
                </div>
            )
        } else {
            customFilterWidget = (<div className="valH1Div"> { utilizationValue}{(isTrend && this.state.type !== "Date Range") && (<span className={utilizationValue === '1' ? "valH1Span valHigh" : utilizationValue === '0' ? "valH1Span valLow" : "valH1Span"}> &nbsp;</span>)}</div>);

            overAllPanWidget = (
                <div class="colmDiVal">
                    { customFilterWidget}
                    { (this.state.type !== "Date Range") && (<div class="valH5Div">
                        { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }:  { utilizationValue}
                    </div>)}
                </div>
            )
        }
        // Merco Over All Pan Utilization Widget
        let OverallPanUtilizationWidget = null;
        OverallPanUtilizationWidget = (<div class="colmDi">
            <h4><FormattedMessage id='KC0354'/>
                    <span className="cornerIcon"></span>
                <CustomDropDownList translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDateOnTime} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate}
                    endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateOnTime} handleChangeStartProps={(date) => this.handleChangeStartOnTime(date)} handleChangeEndProps={(date) => this.handleChangeEndOnTime(date)}
                    OnChange={(e) => this.onDropDownHandler(e, "OverallPan")} filterType={this.state.type} />
            </h4>
            {overAllPanWidget}
        </div>)

        return (
            <React.Fragment>
                <div className="colm4D colm4D3">
                    {OverallPanUtilizationWidget}
                </div>
            </React.Fragment>
        )
    }
};
function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        overallPanUtilizationInfo: state.overAllPanUtilizationResponse,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrentUnit, getCurrentLocation, getOverallPanUtilization }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(OverallPanUtilization);
