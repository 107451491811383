import {REGION_REQUEST,REGION_SUCCESS,REGION_FAIL} from '../../../../actions/listView/locations/regionAction';

const initialState = {
    loaded: false,
    loading: false,
    regionInformation: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case REGION_REQUEST:
            return {
                ...state,
                loading: true,
                loaded: false,
                isServerActionComplete : action.isCascadeActionRequest
               
            }
        case REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                regionInformation: action.regionInformation,
                isServerActionComplete : true
            }
        case REGION_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                errormsg: 'Server Error',
                isServerActionComplete : true
            }
        default:            
            return state 
    }
}