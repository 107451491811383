import { UnitInfo } from '../../service/api';
import http from '../../service/httpService';
export const MODEL_REQUEST = 'MODEL_REQUEST';
export const MODEL_SUCCESS = 'MODEL_SUCCESS';
export const MODEL_FAIL = 'MODEL_FAIL';

export function getModelInfo (unitId, brandID) {
    return dispatch => {
        dispatch({
            type: MODEL_REQUEST
        })
       
        http
            .get(`${UnitInfo.root}${unitId}/${brandID}/device-info`,
            {
              headers: {
                'content-type': 'application/json',
                'filterparams' : 'spaceDetails'
              },data:{}
            })
            .then(response => {
                dispatch({
                    type: MODEL_SUCCESS,
                    deviceInformation: response.data, 
                })
            })
            .catch(error => {
                dispatch({
                    type: MODEL_FAIL,
                   
                })
            })
    }
}