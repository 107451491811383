import React, { PureComponent } from 'react';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import {UNIT_ID, UNIT_BRAND_ID} from '../../../utils/appConstants';
import { FormattedMessage } from 'react-intl';
import { reactIntl } from '../../../locale/locale-provider';

import KCStore from '../../../utils/kcStorage';

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
// let unitID = "";
// let brandID = "";
// let currentUnit;

class GaugeChart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			gaugeChartInformation: [],
      loading: true,
      eventName: "",
      text: "0"
		}
	}

	componentDidMount() {
		this.getReportData();
	}

	getReportData() {
        // currentUnit = this.props.properties.currentUnit;
        // currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        // unitID = currentUnit.UNITID;
        // brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;

        const header = {
                'Content-Type': 'application/json',
                'unitid' : UNIT_ID,
                'reqDate' : todaysDateFormatted,
                'showtrend' : "false"
            };

        let url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + UNIT_BRAND_ID}`;
        this.getData(url, header).then(response => {
          this.renderChartData(response.gaugeChartInformation);
        }).catch(error => {
			    console.log(error);
        });
	}

	getData(url, header) {
		return new Promise((resolve, reject) => {
			http
				.get(url,
					{
						headers: header,
						data: {}
					})
				.then(response => {
					resolve({
						gaugeChartInformation: response.data
					})
				})
				.catch(error => {
					reject({
						gaugeChartInformation: {}
					})
				});
		});
	}

	renderChartData = (data) => {

        let config = data.config;
        let values = data.data;

        this.setState ({
          eventName: data.eventName,
          text: values[0].text
        });

        config.map(item => { item.color = (item.color === "Green" ? "#4c842d" : item.color === "Amber" ? "#f2a93c" : item.color === "Red" ? "#b72b35" : "#4c842d" ); });

       let highestValue = Math.max.apply(Math, config.map(function(o) { 
          return o.end;  
        }))

		    var chart = am4core.create(this.props.widgetName.trim(), am4charts.GaugeChart);
          chart.innerRadius = am4core.percent(88);

          var axis = chart.xAxes.push(new am4charts.ValueAxis());
          axis.min = 0;
          axis.max = highestValue;
          axis.strictMinMax = true;
          axis.renderer.radius = am4core.percent(10);
          axis.renderer.inside = true;
          axis.renderer.line.strokeOpacity = 0;
          axis.renderer.ticks.template.strokeOpacity = 0;
          axis.renderer.ticks.template.length = 0;
          axis.renderer.grid.template.disabled = true;
          axis.renderer.labels.template.disabled = true;
          axis.renderer.labels.template.fontSize = 12;
          axis.renderer.labels.template.fill = am4core.color("#b4b5b7");

          /**
           * Axis for ranges
           */
          var axis2 = chart.xAxes.push(new am4charts.ValueAxis());
          axis2.min = 0;
          axis2.max = highestValue;
          axis2.renderer.innerRadius = -10;
          axis2.strictMinMax = true;
          axis2.renderer.labels.template.disabled = true;
          axis2.renderer.ticks.template.disabled = true;
          axis2.renderer.grid.template.disabled = true;

          let i = 0;
          config.map(element => {
              var range = `range`+i;
              range = axis2.axisRanges.create();
              if(i === 0) {
                range.value = 0;
              } else {
                range.value = element.start;
              }
              range.endValue = element.end;
              range.axisFill.fillOpacity = 1;
              range.axisFill.fill = am4core.color(element.color);
              i++;
            });

           var label = chart.radarContainer.createChild(am4core.Label);
           label.isMeasured = false;
           label.fontSize = 14;
           label.fill = am4core.color("#b4b5b7");
           label.x = am4core.percent(50);
           label.y = 24;
           label.horizontalCenter = "middle";
           label.verticalCenter = "bottom";
           label.text = data.eventName === "Door Hinge" ? reactIntl.formatMessage({ id: 'KC0281' }) : reactIntl.formatMessage({ id: 'KC0280' });

            var labelRange0 = axis.axisRanges.create();
            labelRange0.value = 0;
            labelRange0.label.text = 0;
            labelRange0.grid.stroke = am4core.color('#b4b5b7');
            labelRange0.grid.strokeWidth = 0;
            labelRange0.grid.strokeOpacity = 0;
            labelRange0.label.disabled = false;
            labelRange0.label.inside = true;
            labelRange0.label.fill = labelRange0.grid.stroke;
            labelRange0.tick.length = 10;
            labelRange0.label.radius = -60;

          let j = 1;
          config.map(element => {
            var labelRange = `labelRange`+j;
            labelRange = axis.axisRanges.create();
            labelRange.value = element.end;
            labelRange.label.text = element.endtext;
            labelRange.grid.stroke = am4core.color('#b4b5b7');
            labelRange.grid.strokeWidth = 0;
            labelRange.grid.strokeOpacity = 0;
            labelRange.label.disabled = false;
            labelRange.label.inside = true;
            labelRange.label.fill = labelRange.grid.stroke;
            labelRange.tick.length = 10;
            labelRange.label.radius = -60;
            j++;
        });

          var hand = chart.hands.push(new am4charts.ClockHand());
          hand.axis = axis2;
          hand.innerRadius = am4core.percent(4);
          hand.fill = am4core.color("#cccccc");
          hand.stroke = am4core.color("#cccccc");
          hand.startWidth = 7;
          hand.endWidth = 0;
          hand.pin.disabled = false;
          hand.value = values[0].value < highestValue ? values[0].value : highestValue;
	}

	render() {
        let {eventName, text} = this.state;
        let days = "183";
        let hours = "23";
        let mins = "45";
        let htmlContent = null;
        let newNumberFormat =  <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
        if (KCStore.getItem("thousandSeparator") === ".") {
          newNumberFormat = <NumberFormat value={text} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }

        if (eventName === "Door Hinge")
        {
          htmlContent = (<div class="valH1Div">{newNumberFormat}</div>);
        }
        else {
          try {
            days = text !== "" && text.split(" ")[0] !== undefined ? text.split(" ")[0].replace("days", "") : "0";
            hours = text !== "" && text.split(" ")[1] !== undefined ? text.split(" ")[1].replace("h", "") : "0";
            mins = text !== "" && text.split(" ")[2] !== undefined ? text.split(" ")[2].replace("m", "") : "0";
            htmlContent = (<div class="valH1Div timeDiv">{days}<span class="TimeRangeSpan">{days < 2 ? <FormattedMessage id="KC1885"/> : <FormattedMessage id="KC1886"/>}</span> {hours}<span class="TimeRangeSpan"><FormattedMessage id="KC1898"/></span> {mins}<span class="TimeRangeSpan"><FormattedMessage id="KC1899"/></span></div>);  
          }
          catch (err) {
              days = 0;
              hours = 0;
              mins = 0;
          }
        }
        
		return (
			<div class="colm4D colm4D1">
                <div class="colmDi">
                    <h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID} /> : this.props.widgetName}</h4>
                    <div class="colmDiVal">
                    <div id={this.props.widgetName.trim()} class="ovenOnTimeGauge gaugeGraphSec"></div> 
                    <div class="gaugeName">{this.props.gaugeNameStringID ? <FormattedMessage id={this.props.gaugeNameStringID} /> : this.props.gaugeName}</div>
                        {htmlContent}
                        <div class="valH5Div">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

export default GaugeChart;
