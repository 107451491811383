import http from '../../../service/httpService';
import {LocationInfo} from '../../../service/api';
export const CONNECTIVITY_STATUS_REQUEST = 'CONNECTIVITY_STATUS_REQUEST';
export const CONNECTIVITY_STATUS_SUCCESS = 'CONNECTIVITY_STATUS_SUCCESS';
export const CONNECTIVITY_STATUS_FAIL = 'CONNECTIVITY_STATUS_FAIL';

export function getconnectivityStatusInfo (req) {
    return dispatch => {
        dispatch({
            type: CONNECTIVITY_STATUS_REQUEST
        })
          
        http.get(`${LocationInfo.connectivityStatusURL}`,
        {
            headers: {
                'Content-Type':'application/json',
                'cgid': '',                        
            },
            data:{}
            }
            )
            .then(response => {
                dispatch({
                    type: CONNECTIVITY_STATUS_SUCCESS,
                    connectivityStatusInformation: response.data,
                    
                })
            })
            .catch(error => {
                dispatch({
                    type: CONNECTIVITY_STATUS_FAIL,
                    error
                })
            })
    }
}