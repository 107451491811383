import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import ErrorMoal from '../../../../../modal/error-model';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';

class FrymasterCookingProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            scrollImage: "true",
            numProductImages: "",
            error_modal: {
                open: false,
                message: ""
            },
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                scrollImage: ObjSettingsFile.cooking.product_display.scroll_image === 1 ? "true" : "false",
                numProductImages: ObjSettingsFile.cooking.product_display.number_of_product_images,
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true,
        });
        let { scrollImage, numProductImages } = this.state;
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.cooking.product_display) {
            ObjSettingsFile.cooking.product_display = {};
        }
        if (numProductImages > 12 || numProductImages < 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1458"
                }
            });
        } else {
            ObjSettingsFile.cooking.product_display.scroll_image = scrollImage === "true" ? 1 : 0;
            ObjSettingsFile.cooking.product_display.number_of_product_images = Number(numProductImages);
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            KCStore.setItem("libraryData", strSettingsFile);
            this.props.history.push(`/frymasterCooking`);
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterCooking`);
    }

    handleOnSliderChange = (value, stateKey) => {
        this.setState({
            [stateKey]: value
        })
    }

    onToggleChange = (e, stateKey) => {
        this.setState({
            [stateKey]: e.toString()
        });
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = KCStore.getItem("selectedbrandID");

        const { scrollImage, numProductImages, error_modal } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Cooking Product Display Header Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Cooking Product Display Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Cooking Product Display Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1459"/>
                                </div>


                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li class="width245px"><FormattedMessage id="KC1460"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                placeholder=""
                                                                onChange={(e) => {
                                                                    const re = /^[0-9\b]+$/;
                                                                    // if value is not blank, then test the regex
                                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                                        this.setState({ numProductImages: e.target.value })
                                                                    }
                                                                }}
                                                                value={numProductImages}
                                                                class="form-control" />
                                                        </li>
                                                    </ul>
                                                    <ul class="borderNone">
                                                        <li class="width245px"><FormattedMessage id="KC1461"/></li>
                                                        <li>
                                                            <div class="btn-group-toggle" data-toggle="buttons">
                                                                <span class={scrollImage === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1405"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'scrollImage')}
                                                                    checked={scrollImage === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={scrollImage === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>

                                </div>
                            </div>
                            {/* Frymaster Cooking Product Display Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} message={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterCookingProduct)
