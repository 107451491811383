import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../service/httpService';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { UserProfile } from '../../service/api';
import authService from '../../service/authService';
import fConsts from '../../utils/featureConstants';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import KCStore from '../../utils/kcStorage';

class TimeNumberFormatedit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			success_modal: {
				open: false,
				message: "",
			},
			error_modal: {
                open: false,
                message: ""
            },
			loaderIconVisible: false,
			timeFormat:"12",
			numberFormat:",",
		};
	}

	componentDidMount = () => {
		this.getDetails();
		document.body.style.backgroundColor = "#F2F2F2";
	}

	getDetails = () => {
        this.setState({ loaderIconVisible: true });        
        let url = UserProfile.timenumberpreference;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
				'customer_id': KCStore.getItem("customerId"),
				'email':KCStore.getItem("user"),
            }, data: {}
        }).then(response => {
            if (response && response.data) {
				for(let i=0; i< response.data.data.length ; i++){
					const ele = response.data.data[i];
					this.setState({
						[ele.preference_name]: ele.preference_value
					})
				}
                this.setState({
                    loaderIconVisible: false,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

	updateDetails = () => {
		const { formatMessage } = this.props.intl;

		this.setState({ loaderIconVisible: true });
		const { timeFormat, numberFormat }= this.state;
        let reqData = {
			"user":{
				"customer_id": KCStore.getItem("customerId"),
				"email": KCStore.getItem("user"),
				"user_preferences": [
					{"preference_name":"timeFormat","preference_value":timeFormat},
					{"preference_name":"numberFormat","preference_value":numberFormat}
				],
			}			 
        };
										   
        let url = UserProfile.timenumberpreference;
        http.put(url, reqData,{
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
			KCStore.setItem("timeFormat", timeFormat);
            KCStore.setItem("thousandSeparator", numberFormat); 
            this.setState({
				loaderIconVisible: false,
                success_modal: {
                    open: true,
                    message:formatMessage({id: 'KC0146'})
                },
            }); 
			WoopraEvents(`${Constants.UPDATE_TIME}`)               
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response && err.response.data && err.response.data.message? err.response.data.message: formatMessage({id: 'KC1148'}), 
                },
            });
        });
    }

	onSuccessCloseModal = (e) => {
        this.setState({
            success_modal: {
                open: false
            }
        })
        this.getDetails();
    }

	closeModel = (err) => {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            }
        });
    }

	// Woopra Events
	trackEvent = (e) => {
		let title = e.trackTitle === "Time & Number Format" ? "Time and Number Format" : e.trackTitle;
		WoopraEvents(`${title}`);
	}


	render() {
		const { formatMessage } = this.props.intl;
		let { success_modal, error_modal,loaderIconVisible } = this.state;
		let userNotificationAuthFeature = authService.getFeatureByFeatureName(fConsts.Notifications);
		let myinformationEditOrViewLink;
		let assignedunitgroupsEditOrViewLink;
		let NotificationManagementEditOrViewLink;
		let isUserNotificationFormViewStatus;

		myinformationEditOrViewLink = "/myinformationEdit";
		assignedunitgroupsEditOrViewLink = "/assignedunitgroupsEdit";
		if (userNotificationAuthFeature && userNotificationAuthFeature.feature_active) {
			isUserNotificationFormViewStatus = userNotificationAuthFeature.is_editable ? false : true;
			NotificationManagementEditOrViewLink = "/NotificationManagement";
		}
		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress --> */}

					<div id="page-content-wrapper">
						<div className="userManagementWrapper">
							<div className="userProfileHeader">
								<ul>
									<li>
										<Link to={myinformationEditOrViewLink} title={formatMessage({id: 'KC1916'})} onClick={(e)=> {e.trackTitle="My Information";this.trackEvent(e)}} ><FormattedMessage id='KC1916' /></Link>
										<Link to={assignedunitgroupsEditOrViewLink} title={formatMessage({id: 'KC1917'})} onClick={(e)=> {e.trackTitle="Assigned Unit Groups";this.trackEvent(e)}}><FormattedMessage id='KC1917' /></Link>
										{NotificationManagementEditOrViewLink && <Link to={{ pathname: NotificationManagementEditOrViewLink, state: { fromViewAccountpageValue: false } }} title={formatMessage({id: 'KC0109'})} onClick={(e)=> {e.trackTitle="Notification Management";this.trackEvent(e)}}><FormattedMessage id='KC0109' /></Link>}
										<Link to="/timeNumberFormatedit" title={formatMessage({id: 'KC0110'})} className="active"><FormattedMessage id='KC0110' /></Link>
										<Link to="/termsPricingCondition" title={formatMessage({id: 'KC2439'})} ><FormattedMessage id='KC2439' /></Link>
									</li>
								</ul>
							</div>
							<form className="timeNumberFormatForm">
								<div className="timeNFTable">
									<ul className="tHead">
										<li><FormattedMessage id='KC0142' /></li>
										<li><FormattedMessage id='KC0143' /></li>
										<li>&nbsp;</li>
									</ul>
									{
										[
											{ label: "Time", multiLangId:"KC0144", firstRadio: '24H', firstRadioStringID: 'KC2210', secondRadio: '12H AM/PM', secondRadioStringID: 'KC2211', setStateKey:'timeFormat', firstRadiovalue: '24', secondRadioValue:'12' }, 
											{ label: "Numbers",multiLangId:"KC0145", firstRadio: '1.000,0', secondRadio: '1,000.0', setStateKey:'numberFormat', firstRadiovalue: '.', secondRadioValue:',' }
										].map((value, index) => {
											return (
												<ul className="tBody" key={index}>
													<li><FormattedMessage id={value.multiLangId} /></li>
													<li>
														<div className="customRadioBtn">
															<input id={`time${value.firstRadio}`} onClick={()=>this.setState({[value.setStateKey]:value.firstRadiovalue})} type="radio" name={value.label} value={value.firstRadio} checked={value.label === 'Time' ? this.state.timeFormat === '24' : this.state.numberFormat === '.'} />
															<label for={`time${value.firstRadio}`}>{value.firstRadioStringID? <FormattedMessage id={value.firstRadioStringID}/>: value.firstRadio}</label>
														</div>
													</li>
													<li>
														<div className="customRadioBtn">
															<input id={`time${value.secondRadio}`} type="radio" onClick={()=>this.setState({[value.setStateKey]:value.secondRadioValue})} name={value.label} value={value.firstRadio} checked={value.label === 'Time' ? this.state.timeFormat === '12' : this.state.numberFormat === ','} />
															<label for={`time${value.secondRadio}`}>{value.secondRadioStringID? <FormattedMessage id={value.secondRadioStringID}/>: value.secondRadio}</label>
														</div>
													</li>
												</ul>
											)
										})
									}
									
									<button type="button" className="btn seveBtn" onClick={(e) => this.updateDetails(e)}><FormattedMessage id='KC0123' /></button>
								</div>
							</form>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader hideUserProfileEditBtn={true} headerName="User Profile" headerNameStringID="KC0106" previousPage="User Profile" activeClass="UserProfileNav" />
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TimeNumberFormatedit));