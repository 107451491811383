import React from "react"
import { FormattedMessage, injectIntl } from 'react-intl';
import FormikController from "../../UI_Components/FormikController"
import { Form } from "formik";

const AddUserForm = (props) => {
    const { countryCodeList = [], languageList=[], formDisabled } = props;
    const { formatMessage } = props.intl;

    const responseVal = [{Id: '', language_code: '', name: formatMessage({ id: 'KC2577' }), locale: ''}];
    if (languageList.length>0){            
        languageList.map((item, i) =>
            responseVal.push(item),
        )
    };
    
    return (
        <Form className={formDisabled ? "disabled" : ""}>
            <ul>
                <li>
                    <label className="formlabel"><FormattedMessage id={'KC2542'} /> *</label>
                    <FormikController
                        className="input-group"
                        inputClass= {"form-control formnew"}
                        isRequired = {true}
                        placeholder="Jane"
                        control="input"
                        type="text"
                        errorClass="required"
                        name="firstName"
                    />
                </li>
                <li>
                    <label className="formlabel"><FormattedMessage id={'KC2543'} /> *</label>
                    <FormikController
                        className="input-group"
                        inputClass="form-control formnew"
                        isRequired={true}
                        placeholder="Doe"
                        control="input"
                        type="text"
                        errorClass="required"
                        name="lastName"
                    />
                </li>
                <li>
                    <label className="formlabel"><FormattedMessage id={'KC0136'} /> *</label>
                    <FormikController
                        className="input-group"
                        inputClass="form-control formnew"
                        isRequired={true}
                        placeholder="janedoe@gmail.com"
                        control="input"
                        type="email"
                        errorClass="required"
                        name="email"
                    />
                </li>
                <li style={{ display: formDisabled ? 'none' : 'block' }}>
                    <label className="formlabel"><FormattedMessage id={'KC2567'} /> *</label>
                    <FormikController
                        className="input-group"
                        inputClass="form-control formnew"
                        isRequired={true}
                        placeholder="janedoe@gmail.com"
                        control="input"
                        type="email"
                        errorClass="required"
                        name="retypeEmail"
                    />
                </li>
                <li>   
                    <label className="formlabel"><FormattedMessage id={'KC0920'} /> *</label>                 
                    <FormikController
                        inputClass="form-control formnew"
                        isRequired={true}
                        control="select"
                        referalKey={'name'}
                        referalValue={'Id'}
                        options={responseVal}                        
                        name="languageList"
                        errorClass="required"
                    />                 
                </li>
                <li>
                    <div className="formcodeandpno">
                        <div>
                            <label className="formlabel"><FormattedMessage id={'KC2568'} /></label>
                            <FormikController
                            inputClass="form-control countryCode_user"
                            placeholder={'+91'}
                            control="select"
                            referalKey={'code'}
                            options={countryCodeList}
                            default=""
                            name="countryCode"
                        />
                        </div>
                        <div>
                            <label className="formlabel"><FormattedMessage id={'KC2554'} /></label>
                            <FormikController
                            inputClass="form-control phoneNumber_user"
                            placeholder={'1234567890'}
                            control="input"
                            type="text"
                            name="phoneNumber"
                        />
                        </div>
                        
                    </div>
                </li>
                <li className="infoLi">
                    <p className="formInfoIcon"><FormattedMessage id={'KC0599'} /></p>
                </li>
            </ul>
        </Form>

    )
}

export default injectIntl(AddUserForm);

