import http from '../../../service/httpService';
import { CUSTOMER_ID, BRAND_ID,BASECOUNTRY_ID } from '../../../utils/appConstants';
import { SoftwareManagementAPI } from '../../../service/api';

import KCStore from '../../../utils/kcStorage';

export const SOFTWARE_AVAILABLE_UPDATES_REQUEST = 'SOFTWARE_AVAILABLE_UPDATES_REQUEST';
export const SOFTWARE_AVAILABLE_UPDATES_SUCCESS = 'SOFTWARE_AVAILABLE_UPDATES_SUCCESS';
export const SOFTWARE_AVAILABLE_UPDATES_FAIL = 'SOFTWARE_AVAILABLE_UPDATES_FAIL';

export function getSoftwareAvailableUpdates(req) {
    return async dispatch => {
        dispatch({
            type: SOFTWARE_AVAILABLE_UPDATES_REQUEST
        })

        const customerGroupDetails = KCStore.getItem("groupDetails") ? JSON.parse(KCStore.getItem("groupDetails")) : {};
        await http.get(SoftwareManagementAPI.softwareAvailableUpdatesUrl,
            {
                headers: {
                    'Content-Type': 'application/json',
                    brandId: KCStore.getItem("unitBrandId")?KCStore.getItem("unitBrandId"):BRAND_ID,
                    customer_group: customerGroupDetails && customerGroupDetails.id ? customerGroupDetails.id : "",
                    'cid': KCStore.getItem("customerId") ? KCStore.getItem("customerId") : '',
                    'cgid': KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : '',
                },
                data:{}
            })
        .then(response => {
            dispatch({
                type: SOFTWARE_AVAILABLE_UPDATES_SUCCESS,
                softwareAvailableUpdatesInformation: response.data,
             })
        })            
        .catch(error => {
                dispatch({
                    type: SOFTWARE_AVAILABLE_UPDATES_FAIL,
                    error
                })
            })
    }
}


