import { MediaMenuManagement } from '../api';
import http from '../httpService';

export const getMediaAPIUrl = (unitId) => {
    let apiUrl = `${MediaMenuManagement.root}${unitId}`;
    return apiUrl;
}

export const getMediaLibrary = async(url) => {
    let response ={};
    try{
       let result =  await http.get(url, {
        headers: {
        'content-type': 'application/json'},
        data:{}
    });
       response = result.data.mediaLibraryList;
       return response
    }
    catch(err){
        return response;
    }
}
