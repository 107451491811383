import React, { Component } from 'react';
 import {Router, browserHistory} from 'react-router';
import Modal from "react-responsive-modal";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class SuccessMoal extends Component {

    nextPath(path) {
		this.props.history.push(path);
	}
constructor(props){
    super(props);

        this.onCloseModal = this.onCloseModal.bind(this);
    }

    state = {
        open: false
    };

    onCloseModal = (e) => {
      this.props.onFLSuccessModel();
    };

    render() {
        const { open, message } = this.props;
        return (
            <div style={styles}>
                <Modal open={open} showCloseIcon={false} center onClose={()=>{}}>
                    <div className="modal fade SignInSuccessModal show" data-keyboard="true" data-backdrop="static" id="SignInSuccessModal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog modal-md loginModal">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="singINConfirmation">{message}</div>
                                </div>
                                <div className="modal-footer textAlignCenter">
                                    <button className="btn btn-secondary signINconfirmationOKBtn" data-dismiss="modal" type="button" onClick={()=>{this.onCloseModal()}}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default SuccessMoal;