import http from '../../service/httpService';
import { BaseService, ReportManagement } from '../../service/api';
export const HOLDING_TABLE_REQUEST = 'HOLDING_TABLE_REQUEST';
export const HOLDING_TABLE_SUCCESS = 'HOLDING_TABLE_SUCCESS';
export const HOLDING_TABLE_FAIL = 'HOLDING_TABLE_FAIL';

export function getHoldingTableData(type, fromdate, todate, deviceId, brandId, widgetId = '', holder_id = '') {
    let url;
    let filterType;
    let UNIT_BRAND_ID = 3;
    switch (type) {
        case 'Week':
            filterType = 'weekly';
            break;
        case 'Year':
            filterType = 'yearly';
            break;
        case 'Day':
            filterType = 'daily';
            break;
        case 'Quarter':
            filterType = 'quarterly';
            break;
        case 'Date Range':
            filterType = 'custom';
            break;
        default:
            filterType = 'monthly';
    }

    if (brandId === "MERCO") {
        url = `${BaseService.root}${ReportManagement.holdingDataPerPan}`;
    }
    else if (brandId === "FRYMASTER-UHCTHD") {
        url = `${BaseService.root}${ReportManagement.uhcHoldingDataPerPan}`;
    }
    else {
        url = `${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
    }
    
    return dispatch => {
        dispatch({
            type: HOLDING_TABLE_REQUEST
        })

        const header = {
            'Content-Type': 'application/json',
            'unitid': deviceId,
            'reqdate': fromdate,
            'filtertype': filterType,
            'brandid': brandId
        }

        if (brandId === "FRYMASTER") {
            header.showtrend = "false";
            delete header.brandid;
            if (filterType === 'weekly' || filterType === 'custom' ) {
                delete header.reqdate;
                header.startdate = todate;
                header.enddate = fromdate;
            }
            if (holder_id !== "") {
                header.holderid = holder_id;
            }
        }
        else if (filterType === 'weekly' || filterType === 'monthly' || filterType === 'custom' ) {
            header.reqdate = fromdate;
            header.startdate = todate;
            header.enddate = fromdate;
        }

        http.get(url,
            {
                headers: header,
                data: {}
            })
            .then(response => {
                dispatch({
                    type: HOLDING_TABLE_SUCCESS,
                    holdingTableResponse: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: HOLDING_TABLE_FAIL,
                    error
                })
            })
    }
}