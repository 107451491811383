import React, { Fragment } from 'react';
import moment from '../../utils/momentHelper.js';
import { FormattedMessage } from 'react-intl';

const TandCList = ({ tacList: listItem, isView = false, itemClickedInList }) => {    
    const normalTandC = listItem.filter(c => c.applicableFor === 'user');
    const readOnlyTandC = listItem.filter(c => c.applicableFor === 'admin');
    return (
        <div className="TCWrapperL withDarkBg">
            {/* {isView ? null : <h2><FormattedMessage id="KC2897" /></h2>} */}
            {normalTandC?.length ? normalTandC.map((item, i) =>
                <Fragment key={`${item.id}_${i}`}>     
                    {isView || i > 0 ? null : <h2><FormattedMessage id="KC2897" /></h2>}
                    <div className={item.selected ? "TCWrapperLW active" : "TCWrapperLW"} onClick={() => itemClickedInList(item)}>
                        <div className='effectiveDateWrapper'>
                            <div className="effectiveDate">
                                <FormattedMessage id="KC2896" />: {moment.GetDateTimeByFormat(item.effectiveDate, 'LL')}
                            </div>
                            {isView ? null :
                                <div>
                                    <button className={`btn ${item.accepted ? 'acceptedBtn' : 'unacceptedBtn'}`} type="button">{item.accepted ? <FormattedMessage id="KC2911" /> : <FormattedMessage id="KC2912" />}</button>
                                </div>}
                        </div>
                        <div className="KCLicenseA">{item.title}</div>
                    </div>
                    {i === 0 && !isView ? <hr></hr> : null}
                </Fragment>
            ) : null}

            {readOnlyTandC?.length ? readOnlyTandC.map((item, i) =>
                <Fragment key={`${item.id}_${i}`}>
                    {isView || i > 0 ? null : <h2><FormattedMessage id="KC2936" /></h2>}
                    <div className={item.selected ? "TCWrapperLW active" : "TCWrapperLW"} onClick={() => itemClickedInList(item)}>
                        <div className='effectiveDateWrapper'>
                            <div className="effectiveDate">
                                <FormattedMessage id="KC2896" />: {moment.GetDateTimeByFormat(item.effectiveDate, 'LL')}
                            </div>
                            {isView || item?.applicableFor === 'admin' ? null :
                                <div>
                                    <button className={`btn ${item.accepted ? 'acceptedBtn' : 'unacceptedBtn'}`} type="button">{item.accepted ? <FormattedMessage id="KC2911" /> : <FormattedMessage id="KC2912" />}</button>
                                </div>}
                        </div>
                        <div className="KCLicenseA">{item.title}</div>
                    </div>                    
                </Fragment>
            ) : null}
        </div>
    );
}
export default TandCList;
