import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getCountry } from './../../../actions/listView/locations/countryAction';
import { getFilterbyCountries } from './../../../actions/listView/locations/filterbyAction';
import  LocationHeader  from './../../layoutSection/allLocationHeaderLayout';

let FindSelectedCountryName = "";
let checkEnabledListValue = "";
let checkEnabledList = [];
let alreadySelectedFilterValuesList = [];

class FilterByCountry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cuntryList: "",
			selectedList:[],
			search:'',
		}
		this.GetSlectedCountryName = this.GetSlectedCountryName.bind(this);
		this.callCountryAction = this.callCountryAction.bind(this);
	}

	updatesearch(e)
	{
		this.setState({search:e.target.value});
	}

	componentDidMount() {
		 
	   let { state : previousState }  = this.props.location;
	   let { prevSelectedFilterValue : p_selectedCountryList }  = previousState;
	   if(p_selectedCountryList && p_selectedCountryList.length>0){
		   this.setState({
			selectedList : p_selectedCountryList
		   });
	   }
		
		this.props.getCountry();
		FindSelectedCountryName = "";
		checkEnabledList=[];
	}

	GetSlectedCountryName(e, item) {
		let isChecked = e.target.checked;
 
		this.state.selectedList.find(c =>{ if(c.cust_gp_id === item.cust_gp_id){
                return c.checked = isChecked; 
		}});

		this.setState({
				selectedList:this.state.selectedList
		});

		this.props.getFilterbyCountries(this.state.selectedList);
		// var d = document.getElementById("spannavNotifiStatus");
		// d.className = "navNotifiStatus";
	}

	callCountryAction() {	  
		    this.state.selectedList && this.state.selectedList.length >0 && this.props.getFilterbyCountries(this.state.selectedList);	
	}

	render() {
		let checkvalue = [];
		let countryInformationDetails = [];
		let { selectedList : _countryList  } = this.state;
		const {countryInfo} = this.props;
		// let countryInfo = dummyData;

		if (countryInfo && countryInfo.length > 0) {
			if(_countryList && _countryList.length>0){
                 for (let index = 0; index < _countryList.length; index++) {
					 for (let k = 0; k < countryInfo.length; k++) {
						  if(countryInfo[k].cust_gp_id === _countryList[index].cust_gp_id)
						  {
							if(_countryList[index].checked){
								countryInfo[k].checked=true;
								break;
							} 
						  }
					 }					
				 }
				 this.state.selectedList = countryInfo;
					_countryList = this.state.selectedList;
			}else{
				this.state.selectedList = countryInfo;
				_countryList = this.state.selectedList;
			}	 
		}

		const search=this.state.search;
			const searchedItemInFilter=_countryList.filter(
				(contact)=>{
					return contact.group_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ;				
				}
			);
			_countryList=searchedItemInFilter;
		
			let previouspage = "/dashboardLocations";
			if (this.props.location.state != undefined)
			{
				previouspage = this.props.location.state.prevPath.previouspage;
			}

		return (
			<div>
				<div id="wrapper" className="disableNavLink">
					<div class="toggleTransparent"></div>
					<div class="loadingProgress">
						<div class="loadingProgressIn"></div>
					</div>
					<LocationHeader />
					<div class="navFilterWrapper" style={{ display: 'block' }}>
						<div id="CountryFilter">
							<div class="filterHeader">
								<ul>
									<li>
										<Link onClick={this.callCountryAction.bind(this)} to={{ pathname: '/locationsView/filterBy/', state: { paramvalue: this.state.cuntryList, selectedList: this.state.selectedList, prevPath: {previouspage} } }} title="Back" class="backIcon" id="backToFilterListDiv">&nbsp;</Link>
									</li>
									<li>
										Country
									</li>
									<li>
										<Link to={previouspage} title="Finish">Finish</Link>
										{/* <Link to={previouspage} title="Filter by" class="cancelIcon cancelFilterListDiv">&nbsp;</Link> */}
									</li>
								</ul>
							</div>
							<div class="filterListOuter">
								<input class="form-control headerFilterSearch" value={this.state.search} onChange={this.updatesearch.bind(this)} type="text" placeholder="Search" />
								<div class={_countryList.length > 3 ? 'countryCheckbox withMorethanThree' : 'countryCheckbox'}> 
									{Object.keys(_countryList).map((item, i) => (
										<div key={_countryList[i].cust_gp_id} class="customCheckbox customCheckboxWlabel">
											<input  id={_countryList[i].cust_gp_id} class="chkGroupName" value={_countryList[i].group_name} checked={_countryList[i].checked} onChange={(e) => this.GetSlectedCountryName(e, _countryList[i])} name="testgroupName" type="checkbox" />
											<label  for={_countryList[i].cust_gp_id}>{_countryList[i].group_name}</label>
										</div>
									))}
								</div>
							</div>
						</div>

					</div>

				</div>

			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		countryInfo: state.countryInformation.countryInformation,
		FindSelectedCountryName: state.FindSelectedCountryName,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getCountry, getFilterbyCountries }, dispatch);

}

export default connect(mapStateToProps, mapDispatchToProps)(FilterByCountry);
