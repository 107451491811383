import { GET_DATA, SET_DATA } from './type';

export const getUserDataModel = (UserModel) => dispatch =>{
      dispatch({
          type : GET_DATA,
          payload : UserModel
      });
  }

  export const setUserDataModel = (UserModel) => dispatch =>{
    dispatch({
        type : SET_DATA,
        payload : UserModel
    });
}