import http from '../../../service/httpService';
import * as _ from 'lodash';
import { BRAND_ID } from '../../../utils/appConstants';
import {LocationInfo} from '../../../service/api';
import KCStore from '../../../utils/kcStorage';

export const MODEL_REQUEST = 'MODEL_REQUEST';
export const MODEL_SUCCESS = 'MODEL_SUCCESS';
export const MODEL_FAIL = 'MODEL_FAIL';

export function getModel (req = '',filterKey,isCascadeActionRequest) {
    return dispatch => {
        dispatch({
            type: MODEL_REQUEST,
            isCascadeActionRequest : isCascadeActionRequest ? true : false
        })
        // Apply model filter Brand , Type and Model
        let typeNameList = '';
        let selectedBrandId = BRAND_ID;
        const ffModel = KCStore.getItem(filterKey);
        const filterModel1 = JSON.parse(ffModel) || [];
        if (filterModel1.length) {
            // Selected brand id to display as brand id header param
            let brandDataSource = _.find(filterModel1, { field_name: 'brand' }).dataSource
                selectedBrandId = _.isArray(brandDataSource) && brandDataSource.length ? brandDataSource.map(el => el.ID).join(',') : selectedBrandId;
                
            let dataSource = _.find(filterModel1, { field_name: 'machineType' }).dataSource
                typeNameList = _.isArray(dataSource) && dataSource.length ? dataSource.map(el => el.ID).join(',') : '';
                
            if (typeNameList === 'undefined')
            {
                typeNameList = '';
            }
        }
        

        http
            .get(`${LocationInfo.filters}`,
                {headers: {
                        'Content-Type':'application/json',
                        'Type': 'Product',
                        'brandid': selectedBrandId,
                        'typenamelist': typeNameList,
                        'cid': KCStore.getItem("customerId"),                   
                    },
                    data:{}})
            .then(response => {
                dispatch({
                    type: MODEL_SUCCESS,
                    modelInformation: response.data                    
                })
            })
            .catch(error => {
                dispatch({
                    type: MODEL_FAIL,
                    error
                })
            })
    }
}
