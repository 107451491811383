const AdmZip = require('adm-zip');
var request = require('request');

export const readArchivesFromCompressedResponse = (responseData) => {
    
        return new Promise((resolve, reject) => {
            if (responseData.fetchFromS3) {
                let file_path = responseData.presignedS3URL;
                let Data;
                request.get({ url: file_path, encoding: null }, (err, res, body) => {
                    var zip = new AdmZip(body);
                    var zipEntries = zip.getEntries();

                    zipEntries.forEach(function (a) {
                        Data = JSON.parse(a.getData().toString("utf8"))
                    })
                    resolve(Data);
                })
            }
            else {
                resolve(responseData)
            }
        })  
}
export const errorSnackbarOption = {
    position: 'bottom-right',
    style: {
        backgroundColor: 'red',
        color: '#ffffff',
        fontSize: '18px',
        height: '60px',
        fontWeight: 'bold',
        marginBottom: '5%'
    },
    closeStyle: {
        color: '#ffffff',
        fontSize: '16px',
    },
}