import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

class TroubleShooting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
		};
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
	}

	render() {
		let { loaderIconVisible } = this.state;
		let { dataToTrobleShoot, selectedItem } = this.props.location.state;
		dataToTrobleShoot = dataToTrobleShoot[selectedItem - 1];
		let managerActionDetails;
		if (dataToTrobleShoot && dataToTrobleShoot.manager_action) {
			managerActionDetails = dataToTrobleShoot.manager_action.split('\\n');
		}

		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress --> */}
					<div id="page-content-wrapper">
						<div class="errorWrapper">
							<div class="errorTopHeader">
								<ul>
									<li>
										<Link to="/serviceManagement" title="Back" class="backIcon"></Link>
									</li>
									<li>
										{dataToTrobleShoot && dataToTrobleShoot.error_code && dataToTrobleShoot.description ? dataToTrobleShoot.error_code +": "+ dataToTrobleShoot.description : ""}
                        			</li>
									<li>
										<Link to="/serviceManagement" title="Cancel" class="cancelIcon"></Link>
									</li>
								</ul>
							</div>
							<div class="errorHeader">								
								<li title="Trouble Shooting" class="active">Trouble Shooting</li>
								{/* <Link to="/troubleShooting" title="Trouble Shooting" class="active">Trouble Shooting</Link> */}
								{/* <Link to="/serviceNotes" title="Trouble Shooting" >Service Notes</Link>
								<Link to="/serviceReport" title="Trouble Shooting">Service Report</Link> */}
							</div>

							<div class="serviceInfoListOuter">
								{/* <div class="serviceInfoListR">
									<h5>Error Frequency</h5>
									<div class="errorFrequencyTable">
										<ul class="tHead">
											<li>&nbsp;</li>
											<li>Date</li>
											<li>Time</li>
											<li>Error Status</li>
										</ul>
										<ul>
											<li><span class="errorStatusLevel alertLevelIcon"></span></li>
											<li>November 2, 2019</li>
											<li>hh:mm</li>
											<li>non-critical</li>
										</ul>										
									</div>
								</div> */}
								
								<div class="serviceInfoListL">
									<h5>Manager Action</h5>
									{/* <div class="boxDiv">
										<a href="javascript:void(0)" title="Play Video for Trouble Shooting" class="playVideo">Play Video for Trouble Shooting</a>
									</div> */}
									<div class="managerActionTable">
										<ul class="tHead">
											<li>&nbsp;</li>
											<li>Action</li>
											<li>&nbsp;</li>
										</ul>

										{managerActionDetails && managerActionDetails.map((item, i) => {
											return (
												<ul>
													{/* {i > 8 ? <li> i+1 </li> : <li> 0{i + 1}</li>} */}
													<li> </li>
													<li>
														<h6>{item}</h6>
														{/* <p>{item}</p> */}
													</li>
													{/* <li><a href="javascript:void(0)" class="editIcon">&nbsp;</a></li> */}
													<li> </li>
												</ul>
											)
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};

export default TroubleShooting;