import { brandLocations, brandUnits } from './imgContants'
const brandsLogo = brandLocations;

export const LocationBuilder = (LocationsValue) => {
  
    LocationsValue = LocationsValue && LocationsValue.length> 0? LocationsValue :  [];

    LocationsValue.map(item => {
        let pickBrand = brandsLogo.find(board => board.name === item.location_type);
        if (pickBrand != null) {
            item.Brand_Logo = pickBrand.img;
            item.Brand_Alt = pickBrand.alt;
            item.Brand_Title = pickBrand.title;
        }
        else {
            item.Brand_Logo = require('../images/store/Convenience.svg').default;
        }
    });

    return LocationsValue;
}

export const SetLocationImage = (LocationsValue) => {
   
        let pickBrand = brandsLogo.find(board => board.name === LocationsValue.location_type);
        if (pickBrand != null) {
            LocationsValue.Brand_Logo = pickBrand.img;
            LocationsValue.Brand_Alt = pickBrand.alt;
            LocationsValue.Brand_Title = pickBrand.title;
        }
        else {
            LocationsValue.Brand_Logo = require('../images/store/Convenience.svg').default;
        }
        
      return LocationsValue;
}

