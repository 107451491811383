import axios from 'axios';
import api from '../../service/api';
import http from '../../service/httpService';
export const LOCATION_REQUEST = 'LOCATION_REQUEST';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const LOCATION_FAIL = 'LOCATION_FAIL';


export function getLocation (serialNumber) {
    return dispatch => {
        dispatch({
            type: LOCATION_REQUEST
        })

        http
            .get(api.locationInfoService + serialNumber, {data:{}})
            .then(response => {
                dispatch({
                    type: LOCATION_SUCCESS,
                    locationFilterInformation: response.data.body.location
                })
            })
            .catch(error => {
                dispatch({
                    type: LOCATION_FAIL,
                    error
                })
            })
    }
}
