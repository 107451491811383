import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import 'bootstrap/dist/css/bootstrap.min.css';
import LocationsMapView from './locationsMapView';
import AllLocationHeaderLayout from '../layoutSection/allLocationHeaderLayout';
import appconstants from '../../utils/appConstants';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import constant from '../../woopraConfig/Woopraconstant';

const SiteTree = ({ intl, history }) => {
    const mapWrapperRef = useRef(null);

    useEffect(() => {
        const updateDimensions = () => {
            const windowHeight = window.innerHeight;
            const pageTabHeader = document.querySelector(".pageTabHeader")?.offsetHeight || 0;
            const mapWrapperHeight = (windowHeight - pageTabHeader) - 70;
            if (mapWrapperRef.current) {
                mapWrapperRef.current.style.height = `${mapWrapperHeight}px`;
            }
        };

        window.addEventListener('resize', updateDimensions);
        updateDimensions();

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const locationEvent = () => {
        WoopraEvents(`${constant.MAP_VIEW_LOCATION}`);
    };

    const unitEvent = () => {
        WoopraEvents(`${constant.MAP_VIEW_UNIT}`);
    };

    return (
        <React.Fragment>
            <div id="wrapper">
                <AllLocationHeaderLayout activeClass="MapViewA" filterKey={appconstants.filterKey} history={history} />
                <div id="page-content-wrapper">
                    <div className="pageTabHeader marginTop2px">
                        <ul>
                            <li>
                                <Link to="/locationsMap" title={intl.formatMessage({ id: 'KC0044' })} className="locationsView active" onClick={locationEvent}><FormattedMessage id='KC0044' /></Link>
                            </li>
                            <li>
                                <Link to="/unitsMap" title={intl.formatMessage({ id: 'KC0045' })} className="unitsView" onClick={unitEvent}><FormattedMessage id='KC0045' /></Link>
                            </li>
                        </ul>
                    </div>
                    <div id="googleMap" style={{ backgroundColor: '#595959' }} ref={mapWrapperRef}>
                        <LocationsMapView />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default injectIntl(SiteTree);
