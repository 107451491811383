import React, { PureComponent } from 'react';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import NumberFormat from 'react-number-format';
import { format, addDays } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import { HolderList } from './holderList';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';
import { reactIntl } from '../../../locale/locale-provider';
import KCStore from '../../../utils/kcStorage';


// MLS change classificationValue
const classificationValueStringID = {
    'Poor': 'KC0434',
}

var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = null;
let currentUnit;

class SingleDataTrendFilterWidget extends PureComponent {
    constructor(props) {
        super(props);
        // const filterType = 'filterType_' + props.widgetID;
        // const startDate = 'startDate_' + props.widgetID;
        // const endDate = 'endDate_' + props.widgetID;
        // const holder_name = 'holder_name_' + props.widgetID;
        this.state = {
            filterType: 'Month',
            startDate:'',
            endDate: '',
            widgetInformation: {},
            currentValue: 0,
            priorValue:0,
            trend: -1,
            classificationValue: null,
            unitTime: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            holder_id: "",
            holder_name: "Fryer",
            datapickflag:false
        }

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount() {
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        this.setState({
            filterType: "Month",
        });
        this.getReportData("Month", todaysDateFormatted, weekDateFormatted);
    }

    getReportData(type, fromdate, todate, holder_id = '') {
        currentUnit = this.props.properties.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        let url = '';
        let filterType = '';
        let header = {};
        switch (type) {
          case 'Week':
              filterType = 'weekly';        
              break; 
            case 'Year':
                filterType = 'yearly';            
                break; 
            case 'Day':            
                filterType = 'daily';
                break;
            case 'Quarter':
                filterType = 'quarterly';            
                break; 
            case 'Date Range':
                filterType = 'custom';
                break; 
            default: 
                filterType = 'monthly';              
          }  

        if (brandID.toString() === "4") {
            if (this.props.widgetName === "TOTAL PRODUCTS PRODUCED" || this.props.widgetName === "AVG. PRODUCT COUNT PER DAY") {
                url = `${ReportManagement.countroot}`;
                if (filterType === 'weekly' || filterType === 'custom')
                {
                    header = {
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin':'*',
                        'unitid' : unitID,
                        'brandid' : brandID,
                        'startdate' : todate,
                        'enddate' : fromdate,
                        'filterType' : filterType
                    }; 
                }
                else
                {
                    header = {
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin':'*',
                        'unitid' : unitID,
                        'brandid' : brandID,
                        'reqdate' : fromdate,
                        'filterType' : filterType
                    }; 
                }
                if (this.props.widgetName === "AVG. PRODUCT COUNT PER DAY")
                {
                    header.op = 'avg';
                }
            }
        }
        else if (brandID.toString() === "1" || brandID.toString() === "6" || brandID.toString() === "3" || brandID.toString() === "9") {
            url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + brandID}`;

            if (filterType === 'weekly' || filterType === 'custom')
            {
                header = {
                    'Content-Type' : 'application/json',
                    'unitid' : unitID,
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filtertype' : filterType,
                    'showtrend' : "true"
                }; 
                if(filterType === 'custom')
                {
                    delete header.showtrend;
                    header.showtrend = "false";
                }
            }
            else
            {
                header = {
                    'Content-Type' : 'application/json',
                    'unitid' : unitID,
                    'reqDate' : fromdate,
                    'filtertype' : filterType,
                    'showtrend' : "true"
                };
            }
            if (KCStore.getItem("brandName") === "FRYMASTER" && holder_id !== "") {
                header.holderid = holder_id;
            } 
        }

        // Added for Frymaster McD CR
        const productName = KCStore.getItem("modelFamilyName");
        if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
            header.productModel = productName;
        }
        
        this.getData(url, header).then(response => {
          //this.renderChartData(response.widgetInformation);
            let currentValue = null;
            let priorValue = null;
            let trend = 0;
            let classificationValue = null;
            try {
                if (KCStore.getItem("brandName") === "CREM") {
                    currentValue = response.widgetInformation.currentConsumption;
                    priorValue = response.widgetInformation.lastConsumption;
                    trend = response.widgetInformation.trend;
                }
                else {
                    currentValue = response.widgetInformation && response.widgetInformation.content !== undefined && response.widgetInformation.content.length > 0 ? response.widgetInformation.content[0].value : 0;
                    priorValue = response.widgetInformation && response.widgetInformation.content !== undefined && response.widgetInformation.content.length > 1 ? response.widgetInformation.content[1].value : 0;
                    trend = response.widgetInformation && response.widgetInformation.content !== undefined && response.widgetInformation.content.length > 2 ? response.widgetInformation.content[2].value : 0;
                    if (response.widgetInformation.filtertype === "custom" && response.widgetInformation.content.length === 2) {
                        classificationValue = response.widgetInformation.content[1].value;
                    }
                    else if (response.widgetInformation.content.length === 4) {
                        classificationValue = response.widgetInformation.content[3].value;
                    }
                }
                
            } catch (error) {
                currentValue =  0;
                priorValue =  0;
                trend =  0;
            }
            this.setState({
                currentValue: currentValue,
                priorValue: priorValue,
                trend: trend,
                classificationValue: classificationValue,
                unitTime: response.widgetInformation.unitTime
            });
        }).catch(error => {
			    console.log(error);
        });
    }
    
    getData(url, header) {
		return new Promise((resolve, reject) => {
			http
				.get(url,
					{
						headers: header,
						data: {}
					})
				.then(response => {
					resolve({
                        widgetInformation: response.data,
                        filtertypeInfo: response.filtertype
					})
				})
				.catch(error => {
					reject({
						widgetInformation: {}
					})
				});
		});
	}

    handleChangeStart(date, mindate) {
        this.setState({
            startDate: date,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText: selectedtext };
            this.onDropDownHandler(e, 'HoldingData');
        });
    }

    handleChangeEnd(date) {
        this.setState({
            endDate: date,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText: selectedtext };
            this.onDropDownHandler(e, 'TotalDrinks');
        });
    }

    fetchData(holder_id, text) {
        if (KCStore.getItem("brandName") === "FRYMASTER") { 
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
            }
            else if (text === "Week") { 
                todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
                weekDateFormatted = format(addDays(todaysDate,- 6), 'YYYY-MM-DD');
            }
            else {
                todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            }
        }
        else {
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
            }
            else if (text === 'Week') {
                weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
                todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

                var startDateMonth = getMonth(new Date(weekDateFormatted));
                var endDateMonth = getMonth(new Date(todaysDateFormatted));

                if (startDateMonth !== endDateMonth)
                {
                    weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
                }
            }
            else {
                todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
                weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
            }
        }
        
        this.getReportData(text, todaysDateFormatted, weekDateFormatted, holder_id);
    }

    onDropDownHandler(e, type = '') {
        this.fetchData(this.state.holder_id, e.nodeText);
        this.setState({
            filterType: e.nodeText,
            datapickflag: e.nodeText === 'Date Range'
        });
    }

    onHolderSelection(e, type = '') {
        const holderid = e.target.text.replace("Frypot ","").replace("Fryer","")
        this.setState({ 
            holder_id: holderid,
            holder_name: e.target.text
        });
        this.fetchData(holderid, type);
    }

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (KCStore.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

    render() {
        let {currentValue, priorValue, trend, classificationValue, unitTime} = this.state;
        if (KCStore.getItem("timeFormat") === "24"){
            unitTime = unitTime ? moment(unitTime, 'hh:mm A').format('HH:mm'):"";
        } 
        let currentValueText = null;
        let priorValueText = null;
        let currentNum = null;
        let priorNum = null;
        let greatLevel = "valH5StatusDiv levelGreat";
        let goodLevel = "valH5StatusDiv levelGood";
        let poorLevel = "valH5StatusDiv levelPoor";

        if (this.props.isDecimal) {
            currentNum = currentValue;
            priorNum = priorValue;
            currentValueText = currentValue > 0 ? currentValue.toFixed(1) : currentValue;
            priorValueText = priorValue > 0 ? priorValue.toFixed(1) : priorValue;
        }
        else if((this.props.widgetID === "91" || this.props.widgetID === "107") && KCStore.getItem("brandName") === "GARLAND"){
            currentNum = currentValue;
            priorNum = priorValue;
            currentValueText = (currentValue > 0 && currentValue < 1) ? "<1" : (this.returnNumberFormat(Math.round(currentValue)));
            priorValueText = (priorValue > 0 && priorValue < 1) ? "<1" : (this.returnNumberFormat(Math.round(priorValue)));
        }
        else if(this.props.widgetID === "45" && KCStore.getItem("brandName") === "CONVOTHERM"){
            currentNum = currentValue;
            priorNum = priorValue;
            currentValueText = currentValue ? currentValue.toFixed(1) == 0 ? 0 : currentValue.toFixed(1) : 0;
            priorValueText = priorValue ? priorValue.toFixed(1) == 0 ? 0 : priorValue.toFixed(1) : 0;
        }
        else {
            currentNum = currentValue;
            priorNum = priorValue;
            currentValueText = (currentValue > 0 && currentValue < 1) ? "<1" : ((KCStore.getItem("brandName") === "GARLAND") && currentValue > 0) ? parseFloat(currentValue).toFixed(1) : (this.returnNumberFormat(Math.round(currentValue)));
            priorValueText = (priorValue > 0 && priorValue < 1) ? "<1" : ((KCStore.getItem("brandName") === "GARLAND") && priorValue > 0 ) ? parseFloat(priorValue).toFixed(1) : (this.returnNumberFormat(Math.round(priorValue)));
        }

        return (
            <React.Fragment>
                <div className={this.props.classValue} title={this.props.infoTextStringID ? reactIntl.formatMessage({ id: this.props.infoTextStringID }) : ""}>
                    <div className="colmDi">
                        <h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
                            &nbsp; <span className="red-txt">{/*errormsgTotalDrinks*/}</span><span className="cornerIcon"></span>                            
                            <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} 
                            maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} 
                            endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} 
                            handleChangeStartProps={(date) => this.handleChangeStart(date)} 
                            handleChangeEndProps={(date) => this.handleChangeEnd(date)} 
                            OnChange={(e) => this.onDropDownHandler(e, this.props.widgetName)} 
                            filterType={this.state.filterType} 
                            disableDayFilter={this.props.widgetName === "Avg. Oil Life (Days)" ? true : false} 
                            disableWeekFilter={this.props.widgetName === "Avg. Oil Life (Days)" ? true : false} 
                            minDaysRequired={this.props.widgetName === "Avg. Oil Life (Days)" ? 29 : -1} />
                            {(KCStore.getItem("brandName") === "FRYMASTER") && this.props.isHolder && <HolderList OnHolderChange={(e) => this.onHolderSelection(e, this.state.filterType)} />}
                        </h4>
                        <div className={this.props.widgetName === "FILTER CLEANINGS" ? "colmDiVal minheight155" : "colmDiVal"}>
                            <div className="valH1Div"> {currentValueText}{this.props.symbol ? this.props.symbol : ""} <span className={this.state.filterType === "Date Range" ? "displaynone" : (currentNum === priorNum && currentValueText !== "<1" && priorValueText !== "<1") ? "valH1Span" : trend === '1' ? "valH1Span valHigh" : trend === '0' ? "valH1Span valLow" : "valH1Span"}>{(KCStore.getItem("brandName") === "GARLAND") ? "" : unitTime ? unitTime.toLowerCase() : ''}&nbsp;</span></div>
                            <div className={this.state.filterType === "Date Range" ? "displaynone" : "valH5Div"}>
                                {mappingStringID[`prior ${this.state.filterType.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.filterType.toLowerCase()}`]}/> : ""}: {priorValueText}{this.props.symbol ? this.props.symbol : ""}
                            </div>
                            {this.props.isClassification ? <div className={(classificationValue === 'Great') ? greatLevel : (classificationValue === 'Good') ? goodLevel : (classificationValue === 'Poor') ? poorLevel : "greatLevel"}>
                                    {classificationValueStringID[classificationValue] ? <FormattedMessage id={classificationValueStringID[classificationValue]}/> : classificationValue} 
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SingleDataTrendFilterWidget;
