import {SMARTGROUP_REQUEST,SMARTGROUP_SUCCESS,SMARTGROUP_FAIL} from '../../../../actions/listView/locations/smartGroupAction';

const initialState = {
    loaded: false,
    loading: false,
    smartgroupInformation: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case SMARTGROUP_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                isServerActionComplete : false
               
            }
        case SMARTGROUP_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                smartgroupInformation: action.smartgroupInformation,
                isServerActionComplete : true
            }
        case SMARTGROUP_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
                isServerActionComplete : true

            }
        default:
            return state
    }
}