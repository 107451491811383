import React from "react";
import { FormattedMessage } from "react-intl";
import { timePeriodStringIDMap } from "../../locale/constant";

export function TimePeriodFormatter({ text }) {
    if (text) {
        const [time, period] = text.split(' ');
        return <React.Fragment>{time} <FormattedMessage id={timePeriodStringIDMap[period]}/></React.Fragment>;
    }
    return '';
}