import {AVERAGE_DRINKS_LIST_REQUEST,AVERAGE_DRINKS_LIST_SUCCESS,AVERAGE_DRINKS_LIST_FAIL} from '../../../actions/reportManagement/averageDrinksAction';
const initialState = {
    loaded: false,
    loading: false,
    averageDrinksInformation: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case AVERAGE_DRINKS_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case AVERAGE_DRINKS_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                averageDrinksInformation: action.averageDrinksInformation
            }
        case AVERAGE_DRINKS_LIST_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgAvgDrinks: 'Server Error',

            }
        default:
            return state
    }
}