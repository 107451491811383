import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import { getAverageHoldingCycles } from '../../actions/reportManagement/averageHoldingCyclesActions';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { UNIT_BRAND_ID, AVERAGE_HOLDING_CYCLES_WIDGET_ID, FRYMASTER_UHCTHD_AVERAGE_HOLDING_CYCLES_WIDGET_ID } from '../../utils/appConstants';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../locale/constant';

import KCStore from '../../utils/kcStorage';


// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;

class AverageHoldingCycles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            locationInformation: {},
            deviceInformation: {},
            sd: '',
            ed: '',
            startDate: '',
            endDate: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            datapickflag: false
        }
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_AVERAGE_HOLDING_CYCLES_WIDGET_ID : AVERAGE_HOLDING_CYCLES_WIDGET_ID;
        this.props.getAverageHoldingCycles("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
    }

    componentDidUpdate = async (prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleAverageHoldingCycle(this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStart(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //start Date Change Handler
    handleChangeStart = (avgHoldingDate) => {
        if(this.state.startDate === avgHoldingDate){
            return;
        }
        this.setState({
            startDate: avgHoldingDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleAverageHoldingCycle(selectedtext);
        });
    }

    //end Date Change Handler
    handleChangeEnd(avgHoldingDate) {
        if(this.state.endDate ===  avgHoldingDate){
            return;
        }
        this.setState({
            endDate: avgHoldingDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleAverageHoldingCycle(selectedtext);
        });
    }

    //dropdown Handler
    onDropDownHandler(e) {
        const { nodeText:text } = e;
        this.setState({ type: text, datapickflag: text === 'Date Range', startDate: '', endDate: ''})
        this.handleAverageHoldingCycle(text);
    }

    handleAverageHoldingCycle(text) {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;

        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }

        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_AVERAGE_HOLDING_CYCLES_WIDGET_ID : AVERAGE_HOLDING_CYCLES_WIDGET_ID;
            this.props.getAverageHoldingCycles(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
        }
    }

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (KCStore.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

    render() {
        const { avgHoldingCyclesInfo } = this.props;
        const avgHoldingCycleInformation = avgHoldingCyclesInfo.averageHoldingCyclesResponse ? avgHoldingCyclesInfo.averageHoldingCyclesResponse : {};
        let avgHoldingCycleModel = avgHoldingCycleInformation.content;
        let commonFilterConsumptionValue = null;
        let commonFilterPriorValue = null;
        const isTrend = _.isUndefined(avgHoldingCycleModel) ? false : _.isObject(_.find(avgHoldingCycleModel, el => el.key === 'trend'));

        let avgHoldingCyclesWidget = null;
        if (Array.isArray(avgHoldingCycleModel) && avgHoldingCycleModel.length) {
            commonFilterConsumptionValue = (<div className="valH1Div">{this.returnNumberFormat(Math.round(avgHoldingCycleModel[0].value))} {(isTrend && this.state.type !== "Date Range") && (<span className={avgHoldingCycleModel[2].value === '1' ? "valH1Span valHigh" : avgHoldingCycleModel[2].value === '0' ? "valH1Span valLow" : "valH1Span"}> {avgHoldingCycleInformation.unitTime} &nbsp;</span>)}</div>);
            commonFilterPriorValue = (isTrend && this.state.type !== "Date Range") && (<div className="valH5Div">
                {mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }:  {this.returnNumberFormat(Math.round(avgHoldingCycleModel[1].value))}
            </div>)


            avgHoldingCyclesWidget = (
                <div className="colmDiVal">
                    { commonFilterConsumptionValue}
                    { commonFilterPriorValue}
                </div>
            )
        }
        // Merco Average Holding Cycles Widget
        let avgHoldingCyclesPerDayWidget = null;
        avgHoldingCyclesPerDayWidget = (<div className="colmDi">
            <h4><FormattedMessage id="KC0366"/>
                    <span className="cornerIcon"></span>
                <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.type} />
            </h4>
            {avgHoldingCyclesWidget}

        </div>)

        return (
            <React.Fragment>
                <div className="colm6D colm6D2">
                    {avgHoldingCyclesPerDayWidget}
                </div>
            </React.Fragment>
        )
    }
};
function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        avgHoldingCyclesInfo: state.averageHoldingCyclesResponse,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrentUnit, getCurrentLocation, getAverageHoldingCycles }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AverageHoldingCycles);
