import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import SuccessMoal from '../modal/success-model';
import ErrorModal from '../modal/error-model';
import auth from '../../service/authService';
import _ from 'lodash';
import $ from 'jquery';
import http from '../../service/httpService';
import { BaseService } from '../../service/api';
import { loginAPIUtils } from './loginUtils';
import { setLocale } from "../../actions/localeAction/localeAction";
import { injectIntl } from 'react-intl';


class SSOLoginSuccess extends Component {

    nextPath(path) {
        this.props.history.push(path);
    }

    onSuccessCloseModal() {
        this.props.history.push(`/dashboardLocations`);
    }

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isVerified: false,
            newpassword: '',
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            loaderIconVisible: false,
            fields: {},
            errors: {},
            isFormValidated: false,
            saveData: {},
            showhidepassword: false,
            type: 'password',
        };
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/`);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#0d3968";
        $("body").addClass("loginBg");
        let code = this.getParameterByName("code");
        let { match } = this.props;
        const { params } = match;
        // let cognitoError = this.getParameterByName("error");
        if (code && params.uuid) {
            await this.loginAuthentication(code, params.uuid);
        }
        else {
            this.setState({
                error_modal: {
                    open: true,
                    message: "Un-authorized access."
                },
            })
        }
    }

    getParameterByName = (name) => {
        const url = window.location.href;
        console.log("url: " + window.location.href);
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?#&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    loginAuthentication = async (code, uuid) => {
        this.setState({ loaderIconVisible: true });
        
        return new Promise(async (resolve, reject)=>{
			 auth.getSSOLogin({code, uuid}).then(async res => {   
				let { status, data: loginData } = res;
				if (status === 200) {
					const payLoadData = {
						'userId': loginData.username,
						"roleId": loginData.role,
						"customerId": loginData.customerId,
						"content": "required",
					}
					const tandcDetails = await this.proceedTaCValidation(payLoadData);
					const inputData = { username: loginData.username, from: "login", loginData, res, tandcDetails, rememberme: false };
					if (tandcDetails.body.policyAccepted) {
						await loginAPIUtils(inputData, this.props.history.push, this.settingLocale);
					} else {
						resolve(this.props.history.push({
							pathname: "/termsandconditions",
							inputData: inputData
						}));
					}
				} else {
					reject({
						res: {
							data: res.data
						}
					});
				}		
			}).catch(error => {
				this.setState({ loaderIconVisible: false })
				let errorResponse = error.response;
            if (errorResponse && (errorResponse.status === 400 || errorResponse.status === 500)) {
                let responseMessage = errorResponse.data.message || "";
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        message: responseMessage,
                        open: true
                    }
                });
            }
            else {
                this.setState({ loaderIconVisible: false });
            }
			});
		  });
    }

    settingLocale = (data) => {
		this.props.setLocale(data);
	};

    proceedTaCValidation = async (postdata) => {
		return new Promise(async (res, reject) => {
			let url = BaseService.getTandCdetails;
			await http.post(url, postdata,
				{
					headers: {
						'Content-Type': 'application/json',
					}
				}
			).then(response => {
				if (response && response.data) {
					res(response.data)
				}
			}).catch(err => {
				reject(err);
			});
		})
	}


    render() {
        const { success_modal, error_modal, loaderIconVisible } = this.state;
        return (
            <React.Fragment>
                <div className="loginBg">
                    <div id="adminWrapper">
                        <div id="loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
                            <div className="loadingProgressIn"></div>
                        </div>
                        <nav className="navbar navbar-expand-lg navbar-dark bgAdmin">
                            <div className="collapse navbar-collapse" id="navbarContent">
                                <div className="navbar-header">
                                    <Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>
                                </div>
                            </div>
                        </nav>

                        <div id="page-wrapper">
                            <div class="welcomText">Kitchen Connect Portal</div>
                            <div class="LoginBtnWrapper">
                                <div id="loginBtnFormID">
                                    <div class="loginButton">
                                        <ul>
                                            <li>&nbsp;</li>
                                            <li>
                                                <div class="loginIWrapper">
                                                    {/* <button class="btn btn-secondary singINBtn" type="button" data-toggle="modal" data-target="#SignInModal" >							  </button> */}
                                                </div>
                                            </li>
                                            <li>&nbsp;</li>
                                        </ul>
                                        <ul>
                                            <li>
                                                {/* <Link to="#" data-toggle="modal" data-target="#SignInModal" title="Create Account">Create Account</Link> */}
                                            </li>
                                            <li>
                                                <div class="loginIWrapper">
                                                    {/* <Link to="/existingUserLogin" title="Use Existing Account" class="useExistingAccount">Use Existing Account</Link>  */}
                                                    {/* <Link to="#" data-toggle="modal" data-target="#SignInModal1" title="Create Account">Create Account</Link> */}
                                                    {/* <Link to="#" title="Language" class="languageSel"></Link> */}
                                                </div>
                                            </li>
                                            <li>&nbsp;</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                            <ErrorModal open={error_modal.open} message={error_modal.message} onCloseModel={() => this.closeModel()} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setLocale
	}, dispatch);
}
export default connect(_, mapDispatchToProps)(injectIntl(SSOLoginSuccess));
