import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';
import Modal from "react-responsive-modal";
import { FormattedMessage } from 'react-intl';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class ConfirmModal extends Component {

    nextPath(path) {
        this.props.history.push(path);
    }
    constructor(props) {
        super(props);
        this.onAccessModal = this.onAccessModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    state = {
        open: false
    };

    onAccessModal = (e) => {
        this.props.onAccessNowModel();
    };

    onCloseModal = (e) => {
        this.props.onAccessLaterModel();
    };


    render() {
        const { open, message, stringID, isHtml = false, disabled = 'false', newMessageFormat = false } = this.props;
        let successMsg = (
            <div className="modal fade SignOutModal show" data-keyboard="true" data-backdrop="static" id="SignInSuccessModal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                <div className="modal-dialog modal-md loginModal">
                    <div className="modal-content">
                        <div className="modal-body">
                            {isHtml ? <div className="singOutConfirmation" dangerouslySetInnerHTML={{ __html: message }} /> : <div className="singOutConfirmation">{stringID ? <FormattedMessage id={stringID} /> : message}</div>}
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn btn-secondary signOutYesBtn" data-dismiss="modal" type="button" onClick={() => { this.onCloseModal() }}><FormattedMessage id="KC0021" /></button>
                            <button className={disabled ? "btn btn-secondary signOutNoBtn disabled" : "btn btn-secondary signOutNoBtn"} data-dismiss="modal" type="button" onClick={() => { this.onAccessModal() }}><FormattedMessage id="KC0558" /></button>
                        </div>
                    </div>
                </div>
            </div>
        );
        if (newMessageFormat) {
            successMsg = (
                <div>
                    <div className="modal fade confirmationRMModal show" data-keyboard="true" data-backdrop="static" id="confirmationOkModa1" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div class="modal-dialog modal-md loginModal">
                            <div class="modal-content">
                                <div class="modal-header modal-header-danger">
                                    <FormattedMessage id='KC2174'/>
                                </div>
                                <div class="modal-body">                                    
                                    <div class="reportStatusModal">
                                        <span class="rmStatusIcon"><img src={require("../../images/icons/iconDeleteReport.svg").default}/></span>
                                        <h6><FormattedMessage id='KC0524'/> {message}?</h6>
                                        <div class="smallText">{stringID ? <FormattedMessage id={stringID} /> : message}</div>
                                    </div>
                                </div>
                                <div class="modal-footer textAlignCenter">
                                    <button class="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button" onClick={() => { this.onCloseModal() }}><FormattedMessage id="KC0021" /></button>
                                    <button class="btn btn-secondary deleteYesBtn" id="deleteYesBtn" data-dismiss="modal" type="button" onClick={() => { this.onAccessModal() }}><FormattedMessage id="KC0558" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div style={styles}>
                <Modal open={open} showCloseIcon={false} center onClose={() => { }}>
                    {successMsg}
                </Modal>
            </div>
        );
    }
}
export default ConfirmModal;