import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getRegion } from './../../../actions/listView/locations/regionAction';
import { getFilterbyRegions } from './../../../actions/listView/locations/filterbyAction';
import  LocationHeader  from './../../layoutSection/allLocationHeaderLayout';


class FilterByRegion extends Component {	
	constructor(props) {
		super(props);	
		this.state ={
			regionList:"",
			selectedList:[]	,
			search:'',		
		}				
		this.GetSelectedRegionName=this.GetSelectedRegionName.bind(this);	
		this.callRegionAction = this.callRegionAction.bind(this);
	}
	
	componentDidMount() {
		let { state : previousState }  = this.props.location;
		let { prevSelectedFilterValue : prev_selectedRegionList }  = previousState;
		if(prev_selectedRegionList && prev_selectedRegionList.length>0){
			this.setState({
		 selectedList : prev_selectedRegionList
		});
		}
		
		let selectedCountryList  =  this.props.selectedCountries	? 	 this.props.selectedCountries	:[];
		let selectedCgids =[];
		selectedCountryList.map(c =>{ if(c.checked ===  true){
			return selectedCgids.push(c.cust_gp_id)
        }});
		this.props.getRegion(selectedCgids.join(',')); 
	}
	
	GetSelectedRegionName(e, item) {
		let isChecked = e.target.checked;
		
		this.state.selectedList.find(c =>{ if(c.cust_gp_id === item.cust_gp_id){
			return c.checked = isChecked 
		}});
		
		this.setState({
			selectedList:this.state.selectedList
		});
		
		this.props.getFilterbyRegions(this.state.selectedList);
		// var d = document.getElementById("spannavNotifiStatus");
		// d.className = "navNotifiStatus";
	}

	callRegionAction() {
		this.state.selectedList && this.state.selectedList.length >0 && this.props.getFilterbyRegions(this.state.selectedList);
	}

	updatesearch(e)
	{
		this.setState({search:e.target.value});
	}
	
	render() { 
			let { selectedList : _regionList  } = this.state;			
			  const {regionInfo} = this.props;
			  // let regionInfo = dummyData;
			
			if (regionInfo && regionInfo.length > 0) {
				if(_regionList && _regionList.length>0){
									 for (let index = 0; index < _regionList.length; index++) {
						 for (let k = 0; k < regionInfo.length; k++) {
								if(regionInfo[k].cust_gp_id === _regionList[index].cust_gp_id)
								{
								if(_regionList[index].checked){
									regionInfo[k].checked=true;
									break;
								} 
								}
						 }
						}
						//this.state.selectedList = regionInfo;
						this.setState({
							selectedList : regionInfo,
						})
						_regionList = this.state.selectedList;
				}else{
					//this.state.selectedList = regionInfo;
					this.setState({
						selectedList : regionInfo,
					})
					_regionList = this.state.selectedList;
				}	 
			}

			const search=this.state.search;
			const searchedItemInFilter=_regionList.filter(
				(contact)=>{
					return contact.group_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ;				
				}
			);
			_regionList=searchedItemInFilter;

		let previouspage = "/dashboardLocations";
		if (this.props.location.state !== undefined)
		{
			previouspage = this.props.location.state.prevPath.previouspage;
		}
		
		return (			
				<div>							
					<div id="wrapper" className="disableNavLink">
						<div class="toggleTransparent"></div>
						<div class="loadingProgress">
							<div class="loadingProgressIn"></div>
						</div>						
						<LocationHeader />
						<div class="navFilterWrapper" style={{display: 'block'}}>
							<div id="CountryFilter">
								<div class="filterHeader">
									<ul>
										<li>
											<Link onClick={this.callRegionAction.bind(this)} to={{pathname:'/locationsView/filterBy/', state : {regionParamValue: this.state.regionList, selectedList: this.state.selectedList, prevPath: {previouspage}} }}   title="Back" class="backIcon" id="backToFilterListDiv">&nbsp;</Link>																						
										</li>										
										<li>
											Sub Group
										</li> 																
										<li>
											<Link to={previouspage} title="Finish">Finish</Link>
											{/* <Link to={previouspage} title="Filter by" class="cancelIcon cancelFilterListDiv">&nbsp;</Link> */}
										</li>
									</ul>
								</div> 
								<div class="filterListOuter">
									<input class="form-control headerFilterSearch" type="text" value={this.state.search} onChange={this.updatesearch.bind(this)} placeholder="Search"/> 
									<div class={_regionList.length > 3 ? 'countryCheckbox withMorethanThree' : 'countryCheckbox'}> 
										{Object.keys(_regionList).map((item, i) => (
											<div key={_regionList[i].cust_gp_id} class="customCheckbox customCheckboxWlabel">
												<input class="chkGroupName" id={_regionList[i].cust_gp_id} value= {_regionList[i].group_name} checked={_regionList[i].checked} onChange={(e) => this.GetSelectedRegionName(e, _regionList[i])} name="testgroupName" type="checkbox"/>
												<label for= {_regionList[i].cust_gp_id}>{_regionList[i].group_name}</label>											 
											</div>
										))}																			
									</div>  
								</div>
							</div>
							
						</div>

    				</div>

					</div>  
				)
			}
		};	

		function mapStateToProps(state) {
			return {
				regionInfo: state.regionInformation.regionInformation,			
				FindSelectedRegionName:state.FindSelectedRegionName,
				selectedCountries: state.filterInformation.selectedCountries,
			}
		}

		function mapDispatchToProps(dispatch) {
			return bindActionCreators({ getRegion,getFilterbyRegions }, dispatch);
			
		}

export default connect(mapStateToProps, mapDispatchToProps)(FilterByRegion);  

