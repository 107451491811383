import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { format, addDays } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import LbsOilLifeDisposeInfo from './frymasterWidgets/LbsOilLifeDisposeInfo';
import OilDisposeInformation from './frymasterWidgets/OilDisposeInformation';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

import KCStore from '../../utils/kcStorage';

am4core.useTheme(am4themes_animated);

var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class OilUsage extends Component {

    constructor(props, context) {
        super(props, context);
        const holder_name = 'holder_name_' + props.widgetID;
        this.state = {
            type: 'Month',
            filterType: "Month",
            noResultMsg: 'No Results',
            IsServerError: false,
            startDate: '',
            endDate: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            holder_id: "",
            [holder_name]: "Fryer",
            datapickflag: false,
            isGlobalDropdownChange:false,
            globalDropdownFilter: 'Month',
            globalDropdownFilterStartDate: '',
            globalDropdownFilterEndDate: '',
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_NAME;
    }

    handleChangeStart(filtrationDate) {
        this.setState({
            startDate: filtrationDate,
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'FiltrationData');
        });
    }

    handleChangeEnd(filtrationDate) {
        this.setState({
            endDate: filtrationDate,
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'FiltrationData');
        });
    }

    handleChangeGlobalStart(date) {
        const { currentUnit } = this.props;

        this.setState({
            globalDropdownFilterStartDate: date,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            if (currentUnit.BRAND_NAME === "FRYMASTER") {
                if (this.state.filterType !== "Day" && this.state.filterType !== "Week") {
                    this.refs.frymasterOilLifeDispose.handleChangeStart(date);
                    this.refs.frymasterOilDispose.handleChangeStart(date);
                }
            }
            this.onDropDownGlobalHandler(e);
        });
    }

    handleChangeGlobalEnd(date) {
        const { currentUnit } = this.props;

        this.setState({
            globalDropdownFilterEndDate: date,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            if (currentUnit.BRAND_NAME === "FRYMASTER") {
                if (this.state.filterType !== "Day" && this.state.filterType !== "Week") {
                    this.refs.frymasterOilLifeDispose.handleChangeEnd(date);
                    this.refs.frymasterOilDispose.handleChangeEnd(date);
                }
            }
            this.onDropDownGlobalHandler(e);
        });
    }

    onDropDownGlobalHandler(e) {
        const { currentUnit } = this.props;
        const filterType = e.nodeText;

        this.setState({
            globalDropdownFilter: filterType,
            isGlobalDropdownChange:true
        });
        if (currentUnit.BRAND_NAME === "FRYMASTER") {
            if (filterType !== "Day" && filterType !== "Week") {
                this.refs.frymasterOilLifeDispose.fetchData(this.refs.frymasterOilLifeDispose.state.holder_id, filterType)
                this.refs.frymasterOilLifeDispose.setState({ filterType, datapickflag: filterType === 'Date Range' });

                this.refs.frymasterOilDispose.fetchData(this.refs.frymasterOilDispose.state.holder_id.replace("Frypot ", ""), filterType)
                this.refs.frymasterOilDispose.setState({ filterType, datapickflag: filterType === 'Date Range' });
            }
        }
    }

    fetchData(holder_id, text) {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_NAME;
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
        }

        this.setState({ type: text });
        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.props.getHoldingTableData(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, '68', holder_id);
        }
    }

    onDropDownHandler(e, type = '') {
        this.fetchData(this.state.holder_id, e.nodeText);
        this.setState({
            type: e.nodeText,
        });
    }

    onHolderSelection(e, type = '') {
        const holderid = e.target.text.replace("Frypot ", "").replace("Fryer", "")
        this.setState({
            holder_id: holderid,
            holder_name: e.target.text
        });
        this.fetchData(holderid, type);
    }

    //  woopra event
	reportEvent =(e) => {
		WoopraEvents(`${Constants.REPORT_AND_ANALYTICS}-${e.trackTitle}`);
	};

    render() {
        currentUnit = this.props.currentUnit;
        const { formatMessage } = this.props.intl;
        let utilizationTabHeader = null;
        // FRYMASTER OIL USAGE TAB DISPLAY
        if (currentUnit.BRAND_NAME === 'FRYMASTER') {
            utilizationTabHeader = (<div className="pageHeader">
                <ul>
                    <li>
                        <Link to="/reportManagement/production" title={formatMessage({ id: 'KC0175' })} onClick={(e)=>{e.trackTitle="Production";this.reportEvent(e)}}><FormattedMessage id="KC0175"/></Link>
                    </li>
                    <li>
                        <Link to="/reportManagement/filtration" title={formatMessage({ id: 'KC0442' })} onClick={(e)=>{e.trackTitle="Filtration";this.reportEvent(e)}}><FormattedMessage id="KC0442"/></Link>
                    </li>
                    <li>
                        <Link to="/reportManagement/oilUsage" title={formatMessage({ id: 'KC0443' })} className="active"><FormattedMessage id="KC0443"/></Link>
                    </li>
                    <li>
                        <Link to="/reportManagement/tpmValue" title={formatMessage({ id: 'KC0444' })} onClick={(e)=>{e.trackTitle="TPM Value";this.reportEvent(e)}}><FormattedMessage id="KC0444"/></Link>
                    </li>
                    <li className="filterDropdown">
                        <p className="globalFilterText"><FormattedMessage id="KC0097"/></p>
                        <NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} disableDayFilter={true} disableWeekFilter={true} />
                    </li>
                </ul>
            </div>)
        }

        return (
            <React.Fragment>
                <div id="wrapper">
                    <div className="loadingProgress">
                        <div className="loadingProgressIn"></div>
                    </div>
                    <GlobalHeaderLayout activeClass="reportNav" />
                    <div id="page-content-wrapper">
                        {/* Reports and Analytics oil usage tab header */}
                        {utilizationTabHeader}
                        {/* Oil Usage Filter type | Widgets - 71 (Frymaster | Total LBS Oil Disposed | Average Oil Life (days)*/}
                        {<LbsOilLifeDisposeInfo ref="frymasterOilLifeDispose" properties={this.props} widgetID="71" />}
                        {/* Oil Usage Filter type | Widgets - 82 (Frymaster | Dispose Information*/}
                        {<OilDisposeInformation ref="frymasterOilDispose" properties={this.props} widgetID="82" />}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUnit: state.entityReducer.currentUnit,
        currentLocation: state.entityReducer.currentLocation
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCurrentLocation,
        getCurrentUnit
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OilUsage));
