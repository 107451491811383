import {ReportManagement} from '../../service/api';
import http from '../../service/httpService';
import KCStore from '../../utils/kcStorage';

export const FILTER_CLEANINGS_DATA_REQUEST = 'FILTER_CLEANINGS_DATA_REQUEST';
export const FILTER_CLEANINGS_DATA_SUCCESS = 'FILTER_CLEANINGS_DATA_SUCCESS';
export const FILTER_CLEANINGS_DATA_FAIL = 'FILTER_CLEANINGS_DATA_FAIL';

export function getFilterCleaningsData(type, fromdate, todate, deviceId, brandId, widgetId, productName = '') {
    let url ;
    let filterType;
    switch (type) {
        case 'Week':
            filterType='weekly'; 
            break; 
        case 'Year':
            filterType='yearly';            
            break; 
        case 'Day':            
            filterType='daily';
            break;
        case 'Quarter':
            filterType='quarterly';            
            break; 
        case 'Date Range':
            filterType = 'custom';
            break; 
        default: 
            filterType = 'monthly';              
      }    
    
    url = `${ReportManagement.widgetAPI + '/' + widgetId + '/' + brandId}`;

    return dispatch => {
        dispatch({
            type: FILTER_CLEANINGS_DATA_REQUEST
        })
        let storageBrandName = KCStore.getItem("brandName");
        let header;
        if ((storageBrandName === 'MERRYCHEF') || (storageBrandName === 'FRYMASTER')) {
            if (filterType === 'weekly' || filterType === 'custom') {
                header = {
                    'Content-Type': 'application/json',
                    'unitid': deviceId,
                    'startdate': todate,
                    'enddate': fromdate,
                    'filtertype': filterType,
                    'showtrend': "true"
                };
                if (filterType === 'custom') {
                    delete header.showtrend;
                    header.showtrend = "false";
                }
            }
            else {
                header = {
                    'Content-Type': 'application/json',
                    'unitid': deviceId,
                    'reqDate': fromdate,
                    'filtertype': filterType,
                    'showtrend': "true"
                };
            }
        }

        // Added for Frymaster McD CR
        if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
            header.productModel = productName;
        }

        http.get(url,
                {
                  headers: header,
                  data:{}
                })
            .then(response => {
                dispatch({
                    type: FILTER_CLEANINGS_DATA_SUCCESS,
                    filterCleaningsData: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: FILTER_CLEANINGS_DATA_FAIL,
                    error
                })
            })
    }
}
