import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import NewCustomDragAndDrop from './NewCustomDragAndDrop';
import $ from 'jquery';
import http from '../../../service/httpService';
import { BaseService, UnitInfo, unitAPI } from '../../../service/api';
import ErrorMoal from '../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';

class BulkUnitOnboarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            files: {},
            S3Url: '#',
            uploadFileLink: "",
            fileNameWithModule: "",
            error_modal: {
                open: false,
                message: ""
            },
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $('.browseFile').click(function () { $('#browseUpdate').trigger('click'); });
    }

    downloadUnitOnboardingTemplate = async () => {
        this.setState({ loaderIconVisible: true });
        await http({
            method: 'get',
            url: `${BaseService.root}${UnitInfo.unitTemplate}`,
            data: {}
        }).then(response => {
            if (response.data.body.fileUrl) {
                window.location = response.data.body.fileUrl;
                this.setState({
                    loaderIconVisible: false,
                    S3Url: response.data.body.fileUrl,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    getFileUploadLink = async (fileName) => {
        await http({
            method: 'get',
            url: `${BaseService.root}${unitAPI.bulkUploadFile}${fileName}`,
            data: {}
        }).then(response => {
            if (response && response.data.url) {
                this.setState({ uploadFileLink: response.data.url, fileNameWithModule: response.data.key });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });

    }

    validateFile = async (files) => {
        let fileDet = files[0];
        let fileName = files[0].name;
        fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
        let fileextSplit = fileName.split('.');
        let fileext = fileextSplit[fileextSplit.length - 1];
        fileext = fileext.toLowerCase();
        let fileList = [];
        let message = "";
        if ((fileext.toLowerCase() === "xlsx" || fileext.toLowerCase() === "xls")) {
            fileList.push(files[0])
            this.getBase64(files[0], (result) => {
                this.setState({ files: fileList, fileValue: result, errormsg: "" });
            });
            if (fileName) {
                await this.getFileUploadLink(fileName);
            }
            var xhr = new XMLHttpRequest();
            xhr.open("PUT", this.state.uploadFileLink);
            xhr.setRequestHeader('Content-Type', 'text/csv');
            xhr.onloadend = (res) => {
                if (res.target.status == 200) {
                }
            };
            xhr.onerror = (err) => {
                this.setState({ loaderIconVisible: false });
            };
            xhr.send(fileDet);
        } else {
            message = "KC2283";
        }

        if (message !== "") {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: message
                }
            });
        }
    }


    handleDrop = async (files) => {
        this.setState({
            loaderIconVisible: true
        });
        await this.validateFile(files);
        this.setState({
            loaderIconVisible: false,
        });
    }

    UploadmenuFile = async (e) => {
        if (e.target.files[0] !== null) {
            this.setState({
                loaderIconVisible: true
            });
            await this.validateFile(e.target.files);
        }
        this.setState({
            loaderIconVisible: false,
        });
        $("#browseUpdate").val('');
    }

    getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    closeModel = () => {
        this.setState({
            loaderIconVisible: false,
            error_modal: {
                open: false
            }
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { loaderIconVisible, errormsg, fileNameWithModule, error_modal } = this.state;
        let paramVal = `fileName=${fileNameWithModule}`;        
        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div className="loadingProgress">
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">

                    <div className="unitRegistrationWrapper">
                        <div className="addUnitHeader">
                            <ul>
                                <li>
                                    <Link to="/unitRegistration" title={formatMessage({ id: 'KC0989' })} className="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id="KC2285"/>
                                </li>
                                <li className={this.state.files.length > 0 ? "" : "disabled"}>
                                    <Link to={{
                                        pathname: "/bulkUnitOnboardingConfirmation",
                                        search: paramVal,
                                    }} title={formatMessage({ id: 'KC0543' })}><FormattedMessage id="KC0543"/></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="unitRegFormWrapper">
                            <div className="unitRegForm bulkUploadForm">
                                <ul>
                                    <li className="textAlignCenter">
                                        <Link to="#" onClick={() => this.downloadUnitOnboardingTemplate()} title= {formatMessage({ id: 'KC2622' })} className="unitOnboardingTemplate"><FormattedMessage id="KC2622"/></Link>
                                    </li>
                                    <li>
                                        <div className="dragAndBrowse">
                                            <ul>
                                                <li>
                                                    <NewCustomDragAndDrop handleDrop={this.handleDrop}>
                                                        {this.state.files.length > 0 ? this.state.files.map((file, i) =>
                                                            <a className='drogAndDrop'>{file.name}</a>
                                                        ) : <a className="drogAndDrop"><FormattedMessage id="KC2623"/><span className="drogAndDroporBrowse">or</span></a>}
                                                    </NewCustomDragAndDrop>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)" className="browseFile"><FormattedMessage id="KC0664"/></a>
                                                    <input type="file" className="browseFileS" id="browseUpdate" onChange={(e) => this.UploadmenuFile(e)} />
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <UserManagementHeader headerName="Unit Registration and Assignment" headerNameStringID="KC1684" activeClass="UnitRegistrationNav" />
            </div>
        )
    }
}

export default injectIntl(BulkUnitOnboarding);
