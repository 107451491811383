import axios from 'axios';
import api from '../../../service/api';
import http from '../../../service/httpService';
import { BaseService, LocationInfo } from '../../../service/api';
export const ERROR_STATUS_REQUEST = 'ERROR_STATUS_REQUEST';
export const ERROR_STATUS_SUCCESS = 'ERROR_STATUS_SUCCESS';
export const ERROR_STATUS_FAIL = 'ERROR_STATUS_FAIL';

export function geterrorStatusInfo(req) {
    return dispatch => {
        dispatch({
            type: ERROR_STATUS_REQUEST
        })

        http.get(`${LocationInfo.errorStatusURL}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'cgid': '',
                },
                data: {}
            }
        )
            .then(response => {
                dispatch({
                    type: ERROR_STATUS_SUCCESS,
                    errorStatusInformation: response.data,

                })
            })
            .catch(error => {
                dispatch({
                    type: ERROR_STATUS_FAIL,
                    error
                })
            })
    }
}