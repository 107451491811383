import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { UnitInfo, MenuUploadAPI } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { BRAND_ID_VALUE, CUSTOMER_ID, BRAND_ID, BASECOUNTRY_ID } from '../../../utils/appConstants';
import appconstants from '../../../utils/appConstants';
import authService from '../../../service/authService';
import { injectIntl, FormattedMessage } from 'react-intl';
import NoRecordsFound from '../../controls/noRecordsFound';
import KCStore from '../../../utils/kcStorage';
class FileRetrievalList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			unitsSelected: 0,
			unitsOffline: 0,
			userSelectAll: false,
			isIndeterminateSelect: false,
			userChecked: [],
			userCheckedData: [],
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			total_unit_count: 0,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			scheduleDateTimeUtc: "",
			Type: false,
			isMenuUploadSuccess: false,
			isFlagUpdated: false,
			default_chkbox: true,
			isNextEnabled: false
		};
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
		this.handleRetrievalOnChange = this.handleRetrievalOnChange.bind(this);
		this.ValidateinputField = this.ValidateinputField.bind(this);
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		if (this.state.retrieveType) {
			this.props.history.push('/libraryList');
		}
		else {
			this.props.history.push('/menuUpdateStatus');
		}
	}

	closeModel = () => {
		this.setState({
			error_modal: {
				open: false
			}
		})
	}

	componentWillMount = () => {
		let { previouspath, scheduleDateTimeUtc, Type, libraryIds, libraryType, retrieveType } = this.props.location;
		this.setState({
			previouspath: previouspath,
			scheduleDateTimeUtc: scheduleDateTimeUtc,
			Type: Type,
			libraryIds: libraryIds,
			libraryType: libraryType,
			retrieveType: retrieveType
		});
	}
	componentDidUpdate(prevState) { 
        this.updateDimensions(); 
    }
	updateDimensions() {
		const windowHeight = $(window).height();
        const assignUListSrlBar = $(".assignUListSrlBar");
        assignUListSrlBar.css('height', windowHeight - assignUListSrlBar.offset().top);
	} 
	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			this.requestData();
		} catch (error) {
			//Error Goes Here
		}

	}

	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	getBrandId = () => {
		let brandName = KCStore.getItem("brandName");
		return BRAND_ID_VALUE[brandName] || 0;
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data.message ? (data.stringID || data.message) : ""
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1168"
				}
			});
		}
	}

	requestData = async () => {
		let brandId = this.getBrandId();
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = KCStore.getItem(appconstants.menuFilters);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];

		selectedCountryList.dataSource.map(c => {
			selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			selectedCids.push(c.ID)
		});

		// if (selectedWithinLocationList.dataSource.length > 0) {
		// 	selectedCgids = [];
		// }

		// selectedWithinLocationList.dataSource.map(c => {
		// 	return selectedCgids.push(c.cust_gp_id)
		// });

		selectedBrandList.dataSource.map(c => {
			selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			selectedMachineTypes.push(c.ID)
		});

		var d = document.getElementById("spannavNotifiStatus");
		let custGroupID = KCStore.getItem("selected_cgid") ? JSON.parse(KCStore.getItem("selected_cgid")) : [];
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID.length > 0 ? Array.prototype.map.call(custGroupID, m => m.cust_gp_id) : KCStore.getItem("custGroupID");
		}
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
			d.className = "navNotifiStatus";
		}
		else if (selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
			d.className = "navNotifiStatus";
		}
		else {
			d.className = "";
		}

		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let url = `${UnitInfo.list}`;
		let apiUrl = url;
		this.setState({ loaderIconVisible: true });
		await http.get(apiUrl,
			{
				headers: {
					'Content-Type': 'application/json',
					'cgid': cgids,
					'cid': selectedCids.join(','),
					'brandid': selectedBrands.length > 0 ? selectedBrands.join(',') : brandId ? brandId : BRAND_ID,
					'model': selectedModels.join(','),
					'machinetype': selectedMachineTypes.join(','),
					'sortby': sortedID,
					'sorttype': sortedDesc,
					'basecountry': BASECOUNTRY_ID,
					'modelfamilyids': KCStore.getItem("selectedFamilyID")
				},
				data: {}
			}).then(response => {
				let { units } = response.data;
				if(units.length > 0) {
					units = units.filter(item => item.MODEL_FAMILY_NAME.includes("conneX"))
				}
				units = UnitBuilder(units);

				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				units.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});

				let unitsOfflineCount = units.filter(c => c.CONNECT_STATE === "Offline" || c.THING === null);
				
				this.setState({
					loaderIconVisible: false,
					data: units,
					unitsOffline: unitsOfflineCount.length,
					userChecked: checkedCopy,
					userSelectAll: userSelectAll
				});
			}).catch(err => {
				this.showError(err);
			});
	};

	onBackClickHandler = () => {
		this.props.history.push({
			pathname: "/libraryList",
			previouspath: "/fileRetrievalList",
		});
	}

	getCheckBoxstate(ListCount, unselectedCount) {
		if (ListCount === unselectedCount) {
			return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (unselectedCount === 0) {
			return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (ListCount > unselectedCount) {
			return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, index, rowData) => {
		const units = [...this.state.data];
		let filtered = units.map(c => ({ ...c, checked: false }));

		var checkedCopy = this.state.userChecked;
		checkedCopy[index] = !this.state.userChecked[index];
		if (checkedCopy[index] === false) {
			this.setState({ userSelectAll: false });
		}
		let checkAll = [];
		filtered[index].checked = e.target.checked;

		let checked = filtered.filter(c => c.checked === true);
		let unitsOfflineCount = filtered.filter(c => c.CONNECT_STATE === "Offline" || c.THING === null);
		let unitRegistedCount = filtered.filter(c => c.PROVISIONING_STATUS === "UNIT_REGISTERED").length
		this.setState({
			data: units,
			userChecked: checkedCopy,
			unitsSelected: checked.length,
			unitsOffline: unitsOfflineCount.length,
			userCheckedData: checked,
			...this.getCheckBoxstate(parseInt(units.length) - parseInt(unitRegistedCount)
				, checkAll.length)
		});

		this.isSelectAssign(checked);
	};

	isSelectAssign = (units) => {
		let checkAllTrue = [];
		checkAllTrue = units.filter(c => c.checked === true);

		if (checkAllTrue.length > 0) {
			$("#btnfinish").addClass("btn-default-text activeState");
			$("#btnfinish").removeClass("disabled");
		}
		else {
			$("#btnfinish").removeClass("activeState");
			$("#btnfinish").addClass("disabled");
		}
	}

	handleRetrievalOnChange = (e) => {
		this.ValidateSubmitState();
		if (!e.target.checked) {
			if (e.target.value === 'Cookbook') {
				$("#cookbookEntry").addClass("disabled");
			} else {
				$("#settingsEntry").addClass("disabled");
			}
		} else if (e.target.value === 'Cookbook') {
			$("#cookbookEntry").removeClass("disabled");
		} else {
			$("#settingsEntry").removeClass("disabled");
		}
	}

	ValidateinputField = (e) => {
		var maxLimit = 20;
		if (e.target.value.trim().length > maxLimit && e.target.id == 'cookbookEntry') {
			this.setState({
				error_modal: {
					open: true,
					message: "KC1240",
				},
			});
			$('#cookbookEntry').val($('#cookbookEntry').val().substring(0,maxLimit));
		} else if(e.target.value.trim().length > maxLimit && e.target.id == 'settingsEntry') {
			this.setState({
				error_modal: {
					open: true,
					message: "KC1265",
				},
			});
			$('#settingsEntry').val($('#settingsEntry').val().substring(0,maxLimit));
		}
	}

	ValidateSubmitState = () => {
		let _cc = $("#Cookbook").prop('checked')
		let _sc = $("#Settings").prop('checked');
		if (!_cc && !_sc) {
			$("#submitOk").addClass("disabled");
			$("#settingsEntry").addClass("disabled");
		} else $("#submitOk").removeClass("disabled");
	}

	onNextClickHandler = () => {
		if (this.state.userCheckedData.length > 0) {
			$("#Cookbook").prop('checked', true);
			$("#Settings").prop('checked', true);
			$('#settingsEntry').val('');
			$('#cookbookEntry').val('');
			$("#settingsEntry").removeClass("disabled");
			$("#cookbookEntry").removeClass("disabled");
			$('#cbfileretrievalModal').modal('show');
			this.ValidateSubmitState();
		} else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1154",
				},
			});
		}
	}

	onRetrievalSubmitHandler = () => {
		let submit_Flag = true;
		let _cc = $("#Cookbook").prop('checked')
		let _sc = $("#Settings").prop('checked');
		if (_cc && $('#cookbookEntry').val().trim() == '' && _sc && $('#settingsEntry').val().trim() == '') {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC2270",
				},
			});
			$('#settingsEntry').val('');
			$('#cookbookEntry').val('');
			submit_Flag = false;
		}
		else if (_cc && $('#cookbookEntry').val().trim() == '') {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1240",
				},
			});
			$('#cookbookEntry').val('');
			submit_Flag = false;
		}
		else if (_sc && $('#settingsEntry').val().trim() == '') {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1265",
				},
			});
			$('#settingsEntry').val('');
			submit_Flag = false;
		}
		if (submit_Flag) {
			let retrieveObj = {
				retrieve: {
					cookbookName: $('#cookbookEntry').val().trim() == '' ? '' : $('#cookbookEntry').val().trim(),
					settingsName: $('#settingsEntry').val().trim() == '' ? '' : $('#settingsEntry').val().trim()
				},
			};
			let userCheckedData = this.state.userCheckedData;
			let ModelFamilyID = userCheckedData[0].MODEL_FAMILY_ID;
			const unitList = [];
			userCheckedData.map(item => {
				return unitList.push(
					{
						"thingArn": item.THING,
						"cloudUnitName": item.CLOUD_UNIT_NAME,
						"unitTimeZone": item.TimeZone_Standard,
						"productId": item.PRODUCT_ID,
						"cgid": KCStore.getItem("custGroupID")
					}
				)
			});
			this.props.history.push({
				pathname: "/retrieveLibraryToTopology",
				previouspath: "/fileRetrievalList",
				retrieveType: "Retrieve",
				retrieveData: retrieveObj,
				thingList: unitList,
				ModalFamilyId: ModelFamilyID
			});
		}
	}

	render() {
		const { loaderIconVisible, data, sortColumn, unitsSelected, unitsOffline, error_modal, success_modal, retrieveType, libraryIds } = this.state;
		let menuAuthFeature = authService.getFeature("/menuBrands");
		const { formatMessage } = this.props.intl;
		let orderId = 1;
		let Updatedunits = (parseInt(unitsSelected) - parseInt(unitsOffline));
		let columns = [
			{ path: "", label: "#" },
			{ path: "", label: "", isNbSp: true },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "PRODUCT_TYPE", labelStringID: "KC0102" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "UNIT_NUMBER", labelStringID: "KC0674" },
			{ path: "location_name", labelStringID: "KC0050" },
			{ path: "within_location", labelStringID: "KC0061" },
			{ path: "CONNECT_STATE", labelStringID: "KC0523", isNbSp: true },
			{ path: "Select", labelStringID: "Select" }
		];

		let unitList = data;

		unitList.forEach(item => {
			item.order_id = orderId++
		});

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div class="menuUpdateWrapper">
							<div class="adminBgHeader">
								<ul>
									<li>
										<Link to="#" onClick={() => this.onBackClickHandler()} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link>
									</li>
									<li className="txtboldmsg">
										<FormattedMessage id="KC2267" />
									</li>
									<li className={this.state.userCheckedData.length > 0 ? "" : "disabled"}>
										<button className="btn finishBtn" type="button" id="nextButton" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} title={formatMessage({ id: 'KC0543' })} onClick={() => this.onNextClickHandler()}><FormattedMessage id='KC0543' /></button>
									</li>
								</ul>
							</div>
							<div class="selectedUnitHeader">
								<ul>
									<li><FormattedMessage id="KC1570" values={{ selectedCount: this.state.unitsSelected, offlineCount: this.state.unitsOffline }} /></li>
								</ul>
							</div>
							<form class="SWlistUnits">
								<div class="SWlistUnitsTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
								</div>
								<div className="assignUListSrlBar">
									<div className="SWlistUnitsTable">
										{
											unitList.map((item, i) => {
												return (
													<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody "} key={i}>
														<li>{i + 1}</li>
														<li><img src={item.Brand_Logo} alt="" /></li>
														<li>{item.BRAND_NAME}
															<div class="unitsListSM">{item.PRODUCT_TYPE}, {item.MODEL_NAME}</div>
															<div class="unitsListSML">{item.location_name}</div></li>
														<li>{item.PRODUCT_TYPE}</li>
														<li>{item.MODEL_NAME}</li>
														<li>{item.UNITID} <span class="unitsListAN"><FormattedMessage id="KC0674" /> <b>{item.UNIT_NUMBER}</b></span></li>
														<li>{item.UNIT_NUMBER}</li>
														<li>{item.location_name}</li>
														<li>{item.within_location}</li>
														<li>{item.CONNECT_STATE === 'Offline' || item.THING === null ? <img src={require('../../../images/icons/offlineT.svg').default} alt="offline" /> : <img src={require('../../../images/icons/onlineT.svg').default} alt="online" /> }</li>
														<li>{item.CONNECT_STATE === 'Offline' || item.THING === null ?
															<li>
																<div class="customRadioBtn disabled">
																	<input id={item.order_id - 1} type="radio" disabled name="unitList" value={item.order_id - 1} onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
																	<label for={item.order_id - 1}>&nbsp;</label>
																</div>
															</li>
															:
															<li>
																<div class="customRadioBtn">
																	<input id={item.order_id - 1} type="radio" name="unitList" value={item.order_id - 1} onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
																	<label for={item.order_id - 1}>&nbsp;</label>
																</div>
															</li>
														}
														</li>
													</ul>
												)
											}
											)
										}
									</div>
									<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
								</div>
							</form>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} history={this.props.history} />
				</div>
				{/* Retrival Information Modal */}
				<div class="modal fade selectRecipeModal" data-keyboard="true" data-backdrop="static" id="cbfileretrievalModal" tabindex="-1" role="dialog" aria-labelledby="cbfileretrievalModalLabel">
					<div class="modal-dialog modal-dialog-centered modal-md">
						<div class="modal-content">
							<div class="modal-header bBg modal-header-danger">
							<FormattedMessage id="KC2268" /><button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
							</div>
							<div class="modal-body">
								<div class="cbfileretrievalFormP"><FormattedMessage id="KC2269" /></div>
								<div class="cbfileretrievalForm">
									<ul>
										<li>
											<div class="customCheckbox">
												<input id="Cookbook" name="cbfileretrieval" type="checkbox" value="Cookbook" maxLength="20" defaultChecked={this.state.default_chkbox} onChange={(e) => this.handleRetrievalOnChange(e)} />
												<label for="Cookbook"><FormattedMessage id="KC2009" /></label>
											</div>
										</li>
										<li>
											<input type="text" class="form-control" maxLength="20" id="cookbookEntry" placeholder="Enter name" onChange = {(e) => this.ValidateinputField(e)}/>
										</li>
										<li>
											<span class="menuType"><img src={require('../../../images/icons/manualS.png')} alt="" /></span>
										</li>
									</ul>
									<ul>
										<li>
											<div class="customCheckbox">
												<input id="Settings" name="cbfileretrieval" type="checkbox" value="Settings" maxLength="20" defaultChecked={this.state.default_chkbox} onChange={(e) => this.handleRetrievalOnChange(e)} />
												<label for="Settings"><FormattedMessage id="KC1190" /></label>
											</div>
										</li>
										<li>
											<input type="text" class="form-control" maxLength="20" id="settingsEntry" placeholder="Enter name" onChange = {(e) => this.ValidateinputField(e)} />
										</li>
										<li>
											<span class="menuType"><img src={require('../../../images/icons/settingS.png')} alt="" /></span>
										</li>
									</ul>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary confirmationOKBtn" id="submitOk" onClick={() => this.onRetrievalSubmitHandler()} type="button">OK</button>
							</div>
						</div>
					</div>
				</div>
				{/* //Retrival Information */}
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FileRetrievalList));