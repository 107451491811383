import http from '../../../service/httpService';
import { SoftwareManagementAPI } from '../../../service/api';
import { CUSTOMER_ID, BRAND_ID,BASECOUNTRY_ID } from '../../../utils/appConstants';
import { format } from 'date-fns';
import KCStore from '../../../utils/kcStorage';
export const SOFTWARE_UPDATE_STATUS_REQUEST = 'SOFTWARE_UPDATE_STATUS_REQUEST';
export const SOFTWARE_UPDATE_STATUS_SUCCESS = 'SOFTWARE_UPDATE_STATUS_SUCCESS';
export const SOFTWARE_UPDATE_STATUS_FAIL = 'SOFTWARE_UPDATE_STATUS_FAIL';


export function getSoftwareUpdateStatus(sDate,eDate, currentPage, pageSize) {
    let custGroupID = KCStore.getItem("custGroupID");
		     let cgids = "";
			if (custGroupID != "" && custGroupID != undefined) {
				cgids = custGroupID;
            }

        let url = `${SoftwareManagementAPI.softwareUpdateStatusUrl}`;
		let pageNumber = '?pageNum=' + currentPage;
		let pageLimit = '&pageSize=' + pageSize;
		
		url = url + pageNumber + pageLimit;
            
    return dispatch => {
        dispatch({
            type: SOFTWARE_UPDATE_STATUS_REQUEST
        })        
        http.get(url,
            {
                headers: {
                    'Content-Type': 'application/json', 
                    customerId: CUSTOMER_ID,
                    brandId: BRAND_ID,
                    'cgids':cgids,
                    //'basecountry': BASECOUNTRY_ID,
                    'startdate':sDate ? format(sDate, 'YYYY-MM-DD'):"",
					'enddate':eDate?format(eDate, 'YYYY-MM-DD'):"",
                },
                data:{}
            })
        .then(response => {
            console.log('ORG_VIEWInfo' ,response.data);
            dispatch({
                type: SOFTWARE_UPDATE_STATUS_SUCCESS,
                softwareUpdateStatusInformation: response.data,
             })
        })            
        .catch(error => {
                dispatch({
                    type: SOFTWARE_UPDATE_STATUS_FAIL,
                    softwareUpdateStatusInformation:[],
                    error
                })
            })
    }
}


