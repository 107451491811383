import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SmartTagWidget from './smartTagWidget';
import { ServiceManagement } from '../../../service/api';
import http from '../../../service/httpService';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import KCStore from '../../../utils/kcStorage';

class ErrorSolvedWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			errorResponseData: [],
		};
	}

	componentDidMount = async () => {
		const unitID = KCStore.getItem("unitID");
		this.getErrorinformations(unitID);
	}

	getErrorinformations = async (unitVal) => {
		try {
			let apiUrl = `${ServiceManagement.historicalError}`.replace("X0", unitVal);
			let header = {
				'Content-Type': 'application/json',
				'errorstatus': "resolved",
			}
			const response = await http({
				method: "GET",
				url: apiUrl,
				headers: header,
				data: {},
			});
			if (response.data) {
				this.setState({ errorResponseData: response.data, loaderIconVisible: false });
			}
		} catch (error) {
			this.setState({ loaderIconVisible: false });
		}
	};

	render() {
		let { errorResponseData } = this.state;
		const hasErrors = errorResponseData.length > 0;
		const { withTag } = this.props;

		return (
			<React.Fragment>
				{withTag === true ?
					<div className="colm6D colm6D2">
						<div className="colmDi">
							<h4><FormattedMessage id="KC0388" /></h4>
							<div className="customPara">
								<ul>
									{hasErrors && errorResponseData.slice(0, 2).map((error, index) => (
										<li key={index}>
											<p>{error.error_resolved_date ? moment(error.error_resolved_date).utcOffset(error.error_resolved_date).format('LLL') : ""}</p>
											<p>{error.error_code + " : " + error.description}</p>
										</li>
									))}
								</ul>
							</div>
						</div>
						<SmartTagWidget currentUnit={this.props} currentLocation={this.props} />
					</div>
					:
					<div className="colm6D colm6D2 disabled">
						<div className="colmDi">
							<h4>{KCStore.getItem("brandName") !== "CREM" ? <FormattedMessage id="KC0388" /> : ""}</h4>
						</div>
					</div>
				}
			</React.Fragment>
		)
	}
};

export default ErrorSolvedWidget;
