// User Story 73861: KC China - Compliance & Mandatory change
export const footerTextCustom = () => {
    const hostname = window && window.location && window.location.hostname;
    let copyRights = "";
    if (hostname === "www.welbiltconnect.cn" 
    || hostname === "welbiltconnect.cn") {
        copyRights = "慧而特（杭州）电器科技有限公司| All Rights Reserved | Welbilt";
    } else {
        copyRights = "Copyright © 2022 Welbilt Inc. or its affiliates| All Rights Reserved | Welbilt";
    }
    return copyRights;
};

export const icpTextCustom = () => {
    const hostname = window && window.location && window.location.hostname;
    let icpRegisterNumber = "";
    if (hostname === "www.welbiltconnect.cn" 
    || hostname === "welbiltconnect.cn") {
        icpRegisterNumber = "浙ICP备2022028829号";
    } else {
        icpRegisterNumber = "";
    }
    return icpRegisterNumber;
};

export const projectTitleCustom = () => {
    const hostname = window && window.location && window.location.hostname;
    let titleTextChange = "";
    if (hostname === "www.welbiltconnect.cn" 
    || hostname === "welbiltconnect.cn") {
        titleTextChange = "Welbilt KitchenConnect";
    } else {
        titleTextChange = "KitchenConnect";
    }
    return titleTextChange;
};
