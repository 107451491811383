var frymasterRecipeJSON = {
    "id": "",
    "version": "",
    "edit_date": "",
    "author": "",
    "email": "",
    "name": {
        "en_US": ""
    },
    "image": "",
    "settings": {
        "quality_timer": "",
        "weighted_cook_count": 0,
        "drain_timer" : 0,
        "hold_timer" : 0,
        "instant_on": "",
        "basketLiftSize": "none",
        "push_start": "",
        "exit_mode": "Cooking",
        "schedule": {
            "mode": "false",
            "start_date": "",
            "end_date": ""
        } 
    },
    "ordered_steps": [],
    "conditional_steps": []
}

export default frymasterRecipeJSON 