import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { injectIntl, FormattedMessage } from 'react-intl';
import $ from 'jquery';
import KCStore from '../../../../../utils/kcStorage';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class Timezone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            timezone: ""
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onTimeZoneSelect = this.onTimeZoneSelect.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();

        try {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let jsonTimezone = JSON.parse(jsonSettingsFile).timezone;

            this.setState({
                timezone: jsonTimezone.default ? jsonTimezone.default : 'Eu_Lj'
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/dateAndTimeSettings",
            isEdit: false,
            previouspath: "/timezone"
        });
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true
        });
        let { timezone } = this.state;
        let jsonSettingsFile = KCStore.getItem("librarySettingsData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        ObjSettingsFile.timezone.default = timezone;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("librarySettingsData", strSettingsFile);
        this.props.history.push(`/dateAndTimeSettings`);
    }

    onTimeZoneSelect = (event) => {
        this.setState({
            timezone: event.target.value
        });
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/dateAndTimeSettings`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        let { timezone, error_modal, success_modal } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        const languages = [
            {
                language: 'Europe/Ljublijana',
                locale: 'Eu_Lj'
            },
            {
                language: 'Europe/London',
                locale: 'Eu_Lo'
            }, {
                language: 'Europe/Luxembourg',
                locale: 'Eu_Lu'
            }, {
                language: 'Europe/Madrid',
                locale: 'Eu_Mad'
            }, {
                language: 'Europe/Malta',
                locale: 'Eu_Ma'
            }
        ]

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658" })}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">

                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} alt="" /></div>

                            <div class="recipeEditorWrapper">

                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC2250" />
                                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            {languages.map((item, index) => {
                                                                return (
                                                                    <div class="rcSettingsList02">
                                                                        <ul key={index}>
                                                                            <li>{item.language}</li>
                                                                            <li>
                                                                                <div class="customRadioBtn">
                                                                                    <input id={item.locale} type="radio" name="PrimaryLanguage" value={item.locale} checked={timezone == item.locale} onChange={(e) => this.onTimeZoneSelect(e)} />
                                                                                    <label for={item.locale}>&nbsp;</label>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                )
                                                            })}
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>

                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(Timezone)
