import { MenuUploadAPI, BaseService } from '../../../service/api';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import http from '../../../service/httpService';

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

function uploadMenuToCloud(url, postData, uploadedfile, loggedinUser) {

	return new Promise((resolve, reject) => {
		http({
			url: BaseService.root + url,
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
			data: {}
		}).then(response => {
			let s3url = response.data.message.url;
			let Uploadeds3url = s3url.split('?');
			Uploadeds3url = Uploadeds3url[0];
			let xhr = new XMLHttpRequest();
			xhr.open("PUT", s3url);
			xhr.setRequestHeader('Content-Type', 'multipart/form-data');
			xhr.onloadend = (res) => {
				if (res.target.status == 200) {
					postData.s3Url = Uploadeds3url;
					http({
						method: HTTP_METHOD.POST,
						url: `${BaseService.root}${MenuUploadAPI.getMenuUpdateStatus}`,
						data: postData,
						headers: {
							'Content-Type': 'application/json',
							'user': loggedinUser,
							'basecountry': BASECOUNTRY_ID
						}
					}).then(res => {
						let { data } = res;
						let { httpCode, message } = data;
						if (httpCode === "HTTP_201") {
							resolve(message);
						}
						else {
							reject(res);
						}
					}).catch(err => {
						reject(err);
					});
				}
			};
			xhr.onerror = (err) => {
				reject(err);
			};
			xhr.send(uploadedfile[0]);
		}).catch(err => {
			reject(err);
		});
	});
}

export default uploadMenuToCloud