import React, { Component } from 'react';
// import CreditCard from './creditCard'
import $ from 'jquery';
import { DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import countryCodeArray from '../login/countryCodeArray';
import { FormattedMessage,injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LanguagePreferenceCommon from '../../components/languageDropdown/LanguagePreferenceCommon';
import KCStore from '../../utils/kcStorage';

class SaasRegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            isCreditCardShow: false,
            jwtToken: '',
            plan: {},
            fields: {},
            errors: {},
            countryDropdown: [],
            stateDropdown: [],
            success_modal: {
                open: false,
                message: "",
            },
            error_modal: {
                open: false,
                message: "",
                isHtml: false
            },
            creditCardValidationError: false,
            showLoaderText: false,
        }
    }

    componentDidMount = async () => {
        $("body").addClass("subscriptionBg");
        const jwtToken = KCStore.getItem('authorizeJwtToken');
        const emailValidated = KCStore.getItem('saas-customer-email');
        let fields = this.state.fields;
        fields['email'] = emailValidated;

        var urlParams = new URLSearchParams(window.location.search);
        const getValue = urlParams.get("value");
        const decodedValue = JSON.parse(atob(getValue));
        this.setState({ jwtToken, plan: decodedValue, fields }, async () => {
            await this.validateDevice();
            await this.getCountryDropdown();
            this.setState({ loaderIconVisible: false, countryCodeList: countryCodeArray });
        })
    }

    componentWillUnmount(){
        $("body").removeClass("subscriptionBg");
    }

    getCountryDropdown = () => {
        return new Promise((res, rej) => {
            let url = DeviceRegistration.getCountryDropdown;

            http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {}
            }).then(async response => {
                if (response.data && response.data.length) {
                    await this.setState({ countryDropdown: response.data });
                    res(true);
                }
            }).catch(err => {
                res(false);
            });
        })
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            },
        })
        if (this.state.creditCardValidationError) {
            return;
        }
        if (KCStore.getItem('isCookieAccepted')) {
            KCStore.clear();
            KCStore.setItem('isCookieAccepted', true);
        } else {
            KCStore.clear();
        }
        this.props.history.push('/register-unit')
    }

    onSuccessCloseModal = async (e) => {
        this.setState({
            success_modal: {
                open: false,
            },
        });
        if (KCStore.getItem('isCookieAccepted')) {
            KCStore.clear();
            KCStore.setItem('isCookieAccepted', true);
        } else {
            KCStore.clear();
        }
        this.props.history.push('/')
    }


    validateDevice = () => {
        return new Promise((res, rej) => {
            const jwtToken = KCStore.getItem('authorizeJwtToken');
            this.setState({ loaderIconVisible: true });
            let url = DeviceRegistration.jwtTokenAuth;

            http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': jwtToken,
                },
                data: {}
            }).then(async response => {
                if (response && response.data) {
                    if (response.data.httpCode === 200) {
                        if (response.data.body && response.data.body.length) {
                            await this.setState({ validatedResponse: response.data.body[0], loaderIconVisible: false });
                            res(true);
                        }
                    } else {
                        this.props.history.push('/register-unit');
                        res(false);
                    }
                }
            }).catch(err => {
                this.props.history.push('/register-unit');
                res(false);
            });
        })
    }

    callChargifyApi = () => {
        return new Promise(async (res, rej) => {
            try {
                const apiCall = await this.refs.creditCardForm.handleSubmit();
                res(apiCall);
            } catch (err) {
                this.setState({ loaderIconVisible: false, creditCardValidationError: true, error_modal: { open: true, message: err && err.message ? err.message : err.errors ? err.errors : 'Invalid details' } })
                rej(err)
            }
        })
    }


    registerCustomer = async () => {
        const { validatedResponse, plan, fields } = this.state;

        this.setState({ loaderIconVisible: true, creditCardValidationError: false });

        const pricingPointId = plan.pricingTiers && plan.pricingTiers.length ? plan.pricingTiers[0]['id'] : '';
        if (await this.validateDevice()) {
            this.setState({ loaderIconVisible: true });
            if (await this.validateForm()) {
                this.setState({ showLoaderText: true });
                let url = DeviceRegistration.validateEmail;
                http.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'email': fields.email || '',
                    },
                    data: {}
                }).then(async response => {
                    if (response && response.data && response.data.httpCode === 200) {
                        const jwtToken = KCStore.getItem('authorizeJwtToken');
                        const { locale, languageList } = this.props;
                        const langFilter = languageList.filter((i) =>i.language_code === locale);
                        let bodyParams = {
                            "resourceType": "customers",
                            "state_name": fields.stateName || "",
                            "registrationCode": validatedResponse.REGISTRATION_CODE || "",
                            "brandId": validatedResponse.BRANDID || "",
                            "brandName": validatedResponse.BRAND_NAME || "",
                            "serialNumber": validatedResponse.SERIALNO || "",
                            "trial_months": validatedResponse.TRIAL_MONTHS,
                            'session_uuid': validatedResponse.session_uuid || '',
                            "phone": fields.phoneNumber || "",
                            "countryCode": fields.countryCode || "",
                            "chargifyProductId": plan.product_id || '',
                            "componentId": validatedResponse.COMPONENT_ID || "",
                            "price_point_id": pricingPointId,                            
                            "language_id": langFilter[0].Id,
                            "chargifyAllocation": {
                                // Pro plan only
                                "allocation": {
                                    "quantity": 1,
                                    "memo": "One unit purchased from " + fields.firstName,
                                    "accrue_charge": false,
                                    "upgrade_charge": "prorated"
                                }
                            },
                            "chargifyMetaData": [
                                {
                                    "name": "Commercial Company Name",
                                    "value": fields.restaurantName || ""
                                },
                                {
                                    "name": "Billing Email Address",
                                    "value": fields.companyEmailAddress || ""
                                }, {
                                    "name": "Welbilt Invoicing Entity",
                                    "value": fields.welbiltInvoicingEntity || ""
                                }, {
                                    "name": "Serial Number",
                                    "value": validatedResponse.SERIALNO
                                }
                            ],
                            "subscriptionDetails": {
                                "product_handle": plan.handle || "",
                                "customer_attributes": {
                                    "first_name": fields.firstName || "",
                                    "last_name": fields.lastName || "",
                                    "email": fields.email || "",
                                    "zip": fields.zipCode || "",
                                    "phone": fields.countryCode && fields.phoneNumber ? fields.countryCode + ' ' + fields.phoneNumber : fields.phoneNumber ? fields.phoneNumber : "",
                                    "organization": fields.companyName || "",
                                    "country": fields.country || "",
                                    "state": fields.state || "",
                                    "city": fields.city || "",
                                    "address_2": fields.address2 || "",
                                    "address": fields.address1 || "",
                                    "vat_number": fields.vatNumber || ""
                                }
                            },
                        }
                        bodyParams["subscriptionDetails"]["calendar_billing"] = {
                            "snap_day": 1,
                            "calendar_billing_first_charge": "prorated"
                        };
                        // if (fields.country === "GB") {
                        //     const objValue = {
                        //         "name": "Registered in",
                        //         "value": "England and Wales No. 2656967 as Welbilt UK Limited"
                        //     }
                        //     const WelbiltVatNumber = {
                        //         "name": "Welbilt VAT Number",
                        //         "value": "GB243188561"
                        //     }
                        //     bodyParams['chargifyMetaData'].push(objValue);
                        //     bodyParams['chargifyMetaData'].push(WelbiltVatNumber);
                        // }
                        const isPro = plan.pricingTiers.length && !plan.isFreeTrial;

                        if (isPro) {
                            // Commented this because no credit card flow
                            // await this.callChargifyApi();
                            // const creditCardParam = {
                            //     "chargify_token": this.refs.creditCardForm.state.token || "",
                            //     "payment_type": "credit_card",
                            // }
                            // bodyParams["subscriptionDetails"]["credit_card_attributes"] = creditCardParam;
                            await this.registerAPICall(jwtToken, bodyParams);
                        } else {
                            await this.registerAPICall(jwtToken, bodyParams);
                        }
                    } else {
                        if (!this.state.creditCardValidationError) {
                            let { errors } = this.state;
                            errors['email'] = true;
                            errors["emailInvalid"] = false;
                            this.setState({ errors, loaderIconVisible: false, showLoaderText: false });
                        }
                    }
                }).catch(err => {
                    if (err && err.response && err.response.data && err.response.data.message && err.response.data.message === "Token Expired Or Invalid") {
                        this.setState({
                            loaderIconVisible: false,
                            showLoaderText: false,
                            error_modal: {
                                open: true,
                                message: 'Session Expired. Please try again'
                            }
                        });
                        return;
                    }
                    if (!this.state.creditCardValidationError) {
                        let { errors } = this.state;
                        errors['email'] = true;
                        errors["emailInvalid"] = false;
                        this.setState({ errors, loaderIconVisible: false, showLoaderText: false })
                    }
                });
            } else {
                this.setState({ loaderIconVisible: false, showLoaderText: false });
            }
        } else {
            this.props.history.push('/register-unit')
        }
    }

    onChangeEvent = (e, stateKey) => {
        let fields = this.state.fields;
        fields[stateKey] = stateKey === "email" || stateKey === "companyEmailAddress" ? e.target.value.toLowerCase() : e.target.value;
        this.setState({
            fields
        });
    }

    nameBlurEvent = (stateKey) => {
        let inputdataobj = this.state.fields[stateKey];
        if (inputdataobj && inputdataobj !== "undefined" && inputdataobj.trim() !== "") {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [stateKey]: ''

                }
            })
        }
        return false;
    }

    emailblurevent = () => {
        let inputdataemailobj = this.state.fields.email;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (inputdataemailobj && inputdataemailobj !== "undefined" && pattern.test(inputdataemailobj)) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    email: ''

                }
            })
        }
        return false;
    }

    phoneblurevent = () => {
        let inputdataobj = this.state.fields.phoneNumber;
        var pattern = new RegExp(/^[0-9]{10}$/);
        if (inputdataobj && inputdataobj !== "undefined" && pattern.test(inputdataobj)) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    phoneNumber: ''

                }
            })
        }
        return false;
    }

    checkStatus = async () => {
        const { validatedResponse } = this.state;
        await http.get(DeviceRegistration.checkChargifyStatus, {
            headers: {
                'Content-Type': 'application/json',
                'session_uuid': validatedResponse.session_uuid || ''
            },
            data: {}
        }).then(async response => {
            if (response && response.data) {
                if (response.data.body && response.data.body.status === "INPROGRESS") {
                    setTimeout(() => {
                        this.checkStatus();
                    }, 8000)
                } else if (response.data.body && response.data.body.status === "FINISHED") {
                    this.setState({ loaderIconVisible: false, showLoaderText: false, success_modal: { open: true, message: <FormattedMessage id='KC0972'/> } });
                } else {
                    if (response.data.body.cognito_email_ack && response.data.body.cognito_email_ack === 1) {
                        this.setState({ loaderIconVisible: false, showLoaderText: false, error_modal: { open: true, message: '<div class="errorModalContent"><p>Something went wrong !!</p><p> Apologies, we were not able to complete your account creation. Please disregard the email we’ve sent and try registration again. </p></div>', isHtml: true } });
                    } else {
                        this.setState({ loaderIconVisible: false, showLoaderText: false, error_modal: { open: true, message: '<div class="errorModalContent"><p>Something went wrong !!</p><p> Apologies, we were not able to complete your account creation. Please try registration again.</p></div>', isHtml: true } });
                    }
                }
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false, showLoaderText: false, error_modal: { open: true, message: '<div class="errorModalContent"><p>Something went wrong !!</p><p> Apologies, we were not able to complete your account creation. Please try registration again.</p></div>', isHtml: true } });
        });
    }

    validateForm = async () => {
        let { fields, errors } = this.state;
        let isValidForm = true;
        if (!fields["firstName"] || fields["firstName"] === "undefined" || fields["firstName"].trim() === "") {
            errors["firstName"] = true;
            isValidForm = false;
        }
        if (!fields["lastName"] || fields["lastName"] === "undefined" || fields["lastName"].trim() === "") {
            errors["lastName"] = true;
            isValidForm = false;
        }
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!fields["email"] || fields["email"] === "undefined" || !pattern.test(fields["email"])) {
            errors["email"] = true;
            errors["emailInvalid"] = true;
            isValidForm = false;
        }
        if (!fields["companyName"] || fields["companyName"] === "undefined" || fields["companyName"].trim() === "") {
            errors["companyName"] = true;
            isValidForm = false;
        }
        if (!fields["address1"] || fields["address1"] === "undefined" || fields["address1"].trim() === "") {
            errors["address1"] = true;
            isValidForm = false;
        }
        if (!fields["city"] || fields["city"] === "undefined" || fields["city"].trim() === "") {
            errors["city"] = true;
            isValidForm = false;
        }
        if (!fields["zipCode"] || fields["zipCode"] === "undefined" || fields["zipCode"].trim() === "") {
            errors["zipCode"] = true;
            isValidForm = false;
        }
        if (!fields["country"] || fields["country"] === "undefined" || fields["country"].trim() === "") {
            errors["country"] = true;
            isValidForm = false;
        }
        if (!fields["state"] || fields["state"] === "undefined" || fields["state"].trim() === "") {
            errors["state"] = true;
            isValidForm = false;
        }
        await this.setState({ errors });
        return isValidForm;
    }
    setJwtTokenLocaleStore = async (jwtToken) => {
        KCStore.setItem("Access-Token", jwtToken);
    }

    async registerAPICall(jwtToken, bodyParams) {
        await this.setJwtTokenLocaleStore(jwtToken);
        http({
            method: "POST",
            url: DeviceRegistration.chargifySubscriptionCreation,
            headers: {
                'Content-Type': 'application/json',
            },
            data: bodyParams
        }).then(async (response) => {
            if (response.data.httpCode === 'KC_ERR_200') {
                await this.checkStatus();
            } else {
                this.setState({ loaderIconVisible: false, showLoaderText: false, error_modal: { open: true, message: '<div class="errorModalContent"><p>Something went wrong !!</p><p> Apologies, we were not able to complete your account creation. Please try registration again.</p></div>', isHtml: true } });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false, showLoaderText: false, error_modal: { open: true, message: '<div class="errorModalContent"><p>Something went wrong !!</p> <p>Apologies, we were not able to complete your account creation. Please try registration again.</p></div>', isHtml: true } });
        });
    }

    convertToDollor = (input) => {
        return input === 0 || input === '0' ? input : parseInt(input, 10) / 100;
    }

    render() {
        const { loaderIconVisible, countryCodeList, plan, fields, errors, error_modal, success_modal, showLoaderText } = this.state;
        const isPro = plan && plan.price && plan.pricingTiers ? plan.pricingTiers.length && !plan.isFreeTrial : false;
        const { formatMessage } = this.props.intl;

        return (
            <div id="adminWrapper">
                <div className="toggleTransparent"></div>
                <div className="sideNavToggleTransparent"></div>
                <div className="userToggleTransparent"></div>
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                    {showLoaderText && <div className='loadingProgressInText'><FormattedMessage id='KC2564'/></div>}
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <div className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table">&nbsp;</div>
                    <div id="login-pre">
						<LanguagePreferenceCommon/>
					</div>
                </nav>
                <div id="page-content-wrapper">
                    <div className="subscriptionWrapper">
                        <h2><FormattedMessage id='KC2540'/></h2>
                        <div className="subscriptionInWrapper">
                            <div className="billingOForm">
                                <ul>
                                    <li>
                                        <h2><FormattedMessage id='KC2541'/></h2>
                                        <div className="billingPlanForm marginBottom">
                                            <ul>
                                                <li className="colm50">
                                                    <label><FormattedMessage id='KC2542'/> *</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder= {formatMessage({ id: "KC2542" })} value={fields.firstName} onChange={(e) => this.onChangeEvent(e, "firstName")} onBlur={() => this.nameBlurEvent("firstName")} autoFocus />
                                                    </div>
                                                    {errors["firstName"] && <div className="errorMsg"><FormattedMessage id='KC2552'/></div>}
                                                </li>
                                                <li className="colm50">
                                                    <label><FormattedMessage id='KC2543'/> *</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder={formatMessage({ id: "KC2543" })} value={fields.lastName} onChange={(e) => this.onChangeEvent(e, "lastName")} onBlur={() => this.nameBlurEvent("lastName")} />
                                                        {errors["lastName"] && <div className="errorMsg"><FormattedMessage id='KC2553'/></div>}
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li className='colm50 disabled'>
                                                    <label><FormattedMessage id='KC2563'/> *</label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" placeholder={formatMessage({ id: "KC2563" })} value={fields.email} />
                                                        <span className="requiredField">*</span>
                                                        {errors["email"] && <div className="errorMsg">{fields && fields.email && fields.email.trim() === '' ? `Email is required` : errors['emailInvalid'] ? `Please provide valid email` : `Email already exist`}</div>}
                                                    </div>
                                                </li>
                                                <li className='colm50'>
                                                    <label><FormattedMessage id='KC2554'/></label>
                                                    <div className="input-group">
                                                        <select className="form-control countryCode" onChange={(e) => this.onChangeEvent(e, 'countryCode')}>
                                                            {
                                                                countryCodeList && countryCodeList.length && countryCodeList.map((item, i) =>
                                                                    <option key={i} value={item.code} selected={item.code === this.state.fields.countryCode}>{item.code}</option>
                                                                )
                                                            }
                                                        </select>
                                                        <input type="number" className="form-control phoneNumber" placeholder={formatMessage({ id: "KC2554" })} value={fields.phoneNumber} onChange={(e) => this.onChangeEvent(e, "phoneNumber")} onBlur={() => this.phoneblurevent()} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <h2><FormattedMessage id='KC2544'/></h2>
                                        <div className="billingPlanForm">
                                            <ul>
                                                <li>
                                                    <label><FormattedMessage id='KC2545'/> *</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder={formatMessage({ id: "KC2545" })} value={fields.companyName} onChange={(e) => this.onChangeEvent(e, "companyName")} onBlur={() => this.nameBlurEvent("companyName")} />
                                                        {errors["companyName"] && <div className="errorMsg"><FormattedMessage id='KC2555'/></div>}
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <label><FormattedMessage id='KC2546'/> </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder={formatMessage({ id: "KC2546" })} value={this.state.fields.restaurantName} onChange={(e) => this.onChangeEvent(e, "restaurantName")} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="billingPlanForm">
                                            <ul>
                                                <li className="colm50">
                                                    <label><FormattedMessage id='KC2547'/> </label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" placeholder={formatMessage({ id: "KC2547" })} value={this.state.fields.companyEmailAddress} onChange={(e) => this.onChangeEvent(e, "companyEmailAddress")} />
                                                    </div>
                                                </li>
                                                <li className="colm50">
                                                    <label><FormattedMessage id='KC2548'/> </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder={formatMessage({ id: "KC2548" })} value={fields.vatNumber} onChange={(e) => this.onChangeEvent(e, "vatNumber")} />
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li className="colm50">
                                                    <label><FormattedMessage id='KC2549'/> * </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder={formatMessage({ id: "KC2549" })} value={fields.address1} onChange={(e) => this.onChangeEvent(e, "address1")} onBlur={() => this.nameBlurEvent("address1")} />
                                                        {errors["address1"] && <div className="errorMsg"><FormattedMessage id='KC2558'/></div>}
                                                    </div>
                                                </li>
                                                <li className="colm50">
                                                    <label><FormattedMessage id='KC2550'/></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder={formatMessage({ id: "KC2550" })} value={fields.address2} onChange={(e) => this.onChangeEvent(e, "address2")} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="billingPlanForm">
                                            <ul>
                                                <li className="colm50">
                                                    <label><FormattedMessage id='KC1045'/> *</label>
                                                    <div className="input-group">
                                                        {this.renderCountry()}
                                                    </div>
                                                    {errors["country"] && <div className="errorMsg"><FormattedMessage id='KC2559'/></div>}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="billingPlanForm">
                                            <ul>
                                                <li className="colm33">
                                                    <label><FormattedMessage id='KC0098'/> *</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder={formatMessage({ id: "KC0098" })} value={fields.city} onChange={(e) => this.onChangeEvent(e, "city")} onBlur={() => this.nameBlurEvent("city")} />
                                                        {errors["city"] && <div className="errorMsg"><FormattedMessage id='KC2560'/></div>}
                                                    </div>
                                                </li>
                                                <li className="colm33">
                                                    <label><FormattedMessage id='KC0457'/> *</label>
                                                    <div className="input-group">
                                                        {this.renderState()}
                                                    </div>
                                                    {errors["state"] && <div className="errorMsg"><FormattedMessage id='KC2561'/></div>}
                                                </li>
                                                <li className="colm33">
                                                    <label><FormattedMessage id='KC2551'/> *</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder={formatMessage({ id: "KC2551" })} value={fields.zipCode} onChange={(e) => this.onChangeEvent(e, "zipCode")} onBlur={() => this.nameBlurEvent("zipCode")} />
                                                        {errors["zipCode"] && <div className="errorMsg"><FormattedMessage id='KC2562'/></div>}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* Commented this because chargify customer creation is not in self signup flow */}
                                        {/* {isPro ? <div className="billingPlanForm">
                                            <React.Fragment>
                                                <h2 className="billingInfo">Billing Information</h2>
                                                <div className="billingInfoForm">
                                                    <CreditCard ref="creditCardForm" />
                                                </div>
                                            </React.Fragment>
                                        </div> : null} */}
                                    </li>
                                    <li>
                                        <h2><FormattedMessage id='KC2557'/></h2>
                                        <div className="planSummary">
                                            <h4><FormattedMessage id='KC2556'/> <span>${plan.price}</span></h4>
                                        </div>
                                    </li>
                                </ul>
                                <button className="btn btn-secondary billingFormRegister"
                                    onClick={() => this.registerCustomer()} type="button"><FormattedMessage id='KC0888'/></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} isHtml={error_modal.isHtml} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </div>
        )
    }

    loadStateDropdown = async (countryname, countryiso2code) => {
        await http.get(DeviceRegistration.stateDropdownApi, {
            headers: {
                'Content-Type': 'application/json',
                countryname,
                countryiso2code
            },
            data: {}
        }).then(async response => {
            if (response && response.data) {
                this.setState({ stateDropdown: response.data })
            }
        }).catch(err => {
        });
    }

    renderCountry() {
        const { countryDropdown } = this.state;
        return <select
            className="form-control"
            onChange={async (e) => {
                const split = e.target.value ? e.target.value.split('||') : [];
                let fields = this.state.fields;
                fields['welbiltInvoicingEntity'] = split.length && split[1] ? split[1] : '';
                fields['country'] = split.length ? split[0] : '';
                fields['state'] = '';
                const countryName = split.length ? split[split.length - 1] : '';
                let errors = this.state.errors;
                errors['country'] = '';
                this.setState({
                    fields
                });
                await this.loadStateDropdown(countryName, fields['country'])
            }}>
            <option value="0">Select Country</option>
            {countryDropdown && countryDropdown.length ? countryDropdown.map((country, index) => {
                return (
                    <option key={index} value={country.country_iso2_code + '||' + country.saas_invoice_entity + '||' + country.country_name}>{country.country_name}</option>
                )
            }) : null}
        </select >;
    }

    renderState() {
        const { stateDropdown, fields } = this.state;
        return <select
            className="form-control"
            onChange={async (e) => {
                let fields = this.state.fields;
                const split = e.target.value ? e.target.value.split('||') : [];
                fields['state'] = split.length ? split[0] : '';
                fields['stateName'] = split.length ? split[split.length - 1] : '';
                this.setState({
                    fields
                });
                let errors = this.state.errors;
                errors['state'] = '';
            }}>
            <option value="0">Select State</option>
            {stateDropdown && stateDropdown.length ? stateDropdown.map((state, index) => {
                return (
                    <option selected={fields['state'] === state.state_code} key={index} value={state.state_code + '||' + state.state_name}>{state.state_name}</option>
                )
            }) : null}
        </select >;
    }
}

function mapStateToProps(state) {
    return {
        locale: state.localeInfo.locale,
        languageList: state.localeInfo.languages,
    }
}
export default connect(mapStateToProps)(injectIntl(SaasRegistrationForm));
