import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getLocation } from '../../../actions/listView/locations/locationAction';
import { getFilterbyLocations } from '../../../actions/listView/locations/filterbyAction';
import  LocationHeader  from './../../layoutSection/allLocationHeaderLayout';


class FilterByLocation extends Component {	
	constructor(props) {
		super(props);	
		this.state ={
			locationList:"",
			selectedList:[],
			search:'',
		}				
		this.GetSelectedLocationName=this.GetSelectedLocationName.bind(this);	
	}

	updatesearch(e)
	{
		this.setState({search:e.target.value});
	}

	componentDidMount() {
		let { state : previousState }  = this.props.location;
		let { prevSelectedFilterValue : prev_selectedLocationList }  = previousState;
		if(prev_selectedLocationList && prev_selectedLocationList.length>0){
			this.setState({
		 selectedList : prev_selectedLocationList
		});
		}

		let selectedRegionList = this.props.selectedRegions ? this.props.selectedRegions :[];
		let selectedCgids =[];
		selectedRegionList.map(c =>{ if(c.checked ===  true){
			return selectedCgids.push(c.cust_gp_id)
        }});
		this.props.getLocation(selectedCgids.join(',')); 	
	}

	GetSelectedLocationName(e, item) {
		let isChecked = e.target.checked;
		
		this.state.selectedList.find(c =>{ if(c.cust_gp_id === item.cust_gp_id){
			return c.checked = isChecked 
		}});
		
		this.setState({
			selectedList:this.state.selectedList
		});
		
		this.props.getFilterbyLocations(this.state.selectedList);
		// var d = document.getElementById("spannavNotifiStatus");
		// d.className = "navNotifiStatus";
	}

	callLocationAction() {
		this.state.selectedList && this.state.selectedList.length >0 && this.props.getFilterbyLocations(this.state.selectedList);
	}

	render() { 
			let { selectedList : _locaionList  } = this.state;			
			const {locationInfo} = this.props; 
			
			if (locationInfo && locationInfo.length > 0) {
				if(_locaionList && _locaionList.length>0){
									 for (let index = 0; index < _locaionList.length; index++) {
						 for (let k = 0; k < locationInfo.length; k++) {
								if(locationInfo[k].cust_gp_id === _locaionList[index].cust_gp_id)
								{
								if(_locaionList[index].checked){
									locationInfo[k].checked=true;
									break;
								} 
								}
						 }
						}
						//this.state.selectedList = locationInfo;
						this.setState({
							selectedList:locationInfo,
						})
						_locaionList = this.state.selectedList;
				}else{
					//this.state.selectedList = locationInfo;
					this.setState({
						selectedList:locationInfo,
					})
					_locaionList = this.state.selectedList;
				}	 
			}				
			
			const search=this.state.search;
			const searchedItemInFilter=_locaionList.filter(
				(contact)=>{
					return contact.group_name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ;				
				}
			);
			_locaionList=searchedItemInFilter;

			let previouspage = "/dashboardLocations";
			if (this.props.location.state !== undefined)
			{
				previouspage = this.props.location.state.prevPath.previouspage;
			}

		return (			
				<div>							
					<div id="wrapper" className="disableNavLink">
						<div class="toggleTransparent"></div>
						<div class="loadingProgress">
							<div class="loadingProgressIn"></div>
						</div>
						<LocationHeader />
						<div class="navFilterWrapper" style={{display: 'block'}}>
							<div id="CountryFilter">
								<div class="filterHeader">
									<ul>
										<li>
											<Link onClick={this.callLocationAction.bind(this)} to={{pathname:'/locationsView/filterBy/', state : {paramvalue: this.state.locationList, selectedList: this.state.selectedList, prevPath: {previouspage}} }}   title="Back" class="backIcon" id="backToFilterListDiv">&nbsp;</Link>																						
										</li>										
										<li>
											Location
										</li> 																
										<li>
											<Link to={previouspage} title="Finish">Finish</Link>
											{/* <Link to={previouspage} title="Filter by" class="cancelIcon cancelFilterListDiv">&nbsp;</Link> */}
										</li>
									</ul>
								</div> 
								
								<div class="filterListOuter">
									<input class="form-control headerFilterSearch" value={this.state.search} onChange={this.updatesearch.bind(this)} type="text" placeholder="Search"/> 
									<div class={_locaionList.length > 3 ? 'countryCheckbox withMorethanThree' : 'countryCheckbox'}> 
										{Object.keys(_locaionList).map((item, i) => (
											<div class="customCheckbox customCheckboxWlabel">
												<input onChange={(e) => this.GetSelectedLocationName(e, _locaionList[i])} id= {_locaionList[i].cust_gp_id} class="chkGroupName" value= {_locaionList[i].group_name} checked={_locaionList[i].checked} name="groupName" type="checkbox"/>
												<label for= {_locaionList[i].cust_gp_id}>{_locaionList[i].group_name}</label>											 
											</div>
										))}																			
									</div>  
								</div>
							</div>							
						</div>
    				</div>
					</div>  
				)
			}
		};	

		function mapStateToProps(state) {
			return {
				locationInfo: state.locationInformation.locationInformation,				
				FindSelectedLocationName:state.FindSelectedLocationName,
				selectedRegions: state.filterInformation.selectedRegions,
			}
		}

		function mapDispatchToProps(dispatch) {
			return bindActionCreators({ getLocation, getFilterbyLocations }, dispatch);			
		}

export default connect(mapStateToProps, mapDispatchToProps)(FilterByLocation);
