import React from 'react';
import Styles from './snackbar.module.css';
import { useDispatch } from 'react-redux';
import { setSnackBarData } from '../../actions/snackbar';
import { snackbarClosingSecond } from '../../components/navigation/organizationManagement/organizationManagement';

const areEqual = (prevProps, nextProps) => {
    return prevProps.isActive !== nextProps.isActive;
};

const Snackbar = (props => {
    const { snackBarObj } = props;
    const [message, setMessage] = React.useState('')
    const [isActive, setIsActive] = React.useState(false)
    const [bg, setBg] = React.useState('#4CAF50');
    const dispatch = useDispatch();

    React.useEffect(() => {
        const { message, isActive, seconds, state } = snackBarObj;
        if (isActive) {
            openSnackBar(message, seconds, state);
        }
    }, [snackBarObj])

    const snackBarToast = (message, state) => {
        return new Promise((reslve, _) => {
            reslve(dispatch(setSnackBarData({ message, isActive: false, state, seconds: snackbarClosingSecond })))
        })
    }

    const openSnackBar = (message = 'Something went wrong...', secondsToClose, state) => {
        const bgColor = state === 'approve' ? '#0099FF' : state === 'success' ? '#4CAF50' : '#C29D2D';
        setBg(bgColor);
        setIsActive(true);
        setMessage(message);
        setTimeout(() => {
            setIsActive(false);
            snackBarToast('', '')
        }, secondsToClose || 7000);
    }
    return (
        isActive ? <div className={isActive ? [Styles.snackbar, Styles.show].join(" ") : Styles.snackbar} style={{ backgroundColor: bg }}>
            <span className={Styles.startIcon}>
                {(snackBarObj.state === 'success' || snackBarObj.state === "approve") ? <svg width="14" height="14" viewBox="0 0 512 512" fill="#ffffff">
                    <polygon points="437.3,30 202.7,339.3 64,200.7 0,264.7 213.3,478 512,94 " />
                </svg> : <svg fill="#ffffff" width="18" height="18" viewBox="-2 -2 24 24" preserveAspectRatio="xMinYMin" ><path d='M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-10a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm0-1a1 1 0 1 1 0-2 1 1 0 0 1 0 2z' /></svg>}
            </span>
            {message}
            <span onClick={() => setIsActive(false)} className={Styles.closeIcon}><svg fill="#ffffff" width="12" height="12" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" /></svg></span>
        </div> : null
    )
});



export default Snackbar;