import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../src/service/authService";
import appMessageConfig from '../../src/utils/messageConfig';

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {

  const getAuthorizedFeature = auth.getFeature(path);
  auth.clearLastSessionData();
   
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth.getJwt())
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          );
     else if(!getAuthorizedFeature.feature_active){
      return (
        <Redirect
          to={{
            pathname: "/un-authorized",
            state: { from: props.location }
          }}
        />
      );
     }
        return Component ? <Component {...props} AuthorizedFeature ={getAuthorizedFeature} IsTokenExpired = {auth.IsTokenTimeExipired()} ExpiredMessage={ appMessageConfig.SessionExpire } /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
