import React, { Component } from 'react';
import http from '../../service/httpService';
import auth from '../../service/authService';
import { BaseService } from '../../service/api';
import ErrorMoal from '../modal/error-model';
import SuccessMoal from '../modal/success-model';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import $ from 'jquery';


class CreateAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			isVerified: false,
			errors: {
				emailid: '',
				recaptcha: ''
			},
			error_modal:{
			open:false,
			message:""
			},
			success_modal: {
			open:false,
			message:""
			},
			loaderIconVisible:false,
			userData: {},
			navigateRoute:''
		};
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleSignInSubmitAction = this.handleSignInSubmitAction.bind(this);
		this.onSigninclickHandler = this.onSigninclickHandler.bind(this);
		this._reCaptchaRef = React.createRef();
		this.verifyCallback = this.verifyCallback.bind(this);
	}

	componentWillMount = () =>{
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");
		this.updateLoginDimensions(); 
	}
	updateLoginDimensions() {
		var windowHeight = $(window).height(); 
		var welcomTextH = $(".welcomText").height();
		var bgAdminH = $(".bgAdmin").height() - 25; 
		var loginButtonH = $(".loginButton > ul:nth-child(2)").height();
		var cookieWrapperH = $(".cookieWrapper").height() + 10; 
		//Sign In & Sign Up Modal
		var maintenanceTextH = $(".maintenanceText ").height() + 35;
		var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH) + (loginButtonH) + (cookieWrapperH) + (maintenanceTextH));
		var loginScrollbarHeightS = ((windowHeight) -(welcomTextHbgAdminH)) - 60;   
		$(".loginButton > ul:nth-child(1) > li").css("height", loginScrollbarHeightS); 
	}
	setLoader = (status) => {
		this.setState({
			loaderIconVisible: status
		});
	}

	handleEmailChange(event) {
		this.setState({ email: event.target.value });
		event.preventDefault();
	}

	handleSignInSubmitAction(event) {
		console.log('Your email is: ' + this.state.email);
		event.preventDefault();
	}

	nextPath(path) {
		$('.modal-backdrop').hide();
		this.props.history.push(path);
	}

	emailblurevent(event) {
		let inputdataemailobj = event.target.value;
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if(!pattern.test(inputdataemailobj)) {			
			this.setState({
					errors: {
						emailid: 'Please enter valid email-ID.'										
					}
			})												
		} 
		return false;	
	}

	validateForm() {
		this.setLoader(true);

		let fields = this.state;
		let errors = {};
		let formIsValid = true;
  		  
		if (!fields["email"]) {			
		  formIsValid = false;
		  errors["emailid"] = "Please enter your email-ID.";
		}
  
		if (typeof fields["email"] !== "undefined") {
		  //regular expression for email validation
		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(fields["email"])) {
			formIsValid = false;
			errors["emailid"] = "Please enter valid email-ID.";
		  }
		}
      
		if (!this.state.isVerified && this.state.isVerified !== undefined) {
			formIsValid = false;
			errors["recaptcha"] = "Please select reCAPTCHA.";
		}
		
		this.setState({
		  errors: errors
		});
		return formIsValid;
		}

	onSigninclickHandler(e) {
		if (this.validateForm()) {
			http({
			method: "post",
			url: BaseService.create_Account,
			data: { parentuseremail: this.state.email },
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			if(response.data.responseCode === 200) { 	 
				this.setState({
					success_modal: {
						open: true,
						message: 'You successfully created your Account!'
					},
				});
			}
		}).catch(err => {
			if (!err.response.data || !err.response.data.body) {
				this.props.history.push('/registerAccount',{isExists: true})
			}
			else if (err.response.data.KC_ERR_400 !== undefined && err.response.status === 400) {
				this.setState({
					error_modal: {
						open: true,
						message: err.response.data.KC_ERR_400
					},
				});
			}
			else if (err.response.data.body.responseCode !== null && err.response.data.body.responseCode === 400) {
				this.setState({
					error_modal: {
						open: true,
						message: err.response.data.body.message
						},
						navigateRoute:'Navigate'
				});
			}
		});
	} 
		this.setLoader(false);
	}

	closeModel = (err) => {
		if(this.state.navigateRoute === 'Navigate'){
			this.props.history.push('/registerAccount',{email:this.state.email})
		}
		this.setState({
			loaderIconVisible : false,
			error_modal:{
				open:false,
				message:""
			},
			success_modal: {
				open:false,
				message:""	
			}
	   });
		 }
		 
	onSuccessCloseModal = () => {
		this.setState({ 
			loaderIconVisible : false,
			success_modal:{
				open:false
			}
		})
		this.props.history.push(`/`);
	}

	componentDidMount() {
		if (auth.getJwt())
			return this.props.history.push('/dashBoardLocations');
		$("body").removeClass("modal-open");
		setTimeout(()=>{
			var width = $('.g-recaptcha').parent().width();
			var scale = width / 304;
			$('.g-recaptcha').css('transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('transform-origin', '0 0');
			$('.g-recaptcha').css('-webkit-transform-origin', '0 0');
			$('.g-recaptcha').css('display', 'block');
		}, 100);
	}

	verifyCallback = (recaptchaToken) => {
		if (recaptchaToken) {
			this.setState({
				isVerified: true,
				errors: {
					...this.state.errors,
					recaptcha: ''
				}
			});
		}
	}

	render() {
		const { error_modal, success_modal, loaderIconVisible} = this.state;	
		return (
			<div className="loginBg">
				<div id="adminWrapper">   
					{/* <!-- Loading Progress --> */}
					<div id="loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
            <div className="loadingProgressIn"></div>
        	</div>
					{/* <!-- //Loading Progress --> 
					<!-- Fixed Header navbar --> */}
					<nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">   
                		<Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>  
        			</nav>
					{/* <!-- //Fixed Header navbar -->
					<!-- //Fixed Header navbar --> */}
					<div id="page-wrapper">
					<div className="welcomText">Welcome</div>
						{/* <!-- Login Form --> */}
						<div class="LoginBtnWrapper">
							<div id="loginBtnFormID">
								<div class="loginButton">
									<ul>
										<li>&nbsp;</li>
										<li>
											<div className="loginIWrapper">
												<div className="existingLoginForm">
													<ul>
														<li className="form-group">
															<div className="input-group">
																<input className="form-control" placeholder="Enter email" type="text" name="emailid" value={this.state.email} onChange={this.handleEmailChange} /></div>
															<div className="errorMsg">{this.state.errors.emailid}</div>
														</li>
														<li>
														   <div className="g-recaptcha">
										                     <ReCAPTCHA ref={this._reCaptchaRef} sitekey="6Lfm7poUAAAAAAhOzR85EpbAqgbQ1KiIhai2HyBG" onChange={this.verifyCallback} />
										                   </div>
															<div className="errorMsg">{this.state.errors.recaptcha}</div>
														</li>
														<li className="form-group pass">
															<button className="btn btn-secondary existingSingINBtn" type="submit" id="existingSignInButton" onClick={this.onSigninclickHandler}>Sign Up</button>
														</li>
													</ul>
												</div>
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
									<ul>
										<li>
										</li>
										<li>
											<div className="loginIWrapper">
											<Link to="/Login" title="Login">Login</Link>
											<Link to="/" title="Language" className="languageSel"></Link>
											</div>
										</li> 
										<li>&nbsp;</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- //Login Form -->   */}
					</div>
				</div>
				<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel}/>
				<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal}/>
			</div>
		)
	}
};

export default CreateAccount;
