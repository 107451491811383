import { SET_USERMANAGEMENT_DATA , SET_USERMANAGEMENT_DATA_LOADING} from '../../../actions/stateActionsData/type';
const initialState = {
    loaderIconVisible: false,
    userManageListData: {
        userMgmtList:[]
    },
}
export default function(state = initialState, action){
    switch(action.type) {
        // Reducer for user management
        case SET_USERMANAGEMENT_DATA:
        return {
            ...state,
            userManageListData: action.payload
        }
        case SET_USERMANAGEMENT_DATA_LOADING: 
        return {
            ...state,
            loaderIconVisible: action.payload

         }
        default:
        return state;
    }
}

