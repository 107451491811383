import http from '../../service/httpService';
import { BaseService, ReportManagement } from '../../service/api';
export const PAN_VS_TIME_REQUEST = 'PAN_VS_TIME_REQUEST';
export const PAN_VS_TIME_SUCCESS = 'PAN_VS_TIME_SUCCESS';
export const PAN_VS_TIME_FAIL = 'PAN_VS_TIME_FAIL';

export function getPanVsTime(type, fromdate, todate, deviceId, UNIT_BRAND_ID) {
    let url;
    let filterType;
    switch (type) {
        case 'Day':
            filterType = 'daily';
            break;
        default:
            filterType = 'weekly';
    }

    url = `${BaseService.root}${ReportManagement.panVsTimeLineChart}`;

    return dispatch => {
        dispatch({
            type: PAN_VS_TIME_REQUEST
        })

        const header = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'brandid':UNIT_BRAND_ID,
            'unitid': deviceId,
            'filtertype': filterType,
            'startdate':fromdate,
            'enddate':todate,
            'reqdate': fromdate,
            'showtrend': "true"
        }

        if (filterType === 'weekly') {
            delete header.reqdate;
            header.startdate = todate;
            header.enddate = fromdate;
        }

        http.get(url,
            {
                headers: header,
                data: {}
            })
            .then(response => {
                dispatch({
                    type: PAN_VS_TIME_SUCCESS,
                    panVsTimeResponse: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: PAN_VS_TIME_FAIL,
                    error
                })
            })
    }
}