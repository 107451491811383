import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { mediaLibrary_Label } from '../../appConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Link } from 'react-router-dom';
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { getMediaAPIUrl, getMediaLibrary } from '../../service/mediaManagement/mediaLibrary';
import { getDeviceAPIUrl, getDeviceInfo } from '../../service/unitManagement/deviceinfo';
import ReactPlayer from 'react-player';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../utils/kcStorage';

am4core.useTheme(am4themes_animated);


class MediaLibrary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mediaLibraryInfo: [],
            mediaSpaceInformation: {},
            loaderIconVisible: true
        }
    }

    componentDidMount = async () => {
        let currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        let mediaUrl = getMediaAPIUrl(currentUnit.UNITID);
        let mediaInfo = await getMediaLibrary(mediaUrl);
        let deviceUrl = getDeviceAPIUrl(currentUnit.UNITID, "CREM");
        let deviceInfo = await getDeviceInfo(deviceUrl);

        if (mediaInfo) {
            this.setState({
                mediaLibraryInformation: mediaInfo
            });
        }

        if (deviceInfo) {
            this.setState({
                mediaSpaceInformation: deviceInfo.spaceDetails
            },()=>{
                this.storeAvailableSpace();
            });
        }

        

        setTimeout(() => {
            this.setState({
                loaderIconVisible: false,
            });
          }, 2000)
    }

    storeAvailableSpace = ()=>{
        const {mediaSpaceInformation} = this.state;
        let currentUnit = JSON.parse(KCStore.getItem('currentUnit')); 
        currentUnit.AVAILABLE_SPACE = mediaSpaceInformation && mediaSpaceInformation.spaceAvailable ? mediaSpaceInformation.spaceAvailable : 0;
        KCStore.setItem('currentUnit', JSON.stringify(currentUnit));
    }

    // Woopra
    replace= () => {
        WoopraEvents(Constants.REPLACE);
    }

    render() {
        const { mediaLibraryInformation, mediaSpaceInformation, errormsg, loaderIconVisible } = this.state;
        const { formatMessage } =  this.props.intl;
        
        const screenInfo = mediaLibraryInformation && mediaLibraryInformation.length > 0 ? mediaLibraryInformation.find((e) => e.mediaType === 'screensaver' || e.mediaType === 'compilation') : {};
        const preparationInfo = mediaLibraryInformation && mediaLibraryInformation.length > 0 ? mediaLibraryInformation.find((e) => e.mediaType === 'preparation') : {};
        let screenSaverSpaceOccupied = 0;
        let preparationSpaceOccupied = 0;
        let mediaSpaceInformationspaceAvailable = 80;
        let mediaTotalSize = 0;
        let screenSaverPercent;
        let preparationSpacePercent;
        let mediaSpaceInformationPercent;
        let screenSaverFileFormat = "";
        let preparationFileFormat = "";
        if (mediaSpaceInformation !== null && mediaSpaceInformation !== undefined) {
            screenSaverSpaceOccupied = mediaSpaceInformation.filesSpace && mediaSpaceInformation.filesSpace.length > 0.00 ? mediaSpaceInformation.filesSpace.find((e) => e.mediaType === 'SCREENSAVER') : {};
            preparationSpaceOccupied = mediaSpaceInformation.filesSpace && mediaSpaceInformation.filesSpace.length > 0.00 ? mediaSpaceInformation.filesSpace.find((e) => e.mediaType === 'PREPARATIONFILE') : {};
            mediaSpaceInformationspaceAvailable = mediaSpaceInformation.spaceAvailable;
            mediaTotalSize = screenSaverSpaceOccupied.size + preparationSpaceOccupied.size + mediaSpaceInformationspaceAvailable;
            screenSaverPercent = (screenSaverSpaceOccupied.size / mediaTotalSize) * 100 + '%';
            preparationSpacePercent = (preparationSpaceOccupied.size / mediaTotalSize) * 100 + '%';
            mediaSpaceInformationPercent = (mediaSpaceInformationspaceAvailable / mediaTotalSize) * 100 + '%';

            screenSaverFileFormat = previewfileformat(screenInfo !== undefined ? screenInfo.fileName : '');
            preparationFileFormat = previewfileformat(preparationInfo !== undefined ? preparationInfo.fileName : '');
        }

        function previewfileformat(FILE_NAME)
        {
            let previewformat = "";
            if (FILE_NAME !== undefined && FILE_NAME !== '')
            {
                let fileext = FILE_NAME.split('.');
                fileext = fileext[1];
                
                if(fileext === "png"){
                    previewformat = "image";
                }
                else if(fileext === "mp4"){
                    previewformat = "video";
                }
                else if(fileext === "zip"){
                    previewformat = "zip";
                }
            }
            return previewformat;
        }

        return (
            <div id="wrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
                    <div className="loadingProgressIn"></div>
                </div>
                <GlobalHeaderLayout activeClass="recipeNav" />
                <div id="page-content-wrapper">
                    <div class="pageHeader">
                        <ul>
                            {/* <li>
                                <Link to="/recipesLibrary" title="Recipes Library"> {receipeLibrary_Label} </Link>
                            </li> */}
                            <li>
                                <Link to="/mediaLibrary" title={formatMessage({id: "KC0152"})} className="active"> <FormattedMessage id="KC1924"/> </Link>
                            </li>
                        </ul>
                    </div>
                    <div class="gridViewTable gridViewcolm6DSM">
                        <div class="gridView">
                            <div class="colm6D colm6D1">
                                <div class="colmDi">
                                    <h4><FormattedMessage id="KC0206" />  &nbsp; <span class="red-txt">{errormsg}</span><span class="cornerIcon"></span></h4>
                                </div>
                                <div class="screensaverImgView">
                                    {screenInfo && screenSaverFileFormat === "image" && screenInfo.status !== "DELETED" && <img src={screenInfo !== undefined ? screenInfo.mediaImagePath : ''} alt={formatMessage({id:"KC1983"})} title={formatMessage({id:"KC1983"})} />}
                                    {screenInfo && screenSaverFileFormat === "video" && screenInfo.status !== "DELETED" && <ReactPlayer url={screenInfo.mediaImagePath} playing controls width="100%" />}
                                    {screenInfo && screenSaverFileFormat === "zip" && screenInfo.status !== "DELETED" && <div class="ImgIconView"><img src={require("../../images/icons/ZipIcon.png")} alt={formatMessage({id:"KC1985"})} title={formatMessage({id:"KC1985"})} /></div>}
                                    {screenInfo && screenInfo.status === "DELETED" && <div class="ImgIconView"><img src={require("../../images/icons/DeleteIcon.png")} alt={formatMessage({id:"KC1984"})} title={formatMessage({id:"KC1984"})} /></div>}
                                </div>
                                <div class="screensaverStatus"></div>
                                <div class="colmDi">
                                    <div class="mediaLibraryTable">
                                        <ul>
                                            <li>
                                                <ul>
                                                    <li>
                                                        <label><FormattedMessage id="KC0677"/></label>
                                                        <span class="labelVal">{screenInfo !== undefined ? screenInfo.fileName : ''}</span>
                                                    </li>
                                                    <li>
                                                        <label><FormattedMessage id="KC0048"/></label>
                                                        <span class="labelVal">{screenInfo !== undefined ? screenInfo.category : ''}</span>
                                                    </li>
                                                    <li>
                                                        <label><FormattedMessage id="KC0212"/></label>
                                                        <span class="labelVal">{screenInfo !== undefined ? screenInfo.resolution : ''}</span>
                                                    </li>
                                                    <li>
                                                        <label><FormattedMessage id="KC0213"/></label>
                                                        <span class="labelVal">{screenInfo !== undefined ? screenInfo.size : ''}</span>
                                                    </li>
                                                    {/* <li>
                                                    <label>Version</label>
                                                    <span class="labelVal">{screenInfo.version}</span>
                                                </li> */}
                                                    <li>
                                                        <label><FormattedMessage id="KC0214"/></label>
                                                        <span class="labelVal">{screenInfo !== undefined ? screenInfo.installed : ''}</span>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <button type="button" class="btn btn-default previewBtn"><Link to={{pathname:'/cremMediaPreperation',state : {replaceFrom: 'ScreenSaver', prevPath:'/mediaLibrary' }}} onClick={()=>this.replace()}><FormattedMessage id="KC0215"/></Link></button>
                                        {/* <button type="button" class="btn btn-default previewBtn" disabled="disabled">Preview</button> */}
                                    </div>
                                </div>
                            </div>
                            <div class="colm6D colm6D2">
                                <div class="colmDi">
                                    <h4><FormattedMessage id="KC0210"/> &nbsp; <span class="red-txt">{errormsg}</span><span class="cornerIcon"></span></h4>
                                </div>
                                <div class="preparationImgView">
                                    {preparationInfo && preparationFileFormat === "image" && preparationInfo.status !== "DELETED" && <img src={preparationInfo !== undefined ? preparationInfo.mediaImagePath : ''} alt={formatMessage({id:"KC0210"})} title={formatMessage({id:"KC0210"})} />}
                                    {preparationInfo && preparationFileFormat === "video" && preparationInfo.status !== "DELETED" && <ReactPlayer url={preparationInfo.mediaImagePath} playing controls  width="100%" />}
                                    {preparationInfo && preparationFileFormat === "zip" && preparationInfo.status !== "DELETED" && <div class="ImgIconView"><img src={require("../../images/icons/ZipIcon.png")} alt={formatMessage({id:"KC1985"})} title={formatMessage({id:"KC1985"})} /></div>} 
                                    {preparationInfo && preparationInfo.status === "DELETED" && <div class="ImgIconView"><img src={require("../../images/icons/DeleteIcon.png")} alt={formatMessage({id:"KC1984"})}  title={formatMessage({id:"KC1984"})} /></div>} 
                                </div>
                                <div class="preparationStatus"></div>
                                <div class="colmDi">
                                    <div class="mediaLibraryTable">
                                        <ul>
                                            <li>
                                                <ul>
                                                    <li>
                                                        <label><FormattedMessage id="KC0677"/></label>
                                                        <span class="labelVal">{preparationInfo !== undefined ? preparationInfo.fileName : ''} </span>
                                                    </li>
                                                    <li>
                                                        <label><FormattedMessage id="KC0048"/></label>
                                                        <span class="labelVal">{preparationInfo !== undefined ? preparationInfo.category : ''}</span>
                                                    </li>
                                                    <li>
                                                        <label><FormattedMessage id="KC0212"/></label>
                                                        <span class="labelVal">{preparationInfo !== undefined ? preparationInfo.resolution : ''}</span>
                                                    </li>
                                                    <li>
                                                        <label><FormattedMessage id="KC0213"/></label>
                                                        <span class="labelVal">{preparationInfo !== undefined ? preparationInfo.size : ''}</span>
                                                    </li>
                                                    {/* <li>
                                                        <label>Version</label>
                                                        <span class="labelVal">{preparationInfo.version}</span>
                                                    </li> */}
                                                    <li>
                                                        <label><FormattedMessage id="KC0214"/></label>
                                                        <span class="labelVal">{preparationInfo !== undefined ? preparationInfo.installed : ''}</span>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <button type="button" class="btn btn-default previewBtn"><Link to={{pathname:'/cremMediaPreperation',state : {replaceFrom: 'Preparation', prevPath:'/mediaLibrary' }}}><FormattedMessage id="KC0215"/></Link></button>
                                        {/* <button type="button" class="btn btn-default previewBtn" disabled="disabled">Preview</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gridViewTable">
                        <div class="gridView">
                            <div class="customProgressBar" style={{ width: screenSaverPercent }}>
                                <div class={screenSaverPercent === '0%' ? 'displaynone' : screenSaverPercent === undefined ? 'displaynone' : 'screensaverSize'}>
                                    <div class="mediaTextH2"><FormattedMessage id="KC0209"/></div>
                                    <div class="mediaTextH3">{screenSaverSpaceOccupied === undefined ? 0.00 : screenSaverSpaceOccupied.size === undefined ? 0.00 : screenSaverSpaceOccupied.size.toFixed(2)} <FormattedMessage id="KC1498"/></div>
                                </div>
                            </div>
                            <div class="customProgressBar" style={{ width: preparationSpacePercent }}>
                                <div class={preparationSpacePercent === '0%' ? 'displaynone' : preparationSpacePercent === undefined ? 'displaynone' : 'preparationFileSize'}>
                                    <div class="mediaTextH2"><FormattedMessage id="KC0210"/></div>
                                    <div class="mediaTextH3">{preparationSpaceOccupied === undefined ? 0.00 : preparationSpaceOccupied.size === undefined ? 0.00 : preparationSpaceOccupied.size.toFixed(2)} <FormattedMessage id="KC1498"/></div>
                                </div>
                            </div>
                            <div class="customProgressBar" style={{ width: mediaSpaceInformationPercent }}>
                                <div class="spaceAvailable">
                                    <div class="mediaTextH2"><FormattedMessage id="KC0211"/></div>
                                    <div class="mediaTextH3">{mediaSpaceInformationspaceAvailable === undefined ? 0.00 : mediaSpaceInformationspaceAvailable === undefined ? 0.00 : mediaSpaceInformationspaceAvailable.toFixed(2)} <FormattedMessage id="KC1498"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrentLocation, getCurrentUnit }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MediaLibrary));
