import React, { Component } from 'react';
import { Link,withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LogoutForm from "../logout/logoutForm";
import MainLayout from "../../layout/mainLayout";
import ErrorMoal from '../modal/error-model';
import  auth from '../../service/authService';
import  broadCastTokenEvent from '../../events/tokenAuthrizer';
import  authHelper from '../../utils/authHelper';
import  messageConfig from '../../utils/messageConfig';
import FilterBase from '../FilterBase/FilterBase';
import appconstants from '../../utils/appConstants';
import  pointerDevice from '../../utils/pointerDeviceFinder';
import GlobalSearchBar from '../../components/globalSearchBar/globalSearchBar';
import $ from 'jquery';
import LanguagePreferenceCommon from '../../components/languageDropdown/LanguagePreferenceCommon';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../utils/kcStorage';

class UserManagementHeader extends Component {
    constructor(props) {
        super(props);
        this.toggleHiddenforFilters = this.toggleHiddenforFilters.bind(this);   
        this.state = {
            isHidden: false,
            isHiddenforFilters:false,
            selectedCountries: [],
            selectedRegions: [],
            selectedLocations: [],
            selectedCustomers: [],
            selectedBrands: [],
            selectedModels: []
            }     
      }

      componentDidMount = () =>{        
        this.subscription = broadCastTokenEvent.IsReceiveJwtTokenExpired().subscribe(isJwtExpired => {
            if (isJwtExpired)
                 this.setState({ IsTokenExpired:  isJwtExpired });
        });
        this.ClearAll();
        let zendeskscript = document.getElementById('ze-snippet');
		if (zendeskscript) {
			zendeskscript.remove();
		}
    }

	showLoginForm = (event, isHidden) => {	
        this.setState({
            isHidden: !isHidden
        });
        if(this.state.isHidden){
            $('#backdropElement').click()
        }
        event.preventDefault();  
      }

      showLoginbg = (event, isHidden, isHiddenforFilters) => {
          if(this.state.isHidden === true){
              this.setState({
                  isHidden: !isHidden
              });
              $('#backdropElement').click()
          }
        if(this.state.isHiddenforFilters === true){
            this.setState({
                isHiddenforFilters: !isHiddenforFilters
            });
            $('#backdropElement').click()
        }
        event.preventDefault();  
      }
    
      hideLoginForm = (event) =>  { 
        event.preventDefault();  
        this.setState({
            isHidden: false
         });   
         if(pointerDevice.IsTouchDevice() && event.target) {
            let targetClass = event.target.getAttribute('class');
            if(targetClass && targetClass.indexOf('navFilter')!==-1){
                this.showFilter(event, false);
            }
         }      
       }

       showFilter = (event, isShowFilter) =>{
        event.preventDefault();
        this.setState({
            isHiddenforFilters: !isShowFilter
          });
    }

       toggleHiddenforFilters (event) {
        this.setState({
                isHiddenforFilters: false
        });
        event.preventDefault();
        if(pointerDevice.IsTouchDevice() && event.target) {  
            let targetClass = event.target.getAttribute('class');
            if(targetClass && targetClass.indexOf('navUser')!==-1){
                this.showLoginForm(event, false);
            }
         }
       } 

        nextPath(path) {		
            this.props.history.push("/");
            }

            editInformation() {
                this.props.history.push('/myinformationedit');
            }

        errorCloseModel = () => {     
          authHelper.deleteLocalSession();
          window.location.href ='/';
        }	

        ClearAll = ()=>{
            var d = document.getElementById("spannavNotifiStatus");
            d.className = "";
        }	


  render() {
      const { formatMessage } = this.props.intl;
      let { activeClass, headerName, headerNameStringID, match } = this.props;
      let { path } = match; 
      
      let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
      let loggedinUser = loginUser.username;
      let IsTokenExpired = !this.state.IsTokenExpired ? auth.IsTokenTimeExipired() : this.state.IsTokenExpired;
      let logoutForm = null
    if(this.state.isHidden) {
        logoutForm=(<LogoutForm hideFlag={this.state.isHidden} toggleHiddenArgument={(event) => {this.showLoginForm(event, this.state.isHidden)}} hideProfileEditBtn={this.props.hideUserProfileEditBtn} editinfo={this.editInformation} nextPath={this.nextPath} hideLoginForm ={(e)=> this.hideLoginForm(e)}/>)
    }

    let filterbase = null
    if(this.state.isHiddenforFilters) {
        filterbase=(<FilterBase history={this.props.history} filterHidden= {this.state.isHiddenforFilters} filterKey={this.props.filterKey} callFilterHideToggle={this.toggleHiddenforFilters} />)
    }
    return (
        <React.Fragment>          
            <ErrorMoal open={ IsTokenExpired ? true : false } stringID="KC1678" onCloseModel={() => this.errorCloseModel()}/>
            <nav className="navbar fixed-top  navbar-expand-lg navbar-dark bg-Welbilt mainMenuMobileHeader" onClick={(e) => {this.showLoginbg(e, this.state.isHidden)}}>
                    <div className="navbar-header">
                        <Link className="navbar-brand welbiltRLogo" title={ formatMessage({ id: 'KC0038' }) } to={(this.props.headerName === "Under Construction" || this.props.headerName === "Un-Authorized") ? "/dashBoardLocations" : "/mainMenu"}>&nbsp;</Link>
                        <Link className="navbar-brand sideNavBar" to="#">&nbsp;</Link>
                    </div>
                    <ul className="navbar-nav mr-auto selectedNavbarLeft">
                        <li className="nav-item">
                            <button className="nav-link border0" title={headerNameStringID ? formatMessage({ id: headerNameStringID }) : headerName}>
                                <span className={activeClass}>{headerNameStringID ? <FormattedMessage id={headerNameStringID}/> : headerName}</span>
                            </button>
                        </li>
                    </ul>
                    <ul className="navbar-nav mr-auto navbarRight">
                        
                       {/* Global Search */}
                       { path &&  < GlobalSearchBar path ={ path} /> }

                        <li className={this.state.isHiddenforFilters ? "nav-item activeFilter": "nav-item"}>                                
                            <Link to="#" className={this.state.isHiddenforFilters ? "nav-link navFilter active":"nav-link navFilter"} title={ formatMessage({ id: 'KC0996' }) } style={this.props.filterKey && (this.props.filterKey === appconstants.mediaFilters || this.props.filterKey === appconstants.softwareFilters || this.props.filterKey === appconstants.menuFilters || this.props.filterKey === appconstants.errorCenterFilter|| this.props.filterKey === appconstants.userManagementFilter|| this.props.filterKey === appconstants.orgManagementFilter || this.props.filterKey === appconstants.smartGroupFilter) ? { display: 'block' } : { display: 'none' } } onClick={(e) => {this.showFilter(e, this.state.isHiddenforFilters)}}><span id="spannavNotifiStatus" className=""></span>&nbsp;</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link navSearch disableUnwandedFilterIckon" style={{ display: 'none'}} title={ formatMessage({ id: 'KC0037' }) } to="#">&nbsp;</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link navAlarm disableUnwandedFilterIckon" style={{ display: 'none'}}  title={ formatMessage({ id: 'KC0997' }) } to="#">&nbsp;</Link>
                        </li> 
                        {/* Multilingual   */}
                        <li className="nav-item">
                            <LanguagePreferenceCommon />
                        </li>
                        {/* Multilingual   */}                       
                        <li className={this.state.isHidden ? "nav-item activeNavUser": "nav-item"} >
                            <Link to="#" className={this.state.isHidden ? "nav-link navUser active":"nav-link navUser"}  style={this.props.headerName==="User Profile" ? { display: 'none' } : { display: 'block' }} title={loggedinUser} onClick={(e) => {this.showLoginForm(e, this.state.isHidden)}}><span id="spannavNotifiStatus" ></span>&nbsp;</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav mr-auto navbarRight">
                        <li className="nav-item">
                        <Link className="nav-link navBack" title={ formatMessage({ id: 'KC1679' }) } style={(this.props.headerName === "Under Construction" || this.props.headerName === "Un-Authorized") ? { display: 'none' } : { display: 'block' }} to={ 
                            (this.props.previousPage==="User Profile") ? "/dashBoardLocations" : 
                            (this.props.previousPage==="User List") ? "/user/list" : 
                            (this.props.previousPage==="Software List") ? KCStore.getItem("brandName") === "CREM" ? "/softwareLibraryUpdate" : "/softwareAvailableUpdates" : 
                            (this.props.previousPage==="HACCP List") ? "/haccpLogLibrary" : 
                            (this.props.previousPage==="Swagger List") ? "/c2cSwaggerListView" : 
                            (this.props.previousPage==="Manage Password") ? "/myinformationedit" : 
                            (this.props.previousPage==="Unit Registration") ? "/UnitRegistration":  "/mainMenu"} >&nbsp;</Link>
                        </li>
                    </ul>
                
            </nav>
            
            <MainLayout flagstate={this.state.isHidden} />      
            <div ref={node1 => { this.pop1 = node1 }} className={this.state.isHiddenforFilters ? "navFilterWrapper showLogoutModal" : "navFilterWrapper"}>                                                        
                {filterbase}            
            </div>  
            <div ref={node => { this.pop = node }}>
                {logoutForm}
            </div> 
        </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
     return {
    //     currentLocation: state.entityReducer.currentLocation
        // selectedCustomers: state.filterInformation.selectedCustomers,
        // selectedBrands: state.filterInformation.selectedBrands,
        // selectedModels: state.filterInformation.selectedModels
    }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserManagementHeader)));
