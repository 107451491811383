import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux'
import ErrorMoal from '../../modal/error-model';
import { getMenuList } from '../../../actions/menuManagement/addMenusList';
import moment from '../../../utils/momentHelper.js';
import { BRAND_ID_VALUE } from '../../../utils/appConstants'
import { MenuUploadAPI } from '../../../service/api';
import http from '../../../service/httpService';
import { TableHeader } from '../../controls/tableHeader';
import authService from '../../../service/authService';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';
import { Paginator } from '../../controls/paginator';
import NoRecordsFound from '../../controls/noRecordsFound';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { injectIntl,FormattedMessage } from 'react-intl';
import $ from 'jquery';
import KCStore from '../../../utils/kcStorage';
class menuLibrary extends Component {

	constructor(props) {
		super(props);
		this.state = {
			menuList: [],
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			userCheckedData: [],
			userSelectAll: false,
			isAdd: true,
			isEdit: false,
			isDelete:false,
			isSelectedAllMenus: false,
			isIndeterminateSelect:false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			curr_fileName : "",
			curr_fileSize : "",
			curr_menuName : "",
			curr_versionNo : "",
			curr_applicableModels : "",
			curr_releaseLog : "",
			curr_desicription : "",
			curr_fileType : "",	
			currentPage : 1,
			pageSize: 25,
            totalPages:null,		
		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
	}


	componentDidMount = () => {
		this.getMenuList();
		document.body.style.backgroundColor = "#F2F2F2";
		window.addEventListener("resize", this.updateDimensions());        
	}

	updateDimensions() {
        const windowHeight = $(window).height();
        const tableBody = $('.pageScrollbar');
        const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
        tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
    }

	componentDidUpdate = () => {
        this.updateDimensions();
    }

	getMenuList = () => {
        this.setState({ loaderIconVisible: true });        
        let url = MenuUploadAPI.getMenuList;
        let pageNumber = '?pageNum=' + this.state.currentPage;
		let pageLimit = '&pageSize=' + this.state.pageSize;
		url = url + pageNumber + pageLimit;
		const { ...menuList } = this.state;
        http.get(url, {
            headers: {
              	'Content-Type': 'application/json',
				'basecountry': KCStore.getItem("basecountry"),
				"brandId": KCStore.getItem("selectedbrandID"),
				"customerId": KCStore.getItem("customerId"),
				"cgids":KCStore.getItem("custGroupID"),				
				"userId":KCStore.getItem("user"),
				"rolename":KCStore.getItem("roleName"),
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
				let arr = [];
				let isDelete = false;
                for (let i = 0; i < response.data.getMenuList.length; i++) {                                   
                    isDelete = await this.checkDeleteAccess(response.data.getMenuList[i]);
					isDelete = true;
					arr.push({...response.data.getMenuList[i],isAuthorized: isDelete})
                };
                this.setState({
                    menuList: arr,
                    loaderIconVisible: false,
					isEdit: false,
                    isDelete: false,
                    allSelect: false,
					totalPages : response.data.numberOfPages,
					...this.getCheckBoxstate(arr.length,arr.filter(i=>i.checked === true).length)
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false,totalPages: 0 });
        });
    }

		onEditClick = (e, item) => {
			var rolesList = [...this.state.menuList];
			WoopraEvents(`${Constants.EDIT_MENU}`);
			rolesList.forEach(i => {
					if (i.checked === true || i === true) {
							this.setState({									
									menuId : i.MENU_ID,
									curr_fileName : i.FILE_NAME,
									curr_fileSize : i.FILE_SIZE,
									curr_fileType : i.FILE_TYPE,
									curr_menuName : i.MENU_NAME,
									curr_versionNo : i.VERSION_NO,
									curr_applicableModels : i.APPLICABLE_MODELS,
									curr_releaseLog : i.RELEASE_LOG,
									curr_desicription : i.DESCRIPTION,
							})
					}

			});
	}		

	
	updateProductDetails = (e, item) => {
			let reqData = {					
					"menuId" : this.state.menuId,
					"fileName" : this.state.curr_fileName, 
					"menuName" : this.state.curr_menuName,
					"versionNo" : this.state.curr_versionNo,
					"applicableModels" : this.state.curr_applicableModels,
					"releaseLog" : this.state.curr_releaseLog,
					"description" : this.state.curr_desicription,
					"fileSize" : this.state.curr_fileSize,
					"fileType" : this.state.curr_fileType,					
			};
			let url = MenuUploadAPI.updateMenuList;
			http.put(url, reqData,{
					headers: {
							'Content-Type': 'application/json',
							"brandid": +KCStore.getItem("selectedbrandID")
					}
			}).then(response => {
					let { data } = response;
					let { httpCode} = data;
					if (httpCode === "HTTP_200") {
							this.setState({									
									menuId:"",
									curr_fileName : "",
									curr_fileSize : "",
									curr_menuName : "",
									curr_versionNo : "",
									curr_applicableModels : "",
									curr_releaseLog : "",
									curr_desicription : "",
									loaderIconVisible: false,
									isSelectedAllMenus:false,
									success_modal: {
											open: true,
											message: "KC1150"
									},
							});
							WoopraEvents(`${Constants.MENU_UPDATED}`,reqData);
					}
			}).catch(err => {
					this.setState({
							loaderIconVisible: false,
							error_modal: {
									open: true,
									message: err.response.data.stringID || err.response.data.message ,
							},
					});
			});
	}

	onRedirect = (url) => {
		WoopraEvents(`${Constants.ADD_MENU}`);
		this.props.history.push(url);
	}

	//For First Letter Added in Smart Group List
	getFirstLetter(name) {
		if(name){
			var char = name.charAt(0).toUpperCase();
			return char;
		}    
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			currentPage: 1,
			success_modal: {
				open: false
			},
			isAdd: true,
		},() => {
			this.getMenuList();
		})
		
	}

	prodFilename_Input_Value = (e) => {
		let rName = e.target.value;
		this.setState({
			curr_fileName: rName,
		})
	}

	prodFileSize_Input_Value = (e) => {
			let rName = e.target.value;
			this.setState({
				curr_fileSize: rName,
			})
	}
	prodFileType_Input_Value = (e) => {
		let rName = e.target.value;
		this.setState({
			curr_fileType: rName,
		})
}
	

	prodMenuName_Input_Value = (e) => {
		let rName = e.target.value;
		this.setState({
			curr_menuName: rName,
		})
	}

	prodVersionNo_Input_Value = (e) => {
		let rName = e.target.value;
		this.setState({
			curr_versionNo: rName,
		})
	}

	prodApplicableModels_Input_Value = (e) => {
		let rName = e.target.value;
		this.setState({
			curr_applicableModels: rName,
		})
	}
	prodReleaseLog = (e) => {
		let rName = e.target.value;
		this.setState({
			curr_releaseLog: rName,
		})
	}
	prodDescription = (e) => {
		let rName = e.target.value;
		this.setState({
			curr_desicription: rName,
		})
	}

	handleChange = (e) => {
		const { menuList } = this.state;
        var menuArray = [...menuList];
        menuArray.forEach(r => {
            r.checked = r.isAuthorized ? e.target.checked : false;
        });
        this.isSelectEditDeleteMenu(menuArray);
	};	
	getCheckBoxstate(ListCount,SelectedCount) {
		if (SelectedCount === ListCount) {
		return { isSelectedAllMenus: true, isIndeterminateSelect: false };
		} else if (SelectedCount === 0 && ListCount !== 0) {
		return { isSelectedAllMenus: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
		return { isSelectedAllMenus: true, isIndeterminateSelect: true };
		}
	}
	
	onSelectMenu = async (e, item) => {
		if (this.state.menuList && this.state.menuList.length > 0) {
				const rolesList = [...this.state.menuList];
				const index = rolesList.indexOf(item);
				rolesList[index] = { ...rolesList[index] };
				rolesList[index].checked =rolesList[index].isAuthorized ? e.target.checked: false;
				let checked = rolesList.filter(c => c.checked === true);

				this.setState({
					menuList: rolesList,...this.getCheckBoxstate(rolesList.length,checked.length)
				})
				this.setState({
					allSelect: false,
					isDelete: checked.length > 0 ? true : false,
					isEdit: checked.length === 1 ? true : false,
					isAdd: checked.length > 0 ? false : true
			});
		}
	}
	
	isSelectEditDeleteMenu = async (menuArray) => {
		let selectedMenus = menuArray.filter(item => item.checked === true);
		let isEdit, isAdd;

		isEdit = selectedMenus.length === 1 ? true : false;
		isAdd = selectedMenus.length > 0 ? false : true;


		this.setState({
			isEdit: isEdit,
			isDelete: selectedMenus.length > 0 ? true : false,
			isSelectedAllMenus: selectedMenus.length > 0,
			isIndeterminateSelect:false,
			menuList: menuArray,
			isAdd: isAdd,
			...this.getCheckBoxstate(menuArray.length,selectedMenus.length)
		});
	}

	onInstallButtonClickHandler() {
		WoopraEvents(`${Constants.MENU_TRANSFER}`);
		let menuArray = [...this.state.menuList];
		menuArray = menuArray.filter(item => item.checked === true);

		let item;
		if (menuArray.length > 0) {
			item = menuArray[0];
			KCStore.setItem('selectedItemsInfo', JSON.stringify(item));
			let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
			let val="";
			// if (item.SMARTGROUPS_ID){
			// 	val="smart-group-transfer";
			// }
			this.props.history.push({				
				pathname: val?"menuTransferUnits":"/menuTransfergroupsList",
				FILE_NAME: item.FILE_NAME,
				VERSION: item.VERSION,
				UPLOADED_ON: item.UPLOADED_ON,
				brandId: brandId,
				MODEL_NAMES: item.MODEL_NAMES,
				storeInstalledItemInfo: item,
				transferType : val,
			});
		}
	}

	onAssignSmartGroupsList() {
		WoopraEvents(`${Constants.MENU_SG_ASSIGN}`);
		var menuArray = [...this.state.menuList];
		menuArray = menuArray.filter(item => item.checked === true);
		let item;
		if (menuArray.length > 0) {
			item = menuArray[0];
			KCStore.setItem('selectedItemsInfo', JSON.stringify(item));
			let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
			this.props.history.push({
				pathname: "/menuAddgroupsList",
				FILE_NAME: item.FILE_NAME,
				VERSION: item.VERSION,
				UPLOADED_ON: item.UPLOADED_ON,
				brandId: brandId,
				MODEL_NAMES: item.MODEL_NAMES,
				storeInstalledItemInfo: item,
				smart_groups_ids: item.SMARTGROUPS_ID,
			});
		}
	}
	
	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	deleteMenuLibrary = () => {
		this.setState({ loaderIconVisible: true })
		let { menuList } = this.state;
		let menuIds = [];
		menuList.forEach(e => {
			if (e.checked === true) {
				menuIds.push(e.MENU_ID);
			}
		});

		let req = {
			"menuIds": menuIds
		};
		let url = MenuUploadAPI.getMenuList;
		http.delete(url, {
			headers: {
				'Content-Type': 'application/json',
			},
			data: req,
		}).then(response => {
			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: "KC1149",
				},				
			});
			WoopraEvents(`${Constants.MENU_DELETED}`);
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.response && err.response.data && err.response.data.message ? err.response.data.message || err.response.data.stringID : "KC1148",
				},
			});
		});
	}

	checkDeleteAccess = async (mediaValue) => {
		const userListEmail = JSON.parse(KCStore.getItem('childUsersEmail'));
		const { username } = JSON.parse(KCStore.getItem('loginInfo'));
		const roleName = KCStore.getItem('roleName') || '';

		let isDelete = false;
		if(mediaValue.UPLOADED_BY && mediaValue.UPLOADED_BY.toString().match('@')){
			isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(mediaValue.UPLOADED_BY) || username === mediaValue.UPLOADED_BY ? true : false;
		}else{
			isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
		}
		if (isDelete === false){
			return isDelete;
		}
		return isDelete;
	}

	onPageChange = (currentPage) => {
		this.setState({ loaderIconVisible: true, currentPage, menuList :[] },()=>{
			this.getMenuList();
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		});
	}

	// Woopra Events
	trackEvent = (e) => {
		WoopraEvents(`${e}`)
	}

	render() {
		// let { menuList } = this.props;	
		let { success_modal, error_modal, loaderIconVisible, sortColumn, isSelectedAllMenus,currentPage, pageSize,
			isIndeterminateSelect, isAdd, isEdit, isDelete, menuList } = this.state;
		const { formatMessage } = this.props.intl;
		let orderId = 1;
		let userProfileAuthFeature = authService.getFeature("/menuLibrary");
		let columns = [
			{ path: "", label: "" },
			{ path: "", label: "#" },
			{ path: "MENU_NAME", labelStringID: "KC1142" },
			{ path: "FILE_NAME", labelStringID: "KC0677" },
			{ path: "VERSION_NO", labelStringID: "KC0834" },
			{ path: "updatedAt", labelStringID: "KC1557" }, 
			{ path: "LAST_TRANSFERED_DATE", labelStringID: "KC0679" }, 
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		menuList = _.orderBy(menuList, [sortColumn.path], [sortColumn.order]);

		menuList.forEach(item => {
			item.order_id = orderId++
		});
		let rowNumber = (currentPage * pageSize) - pageSize;  
		return (
			<div id="adminWrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				{/* <!-- //Fixed Header navbar --> */} 
				<div id="page-content-wrapper">					
					<div className="menuUpdateWrapper">
						<div className="">
							<div className="menuFilterHeader menuFHlast">
								<ul>
									<li> 
										<button title={formatMessage({ id: 'KC1503' })} className="active border0"><FormattedMessage id="KC1503"/></button> 
										<Link to="/menuStatus" title={formatMessage({ id: 'KC0523' })}><FormattedMessage id="KC0523"/></Link>
									</li>
									<li>
										{/* <button onClick={() => this.onAssignSmartGroupsList()} title={formatMessage({ id: 'KC0722' })}  className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn sgAdd":"btn sgAdd disabled"} >&nbsp;</button> */}
										<button onClick={() => this.onInstallButtonClickHandler()} title={formatMessage({ id: 'KC2081' })} className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn transfertoSG":"btn transfertoSG disabled"} ></button>									
										<button type="button" onClick={(e) => this.onEditClick(e)} className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn menuEdit":"btn menuEdit disabled"} data-toggle="modal" data-target="#editMenuModal">&nbsp;</button>										
										<button type="button" className={userProfileAuthFeature.is_editable === 1 && isDelete ? "btn menuDelete" : "btn menuDelete disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal">&nbsp;</button>
										<button type="button" onClick ={()=> this.onRedirect('/addNewMenuLibrary')}  className={userProfileAuthFeature.is_editable === 1 && isAdd ? "btn menuAdd":"btn menuAdd disabled"}>&nbsp;</button>
									</li>
								</ul>
							</div>

							<div className="menuLbryLTable">
							<TableHeader
									isIndeterminate={isIndeterminateSelect}
									sortColumn={sortColumn}
									onSort={this.onSort}
									selectAllChange={this.handleChange}
									columns={columns}
									isSelectAll={isSelectedAllMenus} />
							</div>
						</div>						
						<div className='pageScrollbar'>
						<form className="menuLbryLTableOuter"> 				
						{ (menuList && menuList.length === 0) || !menuList &&  <div className ="text-center"><FormattedMessage id="KC0568"/></div>}
							<div className="panel panel-default" id="menuLibraryAccordion" role="tablist" aria-multiselectable="true">
							{ menuList && menuList.map((item, i) => {
								let date = new Date(item.LAST_TRANSFERED_DATE);
								const isValid = date instanceof Date && !isNaN(date.valueOf());

								let updateDate = new Date(item.updatedAt);
								const isValidUpdateDate = updateDate instanceof Date && !isNaN(updateDate.valueOf());

								return(
									<div key ={ i}>
									<div className="menuLbryLTable">
											<ul className="tBody"> 
												<li><button type ="button"
												data-toggle="collapse" 
												href= { "#availableUpdatesAcc" + item.ID}
												data-parent="#menuLibraryAccordion" 
												aria-expanded="false" 
												aria-controls= {'availableUpdatesAcc' + item.ID} 
												className="btn collapseExpand collapsed">&nbsp;</button></li>  
	
												<li>{rowNumber + i + 1}</li>
												<li>{item.MENU_NAME}</li> 
												<li>{item.FILE_NAME}</li>
												<li>{item.VERSION_NO}</li> 
												<li><button type="button" disabled className="btn referesh"></button>{isValidUpdateDate ? moment.GetDateTimeByFormat(item.updatedAt, 'LLL') : '' } </li>												
												<li>
													{ item.LAST_TRANSFERED_DATE && <span>{isValid ? moment.GetDateTimeByFormat(item.LAST_TRANSFERED_DATE, 'LLL'): ''}</span>}
													{ !item.LAST_TRANSFERED_DATE && <span className="disabled">Not Transferred</span>}
												</li> 
												<li>
													{!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1748' })} direction="left" className="targetdisabledCheckboxAlignment" >
														<div className="customCheckbox customCheckboxWOlabel disabled" >
															<input id={i + 1} name="mediaPreparationList" type="checkbox" value={item.checked} defaultChecked={item.checked}
																checked={item.checked}
																disabled
																onChange={(e) => this.onSelectMenu(e, item)} />
															<label for={i + 1}></label>
														</div>
                                                        </Tooltip> : <div className="customCheckbox customCheckboxWOlabel" >
														<input id={i + 1} name="mediaPreparationList" type="checkbox" value={item.checked} defaultChecked={item.checked}
															checked={item.checked}
															onChange={(e) => this.onSelectMenu(e, item)} />
														<label for={i + 1}></label>
													</div>}
												</li>
											</ul>
									</div>
									
									
									<div id= {"availableUpdatesAcc" + item.ID}  
											data-parent="#menuLibraryAccordion" 
											className="panel-collapse collapse" 
											role="tabpanel" 
											aria-labelledby={"availableUpdatesAccH1" + item.ID}>
										<div className="panel-body menuLbryLExpTable">
											<ul className="tBody">
												<li>&nbsp;</li>
												<li><b><FormattedMessage id="KC1146"/></b> {item.APPLICABLE_MODELS}</li>
												<li><b><FormattedMessage id="KC0195"/></b> {item.DESCRIPTION}</li> 
												<li><b><FormattedMessage id="KC1139"/></b> {item.RELEASE_LOG}</li>
											</ul>  
										</div>
									</div>
	
									 </div>
								)
							}							
                           
							)}
							
							</div> 
							<Paginator
								pageNumber={this.state.currentPage}
								pageCount={this.state.totalPages}
								onNextPage={(p) => this.onPageChange(p)}
								onPreviousPage={(p) => this.onPageChange(p)}
								applyClass="tablePagination"
							/>
						</form>
						</div>  
					</div>
				</div>


				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />				
				<NoRecordsFound loaderIconVisible={loaderIconVisible} length={menuList !== undefined ? menuList.length : 0} classname={'norecordsfoundbluetext'} />
				<div className="modal fade selectRecipeModal" data-keyboard="true" data-backdrop="static" id="editMenuModal" tabindex="-1" role="dialog" aria-labelledby="editMenuModalLabel">
					<div className="modal-dialog modal-md">
							<div className="modal-content">  
					<div className="modal-header modal-header-danger tAlginCenter">
						<FormattedMessage id="KC1145"/>
					</div>
					<div className="modal-body"> 					 
						<div className="modalBodyContScrl">
							<div className="addEditFormWrapper">
								<div className="addEditForm">
									<ul>
										<li>
												<label><FormattedMessage id="KC0677"/>*</label>
												<div className="input-group">
														<input onChange={(e) => this.prodFilename_Input_Value(e)} value={this.state.curr_fileName} type="text" className="form-control" placeholder={formatMessage({ id: 'KC0677' })} disabled/>
												</div>
										</li>
										<li> 
												<label><FormattedMessage id="KC1144"/>*</label>
												<div className="input-group">
														<input onChange={(e) => this.prodFileSize_Input_Value(e)} value={this.state.curr_fileSize} type="number" className="form-control" placeholder={formatMessage({ id: 'KC1144' })} disabled/>
												</div>
										</li>
										<li> 
												<label><FormattedMessage id="KC1143"/>*</label>
												<div className="input-group">
														<input onChange={(e) => this.prodFileType_Input_Value(e)} value={this.state.curr_fileType} type="text" className="form-control" placeholder={formatMessage({ id: 'KC0102' })} disabled/>
												</div>
										</li>
										<li> 
												<label><FormattedMessage id="KC1142"/>*</label>
												<div className="input-group">
														<input onChange={(e) => this.prodMenuName_Input_Value(e)} value={this.state.curr_menuName} type="text" className="form-control" placeholder={formatMessage({ id: 'KC1142' })} />
												</div>
										</li>
										<li>
												<label><FormattedMessage id="KC1141"/>*</label>
												<div className="input-group">
														<input onChange={(e) => this.prodVersionNo_Input_Value(e)} value={this.state.curr_versionNo} type="Number" className="form-control" placeholder={formatMessage({ id: 'KC1141' })} />
												</div>
										</li>
										<li>
												<label><FormattedMessage id="KC1140"/>*</label>
												<div className="input-group">
														<input onChange={(e) => this.prodApplicableModels_Input_Value(e)} value={this.state.curr_applicableModels} type="text" className="form-control" placeholder={formatMessage({ id: 'KC1140' })} />
												</div>
										</li>
										<li> 
												<label><FormattedMessage id="KC1139"/>*</label>
												<div className="input-group">
														<input onChange={(e) => this.prodReleaseLog(e)} value={this.state.curr_releaseLog} type="text" className="form-control" placeholder={formatMessage({ id: 'KC1139' })} />
												</div>
										</li>
										<li> 
												<label><FormattedMessage id="KC0195"/>*</label>
												<div className="input-group">
														<input onChange={(e) => this.prodDescription(e)} value={this.state.curr_desicription} type="text" className="form-control" placeholder={formatMessage({ id: 'KC0195' })} />
												</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
						</div>
						<div className="modal-footer textAlignCenter">
							<button className="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent(`${Constants.MENU_CANCELED}`)}><FormattedMessage id="KC0021"/></button>
							<button onClick={(e) => this.updateProductDetails(e)} className="btn btn-secondary yesBtn" id="timeZoneAssign" data-dismiss="modal" type="button"><FormattedMessage id="KC0777"/></button>
						</div>
					</div>
					</div>
				</div>

				{/* Delete Smart Group Unit Modal */}
                <div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
                    <div className="modal-dialog modal-md loginModal">
                        <div className="modal-content" >
                            <div className="modal-body">
                                <div className="singOutConfirmation">
                                    <FormattedMessage id="KC1138"/>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent(`${Constants.MENU_CANCELED}`)}><FormattedMessage id="KC0021"/></button>
                                <button className="btn btn-secondary signOutYesBtn" onClick={() => this.deleteMenuLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>

			</div>

			
		)
	}
};

const mapDispatchToProps = (dispatch)  => {
    return bindActionCreators({
			getMenuList,
        }, dispatch)
    
}

const mapStateToProps = state => {
	return {
		menuList: state.menuListLibrary.menuList,
		loaderIconVisible: state.menuListLibrary.isLoading
	};
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
)(injectIntl(menuLibrary))