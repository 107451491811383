import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import ErrorMoal from '../../../../modal/error-model';
import DatePicker from 'react-datepicker';
import { injectIntl,FormattedMessage } from 'react-intl';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { datePickerLocale } from '../../../../../locale/constant';
import KCStore from '../../../../../utils/kcStorage';

class FrymasterRecipeSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            startDate: "",
            endDate: ""
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSaveSchedule = this.onSaveSchedule.bind(this);
        this.handleStartDateChange =this.handleStartDateChange.bind(this);
        this.handleEndDateChange =this.handleEndDateChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonRecipeFile = KCStore.getItem("libraryData");
            let ObjRecipe = JSON.parse(jsonRecipeFile);
            
            let startDate = ObjRecipe.settings.schedule.start_date;
            let endDate = ObjRecipe.settings.schedule.end_date;
            this.setState({
                startDate: startDate ? moment(startDate, "MM/DD/YYYY").toDate() : "",
                endDate: endDate ? moment(endDate, "MM/DD/YYYY").toDate() : ""
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    onSaveSchedule = () => {
        if (!this.state.startDate || !this.state.endDate) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1378"
                }
            })
        }
        else {
            if (this.state.startDate && this.state.endDate) {
                let jsonRecipeFile = KCStore.getItem("libraryData");
                let ObjRecipe = JSON.parse(jsonRecipeFile);
                ObjRecipe.settings.schedule.start_date = moment(this.state.startDate).format('MM/DD/YYYY') + "T00:00:00";
                ObjRecipe.settings.schedule.end_date = moment(this.state.endDate).format('MM/DD/YYYY') + "T23:59:59";
                KCStore.setItem("libraryData", JSON.stringify(ObjRecipe));
            }
            this.props.history.push({
                pathname: "/frymasterRecipeCreation",
                isEdit: false,
                previouspath: "/frymasterRecipeSchedule"
            });
        }
    }

    onBackButtonClick = () => {
        this.props.history.push(`/frymasterRecipeCreation`);
    }

    ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
    }
    
    handleStartDateChange = (date) => {
		this.setState({
            startDate: date,
            endDate: ''
		});
    }
    
    handleEndDateChange = (date) => {
        if (!this.state.startDate) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1379"
                }
            })
        }
        else {
            this.setState({
                endDate: date
            });
        }
    }
    
    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible, error_modal } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = KCStore.getItem("selectedbrandID");

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1380"/>
                                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCScdlForm"> 
                                                    <ul>
                                                        <li>
                                                            <label><FormattedMessage id="KC2001"/></label>
                                                            <DatePicker locale={datePickerLocale} disabledKeyboardNavigation id="ScheduleStartDate" onKeyDown={(e) => this.ResetDatepicker(e)} autoComplete="off" showMonthDropdown showYearDropdown dropdownMode="select" selected={this.state.startDate} placeholderText={formatMessage({ id: 'KC0835' })} onChange={this.handleStartDateChange} minDate={new Date()} maxDate={this.state.maxdate} className="form-control" />
                                                        </li>
                                                        <li>
                                                            <label><FormattedMessage id="KC2072"/></label>
                                                            <DatePicker locale={datePickerLocale} disabledKeyboardNavigation id="ScheduleEndDate" onKeyDown={(e) => this.ResetDatepicker(e)} autoComplete="off" showMonthDropdown showYearDropdown dropdownMode="select" selected={this.state.endDate} placeholderText={formatMessage({ id: 'KC0835' })} onChange={this.handleEndDateChange} minDate={this.state.startDate ? this.state.startDate : new Date()} maxDate={this.state.maxdate} className="form-control" />
                                                        </li>  
                                                    </ul>  
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">    
                                    <button type="button" class="btn languageButton">&nbsp;</button> 
                                    <button type="button" onClick={() => { this.onSaveSchedule() }} class="btn confirmButton rightAlign">&nbsp;</button>   
                                </div> 
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterRecipeSchedule)
