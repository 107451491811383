import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class CommonConfirmationModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { onCancel, onConfirm, modalText } = this.props;
        return (
            <div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="orgEditConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="orgEditConfirmationModalLabel">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="deleteConfirmation">
                                <FormattedMessage id={modalText} />
                            </div>
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button" onClick={() => onCancel()}><FormattedMessage id='KC0021' /></button>
                            <button className="btn btn-secondary deleteYesBtn" id="updateBtn" data-dismiss="modal" type="button" onClick={() => onConfirm()}><FormattedMessage id='KC0777'/></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
