import {MEDIA_UPLOAD_HISTORY_REQUEST,MEDIA_UPLOAD_HISTORY_SUCCESS,MEDIA_UPLOAD_HISTORY_FAIL} from '../../../../actions/navigation/mediaManagement/mediaUploadHistoryActions';
const initialState = {
    loaded: false,
    loading: false,
    loaderIconVisible:false,
    mediaUploadHistoryResponse: {'mediaDistributeList':[]},
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case MEDIA_UPLOAD_HISTORY_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                loaderIconVisible:false,

                
            }
        case MEDIA_UPLOAD_HISTORY_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                loaderIconVisible:true,
                mediaUploadHistoryResponse: action.mediaUploadHistoryResponse,
               
            }
        case MEDIA_UPLOAD_HISTORY_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: '',
                mediaUploadHistoryResponse:[],

            }
        default:
            return state
    }
}

