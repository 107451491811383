import { GET_CURRENT_LOCATION, 
         SET_CURRENT_LOCATION, 
         GET_CURRENT_UNIT, 
         SET_CURRENT_UNIT } from '../../../actions/organizationentity/type';

const initialState ={
    currentUnit:{
    //   customer_Id:0,
    //   customer_group_id:0,
    //   current_location_id:0,
    //   current_unit_id:0,
    //   Brand_Logo:'../../images/unit/unit01.png'
    },
    currentLocation :{
        // location_id: "107",
        // location_name: "Mississauga Store",
        // location_type: "Hotel",
        // region: "West",
        // country: "Canada",
        // city: "Mississauga",
        // street: "1500 Dundas St E",
        // lat: 43.78148,
        // long: -79.7276,
        // state: "Ontarioa",
        // subLocation: "Floor 6, Bay3 second painted wall"
    }
}

export default function(state = initialState, action){
    switch(action.type) {
        case GET_CURRENT_LOCATION:
        return {
            ...state,
            currentLocation: action.payload
        }

        case SET_CURRENT_LOCATION:
        return {
            ...state,
            currentLocation: action.payload
        }
      
        case GET_CURRENT_UNIT:
        return {
            ...state,
            currentUnit: action.payload
        }
        
        case SET_CURRENT_UNIT:
        return {
            ...state,
            currentUnit: action.payload
        }
        default:
        return state;
    }
}