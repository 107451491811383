import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getMediaPreparationList, mediaSelectedItems } from '../../../actions/navigation/mediaManagement/mediaPreparationListAction';
import { setCreatePackageAction } from '../../../actions/navigation/mediaManagement/mediaCreatePackageAction';
import $ from 'jquery';
import http from '../../../service/httpService';
import authService from '../../../service/authService';
import { MediaManagementAPI, UserProfile } from '../../../service/api';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import _ from 'lodash';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import { mediaDraggedItem, mediaDragDeleteItem, resetSelectedItems } from '../../../actions/navigation/mediaManagement/mediaDragAction';
import ReactPlayer from 'react-player'
import {BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../utils/appConstants';
import {updateDimensions_Media} from '../../helperFunctions'
import Tooltip from 'react-tooltip-lite';
import uploadMediaToCloud from '../media/uploadMedia';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import NoRecordsFound from '../../controls/noRecordsFound';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';
import KCStore from '../../../utils/kcStorage';
const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

class MediaPreparation extends Component {
	
	nextPath(path) {
		this.props.history.push(path);
	}
	
	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		});
		this.removeVideoElement();
		this.loadPage();
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	closePlayer() {
		this.setState({
			previewurl: '',
			previewfilename: ''
		});
	}

	constructor(props) {
		super(props);
		const { formatMessage } = props.intl;
		this.state = {
			isFilename: false,
			fileName: null,
			imagedata: {},
			userData: {},
			userCheckedData: [],
			userSelectAll: false,
			isIndeterminateSelect:false,
			loaderIconVisible: true,
			previewfilename: "",
			previewfileformat: "",
			previewurl: "",
			previewurl11: "",
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			isAdd: true,
			isDelete: false,
			isAddToPreparation: false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			dragItem: {},
			selectFileText: 'KC0731',
			preparationSelectionClass: "initialActive",
			fileName: null,
			isEdit:false,
			searchData: null,
		};

		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.cancelDeleteUser = this.cancelDeleteUser.bind(this);
		this.deleteSelectedMedia = this.deleteSelectedMedia.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.closePlayer = this.closePlayer.bind(this);
		this.onAssignClickHandler = this.onAssignClickHandler.bind(this);
		this.callOrgChartPage = this.callOrgChartPage.bind(this);
	}

	 componentDidMount = () => {
		this.props.searchVisible(true);
		document.body.style.backgroundColor = "#F2F2F2";
		this.loadPage();
		this.props.setCreatePackageAction();
		$('.OpenImgMp4Upload').click(function () { $('#imgMp4upload').trigger('click'); });
		window.addEventListener('resize', updateDimensions_Media());
	}

	loadPage = () => {
		this.setState({loaderIconVisible: true});
		let { searchData } = this.state;
		let searchParam = {};
		if (searchData && searchData.searchtext) {
			searchParam['searchtext'] = encodeURIComponent(searchData.searchtext);
		}

		if (searchData) {
			Object.keys(searchData).map((item) => {
				if (item !== "searchtext") {
					searchParam[item] = encodeURIComponent(searchData[item]);
				}
			})
		}
		this.props.getMediaPreparationList(searchParam).then((res)=>{
			this.setState({loaderIconVisible: false})
		});
	}

	async componentDidUpdate(prevState) {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({ loaderIconVisible: true },()=>{
                this.loadPage();
            });
		}
	}

	
    // componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
		if(this.props.mediaPreparationListInfo && this.props.mediaPreparationListInfo.mediaList){
			this.props.mediaPreparationListInfo.mediaList = [];
		}
	}

	/**
     * Remove video element
     */
	removeVideoElement = () => {
		this.setState({loaderIconVisible: true});
		let myobj = document.getElementById("videoMain");
		if (myobj) {myobj.remove();}
		else{
			return this.setState({loaderIconVisible: false});
		}
		this.setState({loaderIconVisible: false});
	  }

	cancelDeleteUser = () => {		
		WoopraEvents(`${Constants.MEDIA_CANCELED}`)
	}

	onChangeHandler = (e) => {
        const { formatMessage } = this.props.intl;
		let _this = this;
		if (e.target.files[0] !== null) {

			this.setState({
				loaderIconVisible: true
			});

			let fileSize = e.target.files[0].size;
			fileSize = (fileSize / 1048576).toFixed(2);

			let fileName = e.target.files[0].name;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
			let fileextSplit = fileName.split('.');
			let fileext = fileextSplit[1].toLowerCase();
			let fileNamewithOutExt = fileextSplit[0];
			let filelength = fileName.length;

			let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
			let loggedinUser = loginUser.username;

			let resolutionofimageVideo = 0;
			let mediaid = fileNamewithOutExt + '_' + Date.now();
			mediaid = mediaid.replace(/\s+/g, '');

			var FileUploadThumbnail = require('../../../utils/fileThumnailProcesser');
			let md5 = require('md5');
			let data = {
				"customer": KCStore.getItem("customerId"),
				"brand": 'CREM',
				"brand_id": 4,
				"type": 'preparation',
				"fileName": fileName,
				"fileFormat": '.' + fileext,
				"fileSize": fileSize,
				"user": loggedinUser,
				"mediaId": mediaid,
			};


			let url = `${MediaManagementAPI.getMedias3uploadpresigned}` + '?customer=' + KCStore.getItem("customerId") + '&key=' + mediaid + '.' + fileext + '&thumbnail=true';

			if (fileSize <= 80 && filelength <= 30) {
				var width;
				var height;
				let srcfile = e.target.files[0];
				if (fileext === "png") {
					var reader = new FileReader();
					reader.readAsDataURL(e.target.files[0]);
					reader.onload = (e) => {
						var image = new Image();
						image.src = e.target.result;
						data.crc = md5(Buffer.from(e.target.result.replace(/^data:image\/png;base64,/, ""), "base64"));
						image.onload = (img) => {
							width = img.target.width;
							height = img.target.height;
							resolutionofimageVideo = width + " x " + height;
							if ((width > 1368 || height > 800)) {
								_this.setState({
									loaderIconVisible: false,
									error_modal: {
										open: true,
										message: formatMessage({ id: 'KC1514' })
									}
								});
							}
							else {
								data.resolution = resolutionofimageVideo;
								data.category = 'picture';
								if (srcfile) {
									let avb = new FileUploadThumbnail({
										maxWidth: 80,
										maxHeight: 80,
										file: srcfile,
										onSuccess: (src) => {
											src = src.replace(/^data:image\/png;base64,/, "");
											let imageBuffer = Buffer.from(src, "base64");											

											let valCgids = KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") :"";
											valCgids = valCgids.split(',');
											data.cgids = valCgids;
											uploadMediaToCloud(url, data, srcfile, imageBuffer).then(response => {
												_this.setState({
													loaderIconVisible: false,
													success_modal: {
														open: true,
														message: formatMessage({ id: 'KC1515' })
													},
												});
											}).catch(err => {
												_this.showError(err);
											});											

											// this.props.history.push({
											// 	pathname: "/assignMedia",
											// 	url: url,
											// 	data: data,
											// 	srcfile: srcfile,
											// 	imageBuffer: imageBuffer,
											// 	previouspath: "/MediaPreparation"
											// });
										},
										onError: function (err) {
											_this.showError(err);
										}
									}).createThumbnail();
								}
							}
						};
					}
				}
				else if (fileext === 'jpg') {
          var reader = new FileReader()
          reader.readAsDataURL(e.target.files[0])
          reader.onload = e => {
            var image = new Image()
            image.src = e.target.result
            data.crc = md5(Buffer.from(e.target.result.replace(/^data:image\/jpg;base64,/, ''), 'base64'))
            image.onload = img => {
              width = img.target.width
              height = img.target.height
              resolutionofimageVideo = width + ' x ' + height
              if (width > 1368 || height > 800) {
                _this.setState({
                  loaderIconVisible: false,
                  error_modal: {
                    open: true,
                    message: formatMessage({ id: 'KC1514' })
                  }
                })
              } else {
                data.resolution = resolutionofimageVideo
                data.category = 'picture'
                if (srcfile) {
                  let avb = new FileUploadThumbnail({
                    maxWidth: 80,
                    maxHeight: 80,
                    file: srcfile,
                    onSuccess: src => {
                      src = src.replace(/^data:image\/png;base64,/, '')
                      let imageBuffer = Buffer.from(src, 'base64')
						let valCgids = KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : "";
						valCgids = valCgids.split(',');
						data.cgids = valCgids;
						uploadMediaToCloud(url, data, srcfile, imageBuffer).then(response => {
							_this.setState({
								loaderIconVisible: false,
								success_modal: {
									open: true,
									message: formatMessage({ id: 'KC1515' })
								},
							});
						}).catch(err => {
							_this.showError(err);
						});
                    //   this.props.history.push({
                    //     pathname: '/assignMedia',
                    //     url: url,
                    //     data: data,
                    //     srcfile: srcfile,
                    //     imageBuffer: imageBuffer,
                    //     previouspath: '/MediaPreparation'
                    //   })
                    },
                    onError: function(err) {
                      _this.showError(err)
                    }
                  }).createThumbnail()
                }
              }
            }
          }
        } else if (fileext === 'jpeg') {
          var reader = new FileReader()
          reader.readAsDataURL(e.target.files[0])
          reader.onload = e => {
            var image = new Image()
            image.src = e.target.result
            data.crc = md5(Buffer.from(e.target.result.replace(/^data:image\/jpeg;base64,/, ''), 'base64'))
            image.onload = img => {
              width = img.target.width
              height = img.target.height
              resolutionofimageVideo = width + ' x ' + height
              if (width > 1368 || height > 800) {
                _this.setState({
                  loaderIconVisible: false,
                  error_modal: {
                    open: true,
                    message: formatMessage({ id: 'KC1514' })
                  }
                })
              } else {
                data.resolution = resolutionofimageVideo
                data.category = 'picture'
                if (srcfile) {
                  let avb = new FileUploadThumbnail({
                    maxWidth: 80,
                    maxHeight: 80,
                    file: srcfile,
                    onSuccess: src => {
                      src = src.replace(/^data:image\/png;base64,/, '')
                      let imageBuffer = Buffer.from(src, 'base64')
                      
						let valCgids = KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : "";
						valCgids = valCgids.split(',');
						data.cgids = valCgids;
						uploadMediaToCloud(url, data, srcfile, imageBuffer).then(response => {
							_this.setState({
								loaderIconVisible: false,
								success_modal: {
									open: true,
									message: formatMessage({ id: 'KC1515' })
								},
							});
						}).catch(err => {
							_this.showError(err);
						});

                    //   this.props.history.push({
                    //     pathname: '/assignMedia',
                    //     url: url,
                    //     data: data,
                    //     srcfile: srcfile,
                    //     imageBuffer: imageBuffer,
                    //     previouspath: '/MediaPreparation'
                    //   })
                    },
                    onError: function(err) {
                      _this.showError(err)
                    }
                  }).createThumbnail()
                }
              }
            }
          }
        }
				else if (fileext === "mp4") {

					var reader = new FileReader();
					reader.readAsDataURL(e.target.files[0]);
					reader.onload = (e) => {
						var dataUrl = reader.result;
						var $videoEl = $('<video id="videoMain" className="displaynone"></video>');
						$("body").append($videoEl);
						$videoEl.attr('src', dataUrl);
						var videoTagRef = $videoEl[0];
						data.crc = md5(Buffer.from(reader.result.replace(/^data:video\/mp4;base64,/, ""), "base64"));
						videoTagRef.addEventListener('loadedmetadata', (e) => {
							width = videoTagRef.videoWidth;
							height = videoTagRef.videoHeight;
							resolutionofimageVideo = width + " x " + height;

							if ((width > 1500 || height > 800)) {
								_this.setState({
									loaderIconVisible: false,
									error_modal: {
										open: true,
										message: formatMessage({ id: 'KC1514' })
									}
								});
							}
							else {
								data.resolution = resolutionofimageVideo;
								data.category = 'video';
								if (srcfile) {
									let avb = new FileUploadThumbnail({
										maxWidth: 80,
										maxHeight: 80,
										file: srcfile,
										onSuccess: (src) => {
											src = src.replace(/^data:image\/png;base64,/, "");
											let imageBuffer = Buffer.from(src, "base64");

											let valCgids = KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : "";
											valCgids = valCgids.split(',');
											data.cgids = valCgids;
											uploadMediaToCloud(url, data, srcfile, imageBuffer).then(response => {
												_this.setState({
													loaderIconVisible: false,
													success_modal: {
														open: true,
														message: formatMessage({ id: 'KC1515' })
													},
												});
											}).catch(err => {
												_this.showError(err);
											});
											// this.props.history.push({
											// 	pathname: "/assignMedia",
											// 	url: url,
											// 	data: data,
											// 	srcfile: srcfile,
											// 	imageBuffer: imageBuffer,
											// 	previouspath: "/MediaPreparation"
											// });
										},
										onError: (err) => {
											_this.showError(err);
										}
									}).createThumbnail();
								}
							}
						});
					};
				}
				else if (fileext === 'h264') {
          var reader = new FileReader()
          reader.readAsDataURL(e.target.files[0])
          reader.onload = e => {
            var dataUrl = reader.result
            var $videoEl = $('<video id="videoMain" className="displaynone"></video>')
            $('body').append($videoEl)
            $videoEl.attr('src', dataUrl)
            var videoTagRef = $videoEl[0]
            data.crc = md5(Buffer.from(reader.result.replace(/^data:video\/h264;base64,/, ''), 'base64'))
            videoTagRef.addEventListener('loadedmetadata', e => {
              width = videoTagRef.videoWidth
              height = videoTagRef.videoHeight
              resolutionofimageVideo = width + ' x ' + height

              if (width > 1500 || height > 800) {
                _this.setState({
                  loaderIconVisible: false,
                  error_modal: {
                    open: true,
                    message: formatMessage({ id: 'KC1514' })
                  }
                })
              } else {
                data.resolution = resolutionofimageVideo
                data.category = 'video'
                if (srcfile) {
                  let avb = new FileUploadThumbnail({
                    maxWidth: 80,
                    maxHeight: 80,
                    file: srcfile,
                    onSuccess: src => {
                      src = src.replace(/^data:image\/png;base64,/, '')
                      let imageBuffer = Buffer.from(src, 'base64')

                      let valCgids = KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : "";
						valCgids = valCgids.split(',');
						data.cgids = valCgids;
						uploadMediaToCloud(url, data, srcfile, imageBuffer).then(response => {
							_this.setState({
								loaderIconVisible: false,
								success_modal: {
									open: true,
									message: formatMessage({ id: 'KC1515' })
								},
							});
						}).catch(err => {
							_this.showError(err);
						});

                    //   this.props.history.push({
                    //     pathname: '/assignMedia',
                    //     url: url,
                    //     data: data,
                    //     srcfile: srcfile,
                    //     imageBuffer: imageBuffer,
                    //     previouspath: '/MediaPreparation'
                    //   })
                    },
                    onError: err => {
                      _this.showError(err)
                    }
                  }).createThumbnail()
                }
              }
            })
          }
        }
				else if (fileext === "zip") {
					data.resolution = resolutionofimageVideo;
					data.category = 'zip';
					var reader = new FileReader();
					reader.readAsDataURL(e.target.files[0]);
					reader.onload = (e) => {
						data.crc = md5(Buffer.from(reader.result.replace(/^data:application\/x-zip-compressed;base64,/, ""), "base64"));
						
						let valCgids = KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : "";
						valCgids = valCgids.split(',');
						data.cgids = valCgids;
						uploadMediaToCloud(url, data, srcfile, '').then(response => {
							_this.setState({
								loaderIconVisible: false,
								success_modal: {
									open: true,
									message: formatMessage({ id: 'KC1515' })
								},
							});
						}).catch(err => {
							_this.showError(err);
						});

						// this.props.history.push({
						// 	pathname: "/assignMedia",
						// 	url: url,
						// 	data: data,
						// 	srcfile: srcfile,
						// 	imageBuffer: '',
						// 	previouspath: "/MediaPreparation"
						// });
					};
				}
				else {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: formatMessage({ id: 'KC1516' })
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: formatMessage({ id: 'KC1517' })
					}
				});
			}
		}
		$("#imgMp4upload").val('');
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data ? data.message : ""
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false,

			});

		}
	}

	onAssignClickHandler() {
		WoopraEvents(`${Constants.PACKAGE_CREATED}`);
		let { fileName} = this.state;
		if( !fileName) {
			this.setState({isFilename: true});
		} else {
			KCStore.setItem("zipFileName", fileName);
		$('#newFileNameModal').modal('hide');
		this.props.history.push(`/assignPackageMedia`);

		}
	}

	getCheckBoxstate(ListCount,SelectedCount) {
		if (SelectedCount == ListCount) {
		return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (SelectedCount == 0 && ListCount != 0) {
		return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
		return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange(e, rowData, listCount) {
		this.setState({ dragItem: rowData });
		let userCheckedData = this.state.userCheckedData;
		const findIndex = userCheckedData.findIndex((item) => item.MEDIA_ID === rowData.MEDIA_ID)
		if (findIndex > -1) {
			if (e.target.checked === false) {
				userCheckedData.splice(findIndex, 1)
			}
		} else {
			if (e.target.checked === true && rowData.isAuthorized) {
				userCheckedData.push(rowData)
			}
		}

		this.setState({
			userCheckedData: userCheckedData,
			...this.getCheckBoxstate(listCount,userCheckedData.length)
		});

		this.isSelectEditDelete(this.state.userCheckedData);
		this.FinsSelectedFileDetails(userCheckedData)
	}

	isSelectEditDelete = async(CheckedData) => {
		const { formatMessage } = this.props.intl;
		if (CheckedData && CheckedData.length === 1) {
			this.setState({
				isAdd: false,
				isDelete: true,
				isAddToPreparation: true,
				selectFileText: 'KC0724',
				preparationSelectionClass: "active",
				isEdit: true
			});
		}
		else if (CheckedData && CheckedData.length > 1) {
			this.setState({
				isAdd: false,
				isDelete: true,
				isAddToPreparation: false,
				selectFileText: 'KC0731',
				preparationSelectionClass: "initialActive",
				isEdit:false
			});
		}
		else {
			this.setState({
				isAdd: true,
				isDelete:false,
				isEdit: false,
				isAddToPreparation: false,
				selectFileText: 'KC0731',
				preparationSelectionClass: "initialActive"
			});
		}
	}

	handleChange = async() => {

		var userSelectAll = !this.state.userSelectAll;
		const { mediaPreparationListInfo } = this.props;
		let userChecked = [];
		for(let i=0; i<mediaPreparationListInfo.mediaList.length; i++){
			if(mediaPreparationListInfo.mediaList[i].isAuthorized){
				userChecked.push(mediaPreparationListInfo.mediaList[i]);
			}
		}
		mediaPreparationListInfo && mediaPreparationListInfo.length > 0 && mediaPreparationListInfo.map(item => {
			item.checked = item.isAuthorized ? userSelectAll : false;
		});

		if (userSelectAll === true) {
			this.setState({
				userSelectAll: userSelectAll,
				isIndeterminateSelect:false,
				userCheckedData: userChecked,
				isAdd: false,
				isDelete: userChecked.length > 0 ,
				isEdit: userChecked.length === 1,
				isAddToPreparation: false
			});
		} else {
			this.setState({
				userSelectAll: userSelectAll,
				isIndeterminateSelect:false,
				userCheckedData: [],
				isAdd: true,
				isDelete:false,
				isEdit:false,
				isAddToPreparation: false
			});
		}
		this.setState({
			...this.getCheckBoxstate(mediaPreparationListInfo.mediaList.length, userChecked.length)
		});
	}

	deleteSelectedMedia = () => {
		const { formatMessage } = this.props.intl;
		const mediaPreparationList = [...this.state.userCheckedData];
		if (mediaPreparationList && mediaPreparationList.length > 0 && mediaPreparationList != null) {
			let postData = {
				mediaIds: mediaPreparationList.map(function (item) { return item.MEDIA_ID })
			}
			this.setState({
				loaderIconVisible: true
			})
			this.putData(postData, MediaManagementAPI.getMediaBrandCustomer, HTTP_METHOD.DELETE).then(response => {
				if (response && response.data && response.data.message) {
					if (response.data.httpCode === "HTTP_200") {
						this.setState({
							isDelete: false,
							isAdd: true,
							isAddToPreparation: false,
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: (response.data.stringID || response.data.message) || ""
							},
							userSelectAll:false,
							isIndeterminateSelect:false,
							isEdit: false,
							userCheckedData: []
						});
						mediaPreparationList && mediaPreparationList.length > 0 && mediaPreparationList.map(item => {
							item.checked = false;
						});
					}
				}
			}).catch(err => {
				this.showError(err);
			});
		}
		this.removeFileHandler('preparations');
		this.setState({
			selectFileText: 'KC0731',
			preparationSelectionClass: "initialActive"
		});
	}

	LoadMediaList = () => {

		http.get(`${MediaManagementAPI.getMediaBrandCustomer}`,
			{
				headers: {
					'Content-Type': 'application/json',
					'customer': KCStore.getItem("customerId"),
					'brand': 'CREM',
					'type': 'preparation',
					'basecountry': BASECOUNTRY_ID
				},
				data: {}

			})
			.then(response => {
				console.log('media_preparation_list--->', response.data);
			})
			.catch(error => {
				this.showError(error);
			})
	}

	putData = (postData, url, method) => {
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_200") {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	previewMediafile = (MEDIA_ID, FILE_NAME) => {
		if (MEDIA_ID && MEDIA_ID.length > 0 && MEDIA_ID != null) {
			http.get(`${MediaManagementAPI.getMediamediapreview}` + MEDIA_ID,
				{
					headers: {
						'Content-Type': 'application/json'
					},
					data: {}
				})
				.then(response => {

					let fileext = FILE_NAME.split('.');
					fileext = fileext[1];
					let previewformat = "";
					if (fileext === "png" || fileext === "jpg" || fileext === "jpeg")  {
						previewformat = "image";
					}
					else if (fileext === "mp4" || fileext === "h264" || fileext === 'zip') {
						previewformat = "video";
					}
					this.setState({
						previewfilename: FILE_NAME,
						previewurl: response.data.imageUrl,
						previewfileformat: previewformat
					});
				})
				.catch(error => {
					this.showError(error);
				})
		}
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	componentWillReceiveProps(nextProps) {
		console.log('componentWillReceiveProps')
	}
	shouldComponentUpdate(nextProps, nextState) {
		console.log('shouldComponentUpdate')
		return true;
	}

	dragStart(e, item) {
		this.setState({ dragItem: item })

	}
	onDrop(e) {
		const { formatMessage } = this.props.intl;
		let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");
		if (userProfileAuthFeature.is_editable === 1) {
			const { dragItem } = this.state;
			const { mediaDraggedItemInfo } = this.props;
			const draggedItem = { type: 'preparation', item: dragItem }
			if (!mediaDraggedItemInfo.prepartions || mediaDraggedItemInfo.prepartions.length === 0) {
				this.props.mediaDraggedItem(draggedItem);
			}

			this.setState({
				userSelectAll: false,
				isIndeterminateSelect:false,
				userCheckedData: [],
				isAdd: true,
				isDelete: false,
				isAddToPreparation: false,
				selectFileText: 'KC0731',
				preparationSelectionClass: "initialActive"
			});
		}
	}
	onDragOver(e) {
		console.log('onDragOver', e);
	}

	removeFileHandler(type = '') {
		if (type === 'preparations') {
			this.props.mediaDragDeleteItem({ type: 'preparations' });
		} else if (type === 'other') {
			this.props.mediaDragDeleteItem({ type: 'other' });
		}
	}
	updatePrepartionSelectedImage() {
		WoopraEvents(`${Constants.FILE_SELECTED}`);
		const zipimage = require("../../../images/icons/ZipIcon.png");
		const { prepartions } = this.props.mediaDraggedItemInfo;

		return <div className="dragedDetailsTable">
			{prepartions && prepartions.length > 0 && <div className="dragedDetailsRow">
				<div className="dragedImage">
					<img src={prepartions[0].FILE_FORMAT === '.zip' ? zipimage : prepartions[0].thumbUrl} />
				</div>
				<div className="dragedText">
					<h5>{prepartions[0].FILE_NAME}</h5>
					<h6>{prepartions[0].TYPE.replace(/\b[a-z]/g, match => match.toUpperCase())}</h6>
					<a href="#" className="removeImageVideo" onClick={() => this.removeFileHandler('preparations')}></a>
				</div>
			</div>}
		</div>
	}
	updateOtherSelectedImage() {
		const { other } = this.props.mediaDraggedItemInfo;
		const zipimage = require("../../../images/icons/ZipIcon.png");
		return <div className="dragedDetailsTable compilationBg">
			{other && other.length > 0 && <div className="dragedDetailsRow">
				<div className="dragedImage">
					<video poster={other[0].FILE_FORMAT === '.zip' ? zipimage : other[0].thumbUrl}>
						<source src={other[0].FILE_FORMAT === '.zip' ? zipimage : other[0].thumbUrl} type="video/mp4" />
						<FormattedMessage id="KC1518"/>
												</video>
				</div>
				<div className="dragedText">
					<h5>{other[0].FILE_NAME}</h5>
					<h6>{other[0].TYPE.replace(/\b[a-z]/g, match => match.toUpperCase())}</h6>
					<a href="#" className="removeImageVideo" onClick={() => this.removeFileHandler('other')}></a>
				</div>
			</div>}
		</div>
	}
	updateSize() {
		const { size } = this.props.mediaDraggedItemInfo;
		let widthPercent = '0%';
		if (size <= 80) {
			widthPercent = (size * 1.25) + '%';
		} else if (size > 80) {
			widthPercent = '100%';
		}
		
		return <div>
			<div className="maxFileSize">
				<div className={(size > 80) ? "progress-bar errorUpload" : "progress-bar "} role="progressbar"
					style={{ width: widthPercent }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="80">
					<FormattedMessage id="KC1734" values={{ size: this.roundingTheSize() }}/></div>
					<FormattedMessage id="KC1525"/></div>
		</div>
	}
	roundingTheSize() {
		const { size } = this.props.mediaDraggedItemInfo
		if (size >= 0)
			return Math.floor(size * 100) / 100;
		else
			return 0;
	}

	renderButton(filetype, mediaID, fileName, dynacityValue) {
		const { formatMessage } = this.props.intl;
		if (filetype === '.zip') {
			return (
				<div className="mediaPreparationIcon">					
					<button onClick={(e) => {this.callOrgChartPage(dynacityValue)}} className="btn assignunitSite"  title={formatMessage({ id: 'KC1030' })} >&nbsp;</button>
					<a href="javascript:void(0)" className="mediaPreview disabled" title={formatMessage({ id: 'KC0730' })}>&nbsp;</a>
				</div>
			);
		} else {
			return (
				<div className="mediaPreparationIcon">
					<button onClick={(e) => {this.callOrgChartPage(dynacityValue)}} className="btn assignunitSite"  title={formatMessage({ id: 'KC1030' })} >&nbsp;</button>
					<a href="javascript:void(0)" data-toggle="modal" data-target="#mediaPreviewModal"
						onClick={() => this.previewMediafile(mediaID, fileName)}
						className="mediaPreview" title={formatMessage({ id: 'KC0730' })}>&nbsp;</a>					
				</div>
			);
		}
	}

	getDownloadURL = async () => {
		const mediaPreparationList = [...this.state.userCheckedData];
		mediaPreparationList && mediaPreparationList.map((item, i) =>
			this.downloadFiles(item.downloadMediaSignedUrl),
		)		
	}

	downloadFiles = (uri) => {
		const { formatMessage } = this.props.intl;
		const  link = document.createElement("a");
		link.href = uri;
		// link.setAttribute('target', '_blank');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

			this.setState({
				loaderIconVisible: false,
				success_modal: {
					open: true,
					message: formatMessage({ id: 'KC1519' })
				},
			});					
	}
	
	handleChangeText( e, key) {
		let value = e && e.target && e.target.value;
		if( key) {
			let obj = {};
			obj[key] = value;
			this.setState({ ...obj, isFilename : value ? false: true});
		}
	}

	onAssignSmartGroupsList() {
		WoopraEvents(`${Constants.MEDIA_SG_ASSIGN}`);
		var rolesList = [...this.state.userCheckedData];
		let item;
		if (rolesList.length > 0) {
			item = rolesList[0];
			KCStore.setItem('selectedItemsInfo', JSON.stringify(item));
			let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
			this.props.history.push({
				pathname: "/mediaAssignSmartGroupsList",
				previouspath: "/mediaPreparation",
				FILE_NAME: item.FILE_NAME,
				VERSION: item.VERSION,
				UPLOADED_ON: item.UPLOADED_ON,
				brandId: brandId, 
				MODEL_NAMES: item.MODEL_NAMES,
				mediaDraggedItemInfo: item,
				smart_groups_ids: item.SMARTGROUPS_ID,
			});
		}
	}
 

	onTransferMedia() {
		WoopraEvents(`${Constants.MEDIA_TRANSFER}`);
		const { mediaDraggedItemInfo } = this.props;
		var rolesList = [...this.state.userCheckedData];
		let item;
		if (rolesList.length > 0) {
			item = rolesList[0];
			KCStore.setItem('selectedItemsInfo', JSON.stringify(item));
			let brandId = `${BRAND_ID_VALUE[item.BRAND]}`;
			let val="";
			// if (item.SMARTGROUPS_ID){
			// 	val="smart-group-transfer";
			// }
			this.props.history.push({
				pathname: val?"mediaTransferUnits":"/assignmedialistView",
				previouspath: "/MediaPreparation",
				mediaSelectedItemsInfo:mediaDraggedItemInfo,
				FILE_NAME: item.FILE_NAME,
				VERSION: item.VERSION,
				UPLOADED_ON: item.UPLOADED_ON,
				brandId: brandId,
				MODEL_NAMES: item.MODEL_NAMES,
				mediaDraggedItemInfo: item,
				transferType : val,
			});
		}
	}

	FinsSelectedFileDetails = (e) => {
		let userCheckedData = this.state.userCheckedData;
		let files = userCheckedData;		
		let selFiles =[];		
		if( files && files.length > 0 ) {
			// selFiles.push(e.target.files[0])
			// let file =  e.target.files[0];
			let fileName = e[0].FILE_NAME;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_').replace(/ /g,'');

			let arr = fileName.split('.');
			let fileExt = arr && arr.pop();
			let menuId = `${arr.join('')}_${+new Date()}`;
			selFiles.push(menuId);
			KCStore.setItem('selectedItemsInfo', JSON.stringify(files[0].MEDIA_ID?files[0].MEDIA_ID:selFiles));
		}		
	} 

	callOrgChartPage = (selCgid) => {
		this.setState({ loaderIconVisible: true });
		KCStore.setItem('orgCgid',selCgid)
		this.props.history.push({
			pathname: "/organizationChartView",
			getCgids:selCgid,
			previousPath:'/mediapreparation'
		});
	} 

	// Woopra Events
    trackEvent = (e) => {
        WoopraEvents(`${Constants.MEDIA}-${e.trackTitle}`);
    }

	static getDerivedStateFromProps = (props) => ({...props});

	render() {
		const { formatMessage } = this.props.intl;
		const zipimage = require("../../../images/icons/ZipIcon.png");
		const {fileName,  isFilename, userSelectAll, isEdit, userCheckedData, previewfilename, previewurl, isDelete, previewurl11, sortColumn, isAdd, loaderIconVisible, previewfileformat, isAddToPreparation, preparationSelectionClass } = this.state;
		const { prepartions, other, size } = this.props.mediaDraggedItemInfo;
		let { mediaPreparationListInfo = {}, mediaDraggedItemInfo } = this.props;
		let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");

		let { success_modal, error_modal, selectFileText } = this.state;
		
		let orderId = 1;

		let columns = [
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0102", isNbSp: true },
			{ path: "FILE_NAME", labelStringID: "KC0677" },			
			{ path: "CREATEDAT", labelStringID: "KC0725" },
			{ path: "RESOLUTION", labelStringID: "KC0726", isNbSp: true },
			{ path: "FILE_SIZE", labelStringID: "KC0682", isNbSp: true },
			{ path: "IN_USE_COUNT", labelStringID: "KC0728", isNbSp: true },
			{ path: "", labelStringID: "KC0729", isNbSp: true }, 
			// { path: "", labelStringID: "KC2317" },
			{ path: "", labelStringID: "KC0730", isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		if(!mediaPreparationListInfo || !mediaPreparationListInfo.mediaList){
			mediaPreparationListInfo = {};
			mediaPreparationListInfo.mediaList = [];
		}
		// User Story 72565: [Quickwin]: Sorting on Date Fields in CREM Library
		if (sortColumn.path === "CREATEDAT") {
			if (sortColumn.order === "desc") {
				mediaPreparationListInfo.mediaList = mediaPreparationListInfo.mediaList.sort((d1, d2) => new Date(d2.CREATEDAT) - new Date(d1.CREATEDAT));
			}
			else {
				mediaPreparationListInfo.mediaList = mediaPreparationListInfo.mediaList.sort((d1, d2) => new Date(d1.CREATEDAT) - new Date(d2.CREATEDAT));
			}
		}
		else {
		mediaPreparationListInfo.mediaList = _.orderBy(mediaPreparationListInfo.mediaList, [sortColumn.path], [sortColumn.order]);
		}
		mediaPreparationListInfo.mediaList.forEach(item => {
			const findIndex = userCheckedData.findIndex((checkedData) => checkedData.MEDIA_ID === item.MEDIA_ID && item.isAuthorized)
			if (findIndex > -1) {
				item.checked = true;
			}
			else {
				item.checked = false;
			}
			item.order_id = orderId++
		});

		let listCount = mediaPreparationListInfo.mediaList.length;

		let allowDownload=false;
		let selectedCheckboxCount=0;
		mediaPreparationListInfo.mediaList.forEach(item => {
			if (item.checked){
				selectedCheckboxCount = selectedCheckboxCount+1;
			}
		});

		if (selectedCheckboxCount>1){
			allowDownload = false;
		} else if  (selectedCheckboxCount===1){
			allowDownload = true;
		}

		if (previewfileformat === "image") {
			$("#imgdiv").removeClass("displaynone");
			$("#videodiv").addClass("displaynone");
		}
		else if (previewfileformat == "video") {
			$("#videodiv").removeClass("displaynone");
			$("#imgdiv").addClass("displaynone");
		}

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div className="mediaWrapper">
						<div>
							<div className="mediaHeader">
								<ul>
									<li>
										<Link to="/mediapreparation" title={formatMessage({ id: 'KC0719' })} className="active" onClick={(e)=> {e.trackTitle="Preparation";this.trackEvent(e)}}><FormattedMessage id="KC0719"/></Link> 
									</li>
									<li>
										<Link to="/mediascreensaver" title={formatMessage({ id: 'KC0209' })} onClick={(e)=> {e.trackTitle="Screensaver";this.trackEvent(e)}}><FormattedMessage id="KC0209"/></Link>
									</li>
									<li>
										<Link to="/mediacompilation" title={formatMessage({ id: 'KC0736' })} onClick={(e)=> {e.trackTitle="Compilation";this.trackEvent(e)}}><FormattedMessage id="KC0736"/></Link>
									</li>
									<li>
										<Link to="/mediapackage" title={formatMessage({ id: 'KC0737' })} onClick={(e)=> {e.trackTitle="Package";this.trackEvent(e)}}><FormattedMessage id="KC0737"/></Link>
									</li>
									<li>
										{/* <button onClick={() => this.onAssignSmartGroupsList()} title={formatMessage({ id: 'KC0722' })}  className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn sgStatus" : "btn sgStatus disabled"}><span className="btnIcon downloadLogbook" /></button> */}
										<button  title={formatMessage({ id: 'KC0710' })} className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn assignMedia" : "btn assignMedia disabled"} onClick={(e) => this.onTransferMedia(e)} >&nbsp;</button>
										<button data-toggle="modal" data-target="#confirmationDownloadModal" type="button" title={formatMessage({ id: 'KC0723' })}  className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn btn-default-text download" : "btn btn-default-text download disabled"}><span className="btnIcon downloadLogbook"></span></button>
										<button type="button" className={userProfileAuthFeature.is_editable === 1 && isDelete ? "btn btn-default-text rowDelete" : "btn btn-default-text rowDelete disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal" title={formatMessage({ id: 'KC0721' })} >&nbsp;</button>
										<input id="imgMp4upload" type="file" className="mediaUploadImgMp4" accept="image/png, video/mp4, application/zip" onChange={(e) => this.onChangeHandler(e)} />
										<button id="btnadd" type="button" className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text rowAdd OpenImgMp4Upload ${isAdd ? "" : "disabled"}` : "btn btn-default-text rowAdd OpenImgMp4Upload disabled"} title={formatMessage({ id: 'KC0720' })}>&nbsp;</button>
									</li>
								</ul>
						 
							</div>
							<div className="mediaPreparationTable mediatHead">
							<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
							</div>
                          </div>
							<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
								<div className="modal-dialog modal-md">
									<div className="modal-content" >

										<div className="modal-body">
											<div className="deleteConfirmation">
												<FormattedMessage id="KC0753"/>
                            				</div>
										</div>
										<div className="modal-footer textAlignCenter">
											<button className="btn btn-secondary deleteNoBtn" onClick={() => this.cancelDeleteUser()} data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
											<button className="btn btn-secondary deleteYesBtn" onClick={() => this.deleteSelectedMedia()} data-dismiss="modal" type="button"><FormattedMessage id="KC0558"/></button>
										</div>
									</div>
								</div>
							</div>
							<div className="modal fade mediaPreviewModal" data-keyboard="true" data-backdrop="static" id="mediaPreviewModal" tabIndex="-1" role="dialog" aria-labelledby="mediaPreviewModalLabel">
								<div className="modal-dialog modal-lg modal-xs">
									<div className="modal-content">
										<div className="modal-header modal-header-danger">
											{previewfilename} <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closePlayer}>&nbsp;</button>
										</div>
										<div className="modal-body">

											<div id="imgdiv" className="imageandVideoPreview displaynone">
												<img src={previewurl}></img>
											</div>

											<div id="videodiv" className="imageandVideoPreview displaynone">
												<ReactPlayer url={previewurl} playing controls />
											</div>
										</div>
									</div>
								</div>
							</div>
							<form className="mediaPreparationForm mediaTableListForm">
								<div className="mediaPreparationTable">								 
									{mediaPreparationListInfo.mediaList && mediaPreparationListInfo.mediaList.map((item, i) =>					
										<ul className="tBody">
											<li>{i + 1}</li>
											<li draggable="true" onDragStart={(e) => this.dragStart(e, item)}>
												<span className="mediaFileSpan"> <img src={item.FILE_FORMAT === '.zip' ? zipimage : item.thumbUrl} /></span>
											</li>
											<li>{item.FILE_NAME.split(".").length>1?item.FILE_NAME.split(".")[0] + item.FILE_FORMAT.toLowerCase():item.FILE_NAME}
												<div className="mediaSListSM">{item.CREATEDAT}</div>
												<div className="mediaSListRS">{item.RESOLUTION}, {item.FILE_SIZE} <FormattedMessage id="KC1498"/></div>
												<div className="mediaSListAS">
													{item.assignedCustomerGrops.map((subitem, j) =>
														<div className="assignedTo alert alert-dismissible fade show" role="alert">
															{subitem}
														</div>
													)}
												</div></li>																
											<li>{item.CREATEDAT}</li>
											<li>{item.RESOLUTION}</li>
											<li>{item.FILE_SIZE}</li>
											<li>{item.IN_USE_COUNT}</li>
											<li>{item.assignedCustomerGrops.map((subitem, j) =>
												<div className="assignedTo alert alert-dismissible fade show" role="alert">
													{subitem}
												</div>
											)}
											</li>  
											<li>{this.renderButton(item.FILE_FORMAT, item.MEDIA_ID, item.FILE_NAME, item.ASSINGEDTO)}</li>
											<li>
												{!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1748' })} direction="left" className="targetdisabled">
												<div className={ "customCheckbox customCheckboxWOlabel disabled"}>
													<input id={i + 1} name="mediaPreparationList" type="checkbox" value={item.checked} defaultChecked={item.checked}
														checked={item.checked}
														disabled
														onChange={(e) => this.handleSingleCheckboxChange(e, item, listCount)} />
													<label for={i + 1}></label>
												</div>
												</Tooltip> : <div className={userProfileAuthFeature.is_editable == 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
													<input id={i + 1} name="mediaPreparationList" type="checkbox" value={item.checked} defaultChecked={item.checked}
														checked={item.checked}
														onChange={(e) => this.handleSingleCheckboxChange(e, item, listCount)} />
													<label for={i + 1}></label>
												</div>}
											</li>
										</ul>)}
								</div>
								<NoRecordsFound loaderIconVisible={loaderIconVisible} length={(mediaPreparationListInfo && mediaPreparationListInfo.mediaList !== undefined) ? mediaPreparationListInfo.mediaList.length : 0} classname={'norecordsfoundbluetext'} />
							</form>

							{size > 80 && <div className="mediaFileSizeTooLarge"><FormattedMessage id="KC1522"/></div>}

							{/* <!-- Upload --> */}
							<div className="mediaUploadFTable">
								<ul>
									<li>
										<div className="drogAndDropG">
											<ul>
												<li className={userProfileAuthFeature.is_editable ? preparationSelectionClass : ""} onDragLeave={(e) => this.onDrop(e)} onDragOver={(e) => this.onDragOver(e)} onClick={(e) => userProfileAuthFeature.is_editable === 1 && isAddToPreparation ? this.onDrop(e) : (e)}>
													{prepartions && prepartions.length > 0 ? this.updatePrepartionSelectedImage() :
														<Link to="#" className="DragPreparationhere" title={formatMessage({ id:selectFileText})}>{formatMessage({ id:selectFileText})}</Link>
													}</li>
												<li>{other && other.length > 0 ? this.updateOtherSelectedImage() :
													<Link to="#" className="DragScreensaverhere" title={formatMessage({ id: 'KC0732' })}><FormattedMessage id="KC0732"/></Link>
												}</li>
											</ul>
										</div>
										{this.updateSize()}
									</li>
									<li>
										<button type="button" data-toggle="modal" data-target="#newFileNameModal" disabled={(size > 80 || !other || other.length === 0) && (size > 80 || !prepartions || prepartions.length === 0) || userProfileAuthFeature.is_editable === 0}
											className={(size > 80 || !other || other.length === 0) && (size > 80 || !prepartions || prepartions.length === 0) ? '' : userProfileAuthFeature.is_editable === 0 ? '' : 'active'}
										><FormattedMessage id="KC0733"/></button>
										{/* >Assign Media</button> */}
									</li>
								</ul>
							</div>

							<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDownloadModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationDownloadModalLabel">
								<div className="modal-dialog modal-md">
									<div className="modal-content">
										<div className="modal-body">
											<div className="deleteConfirmation">
												<FormattedMessage id="KC1580"/>
											</div>
										</div>
										<div className="modal-footer textAlignCenter">
											<button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
											<button onClick={(e) => { this.getDownloadURL() }} className="btn btn-secondary deleteYesBtn" id="downloadYesBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
										</div>
									</div>
								</div>
							</div>

							{/* New File Name Modal */}
							<div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="newFileNameModal" tabIndex="-1" role="dialog" aria-labelledby="newFileNameModalLabel">
								<div className="modal-dialog modal-md">
									<div className="modal-content">  
										<div className="modal-body"> 
											<div className="confirmationYesNo">  
												<h5><FormattedMessage id="KC1523"/></h5>
												<input 
													onChange ={(e) => this.handleChangeText(e, 'fileName')} 
													type="text" className="form-control" value = {fileName} placeholder={formatMessage({ id: 'KC1922' })} />
												{ isFilename && <div className="errorMsg"> <FormattedMessage id="KC1524"/></div>}
											</div> 
										</div>
										<div className="modal-footer textAlignCenter">
											<button className="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
											<button className="btn btn-secondary yesBtn" type="button" onClick={() => this.onAssignClickHandler()}><FormattedMessage id="KC0123"/></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader headerName="Media – Crem Library" headerNameStringID="KC1737" activeClass="mediaNav" />

				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		mediaPreparationListInfo: state.mediaPreparationListInformation.mediaPreparationListInformation,
		mediaDraggedItemInfo: state.mediaDraggedItem.mediaDraggedItem,
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaPreparationList, mediaSelectedItems, mediaDraggedItem,
		mediaDragDeleteItem, resetSelectedItems, setCreatePackageAction, searchVisible,searchText
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MediaPreparation));