import { panDateFormat } from "../../../utils/dashboardRenderer";
import { getDateFromISOString, secondsToHM, secondsToDHMTrend } from "../configuration-compiler-helper";
import moment from "moment";
const _ = require('lodash');

const getTask = (task) => {
    let newTask = task.toLowerCase()
    if (newTask == 'standard' || newTask == 'active' || newTask == 'warning') newTask = 'holding'
    if (newTask == 'discard') newTask = 'expired'
    if (newTask == 'waiting') newTask = 'idle'
    return newTask
}
const groupEvents = (data) => {
    let result = []
    for (let i = 0; i < data.length; i++) {

        let event = data[i]
        if (result && result.length && new Date(result[result.length - 1].toDate).getTime() == new Date(event.fromDate).getTime() && (result[result.length - 1].task == event.task || ['standard', 'active', 'warning'].includes(result.task))) {
            result[result.length - 1].toDate = event.toDate
        } else {
            result.push(event)
        }
    }
    return result
}


const handleEvent = (data, requestDate = new Date()) => {
    let event = { ...data }
    event.fromDate = moment(event.fromDate).format(panDateFormat)
    event.toDate = event.toDate ? moment(event.toDate).format(panDateFormat) : moment(requestDate).format(panDateFormat)
    event.task = event.task ? getTask(event.task) : 'off'
    if (event.name != "Error") event.name = `Pan ${event.name}`
    return event
}

const handlePan = async (panData, requestToDate, requestFromDate, isDataStandardized) => {

    let panResult = []
    let durationInterval = 5;
    let unit = 'second'
    panData = _.sortBy(panData, 'fromDate')
    panData = isDataStandardized ? panData : groupEvents(panData);

    if (panData?.length) {
        durationInterval = moment.duration(moment(panData[0].toDate).diff(moment(panData[0].fromDate))).asSeconds()
        durationInterval = durationInterval / 2
        unit = durationInterval >= 60 ? 'minute' : 'second'
    }

    for (let index = 0; index < panData.length; index++) {

        let event = { ...panData[index] }
        if (event?.fromDate) event.fromDate = getDateFromISOString(event.fromDate, false)
        if (event?.toDate) event.toDate = getDateFromISOString(event.toDate, false)
        let eventToInsert

        // Insert First Record
        if (index == 0) {
            // Off Event - Beginning Check
            const start = moment(requestFromDate)
            const end = moment(event.fromDate)
            let betweenDuration = moment.duration(end.diff(start));
            betweenDuration = betweenDuration.asSeconds();
            // Insert Off Event
            if (betweenDuration > durationInterval) {
                let offEvent = { ...event }
                offEvent.task = "off";
                offEvent.fromDate = start;
                offEvent.toDate = moment(end).subtract(1, unit);
                eventToInsert = handleEvent(offEvent);
                panResult.push(eventToInsert)
            }
            eventToInsert = handleEvent(event);
            panResult.push(eventToInsert);

            const startNext = moment(event.toDate)
            const endNext = panData[index + 1] ? moment(getDateFromISOString(panData[index + 1].fromDate), false) : moment(new Date())
            let betweenDurationNext = moment.duration(endNext.diff(startNext));
            betweenDurationNext = betweenDurationNext.asSeconds();

            // Check - Off Event
            if (betweenDurationNext > durationInterval) {
                let offEvent = { ...event }
                offEvent.task = "off";
                offEvent.fromDate = moment(startNext).add(1, unit);
                offEvent.toDate = moment(endNext).subtract(1, unit);
                eventToInsert = handleEvent(offEvent)
                panResult.push(eventToInsert)
            }
            continue;
        }

        // Insert Last Record
        if (index == panData.length - 1) {

            // Off Event - End Check
            const start = moment(event.toDate)
            const end = moment(requestToDate)
            let betweenDuration = moment.duration(end.diff(start));
            betweenDuration = betweenDuration.asSeconds();
            // Insert Off Event
            if (betweenDuration > durationInterval) {
                let offEvent = { ...event }
                offEvent.task = "off";
                offEvent.fromDate = moment(start).add(1, unit);
                offEvent.toDate = end;

                // Insert Last Event
                eventToInsert = handleEvent(event);
                panResult.push(eventToInsert);

                // Insert Last Off Event
                eventToInsert = handleEvent(offEvent)
                panResult.push(eventToInsert)
            }
            break;
        }

        // Insert Intermediate Records
        const interEvent = handleEvent(event)
        panResult.push(interEvent)

        const start = moment(event.toDate)
        const end = panData[index + 1] ? moment(getDateFromISOString(panData[index + 1].fromDate, false)) : moment(new Date())
        let betweenDuration = moment.duration(end.diff(start));
        betweenDuration = betweenDuration.asSeconds();

        // Check - Off Event
        if (betweenDuration > durationInterval) {
            let offEvent = { ...event }
            offEvent.task = "off";
            offEvent.fromDate = moment(start).add(1, unit);
            offEvent.toDate = moment(end).subtract(1, unit);
            eventToInsert = handleEvent(offEvent)
            panResult.push(eventToInsert)
        }
    }
    return panResult;
}

const handleAllPans = async (pansData, requestToDate, requestFromDate, isDataStandardized) => {
    const sortedResult = _.sortBy(pansData, ['_id']);
    let result = [];
    for (let i = 0; i < sortedResult.length; i++) {
        let panResult = await handlePan(sortedResult[i].event, requestToDate, requestFromDate, isDataStandardized)
        result.push(panResult)
    }
    result = _.flatten(result)
    return result
}

const handleErrors = async (data, requestDate) => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
        const errorEvent = data?.[i]
        if (errorEvent?.fromDate) errorEvent.fromDate = getDateFromISOString(errorEvent.fromDate, false)
        if (errorEvent?.toDate) errorEvent.toDate = getDateFromISOString(errorEvent.toDate, false)
        const event = await handleEvent(errorEvent, requestDate);
        result.push(event)
    }
    var errorData = result.filter(function (data) {
        return data.name === "Error";
    });
    var errorGroups = _.groupBy(errorData, function (value) {
        return value.fromDate;
    });
    var groupsdata = _.map(errorGroups, function (group) {
        var errDesc = "";
        group.forEach(item => {
            errDesc += item.task + " : " + moment(item.fromDate).format(panDateFormat);
            if (item.toDate !== null) {
                errDesc += " - " + moment(item.toDate).format(panDateFormat);
            }
            errDesc += "\n";
        });
        return {
            fromDate: group[0].fromDate,
            toDate: group[0].toDate,
            name: "",
            panSize: group[0].panSize,
            task: errDesc
        }
    });
    var responsedata = _.remove(result, function (data) {
        return data.name !== "Error";
    });
    groupsdata.forEach(item => {
        responsedata.push(item);
    });
    let value = [];
    for (let index = 0; index < responsedata.length; index++) {
        const element = responsedata[index];
        value.push({
            x: {
                from: element.fromDate,
                to: element.toDate,
            },
            message: element.task,
            image: require("../../../images/icons/error.png"),
            y: "",
            stack: "off",
        })
    }
    return value
}

const formatStandardizedPanData = async (pansData) => {
    for (let i = 0; i < pansData.length; i++) {
        console.log("Pan ", pansData[i]._id)
        let result = await handlePanHold(pansData[i].event)
        result = _.flatten(result)
        result = await validateEvents(result)
        pansData[i].event = result
    }
    return pansData
}

const handlePanHold = async (pansData) => {
    const transactions = _.groupBy(pansData, 'transactionId');
    let panEvents = [];
    const holdEventKeys = Object.keys(transactions)
    for (let i = 0; i < holdEventKeys.length; i++) {
        let data = {}
        const holdEvents = transactions[holdEventKeys[i]]
        for (let j = 0; j < holdEvents.length; j++) {
            let holdEvent = holdEvents[j];
            data.name = holdEvent.binNumber
            if (holdEvent.eventType == "holdStart") {
                data.fromDate = holdEvent.timestamp
            } else if (holdEvent.eventType == "holdStop") {
                data.toDate = holdEvent.timestamp
                data.task = "holding"
            } else if (holdEvent.eventType == "holdExpired") {
                data.toDate = holdEvent.timestamp
                data.task = "expired"
            }
        }
        panEvents.push(data)
    }
    return panEvents
}

const validateEvents = async (events) => {
    const finalEvents = []
    for (let i = 0; i < events.length; i++) {
        let event = events[i]
        if (!event.fromDate) {
            continue;
        } else if (!event.toDate) {
            event.toDate = new Date().toString()
            event.task = "holding"
            finalEvents.push(event)
        } else {
            finalEvents.push(event)
        }
    }
    return finalEvents
}

const panStateVsTime = async (panData, errorData, requestToDate, requestFromDate, isDataStandardized) => {
    const panResult = panData.length ? await handleAllPans(panData, requestToDate, requestFromDate, isDataStandardized) : [];
    const errorResult = errorData.length ? await handleErrors(errorData, requestToDate) : [];
    let value = [];
    for (let index = 0; index < panResult.length; index++) {
        const element = panResult[index];
        value.push({
            x: {
                from: element.fromDate,
                to: element.toDate,
            },
            y: element.name,
            stack: element.task,
        })
    }
    console.log("pan vs statre value", value)
    const result = [...value, ...errorResult];
    return result;
}

const prepareDiagnosticObject = (product, holdingDuration) => {
    let diagnosticDetails = holdingDuration.find(data => data.productName == product)
    let productObj = {
        productName: product,
        holdStartCount: 0,
        holdExpiredCount: 0,
        doubleTapCount: 0,
        resetCount: 0,
        details: []
    }
    diagnosticDetails = diagnosticDetails?.details?.length ? diagnosticDetails.details : []
    for (const detail of diagnosticDetails) {
        let detailObj = {
            productName: product,
            binNumber: detail?.binNumber,
            holdStartCount: 0,
            holdExpiredCount: 0,
            doubleTapCount: 0,
            resetCount: 0,
        }
        productObj.details.push(detailObj)
    }
    return productObj
}

const clientFunctions = {
    holdingDataWidget: {
        transformResponse: ({ _clientResponse, _rootScope }) => {
            if (_rootScope.isDataStandardized) {
                const holdingCount = _clientResponse.data.data.holdingCount || [];
                for (let i = 0; i < holdingCount.length; i++) {
                    holdingCount[i].holdDuration = secondsToHM(holdingCount[i].holdDuration);
                    const details = holdingCount[i].details || [];
                    for (let j = 0; j < details.length; j++) {
                        details[j].holdDuration = secondsToHM(details[j].holdDuration);
                        details[j].productName = details[j].binNumber;
                    }
                }
                return {
                    data: {
                        value: holdingCount
                    }
                }
            } else {
                const holdingDuration = _clientResponse.data.data.holdingDuration || [];
                const holdingCount = _clientResponse.data.data.holdingCount || [];
                const holdingDurationMap = {};
                for (let i = 0; i < holdingDuration.length; i++) {
                    holdingDurationMap[holdingDuration[i].productName] = holdingDuration[i].duration;
                    const details = holdingDuration[i].details || [];
                    for (let j = 0; j < details.length; j++) {
                        holdingDurationMap[details[j].productName + details[j].binNumber] = details[j].duration;
                    }
                }

                // Add Products From Diagnostic Events
                const eventProducts = holdingCount.map(data => data.productName)
                const diagnosticProducts = holdingDuration.map(data => data.productName)
                for (const product of diagnosticProducts) {
                    if (!eventProducts.includes(product)) {
                        const diagnosticObject = prepareDiagnosticObject(product, holdingDuration)
                        holdingCount.push(diagnosticObject)
                    }
                }

                for (let i = 0; i < holdingCount.length; i++) {
                    holdingCount[i].holdDuration = holdingDurationMap[holdingCount[i].productName] || 0;
                    holdingCount[i].holdDuration = secondsToHM(holdingCount[i].holdDuration);
                    const details = holdingCount[i].details || [];
                    for (let j = 0; j < details.length; j++) {
                        details[j].holdDuration = holdingDurationMap[details[j].productName + details[j].binNumber] || 0;
                        details[j].holdDuration = secondsToHM(details[j].holdDuration);
                        details[j].productName = details[j].binNumber;
                    }
                }
                return {
                    data: {
                        value: holdingCount
                    }
                }
            }

        }
    },
    panUtilization: {
        transformResponse: ({ _clientResponse, _rootScope }) => {
            if (_rootScope.isDataStandardized) {
                let systemUpTime = 0;
                let { liveViewData = [], onTime = [], panUtilization = [] } = _clientResponse

                if (_rootScope.isDataStandardized && liveViewData?.length) {
                    console.log("Data Standardized Flow Off Time!")
                    let liveViewDataTS = liveViewData.find(data => data._id == "liveViewData")?.timeStamp
                    let systemBootDataTS = liveViewData.find(data => data._id == "systemBootData")?.timeStamp
    
                    if((liveViewDataTS && systemBootDataTS && moment(liveViewDataTS) > moment(systemBootDataTS))  || (liveViewDataTS && !systemBootDataTS)) {
                        systemUpTime = liveViewData.find(data => data._id = "liveViewData")?.duration
                    }
                }
                systemUpTime += onTime?.[0]?.count ? onTime[0].count : 0

                for (let i = 0; i < panUtilization.length; i++) {
                    const activeDuration = panUtilization[i].activeDuration;
                    panUtilization[i].activeDuration = secondsToHM(activeDuration);
                    panUtilization[i].idleDuration = systemUpTime > activeDuration ? secondsToHM(systemUpTime - activeDuration): secondsToHM(0);
                    panUtilization[i].utilization = systemUpTime > 0 ? `${((activeDuration / systemUpTime) * 100).toFixed(1)}%` : '0.0%';
                    panUtilization[i].expiredUtilization = activeDuration > 0 ? `${((panUtilization[i].expiredDuration / activeDuration) * 100).toFixed(1)}%` : '0.0%';

                    const details = panUtilization[i].details || [];
                    for (let j = 0; j < details.length; j++) {
                        const activeDuration = details[j].activeDuration;
                        details[j].activeDuration = secondsToHM(activeDuration);
                        details[j].idleDuration = systemUpTime > activeDuration ? secondsToHM(systemUpTime - activeDuration): secondsToHM(0);
                        details[j].utilization = systemUpTime > 0 ? `${((activeDuration / systemUpTime) * 100).toFixed(1)}%` : '0.0%';
                        details[j].expiredUtilization = activeDuration > 0 ? `${((details[j].expiredDuration / activeDuration) * 100).toFixed(1)}%` : '0.0%';
                        details[j].binNumber = details[j].productName;
                    }
                }
                return {
                    data: {
                        value: panUtilization
                    }
                }
            } else {
                const onTime = _clientResponse.data.data.onTime?.length ? _clientResponse.data.data.onTime[0].count : 0;
                const panUtilization = _clientResponse.data.data.panUtilization || [];
                for (let i = 0; i < panUtilization.length; i++) {
                    const activeDuration = panUtilization[i].activeDuration;
                    panUtilization[i].activeDuration = secondsToHM(activeDuration);
                    panUtilization[i].idleDuration = secondsToHM(panUtilization[i].idleDuration);
                    panUtilization[i].utilization = onTime > 0 ? `${((activeDuration / onTime) * 100).toFixed(1)}%` : '0.0%';
                    panUtilization[i].expiredUtilization = activeDuration > 0 ? `${((panUtilization[i].expiredDuration / activeDuration) * 100).toFixed(1)}%` : '0.0%';

                    const details = panUtilization[i].details || [];
                    for (let j = 0; j < details.length; j++) {
                        const activeDuration = details[j].activeDuration;
                        details[j].activeDuration = secondsToHM(activeDuration);
                        details[j].idleDuration = secondsToHM(details[j].idleDuration);
                        details[j].utilization = onTime > 0 ? `${((activeDuration / onTime) * 100).toFixed(1)}%` : '0.0%';
                        details[j].expiredUtilization = activeDuration > 0 ? `${((details[j].expiredDuration / activeDuration) * 100).toFixed(1)}%` : '0.0%';
                        details[j].binNumber = details[j].productName;
                    }
                }
                return {
                    data: {
                        value: panUtilization
                    }
                }
            }
        }
    },
    panStatesVsTime: {
        transformResponse: async ({ _clientResponse, _widgetScope, _rootScope }) => {
            let isDataStandardized = 0
            let { panData = [], errorData = [] } = _clientResponse.data.data;
            const requestToDate = moment(_widgetScope.dateFilterValue?.toDate).endOf('day');
            const requestFromDate = moment(_widgetScope.dateFilterValue?.fromDate).startOf('day');
            if (_rootScope.isDataStandardized) {
                console.log("Data Standardized Flow!")
                isDataStandardized = 1
                panData = await formatStandardizedPanData(panData)
            }
            const result = await panStateVsTime(panData, errorData, requestToDate, requestFromDate, isDataStandardized);
            return {
                data: {
                    value: result
                }
            }
        }
    },
    liveDiagnostic: {
        // add live diagnostic widget functions here
    },
    onTime: {
        transformResponse: async ({ _clientResponse, _widgetScope, _rootScope }) => {
            try {
                let { current = [], liveViewData = [] } = _clientResponse.data.data;
                let systemUpTime = 0
                if (_rootScope.isDataStandardized && liveViewData?.length) {
                    let liveViewDataTS = liveViewData.find(data => data._id == "liveViewData")?.timeStamp
                    let systemBootDataTS = liveViewData.find(data => data._id == "systemBootData")?.timeStamp

                    if ((liveViewDataTS && systemBootDataTS && moment(liveViewDataTS) > moment(systemBootDataTS)) || (liveViewDataTS && !systemBootDataTS)) {
                        systemUpTime = liveViewData.find(data => data._id = "liveViewData")?.duration
                    }
                }
                systemUpTime += current?.[0]?.count ? current[0].count : 0
                let result = secondsToDHMTrend(systemUpTime, false, _rootScope)
                console.log("ON TIME: ", result)
                return {
                    data: {
                        label: result,
                        value: systemUpTime
                    }
                }
            } catch (e) {
                console.log("Catch in On Time: ", e)
            }
        }
    },
    offTime: {
        transformResponse: async ({ _clientResponse, _widgetScope, _rootScope }) => {
            try {
                let { current = [], liveViewData = [] } = _clientResponse.data.data;
                let systemUpTime = 0
                if (_rootScope.isDataStandardized && liveViewData?.length) {
                    console.log("Data Standardized Flow Off Time!")
                    let liveViewDataTS = liveViewData.find(data => data._id == "liveViewData")?.timeStamp
                    let systemBootDataTS = liveViewData.find(data => data._id == "systemBootData")?.timeStamp

                    if ((liveViewDataTS && systemBootDataTS && moment(liveViewDataTS) > moment(systemBootDataTS)) || (liveViewDataTS && !systemBootDataTS)) {
                        systemUpTime = liveViewData.find(data => data._id = "liveViewData")?.duration
                    }
                }
                systemUpTime += current?.[0]?.count ? current[0].count : 0

                let result = systemUpTime ? secondsToDHMTrend(Math.abs((_widgetScope.dateFilterMinutes * 60 - 1) - systemUpTime), false, _rootScope) : secondsToDHMTrend(0, false, _rootScope)
                console.log("OFF TIME: ", result)
                return {
                    data: {
                        label: result,
                        value: systemUpTime
                    }
                }
            } catch (e) {
                console.log("Catch in Off Time: ", e)
            }
        }
    },
    utilization: {
        transformResponse: async ({ _clientResponse, _widgetScope, _rootScope }) => {
            try {
                let { currentUnitOnTimeDuration = [], currentUnitUtilizationDuration = [], liveViewData = [], totalBins } = _clientResponse.data.data;
                let systemUpTime = 0
                if (_rootScope.isDataStandardized && liveViewData?.length) {
                    console.log("Data Standardized Utilization!")
                    let liveViewDataTS = liveViewData.find(data => data._id == "liveViewData")?.timeStamp
                    let systemBootDataTS = liveViewData.find(data => data._id == "systemBootData")?.timeStamp

                    if ((liveViewDataTS && systemBootDataTS && moment(liveViewDataTS) > moment(systemBootDataTS)) || (liveViewDataTS && !systemBootDataTS)) {
                        systemUpTime = liveViewData.find(data => data._id = "liveViewData")?.duration
                    }
                }
                systemUpTime += currentUnitOnTimeDuration?.[0]?.count ? currentUnitOnTimeDuration[0].count : 0
                let result = Math.round(((currentUnitUtilizationDuration[0]?.count || 0) / (systemUpTime * (typeof (totalBins?.shadow?.document?.deviceStatusInformation?.deviceIdData?.totalBins?.value) != 'undefined' ? totalBins.shadow.document.deviceStatusInformation.deviceIdData.totalBins.value : 1))) * 100, 1)
                return {
                    data: {
                        label: result ? `${result}%` : `0.0%`,
                        value: result
                    }
                }
            } catch (e) {
                console.log("Catch in Utilization: ", e)
            }
        }
    },
    onTimePercentage: {
        transformResponse: async ({ _clientResponse, _widgetScope, _rootScope }) => {
            try {
                let { current = [], liveViewData = [] } = _clientResponse.data.data;
                let systemUpTime = 0
                if (_rootScope.isDataStandardized && liveViewData?.length) {
                    console.log("Data Standardized On Time Percentage!")
                    let liveViewDataTS = liveViewData.find(data => data._id == "liveViewData")?.timeStamp
                    let systemBootDataTS = liveViewData.find(data => data._id == "systemBootData")?.timeStamp

                    if ((liveViewDataTS && systemBootDataTS && moment(liveViewDataTS) > moment(systemBootDataTS)) || (liveViewDataTS && !systemBootDataTS)) {
                        systemUpTime = liveViewData.find(data => data._id = "liveViewData")?.duration
                    }
                }
                systemUpTime += current?.[0]?.count ? current[0].count : 0
                let result = (typeof (_clientResponse.data.data.current[0]?.count) != 'undefined' && _widgetScope.dateFilterMinutes > 0) ? (Math.round((systemUpTime / (_widgetScope.dateFilterMinutes * 60 - 1)) * 100, 1)) : '0.0';
                return {
                    data: {
                        label: result ? `${result}%` : `0.0%`,
                        value: result
                    }
                }
            } catch (e) {
                console.log("Catch in On Time Percentage: ", e)
            }
        }
    }
};

export default clientFunctions;