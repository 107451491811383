var settingsJSON = {
    "id": "",
    "version": "",
    "edit_date": "",
    "author": "",
    "email": "",
    "general": {
        "time_date": {
            "hour_format": 0,
            "set_time": 0,
            "time_mask_value": "",
            "date_format": 1, 
            "set_date": "",
            "day_light_saving_time": 1,
            "day_light_saving": {
                "dst_start": {
                    "month": 3,
                    "sunday": 2
                },
                "dst_end": {
                    "month": 11,
                    "sunday": 1
                }
            }
        },
        "locale": 0,
        "screen": {
            "brightness": 30,
            "saver": {
                "brightness": 30,
                "timeout": 1800
            }
        },
        "temperature": {
            "temperature_unit": 0,
            "display_temperature_button": 0
        },
        "language": {
            "primary": "English",
            "secondary": "Spanish"
        },
        "sound": {
            "speaker_volume": 9
        },
        "fryer_configuration": {
            "energy_type": 0,
            "oil_type": 0,
            "demo_mode": 0,
            "fresh_oil_type": 0,
            "dispose_oil_type": 0,
            "frypot_cover_prompt": 0,
            "frypot_cover_prompt_duration":10,
        }
    },
    "heating": {
        "performance_test": {
            "performance_test_alarm": 0
        }
    },
    "cooking": {
        "menus": {
            "display_menu_book_button": 0,
            "auto_menu_change": 0,
            "segmented_cook": 0
        },
        "lane_configuration": {
            "cooking_lane": {
                "lane": 0
            }
        },
        "product_display": {
            "number_of_product_images": 0,
            "scroll_image": 0
        },
        "instant_on": {
            "global_time": 0
        },
        "smart_cook_prompt": {
            "prompt": 0,
            "prompt_timer": 15,
            "prompt_temperature": 7
        },
        "basket_lift": {
            "basket_lift": 0,
            "delay_timer": 8
        },
        "oil_condition_alarms": {
            "low_temp_audio": 1,
            "low_temp_cooking": 1,
            "low_temp_idle": 1,
            "oil_cold": 10,
            "oil_low": 10
        }
    },
    "filtration": {
        "general": {
            "sediment_tray": 0,
        },
        "filter_after_cook": {
            "number_of_cook": 15
        }
    },
    "oqs": {
        "dispose_oil_soon": 0,
        "dispose_oil_now": 0,
        "oil_type": 0,
        "reading_display": 2
    },
    "user_access": {
        "manage_password": 1234
    },
    "eco_mode": {
        "eco_mode": 0,
        "temperature": 10,
        "manual_display_button": 0,
        "automatic": 0,
        "automatic_eco_mode": {
            "idle_time": 0,
            "scheduled_time": 0,
            "idle_time_value": "",
            "automatic_scheduled_mode": [{
                "schedule_id": 1,
                "start_time": '',
                "end_time": '',
                "scheduled_day":  ['Monday','Friday'],
                "rotate_fry_pot": 0, 
                "frypot_value":  ['Frypot1','Frypot2']
            }]
        }
    }
}

export default settingsJSON 
