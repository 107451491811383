import React, { Component, PureComponent } from "react";
import http from "../../../service/httpService";
import { ReportManagement } from "../../../service/api";
import { format, addDays } from "date-fns";
import { NewDropDownListLink } from "../../controls/newDropDownListLink";
import { ProductListPopup } from "../frymasterWidgets/ProductListPopup";
import { UNIT_BRAND_ID } from "../../../utils/appConstants";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { FormattedMessage } from 'react-intl';

import KCStore from '../../../utils/kcStorage';

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

var moment = require("moment");
var startOfWeek = require("date-fns/start_of_week");
var startOfMonth = require("date-fns/start_of_month");
var getMonth = require("date-fns/get_month");
var todaysDate = new Date();
let currentUnit;

class TotalCooksLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      peakHourChartInformation: [],
      total_unit_count: 0,
      loading: true,
      filterType: "Month",
      average: 0,
      sd: "",
      ed: "",
      startDate: "",
      endDate: "",
      sdmindate: todaysDate,
      sdmaxdate: moment(todaysDate)
        .subtract("month", 6)
        .subtract(1, "days")._d,
      edmaxdate: todaysDate,
      edmindate: moment(todaysDate)
        .subtract("month", 6)
        .subtract(1, "days")._d,
      productList: [],
      showPopup: false,
      datapickflag: false,
      isStackedLineChart: false,
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.toggleChart = this.toggleChart.bind(this);
  }

  componentDidMount() {
    this.getReportData(this.state.filterType, "", "");
  }

  toggleDatePicker(e) {
    this.setState({ filterType: "Date Range" });
  }

  onCloseModal = () => {
    this.setState({
      showPopup: false,
    });
  };

  handleChangeStart(date) {
    this.setState(
      {
        startDate: date,
        datapickflag: true,
      },
      () => {
        let selectedtext = "Date Range";
        var e = { target: { text: selectedtext }, nodeText:selectedtext };
        this.onDropDownHandler(e);
      }
    );
  }

  handleChangeEnd(date) {
    this.setState(
      {
        endDate: date,
        datapickflag: true,
      },
      () => {
        let selectedtext = "Date Range";
        var e = { target: { text: selectedtext }, nodeText:selectedtext };
        this.onDropDownHandler(e);
      }
    );
  }

  getReportData(text, startDate, date) {
    let unitID = "";
    let brandID = "";
    currentUnit = this.props.properties.currentUnit;
    currentUnit.UNITID = currentUnit.UNITID
      ? currentUnit.UNITID
      : KCStore.getItem("unitID");
    unitID = currentUnit.UNITID;
    brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;

    const header = this.getHeaders(text, startDate, date, unitID);
    let url = `${ReportManagement.widgetAPI +
      "/" +
      this.props.widgetID +
      "/" +
      brandID}`;
    this.getData(url, header)
      .then((response) => {
        let _average = response.peakHourChartInformation.average
          ? response.peakHourChartInformation.average
          : 0;
        this.setState({
          average: _average,
        });
        if (response.peakHourChartInformation) {
          this.renderChartData(text, response.peakHourChartInformation);
        }
      })
      .catch((err) => {
        //this.renderChartData(err);
      });
  }

  onDropDownHandler(e) {
    const { nodeText:text } = e;
    this.setState({ datapickflag: text === "Date Range" });
    this.handleTotalProductCooked(text);
  }

  handleTotalProductCooked(text) {
    this.setState({ filterType: text });
    const { startDate, endDate } = this.state;

    if (text === "Date Range") {
      if (startDate !== "" && endDate !== "") {
        this.getReportData(text, startDate, endDate);
      }
    } else {
      this.getReportData(text, "", "");
    }
  }

  getHeaders(
    type = "Month",
    startDateFormatted = "",
    endDateFormatted = "",
    unitID = ""
  ) {
    let filterType;
    switch (type) {
      case "Week":
        filterType = "weekly";
        break;
      case "Year":
        filterType = "yearly";
        break;
      case "Day":
        filterType = "daily";
        break;
      case "Quarter":
        filterType = "quarterly";
        break;
      case "Date Range":
        filterType = "custom";
        break;
      default:
        filterType = "monthly";
    }

    let header;

    if (filterType === "custom" || filterType === "weekly") {
      if (this.props.widgetID === "65") {
        if (type === "Week") {
          endDateFormatted = format(todaysDate, "YYYY-MM-DD");
          startDateFormatted = format(addDays(todaysDate, -6), "YYYY-MM-DD");
        } else {
          startDateFormatted = format(startDateFormatted, "YYYY-MM-DD");
          endDateFormatted = format(endDateFormatted, "YYYY-MM-DD");
        }
      } else {
        if (type === "Date Range") {
          startDateFormatted = format(startDateFormatted, "YYYY-MM-DD");
          endDateFormatted = format(endDateFormatted, "YYYY-MM-DD");
        } else if (type === "Week") {
          startDateFormatted = format(
            startOfWeek(todaysDate, { weekStartsOn: 1 }),
            "YYYY-MM-DD"
          );
          endDateFormatted = format(todaysDate, "YYYY-MM-DD");

          var startDateMonth = getMonth(new Date(startDateFormatted));
          var endDateMonth = getMonth(new Date(endDateFormatted));

          if (startDateMonth !== endDateMonth) {
            startDateFormatted = format(startOfMonth(todaysDate), "YYYY-MM-DD");
          }
        } else {
          endDateFormatted = format(new Date(), "YYYY-MM-DD");
          startDateFormatted = format(
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            "YYYY-MM-DD"
          );
        }
      }

      header = {
        "Content-Type": "application/json",
        unitId: unitID,
        startdate: startDateFormatted,
        enddate: endDateFormatted,
        filterType: filterType,
      };
    } else {
      startDateFormatted = format(todaysDate, "YYYY-MM-DD");

      header = {
        "Content-Type": "application/json",
        unitId: unitID,
        reqdate: startDateFormatted,
        filterType: filterType,
      };
    }

    return header;
  }

  getData(url, header) {
    return new Promise((resolve, reject) => {
      http
        .get(url, {
          headers: header,
          data: {},
        })
        .then((response) => {
          resolve({
            peakHourChartInformation: response.data,
          });
        })
        .catch((error) => {
          reject({
            peakHourChartInformation: [],
          });
        });
    });
  }

  renderChartData = (type, data) => {
    let response = data.Data && data.Data.length > 0 ? data.Data : [];

    if(type == 'Quarter'){
        let responseData = [...response]
        const splitedQuarter = new Array(Math.ceil(responseData.length / 3))
          .fill()
          .map((_) => responseData.splice(0, 3));
          response = splitedQuarter[Math.floor(new Date().getMonth() / 3)]
    }
    if (type !== "Day") {
      let dateFormat = (type === "Week" || type === "Month" || type === "Custom") ? "D" : (type === "Year" || type === "Quarter") ? "MMMM" : "";
      if (response.length > 0) {
        response.map(item => {
          let day = item.Key.split('-')[2];
          let month = item.Key.split('-')[1];
          day = day && day.length === 1 ? '0' + day : day;
          month = month && month.length === 1 ? '0' + month : month;
          let date = item.Key.split('-')[0] + '-' + month + '-' + day;
          item.Key = (moment(date).format(dateFormat));
          item.alpha = 1;
        });
      }
      else {
        response = [];
      }
    }
    else {
      if (response.length > 0) {
        if (KCStore.getItem("timeFormat") === "24"){
          response.map(item => { item.Key = (item.Key === "24" ? "23-00" : `${item.Key - 1}-${item.Key}` ) });
        } else {
          response.map(item => { item.Key = (item.Key === "12" ? "11-12 pm" : item.Key === "24" ? "11-12 am" : item.Key > 12 ? item.Key === "13" ? "12-1 pm" : `${item.Key-13}-${item.Key-12} pm` : `${item.Key -1}-${item.Key} am`)});
        }
      }
      else {
        response = [];
      }
    }
		

    let chart = am4core.create(
      "totalProductsCookedBarChartdiv",
      am4charts.XYChart
    );
    if (response && response.length > 0) {
      chart.seriesContainer.zIndex = -1;
      chart.data = response;
      this.chart = chart;
      chart.responsive.enabled = true;

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.labels.template.fill = "#fefeff";
      categoryAxis.dataFields.category = "Key";
      categoryAxis.fixedWidthGrid = true;
      categoryAxis.fontSize = 11;
      categoryAxis.fontFamily = "open_sanslight";
      categoryAxis.renderer.line.strokeOpacity = 1;
      categoryAxis.renderer.line.strokeWidth = 1;
      categoryAxis.renderer.line.stroke = am4core.color("#7b7c7f");
      categoryAxis.renderer.autoGridCount = false;
      categoryAxis.renderer.gridCount = 24;
      categoryAxis.renderer.minGridDistance = 24;
      categoryAxis.renderer.grid.template.strokeWidth = 0;
      categoryAxis.renderer.ticks.template.disabled = false;
      categoryAxis.renderer.ticks.template.length = 5;
      categoryAxis.renderer.ticks.template.strokeOpacity = 1;
      categoryAxis.renderer.ticks.template.stroke = am4core.color("#d9d9d9");
      categoryAxis.renderer.ticks.template.strokeWidth = 1;

      function getMaxOfKeys(values, keys) {
        return Math.max(
          ...values.map((obj) => Math.max(...keys.map((key) => obj[key])))
        );
      }

      function createValueAxis () {
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max =
          parseInt(
            getMaxOfKeys(
              response,
              Object.keys(response[0]).filter((e) => e !== "Key" && e !== "Value")
            )
          ) * 2;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");
        valueAxis.renderer.grid.template.stroke = am4core.color("#434549");
        valueAxis.renderer.line.stroke = am4core.color("#7b7c7f");
        valueAxis.renderer.grid.template.strokeWidth = 1;
        valueAxis.renderer.grid.template.strokeOpacity = 1;
        valueAxis.renderer.labels.template.fontSize = 11;
        valueAxis.renderer.labels.template.fontFamily = "open_sanslight";
        }

      

      function createSeriesAndAxis(field, name,color, topMargin, bottomMargin) {
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max =
           parseInt(
            getMaxOfKeys(
              response,
              Object.keys(response[0]).filter((e) => e !== "Key" && e !== "Value")
            )
          ) * 2;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");
        valueAxis.renderer.grid.template.stroke = am4core.color("#434549");
        valueAxis.renderer.line.stroke = am4core.color("#7b7c7f");
        valueAxis.renderer.grid.template.strokeWidth = 1;
        valueAxis.renderer.grid.template.strokeOpacity = 1;
        valueAxis.renderer.labels.template.fontSize = 11;
        valueAxis.renderer.labels.template.fontFamily = "open_sanslight";
        valueAxis.align = "right";

        var series = chart.series.push(new am4charts.LineSeries());
        series.stroke = am4core.color(color);
        series.tooltip.label.fill = am4core.color(color);
        series.tooltip.background.fill = am4core.color(color);
        series.dataFields.valueY = field;
        if(type === "Quarter" || type === "Year"){
        series.dataFields.categoryX = "Key";
        }
        series.dataFields.categoryX = "Key";
        series.name = name;
        series.strokeWidth = 3;
        series.tensionX = 0.7;
        series.yAxis = valueAxis;
        if(type === "Quarter" || type === "Year"){
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.dateFormatter.dateFormat = "MM/yyyy";
        }
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.legendSettings.labelText = "[{stroke}]{name}[/]";
        
        if(response && response.length == 1){
          var bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.strokeWidth = 2;
        }     
       
        if (topMargin && bottomMargin) {
          valueAxis.marginTop = 10;
          valueAxis.marginBottom = 10;
        }
        else {
          if (topMargin) {
            valueAxis.marginTop = 20;
          }
          if (bottomMargin) {
            valueAxis.marginBottom = 20;
          }
        }
        
      }


      // Create series
      function createSeries(field, name, color) {
        var series = chart.series.push(new am4charts.LineSeries());
        series.stroke = am4core.color(color);
        series.tooltip.label.fill = am4core.color(color);
        series.tooltip.background.fill = am4core.color(color);
        series.dataFields.valueY = field;
        if(type === "Quarter" || type === "Year"){
        series.dataFields.categoryX = "Key";
        }
        series.dataFields.categoryX = "Key";
        series.name = name;
        series.strokeWidth = 3;
        series.tensionX = 0.7;
        if(type === "Quarter" || type === "Year"){
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.dateFormatter.dateFormat = "MM/yyyy";
        }
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.legendSettings.labelText = "[{stroke}]{name}[/]";
        
        if(response && response.length == 1){
          var bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.strokeWidth = 2;
        }     

        return series;
      }

      chart.seriesContainer.zIndex = 10;

      if(this.state.isStackedLineChart){
        if(response[0].hasOwnProperty("Lane1") || response[0].hasOwnProperty("Lane0")){
          createSeriesAndAxis("Lane1", "Platen 1","#3a92df", false, true);
        }
        if(response[0].hasOwnProperty("Lane2")){
          createSeriesAndAxis("Lane2", "Platen 2","#91c2ed", false, true);
        }
        if(response[0].hasOwnProperty("Lane3")){
          createSeriesAndAxis("Lane3", "Platen 3","#d3e7f8", true, false);
        }
      }else{
        createValueAxis()
        if(response[0].hasOwnProperty("Lane1") || response[0].hasOwnProperty("Lane0")){
          createSeries("Lane1", "Platen 1", "#3a92df");
        }
        if(response[0].hasOwnProperty("Lane2")){
          createSeries("Lane2", "Platen 2", "#91c2ed");
        }
        if(response[0].hasOwnProperty("Lane3")){
          createSeries("Lane3", "Platen 3", "#d3e7f8");
        }

      }

      chart.legend = new am4charts.Legend();
      chart.cursor = new am4charts.XYCursor();
      chart.legend.useDefaultMarker = true;

      chart.leftAxesContainer.layout = "vertical";

      const marker = chart.legend.markers.template;
      marker.width = 15;
      marker.height = 15;
      const markerColumn = marker.children.getIndex(0);

      markerColumn.cornerRadius(0, 0, 0, 0);
      markerColumn.defaultState.properties.fillOpacity = 0;
      markerColumn.defaultState.properties.strokeWidth = 1;
      markerColumn.defaultState.properties.stroke = am4core.color("#000");
      markerColumn.defaultState.properties.strokeOpacity = 1;
     
      const checkbox = marker.createChild(am4core.Image);
      checkbox.width = 15;
      checkbox.height = 15;
      checkbox.verticalCenter = "top";
      checkbox.horizontalCenter = "left";
      checkbox.href = require('../../../images/icons/checkAllOn.svg').default;
      const checkboxActiveState = checkbox.states.create("active");
      checkboxActiveState.properties.opacity = 0;
    } else {
      if (this.chart) {
        this.chart.data = [];
      }
    }
  };

  toggleChart(e) {
    this.setState({
        isStackedLineChart: !this.state.isStackedLineChart,
    });
  }

  componentDidUpdate( prevProps,  prevState) {
    if(prevState.isStackedLineChart != this.state.isStackedLineChart){
      this.getReportData(this.state.filterType, "", "");
    }
    
  }

  componentWillUnmount = () => {
    if (this.chart) {
      this.chart.dispose();
    }
  };

  render() {
    let { showPopup, productList } = this.state;
    return (
      <div className="colmDi">
        <h4>
          {this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
          <NewDropDownListLink
            translation={true}
            datapickflag={this.state.datapickflag}
            startDatepara={this.state.startDate}
            minDateDisable={this.state.sdmindate}
            maxDateDisable={this.state.sdmaxdate}
            endminDateDisable={this.state.edmindate}
            endmaxDateDisable={this.state.edmaxdate}
            endDatepara={this.state.endDate}
            handleChangeStartProps={(date) => this.handleChangeStart(date)}
            handleChangeEndProps={(date) => this.handleChangeEnd(date)}
            OnChange={(e) => this.onDropDownHandler(e)}
            filterType={this.state.filterType}
            disableDatePicker={
              this.props.disableDatePicker
                ? this.props.disableDatePicker
                : false
            } 
          />
        </h4>
        <div class="chartTitleLgnd">{this.props.chartTitleStringID ? <FormattedMessage id={this.props.chartTitleStringID} /> : this.props.chartTitle}</div>
        <div class="chartValLgnd">
          {this.state.average > 0 && this.state.average < 1
            ? "<1"
            : Math.round(this.state.average)}
        </div>
        <div id="totalProductsCookedBarChartdiv"></div>
        <div className="chartLinetoggle">
          {this.state.average === 0 ? null :<input onChange={this.toggleChart} type="checkbox"/>}
          {this.state.average === 0 ? null :<span> <FormattedMessage id="KC0331"/> </span>}
        </div>
        <ProductListPopup
          open={showPopup}
          productList={productList}
          onCloseModal={this.onCloseModal}
        />
      </div>
    );
  }
}

export default TotalCooksLineChart;
