/**
 * @author Baskaran R
 * Added millisec delay for each file download
 * @param {*} ms 
 */
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));


/**
 * @author Baskaran R
 * create a element and append to document
 * @param {*} ms 
 */
const download = (url, delayTime) => {
    return new Promise(async (resolve, reject) => {
        const a = document.createElement('a');
        a.download = '';
        a.href = url;
        a.style.display = 'none';
        document.body.append(a);
        a.click();

        // Chrome requires the timeout
        await delay(100);
        // a.remove();
        document.body.removeChild(a);
        resolve(true);
    });
};

const multiDownload = (urls, options) => {
    return new Promise(async (resolve, reject) => {
        try {

            console.log(urls);
            if (!urls) {
                throw new Error('`urls` required');
            }
            const timeDelay = options.delay || 1000;
            for await (const [index, obj] of urls.entries()) {
                const url = obj.fileUrl;
                const delayTime = (index + 1) * timeDelay;
                await delay(delayTime);
                await download(url, delayTime);
            }
            resolve(true);

        } catch (error) {
            reject(error)
        }
    });

};

export default multiDownload;