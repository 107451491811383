import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';
import $ from 'jquery';
class ManagerPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            manager_password: 'MANAGER'
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let jsonPassword = JSON.parse(jsonSettingsFile).passwords;

            this.setState({
                manager_password: jsonPassword && jsonPassword.manager ? jsonPassword.manager : ""
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    handlePasswordChange = (e) => {        
        if(/^[\x00-\x7F]*$/.test(e.target.value)) {
            this.setState({
                manager_password: e.target.value.replace(' ', '')
            });
        }
    }       

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true,
        });
        let { manager_password } = this.state;
        if (manager_password.replace(' ', '')) {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            if (ObjSettingsFile.passwords) { ObjSettingsFile.passwords.manager = manager_password; }
            if (ObjSettingsFile.passwords === undefined && manager_password != '') {
                Object.assign(ObjSettingsFile, { "passwords": { "manager": manager_password } })
            }
            this.setState({ manager_password });
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            KCStore.setItem("librarySettingsData", strSettingsFile);
            this.props.history.push(`/settingsEditor`);
        }
        else {
            this.setState({
                loaderIconVisible: false,
                manager_password: ""
                // error_modal: {
                //     open: true,
                //     message: "KC1306"
                // }
            });
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            if (ObjSettingsFile.passwords) { ObjSettingsFile.passwords.manager = manager_password; }
            if (ObjSettingsFile.passwords === undefined && manager_password != '') {
                Object.assign(ObjSettingsFile, { "passwords": { "manager": manager_password } })
            }
            delete ObjSettingsFile.passwords;
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            KCStore.setItem("librarySettingsData", strSettingsFile);
            this.props.history.push(`/settingsEditor`);
        }
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/managerPassword"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        let { manager_password, error_modal, success_modal } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} alt="" /></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1307"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsForm">
                                                    <ul>
                                                        <li>
                                                            <button type="button" class="btn btn-default-text eraseButton">&nbsp;</button>
                                                        </li>
                                                        <li>
                                                            <div class="rcNotesFormField">
                                                            <input type="text" minLength="1" maxLength="10" class="form-control" value={manager_password} onChange={this.handlePasswordChange} />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn btn-default-text confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(ManagerPassword)
