import {MEDIA_LIBRARY_REQUEST,MEDIA_LIBRARY_SUCCESS,MEDIA_LIBRARY_FAIL} from '../../../actions/menuManagement/mediaLibraryAction';

const initialState = {
    loaded: false,
    loading: false,
    mediaLibraryInformation: [],
    mediaSpaceInformation: {},
    errormsg:'',
}

export default function reducer (state = initialState, action = {}) {

    switch (action.type) {
        case MEDIA_LIBRARY_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                errormsg: '',
            }
        case MEDIA_LIBRARY_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                mediaLibraryInformation: action.mediaLibraryInformation,
                mediaSpaceInformation: action.mediaSpaceInformation,
                errormsg: '',
            }
        case MEDIA_LIBRARY_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
            }
        default:
            return state
    }
}