import { SET_USERMANAGEMENT_DATA, SET_USERMANAGEMENT_DATA_LOADING }  from '../stateActionsData/type';
import http from '../../service/httpService';
import { BRAND_ID } from '../../utils/appConstants';
import { UserMgmtAddUser } from '../../service/api';
import { searchText } from '../advanceSearch/advanceSearchAction';
import KCStore from '../../utils/kcStorage';

export const setUserData = ( payload ) => ({ type:SET_USERMANAGEMENT_DATA, payload});
export const setLoader = ( payload ) => ({ type:SET_USERMANAGEMENT_DATA_LOADING, payload});
const userImages = [
	{ name: "System Manager", img: require("../../images/users/owner.svg") },
	{ name: "Crem System Admin", img: require("../../images/users/owner.svg") },
	{ name: "Service Technician", img: require("../../images/users/service.svg") },
	{ name: "1st Line User", img: require("../../images/users/leaser.svg") },
	{ name: "2nd Line User", img: require("../../images/users/chef.svg") },
	{ name: "Crem User", img: require("../../images/users/manager.svg") }
]

// User Management List action
export const userManagementData = (req, searchdata) => dispatch => {
	dispatch(setLoader(true));
	let userDetails = KCStore.getItem("loginInfo");
	let userName = JSON.parse(userDetails).username;
	let url = UserMgmtAddUser.getUserList + KCStore.getItem("customerId") + "&&email=" + userName;
	
	if(searchdata ) {
		Object.keys(searchdata).forEach(key=> {
			if( searchdata[key] ){
				url = `${url}&&${key}=${searchdata[key].toLowerCase()}`
			}
		});
	}
	let headers =  { 
		'Content-Type': 'application/json',
		'cgid': req.sCgids,
		'brandid': req.sBrandids.length>0?req.sBrandids.join(','):BRAND_ID,					
		'machinetype': req.sMachineTypes
 	}
	 if (KCStore.getItem('emulation')) {
		headers.emulation = KCStore.getItem('emulation');
		headers.base_country = KCStore.getItem('basecountry');
	 }
    http.get(url,
			{
				headers,
				data: {}
			}).then(response => {
				if ( response && response.data ) {
					let { data: usersList } = response.data
					usersList.map((item, i) => {
						item.order_id = ++i;
						let pickRole = userImages.find(r => r.name === item.roleName);
						if (pickRole != null) {
							item.img = pickRole.img;
						}
						else if (pickRole === undefined) {
							item.img = require("../../images/users/user.png");
						}
					});
					dispatch(setLoader(false));
					dispatch(setUserData({userMgmtList:usersList}));
					dispatch(searchText(null));
					
				}

			}).catch(err => {
				dispatch(setLoader(false));
			});

   }
