import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { MenuUploadAPI } from '../../../service/api';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import { injectIntl,FormattedMessage } from 'react-intl';

$(window).on('beforeunload', function () {
    $(".loadingProgress").css("display", "none");
});

class MenuConvolink extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			convolinkUrl: '#'
		};
		this.getConvolinkUrl = this.getConvolinkUrl.bind(this);
	}

	componentDidMount = () => {
		$('body').removeClass("modal-backdrop show");
		document.body.style.backgroundColor = "#F2F2F2";
		this.getConvolinkUrl();
	}

	downloadConvolink = () => {
		if (this.state.convolinkUrl !== '#') {
			window.location = this.state.convolinkUrl;
		}
	}

	getConvolinkUrl = () => {
		this.setState({ loaderIconVisible: true });
		return new Promise((resolve, reject) => {
			http.get(MenuUploadAPI.getConvolinkpresignedUrl, {
				headers: {
					'Content-Type': 'application/json',
					'filename' : 'Install_ConvoLink.exe'
				}, data: {}
			}).then(response => {
				if (response && response.data) {
					this.setState({
						loaderIconVisible: false,
						convolinkUrl: response.data.presignedLogViewerUrl,
					});
					resolve(response.data);
				}
			}).catch(err => {
				this.setState({ loaderIconVisible: false });
			});
		});
	}

	render() {
		let { loaderIconVisible } = this.state;
		const { formatMessage } = this.props.intl;

		return (

			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">

					<div class="menuUpdateWrapper">
						<div class="menuFilterHeader">
							<ul>
								<li>
									<Link to="/menuBrands" title={formatMessage({ id: 'KC0661' })}><FormattedMessage id="KC0661"/></Link>
									<Link to="/menuUpdateStatus" title={formatMessage({ id: 'KC0659' })}><FormattedMessage id="KC0659"/></Link>
									<Link to="/menuApps" title={formatMessage({ id: 'KC0660' })} className="active"><FormattedMessage id="KC0660"/></Link>
								</li>
								<li>
									<button class="btn menuDownload" onClick={() => this.downloadConvolink()} type="button" title={formatMessage({ id: 'KC0699' })}>&nbsp;</button>
								</li>
							</ul>
						</div>
						<div class="convolLinkTableDiv">
							<div class="convolLinkTable">
								<ul>
									<li><img src={require("../../../images/recipecreation/convoLinkImg.jpg")} alt="" /></li>
									<li>
										<h4><FormattedMessage id="KC0701"/></h4>
										<p></p>
										<p><FormattedMessage id="KC0702"/></p>
										<h5><FormattedMessage id="KC0703"/></h5>
										<p><FormattedMessage id="KC0704"/></p>
										<h5><FormattedMessage id="KC0705"/></h5>
										<p><FormattedMessage id="KC0706"/></p>
										<h5><FormattedMessage id="KC0707"/></h5>
										<p><FormattedMessage id="KC0708"/></p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
			</div>
		)
	}
};

export default injectIntl(MenuConvolink);
