import {AVG_TRAYS_REQUEST,AVG_TRAYS_SUCCESS,AVG_TRAYS_FAIL} from '../../../actions/unitDashboard/averageTraysAction';
const initialState = {
    loaded: false,
    loading: false,
    avgTraysResponse: {}
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case AVG_TRAYS_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case AVG_TRAYS_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                avgTraysResponse: action.avgTraysResponse
            }
        case AVG_TRAYS_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                avgTraysResponse: {},
                errormsgAvgTraysResponse: 'Server Error',

            }
        default:
            return state
    }
}