import React, { Component } from 'react';
import { Link } from "react-router-dom";
import auth from '../../service/authService';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import { FormattedMessage, injectIntl } from 'react-intl';

 class AllLocationSideBar extends Component {

    // Woopra Events
    trackEvents = (e) =>{
        let trackEvent = e.trackEvent ? e.trackEvent : e.target.title;
        WoopraEvents(trackEvent);
    }

    render() {
        let { activeClass } = this.props;
        const { formatMessage } = this.props.intl;

        let features = auth.getAllFeatures();
        features = features ? features : [];
        let mainMenu = false;
        {
            features.map((item, i) => {
                if (item.isMainMenu && item.featureAvailable) {
                    mainMenu = true;
                }
            })
        }

        return (
            <div id="sidebar-wrapper">
                <ul className="sidebarNav">
                    <li className="brandName">
                        <Link to="/dashBoardLocations" title={formatMessage({id: "KC0999"})} className="homeNav">&nbsp;</Link>
                    </li>
                    <li>
                        <Link to="/dashBoardLocations" title={formatMessage({id: 'KC0046'})} className={activeClass === "dashboardNav" ? "dashboardNav active" : "dashboardNav" } onClick ={(e)=>{e.trackEvent="All Locations"; this.trackEvents(e)}}><FormattedMessage id='KC0046'/></Link>
                    </li>
                    <li>
                        <Link to="/locationsView"  title={formatMessage({id: 'KC1047'})} className={activeClass === "ListviewA" ? "ListviewA active" : "ListviewA"} onClick ={(e)=>{e.trackEvent="List View";this.trackEvents(e)}}><FormattedMessage id='KC1047'/></Link>
                    </li>
                    <li>
                        <Link to="/locationsMap" title={formatMessage({id:"KC1048"})} className={activeClass === "MapViewA" ? "MapViewA active" : "MapViewA"} onClick ={(e)=>{e.trackEvent="Map View";this.trackEvents(e)}}><FormattedMessage id="KC1048"/></Link>
                    </li>
                    <li>
                        <Link to="#" title="Site Map" /*className="SidetreeA"*/ disabled="disabled" onClick ={(e)=> this.trackEvents(e)}>&nbsp;</Link>
                    </li>
                </ul>
                <ul className="sidebarNav bottomNav">
                    <li>
                        <Link to="uploadNav" /*className="uploadNav"*/ title={formatMessage({id: 'KC0683'})} onClick ={(e)=>{e.trackEvent="Upload";this.trackEvents(e)}}>&nbsp;</Link>
                    </li>
                    <li>
                        {mainMenu ? <Link to="/mainMenu" className="menuNav" title={formatMessage({id: "KC1061"})} onClick ={(e)=>{e.trackEvent="Main Menu"; this.trackEvents(e)}}><FormattedMessage id ="KC1061"/></Link> : ""}
                    </li>
                </ul>
            </div>
        )
    }
}

export default injectIntl(AllLocationSideBar)