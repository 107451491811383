import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { getAverageDrinksList } from '../../../actions/reportManagement/averageDrinksAction';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { getAverageCookCountData } from '../../../actions/unitDashboard/averageCookCountAction';
import { UNIT_ID, UNIT_BRAND_ID } from '../../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';
import KCStore from '../../../utils/kcStorage';


var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let brandID = "";

class AverageCookCount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            sd: '',
            ed: '',
            startDate: '',
            endDate: '',
            unitTime: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            datapickflag: false
        }
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }
    componentDidMount() {
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        let storageBrandName = KCStore.getItem("brandName");
        if (storageBrandName === "CREM") {
            brandID = "CREM";
            this.props.getAverageDrinksList("Month", todaysDateFormatted, weekDateFormatted, UNIT_ID, brandID);
        }
        else if (storageBrandName === "MERRYCHEF") {
            let widgetID = 17;
            this.props.getAverageCookCountData("Month", todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID);
        }
        else if (storageBrandName === "GARLAND") {
            let widgetID = 87;
            this.props.getAverageCookCountData("Month", todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID);
        }
    }

    componentDidUpdate = async(prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleAverageCookCount(this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStart(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //Total Drinks Produced
    handleChangeStart(date) {
        if(this.state.startDate === date){
            return;
        }
        this.setState({
            startDate: date,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleAverageCookCount(selectedtext);
        });
    }

    handleChangeEnd(date) {
        if(this.state.endDate === date){
            return;
        }
        this.setState({
            endDate: date,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleAverageCookCount(selectedtext);
        });
    }

    onDropDownHandler(e) {
        const { nodeText: text } = e;
        this.setState({ type: text, datapickflag: text === 'Date Range', startDate: '', endDate: ''})
        this.handleAverageCookCount(text);
    }

    handleAverageCookCount (text){
        if (KCStore.getItem("brandName") === "CREM") {
            brandID = "CREM";
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
            }
            else {
                todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
                weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
            }

            if (todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") {
                this.props.getAverageDrinksList(text, todaysDateFormatted, weekDateFormatted, UNIT_ID, brandID);
            }
        }
        else {
            let widgetID = (KCStore.getItem("brandName") === "MERRYCHEF") ? 17 : 87;
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
            }
            else if (text === 'Week') {
                weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
                todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

                var startDateMonth = getMonth(new Date(weekDateFormatted));
                var endDateMonth = getMonth(new Date(todaysDateFormatted));

                if (startDateMonth !== endDateMonth) {
                    weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
                }
            }
            else {
                todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
                weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
            }
            if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
                this.props.getAverageCookCountData(text, todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID);
            }
        }
    }

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (KCStore.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

    render() {
        const averageDrinksInformation = this.props.ProductsProduced.averageDrinksInfo.averageDrinksInformation;
        const response = this.props.averageCookCountDataInfo;
        const { formatMessage } = this.props.intl;

        let currentValue = null;
        let priorValue = null;
        let trend = 0;
        let unitTime = '';
        let avgCurrentValue = null;
        let avgPriorValue = null;

        if (KCStore.getItem("brandName") === "CREM") {
            currentValue = averageDrinksInformation.currentConsumption;
            priorValue = averageDrinksInformation.lastConsumption;
            trend = averageDrinksInformation.trend;
            unitTime = '';
        }
        else {
            currentValue = response.averageCookCountData.content !== undefined ? response.averageCookCountData.content[0].value : 0;
            priorValue = response.averageCookCountData.content !== undefined && response.averageCookCountData.content.length > 1 ? response.averageCookCountData.content[1].value : 0;
            trend = response.averageCookCountData.content !== undefined && response.averageCookCountData.content.length > 2 ? response.averageCookCountData.content[2].value : 0;
            unitTime = response.averageCookCountData.unitTime ? response.averageCookCountData.unitTime.toLowerCase() : '';
            if (KCStore.getItem("timeFormat") === "24"){
                unitTime = unitTime ? moment(unitTime, 'hh:mm A').format('HH:mm'):"";
            } 
        }

        avgCurrentValue = (currentValue > 0 && currentValue < 1) ? "<1" : (this.returnNumberFormat(Math.round(currentValue)));
        avgPriorValue = (priorValue > 0 && priorValue < 1) ? "<1" : (this.returnNumberFormat(Math.round(priorValue)));

        return (
            <React.Fragment>
                <div className={this.props.classValue}>
                    <div className="colmDi">
                        <h4>{this.props.widgetnameStringID ? <FormattedMessage id={this.props.widgetnameStringID}/> : this.props.widgetname}
                                &nbsp;
                                {KCStore.getItem("excludedProductList") === "true" ? <span class="blueDot" title={formatMessage({ id: "KC1037"})}></span> : ""}
                            <span className="red-txt">{/*errormsgTotalDrinks*/}</span><span className="cornerIcon"></span>
                            <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.type} />
                        </h4>
                        <div className={this.props.widgetname === "FILTER CLEANINGS" ? "colmDiVal minheight155" : "colmDiVal"}>
                            <div className="valH1Div"> {avgCurrentValue} <span className={(Math.round(currentValue) === Math.round(priorValue) && avgCurrentValue !== "<1" && avgPriorValue !== "<1") ? "valH1Span" : trend === "1" ? "valH1Span valHigh" : trend === "0" ? "valH1Span valLow" : "valH1Span"}>{(KCStore.getItem("brandName") === "GARLAND") ? "" : unitTime}</span></div>
                            <div className={this.state.type === "Date Range" ? "displaynone" : "valH5Div"}>
                                { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }: {avgPriorValue}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        averageCookCountDataInfo: state.averageCookCountData,
        averageDrinksInfo: state.averageDrinksInformation,
        currentLocation: state.entityReducer.currentLocation,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAverageCookCountData, getAverageDrinksList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AverageCookCount));
