import React, { Component } from 'react'
import PropTypes from 'prop-types'
import http from '../../../service/httpService';
import {  MediaManagementAPI } from '../../../service/api';
import {  BASECOUNTRY_ID } from '../../../utils/appConstants';
import _ from 'lodash';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../utils/kcStorage';
var changeCase = require("change-case");

class MediaListPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderIconVisible: false,
      data: [],
      isSelectAllLibrary: false,
      error_modal: {
        open: false,
        message: ""
      },
      success_modal: {
        open: false,
        message: ""
      },
      selected_item: {},
      created_by: '',
      last_edited_by: '',
      sortColumn: { path: "", order: "", sort_color: "#63778a" },
      isImportEnabled: false,
      search: '',
      isLoaded: false
    }
    this.getMediaList = this.getMediaList.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.OnMediaItemClick = this.OnMediaItemClick.bind(this);
    this.OnImport = this.OnImport.bind(this);
    this.librarySearchResult = this.librarySearchResult.bind(this);
  }

  async componentDidMount() {
    document.body.style.backgroundColor = "#000000";

    try {
      await this.getMediaList().catch(err => {
        this.setState({
          loaderIconVisible: false
        });
      });
      $('.cbPhotogallery > ul > li > a').on('click', function () {
        $(this).parents('.cbPhotogallery').find(".selectedList").removeClass('selectedList');
        $(this).toggleClass('selectedList');
      });
    } catch (error) {
      //Error Goes Here
    }
  }

  getMediaList = () => {
    this.setState({
      loaderIconVisible: true
    });

    return new Promise((resolve, reject) => {
      http.get(MediaManagementAPI.getMediaBrandCustomer,
        {
          headers: {
            'Content-Type': 'application/json', 
            'customer' : KCStore.getItem("customerId"),
            'brand': KCStore.getItem("brandName"),
            'type': this.props.mediaType + 'Library',
            'cgids': KCStore.getItem("custGroupID"),
            'basecountry': BASECOUNTRY_ID,
            'defaultlibrary' : KCStore.getItem("isConnexUser") || false
          },
          data: {}
        }).then(response => {
          let { data } = response;
          let mediaList = data && data.mediaList ? data.mediaList : [];

          this.setState({
            loaderIconVisible: false,
            data: mediaList,
            isLoaded: true
          });
          resolve(response);
        }).catch(err => {
          this.setState({
            loaderIconVisible: false,
            isLoaded: true
          });
          reject(err);
        });
    });
  }

  OnMediaItemClick(item) {
    this.setState({
      selected_item: item,
      isImportEnabled: true
    });
    $('.cbPhotogallery > ul > li > a').on('click', function () {
      $(this).parents('.cbPhotogallery').find(".selectedList").removeClass('selectedList');
      $(this).toggleClass('selectedList');
    });
  }

  OnImport() {
    this.setState({ search: '' });
    this.props.OnImportImage(this.state.selected_item);
  }

  handleModelChange(e) {
    KCStore.setItem("selectedProductID", e.target.value);
    this.props.handleChangeProps(e);
  }

  OnToggleDetails = (item) => {
    this.setState({
      created_by: item.CREATED_BY,
      last_edited_by: item.UPDATED_BY
    });
  }

  closePopup = () => {
    this.setState({ search: '' });
    $('#PhotoLibraryModal').modal('hide');
  }

  librarySearchResult = (e) => {
    this.setState({ search: e.target.value });
  }

  render() {
    let { data: dataList, sortColumn, isImportEnabled, search, isLoaded } = this.state;
    const { formatMessage } = this.props.intl;
    dataList = dataList && dataList.length > 0 ? dataList : [];
    let { mediaType } = this.props;

    dataList = _.orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path].toString().toLowerCase()], [sortColumn.order]);

    const searchedItemInFilter = dataList && dataList.filter(
      (library) => {
        return library.FILE_NAME.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      }
    );
    dataList = searchedItemInFilter;

    return (
      <React.Fragment>
        <div class="modal fade selectRecipeModal selectLiberaryModal" data-keyboard="true" data-backdrop="static" id="PhotoLibraryModal" tabindex="-1" role="dialog" aria-labelledby="PhotoLibraryModalLabel">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header modal-header-danger">
                <FormattedMessage id="KC2071"/> {changeCase.capitalCase(mediaType)}
                <div class="modalSearchForm">
                  <ul>
                    <li>
                      <div class="modalSearchField">
                        <input type="text" value={search} placeholder={formatMessage({ id: 'KC0037' }) + " " + changeCase.capitalCase(mediaType)} onChange={(e) => this.librarySearchResult(e)} class="form-control" />
                      </div>
                    </li>
                    <li>
                      <button type="button" class="btn modalSearchButton">&nbsp;</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="modal-body">
                <div class="reBodyWrapper">
                  <div class="reWrapperTable">
                    <div class="reWrapperRow">
                      <div class="reWrapperColmn alignTop">
                        <div class={isLoaded ? "cbPhotogallery" : "displaynone"}>
                          <ul>
                          {
                            dataList && dataList.map((item, i) => 
                              <li>
                                <a href="#" title={item.FILE_NAME} onClick={() => this.OnMediaItemClick(item)}>
                                  <span class="navIcon"><img src={item.FILE_FORMAT === '.wav' ? require("../../../images/media/audio.png") : item.thumbUrl} alt="" /></span>
                                  <span class="navTitle">{item.FILE_NAME}</span>
                                </a>
                              </li>
                            )
                          }
                          </ul>
                        </div>
                        {!isLoaded && dataList.length === 0 && <div class="cbPhotogallery loadertext">
                            <FormattedMessage id="KC1151"/>
                        </div>}
                        {isLoaded && dataList.length === 0 && <div class="cbPhotogallery loadertext">
                          <FormattedMessage id='KC0205' />
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer textAlignCenter">
                <button class="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={this.closePopup}><FormattedMessage id="KC0021"/></button>
                <button class={isImportEnabled ? "btn btn-secondary yesBtn" : "btn btn-secondary yesBtn disabled"} data-dismiss="modal" type="button" onClick={() => this.OnImport()}><FormattedMessage id="KC2069"/></button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}



MediaListPopup.propTypes = {
  data: PropTypes.array.isRequired,
  mediaType: PropTypes.bool.isRequired,
}

export default injectIntl(MediaListPopup)
