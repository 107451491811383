import {errorCount_REQUEST,errorCount_SUCCESS,errorCount_FAIL} from '../../../actions/serviceManagement/errorCountActions';
const initialState = {
    loaded: false,
    loading: false,
    ErrorsCountInformation: { },
    errorList:[]
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case errorCount_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                
            }
        case errorCount_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                ErrorsCountInformation: action.ErrorsCountInformation,
                errorList: action.errorList
            }
        case errorCount_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',

            }
        default:
            return state
    }
}