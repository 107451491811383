import { SET_SMART_UNIT_DETAILS, SET_SMART_UNIT_DETAILS_LOADING } from '../../../actions/stateActionsData/type';
const initialState = {
    unitList:null,
    isLoading: false
}
export default function(state = initialState, action){
    switch(action.type) {
        case SET_SMART_UNIT_DETAILS:
        return {
            ...state,
            unitList: action.payload
        }
        case SET_SMART_UNIT_DETAILS_LOADING: 
        return {
            ...state,
            isLoading: action.payload
        }
        default:
        return state;
    }
}