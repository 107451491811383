import {ReportManagement} from '../../service/api';
import http from '../../service/httpService';
export const AVERAGE_COOK_COUNT_DATA_REQUEST = 'AVERAGE_COOK_COUNT_DATA_REQUEST';
export const AVERAGE_COOK_COUNT_DATA_SUCCESS = 'AVERAGE_COOK_COUNT_DATA_SUCCESS';
export const AVERAGE_COOK_COUNT_DATA_FAIL = 'AVERAGE_COOK_COUNT_DATA_FAIL';

export function getAverageCookCountData(type, fromdate, todate, deviceId, brandId, widgetId) {
    let url ;
    let filterType;
    switch (type) {
        case 'Week':
            filterType='weekly'; 
            break; 
        case 'Year':
            filterType='yearly';            
            break; 
        case 'Day':            
            filterType='daily';
            break;
        case 'Quarter':
            filterType='quarterly';            
            break; 
        case 'Date Range':
            filterType = 'custom';
            break; 
        default: 
            filterType = 'monthly';              
      }    
    
    url = `${ReportManagement.widgetAPI + '/' + widgetId + '/' + brandId}`;

    return dispatch => {
        dispatch({
            type: AVERAGE_COOK_COUNT_DATA_REQUEST
        })
        
        let header;
        if (filterType === 'weekly' || filterType === 'custom')
        {
            header = {
                'Content-Type' : 'application/json',
                'unitid' : deviceId,
                'startdate' : todate,
                'enddate' : fromdate,
                'filtertype' : filterType,
                'showtrend' : "true"
              }; 
              if(filterType === 'custom')
              {
                delete header.showtrend;
                header.showtrend = "false";
              }
        }
        else
        {
            header = {
                'Content-Type' : 'application/json',
                'unitid' : deviceId,
                'reqDate' : fromdate,
                'filtertype' : filterType,
                'showtrend' : "true"
              };
        } 

        http.get(url,
                {
                  headers: header,
                  data:{}
                })
            .then(response => {
                dispatch({
                    type: AVERAGE_COOK_COUNT_DATA_SUCCESS,
                    averageCookCountData: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: AVERAGE_COOK_COUNT_DATA_FAIL,
                    error
                })
            })
    }
}
