import React, { Component } from 'react'
// import DocViewer, { PDFRenderer } from "react-doc-viewer";
// import DocumentViewers from '../modal/docViewer';
import $ from 'jquery';
// import { Document } from 'react-pdf'

class pdfViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewerUrl: '',
            loader: true,
            numPages: null,
            pageNumber: 1
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    componentDidMount = () => {
        var urlParams = new URLSearchParams(window.location.search);
        const getValue = urlParams.get("value");
        const viewerUrl = atob(getValue);
        // const docs = []; 
        // docs.push({ uri: viewerUrl })
        // https://arxiv.org/pdf/quant-ph/0410100.pdf
        this.setState({ viewerUrl, loader: false })
    }
    render() {
        const { viewerUrl, loader, pageNumber, numPages } = this.state;
        console.log("viewerUrl", viewerUrl)
        return (
            <div id="adminWrapper" >
                <div id="page-loader" className={loader ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                {/* <Document 
                    file={{
                        url: viewerUrl,
                        header: {
                            'Access-Control-Allow-Origin': '*',
                            'crossOrigin': 'anonymous',
                        },
                        withCredentials: true 
                    }}
                    onLoadSuccess={ this.onDocumentLoadSuccess } >
                </Document> */}
                {/* <PDFReader url={viewerUrl}/> */}
                {/* <DocViewer documents={viewerUrl} /> */}
                {/* <div>
                    <Document file={viewerUrl} onLoadSuccess={this.onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <p>
                        Page {pageNumber} of {numPages}
                    </p>
                </div> */}
                {/* <div class="iframe-loading">
                    <iframe src={viewerUrl} onLoad={$('.iframe-loading').css('background-image', 'none')} width="100%" height={$(window).height()}  ></iframe>
                </div> */}
                {/* <DocumentViewers docType="pdf" docsPath={viewerUrl} />  */}
                <iframe src={viewerUrl} width="100%" height={$(window).height()} />
                {/* <object data={viewerUrl} type="application/pdf" width="100%" height={$(window).height()}>
                    <p>Alternative text - include a link <a href={viewerUrl}>to the PDF!</a></p>
                    <div id="page-loader" className={"loadingProgress showloader"}>
                        <div className="loadingProgressIn"></div>
                    </div>
                </object> */}
            </div >
        )
    }
}

export default pdfViewer