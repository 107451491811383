import React from 'react';
import { DEFAULT_LOCALE, DEFAULT_MESSAGES } from './constant';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { connect } from 'react-redux';

let reactIntl;
const cache = createIntlCache();
const messagesLoader = {
  'en-US': () => import('./languages/en-US.json'),
  'es-ES': () => import('./languages/es-ES.json'),
  'zh-CN': () => import('./languages/zh-CN.json'),
  'fr-FR': () => import('./languages/fr-FR.json')
};

const Provider = ({ children, locale }) => {
  const [ messages, setMessages ] = React.useState(DEFAULT_MESSAGES);
  const loadMessages = (locale) => {
    messagesLoader[locale]().then((messages) => {
      setMessages(messages);
    });
  }

  React.useEffect(() => {
    loadMessages(DEFAULT_LOCALE);
  }, []);
  React.useEffect(() => {
    loadMessages(locale);
  }, [locale]);

   reactIntl = createIntl({
    locale: locale,
    messages: messages,
    defaultLocale: DEFAULT_LOCALE
  }, cache);

  return (
    <RawIntlProvider value={reactIntl}>
      {children}
    </RawIntlProvider>
  )
}

const mapStateToProps = (state) => {
  const { localeInfo } = state;
  return {
    locale: localeInfo.locale
  }
}
const I18nProvider = connect(mapStateToProps)(Provider)
export { I18nProvider, reactIntl };
