import React, { PureComponent } from 'react';
import { HolderChartPopup } from '../frymasterWidgets/HolderChartPopup';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from 'lodash';
import { reactIntl } from '../../../locale/locale-provider';
import KCStore from '../../../utils/kcStorage';

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);


var moment = require('moment');

const mappingChartStringID = {
	"Total Quick Filters Completed": "KC1794",
	"Total Quick Filters Bypassed": "KC1795",
	"Total Auto Filters Completed": "KC1909",
	"Total Auto Filters Bypassed": "KC1910"
}

export default class StackedBarChart extends PureComponent {
	constructor(props) {
		super(props);
		const average = 'average_' + props.widgetID;
		const productList = 'productList_' + props.widgetID;
		const showPopup = 'showPopup_' + props.widgetID;
		this.state = {
			[average]: 0,
			[productList]: [],
			[showPopup]: false
		}
		this.chart = null;
		this.chartID = this.props.chartID;
		this.dateAxis = this.props.dateAxis;
		this.chartData = this.props.chartData;
		this.average = this.props.average;
		this.filterType = this.props.filterType;
		this.allowPopup = this.props.allowPopup;
		this.onCloseModal = this.onCloseModal.bind(this);
	}

	onCloseModal= () => {
		this.setState({  
			 showPopup: false  
		});  
	}  

	componentDidMount() {		
		let response = this.chartData && this.chartData.length > 0 ? this.chartData : [];
		let chart = am4core.create(this.chartID, am4charts.XYChart);
		if(response && response.length > 0) {
		chart.seriesContainer.zIndex = -1;
		chart.zoomOutButton.disabled = true;
		chart.responsive.enabled = true;
		
		response.map(item => {
			item.Key = item.Key.replace('-','/');
			item.endDate = item.endDate ? item.endDate.replace('-','/') : "";
		});

		if(this.dateAxis && (this.filterType === "Month" || this.filterType === "Date Range")) {
			chart.data = response;
			// Create axes
			let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
			dateAxis.dataFields.category = "Key";
				
			// Set input format for the dates
			chart.dateFormatter.inputDateFormat = "yyyy/M/d";
			dateAxis.dateFormats.setKey("day", "d");
			dateAxis.periodChangeDateFormats.setKey("day", "MMM"); 

			dateAxis.renderer.grid.template.location = 0;
			if (window.screen.width > 1000) {
				dateAxis.renderer.minGridDistance = (this.filterType === "Year" || this.filterType === "Quarter") ? 30 : 10;
			}
			else {
				dateAxis.renderer.minGridDistance = 30;
			}
			dateAxis.renderer.labels.template.horizontalCenter = "center";
			dateAxis.renderer.labels.template.fill = am4core.color("#FFF");
			dateAxis.renderer.labels.template.fontSize = 10;
			dateAxis.renderer.inside = false;
			// dateAxis.renderer.labels.template.disabled = false;
			dateAxis.renderer.grid.template.strokeOpacity = 0;
			dateAxis.renderer.grid.template.location = 0;
			dateAxis.renderer.labels.template.dx = -6;
		}
		else {
			if (this.dateAxis && (this.filterType === "Year" || this.filterType === "Quarter")) {
				response.map(item => {
					item.category = window.screen.width > 650 ? moment(item.Key).format('D MMM') + '   ' + moment(item.endDate).format('D MMM')
									: moment(item.Key).format('D MMM') + '-' + moment(item.endDate).format('D MMM'); 
				}); 
			}
			else if (this.dateAxis && this.filterType === "Week") {
				response.map(item => {
					item.category = moment(item.Key).format('ddd'); 
				}); 
			}
			chart.data = response;
			// Create axes
			let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = (this.dateAxis && this.filterType !== "Day") ? "category" : "Key";
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.minGridDistance = (this.filterType === "Quarter") ? 30 : 10;
			categoryAxis.renderer.labels.template.fill = am4core.color("#FFF");
			categoryAxis.renderer.labels.template.fontSize = 10;
			categoryAxis.renderer.inside = false;
			categoryAxis.renderer.labels.template.disabled = false;
			categoryAxis.renderer.grid.template.strokeOpacity = 0;

			if (!this.dateAxis && window.screen.width < 651) {
				categoryAxis.renderer.labels.template.rotation = 270;
				categoryAxis.renderer.labels.template.dx = -6;
			}

			if (this.dateAxis && (this.filterType === "Quarter" || this.filterType === "Year")) {
				let label = categoryAxis.renderer.labels.template;
				if (window.screen.width < 651) {
					categoryAxis.renderer.labels.template.rotation = 270;
				}
				else {
					label.wrap = true;
					label.maxWidth = 55;
				}
			}
		}

		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.grid.template.location = 0;
		valueAxis.renderer.minGridDistance = 100;
		// Enable .total and .totalPercent placeholders for stacked series
		valueAxis.calculateTotals = true;
		valueAxis.renderer.inside = false;
		valueAxis.renderer.labels.template.disabled = false;
		valueAxis.min = 0;
		valueAxis.renderer.labels.template.fill = am4core.color("#FFF");
		valueAxis.renderer.labels.template.fontSize = 10;
		valueAxis.renderer.grid.template.strokeOpacity = 1;
		valueAxis.renderer.grid.template.stroke = am4core.color("#FFF");
		valueAxis.renderer.grid.template.strokeWidth = 0.3;

		let average = Math.round(this.average ? this.average : 0);
		if (average > 0) {
			var range = valueAxis.axisRanges.create();
			range.value = average;
			range.grid.stroke.above = true;
			range.grid.above = true;
			range.grid.stroke = am4core.color("#FFFFFF");
			range.grid.strokeWidth = 1.5;
			range.grid.strokeOpacity = 1;
		}

		// Create series
		function createSeries(field, name, me, colorValue) {
		// Create series
		let series = chart.series.push(new am4charts.ColumnSeries());
		series.dataFields.valueY = field;
		if (me.dateAxis && (me.filterType === "Month" || me.filterType === "Date Range")) {
			series.dataFields.dateX = "Key";
		}
		else {
			series.dataFields.categoryX = (me.dateAxis && me.filterType !== "Day") ? "category" : "Key";
		}
		series.name = name;
		series.columns.template.width = am4core.percent(93);
		// series.columns.template.tooltipText = " " + name + " : [bold]{valueY}[/]";
		series.columns.template.tooltipText = name;
		series.columns.template.properties.fillOpacity = 1;
		series.sequencedInterpolation = true;
		// Make it stacked
		series.stacked = true;
		series.columns.template.stroke = am4core.color(colorValue);
		series.columns.template.fill = am4core.color(colorValue);

		// Series Tooltip Test and bg color
		series.tooltip.getFillFromObject = false;
		series.tooltip.background.fill = am4core.color("#FFFFFF");
		series.tooltip.label.fill = am4core.color("#000000");
		
		series.tooltip.label.adapter.add("textOutput", function (text, target) {
			if (target.dataItem.dataContext !== undefined) {
				var result = Object.keys(target.dataItem.dataContext).map(function (key) {
					if(key === "Key" || key === "category" || key === "endDate" || key === "details" || key === "date" || key === "day" || key === "range") {
						return { key: key, value: 0 };
					}
					else {
						return { key: key, value: Number(target.dataItem.dataContext[key]) };
					}
				});

				var totalValue = result.reduce(function(prev, cur) {
					return prev + cur.value;
				  }, 0);

				if (totalValue > 0) {
					let tooltipStringID = mappingChartStringID[text] ? reactIntl.formatMessage({id : mappingChartStringID[text] }) : text;
					return tooltipStringID + ` : [bold]${target.dataItem.valueY}[/]`;
				}
				else {
					return "";
				}
			}
			
		});

		// On hover state show pointer style
		if (me.allowPopup) {
			series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
		}
			if (!me.dateAxis) {
				series.columns.template.events.on("hit", function(ev) {
					if (ev.target.dataItem && (ev.target.dataItem.dataContext.details !== [])) {
						if ((ev.target.dataItem.dataContext.completed + ev.target.dataItem.dataContext.bypassed) > 0) {
							me.setState({
								showPopup: true,
								productList: ev.target.dataItem.dataContext.details,
								holderID: ev.target.dataItem.dataContext.Key
								});
						}
						}
					}, this);
			}

			let columnTemplate = series.columns.template;
			columnTemplate.strokeWidth = 0;
			columnTemplate.strokeOpacity = 0;
		}

		// Added for Frymaster McD CR
		const modelName = KCStore.getItem("modelFamilyName");
		const productName = _.upperCase(modelName);
		let filtrationWidgetsName = 'Quick Filters';
		if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
		  filtrationWidgetsName = 'Auto Filters'
		}

		if (response.length > 0) {
			Object.keys(response[0]).map((item, i) => {
				if (item !== "Key" && item !== "category" && item !== "endDate" && item !== "details" && item !== "date" && item !== "day" && item !== "range") {
					createSeries(item, item.toString() === "completed" ? `Total ${filtrationWidgetsName} Completed` : `Total ${filtrationWidgetsName} Bypassed`, this, item.toString() === "completed" ? "#00d27a" : "#efb302");
				}
			})
		}

		this.chart = chart;
	  }
	  else{
		  if(this.chart){
			  this.chart.data = [];
		  }
	  }
	}

	componentWillUnmount = () =>{
		if (this.chart) {
			this.chart.dispose();
		}
	}

	render() {
		let {showPopup, productList, holderID} = this.state;
		return (
			<div>
			<div id={this.props.chartID ? this.props.chartID : "chartDiv" }></div>
			{this.allowPopup ? <HolderChartPopup open={showPopup} productList={productList} onCloseModal={this.onCloseModal} holderID={holderID} filterType={this.props.filterType} /> : ""}
			</div>
		);
	}
}
