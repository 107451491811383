import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../controls/modelFamilyList';
import DragDropElement from './dragDropElement'
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';
require('jquery-mousewheel')

class FrymasterMenuRecipeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            selectedCategoryName: "",
            recipeList: []
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.redirect = this.redirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onSaveRecipes = this.onSaveRecipes.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let selectedCategory = KCStore.getItem("selectedCategory");
            let selectedCategoryObj = JSON.parse(selectedCategory);

            let categoryDetails = KCStore.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            let recipeDetails = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);

            this.setState({
                selectedCategoryName: selectedCategoryObj.categoryName,
                recipeList: recipeDetails ? recipeDetails.recipe_items : []
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/frymasterMenuRecipeList"
        });
    }

    onSaveRecipes = () => {
        
        let selectedCategory = KCStore.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);

        let categoryDetails = KCStore.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let index = categoryDetailsObj.findIndex(c => c.ID === selectedCategoryObj.ID);

        categoryDetailsObj[index]['recipe_items'] = this.state.recipeList;
        KCStore.setItem("resourceDetails",JSON.stringify(categoryDetailsObj));


        this.props.history.push({
            pathname: "/frymasterMenuAddFinalReview",
            isEdit: false,
            previouspath: "/frymasterMenuRecipeList"
        });
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: this.props.history.location.previouspath,
            isEdit: false,
            previouspath: "/frymasterMenuRecipeList"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { recipeList, selectedCategoryName } = this.state;
        recipeList = recipeList && recipeList.length > 0 ? recipeList : [];
        let brandid = KCStore.getItem("selectedbrandID");

        return (
            <React.Fragment>
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button> {selectedCategoryName}
                                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
                                                <DragDropElement onChangeItems={(recipeList)=>this.setState({recipeList})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onSaveRecipes() }} class="btn saveButton">&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div >
                </div >
            </React.Fragment >
        )
    }
}

export default injectIntl(FrymasterMenuRecipeList)
