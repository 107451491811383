import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class Paginator extends Component {

    onNextPage = (page) => {
        const currentPage = page + 1;
        this.props.onNextPage(currentPage);
    }

    onPreviousPage = (page) => {
        const currentPage = page - 1;
        this.props.onPreviousPage(currentPage);
    }

    render = () => {

        const { pageNumber, pageCount: totalPages, applyClass } = this.props;
        let getClassName = applyClass ? applyClass : "paginationFooter";
        return (
            <div class={totalPages <= 1 ? "displaynone" : getClassName}>
                <ul>
                    {
                        pageNumber > 1 && <React.Fragment>
                            <li className="prevPageLi" onClick={() => this.onPreviousPage(pageNumber)}>
                                <div title="" class="prevPage">&nbsp;</div>
                            </li>
                            <li class="prevPageCountLi disabled">
                                <FormattedMessage id="KC1038" values={{ number: pageNumber - 1 }}/>
                            </li>
                        </React.Fragment>

                    }
                    {
                        pageNumber === 1 && <React.Fragment>
                            <li style={{ display: 'none' }}>
                                <div title="" class="prevPage">&nbsp;</div>
                            </li>
                            <li style={{ display: 'none' }}>
                                <FormattedMessage id="KC1038" values={{ number: pageNumber - 1 }}/>
                            </li>
                        </React.Fragment>
                    }
                    <li>
                        <FormattedMessage 
                            id="KC1039" 
                            values={{ count: pageNumber, total: totalPages > 0 ? totalPages : 1 }}/>
                    </li>
                    {
                        pageNumber < totalPages && <React.Fragment>
                            <li className="nextPageCountLi">
                                <FormattedMessage id="KC1038" values={{ number: pageNumber + 1 }}/>
                            </li>
                            <li className="nextPageLi" onClick={() => this.onNextPage(pageNumber)}>
                                <div title="" class="nextPage">&nbsp;</div>
                            </li>
                        </React.Fragment>
                    }
                    {
                        pageNumber > totalPages && <React.Fragment>
                            <li style={{ display: 'none' }}>
                                <FormattedMessage id="KC1038" values={{ number: pageNumber + 1 }}/>
                            </li>
                            <li style={{ display: 'none' }}>
                                <div title="" class="nextPage">&nbsp;</div>
                            </li>
                        </React.Fragment>
                    }
                </ul>
            </div>
        )
    }
}