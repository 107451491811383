import {MEDIA_IMAGELIBRARY_LIST_REQUEST,MEDIA_IMAGELIBRARY_LIST_SUCCESS,MEDIA_IMAGELIBRARY_LIST_FAIL,
    MEDIA_SELECTED_LIST_SUCCESS} from '../../../../actions/navigation/mediaManagement/mediaImageLibraryListAction';
const initialState = {
    loaded: false,
    loading: false,
    mediaImageLibraryListInformation: {},
    mediaSelectedItems:[],
    loaderIconVisible: false
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case MEDIA_IMAGELIBRARY_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                loaderIconVisible: false
            }
        case MEDIA_IMAGELIBRARY_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                loaderIconVisible: true,
                mediaImageLibraryListInformation: action.mediaImageLibraryListInformation,
            }
        case MEDIA_IMAGELIBRARY_LIST_FAIL:
            return {
                ...state,
                loading: true,
                loaded: false,
                mediaImageLibraryListInformation: action.mediaImageLibraryListInformation,
                errormsg: '',
                loaderIconVisible: false
            }
        case MEDIA_SELECTED_LIST_SUCCESS:
            return {
                ...state,
                mediaSelectedItems: action.mediaSelectedItems,
            }
        default:
            return state
    }
}
