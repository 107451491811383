import React, { Component } from 'react';
import http from '../../service/httpService';
import auth from '../../service/authService';
import { BaseService } from '../../service/api';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { encriptData } from '../../utils/dashboardRenderer';
import LanguagePreferenceCommon from '../../components/languageDropdown/LanguagePreferenceCommon';
import { changeLocale, setLanguageList, setLocale } from '../../actions/localeAction/localeAction';
import { FormattedMessage,injectIntl } from 'react-intl';

class resetpassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			retypepwd: '',
			value: '',
			loaderIconVisible: true,
			display: 'none',
			currentPassword: '',
			newPassword: '',
			retypeNewPassword: '',
			newPasswordMatched: false,
			validateCurrentPassword: '',
			currentPasswordMismatched: '',
			isFormValidated: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			showhidenewpassword: false,
			showhideretypenewpassword: false,
			type: 'password',
			newpwdtype: 'password',
			retypenewpwdtype: 'password',
			newPasswordDetails: '',
			reTypePasswordDetails: '',
		};
		this.handleSignInSubmitAction = this.handleSignInSubmitAction.bind(this);
		this.allowToResetPasswrdPage = this.allowToResetPasswrdPage.bind(this);
		this.toggleRetypeNewPwd = this.toggleRetypeNewPwd.bind(this);
		this.toggleNewPwd = this.toggleNewPwd.bind(this);
	}

	componentDidMount() {
		if (auth.getJwt())
			return this.props.history.push('/dashBoardLocations');
		$("body").removeClass("modal-open");
		$('body').removeClass('mainMenuBg');
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");
		this.allowToResetPasswrdPage();
		this.settingLocale();
	}

	componentWillMount = () => {
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");
	}

	settingLocale = () => {
		const search = this.props.location.search;
		const params = new URLSearchParams(search);
		const lang = params.get('lang');
		if(lang) {
			this.props.setLocale(lang);
		}		
	}

	removeSpacesOnChange = (e) => {
		var keyCode = e.which || e.keyCode;
		if (keyCode === 32) {
			e.preventDefault();
			return false;
		}
	}

	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onSuccessCloseModal = () => {
		this.setState({
			display: 'block',
			success_modal: {
				open: false,
				message: "User successfully updated.",
			}
		}, () => {
		});
		this.props.history.push('/');
	}

	closeModel = () => {
		this.setState({
			isFormValidated: false,
			error_modal: {
				open: false,
				message: ""
			}
		});
		this.props.history.push('/ForgotPassword');
	}


	retypeNewPasswordDetailsChange = (e) => {
		let value = e.target.value;
		this.setState({
			reTypePasswordDetails: value,
			errorRetypeNewPassword: '',
		});
	}

	newpasswordDetailsOnChange = (e) => {
		let value = e.target.value;
		this.setState({
			errorNewPassword: '',
			newPasswordDetails: value,
		})
	}

	handleSignInSubmitAction= async (event)=> {
		event.preventDefault();
		if (this.validateSignupFormDetails()) {
			var urlParams = new URLSearchParams(window.location.search);
			const email = urlParams.get("emailvalue");
			const resetCode = urlParams.get("resetcode");
			const password = this.state.newPasswordDetails;
			let postData = {
				"username": email,
				"password": password,
				"resetCode": resetCode,
			}
			this.setState({
				loaderIconVisible: true,
				newPasswordMatched: false,
			})
			const newPayload = await encriptData(JSON.stringify(postData))
			http({
				method: "POST",
				url: BaseService.reset_password,
				data: { credential:newPayload},
			}).then(response => {
				let { status, data } = response;
				const { formatMessage } = this.props.intl;
				if (status === 200) {
					this.setState({
						loaderIconVisible: false,
						success_modal: {
							open: true,
							message: formatMessage({ id: "KC2566" })
						},
					});
				}
			}).catch(err => {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.response.data.message
					}
				})
			})
		}
	}

	goBack = () => {
		this.props.history.push('/');
	}

	nextPath(path) {
		$('.modal-backdrop').hide();
		this.props.history.push(path);
	}

	allowToResetPasswrdPage(e) {
		var urlParams = new URLSearchParams(window.location.search);
		const email = urlParams.get("emailvalue");
		let forgetPswUrl = `${BaseService.authenticate_reset_password}${email}`
		http.get(forgetPswUrl,
			{
				headers: {
					'Content-Type': 'application/json'
				}, data: {},
			}).then(response => {
				if (response.data.responseCode === 200) {
					this.setState({
						loaderIconVisible: false,
						display: 'block',
						success_modal: {
							open: false,
							message: response.data.message
						},
					});
				} else {
					this.setState({
						loaderIconVisible: false,
						display: 'none',
						error_modal: {
							open: true,
							message: response.data.message
						},
					});
				}
				this.setState({
					email: email
				})
			}).catch(err => {
				if (err.response !== undefined) {
					this.setState({
						error_modal: {
							open: true,
							message: err.response.data.message
						},
						loaderIconVisible: false,
						display: 'none'
					});

				} else {
					this.setState({
						error_modal: {
							open: true,
							message: "The passsword reset link has been expired."
						},
						loaderIconVisible: false,
						display: 'none'
					});
				}
			});
	}

	newPasswordOnBluerEvent() {
		let newPasswordDetails = this.state.newPasswordDetails;
		let errorNewPassword = "";

		if (!newPasswordDetails.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*?_+;.,:><|/]).*$/)) {
			errorNewPassword = "New Password should be minimum of 8 characters and contain at least one lowercase, uppercase, numeric and special characters.";
			
			this.setState({ 
				errorRetypeNewPassword:""	
			}, () => {
				this.setState({ 
					errorRetypeNewPassword:""	
				})
			});

		}
		if (newPasswordDetails) {
			this.setState({
				errorNewPassword: errorNewPassword,				
			},()=>{
				this.setState({ 
					errorNewPassword: errorNewPassword,	
				})
			});
		} 

		let reTypePasswordDetails = this.state.reTypePasswordDetails;
		if (reTypePasswordDetails && !this.state.errorNewPassword) {
			this.reTypePasswordOnBluerEvent();
		}
	}

	reTypePasswordOnBluerEvent() {
		let newPasswordDetails = this.state.newPasswordDetails;
		let reTypePasswordDetails = this.state.reTypePasswordDetails;
		let errorNewPassword = "";
		let errorRetypeNewPassword = "";
		errorNewPassword = this.state.errorNewPassword;
		if ((errorNewPassword === "" && errorRetypeNewPassword === "") && (newPasswordDetails !== reTypePasswordDetails)) {
			errorRetypeNewPassword = "Retype new password should match with new password.";
		}
		if (reTypePasswordDetails) {
			this.setState({
				errorRetypeNewPassword: errorRetypeNewPassword
			});
		}
	}

	validateSignupFormDetails() {
		let newPasswordDetails = this.state.newPasswordDetails;
		let reTypePasswordDetails = this.state.reTypePasswordDetails;

		let oldPasswordCheck = this.state.password;
		let errorNewPassword = "";
		let errorRetypeNewPassword = "";
		let formIsValid = true;

		// if (!newPasswordDetails) {
		// 	formIsValid = false;
		// 	errorNewPassword = "Please enter your new password.";
		// }
		// else 
		if (!newPasswordDetails.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*?_+;.,:><|/]).*$/)) {
			formIsValid = false;
			errorNewPassword = "New Password should be minimum of 8 characters and contain at least one lowercase, uppercase, numeric and special characters.";
		}

		if ((errorNewPassword === "") && (oldPasswordCheck === newPasswordDetails)) {
			formIsValid = false;
			errorNewPassword = "New password should not be same as temporary password.";
		}

		// if (!reTypePasswordDetails) {
		// 	formIsValid = false;
		// 	errorRetypeNewPassword = "Please enter your retype new password.";
		// }

		if ((errorNewPassword === "" && errorRetypeNewPassword === "") && (newPasswordDetails !== reTypePasswordDetails)) {
			formIsValid = false;
			errorRetypeNewPassword = "Retype new password should match with new password.";
		}

		this.setState({
			errorNewPassword: errorNewPassword,
			errorRetypeNewPassword: errorRetypeNewPassword
		});
		return formIsValid;
	}

	toggleNewPwd() {
		this.setState({
			showhidenewpassword: !this.state.showhidenewpassword,
			newpwdtype: this.state.newpwdtype === 'password' ? 'text' : 'password'
		})
	}

	toggleRetypeNewPwd() {
		this.setState({
			showhideretypenewpassword: !this.state.showhideretypenewpassword,
			retypenewpwdtype: this.state.retypenewpwdtype === 'password' ? 'text' : 'password'
		})
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { loaderIconVisible, display, currentPasswordMismatched, success_modal, error_modal, newPasswordDetails, reTypePasswordDetails } = this.state;
		let { showhidenewpassword, showhideretypenewpassword, type, newpwdtype, retypenewpwdtype } = this.state;
		let { currentPassword, newPassword, retypeNewPassword, newPasswordMatched, validateCurrentPassword, } = this.state;
		if (currentPassword) {
			validateCurrentPassword = currentPassword !== '' && currentPassword && currentPassword.length > 0;
		}

		if (newPassword) {
			currentPasswordMismatched = newPassword && newPassword.length > 0 && newPassword !== currentPassword;
		}
		if (retypeNewPassword) {
			newPasswordMatched = newPassword && retypeNewPassword && newPassword === retypeNewPassword;
			if (this.state.newPasswordMatched === true) {
				newPasswordMatched = true;
			}
		} else {
			newPasswordMatched = true;
		}
		return (
			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				{/* <!-- //Loading Progress --> */}
				<nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
					<Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="/">&nbsp;</Link>
					<div id="login-pre">
						<LanguagePreferenceCommon />
					</div>
				</nav>
				<div id="page-wrapper" style={{ display: display }}>					
					<div className="welcomText"><FormattedMessage id='KC0006' /></div>
					<div className="LoginBtnWrapper">
						<div id="loginBtnFormID">
							<div className="loginButton">
								<div data-keyboard="true" data-backdrop="static" id="SignInModal" tabIndex="-1" role="dialog" aria-labelledby="SignInModalLabel">
									<div className="modal-dialog modal-md loginModal">
										<div className="modal-content">
											<div className="modal-header loginModalHeader modal-header-danger">
											<FormattedMessage id='KC0609'/> <button type="button" className="close" data-dismiss="modal" onClick={() => { this.goBack() }} aria-label="Close">&nbsp;</button>
											</div>
											<div className="modal-body paddingTop0px">
												<div className="loginForm">
													<ul>
														<li className="form-group marginBottom10px">
															<input type="text" name="Email" className="form-control" disabled={true} placeholder={formatMessage({ id: "KC2565" })} value={this.state.email} />
														</li>

														<li className="form-group pass">
															<div className="input-group">															
																<input className="form-control pwd" id="password2" name="newpassword" placeholder={formatMessage({ id: "KC0126" })} value={this.state.newPasswordDetails} type={this.state.newpwdtype} onBlur={() => this.newPasswordOnBluerEvent()} onChange={(e) => this.newpasswordDetailsOnChange(e)} onKeyPress={(e) => this.removeSpacesOnChange(e)} />
																<div className="errorMsg">{this.state.errorNewPassword}</div>
																<span className="input-group-btn" onClick={this.toggleNewPwd}>
																	<button className="btn input-group-addon reveal" type="button"><i className={showhidenewpassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
																</span>
															</div>
														</li>
														<li className="form-group pass">
															<div className="input-group">
																<input type={retypenewpwdtype} class="form-control" id="password2" name="ConfirmPasswordHash" placeholder={formatMessage({ id: "KC0128" })} onBlur={() => this.reTypePasswordOnBluerEvent()} onChange={(e) => this.retypeNewPasswordDetailsChange(e)} onKeyPress={(e) => this.removeSpacesOnChange(e)} />
																<div className="errorMsg">{this.state.errorRetypeNewPassword}</div>
																<span className="input-group-btn" onClick={this.toggleRetypeNewPwd}>
																	<button className="btn input-group-addon reveal" type="button"><i className={showhideretypenewpassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
																</span>
															</div>
														</li>

														<li className="form-group pass">
															<div className="customCheckbox customCheckboxWlabel">
																<input id="RememberMe" name="RememberMe" type="checkbox" value="RememberMe" />
																<label htmlFor="RememberMe"><FormattedMessage id='KC0009'/></label>
															</div>
														</li>
													</ul>
												</div>
											</div>
											<div className="modal-footer textAlignCenter firstLoginBtn">
												<button className="btn btn-secondary singINBtn" disabled={newPasswordDetails && reTypePasswordDetails ? false : true} type="button" id="SignInButton" onClick={this.handleSignInSubmitAction}><FormattedMessage id='KC0022'/></button>
											</div>
										</div>
									</div>
								</div>

								<div className="modal fade SignInSuccessModal" data-keyboard="true" data-backdrop="false" id="SignInSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel">
									<div className="modal-dialog modal-md loginModal">
										<div className="modal-content">
											<div className="modal-body">
												<div className="singINConfirmation">
													You successfully created your personal password!
												</div>
											</div>
											<div className="modal-footer textAlignCenter">
												<button className="btn btn-secondary signINconfirmationOKBtn" data-dismiss="modal" onClick={() => this.nextPath('/dashBoardLocations')} type="button">OK</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
			</div>
		)
	}
};
function mapStateToProps(state) {
    return {
        locale: state.localeInfo.locale,
        languageList: state.localeInfo.languages,
        defaultLocale: state.localeInfo.defaultLocale
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changeLocale, setLanguageList, setLocale }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(resetpassword));