import { BAKING_PLAN_LIST, BAKING_PLAN_LIST_SUCCESS, BAKING_PLAN_LIST_FAIL } from '../../../actions/navigation/softwareManagement/actionTypes';

const initialState = {
    data: [],
    loaderIconVisible: false,
    totalPages: 0,
    currentPage: 0,
    error: ''
}
export default function (state = initialState, action) {
    switch (action.type) {
        case BAKING_PLAN_LIST:
            return { ...state, loaderIconVisible: true };
        case BAKING_PLAN_LIST_SUCCESS:
            return { ...state, 
                data: action.payload, 
                loaderIconVisible: false,
                totalPages: action.totalPages,
                currentPage: action.currentPage
             };
        case BAKING_PLAN_LIST_FAIL:
            return { ...state,
                data: [],
                loaderIconVisible: false,
                error: action.error };
    }
    return state;
}