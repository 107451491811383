
import React, { Component } from 'react';
import { connect } from 'react-redux';
import http from '../../../service/httpService';
import { MenuUploadAPI } from '../../../service/api';
import ErrorMoal from '../../modal/error-model';
import SuccessMoal from '../../modal/success-model';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import { orderBy } from 'lodash'
import { CUSTOMER_ID } from '../../../utils/appConstants';
import NoRecordsFound from '../../controls/noRecordsFound';
import { CreatedUserPopup } from "./createdUserPopup";
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../utils/kcStorage';

class ReadyRecipeLibraryList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			customerId: parseInt(KCStore.getItem("customerId")),
			isAdd: true,
			isEdit: false,
			isUpload: true,
			isDownload: false,
			isDelete: false,
			data: [],
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			unitIdValue: {},
			sortColumn: { path: "UPDATED_ON", order: "desc", sort_color: "#63778a" },
			created_by: '',
			last_edited_by: '',
			cookbookCount: 0,
			recipeCount: 0,
			readyRecipeCount: 0,
			legacyCount: 0,
			settingsCount: 0,
			selected_item: {},
			totalfilesize: 0.00,
			selectedFile: {},
			uploadOption: 'ReadyRecipe'
		}
		this.getReadyRecipe = this.getReadyRecipe.bind(this);
		this.OnToggleDetails = this.OnToggleDetails.bind(this);
		this.OnLibraryAdd = this.OnLibraryAdd.bind(this);
	}

	async componentDidMount() {
		$('body').removeClass("modal-open");
		$('div').removeClass("modal-backdrop show");
		$('.styles_overlayCenter__YHoO7').addClass("displaynone");
		document.body.style.backgroundColor = "#FFFFFF";
		KCStore.setItem("libraryData", "{}");
		KCStore.setItem("libraryDetails", "{}");
		KCStore.setItem("libraryImage", "");
		KCStore.setItem("schedulerDetails", "{}");
		KCStore.setItem("defaultLibraryData", "{}");
		KCStore.setItem("resourceDetails", "[]");
		KCStore.setItem("selectedCategory", "{}");
		window.addEventListener('resize', this.updateDimensions);
		window.addEventListener('resize', this.updateDimensions);
		$('#UploadLibrary').click(function () { $('#menuUpdate').trigger('click'); });
		await this.getReadyRecipe().catch(() => {
			this.setState({
				loaderIconVisible: false
			});
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
		$('div').removeClass("modal-backdrop fade show");
	}

	updateDimensions() {
		var windowHeight = $(window).height();
		var menuAssignFTable = $(".menuAssignFTable").height();
		var menuFilterHeader = $(".menuFilterHeader").height();
		var menuLibraryTable = $(".menuLibraryTable").height();
		var pageMnuWrprHeight = ((windowHeight) - (menuFilterHeader + menuLibraryTable + menuAssignFTable)) - 65;
		$(".menuLibrarySrlTable").css("height", pageMnuWrprHeight);
	}

	OnToggleDetails = (item) => {
		const { CREATED_BY: created_by, UPDATED_BY: last_edited_by } = item;
		this.setState({
			created_by,
			last_edited_by
		});
	}

	getReadyRecipe = () => {
		this.setState({
			loaderIconVisible: true
		})
		let brandID = KCStore.getItem("selectedbrandID");
		return new Promise((resolve, reject) => {
			http.get(`${MenuUploadAPI.legacyLibrarylist}/${brandID}/ready-recipes`,
				{
					headers: {
						'Content-Type': 'application/json',
						'customerid': CUSTOMER_ID,
						'cgids': KCStore.getItem("custGroupID"),
					},
					data: {}
				}).then(response => {
					let { data } = response;
					let recipeList = data ? data : [];
					recipeList.length > 0 && recipeList.map(item => {
						return item.RECIPE_FILE_SIZE = item.RECIPE_FILE_SIZE ? parseFloat(item.RECIPE_FILE_SIZE, 2) : 0.00;
					});
					this.setState({
						loaderIconVisible: false,
						data: recipeList
					});
					resolve(response);
				}).catch(err => {
					reject(err);
				});
		});

	}

	OnLibraryAdd = (item) =>{
		this.setState({
			loaderIconVisible: true
		});
		let kcbrandsitedata = {};
		kcbrandsitedata.readyrecipeids = item.READY_RECIPE_ID.toString();
		KCStore.setItem("kcbrandsitedata",JSON.stringify(kcbrandsitedata))
		let modelFamilyIDs = "";
		if (item.MODEL_FAMILY) {
			modelFamilyIDs =  item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : "";
		}
		KCStore.setItem("selectedFamilyID", modelFamilyIDs);
		KCStore.setItem("selectedbrandID", item.BRAND === "MERRYCHEF" ? 1 : 6);
        KCStore.setItem("brandName", item.BRAND);
		this.props.history.push({
			pathname: "/assignLegacyRecipeToTopology",
			previouspath: "/readyRecipeLibraryList",
		});
	}

	// Check the unique field is present or not to identify the correct json file
	uniqueKeyFind = (brand, type) => {
		if (brand === "MERRYCHEF") {
			return 'ordered_steps'
		}
		else {
			return 'NA';
		}
	}

	OnCancelLibrary = () => {
		$('#ConfirmationOfImportModal').modal('hide');
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				let { body } = data;
				if (body) {
					this.openErrorModal(body.stringID || body.message);
				}
			}
		} else {
			this.openErrorModal("KC1065");
		}
	}

	openErrorModal = (message) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: true,
				message
			}
		});
	}

	onSuccessCloseModal = () => {
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/readyRecipeLibraryList`);
	}

	closeModel = () => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ''
			}
		})
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	updateSize() {
		const { totalfilesize } = this.state;
		let widthPercent = '0%';
		if (totalfilesize <= 80) {
			widthPercent = (totalfilesize * 1.25) + '%';
		} else if (totalfilesize > 80) {
			widthPercent = '100%';
		} return <div>
			<div className="maxFileSize">
				<div className={(totalfilesize > 80) ? "progress-bar errorUpload" : "progress-bar "} role="progressbar"
					style={{ width: widthPercent }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
					{this.roundingTheSize(totalfilesize)} <FormattedMessage id="KC1498"/></div>
				<FormattedMessage id="KC2064"/></div>
		</div>
	}

	roundingTheSize(totalfilesize) {
		if (totalfilesize >= 0)
			return Math.floor(totalfilesize * 100) / 100;
		else
			return 0;
	}

	render() {
		let { data: dataList, error_modal, success_modal, loaderIconVisible, sortColumn, created_by, last_edited_by } = this.state;
		dataList = dataList && dataList.length > 0 ? dataList : [];
		const { formatMessage } = this.props.intl;

		let columns = [
			{ path: "", label: "#" },
			{ path: "TYPE", labelStringID: "KC0102" },
			{ path: "", labelStringID: "KC0680" },
			{ path: "BRAND", labelStringID: "KC0062" },
			{ path: "", labelStringID: "KC0649" },
			{ path: "RECIPE_NAME", labelStringID: "KC0241" },
			{ path: "RECIPE_FILE_SIZE", labelStringID: "KC0682" },
			{ path: "", labelStringID: "KC0325" },
			{ path: "", label: ""},
		];

		dataList = dataList.filter(data => data.TYPE === "ReadyRecipe");

		if (sortColumn.path === "UPDATED_ON") {
			if (sortColumn.order === "desc") {
				dataList = dataList.sort((d1, d2) => new Date(d2.UPDATED_ON) - new Date(d1.UPDATED_ON));
			}
			else {
				dataList = dataList.sort((d1, d2) => new Date(d1.UPDATED_ON) - new Date(d2.UPDATED_ON));
			}
		}
		else if (sortColumn.path === "LIBRARY_FILE_SIZE") {
			dataList = orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path]], [sortColumn.order]);
		}
		else {
			dataList = orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path].toString().toLowerCase()], [sortColumn.order]);
		}

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div className="menuUpdateWrapper">
							<div className="menuFilterHeader">
								<ul>
									<li>
										<Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
										<Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })}><FormattedMessage id="KC1180"/></Link>
										<Link to="/readyRecipeLibraryList" title={formatMessage({ id: 'KC1181' })} className="active"><FormattedMessage id="KC1181"/></Link>
										<Link to="/menuUpdateStatus" title={formatMessage({ id: 'KC0659' })}><FormattedMessage id="KC0659"/></Link>
									</li>
								</ul>
							</div>
							<form className="readyRecipeForm">
								<div className="readyRecipeTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.selectAllLibrary}
										columns={columns}
									 />
								</div>
								<div className="readyRecipeSrlTable">
									<div className="readyRecipeTable">
										{
											dataList.map((item, i) => {
												return (
													<ul className="tBody" key={i}>
														<li>{i + 1}</li>
														<li><span className="menuType"><img src={require("../../../images/icons/recipeS.png")} title={item.TYPE === "ReadyRecipe" ? "Ready Recipe" : item.TYPE === "Recipe" ? "Recipe" : item.TYPE === "Settings" ? "Settings" : item.TYPE === "Legacy" ? "Legacy" : "Cookbook"} alt="" /></span></li>
														<li><span className="menuPicture">{item.TYPE === "Settings" ? "" : item.IMAGE_S3_SIGNED_URL ? <img src={item.IMAGE_S3_SIGNED_URL} alt=""/> : ""}</span></li>
														<li>{item.BRAND}&nbsp;
															<div className="menuSListSM">{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString() : ''}</div>
															<div className="menuSListSMF">{item.RECIPE_NAME}</div>
															<div className="menuSListAS">{item.RECIPE_FILE_SIZE ? item.RECIPE_FILE_SIZE.toFixed(2) : "0.00"} mb</div>
														</li>
														<li>{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString() : ''}</li>
														<li>{item.RECIPE_NAME}</li>
														<li>{item.RECIPE_FILE_SIZE ? item.RECIPE_FILE_SIZE.toFixed(2) : "0.00"}</li>
														<li><button type="button" disabled={true} className="btn btn-default-text menuInfo" data-toggle="modal" data-target="#detailsInfoModal" onClick={() => this.OnToggleDetails(item)}>&nbsp;</button></li>
														<li>
															<button type="button" disabled={item.IS_ADDED_IN_LIBRARY === 1} className="btn btn-default" onClick={() => this.OnLibraryAdd(item)}><FormattedMessage id="KC1499"/></button>
														</li>
													</ul>
												)
											})
										}
									</div>
								</div>
								<NoRecordsFound loaderIconVisible={loaderIconVisible} length={dataList !== undefined ? dataList.length : 0} classname={'norecordsfoundbluetext'} />
							</form>
							<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
							<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
						</div>
					</div>
					<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
				</div>
				{/* <!-- Delete Confirmation Modal --> */}
				<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="deleteConfirmation">
									<FormattedMessage id="KC1497"/>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancelLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
								<button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDeleteLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- //Delete Confirmation --> */}
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
		selectedCountries: state.filterInformation.selectedCountries,
		selectedRegions: state.filterInformation.selectedRegions,
		selectedLocations: state.filterInformation.selectedLocations,
		selectedCustomers: state.filterInformation.selectedCustomers,
		selectedBrands: state.filterInformation.selectedBrands,
		selectedModels: state.filterInformation.selectedModels
	}
}

export default connect(mapStateToProps)(injectIntl(ReadyRecipeLibraryList));
