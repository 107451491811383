import {TOTAL_TRAY_COUNT_REQUEST,TOTAL_TRAY_COUNT_SUCCESS,TOTAL_TRAY_COUNT_FAIL} from '../../../actions/unitDashboard/totalTrayCountAction';
const initialState = {
    loaded: false,
    loading: false,
    totalTrayCountResponse: {}
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case TOTAL_TRAY_COUNT_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case TOTAL_TRAY_COUNT_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                totalTrayCountResponse: action.totalTrayCountResponse
            }
        case TOTAL_TRAY_COUNT_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                totalTrayCountResponse: {},
                errormsgTotalTrayCountResponse: 'Server Error',

            }
        default:
            return state
    }
}