import {PAN_VS_TIME_REQUEST,PAN_VS_TIME_SUCCESS,PAN_VS_TIME_FAIL} from '../../../actions/reportManagement/panVsTimeLineAction';
const initialState = {
    loaded: false,
    loading: false,
    panVsTimeResponse: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case PAN_VS_TIME_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case PAN_VS_TIME_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                panVsTimeResponse: action.panVsTimeResponse
            }
        case PAN_VS_TIME_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgPanvsTime: 'Server Error',

            }
        default:
            return state
    }
}