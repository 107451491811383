import SecureStorage from 'react-secure-storage';
 
/**
 * KCStorage is a utility object for securely managing storage operations.
 * It provides methods to set, get, remove, and clear items in secure storage.
 *
 * @namespace KCStorage
 * @property {function(string, string): Promise<void>} setItem - Stores a key-value pair in secure storage.
 * @property {function(string): Promise<string|null>} getItem - Retrieves the value associated with a key from secure storage.
 * @property {function(string): Promise<void>} removeItem - Removes the key-value pair associated with a key from secure storage.
 * @property {function(): Promise<void>} clear - Clears all key-value pairs from secure storage.
 */
const KCStorage = {
    setItem: (key, value) => SecureStorage.setItem(key, value),
    getItem: (key) => {
        let value = SecureStorage.getItem(key);
        if (typeof value === "number") {
            return value = value.toString();
        }
        return value;
    },
    removeItem: (key) => SecureStorage.removeItem(key),
    clear: () => SecureStorage.clear(),
};
export default KCStorage;