import http from '../../service/httpService';
import authService from '../../service/authService';
import React from 'react';
import { TableHeader } from '../../components/controls/tableHeader';
import { userRoleURI } from '../../service/api';
import _ from 'lodash';
import NoRecordsFound from '../../components/controls/noRecordsFound';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCLink from '../../UI_Components/KCLink';
import KCStore from '../../utils/kcStorage';

const RoleListComponent = (props) => {


	const { formatMessage } = props.intl;
	const { role, loader, searchData, allSelect, isIndeterminateSelect, editRole, handleChange, setLoading, snackBarToast, getRoleListCallback,sortColumn,onSort } = props;
	let userProfileAuthFeature = authService.getFeature("/role/add");

	const loadingPopupShowHide = (isActive) => {
		setLoading(isActive)
	}

	const deleteRoles = async () => {
		loadingPopupShowHide(true);
		let selectedRolesList = role.filter(item => item.checked === true);
		let requestObject = {
			roleIds: selectedRolesList.map(item => item.Id),
			customer_id: KCStore.getItem("customerId")
		}
		try {
			const deleteResponse = await http({
				method: "DELETE",
				url: userRoleURI.deleteAPI,
				data: requestObject,
				headers: { 'Content-Type': 'application/json' }
			});
			let { data } = deleteResponse;
			let message = data.data.isRoleAlreadyAssigned ? formatMessage({ id: 'KC1072' }, { roleNames: selectedRolesList.map(i => i.role_name).join(',') }) : (data.data.stringID || data.data.message);
			snackBarToast(formatMessage({ id: message}), data.data.isRoleAlreadyAssigned ? 'approve' : 'success').then(async()=>{
				if(!data.data.isRoleAlreadyAssigned){
                    await getRoleListCallback();
                }
				loadingPopupShowHide(false);
				WoopraEvents(`${Constants.DELETE_ROLE}`);
			});

		} catch (error) {
			let errorResponse = error.response;			
			if (errorResponse.status === 400 || errorResponse.status === 500) {
				let responseMessage = errorResponse.data["KC_ERR_400"] ? errorResponse.data["KC_ERR_400"] : errorResponse.data["KC_ERR_500"] ? errorResponse.data["KC_ERR_500"] : "";
				snackBarToast(responseMessage, 'error');
				loadingPopupShowHide(false);
			}
			else {
				loadingPopupShowHide(false);
			}
		}

	}

	// Woopra Events
	const trackEvent = (e) => {
		WoopraEvents(`${Constants.USER_MANAGEMENT}-${e.trackTitle}`);
	}


	let columns = [
		{ path: "", label: "#" },
		{ path: "", label: "", isNbSp: true },
		{ path: "role_name", labelStringID: 'KC0615' },
		{ path: "", label: "", isNbSp: true },
		{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
	];


	const isAdd = role?.filter((i) => i.checked === true)?.length === 0;
	const isEdit = role?.filter((i) => i.checked === true)?.length === 1;
	const isDelete = role?.filter((i) => i.checked === true)?.length >= 1;

	return (
		<div id="adminWrapper">
			<div id="page-content-wrapper" className="withSearchBar">
				<div className="userManagementWrapper">
					<div className="userMgmtHeader">
						<ul>
							<li>
								<KCLink to="/user/list" onClick={(e) => { e.trackTitle = "User List"; trackEvent(e) }} title={formatMessage({ id: "KC0592" })}><FormattedMessage id={'KC0592'} /></KCLink>
								<KCLink to="/role/list" className="active" title={formatMessage({ id: "KC0585" })}> <FormattedMessage id={'KC0585'} /></KCLink>
							</li>
							<li>
								<KCLink to="#" onClick={editRole} className={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn btn-default-text userEdit" : "btn btn-default-text userEdit disabled"} title={formatMessage({ id: "KC0617" })}>&nbsp;</KCLink>
								<KCLink to="#" className={userProfileAuthFeature.is_editable === 1 && isDelete ? "btn btn-default-text signOutBtn userDelete" : "btn btn-default-text userDelete disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal" title={formatMessage({ id: "KC0618" })}>&nbsp;</KCLink>
								<KCLink to="/role/add" className={userProfileAuthFeature.is_editable === 1 && isAdd ? "btn btn-default-text userAdd" : "btn btn-default-text userAdd disabled"} title={formatMessage({ id: "KC0616" })}>&nbsp;</KCLink>

							</li>
						</ul>
					</div>
					<form className="userMgmtRolesForm">
						<div className="userMgmtRolesTable">
							<TableHeader columns={columns}
								isIndeterminate={isIndeterminateSelect}
								isSelectAll={allSelect}
								sortColumn={sortColumn}
								onSort={onSort}
								/>
						</div>
						<div class="userMgmtTableSrlBar">
							<div className="userMgmtRolesTable">
								{role?.map((item, i) => (
									<ul className="tBody" key={i}>
										<li>{1+ i++}</li>
										<li><span className="userDP"><img src={item.img} alt="" /></span></li>
										<li>{item.role_name}</li>
										<li>&nbsp;</li>
										<li>
											<div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
												<input id={i} name={i} type="checkbox"
													checked={item.checked}
													onChange={(e) => handleChange(e, item, 'single')} />
												<label htmlFor={i}></label>
											</div>
										</li>
									</ul>
								))
								}
							</div>
							<NoRecordsFound loaderIconVisible={loader} length={role !== undefined ? role.length : 0} classname={'norecordsfoundbluetext'} stringID={searchData && Object.keys(searchData).length !== 0 && role && role.length === 0 ? "KC0853" : "KC0205"} />
						</div>
					</form>
				</div>
				<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
					<div className="modal-dialog modal-md loginModal">
						<div className="modal-content" >

							<div className="modal-body">
								<div className="singOutConfirmation">
									<FormattedMessage id="KC1071" />
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button"><FormattedMessage id={'KC0021'} /></button>
								<button className="btn btn-secondary signOutYesBtn" onClick={() => deleteRoles()} data-dismiss="modal" type="button"><FormattedMessage id={'KC0611'} /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

};


export default injectIntl(RoleListComponent);
