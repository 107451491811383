import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getModel } from './../../../actions/listView/locations/modelAction';
import { getFilterbyModels } from './../../../actions/listView/locations/filterbyAction';
import  LocationHeader  from './../../layoutSection/allLocationHeaderLayout';


class FilterByModel extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			modelList: "",
			selectedList: [],
			search:'',
		}
		this.getSelectedModelName = this.getSelectedModelName.bind(this);
		this.callModelAction = this.callModelAction.bind(this);
	}

	updatesearch(e)
	{
		this.setState({search:e.target.value});
	}

	componentDidMount() {
		let { state: previousState } = this.props.location;
		let { prevSelectedFilterValue: prev_selectedModelList } = previousState;
		if (prev_selectedModelList && prev_selectedModelList.length > 0) {
			this.setState({
				selectedList: prev_selectedModelList
			});
		}

		let selectedBrandList =	this.props.selectedBrands ? this.props.selectedBrands :[];
		let selectedCgids =[];
		selectedBrandList.map(c =>{ if(c.checked ===  true){
			return selectedCgids.push(c.ID)
        }});
		this.props.getModel(selectedCgids.join(','));
	}

	getSelectedModelName(e, item) {
		let isChecked = e.target.checked;
		this.state.selectedList.find(c => {
			if (c.ID === item.ID) {
				return c.checked = isChecked
			}
		});

		this.setState({
			selectedList: this.state.selectedList
		});
		this.props.getFilterbyModels(this.state.selectedList); 
	}

	callModelAction() {
		this.state.selectedList && this.state.selectedList.length > 0 && this.props.getFilterbyModels(this.state.selectedList);
	}

	render() {
		let { selectedList: _modelList } = this.state;
		const {modelInfo} = this.props;  

		if (modelInfo && modelInfo.length > 0) {
			if (_modelList && _modelList.length > 0) {
				for (let index = 0; index < _modelList.length; index++) {
					for (let k = 0; k < modelInfo.length; k++) {
						if (modelInfo[k].ID === _modelList[index].ID) {
							if (_modelList[index].checked) {
								modelInfo[k].checked = true;
								break;
							}
						}
					}
				}
				//this.state.selectedList = modelInfo;
				this.setState({selectedList:modelInfo})
				_modelList = this.state.selectedList;

			} else {
				//this.state.selectedList = modelInfo;
				this.setState({selectedList:modelInfo})
				_modelList = this.state.selectedList;
			}
		}

		const search=this.state.search;
			const searchedItemInFilter=_modelList.filter(
				(contact)=>{
					return contact.PRODUCT_NAME.toLowerCase().startsWith(search.toLowerCase() )|| false;				
				}
		);
		_modelList=searchedItemInFilter;

		let previouspage = "/dashboardLocations";
		if (this.props.location.state !== undefined)
		{
			previouspage = this.props.location.state.prevPath.previouspage;
		}
		
		return (			
				<div>							
					<div id="wrapper" className="disableNavLink">
						<div class="toggleTransparent"></div>
						<div class="loadingProgress">
							<div class="loadingProgressIn"></div>
						</div>
						<LocationHeader />
					<div class="navFilterWrapper" style={{ display: 'block' }}>
						<div id="CountryFilter">
							<div class="filterHeader">
								<ul>
									<li ><Link onClick={this.callModelAction.bind(this)} to={{ pathname: '/locationsView/filterBy/', state: { paramvalue: this.state.modelList, selectedList: this.state.selectedList, prevPath: {previouspage} } }} title="Back" class="backIcon" id="backToFilterListDiv">&nbsp;</Link>
									</li>
									<li>
										Model
									</li>
									<li>
										<Link to={previouspage} title="Finish">Finish</Link>
										{/* <Link to={previouspage} title="Filter by" class="cancelIcon cancelFilterListDiv">&nbsp;</Link> */}
									</li>
								</ul>
							</div>
							<div class="filterListOuter">
								<input class="form-control headerFilterSearch" value={this.state.search} onChange={this.updatesearch.bind(this)} type="text" placeholder="Search" />
								<div class={_modelList.length > 3 ? 'countryCheckbox withMorethanThree' : 'countryCheckbox'}> 
									{Object.keys(_modelList).map((item, i) => (
										<div key={_modelList[i].ID} class="customCheckbox customCheckboxWlabel">
											<input id={_modelList[i].PRODUCT_NAME} class="chkGroupName" value={_modelList[i].PRODUCT_NAME} checked={_modelList[i].checked} onChange={(e) => this.getSelectedModelName(e, _modelList[i])} name="testgroupName" type="checkbox" />
											<label for={_modelList[i].PRODUCT_NAME}>{_modelList[i].PRODUCT_NAME}</label>
										</div>
									))}
								</div>
							</div>
						</div>

					</div>

    				</div>
					</div>  
				)
			}
		};	

function mapStateToProps(state) {
	return {
		modelInfo: state.modelInformation.modelInformation,
		selectedBrands: state.filterInformation.selectedBrands,
	}
}

		function mapDispatchToProps(dispatch) {
			return bindActionCreators({ getModel,getFilterbyModels }, dispatch);			
		}

export default connect(mapStateToProps, mapDispatchToProps)(FilterByModel);
