import {ORG_UNITS_DETAILS_REQUEST,ORG_UNITS_DETAILS_SUCCESS,ORG_UNITS_DETAILS_FAIL} from '../../../../actions/navigation/organizationmanagement/organizationUnitsDetailsActions';
const initialState = {
    loaded: false,
    loading: false,
    OrganizationUnitsDetailsInformation: {}
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case ORG_UNITS_DETAILS_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                
            }
        case ORG_UNITS_DETAILS_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                OrganizationUnitsDetailsInformation: action.OrganizationUnitsDetailsInformation,
               
            }
        case ORG_UNITS_DETAILS_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
               errormsg: '',

            }
        default:
            return state
    }
}

