import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';

class FrymasterAutomaticEcoMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            idleTime: false,
            scheduleTime: false,
            hourFormat: 'false',
            timeMask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            if (ObjSettingsFile.general.time_date) {
                this.setState({
                    hourFormat: ObjSettingsFile.general.time_date.hour_format === 1 ? "true" : "false"
                })
            }
            if (ObjSettingsFile.eco_mode) {
                this.setState({
                    idleTime: ObjSettingsFile.eco_mode.automatic_eco_mode.idle_time === 0 ? false : true,
                    scheduleTime: ObjSettingsFile.eco_mode.automatic_eco_mode.scheduled_time === 0 ? false : true,
                    autoTime: ObjSettingsFile.eco_mode.automatic_eco_mode.idle_time_value
                })
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        let { idleTime, scheduleTime, autoTime } = this.state;
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.eco_mode.automatic_eco_mode) {
            ObjSettingsFile.eco_mode.automatic_eco_mode = {}
        }

        ObjSettingsFile.eco_mode.automatic_eco_mode.idle_time = idleTime === false ? 0 : 1;
        ObjSettingsFile.eco_mode.automatic_eco_mode.scheduled_time = scheduleTime === false ? 0 : 1;
        ObjSettingsFile.eco_mode.automatic_eco_mode.idle_time_value = autoTime;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/fryMasterEcoMode`);
    }

    onSaveWhileAction = (key, value) => {
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.eco_mode.automatic_eco_mode) {
            ObjSettingsFile.eco_mode.automatic_eco_mode = {};
        }
        ObjSettingsFile.eco_mode.automatic_eco_mode[key] = value;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/fryMasterEcoMode`);
    }

    handleOnSliderChange = (value, stateKey) => {
        this.setState({
            [stateKey]: value
        })
    }

    onCheckValue = (e, key, localStateKey) => {
        const value = e.target.checked;
        this.setState({
            [key]: value
        }, () => {
            this.onSaveWhileAction(localStateKey, value === false ? 0 : 1)
        });
    }

    onSetTimeValueChange = (e) => {
        let startsWith = e.target.value[0];
        const value =  e.target.value;
        
        this.setState({
            timeMask: [
                this.state.hourFormat === "false" ? /[0-2]/ : /[0-1]/,
                this.state.hourFormat === "true" && startsWith === "1" ? /[0-2]/ : this.state.hourFormat === "false" ? /[0-3]/ : /[0-9]/,
                ':',
                this.state.hourFormat === "true" ? /[0-0]/ : /[0-5]/,
                this.state.hourFormat === "true" ? /[0-0]/ : /[0-9]/
            ],
            autoTime: value
        },()=>{
            this.onSaveWhileAction('idle_time_value',value)
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = KCStore.getItem("selectedbrandID");

        const { idleTime, scheduleTime, autoTime, timeMask, hourFormat } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Automatic Eco Mode Header Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Automatic Eco Mode Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Automatic Eco Mode Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC0155"/>
                                </div>



                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm ecoModeDiv">
                                                    <ul>
                                                        <li class="width245px"><div class="customCheckbox">
                                                            <input id="IdleTime" name="EcoMode" type="checkbox" value="IdleTime" onChange={(e) => this.onCheckValue(e, 'idleTime','idle_time')} checked={idleTime} />
                                                            <label for="IdleTime"><FormattedMessage id="KC1488"/></label>
                                                        </div></li>
                                                        <li class="smallFormFields">
                                                            {/* <input type="time" class="form-control" placeholder="HH:MM" onChange={(e) => this.setState({ autoTime: e.target.value })} value={autoTime} /> */}
                                                            <InputMask
                                                                mask={timeMask}
                                                                maskChar=""
                                                                placeholder={hourFormat === "true" ? formatMessage({ id: 'KC1973' }) : formatMessage({ id: 'KC0836' })}
                                                                onChange={(e) => this.onSetTimeValueChange(e)}
                                                                value={autoTime}
                                                                class="form-control" />
                                                        </li>
                                                    </ul>
                                                    <ul class="borderNone">
                                                        <li class="width245px"><div class="customCheckbox">
                                                            <input id="ScheduledTime" name="EcoMode" type="checkbox" value="ScheduledTime" onChange={(e) => this.onCheckValue(e, 'scheduleTime','scheduled_time')} checked={scheduleTime} />
                                                            <label for="ScheduledTime"><FormattedMessage id="KC1489"/></label>
                                                        </div></li>
                                                        <li class="arrowBtn"><button type="button" onClick={() => this.props.history.push('/frymasterEcoModeScheduled')} class={scheduleTime ? "btn nextButton" : "btn nextButton disabled"}>&nbsp;</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Automatic Eco Mode Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterAutomaticEcoMode)
