import { SET_LIVE_VIEW_DATA_DATA, SET_LIVE_VIEW_DATA_STATUS, CLEAR_LIVE_VIEW_SESSION_DATA } from '../../../actions/liveViewMode/type';

const initialState = {
    liveViewConnectionStatus: null,
    liveViewData: [],
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_LIVE_VIEW_DATA_DATA:
            return {
                ...state,
                liveViewData: action.payload,
            }
        case SET_LIVE_VIEW_DATA_STATUS:
            return {
                ...state,
                liveViewConnectionStatus: action.payload,
            }
        case CLEAR_LIVE_VIEW_SESSION_DATA:
            return {
                initialState
            }
        default:
            return state
    }
}