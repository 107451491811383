import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {Link} from 'react-router-dom';

am4core.useTheme(am4themes_animated);




class ServiceNotes extends Component {
    render() {
        return(
            <React.Fragment>
                <div id="wrapper">  
                <nav class="navbar navbar-expand-lg navbar-dark bg-Welbilt"> 
			  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			  </button>

			  <div class="collapse navbar-collapse" id="navbarContent">
				<ul class="navbar-nav mr-auto navbarLeft">
				  <li class="nav-item">
					<Link class="nav-link" to="#" title="Welbilt Kitchen">
						<span class="navStoreIcon"><img src={require('../../images/store/store01.svg').default} alt="Welbilt Kitchen" title="Welbilt Kitchen"/></span>
						<span class="navStoreText">Welbilt Kitchen <b>Munich City</b></span>
					</Link>
				  </li>
				  <li class="nav-item">
					<Link class="nav-link" to="#" title="Carrara Espresso">
						<span class="navStoreIcon"><img src={require('../../images/unit/unit01.png')} alt="Carrara Espresso" title="Carrara Espresso"/></span> 
						<span class="navStoreText">Carrara Espresso <b>Crem</b></span>
					</Link>
				  </li>  
				</ul> 

				<ul class="navbar-nav navbarLRight">
                    <li class="nav-item">
                        <Link class="nav-link" to="#" title="Serial Number">
                            <span class="deviceSNText">S/N <b>530.115.987</b></span>
                        </Link>
                    </li>
        </ul>

				<ul class="navbar-nav mr-auto navbarRight">
				  <li class="nav-item">
					<Link class="nav-link " title="Sorting" to="#">&nbsp;</Link>
				  </li> 
				  <li class="nav-item">
					<Link class="nav-link navFilter" title="Filter" to="#">&nbsp;</Link>
				  </li> 
				  <li class="nav-item">
					<Link class="nav-link navSearch" title="Search" to="#">&nbsp;</Link>
				  </li> 
				  <li class="nav-item">
					<Link class="nav-link navAlarm" title="Notification" to="#">&nbsp;</Link>
				  </li> 
				  <li class="nav-item">
					<Link class="nav-link navUser" title="User Name" to="#">&nbsp;</Link>
				  </li> 
				</ul> 
			  </div>
		</nav>

        <div id="page-content-wrapper">

            <div class="errorWrapper">
                <div class="errorHeader">
                    <ul>
                        <li>
                            <Link to="/errorDescription" title="Back" class="backIcon">&nbsp;</Link>
                        </li>
                        <li>
                            Ec_2: Error Description
                        </li>
                        <li>
                            <Link to="/errorReport" title="Create Report">Create Report</Link>
                        </li>
                        <li>
                            <Link to="/errorReport" title="Create Report" class="forwardIcon">&nbsp;</Link>
                        </li>
                    </ul>
                </div>

                <div class="serviceInfoListOuter">

                    <div class="serviceInfoListF">
                        <h5>Service Notes</h5>
                        <div class="serviceNotesTable">
                            <ul class="tHead">
                                <li>#</li>
                                <li>Name</li>
                                <li>Date</li>
                                <li>Time</li>
                            </ul>
                            <ul>
                                <li>01</li>
                                <li>
                                    <h6>Milk compartment MC 5000.00 replaced.</h6>
                                    <p>The bitterness of poor quality remains long after the sweetness of low price is forgotten. The bitterness of poor quality remains long after the sweetness of low price is forgotten.</p>
                                </li>
                                <li>2019-01-14</li>
                    <li>10:25</li>
                            </ul>
                            <ul>
                                <li>02</li>
                                <li>
                                    <h6>Milk compartment MC 5000.00 replaced.</h6>
                                    <p>The bitterness of poor quality remains long after the sweetness of low price is forgotten. The bitterness of poor quality remains long after the sweetness of low price is forgotten.</p>
                                </li>
                                <li>2019-01-14</li>
                    <li>22:02</li>
                            </ul>
                            <ul>
                                <li>03</li>
                                <li>
                                    <h6>Milk compartment MC 5000.00 replaced.</h6>
                                    <p>The bitterness of poor quality remains long after the sweetness of low price is forgotten. The bitterness of poor quality remains long after the sweetness of low price is forgotten.</p>
                                </li>
                                <li>2019-01-11</li>
                    <li>01:52</li>
                            </ul>
                            <ul>
                                <li>04</li>
                                <li>
                                    <h6>Milk compartment MC 5000.00 replaced.</h6>
                                    <p>The bitterness of poor quality remains long after the sweetness of low price is forgotten. The bitterness of poor quality remains long after the sweetness of low price is forgotten.</p>
                                </li>
                                <li>2019-01-10</li>
                    <li>12:25</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
                </div>

		
		
               </div>

            </React.Fragment>
        )
    }
};

export default ServiceNotes;
