
export const showBackDrop = (event) => {

    let cover = null;
    cover = document.createElement('div');
    /*Create a transparent cover for the viewport*/
    cover = document.createElement("div");
    cover.id = "backdropElement";
    cover.style.height = "100%";
    cover.style.width = "100%";
   // cover.style.backgroundColor = "black";
    cover.style.opacity = "0.8";
    cover.style.position = "fixed";
    cover.style.top = "0px";
    cover.style.left = "0px";
    cover.style.zIndex = "9";
    document.body.appendChild(cover);
 
    return cover;
}

export const hideBackdrop = (event) =>{
    let cover = document.getElementById("backdropElement");
    if(cover) {
      document.body.removeChild(cover);
    }
}

export default  {
    showBackDrop,
    hideBackdrop
}