import React, { Component } from 'react';
import axios from 'axios';
import http from '../../service/httpService';
import { BaseService } from '../../service/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage } from 'react-intl';

import KCStore from '../../utils/kcStorage';
// require('../../css/welbilt.scss');
// require('../../css/welbiltmediascreen.css');

const recaptchaRef = React.createRef();

const HTTP_METHOD ={
	GET:'GET',
	PUT:'PUT',
	POST:'POST',
	DELETE:'DELETE'
}


	let formIsValid = true;
	var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
	var pwdmatch = new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/);

    class SignUp extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	onSuccessCloseModal() {
		this.props.history.push(`/dashboardLocations`);
	}
	
	constructor(props){
        super(props);  
		this.state = {
			username:'',
			password:'',
			isVerified:false,
			newpassword:'',
			error_modal:{
				open:false,
				message:""
			  },
			  success_modal: {
				  open:false,
				message:""
			  },
				loaderIconVisible:false,
			fields: {},
        	errors: {},
					isFormValidated:false,
					saveData:{},
					showhidepassword: false,
					 type: 'password',
					 showFirstLogin: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.emailblurevent = this.emailblurevent.bind(this);
		this.passwordblurevent = this.passwordblurevent.bind(this);
		this.postComment = this.postComment.bind(this);
		this.closeModel = this.closeModel.bind(this); 
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.togglePwd = this.togglePwd.bind(this);
		this._reCaptchaRef = React.createRef();
		this.verifyCallback = this.verifyCallback.bind(this);
	}
	closeModel() {
		this.setState({
      error_modal:{
				open:false				
			  }
    })
	}
	// handleChange = ({ target }) => {
	// 	 this.setState({ [target.name]: target.value });
	// 	 this.setState({username: event.target.value});
	//  };

	 handleChange(e) {
      let fields = this.state.fields;
			// console.log(fields)				
      fields[e.target.name] = e.target.value;
			// console.log(fields[e.target.name])
      this.setState({
        fields
      });		
    }	
	
	emailblurevent() {
		let fields = {};			  
		let inputdataemailobj = this.state.fields.emailid;
		let errors ={...this.state.errors};
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if(inputdataemailobj && inputdataemailobj !== "undefined" && pattern.test(inputdataemailobj)) {			
			this.setState({
					errors: {
								...this.state.errors,
								emailid: ''										
								
					}
			})												
		} 
		return false;	
	}
	passwordblurevent() {		
		let fields = {};			  				
		let inputdatapwdobj = this.state.fields.password

		let errors ={...this.state.errors};		
		var pwdmatch = new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/);		
		// remove error condition for pwd id
		if(inputdatapwdobj && inputdatapwdobj!== "undefined" && pwdmatch.test(inputdatapwdobj)){				
				this.setState({
						errors: {
									...this.state.errors,
									password: ''
						}
				})																		
		}
		return false;							
	}
	
	 
	togglePwd() {
		 this.setState( { showhidepassword: !this.state.showhidepassword }) 
    this.setState( { type: this.state.type === 'password' ? 'text' : 'password' }) 
	}
	showError= (err)=>{
		let { response } = err;	
		this.setState({
			loaderIconVisible: true
		});
		let postData ={
			username:this.state.fields.emailid,
			password:this.state.fields.password,
		}				
        if(response){
			let { data } = response;
			let { statusCode } = data;
			let fields = {};
			let inputdataemailobj = this.state.fields.emailid;
			let inputdatapwdobj = this.state.fields.password;
			if(data){
					this.setState({						
						fields: {
									...this.state.fields,
									emailid: '',
									password: ''																											
						},
						loaderIconVisible: false
			
				   });
				   let fields = {};							  					
			}
			if(statusCode == 500){ 	 
				let { body } = data;
				// KCStore.setItem("loginInfo", JSON.stringify(postData))
				// KCStore.setItem("Access-Token", body.idToken);
				// let { errorCode } = body;
				if( data.errorCode === "FIRST-LOGIN") {

					this.setState({
				loaderIconVisible: false,
				showFirstLogin: true,
				username:inputdataemailobj,
				password:inputdatapwdobj,
				success_modal:{
					open:false,
					message: "You have successfully logged in."
				},
				 }); 
				}
			}
		 }else{
			this.setState({
				success_modal:{
					open:true,
					message: `You have successfully logged in "${this.state.username}".`
				},
				loaderIconVisible: false,
				showFirstLogin: false  
			}); 
			this.props.history.push(`/dashboardLocations`)
		}	
	}

	
	postComment =()=>{
		
			this.setState({
						loaderIconVisible: true
			});
	
		if (this.validateForm()) {	
			debugger;
			this.setState({
						loaderIconVisible: false
			});		
			let fields = {};			
			this.setState({fields:fields});
			//alert(fields);
			//alert(" Login Success ");

			let postData ={
				username:this.state.fields.emailid,
				password:this.state.fields.password,
			}		
		// if(postData["username"] && postData["username"] !== "undefined" && pattern.test(postData["username"]) && postData["password"] && postData["password"]!== "undefined" && pwdmatch.test(postData["password"])) {			
				this.putData(postData, BaseService.login, HTTP_METHOD.POST).then(response =>{
					console.log(postData, "------->response")
				}).catch(err => {
					this.showError(err);
					//this.showError2(err);
				});
			// }
		}
	}
	        
	createPassword = (e) => {	

		this.setState({
			loaderIconVisible: true
		});
		let postData = {          
		username: this.state.username,
		oldPassword:this.state.password,
		newPassword:this.state.fields.newpassword
	};		

		http({
				method: "PUT",
				url: BaseService.first_login,
	data: postData,											
		}).then( function(response)  {

			
			// let {data} = response;
			this.setState({	
				loaderIconVisible: false,		
				success_modal:{
					open:true,
					message: `You have successfully logged in "${this.state.username}".`
				},
				 });  
				 this.props.history.push(`/dashboardLocations`);         
		
		}).catch(err => {

this.showError(err); 
}); 
}

	validateForm() {

		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
  		  
		if (!fields["emailid"]) {			
		  formIsValid = false;
		  errors["emailid"] = "*Please enter your email-ID.";
		}
  
		if (typeof fields["emailid"] !== "undefined") {
		  //regular expression for email validation
		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(fields["emailid"])) {
			formIsValid = false;
			errors["emailid"] = <FormattedMessage id="KC0018" />;
		  }
		}
      
		if (!fields["password"]) {
		  formIsValid = false;
		  errors["password"] = "*Please enter your password.";
		}
		// if (!fields["newpassword"]) {
		//   formIsValid = false;
		//   errors["newpassword"] = "*Please enter your new password.";
		// }
  
		// if (typeof fields["password"] !== "undefined") {
		//   if (!fields["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
		// 	formIsValid = false;
		// 	errors["password"] = "*Please enter secure and strong password.";
		//   }
		// }
  
		if (!this.state.isVerified && this.state.isVerified != undefined)
		{
			debugger;
			formIsValid = false;
		  errors["recaptcha"] = "*Please select reCAPTCHA.";
		}
		this.setState({
		  errors: errors,
		  loaderIconVisible: false,
		});
		return formIsValid;
  
  
	  }

	putData =(postData, url, method) =>{
		
		let username = this.state.fields.emailid;
		return new Promise((resolve, reject)=>{
		  http({
			  method:method,
			  url: url,
			  data: postData,
				loaderIconVisible: true,
			  headers: {'Content-Type': 'application/json',
	 		/*"Authorization": jwt_decode(token)*/  } 
		  }).then(res =>{
			  
			  let { data } = res;
			   let { statusCode } = data;
			   if(statusCode == 200){ 	 
					 let { body } = data;
					 KCStore.setItem("loginInfo", JSON.stringify(postData))
					 KCStore.setItem("Access-Token", body.idToken);
            this.setState({
          loaderIconVisible: false,
					success_modal:{
						open:true,
						message: `You have successfully logged in "${username}".`
					},
		   		}); 
				  	this.props.history.push(`/dashboardLocations`);
			   }else{
					
			 	  reject({ res : {
			 		  data : res.data
				   }});					 					 			
			   }  				
		  }).catch(err=>{
			  reject(err);
		  });
		});  
	  }
  
		verifyCallback = (recaptchaToken) => {
			// Here you will get the final recaptchaToken!!!  
			//console.log(recaptchaToken, "<= your recaptcha token")
			debugger;
			if (recaptchaToken)
			{
				this.setState({
					isVerified: true,
					errors: {
						...this.state.errors,
						recaptcha: ''
					}
				});
			}
		}

	render() {
	const { saveData, error_modal, success_modal, closeModel, loaderIconVisible, showFirstLogin} = this.state;	
		return (
            <div>
                <div className="loginBg">
                <div id="adminWrapper">        
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
            <div className="loadingProgressIn"></div>
        </div>
		
        <nav className="navbar navbar-expand-lg navbar-dark bgAdmin"> 
            <div className="collapse navbar-collapse" id="navbarContent">
                 <div className="navbar-header"> 
                <Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>
            </div>
            <p className="navbartextAlignCenter">Welcome</p>
            
            </div>
        </nav>
		
                    <div id="page-wrapper"> 
		<Link className="kitchenConncetLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>
                        <div class="LoginBtnWrapper"> 
                            <div id="loginBtnFormID">
                                <div class="loginButton">
                                    <ul>
                                        <li>&nbsp;</li>
                                        <li>
                                        <div class="loginIWrapper">
                                        <button class="btn btn-secondary singINBtn" type="button"  data-toggle="modal" data-target="#SignInModal" >
								Sign In
							  </button>
                              </div>
                                        </li>
                                        <li>&nbsp;</li>
                                    </ul>
                                    <ul>
                                        <li>
                                            {/* <Link to="#" data-toggle="modal" data-target="#SignInModal" title="Create Account">Create Account</Link> */}
                                        </li>
                                        <li>
                                        <div class="loginIWrapper">
                                        {/* <Link to="/existingUserLogin" title="Use Existing Account" class="useExistingAccount">Use Existing Account</Link>  */}
                                        <Link to="#" data-toggle="modal" data-target="#SignInModal1" title="Create Account">Create Account</Link>
                                        {/* <Link to="#" title="Language" class="languageSel"></Link> */}
                                        </div>
                                        </li> 
                                        <li>&nbsp;</li>
                                    </ul>
                                </div>
                            </div>
                        
                        <div class="modal fade" data-keyboard="true" data-backdrop="static" id="SignInModal1" tabindex="-1" role="dialog" aria-labelledby="SignInModalLabel">
                            <div class="modal-dialog modal-md loginModal">
                                <div class="modal-content">
                                    <div class="modal-header loginModalHeader modal-header-danger">
                                    Sign Up <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
                                    </div>
                                    <div class="modal-body paddingTop0px"> 
                                        <div class="loginForm">  
                                            <ul>
                                                <li class="form-group marginBottom10px"> 
                                                    <input type="text" name="Email" class="form-control" placeholder="Enter your Email"/>  
                                                </li> 
                                                <li class="form-group pass"> 
                                                    <div class="input-group">
                                                        <input class="form-control pwd" name="PasswordHash" placeholder="Create a password" type="password" value="" />
                                                        <span class="input-group-btn">
                                                            <button class="btn input-group-addon reveal" type="button"><i class="glyphicon glyphicon-eye-open"></i></button>
                                                        </span>
                                                    </div>   
                                                </li>
                                                
                                                <li class="form-group pass"> 
                                                    <div class="input-group">
                                                        <input class="form-control pwd" id="password2" name="ConfirmPasswordHash" placeholder="Retype password" type="password" value="" />
                                                        <span class="input-group-btn">
                                                            <button class="btn input-group-addon reveal" type="button"><i class="glyphicon glyphicon-eye-open"></i></button>
                                                        </span>
                                                    </div>  
                                                </li> 
                                                <li>
										        <ReCAPTCHA ref={this._reCaptchaRef} sitekey="6Lfm7poUAAAAAAhOzR85EpbAqgbQ1KiIhai2HyBG" onChange={this.verifyCallback} />
										        <div className="errorMsg">{this.state.errors.recaptcha}</div> 
									        </li>

                                                <li class="form-group pass"> 
                                                    <div class="customCheckbox customCheckboxWlabel">
                                                        <input id="RememberMe" name="RememberMe" type="checkbox" value="RememberMe"/>
                                                        <label for="RememberMe">Remember Me</label>
                                                    </div> 
                                                </li> 
                                            </ul>
                                        </div> 
                                    </div>
                                    <div class="modal-footer textAlignCenter firstLoginBtn">
									{/* <button class="btn btn-secondary singINBtn" type="button"  id="SignInButton" >Sign Up</button> */}
										<button className="btn btn-secondary existingSingINBtn" type="button" id="existingSignInButton" onClick={() => this.postComment()}>Sign In </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                         <div class="modal fade" data-keyboard="true" data-backdrop="static" id="SignInModal" tabindex="-1" role="dialog" aria-labelledby="SignInModalLabel">
                            <div class="modal-dialog modal-md loginModal">
                            <div class="modal-content">
                                    <div class="modal-header loginModalHeader modal-header-danger">
                                    Sign In <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
                                    </div>
                                    <div class="modal-body paddingTop0px"> 
                                        <div class="loginForm">  
                                            <ul>
                                                <li class="form-group "> 
                                                <div className="input-group"> 
                                                <input className="form-control" placeholder="Enter Email" type="text" name="emailid" value={this.state.fields.emailid} onChange={this.handleChange} onBlur={this.emailblurevent} />
                                                </div>
                                                <div className="errorMsg">{this.state.errors.emailid}</div>
                                                </li> 
                                                <li class="form-group pass"> 
                                                <div className="input-group">
                                                <input className="form-control pwd" name="password" placeholder="Enter Password" value={this.state.fields.password} type={this.state.type} onChange={this.handleChange} onBlur={this.passwordblurevent} />
                                                <div className="errorMsg">{this.state.errors.password}</div> 
                                                <span className="input-group-btn" onClick={this.togglePwd}>
												<button className="btn input-group-addon reveal" type="button">
                                                    <i className={this.state.showhidepassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open" }></i>
                                                </button>
											</span>
										</div>   
                                                </li>

                                                <div class="modal fade" data-keyboard="true" data-backdrop="static" id="SignInModal1" tabindex="-1" role="dialog" aria-labelledby="SignInModalLabel">
                                                <li class="form-group pass"> 
                                                    <div class="input-group">
                                                        <input class="form-control pwd" id="password2" name="ConfirmPasswordHash" placeholder="Retype password" type="password" value="" />
                                                        <span class="input-group-btn" onClick={this.togglePwd}>
                                                            <button class="btn input-group-addon reveal" type="button">
                                                            <i className={this.state.showhidepassword ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open" }></i>
                                                            </button>
                                                        </span>
                                                    </div>  
                                                </li> 
                                            </div>
                                            <li>
										        <ReCAPTCHA ref={this._reCaptchaRef} sitekey="6Lfm7poUAAAAAAhOzR85EpbAqgbQ1KiIhai2HyBG" onChange={this.verifyCallback} />
										        <div className="errorMsg">{this.state.errors.recaptcha}</div> 
									        </li>

                                                
                                            <li class="form-group pass"> 
                                            <div class="customCheckbox customCheckboxWlabel">
                                                        <input id="RememberMe"  name="RememberMe" type="checkbox" value="RememberMe"/>
                                                        <label for="RememberMe">Remember Me</label>
                                                    </div> 
                                                    {/* <Link to="/forgetPassword" title="Language" className="ForgotPassword">Forgot Password</Link>  */}
                                             </li> 
                                                
                                            </ul>
                                        </div> 
                                    </div>
                                    <div class="modal-footer textAlignCenter firstLoginBtn">
                                    <button class="btn btn-secondary singINBtn" type="button"  id="SignInButton" onClick={() => this.postComment()} >Sign In</button>
                                    </div>

                                    {/* <button className="btn btn-secondary existingSingINBtn" type="button" id="existingSignInButton" onClick={() => this.postComment()}>Sign In </button> */}
                                </div>
                                    </div> 
                                    </div>
                                    </div> 
                                    <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel}/>
			                        <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal}/>
                                    </div>
                            </div>
	{/* <!-- SignIn Modal --> */}
    <div className={showFirstLogin ? "modal fade showFirstLogin show": "modal fade"} data-keyboard="false" data-backdrop="static" id="SignInModal" tabindex="-1" role="dialog" aria-labelledby="SignInModalLabel">
        <div className="modal-dialog modal-md loginModal">
            <div className="modal-content"> 
						<div className="modal-header loginModalHeader modal-header-danger">
                Sign Up <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
                </div>
                <div className="modal-body paddingTop0px"> 
					<div className="loginForm">  
						<ul>
							<li className="form-group marginBottom10px"> 
								<input type="text" name="emailid" className="form-control" placeholder="Enter your Email" defaultValue={this.state.username} onChange={this.handleChange} onBlur={this.emailblurevent}/>  
							</li> 
							<li className="form-group pass"> 
								<div className="input-group">
									<input className="form-control pwd" name="password" placeholder="Old password" type="password" defaultValue={this.state.password} type={this.state.type} onChange={this.handleChange} onBlur={this.passwordblurevent} />
									<span className="input-group-btn">
										<button className="btn input-group-addon reveal" type="button"><i className="glyphicon glyphicon-eye-open"></i></button>
									</span>
								</div>   
							</li>
							
							<li className="form-group pass"> 
								<div className="input-group">
									<input className="form-control pwd" id="password2" name="newpassword" placeholder="Create password" type="password" value={this.state.fields.newpassword} type={this.state.type} onChange={this.handleChange} onBlur={this.passwordblurevent}/>
									{/* <div className="errorMsg">{this.state.errors.newpassword}</div> */}
									<span className="input-group-btn">
										<button className="btn input-group-addon reveal" type="button"><i className="glyphicon glyphicon-eye-open"></i></button>
									</span>
								</div>  
							</li> 
							<li className="form-group pass"> 
								<div className="customCheckbox customCheckboxWlabel">
									<input id="RememberMe" name="RememberMe" type="checkbox" value="RememberMe"/>
									<label for="RememberMe">Remember Me</label>
								</div> 
							</li> 
						</ul>
					</div> 
                </div>
                <div className="modal-footer textAlignCenter firstLoginBtn">
                   <button className="btn btn-secondary singINBtn" type="button" id="SignInButton" onClick = {(e) => this.createPassword(e)}>Sign Up</button>
                </div>
            </div>
						<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel}/>
			<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal}/>
        </div>
    </div>

                    
                   
    <div class="modal fade SignInSuccessModal " data-keyboard="true" data-backdrop="static" id="SignInSuccessModal" tabindex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel">
        <div class="modal-dialog modal-md loginModal">
            <div class="modal-content"> 
                <div class="modal-body"> 
					<div class="singINConfirmation"> 
					You successfully created your personal password!
					</div>
                </div>
                <div class="modal-footer textAlignCenter">
                   <button class="btn btn-secondary signINconfirmationOKBtn" onclick='/dashboardlocations' type="button">OK</button>
                </div>
            </div>
        </div>
    </div>

                </div>
            </div>
		)
	}
};

export default SignUp;

function onChange(value) {
	console.log("Captcha value:", value);
}
