import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

class AssignUpdate extends Component {

	constructor(props) {
		super(props);
		this.state = {
        };
	}
	componentDidMount() {
	}

	render() {
		const { formatMessage } = this.props.intl;
		return (
            <div id="adminWrapper">
           <div class="loadingProgress">
            <div class="loadingProgressIn"></div>
        </div>
       
        <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-Welbilt"> 
            <div class="collapse navbar-collapse" id="navbarContent">
				<div class="navbar-header"> 
					<Link class="navbar-brand welbiltRLogo" title={formatMessage({ id: 'KC0038' })} to="/mainmenu">&nbsp;</Link>
				</div>
                 <ul class="navbar-nav mr-auto selectedNavbarLeft">
                    <li class="nav-item">
                        <Link class="nav-link" to="#" title={formatMessage({ id: 'KC0832' })}> 
                            <span class="softwareNav"><FormattedMessage id="KC0832"/></span>
                        </Link>
                    </li> 
                </ul> 
				<ul class="navbar-nav mr-auto navbarRight"> 
                    <li class="nav-item">
                        <Link class="nav-link navFilter" title={formatMessage({ id: 'KC0996' })} to="#">&nbsp;</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link navSearch" title={formatMessage({ id: 'KC0037' })} to="#">&nbsp;</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link navAlarm" title={formatMessage({ id: 'KC0997' })} to="#">&nbsp;</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link navUser" title={formatMessage({ id: 'KC0998' })} to="#">&nbsp;</Link>
                    </li>
                </ul>
				<ul class="navbar-nav mr-auto navbarRight">
                    <li class="nav-item">
                        <Link class="nav-link navBack" title={formatMessage({ id: 'KC0989' })} to="/mainmenu">&nbsp;</Link>
                    </li> 
                </ul>
            </div>
        </nav>
        {/* <!-- //Fixed Header navbar -->  style={{padding:'64px 0px 0px'}}*/}
        <div id="page-content-wrapper">
        <div class="unitRegistrationWrapper">
        <div class="addUnitHeader">
				<ul>
					<li>&nbsp;</li>
					<li>
						<FormattedMessage id="KC1169"/>
					</li>
					<li>
						<Link to="/softwareUpdatesStatus" title={formatMessage({ id: 'KC0056' })}><FormattedMessage id="KC0056"/></Link> 
					</li>
				</ul>
			</div>
			<div class="selectedUnitHeader">
				<ul>
					<li><FormattedMessage id="KC1570" values={{ selectedCount: 10, offlineCount: 3 }}/></li>
					<li>
						<Link to="/assignupdate"  class="btn btn-default-text assignunitSite">&nbsp;</Link>
                        {/* <Link to="/assignupdatelist" class="btn btn-default-text assignunitList">&nbsp;</Link>    */}
                        <Link to="/assignupdate" class="btn btn-default-text assignunitList">&nbsp;</Link>   
					</li>
				</ul>
			</div>
        </div>
        <div class="orgznManagementWrapper borderBottom0px"> 
			
				<div class="collapseExpandHeader">
					<ul>
						<li>
							<Link onClick={(e)=> e.preventDefault()} title={formatMessage({ id: 'KC0057' })}><FormattedMessage id="KC0057"/></Link>   
						</li> 
						<li>
							<Link onClick={(e)=> e.preventDefault()} title={formatMessage({ id: 'KC1045' })}><FormattedMessage id="Country"/></Link>
						</li> 
						<li>
							<Link onClick={(e)=> e.preventDefault()} title={formatMessage({ id: 'KC1046' })}><FormattedMessage id="Sub Group"/></Link>
						</li> 
						<li>
							<Link onClick={(e)=> e.preventDefault()} title={formatMessage({ id: 'KC0050' })}><FormattedMessage id="KC0050"/></Link>   
						</li> 
						<li>
							<Link onClick={(e)=> e.preventDefault()} title={formatMessage({ id: 'KC1567' })}><FormattedMessage id="Floor"/></Link>
						</li> 
						<li>
							<Link onClick={(e)=> e.preventDefault()} title={formatMessage({ id: 'KC0101' })}><FormattedMessage id="KC0101"/></Link>   
						</li> 
					</ul>
				</div> 
				<div class="orgznSiteTreeOuter assignUnitDotted"> 
                
                </div>
				 
			</div>
			 
        </div>
		</div>
    

            
		)
	}
};

function mapStateToProps(state) {
	debugger;
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignUpdate));
