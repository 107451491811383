var recipeJSON = {    
    "id": "",
    "edit_date": "",
    "author": "",
    "email": "",
    "name": {
        // "en_US": ""
     },
    "image": "",
    "settings": { "portion": 1, "state": "ambient", "weight": 50 },
    "ordered_steps": [{
            "duration": "0",
            "actions": {
                "set_point": {
                    "value": 0,
                    "units": ""
                },
                "prompt": {
                    "message": {
                        // "en_US": ""
                    },
                    "type": "",
                    "prompt-id": "",
                    "image": "",
                    "sound": "",
                    "sound_repeat": 0,
                    "duration": ""
                }
            }
        }
    ]
}

export default recipeJSON 