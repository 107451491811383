import {UNIT_DASHBOARD_REQUEST,UNIT_DASHBOARD_SUCCESS,UNIT_DASHBOARD_FAIL} from '../../../actions/unitDashboard/UnitDashboardAction';
const initialState = {
    loaded: false,
    loading: false,
    unitdashboardInformation:{},
    unitdashboardProductInformationdata:{}
}

export default function reducer (state = initialState, action = {}) {

    switch (action.type) {
        case UNIT_DASHBOARD_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
               
            }
        case UNIT_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                unitdashboardInformation: action.unitdashboardInformation,
                unitdashboardProductInformation: action.unitdashboardProductInformation
            }
        case UNIT_DASHBOARD_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',

            }
        default:
            return state
    }
}