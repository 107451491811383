import React, { PureComponent } from 'react';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';
import { TableHeader } from '../../controls/tableHeader';
import { reactIntl } from '../../../locale/locale-provider';
import _ from 'lodash';

import KCStore from '../../../utils/kcStorage';

var moment = require('moment');
am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);
var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var startOfQuarter = require('date-fns/start_of_quarter');
var endOfWeek = require('date-fns/end_of_week');
var endOfMonth = require('date-fns/end_of_month');
var endOfQuarter = require('date-fns/end_of_quarter');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let currentUnit;
let modelNo;
const Colors = ["#FFF", "#009c43", "#efb302", "#dc0026"];


const chartMapping = {
	"Individual Cooking": "KC2244",
	"ConvoSense": "KC2245",
	"Service": "KC0626",
	"Idle": "KC0360",
	"Press&Go": "KC1281",
	"Cleaning":"KC1789"
}
class ConvothermOvenStatusPieChart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			ovenStatusChartInformation: [],
			total_unit_count: 0,
			loading: true,
			filterType: "Month",
			average: 0,
			sd: '',
			ed: '',
			startDate: '',
			endDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			datapickflag: false,
            onOvenStatusSortColumn:{ path: "", order: "", sort_color: "#FFFFFF" },
            sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
		}
		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount() {
		this.getReportData(this.state.filterType, "", "");
	}

	toggleDatePicker(e) {
		this.setState({ filterType: "Date Range" })
	}

	handleChangeStart(date) {
		this.setState({
			startDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e);
		});
	}

	handleChangeEnd(date) {
		this.setState({
			endDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e);
		});
	}

	getReportData(text, startDate, date) {
		let unitID = "";
		let brandID = "";
		currentUnit = this.props.properties.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;

		const header = this.getHeaders(text, startDate, date, unitID);
		let url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + brandID}`;
		this.getData(url, header).then(response => {
			this.renderChartData(text, response.ovenStatusChartInformation);
		}).catch(err => {
			this.renderChartData(err);
            console.log("Failed to render chart", err);
		});
	}

	onDropDownHandler(e) {
        this.setState({ovenStatusChartInformation: [] })
		const { nodeText:text } = e;
		this.setState({ datapickflag: text === 'Date Range'})
		this.handleOvenStatusUtilization(text);
	}

	handleOvenStatusUtilization(text) {
		const { startDate, endDate } = this.state;
		this.setState({ filterType: text })
		if (text === "Date Range") {
			if (startDate !== "" && endDate !== "") {
				this.getReportData(text, startDate, endDate);
			}
		}
		else {
			this.getReportData(text, "", "");
		}
	}

	getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', unitID = '') {
		let filterType;
		switch (type) {
			case 'Week':
				filterType = 'weekly';
				break;
			case 'Year':
				filterType = 'yearly';
				break;
			case 'Day':
				filterType = 'daily';
				break;
			case 'Quarter':
				filterType = 'quarterly';
				break;
			case 'Date Range':
				filterType = 'custom';
				break;
			default:
				filterType = 'monthly';
		}

		let header;

		if (filterType === 'custom' || filterType === 'weekly') {
			if (type === 'Week') {
				startDateFormatted = format(todaysDate, 'YYYY-MM-DD');
				endDateFormatted = format(todaysDate, 'YYYY-MM-DD');

				var startDateMonth = getMonth(new Date(startDateFormatted));
				var endDateMonth = getMonth(new Date(endDateFormatted));

				if (startDateMonth !== endDateMonth) {
					startDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
				}
			}
			else {
				startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');
				endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');
				startDateFormatted = format(todaysDate, 'YYYY-MM-DD');
			}
			modelNo = currentUnit.PRODUCT_NAME;

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'startdate': startDateFormatted,
				'enddate': endDateFormatted,
				'filterType': filterType,
				'showtrend': "false",
				'reqdate': startDateFormatted,
			};

		}
		else {
			startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			currentUnit = this.props.properties.currentUnit;
			modelNo = currentUnit.PRODUCT_NAME;

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'reqdate': startDateFormatted,
				'filterType': filterType,
				'showtrend': "false",
			};
		}

		return header;
	}


    getData(url, header) {
        console.log("Header and URL", url, header);
        return new Promise((resolve, reject) => {
            http
                .get(url,
                    {
                        headers: header,
                        data: {}
                    })
                .then(response => {
                    console.log("Response::", response);
                    this.setState({ovenStatusChartInformation: response.data.ovenStatus})
                    resolve({
                        ovenStatusChartInformation: response.data.ovenStatus
                    })
                })
                .catch(error => {
                    console.log('Error', error);
                    reject({
                        ovenStatusChartInformation: []
                    })
                });
        });
    }

	renderChartData = (type, data) => {
		let response =  [];
		response = data;
        console.log("chart data for convosense", data);
        if (response && response.length > 0 && !this.chart) // Chart Rendering at first time response
        {
            // Create Pie chart 
            let pie_chart = am4core.create("ovenUtilizationStatusPieChartDiv", am4charts.PieChart);
            pie_chart.data = response;
            this.chart = pie_chart;
            pie_chart.responsive.enabled = true;

            // Add and configure Series
            let pieSeries = pie_chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "value";
            pieSeries.dataFields.category = "key";
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;
            pieSeries.slices.template.stroke = am4core.color("#FFFFFF");
            pieSeries.slices.template.strokeWidth = 0.8;
            pieSeries.slices.template.strokeOpacity = 1;
            pieSeries.colors.list = [
				am4core.color("#ffc000"),
                am4core.color("#5b9bd5"),
                am4core.color("#a5a5a5"),
                am4core.color("#70ad47"),
                am4core.color("#ed7d31"),
                am4core.color("#4472c4")
            ];

            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;

            // Pie Series Tooltip Text and bg color
            pieSeries.slices.template.tooltipText = "{category}: {value.value}%";
            pieSeries.tooltip.getFillFromObject = false;
            pieSeries.tooltip.background.fill = am4core.color("#FFFFFF");
            pieSeries.tooltip.label.fill = am4core.color("#000000");

			pieSeries.tooltip.label.adapter.add("textOutput", function (text, target) {
				if (target.dataItem.dataContext != undefined) {
					let seriesNameTrans =  target.dataItem.dataContext.key ? reactIntl.formatMessage({ id: chartMapping[target.dataItem.dataContext.key] }) : '';
					if ((target.dataItem.dataContext.value !== 0)) {
						return " " + seriesNameTrans + " : [bold]" + target.dataItem.dataContext.value + "%"+"[/]";
					}
					else {
						return "";
					}
				}
				else {
					return "";
				}
			});
        }
        else { // Chart rendering with only data source
            if (this.chart) {
                this.chart.data = response;
            }
        }

	}

	componentWillUnmount = () => {
		if (this.chart) {
			this.chart.dispose();
		}
	}

    onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

    onOvenStatusSort = onOvenStatusSortColumn => {
		this.setState({
			onOvenStatusSortColumn: onOvenStatusSortColumn
		});
	}

	render() {
        const { onOvenStatusSortColumn, ovenStatusChartInformation } = this.state;
        let onOvenStatusColumn = [
			{ path: "Key", labelStringID: "KC2243" },
            { path: "Value", labelStringID: "KC2242" }
		];

		return (
			<div className="gridViewTable">
				<div className="gridView">
					<div className="colm12D">
						<div className="colmDi">
							{/* <div className={displayfrypotStatusContent.length === 0 ? "noResultMsg" : "displaynone"}><FormattedMessage id="KC0264"/></div> */}
							{/* <div className={displayfrypotStatusContent && displayfrypotStatusContent.length > 0 ? "frypotStatusOuter" : "displaynone"}> */}
							<h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID} /> : this.props.widgetName}
								<NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.filterType} disableDatePicker={this.props.disableDatePicker ? this.props.disableDatePicker : false} />
							</h4>
							<div className="frypotStatusChart">
								<div id="ovenUtilizationStatusPieChartDiv"></div>
								<ul className="convothermStsLegendSection">
									<li><span></span><FormattedMessage id="KC2244" /></li>
									<li><span></span><FormattedMessage id="KC1281" /></li>
									<li><span></span><FormattedMessage id="KC2245" /></li>
									<li><span></span><FormattedMessage id="KC0626" /></li>
									<li><span></span><FormattedMessage id="KC1274" /></li>
									<li><span></span><FormattedMessage id="KC0360" /></li>
								</ul>
							</div>
							<div className="frypotStatusRD">
								<div className="frypotStatusTable">
									<TableHeader
										sortColumn={onOvenStatusSortColumn}
										onSort={this.onOvenStatusSort}
										columns={onOvenStatusColumn} />
									{ovenStatusChartInformation && ovenStatusChartInformation.map((item, i) => <ul className="tBody">
										<li>{item.key ? item.key : ""}</li>
										<li>{item.value ? item.value : 0}%</li>
									</ul>)}
								</div>
							</div>
							{/* </div> */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ConvothermOvenStatusPieChart;
