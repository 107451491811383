import {MediaManagementAPI, BaseService} from '../../../service/api';
import  http from '../../../service/httpService';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';

const HTTP_METHOD = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE'
}

function uploadMediaToCloud(url,postData,imagevideodata,thumbnaildata) {
	
	return new Promise((resolve, reject) => {
		http({
			url : BaseService.root + url,
		    method :'get',
			headers: {
				'Content-Type': 'application/json', 			
			},
			data:{}	
		}).then(response => {
			let imageurl = response.data.message.url;
			let thumbnailurl = response.data.message.thumbnailUrl;
			let Uploadeds3url = imageurl.split('?');
			Uploadeds3url = Uploadeds3url[0];
			if(postData.category !== 'zip' && postData.category !== 'wav') {

			var xhr = new XMLHttpRequest();
			xhr.open("PUT", thumbnailurl);
			xhr.setRequestHeader('Content-Type', 'multipart/form-data');
			xhr.onloadend =  (res) => {
				 if(res.target.status == 200){										
					var xhrthumb = new XMLHttpRequest();
					xhrthumb.open("PUT",  imageurl);
					xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
					xhrthumb.onloadend =  (res) => {
						if(res.target.status == 200){
							postData.s3Url  = Uploadeds3url;
								http({
								method:HTTP_METHOD.POST,
								url:`${BaseService.root}${MediaManagementAPI.getMediaBrandCustomer}`,
								data: postData,
								headers: {
									'Content-Type': 'application/json', 
									'basecountry': BASECOUNTRY_ID
								}
							}).then(res =>{
								let { data } = res;
								let { httpCode, responseCode, message } = data;
							if(httpCode === "HTTP_200"){  
								WoopraEvents(`${Constants.MEDIA_FILE_UPLOADED}`,postData)                        
								resolve(message);
								}
								else{
									reject(res);
								}
							}).catch(err => {
								reject(err);
							});
						}
					};
					xhrthumb.onerror = (err) =>{
						reject(err);
					};
					xhrthumb.send(imagevideodata);
					//
				 }
			};
			xhr.onerror = (err) =>{
                reject(err);
			};
			xhr.send(thumbnaildata);
		}
		else{
			var xhr = new XMLHttpRequest();
			xhr.open("PUT", imageurl);
			xhr.setRequestHeader('Content-Type', 'multipart/form-data');
			xhr.onloadend =  (res) => {
				 if(res.target.status == 200){
					postData.s3Url  = Uploadeds3url;	
				
					
								http({
								method:HTTP_METHOD.POST,
								url:`${BaseService.root}${MediaManagementAPI.getMediaBrandCustomer}`,
								data: postData,
								headers: {
									'Content-Type': 'application/json',
									'basecountry': BASECOUNTRY_ID
								}
							}).then(res =>{
								let { data } = res;
								let { httpCode, responseCode, message } = data;
							if(httpCode === "HTTP_200"){                          
								resolve(message);
								}
								else{
									reject(res);
								}
							}).catch(err => {
								reject(err);
							});
						}
					
			};
			xhr.onerror = (err) =>{
                reject(err);
			};
			xhr.send(imagevideodata);
		}
		}).catch(err => {
			reject(err);
		});
	});
}

export default uploadMediaToCloud