import moment from 'moment';
import { moment as momentTimezone } from 'moment-timezone';

/**
 * IMPORTANT: This file can be distributed across microservices and UI application.
 * So keep this file standalone, do not import any other modules.
 * Declare all dependencies here itself
 */

const secondsToDHM = (seconds, skipZeroDay = false) => {
    if (!isNaN(seconds) && seconds >= 0) {
        const days = Math.floor(seconds / 24 / 60 / 60);
        const hours = Math.floor(seconds / 60 / 60 % 24);
        const mins = Math.floor(seconds / 60 % 60);
        console.log("Days: ", days, " Hours: ", hours, " Mins: ", mins)
        return skipZeroDay && days === 0 ? `${hours}h ${mins}m` : `${days}d ${hours}h ${mins}m`;
    }
    return skipZeroDay ? '0h 0m' : '0d 0h 0m';
};

const secondsToDHMTrend = (seconds, skipZeroDay = false, rootScope) => {
    if (!isNaN(seconds) && seconds >= 0) {
        const days = Math.floor(seconds / 24 / 60 / 60);
        const hours = Math.floor(seconds / 60 / 60 % 24);
        const mins = Math.floor(seconds / 60 % 60);
        return skipZeroDay && days === 0 ? `<span>${hours}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> ${mins}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>` : `<span>${days}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC2391')}</span> ${hours}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> ${mins}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>`;
    }
    return skipZeroDay ? `<span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>` : `<span>0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC2391')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>`;
};

const minutesToDHM = (minutes, skipZeroDay = false) => {
    if (!isNaN(minutes) && minutes >= 0) {
        const days = Math.floor(minutes / 24 / 60);
        const hours = Math.floor(minutes / 60 % 24);
        const mins = Math.floor(minutes % 60);
        return skipZeroDay && days === 0 ? `${hours}h ${mins}m` : `${days}d ${hours}h ${mins}m`;
    }
    return skipZeroDay ? '0h 0m' : '0d 0h 0m';
};

const minutesToDHMTrend = (minutes, skipZeroDay = false, rootScope) => {
    if (!isNaN(minutes) && minutes >= 0) {
        const days = Math.floor(minutes / 24 / 60);
        const hours = Math.floor(minutes / 60 % 24);
        const mins = Math.floor(minutes % 60);
        return skipZeroDay && days === 0 ? `<span>${hours}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> ${mins}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>` : `<span>${days}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC2391')}</span> ${hours}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> ${mins}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>`;
    }
    return skipZeroDay ? `<span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>` : `<span>0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC2391')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>`;
};

const minutesToHM = (minutes) => {
    if (!isNaN(minutes) && minutes >= 0) {
        const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
        const mins = (minutes % 60).toString().padStart(2, '0');
        return `${hours}:${mins}`;
    }
    return '00:00';
};

const secondsToHM = (seconds) => {
    if (!isNaN(seconds) && seconds >= 0) {
        const hours = Math.floor(seconds / 60 / 60).toString().padStart(2, '0');
        const mins = Math.floor((seconds / 60) % 60).toString().padStart(2, '0');
        return `${hours}:${mins}`;
    }
    return '00:00';
};

const typeOf = (value) => {
    return typeof value;
};

const formatISODateString = (isoDateString, format, offset) => {
    isoDateString = !offset ? isoDateString.replace('Z', '') : isoDateString;
    return moment(isoDateString).format(format);
};

const formatDate = (date, format) => {
    return moment(date).format(format);
};

const dateDiffForISOString = (startISODateString, endISODateString, offset) => {
    startISODateString = !offset ? startISODateString.replace('Z', '') : startISODateString;
    endISODateString = !offset ? endISODateString.replace('Z', '') : endISODateString;
    return {
        seconds: moment(endISODateString).diff(moment(startISODateString), 'seconds'),
        minutes: moment(endISODateString).diff(moment(startISODateString), 'minutes'),
        hours: moment(endISODateString).diff(moment(startISODateString), 'hours'),
        days: moment(endISODateString).diff(moment(startISODateString), 'days'),
        months: moment(endISODateString).diff(moment(startISODateString), 'months'),
        years: moment(endISODateString).diff(moment(startISODateString), 'years')
    }
};

const dateDiff = (startDate, endDate) => {
    return {
        seconds: moment(endDate).diff(moment(startDate), 'seconds'),
        minutes: moment(endDate).diff(moment(startDate), 'minutes'),
        hours: moment(endDate).diff(moment(startDate), 'hours'),
        days: moment(endDate).diff(moment(startDate), 'days'),
        months: moment(endDate).diff(moment(startDate), 'months'),
        years: moment(endDate).diff(moment(startDate), 'years')
    }
};

const getDateFromISOString = (isoDateString, offset) => {
    isoDateString = !offset ? isoDateString.replace('Z', '') : isoDateString;
    return new Date(isoDateString);
};

const getISOStringFromDate = (date) => {
    return date.toISOString();
};

const hasValueOnlyForProps = (obj, propsArray) => {
    if (!obj || Object.keys(obj).length === 0) {
        return false;
    }
    for (const key in obj) {
        if (obj[key] && propsArray.indexOf(key) === -1) {
            return false;
        }
    }
    return true;
};

const hasValueAllForProps = (obj = {}, propsArray) => {
    const filters = Object.keys(obj)

    if (!filters.length) return false

    if (filters.length && propsArray.length) {
        for (const prop of propsArray) {
            if (filters.indexOf(prop) === -1) {
                return false
            }
        }
    }
    return true
};

const formatTimezoneDate = (timeZone, format) => {
    return timeZone && timeZone !== '' ? moment().tz(timeZone).format(format) : moment().tz(moment.tz.guess()).format(format);
}

const formatTimeWithTimezoneDate = (date, timeZone, format) => {
    return  moment(new Date(date).toString()).tz(timeZone).format(format);
}

// ACP - TIME FROM LAST FILTER MSG (CurrentTimeStamp - deviceTimeStamp);
const timeStampToDHMValue = (eventTimeStamp, rootScope) => {


    if (eventTimeStamp) {

        const givenTime = new Date(eventTimeStamp);
        // Get the current UTC time
        const currentTime = new Date();
        // Calculate the difference in milliseconds
        const differenceInMilliseconds = currentTime - givenTime;

        if (differenceInMilliseconds < 0) {
            return `<span>0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1886')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>`;
        }

        // Calculate hours, minutes, and seconds
        const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        const hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

        return `<span>${days}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1886')}</span> ${hours}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> ${minutes}<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>`;
    }
 
    return `<span>0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1886')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1898')}</span> 0<span class="kc-trend-time-specifiers">${rootScope?.translateText('KC1899')}</span></span>`;
};

const handleFahrenheit = (value) => {
    return `${value}<span class="kc-trend-time-specifiers"> °F</span>`;
}

const jsonataCustomFunctions = [
    {
        name: 'secondsToDHM',
        fn: secondsToDHM,
        signature: ''
    },
    {
        name: 'secondsToDHMTrend',
        fn: secondsToDHMTrend,
        signature: ''
    },
    {
        name: 'minutesToDHM',
        fn: minutesToDHM,
        signature: ''
    },
    {
        name: 'minutesToDHMTrend',
        fn: minutesToDHMTrend,
        signature: ''
    },
    {
        name: 'minutesToHM',
        fn: minutesToHM,
        signature: ''
    },
    {
        name: 'secondsToHM',
        fn: secondsToHM,
        signature: ''
    },
    {
        name: 'typeOf',
        fn: typeOf,
        signature: ''
    },
    {
        name: 'formatISODateString',
        fn: formatISODateString,
        signature: ''
    },
    {
        name: 'formatDate',
        fn: formatDate,
        signature: ''
    },
    {
        name: 'dateDiffForISOString',
        fn: dateDiffForISOString,
        signature: ''
    },
    {
        name: 'dateDiff',
        fn: dateDiff,
        signature: ''
    },
    {
        name: 'getDateFromISOString',
        fn: getDateFromISOString,
        signature: ''
    },
    {
        name: 'getISOStringFromDate',
        fn: getISOStringFromDate,
        signature: ''
    },
    {
        name: 'hasValueOnlyForProps',
        fn: hasValueOnlyForProps,
        signature: ''
    },
    {
        name: 'hasValueAllForProps',
        fn: hasValueAllForProps,
        signature: ''
    },
    {
        name: 'formatTimezoneDate',
        fn: formatTimezoneDate,
        signature: ''
    },
    {
        name: 'formatTimeWithTimezoneDate',
        fn: formatTimeWithTimezoneDate,
        signature: ''
    },
    {
        name: 'timeStampToDHMValue',
        fn: timeStampToDHMValue,
        signature: ''
    },
    {
        name: 'handleFahrenheit',
        fn: handleFahrenheit,
        signature: ''
    }
];

export {
    jsonataCustomFunctions,
    secondsToDHM,
    secondsToDHMTrend,
    secondsToHM,
    minutesToDHM,
    minutesToHM,
    typeOf,
    formatISODateString,
    formatDate,
    dateDiffForISOString,
    dateDiff,
    getDateFromISOString,
    getISOStringFromDate,
    hasValueOnlyForProps,
    hasValueAllForProps,
    formatTimezoneDate,
    timeStampToDHMValue,
    handleFahrenheit
};
