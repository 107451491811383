import React, { Component } from 'react';
 import {Router, browserHistory} from 'react-router';
import Modal from "react-responsive-modal";
import { FormattedMessage } from 'react-intl';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class AccessModal extends Component {

    nextPath(path) {
		this.props.history.push(path);
	}
constructor(props){
    super(props);
        this.onAccessModal = this.onAccessModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    state = {
        open: false
    };

    onAccessModal = (e) => {
        this.props.onAccessNowModel();
    };

    onCloseModal = (e) => {
      this.props.onAccessLaterModel();
    };

    render() {
        const { open, message } = this.props;
        return (
            <div style={styles}>
                <Modal open={open} showCloseIcon={false} center onClose={()=>{}}>
                    <div className="modal fade SignOutModal show" data-keyboard="true" data-backdrop="static" id="SignInSuccessModal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog modal-md loginModal">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="singOutConfirmation">{message}</div>
                                </div>
                                <div className="modal-footer textAlignCenter">
                                    <button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={()=>{this.onAccessModal()}}><FormattedMessage id='KC0915' /></button>
                                    <button className="btn btn-secondary signOutYesBtn" data-dismiss="modal" type="button" onClick={()=>{this.onCloseModal()}}><FormattedMessage id='KC0916' /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default AccessModal;