import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropDownListLink } from '../../controls/dropDownListLink';
import { format } from 'date-fns';
import { getheatMapTableInfo } from '../../../actions/reportManagement/heatMapTableAction';
import { getCurrentUnit } from '../../../actions/organizationentity/entityactions';
import authHelper from '../../../utils/authHelper';
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import KCStore from '../../../utils/kcStorage';


var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;
let productName;
class HeatMapTable extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			avgType: 'Month',
			topTenType: 'Month',
			peakHourType: 'Month',
			noOfDrinksType: 'Month',
			filterType: "Month",
			dateRange: {
				selection: {
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection',
				},
			},
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			sortColumnDrinks: { path: "", order: "", sort_color: "#FFFFFF" },
			sd: '',
			ed: '',
			startDate: todaysDate,
			endDate: todaysDate,
			sdAvgDrinks: '',
			edAvgDrinks: '',
			startDateAvgDrinks: todaysDate,
			endDateAvgDrinks: todaysDate,
			sdPeakHour: '',
			edPeakHour: '',
			startDatePeakHour: todaysDate,
			endDatePeakHour: todaysDate,
			sdTopTen: '',
			edTopTen: '',
			startDateTopTen: todaysDate,
			endDateTopTen: todaysDate,
			sdHeatMap: '',
			edHeatMap: '',
			startDateHeatMap: todaysDate,
			endDateHeatMap: todaysDate,
			//loaderIconVisible: true,
			hideDateRange: true,
			ResetCycle: [],
			datapickflag: false
		};

		this.handleChangeStartHeatMap = this.handleChangeStartHeatMap.bind(this);
		this.handleChangeEndHeatMap = this.handleChangeEndHeatMap.bind(this);
	}

	componentDidMount = async () => {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		productName = currentUnit.MODEL_NAME;
		this.props.getheatMapTableInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, productName);
		setTimeout(() => {
			this.setState({
				//loaderIconVisible: false,
			});
		}, 2000)
	}

	componentDidUpdate = async (prevProp, prevState) => {
		if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.noOfDrinksType !== this.props.globalDropdownFilter && this.props.globalDropdownFilter !== "Date Range" && this.props.isGlobalDropdownChange) {
			this.setState({ noOfDrinksType: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
			this.handleHeatMapTable(this.props.globalDropdownFilter, 'HeatMapTable');
			await this.props.handleDropdownChangeEnd();
		}
	}

	//Heat Map
	handleChangeStartHeatMap(dateHeatMap) {
		this.setState({ 
			startDateHeatMap: dateHeatMap, 
			sdHeatMap: dateHeatMap, 
			endDateHeatMap: dateHeatMap, 
			edHeatMap: moment(dateHeatMap).add('month', 1).subtract(1, 'days')._d ,
			datapickflag:true
		});
	}

	handleChangeEndHeatMap(dateHeatMap) {
		let selectedtext = 'Date Range';
		this.setState({
			endDateHeatMap:dateHeatMap,
			datapickflag: true
		});
		this.handleHeatMapTable(selectedtext, 'HeatMapTable');
	}

	onDropDownHandler(e, type = '') {
		const { nodeText:text } = e;
		this.setState({noOfDrinksType: text, datapickflag: text === 'Date Range', startDateHeatMap: '', endDateHeatMap: ''})
		this.handleHeatMapTable(text, type);
	}

	handleHeatMapTable(text, type) {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		productName = currentUnit.MODEL_NAME;
		if (text === "Date Range") {
			todaysDateFormatted = format(this.state.endDateHeatMap, 'YYYY-MM-DD');
			weekDateFormatted = format(this.state.startDateHeatMap, 'YYYY-MM-DD');
		}
		else if (text === 'Week') {
			weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
			todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
			if (KCStore.getItem("brandName") !== "CREM") {
				var startDateMonth = getMonth(new Date(weekDateFormatted));
				var endDateMonth = getMonth(new Date(todaysDateFormatted));

				if (startDateMonth !== endDateMonth) {
					weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
				}
			}
		}
		else {
			todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
			weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
		}

		if (type === "HeatMapTable") {
			this.setState({ noOfDrinksType: text });
			this.props.getheatMapTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, productName);
		}
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	onSortDrinks = sortColumnDrinks => {
		this.setState({
			sortColumnDrinks: sortColumnDrinks
		});
	}

	errorCloseModel = () => {
		authHelper.deleteLocalSession();
		this.props.history.push('/');
	}

	render() {
		//const currentUnit = this.props;
		//let heatMapTable = [];
		const { formatMessage } = this.props.intl;
		let heatMapTableWithColumn = [];
		//let { loaderIconVisible } = this.state;
		const { heatMapTableInfo } = this.props;
		const { noOfDrinksType } = this.state;
		let heatMapTableData = [];
		let heatMapTableColumnLabel = "";
		const { filterType } = this.props;
		let storageBrandName = KCStore.getItem("brandName");
		if (storageBrandName === "MERRYCHEF") {
			heatMapTableData = heatMapTableInfo.result === "{}" ? [] : heatMapTableInfo.result;
		}
		else if (storageBrandName === "CONVOTHERM") {
			heatMapTableData = heatMapTableInfo.content === "{}" ? [] : heatMapTableInfo.content;
		}
		else {
			heatMapTableData = heatMapTableInfo;
		}
		// CREM Expobar Model(EX3 DISPLAY & DIAMANT PRO DISPLAY) Sprint-3 UI Implementation
		currentUnit = this.props.currentUnit;
		productName = currentUnit && currentUnit.MODEL_NAME;
		//&& filterInput !== undefined ? filterInput.replace('ly','') : "" === noOfDrinksType.toLowerCase()

		if ((storageBrandName === "MERRYCHEF" || storageBrandName === "CREM") && Array.isArray(heatMapTableData) && heatMapTableData.length > 0) {
			for (var j = 0; j < heatMapTableData.length; j++) {
				// CREM Expobar Model(EX3 DISPLAY & DIAMANT PRO DISPLAY) Sprint-3 UI Implementation
				if (heatMapTableData[j].keyVal && (productName === "EX3" || productName === "DIAMANT PRO")) {
					heatMapTableColumnLabel = heatMapTableData[j].productID + " - " + heatMapTableData[j].keyVal;
				}
				else {
					heatMapTableColumnLabel = heatMapTableData[j].productID;
				}
				let heatMapTableWithColumnArr = {};
				heatMapTableWithColumnArr.productID = heatMapTableColumnLabel;
				heatMapTableData[j].counts.map((countItem, index) => {
					const i = index + 1;
					var obj = [];
					const month = () => {
						switch (filterType) {
							case 'monthly':
								obj = 'Week ' + i;
								heatMapTableWithColumnArr[obj] = countItem;
								break;
							case 'daily':
								obj = 'Day';
								heatMapTableWithColumnArr[obj] = countItem;
								break;
							case 'quarterly':
								obj = 'Month ' + i;
								heatMapTableWithColumnArr[obj] = countItem;
								break;
							case 'yearly':
								obj = 'Q' + i;
								heatMapTableWithColumnArr[obj] = countItem;
								break;
							case 'weekly':
								obj = 'Day ' + i;
								heatMapTableWithColumnArr[obj] = countItem;
								break;
							default:
								obj = 'Week ' + i;
								heatMapTableWithColumnArr[obj] = countItem;
						}
					}
					month();
				})
				heatMapTableWithColumnArr.totalCount = heatMapTableData[j].totalCount;
				heatMapTableWithColumnArr.trend = heatMapTableData[j].trend;
				heatMapTableWithColumn.push(heatMapTableWithColumnArr);
			}
		}
		else if ((storageBrandName === "CONVOTHERM") && heatMapTableData && heatMapTableData.length > 0) {

			for (var j = 0; j < heatMapTableData.length; j++) {
				let heatMapTableWithColumnArr = {};
				heatMapTableWithColumnArr.key = heatMapTableData[j].key;
				heatMapTableWithColumnArr.started = heatMapTableData[j].started;
				heatMapTableWithColumnArr.cancelled = heatMapTableData[j].cancelled;
				heatMapTableWithColumnArr.completed = heatMapTableData[j].completed;
				heatMapTableWithColumn.push(heatMapTableWithColumnArr);
			}
		}
		else {
			heatMapTableWithColumn = [];
		}

		const { sortColumn, sortColumnDrinks } = this.state;
		let heatmaptableclass = "drinksSoldTable";
		if (filterType === "daily") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableDay";
		}
		else if (filterType === "weekly") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableWeek";
		}
		else if (filterType === "monthly") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableMonth";
		}
		else if (filterType === "quarterly") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableQuarter";
		}
		else if (filterType === "yearly") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableYear";
		}

		let columns = [
			{ path: "", label: "#" },
			{ path: "", label: "", isNbSp: true },
			{ path: "productID", label: "Product" },
			{ path: "trend", label: "Trend" },
			{ path: "counts", label: "Count" },
		];

		let columnsDrinks = [
			{ path: "", label: "#" }
		];
		if ((storageBrandName === "MERRYCHEF" || storageBrandName === "CREM")) {
			if (heatMapTableWithColumn.length > 0) {
				Object.keys(heatMapTableWithColumn[0]).forEach(function (key) {
					if (key === "productID") {
						columnsDrinks.push({ path: key, labelStringID: "KC0170" });
					}
					else if (key === "totalCount") {
						columnsDrinks.push({ path: key, labelStringID: "KC0172" }); 
					}
					else if (key === "trend") {
						columnsDrinks.push({ path: key, labelStringID: "KC0171" });
					}
					else {
						columnsDrinks.push({ path: key, label: key });
					}
				});
				var swapItem = columnsDrinks[columnsDrinks.length - 1];
				columnsDrinks[columnsDrinks.length - 1] = columnsDrinks[columnsDrinks.length - 2];
				columnsDrinks[columnsDrinks.length - 2] = swapItem;
			} else {
				columnsDrinks.push(
					{ path: "productID", labelStringID: "KC0170" },
					{ path: "trend", labelStringID: "KC0171" },
					{ path: "totalCount", labelStringID: "KC0172" }
				);
			}
		}
		if ((storageBrandName === "CONVOTHERM")) {
			if (heatMapTableWithColumn.length > 0) {
				Object.keys(heatMapTableWithColumn[0]).forEach(function (key) {
					if (key === "key") {
						columnsDrinks.push({ path: key, labelStringID: "KC0170" });
					}
					else if (key === "started") {
						columnsDrinks.push({ path: key, labelStringID: "KC0196" });
					}
					else if (key === "cancelled") {
						columnsDrinks.push({ path: key, labelStringID: "KC0323" });
					}
					else if (key === "completed") {
						columnsDrinks.push({ path: key, labelStringID: "KC0324" });
					}
					else {
						columnsDrinks.push({ path: key, label: key });
					}
				});
			}
			else {
				columnsDrinks.push(
					{ path: "key", labelStringID: "KC0170" },
					{ path: "started", labelStringID: "KC0196" },
					{ path: "cancelled", labelStringID: "KC0323" },
					{ path: "completed", labelStringID: "KC0324" }
				);
			}
		}
		heatMapTableWithColumn = _.orderBy(heatMapTableWithColumn, [sortColumnDrinks.path], [sortColumnDrinks.order]);

		let displayWidgetResponse;
		if (storageBrandName === "MERRYCHEF" || storageBrandName === "CREM") {
			displayWidgetResponse = (Object.values(heatMapTableWithColumn).map((item, i) => (
				<ul>
					<li>{i + 1}</li>
					{
						Object.keys(heatMapTableWithColumn[i]).map((key) => {
							if (key === "totalCount") {
								return <li><span className={heatMapTableWithColumn[i]["trend"] === '1' ? "upArrow" : heatMapTableWithColumn[i]["trend"] === '0' ? "downArrow" : ""}></span></li>
							}
							else if (key === "trend") {
								return <li>{heatMapTableWithColumn[i]["totalCount"]} </li>
							}
							else {
								return <li>{heatMapTableWithColumn[i][key]} </li>
							}
						})
					}
				</ul>
			)))
		}
		else {
			displayWidgetResponse = (
				Object.values(heatMapTableWithColumn).map((item, i) => (
					<ul class="tHead">
						<li>{i + 1}</li>
						{
							Object.keys(heatMapTableWithColumn[i]).map((key) => {
								if (key === "key") {
									return <li>{heatMapTableWithColumn[i]["key"]}</li>
								}
								else if (key === "started") {
									return <li>{heatMapTableWithColumn[i]["started"]} </li>
								}
								else if (key === "cancelled") {
									return <li>{heatMapTableWithColumn[i]["cancelled"]} </li>
								}
								else if (key === "completed") {
									return <li>{heatMapTableWithColumn[i]["completed"]} </li>
								}
								else {
									return <li>{heatMapTableWithColumn[i][key]} </li>
								}
							})
						}
					</ul>
				))
			)
		}



		return (
			<div className="gridViewTable">
				<div class="gridView">
					<div class="colm12D borderNone">
						<div class="colmDi">
							<h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName} &nbsp;{KCStore.getItem("excludedProductList") === "true" ? <span class="blueDot" title={formatMessage({id: "KC1037"})}></span> : ""}  <span class="red-txt"> </span> 
								<span class="cornerIcon"></span>
								<DropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDateHeatMap} minDateDisable={this.state.sdHeatMap} maxDateDisable={this.state.edHeatMap} endDatepara={this.state.endDateHeatMap} handleChangeStartProps={this.handleChangeStartHeatMap} handleChangeEndProps={this.handleChangeEndHeatMap} OnChange={(e) => this.onDropDownHandler(e, "HeatMapTable")} filterType={this.state.noOfDrinksType} disableDatePicker={this.state.hideDateRange} />
							</h4>
							<div class={storageBrandName === "MERRYCHEF" || storageBrandName === "CREM" ? "drinksSold" : "cookingDataTableOuter minheightSetTable"}>
								<div class={storageBrandName === "MERRYCHEF" || storageBrandName === "CREM" ? heatmaptableclass : "cookingDataTable"}>
									<TableHeader
										sortColumn={sortColumnDrinks}
										onSort={this.onSortDrinks}
										columns={columnsDrinks} />
									{displayWidgetResponse}

								</div>
								<div >
									{
										heatMapTableWithColumn && heatMapTableWithColumn.length > 0 && heatMapTableWithColumn != null ? " " :
											<div className="noResultMsg"><FormattedMessage id="KC0264"/></div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		)
	}
};
function mapStateToProps(state) {
	return {
		heatMapTableInfo: state.heatMapTableInformation.heatMapTableInformation,
		currentUnit: state.entityReducer.currentUnit,
		filterType: state.filterType.filterType
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getheatMapTableInfo,
		getCurrentUnit,
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HeatMapTable));