import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import { getNumberOfHoldingCycles } from '../../actions/reportManagement/numberOfHoldingCyclesActions';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { UNIT_BRAND_ID, NO_OF_HOLDING_CYCLES_WIDGET_ID, FRYMASTER_UHCTHD_NO_OF_HOLDING_CYCLES_WIDGET_ID } from '../../utils/appConstants';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../locale/constant';

import KCStore from '../../utils/kcStorage';
// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;

class NumberOfHoldingCycles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            locationInformation: {},
            deviceInformation: {},
            sd: '',
            ed: '',
            startDate: '',
            endDate: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            datapickflag: false
        }
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_NO_OF_HOLDING_CYCLES_WIDGET_ID : NO_OF_HOLDING_CYCLES_WIDGET_ID;
        this.props.getNumberOfHoldingCycles("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
    }

    componentDidUpdate = async(prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleNumberOfCycle(this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStart(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //start Date Change Handler
    handleChangeStart = (noOfHoldingDate) => {
        if(this.state.startDate === noOfHoldingDate){
            return;
        }
        this.setState({
            startDate: noOfHoldingDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleNumberOfCycle(selectedtext);
        });
    }

    //end Date Change Handler
    handleChangeEnd(noOfHoldingDate) {
        if(this.state.endDate === noOfHoldingDate){
            return;
        }
        this.setState({
            endDate: noOfHoldingDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleNumberOfCycle(selectedtext);
        });
    }

    //dropdown Handler
    onDropDownHandler(e) {
        const { nodeText:text } = e;
        this.setState({ type: text, datapickflag: text === 'Date Range', startDate: '', endDate: ''})
        this.handleNumberOfCycle(text);
    }

    handleNumberOfCycle(text) {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }

        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_NO_OF_HOLDING_CYCLES_WIDGET_ID : NO_OF_HOLDING_CYCLES_WIDGET_ID;
            this.props.getNumberOfHoldingCycles(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
        }
    }

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (KCStore.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

    render() {
        const { noOfHoldingCyclesInfo } = this.props;
        const noOfHoldingCyclesInformation = noOfHoldingCyclesInfo.noOfHoldingCyclesResponse ? noOfHoldingCyclesInfo.noOfHoldingCyclesResponse : {};
        let noOfHoldingCycleModel = noOfHoldingCyclesInformation.content;
        let commonFilterConsumptionValue = null;
        let commonFilterPriorValue = null;
        const isTrend = _.isUndefined(noOfHoldingCycleModel) ? false : _.isObject(_.find(noOfHoldingCycleModel, el => el.key === 'trend'));

        let noOfHoldingCyclesWidget = null;
        if (Array.isArray(noOfHoldingCycleModel) && noOfHoldingCycleModel.length) {
            commonFilterConsumptionValue = (<div className="valH1Div"> {this.returnNumberFormat(Math.round(noOfHoldingCycleModel[0].value) )} {(isTrend && this.state.type !== "Date Range") && (<span className={noOfHoldingCycleModel[2].value === '1' ? "valH1Span valHigh" : noOfHoldingCycleModel[2].value === '0' ? "valH1Span valLow" : "valH1Span"}> &nbsp;</span>)}</div>);
            commonFilterPriorValue = (isTrend && this.state.type !== "Date Range") && (<div className="valH5Div">
                {mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }: {this.returnNumberFormat(Math.round(noOfHoldingCycleModel[1].value))}
            </div>)

            noOfHoldingCyclesWidget = (
                <div className="colmDiVal">
                    { commonFilterConsumptionValue}
                    { commonFilterPriorValue}
                </div>
            )
        }
        // Merco Average Holding Cycles Widget
        let numberOfHoldingCyclesWidget = null;
        numberOfHoldingCyclesWidget = (<div className="colmDi">
            <h4><FormattedMessage id="KC0365"/>
                    <span className="cornerIcon"></span>
                <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.type} />
            </h4>
            {noOfHoldingCyclesWidget}

        </div>)

        return (
            <React.Fragment>
                <div className="colm6D colm6D1">
                    {numberOfHoldingCyclesWidget}
                </div>
            </React.Fragment>
        )
    }
};
function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        noOfHoldingCyclesInfo: state.noOfHoldingCyclesResponse,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrentUnit, getCurrentLocation, getNumberOfHoldingCycles }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberOfHoldingCycles);
