import React from 'react';

const ConnectionFailedMsg = () => {
    
    return (
        <div>
            <h2 className="connectionFailed">Connection Failed</h2>
            <div className="connectivitySrlBar mCustomScrollbar">
                <div className="connectivitySubList">
                    <ul>
                        <li>Verify that the Device is switched on and connected to the Internet (see user manual on how to connect your Device).</li>
                        <li>Verify that the Device has the latest software version.</li>
                        <li>If the Device is connected to the Internet through a business network, verify with IT Department that the communication of the Device with KitchenConnect is not blocked (see user manual for technical details on URLs and Ports to be whitelisted).</li>
                        <li>Try to connect the Device to the Internet using your mobile device as a Hotspot. If the connection is successful, the local network may be blocking the communication of the Device to KitchenConnect, inform your IT Department (see user manual for technical details on URLs and Ports to be whitelisted).</li>
                        <li>In some occasions, temporary disconnections can happen due to network issues. If you have verified all the points above please try again later to confirm again.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ConnectionFailedMsg;