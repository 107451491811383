import { SET_CURRENT_UNIT, SET_CURRENT_LOCATION } from '../organizationentity/type';
import { SET_CONFIGURATION_FILE } from './type';
import { getUnitDetails, getConfigurationDetails } from '../../middleware/dashboardRenderer/dashboardRenderer';
import { SetLocationImage } from '../../utils/locationResponseBuilder';


export const setUnit = (unit) => {
    return dispatch => {
        if (unit != null) {
            let dynastyAttributes = unit.dynastyAttributes ? JSON.parse(unit.dynastyAttributes) : {};
            if (dynastyAttributes != null) {
                dynastyAttributes = SetLocationImage(dynastyAttributes);
                dispatch(setLocation(dynastyAttributes))
            }
        }
        dispatch({
            type: SET_CURRENT_UNIT,
            payload: unit
        })
    }
}

export const setLocation = location => ({
    type: SET_CURRENT_LOCATION,
    payload: location
});

export const getUnit = (serialNumber) => {
    return dispatch => {
        dispatch(setUnit({}));
        return new Promise((resolve, rej) => {
            getUnitDetails(serialNumber).then((res) => {
                dispatch(setUnit(res.units));
                dispatch(setLocation(res.location))
                resolve(res);
            })
        })
    }
}


export const setConfigFile = (file) => {
    return dispatch => {
        dispatch({
            type: SET_CONFIGURATION_FILE,
            payload: file
        })
    }
}

export const getConfigFile = (unitDetails) => {
    return dispatch => {
        return new Promise((resolve,rej)=>{
            getConfigurationDetails(unitDetails).then((res) => {
                if(res?.data){
                    dispatch(setConfigFile(res.data));
                }
                resolve(res);
            }).catch((err)=>{
                rej(err);
            })
        })
    }
}