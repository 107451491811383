import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../layoutSection/userManagementHeader'
import $ from 'jquery';


class unAuthorized extends Component {

	componentDidMount() {
		$("body").removeClass("loginBg");
		$("body").removeClass("modal-open");
		$("body").addClass("mainMenuBg");
		document.body.style.backgroundColor = "#042546";
	}

	render() {
		return (
			<div id="wrapper">
				<UserManagementHeader headerName="Un-Authorized" activeClass="UserManagementNav" />
				<div id="page-wrapper">
					<div class="underConstruction">
						<div class="underConstructionImg"></div>
						<h3>You are not authorized to view this page.</h3>
						<div class="clear"></div>
					</div>
				</div>
			</div>
		)
	}

};

export default unAuthorized;
