import React, { Component } from 'react';
import Modal from "react-responsive-modal";
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';

export class ProductListPopup extends Component {
  constructor(props){
    super(props);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    state = {
        open: false
    };

    componentDidUpdate() {
      this.setModalMaxHeight();
    }

    setModalMaxHeight() {
      this.$element     = $('.frypotInfoModal');  
      this.$content     = this.$element.find('.frypotInfoModal .modal-content');
      var borderWidth   = this.$content.outerHeight() - this.$content.innerHeight();
      var dialogMargin  = $(window).width() < 768 ? 20 : 60;
      var contentHeight = $(window).height() - (dialogMargin + borderWidth);
      var headerHeight  = this.$element.find('.frypotInfoModal .modal-header').outerHeight() || 0; 
      var maxHeight     = contentHeight - ((headerHeight) + 70);

      this.$content.css({
          'overflow': 'hidden'
      });
      
      this.$element
        .find('.frpotInfoScrollBarOuter').css({
          'max-height': maxHeight,
          'overflow-y': 'auto'
      });
    }

    onCloseModal = (e) => {
      this.props.onCloseModal();
    };

    render() {  
      const { open } = this.props;
      return (  
      <Modal open={open} showCloseIcon={false} center onClose={()=>{}}>
      <div className="modal fade frypotInfoModal show" data-keyboard="true" data-backdrop="static" id="TotalCooksCompletedModal" tabindex="-1" role="dialog" aria-labelledby="TotalCooksCompletedModalLabel" style={{ display: "block", paddingRight: "12px" }}>
        <div className="modal-dialog modal-md frypotInfoModal">
        <div className="modal-content">  
				<div className="modal-header modal-header-danger">
        {this.props.productList ? this.props.productList["Key"] : "Frypot"} <button type="button" className="close" data-dismiss="modal" onClick={this.onCloseModal}>&nbsp;</button> 
                </div>
                <div className="modal-body">   
                  <div className="frpotInfoTable">
                    <ul className="tHead">
                      <li>#</li>
                      <li><FormattedMessage id="KC0170"/></li>
                      <li><FormattedMessage id="KC1793"/></li> 
                    </ul> 
                    </div>
                    <div class="frpotInfoScrollBarOuter">
					          <div class="frpotInfoTable">
                    {Object.keys(this.props.productList ? this.props.productList : {}).map((item, i) => {
                    if (item !== "Key" && item !== "Value" && item !== "ActualValue") {
                      return (
                        <ul className="tBody">
                          <li>{i}</li>
                          <li>{item}</li>
                          <li>{this.props.productList[item]}</li>
                        </ul>
                      )
                    }
                  })}
                  </div>
                </div> 
            </div>
        </div>
    </div>
    </div>
    </Modal>
    );  
    }  
  }  

  export default ProductListPopup;
