import { SET_SMART_TAG_DETAILS, SET_SMART_TAG_DETAILS_LOADING,SMART_TAG_FAIL } from '../../../actions/stateActionsData/type';
const initialState = {
    smartTagList:[],
    isLoading: false
}
export default function(state = initialState, action){
    switch(action.type) {
        case SET_SMART_TAG_DETAILS_LOADING: 
        return {
            ...state,
            isLoading: action.payload
        }
        case SET_SMART_TAG_DETAILS:
        return {
            ...state,
            smartTagList: action.payload,
            isLoading: false
        }        
        case SMART_TAG_FAIL: 
        return {
            ...state,
            isLoading: action.payload,
            smartTagList:[]
        }        
        default:
        return state;
    }
}