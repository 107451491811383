export const GET_DATA ="GET_DATA";
export const SET_DATA ="SET_DATA";
export const MODEL_FAIL = 'MODEL_FAIL';
export const SET_SMART_UNIT_DETAILS = 'SET_SMART_UNIT_DETAILS';
export const SET_SMART_UNIT_DETAILS_LOADING = 'SET_SMART_UNIT_DETAILS_LOADING';
export const SET_UPDATE_HISTORY_DETAILS = 'SET_UPDATE_HISTORY_DETAILS';
export const SET_UPDATE_HISTORY_DETAILS_LOADING = 'SET_UPDATE_HISTORY_DETAILS_LOADING';
export const SET_SMART_GROUP_DELETE_UNIT = 'SET_SMART_GROUP_DELETE_UNIT';
export const SET_REPORT_DELETE = "SET_REPORT_DELETE";
export const SET_REPORT_DELETE_LOADING = "SET_REPORT_DELETE_LOADING";
export const SET_ADD_NEW_MENU_LIBRARY = "SET_ADD_NEW_MENU_LIBRARY";
export const SET_ADD_NEW_MENU_LIBRARY_LOADING = "SET_ADD_NEW_MENU_LIBRARY_LOADING";
export const GET_ADD_MENU_LIST = "GET_ADD_MENU_LIST";
export const GET_ADD_MENU_LIST_LOADING = "GET_ADD_MENU_LIST_LOADING";
export const SET_MENU_LIST = "SET_MENU_LIST";
export const SET_MENU_LIST_LOADING = "SET_MENU_LIST_LOADING";
export const SET_CREATE_PACKAGE_LIST = "SET_CREATE_PACKAGE_LIST";
export const SET_CREATE_PACKAGE_LIST_LOADING = "SET_CREATE_PACKAGE_LIST_LOADING";

// type for global search
export const SEARCH = "SEARCH";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const GET_SEARCH_DATA = "GET_SEARCH_DATA";
export const GET_SEARCH_DATA_LOADING = "GET_SEARCH_DATA_LOADING";
export const SEARCH_VISIBLE = "SEARCH_VISIBLE";
export const GET_SEARCH_API = "GET_SEARCH_API";
export const SET_USERMANAGEMENT_DATA = "SET_USERMANAGEMENT_DATA";
export const SET_USERMANAGEMENT_DATA_LOADING = "SET_USERMANAGEMENT_DATA_LOADING";
export const SEARCH_BY_SELECT_CATEGORY = "SEARCH_BY_SELECT_CATEGORY";
export const GET_SEARCH_BY_CATEGORY = "GET_SEARCH_BY_CATEGORY";
export const SET_USERMANAGEMENT_ROLE_DATA = "SET_USERMANAGEMENT_ROLE_DATA";
export const SET_USERMANAGEMENT_ROLE_DATA_LOADING = "SET_USERMANAGEMENT_ROLE_DATA_LOADING";

export const SET_SMART_TAG_DETAILS = "SET_SMART_TAG_DETAILS";
export const SET_SMART_TAG_DETAILS_LOADING = "SET_SMART_TAG_DETAILS_LOADING";
export const SMART_TAG_FAIL = "SMART_TAG_FAIL";
export const CLEAR_GLOBAL_SEARCH = "CLEAR_GLOBAL_SEARCH";