import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import KCStore from '../../utils/kcStorage';

function OfflinePopup({ show, unitId, timeFormated }) {
    const [offline, setOffline] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [time, setLastConnectionTime] = React.useState(' N/A ');

    const intl = useIntl();

    React.useEffect(() => {
        setOffline(show)
        setLastConnectionTime(timeFormated)
        if (show) {
            KCStore.setItem('showOfflinePopup', false)
        }
    }, [show, timeFormated])

    const closePopup = () => {
        setOffline(false);
    };

    const handleChange = (e) => {
        setChecked(e.target.checked);
        let getOfflinePopupDisabledUnits = KCStore.getItem('offlineDisabledUnits') ? JSON.parse(KCStore.getItem('offlineDisabledUnits')) : [];
        const filterUnitId = getOfflinePopupDisabledUnits.filter((i) => i === unitId);

        if (filterUnitId.length) {
            if (e.target.checked === false) {
                //splice
                const index = getOfflinePopupDisabledUnits.findIndex((i) => i === unitId);
                getOfflinePopupDisabledUnits.splice(index, 1)
            }
        } else {
            if (e.target.checked === true) {
                getOfflinePopupDisabledUnits.push(unitId);
            }
        }
        KCStore.setItem('offlineDisabledUnits', JSON.stringify(getOfflinePopupDisabledUnits))
    };
    return (
        !offline || offline === false ? null : <div className="kc-offline-popup-main">
        <div className="kc-offline-popup-popup">
                <div className="custom-cancel-div">
                    <img className='custom-cancel-image' onClick={closePopup} src={require("../../images/icons/cancel.png")} alt="Cancel_button" /><br></br>
                </div>
                <div className="kc-offline-popup-right_side">
                    <img className="kc-wifi-image" src={require("../../images/icons/wifiOffline.svg").default} alt="nosignal" /><br></br><br></br>
                    <b><FormattedMessage id='KC2413' /></b>   
                </div>
                <div className="data-display"><p><FormattedMessage id='KC2414' values={{ time }} /></p></div>  
        </div>
    </div>
    );
};

export default OfflinePopup;
