import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import Switch from "react-switch";
import { injectIntl,FormattedMessage } from 'react-intl';
import $ from 'jquery';
import KCStore from '../../../../../utils/kcStorage';

class SettingsModes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            mode: "",
            locked: "",
            energy_save_enabled: "",
            favorites_enabled: "",
            all_recipes_enabled: ""
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onModesSelect = this.onModesSelect.bind(this);
        this.onLockTypeSelect = this.onLockTypeSelect.bind(this);
        this.onEnergySaveSelect = this.onEnergySaveSelect.bind(this);
        this.onShowFavouritesSelect = this.onShowFavouritesSelect.bind(this);
        this.onShowAllRecipesSelect = this.onShowAllRecipesSelect.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let jsonModes = JSON.parse(jsonSettingsFile);
            this.setState({
                mode: jsonModes.mode,
                locked: jsonModes.locked,
                energy_save_enabled: jsonModes.energy_save,
                favorites_enabled: jsonModes.favorites_enabled,
                all_recipes_enabled: jsonModes.all_recipes_enabled
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/settingsModes"
        });
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true
        });
        let { mode, locked, energy_save_enabled, favorites_enabled, all_recipes_enabled } = this.state;
        let jsonSettingsFile = KCStore.getItem("librarySettingsData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        ObjSettingsFile.mode = mode;
        ObjSettingsFile.locked = locked.toString();
        ObjSettingsFile.energy_save = energy_save_enabled.toString();
        ObjSettingsFile.favorites_enabled = favorites_enabled.toString();
        ObjSettingsFile.all_recipes_enabled = all_recipes_enabled.toString();
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("librarySettingsData", strSettingsFile);
        this.props.history.push(`/settingsEditor`);
    }

    onModesSelect = (event) => {
        this.setState({
            mode: event.target.value
        });
    }

    onLockTypeSelect = (e) => {
        this.setState({
            locked: e
        });
    }

    onEnergySaveSelect = (e) => {
        this.setState({
            energy_save_enabled: e
        });
    }

    onShowFavouritesSelect = (e) => {
        this.setState({
            favorites_enabled: e
        });
    }

    onShowAllRecipesSelect = (e) => {
        this.setState({
            all_recipes_enabled: e
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    render() {
        let { mode, locked, energy_save_enabled, favorites_enabled, all_recipes_enabled, success_modal } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1268"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            <div class="rcSettingsList">
                                                                <ul>
                                                                    <li><img src={require("../../../../../images/recipecreation/fullServeMode.svg").default}  alt=""/></li>
                                                                    <li><FormattedMessage id="KC1279"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onModesSelect}>
                                                                            <input id="FullServeMode" type="radio" name="modeAll" value="fs" checked={mode === "fs" ? true : false} />
                                                                            <label for="FullServeMode" value="fs">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li><img src={require("../../../../../images/recipecreation/quickServeMode.svg").default}  alt=""/></li>
                                                                    <li><FormattedMessage id="KC1280"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onModesSelect}>
                                                                            <input id="QuickServeMode" type="radio" name="modeAll" value="qsr" checked={mode === "qsr" ? true : false} />
                                                                            <label for="QuickServeMode" value="qsr">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li><img src={require("../../../../../images/recipecreation/pressandgo.svg").default}  alt=""/></li>
                                                                    <li><FormattedMessage id="KC1281"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onModesSelect}>
                                                                            <input id="PressandGo" type="radio" name="modeAll" value="pg" checked={mode === "pg" ? true : false} />
                                                                            <label for="PressandGo" value="pg">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li><img src={require("../../../../../images/recipecreation/manual.svg").default}  alt=""/></li>
                                                                    <li><FormattedMessage id="KC1285"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onModesSelect}>
                                                                            <input id="Manual" type="radio" name="modeAll" value="manual" checked={mode === "manual" ? true : false} />
                                                                            <label for={formatMessage({ id: 'KC1285' })} value="manual">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcSettingsList">
                                                                <ul>
                                                                    <li><img src={require("../../../../../images/recipecreation/systemLocked.svg").default}  alt=""/></li>
                                                                    <li><FormattedMessage id="KC1284"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onLockTypeSelect(e)}
                                                                                checked={(locked === "true" || locked == true) ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />    
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcSettingsList">
                                                                <ul>
                                                                    <li><img src={require("../../../../../images/recipecreation/energysave.svg").default} alt="" /></li>
                                                                    <li><FormattedMessage id="KC2247" /></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onEnergySaveSelect(e)}
                                                                                checked={(energy_save_enabled === "true" || energy_save_enabled == true) ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />        
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class={(mode === "fs" || mode === "qsr") ? "rcSettingsList" : "displaynone"}>
                                                                <ul>
                                                                    <li><img src={require("../../../../../images/recipecreation/favourites.svg").default}  alt=""/></li>
                                                                    <li><FormattedMessage id="KC1282"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onShowFavouritesSelect(e)}
                                                                                checked={(favorites_enabled === "true" || favorites_enabled == true) ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />          
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li><img src={require("../../../../../images/recipecreation/allRecipes.png")}  alt=""/></li>
                                                                    <li><FormattedMessage id="KC1283"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onShowAllRecipesSelect(e)}
                                                                                checked={(all_recipes_enabled === "true" || all_recipes_enabled == true) ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />      
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn btn-default-text confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(SettingsModes)
