import en_US from './languages/en-US.json';
import { reactIntl } from './locale-provider';

export const LOCALES = {
    ENGLISH: 'en-US',
    CHINESE: 'zh-CN',
    SPANISH: 'es-ES',
    FRENCH: 'fr-FR',
};

export const DEFAULT_LOCALE = LOCALES.ENGLISH;
export const DEFAULT_MESSAGES = en_US;

export const LOCALE_IMG_MAP = {
    [LOCALES.ENGLISH]: 'ENG',
    [LOCALES.CHINESE]: '字',
    [LOCALES.SPANISH]: 'ESP',
    [LOCALES.FRENCH]: 'FRA',
};

// MLS Change
export const mappingStringID = {
    "prior month": "KC0160",
    "prior day": "KC0161",
    "prior week": "KC0162",
    "prior quarter": "KC0163",
    "prior year": "KC0164",
    "Date Range":"KC0308",
}

export const filterTypeOptionStringIDMap = {
    "Day": "KC0085",
    "Week": "KC0086",
    "Month": "KC0087",
    "Quarter": "KC0088",
    "Year": "KC0089",
    "Date Range" : "KC0090",
}

export const mappingStringTranslationID = {
    "Export": "KC1848",
    "Try Again": "KC1117",
    "N/A": "KC1664",
    "Awaiting connection": "KC1906",
}

export const filterBaseMappingStringID = {
    'Customer': 'KC0057',
    'Brand': 'KC0062',
    'Unit Type': 'KC0063',
    'Model Name': 'KC0064',
    'Smart Tag': 'KC2317',
}

const days = ['KC2086', 'KC2087', 'KC2088', 'KC2089', 'KC2090', 'KC2091', 'KC2092'];
const months = ['KC2047', 'KC2048', 'KC2049', 'KC2050', 'KC2051', 'KC2052', 'KC2053', 'KC2054', 'KC2055', 'KC2056', 'KC2057', 'KC2058'];
export const datePickerLocale = {
  localize: {
    day: n => reactIntl.formatMessage({ id: days[n] }),
    month: n => reactIntl.formatMessage({ id: months[n] })
  },
  formatLong: {}
};

export const timePeriodStringIDMap = {
    'AM': 'KC1406',
    'PM': 'KC1407'
};
