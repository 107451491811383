import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { getLocation } from '../../actions/unitManagement/locationAction';
import { getModelInfo } from '../../actions/unitManagement/ModelInfoAction';
import { getTotalDrinksList } from '../../actions/reportManagement/totalDrinksAction';
import { getAverageDrinksList } from '../../actions/reportManagement/averageDrinksAction';
import { UnitManagement_Label } from '../../appConfig';
import { getpeakHourChartInfo } from '../../actions/reportManagement/peakHourChartAction';
import { getTopTenTableInfo } from '../../actions/reportManagement/topTenTableActoin';
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import { getTotalErrorsCount } from '../../actions/serviceManagement/errorCountActions';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import authHelper from '../../utils/authHelper';
import _ from 'lodash';
import LocationInformationWidget from "../unitManagement/widget/locationinformationwidget";
import TimerDoubleTapsWidget from '../unitDashboard/mercoWidgets/timerDoubleTaps';
import TotalOnTimeWidget from '../unitDashboard/mercoWidgets/totalOnTime';
import DeviceDetailsWidget from '../unitManagement/widget/devicedetailswidget';
import DeviceStatusWidget from '../unitManagement/widget/devicestatuswidget';
import UnitBrandLogoImg from '../unitDashboard/globalWidgets/unitbrandlogoicon';
import UnitErrorStatus from '../unitDashboard/globalWidgets/uniterrorstatus';
import TotalCookCount from '../unitDashboard/merrychefWidgets/totalCookCount';
import AverageCookCount from '../unitDashboard/merrychefWidgets/averageCookCount';
import FilterCleanings from '../unitDashboard/merrychefWidgets/filterCleanings';
import PeakHourWidget from '../unitDashboard/globalWidgets/peakhourparentwidget';
import { getTimerDoubleTaps } from '../../actions/unitDashboard/timerDoubleTapsAction';
import SingleDataWidget from '../unitDashboard/globalWidgets/singledatawidget';
import TopTenRecipes from '../unitDashboard/globalWidgets/topTenRecipes';
import OverallPanUtilization from './mercoWidgets/overallPanUtilization';
import TotalHoldingCycles from '../unitDashboard/mercoWidgets/totalHoldingCycles';
import TimeLineChartWidget from '../unitDashboard/globalWidgets/timeLlnechartwidget';
import AverageTrays from '../unitDashboard/convothermWidgets/averageTrays';
import TotalTrayCount from '../unitDashboard/convothermWidgets/totalTrayCount';
import SingleDataTrendFilterWidget from '../unitDashboard/globalWidgets/singledatatrendfilterwidget';
import MostFrequentErrorsChart from '../unitDashboard/globalWidgets/mostFrequentErrorsChart';
import UHCTimeLinechartwidget from '../unitDashboard/globalWidgets/uhcTimeLinechartwidget';
import $ from 'jquery';
import { updateDimensions } from "../../utils/csvExUnitRedirect";
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import { FormattedMessage, injectIntl} from 'react-intl';
import KCStore from '../../utils/kcStorage';

require('../../images/unit/CremCarrera.png');
var moment = require('moment');

const Colors = ["#ff97cd", "#0083b3", "#7192f1", "#c10b6d", "#414548", "#eacd61", "#59a9ed", "#00eabf", "#ff3b84", "#e08e53"];
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class UnitDashboard extends Component {
    constructor(props) {
        super(props);
        var today = new Date(),
            months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        var date = (months[today.getMonth()]) + '  ' + today.getDate() + ' , ' + today.getFullYear();
        this.state = {
            error: null,
            date: date,
            topTenTable: [],
            topTenTableTotal: 0,
            topTenType: 'Month',
            peakHourType: 'Month',
            type: 'Month',
            avgType: 'Month',
            filterType: "Month",
            sortColumn: { path: "", order: "" },
            noResultMsg: 'No Results',
            IsServerError: false,
            error_modal: {
                open: false,
                message: ""
            },
            sd: '',
            ed: '',
            startDate: todaysDate,
            endDate: todaysDate,
            sdAvgDrinks: '',
            edAvgDrinks: '',
            startDateAvgDrinks: todaysDate,
            endDateAvgDrinks: todaysDate,
            sdPeakHour: '',
            edPeakHour: '',
            startDatePeakHour: todaysDate,
            endDatePeakHour: todaysDate,
            sdTopTen: '',
            edTopTen: '',
            startDateTopTen: todaysDate,
            endDateTopTen: todaysDate,
            topTenTableChanged: false,
            peakHourChanged: false,
            loaderIconVisible: true,
            checkUnitDashboard: true,
            datapickflag: false,
            globalDropdownFilter: 'Month',
            isGlobalDropdownChange: false,
            globalDropdownFilterStartDate: '',
            globalDropdownFilterEndDate: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
        };
        window.addEventListener("load", updateDimensions($));
        window.addEventListener("resize", updateDimensions($));
    }

    componentDidMount = async () => {
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        updateDimensions($)
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = KCStore.getItem("brandName");
        this.props.getpeakHourChartInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, "Unit");
        this.props.getTopTenTableInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, "Unit");
        this.props.getLocation();
        this.props.getModelInfo(unitID, brandID);
        this.props.getTotalDrinksList("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
        this.props.getAverageDrinksList("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
        this.props.getTotalErrorsCount(unitID, brandID, "ErrorCount");
        this.props.getCurrentUnit("currentUnit");
        this.props.getCurrentLocation("currentLocation");
        setTimeout(() => {
            this.setState({
                loaderIconVisible: false,
            });
        }, 2000)
    }

    handleChangeStart(date) {
        const { currentUnit } = this.props;
        this.setState({
            globalDropdownFilterStartDate: date,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText: selectedtext };
            if (currentUnit.BRAND_NAME === "CONVOTHERM") {
                this.refs.totalCleaningConvotherm.handleChangeStart(date);
            } else if (currentUnit.BRAND_NAME === "FRYMASTER") {
                this.refs.singleDataTrendWidget.handleChangeStart(date);
                this.refs.mostFrequenmtErrorWidget.handleChangeStart(date);
                this.refs.quickFilterCompailance.handleChangeStart(date);
                this.refs.cleanFilterCompailance.handleChangeStart(date);
            } else if (currentUnit.BRAND_NAME === "GARLAND") {
                this.refs.garlandUnitUtilization.handleChangeStart(date);
            }
            this.onDropDownHandler(e);
        });
    }
    handleChangeEnd(date) {
        const { currentUnit } = this.props;
        this.setState({
            globalDropdownFilterEndDate: date,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText: selectedtext };
            if (currentUnit.BRAND_NAME === "CONVOTHERM") {
                this.refs.totalCleaningConvotherm.handleChangeEnd(date);
            } else if (currentUnit.BRAND_NAME === "FRYMASTER") {
                this.refs.singleDataTrendWidget.handleChangeEnd(date);
                this.refs.mostFrequenmtErrorWidget.handleChangeEnd(date);
                this.refs.quickFilterCompailance.handleChangeEnd(date);
                this.refs.cleanFilterCompailance.handleChangeEnd(date);
            } else if (currentUnit.BRAND_NAME === "GARLAND") {
                this.refs.garlandUnitUtilization.handleChangeEnd(date);
            }
            this.onDropDownHandler(e);
        });
    }
    onDropDownHandler(e) {
        const { currentUnit } = this.props;
        const filterType = e.nodeText; 
        
        this.setState({
            globalDropdownFilter: filterType,
            isGlobalDropdownChange: true,
            datapickflag: filterType === 'Date Range'
        });
        if (currentUnit.BRAND_NAME === "CONVOTHERM") {
            this.refs.totalCleaningConvotherm.fetchData(this.refs.totalCleaningConvotherm.state.holder_id, filterType)
            this.refs.totalCleaningConvotherm.setState({ filterType, datapickflag: filterType === 'Date Range' })
        } else if (currentUnit.BRAND_NAME === "FRYMASTER") {
            this.refs.singleDataTrendWidget.fetchData(this.refs.singleDataTrendWidget.state.holder_id, filterType)
            this.refs.singleDataTrendWidget.setState({ filterType, datapickflag: filterType === 'Date Range' });
            this.refs.mostFrequenmtErrorWidget.fetchData(this.refs.mostFrequenmtErrorWidget.state.holder_id, filterType)
            this.refs.mostFrequenmtErrorWidget.setState({ filterType, datapickflag: filterType === 'Date Range' });
            this.refs.quickFilterCompailance.fetchData(this.refs.quickFilterCompailance.state.holder_id, filterType)
            this.refs.quickFilterCompailance.setState({ filterType, datapickflag: filterType === 'Date Range' });
            this.refs.cleanFilterCompailance.fetchData(this.refs.cleanFilterCompailance.state.holder_id, filterType)
            this.refs.cleanFilterCompailance.setState({ filterType, datapickflag: filterType === 'Date Range' });
        } else if (currentUnit.BRAND_NAME === "MERCO") {
            if (filterType === "Day" || filterType === "Week") {
                this.refs.mercoPanStateTimline.handlePanTime(filterType);
                this.refs.mercoPanStateTimline.setState({ PanType: filterType });
            }
        } else if (currentUnit.BRAND_NAME === "GARLAND") {
            this.refs.garlandUnitUtilization.fetchData(this.refs.garlandUnitUtilization.state.holder_id, filterType);
            this.refs.garlandUnitUtilization.setState({ filterType, datapickflag: filterType === 'Date Range' });
        } else if (currentUnit.BRAND_NAME === "FRYMASTER-UHCTHD") {
            if(filterType === 'Day' || filterType === 'Week'){
                this.refs.frymasterUhcthdSlotStateTime.onChangePanvsTime(filterType);
                this.refs.frymasterUhcthdSlotStateTime.setState({ PanType: filterType, datapickflag: filterType === 'Date Range' });
            }
        }
    }

    onSort = sortColumn => {
        this.setState({
            sortColumn: sortColumn
        });
    }

    errorCloseModel = () => {
        authHelper.deleteLocalSession();
        this.props.history.push('/');
    }

    findPeakHour = (data) => {
        let maxTotalCount = 0;
        let peakHour = 0;

        let chartData = data && data.length > 0 ? data : [];

        //Find maximum count
        for (let index = 0; index < chartData.length; index++) {
            if (maxTotalCount < chartData[index].totalcount) {
                maxTotalCount = chartData[index].totalcount;
            }
        }

        if (maxTotalCount) {
            for (let index = 0; index < chartData.length; index++) {

                if (chartData[index].totalcount === maxTotalCount) {
                    chartData[index].fillOpacity = 1;
                    chartData[index].color = "#0FABFF";
                    peakHour = chartData[index].hours;
                } else {
                    chartData[index].fillOpacity = 1;
                    chartData[index].color = "#1A709C";
                }
            }
        }

        return parseInt(peakHour);
    }

    render() {
        const { currentUnit } = this.props;
        const { formatMessage } = this.props.intl;
        let unitInformation = currentUnit;
        let { sortColumn, loaderIconVisible, IsServerError, isGlobalDropdownChange, globalDropdownFilter, globalDropdownFilterEndDate, globalDropdownFilterStartDate } = this.state;
        let orderId = 1;
        const { deviceInformation } = this.props.deviceInfo;

        const { totalDrinksInformation, errormsgTotalDrinks } = this.props.totalDrinksInfo;
        let totalProductsTrend = this.props.totalDrinksInfo.totalDrinksInformation.trend;
        const { averageDrinksInformation, errormsgAvgDrinks } = this.props.averageDrinksInfo;
        let avgProductsTrend = this.props.averageDrinksInfo.averageDrinksInformation.trend;
        const { errormsgPeakHour } = this.props.getpeakHourChartInfo;

        let refeshTime = format(new Date(), 'hh:mm');
        let refeshTime24hrs = format(new Date(), 'HH');

        let topTenTableTotal = 0;
        let topTenTable = [];
        const { peakHourChartInfo, topTenTableInfo, errormsgTopTenTable } = this.props;

        let _peakHour = 0;
        if (peakHourChartInfo.length > 0) {
            _peakHour = this.findPeakHour(peakHourChartInfo);
            this.setState({
                peakHour: _peakHour
            })
        }

        let { topTenTableInformation } = topTenTableInfo;
        if (currentUnit.BRAND_NAME === "MERRYCHEF") {
            topTenTableInformation = topTenTableInfo.topTenTableInformation.result;
        }
        if (currentUnit.BRAND_NAME !== "FRYMASTER") {
            if (topTenTableInformation !== "{}") {
                if (Array.isArray(topTenTableInformation) && topTenTableInformation.length > 0) {
                    topTenTableTotal = topTenTableInformation.reduce((prev, cur) => prev + cur.counts, 0);
                    topTenTable = topTenTableInformation;

                    if (topTenTable && topTenTable.length > 0) {
                        topTenTable.map((item, i) => { item.color = Colors[i] });
                    }
                }
            }
        }

        var topTencountDiv;
        var totalsoldDiv;
        let newNumberFormat =  <NumberFormat value={topTenTableTotal} displayType={'text'} thousandSeparator={true} />
        if (KCStore.getItem("thousandSeparator") === "."){
        newNumberFormat =  <NumberFormat value={topTenTableTotal} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        if (topTenTableTotal > 0) {
            topTencountDiv = <div className="piechartvalueText">{newNumberFormat} <span className="drinksText"/* trendimg"*/>drinks</span></div>
            totalsoldDiv = <div className="totalsoldtext"> total sold </div>
        }
        else {
            topTencountDiv = <div className="piechartvalueText">   <span className="drinksText"/* trendimg"*/></span></div>
            totalsoldDiv = <div className="totalsoldtext"> </div>
        }


        var i = 0;
        var strCamelValueForBrandType = "";
        var strCamelValueForProdName = "";
        var strCamelValueForBrandName = "";
        var splitStr = "";
        let deviceOnline = 'Online', deviceOffline = 'Offline',
            deviceOnlineStatus = 'divStatusWithIcon online',
            deviceOfflineStatus = 'divStatusWithIcon offline', errordateValue = this.state.date;

        if (unitInformation.PRODUCT_NUMBER !== undefined) {
            strCamelValueForBrandType = unitInformation.PRODUCT_NUMBER
            splitStr = strCamelValueForBrandType.toLowerCase().split(' ');
            for (i = 0; i < splitStr.length; i++) {
                if (splitStr[i].length > 0) {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                }
            }
            strCamelValueForBrandType = splitStr.join(' ');
        }

        if (KCStore.getItem("brandName") === "MERCO" || KCStore.getItem("brandName") === "FRYMASTER-UHCTHD") {
            strCamelValueForBrandType = unitInformation.PRODUCT_NUMBER
        }


        if (unitInformation.PRODUCT_NAME !== undefined) {
            strCamelValueForProdName = unitInformation.PRODUCT_NAME;
            if (currentUnit.BRAND_NAME === "MERRYCHEF") {
                strCamelValueForProdName = strCamelValueForProdName.toLowerCase();
            }
            else if (currentUnit.BRAND_NAME === "CONVOTHERM") {
                strCamelValueForProdName = strCamelValueForProdName.replace('C4', '4');
            }
            else {
                splitStr = strCamelValueForProdName.toLowerCase().split(' ');
                for (i = 0; i < splitStr.length; i++) {
                    if (splitStr[i].length > 0) {
                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                    }
                }
                strCamelValueForProdName = splitStr.join(' ');
            }
        }

        if (unitInformation.BRAND_NAME !== undefined) {
            strCamelValueForBrandName = unitInformation.BRAND_NAME
            splitStr = strCamelValueForBrandName.toLowerCase().split(' ');
            for (i = 0; i < splitStr.length; i++) {
                if (splitStr[i].length > 0) {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                }
            }
            strCamelValueForBrandName = splitStr.join(' ');
        }

        let columns = [
            { path: "", label: "#" },
            { path: "", label: "", isNbSp: true },
            { path: "productID", label: "Product" },
            { path: "trend", label: "Trend" },
            { path: "counts", label: "Count" },
        ];

        topTenTable = _.orderBy(topTenTable, [sortColumn.path], [sortColumn.order]);

        topTenTable.forEach(item => {
            item.order_id = orderId++
        });

        IsServerError = errormsgTotalDrinks || errormsgAvgDrinks || errormsgPeakHour || errormsgTopTenTable;

        //MERCO On Time widget display
        let OnTimeWidget = null;

        if (currentUnit.BRAND_NAME === 'MERCO' || KCStore.getItem("brandName") === "FRYMASTER-UHCTHD") { // (OnTimeWidget) is declared for ontime widgets in merco and peak hour for merco and merrychef

            OnTimeWidget = (
                <TotalOnTimeWidget page={"Dashboard"} cycleRatesOnTimeInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
            )
        }
        //CREM Peak Hour widget display
        else if (currentUnit.BRAND_NAME === 'CREM') {
            OnTimeWidget = (

                <PeakHourWidget peakHourData={this.props} brandType="crem" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
            )
        }
        //CONVOTHERM Peak Hour widget display
        else if (currentUnit.BRAND_NAME === 'CONVOTHERM') {
            OnTimeWidget = (

                <PeakHourWidget peakHourData={this.props} brandType="convotherm" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
            )
        }
        //FRYMASTER Peak Hour widget display
        else if (currentUnit.BRAND_NAME === 'FRYMASTER') {
            OnTimeWidget = (

                <PeakHourWidget peakHourData={this.props} brandType="frymaster" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} deviceInformation={deviceInformation} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
            )
        }
        //Garland Peak Hour widget display
        else if (currentUnit.BRAND_NAME === 'GARLAND') {
            OnTimeWidget = (

                <PeakHourWidget peakHourData={this.props} brandType="garland" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
            )
        }
        else {
            OnTimeWidget = (

                <PeakHourWidget peakHourData={this.props} brandType="merrycef" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
            )
        }
        let showTop10Recipes = null;
        if (KCStore.getItem("brandName") === "MERRYCHEF" || currentUnit.BRAND_NAME === "CONVOTHERM" || currentUnit.BRAND_NAME === "GARLAND") {
            showTop10Recipes = (<TopTenRecipes currentUnit={this.props} widgetname={currentUnit.BRAND_NAME === "CONVOTHERM" ? "Top 10 Recipes" : "Top 10 Products"} widgetnameStringID={currentUnit.BRAND_NAME === "CONVOTHERM" ? "KC0398" : "KC0313"} measure={""} caption={currentUnit.BRAND_NAME === "CONVOTHERM" ? "" : currentUnit.BRAND_NAME === "GARLAND" ? "Total Cooked" : "Total produced"} captionStringID={currentUnit.BRAND_NAME === "CONVOTHERM" ? "" : currentUnit.BRAND_NAME === "GARLAND" ? "Total Cooked" : "KC0265"} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} ></TopTenRecipes>); /* MSL:TODO */
        }
        else if (KCStore.getItem("brandName") === "CREM") {
            // CREM Expobar Model(EX3 DISPLAY & DIAMANT PRO DISPLAY) widget header name changes in unit dashboard screen
            let widgetHeadername = (currentUnit.MODEL_NAME === "EX3" || currentUnit.MODEL_NAME === "DIAMANT PRO") ? "Group Production Distribution" : "Top 10 Products Sold"
            let widgetHeadernameStringID = (currentUnit.MODEL_NAME === "EX3" || currentUnit.MODEL_NAME === "DIAMANT PRO") ? "KC1792" : "KC0168"
            KCStore.setItem("expobarModelName", currentUnit.MODEL_NAME);
            showTop10Recipes = (<TopTenRecipes currentUnit={this.props} widgetname={widgetHeadername} widgetnameStringID={widgetHeadernameStringID} measure={"drinks"} measureStringID="KC0173" caption={"total sold"} captionStringID="KC0174" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}></TopTenRecipes>);
        }


        //MERCO Total Holding Cycles Widget
        let totalHoldingCyclesWidget = null;
        if (currentUnit.BRAND_NAME === 'MERCO' || KCStore.getItem("brandName") === "FRYMASTER-UHCTHD") {
            totalHoldingCyclesWidget = (<TotalHoldingCycles currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />);
        }
        else if (currentUnit.BRAND_NAME === "CONVOTHERM") {
            totalHoldingCyclesWidget = (<TotalTrayCount currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />);
        }
        else {
            
            totalHoldingCyclesWidget = (<TotalCookCount widgetname={
                (KCStore.getItem("brandName") === "MERRYCHEF") ? "TOTAL COOK COUNT" :
                    (KCStore.getItem("brandName") === "FRYMASTER") ? "TOTAL COOKS COMPLETED" :
                        (KCStore.getItem("brandName") === "MANITOWOC ICE") ? "BIN FULL COUNT" :
                            (KCStore.getItem("brandName") === "DELFIELD") ? "DOOR OPENINGS" :
                                (KCStore.getItem("brandName") === "GARLAND") ? "TOTAL COOKS COMPLETED" :
                                    (KCStore.getItem("brandName") === "MULTIPLEX") ? "TOTAL PRODUCTS DISPENSED" :
                                        (KCStore.getItem("brandName") === "LINCOLN") ? "SYSTEM ON TIME" :
                                            "TOTAL PRODUCTS PRODUCED"}
                                            /* MLS CHANGES */
                                            widgetnameStringID={
                                                (KCStore.getItem("brandName") === "MERRYCHEF") ? "KC0257" :
                                                    (KCStore.getItem("brandName") === "FRYMASTER") ? "KC0430" :
                                                        (KCStore.getItem("brandName") === "MANITOWOC ICE") ? "KC1903" :
                                                            (KCStore.getItem("brandName") === "DELFIELD") ? "KC0260" :
                                                                (KCStore.getItem("brandName") === "GARLAND") ? "KC0430" :
                                                                    (KCStore.getItem("brandName") === "MULTIPLEX") ? "KC1902" :
                                                                        (KCStore.getItem("brandName") === "LINCOLN") ? "KC1901" :
                                                                            "KC0159"} ProductsProduced={this.props} classValue={
                                                (KCStore.getItem("brandName") === "MANITOWOC ICE" ||
                                                    KCStore.getItem("brandName") === "DELFIELD" ||
                                                    KCStore.getItem("brandName") === "MULTIPLEX" ||
                                                    KCStore.getItem("brandName") === "LINCOLN") ? "colm4D disabled" : "colm4D"} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />);
        }

        //MERCO Over All Pan Utilization Widget
        let panUtilizationWidget = null;
        if (currentUnit.BRAND_NAME === 'MERCO' || KCStore.getItem("brandName") === "FRYMASTER-UHCTHD") {
            panUtilizationWidget = (<OverallPanUtilization currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />);
        }
        else if (currentUnit.BRAND_NAME === "CONVOTHERM") {
            panUtilizationWidget = (<AverageTrays currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />);
        }
        else if (currentUnit.BRAND_NAME === "FRYMASTER") {
            panUtilizationWidget = (<SingleDataWidget properties={this.props} widgetID="60" widgetName="OIL LIFE / NO. OF DISPOSES" widgetNameStringID="KC0431" classValue="colm4D colm4D3" />);
        }
        else {
            panUtilizationWidget = (<AverageCookCount widgetname={
                (KCStore.getItem("brandName") === "MERRYCHEF") ? "AVG. COOKS" :
                    (KCStore.getItem("brandName") === "MANITOWOC ICE") ? "ICE PRODUCTION" :
                        (KCStore.getItem("brandName") === "DELFIELD") ? "AVG. ENERGY USE" :
                            (KCStore.getItem("brandName") === "GARLAND") ? "TOTAL PLATEN CYCLES" :
                                (KCStore.getItem("brandName") === "MULTIPLEX") ? "NOTIFICATIONS" :
                                    (KCStore.getItem("brandName") === "LINCOLN") ? "UPPER BLOWER RUN TIME" :
                                        "AVG. PRODUCT COUNT PER DAY"}
                                        widgetnameStringID={
                                            (KCStore.getItem("brandName") === "MERRYCHEF") ? "KC0258" :
                                                (KCStore.getItem("brandName") === "MANITOWOC ICE") ? "KC1904" :
                                                    (KCStore.getItem("brandName") === "DELFIELD") ? "KC1911" :
                                                        (KCStore.getItem("brandName") === "GARLAND") ? "KC0326" :
                                                            (KCStore.getItem("brandName") === "MULTIPLEX") ? "KC0624" :
                                                                (KCStore.getItem("brandName") === "LINCOLN") ? "KC1905" :
                                                                    "KC0988"} ProductsProduced={this.props} classValue={
                                            (KCStore.getItem("brandName") === "MANITOWOC ICE" ||
                                                KCStore.getItem("brandName") === "DELFIELD" ||
                                                KCStore.getItem("brandName") === "MULTIPLEX" ||
                                                KCStore.getItem("brandName") === "LINCOLN") ? "colm4D colm4D3 disabled" : "colm4D colm4D3"} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />);
        }

        let frymasLvlFourAvgOilWidget = null;
        if (currentUnit.BRAND_NAME === 'FRYMASTER') {
            frymasLvlFourAvgOilWidget = (
                <SingleDataTrendFilterWidget ref="singleDataTrendWidget" properties={this.props} widgetID="59" widgetName="Avg. Oil Life (Days)" widgetNameStringID="KC0436" classValue="colm4D colm4D3" isTimeDisplay="false" isHolder="true" isDecimal={true} />
            )
        }
        /*Frymaster TLOV/JLOV model changes */
        let filtrationWidgetsName = 'QUICK FILTER';
        let cleanFilterWidgetName = 'CLEANING';/*USER STORY 43745 widget label change */
        let cleanFilterLastPerfrm = 'CLEAN & FILTER';
        let hotColdWidgetName = 'HOT / COLD';
        // MLS Change
        let hotColdWidgetNameStringID = 'KC0437';
        let cleanFilterLastPerfrmStringID = 'KC0440';
        let filtrationWidgetsNameStringID = 'KC0432';
        let cleanFilterWidgetNameStringID = 'KC0435'; //DAILY CLEANING COMPLIANCE
        let quickFilterWidgetNameStringID = 'KC0433'

        const productName = _.upperCase(currentUnit.MODEL_FAMILY_NAME);
        if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
            filtrationWidgetsName = 'AUTO FILTER';
            cleanFilterWidgetName = 'MAINTENANCE';
            cleanFilterLastPerfrm = 'MAINTENANCE';
            hotColdWidgetName = 'DEEP';
            // MLS Change
            hotColdWidgetNameStringID = 'KC0469';
            cleanFilterLastPerfrmStringID = 'KC0492';
            filtrationWidgetsNameStringID = 'KC0490'; // AUTO FILTER COMPLIANCE
            cleanFilterWidgetNameStringID = 'KC0491'; // DAILY MAINTENANCE COMPLIANCE
            quickFilterWidgetNameStringID = 'KC0489';
        }

        return (
            <React.Fragment>
                <div id="wrapper">
                    <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                        <div className="loadingProgressIn"></div>
                    </div>
                    <GlobalHeaderLayout activeClass="dashboardNav" />
                    <div id="page-content-wrapper">

                        <div className="pageHeader">
                            <ul>
                                <li>
                                    <Link to="/unitdashboard" title={formatMessage({id: 'KC0175'})} className="active"> <FormattedMessage id="KC0149"/></Link>
                                </li>
                                <li class="filterDropdown">
                                    <p class="globalFilterText"><FormattedMessage id='KC0097'/></p>
                                    <NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.globalDropdownFilter} />
                                </li>
                            </ul>
                        </div>
                        {/* Level 1 Widgets - 3 widget | Unit & Brand Logo | Device Details | Device Status */}
                        <div className="gridViewTable">
                            <div className="gridView selectedUnitBg">
                                <UnitBrandLogoImg unitlogodata={this.props.currentUnit} />
                                <DeviceDetailsWidget unitDashboradFlag={this.state.checkUnitDashboard} currentUnit={this.props} currentLocation={this.props} />
                                <DeviceStatusWidget currentUnit={this.props} currentLocation={this.props} />
                            </div>
                        </div>
                        {/* Level 2 Widgets - 3 widget | Unit Error Status | 2nd widget | 3rd widget */}
                        <div className="gridViewTable gridViewcolm4DSM">
                            <div className="gridView">
                                <UnitErrorStatus UnitErrorStatusData={this.props.errorsCountInfo} ErrorsCountInformation={this.props.errorsCountInfo} />
                                {totalHoldingCyclesWidget}
                                {panUtilizationWidget}
                            </div>
                        </div>
                        {/* Level 3 Widgets - 3 widget | 1st widget | 2nd widget | 3rd widget */}
                        <div className="gridViewTable gridViewcolm4DSM">
                            <div className="gridView">
                                {OnTimeWidget}
                                {(KCStore.getItem("brandName") === "MERCO" || KCStore.getItem("brandName") === "FRYMASTER-UHCTHD") && <div className="colm4D colm4D2">
                                    <TimerDoubleTapsWidget page={"Dashboard"} doubleTapsInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
                                </div>}
                                {KCStore.getItem("brandName") === "MERRYCHEF" &&
                                    <FilterCleanings widgetname="FILTER CLEANINGS" widgetnameStringID="KC0259" ProductsProduced={this.props} classValue="colm4D colm4D2" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
                                }
                                {KCStore.getItem("brandName") === "FRYMASTER" &&
                                    <FilterCleanings widgetname={`TOTAL ${filtrationWidgetsName}S COMPLETED`} widgetnameStringID={filtrationWidgetsNameStringID} ProductsProduced={this.props} classValue="colm4D colm4D2" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
                                }
                                {KCStore.getItem("brandName") === "CONVOTHERM" &&
                                    <SingleDataTrendFilterWidget ref="totalCleaningConvotherm" properties={this.props} widgetID="33" widgetName="TOTAL CLEANINGS" widgetNameStringID="KC0394" classValue="colm4D colm4D2" isTimeDisplay="true" />
                                }
                                {KCStore.getItem("brandName") === "GARLAND" &&
                                    <SingleDataTrendFilterWidget ref="garlandUnitUtilization" properties={this.props} widgetID="88" widgetName="UNIT UTILIZATION" widgetNameStringID="KC0310" classValue="colm4D colm4D2" symbol="%" />
                                }
                                {KCStore.getItem("brandName") === "CREM" && <div className="colm4D colm4D2 disabled"><div className="colmDi"><h4><FormattedMessage id="KC0166"/></h4></div>
                                </div>}
                                <div className={KCStore.getItem("brandName") === "CREM" ? "colm4D colm4D3 disabled" : "colm4D colm4D3"}>
                                    <LocationInformationWidget page={"Dashboard"} currentUnit={this.props} currentLocation={this.props} />
                                </div>
                            </div>
                        </div>
                        {/* Level 4 Widgets - 3 widget | 1st widget | 2nd widget | 3rd widget */}
                        {KCStore.getItem("brandName") === "MERRYCHEF" && <div className="gridViewTable gridViewcolm4DSM">
                            <div class="gridView">
                                <SingleDataWidget properties={this.props} widgetID="12" widgetName="DOOR OPENINGS" widgetNameStringID="KC0260" classValue="colm4D colm4D1" />
                                <SingleDataWidget properties={this.props} widgetID="13" widgetName="OVEN ON TIME"  widgetNameStringID="KC0261" classValue="colm4D" />
                                <SingleDataWidget properties={this.props} widgetID="14" widgetName="HEATER ON TIME" widgetNameStringID="KC0262" classValue="colm4D colm4D3" />
                            </div>
                        </div>
                        }
                        {KCStore.getItem("brandName") === "CONVOTHERM" && <div class="gridViewTable gridViewcolm4DSM">
                            <div class="gridView">
                                <TotalOnTimeWidget page={"Dashboard"} widgetID="115" widgetName="TOTAL ON TIME" cycleRatesOnTimeInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
                                <SingleDataWidget properties={this.props} widgetID="35" widgetName="OVEN TEMPERATURE" widgetNameStringID="KC0396" classValue="colm4D" />
                                <SingleDataWidget properties={this.props} widgetID="36" widgetName="CORE TEMPERATURE" widgetNameStringID="KC0397" classValue="colm4D colm4D3" />
                            </div>
                        </div>
                        }
                        {KCStore.getItem("brandName") === "FRYMASTER" && <div class="gridViewTable gridViewcolm4DSM">
                            <div class="gridView">
                                <SingleDataTrendFilterWidget ref="quickFilterCompailance" properties={this.props} widgetID="55" widgetName={`${filtrationWidgetsName} COMPLIANCE`} widgetNameStringID={quickFilterWidgetNameStringID} classValue="colm4D colm4D1" symbol="%" isClassification="true" globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
                                <SingleDataTrendFilterWidget ref="cleanFilterCompailance" properties={this.props} widgetID="73" widgetName={`DAILY ${cleanFilterWidgetName} COMPLIANCE`} widgetNameStringID={cleanFilterWidgetNameStringID} classValue="colm4D colm4D2" symbol="%" isClassification="true" globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
                                {frymasLvlFourAvgOilWidget}
                            </div>
                        </div>
                        }
                        {/* Level 5 Widgets - 1 widget | Top Ten Chart/TimeLine Chart | HOT/COLD CLEAN/ */}
                        {KCStore.getItem("brandName") === "FRYMASTER" ? <div class="gridViewTable gridViewcolm4DSM">
                            <div class="gridView">
                                <SingleDataWidget properties={this.props} widgetID="56" widgetName={`${hotColdWidgetName} CLEAN`} widgetNameStringID={hotColdWidgetNameStringID} classValue="colm4D colm4D1" />
                                <SingleDataWidget properties={this.props} widgetID="57" widgetName="OQS FILTER" widgetNameStringID="KC0439" classValue="colm4D" />
                                <SingleDataWidget properties={this.props} widgetID="58" widgetName={`${cleanFilterLastPerfrm}`} widgetNameStringID={cleanFilterLastPerfrmStringID} classValue="colm4D colm4D3" />
                            </div>
                        </div> :
                            <div className="gridViewTable">
                                <div className="gridView">
                                    <div className="colm12D">
                                        {showTop10Recipes}
                                        {KCStore.getItem("brandName") === "MERCO" && <div className="colmDi">
                                            <TimeLineChartWidget ref="mercoPanStateTimline" widgetName="PAN STATES VS TIME" widgetNameStringID="KC0357" currentUnit={this.props.currentUnit} />
                                        </div>}
                                        {KCStore.getItem("brandName") === "FRYMASTER-UHCTHD" && <div className="colmDi">
                                            <UHCTimeLinechartwidget ref="frymasterUhcthdSlotStateTime" widgetName="SLOT STATES VS TIME" widgetNameStringID="KC1915" currentUnit={this.props.currentUnit} /> 
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Level 6 Widgets - 1 widget | Most Frequent Critical / Non Critical Error(s) Chart | HOT/COLD CLEAN/ */}
                        <div className="gridViewTable">
                            <div className="gridView">
                                <div className="colm12D">
                                    {KCStore.getItem("brandName") === "FRYMASTER" &&
                                        <MostFrequentErrorsChart ref="mostFrequenmtErrorWidget" properties={this.props} widgetID="18" widgetName="Most Frequent Errors" widgetNameStringID="KC0441" chartTitle="" disableDatePicker={false} chartTitleEnable={false} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};

function mapStateToProps(state) {
    return {
        unitdashboardInfo: state.unitdashboardInformation,
        unitdashboardproductInfo: state.unitdashboardProductInformation,
        unitdashboardProductInformationdata: {},
        locationInfo: state.locationInformation,
        deviceInfo: state.deviceInformation,
        totalDrinksInfo: state.totalDrinksInformation,
        averageDrinksInfo: state.averageDrinksInformation,
        peakHourChartInfo: state.peakHourChartInformation,
        topTenTableInfo: state.topTenTableInformation,
        currentAppEntity: state.entityReducer.commonentity,
        errorsCountInfo: state.ErrorsCountInformation,
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        doubleTapsInfo: state.timerDoubleTapsResponse,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getLocation, getModelInfo, getTotalDrinksList, getAverageDrinksList, getpeakHourChartInfo, getTopTenTableInfo, getTotalErrorsCount, getCurrentLocation, getCurrentUnit, getTimerDoubleTaps }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UnitDashboard));
