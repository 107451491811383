import {OVERALL_PAN_UTILIZATION_REQUEST,OVERALL_PAN_UTILIZATION_SUCCESS,OVERALL_PAN_UTILIZATION_FAIL} from '../../../actions/unitDashboard/overAllPanUtilizationAction';
const initialState = {
    loaded: false,
    loading: false,
    overAllPanUtilizationResponse: {}
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case OVERALL_PAN_UTILIZATION_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case OVERALL_PAN_UTILIZATION_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                overAllPanUtilizationResponse: action.overAllPanUtilizationResponse
            }
        case OVERALL_PAN_UTILIZATION_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                overAllPanUtilizationResponse: {},
                errormsgOverAllPanUtilization: 'Server Error',

            }
        default:
            return state
    }
}