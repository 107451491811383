import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as _ from 'lodash';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import NewCustomDragAndDrop from '../unitRegisterAndAssignment/NewCustomDragAndDrop';
import { smartTagMangement } from '../../../service/api';
import http from '../../../service/httpService';
import { BRAND_ID } from '../../../utils/appConstants';
import ErrorMoal from '../../modal/error-model';
import authService from '../../../service/authService';
import { connect } from 'react-redux';
import KCStore from '../../../utils/kcStorage';

const CreateUnitSTag = (props) => {
	const { formatMessage } = props.intl;
	const [loaderIconVisible, setLoaderIconVisible] = useState(false);
	const [tagName, setTagName] = useState("");
	const [tagDescription, setTagDescription] = useState("");
	const [nameAlreadyExist, setNameAlreadyExist] = useState(true);
	const [enableWarningMsg, setEnableWarningMsg] = useState(false);
	const [files, setFiles] = useState({});
	const [fileValue, setFileValue] = useState({});
	const [S3Url, setS3Url] = useState('#');
	const [errorMessage, setErrorMessage] = useState("");
	const [userCheckedData, setUserCheckedData] = useState({});
	const [getCurrentList, setCurrentList] = useState([]);
	const [mode, setMode] = useState("");
	const [showConfirmationPage, setShowConfirmationPage] = useState("");
	const [error_modal, setError_modal] = useState({ open: false, message: "" });
	const [smartTagDetails, setSmartTagDetails] = useState(JSON.parse(KCStore.getItem("smartTagInfo")));

	const updateDimensions = () => {
		var windowHeight = $(window).height();
		var userMgmtHeader = $(".userMgmtHeader").height();
		var userMgmtListTableH = $(".userMgmtListTable > .tHead").height();
		var pageWrapperP = $("#page-content-wrapper").innerHeight();
		var userMgmtTableSrlBarH = ((windowHeight) - (userMgmtHeader + userMgmtListTableH)) - (pageWrapperP + 5);
		$(".userMgmtTableSrlBar").css("height", userMgmtTableSrlBarH);
	}

	useEffect(() => {
		document.body.style.backgroundColor = "#F2F2F2";
		$("div").removeClass("modal-backdrop fade show");
		window.addEventListener('resize', updateDimensions);
		updateDimensions();
		getLocalStorageValue();
		if(props && props.location && props.location.state && props.location.state.availableTagList) {
            setCurrentList(props.location.state.availableTagList)
        }
		if (props && props.location && props.location.state && props.location.state.userCheckedData) {
			setUserCheckedData(props.location.state.userCheckedData)
		}
		$('[data-toggle="popover"]').popover({
            trigger: 'hover',
            'placement': 'top'
        });
	}, []);

	const getLocalStorageValue = () => {
		let smartTagInfo = JSON.parse(KCStore.getItem("smartTagInfo"));
		setMode(smartTagInfo && smartTagInfo.mode ? smartTagInfo.mode : "");
		if (smartTagInfo.unitTagDetails) {
			setTagName(smartTagInfo.unitTagDetails.TagName);
			setTagDescription(smartTagInfo.unitTagDetails.TagDescription);
			setNameAlreadyExist(false);
			if(smartTagInfo.mode !== "edit"){
				checkTagName(smartTagInfo.unitTagDetails.TagName);
			}
		}
	}

	const setTagDetails = (isUnit = true) => {
		let smartTagInfo = JSON.parse(KCStore.getItem("smartTagInfo"));
		if (isUnit) {
			smartTagInfo.unitTagDetails = {}
		}
		smartTagInfo.unitTagDetails['TagName'] = tagName;
		smartTagInfo.unitTagDetails['TagDescription'] = tagDescription;
		KCStore.setItem('smartTagInfo', JSON.stringify(smartTagInfo));
		if (isUnit) {
			removeFile();
		} else {
			onProceedClick();
		}
	}

	const onProceedClick = async () =>{
		$('.validatingFile').show();
		let tagDetails = JSON.parse(KCStore.getItem("smartTagInfo"));
		let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		let userName = KCStore.getItem("userName");
		let value = tagDetails.unitTagDetails;
		let postdata = {
			"fileName": value.fileName,
			"tagName": value.TagName,
			"customerId": + KCStore.getItem("customerId"),
			"cgid": KCStore.getItem("custGroupID"),
			"registrationFlag": true,
			"brandId": BRAND_ID,
			"tagType": "UNIT",
			"type": tagDetails.mode === "add" ? "create" : "edit",
		};
		if (tagDetails && tagDetails.mode === "edit") {
			postdata.smartTagId = tagDetails && tagDetails.selectedRow && tagDetails.selectedRow.id
		}
		let url  = smartTagMangement.smartTagBulkUpload;
		let method = "post";
		await http[method](url, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {
				$('.validatingFile').hide();
				setShowConfirmationPage(response.data.data);
				props.history.push({
					pathname: "/smartTag/smartTagConfirmation",
					state: {
						dataList: response.data.data,
						modeDetails: mode ? mode : "",
					},					
				});
			}
		}).catch(err => {
			setError_modal({ open: true, message: "KC2636" });
			$('.validatingFile').hide();
		});
	}

	const checkTagName = (val) => {
        let alreadyExist = getCurrentList && getCurrentList.filter(c => c.tagName.toLowerCase() === val.toLowerCase());
        setNameAlreadyExist(alreadyExist.length > 0 ? true : false);
        setEnableWarningMsg(alreadyExist.length > 0 ? true : false);
    }

	const typeTagName = (e) => {
		let val = e.target.value;
		val = val.replace(/[&\/\\#,+$~%.'":*?<>{}`!@^=|;\[\]]/g, '');
		if(val && (!val.trim() || val.length > 15)){
			return;
		}
		if(smartTagDetails.mode==='edit'){
			let existingTagDetails =  smartTagDetails && smartTagDetails.selectedRow ? smartTagDetails && smartTagDetails.selectedRow : {};
			if (existingTagDetails.editable === false){
				return;
			}
			setTagName(val);
			if (existingTagDetails.tagName === val.trim()){
				return;
			}
		} else {
			setTagName(val);
		}
		setEnableWarningMsg(false);
		setNameAlreadyExist(true);		
		checkTagName(val);		
	}

	const typeTagDescription = (e) => {
        let val = e.target.value;
        if(val && (!val.trim() || val.length > 250)){            
            return;
        }
        if (smartTagDetails && smartTagDetails.selectedRow  && smartTagDetails.selectedRow.editable === false){
            return;
        }
        setTagDescription(val);
    }

	const downloadUnitList = () => {
		setLoaderIconVisible(true);
		let smartTagInfo = JSON.parse(KCStore.getItem("smartTagInfo"));
		smartTagInfo = smartTagInfo && smartTagInfo.selectedRow ? smartTagInfo.selectedRow : "";

		let url = smartTagMangement.getPresignedUrl;
		let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
		if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
			url = url + "?registrationFlag=yes&isView=yes";
		} else {
			url = url + "?registrationFlag=no&isView=yes";
		}
		if (mode === 'edit') {
			url = url + `&tagName=${smartTagInfo.tagName}`;
		}
		
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'customerid': KCStore.getItem("customerId"),
				'cgid': KCStore.getItem("custGroupID"),
				'brandid': BRAND_ID,
			}, data: {}
		}).then(async response => {
			if (response && response.data && response.data.data) {
				setLoaderIconVisible(false);
				if (response.data.preSignedUrl !== '#') {
					window.location = response.data.data.preSignedUrl;
					setS3Url(response.data.data.preSignedUrl)
				}
				$('.loadingProgress').removeAttr('style')
			}
		}).catch(err => {
			setLoaderIconVisible(false);
		});
	}

	const validateFile = async (files) => {
		let fileDet = files[0];
		let fileName = files[0].name;
		fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
		let fileextSplit = fileName.split('.');
		let fileext = fileextSplit[fileextSplit.length - 1];
		fileext = fileext.toLowerCase();
		let fileList = [];
		let message = "";
		if ((fileext.toLowerCase() === "xlsx" || fileext.toLowerCase() === "xls")) {
			fileList.push(files[0])
			getBase64(files[0], (result) => {
				setFiles(fileList);
				setFileValue(result);
			});
			let response = {};
			if (fileName) {
				response = await getFileUploadLink(fileName);
			}

			var xhr = new XMLHttpRequest();
			xhr.open("PUT", response.preSignedUrl);
			xhr.setRequestHeader('Content-Type', 'text/xlsx');
			xhr.onloadend = (res) => {
				if (res.target.status == 200) {
					setLoaderIconVisible(false)
				}
			};
			xhr.onerror = (err) => {
				setLoaderIconVisible(false)
			};
			xhr.send(fileDet);
		} else {
			message = "KC2283";
		}

		if (message !== "") {
			setLoaderIconVisible(false);
			setErrorMessage('Please use a file with the right format.');
		}
	}

	const getFileUploadLink = (fileName) => {
		return new Promise(async (res, reject) => {
			let url = smartTagMangement.getPresignedUrl;
			url = url + `?registrationFlag=yes&isView=no&fileName=` + fileName;

			await http({
				method: 'get',
				url,
				headers: {
					'Content-Type': 'application/json',
					'customerid': KCStore.getItem("customerId"),
					'cgid': KCStore.getItem("custGroupID"),
					'brandid': BRAND_ID,
				}, data: {}
			}).then(async response => {
				if (response && response.data && response.data.data) {
					let obj = JSON.parse(KCStore.getItem("smartTagInfo"));
					obj.unitTagDetails = {
						fileName: response.data.data.key
					}
					KCStore.setItem("smartTagInfo", JSON.stringify(obj))
					res(response.data.data);
				}
			}).catch(err => {
				setLoaderIconVisible(false);
				res({});

			});
		})
	}

	const handleDrop = async (files) => {
		if (tagName && !nameAlreadyExist) {
			setLoaderIconVisible(true);
			await validateFile(files);
		}
	}

	const UploadmenuFile = async (e) => {
		if (e.target.files[0] !== null && tagName && !nameAlreadyExist) {
			setLoaderIconVisible(true);
			await validateFile(e.target.files);
		}		
		$("#browseUpdate").val('');
	}

	const removeFile = () => {
		setLoaderIconVisible(true);
		setFiles({});
		setFileValue({});
		setLoaderIconVisible(false);
		$("#browseUpdate").val('');
	}

	const getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	const closeModel = () => {
		setError_modal({ open: false });
	}

	let validate = false;
	validate = tagName && !nameAlreadyExist;
	return (
		<div id="adminWrapper">
			<div className="toggleTransparent"></div>
			<div className="sideNavToggleTransparent"></div>
			<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
				<div className="loadingProgressIn"></div>
			</div>
			<div className="validatingFile"><span> <FormattedMessage id="KC2635" /></span></div>
			<div id="page-content-wrapper">
				<div className="menuUpdateWrapper">
					<div className="adminBgHeader">
						<ul>
							<li>
								<Link to="/smartTag/tagList" title={formatMessage({id:'KC0989'})} className="backIcon">&nbsp;</Link>
							</li>
							<li>
								<FormattedMessage id={mode === 'edit' ? "KC2333" : "KC2309"} />
							</li>
							<li>&nbsp;</li>
						</ul>
					</div>
					<div className="formWrapper">
						<div className="formWrapperL">
							<div className="formList">
								<ul>
									<li><label className={smartTagDetails && smartTagDetails.selectedRow && !smartTagDetails.selectedRow.editable ? "stPermissionsD" :""} data-toggle="popover" title={smartTagDetails.mode === 'add' || (smartTagDetails.selectedRow && smartTagDetails.selectedRow.editable) ? "" : formatMessage({id: 'KC2633'})} data-content={smartTagDetails.mode === 'add' || (smartTagDetails.selectedRow && smartTagDetails.selectedRow.editable) ? "" : formatMessage({id: 'KC2914'})}><FormattedMessage id="KC2319" /> *</label></li>
									<li>
										<input maxLength={15} onChange={(e) => typeTagName(e)} value={tagName} type="text" className="form-control" placeholder={formatMessage({id:'KC0597'})}/>
										{enableWarningMsg ?
											<div className="errorMsg"> <FormattedMessage id="KC2315" /></div> :
											<div className="errorMsg">&nbsp;</div>
										}
									</li>
								</ul>
							</div>
						</div>
						<div className="formWrapperR">
							<div className="formList">
								<ul>
                                    <li><label className={smartTagDetails && smartTagDetails.selectedRow && !smartTagDetails.selectedRow.editable ? "stPermissionsD" :""} data-toggle="popover" title={smartTagDetails.mode === 'add' || (smartTagDetails.selectedRow && smartTagDetails.selectedRow.editable) ? "" : formatMessage({id: 'KC2633'})} data-content={smartTagDetails.mode === 'add' || (smartTagDetails.selectedRow && smartTagDetails.selectedRow.editable) ? "" : formatMessage({id: 'KC2914'})}><FormattedMessage id="KC2321" /></label></li>
                                    <li>
                                        <textarea maxLength={250} onChange={(e) => typeTagDescription(e)} value={tagDescription} className="form-control" rows="2" cols="50" placeholder={formatMessage({id:'KC0560'})}></textarea>
                                    </li>
                                </ul>
							</div>
						</div>
					</div>
						<div className="STUplaodForm">
							<ul>
								<li>
									<Link to={{ pathname: "/smartTag/smartTagTopology", userCheckedData: userCheckedData || {}, from: "Unit" }} onClick={(e) => setTagDetails(e)} className={validate ? "getOrgList" : "getOrgList disabled"}><FormattedMessage id={mode === 'edit' ? "KC2368" :"KC2367"} /></Link>
									<Link to={{ pathname: "/smartTag/smartTagTopology", userCheckedData: userCheckedData || {}, from: "Unit" }} onClick={(e) => setTagDetails(e)} className={validate ? "btn selectBtn" : "btn selectBtn disabled"}><FormattedMessage id={mode === 'edit' ? "KC1166" :"KC0711"} /></Link>
								</li>
								<li>
									<span className="selectORdrag"><FormattedMessage id="KC2373" /></span>
								</li> 
								<li>
								<a href="javascript:void(0)" title={formatMessage({ id: "KC2366"})} onClick={downloadUnitList} className="getListUnits"> <span><FormattedMessage id="KC2377" /></span>&nbsp;<FormattedMessage id="KC2378" /></a>
								 
								{files.length > 0 ? <>
									<div className="dragAndBrowse">
										<ul>
											<li>
												{files.map((file, i) =>
													<a href="javascript:void(0)" className="drogAndDrop">{file.name} <button className="btn withClear" onClick={removeFile} type="button">&nbsp;</button></a>
												)}
											</li>
											<li>
												<Link to={"#"} onClick={() => setTagDetails(false)} className="proceedFile" ><FormattedMessage id="KC2328" /></Link>
											</li>
										</ul>
									</div>
								</> : <>
									{errorMessage && errorMessage !== "" && <div className="errorMsg alignmentCenter">{errorMessage}</div>}
									<div className="dragAndBrowse">
										<ul>
											<li className={!validate ? 'disabled' : ''}>
												<NewCustomDragAndDrop handleDrop={handleDrop}>
													<a className="drogAndDrop"><FormattedMessage id='KC2329' /></a>
												</NewCustomDragAndDrop>
											</li>
											<li>
												<a href="javascript:void(0)" onClick={() => $('#browseUpdate').trigger('click')} className={`browseFile ${!validate ? 'disabled' : ''}`}><FormattedMessage id='KC0664' /></a>
												<input type="file" accept=".xls,.xlsx" className="browseFileS" id="browseUpdate" onChange={UploadmenuFile} />
											</li>
										</ul>
									</div>
								</>}
								</li>
							</ul>
						</div>
					
				</div>
			</div>
			<UserManagementHeader headerName={formatMessage({ id: 'KC2318' })} headerNameStringID="KC2318" activeClass="smartTagNav" />
			<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={closeModel} />
		</div>
	)
}

const mapStateToProps = state => {
    return {
        allSTagList: state.smartTag.smartTagList
    };
}
export default injectIntl(connect(mapStateToProps)(CreateUnitSTag))