import { UnitInfo } from '../api';
import http from '../httpService';

export const getDeviceAPIUrl = (unitId, brandId) => {
    let apiUrl = `${UnitInfo.root}${unitId}/${brandId}/device-info`;
    return apiUrl;
}

export const getDeviceInfo = async(url) => {
    let response ={};
    try{
       let result =  await http.get(url,
        {
          headers: {
            'content-type': 'application/json',
            'filterparams' : 'spaceDetails'
          }, data:{}
        });
       response = result.data;
       return response;
    }
    catch(err){
        return response;
    }
}

