


import React, { Component } from 'react';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class FrymasterLanguageSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            languageSelection: 'English'
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        const { title = 'Primary' } = this.props.history.location.state;
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            if (ObjSettingsFile.general.language.primary && title === "Primary Language") {
                this.setState({
                    languageSelection: ObjSettingsFile.general.language.primary
                })
            }
            else if (ObjSettingsFile.general.language.secondary && title === "Secondary Language") {
                this.setState({
                    languageSelection: ObjSettingsFile.general.language.secondary
                })
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        const { title = 'Primary' } = this.props.history.location.state;

        this.setState({
            loaderIconVisible: true,
        });
        let { languageSelection } = this.state;
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.general.language) {
            ObjSettingsFile.general.language = {};
        }
        ObjSettingsFile.general.language.primary = title === "Primary Language" ? languageSelection : ObjSettingsFile.general.language.primary;
        ObjSettingsFile.general.language.secondary = title === "Secondary Language" ? languageSelection : ObjSettingsFile.general.language.secondary;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterLanguage`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSettingsClick = (pathname) => {
        this.props.history.push({
            pathname,
            isEdit: false,
            type: "Settings",
            previouspath: "/fryMasterSettings"
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterLanguage`);
    }

    onChangeLocale = (e) => {
        this.setState({
            languageSelection: e.target.value
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = KCStore.getItem("selectedbrandID");
        const { title = 'Primary' } = this.props.history.location.state;
        const { languageSelection } = this.state;

        const languages = [
            {
                value: 'English',
                id: 'English'
            },
            {
                value: 'Arabic العربية',
                id: 'Arabic'
            },
            {
                value: 'Bulgarian Български',
                id: 'Bulgarian'
            },
            {
                value: 'Chinese Mandarin 中文 普通話',
                id: 'Chinese Mandarin'
            }, {
                value: 'Croatian hrvatski',
                id: 'Croatian'
            },
            {
                value: 'Czech Čeština',
                id: 'Czech'
            }, {
                value: 'Danish dansk',
                id: 'Danish'
            },
            {
                value: 'Dutch Netherlands',
                id: 'Dutch'
            }, {
                value: 'Finnish suomi',
                id: 'Finnish'
            },
            {
                value: 'French Français',
                id: 'French'
            }, {
                value: 'French Canadian Français Canadien',
                id: 'French Canadian'
            },
            {
                value: 'German Deutsch',
                id: 'German'
            }, {
                value: 'Greek Ελληνικά',
                id: 'Greek'
            },
            {
                value: 'Hebrew עברית',
                id: 'Hebrew'
            }, {
                value: 'Hungarian magyar',
                id: 'Hungarian'
            },
            {
                value: 'Italian Italiano',
                id: 'Italian'
            }, {
                value: 'Japanese 日本語',
                id: 'Japanese'
            },
            {
                value: 'Korean 한국어',
                id: 'Korean'
            }, {
                value: 'Norwegian Norsk',
                id: 'Norwegian'
            },
            {
                value: 'Polish Polski',
                id: 'Polish'
            }, {
                value: 'Portuguese Portugués',
                id: 'Portuguese'
            },
            {
                value: 'Russian русский',
                id: 'Russian'
            }, {
                value: 'Serbian Српски',
                id: 'Serbian'
            },
            {
                value: 'Slovakian Slovenský',
                id: 'Slovakian'
            }, {
                value: 'Slovene Slovenski',
                id: 'Slovene'
            },
            {
                value: 'Spanish Español',
                id: 'Spanish'
            }, {
                value: 'Swedish Svenska',
                id: 'Swedish'
            },
            {
                value: 'Turkish Türkçe',
                id: 'Turkish'
            }, {
                value: 'Vietnamese Viét',
                id: 'Vietnamese'
            }

        ]


        return (
            <React.Fragment>
                {/* Frymaster Language Selection Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Language Selection Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Language Selection Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> {title}
                                </div>

                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="textWRadioBtnList">
                                                    {languages.map((language, index) => {
                                                        return (
                                                            <ul key={index}>
                                                                <li>{language.value}</li>
                                                                <li>
                                                                    <div class="customRadioBtn">
                                                                        <input id={language.id} type="radio" name="PrimaryLanguage" value={language.id} checked={languageSelection === language.id} onChange={(e) => this.onChangeLocale(e)} />
                                                                        <label for={language.id}>&nbsp;</label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Language Selection Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterLanguageSelection)
