import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { useSnackbar } from 'react-simple-snackbar'
import GoToPagination from '../controls/goToPagination';
import { headerRow } from './plan';
import { SoftwareManagementAPI, subscriptionManagement, unitAPI } from '../../service/api';
import http from '../../service/httpService';
import { BRAND_ID } from '../../utils/appConstants';
import _ from 'lodash';
import { brandLogo } from '../../utils/imgContants';
import KCStore from '../../utils/kcStorage';

const formDaysInMonth = (startValue, endValue) => {
    let data = [];
    for (let index = Number(startValue); index <= endValue; index++) {
        data.push(index)
    }
    return data.splice(0, 30)
}

const formNextMonthDays = (remainingDaysInMonth, nextMonthDays) => {
    let data = [];
    for (let index = 0; index < nextMonthDays - remainingDaysInMonth; index++) {
        data.push(index + 1)
    }
    return data
}

export const snackBarOption = {
    position: 'bottom-right',
    style: {
        backgroundColor: 'white',
        color: '#253465',
        fontSize: '18px',
        height: '60px',
        fontWeight: 'bold',
        marginBottom: '5%'
    },
    closeStyle: {
        color: '#253465',
        fontSize: '16px',
    },
}



const Dashboard = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(true);
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [dashboardData, setDashboardData] = useState({});
    const [brand, setBrand] = useState('');
    const [brandList, setBrandList] = useState([]);
    const [model, setModel] = useState('');
    const [modelList, setModelList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(null);
    const [invoiceData, setInvoiceData] = useState([]);
    const [openSnackbar, closeSnackbar] = useSnackbar(snackBarOption);
    const [initialPageLoad, setInitialPageload] = useState(false);

    const invSnackBar = KCStore.getItem('invoiceSnackbar') ? KCStore.getItem('invoiceSnackbar') : false;
    const currentDateVal = new Date();
    const nextMonthStartDifference = (moment(currentDateVal).diff(moment(moment(currentDateVal).add(1, 'months')).startOf('month'), 'days')) + 1;
    const currentMonth = (moment(currentDateVal).month()) + 1;

    const currentMonthDays = moment(currentDateVal).daysInMonth();
    const todayDate = moment(currentDateVal).format('DD');
    const currentMonthName = moment(currentDateVal).format('MMM');
    const currentYearName = moment(currentDateVal).format('YYYY');
    const timeLineDays = 30;

    const nextMonth = (moment(currentDateVal).add(1, 'months').month()) + 1;
    const nextMonthDays = moment(currentDateVal).add(1, 'months').daysInMonth();
    const nextMonthName = moment(currentDateVal).add(1, 'months').format('MMM');
    const nextYearName = moment(currentDateVal).add(1, 'months').format('YYYY');


    const remainingDaysInMonth = (currentMonthDays - todayDate) + 1;
    const daysInThisMonth = remainingDaysInMonth === 1 ? [todayDate] : formDaysInMonth(todayDate, currentMonthDays);
    const nextMonthDay = formNextMonthDays(remainingDaysInMonth, timeLineDays);

    let currentMonthEndingUnit = React.useRef([]);
    let nextMonthEndingUnit = React.useRef([]);

    useEffect(() => {
        $("div").removeClass("modal-backdrop fade show");
        document.body.style.backgroundColor = "#F2F2F2";
        loadData();
        return () => {
            if (closeSnackbar) {
                closeSnackbar();
                if(invoiceData.length > 1){
                    KCStore.setItem('invoiceSnackbar', true)
                }
            }
        }
    }, []);

    const loadData = async () => {
        await getBrand();
        if (!invSnackBar) {
            await getInvoiceData();
        }
        await getDashboardData()
        await getSubscriptionData(1)
    }

    const getInvoiceData = async () => {
        setLoaderIconVisible(true);
        var date = new Date();
        let url = subscriptionManagement.getInvoice;
        url = url + `?customerId=${KCStore.getItem("customerId")}&pageNum=1&pageSize=10&startDate=${date}&endDate=${date}`;

        await http.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                if (response.data?.invoices?.length) {
                    setInvoiceData(response.data.invoices)
                    if (openSnackbar) {
                        for (let index = 0; index < response.data?.invoices.length; index++) {
                            const element = response.data?.invoices[index];
                            openSnackbar(<p style={{ paddingTop: 20 }}>{`${response.data?.invoices?.length === 1 ? formatMessage({ id: 'KC2938' }) : `${response.data?.invoices.length} ${formatMessage({ id: 'KC2939' })}` }`} <button className="btn invoiceViewButton" type="button" onClick={() => {
                                KCStore.setItem('invoiceSnackbar', true)
                                props.history.push(`/subscription/invoice?inv=${response.data?.invoices[0]?.uid}`)
                            }}><FormattedMessage id="KC1614" /></button></p>, 120000)
                        }
                    }
                }
            }
        }).catch(err => {
        });
    }

    const getBrand = async () => {
        await http.get(`${SoftwareManagementAPI.getSoftwareBrandList}`, {
            headers: {
                'Content-Type': 'application/json',
                brandId: BRAND_ID,
            },
            data: {}
        }).then(async response => {
            if (response && response.data?.branList) {
                if (response.data?.branList?.length === 1) {
                    setBrand(response.data.branList[0].ID)
                    await getModelList(response.data.branList[0].ID);
                }
                setBrandList(_.orderBy(response.data.branList, ['BRAND_NAME'], ['asc']));
            }
        }).catch(err => {
        });
    }

    const getModelList = (brandId) => {
        return new Promise(async (resolve, reject) => {
            setLoaderIconVisible(true)
            await http.get(subscriptionManagement.getSubscriptionList,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'brand_id': brandId,
                        'type': 'modelList',
                        'customer_id': KCStore.getItem("customerId")
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    setModelList(data);
                    if (data?.length === 1) {
                        setModel(data[0].model_id)
                    } else {
                        setModel('');
                    }
                    setLoaderIconVisible(false)
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    const onPageChange = (currentPageNumber) => {
        setLoaderIconVisible(true);
        getSubscriptionData(currentPageNumber, brand, model)
    }

    const getSubscriptionData = async (currentPageNumber, brand, model) => {
        let url = subscriptionManagement.getSubscriptionList;
        let headers = {
            'Content-Type': 'application/json',
            'customer_id': KCStore.getItem("customerId"),
            'type': 'subscriptionDashboard',
            'cgid': KCStore.getItem("custGroupID"),
            'page': currentPageNumber,
            'page_size': pageSize,
        }
        if (brand) {
            headers['brand_id'] = brand;
        }
        if (model) {
            headers['product_id'] = model;
        }

        await http.get(url, {
            headers, data: {}
        }).then(async response => {
            if (response && response.data) {
                if (response.data?.result) {
                    setSubscriptionData(response.data?.result);
                    setTotalPages(Math.ceil(response.data?.totalCount / pageSize))
                    setLoaderIconVisible(false);
                    setInitialPageload(true);
                } else {
                    setLoaderIconVisible(false);
                    setInitialPageload(true);
                }
            } else {
                setLoaderIconVisible(false);
                setInitialPageload(true);
            }

        }).catch(err => {
            setLoaderIconVisible(false);
            setInitialPageload(true);
        });
    }
    const getDashboardData = async () => {
        let url = subscriptionManagement.getSubscriptionDashboard;
        let headers = {
            'Content-Type': 'application/json',
            'customer_id': KCStore.getItem("customerId")
        }

        await http.get(url, {
            headers, data: {}
        }).then(async response => {
            if (response && response.data) {
                if (response.data) {
                    setDashboardData(response.data);
                    for (let index = 0; index < response.data?.nextOneMonthDExpiringUnit.length; index++) {
                        const element = response.data?.nextOneMonthDExpiringUnit[index];
                        if (element.chargeable_status === "PREPAID") {
                            const prepaidMonth = (moment(element.unit_prepaid_end_date).month()) + 1;
                            if (prepaidMonth === currentMonth) {
                                currentMonthEndingUnit.current.push(Number(moment(element.unit_prepaid_end_date).format('DD')))
                            }
                            if (prepaidMonth === nextMonth) {
                                nextMonthEndingUnit.current.push(Number(moment(element.unit_prepaid_end_date).format('DD')))
                            }
                        }
                    }
                }
            }
        }).catch(err => {
        });
    }

    const inActiveCount = dashboardData?.subscriptionDashboardTial?.find((i) => i.chargeable_status === 'NO') || { totalCount: 0 }
    const subscribeCount = dashboardData?.subscriptionDashboardTial?.find((i) => i.chargeable_status === 'YES') || { totalCount: 0 }
    const freeTrialCount = dashboardData?.subscriptionDashboardTial?.find((i) => i.chargeable_status === 'PREPAID') || { totalCount: 0 }
    const freeCount = dashboardData?.subscriptionDashboardTial?.find((i) => i.chargeable_status === 'FREE') || { totalCount: 0 }

    const prepaidExpiryCount = dashboardData?.nextOneMonthDExpiringUnit?.filter((i) => i.chargeable_status === 'PREPAID');
    const subscribedExpiryCount = dashboardData?.nextOneMonthDExpiringUnit?.filter((i) => i.chargeable_status === 'YES');

    $(function () {
        $('[data-toggle="popover"]').popover({
            trigger: 'hover',
            placement: 'bottom'
        })
    })

    return (
        <div id="adminWrapper">
            <div className="searchToggleTransparent"></div>
            <div className="toggleTransparent"></div>
            <div className="sideNavToggleTransparent"></div>
            <div className="userToggleTransparent"></div>
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <div id="page-content-wrapper">
                <div className="lightBgWrapper">
                    <div className="navBarXScroll">
                        <ul>
                            {headerRow(formatMessage, 'Dashboard')}
                        </ul>
                    </div>
                    <div className="subspnDashboard" >
                        <div className="subspnDashboardGrid">
                            <div className="subspnDashboardGridD">
                                <div className="widgetIcon"><img src={require('../../images/icons/totalUnits.svg').default} /></div>
                                <div className="widgetContent">
                                    <h4><FormattedMessage id='KC2466' /></h4>
                                    <h5>{dashboardData.totalUnitsCount || 0}</h5>
                                </div>
                            </div>
                            <div className="subspnDashboardGridD">
                                <div className="widgetIcon"><img src={require('../../images/icons/inactiveUnits.svg').default} /></div>
                                <div className="widgetContent">
                                    <h4><FormattedMessage id='KC2459' /></h4>
                                    <h5>{inActiveCount.totalCount || 0}</h5>
                                </div>
                            </div>
                            {freeCount?.totalCount !== 0 ? <div className="subspnDashboardGridD">
                                <div className="widgetIcon"><img src={require('../../images/icons/freeUnits.svg').default} /></div>
                                <div className="widgetContent">
                                    <h4><FormattedMessage id='KC2460' /></h4>
                                    <h5>{freeCount.totalCount || 0}</h5>
                                </div>
                            </div> : null}
                            <div className="subspnDashboardGridD">
                                <div className="widgetIcon"><img src={require('../../images/icons/freeTrialUnits.svg').default} /></div>
                                <div className="widgetContent">
                                    <h4><FormattedMessage id='KC2461' /></h4>
                                    <h5>{freeTrialCount.totalCount || 0}</h5>
                                </div>
                            </div>
                            <div className="subspnDashboardGridD">
                                <div className="widgetIcon"><img src={require('../../images/icons/subscribedUnits.svg').default} /></div>
                                <div className="widgetContent">
                                    <h4><FormattedMessage id='KC2462' /></h4>
                                    <h5>{subscribeCount.totalCount || 0}</h5>
                                </div>
                            </div>
                        </div>

                        <div className="subspnDefault">
                            <div className="subspnDefaultView">
                                <div className="widgetLabel">
                                    <h4><FormattedMessage id='KC2463' /></h4>
                                    <h5><FormattedMessage id='KC2464' /> </h5>
                                    <h4><FormattedMessage id='KC2465' /></h4>
                                </div>
                                <ul className="widgetList" style={{ display: initialPageLoad ? '' : 'none' }}>
                                    {dashboardData?.nextOneMonthDExpiringUnit?.length && prepaidExpiryCount.length ? <li><FormattedMessage id='KC2473' values={{ amount: `${prepaidExpiryCount.length} Unit${prepaidExpiryCount.length > 1 ? 's' : ''}` }} /></li> : null}
                                    {dashboardData?.nextOneMonthDExpiringUnit?.length === 0 ? <li><FormattedMessage id='KC2472' /></li> : null}
                                    {dashboardData?.nextOneMonthDExpiringUnit?.length === 0 ? null : <li><FormattedMessage id='KC2474' values={{ days: remainingDaysInMonth }} /></li>}
                                    {/* {dashboardData?.nextOneMonthDExpiringUnit?.length && dashboardData?.expectedNextMonthBilling.length ? <li><FormattedMessage id='KC2475' values={{ amount: dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price }} /></li> : null} */}
                                </ul>
                            </div>
                            <div className="subspnDefaultViewOneM">
                                {daysInThisMonth.length ? <div className="perMonthWidget">
                                    <div className="monthLabel">{currentMonthName}</div>
                                    <ul className="dayList">
                                        {daysInThisMonth?.map((date, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    data-toggle={Number(date) === 1 ? 'popover' : currentMonthEndingUnit?.current?.includes(date) ? 'popover' : Number(date) === Number(todayDate) ? "popover" : ''}
                                                    data-content={Number(date) === 1 ? `${Number(todayDate) === Number(date) ? `${formatMessage({ id: 'KC2948' })} $${dashboardData?.expectedNextMonthBilling?.length && dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price ? dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price : 0}` : `${formatMessage({ id: 'KC2703' })} $${dashboardData?.expectedNextMonthBilling?.length && dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price ? dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price : 0}`}` : currentMonthEndingUnit?.current?.includes(date) ? formatMessage({ id: 'KC2937' }): Number(date) === Number(todayDate) ? formatMessage({ id: 'KC2702' }): ''}
                                                    className={Number(date) === 1 ? 'eventDayR' : currentMonthEndingUnit?.current?.includes(date) ? 'eventDay' : Number(date) === Number(todayDate) ? "today" : ''}>
                                                    {date}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <div className="yearLabel">{currentYearName}</div>
                                </div> : null}
                                {nextMonthDay.length ? <div className="perMonthWidget">
                                    <div className="monthLabel">{nextMonthName}</div>
                                    <ul className="dayList">
                                        {nextMonthDay?.map((date, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    data-toggle={dashboardData?.nextOneMonthDExpiringUnit && dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price && Number(date) === 1 ? 'popover' : nextMonthEndingUnit?.current?.includes(date) ? 'popover' : ''}
                                                    data-content={dashboardData?.nextOneMonthDExpiringUnit && dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price && Number(date) === 1 ? `${Number(todayDate) === Number(date) ? `${formatMessage({ id: 'KC2948' })} $${dashboardData?.expectedNextMonthBilling?.length && dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price ? dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price : 0}` : `${formatMessage({ id: 'KC2703' })} $${dashboardData?.expectedNextMonthBilling?.length && dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price ? dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price : 0}`}` : nextMonthEndingUnit?.current?.includes(date) ? formatMessage({ id: 'KC2937' }) : ''}
                                                    className={`${dashboardData?.nextOneMonthDExpiringUnit && dashboardData?.expectedNextMonthBilling[0]?.monthly_expected_price && Number(date) === 1 ? 'eventDayR' : nextMonthEndingUnit?.current?.includes(date) ? 'eventDay' : ''}`}>
                                                    {date}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <div className="yearLabel">{nextYearName}</div>
                                </div> : null}
                            </div>
                        </div>
                    </div>

                    <div className="tableSHeader" >
                        <ul>
                            <li>
                                <div className={`form-group ${brandList.length === 1 ? 'disabled' : ''}`}>
                                    {<select className="form-control" id="selectBrandId" value={brand} onChange={async (e) => {
                                        if (e.target.value === '') {
                                            setBrand(e.target.value);
                                            setLoaderIconVisible(true);
                                            setModelList([]);
                                            await getSubscriptionData(1)
                                        } else {
                                            setBrand(e.target.value);
                                            setLoaderIconVisible(true);
                                            await getModelList(e.target.value);
                                            await getSubscriptionData(1, e.target.value)
                                        }
                                    }}>
                                        <option value=""><FormattedMessage id='KC0651' /></option>
                                        {brandList.map((i) => <option value={i.ID}>{i.BRAND_NAME}</option>)}
                                    </select>}
                                </div>
                            </li>
                            <li className="widthAuto">
                                <div className={`form-group ${brand === '' || modelList.length === 1 ? 'disabled' : ''}`}>
                                    <select className={`form-control`} id="selectModelId" value={model} onChange={async (e) => {
                                        if (e.target.value === '') {
                                            setModel(e.target.value);
                                            setLoaderIconVisible(true);
                                            await getSubscriptionData(1, brand)
                                        } else {
                                            setModel(e.target.value);
                                            setLoaderIconVisible(true);
                                            await getSubscriptionData(1, brand, e.target.value)
                                        }
                                    }}>
                                        <option value=""><FormattedMessage id='KC2446' /></option>
                                        {modelList.map((i) => <option value={i.model_id}>{i.model_name}</option>)}
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="subspnStatusCard" >
                        {subscriptionData?.length ? <ul>
                            {subscriptionData?.map((subscription, index) => {
                                let brandImage = brandLogo.find(r => r.name === subscription.BRAND_NAME);
                                return (
                                    <li key={index}>
                                        <div className="subspnStatusCardH">
                                            <span className="statusCardBrand">
                                                <img src={brandImage?.img || ''} />
                                            </span>
                                            <span className="statusCardModel">{subscription.MODEL_NAME}</span>
                                        </div>
                                        <div className="statusCardIlist">
                                            <div className="statusCardLabel">
                                                <FormattedMessage id='KC2466' />
                                                <span>{subscription.totalUnits || 0}</span>
                                            </div>
                                            <div className="statusCardLabel">
                                                <FormattedMessage id='KC2459' />
                                                <span>{subscription.inactive_units || 0}</span>
                                            </div>
                                            {subscription?.free_units !== 0 ? <div className="statusCardLabel">
                                                <FormattedMessage id='KC2467' />
                                                <span>{subscription.free_units || 0}</span>
                                            </div> : null}
                                            <div className="statusCardLabel">
                                                <FormattedMessage id='KC2468' />
                                                <span>{subscription.prepaid_units || 0}</span>
                                            </div>
                                            <div className="statusCardLabel">
                                                <FormattedMessage id='KC2469' />
                                                <span>{subscription.subscribed_units || 0}</span>
                                            </div>
                                            <div className="statusCardLabel">
                                                <FormattedMessage id='KC2470' />
                                                <span>${subscription.price_per_unit || 0}</span>
                                            </div>
                                            <div className="statusCardLabel">
                                                <FormattedMessage id='KC2471' />
                                                <span>${subscription.monthly_fee || 0}</span>
                                            </div>
                                        </div>
                                        <button type="button" className="btn viewBtn" onClick={() => props.history.push(`/subscription/manageSubscription`, { brandId: subscription.brand_id, modeId: subscription.PRODUCT_ID })}><FormattedMessage id='KC1614' /></button>
                                    </li>
                                )
                            })}
                        </ul> : !loaderIconVisible ? <div className="noRecordsTable"><button type="button" className="btn noRecordsTableInfo  cursor-default">&nbsp;</button> <FormattedMessage id='KC2445' values={{ value: "Subscription" }} /></div> : null}
                    </div>
                    {subscriptionData.length && totalPages && totalPages > 1 ? <GoToPagination
                        isBlackBackground={false}
                        totalPageNumber={totalPages}
                        pageNumber={currentPage}
                        navigateTo={(p) => onPageChange(p)} /> : null}
                </div>
            </div>

            <UserManagementHeader headerName={formatMessage({ id: 'KC1668' })} headerNameStringID="KC1668" activeClass="billingPortalNav" />
        </div >
    )
};

export default injectIntl(Dashboard)