import React from 'react';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../components/Loader';
import UserManagementHeader from '../../components/layoutSection/userManagementHeader';
import RoleListComponent from '../../Features/roleMgmt/list';
import { searchData_function } from '../../components/globalSearchBar/selectOption';
import { userRoleURI } from '../../service/api';
import { BRAND_ID } from '../../utils/appConstants';
import http from '../../service/httpService';
import { searchText, searchVisible } from '../../actions/advanceSearch/advanceSearchAction';
import { setSnackBarData } from '../../actions/snackbar';
import { snackbarClosingSecond } from '../../components/navigation/organizationManagement/organizationManagement';
import authConstants from '../../utils/authConstants';
import _ from 'lodash';

import KCStore from "../../utils/kcStorage";


const rolesImages = [
    { name: "Customer Admin", img: require("../../images/users/owner.svg").default },
    { name: "Crem System Admin", img: require("../../images/users/owner.svg").default },
    { name: "Service Technician", img: require("../../images/users/service.svg").default },
    { name: "1st Line User", img: require("../../images/users/leaser.svg").default },
    { name: "2nd Line User", img: require("../../images/users/chef.svg").default },
    { name: "Crem User", img: require("../../images/users/manager.svg").default }
]


const RoleList = (props) => {
    const [loader, setLoading] = React.useState(false);
    const [roleList, setRoleList] = React.useState([]);
    const [sortColumn, setSortColumn] = React.useState({ path: "name", order: "asc", sort_color: "#63778a" });
    const [allSelect, setAllSelect] = React.useState(false);
    const [isIndeterminateSelect, setIsIndeterminate] = React.useState(true);

    const { allFeaturesKey } = authConstants;

    const dispatch = useDispatch();

    const searchData = useSelector(state => state.advanceSearch.searchData);

    React.useEffect(() => {
        document.body.style.backgroundColor = "#F2F2F2";
        window.addEventListener('resize', updateDimensions);
        dispatch(searchVisible(true));
        init();
        return () => {
            dispatch(searchText({}));
            dispatch(searchVisible(false));
        }
    }, []);

    React.useEffect(() => {
        updateDimensions()
    }, [searchData])

    React.useEffect(() => {
        if (searchData && Object.keys(searchData).length) getRoleList();
    }, [searchData]);

    const snackBarToast = (message, state) => {
        return new Promise((reslve, _) => {
            reslve(dispatch(setSnackBarData({ message, isActive: true, state, seconds: snackbarClosingSecond })))
        })
    }

    const init = async () => {
        await getRoleList();
    }

    const getRoleList = async (sortColumnParam = sortColumn) => {
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let loginFeatureInfo = JSON.parse(KCStore.getItem(allFeaturesKey));
        let loggedinUserRoleId = loginFeatureInfo[0].role_id;
        let brandIds = BRAND_ID;
        let url = userRoleURI.getRolesByCustomer + KCStore.getItem("customerId") + "&user_email=" + loggedinUser + "&loginUserRoleid=" + loggedinUserRoleId + "&brandIds=" + brandIds + "&listType=true&refreshCache=" + new Date().getTime();

        //searchData_function
        let _search = searchData_function(searchData);
        if (_search) {
            url = `${url}${_search}`;
        }

        setLoading(true);
        const response = await http.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }, data: {}
        })
        if (response && response.data) {
            let { data: rolesList } = response.data
            let rolemgtlist = [];
            rolemgtlist = rolesList.filter(r => r.role_name !== 'Customer Admin');
            rolemgtlist = rolemgtlist.filter(r => r.role_name !== 'Merrychef Admin');
            rolemgtlist.forEach((item, i) => {
                item.order_id = ++i;
                let pickRole = rolesImages.find(r => r.name === item.role_name);
                if (pickRole != null) {
                    item.img = pickRole.img;
                }
                else if (pickRole === undefined) {
                    item.img = require("../../images/users/user.png");
                }
            });
            console.log("rolemgtlist", rolemgtlist)
            rolemgtlist = _.orderBy(rolemgtlist, [rolemgtlist => rolemgtlist.role_name && rolemgtlist.role_name.toString().toLowerCase()], [sortColumnParam.order]);
            setRoleList(rolemgtlist);
            setLoading(false);
        }

    }

    const updateDimensions = () => {
        var windowHeight = $(window).height();
        var userMgmtHeader = $(".userMgmtHeader").height();
        var userMgmtListTableH = $(".userMgmtRolesTable .tHead").height();
        var pageWrapperP = $("#page-content-wrapper").innerHeight();
        var userMgmtTableSrlBarH = ((windowHeight) - (userMgmtHeader + userMgmtListTableH)) - (pageWrapperP + 5);
        $(".userMgmtTableSrlBar").css("height", userMgmtTableSrlBarH);
    }

    const onSort = sortColumn => {
        setSortColumn(sortColumn)
        getRoleList(sortColumn)
    }

    const editRole = () => {
        let selectedRoles = roleList?.filter(item => item.checked === true);
        console.log("selectedRoles", selectedRoles)
        if (selectedRoles?.length) {
            console.log("selectedRole 111s", selectedRoles)
            props.history.push({
                pathname: '/role/edit/'+selectedRoles[0].Id,
            })
        }
    }

    const handleChange = (e, userVal, type) => {
        if (type === 'multi') {
            setRoleList((prev) => {
                const newData = prev?.map((item => {
                    return { ...item, checked: e.target.checked }
                }))
                return newData
            })
        } else {
            setRoleList((prev) => {
                const newData = prev?.map((item => {
                    if (item.Id === userVal.Id) {
                        return { ...item, checked: e.target.checked }
                    }
                    return item;
                }));
                console.log("newData", newData)
                return newData
            })
        }
    }

    return (
        <>
            <Loader loading={loader} />
            <UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav" />
            <RoleListComponent
                role={roleList}
                setLoading={setLoading}
                loader={loader}
                isIndeterminateSelect={isIndeterminateSelect}
                allSelect={allSelect}
                sortColumn={sortColumn}
                setAllSelect={setAllSelect}
                setIsIndeterminate={setIsIndeterminate}
                searchData={searchData}
                getRoleListCallback={getRoleList}
                handleChange={handleChange}
                editRole={editRole}
                history={props.history}
                snackBarToast={snackBarToast}
                onSort={onSort} />
        </>
    )
}


export default RoleList;