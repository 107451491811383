import React from 'react';
import * as Yup from "yup"
import { Formik } from 'formik';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import http from '../../service/httpService';
import auth from '../../service/authService';
import { BaseService } from '../../service/api';
import ErrorMoal from '../modal/error-model';
import SuccessMoal from '../modal/success-model';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { isCaptchaEnabled } from '../../utils/captcha'
import FormikController from '../../UI_Components/FormikController';
import KCLink from '../../UI_Components/KCLink';

import 'bootstrap/dist/css/bootstrap.min.css';
import LanguagePreferenceCommon from '../languageDropdown/LanguagePreferenceCommon';

import Captcha from '../controls/Captcha';

const ForgotPassword = (props) => {
	const intl = useIntl();
	const { formatMessage } = intl;

	const _reCaptchaRef = React.useRef();
	const langDetails = useSelector(state => state.localeInfo.locale)

	const [isCaptchaVerified, setCaptchaVerified] = React.useState(false);
	const [loaderIconVisible, setLoader] = React.useState(false);
	const [errors, setErrors] = React.useState({
		recaptcha: ''
	});
	const [error_modal, setErrorModal] = React.useState({
		open: false,
		message: ""
	});
	const [success_modal, setSuccessModal] = React.useState({
		open: false,
		message: ""
	});


	React.useEffect(() => {
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");
		updateLoginDimensions();
		if (auth.getJwt())
			return props.history.push('/dashBoardLocations');
		$("body").removeClass("modal-open");
		setTimeout(() => {
			var width = $('.g-recaptcha').parent().width();
			var scale = width / 304;
			$('.g-recaptcha').css('transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('transform-origin', '0 0');
			$('.g-recaptcha').css('-webkit-transform-origin', '0 0');
			$('.g-recaptcha').css('display', 'block');
		}, 100);
	}, [])


	const onDeleteClickHandler = () => {
		props.history.push('/');
	}

	const nextPath = (path) => {
		$('.modal-backdrop').hide();
		props.history.push(path);
	}

	const isValideEmailorNot = async (email) => {
		if (email) {
			setLoader(true);
			try {
				const response = await http.get(`${BaseService.check_valid_email}`,
					{
						headers: {
							'Content-Type': 'application/json',
							'username': email
						}, data: {},
					})
				let { data } = response;
				if (data.responseCode === 200 && data.message)
					return { isValidEmail: true }
				else {
					return { isValidEmail: false }
				}
			} catch (error) {
				const errorData = error?.request?.response ? JSON.parse(error?.request?.response) : {};
				if (errorData?.responseCode && errorData?.responseCode !== null && errorData?.responseCode !== undefined){
					setLoader(false);
					setErrorModal({
						open: true,
						message: errorData?.message || "Something went wrong"
					})
				}
				return { isValidEmail: false }
			}
		}
	}

	const onSigninclickHandler = async (email) => {
		const { isValidEmail } = await isValideEmailorNot(email);
		if (isValidEmail) {
			let postData = {
				"username": email
			}
			try {
				const response = await http({
					method: "POST",
					url: BaseService.submit_forget_password,
					data: postData,
				})
				let { status, data } = response;
				if (status === 200) {
					setLoader(false);
					setSuccessModal({
						open: true,
						message: formatMessage({ id: "KC2206" })
					})
					WoopraEvents(`${Constants.FORGET_PASSWORD_REQUEST}`);
				}
			} catch (error) {
				const errorData = error?.request?.response ? JSON.parse(error?.request?.response) : {};
				setLoader(false);
				setErrorModal({
					open: true,
					message: errorData.message || "Something went wrong!"
				})
				WoopraEvents(`${Constants.FORGET_PASSWORD_REQUEST_FAIL}`);
			}
		}
	}

	const closeModel = (err) => {
		setLoader(false);
		setSuccessModal({
			open: false
		})
		setErrorModal({
			open: false
		})
	}

	const onSuccessCloseModal = () => {
		setLoader(false);
		setSuccessModal({
			open: false
		})
		props.history.push(`/`);
	}

	const updateLoginDimensions = () => {
		var windowHeight = $(window).height();
		var welcomTextH = $(".welcomText").height();
		var bgAdminH = $(".bgAdmin").height() - 25;
		var loginButtonH = $(".loginButton > ul:nth-child(2)").height();
		//Sign In & Sign Up Modal
		var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH) + (loginButtonH));
		var loginScrollbarHeightS = ((windowHeight) - (welcomTextHbgAdminH)) - 60;
		$(".loginButton > ul:nth-child(1) > li").css("height", loginScrollbarHeightS);
	}

	const verifyCallback = (recaptchaToken) => {
		if (recaptchaToken) {
			setCaptchaVerified(true);
			setErrors({
				...errors,
				recaptcha: ''
			})
		}
	}

	const initialValues = {
		email: "",
	}

	const validationSchema = () => {
		return Yup.object().shape({
			email: Yup.string()
				.required(<FormattedMessage id="KC0018" />)
				.email(<FormattedMessage id="KC2654" />),
		})
	}

	return (
		<div className="loginBg">
			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div id="loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				{/* <!-- //Loading Progress --> 
					<!-- Fixed Header navbar --> */}
				<nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
					<KCLink className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</KCLink>
					<div id="login-pre">
						<LanguagePreferenceCommon/>
					</div>
				</nav>
				{/* <!-- //Fixed Header navbar -->
					<!-- //Fixed Header navbar --> */}
				<div id="page-wrapper">
					<div className="welcomText"><FormattedMessage id='KC0006' /></div>
					{/* <!-- Login Form --> */}
					<div className="LoginBtnWrapper">
						<div id="loginBtnFormID">
							<div className="loginButton">
								<ul>
									<li>&nbsp;</li>
									<li>
										<div className="loginIWrapper">
											<div className="existingLoginForm">
												<Formik
													initialValues={initialValues}
													validationSchema={validationSchema}
													isInitialValid={false}
												>
													{(formik) => (
														<ul>
															<li className="form-group">
																<FormikController
																	className="input-group"
																	inputClass="form-control"
																	isRequired={true}
																	placeholder={formatMessage({ id: "KC0007" })}
																	control="input"
																	type="email"
																	errorClass="required"
																	name="email"
																/>
															</li>
															{isCaptchaEnabled() ? <li className="form-group">
																<div className="g-recaptcha">
																	<Captcha ref={_reCaptchaRef} language={langDetails} onChange={verifyCallback} />
																</div>
																{errors["recaptcha"] && <div className="errorMsg">{errors.recaptcha}</div>}
															</li> : null}

															<li className="form-group">
																<button className="btn btn-secondary cancelBtn" type="button" onClick={onDeleteClickHandler}><FormattedMessage id='KC0021' /></button>
																<button disabled={isCaptchaEnabled() ? formik.isValid && isCaptchaVerified ? false : true : formik.isValid ? false : true} class="btn btn-secondary submitBtn" type="submit" id="existingSignInButton" onClick={() => onSigninclickHandler(formik.values.email)}><FormattedMessage id='KC0022' /></button>
															</li>
														</ul>
													)}
												</Formik>
											</div>
										</div>
									</li>
									<li>&nbsp;</li>
								</ul>
								<ul>
									<li>
									</li>
									<li>
										<div className="loginIWrapper">
											<KCLink to="/" title="Login"><FormattedMessage id='KC0001' /></KCLink>
											<KCLink to="/" title="Language" className="languageSel"></KCLink>
										</div>
									</li>
									<li>&nbsp;</li>
								</ul>
							</div>
						</div>
					</div>
					{/* <!-- //Login Form -->   */}
				</div>
			</div>
			<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={closeModel} />
			<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={onSuccessCloseModal} />

			<div className="modal fade SignInSuccessModal" data-keyboard="true" data-backdrop="false" id="SignInSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel">
				<div className="modal-dialog modal-md loginModal">
					<div className="modal-content">
						<div className="modal-body">
							<div className="singINConfirmation">
								You successfully created your personal password!
							</div>
						</div>
						<div className="modal-footer textAlignCenter">
							<button className="btn btn-secondary signINconfirmationOKBtn" data-dismiss="modal" onClick={() => nextPath('/dashBoardLocations')} type="button"><FormattedMessage id='KC0777' /></button>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ForgotPassword;
