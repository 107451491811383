
export const MEDIA_DRAG_LIST_SUCCESS = 'MEDIA_DRAG_LIST_SUCCESS';
export const MEDIA_DRAG_DELETE_SUCCESS = 'MEDIA_DRAG_DELETE_SUCCESS';
export const MEDIA_RESET_SELECTED_SUCCESS = 'MEDIA_RESET_SELECTED_SUCCESS';

export function mediaDraggedItem(draggedItem) {
    return dispatch => {
        dispatch({
            type: MEDIA_DRAG_LIST_SUCCESS,
            mediaDraggedItem:draggedItem,
         })
    }
}
export function mediaDragDeleteItem(type) {
    return dispatch => {
        dispatch({
            type: MEDIA_DRAG_DELETE_SUCCESS,
            deleteType:type,
         })
    }
}
export function resetSelectedItems() {
    return dispatch => {
        dispatch({
            type: MEDIA_RESET_SELECTED_SUCCESS,
           
         })
    }
}









