export const customConfiguration = {
    'unit-version-connectivity': {
        id: "unit-version-connectivity-details",
        url: "/reportmanagement/report-management/dashboard-service/v1/widget-data",
        configuration: {
            "execute": [
                {
                    "database": "docdb",
                    "query": {
                        "collection": "units",
                        "operation": [
                            {
                                "name": "findOne",
                                "options": [
                                    {
                                        "UNITID": "{{_rootScope.unitDetails.UNITID}}"
                                    },
                                    "{\"shadow.CONNECT_STATE\":1}"
                                ]
                            }
                        ]
                    },
                    "outputProperty": "unit"
                },
                {
                    "database": "docdb",
                    "query": {
                        "collection": "unitConnectivityHistory",
                        "operation": [
                            {
                                "name": "find",
                                "options": [
                                    "{\"cloudUnitName\":\"{{_rootScope.unitDetails.CLOUD_UNIT_NAME}}\"}"
                                ]
                            },
                            {
                                "name": "sort",
                                "options": [
                                    {
                                        "eventDate": -1
                                    }
                                ]
                            },
                            {
                                "name": "limit",
                                "options": [
                                    1
                                ]
                            }
                        ]
                    },
                    "outputProperty": "connectivityHistory"
                },
                {
                    "database": "docdb",
                    "query": {
                        "collection": "units",
                        "operation": [
                            {
                                "name": "findOne",
                                "options": [
                                    {
                                        "UNITID": "{{_rootScope.unitDetails.UNITID}}"
                                    },
                                    "{\"shadow.document\":1}"
                                ]
                            }
                        ]
                    },
                    "outputProperty": "version"
                }
            ]
        }
    }
}