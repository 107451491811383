export const UnitManagementInfo = {
    CREM: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: true },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'IPD',stringID: 'KC0234', fieldValue: 'ipd_filename', visible: true },
            { sort: 2, fieldname: 'IoT agent version',stringID: 'IoT agent version', fieldValue: 'iot_version', visible: true },
            { sort: 3, fieldname: 'MPD',stringID: 'KC0233', fieldValue: 'mpd_filename', visible: true },
            { sort: 4, fieldname: 'Controller software',stringID: 'KC0232', fieldValue: 'stum_ver', visible: true }],
        
        expobarSoftwareVersion: [
            { sort: 1, fieldname: 'PCBA ID',stringID: 'KC2354', fieldValue: 'softwareVersion', visible: true },
            { sort: 2, fieldname: 'Firmware Version',stringID: 'KC1860', fieldValue: 'firmwareVersion', visible: true },
            { sort: 3, fieldname: 'IoT Agent',stringID: 'KC2353', fieldValue: 'commSoftwareVersion', visible: true }],

        hardwareInfo: [
                { sort: 1, fieldname: 'Machine Status', stringID: 'KC1830', fieldValue: 'machineStatus', visible: true },
                { sort: 2, fieldname: 'Group 1 Status', stringID: 'KC1831', fieldValue: 'group1Status', visible: true },
                { sort: 3, fieldname: 'Group 2 Status', stringID: 'KC1832', fieldValue: 'group2Status', visible: true },
                { sort: 4, fieldname: 'Group 3 Status', stringID: 'KC1833', fieldValue: 'group3Status', visible: true }],

        steamTemperatureInfo: [
                { fieldname: 'Steam Temperature',stringID: 'KC1834', fieldValue: '', visible: true }],

        helpInfo: [
            { fieldname1: 'Manuals', fieldValue1: '', visible: false },]
    },
    MERCO: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: false },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],
            

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'Software Version of UI', stringID: 'KC1853', fieldValue: 'firmwareVersionUI', visible: true },
            { sort: 2, fieldname: 'Software Version of IO', stringID: 'KC1854', fieldValue: 'firmwareVersionIO', visible: true },
            { sort: 3, fieldname: 'Software Version of IoT',stringID: 'KC1855', fieldValue: 'firmwareVersionIoT', visible: true },
            ],
        menuFileInfo: [
            { sort: 1, fieldname: 'Current Menu File', stringID:'KC0387', fieldValue: 'menuVersion', visible: true }
        ],
        helpInfo: [
            { fieldname: 'Manuals',stringID: 'KC0294', fieldValue1: '', visible: true, link:'https://www.mercoproducts.com/Resources' },
        ]
    },

    MERRYCHEF: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: true },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],
        //User Story 87817: Tag name change
        softwareVersionInfo: [
            { sort: 1, fieldname: 'Software Version', stringID: 'KC1861', fieldValue: 'firmwareVersionQts', visible: true },
            { sort: 2, fieldname: 'SRB', stringID: 'KC0289', fieldValue: 'firmwareVersionSrb', visible: true },
            { sort: 3, fieldname: 'SUI', stringID: 'KC0288', fieldValue: 'firmwareVersionIot', visible: true },
        ],
        menuFileInfo: [
            { sort: 1, fieldname: 'Menu File Name',stringID: 'KC0291', fieldValue: 'menuFileName', visible: true },
        ],
        helpInfo: [
            { fieldname: 'Manuals', stringID: 'KC0294', fieldValue: '', visible: true, link:'https://www.merrychef.com/Resources' },            
        ]
    },

    CONVOTHERM: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: true },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'SIB',stringID: 'KC0424', fieldValue: 'firmwareVersionSIB', visible: true },
            { sort: 2, fieldname: 'UI',stringID: 'KC0423', fieldValue: 'firmwareVersionUI', visible: true },
            { sort: 3, fieldname: 'IOT APP',stringID: 'KC0425', fieldValue: 'firmwareVersionIOT', visible: true },
            { sort: 4, fieldname: 'Cookbook Version',stringID: 'KC0426', fieldValue: 'cookbookVersion', visible: true },
            { sort: 5, fieldname: 'Cookbook Name',stringID: 'KC0686', fieldValue: 'cookbookVersionName', visible: true }],

        helpInfo: [
            { fieldname: 'Manuals', stringID: 'KC0294', fieldValue: '', visible: true, link:'https://www.convotherm.com/Resources' },            
        ]
    },

    FRYMASTER: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: true },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: true },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: true },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: true },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        intuitionModelInfo: [
            { fieldname: 'Energy Type',stringID: 'KC0507', fieldValue: '', visible: true },
            { fieldname: 'Filtration Type',stringID: 'KC0508', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'FIB',stringID: 'KC0516', fieldValue: 'FIBSWVersion', visible: true },
            { sort: 2, fieldname: 'SUI',stringID: 'KC0288', fieldValue: 'SUISWVersion', visible: true },
            { sort: 3, fieldname: 'OQS',stringID: 'KC0517', fieldValue: 'OQSSWVersion', visible: true },
        ],

        intuitionSoftwareInfo: [
            { sort: 1, fieldname: 'SCB',stringID: 'KC0515', fieldValue: 'SCBSWVersion', visible: true },
            { sort: 2, fieldname: 'FIB',stringID: 'KC0516', fieldValue: 'FIBSWVersion', visible: true },
            { sort: 3, fieldname: 'SUI',stringID: 'KC0288', fieldValue: 'SUISWVersion', visible: true },
            { sort: 4, fieldname: 'OQS',stringID: 'KC0517', fieldValue: 'OQSSWVersion', visible: true },
        ],
        menuFileInfo: [
            { sort: 1, fieldname: 'Menu File Name',stringID: 'KC0291', fieldValue: 'menuFileName', visible: true },
        ],
        softwareFrypots : [
            { sort: 1, fieldname: 'UIB',stringID: 'KC0512', fieldValue: 'UIBSWVersion', visible: true },
            { sort: 2, fieldname: 'SIB',stringID: 'KC0424', fieldValue: 'SIBSWVersion', visible: true },
            { sort: 3, fieldname: 'VIB',stringID: 'KC0514', fieldValue: 'VIBSWVersion', visible: true },
        ],
        intutionSoftwareFrypots : [
            { sort: 1, fieldname: 'UIB', stringID: 'KC0512', fieldValue: 'UIBSWVersion', visible: true },
            { sort: 2, fieldname: 'SIB', stringID: 'KC0424', fieldValue: 'SIBSWVersion', visible: true },
            { sort: 3, fieldname: 'HCB', stringID: 'KC0513', fieldValue: 'HCBSWVersion', visible: true },
            { sort: 4, fieldname: 'VIB', stringID: 'KC0514', fieldValue: 'VIBSWVersion', visible: true },
        ],
        helpInfo: [
            { fieldname: 'Manuals',stringID: 'KC0294', fieldValue: '', visible: true, link:'http://fm-xweb.frymaster.com/service/udocs/manuals.asp' },            
        ]
    },

    ['MANITOWOC ICE']: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: true },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'Controller Software Version',stringID: 'KC1852', fieldValue: 'FIBSWVersion', visible: true },
            { sort: 2, fieldname: 'Display Software Version',stringID: 'KC1851', fieldValue: 'SUISWVersion', visible: true },
            { sort: 3, fieldname: 'SUI',fieldname: 'SUI', stringID: 'KC0288', fieldValue: 'OQSSWVersion', visible: true },
        ],
        helpInfo: [
            { fieldname: 'Manuals', stringID: 'KC0294', fieldValue: '', visible: true, link:'https://www.manitowocice.com/Resources' },]
    },

    DELFIELD: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: true },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'SUI Firmware Revision',stringID: 'SUI Firmware Revision', fieldValue: 'SUISWVersion', visible: true },
            { sort: 2, fieldname: 'CCB Firmware Revision',stringID: 'CCB Firmware Revision', fieldValue: 'CCBSWVersion', visible: true },
        ],

        helpInfo: [
            { fieldname: 'Manuals', stringID: 'KC0294', fieldValue: '', visible: true, link:'https://www.delfield.com/Resources' },]
    },
    MULTIPLEX: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: true },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'Cleaning',stringID: 'KC1274', fieldValue: 'Cleaning', visible: true },
            { sort: 2, fieldname: 'UI',stringID: 'KC0423', fieldValue: 'UI', visible: true },
            { sort: 3, fieldname: 'Aux',stringID: 'KC1857', fieldValue: 'Aux', visible: true },
            { sort: 4, fieldname: 'SRB',stringID: 'KC0289', fieldValue: 'SRB', visible: true },
            { sort: 5, fieldname: 'Mixer',stringID: 'KC1858', fieldValue: 'Mixer', visible: true },
            { sort: 6, fieldname: 'Motor Board',stringID: 'KC1859', fieldValue: 'Motor Board', visible: true },
        ],
        menuFileInfo: [
            { sort: 1, fieldname: 'Menu File Name',stringID: 'KC0291',  fieldValue: 'menuFileName', visible: true },
        ],

        helpInfo: [
            { fieldname: 'Manuals', stringID: 'KC0294', fieldValue: '', visible: true, link:'https://www.multiplexbeverage.com/Resources' },]
    },
    GARLAND: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: true },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'Hardware UI',stringID: 'KC0345', fieldValue: 'uiHardwareRev', visible: true },
            { sort: 2, fieldname: 'Software UI',stringID: 'KC0346', fieldValue: 'uiSoftwareRev', visible: true },
            { sort: 3, fieldname: 'HRCB Kernel',stringID: 'KC0347', fieldValue: 'HRCBkernelRev', visible: true },
            { sort: 4, fieldname: 'SSRB Hardware',stringID: 'KC0348', fieldValue: 'SSRBhardwareRev', visible: true },
            { sort: 5, fieldname: 'Hardware Controller',stringID: 'KC0349', fieldValue: 'controllerHardwareRev', visible: true },
            { sort: 6, fieldname: 'Software Controller',stringID: 'KC0350', fieldValue: 'controllerSoftwareRev', visible: true },
            { sort: 7, fieldname: 'Protocol',stringID: 'KC0351', fieldValue: 'protocolRev', visible: true },
            { sort: 8, fieldname: 'IOT SW Version',stringID: 'KC2363', fieldValue: 'iotSwVersion', visible: true}
        ],

        helpInfo: [
            { fieldname: 'Manuals', stringID: 'KC0294', fieldValue: '', visible: true, link:'https://www.garland-group.com/Resources' },]
    },
    LINCOLN: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: true },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'SUI',stringID: 'KC0288', fieldValue: 'SUISWVersion', visible: true },
        ],
        menuFileInfo: [
            { sort: 1, fieldname: 'Menu File Name',stringID: 'KC0291', fieldValue: 'menuFileName', visible: true },
        ],

        helpInfo: [
            { fieldname: 'Manuals', stringID: 'KC0294', fieldValue: '', visible: true, link:'https://www.lincolnfp.com/Downloads' },]
    },
    ['FRYMASTER-UHCTHD']: {
        modelInfo: [
            { fieldname: 'Asset Number',stringID: 'KC0105', fieldValue: '', visible: true },
            { fieldname: 'Serial Number',stringID: 'KC0104', fieldValue: '', visible: true },
            { fieldname: 'Model Name', stringID: 'KC0064', fieldValue: '', visible: true },
            { fieldname: 'Connected Network',stringID: 'KC0218', fieldValue: '', visible: true },
            { fieldname: 'Signal Strength',stringID: 'KC0219', fieldValue: '', visible: true },
            { fieldname: 'MAC Address',stringID: 'KC0506', fieldValue: '', visible: false },
            { fieldname: 'Commissioning Date',stringID: 'KC0220', fieldValue: '', visible: false },
            { fieldname: 'Current JIB Status',stringID: 'KC0509', fieldValue: '', visible: false },
            { fieldname: 'Current OQS Oil Type',stringID: 'KC0510', fieldValue: '', visible: false },
            { fieldname: 'Network Provider',stringID: 'KC0286', fieldValue: '', visible: true },
            { fieldname: 'Sim Card Number',stringID: 'KC0222', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI Number',stringID: 'KC0223', fieldValue: '', visible: true },
            { fieldname: 'Modem IMEI SV Number', stringID: 'KC0224', fieldValue: '', visible: true },
        ],

        locationInfo: [
            { fieldname: 'Location',stringID: 'KC0050', fieldValue: '', visible: true },
            { fieldname: 'Location ID', stringID: 'KC0228', fieldValue: '', visible: true },
            { fieldname: 'Address',stringID: 'KC0051', fieldValue: '', visible: true },
            { fieldname: 'Within Location', stringID: 'KC0061', fieldValue: '', visible: true }],

        softwareVersionInfo: [
            { sort: 1, fieldname: 'Software Version of UI', stringID: 'KC1853',fieldValue: 'firmwareVersionUI', visible: true },
            { sort: 2, fieldname: 'Software Version of IO',stringID: 'KC1854', fieldValue: 'firmwareVersionIO', visible: true },
            { sort: 3, fieldname: 'Software Version of IoT',stringID: 'KC1855', fieldValue: 'firmwareVersionIoT', visible: true },
            ],
        menuFileInfo: [
            { sort: 1, fieldname: 'Current Menu File', stringID: 'KC0387',fieldValue: 'menuVersion', visible: true }
        ],
        helpInfo: [
            { fieldname: 'Manuals', stringID: 'KC0294', fieldValue1: '', visible: true, link:'http://fm-xweb.frymaster.com/service/udocs/manuals.asp' },
        ]
    },
}
export default UnitManagementInfo;