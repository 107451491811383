export const liveDiagnosticDataConf = {
    2: {
        id: "liveDiagnosticData",
        url: "/reportmanagement/report-management/dashboard-service/v1/widget-data",
        configuration: {
            execute: [
                {
                    database: "docdb",
                    query: {
                        collection: "diagnosticEvents",
                        operation: [
                            {
                                name: "aggregate",
                                options: [
                                    [
                                        {
                                            $match: {
                                                serialNo: "{{ data.serialNo }}",
                                                eventIntervalInSeconds: { $exists: true },
                                                eventType: "operationalDiagnostics",
                                                'eventData.bin.value': { $ne: null },
                                                timeStamp: {
                                                    $gte: "{{_serverScope.startTime | dataType: date}}",
                                                    $lt: "{{_serverScope.endTime | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $sort: { timeStamp: 1 }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    outputProperty: "liveData"
                }
            ],
            executionData: {
                "_serverScope": {
                    startTime: "{{data.startTime}}",
                    endTime: "{{data.endTime}}"
                }
            }
        }
    },
    12: {
        id: "liveDiagnosticData",
        url: "/reportmanagement/report-management/dashboard-service/v1/widget-data",
        configuration: {
            execute: [
                {
                    database: "docdb",
                    query: {
                        collection: "diagnosticEvents",
                        operation: [
                            {
                                name: "aggregate",
                                options: [
                                    [
                                        {
                                            $match: {
                                                serialNo: "{{ data.serialNo }}",
                                                eventIntervalInSeconds: { $exists: true },
                                                eventType: "operationalDiagnostics",
                                                'eventData.registerValues.value': { $ne: null },
                                                timeStamp: {
                                                    $gte: "{{_serverScope.startTime | dataType: date}}",
                                                    $lt: "{{_serverScope.endTime | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $sort: { timeStamp: 1 }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    outputProperty: "liveData"
                }
            ],
            executionData: {
                "_serverScope": {
                    startTime: "{{data.startTime}}",
                    endTime: "{{data.endTime}}"
                }
            }
        }
    }
}