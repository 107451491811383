import {HOLDING_TABLE_REQUEST,HOLDING_TABLE_SUCCESS,HOLDING_TABLE_FAIL} from '../../../actions/reportManagement/holdingTableActions';
const initialState = {
    loaded: false,
    loading: false,
    holdingTableResponse: {}
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case HOLDING_TABLE_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case HOLDING_TABLE_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                holdingTableResponse: action.holdingTableResponse
            }
        case HOLDING_TABLE_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgHoldingTable: 'Server Error',

            }
        default:
            return state
    }
}