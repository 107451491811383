import {ORG_SUBELEMENT_COUNTREQUEST,ORG_SUBELEMENT_COUNTSUCCESS,ORG_SUBELEMENT_COUNTFAIL,ORG_SUBELEMENT_COUNTRESET} from '../../../../actions/navigation/organizationmanagement/organizationSubElementCountActions';
const initialState = {
    loaded: false,
    loading: false,
    OrganizationSubElementCountInformation: {"subElementCount":[],"totalSubElementCount":0}
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case ORG_SUBELEMENT_COUNTREQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                
            }
        case ORG_SUBELEMENT_COUNTSUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                OrganizationSubElementCountInformation: action.OrganizationSubElementCountInformation,
               
            }
        case ORG_SUBELEMENT_COUNTFAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
               errormsg: '',

            }
            case  ORG_SUBELEMENT_COUNTRESET:
            return {
                ...state,
                loading: true,
                loaded: true,
                OrganizationSubElementCountInformation: {"subElementCount":[],"totalSubElementCount":0},
               errormsg: '',

            }
           
        default:
            return state
    }
}

