import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { UnitInfo } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import uploadMediaToCloud from './uploadMedia';
import Tooltip from 'react-tooltip-lite';
import authService from '../../../service/authService';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj} from '../../globalSearchBar/selectOption';
import KCStore from '../../../utils/kcStorage';


$(window).on('beforeunload', function () {
    if ($(".export-error-report").length > 0) {
        $("#exportReport").removeClass("export-error-report");
        $(".loadingProgress").css("display", "none");
    }
});

class assignMediaUpdateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            data: [],
            userSelectAll: false,
            sortColumn: { path: "", order: "", sort_color: "#fff" },
            nameFromReport: '',
            startDateFromReport: '',
            endDateFromReport: '',
            selectedoptionsFromReport: [],
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            errorResponse: "",
            purposeValData: [],
            stateData: [],
            url: '',
            srcfile: {},
            imageBuffer: '',
            previouspath: '',
            isIndeterminateSelect: false,
        };
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);

    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        this.props.searchVisible(true);
        try {
            if (this.props.location && this.props.location) {
                let { url, data, srcfile, imageBuffer, previouspath } = this.props.location;
                this.setState({
                    url,
                    stateData: data,
                    srcfile,
                    imageBuffer,
                    previouspath
                });
            }
            this.requestData();
        } catch (error) {
            //Error Goes Here
        }
    }

    componentWillMount() {
        if (this.props.location && this.props.location) {
            let { url, data, srcfile, imageBuffer, previouspath } = this.props.location;
            this.setState({
                url,
                stateData: data,
                srcfile,
                imageBuffer,
                previouspath
            });
        }
    }

    componentDidUpdate(prevState) {
        let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
            this.setState({
				currentPage: 1
			},() =>{
				this.requestData();
			})
		}
    }

    static getDerivedStateFromProps = (props) => ({...props});

    // componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

    // clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

    /**
     * Convert Encoding UTF8 to binary
     */
    convertToBinary = (string) => {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }

    onSort = sortColumn => {
        $(".table-header").removeClass('sortingParent');
        $(".sortingChild").parent().addClass('sortingParent');
        this.setState({
            sortColumn: sortColumn
        });
    }

    requestData = async () => {
        this.setState({ loaderIconVisible: true })
        let selectedModels = [];
        let custGroupID = KCStore.getItem("custGroupID");
        let cgids = "";
        if (custGroupID !== "" && custGroupID !== undefined) {
            cgids = custGroupID;
        }
        let sortedDesc = "desc";
        let sortedID = "CONNECT_STATE";
        let apiUrl = `${UnitInfo.unitList}`;
        let { searchData } = this.state;
        if (searchData && searchData.searchtext) {
            apiUrl = `${apiUrl}?searchText=${searchData.searchtext}`;
        }

        if (searchData) {
            let searchParam = {};
            Object.keys(searchData).forEach((item) => {
                if (item !== "searchtext") {
                    return searchParam[item] = searchData[item];
                }
            })
            if (searchParam && Object.keys(searchParam).length) {
                apiUrl = `${apiUrl}?searchParams=${btoa(JSON.stringify(searchParam))}`;
            }
        }

        let headerValue = {
            'Content-Type': 'application/json',
            'cgid': cgids,
            'cid': KCStore.getItem("customerId"),
            'brandid': KCStore.getItem("unitBrandId"),
            'model': selectedModels.join(','),
            'sortby': sortedID,
            'sorttype': sortedDesc,
            'basecountry': BASECOUNTRY_ID,
            'showOnlyActiveUnits': true
        }

        await http.get(apiUrl,
            {
                headers: headerValue,
                data: {}
            }).then(response => {
                let { units, } = response.data;
                units = UnitBuilder(units);
                let unitsAlreadyCheck = [];

				if(this.props.location && this.props.location.userCheckedData){
					for(let i=0; i<this.props.location.userCheckedData.length; i++){
						unitsAlreadyCheck.push(this.props.location.userCheckedData[i].ID);
					}
				}

                for(let j=0; j<units.length; j++){
					const item = units[j];
					if(unitsAlreadyCheck.includes(item.ID)){
						units[j].checked = true;
					}
				}
                this.setState({
                    loaderIconVisible: false,
                    data: units,
                    ...this.getCheckBoxstate(units.length,units.filter((i)=>i.checked === true).length)
                });

            }).catch(err => {
                this.setState({
                    loaderIconVisible: false,
                    data: [],
                });
            });
    }

    onBackClickHandler = () => {
        let { url, stateData, srcfile, imageBuffer, previouspath } = this.state;
        this.props.history.push({
            pathname: `/assignMedia`,
            url,
            data:stateData,
            srcfile,
            previouspath,
            imageBuffer
        });
    }

    handleChange = () => {
		let units = this.state.data;

		for(let i=0; i < units.length; i++){
			units[i].checked = units[i].PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" ? !this.state.userSelectAll : false;
		}
		let unitsSelected = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);


		this.setState(prevState => ({
			data: units,
			userSelectAll: !prevState.userSelectAll,
			isIndeterminateSelect: false,
			...this.getCheckBoxstate(units.length,unitsSelected.length)
		}));
	};

	getCheckBoxstate(ListCount, SelectedCount) {
		if (SelectedCount === ListCount) {
			return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (SelectedCount === 0 && ListCount !== 0) {
			return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
			return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, ID) => {
		let units = [];
		for(let i=0; i< this.state.data.length; i++){
			units.push({...this.state.data[i],checked:this.state.data[i].ID === ID ? e.target.checked : this.state.data[i].checked || false});
		}
		
		this.setState({
			data: units,
			...this.getCheckBoxstate(units.length, units.filter((i)=>i.checked === true).length)
		});
	};

    onClickFinish = async (e) => {
        this.sgPurposeValidations();
    }

    sgPurposeValidations = async () => {
        this.setState({ loaderIconVisible: true, })

        let userCheckedData = this.state.data.filter(unit => unit.checked === true);
        let unitObj = [];
        for (let i = 0; i < userCheckedData.length; i++) {
            if (!unitObj.includes(userCheckedData[i].CUSTOMER_GROUP_ID)) {
                unitObj.push(userCheckedData[i].CUSTOMER_GROUP_ID);
            }
        }

        this.state.stateData.cgids = unitObj;
        let _this = this;

        if (this.state.url != null && this.state.stateData != null && this.state.srcfile != null) {
            uploadMediaToCloud(this.state.url, this.state.stateData, this.state.srcfile, this.state.imageBuffer).then(response => {
                _this.setState({
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: "KC1515"
                    },
                })
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: err.response && err.response.data && err.response.data.message ? err.response.data.message : "KC1110",
                    },
                });
            });
        }
    }

    showError = (err) => {
        try {
            let { response } = err;
            if (response && response.status) {

                let { data } = response;
                let { body } = data;
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: body.message ? body.message : ""
                    }
                });
            } else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: err.message
                    }

                });
            }
        } catch (e) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.message
                }

            });
        }
    }

    closeModel() {
        this.setState({ error_modal: { open: false } });
        this.props.history.push(this.state.previouspath);
    }

    onSuccessCloseModal = () => {
        this.setState({
            success_modal: {
                open: false
            }
        })
        this.props.history.push(this.state.previouspath);
    }


    static getDerivedStateFromProps = (props) => ({ ...props });


    render() {
        const { formatMessage } = this.props.intl;
        const { loaderIconVisible, data, sortColumn, error_modal, success_modal } = this.state;

        let columns = [
            { path: "", label: "#" },
            { path: "", labelStringID: "KC0101", isNbSp: true },
            { path: "BRAND_NAME", labelStringID: "KC0062" },
            { path: "PRODUCT_TYPE", labelStringID: "KC0102" },
            { path: "MODEL_NAME", labelStringID: "KC0064" },
            { path: "UNITID", labelStringID: "KC0104" },
            { path: "UNIT_NUMBER", labelStringID: "KC0674" },
            { path: "location_name", labelStringID: "KC0675" },
            { path: "Within_Location", labelStringID: "KC0061" },
			// { path: "", labelStringID: "KC2317" },
			{ path: "", labelStringID: "" },
            { path: "CONNECT_STATE", labelStringID: "KC0523", isNbSp: true },
            { path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
        ];

        let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");

        let unitList = data;

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);
		const filterCheckedData = unitList.filter((i) => i.checked === true);
		const filterOfflineSelectedData = unitList.filter((i) => i.checked === true && i.CONNECT_STATE === "Offline");


        let showHeader;
        showHeader = (
            <ul>
				<li><FormattedMessage id="KC1570" values={{ selectedCount: filterCheckedData.length || 0, offlineCount: filterOfflineSelectedData.length || 0 }}/></li>
                <li>

                    <Link to={{pathname:"/assignMedia",userChecked:filterCheckedData}} class="assignunitSite">&nbsp;</Link>
                    <Link to="#" class="assignunitList active">&nbsp;</Link>
                </li>
            </ul>
        );

        return (
            <React.Fragment>
                <div id="adminWrapper">
                    <div id="exportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                        <div className="loadingProgressIn"></div>
                    </div>
                    <div id="page-content-wrapper">
                        <div class="reportWrapper">
                            <div class="adminBgHeader">
                                <ul>
                                    <li>
                                        <button onClick={() => this.onBackClickHandler()} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</button>
                                    </li>
                                    <li className="txtboldmsg">
                                        <FormattedMessage id="KC0711"/>
				            	</li>
                                    <li>
                                        <button id="btnfinish" title={formatMessage({ id: 'KC0056' })} className={`btn-default-text nextText ${filterCheckedData.length >0 ? 'activeState': 'disabled'}`}  onClick={() => this.onClickFinish()}><FormattedMessage id="KC0056"/></button>
                                    </li>
                                </ul>
                            </div>
                            <div class="selectedUnitHeader">
                                {showHeader}
                            </div>
                            <form class="SGlistUnits">
                            <div class="SWlistUnitsTable">
                                    <TableHeader
                                        sortColumn={sortColumn}
                                        onSort={this.onSort}
                                        selectAllChange={this.handleChange}
                                        columns={columns}
                                        isIndeterminate={this.state.isIndeterminateSelect}
                                        isSelectAll={this.state.userSelectAll} />
                                </div>
                                    {unitList.map((item, i) => {
                                        const title = item.smartGroups && item.smartGroups.length > 0 ? item.smartGroups.map((i)=>i.smartGroupName) : [];
                                        return (
                                            <React.Fragment key={i}>
                                                <div class="SWlistUnitsTable">
                                                    <ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody"} key={i}>
                                                        <li>{i + 1}</li>
                                                        <li><img src={item.Brand_Logo} alt="" /></li>
                                                        <li>{item.BRAND_NAME}</li>
                                                        <li>{item.PRODUCT_TYPE}</li>
                                                        <li>{item.MODEL_NAME}</li>
                                                        <li>{item.UNITID}</li>
                                                        <li>{item.UNIT_NUMBER}</li>
                                                        <li>{item.location_name}</li>
                                                        <li>{item.within_location}</li>
                                                        <li>
                                                            {/* {item.smartGroups && item.smartGroups.map((item, i) => {
                                                                if (i === 3) {
                                                                    return <span title={title.join(',')} className="contentOverflow active"> ... </span>
                                                                } else if (i <= 3) {
                                                                    return <span title={item.smartGroupName} className="sgFL">{item.smartGroupKeyName ? item.smartGroupKeyName : item.smartGroupName.charAt(0)}</span>
                                                                }
                                                            })} */}
                                                        </li>
                                                        <li><span className={`${item.CONNECT_STATE === 'Offline' ? "offlineStausTable" : ""}`}></span></li>
                                                        <li>{item.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED" ? <Tooltip content={formatMessage({ id: 'KC1735' })} direction="left" className="targetdisabled">
                                                            <div className={"customCheckbox customCheckboxWOlabel disabled"}>
                                                                <input id={`checkbox_${i}`} type="checkbox" value={item.checked} defaultChecked={item.checked}
																	checked={item.checked}
																	onChange={(e) => this.handleSingleCheckboxChange(e, item.ID)} />
																<label for={`checkbox_${i}`} ></label>
                                                            </div></Tooltip> 
                                                                : <div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
                                                                <input id={`checkbox_${i}`} type="checkbox" value={item.checked} defaultChecked={item.checked}
																	checked={item.checked}
																	onChange={(e) => this.handleSingleCheckboxChange(e, item.ID)} />
																<label for={`checkbox_${i}`} ></label>
                                                            </div>}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                    )}
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
                            </form>
                        </div>
                    </div>
                    <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                    <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                    <UserManagementHeader headerName={`Media – ${KCStore.getItem("unitBrandId") === "1" ? "Merrychef" : "Frymaster"} Library`} headerNameStringID={`${formatMessage({ id: 'KC0152' })} - ${KCStore.getItem("unitBrandId") === "1" ? "Merrychef" : "Frymaster"} ${formatMessage({ id: 'KC0658' })}`} activeClass="mediaNav"  />
                </div>
            </React.Fragment>
        )
    }
};

function mapStateToProps(state) {
    return {
        searchData: state.advanceSearch.searchData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({        
        searchVisible, searchText, clearSearchText
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(assignMediaUpdateList));

// export default injectIntl(assignMediaUpdateList);