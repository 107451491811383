import React, { useState, useEffect, useRef, useMemo, useCallback, memo } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, GeoJSON, ZoomControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import 'leaflet/dist/leaflet.css';
import '../../css/uidesign.css'
import northAmerica from './geoJSON/america-north.json';
import countries from './geoJSON/countries.json';
import { brandLocations } from '../../utils/imgContants';
import appconstants from '../../utils/appConstants';
import { CUSTOMER_ID, BRAND_ID } from '../../utils/appConstants';

import http from '../../service/httpService';
import { LocationInfo } from '../../service/api';
import KCStore from '../../utils/kcStorage';

const LocationsMapView = (props) => {
    const [loaderIconVisible, setLoaderIconVisible] = useState(true);
    const [map_locations, setMapLocations] = useState([]);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight - 132);
    const mapRef = useRef();

    useEffect(() => {
        let url = `${LocationInfo.list}`;
        let filterModel = props.filterlistInformtion.filterModel;
        const ffModel = KCStore.getItem(appconstants.filterKey);
        if (ffModel) {
            filterModel = JSON.parse(ffModel);
        }

        const selectedFilters = ['country', 'chain', 'location', 'withinlocation', 'customer', 'brand', 'model', 'machineType', 'unitSmartTagsFilter', 'orgSmartTagsFilter'];
        const selectedData = selectedFilters.reduce((acc, filter) => {
            const found = filterModel.find(el => el.field_name === filter);
            if (found) {
                acc[filter] = found.dataSource.map(item => item.id || item.cust_gp_id || item.ID);
            }
            return acc;
        }, {});

        let cgids = selectedData.country || KCStore.getItem("custGroupID");
        let cids = selectedData.customer || [];
        let brands = selectedData.brand || [];
        let models = selectedData.model || [];
        let machineTypes = selectedData.machineType || [];
        let tags = selectedData.unitSmartTagsFilter || [];

        let headers = {
            'Content-Type': 'application/json',
            'cgid': cgids.join(','),
            'cid': CUSTOMER_ID === "-1" ? cids.join(',') : CUSTOMER_ID,
            'brandid': brands.length > 0 ? brands.join(',') : BRAND_ID,
            'model': models.join(','),
            'machinetype': machineTypes.join(','),
            'tags': tags.length > 0 ? tags.join(',') : undefined
        };

        http.get(url, { headers, data: {} })
            .then(res => {
                res.data.locations.forEach(item => {
                    const pickBrand = brandLocations.find(element => element.name === item.location_type);
                    item.Brand_Logo = pickBrand ? pickBrand.img : require('../../images/store/FastCasual.svg').default;
                });

                const uniqueData = res.data.locations.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.location_id === item.location_id && t.location_type === item.location_type
                    ))
                );

                setMapLocations(uniqueData);
                setLoaderIconVisible(false);
            }).catch(err => {
                console.log(err);
            });

        window.addEventListener('resize', updateViewportHeight);
        return () => window.removeEventListener('resize', updateViewportHeight);
    }, [props.filterlistInformtion.filterModel]);

    const updateViewportHeight = () => {
        setViewportHeight(window.innerHeight);
    };

    const removeLineUnderPlusButton = () => {
        const zoomInButton = document.querySelector('.leaflet-control-zoom-in');
        if (zoomInButton) {
            zoomInButton.style.borderBottom = 'none';
            zoomInButton.style.boxShadow = 'none';
        }
    };

    const createCustomIcon = (location) => {
        let color = '#808080'
        let value = 0;

        if (location.CriticalOffline > 0 || location.NonCriticalOffline > 0 || location.NoErrorOffline > 0) {
            color = '#808080';
            value = location.CriticalOffline + location.NonCriticalOffline + location.NoErrorOffline;
        } else if (location.CriticalOnline > 0) {
            color = '#cd1d45';
            value = location.CriticalOnline;
        } else if (location.NonCriticalOnline > 0) {
            color = '#d39800';
            value = location.NonCriticalOnline;
        }

        const customIcon = L.divIcon({
            html: `
            <div title="${location.location_name} - ${location.street}, ${location.city}, ${location.country}" style="position: relative; width: auto; height: 25px; display: flex; justify-content: center; align-items: center;">
                <div style="background-color: #333; border-radius: 10px; display: flex; justify-content: center; align-items: center; width: auto; height: auto; position: relative; padding: 5px 10px; color: white;">
                    <div style="position: absolute; top: -10px; left: -10px; background-color: ${color}; border-radius: 50%; width: 23px; height: 23px; display: flex; justify-content: center; align-items: center; color: black; font-size: 12px;">
                        ${value}
                    </div>
                    ${location.total_Units}
                </div>
            </div>            
            `,
            className: 'marker-cluster',
            iconSize: L.point(50, 50)
        });

        return customIcon;
    };

    const createClusterIcon = (cluster) => {
        const markers = cluster.getAllChildMarkers();
        let totalCriticalOnline = 0;
        let totalOffline = 0;
        let totalUnits = 0;
        let totalNonCriticalOnline = 0;

        markers.forEach(marker => {
            const location = marker.options.location;
            totalCriticalOnline += location.CriticalOnline;
            totalNonCriticalOnline += location.NonCriticalOnline;
            totalOffline += location.CriticalOffline + location.NonCriticalOffline + location.NoErrorOffline;
            totalUnits += location.total_Units;
        });

        let color = '#808080'
        let value = 0;

        if (totalOffline > 0) {
            color = '#808080';
            value = totalOffline;
        } else if (totalCriticalOnline > 0) {
            color = '#cd1d45';
            value = totalCriticalOnline;
        } else if (totalNonCriticalOnline > 0) {
            color = '#d39800';
            value = totalNonCriticalOnline;
        }

        const size = 50;
        const icon = L.divIcon({
            html: `
            <div title="Multiple Locations. Click to expand." style="position: relative; width: auto; height: 25px; display: flex; justify-content: center; align-items: center;">
                <div style="background-color: #333; border-radius: 10px; display: flex; justify-content: center; align-items: center; width: auto; height: auto; position: relative; padding: 5px 10px; color: white;">
                    <div style="position: absolute; top: -10px; left: -10px; background-color: ${color}; border-radius: 50%; width: 23px; height: 23px; display: flex; justify-content: center; align-items: center; color: black; font-size: 12px;">
                        ${value}
                    </div>
                    ${totalUnits}
                </div>
            </div>            
            `,
            className: 'marker-cluster',
            iconSize: L.point(size, size),
        });
        return icon;
    };

    const handleMouseOver = useCallback((e) => {
        e.target.setZIndexOffset(1000);
    }, []);

    const handleMouseOut = useCallback((e) => {
        e.target.setZIndexOffset(0);
    }, []);

    const northAmericaGeoJSONOptions = {
        style: {
            color: "#000",
            weight: 1,
            fill: true,
            fillColor: "#c9d9d3",
            fillOpacity: 1,
        },
        onEachFeature: (feature, layer) => {
            if (feature.properties && feature.properties.NAME) {
                layer.bindTooltip(feature.properties.NAME, {
                    permanent: false,
                    direction: "top",
                });
                layer.options.interactive = true;
            }
        },
    };
    const countriesGeoJSONOptions = {
        style: {
            color: "#fff",
            weight: 1,
            fill: true,
            fillColor: "#d9d9d9",
            fillOpacity: 1,
        },
        onEachFeature: (feature, layer) => {
            layer.options.interactive = false;
        },
    };

    const mapCenter = useMemo(() => [30, -10], []);
    const mapZoom = useMemo(() => 2, []);
    const mapZoomSnap = useMemo(() => 0.5, []);
    const minMapZoom = useMemo(() => 2, []);
    const maxMapZoom = useMemo(() => 18, []);
    const mapAttribution = useMemo(() => false, []);
    const mapPreferCanvas = useMemo(() => true, []);
    const markerShowCoverage = useMemo(() => false, []);

    const MemoizedMarker = memo(({ position, icon, location, eventHandlers }) => (
        <Marker position={position} icon={icon} location={location} eventHandlers={eventHandlers} />
    ));

    const maxBounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));
    removeLineUnderPlusButton();

    return (
        <div style={{ height: `${viewportHeight}px` }}>
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <MapContainer
                style={{ height: "100%", width: "100%", backgroundColor: "#2d3034" }}
                ref={mapRef}
                preferCanvas={mapPreferCanvas}
                center={mapCenter}
                zoom={mapZoom}
                zoomSnap={mapZoomSnap}
                maxBounds={maxBounds}
                attributionControl={mapAttribution}
                minZoom={minMapZoom}
                maxZoom={maxMapZoom}
                zoomControl={false}>
                <ZoomControl position="bottomright" />
                {/* <TileLayer url="https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png" noWrap={true} /> */}
                <GeoJSON data={countries} {...countriesGeoJSONOptions} />
                <GeoJSON data={northAmerica} {...northAmericaGeoJSONOptions} />
                <MarkerClusterGroup iconCreateFunction={createClusterIcon} showCoverageOnHover={markerShowCoverage} >
                    {map_locations.map((location, index) => {
                        if (location.latitute && location.longitude) {
                            const latitude = parseFloat(location.latitute);
                            const longitude = parseFloat(location.longitude);
                            const offset = 0.00001 * index;
                            const position = [latitude + offset, longitude + offset];
                            return (
                                <MemoizedMarker key={`marker-${index}`} position={position} icon={createCustomIcon(location)} location={location}
                                    eventHandlers={{
                                        mouseover: handleMouseOver,
                                        mouseout: handleMouseOut,
                                        click: () => {
                                            KCStore.setItem("currentLocation", JSON.stringify(location));
                                            const url = "/dashBoardLocations/specificLocation"
                                            window.location.href = url;
                                        }
                                    }}>
                                </MemoizedMarker>
                            );
                        }
                        return null;
                    })}
                </MarkerClusterGroup>
            </MapContainer>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        filterlistInformtion: state.filterModel,
        selectedCountries: state.filterInformation.selectedCountries,
        selectedRegions: state.filterInformation.selectedRegions,
        selectedLocations: state.filterInformation.selectedLocations,
        selectedCustomers: state.filterInformation.selectedCustomers,
        selectedBrands: state.filterInformation.selectedBrands,
        selectedModels: state.filterInformation.selectedModels
    }
}

export default connect(mapStateToProps)(LocationsMapView);
