export const COUNTRY_SELECT = 'COUNTRY_SELECT';
export const REGION_SELECT = 'REGION_SELECT';
export const LOCATION_SELECT = 'LOCATION_SELECT';
export const CUSTOMER_SELECT = 'CUSTOMER_SELECT';
export const BRAND_SELECT = 'BRAND_SELECT';
export const MODEL_SELECT = 'MODEL_SELECT';

export function getFilterbyCountries (data) {
    return dispatch => {
        dispatch({
            type: COUNTRY_SELECT,
            information: data
        })
    }
}
export function getFilterbyRegions (data) {
    return dispatch => {
        dispatch({
            type: REGION_SELECT,
            information: data
        })
    }
}

export function getFilterbyLocations (data) {
    return dispatch => {
        dispatch({
            type: LOCATION_SELECT,
            information: data
        })
    }
}

export function getFilterbyCustomers (data) {
    return dispatch => {
        dispatch({
            type: CUSTOMER_SELECT,
            information: data
        })
    }
}

export function getFilterbyBrands (data) {
    return dispatch => {
        dispatch({
            type: BRAND_SELECT,
            information: data
        })
    }
}

export function getFilterbyModels (data) {
    return dispatch => {
        dispatch({
            type: MODEL_SELECT,
            information: data
        })
    }
}