import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import auth from '../../service/authService';


class ForgetPassword extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}
	
	componentDidMount(){
		if(auth.getJwt())
		   return this.props.history.push('/dashBoardLocations');
	}

	render() {
		return (
			<React.Fragment>
				
				<div id="adminWrapper">
				<div class="loginBg">
        {/* <!-- Loading Progress --> */}
        <div class="loadingProgress">
            <div class="loadingProgressIn"></div>
        </div>
        {/* <!-- //Loading Progress --> 
		
		<!-- Fixed Header navbar --> */}
        <nav class="navbar navbar-expand-lg navbar-dark bgAdmin"> 
            <div class="collapse navbar-collapse" id="navbarContent">
                 <div class="navbar-header"> 
                <a class="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" href="index.html">&nbsp;</a>
            </div>
            <p class="navbartextAlignCenter">Welcome</p>
            
            </div>
        </nav>
        {/* <!-- //Fixed Header navbar -->
		
        <!-- //Fixed Header navbar --> */}
        <div id="page-wrapper"> 
		<a class="kitchenConncetLogo" title="Welbilt - Bringing innovation to the table" href="index.html">&nbsp;</a>
			{/* <!-- Login Form --> */}
			<div class="LoginBtnWrapper"> 
				
				<div id="loginBtnFormID">
				<div class="loginButton">
					<ul>
						<li>&nbsp;</li>
						<li>
							<div class="loginIWrapper">
							<div class="existingLoginForm">  
								<ul>
									<li class="form-group">
										<div class="input-group"> 
											<select class="form-control countryCode">
												<option value="0">+1</option> 
												<option value="1">+1</option> 
												<option value="2">+1</option> 
											</select>
											<input type="tel" class="form-control phoneNumber" placeholder="Enter cellphone number"/> 
										</div>
									</li> 
									<li>
										<p>You will send you a code to your cellphone associated with your Welbilt account to reset your password</p>
									</li> 
									<li class="form-group pass"> 
										<button class="btn btn-secondary existingSingINBtn" onClick={() => this.nextPath('/passwordReset')}  type="button">Submit</button>
									</li> 
								</ul>
							</div>
							</div>
						 </li>
						<li>&nbsp;</li>
					</ul>
					<ul>
						<li><a href="createaccount.html" title="Create Account">Create Account</a></li>
						<li>
							<div class="loginIWrapper">
							{/* <a href="index.html" title="Change User" class="useExistingAccount">Change User</a> <a href="index.html" title="Language" class="languageSel"></a> */}
							<Link to="/" title="Use Existing Account" class="useExistingAccount">Change User</Link>
							<Link to="/" title="Language" class="languageSel"></Link>
							</div>
						</li> 
						<li>&nbsp;</li>
					</ul>
				</div>
				</div> 
			</div> 
			
			{/* <!-- //Login Form -->   */}
        </div>
    </div> 
	
	
	
	{/* <!-- SignIn Modal --> */}
    <div class="modal fade" data-keyboard="false" data-backdrop="static" id="SignInModal" tabindex="-1" role="dialog" aria-labelledby="SignInModalLabel">
        <div class="modal-dialog modal-md loginModal">
            <div class="modal-content"> 
                <div class="modal-body">
                   
				
					
						 
				 
                </div>
                <div class="modal-footer textAlignCenter">
                   <button class="btn btn-secondary singINBtn" type="button" id="SignInButton">Sign In</button>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- //SignIn Modal -->
	<!-- SignIn Confirmation Modal --> */}
    <div class="modal fade" data-keyboard="false" data-backdrop="static" id="SignInSuccessModal" tabindex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel">
        <div class="modal-dialog modal-md loginModal">
            <div class="modal-content"> 
                <div class="modal-body"> 
					<div class="singINConfirmation"> 
					You successfully created your personal password!
					</div>
						 
				 
                </div>
                <div class="modal-footer textAlignCenter">
                   <button class="btn btn-secondary signINconfirmationOKBtn" type="button">OK</button>
                </div>
            </div>
        </div>
    </div>

</div>
        </React.Fragment>
		)
	}
};

export default ForgetPassword;
