import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import SwaggerUI from './SwaggerUI';


class SwaggerUIComponent extends PureComponent {

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
    }

    render() {
        
        return (
            <React.Fragment>
                <div id="adminWrapper">
                    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-Welbilt">
                        <div className="navbar-header">
                            <Link className="navbar-brand welbiltRLogo" to="/mainMenu" title="Main Menu">&nbsp;</Link>
                        </div>
                        <ul className="navbar-nav mr-auto titlebarLeft">
                            <li className="nav-item">
                                <Link className="nav-link" to="/" title="Swagger Interface">Swagger Interface</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav mr-auto navbarRight">
                            <li className="nav-item">
                                <Link className="nav-link navUser" title="maneesh.kumar@mtwfs.com" to="#">&nbsp;</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav mr-auto navbarRight">
                            <li className="nav-item">
                                <Link className="nav-link navBack" to='/swaggerApiGroup' onclick="goBack()" title="Close">&nbsp;</Link>
                            </li>
                        </ul>
                    </nav>
                    <div id="page-content-wrapper">
                        <div className="mainMenuGrid">
                            <SwaggerUI fileName = {this.props.location.fileName}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SwaggerUIComponent
