import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import Switch from 'react-switch';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { headerRow } from './plan';
import GoToPagination from '../controls/goToPagination';
import { SoftwareManagementAPI, subscriptionManagement, unitAPI } from '../../service/api';
import http from '../../service/httpService';
import { TableHeader } from '../controls/tableHeader';
import { subscriptionManagementDateFormatter } from './invoice';
import { BRAND_ID } from '../../utils/appConstants';
import { OrganizationViewAPI } from '../../service/api_china';
import { snackBarOptions, snackbarClosingSecond } from '../navigation/organizationManagement/organizationManagement';
import { useSnackbar } from 'react-simple-snackbar';
import KCStore from '../../utils/kcStorage';

export const subscriptionField = {
    'PREPAID': 'Free Trial',
    'YES': 'Subscribed',
    'NO': 'Inactive',
    'FREE': 'Free'
}


const ManageSubscription = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(true);
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [brand, setBrand] = useState('');
    const [brandList, setBrandList] = useState([]);
    const [model, setModel] = useState('');
    const [modelList, setModelList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(null);
    const [toggleSubscription, setToggleSubscription] = useState({});
    const [isPaymentDetailAvailable, setIsPaymentDetailAvailable] = useState(true);
    const [searchClear, setClearSearch] = useState(false);
    const [initialDisable, setInitialDisable] = useState(false);
    const [openSnackbar, closeSnackbar] = useSnackbar(snackBarOptions);

    let brandValue = props?.history?.location?.state?.brandId || null;
    let modelValue = props?.history?.location?.state?.modeId || null;

    useEffect(() => {
        $("div").removeClass("modal-backdrop fade show");
        document.body.style.backgroundColor = "#F2F2F2";
        loadData()
        return () => {
            window.removeEventListener('resize', updateDimensions);
            if (closeSnackbar) closeSnackbar()
        }
    }, []);

    useEffect(() => {
        setBrand(brandValue || '')
        setModel(modelValue || '')
    }, [brandValue, modelValue]);


    const getPaymentInfo = async () => {
        await http.get(`${subscriptionManagement.paymentApi}?customerid=${KCStore.getItem("customerId")}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            data: {}
        }).then(async response => {
            if (response && response.data) {
                const isPaymentDetail = response?.data?.payment_profile ? true : false;
                setIsPaymentDetailAvailable(isPaymentDetail)
            }
        }).catch(err => {
        });
    }

    const loadData = async () => {
        await getPaymentInfo();
        await getBrand();
        await getSubscriptionData(1, false);
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        setTimeout(() => {
            updateDimensions();
        }, 2000)
    }

    const updateDimensions = () => {
        const windowHeight = $(window).height();
        const $tableBody = $('.manageSubscriptionTScrl');
        const $tableBodyBottom = $('.paginationWgoto').height() ? $('.paginationWgoto').height() + 25 : 0;
        $tableBody.css('height', windowHeight - ($tableBody?.offset()?.top + $tableBodyBottom));
    }

    const getBrand = async () => {
        await http.get(`${SoftwareManagementAPI.getSoftwareBrandList}`, {
            headers: {
                'Content-Type': 'application/json',
                brandId: BRAND_ID,
            },
            data: {}
        }).then(async response => {
            if (response && response.data?.branList) {
                setBrandList(_.orderBy(response.data.branList, ['BRAND_NAME']));
                if (response.data?.branList?.length === 1) {
                    setBrand(response.data.branList[0].ID)
                    await getModelList(response.data.branList[0].ID);
                }
                if (brandValue) {
                    setBrand(brandValue);
                    await getModelList(brandValue);

                }
            }
        }).catch(err => {
        });
    }

    const getModelList = (brandId) => {
        return new Promise(async (resolve, reject) => {
            setLoaderIconVisible(true)
            await http.get(subscriptionManagement.getSubscriptionList,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'brand_id': brandId,
                        'type': 'modelList',
                        'customer_id': KCStore.getItem("customerId")
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    setModelList(data);
                    if (data?.length === 1) {
                        setModel(data[0].model_id);
                        setInitialDisable(true)
                    }
                    setLoaderIconVisible(false)
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    const getSubscriptionData = async (currentPageNumber = currentPage, isClear = false, brandValueParam, modelValueParam) => {
        setLoaderIconVisible(true);
        let url = subscriptionManagement.getSubscriptionList;
        let headers = {
            'Content-Type': 'application/json',
            'customer_id': KCStore.getItem("customerId"),
            'page': currentPageNumber,
            'page_size': pageSize,
            'type': 'manageSub',
            'cgid': KCStore.getItem("custGroupID")
        }
        if (searchValue && !isClear) {
            headers['search_text'] = searchValue;
        }
        if ((brand && !isClear)) {
            headers['brand_id'] = brand;
        }
        if (model && !isClear) {
            headers['product_id'] = model;
        }
        if ((brandValue) && !searchClear && !isClear) {
            headers['brand_id'] = brandValue;
        }
        if (modelValue && !searchClear && !isClear) {
            headers['product_id'] = modelValue;
        }

        if (brandValueParam) {
            headers['brand_id'] = brandValueParam;
        }
        if (modelValueParam) {
            headers['product_id'] = modelValueParam;
        }

        http.get(url, {
            headers, data: {}
        }).then(async response => {
            if (response && response.data) {
                setCurrentPage(currentPageNumber);
                setLoaderIconVisible(false);
                if (response.data?.result) {
                    setSubscriptionData(response.data?.result);
                    setTotalPages(Math.ceil(response.data?.totalCount / pageSize))
                }
                updateDimensions();
            }
        }).catch(err => {
            setLoaderIconVisible(false);
        });
    }

    const onPageChange = (currentPageNumber) => {
        getSubscriptionData(currentPageNumber)
    }

    const putActiveDeactive = (subscription) => {
        setLoaderIconVisible(true);
        let token = KCStore.getItem("Access-Token");
        let loggedinUser;
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        loggedinUser = loginUser.username;
        const activeValueKey = subscription?.subscription_status !== null && subscription?.subscription_status !== 'null' ? subscription.subscription_status === 0 ? 'INACTIVE' : 'ACTIVE' : subscription.Activation_status;
        const reqData = {
            unitIds: [subscription.unitId],
            activationStatus: activeValueKey === "ACTIVE" ? "INACTIVE-ONLINE" : "ACTIVE",
            assetNumber: subscription.Asset_Number,
        };
        const isUnitPrepaidDateEnded = subscription?.unit_prepaid_end_date ? moment(moment(subscription?.unit_prepaid_end_date).format('YYYY-MM-DD')).diff(moment()) > 0 ? false : true : false
        const subscriptionDetails = KCStore.getItem('subscriptionDetails') ? JSON.parse(KCStore.getItem('subscriptionDetails')) : {};
        const url = subscriptionDetails.is_new_customer === 0 && isUnitPrepaidDateEnded ? OrganizationViewAPI.active_Deactive_Unit : subscriptionDetails.is_new_customer === 1 && subscription.subscription_status === 0 && isUnitPrepaidDateEnded ? OrganizationViewAPI.active_Deactive_Unit : subscriptionManagement.updateSubscription;
    
        return new Promise((resolve, reject) => {
            http.put(url, reqData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                        'customerid': KCStore.getItem("customerId"),
                        'useremail': loggedinUser,
                    }
                }
            )
                .then(async function (response) {
                    setLoaderIconVisible(false);
                    await getSubscriptionData(currentPage);
                    if (openSnackbar) openSnackbar( formatMessage({ id: 'KC2351' }), snackbarClosingSecond);
                    resolve(response);
                }).catch(function (err) {
                    setLoaderIconVisible(false);
                    reject(err);
                });
        });
    }

    let columns = [
        { path: "#", labelStringID: "KC0169" },
        { path: "invoice", labelStringID: "KC0104" },
        { path: "date", labelStringID: "KC0105" },
        { path: "", labelStringID: "KC0050" },
        { path: "", labelStringID: "KC2447" },
        { path: "", labelStringID: "KC2448" },
        { path: "", labelStringID: "KC1775" },
        { path: "", labelStringID: "KC2704" },
        { path: "", labelStringID: "KC2449" },
        { path: "", labelStringID: "KC2458" },
    ];
    let rowNumber = (currentPage * pageSize) - pageSize;

    $(function () {
        $('[data-toggle="popover"]').popover({
            trigger: 'hover',
            'placement': 'bottom'
        })
    })

    const clearQueryParam = () => {
        brandValue = null;
        modelValue = null;
        setClearSearch(true)
    }

    const isValidDate = toggleSubscription?.subscription ? moment(moment(toggleSubscription?.unit_prepaid_end_date).format('YYYY-MM-DD')).diff(moment()) > 0 ? false : true : false
    const searchButtonEnable = brandList.length > 1 && modelList.length > 1 ? brand !== '' || model !== '' || searchValue !== "" : modelList.length > 1 ? model !== '' || searchValue !== '' : searchValue !== "";
    return (
        <div id="adminWrapper">
            <div className="searchToggleTransparent"></div>
            <div className="toggleTransparent"></div>
            <div className="sideNavToggleTransparent"></div>
            <div className="userToggleTransparent"></div>
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <div id="page-content-wrapper">
                <div className="lightBgWrapper">
                    <div className="navBarXScroll">
                        <ul>
                            {headerRow(formatMessage, 'Manage Subscription')}
                        </ul>
                    </div>
                </div>
                <div className="tableSHeader">
                    <ul>
                        <li>
                            <div className={`form-group ${brandList.length === 1 ? 'disabled' : ''}`}>
                                {<select className="form-control" id="selectBrandId" value={brand} onChange={(e) => {
                                    if (e.target.value === '') {
                                        if (brandValue) {
                                            clearQueryParam()
                                        }
                                        if (brandList.length > 1) {
                                            setBrand('');
                                        }
                                        if (modelList.length > 1) {
                                            setModel('');
                                        }
                                        setBrand(e.target.value);
                                        setModelList([]);
                                        getSubscriptionData(1, true);
                                    } else {
                                        setBrand(e.target.value);
                                        getModelList(e.target.value);
                                        getSubscriptionData(1, true, e.target.value)
                                    }
                                }}>
                                    <option value=""><FormattedMessage id='KC0651' /></option>
                                    {brandList?.map((i) => <option value={i.ID}>{i.BRAND_NAME}</option>)}
                                </select>}
                            </div>
                        </li>
                        <li>
                            <div className={`form-group ${brand === '' || modelList.length === 1 ? 'disabled' : ''}`}>
                                <select className={`form-control`} id="selectModelId" value={model} onChange={(e) => {
                                    if (e.target.value === '') {
                                        setModel(e.target.value);
                                        getSubscriptionData(1, true, brand);
                                    } else {
                                        setModel(e.target.value);
                                        getSubscriptionData(1, true, brand, e.target.value)
                                    }
                                }}>
                                    <option value=""><FormattedMessage id='KC2446' /></option>
                                    {modelList?.map((i) => <option value={i.model_id}>{i.model_name}</option>)}
                                </select>
                            </div>
                        </li>
                        <li>
                            <div className="form-group">
                                <span className="searchIcon"></span>
                                <input type="search" className="form-control searchBar" placeholder={formatMessage({ id: 'KC0037' })} value={searchValue} onChange={(e) => {
                                    if (e.target.value === '') {
                                        getSubscriptionData(1, true)
                                    }
                                    setSearchValue(e.target.value)
                                }
                                } />
                            </div>
                        </li>
                        <li>
                            <button type="button" className={`btn searchBtn ${brand !== '' || model !== '' || searchValue !== "" ? '' : 'disabled'}`} onClick={() => {
                                getSubscriptionData(1)
                            }}><FormattedMessage id="KC0037" /></button>
                            {searchButtonEnable ?
                                <button type="button" className={`btn clearBtn`} onClick={async () => {
                                    if (brandValue) {
                                        clearQueryParam()
                                    }
                                    if (brandList.length > 1) {
                                        setBrand('');
                                    }
                                    if (modelList.length > 1) {
                                        setModel('');
                                    }
                                    setSearchValue('');
                                    getSubscriptionData(1, true);
                                }}><FormattedMessage id="KC2711" /></button>
                                : null}
                        </li>
                    </ul>
                </div>
                <form className="manageSubscriptionForm">
                    {subscriptionData.length === 0 && !loaderIconVisible ? <div className="noRecordsTable"><button type="button" className="btn noRecordsTableInfo  cursor-default">&nbsp;</button> <FormattedMessage id='KC2445' values={{ value: "Subscription" }} /></div> : null}
                    <div className="manageSubscriptionOuter">
                        <div className='manageSubscriptionTO'>
                            {subscriptionData.length ? <div className="manageSubscriptionT">
                                <TableHeader
                                    columns={columns}
                                />
                            </div> : null}
                            <div className="manageSubscriptionTScrl">
                                <div className="manageSubscriptionT">
                                    {subscriptionData?.map((subscription, index) => {
                                        const activeValueKey = subscription?.subscription_status !== null && subscription?.subscription_status !== 'null' ? subscription.subscription_status === 0 ? 'INACTIVE' : 'ACTIVE' : subscription.Activation_status;                                                                                
                                        let dayDiferance = 0;
                                        if (subscription.unit_prepaid_end_date) {
                                            const currentDate = moment().format('YYYY-MM-DD');
                                            const prepaidEnddate = subscription.unit_prepaid_end_date ? moment(subscription.unit_prepaid_end_date, 'YYYY-MM-DD') : "";
                                            dayDiferance = prepaidEnddate.diff(currentDate, 'days');
                                        }
                                        
                                        return (
                                            <ul key={index} className="tBody">
                                                <li>{rowNumber + index + 1}</li>
                                                <li>{subscription?.serial_no ? subscription.serial_no : '-'}</li>
                                                <li>{subscription?.Asset_Number ? subscription.Asset_Number : '-'}</li>
                                                <li>{subscription?.location_name ? subscription.location_name : '-'}</li>
                                                <li>{subscription.date_activation ? moment(subscription.date_activation).format(subscriptionManagementDateFormatter) : '-'}</li>
                                                <li>{subscription.unit_prepaid_end_date && subscription.subscription === "PREPAID" ? moment(subscription.unit_prepaid_end_date).format(subscriptionManagementDateFormatter) : '-'}</li>
                                                <li>{subscriptionField[subscription.subscription] ? subscriptionField[subscription.subscription] : '-'}</li>
                                                <li>{subscription.unit_prepaid_end_date && (subscription.subscription === "PREPAID" || subscription.subscription === "YES") && dayDiferance > 0 ? <span data-toggle="popover" data-content= {formatMessage({ id: 'KC2949' })}>{ dayDiferance } { dayDiferance > 1 ? " Days" : " Day"}</span> : '-'}</li>
                                                <li>${subscription.price}</li>
                                                <li>
                                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                                        <Switch
                                                            onChange={(e) => {
                                                                setToggleSubscription(subscription);
                                                                if (activeValueKey === "ACTIVE") {
                                                                    $('#deActivateModal').modal('show');
                                                                }
                                                                if (activeValueKey === "INACTIVE" || activeValueKey === "INACTIVE-ONLINE") {
                                                                    $('#unitActivateModal').modal('show');
                                                                }
                                                            }}
                                                            checked={activeValueKey === "ACTIVE" ? true : false}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            onColor={'#c5cdd7'}
                                                            offColor={'#ffffff'}
                                                            offHandleColor={'#0d3968'}
                                                            onHandleColor={'#0d3968'}
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                        )
                                    }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {subscriptionData.length && totalPages && totalPages > 1 ? <GoToPagination
                        isBlackBackground={false}
                        totalPageNumber={totalPages}
                        pageNumber={currentPage}
                        navigateTo={(p) => onPageChange(p)} /> : null}
                </form>

            </div>
            {/* <!-- Deactivate unit --> */}
            <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="deActivateModal" tabindex="-1" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="confirmMContententD">
                                <div className="h3Title"><FormattedMessage id='KC2456' /></div>
                                {toggleSubscription.subscription === 'YES' ? <p><FormattedMessage id='KC2508' /> <br /> <FormattedMessage id='KC2509' /></p> : toggleSubscription.subscription === 'PREPAID' && toggleSubscription.location_name ? <p><FormattedMessage id='KC2510' /> <br /> <FormattedMessage id='KC2511' values={{ date: toggleSubscription.unit_prepaid_end_date ? moment(toggleSubscription.unit_prepaid_end_date).format(subscriptionManagementDateFormatter) : '-' }} /></p> : ''}
                            </div>
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn confirmYes" data-dismiss="modal" type="button"><FormattedMessage id="KC0021" /></button>
                            <button className="btn confirmNo" data-dismiss="modal" type="button" onClick={() => putActiveDeactive(toggleSubscription)}><FormattedMessage id="KC0558" /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Deactivate unit -->
            <!-- Activate Unit --> */}
            <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="unitActivateModal" tabindex="-1" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="confirmMContententD">
                                <div className="h3Title">{!isPaymentDetailAvailable ? <FormattedMessage id='KC2494' /> : isPaymentDetailAvailable && (toggleSubscription.subscription === 'PREPAID' || toggleSubscription.subscription === 'YES') && isValidDate ? <FormattedMessage id='KC2475' /> : <FormattedMessage id='KC2457' />} {!isPaymentDetailAvailable ? <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> : null} </div>
                                <p>{!isPaymentDetailAvailable ? <FormattedMessage id='KC2507' /> : isPaymentDetailAvailable && (toggleSubscription.subscription === 'PREPAID' || toggleSubscription.subscription === 'YES') && isValidDate ? <FormattedMessage id='KC2493' /> : ''}</p>
                            </div>
                        </div>
                        {!isPaymentDetailAvailable ?
                            <div className="modal-footer">
                                <button className="btn confirmNo" data-dismiss="modal" type="button" onClick={() => props.history.push(`/subscription/payments`)}><FormattedMessage id="KC2488" /></button>
                            </div> : isPaymentDetailAvailable && (toggleSubscription.subscription === 'PREPAID' || toggleSubscription.subscription === 'YES') && isValidDate ?
                                <div className="modal-footer textAlignCenter">
                                    <button className="btn confirmYes" data-dismiss="modal" type="button" onClick={() => putActiveDeactive(toggleSubscription)}><FormattedMessage id="KC0611" /></button>
                                    <button className="btn confirmNo" data-dismiss="modal" type="button"><FormattedMessage id="KC0580" /></button>
                                </div> :
                                <div className="modal-footer textAlignCenter">
                                    <button className="btn confirmYes" data-dismiss="modal" type="button"><FormattedMessage id="KC0021" /></button>
                                    <button className="btn confirmNo" data-dismiss="modal" type="button" onClick={() => putActiveDeactive(toggleSubscription)}><FormattedMessage id="KC0558" /></button>
                                </div>}
                    </div>
                </div>
            </div>
            {/* <!-- //Activate Unit --> */}
            <UserManagementHeader headerName={formatMessage({ id: 'KC1668' })} headerNameStringID="KC1668" activeClass="billingPortalNav" />
        </div >
    )
};


export default injectIntl(ManageSubscription)