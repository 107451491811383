import _ from 'lodash';
import { connectivityStatus } from '../../service/api';
import http from '../../service/httpService';
import {
    GET_DEVICE_CONNECTIVITY_STATUS,
    GET_DEVICE_CONNECTIVITY_STATUS_SUCCESS,
    GET_DEVICE_CONNECTIVITY_STATUS_FAIL,
    SERIAL_NUMBER_CHANGED, 
    BRAND_CHANGED,
    CLEAR
} from './actionType';

let headers = {
    'Content-Type': 'application/json',
};
//  get device connectivity status
export const getDeviceConnectivityStatus = (brandID, serialNumber) => dispatch => {
    dispatch({ type: GET_DEVICE_CONNECTIVITY_STATUS });
    http.get(`${connectivityStatus.deviceConnectivityStatus}?brandId=${brandID}&serialNumber=${serialNumber}`, {
        headers,
        data: {}
    }).then(response => {
        if (response && response.data) {
            dispatch({ type: GET_DEVICE_CONNECTIVITY_STATUS_SUCCESS, payload: response.data.body.responseCode })
        } else {
          dispatch({ type: GET_DEVICE_CONNECTIVITY_STATUS_FAIL });
        }
    }).catch(err => {
        console.log('Failed to get device status: ', err);
        dispatch({ type: GET_DEVICE_CONNECTIVITY_STATUS_FAIL });
    });
}

export const serialNumberChanged = (text) => {
    return {
        type: SERIAL_NUMBER_CHANGED,
        payload: text
    }
};

export const brandChanged = (text) => {
    return {
        type: BRAND_CHANGED,
        payload: text
    }
};


// verify other device
export const verifyOtherDevice = () => dispatch => {
    dispatch({ type: CLEAR });
}
