import {SOFTWARE_AVAILABLE_UPDATES_REQUEST,SOFTWARE_AVAILABLE_UPDATES_SUCCESS,SOFTWARE_AVAILABLE_UPDATES_FAIL} from '../../../../actions/navigation/softwareManagement/softwareAvailableUpdatesActions';
const initialState = {
    loaded: false,
    loading: false,
    loaderIconVisible:false,
    softwareAvailableUpdatesInformation: {'Software_List':[]},
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case SOFTWARE_AVAILABLE_UPDATES_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                loaderIconVisible:false,

                
            }
        case SOFTWARE_AVAILABLE_UPDATES_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                loaderIconVisible:true,
                softwareAvailableUpdatesInformation: action.softwareAvailableUpdatesInformation,
               
            }
        case SOFTWARE_AVAILABLE_UPDATES_FAIL:
            return {
                ...state,
                loading: true,
                loaded: false,
                errormsg: '',
                softwareAvailableUpdatesInformation:[],
            }
        default:
            return state
    }
}

