import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Link } from "react-router-dom";
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import ServiceMgmtList from './ServiceMgmtList';
import UnitErrors from './unitErrors';
import authHelper from '../../utils/authHelper';
import { BaseService, WarrantyAPI } from '../../service/api';
import http from '../../service/httpService';
import WarrantyWidget from '../unitManagement/widget/warrantywidget';
import { searchVisible, searchText } from '../../actions/advanceSearch/advanceSearchAction';
import { searchData_function} from '../globalSearchBar/selectOption';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import constant from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

import KCStore from '../../utils/kcStorage';

am4core.useTheme(am4themes_animated);

class ServiceManagement extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: true,
			warrantyResponseData: [],
			currentPercentageUsed: 0,
			currentPercentageUsed_width: { width: "0%" },
		}
	}

	componentDidMount() {
		this.props.searchVisible(true);
		this.setState({ loaderIconVisible: true });
		
		this.getWarrantyinformations();
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	// componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	getWarrantyinformations = () => {
		let { searchData } = this.state;
		const unitID = KCStore.getItem("unitID");
		const brandID = KCStore.getItem("unitBrandId");
		let header = {
			'Content-Type': 'application/json',
		}
		let url = `${BaseService.root}${WarrantyAPI.Warrantyinformations}?unitId=${unitID}&brandId=${brandID}`;

		//searchData_function
		let _search = searchData_function(searchData);
		if( _search ){
			url = `${url}${_search}`;
		}

		this.setState({ loaderIconVisible: true });
		http({
			method: "GET",
			url: url,
			headers: header,
			data: {},
		}).then(response => {
			this.setState({ warrantyResponseData: response.data, loaderIconVisible: false });
			const progVal = response.data.graphData.value;
			this.setState({
				currentPercentageUsed_width: { width: `${progVal}%`, background: response.data.graphData.color, loaderIconVisible: false }
			});
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	goToLoginPage = () => {
		this.props.history.push('/');
	}

	errorCloseModel = () => {
		authHelper.deleteLocalSession();
		this.props.history.push('/');
	}

	// woopra event
	errorEvent = (e) => {
		console.log(`${constant.SERVICE_MGMNT_CURRENT_AND_HISTORIC_ERRORS}-${e.trackTitle}`)
		WoopraEvents(`${constant.SERVICE_MGMNT_CURRENT_AND_HISTORIC_ERRORS}-${e.trackTitle}`);
	}

	render() {

		const { currentLocation, currentUnit } = this.props;
		let { loaderIconVisible } = this.state;
		const { formatMessage } = this.props.intl;
		
		return (
			<React.Fragment>
				<div id="wrapper" className='serviceManagementWsearch'>
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<GlobalHeaderLayout activeClass="serviceNav" />
					<div id="page-content-wrapper" class="withSearchBar">
						<div className="pageHeader">
							<ul>
								<li>
									<Link to="/serviceManagement" title={formatMessage({ id: 'KC0185' })} className="active"><FormattedMessage id="KC0185"/></Link>
								</li>
								<li>
									<Link to="/serviceManagement/historicErrors" title={formatMessage({ id: 'KC0186' })} class="disabledqw" onClick={(e)=>{e.trackTitle="Historic Errors";this.errorEvent(e)}}><FormattedMessage id="KC0186"/></Link>
								</li>
							</ul>
						</div>
						<div class="gridViewTable gridViewSMcolm39DSM">
							<div class="gridView">
								<UnitErrors currentUnit={currentUnit} />
								<WarrantyWidget page={"SeriveManagement"} classValue="colm6D colm6D1"/>
							</div>
						</div>
						<ServiceMgmtList currentUnit={currentUnit} currentLocation={currentLocation} loaderIconVisible={loaderIconVisible} OnCloseSessionModal={() => this.goToLoginPage()} />
					</div>
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		currentLocation: state.entityReducer.currentLocation,
		currentUnit: state.entityReducer.currentUnit,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ 
		searchVisible,
		getCurrentLocation, 
		getCurrentUnit,
		searchText
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ServiceManagement));
