
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { unregister } from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
// multilingual
import { I18nProvider } from './locale/locale-provider';
var browserHistory = BrowserRouter.browserHistory;

//registerServiceWorker();
unregister();

ReactDOM.render(
    <Provider store={store}  >
        <I18nProvider>
            <BrowserRouter history={browserHistory}>
                <App/>
            </BrowserRouter>
        </I18nProvider>
    </Provider>
    , document.getElementById('root'));
