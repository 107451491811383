import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { changeLocale, setLanguageList, setLocale } from '../../actions/localeAction/localeAction';
import { LanguagePreference } from '../../service/api';
import http from '../../service/httpService';
import { LOCALE_IMG_MAP } from '../../locale/constant';
import KCStore from '../../utils/kcStorage';

const langLogo = {
    "en-US": require('../../images/countries/English.png'),
    "zh-CN": require('../../images/countries/Chinese.png'),
    "es-ES": require('../../images/countries/Spanish.png'),
    "fr-FR": require('../../images/countries/France.png'),
};

class LanguagePreferenceCommon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            httpPendingCount: 0,
        };
    }

    componentDidMount() {
        // API CALL
        const isLanguageListEmpty = this.props.languageList.length == 0;
        const isDefaultLanguage = this.props.defaultLocale;
        this.setState({
            httpPendingCount: [isLanguageListEmpty, isDefaultLanguage].filter(item => item).length
        });
        if (isLanguageListEmpty) {
            this.getLanguages();
        }
        if (isDefaultLanguage) {
            this.getUserDefaultLanguage();
        }
    }

    decrementHTTPPendingCount() {
        const httpPendingCount = this.state.httpPendingCount - 1;
        this.setState({
            httpPendingCount
        });
        if (httpPendingCount === 0) {
            this.setState({
                loaderIconVisible: false
            });
        }
    }

    // get list of languages
    getLanguages = () => {
        this.setState({
            loaderIconVisible: true
        });
        const getAPI = LanguagePreference.getLanguages;
        http({
            method: 'GET',
            url: getAPI,
            headers: {
                'Content-Type': 'application/json',
            }, data: {},
        }).then((response) => {
            if (response.data && response.data.responseCode == 200) {
                this.props.setLanguageList(response.data.data);
                this.decrementHTTPPendingCount();
            }
        }).catch((err) => {
            console.log('Error :', err);
            this.decrementHTTPPendingCount();
        });
    };

    // Get user default/Selected language
    getUserDefaultLanguage = () => {
        this.setState({
            loaderIconVisible: true
        });
        const getDefaultLocaleAPI = LanguagePreference.getUserDefaultLocale;
        let userEmail = KCStore.getItem('user');
        http({
            method: 'GET',
            url: getDefaultLocaleAPI,
            headers: {
                'Content-Type': 'application/json',
                email: userEmail,
                'base_country': KCStore.getItem('basecountry'),
                'emulation': KCStore.getItem('emulation') ? true : false
            },
            data: {}
        }).then((response) => {
            if (response.data && response.data.responseCode == 200) {
                this.props.setLocale(response.data.data.language_code);
                this.decrementHTTPPendingCount();
            }
        }).catch((err) => {
            console.log('Error :', err);
            this.decrementHTTPPendingCount();
        });
    };

    handleChange = (language) => {
        this.setState({
            loaderIconVisible: true
        });
        let userEmail = KCStore.getItem('user');
        let data = {};
        let user = {};
        user['email'] = userEmail;
        user['preference_value'] = language.Id.toString();
        data['user'] = user;
        if (userEmail) {
            http({
                method: 'PUT',
                url: LanguagePreference.updateLocale,
                headers: {
                    'Content-Type': 'application/json',
                    'base_country': KCStore.getItem('basecountry'),
                    'emulation': KCStore.getItem('emulation') ? true : false
                },
                data,
            }).then((response) => {
                if (response.data && response.data.responseCode == 200) {
                    this.props.changeLocale(language);
                    this.setState({
                        loaderIconVisible: false
                    });
                }
            }).catch((err) => {
                console.log('Failed to updateLanguage', err);
                this.setState({
                    loaderIconVisible: false
                });
            });
        } else {
            this.props.changeLocale(language);
            this.setState({
                loaderIconVisible: false
            });
        }
    };


    render() {
        const { languageList, locale } = this.props;
        const { loaderIconVisible } = this.state;
        const localeImgMap = LOCALE_IMG_MAP;
        const langFilter = languageList && languageList.filter((i) => i.language_code === locale);
        return (
            <div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <li class="nav-item">
                    <div class="dropdown multiLanguage">
                        <button class="btn btn-secondary dropdown-toggle languageSelection" type="button" id="langDropdownBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {langFilter.length > 0 ? <img alt="" src={langLogo[langFilter[0].language_code]} class="langflag" /> : ""}
                            {localeImgMap[locale]}
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="langDropdownBtn">
                            {
                                languageList && languageList.length > 0 ? languageList.map((val, key) =>
                                    <Link key={key} className="dropdown-item" to="#" onClick={(e) => this.handleChange(val)}>
                                        <img alt="" src={langLogo[val.language_code]} class="langflag" />
                                        <span class="langImg">{localeImgMap[val.language_code]}</span>{val.locale}</Link>
                                ) : null
                            }
                        </div>
                    </div>
                </li>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        locale: state.localeInfo.locale,
        languageList: state.localeInfo.languages,
        defaultLocale: state.localeInfo.defaultLocale
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changeLocale, setLanguageList, setLocale }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePreferenceCommon);
