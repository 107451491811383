import React, { Component } from 'react';
import { Link } from "react-router-dom";
import WoopraEvent from '../../../woopraConfig/WoopraEvents';
import constant from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage } from 'react-intl';

class UnitErrorStatus extends Component {
    constructor(props) {
		super(props);
		this.state = { 

		}

    }

    // Woopra events
    getUnitErrorAction =()=> {
        WoopraEvent(`${constant.SERVICE_MANAGEMENT}`);
    }
    
    	render() {
            const UnitErrorStatusData = this.props.UnitErrorStatusData;  
            const ErrorsCountInformation = this.props.ErrorsCountInformation.ErrorsCountInformation;

        var errorWidgetCritical;
        var errorWidgetNonCritical;
        var errorDescription;
        if (ErrorsCountInformation.totalErrors !== undefined && ErrorsCountInformation.totalErrors !== 0) {     
                  
            errorWidgetCritical = <Link to='/serviceManagement'> <div className="statusH4 leftAlign poorIcon"><span>{ErrorsCountInformation.criticalCount}</span> <FormattedMessage id='KC0156'/></div></Link>;
            errorWidgetNonCritical = <Link to='/serviceManagement'> <div className="statusH4 leftAlign goodIcon"><span>{ErrorsCountInformation.nonCriticalCount}</span><FormattedMessage id='KC0157'/></div></Link>;
            errorDescription = <Link to='/serviceManagement'> <div class="statusH6 poorIcon"><p style={{color: ErrorsCountInformation && ErrorsCountInformation.currentDeviceError && ErrorsCountInformation.currentDeviceError.is_critical=== 1 ? '#F93251':"#FFBE00"}} title={ErrorsCountInformation && ErrorsCountInformation.currentDeviceError ? ErrorsCountInformation.currentDeviceError.description:""}><span>{ErrorsCountInformation && ErrorsCountInformation.currentDeviceError && ErrorsCountInformation.currentDeviceError.error_code ? ErrorsCountInformation.currentDeviceError.error_code + " :":""}</span>  {ErrorsCountInformation && ErrorsCountInformation.currentDeviceError ? ErrorsCountInformation.currentDeviceError.description:""} </p></div></Link>
        }
        else {
            errorWidgetCritical = <div className="valH1WStatusDiv noErrorStatus">
                <FormattedMessage id='KC0072'/>
        <span className="valH1WStatusSpan">
                    &nbsp;
        </span>
            </div>;
            errorWidgetNonCritical = <div></div>;
        }
            return (
                <React.Fragment>
                    <div className="colm4D colm4D1">
                        <div className="colmDi">
                            <h4><FormattedMessage id='KC0158' /> </h4>
                            {ErrorsCountInformation && ErrorsCountInformation.totalErrors && ErrorsCountInformation.totalErrors.length > 1 ? <Link to='/serviceManagement'
                                onClick={()=>this.getUnitErrorAction()}
                                ><div className="colmDiVal">
                                    {errorWidgetCritical}
                                    {errorWidgetNonCritical}
                                    {errorDescription}  
                            </div> </Link> : <Link to='/serviceManagement'
                             onClick={() =>this.getUnitErrorAction()}><div className="colmDiVal"> 
                                    {errorWidgetCritical}
                                    {errorWidgetNonCritical}
                                    {errorDescription}
                                    </div> </Link>
                            }
                        </div>
                    </div>
                </React.Fragment>
            )
        }

}
export default UnitErrorStatus;