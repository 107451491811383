import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { smartGroupAPI, MenuUploadAPI } from '../../../service/api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import http from '../../../service/httpService';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { getUnitDetails } from '../../../actions/smart-groups/getSmartGroupUnit';
import { getUpdateHistory } from '../../../actions/smart-groups/getSmartGroupUpdateHistory';
import { deleteUnitList, setUnitDeleteData } from '../../../actions/smart-groups/deleteSmartGroupUnit';
import moment from '../../../utils/momentHelper.js';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import { BRAND_ID_VALUE, CUSTOMER_ID } from '../../../utils/appConstants';
import { TableHeader } from '../../controls/tableHeader';
import authService from '../../../service/authService';
import Tooltip from 'react-tooltip-lite';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../utils/kcStorage';
class menuAddgroupsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            unitDetails: null,
            isModalLoading: null,
            loaderIconVisible: false,
            isDeleted: false,
            excludedProductList: [],
            unitLoading: false,
            isEdit: false,
            isDelete: true,
            isEdit: false,
            allSelect: false,
            isIndeterminateSelect: false,
            smartGroupInfo: null,
            customerId: null,
            unitSelectFinishBtn: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            storeInstalledItemInfo: {},
            locationInfo: {},
            existingSmartGroupID: [],
            sortColumn: { path: "", order: "", sort_color: "#63778a" },
        };
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
    }

    componentDidMount = () => {
        $('body').removeClass("modal-backdrop show");
        document.body.style.backgroundColor = "#F2F2F2";

        if (this.props.location && this.props.location.state) {
            this.setState({
                userCheckedData: this.props.location.state.userCheckedData,
            });
        }
        let storeInstalledItemInfo = this.props.storeInstalledItemInfo && this.props.storeInstalledItemInfo.SOFTWARE_ID ? this.props.storeInstalledItemInfo : JSON.parse(KCStore.getItem('selectedItemsInfo'));
        KCStore.setItem('selectedItemsInfo', JSON.stringify(storeInstalledItemInfo))

        let locationInfo = this.props.location && this.props.location.FILE_NAME ? this.props.location : JSON.parse(KCStore.getItem('locationInfo'));
        KCStore.setItem('locationInfo', JSON.stringify(locationInfo))

        this.setState({
            storeInstalledItemInfo: storeInstalledItemInfo,
            locationInfo: locationInfo,
            existingSmartGroupID: this.props.location && this.props.location.smart_groups_ids ? this.props.location.smart_groups_ids.split(',') : [],
        });

        this.getProductsDetails();
    }

    componentWillMount() {
        if (this.props.location && this.props.location.state) {
            this.setState({
                userCheckedData: this.props.location.state.userCheckedData,
            });
        }

        let storeInstalledItemInfo = this.props.storeInstalledItemInfo && this.props.storeInstalledItemInfo.SOFTWARE_ID ? this.props.storeInstalledItemInfo : JSON.parse(KCStore.getItem('selectedItemsInfo'));
        KCStore.setItem('selectedItemsInfo', JSON.stringify(storeInstalledItemInfo))

        let locationInfo = this.props.location && this.props.location.FILE_NAME ? this.props.location : JSON.parse(KCStore.getItem('locationInfo'));
        KCStore.setItem('locationInfo', JSON.stringify(locationInfo))

        this.setState({
            storeInstalledItemInfo: storeInstalledItemInfo,
            locationInfo: locationInfo,
            existingSmartGroupID: this.props.location && this.props.location.smart_groups_ids ? this.props.location.smart_groups_ids.split(',') : [],
        });
    }

    getProductsDetails = () => {
        this.setState({
            loaderIconVisible: true,
            allSelect: false,
            isDelete: false,
            isEdit: false,
        });

        let queryString;
        queryString = `customerId=${KCStore.getItem("customerId")}`

        let url = `${smartGroupAPI.createSmartGroup}?${queryString}`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                let existingSmartGroupID = this.state.existingSmartGroupID;
                let responseValue=response.data;
                if (existingSmartGroupID.length > 0 && responseValue.length > 0) {
                    existingSmartGroupID.forEach(r => {
                        const index = +r;
                        let order = 0;
                        responseValue.forEach(item => {
                            order = order + 1;
                            if (item.smartGrpId === index) {
                                responseValue[order - 1].checked = true;
                            }
                        });
                    });
                }
                let isDelete = false;
                for (let i = 0; i < responseValue.length; i++) {                                   
                    isDelete = await this.checkDeleteAccess(responseValue[i]);
                    responseValue[i].isAuthorized = isDelete ? isDelete : false;
                };

                let checked =responseValue.filter(c => c.checked === true);


                this.setState({
                    excludedProductList: responseValue,
                    loaderIconVisible: false,
                    isDelete:checked.length > 0 ? true : false,
                    ...this.getCheckBoxstate(responseValue.length,checked.length)
                });
               
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    onSuccessCloseModal = (e) => {
        this.setState({
            success_modal: {
                open: false
            }
        });
        this.props.actions.setUnitDeleteData(null);
        this.props.history.push({ pathname: "/menuLibrary", });
    }

    closeModel = (err) => {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            }
        });
    }

    handleChange = async (e) => {
        var rolesList = [...this.state.excludedProductList];
        let allSelect = e.target.checked
        rolesList.forEach(r => {
            r.checked = r.isAuthorized ? allSelect : false;
        });
        let checked = rolesList.filter((i)=>i.checked === true);
        this.setState({
            excludedProductList: rolesList,
            allSelect: rolesList.length === checked.length,
            isDelete: checked.length > 0,
            isEdit:false,
            isIndeterminateSelect: false
        })

    };

    getCheckBoxstate(ListCount,SelectedCount) {
        if (SelectedCount == ListCount) {
        return { allSelect: true, isIndeterminateSelect: false };
        } else if (SelectedCount == 0 && ListCount != 0) {
        return { allSelect: false, isIndeterminateSelect: false };
        } else if (ListCount >= SelectedCount) {
        return { allSelect: true, isIndeterminateSelect: true };
        }
    }

    onSelectRole = async (e, item) => {
        if (this.state.excludedProductList && this.state.excludedProductList.length > 0) {
            const rolesList = [...this.state.excludedProductList];
            const index = rolesList.indexOf(item);
            rolesList[index] = { ...rolesList[index] };
            rolesList[index].checked = rolesList[index].isAuthorized ? e.target.checked: false;
            let checked = rolesList.filter(c => c.checked === true);

            this.setState({
                excludedProductList: rolesList,
                isDelete:checked.length > 0 ? true : false,
                isEdit: checked.length === 1 ? true : false,
                ...this.getCheckBoxstate(rolesList.length,checked.length)
            })
        }
    }

    //For First Letter Added in Smart Group List
    getFirstLetter(name) {
        if (name) {
            var char = name.charAt(0).toUpperCase();
            return char;
        }
    }

    static getDerivedStateFromProps(props) {
        return { ...props };
    }

    onFinishClickHandler() {
        const { storeInstalledItemInfo, locationInfo } = this.state;
        let smartGroupIds;
        let smartGroupNames;
        let smartGroupKeyNames;
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(r => {
            if (r.checked) {
                if (smartGroupIds && smartGroupNames) {
                    smartGroupIds = smartGroupIds + "," + r.smartGrpId;
                    smartGroupNames = smartGroupNames + "," + r.smarGrpName;
                    smartGroupKeyNames = smartGroupKeyNames + "," + r.smartGroupKeyName;
                } else {
                    smartGroupIds = r.smartGrpId;
                    smartGroupNames = r.smarGrpName;
                    smartGroupKeyNames = r.smartGroupKeyName;
                }
            }
        });

        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		let brandName = KCStore.getItem("brandName");
        let req = {
            "thingList": "",
            "viewType": "smart-group-assignment",            
            "transferType": "",
            
            "smartgroupId":smartGroupIds ? smartGroupIds.toString() : "",
            "smartgroupName":smartGroupNames ? smartGroupNames.toString() : "",
            "operation":"assign",            
            "menuId":storeInstalledItemInfo.MENU_ID,
            "brandId": BRAND_ID_VALUE[brandName],
            "customer":CUSTOMER_ID,
            "cgid":KCStore.getItem("custGroupID"),
            "brandName":brandName,
            "oldSmartgroupIds": this.state.existingSmartGroupID.toString(),
            "smartGroupKeyNames": smartGroupKeyNames ? smartGroupKeyNames: "",         
        }

        this.setState({ loaderIconVisible: true });
        this.postData(MenuUploadAPI.getMenuUpdateStatus, req).then(response => {
            this.props.history.push(`/menuLibrary`);
        }).catch(err => {
            //Error Goes Here
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.message ? err.message : "KC1725"
                }
            });
        });
    }

    postData = (url, reqData) => {
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {
            http.post(url
                , reqData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'user': KCStore.getItem("user"),
                        // 'customer': KCStore.getItem("customerId"),                        
                        // 'basecountry': BASECOUNTRY_ID,
                        // 'brandid': KCStore.getItem("selectedbrandID"),
                    }
                }
            )
                .then(response => {
                    WoopraEvents(`${Constants.MENU_SG_ASSIGNED}`,reqData);
                    let { data } = response;
                    let { httpCode, responseCode, message } = data;
                    if (response && response.status === 200) {
                        this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: data.stringID || data.message 
                            },
                        });
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

    checkDeleteAccess = async (mediaValue) => {
		const userListEmail = JSON.parse(KCStore.getItem('childUsersEmail'));
		const { username } = JSON.parse(KCStore.getItem('loginInfo'));
		const roleName = KCStore.getItem('roleName') || '';

		let isDelete = false;
        if(mediaValue.createdBy && mediaValue.createdBy.toString().match('@')){
            isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(mediaValue.createdBy) || username === mediaValue.createdBy ? true : false;
        }else{
            isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
        }
        if (isDelete === false){
            return isDelete;
        }
		return isDelete;
	}

    render() {
        const { formatMessage } = this.props.intl;
        $(document).ready(function () {
            // Nav Toggle
            $('.collapse').off('click').on('click', function () {
                $(this).parents(".tBody").toggleClass('descExp');
            });
        });

        let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");

        let orderId = 1;
        let { isDeleted, unitDetails, isIndeterminateSelect,isModalLoading,sortColumn, smartGroupInfo, excludedProductList, loaderIconVisible, error_modal, success_modal, allSelect, unitSelectFinishBtn, unitLoading } = this.state;
        let units = unitDetails && unitDetails.units;
        units = UnitBuilder(units);
        let infoUnits = smartGroupInfo && smartGroupInfo.units;
        if (infoUnits) {
            infoUnits.addedUnits = infoUnits.addedUnits ? UnitBuilder(infoUnits.addedUnits) : [];
            infoUnits.removedUnits = infoUnits.removedUnits ? UnitBuilder(infoUnits.removedUnits) : [];
        }
        let columns = [
			{ path: "", label: "" },
			{ path: "", label: "#" },
			{ path: "smarGrpName", labelStringID: "KC0241" },
			{ path: "purposeName", labelStringID: "KC0242" },
			{ path: "groupsize", labelStringID: "KC1779" },
			{ path: "modified", labelStringID: "KC0542" },
			{ path: "", labelStringID: "KC0325" },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

        excludedProductList = _.orderBy(excludedProductList, [sortColumn.path], [sortColumn.order]);

		excludedProductList.forEach(item => {
			item.order_id = orderId++
		});

        if (isDeleted && isDeleted.msg) {
            success_modal = { open: true, message: isDeleted.msg };
        }

        const finishBtnClass = ['btn-default-text btn forwardNext'];

        if (unitSelectFinishBtn) {
            finishBtnClass.push('activeState');
        } else {
            finishBtnClass.push('disabled');
        }

        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>

                <div id="page-content-wrapper">
                    <div className="stickyTableHeader">
                        <div className="menuUpdateWrapper">
                            <div className="addUnitHeader">
                                <ul>
                                    <li>
                                        <Link to="/menuLibrary" title={formatMessage({ id: 'KC0989' })} className="backIcon"></Link>
                                    </li>
                                    <li> <FormattedMessage id="KC1780"/> </li>
                                    <li>
                                        <button className= {"btn-default-text btn forwardNext activeState"}
                                            onClick={() => this.onFinishClickHandler()} title={formatMessage({ id: 'KC0056' })}>&nbsp;<FormattedMessage id="KC0056"/></button>
                                    </li>
                                </ul>
                            </div>

                            <div class="orgTableOuter">
                                <div className="orgTable">
                                <TableHeader
									isIndeterminate={isIndeterminateSelect}
									selectAllChange={this.handleChange}
                                    sortColumn={sortColumn}
									onSort={this.onSort}
									columns={columns}
									isSelectAll={allSelect} />
                                </div>

                                <div class="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="false">

                                    {excludedProductList.length > 0 && excludedProductList.map((item, i) =>
                                        <div key={i}>
                                            <div class="orgTable">
                                                <ul className="tBody">
                                                    <li><button onClick={() => this.props.actions.getUnitDetails(item.smartGrpId)} role="button" data-toggle="collapse" data-parent="#availableUpdatesAccordion" href={"#availableUpdatesAcc" + item.smartGrpId} aria-expanded="false" aria-controls={"#availableUpdatesAcc" + item.smartGrpId} class="btn collapseExpand collapsed">&nbsp;</button></li>
                                                    <li>{i + 1}</li>                                                    
                                                    <li> <span class="sgFL"> {item.smartGroupKeyName?item.smartGroupKeyName:this.getFirstLetter(item.smarGrpName)}</span> {item.smarGrpName} </li>
                                                    <li>
                                                        {item.purpose.map((item1, i) =>
                                                            <span class="purposeTo" key={'item' + i}>
                                                                {item1.purposeName}
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li>{item.groupsize > 0 ? item.groupsize + " Units" : ""}</li>
                                                    <li>{moment.GetDateTimeByFormat(item.modified, 'LLL')}</li>
                                                    <li><button onClick={() => this.props.actions.getUpdateHistory(item.smartGrpId)} type="button" class="btn sgInfo" data-toggle="modal" data-target="#detailsInfoModal">&nbsp;</button></li>
                                                    <li>
                                                        {!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1748' })} direction="left" className="targetdisabledCheckboxAlignment">
                                                            <div className={ "customCheckbox customCheckboxWOlabel disabled"}>
                                                                <input id={i} name={i} type="checkbox"
                                                                    checked={item.checked}
                                                                    disabled
                                                                    onChange={(e) => this.onSelectRole(e, item)} />
                                                                <label htmlFor={i}></label>
                                                            </div>
                                                        </Tooltip> : <div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
                                                            <input id={i} name={i} type="checkbox"
                                                                checked={item.checked}
                                                                onChange={(e) => this.onSelectRole(e, item)} />
                                                            <label htmlFor={i}></label>
                                                        </div>}
                                                    </li>
                                                </ul>
                                            </div>

                                            <div id={"availableUpdatesAcc" + item.smartGrpId}
                                                data-parent="#availableUpdatesAccordion"
                                                class="panel-collapse collapse"
                                                role="tabpanel" aria-labelledby="availableUpdatesAccH1">

                                                <div class="panel-body orgUnitsList">
                                                    {unitLoading && <div className="text-center"> <FormattedMessage id="KC1151"/></div>}

                                                    {!unitLoading && <>
                                                        <ul class="tHead">
                                                            <li>#</li>
                                                            <li><FormattedMessage id="KC0101"/></li>
                                                            <li><FormattedMessage id="KC0062"/></li>
                                                            <li><FormattedMessage id="KC0102"/></li>
                                                            <li><FormattedMessage id="KC0064"/></li>
                                                            <li><FormattedMessage id="KC0104"/></li>
                                                            <li><FormattedMessage id="KC1152"/></li>
                                                            <li><FormattedMessage id="KC0050"/></li>
                                                            <li><FormattedMessage id="KC0061"/></li>
                                                        </ul>

                                                        {units && units.map((item, i) =>
                                                            <ul class="tBody" key={item.ID}>
                                                                <li> {i + 1} </li>
                                                                <li> <img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
                                                                <li> {item.BRAND_NAME} </li>
                                                                <li> {item.PRODUCT_TYPE} </li>
                                                                <li> {item.MODEL_NAME} </li>
                                                                <li> {item.UNITID} </li>
                                                                <li> {item.PRODUCT_TYPE_ID} </li>
                                                                <li> {item.location_name}</li>
                                                                <li> {item.group_name}</li>
                                                            </ul>)}
                                                    </>}

                                                </div>
                                                {unitLoading === false && <>

                                                    {((units && units.length === 0) || !units) && <div className="text-center"><FormattedMessage id="KC0568"/> </div>}

                                                    {units && units.length > 0 &&
                                                        <>
                                                            {unitDetails && unitDetails.total_unit_count > 3 &&
                                                                <button type="button" class="btn btn-disabled disabled">
                                                                    <span> + {unitDetails.total_unit_count - 3}</span> Units</button>}

                                                            {/* <Link className="btn btn-default" id="AssignUpdate" to={{
                                                                pathname: "/editsmartGrpAssignUpdateList",
                                                                state: {
                                                                    nameFromReport: item.smarGrpName,
                                                                    oldSmartGroupName: item.smarGrpName,
                                                                    selectedoptionsFromReport: "",
                                                                    oldSelectedPurposes: "",
                                                                    sumartGroupID: item.smartGrpId,
                                                                    pageFrom: "smartGroupList",
                                                                }
                                                            }} title="Edit Unit List">Edit Units</Link> */}
                                                        </>
                                                    }

                                                </>}

                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Update History Modal */}
                <div class="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="detailsInfoModal" tabindex="-1" role="dialog" aria-labelledby="detailsInfoModalLabel">
                    <div class="modal-dialog modal-md infoModal sgModal">
                        {isModalLoading && <div class="modal-content text-center"> <br /><br /><FormattedMessage id="KC1151"/><br /><br /></div>}
                        {!isModalLoading && <div class="modal-content">
                            <div class="modal-header modal-header-danger">
                                <FormattedMessage id="KC1781"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                            </div>
                            <div class="modal-body">
                                <div class="sgModalContent">
                                    <p><FormattedMessage id="KC0565"/></p>
                                    <div class="sgModalUnitsList">
                                        <ul class="tHead">
                                            <li><FormattedMessage id="KC0101"/></li>
                                            <li>&nbsp;</li>
                                            <li><FormattedMessage id="KC0566"/></li>
                                            <li><FormattedMessage id="KC0741"/></li>
                                        </ul>

                                        {infoUnits && infoUnits.addedUnits && infoUnits.addedUnits.map((item, i) =>
                                            <ul class="tBody" key={'unit-' + i}>
                                                <li><img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
                                                <li> {item.BRAND_NAME}<span>{item.BRAND_TYPE}</span></li>
                                                <li> {item.UNITID}<span>{item.UNIT_NUMBER}</span></li>
                                                <li> {moment.GetDateTimeByFormat(item.createdAt, 'LLL')}</li>
                                            </ul>
                                        )}
                                    </div>
                                    {infoUnits && infoUnits.addedUnits && infoUnits.addedUnits.length === 0 &&
                                        <div className="text-center"> <FormattedMessage id="KC0568"/></div>
                                    }
                                    <p><FormattedMessage id="KC0244"/></p>
                                    <div class="sgModalUnitsList">
                                        <ul class="tHead">
                                            <li><FormattedMessage id="KC0101"/></li>
                                            <li>&nbsp;</li>
                                            <li><FormattedMessage id="KC0566"/></li>
                                            <li><FormattedMessage id="KC0741"/></li>
                                        </ul>
                                        {infoUnits && infoUnits.removedUnits && infoUnits.removedUnits.map((item, i) =>
                                            <ul class="tBody" key={'remove' + i}>
                                                <li><img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
                                                <li> {item.BRAND_NAME}<span>{item.BRAND_TYPE}</span></li>
                                                <li> {item.UNITID}<span>{item.UNIT_NUMBER}</span></li>
                                                <li> {moment.GetDateTimeByFormat(item.createdAt, 'LLL')}</li>
                                            </ul>)}
                                    </div>
                                    {infoUnits && infoUnits.removedUnits && infoUnits.removedUnits.length === 0 && <div className="text-center"> <FormattedMessage id="KC0568"/></div>}
                                </div>                                
                            </div>
                        </div>}
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />                
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getUnitDetails,
            getUpdateHistory,
            deleteUnitList,
            setUnitDeleteData
        }, dispatch)
    }
}

const mapStateToProps = state => {
    return {
        customerId: state.userReducer.UserModel.user.customerId,
        unitDetails: state.smartGroups.unitList,
        unitLoading: state.smartGroups.isLoading,
        smartGroupInfo: state.smartGroupsHistory.unitHistory,
        isModalLoading: state.smartGroupsHistory.isLoading,
        isDeleted: state.smartGroupUnitDelete.unitListDelete
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(menuAddgroupsList)); 