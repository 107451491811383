import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { BaseService, ReportManagement } from '../../../service/api';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { format, addDays } from 'date-fns';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import  RenderBarChart from '../../controls/barChartComponent';
import { FormattedMessage, injectIntl } from 'react-intl';

import KCStore from '../../../utils/kcStorage';

var moment = require('moment');
var todaysDate = new Date();
let currentUnit;
const FRYPOT_NAME = 'frypot';

class TpmValueAllFrypots extends Component{

	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			filterType: "Month",
			dateRange: {
				selection: {
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection',
				},
			},
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			sortColumnDrinks: { path: "", order: "", sort_color: "#FFFFFF" },
			IsServerError: false,
			sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,           
			startDate: '',
			endDate: '',
			loaderIconVisible: true,
			hideDateRange: true,
			tpmValueFrypotInfo: [],
			datapickflag: false
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount = async () => {
		this.getTpmValueFrypots(this.state.filterType, "", "");
	}

	componentDidUpdate = async(prevProp, prevState) => {
		if (this.props.globalDropdownFilter !== "Year" && this.props.globalDropdownFilter !== "Quarter" && this.props.globalDropdownFilter !== "Day") {
			if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.filterType !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
				this.setState({ filterType: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
				this.handleTpmValueFrypot(this.props.globalDropdownFilter, '');
				await this.props.handleDropdownChangeEnd();
			} else {
				if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
					if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
						this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
						await this.props.handleDropdownChangeEnd();
					}
					if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
						this.handleChangeStart(this.props.globalDropdownFilterStartDate);
						await this.props.handleDropdownChangeEnd();
					}
				}
			}
		}
	}

	toggleDatePicker(e) {
		this.setState({ filterType: "Date Range" })
	}

	handleChangeStart(date) {
		if(this.state.startDate === date){
			return;
		}
		this.setState({
			startDate: date,
			datapickflag:true
		}, () => {
			if (this.state.endDate !== "") {
				let selectedtext = 'Date Range';
				this.handleTpmValueFrypot(selectedtext);
			}
		});
	}

	handleChangeEnd(date) {
		if(this.state.endDate === date){
			return;
		}
		this.setState({
			endDate: date,
			datapickflag:true
		}, () => {
			if (this.state.startDate !== "") {
				let selectedtext = 'Date Range';
				this.handleTpmValueFrypot(selectedtext);
			}
		});
	}

	getTpmValueFrypots(text, startDate, date) {
		this.setState({
			loaderIconVisible: true
		});
		let unitID = "";
		let brandID = "";
		currentUnit = this.props.properties.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;

		const header = this.getHeaders(text, startDate, date, unitID);
		let url = `${BaseService.root}${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + brandID}`;
		this.getData(url, header).then(response => {
			this.setState({
				tpmValueFrypotInfo: response,
				loaderIconVisible: false
			});
		}).catch(err => {
			console.log('err',err);
		});
	}

	onDropDownHandler(e) {
		const { nodeText:text } = e;
		this.setState({ type: text, datapickflag: text === 'Date Range', startDate:'', endDate:''})
		this.handleTpmValueFrypot(text);
	}

	handleTpmValueFrypot(text) {

		this.setState({ 
			filterType: text,
			tpmValueFrypotInfo: {} 
		});
		if (text === "Date Range") {
			if (this.state.startDate !== "" || this.state.endDate !== "") {
				this.getTpmValueFrypots(text, this.state.startDate, this.state.endDate);
			}
		}
		else {
			this.getTpmValueFrypots(text, "", "");
		}
	}

	getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', unitID = '') {
		let filterType;
		switch (type) {
			case 'Week':
				filterType = 'weekly';
				break;
			case 'Date Range':
				filterType = 'custom';
				break;
			default:
				filterType = 'monthly';
		}

		let header;

		if (filterType === 'custom' || filterType === 'weekly') {
			if (type === 'Week') {
				startDateFormatted = format(addDays(todaysDate,- 6), 'YYYY-MM-DD');
				endDateFormatted = format(todaysDate, 'YYYY-MM-DD');
			}
			else {
				startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');;
				endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');;
			}
			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'startdate': startDateFormatted,
				'enddate': endDateFormatted,
				'filterType': filterType,
				'showtrend': "false"
			};
		}
		else {
			startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'reqdate': startDateFormatted,
				'filterType': filterType,
				'showtrend': "false"
			};
		}

		return header;
	}

	getData(url, header) {

		return new Promise((resolve, reject) => {
			http
				.get(url,
					{
						headers: header,
						data: {}
					})
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	render(){
			let { tpmValueFrypotInfo, loaderIconVisible } = this.state;
			const { formatMessage } = this.props.intl;
			let displayTpmAllFrypots = (<div className="noResultMsg TPMGraphminheightdiv"><FormattedMessage id="KC0264"/></div>);
			let tpmFrypotsContent = tpmValueFrypotInfo ? tpmValueFrypotInfo.content : [];
			const chartProperty = {
				valueY: 'count',
				categoryX: 'date'
			};
			if (Array.isArray(tpmFrypotsContent) && tpmFrypotsContent.length) {
			displayTpmAllFrypots = tpmFrypotsContent.map((el, ind) => {
		
			return (
				<React.Fragment>
					<div class="chartHeaderTitleTable" key ={ind}>
							<ul>
								<li>{el[`${FRYPOT_NAME}${ind+1}`]}</li>
								<li>
									<div class="chartTitleLgnd">{`${el.currentTPMKey} `}</div>
									<div class="chartValLgnd">{el.currentTPMValue ? el.currentTPMValue.toFixed(1) : '0'}</div>
								</li>
								{ind === 0 ? <li><img src={require("../../../images/icons/oil.png")} alt="Oil Disposed" title={formatMessage({ id: "KC0488" })}/> <FormattedMessage id="KC0488"/></li> : <li>&nbsp;</li>}
							</ul>
						</div>
				<div class="chartTitleDiv" key ={ind}>
					<div class="chartTitleLgnd">  
						<span class="chartTitleLgndTitl"></span>
					</div>
					<div class="chartValLgnd"></div>
				</div>
				<RenderBarChart content={el.data} elementId={`TPMvalueAllFrypotBarChartdiv_${ind}`} property={chartProperty} filterType={this.state.filterType} isDecimal={true} />
				</React.Fragment>  
			)
			})
		}

		return(
	<div className="gridViewTable">
		<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
			<div className="gridView">
                <div className="colm12D">
                    <div className="colmDi">
                        <h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
						<span className="cornerIcon">
								</span>
								<NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} 
								minDateDisable={this.state.sdmindate} 
								maxDateDisable={this.state.sdmaxdate} 
								endminDateDisable={this.state.edmindate} 
								endmaxDateDisable={this.state.edmaxdate} 
								endDatepara={this.state.endDate} 
								handleChangeStartProps={(date) => this.handleChangeStart(date)} 
								handleChangeEndProps={(date) => this.handleChangeEnd(date)} 
								OnChange={(e) => this.onDropDownHandler(e)} 
								filterType={this.state.filterType} 
								disableDayFilter={true} 
								disableQuarterFilter={true}
								disableYearFilter={true}
								disableDatePicker={false} 
								minDaysRequired={6} 
								maxDaysRequired={29} />
							</h4>
				 {displayTpmAllFrypots}
                    </div>
                    </div>
				</div>
                </div>
		)
	}
};

function mapStateToProps(state) {
	return {
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getCurrentLocation, 
		getCurrentUnit
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TpmValueAllFrypots));
