import axios from 'axios';
import api from '../../../service/api';
import http from '../../../service/httpService';
export const ORG_LOCATION_TYPESREQUEST = 'ORG_LOCATION_TYPESREQUEST';
export const ORG_LOCATION_TYPESSUCCESS = 'ORG_LOCATION_TYPESSUCCESS';
export const ORG_LOCATION_TYPESFAIL = 'ORG_LOCATION_TYPESFAIL';

export function getOrganizationLocationTypes(req) {
    return dispatch => {
        dispatch({
            type: ORG_LOCATION_TYPESREQUEST
        })
        http.get(api.organizaionLocationTypesUrl,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                data:{}
             } )
        .then(response => {
            console.log('OrganizationLocationTypesInformation' ,response.data);
            dispatch({
                type: ORG_LOCATION_TYPESSUCCESS,
            OrganizationLocationTypesInformation: response.data,
            //OrganizationLocationTypesInformation: locationTypeList,
             })
        })            
        .catch(error => {
                dispatch({
                    type: ORG_LOCATION_TYPESFAIL,
                    error
                })
            })
    }
}

const locationTypeList = {"customer-group_type": [
    {
        "cust_gp_type_id": 1,
        "cust_type_name": "Hospital"
    },
    {
        "cust_gp_type_id": 2,
        "cust_type_name": "CVS"
    },
    {
        "cust_gp_type_id": 3,
        "cust_type_name": "QSR"
    },
    {
        "cust_gp_type_id": 4,
        "cust_type_name": "Office"
    },
    {
        "cust_gp_type_id": 5,
        "cust_type_name": "Hotel"
    },
    {
        "cust_gp_type_id": 6,
        "cust_type_name": "Restaurant"
    },
    {
        "cust_gp_type_id": 7,
        "cust_type_name": "Cafe"
    },
    {
        "cust_gp_type_id": 8,
        "cust_type_name": "Store"
    },
    {
        "cust_gp_type_id": 9,
        "cust_type_name": "Government"
    },
    {
        "cust_gp_type_id": 10,
        "cust_type_name": "University"
    },
    {
        "cust_gp_type_id": 11,
        "cust_type_name": "Other"
    }
]
}
// axios.get('/api', {
//     params: {
//       foo: 'bar'
//     }
//   });