import React from "react"
import KCInput from "../Input"
import KCTextarea from "../TextArea"
import KCRadioButtons from "../RadioButton"
import KCSelect from "../Select"

function FormikController(props) {
    const { control, ...rest } = props
    switch (control) {
        case "input":
            return <KCInput {...rest} />
        case "textArea":
            return <KCTextarea {...rest} />
        case "select":
            return <KCSelect {...rest} />
        case "radio":
            return <KCRadioButtons {...rest} />
        default:
            return null
    }
}
export default FormikController