import {SOFTWARE_UPDATE_STATUS_REQUEST,SOFTWARE_UPDATE_STATUS_SUCCESS,SOFTWARE_UPDATE_STATUS_FAIL} from '../../../../actions/navigation/softwareManagement/softwareUpdateStatusActions';
const initialState = {
    loaded: false,
    loading: false,
    softwareUpdateStatusInformation: {'Software_Distribute_List':[]},
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case SOFTWARE_UPDATE_STATUS_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                
            }
        case SOFTWARE_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                softwareUpdateStatusInformation: action.softwareUpdateStatusInformation,
               
            }
        case SOFTWARE_UPDATE_STATUS_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: '',
                softwareUpdateStatusInformation:[],
            }
        default:
            return state
    }
}
