import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import http from '../../../service/httpService';
import { BaseService, unitAPI, userRoleURI } from '../../../service/api';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import ErrorMoal from '../../modal/error-model';
import _ from 'lodash';
import authConstants from '../../../utils/authConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../utils/kcStorage';
var XLSX = require("xlsx");

class BulkUnitOnboardingConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            bulkUnit: {},
            error_modal: {
                open: false,
                message: ""
            },
        }
    }

    updateDimension = () => {
        var windowHeight = $(window).height();

        var addUnitHeader = $(".addUnitHeader").height();
        var uploadedUnitsList = $(".uploadedUnitsList").height();
        var unitRegCListH = $(".unitRegCList .tHead").height();
        var unitRegCListSrlBarH = ((windowHeight) - (addUnitHeader + uploadedUnitsList + unitRegCListH)) - 100;
        $(".unitRegCListSrlBar").css("height", unitRegCListSrlBarH);
    }

    closeModel = () => {
        this.setState({
            loaderIconVisible: false,
            error_modal: {
                open: false
            }
        });
        this.props.history.push('/bulkUnitOnboarding');
    }

    componentDidMount = () => {
        document.body.style.backgroundColor = "#F2F2F2";
        window.addEventListener('resize', this.updateDimension);
        this.uploadFile();
    }

    updateCustBrandAndFeature = async () => {
        let customerId = KCStore.getItem("customerId");
        let roleId = KCStore.getItem("roleId");
        let Urls = userRoleURI.getFeatures + "/" + roleId + "/features?customer=" + customerId + "&is_featurefunction_required=true"
        await http.get(Urls,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {}
            }).then(async response => {
                let rolefeatures = response.data.data.features;
                await KCStore.setItem(authConstants.allFeaturesKey, JSON.stringify(rolefeatures));
                let brandIDS = response.data.data.brandIds;
                await KCStore.setItem('brandId', JSON.stringify(brandIDS));
            }).catch(err => {
            });
    }

    componentWillUnmount = () => {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    getRegistrationStatus = async (transID) => {        
        let Urls = unitAPI.getRegistrationStatus;
        await http.get(Urls,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'transaction_id': transID
                },
                data: {}
            }).then(async response => {
                if (response && response.data && response.data.data) {
                    if (response.data.data.Status && response.data.data.Status === "InProgress") {
                        this.setState({
                            loaderIconVisible: true,
                        })
                        if (!this.interval) {
                            this.interval = setInterval(() => this.getRegistrationStatus(transID), 10000);
                        }                        
                    } else {
                        if (this.interval) {
                            clearInterval(this.interval);
                        }
                        if (response.data.data.unitsList && response.data.data.unitsList.length) {
                            this.setState({
                                loaderIconVisible: false,
                                bulkUnit: response.data.data,
                            })

                            await this.updateCustBrandAndFeature();

                            let result = response.data.data.unitsList;
                            const newArray = result.map(item => {
                                return {
                                    SERIALNO: item["BulkUnitTransactions.unitId"],
                                    BRANDNAME: item["BulkUnitTransactions.brandName"],
                                    REGISTRATIONCODE: item["BulkUnitTransactions.registrationCode"],
                                    ASSETNUMBER: item["BulkUnitTransactions.assetNumber"],
                                    STATUS: item["BulkUnitTransactions.status"],
                                };
                            });
                            this.generateExcel(newArray);
                        } else {
                            this.setState({
                                loaderIconVisible: false,
                                error_modal: {
                                    open: true,
                                    message: "KC2284"
                                },
                            })
                        }
                    }
                }
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: err && err.response && err.response.data && err.response.data.body && err.response.data.body.message ? err.response.data.body.message : "KC0944"
                    },
                });
            });
    }

    uploadFile = async () => {
        this.setState({ loaderIconVisible: true });
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const fileName = params.get('fileName');
        let postData;
        let loggedinUser;
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        loggedinUser = loginUser.username;

        postData = {
            "fileName": fileName,
            "existingBrandsAccess": KCStore.getItem("brandId") ? JSON.parse(KCStore.getItem("brandId")) : [],
            'customerId': Number(KCStore.getItem("customerId")),
            'useremail': loggedinUser,
            'basecountry': BASECOUNTRY_ID,
            'subscriptionId': KCStore.getItem("subscriptionId") ? KCStore.getItem("subscriptionId") : '',
            'subscriptionPlanId': KCStore.getItem("subscriptionPlan") ? KCStore.getItem("subscriptionPlan") : 0
        };
        http({
            method: "POST",
            url: `${BaseService.root}${unitAPI.bulkUploadFilePost}`,
            data: postData
        }).then(async response => {
            if (response && response.data && response.data.body && response.data.body.message === "Sent to SNS topic for bulk Unit Registration.") {                
                if (response.data.body.transactionId){
                    await this.getRegistrationStatus(response.data.body.transactionId);
                }                
            }

            
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err && err.response && err.response.data && err.response.data.body && err.response.data.body.message ? err.response.data.body.message : "KC0944"
                },
            });
        });
    }

    finishHandler = () => {
        this.props.history.push('/unitRegistration');
    }

    generateExcel = (arrVal) => { 
        var wscols = [
            {wch:20},
            {wch:20},
            {wch:25},
            {wch:20},
            {wch:35},
            {wch:35}
        ];
        const ws = XLSX.utils.json_to_sheet(arrVal)
        ws['!cols'] = wscols;
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Unit Registration List')
        XLSX.writeFile(wb, 'Template_'+ `${Date.now()}`+'.xlsx')
    }

    render() {
        const { loaderIconVisible, bulkUnit, error_modal } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div className="loadingProgress">
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">

                    <div className="unitRegistrationWrapper">
                        <div className="addUnitHeader">
                            <ul>
                                <li>
                                    <Link to="/bulkUnitOnboarding" title={formatMessage({ id: 'KC0989' })} className="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    Confirmation
                                </li>
                                <li>
                                    <Link to="#" title={formatMessage({ id: 'KC0056' })} className={`btn-default-text activeState`} onClick={() => this.finishHandler()}><FormattedMessage id="KC0056"/></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="uploadedUnitsList">
                            <ul>
                                <li>
                                    <div className="registeredDevices">
                                        <h5>{bulkUnit.registeredUnitCount || 0}</h5>
                                        <h6><FormattedMessage id="KC2624"/></h6>
                                    </div>
                                </li>
                                <li>
                                    <div className="existingDevices">
                                        <h5>{bulkUnit.alreadyRegisteredUnitCount || 0}</h5>
                                        <h6><FormattedMessage id="KC2625"/></h6>
                                    </div>
                                </li>
                                <li>
                                    <div className="duplicateEntry">
                                        <h5>{bulkUnit.duplicateEntryUnitCount || 0}</h5>
                                        <h6><FormattedMessage id="KC2643"/></h6>
                                        <h6><FormattedMessage id="KC2917"/></h6>
                                    </div>
                                </li>
                                <li>
                                    <div className="wrongEntry">
                                        <h5>{bulkUnit.wrongEntryUnitCount || 0}</h5>
                                        <h6><FormattedMessage id="KC2644"/></h6>
                                        <h6><FormattedMessage id="KC2917"/></h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <form className="unitRegCListForm">
                            <div className="unitRegCList">
                                <ul className="tHead">
                                    <li>#</li>
                                    <li><FormattedMessage id="KC0062"/></li>
                                    <li><FormattedMessage id="KC0649"/></li>
                                    <li><FormattedMessage id="KC0064"/></li>
                                    <li><FormattedMessage id="KC0647"/></li>
                                    <li><FormattedMessage id="KC0104"/></li>
                                    <li><FormattedMessage id="KC0652"/></li>
                                    <li><FormattedMessage id="KC0523"/></li>
                                </ul>
                            </div>
                            <div className="unitRegCListSrlBar">
                                <div className="unitRegCList">
                                    {bulkUnit && bulkUnit.unitsList && bulkUnit.unitsList.length ? bulkUnit.unitsList.map((unit, index) => {
                                        const statusClassName = unit["BulkUnitTransactions.status"] === "Registered Successfully" ? "registeredDevices" : unit["BulkUnitTransactions.status"] === "Existing Device" ? "existingDevices" : unit["BulkUnitTransactions.status"] === "Wrong Entry" ? "wrongEntry" : unit["BulkUnitTransactions.status"] === "Duplicate Entry" ? "duplicateEntry" : "";
                                        return (
                                            <ul key={index + unit.id} className="tBody">
                                                <li>{index + 1}</li>
                                                <li>{unit["BulkUnitTransactions.brandName"]}
                                                    <span className="unitsListSM">{`${unit["BulkUnitTransactions.modelFamily"]} , ${unit["BulkUnitTransactions.modelName"]}`}</span>
                                                    <span className={`unitsRSM ${statusClassName}`}>{unit["BulkUnitTransactions.status"]}</span></li>
                                                <li>{unit["BulkUnitTransactions.modelFamily"]}</li>
                                                <li>{unit["BulkUnitTransactions.modelName"]}</li>
                                                <li>{unit["BulkUnitTransactions.assetNumber"]}</li>
                                                <li>{unit["BulkUnitTransactions.unitId"]} <span className="unitsListAN">Asset Number <b>{unit["BulkUnitTransactions.unitId"]}</b></span></li>
                                                <li>{unit["BulkUnitTransactions.registrationCode"]}</li>
                                                <li><span className={statusClassName}>{unit["BulkUnitTransactions.status"]}</span></li>
                                            </ul>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <UserManagementHeader headerName="Unit Registration and Assignment" headerNameStringID="KC1684" activeClass="UnitRegistrationNav" />
            </div>
        )
    }
}
export default (injectIntl(BulkUnitOnboardingConfirmation));
