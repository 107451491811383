import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import ErrorMoal from '../../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import { datePickerLocale } from '../../../../../../locale/constant';
import KCStore from '../../../../../../utils/kcStorage';

class FrymasterTimeDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            error_modal: {
                open: false,
                message: ""
            },
            hourFormat: "false",
            setTime: "false",
            dateFormat: "false",
            daylightSaving: "false",
            timeMask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            timeMaskValue: '',
            setDate: "",
            defaultConfig: {}

        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.closeModel = this.closeModel.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            console.log("ObjSettingsFile",ObjSettingsFile)

            let defaultConfig = {
                hourFormat: ObjSettingsFile.general.time_date.hour_format === 1 ? "true" : "false",
                setTime: ObjSettingsFile.general.time_date.set_time === 1 ? "true" : "false",
                dateFormat: ObjSettingsFile.general.time_date.date_format === 1 ? "true" : "false",
                daylightSaving: ObjSettingsFile.general.time_date.day_light_saving_time === 1 ? "true" : "false",
                timeMaskValue: ObjSettingsFile.general.time_date.time_mask_value || "",
                setDate: ObjSettingsFile.general.time_date.set_date || "",
            }

            this.setState({
                hourFormat: ObjSettingsFile.general.time_date.hour_format === 1 ? "true" : "false",
                setTime: ObjSettingsFile.general.time_date.set_time === 1 ? "true" : "false",
                dateFormat: ObjSettingsFile.general.time_date.date_format === 1 ? "true" : "false",
                daylightSaving: ObjSettingsFile.general.time_date.day_light_saving_time === 1 ? "true" : "false",
                timeMaskValue: ObjSettingsFile.general.time_date.time_mask_value,
                setDate: ObjSettingsFile.general.time_date.set_date && ObjSettingsFile.general.time_date.set_date !== '' ?  new Date(ObjSettingsFile.general.time_date.set_date) : '',
                defaultConfig
            })

        } catch (error) {
            //Error Goes Here
        }
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSettingsClick = (pathname) => {
        this.props.history.push({
            pathname,
            isEdit: false,
            type: "Settings",
            previouspath: "/fryMasterSettings"
        });
    }

    onBackNavigation = () => {
        const {defaultConfig} = this.state;

        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.general.time_date) {
            ObjSettingsFile.general.time_date = {};
        }

        ObjSettingsFile.general.time_date.hour_format = defaultConfig.hourFormat === "true" ? 1 : 0;
        ObjSettingsFile.general.time_date.time_mask_value = defaultConfig.timeMaskValue;
        ObjSettingsFile.general.time_date.set_time = defaultConfig.setTime === "true" ? 1 : 0;
        ObjSettingsFile.general.time_date.date_format = defaultConfig.dateFormat === "true" ? 1 : 0;
        ObjSettingsFile.general.time_date.set_date = defaultConfig.setDate;
        // if (!ObjSettingsFile.general.time_date.day_light_saving_time) {
        //     ObjSettingsFile.general.time_date.day_light_saving_time = {};
        // }
        ObjSettingsFile.general.time_date.day_light_saving_time = defaultConfig.daylightSaving === "true" ? 1 : 0;

        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);

        this.props.history.push(`/frymasterGeneralSettings`);
    }

    onToggleChange = (e, stateKey, localStateKey, localeStateValue) => {
        const value = e.toString();

        this.setState({
            [stateKey]: value
        }, () => {
            this.onSaveWhileAction(localStateKey, localeStateValue)
        });
        if (stateKey === "hourFormat") {
            this.setState({
                timeMask: e.toString() === "true" ? [/[0-1]/, /[0-2]/, ':', /[0-5]/, /[0-9]/] : [/[0-2]/, /[0-3]/, ':', /[0-5]/, /[0-9]/],
                timeMaskValue: ''
            });
        }
    }

    onSetTimeValueChange = (e) => {
        let startsWith = e.target.value[0];
        const value = e.target.value;
        
        this.setState({
            timeMask: [
                this.state.hourFormat === "false" ? /[0-2]/ : /[0-1]/,
                this.state.hourFormat === "true" && startsWith === "1" ? /[0-2]/ : this.state.hourFormat === "false" ? /[0-5]/ : /[0-9]/,
                ':',
                this.state.hourFormat === "true" ? e.target.value.substr(0,2) === "12" ? /[0-0]/ : e.target.value.substr(0,2) === "00" ? /[1-5]/ :  /[0-5]/ : /[0-5]/,
                this.state.hourFormat === "true" ? e.target.value.substr(0,2) === "12" ? /[0-0]/ : /[0-9]/ : /[0-9]/
            ],
            timeMaskValue: value
        }, () => {
            this.onSaveWhileAction('time_mask_value', value)
        });
    }

    handleStartDateChange = (date) => {
        this.setState({
            setDate: date,
        }, () => {
            this.onSaveWhileAction('set_date', date)
        });
    }

    ResetDatepicker = (e) => {
        e.preventDefault();
        return false;
    }

    onConfirmButtonClick = (action) => {
        this.setState({
            loaderIconVisible: true,
        });
        let { timeMaskValue, setDate, setTime, hourFormat, dateFormat, daylightSaving } = this.state;
        if (!timeMaskValue) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1410"
                }
            });
        }
        else if (!setDate) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1409"
                }
            });
        }
        else {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            if (!ObjSettingsFile.general.time_date) {
                ObjSettingsFile.general.time_date = {};
            }

            ObjSettingsFile.general.time_date.hour_format = hourFormat === "true" ? 1 : 0;
            ObjSettingsFile.general.time_date.time_mask_value = timeMaskValue;
            ObjSettingsFile.general.time_date.set_time = setTime === "true" ? 1 : 0;
            ObjSettingsFile.general.time_date.date_format = dateFormat === "true" ? 1 : 0;
            ObjSettingsFile.general.time_date.set_date = setDate;
            // if (!ObjSettingsFile.general.time_date.day_light_saving_time) {
            //     ObjSettingsFile.general.time_date.day_light_saving_time = {};
            // }
            ObjSettingsFile.general.time_date.day_light_saving_time = daylightSaving === "true" ? 1 : 0;

            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            KCStore.setItem("libraryData", strSettingsFile);
            if (action === "nextPage") {
                this.props.history.push(`/frymasterDaylightSaving`);
            }
            else {
                this.props.history.push(`/frymasterGeneralSettings`);
            }
        }
    }

    onSaveWhileAction = (key, value) => {
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.general.time_date) {
            ObjSettingsFile.general.time_date = {};
        }
        ObjSettingsFile.general.time_date[key] = value;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { hourFormat, setTime, dateFormat, daylightSaving, timeMask, timeMaskValue, error_modal } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        return (
            <React.Fragment>
                {/* Frymaster Time & Date Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Time & Date Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Time & Date Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1411"/>
                                </div>

                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li><FormattedMessage id="KC1412"/></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle" data-toggle="buttons"> <span class={hourFormat === "false" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1403"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'hourFormat', 'hour_format', e.toString() === "true" ? 1 : 0)}
                                                                    checked={hourFormat === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={hourFormat === "true" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1404"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1413"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                mask={timeMask}
                                                                maskChar=""
                                                                placeholder={hourFormat === "true" ? formatMessage({ id: 'KC1973' }) : formatMessage({ id: 'KC0836' })}
                                                                onChange={(e) => this.onSetTimeValueChange(e)}
                                                                value={timeMaskValue}
                                                                class="form-control floatLeft" />
                                                            <div class={hourFormat === "true" ? "btn-group-toggle" : "btn-group-toggle disabled"} data-toggle="buttons">
                                                                <span class={setTime === "false" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1406"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'setTime', 'set_time', e.toString() === "true" ? 1 : 0)}
                                                                    checked={setTime === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={setTime === "true" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1407"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1414"/></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle" data-toggle="buttons">
                                                                <span class={dateFormat === "false" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC2004"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'dateFormat', 'date_format', e.toString() === "true" ? 1 : 0)}
                                                                    checked={dateFormat === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={dateFormat === "true" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC0835"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1415"/></li>
                                                        <li class="smallFormFields">
                                                            <DatePicker
                                                                locale={datePickerLocale}
                                                                disabledKeyboardNavigation
                                                                id="SetDate"
                                                                onKeyDown={(e) => this.ResetDatepicker(e)}
                                                                autoComplete="off"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={this.state.setDate}
                                                                placeholderText={dateFormat === "true" ? formatMessage({ id: 'KC0835' }) : formatMessage({ id: 'KC2004' })}
                                                                dateFormat={dateFormat === "true" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
                                                                onChange={this.handleStartDateChange}
                                                                minDate={new Date()}
                                                                className="form-control" />
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    <ul class="borderNone">
                                                        <li class="width190px"><FormattedMessage id="KC1416"/></li>
                                                        <li class="arrowBtn">
                                                            <div class="btn-group-toggle" data-toggle="buttons"> <span class={daylightSaving === "false" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC2016"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'daylightSaving', 'day_light_saving_time', e.toString() === "true" ? 1 : 0)}
                                                                    checked={daylightSaving === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={daylightSaving === "true" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC2002"/></span>
                                                            </div>

                                                            <button type="button" onClick={() => { this.onConfirmButtonClick('nextPage') }} class="btn nextButton">&nbsp;</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick('save') }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Time & Date Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterTimeDate)
