import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from 'lodash';

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

export class FrypotStatusPieChart extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        if (_.isEqual(this.props.frypotStatusInformation, nextProps.frypotStatusInformation)) {
          return false;
        }
        this.renderComponent(nextProps.frypotStatusInformation);
        return true;
      }

    renderComponent(nextPropsValues) {
        let response = [];
        if (nextPropsValues.length > 0) {
            response = nextPropsValues;
        }

        if (response && response.length > 0 && !this.chart) // Chart Rendering at first time response
        {
            // Create Pie chart 
            let pie_chart = am4core.create("frypotStatusChartDiv", am4charts.PieChart);
            pie_chart.data = response;
            this.chart = pie_chart;
            pie_chart.responsive.enabled = true;

            // Add and configure Series
            let pieSeries = pie_chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "Value";
            pieSeries.dataFields.category = "Key";
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;
            pieSeries.slices.template.stroke = am4core.color("#FFFFFF");
            pieSeries.slices.template.strokeWidth = 0.8;
            pieSeries.slices.template.strokeOpacity = 1;
            pieSeries.colors.list = [
                am4core.color("#0d81b9"),
                am4core.color("#cc1056"),
                am4core.color("#b4b4b4"),
                am4core.color("#2d3034")
                ];

            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;

            // Pie Series Tooltip Text and bg color
            pieSeries.slices.template.tooltipText = "{category}: {value.value}%";
            pieSeries.tooltip.getFillFromObject = false;
            pieSeries.tooltip.background.fill = am4core.color("#FFFFFF");
            pieSeries.tooltip.label.fill = am4core.color("#000000");
        }
        else { // Chart rendering with only data source
            if (this.chart) {
                this.chart.data = response;
            }
        }

    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div id="frypotStatusChartDiv"></div>
        )
    }
};

export default FrypotStatusPieChart;
