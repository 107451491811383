import React from 'react';

const Loader = ({ loading }) => {
    return (
        <div id="page-loader" className={loading ? "loadingProgress showloader" : "loadingProgress"}>
            <div className="loadingProgressIn"></div>
        </div>
    )
}

export default Loader;