import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import { HACCPManagementAPI } from '../../../service/api';
import _ from 'lodash';
import $ from 'jquery';
import ErrorMoal from '../../modal/error-model';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../utils/kcStorage';

$(window).on('beforeunload', function () {
    if($(".fleet-export-error-report").length>0){
        $("#haccpReport").removeClass("fleet-export-error-report");
        $(".loadingProgress").css("display", "none");
    }
});

class HACCPRetrieveStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			haccpLogDistributionList: [],
			loaderIconVisible: false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			error_modal: {
				open: false,
				message: ""
			},
			haccpViewerUrl: '#'
		};

		this.refreshPage = this.refreshPage.bind(this);
		this.getHACCPViewerUrl = this.getHACCPViewerUrl.bind(this);
	}

	componentDidMount = async () =>{
		document.body.style.backgroundColor = "#F2F2F2";
		await this.getHACCPViewerUrl();
		this.getHACCPUpdate().catch(err => {
			let { response, message } = err;
			if (response || message) {
				let { data } = response || { data: { message } };
				if (data) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: data.message ? data.message : ""
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1083"
					}
				});
			}
		});
		
	}

	refreshPage = () => {
		this.getHACCPUpdate();
	}

	getHACCPViewerUrl = () => {
		this.setState({ loaderIconVisible: true });
		return new Promise((resolve, reject) => {
			http.get(HACCPManagementAPI.logViewerUrl, {
				headers: {
					'Content-Type': 'application/json'
				}, data: {}
			}).then(response => {
				if (response && response.data) {
					this.setState({
						haccpViewerUrl: response.data.presignedLogViewerUrl,
					});
					resolve(response.data);
				}
			}).catch(err => {
				this.setState({ loaderIconVisible: false });
			});
		});
	}

	getHACCPUpdate = () => {
		this.setState({ loaderIconVisible: true });
		let custGroupID = KCStore.getItem("custGroupID");
		let storageCustomerId = KCStore.getItem("customerId");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		return new Promise((resolve) => {
			http.get(HACCPManagementAPI.logLibraryUrl, {
				headers: {
					'Content-Type': 'application/json',
					'basecountry': BASECOUNTRY_ID,
					'customerid': storageCustomerId,
					'cgids': cgids
				}, data: { Status: '' }
			}).then(response => {
				if (response && response.data) {
					this.setState({
						loaderIconVisible: false,
						haccpLogDistributionList: response.data,
					});
					WoopraEvents(`${Constants.REFRESH}`);
					resolve(response.data);
				}
			}).catch(err => {
				// reject(err);
				this.setState({ loaderIconVisible: false });
			});
		});
	}

	downloadLogViewer = () => {
		if (this.state.haccpViewerUrl !== '#') {
			$("#haccpReport").addClass("fleet-export-error-report");
			window.location = this.state.haccpViewerUrl;
		}
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			}
		});
	}

	// Woopra Events
	logLibrary = () => {
		WoopraEvents(`${Constants.LOG_LIBRARY}`);
	}
	
	render() {
		const { formatMessage } = this.props.intl;
		let { haccpLogDistributionList, loaderIconVisible, sortColumn, error_modal } = this.state;
		let queued = 0;
		let pending = 0;
		let completed = 0;
		let failed = 0; 
		
		let columns = [
			{ path: "", label: "#" },
			{ path: "BRAND", labelStringID: "KC0062" },
			{ path: "MODEL_FAMILY", labelStringID: "KC0649" },
			{ path: "MODEL", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "CREATION_DATE", labelStringID: "KC0298", },
			{ path: "status", labelStringID: "KC0523" },
		];

		let haccpLogDistributionUpdateList = haccpLogDistributionList && haccpLogDistributionList.devices &&
			haccpLogDistributionList.devices.length > 0 ? haccpLogDistributionList.devices : [];

		haccpLogDistributionUpdateList.forEach(item => {
			queued += item.status === 'Queued' ? 1 : 0;
			pending += item.status === 'IN_PROGRESS' ? 1 : 0;
			completed += item.status === 'SUCCEEDED' ? 1 : 0;
			failed += item.status === 'FAILED' ? 1 : 0;
		});

		if (sortColumn.path === "CREATION_DATE") {
			if (sortColumn.order === "desc") {
				haccpLogDistributionUpdateList = haccpLogDistributionUpdateList.sort((d1, d2) => new Date(d2.CREATION_DATE) - new Date(d1.CREATION_DATE));
			}
			else {
				haccpLogDistributionUpdateList = haccpLogDistributionUpdateList.sort((d1, d2) => new Date(d1.CREATION_DATE) - new Date(d2.CREATION_DATE));
			}
		}
		else {
			haccpLogDistributionUpdateList = _.orderBy(haccpLogDistributionUpdateList, [sortColumn.path], [sortColumn.order]);
		}
		
		return (
			<div id="adminWrapper">
				<div id="haccpReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div className="reportWrapper">
						<div className="reportHeader">
							<ul>
								<li>
									<Link to="/haccpLogLibrary" title={formatMessage({ id: 'KC0759' })} onClick={()=> this.logLibrary()}><FormattedMessage id="KC0759"/></Link>
									<Link to="/haccpRetrieveStatus" title={formatMessage({ id: 'KC0760' })} className="active"><FormattedMessage id="KC0760"/> </Link>
									<button className="btn btn-default-text logViewBtn" onClick={() => this.downloadLogViewer()} type="button" title={formatMessage({ id: 'KC0761' })}><FormattedMessage id="KC0761"/></button>
								</li>
							</ul>
						</div>
						<div className="selectedUnitHeader">
							<ul>
								<li>
									<Link to="#" title={queued + ` ${formatMessage({ id: 'KC1765' })}`}>{queued} <FormattedMessage id="KC1765"/></Link>
									<Link to="#" title={pending + ` ${formatMessage({ id: 'KC1766' })}`}>{pending} <FormattedMessage id="KC1766"/></Link>
									<Link to="#" title={completed + ` ${formatMessage({ id: 'KC1767' })}`}>{completed} <FormattedMessage id="KC1767"/></Link>
									<Link to="#" title={failed + ` ${formatMessage({ id: 'KC1768' })}`}>{failed} <FormattedMessage id="KC1768"/></Link>
									<Link className="refreshIcon" to="#" onClick={this.refreshPage} title={formatMessage({ id: 'KC0769' })}>&nbsp;</Link>
								</li>
							</ul>
						</div>
						<form className="haccpUpdateStatusTableOuter">
							<div className="haccpUpdateStatusTable">
								<TableHeader
									menuUpdateScreen={true}
									sortColumn={sortColumn}
									onSort={this.onSort}
									columns={columns} />
										{haccpLogDistributionUpdateList.map((item, j) => {
											let WINDOW_MODELFAMILY_COLUMN = item.MODEL_FAMILY === "eikon® e1s" ? (<li>eikon<sup>®</sup> e1s</li>) : item.MODEL_FAMILY === "eikon® e2s" ? (<li>eikon<sup>®</sup> e2s</li>) : item.MODEL_FAMILY === "eikon® e4s" ? (<li>eikon<sup>®</sup> e4s</li>) : (<li>{item.MODEL_FAMILY}</li>)
											let MOBILE_MODELFAMILY_COLUMN = item.MODEL_FAMILY === "eikon® e1s" ? (<span>eikon<sup>®</sup> e1s</span>) : item.MODEL_FAMILY === "eikon® e2s" ? (<span>eikon<sup>®</sup> e2s</span>) : item.MODEL_FAMILY === "eikon® e4s" ? (<span>eikon<sup>®</sup> e4s</span>) : (<span>{item.MODEL_FAMILY}</span>)
											return (
												<React.Fragment key={j}>
														<ul className="tBody">
															<li>{j + 1}</li>
															<li>{item.BRAND}
																<div className="updateStatusSM">{item.MODEL}, {MOBILE_MODELFAMILY_COLUMN}</div>
																<div className="updateStatusSB">{item.CREATION_DATE}</div>
																<div className="updateStatusSB">{item.status === "IN_PROGRESS" ? <FormattedMessage id="KC1766"/> : item.status === "SUCCEEDED" ? <FormattedMessage id="KC1767"/> : item.status.toLowerCase()}</div>
															</li>
															{WINDOW_MODELFAMILY_COLUMN}
															<li>{item.MODEL}</li>
															<li>{item.UNITID}</li>
															<li>{item.CREATION_DATE}</li>
															<li>{item.status === "IN_PROGRESS" ? <FormattedMessage id="KC1766"/> : item.status === "SUCCEEDED" ? <FormattedMessage id="KC1767"/> : item.status.toLowerCase()}</li>
														</ul>
												</React.Fragment>
											)
										})
										}
									</div>
					<NoRecordsFound loaderIconVisible={loaderIconVisible} length={haccpLogDistributionUpdateList !== undefined ? haccpLogDistributionUpdateList.length : 0} classname={'norecordsfoundbluetext'} />
						</form>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				</div>
				<UserManagementHeader headerName="HACCP Management" headerNameStringID="KC0636" activeClass="haccpNav" />
			</div>
		)
	}
};

export default (injectIntl(HACCPRetrieveStatus));
