import {DELETE_ELEMENT_REQUEST,DELETE_ELEMENT_SUCCESS,DELETE_ELEMENT_FAIL,DELETE_ELEMENT_RESET} from '../../../../actions/navigation/organizationmanagement/deleteOrganizationElementAction';
const initialState = {
    loaded: false,
    loading: false,
    deleteElementInformation: {}
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case DELETE_ELEMENT_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                
            }
        case DELETE_ELEMENT_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                deleteElementInformation: action.deleteElementInformation,
               
            }
        case DELETE_ELEMENT_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
               errormsg: '',

            }
                      
        default:
            return state
    }
}

