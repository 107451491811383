
export default {
    UnAuthorized :"You are not authorized to view this page",
    SessionExpire: "Your session has expired. Please login again."
}

export const staticNotificationTypeData = [
  {
    Id: 960,
    orderId: 1,
    preference_name: "Unit Offline",
    preference_value: "Email",	  
    isEmailChecked: false,
    value:24,
  },
  {
    Id: 961,
    orderId: 2,
    preference_name: "Critical Errors",
    preference_value: "Text,Email",
    isTextChecked: false,
    isEmailChecked: false
  },
  {
    Id: 962,
    orderId: 3,
    preference_name: "Non-critical Errors",
    preference_value: "Text,Email",
    isTextChecked: false,
    isEmailChecked: false
  },
  {
    Id: 963,
    orderId: 4,
    preference_name: "Operational Events",
    preference_value: "Text,Email",
	  isTextChecked: false,
    isEmailChecked: false
  },
  {
    Id: 964,
    orderId: 5,
    preference_name: "Informative Events",
    preference_value: "Text,Email",
	  isTextChecked: false,
    isEmailChecked: false
  },
  {
    Id: 965,
    orderId: 6,
    preference_name: "Resolved Errors",
    preference_value: "Text,Email",	  
    isTextChecked: false,
    isEmailChecked: false
  }
];
