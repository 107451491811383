import React from 'react';

const UnitNotRegisterMsg = () => {
    return (
        <div >
            <h2 className="notRegisterd">Unit Not Registered</h2>
            <div className="connectivitySrlBar mCustomScrollbar">
                <div className="connectivitySubList">
                    <ul>
                        <li>Verify Brand and Serial Number entered.</li>
                        <li>Confirm with your Distributor that the Device is KitchenConnect compatible.</li>
                        <li>Ask your Distributor to request the Brand to register the Device within KitchenConnect database.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default UnitNotRegisterMsg;