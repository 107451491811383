

import React from 'react';
import EventOrg from '../eventOrg';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';

const EventOrgModal = ({ selectedNotification, getBrandImage, getUnitCount, getBrandUnitImage, getBrandId, workflowDropdown, selectedWorkflowId, addSpaceBetweenComma }) => {
    return (
        <div class="modal fade orgViewModal" data-keyboard="true" data-backdrop="static" id="orgViewModal" tabindex="-1" role="dialog" aria-labelledby="orgViewModalLabel">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" style={{ minHeight: $(window).height() - 60 }}>
                    <div class="modal-header modal-header-danger">
                        <FormattedMessage 
                                id="KC1720" 
                                values={{ eventCode: selectedNotification.event_code || '', eventName: selectedNotification.event_name || '' }}/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                    </div>
                    <div class="modal-header modal-header-danger">
                        <div class="detailsViewHeader marginTop0">
                            <ul>
                                <li>
                                    <div class="eventTypeText">
                                        {selectedNotification.event}
                                    </div>
                                </li>
                                <li>
                                    <span className="brandLogo">
                                        <img src={getBrandImage(selectedNotification.brand ? selectedNotification.brand.toUpperCase() : '')} />
                                    </span>
                                    <span className="brandModel">{addSpaceBetweenComma(selectedNotification.model)}</span>
                                    <div class="notifnFlowDetails">
                                        {getUnitCount(selectedNotification.model)}/{getUnitCount(selectedNotification.model)}
                                        <span className="brandModelIcon">
                                            <img src={getBrandUnitImage(selectedNotification.brand ? selectedNotification.brand.toUpperCase() : '')} />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-body">
                        {selectedNotification && selectedNotification.model && <EventOrg selectedNotification={selectedNotification} getBrandId={getBrandId} workflowDropdown={workflowDropdown} selectedWorkflowId={selectedWorkflowId} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventOrgModal;