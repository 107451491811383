import React from 'react';
import { FormattedMessage } from 'react-intl';

import KCStore from "../../utils/kcStorage";

export default class SupportButton extends React.Component {
    constructor(props) {
        super(props);
    }

    getBrowser = () => {
        const userAgent = navigator.userAgent;
        let browser = "unkown";
        // Detect browser name
        browser = (/ucbrowser/i).test(userAgent) ? 'UCBrowser' : browser;
        browser = (/edg/i).test(userAgent) ? 'Edge' : browser;
        browser = (/googlebot/i).test(userAgent) ? 'GoogleBot' : browser;
        browser = (/chromium/i).test(userAgent) ? 'Chromium' : browser;
        browser = (/firefox|fxios/i).test(userAgent) && !(/seamonkey/i).test(userAgent) ? 'Firefox' : browser;
        browser = (/; msie|trident/i).test(userAgent) && !(/ucbrowser/i).test(userAgent) ? 'IE' : browser;
        browser = (/chrome|crios/i).test(userAgent) && !(/opr|opera|chromium|edg|ucbrowser|googlebot/i).test(userAgent) ? 'Chrome' : browser;;
        browser = (/safari/i).test(userAgent) && !(/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i).test(userAgent) ? 'Safari' : browser;
        browser = (/opr|opera/i).test(userAgent) ? 'Opera' : browser;
    
        // detect browser version
        switch (browser) {
            case 'UCBrowser': return `${browser}/${this.browserVersion(userAgent,/(ucbrowser)\/([\d\.]+)/i)}`;
            case 'Edge': return `${browser}/${this.browserVersion(userAgent,/(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
            case 'GoogleBot': return `${browser}/${this.browserVersion(userAgent,/(googlebot)\/([\d\.]+)/i)}`;
            case 'Chromium': return `${browser}/${this.browserVersion(userAgent,/(chromium)\/([\d\.]+)/i)}`;
            case 'Firefox': return `${browser}/${this.browserVersion(userAgent,/(firefox|fxios)\/([\d\.]+)/i)}`;
            case 'Chrome': return `${browser}/${this.browserVersion(userAgent,/(chrome|crios)\/([\d\.]+)/i)}`;
            case 'Safari': return `${browser}/${this.browserVersion(userAgent,/(safari)\/([\d\.]+)/i)}`;
            case 'Opera': return `${browser}/${this.browserVersion(userAgent,/(opera|opr)\/([\d\.]+)/i)}`;
            case 'IE': const version = this.browserVersion(userAgent,/(trident)\/([\d\.]+)/i);
                // IE version is mapped using trident version 
                // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
                return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
            default: return `unknown/0.0.0.0`;
        }
    }
    
    browserVersion = (userAgent,regex) => {
        return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
    }

    render() {
        let browser = this.getBrowser();
        let user = KCStore.getItem("user");
        let customerName = KCStore.getItem("customerName");
        let mailTo = `mailto:DigitalSupport@welbilt.com?subject=${this.props.subject}&body=${this.props.body}%0D%0A%0D%0A${user !== undefined && user !== null? `User: ${user}%0D%0A`:''}${customerName !== undefined && customerName !== null? `Account: ${customerName}%0D%0A`:''}Browser: ${browser}`
        return (
            <div className="supportButtonWrapper">
                <a href={mailTo}><FormattedMessage id='KC0004' /></a>
            </div>
            
        );
    }
}