import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class FrymasterOQSOilType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            oilTypeState: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                oilTypeState: Number(ObjSettingsFile.oqs.oil_type),
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        let { oilTypeState } = this.state;
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.oqs) {
            ObjSettingsFile.oqs = {}
        }
        ObjSettingsFile.oqs.oil_type = Number(oilTypeState);
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterOQS`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/fryMasterOQS`);
    }

    onChangeOilType = (e) => {
        this.setState({
            oilTypeState: Number(e.target.value)
        });
    }

    render() {
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        const { oilTypeState } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster OQS Oil Type Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster OQS Oil Type Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster OQS Oil Type Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1436"/>
                                </div>

                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="textWRadioBtnList">
                                                    {[{ label: "OC1", value: 0 }, { label: "OC2", value: 1 }, { label: "OC3", value: 2 }, { label: "OC4", value: 3 }, { label: "OC5", value: 4 }, { label: "OC6", value: 5 }, { label: "OC7", value: 6 }, { label: "OC8", value: 7 }, { label: "OC9", value: 8 },
                                                    { label: "OC10", value: 9 }, { label: "OC11", value: 10 }, { label: "OC12", value: 11 }, { label: "OC13", value: 12 }, { label: "OC14", value: 13 }, { label: "OC15", value: 14 }, { label: "OC16", value: 15 }, { label: "OC17", value: 16 }, { label: "OC18", value: 17 },
                                                    { label: "OC19", value: 18 }, { label: "OC20", value: 19 }, { label: "OC21", value: 20 }, { label: "OC22", value: 21 }, { label: "OC23", value: 22 }, { label: "OC24", value: 23 }, { label: "OC25", value: 24 }].map((oilType, index) => {
                                                        return (
                                                            <ul key={index}>
                                                                <li>{oilType.label}</li>
                                                                <li>
                                                                    <div class="customRadioBtn">
                                                                        <input id={oilType.value} type="radio" name="OilType" value={oilType.value} onChange={(e) => this.onChangeOilType(e)} checked={oilType.value === oilTypeState} />
                                                                        <label for={oilType.value}>&nbsp;</label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster OQS Oil Type Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterOQSOilType)
