import {MEDIA_DRAG_LIST_SUCCESS,MEDIA_DRAG_DELETE_SUCCESS,MEDIA_RESET_SELECTED_SUCCESS} from '../../../../actions/navigation/mediaManagement/mediaDragAction';
const initialState = {
    mediaDraggedItem:{prepartions: [], other:[], size : 0},
}

function updateDraggedItem(draggedItem,state){
    let mediaDraggedItem = Object.assign({},state.mediaDraggedItem);
    if(mediaDraggedItem.mediaDraggedItem){
        mediaDraggedItem = mediaDraggedItem.mediaDraggedItem;
    }
    // if(!mediaDraggedItem.size){
    //     mediaDraggedItem = {
    //         mediaDraggedItem:{prepartions: [], other:[], size : 0},
    //     };
    // } 
    if(draggedItem.type === 'preparation'){
     const findItem = mediaDraggedItem.prepartions && mediaDraggedItem.prepartions.find(item => item.MEDIA_ID === draggedItem.item.MEDIA_ID)
     if(!findItem){
     //   mediaDraggedItem.prepartions =[];
        mediaDraggedItem.prepartions.push(draggedItem.item);
        mediaDraggedItem.size = Number(mediaDraggedItem.size) + Number(draggedItem.item.FILE_SIZE);
     }
    }else if(draggedItem.type === 'other'){
        const findItem =  mediaDraggedItem.other && mediaDraggedItem.other.find(item => item.MEDIA_ID === draggedItem.item.MEDIA_ID)
        if(!findItem){
        //    mediaDraggedItem.other = [];
           mediaDraggedItem.other.push(draggedItem.item);
           mediaDraggedItem.size = Number(mediaDraggedItem.size) + Number(draggedItem.item.FILE_SIZE);
        }
    }
    return mediaDraggedItem;

}
function updateDeleteItem(obj,state){
    let mediaDraggedItem = Object.assign({},state.mediaDraggedItem);
    const preparationItem = mediaDraggedItem && mediaDraggedItem.prepartions && mediaDraggedItem.prepartions[0];
    const otherItem = mediaDraggedItem && mediaDraggedItem.other && mediaDraggedItem.other[0];
    if(obj.type === 'preparations'){
    // const findIndex =  mediaDraggedItem.prepartions.findIndex(item => item.MEDIA_ID === draggedItem.item.MEDIA_ID)
    // if(!findIndex > -1){
       
       
      //  mediaDraggedItem.prepartions.push(draggedItem.item);
      if(otherItem){
        mediaDraggedItem.size  = Number(otherItem.FILE_SIZE);
      }else{
        mediaDraggedItem.size =  0;
      }
       
        mediaDraggedItem.prepartions =[]
   //  }
    }else if(obj.type === 'other'){
       // const findItem =  mediaDraggedItem.other.find(item => item.MEDIA_ID === draggedItem.item.MEDIA_ID)
      
    //    if(!findItem){
           if(preparationItem){
            mediaDraggedItem.size =  Number(preparationItem.FILE_SIZE);
           }else{
            mediaDraggedItem.size =  0;
           }
          
           mediaDraggedItem.other =[];
    //    }
    }
    return mediaDraggedItem;

}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case MEDIA_DRAG_LIST_SUCCESS:
            return {
                ...state,
                mediaDraggedItem: updateDraggedItem(action.mediaDraggedItem,state),
            }
        case MEDIA_DRAG_DELETE_SUCCESS:
            return {
                ...state,
                mediaDraggedItem: updateDeleteItem(action.deleteType,state),
            }
            case MEDIA_RESET_SELECTED_SUCCESS:
            return {
                ...state,
                mediaDraggedItem:  {
                    mediaDraggedItem:{prepartions: [], other:[], size : 0},
                },
            }
        default:
            return state
    }
}
