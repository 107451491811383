import React from 'react'

const LoadingPopup = ( {loading} ) => {
  return (
    <div className={ loading ? "loadingProgress showloader" : "loadingProgress"}>
          <div className="loadingProgressIn"></div>
    </div>
  )
}

export default LoadingPopup

