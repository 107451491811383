import React, { Component } from 'react'
import $ from 'jquery';

const updateDimensions_Media = ()  => {
  var windowHeight = $(window).height();
  var mediaHeader = $(".mediaHeader").height();
  var mediaPageWrapperH = $(".mediatHead").height();
  var mediaUploadFTable = $(".mediaUploadFTable").height();
  var mediaPageWrapperHeight = ((windowHeight) - (mediaHeader + mediaPageWrapperH + mediaUploadFTable)) - 65;
  $(".mediaTableListForm").css("height", mediaPageWrapperHeight);
}

const updateDimensions_MediaPackage = ()  => {
  var windowHeight = $(window).height();
  var mediaHeader = $(".mediaHeader").height();
  var mediaPageWrapperH = $(".mediatHead").height();
  var mediaPageWrapperHeight = ((windowHeight) - (mediaHeader + mediaPageWrapperH )) - 65;
  $(".mediaTableListForm").css("height", mediaPageWrapperHeight);
}

export {updateDimensions_Media, updateDimensions_MediaPackage}