import axios from 'axios';
import http from '../../../service/httpService';
import { MediaManagementAPI } from '../../../service/api';
import { CUSTOMER_ID, BRAND_ID,BASECOUNTRY_ID } from '../../../utils/appConstants';
import { format } from 'date-fns';

import KCStore from '../../../utils/kcStorage';

export const MEDIA_UPLOAD_HISTORY_REQUEST = 'MEDIA_UPLOAD_HISTORY_REQUEST';
export const MEDIA_UPLOAD_HISTORY_SUCCESS = 'MEDIA_UPLOAD_HISTORY_SUCCESS';
export const MEDIA_UPLOAD_HISTORY_FAIL = 'MEDIA_UPLOAD_HISTORY_FAIL';

export function getMediaUploadHistory(sDate,eDate,currentPage=1,pageSize=24) {
    let custGroupID = KCStore.getItem("custGroupID");
		     let cgids = "";
			if (custGroupID != "" && custGroupID != undefined) {
				cgids = custGroupID;
            }
            

        let url = `${MediaManagementAPI.getUploadHistory}`;
		let pageNumber = '?pageNum=' + currentPage;
		let pageLimit = '&pageSize=' + pageSize;
		
		url = url + pageNumber + pageLimit;

    return dispatch => {
        dispatch({
            type: MEDIA_UPLOAD_HISTORY_REQUEST
        })

           http.get(`${url}`,

            {
                headers: {
                    'Content-Type': 'application/json',
                    customerId: CUSTOMER_ID,
                    brandId: BRAND_ID,
                    'cgids':cgids,
                    'basecountry': BASECOUNTRY_ID,
                    'startdate':sDate ? format(sDate, 'YYYY-MM-DD'):"",
					'enddate':eDate?format(eDate, 'YYYY-MM-DD'):"",
                },
                data:{}

            })
        .then(response => {
            console.log('MediaUploadHistory' ,response.data);
            dispatch({
                type: MEDIA_UPLOAD_HISTORY_SUCCESS,
                mediaUploadHistoryResponse: response.data.mediaDistributeResponse,
             })
        })            
        .catch(error => {
                dispatch({
                    type: MEDIA_UPLOAD_HISTORY_FAIL,
                    mediaUploadHistoryResponse:[],
                    error
                })
            })
    }
}


