import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { injectIntl,FormattedMessage } from 'react-intl';

class MenuApps extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			}
		};
	}

	componentDidMount = () => {
		$('body').removeClass("modal-backdrop show");
		document.body.style.backgroundColor = "#F2F2F2";
	}

	// Woopra Event
	trackEvent = (e) => {
		WoopraEvents(`${Constants.MENU_AND_UNITSETTING}-${e.target.title}`);
	}

	render() {
		let { loaderIconVisible } = this.state;
		const { formatMessage } = this.props.intl;
		
		return (

			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">

					<div class="menuUpdateWrapper">
						<div class="menuUpdateHeader">
							<ul>
								<li>
									<Link to="/menuBrands" title={formatMessage({ id: 'KC0661' })} onClick= {(e) => this.trackEvent(e)}><FormattedMessage id="KC0661"/></Link>
									<Link to="/menuUpdateStatus" title={formatMessage({ id: 'KC0659' })} onClick= {(e) => this.trackEvent(e)}><FormattedMessage id="KC0659"/></Link>
									<Link to="/menuApps" title={formatMessage({ id: 'KC0660' })} className="active"><FormattedMessage id="KC0660"/></Link>
								</li>
							</ul>
						</div>
						<div class="recipeCreationTableDiv">
							<div class="recipeCreationTable">
								<ul class="tHead">
									<li>#</li>
									<li><FormattedMessage id="KC0062"/></li>
									<li>&nbsp;</li>
									<li>&nbsp;</li>
								</ul>
								<ul class="tBody">
									<li>01</li>
									<li><FormattedMessage id="KC0700"/></li>
									<li>&nbsp;</li>
									<li><Link to="/menuConvolink" className="forwardNext">&nbsp;</Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
			</div>
		)
	}
};

export default injectIntl(MenuApps);

