import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { getTotalDrinksList } from '../../actions/reportManagement/totalDrinksAction';
import { getAverageDrinksList } from '../../actions/reportManagement/averageDrinksAction';
import { getTopTenTableInfo } from '../../actions/reportManagement/topTenTableActoin';
import { getheatMapTableInfo } from '../../actions/reportManagement/heatMapTableAction';
import { getpeakHourChartInfo } from '../../actions/reportManagement/peakHourChartAction';
import PeakHoursChart from './peakHoursChart';
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import _ from 'lodash';
import authHelper from '../../utils/authHelper';
import { RESET_CYCLE_WIDGET_ID, FRYMASTER_UHCTHD_RESET_CYCLE_WIDGET_ID } from '../../utils/appConstants';
import { getresetcycles } from '../../actions/unitDashboard/resetCycleAction';
import AverageHoldingCycles from './averageHoldingCycles';
import NumberOfHoldingCycles from './NumberOfHoldingCycles';
import TotalProductCookedChart from './merrychefWidgets/totalProductsCookedChart';
import HoldingDataSummaryTable from './holdingDataSummaryTable';
import TopTenRecipes from '../unitDashboard/globalWidgets/topTenRecipes';
import HeatMapTable from '../reportManagement/globalWidgets/heatMapTable';
import SingleDataWidget from '../unitDashboard/globalWidgets/singledatawidget';
import SingleDataTrendFilterWidget from '../unitDashboard/globalWidgets/singledatatrendfilterwidget';
import FrypotTimeChart from './frymasterWidgets/FrypotTimeChart';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import ProductMixByHourTable from '../reportManagement/frymasterWidgets/productMixByHourTable';
import TotalCookCount from '../unitDashboard/merrychefWidgets/totalCookCount';
import CookingTableData from '../reportManagement/garlandWidgets/CookingTableData';
import TotalCooksLineChart from '../reportManagement/garlandWidgets/TotalCooksLineChart';
import TotalCooksPerPlatenChart from '../reportManagement/garlandWidgets/TotalCooksPerPlatenChart';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import { mappingStringID } from '../../locale/constant';

import KCStore from '../../utils/kcStorage';


const Colors = ["#ff97cd", "#0083b3", "#7192f1", "#c10b6d", "#414548", "#eacd61", "#59a9ed", "#00eabf", "#ff3b84", "#e08e53"];
var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;
let productName;
let brandIdValue;
class Production extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			avgType: 'Month',
			topTenType: 'Month',
			peakHourType: 'Month',
			noOfDrinksType: 'Month',
			filterType: "Month",
			dateRange: {
				selection: {
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection',
				},
			},
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			sortColumnDrinks: { path: "", order: "", sort_color: "#FFFFFF" },
			IsServerError: false,
			sd: '',
			ed: '',
			startDate: '',
			endDate: '',
			sdAvgDrinks: '',
			edAvgDrinks: '',
			startDateAvgDrinks: '',
			endDateAvgDrinks: '',
			sdPeakHour: '',
			edPeakHour: '',
			startDatePeakHour: '',
			endDatePeakHour: '',
			sdTopTen: '',
			edTopTen: '',
			startDateTopTen: todaysDate,
			endDateTopTen: todaysDate,
			datapickflagTopTen:false,
			sdHeatMap: '',
			edHeatMap: '',
			startDateHeatMap: todaysDate,
			endDateHeatMap: todaysDate,
			datapickflagHeatMap:false,
			loaderIconVisible: true,
			hideDateRange: true,
			ResetCycle: [],
			startDateResetCycles: "",
			endDateResetCycles: "",
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			datapickflag: false,
			datapickflagResetCycle:false,
			datapickflagAvgDrinks:false,
			isGlobalDropdownChange:false,
			datapickflagPeakHour:false,
			globalDropdownFilter: 'Month',
			globalDropdownFilterStartDate: '',
			globalDropdownFilterEndDate: '',
			startDateTotalDrinks: '',
			endDateTotalDrinks: '',
			datapickflagProductProduced: false,
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);

		this.handleChangeStartAvgDrinks = this.handleChangeStartAvgDrinks.bind(this);
		this.handleChangeEndAvgDrinks = this.handleChangeEndAvgDrinks.bind(this);

		this.handleChangeStartPeakHour = this.handleChangeStartPeakHour.bind(this);
		this.handleChangeEndPeakHour = this.handleChangeEndPeakHour.bind(this);

		this.handleChangeStartTopTen = this.handleChangeStartTopTen.bind(this);
		this.handleChangeEndTopTen = this.handleChangeEndTopTen.bind(this);

		this.handleChangeStartHeatMap = this.handleChangeStartHeatMap.bind(this);
		this.handleChangeEndHeatMap = this.handleChangeEndHeatMap.bind(this);
	}

	componentDidMount = async () => {
		todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		productName = currentUnit.MODEL_NAME;
		brandIdValue = KCStore.getItem("unitBrandId");
		this.props.getTotalDrinksList("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
		this.props.getAverageDrinksList("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
		this.props.getTopTenTableInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, "Production");
		this.props.getheatMapTableInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, productName);
		this.props.getpeakHourChartInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, "Production");
		//Reset Cycle Widget ID
		let widgetId = (brandID === "FRYMASTER-UHCTHD") ? FRYMASTER_UHCTHD_RESET_CYCLE_WIDGET_ID : RESET_CYCLE_WIDGET_ID;
		this.props.getresetcycles("Month", todaysDateFormatted, weekDateFormatted, unitID, brandIdValue, widgetId);
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	//start Date Reset Cycles
	handleChangeStartResetCycles(onTimeDate) {
		this.setState({
			startDateResetCycles: onTimeDate,
			datapickflagResetCycle: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e, 'ResetCycles');
		});
	}

	//end Date Reset Cycles
	handleChangeEndResetCycles(onTimeDate) {
		this.setState({
			endDateResetCycles: onTimeDate,
			datapickflagResetCycle: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e, 'ResetCycles');
		});
	}

	//Total Drinks Produced
	handleChangeStartTotalDrinks(totalDrinksDate, mindate) {
        this.setState({
            startDateTotalDrinks: totalDrinksDate,
            datapickflagProductProduced: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText: selectedtext };
            this.onDropDownHandler(e, 'TotalDrinks');
        });
    }

    handleChangeEndTotalDrinks(dateAvgDrinks) {
        this.setState({
            endDateTotalDrinks: dateAvgDrinks,
            datapickflagProductProduced: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText: selectedtext };
            this.onDropDownHandler(e, 'TotalDrinks');
        });
    }

	handleChangeStart(date, mindate) {
		this.setState({
			startDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e, 'TotalDrinks');
		});
	}

	handleChangeEnd(date) {
		this.setState({
			endDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e, 'TotalDrinks');
		});
	}

	//Average Drinks Produced

	handleChangeStartAvgDrinks(dateAvgDrinks, mindate) {
		this.setState({
			startDateAvgDrinks: dateAvgDrinks,
			datapickflagAvgDrinks: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e, 'AverageDrinks');
		});
	}

	handleChangeEndAvgDrinks(dateAvgDrinks) {
		this.setState({
			endDateAvgDrinks: dateAvgDrinks,
			datapickflagAvgDrinks: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e, 'AverageDrinks');
		});
	}

	//Peak Hour Drinks
	handleChangeStartPeakHour(datePeakHour, mindate) {
		this.setState({
			startDatePeakHour: datePeakHour,
			datapickflagPeakHour:true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e, 'PeakHourDrinks');
		});
	}

	handleChangeEndPeakHour(datePeakHour) {
		this.setState({
			endDatePeakHour: datePeakHour,
			datapickflagPeakHour: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			this.onDropDownHandler(e, 'PeakHourDrinks');
		});
	}

	//Top Ten Drinks
	handleChangeStartTopTen(dateTopTen, mindate) {
		this.setState({ startDateTopTen: dateTopTen,datapickflagTopTen: true, sdTopTen: dateTopTen, endDateTopTen: dateTopTen, edTopTen: moment(dateTopTen).add('month', 1).subtract(1, 'days')._d });
	}

	handleChangeEndTopTen(dateTopTen) {
		let selectedtext = 'Date Range';
		this.setState({
			endDateTopTen: dateTopTen,
			datapickflagTopTen: true
		})
		var e = { target: { text: selectedtext }, nodeText: selectedtext };
		this.onDropDownHandler(e, 'TopTenDrinks');
	}

	//Top Ten Drinks
	handleChangeStartHeatMap(dateHeatMap, mindate) {
		this.setState({ startDateHeatMap: dateHeatMap,datapickflagHeatMap: true, sdHeatMap: dateHeatMap, endDateHeatMap: dateHeatMap, edHeatMap: moment(dateHeatMap).add('month', 1).subtract(1, 'days')._d });
	}

	handleChangeEndHeatMap(dateHeatMap) {
		let selectedtext = 'Date Range';
		this.setState({
			endDateHeatMap: dateHeatMap,
			datapickflagHeatMap: true
		});
		var e = { target: { text: selectedtext }, nodeText: selectedtext };
		this.onDropDownHandler(e, 'HeatMapTable');
	}

	onDropDownHandler(e, type = '') {
		const { nodeText:text } = e;
		const key = type === 'HeatMapTable' ? 'datapickflagHeatMap' 
						: type === "TopTenDrinks" ? "datapickflagTopTen" 
						: type === "PeakHourDrinks" ? "datapickflagPeakHour" 
						: type === "AverageDrinks" ? "datapickflagAvgDrinks"
						: type === "ResetCycles" ? "datapickflagResetCycle"
						: type === "TotalDrinks" ? "datapickflagProductProduced" 
						: 'datapickflag'
		this.setState({
			[key]: text === 'Date Range'
		})
		this.handleDropdownHandler(text, type);
	}


	handleDropdownHandler(text, type) {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		productName = currentUnit.MODEL_NAME;
		brandIdValue = KCStore.getItem("unitBrandId");
		const { startDateResetCycles, endDateResetCycles } = this.state;
		if (text === "Date Range") {
			if (type === "TotalDrinks") {
				todaysDateFormatted = format(this.state.endDateTotalDrinks, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateTotalDrinks, 'YYYY-MM-DD');
			}
			else if (type === "AverageDrinks") {
				todaysDateFormatted = format(this.state.endDateAvgDrinks, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateAvgDrinks, 'YYYY-MM-DD');
			}
			else if (type === "PeakHourDrinks") {
				todaysDateFormatted = format(this.state.endDatePeakHour, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDatePeakHour, 'YYYY-MM-DD');
			}
			else if (type === "TopTenDrinks") {
				todaysDateFormatted = format(this.state.endDateTopTen, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateTopTen, 'YYYY-MM-DD');
			}
			else if (type === "ResetCycles") {
				todaysDateFormatted = format(endDateResetCycles, 'YYYY-MM-DD');
				weekDateFormatted = format(startDateResetCycles, 'YYYY-MM-DD');
			}
			else {
				todaysDateFormatted = format(this.state.endDateHeatMap, 'YYYY-MM-DD');
				weekDateFormatted = format(this.state.startDateHeatMap, 'YYYY-MM-DD');
			}
		}
		else {
			todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
			weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
		}

		if (type === "TotalDrinks") {
			this.setState({ type: text });
			this.props.getTotalDrinksList(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
		else if (type === "AverageDrinks") {
			this.setState({ avgType: text });
			if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") &&
				(weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
				if (KCStore.getItem("brandName") === "CREM") {
					brandID = "CREM";
				} else {
					brandID = currentUnit.BRAND_ID;
				}
				this.props.getAverageDrinksList(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
			}
		}
		else if (type === "PeakHourDrinks") {
			this.setState({ peakHourType: text });
			this.props.getpeakHourChartInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, "Production");
		}
		else if (type === "TopTenDrinks") {
			this.setState({ topTenType: text });
			this.props.getTopTenTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, "Production");
		}
		else if (type === "HeatMapTable") {
			this.setState({ noOfDrinksType: text });
			this.props.getheatMapTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, productName);
		}
		else if (type === "ResetCycles") {
			this.setState({ type: text });
			if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") &&
				(weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
				let widgetId = (brandID === "FRYMASTER-UHCTHD") ? FRYMASTER_UHCTHD_RESET_CYCLE_WIDGET_ID : RESET_CYCLE_WIDGET_ID;
				this.props.getresetcycles(text, todaysDateFormatted, weekDateFormatted, unitID, brandIdValue, widgetId);
			}
		}
	}

	handleChangeGlobalStart(date) {
		const { currentUnit } = this.props;

		this.setState({
			globalDropdownFilterStartDate: date,
			datapickflag: true,
			datapickflagResetCycle:true,
			datapickflagTopTen: true,
			datapickflagPeakHour:true,
			datapickflagAvgDrinks:true,
			datapickflagHeatMap:true,
			datapickflagProductProduced: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			if (currentUnit.BRAND_NAME === "CONVOTHERM") {
				this.refs.convothermCompletedTray.handleChangeStart(date);
				this.refs.convothermTraywithoutCleaning.handleChangeStart(date);
				this.refs.convothermCancelledTray.handleChangeStart(date);
				this.refs.convothermTrayWithCriticalDoor.handleChangeStart(date);
				// this.refs.convothermTotalTrayCount.handleChangeStart(date);

			} else if (currentUnit.BRAND_NAME === "FRYMASTER") {
				this.refs.frymasterTotalCookCompleted.handleChangeStart(date);
				this.refs.frymasterAverageCookPerDay.handleChangeStart(date);
				this.refs.frymasterFrypotStatus.handleChangeStart(date);
				this.refs.frymasterTotalCookComplete.handleChangeStart(date)

			} else if (currentUnit.BRAND_NAME === "MERRYCHEF") {
				this.refs.merrychefTotalCook.handleChangeStart(date);
				this.refs.merrychefTotalProductCooked.handleChangeStart(date);
			} else if (currentUnit.BRAND_NAME === "MERCO" || currentUnit.BRAND_NAME === "FRYMASTER-UHCTHD") {
				this.handleChangeStartResetCycles(date);
			} else if (currentUnit.BRAND_NAME === "GARLAND") {
				if(this.refs.garlandCookPerPlaten){
					this.refs.garlandCookPerPlaten.handleChangeStart(date);
				}
				this.refs.garlandAvgCookPerDay.handleChangeStart(date);
				this.refs.garlandAvgCookPlaten.handleChangeStart(date);
			} else {
				this.handleChangeStart(date);
				this.handleChangeStartAvgDrinks(date);
				this.handleChangeStartPeakHour(date);
				this.handleChangeStartTotalDrinks(date);
			}
			this.onDropDownGlobalHandler(e);
		});
	}

	handleChangeGlobalEnd(date) {
		const { currentUnit } = this.props;

		this.setState({
			globalDropdownFilterEndDate: date,
			datapickflag: true,
			datapickflagResetCycle:true,
			datapickflagTopTen: true,
			datapickflagPeakHour:true,
			datapickflagAvgDrinks:true,
			datapickflagHeatMap:true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			if (currentUnit.BRAND_NAME === "CONVOTHERM") {
				this.refs.convothermCompletedTray.handleChangeEnd(date);
				this.refs.convothermTraywithoutCleaning.handleChangeEnd(date);
				this.refs.convothermCancelledTray.handleChangeEnd(date);
				this.refs.convothermTrayWithCriticalDoor.handleChangeEnd(date);
				// this.refs.convothermTotalTrayCount.handleChangeEnd(date);
			} else if (currentUnit.BRAND_NAME === "FRYMASTER") {
				this.refs.frymasterTotalCookCompleted.handleChangeEnd(date);
				this.refs.frymasterAverageCookPerDay.handleChangeEnd(date);
				this.refs.frymasterFrypotStatus.handleChangeEnd(date);
				this.refs.frymasterTotalCookComplete.handleChangeEnd(date)
			} else if (currentUnit.BRAND_NAME === "MERRYCHEF") {
				this.refs.merrychefTotalCook.handleChangeEnd(date);
				this.refs.merrychefTotalProductCooked.handleChangeEnd(date);
			} else if (currentUnit.BRAND_NAME === "MERCO" || currentUnit.BRAND_NAME === "FRYMASTER-UHCTHD") {
				this.handleChangeEndResetCycles(date);
			} else if (currentUnit.BRAND_NAME === "GARLAND") {
				if(this.refs.garlandCookPerPlaten){
					this.refs.garlandCookPerPlaten.handleChangeEnd(date);
				}
				this.refs.garlandAvgCookPerDay.handleChangeEnd(date);
				this.refs.garlandAvgCookPlaten.handleChangeEnd(date)
			} else {
				this.handleChangeEnd(date);
				this.handleChangeEndAvgDrinks(date);
				this.handleChangeEndPeakHour(date);
				this.handleChangeEndTotalDrinks(date);
			}
			this.onDropDownGlobalHandler(e);
		});
	}

	onDropDownGlobalHandler(e) {
		const { currentUnit } = this.props;
		const filterType = e.nodeText;

		this.setState({
			globalDropdownFilter: filterType,
			isGlobalDropdownChange:true,
			datapickflag: filterType === 'Date Range',
			datapickflagResetCycle:filterType === 'Date Range',
			datapickflagTopTen: filterType === 'Date Range',
			datapickflagPeakHour:filterType === 'Date Range',
			datapickflagAvgDrinks:filterType === 'Date Range',
			datapickflagHeatMap:filterType === 'Date Range',
			datapickflagProductProduced:filterType === 'Date Range',
		});
		if (currentUnit.BRAND_NAME === "CONVOTHERM") {

			this.refs.convothermCompletedTray.fetchData(this.refs.convothermCompletedTray.state.holder_id, filterType)
			this.refs.convothermCompletedTray.setState({ filterType, datapickflag: filterType === 'Date Range' });

			this.refs.convothermTraywithoutCleaning.fetchData(this.refs.convothermTraywithoutCleaning.state.holder_id, filterType)
			this.refs.convothermTraywithoutCleaning.setState({ filterType, datapickflag: filterType === 'Date Range' });

			this.refs.convothermCancelledTray.fetchData(this.refs.convothermCancelledTray.state.holder_id, filterType)
			this.refs.convothermCancelledTray.setState({ filterType, datapickflag: filterType === 'Date Range' });

			this.refs.convothermTrayWithCriticalDoor.fetchData(this.refs.convothermTrayWithCriticalDoor.state.holder_id, filterType)
			this.refs.convothermTrayWithCriticalDoor.setState({ filterType, datapickflag: filterType === 'Date Range' });
			if(filterType !== 'Date Range'){
				this.refs.convothermTotalTrayCount.handleTotalProductCooked(filterType)
				this.refs.convothermTotalTrayCount.setState({ filterType, datapickflag: filterType === 'Date Range' });
			}
		} else if (currentUnit.BRAND_NAME === "FRYMASTER") {

			this.refs.frymasterTotalCookCompleted.fetchData(this.refs.frymasterTotalCookCompleted.state.holder_id, filterType)
			this.refs.frymasterTotalCookCompleted.setState({ filterType, datapickflag: filterType === 'Date Range' });

			this.refs.frymasterAverageCookPerDay.fetchData(this.refs.frymasterAverageCookPerDay.state.holder_id, filterType)
			this.refs.frymasterAverageCookPerDay.setState({ filterType, datapickflag: filterType === 'Date Range' });

			if(filterType === 'Day' || filterType === 'Week' || filterType === 'Date Range'){
				this.refs.frymasterFrypotStatus.handleFrypotTimeChart(filterType)
				this.refs.frymasterFrypotStatus.setState({ type: filterType, datapickflag: filterType === 'Date Range' });
			}
			this.refs.frymasterTotalCookComplete.handleTotalProductCooked(filterType)
			this.refs.frymasterTotalCookComplete.setState({ filterType, datapickflag: filterType === 'Date Range' });

		} else if (currentUnit.BRAND_NAME === "MERRYCHEF") {
			this.refs.merrychefTotalCook.fetchData(this.refs.merrychefTotalCook.state.holder_id, filterType)
			this.refs.merrychefTotalCook.setState({ filterType, datapickflag: filterType === 'Date Range' });

			this.refs.merrychefTotalProductCooked.handleTotalProductCooked(filterType, currentUnit.BRAND_NAME)
			// this.refs.merrychefTotalProductCooked.setState({ filterType, datapickflag: filterType === 'Date Range' });
		} else if (currentUnit.BRAND_NAME === "MERCO" || currentUnit.BRAND_NAME === "FRYMASTER-UHCTHD") {
			this.handleDropdownHandler(filterType, 'ResetCycles');
		} else if (currentUnit.BRAND_NAME === "GARLAND") {
			if(this.refs.garlandCookPerPlaten){
				this.refs.garlandCookPerPlaten.fetchData(filterType);
				this.refs.garlandCookPerPlaten.setState({ filterType, datapickflag: filterType === 'Date Range' });
			}

			this.refs.garlandAvgCookPerDay.fetchData(this.refs.garlandAvgCookPerDay.state.holder_id, filterType)
			this.refs.garlandAvgCookPerDay.setState({ filterType, datapickflag: filterType === 'Date Range' });

			this.refs.garlandAvgCookPlaten.handleTotalProductCooked(filterType)
			this.refs.garlandAvgCookPlaten.setState({ filterType, datapickflag: filterType === 'Date Range' });

		} else {
			this.handleDropdownHandler(filterType, 'AverageDrinks');
			this.handleDropdownHandler(filterType, 'TotalDrinks')
			this.handleDropdownHandler(filterType, 'PeakHourDrinks')
		}

	}

	handleRangeChange(which, payload) {
		this.setState({
			[which]: {
				...this.state[which],
				...payload,
			},
		});
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	onSortDrinks = sortColumnDrinks => {
		this.setState({
			sortColumnDrinks: sortColumnDrinks
		});
	}
	errorCloseModel = () => {
		authHelper.deleteLocalSession();
		this.props.history.push('/');
	}

	returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (KCStore.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

	//  woopra event
	reportEvent =(e) => {
		WoopraEvents(`${Constants.REPORT_AND_ANALYTICS}-${e.trackEvent}`);
	};

	render() {
		let topTenTableTotal = 0;
		let topTenTable = [];
		let heatMapTable = [];
		let heatMapTableWithColumn = [];
		let refeshTime = format(new Date(), 'hh:mm');
		let refeshTime24hrs = format(new Date(), 'HH');
		let orderId = 1;
		let { IsServerError, loaderIconVisible,isGlobalDropdownChange, globalDropdownFilter, globalDropdownFilterEndDate, globalDropdownFilterStartDate } = this.state;
		const { peakHourChartInfo, topTenTableInfo, heatMapTableInfo, errormsgHeatMap, errormsgTopTenTable, IsTokenExpired, ExpiredMessage } = this.props;

		const { formatMessage } = this.props.intl;

		if (peakHourChartInfo.length == 0) {
			$(".PeakHoursBarChartdiv1 > div").css("display:none");
		}
		currentUnit = this.props.currentUnit;
		let { topTenTableInformation } = topTenTableInfo;
		if (currentUnit.BRAND_NAME === "MERRYCHEF") {
			topTenTableInformation = topTenTableInfo.topTenTableInformation.result;
		}

		if (currentUnit.BRAND_NAME !== "FRYMASTER") {
			if (topTenTableInformation !== "{}") {
				if (Array.isArray(topTenTableInformation) && topTenTableInformation.length > 0) {
					topTenTableTotal = topTenTableInformation.reduce((prev, cur) => prev + cur.counts, 0);
					topTenTable = topTenTableInformation;

					if (topTenTable && topTenTable.length > 0) {
						topTenTable.map((item, i) => { item.color = Colors[i] });
					}
				}
			}


			if (Array.isArray(heatMapTableInfo) && heatMapTableInfo.length > 0) {
				heatMapTable = heatMapTableInfo;
				for (var j = 0; j < heatMapTableInfo.length; j++) {
					let heatMapTableWithColumnArr = {};
					heatMapTableWithColumnArr.productID = heatMapTableInfo[j].productID;
					heatMapTableInfo[j].counts.map((countItem, index) => {
						const { noOfDrinksType } = this.state;
						const i = index + 1;
						var obj = [];
						const month = () => {
							switch (noOfDrinksType) {
								case 'Month':
									obj = 'Week' + i;
									heatMapTableWithColumnArr[obj] = countItem;
									break;
								case 'Day':
									obj = 'Day';
									heatMapTableWithColumnArr[obj] = countItem;
									break;
								case 'Quarter':
									obj = 'Month' + i;
									heatMapTableWithColumnArr[obj] = countItem;
									break;
								case 'Year':
									obj = 'Q' + i;
									heatMapTableWithColumnArr[obj] = countItem;
									break;
								case 'Week':
									obj = 'Day' + i;
									heatMapTableWithColumnArr[obj] = countItem;
									break;
								default:
									obj = 'Week' + i;
									heatMapTableWithColumnArr[obj] = countItem;
							}
						}
						month();
					})
					heatMapTableWithColumnArr.totalCount = heatMapTableInfo[j].totalCount;
					heatMapTableWithColumnArr.trend = heatMapTableInfo[j].trend;
					heatMapTableWithColumn.push(heatMapTableWithColumnArr);
				}
			}
		}

		const { totalDrinksInformation, errormsgTotalDrinks } = this.props.totalDrinksInfo;
		let totalProductsTrend = this.props.totalDrinksInfo.totalDrinksInformation.trend;
		const { averageDrinksInformation, errormsgAvgDrinks } = this.props.averageDrinksInfo;
		let avgProductsTrend = this.props.averageDrinksInfo.averageDrinksInformation.trend;
		const { errormsgPeakHour } = this.props.getpeakHourChartInfo;

		IsServerError = errormsgTotalDrinks || errormsgAvgDrinks || errormsgPeakHour || errormsgHeatMap || errormsgTopTenTable;

		let drinksCountValueDiv = "";
		let drinksTotalSoldDiv = "";
		if (topTenTableTotal != undefined) {
			if (topTenTableTotal > 0) {
				drinksCountValueDiv = <div class="piechartvalueText"> {this.returnNumberFormat(topTenTableTotal)} <span class="drinksText"/* trendimg"*/><FormattedMessage id="KC0176"/></span></div>
				drinksTotalSoldDiv = <div class="totalsoldtext"> <FormattedMessage id="KC0174"/></div>
			}
		}

		const { noOfDrinksType, sortColumn, sortColumnDrinks } = this.state;
		let heatmaptableclass = "drinksSoldTable";
		if (noOfDrinksType === "Day") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableDay";
		}
		else if (noOfDrinksType === "Week") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableWeek";
		}
		else if (noOfDrinksType === "Month" || noOfDrinksType === "custom") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableMonth";
		}
		else if (noOfDrinksType === "Quarter") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableQuarter";
		}
		else if (noOfDrinksType === "Year") {
			heatmaptableclass = "drinksSoldTable drinksSoldTableYear";
		}

		let columnsDrinks = [
			{ path: "", label: "#" }
		];
		if (heatMapTableWithColumn.length > 0) {
			Object.keys(heatMapTableWithColumn[0]).forEach(function (key) {
				if (key === "productID") {
					columnsDrinks.push({ path: key, label: "Product" });
				}
				else if (key === "totalCount") {
					columnsDrinks.push({ path: key, label: "Count" });
				}
				else if (key === "trend") {
					columnsDrinks.push({ path: key, label: "Trend" });
				}
				else {
					columnsDrinks.push({ path: key, label: key });
				}
			});
			var swapItem = columnsDrinks[columnsDrinks.length - 1];
			columnsDrinks[columnsDrinks.length - 1] = columnsDrinks[columnsDrinks.length - 2];
			columnsDrinks[columnsDrinks.length - 2] = swapItem;
		}
		else {
			columnsDrinks.push(
				{ path: "productID", label: "Product" },
				{ path: "trend", label: "Trend" },
				{ path: "totalCount", label: "Count" }
			);
		}
		topTenTable = _.orderBy(topTenTable, [sortColumn.path], [sortColumn.order]);
		heatMapTableWithColumn = _.orderBy(heatMapTableWithColumn, [sortColumnDrinks.path], [sortColumnDrinks.order]);

		topTenTable.forEach(item => {
			item.order_id = orderId++
		});

		// MERCO Utilization Header display
		currentUnit = this.props.currentUnit;
		let utilizationWidget = null;
		if (currentUnit.BRAND_NAME === 'MERCO' || currentUnit.BRAND_NAME === 'MERRYCHEF' || currentUnit.BRAND_NAME === 'FRYMASTER-UHCTHD') {
			utilizationWidget = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({id: "KC0175"})} className="active"><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/utilization" title={formatMessage({id: "KC0270"})} onClick={(e)=>{e.trackEvent = "Utilization"; this.reportEvent(e)}} ><FormattedMessage id="KC0270" /></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}

		// CONVOTHERM Clean Tab header display
		else if (currentUnit.BRAND_NAME === 'CONVOTHERM') {
			utilizationWidget = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({id:"KC0175"})} className="active"><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/clean" title={formatMessage({id: "KC0405"})} onClick={(e)=>{e.trackEvent = "Clean";this.reportEvent(e)}}><FormattedMessage id="KC0405"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/utilization" title={formatMessage({id: "KC0270"})} onClick={(e)=>{e.trackEvent = "Utilization";this.reportEvent(e)}} ><FormattedMessage id="KC0270" /></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}
		// FRYMASTER Production || Filtration || Oil Usage || TPM Value tab display
		else if (currentUnit.BRAND_NAME === 'FRYMASTER') {
			utilizationWidget = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({id:"KC0175"})} className="active"><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/filtration" title={formatMessage({id:"KC0442"})} onClick={(e)=>{e.trackEvent="Filtration";this.reportEvent(e)}}><FormattedMessage id="KC0442"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/oilUsage" title={formatMessage({id:"KC0443"})} onClick={(e)=>{e.trackEvent="Oil Usage";this.reportEvent(e)}}><FormattedMessage id="KC0443"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/tpmValue" title={formatMessage({id:"KC0444"})} onClick={(e)=>{e.trackEvent="TPM Value";this.reportEvent(e)}}><FormattedMessage id="KC0444"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}
		// GARLAND Production | Platen Cycles | Utilization Tab header display
		else if (currentUnit.BRAND_NAME === 'GARLAND') {
			utilizationWidget = (<div class="pageHeader"> 
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({id:"KC0175"})} className="active"><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/clean" title={formatMessage({id:"KC1799"})} onClick={(e)=>{e.trackEvent="Platen Cycles";this.reportEvent(e)}}><FormattedMessage id="KC1799"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/utilization" title={formatMessage({id:"KC0270"})} onClick={(e)=>{ e.trackEvent="Utilization";this.reportEvent(e)}}><FormattedMessage id="KC0270"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}
		// CREM Header display
		else {
			utilizationWidget = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({id:"KC0175"})} className="active"><FormattedMessage id="KC0175"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>
			)
		}

		// MERCO production widgets display
		let productionWidgets = null;
		let productionWidgetsRow2 = null;
		const { ResetCycleResponse } = this.props;
		const ResetCycleInformation = ResetCycleResponse.ResetCycleResponse ? ResetCycleResponse.ResetCycleResponse : {};
		let ResetCycle = ResetCycleInformation.content;
		let widgetResetCycles = null;
		let commonFilterPriorValue = null;
		if (Array.isArray(ResetCycle) && ResetCycle.length) {
			if (ResetCycle.length === 1) {
				widgetResetCycles = (
					<div class="colmDiVal">
						<div className="valH1Div"> {this.returnNumberFormat(Math.round(ResetCycle[0].value))} </div>
					</div>
				)
			} else {
				widgetResetCycles = (
					<div class="colmDiVal">
						<div className="valH1Div">{this.returnNumberFormat(Math.round(ResetCycle[0].value))} {this.state.type !== "Date Range" && <span className={ResetCycle[2].value === '1' ? "valH1Span valHigh" : ResetCycle[2].value === '0' ? "valH1Span valLow" : "valH1Span"}> &nbsp;</span>}</div>
						{this.state.type !== "Date Range" && (
							<div class="valH5Div">
								{mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/>: `prior ${this.state.type.toLowerCase()}`}: {this.returnNumberFormat(Math.round(ResetCycle[1].value))}
							</div>
						)}
					</div>
				)
			}
		}
		let productNumber = ((currentUnit.MODEL_NAME === "XPE12") || (currentUnit.MODEL_NAME === "XPG12") || (currentUnit.MODEL_NAME === "ME-12") || (currentUnit.MODEL_NAME === "MG-12"));
		if (currentUnit.BRAND_NAME === 'MERCO' || currentUnit.BRAND_NAME === 'FRYMASTER-UHCTHD') {
			commonFilterPriorValue = (this.state.avgType !== "Date Range") ? (<div class="valH5Div">
				{mappingStringID[`prior ${this.state.avgType.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.avgType.toLowerCase()}`]}/>: `prior ${this.state.avgType.toLowerCase()}`}: 159
			</div>) : ""
			productionWidgets = (
				<div>
					<div class="gridViewTable gridViewcolm6DSM">
						<div class="gridView">
							<div class="colm6D colm6D1">
								<div class="colmDi">
									<h4><FormattedMessage id="KC0363"/>
									 		<span class="red-txt"></span><span class="cornerIcon"></span>
										<NewDropDownListLink translation={true} datapickflag={this.state.datapickflagResetCycle} startDatepara={this.state.startDateResetCycles} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateResetCycles} handleChangeStartProps={(date) => this.handleChangeStartResetCycles(date)} handleChangeEndProps={(date) => this.handleChangeEndResetCycles(date)} OnChange={(e) => this.onDropDownHandler(e, "ResetCycles")} filterType={this.state.type} />
									</h4>
									{widgetResetCycles}
								</div>
							</div>
							<div class="colm6D colm6D2 disabled">
								<div class="colmDi">
									<h4><FormattedMessage id="KC0364"/>
									</h4>
								</div>
							</div>
						</div>
					</div>
					<div class="gridViewTable gridViewcolm6DSM">
						<div class="gridView">
							<NumberOfHoldingCycles page={"Dashboard"} noOfHoldingCyclesInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
							<AverageHoldingCycles page={"Dashboard"} avgHoldingCyclesInfo={this.props} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
						</div>
					</div>
				</div>
			)
		}
		else if (currentUnit.BRAND_NAME === 'MERRYCHEF') {
			productionWidgets = (<div class="gridViewTable gridViewcolm6DSM">
				<div class="gridView">
					<SingleDataTrendFilterWidget ref="merrychefTotalCook" properties={this.props} widgetID="4" widgetName="TOTAL COOKS" widgetNameStringID="KC0266" classValue="colm6D colm6D1" isTimeDisplay="true" />
					<SingleDataWidget properties={this.props} widgetID="29" widgetName="TOTAL COOKS SINCE MENU UPDATE" widgetNameStringID="KC0267" classValue="colm6D colm6D2" />
				</div>
			</div>)
		}
		else if (currentUnit.BRAND_NAME === 'CONVOTHERM') {
			productionWidgets = (<div class="gridViewTable gridViewcolm6DSM">
				<div class="gridView">
					<SingleDataTrendFilterWidget ref="convothermCompletedTray" properties={this.props} widgetID="39" widgetName="COMPLETED TRAYS" widgetNameStringID="KC0399" classValue="colm6D colm6D1" isTimeDisplay="true" />
					<SingleDataTrendFilterWidget ref="convothermTraywithoutCleaning" properties={this.props} widgetID="42" widgetName="TRAYS WITHOUT CLEANING" widgetNameStringID="KC0400" infoTextStringID="KC2233" classValue="colm6D colm6D2" isTimeDisplay="false" />
				</div>
			</div>)
			productionWidgetsRow2 = (<div class="gridViewTable gridViewcolm6DSM">
				<div class="gridView">
					<SingleDataTrendFilterWidget ref="convothermCancelledTray" properties={this.props} widgetID="40" widgetName="CANCELLED TRAYS" widgetNameStringID="KC0401" infoTextStringID="KC2235" classValue="colm6D colm6D1" isTimeDisplay="false" />
					<SingleDataTrendFilterWidget ref="convothermTrayWithCriticalDoor" properties={this.props} widgetID="44" widgetName="TRAYS WITH CRITICAL DOOR OPENINGS" widgetNameStringID="KC0402" infoTextStringID="KC2234" classValue="colm6D colm6D2" isTimeDisplay="false" />
				</div>
			</div>)
		}
		else if (currentUnit.BRAND_NAME === 'FRYMASTER') {
			productionWidgets = (<div class="gridViewTable gridViewcolm6DSM">
				<div class="gridView">
					<SingleDataTrendFilterWidget ref="frymasterTotalCookCompleted" properties={this.props} widgetID="52" widgetName="TOTAL COOKS COMPLETED" widgetNameStringID="KC0430" classValue="colm6D colm6D1" isTimeDisplay="true" />
					<SingleDataTrendFilterWidget ref="frymasterAverageCookPerDay" properties={this.props} widgetID="63" widgetName="AVG. COOKS PER DAY" widgetNameStringID="KC0445" classValue="colm6D colm6D2" isTimeDisplay="false" />
				</div>
			</div>)
		}
		else if (currentUnit.BRAND_NAME === 'GARLAND') {
			productionWidgets = (<div class="gridViewTable gridViewcolm4DSM">
				<div class="gridView">
					<TotalCookCount widgetname="TOTAL COOKS COMPLETED" widgetnameStringID="KC0301" ProductsProduced={this.props} classValue="colm4D colm4D1" isTimeDisplay="true" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
					{productNumber ? "" : <TotalCooksPerPlatenChart ref="garlandCookPerPlaten" properties={this.props} widgetID="90" widgetName="TOTAL COOKS PER PLATEN" widgetNameStringID="KC0316" classValue="colm4D" isTimeDisplay="false" />}
					<SingleDataTrendFilterWidget ref="garlandAvgCookPerDay" properties={this.props} widgetID="91" widgetName="AVG. COOKS PER DAY" widgetNameStringID="KC0445" classValue="colm4D colm4D3" isTimeDisplay="false" />
				</div>
			</div>)
		}
		else {
			let currentValue = null;
			let priorValue = null;
			let avgCurrentValue = null;
			let avgPriorValue = null;
			currentValue = averageDrinksInformation.currentConsumption ? averageDrinksInformation.currentConsumption : 0;
			priorValue = averageDrinksInformation.lastConsumption ? averageDrinksInformation.lastConsumption : 0;

			avgCurrentValue = (currentValue > 0 && currentValue < 1) ? "<1" : ( this.returnNumberFormat(Math.round(currentValue)));
			avgPriorValue = (priorValue > 0 && priorValue < 1) ? "<1" : (this.returnNumberFormat(Math.round(priorValue)));
			let unitTime = averageDrinksInformation.unitTime ? averageDrinksInformation.unitTime.toLowerCase() : "";			
			if (KCStore.getItem("timeFormat") === "24"){
				unitTime = unitTime ? moment(unitTime, 'hh:mm A').format('HH:mm'):"";
			}
			productionWidgets = (
				<div class="gridViewTable gridViewcolm6DSM">
					<div class="gridView">
						<div class="colm6D colm6D1">
							<div class="colmDi">
								<h4><FormattedMessage id="KC0159"/>
									&nbsp; {KCStore.getItem("excludedProductList") === "true" ? <span class="blueDot" title={formatMessage({ id: "KC1037" })}></span> : ""}
									<span class="red-txt"></span><span class="cornerIcon"></span>
									<NewDropDownListLink translation={true} datapickflag={this.state.datapickflagProductProduced} startDatepara={this.state.startDateTotalDrinks} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateTotalDrinks} handleChangeStartProps={(date) => this.handleChangeStartTotalDrinks(date)} handleChangeEndProps={(date) => this.handleChangeEndTotalDrinks(date)} OnChange={(e) => this.onDropDownHandler(e, "TotalDrinks")} filterType={this.state.type} />
								</h4>
								<div class="colmDiVal">
									<div class="valH1Div">{this.returnNumberFormat(Math.round(totalDrinksInformation.currentConsumption))} <span className={Math.round(totalDrinksInformation.currentConsumption) === Math.round(totalDrinksInformation.lastConsumption) ? "valH1Span" : totalProductsTrend === '1' ? "valH1Span valHigh" : totalProductsTrend === '0' ? "valH1Span valLow" : "valH1Span"}><FormattedMessage id="KC0176"/></span></div>
									<div class={this.state.type === "Date Range" ? "displaynone" : "valH5Div"}>
										{mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }: {this.returnNumberFormat(Math.round(totalDrinksInformation.lastConsumption))} <FormattedMessage id="KC0176"/>
											</div>
								</div>
							</div>
						</div>
						<div class="colm6D colm6D2">
							<div class="colmDi">
								<h4><FormattedMessage id="KC0177"/>
								&nbsp; {KCStore.getItem("excludedProductList") === "true" ? <span class="blueDot" title={formatMessage({ id: "KC1037" })}></span> : ""}
									<span class="red-txt"></span><span class="cornerIcon"></span>
									<NewDropDownListLink translation={true} datapickflag={this.state.datapickflagAvgDrinks} startDatepara={this.state.startDateAvgDrinks} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateAvgDrinks} handleChangeStartProps={(date) => this.handleChangeStartAvgDrinks(date)} handleChangeEndProps={(date) => this.handleChangeEndAvgDrinks(date)} OnChange={(e) => this.onDropDownHandler(e, "AverageDrinks")} filterType={this.state.avgType} />
								</h4>
								<div class="colmDiVal">
									<div class="valH1Div"> {avgCurrentValue} <span className={(Math.round(avgCurrentValue) === Math.round(avgPriorValue)) ? "valH1Span" : avgProductsTrend === '1' ? "valH1Span valHigh" : avgProductsTrend === '0' ? "valH1Span valLow" : "valH1Span"}>{unitTime}</span></div>
									<div class={this.state.avgType === "Date Range" ? "displaynone" : "valH5Div"}>
										{mappingStringID[`prior ${this.state.avgType.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.avgType.toLowerCase()}`]}/>  : `prior ${this.state.avgType.toLowerCase()}` }: {avgPriorValue}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		//MERCO Holding Data 
		let holdingDataWidgets = null;
		if (currentUnit.BRAND_NAME === 'MERCO' || currentUnit.BRAND_NAME === 'FRYMASTER-UHCTHD') {
			holdingDataWidgets = (<HoldingDataSummaryTable currentUnit={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />)
		}
		else if (currentUnit.BRAND_NAME === 'MERRYCHEF') {
			holdingDataWidgets = (<HeatMapTable currentUnit={this.props} widgetName="Total Products Cooked" widgetNameStringID="KC0268" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />)
		}
		else if (currentUnit.BRAND_NAME === 'CONVOTHERM') {
			holdingDataWidgets = (<HeatMapTable currentUnit={this.props} widgetName="Cooking Data" widgetNameStringID="KC0321" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />)
		}
		// GARLAND Cooking Data Tabular View Implementation
		else if (currentUnit.BRAND_NAME === 'GARLAND') {
			holdingDataWidgets = (<CookingTableData currentUnit={this.props} widgetName="Cooking Data" widgetNameStringID="KC0321" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}/>)
		}
		else if (currentUnit.BRAND_NAME === 'FRYMASTER') {
			holdingDataWidgets = (
				<div class="gridViewTable">
					<div class="gridView">
						<div class="colm12D">
							<div class="colmDi">
								<FrypotTimeChart ref="frymasterFrypotStatus" widgetID="66" widgetName="FRYPOT STATUS VS TIME" widgetNameStringID="KC0452"  currentUnit={this.props.currentUnit} disableMonthFilter={true} />
							</div>
						</div>
					</div>
				</div>)
		}
		else {
			holdingDataWidgets = (<HeatMapTable currentUnit={this.props} widgetName="Number Of Products Sold" widgetNameStringID="KC0178" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />) 
		}

		let showTop10Recipes = null;
		if (currentUnit.BRAND_NAME === "CREM") {
			// CREM Expobar Model(EX3 DISPLAY & DIAMANT PRO DISPLAY) widget header name changes in reports & analytics screen
			let widgetHeadername = (currentUnit.MODEL_NAME === "EX3" || currentUnit.MODEL_NAME === "DIAMANT PRO") ? "Group Production Distribution" : "Top 10 Products Sold"
			let widgetHeadernameStringID = (currentUnit.MODEL_NAME === "EX3" || currentUnit.MODEL_NAME === "DIAMANT PRO") ? "KC1792" : "KC0168"
			showTop10Recipes = (<TopTenRecipes currentUnit={this.props} widgetname={widgetHeadername} widgetnameStringID={widgetHeadernameStringID} measure={"drinks"} caption={"total sold"} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})} globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}></TopTenRecipes>);
		}

		return (
			<React.Fragment>
				<div id="wrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<GlobalHeaderLayout activeClass="reportNav" />
					<div id="page-content-wrapper">
						{/* Level 1 | Widgets - 2 */}
						{utilizationWidget}
						{/* Level 2 | Widgets - 2 */}
						{productionWidgets}
						{/* Level 3 | Widgets - 2 */}
						{productionWidgetsRow2}
						{/* Level 4 | Widgets - 1 | Peak Hour(CREM) | Merco (displaynone) | Total Cooks (Merrychef) | Total Trays (Convotherm) | Total Cooks (Garland) */}
						{(KCStore.getItem("brandName") !== "MERCO" && KCStore.getItem("brandName") !== "FRYMASTER-UHCTHD") && <div class="gridViewTable">
							<div class="gridView">
								<div class="colm12D">
									{KCStore.getItem("brandName") === "MERRYCHEF" ? <TotalProductCookedChart ref="merrychefTotalProductCooked"  properties={this.props} widgetID="18" widgetName="Total Products Cooked" widgetNameStringID="KC0268" chartTitle="Avg. cooks per day" chartTitleStringID="KC0269" disableDatePicker={true} />
									: KCStore.getItem("brandName") === "CONVOTHERM" ? <TotalProductCookedChart ref="convothermTotalTrayCount" properties={this.props} widgetID="32" widgetName="Total Trays Count" widgetNameStringID="KC0403" chartTitle="Avg. trays per day" chartTitleStringID="KC0393" disableDatePicker={true} />
									: KCStore.getItem("brandName") === "FRYMASTER" ? <TotalProductCookedChart ref="frymasterTotalCookComplete" properties={this.props} widgetID="65" widgetName="Total Cooks Completed" widgetNameStringID="KC0301" chartTitle="Avg. cooks per frypot"  chartTitleStringID="KC0446" disableDatePicker={false} />
									: KCStore.getItem("brandName") === "GARLAND" ? <TotalCooksLineChart ref="garlandAvgCookPlaten" properties={this.props} widgetID="106" widgetName="Total Cooks" widgetNameStringID="KC0266" chartTitle="Avg. cooks per day and platen" chartTitleStringID="KC0319" disableDatePicker={false} />
										 : <div class="colmDi">
											<h4><FormattedMessage id="KC0804"/> &nbsp; {KCStore.getItem("excludedProductList") === "true" ? <span class="blueDot" title={formatMessage({ id: 'KC1037' })}></span> : ""} <span class="red-txt"></span>											
												<span class="cornerIcon"></span>
												<NewDropDownListLink translation={true} datapickflag={this.state.datapickflagPeakHour} startDatepara={this.state.startDatePeakHour} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate}  endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDatePeakHour} handleChangeStartProps={(date) =>this.handleChangeStartPeakHour(date)} handleChangeEndProps={(date) =>this.handleChangeEndPeakHour(date)} OnChange={(e) => this.onDropDownHandler(e, "PeakHourDrinks")} filterType={this.state.peakHourType} />
											</h4>
											<div class="peakHoursDBChartdiv">
												{ <PeakHoursChart peakHourChartInfo={peakHourChartInfo} />}
											</div>
										</div> }
								</div>
							</div>
						</div>}
						{/* Level 5 | Widgets - 1 | Top Ten | Product Mix by Hour of the day(Frymaster)*/}
						<div class={KCStore.getItem("brandName") === "MERCO" || KCStore.getItem("brandName") === "FRYMASTER" ? "displaynone" : "gridViewTable"}>
							<div class="gridView">
								<div class="colm12D">
									{showTop10Recipes}
								</div>
							</div>
						</div>
						{/* Level 5 | Widgets - 1 | Product Mix by Hour of the day(Frymaster)*/}
						{KCStore.getItem("brandName") === "FRYMASTER" &&
							<ProductMixByHourTable currentUnit={this.props} widgetID="68" widgetName="PRODUCT MIX BY HOUR OF THE DAY" widgetNameStringID="KC0448" isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
						}
						{/* Level 6 | Widgets - 1 | Heat Map Table | Frypot Status Vs Time(Frymaster)*/}
						{holdingDataWidgets}
					</div>
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		totalDrinksInfo: state.totalDrinksInformation,
		averageDrinksInfo: state.averageDrinksInformation,
		topTenTableInfo: state.topTenTableInformation,
		heatMapTableInfo: state.heatMapTableInformation.heatMapTableInformation,
		peakHourChartInfo: state.peakHourChartInformation,
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation,
		ResetCycleResponse: state.ResetCycleResponse,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getTotalDrinksList, getAverageDrinksList,
		getTopTenTableInfo, getheatMapTableInfo, getpeakHourChartInfo,
		getCurrentLocation, getCurrentUnit, getresetcycles
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Production));
