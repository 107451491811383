import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CustomDropDownList } from '../../controls/customDropDownList';
import { format } from 'date-fns';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { getUtilizationTableData } from '../../../actions/reportManagement/utilization/utilizationTableActions';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import { FormattedMessage } from 'react-intl';

import KCStore from '../../../utils/kcStorage';


var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'MM-DD-YYYY');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'MM-DD-YYYY');
let unitID = "";
let brandID = "";
let currentUnit;

class UtilizationSummaryTable extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			filterType: "Month",
			dateRange: {
				selection: {
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection',
				},
			},
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			sortColumnDrinks: { path: "", order: "", sort_color: "#FFFFFF" },
			IsServerError: false,
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			startDateOnTime: "",
			endDateOnTime: "",
			loaderIconVisible: true,
			hideDateRange: true,
			datapickflag: false
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount = async () => {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
		todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
		this.props.getUtilizationTableData("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	componentDidUpdate = async (prevProp, prevState) => {
		if (this.props.globalDropdownFilter !== "Year" && this.props.globalDropdownFilter !== "Quarter") {
			if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
				this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
				this.handleUtilizationSummary(this.props.globalDropdownFilter);
				await this.props.handleDropdownChangeEnd();
			} else {
				if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
					if (prevState.endDateOnTime !== this.props.globalDropdownFilterEndDate) {
						this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
						await this.props.handleDropdownChangeEnd();
					}
					if (prevState.startDateOnTime !== this.props.globalDropdownFilterStartDate) {
						this.handleChangeStart(this.props.globalDropdownFilterStartDate);
						await this.props.handleDropdownChangeEnd();
					}
				}
			}
		}
	}


	handleChangeStart(holdingDate) {
		if(this.state.startDateOnTime === holdingDate){
			return;
		}
		this.setState({
			startDateOnTime: holdingDate,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			this.handleUtilizationSummary(selectedtext);
		});
	}

	handleChangeEnd(holdingDate) {
		if(this.state.endDateOnTime === holdingDate){
			return;
		}
		this.setState({
			endDateOnTime: holdingDate,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			this.handleUtilizationSummary(selectedtext);
		});
	}

	onDropDownHandler(e) {
		const { nodeText:text } = e;
		this.setState({ type: text, datapickflag: text === "Date Range", startDateOnTime:'', endDateOnTime:''})
		this.handleUtilizationSummary(text);
	}

	handleUtilizationSummary(text) {
		currentUnit = this.props.currentUnit;
		const { startDateOnTime, endDateOnTime } = this.state;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
		if (text === "Date Range") {
			todaysDateFormatted = format(endDateOnTime, 'YYYY-MM-DD');
			weekDateFormatted = format(startDateOnTime, 'YYYY-MM-DD');
		}
		else {
			todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
			weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
		}
		this.setState({ type: text });
		if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") &&
			(weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
			this.props.getUtilizationTableData(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
	}

	handleRangeChange(which, payload) {
		console.log(which, payload);
		this.setState({
			[which]: {
				...this.state[which],
				...payload,
			},
		});
	}

	render() {
		const { utilizationTableInfo, holdtype, holdtypeStringID } = this.props;
		const utilizationTableData = utilizationTableInfo.utilizationTableResponse ? utilizationTableInfo.utilizationTableResponse : {};
		let utilizationTableContent = utilizationTableData.content;
		const displayContents = utilizationTableContent && utilizationTableContent.length > 0 ? utilizationTableContent : [];
		let displayUtilizationDatas;
		if (Array.isArray(displayContents) && displayContents.length) {
			displayUtilizationDatas = displayContents.map((item, i) =>
				<React.Fragment key={i}>
					<div className="utilizationTable">
						<ul className="tBody">
							<li>{item.panid}</li>
							<li>{item.holdingtime}</li>
							<li>{item.idletime}</li>
							<li>{item.utilization}</li>
							<li>{item.exptimer}</li>
							<li><a role="button" data-toggle="collapse" data-parent="#utilizationAccordion" data-target={`#utilizationAcc_` + i} aria-expanded="false" aria-controls={`#utilizationAcc_` + i} className="btn collapseExpand collapsed">&nbsp;</a></li>
						</ul>
					</div>
					{/* <!-- Header 01 Content --> */}
					<div id={`utilizationAcc_` + i} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`utilizationAcc_` + i}>
						<div className="panel-body utilizationExpTable">
							{displayContents[i] && displayContents[i].details && displayContents[i].details.map((items, j) =>
								<React.Fragment key={j}>
									<ul className="tBody">
										<li>{items.productName}</li>
										<li>{items.holdingtime}</li>
										<li>{items.idletime}</li>
										<li>{items.utilization}</li>
										<li>{items.exptimer}</li>
										<li>&nbsp;</li>
									</ul>
								</React.Fragment>
							)}
						</div>
					</div>
				</React.Fragment>
			)
		}
		else if (utilizationTableInfo.errormsgUtilizationTable === "No Results" && displayContents.length) {
			displayUtilizationDatas = (<div className="noResultMsg">{utilizationTableInfo.errormsgUtilizationTable ? <FormattedMessage id="KC0264"/> : <FormattedMessage id="KC0264"/>}</div>)
		}
		else {
			displayUtilizationDatas = (<div className="noResultMsg">{utilizationTableInfo.errormsgUtilizationTable ? <FormattedMessage id="KC0264"/> : <FormattedMessage id="KC0264"/>}</div>)
		}
		return (
			<div className="gridViewTable">
				<div className="gridView">
					<div className="colm12D">
						<div className="colmDi">
							<h4><FormattedMessage id="KC0270"/>
						<span className="cornerIcon">
								</span>
								<CustomDropDownList translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDateOnTime}
									minDateDisable={this.state.sdmindate}
									maxDateDisable={this.state.sdmaxdate}
									endDatepara={this.state.endDateOnTime}
									endminDateDisable={this.state.edmindate}
									endmaxDateDisable={this.state.edmaxdate}
									handleChangeStartProps={(date) => this.handleChangeStart(date)}
									handleChangeEndProps={(date) => this.handleChangeEnd(date)}
									OnChange={(e) => this.onDropDownHandler(e, "UtilizationTable")}
									filterType={this.state.type}
								/>
							</h4>
						</div>
						{/* <!-- Utilization Table -->   */}
						<div className="utilizationTableDiv">
							<div className="utilizationTableOuter">
								<div className="utilizationTable">
									<ul className="tHead">
										<li>{holdtypeStringID ? <FormattedMessage id={holdtypeStringID} /> : holdtype}</li>
										<li><FormattedMessage id="KC1809"/> <span> <FormattedMessage id="KC1811"/> </span></li>
										<li><FormattedMessage id="KC1488"/> <span> <FormattedMessage id="KC1811"/> </span></li> 
										<li><FormattedMessage id="KC0270"/></li>
										<li><FormattedMessage id="KC0378"/></li>
										<li><FormattedMessage id="KC0325"/></li>
									</ul>
								</div>
								<div className="panel panel-default" id="utilizationAccordion" role="tablist" aria-multiselectable="true">
									{/* <!-- Header 01 --> */}
									{displayUtilizationDatas}
									{/* <!-- Header 01 Content End --> */}
								</div>
							</div>
						</div>
						{/* //Utilization Table   */}
					</div>
				</div>
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		utilizationTableInfo: state.utilizationTableResponse,
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getUtilizationTableData,
		getCurrentLocation,
		getCurrentUnit
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UtilizationSummaryTable);
