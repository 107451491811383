import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import ReportInfoModal from '../../modal/report-info-model';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { format } from 'date-fns';
import { datePickerLocale } from '../../../locale/constant';

let todaysDate = new Date();
let Yesterdaydate = new Date(todaysDate.setDate(todaysDate.getDate() - 1));
let sixMonthsFromNow = new Date(todaysDate.setMonth(todaysDate.getMonth() - 6));
let savestartDate = sixMonthsFromNow;
let saveendDate = Yesterdaydate;
$(function () {
	$('#startdate').on('focus', function (e) {
		e.preventDefault();
		$(this).blur();
	});
	$('#enddate').on('focus', function (e) {
		e.preventDefault();
		$(this).blur();
	});
});

$(window).on('beforeunload', function () {
	if ($(".fleet-export-error-report").length > 0) {
		$("#fleetExportReport").removeClass("fleet-export-error-report");
		$(".loadingProgress").css("display", "none");
	}
});

const reportOptions = [{
    fieldName: "fryer_info",
	displayName: "Fryer Asset Information",
	isChecked: true,
	disabled: true
},
{
    fieldName: "error_statistics",
    displayName: "Error Statistics",
	isChecked: true,
	disabled: false
},
{
    fieldName: "dispose_data",
    displayName: "Dispose Data",
	isChecked: true,
	disabled: false
},
{
    fieldName: "cooking_info",
    displayName: "Cooking Information",
	isChecked: true,
	disabled: false
},
{
    fieldName: "filter_info",
    displayName: "Filter Information",
	isChecked: true,
	disabled: false
},
{
    fieldName: "utilization",
    displayName: "Utilization",
	isChecked: true,
	disabled: false
}];

class FryerReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			ErrorReport: false,
			FleetReport: false,
			startDate: sixMonthsFromNow,
			endDate: Yesterdaydate,
			maxdate: Yesterdaydate,
			minDate: sixMonthsFromNow,
			fleetResponse: "",
			brandIdData: "",
			FleetData: false,
			ConsumptionTurnover: false,
			PeakHour: false,
			Production: false,
			ActiveErrors: false,
			HistoricalErrors: false,
			reportName: "",
			selChkInArr: "",
			unitChecked: true,
			storeChecked: false,
			pdfChecked: false,
			excelChecked: true,
			reportOptions: [...reportOptions],
			reportInfoModal: {
				open: false,
				title: "",
				message: ""
			}

		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.ResetDatepicker = this.ResetDatepicker.bind(this);
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		let second_date = new Date(todaysDate);
		second_date.setMonth(second_date.getMonth() + 6)
		this.setState({
			maxdate: second_date
		});
	}

	componentWillMount() {
		if (this.props.location && this.props.location.state) {
			let values = this.props.location.state
			this.setState({ ...values });
		}
	}
	
	handleChangestart = (date) => {
		savestartDate = date;
		this.setState({
			startDate: date
		});
		if (savestartDate > saveendDate) {
			this.setState({
				error_modal: {
					open: true,
					message: "Start date should be lesser than End date."
				}
			});
		}
	}

	handleChangeend = (date) => {
		saveendDate = date;
		this.setState({
			endDate: date
		});
		if (savestartDate > saveendDate) {
			this.setState({
				error_modal: {
					open: true,
					message: "End date should be greater than Start date."
				}
			});
		}
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
	}
	onReportCloseModal = () => {
		this.setState ({ reportInfoModal: { open: false}});
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	
	onSelectCheckbox = (e) => {
		const { reportOptions } = this.state;
		const reportIndex = reportOptions.findIndex(el => el.fieldName == e.target.name);
		const copyReportOption = [...reportOptions];
		copyReportOption[reportIndex] = { ...reportOptions[reportIndex] };
		copyReportOption[reportIndex].isChecked = e.target.checked;
		this.setState({ reportOptions: copyReportOption });
	}

	onSelectRadioCheckbox = (e) => {
		const obj = {unitChecked:false, storeChecked:false};
		if(e.target.value === "unit"){
			obj.unitChecked = true;
		}
		if(e.target.value === "store"){
			obj.storeChecked = true;
		}
		this.setState({ ...obj });
	}

	onReportFormatHandler = (e) => {
		const obj = {pdfChecked:false, excelChecked:false};

		if(e.target.value === "PDF"){
			obj.pdfChecked = true;
		}
		if(e.target.value === "XLSX"){
			obj.excelChecked = true;
		}
		
		this.setState({ ...obj });
	}

	onReportNameChange = (e, item) => {
		let rName = e.target.value;
		this.setState({
			reportName: rName,
		})
	}

	onOpenModal = (item) => {       
        this.setState({
            reportInfoModal: {
                open: true,
				title: item,
				message: item
            }
		});
    }


	render() {
		let { success_modal, error_modal, loaderIconVisible, reportName, startDate, endDate, unitChecked, storeChecked, pdfChecked, excelChecked, reportOptions, reportInfoModal } = this.state;
		let isNextEnabled = false
		if (reportName && startDate && endDate) {
			if ((unitChecked == true || storeChecked == true) && (pdfChecked == true || excelChecked == true)) {
				isNextEnabled = true
			}
		}
			const displayReportOptions = reportOptions && reportOptions.map(item => ( <ul>
			<li>
				<div className={item.disabled?"customCheckbox customCheckboxWOlabel disabled":"customCheckbox customCheckboxWOlabel"}>
					<input onChange={(e) => this.onSelectCheckbox(e)} checked={item.isChecked} id={item.fieldName} name={item.fieldName} type="checkbox" value={item.isChecked}/>
					<label for={item.fieldName}>{item.displayName}</label>
				</div>
			</li>
		</ul>));

		let showOptions;
		let showLeftSideDetails;
		showOptions = (
			<div className="reportTypeListCBox">
				<ul>
					<li>Report Options</li>
				</ul>
				{displayReportOptions}
			</div>
		);

		showLeftSideDetails = (
			<div className="newReportWrapperL">
				<div className="reportTypeList">
					<h6>Report Name *</h6>
					<ul>
						<li>
							<input type="text" value={reportName} onChange={(e) => this.onReportNameChange(e)} className="form-control" placeholder="Enter Name" />
						</li>
					</ul>
				</div>
			</div>
		);

		/** Frymaster report generation by serial number or store */
		let showReportType;
		let showReportFileFormat;
		showReportType = (
			<div className="newReportForm">
					<h5>Report Type</h5>
					<ul>
						<li>
							<div className="customRadioBtn">
								{/* <input id="byUnit" type="radio" name="ReportType" value={unitChecked} onChange = {(e) => this.onSelectRadioCheckbox(e)}/> */}
								<input id="unitChecked" type="radio" name="ReportType" checked={unitChecked} value="unit" onChange = {(e) => this.onSelectRadioCheckbox(e)}/>
								<label for="unitChecked">By Unit</label>
							</div>
						</li>
						<li>
							<div className="customRadioBtn">
								<input id="storeChecked" type="radio" name="ReportType" checked={storeChecked} value="store" onChange = {(e) => this.onSelectRadioCheckbox(e)}/>
								<label for="storeChecked">By Store</label>
							</div>
						</li> 
					</ul>
				</div>
		);

		showReportFileFormat = (
			<div className="newReportForm">
					<h5>File Format</h5>
					<ul>
						<li>
							<div className="customRadioBtn">
								<input id="XLSX" type="radio" name="FileFormat" checked={excelChecked} value="XLSX" onChange = {(e) => this.onReportFormatHandler(e)}/>
								<label for="XLSX">Excel</label>
							</div>
						</li> 
						<li>
							<div className="customRadioBtn">
								<input id="PDF" type="radio" name="FileFormat" checked={pdfChecked} value="PDF" onChange = {(e) => this.onReportFormatHandler(e)}/>
								<label for="PDF">PDF</label>
							</div>
						</li>
					</ul>
				</div>
		);
			
		return (

			<div id="adminWrapper">
				<div id="fleetExportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div className="reportWrapper">
						<div className="adminBgHeader">
							<ul>
								<li>
									<Link to="/reportList" title="Back" className="backIcon">&nbsp;</Link>
								</li>
								<li className="txtboldmsg">
									New Report
				             	</li>
								<li>
									<Link className={isNextEnabled ? "activeState" : "disabled"} id="AssignUpdate" to={{
										pathname: unitChecked === true ? "/reportAssignUpdateList" :"/reportAssignStoreList",
										state: {
											reportName,
											startDate: format(startDate, "YYYY-MM-DD"),
											endDate: format(endDate, "YYYY-MM-DD"),
											reportByUnit: unitChecked,
											reportByStore: storeChecked,
											reportFormat: pdfChecked?"PDF":"XLSX",
											reportOptions
										}
									}} title="Next">Next</Link>
								</li>
							</ul>
						</div>
						<div className="newReportWrapper">
							{showLeftSideDetails}
							<div className="newReportWrapperR">
								<div className="newReportForm">
									<h6>Select Duration</h6>
									<ul>
										<li>
											<label>Start Date *</label>
											<div className="input-group">
												<DatePicker locale={datePickerLocale} autoComplete="off" id="startdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.startDate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} />
											</div>
										</li>
										<li>
											<label>End Date</label>
											<div className="input-group">
												<DatePicker locale={datePickerLocale} autoComplete="off" id="enddate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.endDate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate} />
											</div>
										</li>
									</ul>
								</div>
								<div className="maxDuration">* Maximum duration: 6 months prior.</div>
								{showReportType}
								{showReportFileFormat}
								{showOptions}
							</div>
						</div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<ReportInfoModal open={reportInfoModal.open} title={reportInfoModal.title} message={reportInfoModal.message} onCloseModal={this.onReportCloseModal}/>
				<UserManagementHeader headerName="Reports" activeClass="reportNav" />
			</div>
		)
	}
};

export default FryerReport;

