import axios from 'axios';
import api from '../../../../service/api';
import http from '../../../../service/httpService';
import {BaseService, UnitInfo} from '../../../../service/api';
import { CUSTOMER_ID, BRAND_ID,BASECOUNTRY_ID } from '../../../../utils/appConstants';
export const UNITS_GRID_REQUEST = 'UNITS_GRID_REQUEST';
export const UNITS_GRID_SUCCESS = 'UNITS_GRID_SUCCESS';
export const UNITS_GRID_FAIL = 'UNITS_GRID_FAIL';

export function getDashboardUnitsGrid(organizationHierarchy = '', cgid = '', cid = '', brandid ='', model = '', machineType = '') {
    return dispatch => {
        dispatch({
            type: UNITS_GRID_REQUEST
        })
        
        let cgids = cgid;

        if (organizationHierarchy != "")
        {
            cgids = organizationHierarchy + ',' + cgid;
        }

        http.get(`${UnitInfo.list}`,
                {
                    headers: {
                        'content-type':'application/json',
                        'cgid': cgids,  
                        'cid': CUSTOMER_ID === "-1" ? cid : CUSTOMER_ID,  
                        'brandid': brandid ? brandid:BRAND_ID,  
                        'model': model,
                        'machinetype': machineType                      
                    },
                    data:{}
                }
            )
            .then(response => {
                dispatch({
                    type: UNITS_GRID_SUCCESS,
                    dashboardUnitsValue: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: UNITS_GRID_FAIL,
                    error
                })
            })
    }
}

