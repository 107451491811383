import { MediaManagementAPI } from '../../../service/api';
import uploadMediaToCloud from '../../../components/navigation/menus/mediaUpload';
import { SET_CREATE_PACKAGE_LIST, SET_CREATE_PACKAGE_LIST_LOADING} from '../../stateActionsData/type';
import { resetSelectedItems} from './mediaDragAction';
import KCStore from '../../../utils/kcStorage';

export const setCreatePackageAction = ( data ) => ({ type: SET_CREATE_PACKAGE_LIST, payload: data});
const setLoader = ( bol ) => ({ type: SET_CREATE_PACKAGE_LIST_LOADING, payload: bol});

const converFileName  = (  ) => {
    //code for add the extension with file
    let filename = KCStore.getItem('zipFileName');
    if( filename ) {
    var fileExtension;
        fileExtension = filename.split('.').pop();
        if( fileExtension === "zip" ) {
            return filename;
        }
        else {
            return (filename+'.zip');
        }
    }
}
const getSession = (cgids) => { 
    const  _fileName = converFileName();
    return ({
        customer : KCStore.getItem('customerId'),
        user : KCStore.getItem('user'),
        cgids : cgids && cgids.length ? cgids : [KCStore.getItem('custGroupID')],
        fileName : _fileName,
        packageName: _fileName
    });
};

export const  createPackageZip =  async (reqData)  => dispatch => {
    dispatch(setLoader(true));
    let { files } = reqData;
    let mediaId = [];
    let fileSize = 0;

    files.forEach((item)=> {
        let size = +(item.FILE_SIZE);   
        fileSize =  fileSize + size;
        mediaId.push(item.MEDIA_ID);
    });

    let selFiles =[];
    selFiles.push(reqData.files[0])
    
    if( fileSize) {
        fileSize = fileSize.toFixed(3);
    }
            let req = { 
                ...getSession(reqData.cgid), 
                brand: "CREM", //reqData.selectedBrand.BRAND_NAME,
                brand_id: 4, //reqData.selectedBrand.BRAND_ID,
                fileSize,
                fileFormat: ".zip", 
                type: "package",
                resolution :"0",
                category: "zip",
                packageDetails:mediaId && mediaId.length>0 ?mediaId.join(",") : "",                
            };

            let presignedUrl = `${MediaManagementAPI.getPresignedUrl}?customer=${+req.customer}&thumbnail=true`;

            uploadMediaToCloud(presignedUrl, req, null, req.user, mediaId).then(response => {                
                KCStore.removeItem('zipFileName');
                KCStore.removeItem('selectedItemsInfo');
                dispatch(setCreatePackageAction(response));
                dispatch(resetSelectedItems());
                dispatch(setLoader(false));

            }).catch(e => {
                dispatch(setLoader(false));
            });

}

