import {BRAND_REQUEST,BRAND_SUCCESS,BRAND_FAIL} from '../../../../actions/listView/locations/brandAction';

const initialState = {
    loaded: false,
    loading: false,
    brandInformation: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case BRAND_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                isServerActionComplete : action.isCascadeActionRequest
               
            }
        case BRAND_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                brandInformation: action.brandInformation,
                isServerActionComplete : true
            }
        case BRAND_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
                isServerActionComplete : true

            }
        default:
            return state
    }
}