import React, { useEffect, useState} from 'react';
import { FormattedMessage } from "react-intl";
import $ from 'jquery';
import { smartTagMangement } from '../../../service/api';
import http from '../../../service/httpService';

const OrgTagsDetail = (props) => {
    const [loaderIconVisible, setLoaderIconVisible] = useState(false);
    const [nodeDetails, setNodeDetails] = useState([]);

    const updateDimensions = () => {
        const windowHeight = $(window).height();
        var reportDetailsDHeight = ((windowHeight) - 120);
	    $(".reportDetailsD").css("max-height", reportDetailsDHeight);
    }

    useEffect(() => {
        getTagDetails();
    }, [props.nodeDet,props.unitDet]);

    useEffect(()=>{
        updateDimensions();
    })

    const getTagDetails = async () => {
        setLoaderIconVisible(true);
        let url = smartTagMangement.nodeTagDetails;
        if (props.isUnit) {
            url = smartTagMangement.unitTagDetails + props.unitDet.id + "/smart-tag"
        } else {
            url = url + props.nodeDet.cust_gp_id
        }

        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }, data: {}
        }).then(async response => {
            if (response && response.data.data) {
                setNodeDetails(response.data.data)
                setLoaderIconVisible(false);
            }
        }).catch(err => {
            setLoaderIconVisible(false);
        });
    }

    const onOkClick = () => {
        setNodeDetails([]);
        props.closeSTModel();
        $('#unitSmartTagsDetails').modal('hide');
    }

    return (
        <>
            <div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="unitSmartTagsDetails" tabindex="-1" role="dialog" aria-labelledby="unitSmartTagsDetailsLabel">
                <div className="modal-dialog modal-md">
                    {loaderIconVisible ? <div className="modal-content text-center"> <br /><br /><FormattedMessage id='KC1151' /><br /><br /></div> :
                        <div className="modal-content">
                            <div className="modal-header modalHeader">
                                {props.isUnit ?
                                        <FormattedMessage id='KC2374' />
                                    :
                                    <>
                                        <span className="largeText">{props && props.nodeDet && props.nodeDet.group_name ?props.nodeDet.group_name : ""} Details</span>
                                        <span className="smallText">{props && props.formBreadCrum && props.nodeDet && props.nodeDet.dynasty ? props.formBreadCrum(props.nodeDet.dynasty) : ''}</span>
                                    </>
                                }
                            </div>
                            <div className="modal-body">
                                <div className="reportDetailsD">
                                    <div className="STmodalDetail">
                                        {props.isUnit && nodeDetails ?
                                            <>
                                                <ul>
                                                    <li><FormattedMessage id='KC0105' />:</li>
                                                    <li>{nodeDetails.assetNumber}</li>
                                                </ul>
                                                <ul>
                                                    <li><FormattedMessage id='KC0104' />:</li>
                                                    <li>{nodeDetails.unitId}</li>
                                                </ul>
                                                <ul>
                                                    <li><FormattedMessage id='KC0103' />:</li>
                                                    <li>{nodeDetails.modelName}</li>
                                                </ul>
                                                <ul>
                                                    <li><FormattedMessage id='KC2375' />:</li>
                                                    <li>
                                                        <div className="tagsList">
                                                            {nodeDetails.UnitTags && nodeDetails.UnitTags.map((result, index) =>
                                                                <div key={index} className="tagTypeUnit"><span>{result}</span></div>
                                                            )}
                                                            <div class="clear"></div>
                                                            <><hr/></>
                                                            {nodeDetails.OrgTags && nodeDetails.OrgTags.map((result, index) =>
                                                                <div key={index} className="tagTypeLocation"><span>{result}</span></div>
                                                            )}
                                                            
                                                        </div>
                                                    </li>
                                                </ul>
                                            </>                                            
                                            : null
                                        }

                                        {!props.isUnit && nodeDetails && nodeDetails.length ?
                                            nodeDetails.map((item, index) =>
                                                <ul key={index}>
                                                    <li>{item.group_name}:</li>
                                                    <li>
                                                        {item.tagNames.map((result, index) =>
                                                            <div className="tagTypeLocation"><span>{result}</span></div>
                                                        )}
                                                    </li>
                                                </ul>
                                            )
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary okBtn" onClick={onOkClick} type="button"><FormattedMessage id="KC0777" /></button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default OrgTagsDetail;