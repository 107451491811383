var cookbookJSON = {
    "id": "",
    "edit_date": "",
    "author": "",
    "email": "",
    "recipes": [
    ],
    "menus": [
        {
            "id": "",
            "edit_date": "",
            "author": "",
            "email": "",
            "name": {
                "en_US": ""
            },
            "description": {
                "en_US": ""
            },
            "image": "",
            "menu_level": "",
            "recipe_items": [
            ]
        }
    ],
    "PressAndGoItems" : []
}

export default cookbookJSON 
