import {STORE_INSTALLED_ITEM_REQUEST,STORE_INSTALLED_ITEM_SUCCESS,STORE_INSTALLED_ITEM_FAIL} from '../../../../actions/navigation/softwareManagement/storeInstalledItemAction';
const initialState = {
    loaded: false,
    loading: false,
    storeInstalledItemInformation: {'Software_Distribute_List':[]},
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case STORE_INSTALLED_ITEM_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                
            }
        case STORE_INSTALLED_ITEM_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                storeInstalledItemInformation: action.storeInstalledItemInformation,
               
            }
        case STORE_INSTALLED_ITEM_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: '',

            }
        default:
            return state
    }
}
