const countryCodeArray = [
    {
        "code": "+1"
    },
    {
        "code": "+1242"
    },
    {
        "code": "+1246"
    },
    {
        "code": "+1264"
    },
    {
        "code": "+1268"
    },
    {
        "code": "+1284"
    },
    {
        "code": "+1340"
    },
    {
        "code": "+1345"
    },
    {
        "code": "+1441"
    },
    {
        "code": "+1473"
    },
    {
        "code": "+1649"
    },
    {
        "code": "+1664"
    },
    {
        "code": "+1671"
    },
    {
        "code": "+1758"
    },
    {
        "code": "+1767"
    },
    {
        "code": "+1784"
    },
    {
        "code": "+1787"
    },
    {
        "code": "+1809 "
    },
    {
        "code": "+1829"
    },
    {
        "code": "+1868"
    },
    {
        "code": "+1869"
    },
    {
        "code": "+1876"
    },
    {
        "code": "+1939"
    },
    {
        "code": "+20"
    },
    {
        "code": "+211"
    },
    {
        "code": "+212"
    },
    {
        "code": "+213"
    },
    {
        "code": "+216"
    },
    {
        "code": "+218"
    },
    {
        "code": "+220"
    },
    {
        "code": "+221"
    },
    {
        "code": "+222"
    },
    {
        "code": "+223"
    },
    {
        "code": "+224"
    },
    {
        "code": "+225"
    },
    {
        "code": "+226"
    },
    {
        "code": "+227"
    },
    {
        "code": "+228"
    },
    {
        "code": "+229"
    },
    {
        "code": "+230"
    },
    {
        "code": "+231"
    },
    {
        "code": "+232"
    },
    {
        "code": "+233"
    },
    {
        "code": "+234"
    },
    {
        "code": "+235"
    },
    {
        "code": "+236"
    },
    {
        "code": "+237"
    },
    {
        "code": "+238"
    },
    {
        "code": "+239"
    },
    {
        "code": "+240"
    },
    {
        "code": "+241"
    },
    {
        "code": "+242"
    },
    {
        "code": "+243"
    },
    {
        "code": "+244"
    },
    {
        "code": "+245"
    },
    {
        "code": "+248"
    },
    {
        "code": "+250"
    },
    {
        "code": "+251"
    },
    {
        "code": "+252"
    },
    {
        "code": "+253"
    },
    {
        "code": "+254"
    },
    {
        "code": "+255"
    },
    {
        "code": "+256"
    },
    {
        "code": "+257"
    },
    {
        "code": "+258"
    },
    {
        "code": "+260"
    },
    {
        "code": "+261"
    },
    {
        "code": "+262"
    },
    {
        "code": "+263"
    },
    {
        "code": "+264"
    },
    {
        "code": "+265"
    },
    {
        "code": "+266"
    },
    {
        "code": "+267"
    },
    {
        "code": "+268"
    },
    {
        "code": "+269"
    },
    {
        "code": "+27"
    },
    {
        "code": "+297"
    },
    {
        "code": "+298"
    },
    {
        "code": "+299"
    },
    {
        "code": "+30"
    },
    {
        "code": "+31"
    },
    {
        "code": "+32"
    },
    {
        "code": "+33"
    },
    {
        "code": "+34"
    },
    {
        "code": "+350"
    },
    {
        "code": "+351"
    },
    {
        "code": "+352"
    },
    {
        "code": "+353"
    },
    {
        "code": "+354"
    },
    {
        "code": "+355"
    },
    {
        "code": "+356"
    },
    {
        "code": "+357"
    },
    {
        "code": "+358"
    },
    {
        "code": "+359"
    },
    {
        "code": "+36"
    },
    {
        "code": "+370"
    },
    {
        "code": "+371"
    },
    {
        "code": "+372"
    },
    {
        "code": "+373"
    },
    {
        "code": "+374"
    },
    {
        "code": "+375"
    },
    {
        "code": "+376"
    },
    {
        "code": "+377"
    },
    {
        "code": "+380"
    },
    {
        "code": "+381"
    },
    {
        "code": "+382"
    },
    {
        "code": "+385"
    },
    {
        "code": "+386"
    },
    {
        "code": "+387"
    },
    {
        "code": "+389"
    },
    {
        "code": "+39"
    },
    {
        "code": "+40"
    },
    {
        "code": "+41"
    },
    {
        "code": "+420"
    },
    {
        "code": "+421"
    },
    {
        "code": "+423"
    },
    {
        "code": "+43"
    },
    {
        "code": "+44"
    },
    {
        "code": "+45"
    },
    {
        "code": "+46"
    },
    {
        "code": "+47"
    },
    {
        "code": "+48"
    },
    {
        "code": "+49"
    },
    {
        "code": "+501"
    },
    {
        "code": "+502"
    },
    {
        "code": "+503"
    },
    {
        "code": "+504"
    },
    {
        "code": "+505"
    },
    {
        "code": "+506"
    },
    {
        "code": "+507"
    },
    {
        "code": "+509"
    },
    {
        "code": "+51"
    },
    {
        "code": "+52"
    },
    {
        "code": "+53"
    },
    {
        "code": "+54"
    },
    {
        "code": "+55"
    },
    {
        "code": "+56"
    },
    {
        "code": "+57"
    },
    {
        "code": "+58"
    },
    {
        "code": "+590"
    },
    {
        "code": "+591"
    },
    {
        "code": "+592"
    },
    {
        "code": "+593"
    },
    {
        "code": "+594"
    },
    {
        "code": "+595"
    },
    {
        "code": "+596"
    },
    {
        "code": "+597"
    },
    {
        "code": "+598"
    },
    {
        "code": "+60"
    },
    {
        "code": "+61"
    },
    {
        "code": "+62"
    },
    {
        "code": "+63"
    },
    {
        "code": "+64"
    },
    {
        "code": "+65"
    },
    {
        "code": "+66"
    },
    {
        "code": "+670"
    },
    {
        "code": "+673"
    },
    {
        "code": "+675"
    },
    {
        "code": "+676"
    },
    {
        "code": "+677"
    },
    {
        "code": "+678"
    },
    {
        "code": "+679"
    },
    {
        "code": "+680"
    },
    {
        "code": "+682"
    },
    {
        "code": "+685"
    },
    {
        "code": "+686"
    },
    {
        "code": "+687"
    },
    {
        "code": "+7"
    },
    {
        "code": "+81"
    },
    {
        "code": "+82"
    },
    {
        "code": "+84"
    },
    {
        "code": "+852"
    },
    {
        "code": "+853"
    },
    {
        "code": "+855"
    },
    {
        "code": "+856"
    },
    {
        "code": "+86"
    },
    {
        "code": "+880"
    },
    {
        "code": "+886"
    },
    {
        "code": "+90"
    },
    {
        "code": "+91"
    },
    {
        "code": "+92"
    },
    {
        "code": "+93"
    },
    {
        "code": "+94"
    },
    {
        "code": "+95"
    },
    {
        "code": "+960"
    },
    {
        "code": "+961"
    },
    {
        "code": "+962"
    },
    {
        "code": "+964"
    },
    {
        "code": "+965"
    },
    {
        "code": "+966"
    },
    {
        "code": "+967"
    },
    {
        "code": "+968"
    },
    {
        "code": "+970"
    },
    {
        "code": "+971"
    },
    {
        "code": "+972"
    },
    {
        "code": "+973"
    },
    {
        "code": "+974"
    },
    {
        "code": "+975"
    },
    {
        "code": "+976"
    },
    {
        "code": "+977"
    },
    {
        "code": "+992"
    },
    {
        "code": "+993"
    },
    {
        "code": "+994"
    },
    {
        "code": "+995"
    },
    {
        "code": "+996"
    },
    {
        "code": "+998"
    }
];


export default countryCodeArray;