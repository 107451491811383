import api from '../../../service/api';
import http from '../../../service/httpService';
import moment from '../../../utils/momentHelper.js';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import { MediaManagementAPI } from '../../../service/api';
import KCStore from '../../../utils/kcStorage';

export const MEDIA_IMAGELIBRARY_LIST_REQUEST = 'MEDIA_IMAGELIBRARY_LIST_REQUEST';
export const MEDIA_IMAGELIBRARY_LIST_SUCCESS = 'MEDIA_IMAGELIBRARY_LIST_SUCCESS';
export const MEDIA_IMAGELIBRARY_LIST_FAIL = 'MEDIA_IMAGELIBRARY_LIST_FAIL';
export const MEDIA_SELECTED_LIST_SUCCESS = 'MEDIA_SELECTED_LIST_SUCCESS';

export function getMediaImageLibraryList(req,searchParam) {
    let custGroupID = KCStore.getItem("custGroupID");
		     let cgids = "";
			if (custGroupID != "" && custGroupID != undefined) {
				cgids = custGroupID;
            }
            
            if (req === "Reset") {
                return dispatch => {
                    dispatch({
                        type: MEDIA_IMAGELIBRARY_LIST_SUCCESS,
                        mediaImageLibraryListInformation: [],
                    })
                }
            }
            else {
                return dispatch => {
                    dispatch({
                        type: MEDIA_IMAGELIBRARY_LIST_SUCCESS,
                        mediaImageLibraryListInformation: [],
                    })
                    dispatch({
                        type: MEDIA_IMAGELIBRARY_LIST_REQUEST
                    })
                    return new Promise((resolve, reject) => {

                        let headers = {
                            'Content-Type': 'application/json',
                            'customer': KCStore.getItem("customerId"),
                            'brand': KCStore.getItem("brandName"),
                            'type': 'imageLibrary',
                            'cgids': cgids,
                            'basecountry': BASECOUNTRY_ID,
                            'defaultlibrary' : KCStore.getItem("isConnexUser") || false
                        };
                        if (searchParam) {
                            headers = Object.assign(headers, searchParam);
                        }
                        http.get(`${MediaManagementAPI.getMediaBrandCustomer}`,
                            {
                                headers,
                                data: {}
                            })
                            .then(response => {
                                const { mediaList } = response.data;
                                if (mediaList) {
                                    mediaList.forEach(item => {
                                        item.CREATEDAT = moment.ConvertLocalTimeFromUTCTime(item.CREATEDAT, "MMMM DD, YYYY hh:mm A");
                                    });
                                }
                                dispatch({
                                    type: MEDIA_IMAGELIBRARY_LIST_SUCCESS,
                                    mediaImageLibraryListInformation: response.data,
                                })
                                resolve(response.data)
                            })
                            .catch(error => {
                                dispatch({
                                    type: MEDIA_IMAGELIBRARY_LIST_FAIL,
                                    mediaImageLibraryListInformation: [],
                                    error
                                })
                                resolve(error)
                            })
                    })
                }
            }
}

export function mediaSelectedItems(selectedItems) {
    return dispatch => {
        dispatch({
            type: MEDIA_SELECTED_LIST_SUCCESS,
            mediaSelectedItems:selectedItems,
         })
    }
}








