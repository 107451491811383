import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SwaggerUi from 'swagger-ui';
// import SwaggerUi, {presets} from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

const files = {
    'deviceManagement' : "device-management-dev-swagger.yaml",
    'organizationManagement' : "organization-management-dev-swagger.yaml",
    'productionManagement' : "production-management-dev-swagger.yaml",
    'filesManagement' : "files-management-dev-swagger.yaml",
    'pushDataManagement' : "push-data-configuration-management-dev-swagger.yaml",
    'webhookApiManagement':'webhook-configuration-management-swagger.yaml',
    'huePhilipsApi' : "lights-api-swagger.yaml",

}

class SwaggerUI extends Component {

 basePath = 'https://dev-swagger-bucket.s3.eu-west-2.amazonaws.com/';

    componentDidMount() {
        SwaggerUi({
            dom_id: '#swaggerContainer',
            url: this.basePath + `${files[this.props.fileName]}`,
            spec: this.props.spec,
            // presets: [presets.apis]
        });
    }

    render() {
        return (
            <div id="swaggerContainer" />
        );
    }
}

SwaggerUI.propTypes = {
    url: PropTypes.string,
    spec: PropTypes.object
};


export default SwaggerUI;