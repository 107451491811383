export let Option = [];

export const setOptions = (url) => {

	const urlMap = {
		//For Event Library
		'/eventLibrary': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'brandName', name: 'Brand Name', nameStringID: 'KC0873', placeholder: 'KC0874', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'modelName', name: 'Model Family', nameStringID: 'KC0649', placeholder: 'KC0875', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'eventCode', name: 'Event Code', nameStringID: 'KC0858', placeholder: 'KC0876', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'equipmentType', name: 'Equipment Type', nameStringID: 'KC0857', placeholder: 'KC0877', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'eventType', name: 'Event Type', nameStringID: 'KC0859', placeholder: 'KC0878', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'eventName', name: 'Event Name', nameStringID: 'KC0860', placeholder: 'KC0879', selectedKey: '', disabled: false, childValues: [] }
		],
		//For userManagementList
		'/user/list': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchname', name: 'User Name', nameStringID: 'KC0998', placeholder: 'KC1761', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchrolename', name: 'User Role', nameStringID:'KC0615', placeholder: 'KC1676', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchemail', name: 'User email', nameStringID: 'KC1760', placeholder: 'KC1762', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchstatus', name: 'Status', nameStringID:'KC0523',placeholder: 'KC1763', selectedKey: '', disabled: false, childValues: [] }
		],

		//For userManagementRoles
		'/role/list': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchrolename', name: 'User Role', nameStringID: 'KC0615', placeholder: 'KC1676', selectedKey: '', disabled: false, childValues: [] },
		],

		//For Error Page
		'/serviceManagement': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'errorCode', name: 'Error Code',nameStringID: 'KC0194', placeholder: 'KC0850', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'errorDescription', name: 'Error Description',nameStringID: 'KC0849', placeholder: 'KC0851', selectedKey: '', disabled: false, childValues: [] },
		],

		
		//For software Updates Status
		'/softwareUpdatesStatus': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchbrand', name: 'Brand',nameStringID: 'KC0062', placeholder: 'KC2336', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchlocation', name: 'Location',nameStringID: 'KC0050', placeholder: 'KC2342', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchmodelname', name: 'Model Name',nameStringID: 'KC0064', placeholder: 'KC2341', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchunit', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC2339', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchname', name: 'Software Name',nameStringID: 'KC2337', placeholder: 'KC2338', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchfilename', name: 'File Name',nameStringID: 'KC0677', placeholder: 'KC2355', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchstatus', name: 'Status',nameStringID: 'KC0523', placeholder: 'KC2344', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchwithin', name: 'Within Location',nameStringID: 'KC0061', placeholder: 'KC2343', selectedKey: '', disabled: false, childValues: [] },
			// { value: 'searchmodelfamilyname', name: 'Model Family',nameStringID: 'KC0649', placeholder: 'KC2340', selectedKey: '', disabled: false, childValues: [] },	
		],

		//For Menu Updates Status
		'/menuUpdateStatus': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchbrand', name: 'Brand',nameStringID: 'KC0062', placeholder: 'KC2336', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchunit', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC2339', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchassest', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1688', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchtype', name: 'Type',  nameStringID: 'KC0102', placeholder: 'KC0102', selectedKey: '', disabled: false, childValues: []},			
			{ value: 'searchmodelname', name: 'Model Name',nameStringID: 'KC0064', placeholder: 'KC2341', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchlocation', name: 'Location',nameStringID: 'KC0050', placeholder: 'KC2342', selectedKey: '', disabled: false, childValues: [] },			
		],

		'/menuStatus': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchbrand', name: 'Brand',nameStringID: 'KC0062', placeholder: 'KC2336', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchunit', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC2339', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchassest', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1688', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchtype', name: 'Type',  nameStringID: 'KC0102', placeholder: 'KC0102', selectedKey: '', disabled: false, childValues: []},			
			{ value: 'searchmodelname', name: 'Model Name',nameStringID: 'KC0064', placeholder: 'KC2341', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchlocation', name: 'Location',nameStringID: 'KC0050', placeholder: 'KC2342', selectedKey: '', disabled: false, childValues: [] },			
		],

		'/mediaUploadHistory': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchbrand', name: 'Brand',nameStringID: 'KC0062', placeholder: 'KC2336', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchunit', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC2339', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchassest', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1688', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchtype', name: 'Type',  nameStringID: 'KC0102', placeholder: 'KC0102', selectedKey: '', disabled: false, childValues: []},			
			{ value: 'searchmodelname', name: 'Model Name',nameStringID: 'KC0064', placeholder: 'KC2341', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchlocation', name: 'Location',nameStringID: 'KC0050', placeholder: 'KC2342', selectedKey: '', disabled: false, childValues: [] },			
		],

		'/mediaStatus': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchbrand', name: 'Brand',nameStringID: 'KC0062', placeholder: 'KC2336', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchunit', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC2339', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchassest', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1688', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchtype', name: 'Type',  nameStringID: 'KC0102', placeholder: 'KC0102', selectedKey: '', disabled: false, childValues: []},			
			{ value: 'searchmodelname', name: 'Model Name',nameStringID: 'KC0064', placeholder: 'KC2341', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchlocation', name: 'Location',nameStringID: 'KC0050', placeholder: 'KC2342', selectedKey: '', disabled: false, childValues: [] },			
		],

		//For Historic Page
		'/serviceManagement/historicErrors': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'errorCode', name: 'Error Code', nameStringID: 'KC0194',placeholder: 'KC0850', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'errorDescription', name: 'Error Description',nameStringID: 'KC0849', placeholder: 'KC0851', selectedKey: '', disabled: false, childValues: [] },
		],

		'/allcurrenterrors': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'errorCode', name: 'Error Code', nameStringID: 'KC0194', placeholder: 'KC0850', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'errorDescription', name: 'Error Description',nameStringID: 'KC0849', placeholder: 'KC0851', selectedKey: '', disabled: false, childValues: [] },
		],

		'/allhistoricerrors': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'errorCode', name: 'Error Code', nameStringID: 'KC0194', placeholder: 'KC0850', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'errorDescription', name: 'Error Description', nameStringID: 'KC0849', placeholder: 'KC0851', selectedKey: '', disabled: false, childValues: [] },
		],

		//For Unit and Registration Page
		'/unitRegistration': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'modelName', name: 'Model Name', nameStringID: 'KC0064', placeholder: 'KC1687', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset No.', nameStringID: 'KC0647', placeholder: 'KC1688', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'modelNumber', name: 'Model No.', nameStringID: 'KC1686', placeholder: 'KC1689', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial No.', nameStringID: 'KC0841', placeholder: 'KC1690', selectedKey: '', disabled: false, childValues: [] },
		],

		
		'/dashboardLocations': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'sitetree', name: 'Site Tree Group/Node', nameStringID: 'KC0049', placeholder: 'KC1931', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'location', name: 'Location', nameStringID: 'KC0050', placeholder: 'KC1932', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'address', name: 'Address', nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{
				value: 'errors', name: 'Error',nameStringID:'KC0052', placeholder: 'KC1934', selectedKey: '', disabled: false, childValues: [
					{ value: 'errorType', name: 'Error Type', nameStringID:'KC0053', placeholder: 'KC1935', disabled: false },
					// { value: 'errorCode', name: 'Error Code', placeholder: 'Search for error code', selectedKey: '', disabled: false, childValues: [] },
					// { value: 'errorDescription', name: 'Error Desc', placeholder: 'Search for error description', selectedKey: '', disabled: false, childValues: [] },
				]
			},
		],

		//For Unit Page
	
		'/dashboardUnits': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			// { value: 'sitetree', name: 'Site Tree Group/Node', placeholder: 'Search for Site tree', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'locationId', name: 'Location',  nameStringID: 'KC0050', placeholder: 'KC1932', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'address', name: 'Address',  nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{
				value: 'modalProperties', name: 'Model Properties', nameStringID:'KC1936', placeholder: 'KC1937', selectedKey: '', disabled: false, childValues: [
					{ value: 'modelNumber', name: 'Model No', nameStringID:'KC1686', placeholder: 'KC1938', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'modelName', name: 'Model Name', nameStringID:'KC0064', placeholder: 'KC1687', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'serialNumber', name: 'Serial No', nameStringID:'KC0574', placeholder: 'KC1939', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'assetNumber', name: 'Asset No', nameStringID:'KC0575', placeholder: 'KC1940', selectedKey: '', disabled: false, childValues: [] },
				]
			},
			{
				value: 'softwareVersion', name: 'Software Version', nameStringID:'KC1861', placeholder: 'KC1941', selectedKey: '', disabled: false, childValues: [
					{ value: 'civet', name: 'Civet', nameStringID:'KC1942', placeholder: 'KC1947', disabled: false },
					{ value: 'agent', name: 'Agent', nameStringID:'KC1943', placeholder: 'KC1948', disabled: false },
					{ value: 'mpd', name: 'MPD', nameStringID:'KC0233', placeholder: 'KC1949', disabled: false },
					{ value: 'ipd', name: 'IPD', nameStringID:'KC0234', placeholder: 'KC1950', disabled: false },
					{ value: 'imei', name: 'Modem IMEI', nameStringID:'KC1944', placeholder: 'KC1951', disabled: false },
					{ value: 'simNumber', name: 'Sim Card Number', nameStringID:'KC0222', placeholder: 'KC1952', disabled: false },
					{ value: 'menu', name: 'Menu', nameStringID:'KC0676', placeholder: 'Search for menu', disabled: false },
					{ value: 'machineSetting', name: 'Machine Settings', nameStringID:'KC0235', placeholder: 'KC1954', disabled: false },
					{ value: 'compaign', name: 'Compaign', nameStringID:'KC1945', placeholder: 'KC1955', disabled: false },
					{ value: 'customPackage', name: 'Custom Package Version', nameStringID:'KC1946', placeholder: 'KC1956', disabled: false },
				]
			},
			{
				value: 'errors', name: 'Error', nameStringID:'KC0052', placeholder: 'KC1934', selectedKey: '', disabled: false, childValues: [
					{ value: 'errorType', name: 'Error Type', nameStringID:'KC0053', placeholder: 'KC1935', disabled: false },
					{ value: 'errorCode', name: 'Error Code', nameStringID:'KC0194', placeholder: 'KC0850', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'errorDescription', name: 'Error Desc', nameStringID:'KC1959', placeholder: 'KC0851', selectedKey: '', disabled: false, childValues: [] },
				]
			},
		],

		
		'/unitsView': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'siteTree', name: 'Site Tree Group/Node', nameStringID: 'KC0049',  placeholder: 'KC1931', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'locationId', name: 'Location',nameStringID: 'KC0050',  placeholder: 'KC1932', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051',  placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{
				value: 'modalProperties', name: 'Model Properties',nameStringID: 'KC1936',  placeholder: 'KC1937', selectedKey: '', disabled: false, childValues: [
					{ value: 'modelNumber', name: 'Model No',nameStringID: 'KC1686',  placeholder: 'KC1938', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'modelName', name: 'Model Name',nameStringID: 'KC0064',  placeholder: 'KC1687', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'serialNumber', name: 'Serial No',nameStringID: 'KC0574',  placeholder: 'KC1939', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'assetNumber', name: 'Asset No', nameStringID: 'KC0575',  placeholder: 'KC1940', selectedKey: '', disabled: false, childValues: [] },
				]
			},
			{
				value: 'softwareVersion', name: 'Software Version',nameStringID: 'KC1861',  placeholder: 'KC1941', selectedKey: '', disabled: false, childValues: [
					{ value: 'civet', name: 'Civet',nameStringID: 'KC1942',  placeholder: 'KC1947', disabled: false },
					{ value: 'agent', name: 'Agent',nameStringID: 'KC1943',  placeholder: 'KC1948', disabled: false },
					{ value: 'mpd', name: 'MPD',nameStringID: 'KC0233',  placeholder: 'KC1949', disabled: false },
					{ value: 'ipd', name: 'IPD',nameStringID: 'KC0234',  placeholder: 'KC1950', disabled: false },
					{ value: 'imei', name: 'Modem IMEI',nameStringID: 'KC1944',  placeholder: 'KC1951', disabled: false },
					{ value: 'simNumber', name: 'Sim Card Number',nameStringID: 'KC0340',  placeholder: 'KC1952', disabled: false },
					{ value: 'menu', name: 'Menu', nameStringID: 'KC0676',  placeholder: 'KC1953', disabled: false },
					{ value: 'machineSetting', name: 'Machine Settings',nameStringID: 'KC0235',  placeholder: 'KC1954', disabled: false },
					{ value: 'compaign', name: 'Compaign',nameStringID: 'KC1945',  placeholder: 'KC1955', disabled: false },
					{ value: 'customPackage', name: 'Custom Package Version',nameStringID: 'KC1946',  placeholder: 'KC1956', disabled: false },
				]
			},
			{
				value: 'errors', name: 'Error',nameStringID: 'KC0052',  placeholder: 'KC1934', selectedKey: '', disabled: false, childValues: [
					{ value: 'errorType', name: 'Error Type', nameStringID: 'KC0053', placeholder: 'KC1935', disabled: false },
					{ value: 'errorCode', name: 'Error Code',nameStringID: 'KC0194',  placeholder: 'KC0850', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'errorDescription', name: 'Error Desc',nameStringID: 'KC1959',  placeholder: 'KC0851', selectedKey: '', disabled: false, childValues: [] },
				]
			},
		],
		'/editsmartGrpAssignUpdateList': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'siteTree', name: 'Site Tree Group/Node', nameStringID: 'KC0049', placeholder: 'KC1931', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'locationId', name: 'Location',nameStringID: 'KC0050', placeholder: 'KC1932', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'address', name: 'Address', nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{
				value: 'modalProperties', name: 'Model Properties',nameStringID: 'KC1936', placeholder: 'KC1937', selectedKey: '', disabled: false, childValues: [
					{ value: 'modelNumber', name: 'Model No', nameStringID: 'KC1686', placeholder: 'KC1938', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'modelName', name: 'Model Name',nameStringID: 'KC0064', placeholder: 'KC1687', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'serialNumber', name: 'Serial No',  nameStringID: 'KC0574', placeholder: 'KC1939', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'assetNumber', name: 'Asset No', nameStringID: 'KC0575', placeholder: 'KC1940', selectedKey: '', disabled: false, childValues: [] },
				]
			},
			{
				value: 'softwareVersion', name: 'Software Version',nameStringID: 'KC1861', placeholder: 'KC1941', selectedKey: '', disabled: false, childValues: [
					{ value: 'civet', name: 'Civet',nameStringID: 'KC1942', placeholder: 'KC1947', disabled: false },
					{ value: 'agent', name: 'Agent',nameStringID: 'KC1943', placeholder: 'KC1948', disabled: false },
					{ value: 'mpd', name: 'MPD',nameStringID: 'KC0233', placeholder: 'KC1949', disabled: false },
					{ value: 'ipd', name: 'IPD',nameStringID: 'KC0234', placeholder: 'KC1950', disabled: false },
					{ value: 'imei', name: 'Modem IMEI', nameStringID: 'KC1944', placeholder: 'KC1951', disabled: false },
					{ value: 'simNumber', name: 'Sim Card Number',nameStringID: 'KC0222', placeholder: 'KC1952', disabled: false },
					{ value: 'menu', name: 'Menu',  nameStringID: 'KC0676', placeholder: 'KC1953', disabled: false },
					{ value: 'machineSetting', name: 'Machine Settings', nameStringID: 'KC0235', placeholder: 'KC1954', disabled: false },
					{ value: 'compaign', name: 'Compaign', nameStringID: 'KC1945', placeholder: 'KC1955', disabled: false },
					{ value: 'customPackage', name: 'Custom Package Version', nameStringID: 'KC1946', placeholder: 'KC1956', disabled: false },
				]
			},
			{
				value: 'errors', name: 'Error',nameStringID: 'KC0052', placeholder: 'KC1934', selectedKey: '', disabled: false, childValues: [
					{ value: 'errorType', name: 'Error Type',nameStringID: 'KC0053', placeholder: 'KC1935', disabled: false },
					{ value: 'errorCode', name: 'Error Code',nameStringID: 'KC0194', placeholder: 'KC0850', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'errorDescription', name: 'Error Desc', nameStringID: 'KC1959', placeholder: 'KC0851', selectedKey: '', disabled: false, childValues: [] },
				]
			},
		],
		'/smartGrpAssignUpdateList': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'siteTree', name: 'Site Tree Group/Node', nameStringID: 'KC0049', placeholder: 'KC1931', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'locationId', name: 'Location',  nameStringID: 'KC0050', placeholder: 'KC1932', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{
				value: 'modalProperties', name: 'Model Properties', nameStringID: 'KC1936', placeholder: 'KC1937', selectedKey: '', disabled: false, childValues: [
					{ value: 'modelNumber', name: 'Model No', nameStringID: 'KC1686', placeholder: 'KC1938', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'modelName', name: 'Model Name',nameStringID: 'KC0064', placeholder: 'KC1687', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'serialNumber', name: 'Serial No',nameStringID: 'KC0574', placeholder: 'KC1939', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'assetNumber', name: 'Asset No',nameStringID: 'KC0575', placeholder: 'KC1940', selectedKey: '', disabled: false, childValues: [] },
				]
			},
			{
				value: 'softwareVersion', name: 'Software Version',nameStringID: 'KC1861', placeholder: 'KC1941', selectedKey: '', disabled: false, childValues: [
					{ value: 'civet', name: 'Civet', nameStringID: 'KC1942', placeholder: 'KC1947', disabled: false },
					{ value: 'agent', name: 'Agent', nameStringID: 'KC1943', placeholder: 'KC1948', disabled: false },
					{ value: 'mpd', name: 'MPD',nameStringID: 'KC0233', placeholder: 'KC1949', disabled: false },
					{ value: 'ipd', name: 'IPD',nameStringID: 'KC0234', placeholder: 'KC1950', disabled: false },
					{ value: 'imei', name: 'Modem IMEI', nameStringID: 'KC1944', placeholder: 'KC1951', disabled: false },
					{ value: 'simNumber', name: 'Sim Card Number',nameStringID: 'KC0222', placeholder: 'KC1952', disabled: false },
					{ value: 'menu', name: 'Menu', nameStringID: 'KC0676',  placeholder: 'KC1953', disabled: false },
					{ value: 'machineSetting', name: 'Machine Settings',  nameStringID: 'KC0235',placeholder: 'KC1954', disabled: false },
					{ value: 'compaign', name: 'Compaign', nameStringID: 'KC1945', placeholder: 'KC1955', disabled: false },
					{ value: 'customPackage', name: 'Custom Package Version', nameStringID: 'KC1946',  placeholder: 'KC1956', disabled: false },
				]
			},
			{
				value: 'errors', name: 'Error',nameStringID: 'KC0052',  placeholder: 'KC1934', selectedKey: '', disabled: false, childValues: [
					{ value: 'errorType', name: 'Error Type',nameStringID: 'KC0053', placeholder: 'KC1935', disabled: false },
					{ value: 'errorCode', name: 'Error Code', nameStringID: 'KC0194', placeholder: 'KC0850', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'errorDescription', name: 'Error Desc',nameStringID: 'KC1959', placeholder: 'KC0851', selectedKey: '', disabled: false, childValues: [] },
				]
			},
		],
		'/assignMediaUpdateList': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'siteTree', name: 'Site Tree Group/Node', nameStringID: 'KC0049', placeholder: 'KC1931', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'locationId', name: 'Location',  nameStringID: 'KC0050', placeholder: 'KC1932', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{
				value: 'modalProperties', name: 'Model Properties', nameStringID: 'KC1936', placeholder: 'KC1937', selectedKey: '', disabled: false, childValues: [
					{ value: 'modelNumber', name: 'Model No', nameStringID: 'KC1686', placeholder: 'KC1938', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'modelName', name: 'Model Name',nameStringID: 'KC0064', placeholder: 'KC1687', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'serialNumber', name: 'Serial No',nameStringID: 'KC0574', placeholder: 'KC1939', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'assetNumber', name: 'Asset No',nameStringID: 'KC0575', placeholder: 'KC1940', selectedKey: '', disabled: false, childValues: [] },
				]
			},
		],
		'/smartTag/smartTagUnitList': [
			{ value: '', name: 'Category', nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'siteTree', name: 'Site Tree Group/Node', nameStringID: 'KC0049', placeholder: 'KC1931', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'locationId', name: 'Location',  nameStringID: 'KC0050', placeholder: 'KC1932', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{
				value: 'modalProperties', name: 'Model Properties', nameStringID: 'KC1936', placeholder: 'KC1937', selectedKey: '', disabled: false, childValues: [
					{ value: 'modelNumber', name: 'Model No', nameStringID: 'KC1686', placeholder: 'KC1938', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'modelName', name: 'Model Name',nameStringID: 'KC0064', placeholder: 'KC1687', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'serialNumber', name: 'Serial No',nameStringID: 'KC0574', placeholder: 'KC1939', selectedKey: '', disabled: false, childValues: [] },
					{ value: 'assetNumber', name: 'Asset No',nameStringID: 'KC0575', placeholder: 'KC1940', selectedKey: '', disabled: false, childValues: [] },
				]
			},
		],
		// User Story 55079: [High Priority] KC Portal - Add Global Search to Organization Management
		'/organizationManagement': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'country', name: 'Country Name', nameStringID: 'KC1960', placeholder: 'KC1966', selectedKey: '', disabled: false, childValues: [] ,level_id: 2 },
			{ value: 'chainName', name: 'Sub Group Name', nameStringID: 'KC1961', placeholder: 'KC1965', selectedKey: '', disabled: false, childValues: [] ,level_id: 3 },
			{ value: 'locationName', name: 'Location Name',nameStringID: 'KC1040', placeholder: 'KC1964', selectedKey: '', disabled: false, childValues: [] ,level_id: 4 },
			{ value: 'withinLocation', name: 'Within Location Name', nameStringID: 'KC1962', placeholder: 'KC1963', selectedKey: '', disabled: false, childValues: [] ,level_id: 5 },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1968', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC1967', selectedKey: '', disabled: false, childValues: [] },
		],
		'/assignReportUnitToTopology': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'country', name: 'Country Name', nameStringID: 'KC1960', placeholder: 'KC1966', selectedKey: '', disabled: false, childValues: [] ,level_id: 2 },
			{ value: 'chainName', name: 'Sub Group Name', nameStringID: 'KC1961', placeholder: 'KC1965', selectedKey: '', disabled: false, childValues: [] ,level_id: 3 },
			{ value: 'locationName', name: 'Location Name',nameStringID: 'KC1040', placeholder: 'KC1964', selectedKey: '', disabled: false, childValues: [] ,level_id: 4 },
			{ value: 'withinLocation', name: 'Within Location Name', nameStringID: 'KC1962', placeholder: 'KC1963', selectedKey: '', disabled: false, childValues: [] ,level_id: 5 },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1968', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC1967', selectedKey: '', disabled: false, childValues: [] },
		],
		'/menuAssigngroupTopology': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'country', name: 'Country Name', nameStringID: 'KC1960', placeholder: 'KC1966', selectedKey: '', disabled: false, childValues: [] ,level_id: 2 },
			{ value: 'chainName', name: 'Sub Group Name', nameStringID: 'KC1961', placeholder: 'KC1965', selectedKey: '', disabled: false, childValues: [] ,level_id: 3 },
			{ value: 'locationName', name: 'Location Name',nameStringID: 'KC1040', placeholder: 'KC1964', selectedKey: '', disabled: false, childValues: [] ,level_id: 4 },
			{ value: 'withinLocation', name: 'Within Location Name', nameStringID: 'KC1962', placeholder: 'KC1963', selectedKey: '', disabled: false, childValues: [] ,level_id: 5 },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1968', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC1967', selectedKey: '', disabled: false, childValues: [] },
		],
		'/assignMedia': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'country', name: 'Country Name', nameStringID: 'KC1960', placeholder: 'KC1966', selectedKey: '', disabled: false, childValues: [] ,level_id: 2 },
			{ value: 'chainName', name: 'Sub Group Name', nameStringID: 'KC1961', placeholder: 'KC1965', selectedKey: '', disabled: false, childValues: [] ,level_id: 3 },
			{ value: 'locationName', name: 'Location Name',nameStringID: 'KC1040', placeholder: 'KC1964', selectedKey: '', disabled: false, childValues: [] ,level_id: 4 },
			{ value: 'withinLocation', name: 'Within Location Name', nameStringID: 'KC1962', placeholder: 'KC1963', selectedKey: '', disabled: false, childValues: [] ,level_id: 5 },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1968', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC1967', selectedKey: '', disabled: false, childValues: [] },
		],
		'/smartTag/smartTagTopology': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'country', name: 'Country Name', nameStringID: 'KC1960', placeholder: 'KC1966', selectedKey: '', disabled: false, childValues: [] ,level_id: 2 },
			{ value: 'chainName', name: 'Sub Group Name', nameStringID: 'KC1961', placeholder: 'KC1965', selectedKey: '', disabled: false, childValues: [] ,level_id: 3 },
			{ value: 'locationName', name: 'Location Name',nameStringID: 'KC1040', placeholder: 'KC1964', selectedKey: '', disabled: false, childValues: [] ,level_id: 4 },
			{ value: 'withinLocation', name: 'Within Location Name', nameStringID: 'KC1962', placeholder: 'KC1963', selectedKey: '', disabled: false, childValues: [] ,level_id: 5 },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1968', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC1967', selectedKey: '', disabled: false, childValues: [] },
		],
		'/uploadLegacyCookbookToTopology': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'country', name: 'Country Name', nameStringID: 'KC1960', placeholder: 'KC1966', selectedKey: '', disabled: false, childValues: [] ,level_id: 2 },
			{ value: 'chainName', name: 'Sub Group Name', nameStringID: 'KC1961', placeholder: 'KC1965', selectedKey: '', disabled: false, childValues: [] ,level_id: 3 },
			{ value: 'locationName', name: 'Location Name',nameStringID: 'KC1040', placeholder: 'KC1964', selectedKey: '', disabled: false, childValues: [] ,level_id: 4 },
			{ value: 'withinLocation', name: 'Within Location Name', nameStringID: 'KC1962', placeholder: 'KC1963', selectedKey: '', disabled: false, childValues: [] ,level_id: 5 },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1968', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC1967', selectedKey: '', disabled: false, childValues: [] },
		],
		'/assignLegacyMenuToTopology': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'country', name: 'Country Name', nameStringID: 'KC1960', placeholder: 'KC1966', selectedKey: '', disabled: false, childValues: [] ,level_id: 2 },
			{ value: 'chainName', name: 'Sub Group Name', nameStringID: 'KC1961', placeholder: 'KC1965', selectedKey: '', disabled: false, childValues: [] ,level_id: 3 },
			{ value: 'locationName', name: 'Location Name',nameStringID: 'KC1040', placeholder: 'KC1964', selectedKey: '', disabled: false, childValues: [] ,level_id: 4 },
			{ value: 'withinLocation', name: 'Within Location Name', nameStringID: 'KC1962', placeholder: 'KC1963', selectedKey: '', disabled: false, childValues: [] ,level_id: 5 },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1968', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC1967', selectedKey: '', disabled: false, childValues: [] },
		],
		'/assignUserToTopology': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'country', name: 'Country Name', nameStringID: 'KC1960', placeholder: 'KC1966', selectedKey: '', disabled: false, childValues: [] ,level_id: 2 },
			{ value: 'chainName', name: 'Sub Group Name', nameStringID: 'KC1961', placeholder: 'KC1965', selectedKey: '', disabled: false, childValues: [] ,level_id: 3 },
			{ value: 'locationName', name: 'Location Name',nameStringID: 'KC1040', placeholder: 'KC1964', selectedKey: '', disabled: false, childValues: [] ,level_id: 4 },
			{ value: 'withinLocation', name: 'Within Location Name', nameStringID: 'KC1962', placeholder: 'KC1963', selectedKey: '', disabled: false, childValues: [] ,level_id: 5 },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1968', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC1967', selectedKey: '', disabled: false, childValues: [] },
		],
		// User Story 72242: KC Portal : slow performance while assigning units to organization + Enable Global Search feature
		'/assignUnitToTopology': [
			{ value: '', name: 'Category', nameStringID: 'KC0048',selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'country', name: 'Country Name', nameStringID: 'KC1960', placeholder: 'KC1966', selectedKey: '', disabled: false, childValues: [] ,level_id: 2 },
			{ value: 'chainName', name: 'Sub Group Name', nameStringID: 'KC1961', placeholder: 'KC1965', selectedKey: '', disabled: false, childValues: [] ,level_id: 3 },
			{ value: 'locationName', name: 'Location Name',nameStringID: 'KC1040', placeholder: 'KC1964', selectedKey: '', disabled: false, childValues: [] ,level_id: 4 },
			{ value: 'withinLocation', name: 'Within Location Name', nameStringID: 'KC1962', placeholder: 'KC1963', selectedKey: '', disabled: false, childValues: [] ,level_id: 5 },
			{ value: 'address', name: 'Address',nameStringID: 'KC0051', placeholder: 'KC1933', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'assetNumber', name: 'Asset Number', nameStringID: 'KC0105', placeholder: 'KC1968', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'serialNumber', name: 'Serial Number',nameStringID: 'KC0104', placeholder: 'KC1967', selectedKey: '', disabled: false, childValues: [] },
		],
		//For reportList
		'/reportList': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchbrandname', name: 'Brand Name', nameStringID: 'KC0873', placeholder: 'KC1970', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchreportname', name: 'Report Name',  nameStringID: 'KC0794', placeholder: 'KC1969', selectedKey: '', disabled: false, childValues: [] }
		],
		//For scheduledReportList
		'/scheduledReportList': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchreportname', name: 'Report Name', nameStringID: 'KC0794', placeholder: 'KC1969', selectedKey: '', disabled: false, childValues: [] }
		],//For libraryList
		'/libraryList': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchtype', name: 'Type',  nameStringID: 'KC0102', placeholder: 'KC0102', selectedKey: '', disabled: false, childValues: [], isDropdown: [{label:"Recipe",value:"Recipe"},{label:"CookBook",value:"CookBook"},{label:"Settings",value:"Settings"}] },
			{ value: 'searchmodelfamily', name: 'Model Family',  nameStringID: 'KC0649', placeholder: 'KC0649', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchlibraryname', name: 'Name',  nameStringID: 'KC0241', placeholder: 'KC0241', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchcreatedby', name: 'Created By',  nameStringID: 'KC0589', placeholder: 'KC0589', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searcheditedby', name: 'Edited By',  nameStringID: 'KC2273', placeholder: 'KC2273', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchdescription', name: 'Description', nameStringID: 'KC0195', placeholder: 'KC0195', selectedKey: '', disabled: false, childValues: [] }
		],
		'/libraryListGarland': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchtype', name: 'Type',  nameStringID: 'KC0102', placeholder: 'KC0102', selectedKey: '', disabled: false, childValues: [], isDropdown: [{label:"Recipe",value:"Recipe"},{label:"Menu",value:"CookBook"}] },
			{ value: 'searchmodelfamily', name: 'Model Family',  nameStringID: 'KC0649', placeholder: 'KC0649', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchlibraryname', name: 'Name',  nameStringID: 'KC0241', placeholder: 'KC0241', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchcreatedby', name: 'Created By',  nameStringID: 'KC0589', placeholder: 'KC0589', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searcheditedby', name: 'Edited By',  nameStringID: 'KC2273', placeholder: 'KC2273', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchdescription', name: 'Description', nameStringID: 'KC0195', placeholder: 'KC0195', selectedKey: '', disabled: false, childValues: [] }
		],
		'/legacyLibraryList': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchtype', name: 'Type',  nameStringID: 'KC0102', placeholder: 'KC0102', selectedKey: '', disabled: false, childValues: [], isDropdown: ["Recipe", "CookBook"] },
			{ value: 'searchmodelfamily', name: 'Model Family',  nameStringID: 'KC0649', placeholder: 'KC0649', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchlibraryname', name: 'Name',  nameStringID: 'KC0241', placeholder: 'KC0241', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchcreatedby', name: 'Created By',  nameStringID: 'KC0589', placeholder: 'KC0589', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searcheditedby', name: 'Edited By',  nameStringID: 'KC2273', placeholder: 'KC2273', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchdescription', name: 'Description', nameStringID: 'KC0195', placeholder: 'KC0195', selectedKey: '', disabled: false, childValues: [] }
		],
		'/legacyReadyRecipeLibraryList': [
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchmodelfamily', name: 'Model Family',  nameStringID: 'KC0649', placeholder: 'KC0649', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchlibraryname', name: 'Name',  nameStringID: 'KC0241', placeholder: 'KC0241', selectedKey: '', disabled: false, childValues: [] }
		],
		'/mediaPages':[
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchtype', name: 'Type',  nameStringID: 'KC0102', placeholder: 'KC2944', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchname', name: 'Name',  nameStringID: 'KC0241', placeholder: 'KC2945', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchcgids', name: 'Assigned To',  nameStringID: 'KC2301', placeholder: 'KC2946', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchsg', name: 'Smart Groups',  nameStringID: 'KC2302', placeholder: 'KC2947', selectedKey: '', disabled: false, childValues: [] },
		],
		'/mediaOtherPages':[
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'searchtype', name: 'Type',  nameStringID: 'KC0102', placeholder: 'KC2944', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchname', name: 'Name',  nameStringID: 'KC0241', placeholder: 'KC2945', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'searchcgids', name: 'Assigned To',  nameStringID: 'KC2301', placeholder: 'KC2946', selectedKey: '', disabled: false, childValues: [] },
		],
		'/smartTag/tagList':[
			{ value: '', name: 'Category',nameStringID: 'KC0048', selectedKey: '', disabled: false, placeholder: 'KC0037', childValues: [] },
			{ value: 'tagName', name: 'Smart Tag Name',  nameStringID: 'KC2319', placeholder: 'KC2648', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'description', name: 'Description',  nameStringID: 'KC0195', placeholder: 'KC2649', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'createdBy', name: 'Created By',  nameStringID: 'KC0589', placeholder: 'KC2650', selectedKey: '', disabled: false, childValues: [] },
			{ value: 'updatedBy', name: 'Last Edited By',  nameStringID: 'KC2277', placeholder: 'KC2651', selectedKey: '', disabled: false, childValues: [] },
		]
	};
	return url.includes('specificLocation') ? urlMap['/dashboardUnits'] : url === "/dashboardLocations" || url === "/dashBoardLocations" || url === "/locationsView" ? urlMap['/dashboardLocations'] : url.includes('dashBoardLocations/unitsView') ? urlMap['/dashboardUnits'] : urlMap[url]; 
}

export const searchData_function = (searchData, isEncodeNeeded = false) => {
	if (searchData) {
		let url = '';
		Object.keys(searchData).forEach(key => {
			if (searchData[key]) {
				url = `${url}&${key}=${isEncodeNeeded ? encodeURIComponent(searchData[key].toLowerCase()) : searchData[key].toLowerCase()}`
			}
		});
		return url;
	}
}

export const getLabelNames = (data, url) => {
	return data.map(key => {
		let Option = setOptions(url);
		let obj = Option.find(node => node.value === key);
		return obj.name;
	})
}

export const removeEle = (data, key) => {
	return data.filter(e => {
		let keys = Object.keys(e);
		let i = keys.indexOf(key);
		if (i >= 0) {
			return false;
		} else {
			return true;
		}
	});
}
export const getObjByValue = (value, url) => {
	let Option = setOptions(url);
	let obj = Option.find(e => e.value === value);
	return obj;
}

export const removeEmpty = (data) => {
	return data.filter(e => {
		let x = Object.keys(e);
		x = x.find(e => Boolean(e));
		if (x) {
			return true;
		}
	});
}
export const setDisabledByValue = (data, value, bol) => {
	return data.map(e => e.value === value ? { ...e, disabled: bol } : { ...e });
}

export const setChildSelectedValue = (data, itemValue, value) => {
	return data.map(e => e.value === itemValue ? { ...e, selectedKey: value } : { ...e })
}

export const isExist = (data, value) => {
	let isExist = false;
	if (data) {
		data.forEach(e => {
			if ((e && e.value === value)) {
				isExist = true;
			}
		});
	}
	return isExist;
}

export const isEqualObj = (object1, object2) => {
	if (!object1 || !object2) return false;
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}
	return true;
}