import {TIMER_DOUBLE_TAPS_REQUEST,TIMER_DOUBLE_TAPS_SUCCESS,TIMER_DOUBLE_TAPS_FAIL} from '../../../actions/unitDashboard/timerDoubleTapsAction';
const initialState = {
    loaded: false,
    loading: false,
    timerDoubleTapsResponse: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case TIMER_DOUBLE_TAPS_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case TIMER_DOUBLE_TAPS_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                timerDoubleTapsResponse: action.timerDoubleTapsResponse
            }
        case TIMER_DOUBLE_TAPS_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgdobuleTaps: 'Server Error',

            }
        default:
            return state
    }
}