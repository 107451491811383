import {DOOR_OPENINGS_DATA_REQUEST,DOOR_OPENINGS_DATA_SUCCESS,DOOR_OPENINGS_DATA_FAIL} from '../../../actions/unitDashboard/doorOpeningsAction';
const initialState = {
    loaded: false,
    loading: false,
    doorOpeningsData:{}
}

export default function reducer (state = initialState, action = {}) {

    switch (action.type) {
        case DOOR_OPENINGS_DATA_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
            }
        case DOOR_OPENINGS_DATA_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                doorOpeningsData: action.doorOpeningsData
            }
        case DOOR_OPENINGS_DATA_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
            }
        default:
            return state
    }
}
