import http from '../../../service/httpService';
import { MediaManagementAPI } from '../../../service/api';
import moment from '../../../utils/momentHelper.js';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';

import KCStore from '../../../utils/kcStorage';

export const MEDIA_COMPILATION_LIST_REQUEST = 'MEDIA_COMPILATION_LIST_REQUEST';
export const MEDIA_COMPILATION_LIST_SUCCESS = 'MEDIA_COMPILATION_LIST_SUCCESS';
export const MEDIA_COMPILATION_LIST_FAIL = 'MEDIA_COMPILATION_LIST_FAIL';
export const MEDIA_SELECTED_LIST_SUCCESS = 'MEDIA_SELECTED_LIST_SUCCESS';


export function getMediaCompilationList(searchParam) {
    const userListEmail = JSON.parse(KCStore.getItem('childUsersEmail'));
    const { username } = JSON.parse(KCStore.getItem('loginInfo'));
    const roleName = KCStore.getItem('roleName') || '';

    let custGroupID = KCStore.getItem("custGroupID");
    let cgids = "";
    if (custGroupID != "" && custGroupID != undefined) {
        cgids = custGroupID;
    }

    return dispatch => {
        dispatch({
            type: MEDIA_COMPILATION_LIST_SUCCESS,
            mediaCompilationListInformation: [],
        })
        dispatch({
            type: MEDIA_COMPILATION_LIST_REQUEST
        })
        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json',
                'customer': KCStore.getItem("customerId"),
                'brand': 'CREM',
                'type': 'compilation',
                'cgids': cgids,
                'basecountry': BASECOUNTRY_ID
            };
            if (searchParam) {
                headers = Object.assign(headers, searchParam);
            }
            http.get(`${MediaManagementAPI.getMediaBrandCustomer}`,
                {
                    headers,
                    data: {}

                })
                .then(response => {

                    const { mediaList } = response.data;
                    if (mediaList) {
                        mediaList.forEach(item => {
                            item.CREATEDAT = moment.ConvertLocalTimeFromUTCTime(item.CREATEDAT, "MMMM DD, YYYY hh:mm A");
                            let isDelete = false;
                            if (item.UPLOADED_BY && item.UPLOADED_BY.toString().match('@')) {
                                isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(item.UPLOADED_BY) || username === item.UPLOADED_BY ? true : false;
                            } else {
                                isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
                            }
                            item.isAuthorized = isDelete;
                        });
                    }
                    dispatch({
                        type: MEDIA_COMPILATION_LIST_SUCCESS,
                        mediaCompilationListInformation: response.data,
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    dispatch({
                        type: MEDIA_COMPILATION_LIST_FAIL,
                        error
                    })
                    resolve(error)
                })
        })
    }
}

export function mediaSelectedItems(selectedItems) {
    return dispatch => {
        dispatch({
            type: MEDIA_SELECTED_LIST_SUCCESS,
            mediaSelectedItems:selectedItems,
         })
    }
}
