import React, { Component, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { UnitInfo, smartGroupAPI } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { BaseService, ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../utils/kcStorage';

$(window).on('beforeunload', function () {
	if ($(".export-error-report").length > 0) {
		$("#exportReport").removeClass("export-error-report");
		$(".loadingProgress").css("display", "none");
	}
});

class SoftwareAssignlistView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			unitsSelected: 0,
			unitsOffline: 0,
			userSelectAll: false,
			userChecked: [],
			userCheckedData: [],
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			total_unit_count: 0,
			nameFromReport: '',
			startDateFromReport: '',
			endDateFromReport: '',
			selectedoptionsFromReport: [],
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			errorResponse: ""
		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);

		this.myReports = this.myReports.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);

	}

	componentDidMount() {
		console.log("location", this.props.location)
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			this.requestData();
		} catch (error) {
			//Error Goes Here
		} 
	}

	componentWillMount() {
		if (this.props.location && this.props.location.state) {
			this.setState({
				nameFromReport: this.props.location.state.nameFromReport,
				startDateFromReport: this.props.location.state.startDateFromReport,
				endDateFromReport: this.props.location.state.endDateFromReport,
				selectedoptionsFromReport: this.props.location.state.selectedoptionsFromReport,
			});
		}
	}
	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	requestData = async () => {
		let selectedCids = [];
		let selectedModels = [];
		let custGroupID = KCStore.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let apiUrl = `${UnitInfo.list}`;

		let headerValue = {
			'Content-Type': 'application/json',
			'cgid': cgids,
			'cid': selectedCids.join(','),
			'brandid':"",// KCStore.getItem("brandId"),
			'model': selectedModels.join(','),
			'sortby': sortedID,
			'sorttype': sortedDesc,
			'basecountry': BASECOUNTRY_ID,
			'showOnlyActiveUnits': true
		}

		if (KCStore.getItem("selectedbrandID") === "4") {
			headerValue = {
				'Content-Type': 'application/json',
				'cgid': cgids,
				'brandid': KCStore.getItem("selectedbrandID"),
				'cid': KCStore.getItem("customerId"),
			}
		}

		await http.get(apiUrl,
			{
				headers: headerValue,
				data: {}
			}).then(response => {
				let { units, total_unit_count, } = response.data;
				units = UnitBuilder(units);

				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				units.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});

				this.setState({
					loaderIconVisible: false,
					data: units,
					userChecked: checkedCopy,
					userSelectAll: userSelectAll,
					total_unit_count: total_unit_count
				});

			}).catch(err => {

			});
	}

	onBackClickHandler = () => {
		this.props.history.push({
			pathname: `/createnewsmartgrp`,
			state: {
				nameFromReport: this.state.nameFromReport,
				startDateFromReport: this.state.startDateFromReport,// "2020-04-09",
				endDateFromReport: this.state.endDateFromReport,// "2020-04-09",
				selectedoptionsFromReport: this.state.selectedoptionsFromReport,
			},
		});
	}

	myReports = () => {
		this.setState({
			loaderIconVisible: false,
		});
		this.props.history.push(`/reportList`);
	}

	handleChange = () => {
		var userSelectAll = !this.state.userSelectAll;
		this.setState({ userSelectAll: userSelectAll });
		var checkedCopy = [];
		let units = [...this.state.data];
		units.forEach(function (e, index) {
			checkedCopy.push(userSelectAll);
		});

		units && units.length > 0 && units.map(item => {
			item.checked = userSelectAll;
		});

		this.isSelectAssign(checkedCopy);
		let unitsOfflineCount = units.filter(c => c.CONNECT_STATE === "Offline");

		if (userSelectAll) {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				unitsSelected: units.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: []
			});
		}
		else {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				unitsSelected: 0,
				unitsOffline: 0,
				userCheckedData: [],
			});
		}
	};

	handleSingleCheckboxChange = (e, index, rowData) => {
		var checkedCopy = this.state.userChecked;
		checkedCopy[index] = !this.state.userChecked[index];
		if (checkedCopy[index] === false) {
			this.setState({ userSelectAll: false });
		}
		let checkAll = [];

		const units = [...this.state.data];
		units[index] = { ...units[index] };
		units[index].checked = e.target.checked;

		checkAll = units.filter(c => c.checked === false || c.checked === undefined);
		let checked = units.filter(c => c.checked === true);
		let unitsOfflineCount = checked.filter(c => c.CONNECT_STATE === "Offline");

		if (checkAll.length === 0) {
			this.setState({
				data: units,
				userSelectAll: true,
				userChecked: checkedCopy,
				unitsSelected: checked.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: units
			});
		}
		else {

			this.setState({
				data: units,
				userSelectAll: false,
				userChecked: checkedCopy,
				unitsSelected: checked.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: checked,
			});
		}

		this.isSelectAssign(this.state.userChecked);
	};

	isSelectAssign = (units) => {
		let checkAllTrue = [];
		checkAllTrue = units.filter(c => c === true);

		if (checkAllTrue.length > 0) {
			$("#btnfinish").addClass("btn-default-text activeState");
			$("#btnfinish").removeClass("disabled");
		}
		else {
			$("#btnfinish").removeClass("activeState");
			$("#btnfinish").addClass("disabled");
		}
	}

	onClickFinish = async (e) => {
		this.getSmartGroupID();
	}

	getSmartGroupID = async (e) => {
		this.setState({ loaderIconVisible: true, })
		let postdata = {
			"name": this.state.nameFromReport,
			"purposeId": this.state.selectedoptionsFromReport,
			"customerId": + KCStore.getItem("customerId"),
			"userId": KCStore.getItem("user"),
		};
		await http.post(smartGroupAPI.createSmartGroup, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({ responseData: response.data, loaderIconVisible: false });
				this.submitForExportReport(response.data.smartGrpID)
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	submitForExportReport = async (smartGroupID) => {
		this.setState({ loaderIconVisible: true, })
		let selectedUnitList = [];
		let userChecked = this.state.userCheckedData;
		let unitObj =[];
			
		if (userChecked && userChecked.length > 0) {
			userChecked.forEach(function (item, index) {
				selectedUnitList.push(item.ID);
				unitObj.push(
                    {"unitId":item.ID,
                    "brandId":item.BRAND_ID,}
                    );
			})
		} else if (this.state.userSelectAll) {
			if (this.state.data && this.state.data.length > 0) {
				this.state.data.forEach(function (item, index) {
					selectedUnitList.push(item.ID);
					unitObj.push(
						{"unitId":item.ID,
						"brandId":item.BRAND_ID,}
						);
				})
			}
		}

		let postdata = {
			"smartGroupId": smartGroupID,
			"unitObj": unitObj,
		};
		await http.post(smartGroupAPI.createSmartGroupWithUnit, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({ responseData: response.data, loaderIconVisible: false });

				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: "KC1574",
					},
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	onFinishClickHandler() {
		let userCheckedData = this.state.userCheckedData;
		const { userSelectAll, data } = this.state;
		const unitList = [];
		if (userSelectAll === true) {
			data.map(item => {
				return unitList.push(item.CLOUD_UNIT_NAME);
			});
		} else {
			userCheckedData.map(item => {
				return unitList.push(item.CLOUD_UNIT_NAME);
			});
		}

		const unitData = [];
		if (userSelectAll === true) {
			data.map(item => {
				return unitData.push(
					{
						"CLOUD_UNIT_NAME": item.CLOUD_UNIT_NAME,
						"productId": item.PRODUCT_ID,
						"UNITID": item.UNITID,
						"dynastyAttributes": item.dynastyAttributes,
					}
				)
			});
		} else {
			userCheckedData.map(item => {
				return unitData.push(
					{
						"CLOUD_UNIT_NAME": item.CLOUD_UNIT_NAME,
						"productId": item.PRODUCT_ID,
						"UNITID": item.UNITID,
						"dynastyAttributes": item.dynastyAttributes,
					}
				)
			});
		}

		//modelNumber
		let startdateofReport = this.props.location.selectedstartDate;
		startdateofReport = new Date(startdateofReport);
		startdateofReport = format(startdateofReport, 'YYYY-MM-DD');

		let enddateofReport = this.props.location.selectedendDate;
		enddateofReport = new Date(enddateofReport).toDateString();
		enddateofReport = format(enddateofReport, 'YYYY-MM-DD');

		let token = KCStore.getItem('Access-Token');
		let ErrorReportPostData = {
			startDate: startdateofReport,
			endDate: enddateofReport,
			timeZone: "abc",
			unitIds: unitList,
			unitData: unitData
		};

		http({
			method: "POST",
			url: `${BaseService.root}${ReportManagement.deviceDataApi}`,
			data: ErrorReportPostData,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': token,
			}
		}).then(response => {
			if (response)
				this.setState({
					loaderIconVisible: false,
					errorResponse: response.data.fileUrl
				});
			$("#exportReport").addClass("export-error-report");
			window.location = response.data.fileUrl;
		}).catch(err => {
			this.showError(err);
		});
	}

	showError = (err) => {
		try {
			let { response } = err;
			if (response && response.status) {

				let { data } = response;
				let { body } = data;
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: body.message ? body.message : ""
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.message
					}

				});
			}
		} catch (e) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.message
				}

			});
		}
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/smartgroupslist`);
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { loaderIconVisible, data, sortColumn, unitsSelected, unitsOffline, error_modal, success_modal } = this.state;
		let orderId = 1;
		let Updatedunits = (parseInt(unitsSelected) - parseInt(unitsOffline));
		let columns = [ 
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0101", isNbSp: true },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "PRODUCT_TYPE", labelStringID: "KC0102" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "UNIT_NUMBER", labelStringID: "KC0674" },
			{ path: "location_name", labelStringID: "KC0675" },
			{ path: "Within_Location", labelStringID: "KC0061" },
			{ path: "Smart_Group", labelStringID: "KC0065" },
			{ path: "CONNECT_STATE", labelStringID: "KC0523", isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
		];

		let unitList = data;

		unitList.forEach(item => {
			item.order_id = orderId++
		});

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);

		let showHeader;
		const brandsName = KCStore.getItem("brandName");
		showHeader = (
			<ul>
				<li><FormattedMessage id="KC1570" values={{ selectedCount: this.state.unitsSelected, offlineCount: this.state.unitsOffline }}/></li>
				<li>
					<Link to="/assigngroupTopology" class="assignunitSite">&nbsp;</Link>
					<Link to="#" id="btnAssign" className="assignunitList active" onClick={this.onClickListView}>&nbsp;</Link>
				</li>
			</ul>
		);

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div id="exportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div class="reportWrapper">
							<div class="adminBgHeader">
								<ul>
									<li>
										<Link to="/mediacompilation" onClick={() => this.onBackClickHandler()} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link>
									</li>
									<li className="txtboldmsg">
										<FormattedMessage id="KC0711"/>
				            	</li>
									<li>
										{/* <Link id="btnfinish" to="/assigngroupTopology" title="Finish" className="disabled" onClick={() => this.onClickFinish()}>Finish</Link> */}
										{/* <Link id="btnfinish" to="/assignmediaconfm" title="Next" className="disabled">Next</Link> */}
										<a id="btnfinish" href="javascript:void(0)" title={formatMessage({ id: 'KC0056' })} className="disabled" onClick={() => this.onClickFinish()}><FormattedMessage id="KC0056"/></a>

									</li>
								</ul>
							</div>
							<div class="selectedUnitHeader">
								{showHeader}
							</div>
							<form class="SGlistUnits">
								<div class="SWlistUnitsTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isSelectAll={this.state.userSelectAll} />
								</div>
								 	{unitList.map((item, i) => {
										return (
											<React.Fragment key={i}>
												<div class="SWlistUnitsTable">
													<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody"} key={i}>														
														<li>{i + 1}</li>
														<li><img src={item.Brand_Logo} /></li>
														<li>{item.BRAND_NAME}</li>
														<li>{item.PRODUCT_TYPE}</li>
														<li>{item.MODEL_NAME}</li>
														<li>{item.UNITID}</li>
														<li>{item.UNIT_NUMBER}</li>
														<li>{item.location_name}</li>
														<li>{item.within_location}</li>
														<li>
															{item.smartGroups && item.smartGroups.map((item, i) => {
																return <span className={i <= 3 ? "sgFL" : ""}>{i <= 3 ? item.smartGroupName.charAt(0) : ""}&nbsp;</span>
															})}
														</li>

														<li><span className={`${item.CONNECT_STATE === 'Offline' ? "offlineStausTable" : ""}`}></span></li>
														{<li>
															<div className="customCheckbox customCheckboxWOlabel">
																<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
																	checked={item.checked}
																	onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
																<label for={item.order_id - 1}></label>
															</div>
														</li>}
													</ul>
												</div> 
											</React.Fragment>
										)
									}
									)} 
							</form>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader headerName="Media – Crem Library" headerNameStringID="KC1737" activeClass="orgznManagementNav" />
				</div>
				<div class="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationOkModal" tabindex="-1" role="dialog" aria-labelledby=" confirmationOkModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-body">
								<div class="confirmationContentDiv">
									<h5><FormattedMessage id="KC0832"/></h5>
									<p><FormattedMessage 
											id="KC1571" 
											values={{ Updatedunits, unitsSelected, unitsOffline}}/></p>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" onClick={() => this.myReports()} type="button"><FormattedMessage id="KC0777"/></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};

export default injectIntl(SoftwareAssignlistView);