import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ErrorModal from '../modal/error-model';
import auth from '../../service/authService';

const SSOLoginTenantRedirect = ({ match }) => {
    const [error, setError] = useState({ open: false, message: "" });
    const [loading, setLoading] = useState(false);

    const { uuid } = match.params;

    useEffect(() => {
        document.body.style.backgroundColor = "#0d3968";
        document.body.classList.add("loginBg");
        cognitoUrl(uuid);
        // Cleanup function to remove class when component unmounts
        return () => {
            document.body.classList.remove("loginBg");
        };
    }, [uuid]);

    const cognitoUrl = async (uuid) => {
        if (!uuid) {
            setError({ open: true, message: "Un-authorized access." });  
            return;
        } 
        try {
            setLoading(true);
            const res = await auth.getSSOCognitoURL({ uuid });
            const { status, data } = res;
            if (status === 200) {
                window.location.replace(data.cognito_url);
            }
        } catch (error) {
            setError({ message: "Un-authorized access.", open: true });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="loginBg">
            <div id="adminWrapper">
                <div id="loader" className={loading ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar navbar-expand-lg navbar-dark bgAdmin">
                    <div className="collapse navbar-collapse" id="navbarContent">
                        <div className="navbar-header">
                            <Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>
                        </div>
                    </div>
                </nav>

                <div id="page-wrapper">
                    <div className="welcomText">Kitchen Connect Portal</div>
                    <div className="LoginBtnWrapper">
                        <div id="loginBtnFormID">
                            <div className="loginButton">
                                <ul>
                                    <li>&nbsp;</li>
                                    <li>
                                        <div className="loginIWrapper">
                                            {/* Placeholder for future buttons or links */}
                                        </div>
                                    </li>
                                    <li>&nbsp;</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <ErrorModal 
                    open={error.open} 
                    message={error.message} 
                    onCloseModel={()=> setError({ open: false, message: "" })
                    } />
                </div>
            </div>
        </div>
    );
};

export default SSOLoginTenantRedirect;
