import React, { Component } from 'react';
import $ from 'jquery';
import { DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';
import KCStore from '../../utils/kcStorage';

class PaymentCard extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.chargifyForm = React.createRef();
        this.state = {
            token: '',
            envData: {},
            checkedValue: false
        };
    }

    handleSubmit = () => {
        return new Promise(async (res, rej) => {
            await this.chargify.token(
                this.chargifyForm.current,

                (token) => {
                    $('.cfy-container--inline.cfy-container--invalid').css('border-color', '#ddd')
                    res(this.setState({ token: token }));
                },

                (error) => {
                    this.props.throwError(error)
                    rej(error);
                }
            );
        })
    }

    getChargifyApiKey = () => {
        return new Promise((res, rej) => {
            let url = DeviceRegistration.getChargifyApiKey;

            http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {}
            }).then(async response => {
                if (response.data && response.data.length) {
                    const envIDValue = KCStore.getItem('subscriptionDetails') && JSON.parse(KCStore.getItem('subscriptionDetails'))?.env_id ? JSON.parse(KCStore.getItem('subscriptionDetails'))?.env_id : 1
                    const envId = response.data.find((i) => i.id === envIDValue);
                    this.setState({ envData: envId })
                    await this.loadKCUI(envId);
                    res(true);
                }
            }).catch(err => {
                res(false);
            });
        })
    }

    async loadKCUI(response) {
        const styleObj = {
            field: {
                width: `100%`
            },
            input: {
                width: '100%',
                padding: '5px 30px 5px 5px',
                lineHeight: 'inherit',
                background: 'transparent',
                color: '#0D3968',
                fontSize: '18px',
                border: '1px solid #0D3968',
                borderRadius: '5px',
            },
            label: {
                float: 'left',
                clear: 'both',
                color: '#0D3968',
                fontSize: '18px',
                fontFamily: 'sans-serif',
                fontWeight: 'unset'
            },
            message: { paddingTop: '2px', paddingBottom: '1px' }
        }

        await this.chargify.load({
            selector: '#chargify-form',
            publicKey: response.public_key,
            hideCardImage: true,
            type: 'card',
            serverHost: response.server_host,
            addressDropdowns: true,
            gatewayHandle: response?.payment_gateway?.credit_card?.handle,
            current_vault: response?.payment_gateway?.credit_card?.current_valut,
            fields: {
                number: {
                    selector: '#chargify1',
                    label: 'Card Number *',
                    required: true,
                    placeholder: 'xxxx xxxx xxxx xxxx',
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                firstName: {
                    selector: '#chargify5',
                    label: 'First Name *',
                    required: true,
                    placeholder: 'John',
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                lastName: {
                    selector: '#chargify6',
                    label: 'Last Name *',
                    required: true,
                    placeholder: 'Doe',
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                month: {
                    selector: '#chargify2',
                    label: 'Expiration Month *',
                    placeholder: 'MM',
                    required: true,
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                year: {
                    selector: '#chargify3',
                    label: 'Expiration Year *',
                    placeholder: 'YYYY',
                    required: true,
                    // message: 'This field is not valid. Please update it.',
                    style: styleObj
                },
                cvv: {
                    selector: '#chargify4',
                    label: 'CVV *',
                    placeholder: 'CVV',
                    // message: 'Invalid CVV',
                    required: true,
                    style: styleObj,
                    maxlength: '3',
                }             
            }
        });
        
        this.props.handleLoader(false);
        this.props.setIsLoaded(true);
        // $('.cfy-additional-label').html('')
    }

    async componentDidMount() {
        this.chargify = new window.Chargify();
        await this.getChargifyApiKey();
    }

    componentWillUnmount() {
        this.chargify.unload();
    }

    render() {
        return (
            <form ref={this.chargifyForm} className='payment-card-subscription'>
                <div id="chargify-form"></div>
                <div className="paymentForm">
                    <ul>
                        <li>
                            <div id="chargify1"></div>
                        </li>
                    </ul>
                </div>
                <div className="paymentForm">
                    <ul>
                        <li className="colm50 firstLastName">
                            <div id="chargify5"></div>
                        </li>
                        <li className="colm50 firstLastName">
                            <div id="chargify6"></div>
                        </li>
                    </ul>
                </div>
                <div className="paymentForm">
                    <ul>
                        <li className="colm50 firstLastName">
                            <div id="chargify2"></div>
                        </li>
                        <li className="colm50 firstLastName">
                            <div id="chargify3"></div>
                        </li>
                    </ul>
                    <ul>
                        <li className="colm50 firstLastName">
                            <div id="chargify4"></div>
                        </li>
                        <li className="colm50 firstLastName"></li>
                    </ul>
                </div>                
            </form>
        )
    }
}

export default PaymentCard;