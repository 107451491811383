import {HEATMAP_TABLE_REQUEST,HEATMAP_TABLE_SUCCESS,HEATMAP_TABLE_FAIL} from '../../../actions/reportManagement/heatMapTableAction';
const initialState = {
    loaded: false,
    loading: false,
    heatMapTableInformation: [],
    filterType: ''
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case HEATMAP_TABLE_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case HEATMAP_TABLE_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                heatMapTableInformation: action.heatMapTableInformation,
                filterType: action.filterType
            }
        case HEATMAP_TABLE_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgHeatMap: 'Server Error',

            }
        default:
            return state
    }
}