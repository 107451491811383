import {
    GET_DEVICE_CONNECTIVITY_STATUS, 
    GET_DEVICE_CONNECTIVITY_STATUS_FAIL,
    GET_DEVICE_CONNECTIVITY_STATUS_SUCCESS,
    CLEAR,
    BRAND_CHANGED,
    SERIAL_NUMBER_CHANGED
} from '../../../actions/connectivityTest/actionType';

const initialState = {
    serialNumber: '',
    brandName: '',
    loading: false,
    deviceStatus: '',
    showCaptcha: true,
    connectionSuccess: false,
    showButton: true

}
export default function (state = initialState, action) {
    switch (action.type) {
        case BRAND_CHANGED:
            return { ...state, loading: false, brandName: action.payload };
        case SERIAL_NUMBER_CHANGED:
            return { ...state, loading: false, serialNumber: action.payload };
        case GET_DEVICE_CONNECTIVITY_STATUS:
            return { ...state, loading: true };
        case GET_DEVICE_CONNECTIVITY_STATUS_SUCCESS:
            return { ...state, deviceStatus: action.payload, showCaptcha: action.payload === 'IMS2001' || 'IMS2002' || 'IMS4004' ? false: true, connectionSuccess:action.payload === 'IMS2001' ? true: false, showButton: action.payload === 'IMS2001' ? false : true,  loading: false };
        case GET_DEVICE_CONNECTIVITY_STATUS_FAIL:
            return { ...state, loading: false };
        case CLEAR:
            return { ...state, showCaptcha: false, deviceStatus: '', connectionSuccess: false, showButton: true, brandName: '', serialNumber: '' }
        default:
            return state;
    }
}
