import axios from 'axios';
import api from '../../service/api';
export const MEDIA_LIBRARY_REQUEST = 'MEDIA_LIBRARY_REQUEST';
export const MEDIA_LIBRARY_SUCCESS = 'MEDIA_LIBRARY_SUCCESS';
export const MEDIA_LIBRARY_FAIL = 'MEDIA_LIBRARY_FAIL';

export function getMediaLibrary(req) {
    return dispatch => {
        dispatch({
            type: MEDIA_LIBRARY_REQUEST
        })
        axios
            .all([getMediaLibraryData(), getMediaSpaceData()])
            .then(axios.spread(function (mediaInfo, spaceInfo) {
                dispatch({
                    type: MEDIA_LIBRARY_SUCCESS,
                    mediaLibraryInformation: mediaInfo.data.body,
                    mediaSpaceInformation: spaceInfo.data.spaceDetails
                })
            }))
            .catch(error => {
                dispatch({
                    type: MEDIA_LIBRARY_FAIL,
                    error
                })
            })
    }
}

function getMediaLibraryData() {
    return axios.get(api.mediaMgmtInfoService);
}

function getMediaSpaceData() {
    return axios.get(api.mediaMgmtFileSpace);
}