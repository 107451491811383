import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import Switch from 'react-switch';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';
import $ from 'jquery';

class Cleaning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            clean_ice_enabled: "",
            clean_protector_enabled: "",
            clean_signoff_enabled: ""
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onModesSelect = this.onModesSelect.bind(this);
        this.onLockTypeSelect = this.onLockTypeSelect.bind(this);
        this.onCleanIceEnabledSelect = this.onCleanIceEnabledSelect.bind(this);
        this.onCleanProtectorEnabledSelect = this.onCleanProtectorEnabledSelect.bind(this);
        this.onProvideCleaningSignOff = this.onProvideCleaningSignOff.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let jsonCleaning = JSON.parse(jsonSettingsFile);

            this.setState({
                clean_ice_enabled: jsonCleaning.clean_ice_enabled,
                clean_protector_enabled: jsonCleaning.clean_protector_enabled,
                clean_signoff_enabled: jsonCleaning.clean_signoff_enabled
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/cleaning"
        });
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true
        });
        let { clean_ice_enabled, clean_protector_enabled, clean_signoff_enabled } = this.state;
        let jsonSettingsFile = KCStore.getItem("librarySettingsData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        ObjSettingsFile.clean_ice_enabled = clean_ice_enabled;
        ObjSettingsFile.clean_protector_enabled = clean_protector_enabled;
        ObjSettingsFile.clean_signoff_enabled = clean_signoff_enabled;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("librarySettingsData", strSettingsFile);
        this.props.history.push(`/settingsEditor`);
    }

    onModesSelect = (event) => {
        this.setState({
            mode: event.target.value
        });
    }

    onLockTypeSelect = (event) => {
        this.setState({
            locked: event.target.value
        });
    }

    onCleanIceEnabledSelect = (e) => {
        this.setState({
            clean_ice_enabled: e.toString()
        });
    }

    onCleanProtectorEnabledSelect = (e) => {
        this.setState({
            clean_protector_enabled: e.toString()
        });
    }

    onProvideCleaningSignOff = (e) => {
        this.setState({
            clean_signoff_enabled: e.toString()
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    render() {
        let { clean_ice_enabled, clean_protector_enabled, clean_signoff_enabled, success_modal } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1316"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            <div class="rcSettingsList02">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC1317"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onCleanIceEnabledSelect(e)}
                                                                                checked={clean_ice_enabled === "true" ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcSettingsList02">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC1318"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onCleanProtectorEnabledSelect(e)}
                                                                                checked={clean_protector_enabled === "true" ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcSettingsList02">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC1319"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onProvideCleaningSignOff(e)}
                                                                                checked={clean_signoff_enabled === "true" ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn btn-default-text confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(Cleaning)
