
import { dashboardRenderer } from '../../service/api';
import http from '../../service/httpService';
import configData from './data.json';
import queryString from 'query-string';


export const getUnitStatusDetails = (url, payload) => {
    return new Promise((resolve, rej) => {
        http.post(url, payload).then(unitStatusResponse => {
            if (unitStatusResponse.data?.data?.data?.shadow?.CONNECT_STATE) {
                resolve(unitStatusResponse.data.data.data.shadow.CONNECT_STATE);
            } else {
                resolve(unitStatusResponse);
            }
        }).catch(err => {
            rej(err);
        });
    })
}

export const establishDiagnosticConnectionEvent = (payload) => {
    return new Promise((resolve, rej) => {
        http.post(dashboardRenderer.diagnosticEventAction, payload).then(response => {
            resolve(response);
        }).catch(err => {
            rej(err);
        });
    })
}

export const getDiagnosticConnectionDetails = (SerialNo) => {
    return new Promise((resolve, rej) => {
        http.get(dashboardRenderer.diagnosticDeviceStatus + SerialNo, {
            headers: {
                'Content-Type': 'application/json',
            },
            data: {}
        }).then(diagnosticResponse => {
            resolve(diagnosticResponse);
        }).catch(err => {
            rej(err);
        });
    })
}



export const getDiagnosticConfigurationDetails = (unitDetails) => {
    return new Promise((resolve, rej) => {
        // resolve(configData)
        if(!unitDetails || !Object.keys(unitDetails).length){
            resolve({});
            return;
        }
        let url = `${dashboardRenderer.getLiveDiagnosticConfiguration}`;
        const stringified = queryString.stringify({
            "modelFamily": unitDetails.MODEL_FAMILY_NAME,
            "model": unitDetails.MODEL_NAME,
            "brand": unitDetails.BRAND_NAME
        });
        url =  url + (stringified ? `?${stringified}` : '');
        http.get(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {}
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            resolve(configData)
            // rej(err);
        });
    })
}