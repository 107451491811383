import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BaseService, WarrantyAPI } from '../../../service/api';
import http from '../../../service/httpService';
import UnitManagementInfo from './widgetconstants';
import { getDeviceAPIUrl, getDeviceInfo } from '../../../service/unitManagement/deviceinfo';
import { getSteamTemperature } from '../../../service/unitManagement/locationinfo';
import * as moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';

import KCStore from '../../../utils/kcStorage';

class WarrantyWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPercentageUsed: 0,
			currentPercentageUsed_width: { width: "0%" },
			loaderIconVisible: false,
			warrantyResponseData: [],
			deviceInformation: {},
			steamValue: 0
		};

	}

	componentDidMount = async () => {
		const unitID = KCStore.getItem("unitID")
		const brandID = KCStore.getItem("unitBrandId");
		//CREM Expobar Model(EX3 DISPLAY & DIAMANT PRO DISPLAY) New Widget Of Hardware Parameters
		const brandName = KCStore.getItem("brandName");
		let deviceUrl = getDeviceAPIUrl(unitID, brandName);
		let deviceInfo = await getDeviceInfo(deviceUrl);
		let steamTempInfo = await getSteamTemperature(unitID);
		
		if (deviceInfo) {
			this.setState({
				deviceInformation: deviceInfo
			});
		}
		if (steamTempInfo) {
			this.setState({ steamValue : steamTempInfo });
		}
		this.getWarrantyinformations(unitID, brandID);
	}

	getWarrantyinformations = (unitVal, brandVal) => {
		const unitId = unitVal, brandId = brandVal;
		let header = {
			'Content-Type': 'application/json',
		}
		http({
			method: "GET",
			url: `${BaseService.root}${WarrantyAPI.Warrantyinformations}?unitId=${unitId}&brandId=${brandId}`,
			headers: header,
			data: {},
		}).then(response => {
			this.setState({ warrantyResponseData: response.data, loaderIconVisible: false });
			const progVal = response.data.graphData.value;
			this.setState({
				currentPercentageUsed_width: { width: `${progVal}%`, background: response.data.graphData.color }
			});
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
			console.log('catch exception occured');
		});
	};
	
	
	render() {
		let { currentPercentageUsed, currentPercentageUsed_width, warrantyResponseData, steamValue } = this.state;
		const { formatMessage } = this.props.intl;
		let { deviceInformation } = this.state;
		const unitMngInfo = UnitManagementInfo;
		const brandName = KCStore.getItem("brandName");
		const expobarProductName = KCStore.getItem("expobarModelName");
		let hardwareInfo = unitMngInfo[brandName].hardwareInfo;
		let steamTempValue = unitMngInfo[brandName].steamTemperatureInfo;
		let pageRender;		
		if (KCStore.getItem("brandName") === "FRYMASTER TEST") {
			pageRender = (
				<div class={!this.props.page ? "colm6D colm6D2" : ""}>
					<div class="colmDi">
						<h4><FormattedMessage id="KC0497"/></h4>
						<div class="colmDiVal">
							<div class="progress">
								<div role="progressbar" title="Warranty"
									style={currentPercentageUsed_width} aria-valuenow={currentPercentageUsed} aria-valuemin="0" aria-valuemax="100"></div>
							</div>
							{warrantyResponseData.warrantCount > 0 ? <div class="progressBarBText">{warrantyResponseData.warrantCount} <FormattedMessage id="KC1818"/></div> : ""}  
							<div class="gridTableView">
								<ul>
									<li>
										<ul>
											<li>
												<label><FormattedMessage id="KC1819"/></label>
												{warrantyResponseData.WARRANTY_START && warrantyResponseData.WARRANTY_START !== 'N/A' ? <span class="labelVal"> {moment(warrantyResponseData.WARRANTY_START).format('LL')}</span> : ""}
											</li>
											<li>
												<label><FormattedMessage id="KC1820"/></label>
												{warrantyResponseData.WARRANTY_END && warrantyResponseData.WARRANTY_END !== 'N/A' ? <span class="labelVal">{moment(warrantyResponseData.WARRANTY_END).format('LL')}</span> : ""}
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>);
		}
		//CREM Expobar Model(EX3 DISPLAY & DIAMANT PRO DISPLAY) New Widget Of Hardware Parameters
		else if((this.props.page !== "SeriveManagement") && brandName === "CREM" && (expobarProductName === "EX3" || expobarProductName === "DIAMANT PRO")){
			pageRender = ( <div class="colm6D colm6D2">
			<div class="colmDi">
				<h4><FormattedMessage id="KC1821"/></h4>
				<div class="colmDiVal">
					<div class="gridTableView gridTableViewSW">
								<ul>
									<li>
										<ul>
				{hardwareInfo.map(el => {
					let value = null;
					if (deviceInformation && deviceInformation.length > 0) {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : "N/A";
					}
					else {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : "N/A";
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{(value === "" || value === undefined || value === null) ? <FormattedMessage id="KC1664"/> : value}</span>
						</li>
					)
				})}
				{steamTempValue[0].visible ? <li>
					<label>{steamTempValue[0].stringID ? <FormattedMessage id={steamTempValue[0].stringID}/> : steamTempValue[0].fieldname}</label>												
					<span class="labelVal">{steamValue?steamValue+' '+ formatMessage({ id: 'KC1874' }): 0+' '+ formatMessage({ id: 'KC1874' })}</span>  
				</li> : "0%" }
				</ul> 
				</li> 
                                        </ul>
                            </div>

                        </div>
                    </div>
                </div>)
		}
		else {
			pageRender = (
				<div class={(this.props.page === "SeriveManagement") ? `${this.props.classValue}`+" disabled" :"colm6D colm6D2 disabled"}>
					<div class="colmDi">
						<h4><FormattedMessage id="KC0497"/></h4>
					</div>
				</div>);
		}		
		return (
			<React.Fragment>
				{pageRender}				
			</React.Fragment>
		)
	}
};
export default injectIntl(WarrantyWidget); 
