import jsonata from 'jsonata';
import { jsonataCustomFunctions } from './configuration-compiler-helper';

/**
 * IMPORTANT: This file can be distributed across microservices and UI application.
 * So keep this file standalone, do not import any other modules.
 * Declare all dependencies here itself
 */

const configurationExpressionDelimiter = {
    start: '{{',
    end: '}}',
    metadata: '|',
    metadataKeyValue: ':'
};
const ConfigurationExpressionDataType = {
    Number: 'number',
    String: 'string',
    Boolean: 'boolean',
    Date: 'date'
};
const ConfigurationExpressionMetadataName = {
    Expression: 'expr',
    DataType: 'dataType'
};
const configurationExpressionRegex = /\{{.*?\}}/g;

/**
 * Decode metadata for the given expression
 * @param {*} expr Expression
 * @returns Expression metadata
 */
const decodeExpression = (expr) => {
    const ret = {
        expression: '',
        dataType: ''
    };

    // Remove expression delimiter and extract metadata
    const _expr = expr
        .replace(configurationExpressionDelimiter.start, '')
        .replace(configurationExpressionDelimiter.end, '').trim();
    if (!_expr) {
        return ret;
    }
    let exprMetadataArray = _expr.split(configurationExpressionDelimiter.metadata);
    exprMetadataArray = exprMetadataArray.map((item) => item.trim());

    // Extract actual expression
    if (exprMetadataArray.length) {
        const exprMetadata = exprMetadataArray.shift();
        let exprMetadataKeyValue = exprMetadata.split(configurationExpressionDelimiter.metadataKeyValue);
        exprMetadataKeyValue = exprMetadataKeyValue.map((item) => item.trim());
        if (exprMetadataKeyValue[0] === ConfigurationExpressionMetadataName.Expression) {
            exprMetadataKeyValue.shift();
        }
        ret.expression = exprMetadataKeyValue.join(configurationExpressionDelimiter.metadataKeyValue);
    }

    // Extract other metadata
    for (const exprMetadata of exprMetadataArray) {
        let metadataKeyValue = exprMetadata.split(configurationExpressionDelimiter.metadataKeyValue);
        metadataKeyValue = metadataKeyValue.map((item) => item.trim());
        const metadataName = metadataKeyValue[0];
        metadataKeyValue.shift();
        ret[metadataName] = metadataKeyValue.join(configurationExpressionDelimiter.metadataKeyValue);
    }
    return ret;
};

/**
 * Register custom function on the given expression
 * @param {*} expression jsonata expression object
 */
const registerCustomFunctions = (expression) => {
    for (let func of jsonataCustomFunctions) {
        expression.registerFunction(...[func.name, func.fn, func.signature].filter(Boolean));
    }
};

/**
 * Compile the given expression
 * @param {*} expr Expression to compile
 * @param {*} data Data for evaluation
 * @returns Compiled value
 */
const compileExpression = async (expr, data) => {
    // Decode expression metadata
    const exprMetadata = decodeExpression(expr);

    // If expression data is falsy, then return original expression
    const exprPrefix = exprMetadata.expression.split('.')[0];
    if (exprPrefix.startsWith('_') && !data[exprPrefix]) {
        return expr;
    }

    // Compile expression
    let result;
    try {
        const expression = jsonata(exprMetadata.expression);
        registerCustomFunctions(expression);
        result = await expression.evaluate(data);
    } catch (error) {
        console.error('Error in JSONata evaluate', error);
        // If error occurs during expression compile, then return original expression
        return expr;
    }

    // If expression result is undefined, then return original expression
    if (result === undefined) {
        return expr;
    }

    // Transform expression result's data type. Only transform when the result has primitive value
    if ((result !== undefined && result !== null) && exprMetadata.dataType && typeof result !== 'function' && typeof result !== 'object') {
        switch (exprMetadata.dataType) {
            case ConfigurationExpressionDataType.Number:
                result = +result;
                break;
            case ConfigurationExpressionDataType.String:
                result = result.toString();
                break;
            case ConfigurationExpressionDataType.Boolean:
                result = !!result;
                break;
            case ConfigurationExpressionDataType.Date:
                result = new Date(result);
                break;
            default:
                break;
        }
    }
    return result;
};

/**
 * Compile string that contain expression
 * @param {*} str String to compile
 * @param {*} data Data for evaluation
 * @param {*} options Compile options
 * @returns Compiled string
 */
const compileString = async (str, data, options) => {
    // Extract expressions
    const matches = str.match(configurationExpressionRegex);
    if (!matches) {
        return str;
    }

    if (matches.length === 1) {
        // If the string has one expression
        let result = await compileExpression(matches[0], data);
        result = (typeof result === 'function' && options.executeFunction ? result(data) : result);
        if (str !== matches[0]) {
            // If the expression is subset of string, then replace expression with result
            return str.replace(matches[0], result);
        }
        // If the expression equals string, then replace string with result
        return result;
    } else {
        // If the string has multiple expression
        const uniqueMatches = [...new Set(matches)];
        for (let match of uniqueMatches) {
            let result = await compileExpression(match, data);
            result = (typeof result === 'function' && options.executeFunction ? result(data) : result);
            str = str.replaceAll(match, result);
        }
        return str;
    }
};

/**
 * Traverse configuration and compile expression
 * @param {*} configuration Configuration object
 * @param {*} data Data for evaluation
 * @param {*} options Compile options
 * @returns Compiled object
 */
const traverseAndCompileConfiguration = async (configuration, data, options) => {
    if (Array.isArray(configuration)) {
        for (let i = 0; i < configuration.length; i++) {
            if (typeof configuration[i] === 'string') {
                try {
                    const parsedObj = JSON.parse(configuration[i]);
                    if (typeof parsedObj === 'object') {
                        // If it has a json string, then traverse again
                        configuration[i] = JSON.stringify(await traverseAndCompileConfiguration(parsedObj, data, options));
                    }
                } catch (err) {
                    // If it has a string, then compile it
                    configuration[i] = await compileString(configuration[i], data, options);
                }
            } else {
                // If its not a string, then traverse again
                configuration[i] = await traverseAndCompileConfiguration(configuration[i], data, options);
            }
        }
    } else if (typeof configuration === 'object') {
        for (let key in configuration) {
            if (typeof configuration[key] === 'string') {
                try {
                    const parsedObj = JSON.parse(configuration[key]);
                    if (typeof parsedObj === 'object') {
                        // If it has a json string, then traverse again
                        configuration[key] = JSON.stringify(await traverseAndCompileConfiguration(parsedObj, data, options));
                    }
                } catch (err) {
                    // If it has a string, then compile it
                    configuration[key] = await compileString(configuration[key], data, options);
                }
            } else {
                // If its not a string, then traverse again
                configuration[key] = await traverseAndCompileConfiguration(configuration[key], data, options);
            }
        }
    }
    return configuration;
};

/**
 * Compile configuration
 * @param {*} configuration Configuration object
 * @param {*} data Data for evaluation
 * @param {*} options Compile options
 * @returns Compiled object
 */
const compileConfiguration = async (configuration, data, options) => {
    const result = await traverseAndCompileConfiguration(JSON.parse(JSON.stringify(configuration)), data, options);
    return result;
};

export {
    configurationExpressionDelimiter,
    ConfigurationExpressionDataType,
    ConfigurationExpressionMetadataName,
    configurationExpressionRegex,
    decodeExpression,
    compileExpression,
    compileString,
    traverseAndCompileConfiguration,
    compileConfiguration
};
