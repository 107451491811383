import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import Switch from 'react-switch';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import  MediaListPopup  from '../../mediaListPopup';
import http from '../../../../../service/httpService';
import axios from 'axios';
import { MenuUploadAPI, BaseService, MediaManagementAPI } from '../../../../../service/api';
import { CUSTOMER_ID } from '../../../../../utils/appConstants';
import $ from 'jquery';
import { Cropper } from '../../../../controls/cropper';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'react-image-crop/dist/ReactCrop.css';
import Jimp from 'jimp';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { nanoid } from 'nanoid';
import KCStore from '../../../../../utils/kcStorage';
var request = require('request');

class Screensaver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            screen_saver_enabled: "false",
            screen_saver_time: 1,
            screen_saver_picture: "Resources/Images/Welbiltlogo.png",
            visual_warning_enabled: "false",
            srcfile: {},
            importOption:"Library",
            cropWidth: 100,
            cropHeight: 100
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onScreensaverEnabledSelect = this.onScreensaverEnabledSelect.bind(this);
        this.onVisualWarningEnabledSelect = this.onVisualWarningEnabledSelect.bind(this);
        this.handleScreensaverTimeChange = this.handleScreensaverTimeChange.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
        this.onChangeScreensaverClick = this.onChangeScreensaverClick.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.closeCropPopup = this.closeCropPopup.bind(this);
        this.cropSubmit = this.cropSubmit.bind(this);
        this.onImportTypeSelect = this.onImportTypeSelect.bind(this);
        this.cancelImportClick = this.cancelImportClick.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $('.mCustomScrollbar').mCustomScrollbar();
        $('#ImportButton').on('click', function (e) {
            $('#fileInput').trigger('click');
            $('#ConfirmationOfImportModal').modal('hide');
        });

        try {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            let jsonlibraryDetails = KCStore.getItem("libraryDetails");
            let libraryDetails = JSON.parse(jsonlibraryDetails);

            let libraryImage = KCStore.getItem("libraryImage");
            let imageDisplayFormatLocal = 'image/png';
            let fileNameDisplay = KCStore.getItem("libraryScreenSaverExt");
            if (fileNameDisplay)
                imageDisplayFormatLocal = fileNameDisplay.toLowerCase() === "svg" ? "image/svg+xml" : imageDisplayFormatLocal;

            this.setState({
                visual_warning_enabled: ObjSettingsFile.visual_warning_enabled,
                screen_saver_enabled: ObjSettingsFile.screen_saver.enabled,
                screen_saver_time: ObjSettingsFile.screen_saver.time,
                screen_saver_picture: ObjSettingsFile.screen_saver.picture,
                imageDataBuffer: ObjSettingsFile.screen_saver.picture ? Buffer.from(libraryImage, "base64") : null,
                imageDatab64: ObjSettingsFile.screen_saver.picture ? libraryImage : "",
                libraryDetails: libraryDetails,
                imageDisplayFormat: imageDisplayFormatLocal
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                loaderIconVisible: true
            });
            let libraryDetails = {};
            let fileSize = event.target.files[0].size;
            fileSize = (fileSize / 1048576).toFixed(2);

            let fileName = event.target.files[0].name;
            // fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');            
            let fileext =  fileName.substring(fileName.lastIndexOf('.') + 1);

            libraryDetails.type = "image";
            libraryDetails.fileName = fileName;
            libraryDetails.fileFormat = fileext;
            libraryDetails.fileSize = fileSize;
            libraryDetails.s3Url = "";
            libraryDetails.fileType = "screensaver";
            libraryDetails.isDefault = false;

            var FileUploadThumbnail = require('../../../../../utils/fileThumnailProcesser');
            let md5 = require('md5');

            if (fileSize) {
                let resolutionofimageVideo = 0;
                var width;
                var height;
                let srcfile = event.target.files[0];
                if (fileext === "png" || fileext === "PNG" || fileext === "JPEG" || fileext === "jpeg" || fileext === "JPG" || fileext === "jpg" || fileext === "svg" || fileext === "SVG" || fileext === "gif" || fileext === "GIF" || fileext === "bmp" || fileext === "BMP") {
                    var reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = e.target.result;
                        let imageDataBuffer = Buffer.from(e.target.result.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
                        libraryDetails.crc = md5(imageDataBuffer, "base64");
                        image.onload = (img) => {
                            width = img.target.width;
                            height = img.target.height;
                            resolutionofimageVideo = width + " x " + height;
                            //User Story 86440: MRE - Uploading a picture of minimum 100*100 pixels size in Category/Sub-Category/Recipes - Connex supports all sizes above or below 100*100 pixels.
                            if ((width > 100 || height > 100) && (fileext !== "svg" && fileext !== "SVG" && fileext !== "gif" && fileext !== "GIF" && fileext !== "bmp" && fileext !== "BMP")) {
								this.setState({
									loaderIconVisible: false,
									showCropPopup:true,
									src: reader.result,
                                    cropWidth: width < height ? width : height,
									cropHeight: width < height ? width : height,
									libraryDetails: libraryDetails,
								});
								$('#popupTrigger').trigger('click');
							}
                            else {
                                if (srcfile) {
                                    let image = new FileUploadThumbnail({
                                        maxWidth: 400,
                                        maxHeight: 400,
                                        file: srcfile,
                                        onSuccess: (src) => {
                                            src = src.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                            let imageDataBuffer = Buffer.from(src, "base64");
                                            //libraryDetails.s3Url = imageDataBuffer;
                                            libraryDetails.isChanged = true;
                                            libraryDetails.fileName = libraryDetails.fileName.split('.')[0] + '.png';
                                            libraryDetails.fileFormat = 'png';
                                            let jsonlibraryDetails = KCStore.getItem("libraryDetails");
                                            let ObjlibraryDetails = JSON.parse(jsonlibraryDetails);
                                            var foundIndex = ObjlibraryDetails.settings.findIndex(x => x.fileType == 'screensaver');
                                            ObjlibraryDetails.settings[foundIndex] = libraryDetails;

                                            KCStore.setItem("libraryImage", src);
                                            KCStore.setItem("libraryScreenSaverExt", 'png');
                                            KCStore.setItem("libraryDetails", JSON.stringify(ObjlibraryDetails));
                                            this.setState({
                                                loaderIconVisible: false,
                                                imageDataBuffer: imageDataBuffer,
                                                imageDatab64: src,
                                                libraryDetails: libraryDetails,
                                                screen_saver_picture: "Resources/Images/" + fileName,
                                                imageDisplayFormat: 'image/png'
                                            });
                                        },
                                        onError: function (err) {
                                            this.setState({
                                                loaderIconVisible: false
                                            });
                                        }
                                    }).createThumbnail();
                                }
                            }
                        };
                    }
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "KC1225"
                        }
                    });
                }
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1249"
                    }
                });
            }
            event.target.value = '';
        }
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/cleaning"
        });
    }

    onConfirmButtonClick = (page) => {
        this.setState({
            loaderIconVisible: true
        });
        let { visual_warning_enabled, screen_saver_enabled, screen_saver_time, screen_saver_picture } = this.state;
        let screensavertime = parseInt(screen_saver_time);
        if ((!screen_saver_time || screensavertime < 1 || screensavertime > 20) && screen_saver_enabled === "true") {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1320"
                }
            });
        }
        else {
            try {
                let jsonSettingsFile = KCStore.getItem("librarySettingsData");
                let ObjSettingsFile = JSON.parse(jsonSettingsFile);
                ObjSettingsFile.visual_warning_enabled = visual_warning_enabled;
                ObjSettingsFile.screen_saver.enabled = screen_saver_enabled;
                ObjSettingsFile.screen_saver.time = screen_saver_time ? parseInt(screen_saver_time) : 1;
                ObjSettingsFile.screen_saver.picture = screen_saver_picture;
                let strSettingsFile = JSON.stringify(ObjSettingsFile);
                KCStore.setItem("librarySettingsData", strSettingsFile);

                let jsonlibraryDetails = KCStore.getItem("libraryDetails");
                let ObjlibraryDetails = JSON.parse(jsonlibraryDetails);
                let changedFile = ObjlibraryDetails.settings.find(a => a.isDefault === false && a.fileType === "screensaver");
                if (changedFile && changedFile.isChanged && screen_saver_enabled === "true") {
                    // let imagefilekey = changedFile.fileName.replace('.', '').replace(changedFile.fileFormat, '') + '_' + Date.now() + '.' + changedFile.fileFormat.replace('.','');
                    let imagefilekey = nanoid() + '_' + Date.now()+ '.' + changedFile.fileFormat.replace('.','');
                    let headerpresigned = {
                        'Content-Type': 'application/json',
                        'customerid': CUSTOMER_ID,
                        'imagefilekey': encodeURIComponent(imagefilekey),
                        'jsonfilekey': '',
                        'audiofilekey': '',
                        'isthumbnail': false
                    }
                    if (KCStore.getItem("brandName") !== "MERRYCHEF"){
                        delete headerpresigned.encryption;
                        headerpresigned.imagefilekey = imagefilekey;
                    }
    
                    return new Promise((resolve, reject) => {
                        let token = KCStore.getItem('Access-Token');
                        headerpresigned.Authorization = token;
                        axios({
                            url: BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl,
                            method: "GET",
                            headers: headerpresigned,
                            data: {}
                        }).then(response => {
                            let { imagePresignedURL } = response.data.presignedURLs
        
                            let UploadedImages3url = "";
                            if (imagePresignedURL) {
                                UploadedImages3url = imagePresignedURL.split('?');
                                UploadedImages3url = UploadedImages3url[0];
                            }
                            var xhrimage = new XMLHttpRequest();
                            xhrimage.open("PUT", imagePresignedURL);
                            xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
                            xhrimage.onloadend = (res) => {
                                if (res.target.status == 200) {
                                    var foundIndex = ObjlibraryDetails.settings.findIndex(x => x.fileType === changedFile.fileType);
                                    changedFile.s3Url = UploadedImages3url;
                                    changedFile.s3FileName = imagefilekey;
                                    changedFile.isChanged = false;
                                    ObjlibraryDetails.settings[foundIndex] = changedFile;
                                    KCStore.setItem("libraryDetails", JSON.stringify(ObjlibraryDetails));
                                    let libraryData = KCStore.getItem("librarySettingsData");
                                    let libraryDataObj = JSON.parse(libraryData);
                                    libraryDataObj.sounds = ObjlibraryDetails.settings;
                                    KCStore.setItem("librarySettingsData", JSON.stringify(libraryDataObj));
                                    this.props.history.push(`/`+page);
                                }
                            };
                            xhrimage.onerror = (err) => {
                                reject(err);
                            };
                            xhrimage.send(this.state.imageDataBuffer);
                        }).catch(err => {
                            this.setState({
                                loaderIconVisible: false
                            });
                            reject(err);
                        });
                    });
                }
                else {
                    this.props.history.push(`/`+page);
                    this.setState({
                        loaderIconVisible: false
                    });
                }
            }
            catch (error) {
                this.setState({
                    loaderIconVisible: false
                });
            }
        }
    }

    handleInputClick = (e) => {
        if (this.state.importOption === "Library") {
            $('#PhotoLibraryModal').modal('show');
            $('#ConfirmationOfImportModal').modal('hide');
        }
        else {
            this.inputElement.click();
            $('#ConfirmationOfImportModal').modal('hide');
        }
    }

    onLockTypeSelect = (e) => {
        this.setState({
            locked: e
        });
    }

    onScreensaverEnabledSelect = (e) => {
        if(e){
            this.setState({
                screen_saver_enabled: e.toString(),
                screen_saver_time: 1,
                imageDatab64: null
            });
        }else{
            this.setState({
                screen_saver_enabled: e.toString(),
            });
        }
    }

    onVisualWarningEnabledSelect = (e) => {
        this.setState({
            visual_warning_enabled: e.toString()
        });
    }

    handleScreensaverTimeChange = (e) => {
        this.setState({ 
            screen_saver_time: e.target.value 
        })
    }

    onChangeScreensaverClick = () => {
        this.props.history.push(`/changeScreensaver`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    closeCropPopup = () => {
		this.setState({
			showCropPopup: false
		});
    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    async OnImport(item) {
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
        try {
            let libraryDetails = {};
            libraryDetails.type = "image";
            libraryDetails.fileName = item.FILE_NAME;
            libraryDetails.fileFormat = item.FILE_FORMAT ? item.FILE_FORMAT.replace('.','') : '';
            libraryDetails.fileSize = item.FILE_SIZE;
            libraryDetails.s3Url = "";
            libraryDetails.fileType = "screensaver";
            libraryDetails.isDefault = false;
            if(item.FILE_FORMAT === '.svg'){
                var res = await fetch(item.thumbUrl);
                var blob = await res.blob();
                let thumbsrc = await new Promise((resolve, reject) => {
                  var reader  = new FileReader();
                  reader.addEventListener("load", function () {
                      resolve(reader.result);
                  }, false);
                  reader.onerror = () => {
                    return reject(this);
                  };
                  reader.readAsDataURL(blob);
                })
                let imgBuffer =  this.base64ToArrayBuffer(thumbsrc.replace("data:multipart/form-data;base64,", ""))

                let src = thumbsrc.replace("data:multipart/form-data;base64,", "");
                    libraryDetails.isChanged = true;

                    let jsonlibraryDetails = KCStore.getItem("libraryDetails");
                    let ObjlibraryDetails = JSON.parse(jsonlibraryDetails);
                    var foundIndex = ObjlibraryDetails.settings.findIndex(x => x.fileType == 'screensaver');
                    ObjlibraryDetails.settings[foundIndex] = libraryDetails;

                    KCStore.setItem("libraryImage", src);
                    KCStore.setItem("libraryScreenSaverExt", 'png');
                    KCStore.setItem("libraryDetails", JSON.stringify(ObjlibraryDetails));
                    this.setState({
                        imageDataBuffer: imgBuffer,
                        imageDatab64: src,
                        libraryDetails: libraryDetails,
                        screen_saver_picture: "Resources/Images/" + item.FILE_NAME,
                        loaderIconVisible: false,
                        imageDisplayFormat: 'image/png'
                    });
            }else{

            await http.get(`${MediaManagementAPI.getMediamediapreview}` + item.MEDIA_ID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'type': item.TYPE
                    },
                    data: {}
                })
                .then(response => {
                    request.get({ url: response.data.imageUrl, encoding: null }, (err, res, body) => {
                    let src = body.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                    libraryDetails.isChanged = true;

                    let jsonlibraryDetails = KCStore.getItem("libraryDetails");
                    let ObjlibraryDetails = JSON.parse(jsonlibraryDetails);
                    var foundIndex = ObjlibraryDetails.settings.findIndex(x => x.fileType == 'screensaver');
                    ObjlibraryDetails.settings[foundIndex] = libraryDetails;

                    KCStore.setItem("libraryImage", src);
                    KCStore.setItem("libraryScreenSaverExt", 'png');
                    KCStore.setItem("libraryDetails", JSON.stringify(ObjlibraryDetails));
                    this.setState({
                        imageDataBuffer: body,
                        imageDatab64: src,
                        libraryDetails: libraryDetails,
                        screen_saver_picture: "Resources/Images/" + item.FILE_NAME,
                        loaderIconVisible: false,
                        imageDisplayFormat: 'image/png'
                    });
                    })
                    .catch(err => {
                        // Handle an exception.
                    });
                })
                .catch(ex => {
                    console.log(ex);
                    _this.setState({
                        loaderIconVisible: false
                    });
                })
            }
        }
        catch (ex) {
            console.log(ex);
            _this.setState({
                loaderIconVisible: false
            });
        }
    }

    async cropSubmit(croppedImage) {
        $('#imageResizeModal').modal('hide');
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
        
        let croppedImageType = croppedImage.type.toLowerCase() === "jpg" ? "jpeg" : croppedImage.type.toLowerCase();
        Jimp.read(croppedImage.data)
        .then(image => {
            image.resize(400, 400, (err, image) => {
                image.getBufferAsync('image/' + croppedImageType).then(imagebuffer => {
                    image.getBase64Async('image/' + croppedImageType).then(imageb64 => {
                        let fileSize = Buffer.byteLength(imagebuffer);
                        fileSize = (fileSize / 1048576).toFixed(2);
                        let libraryDetails = {};
                        libraryDetails.type = "image";
                        libraryDetails.fileName = croppedImage.name;
                        libraryDetails.fileFormat = croppedImageType ? croppedImageType.replace('image/','') : 'png';
                        libraryDetails.fileSize = fileSize;
                        libraryDetails.s3Url = "";
                        libraryDetails.fileType = "screensaver";
                        libraryDetails.isDefault = false;
                        libraryDetails.isChanged = true;

                        let jsonlibraryDetails = KCStore.getItem("libraryDetails");
                        let ObjlibraryDetails = JSON.parse(jsonlibraryDetails);
                        var foundIndex = ObjlibraryDetails.settings.findIndex(x => x.fileType == 'screensaver');
                        ObjlibraryDetails.settings[foundIndex] = libraryDetails;

                        KCStore.setItem("libraryImage", imageb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
                        KCStore.setItem("libraryScreenSaverExt", 'png');
                        KCStore.setItem("libraryDetails", JSON.stringify(ObjlibraryDetails));

                        _this.setState({
                            imageDataBuffer: imagebuffer,
                            imageDatab64: imageb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                            libraryDetails: libraryDetails,
                            screen_saver_picture: "Resources/Images/" + croppedImage.name,
                            loaderIconVisible: false,
                            imageDisplayFormat: 'image/png'
                        });
                    })
                })
            })            
        })
        .catch(err => {
            console.log(err);
            _this.setState({
                loaderIconVisible: false
            });
        })
    }
    
    onImportTypeSelect = (event) => {
        this.setState({
            importOption: event.target.value
        });
    }

    cancelImportClick = (event) => {
        $('#ConfirmationOfImportModal').modal('hide');
    }

    onClearImage (){
        let libraryDetails = {};
        libraryDetails.type = "image";
        libraryDetails.fileName = "Welbiltlogo.png";
        libraryDetails.fileFormat = ".png";
        libraryDetails.fileSize = "0.021084";
        libraryDetails.s3Url = "https://test-welbilt-kitchenconnect-file-management-ireland-test.s3.eu-west-1.amazonaws.com/Menu/Library/Settings/Welbiltlogo.png";
        libraryDetails.fileType = "screensaver";
        libraryDetails.isDefault = false;
        libraryDetails.isChanged = true;

        let jsonlibraryDetails = KCStore.getItem("libraryDetails");
        let ObjlibraryDetails = JSON.parse(jsonlibraryDetails);
        var foundIndex = ObjlibraryDetails.settings.findIndex(x => x.fileType == 'screensaver');
        ObjlibraryDetails.settings[foundIndex] = libraryDetails;

        KCStore.setItem("libraryImage", "");
        KCStore.setItem("libraryScreenSaverExt", "");
        KCStore.setItem("libraryDetails", JSON.stringify(ObjlibraryDetails));

        this.setState({
            imageDataBuffer: null,
            imageDatab64: null,
            libraryDetails: null,
            screen_saver_picture: "Resources/Images/Welbiltlogo.png",
            isImageChanged: true,
            imageDisplayFormat: 'image/png'
        });
    }

    render() {
        let { visual_warning_enabled, screen_saver_enabled, screen_saver_time, imageDatab64, error_modal, success_modal, libraryDetails, loaderIconVisible, importOption, cropWidth, cropHeight, imageDisplayFormat } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#imageResizeModal" onClick={() => { }}>&nbsp;</button>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1271"/>
				                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            <div class="rcSettingsList02">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC1321"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onVisualWarningEnabledSelect(e)}
                                                                                checked={visual_warning_enabled === "true" ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            /> 
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcSettingsList02">
                                                                <ul>
                                                                    
                                                                    <li><FormattedMessage id="KC0209"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onScreensaverEnabledSelect(e)}
                                                                                checked={screen_saver_enabled === "true"  ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            /> 
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class={screen_saver_enabled === "true" ? "rcSaveForm" : "displaynone"}>
                                                                <div class="importImageDiv editActive">
                                                                    <div class="importImage">
                                                                        <button type="button" class="btn cancelButton" onClick={() => this.onClearImage()}>&nbsp;</button>
                                                                        <div className="menuBrowse" title={formatMessage({ id: 'KC1251' })}>
                                                                        <a href="javascript:void(0)" class="menuBrowse" data-toggle="modal" data-target="#ConfirmationOfImportModal" title={formatMessage({ id: 'KC1251' })}><img src={imageDatab64 ? `data:${imageDisplayFormat};base64,${imageDatab64}` : require("../../../../../images/media/logo.png")}  alt=""/></a></div>
                                                                    </div>
                                                                    <label><FormattedMessage id="KC2035"/></label>
                                                                </div>
                                                            </div>
                                                            <div class={screen_saver_enabled === "true" ? "rcChangeSSV" : "displaynone"}> 
                                                                <ul onClick={() => { this.onConfirmButtonClick('changeScreensaver') }}>  
                                                                    <li><FormattedMessage id="KC2036"/></li>
                                                                    <li><img src={require("../../../../../images/recipecreation/rightArrow.png")}  alt=""/></li>
                                                                </ul>  
                                                            </div> 
                                                            <div class={screen_saver_enabled === "true" ? "rcSettingsList02" : "displaynone"}>
                                                                <ul>
                                                                    <li><FormattedMessage id="KC1322"/></li>
                                                                    <li>
                                                                        <input type="number" value={screen_saver_time ? screen_saver_time : ''} class="form-control saverTime" onChange={this.handleScreensaverTimeChange} />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick('settingsEditor') }} class="btn btn-default-text confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Import Image Modal --> */}
                <MediaListPopup mediaType={'image'} OnImportImage={(item) => this.OnImport(item)} />
                {/* <!-- //Import Image Modal --> */}
                {/* <!-- Import a Picture Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmationYesNo"> 
                                    <h5><FormattedMessage id="KC1232"/></h5>
                                    <div class="uploadOption">
                                        <div class="customRadioBtn">
                                            <input id="selectFromLibrary" type="radio" name="uploadOption" value="Library" checked={importOption === "Library" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromLibrary" value="Library"><FormattedMessage id='KC1924' /></label>
                                        </div> 
                                        <div class="customRadioBtn">
                                            <input id="selectFromDevice" type="radio" name="uploadOption" value="Device" checked={importOption === "Device" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromDevice" value="Device"><FormattedMessage id="KC1233"/></label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary noBtn" id="selectFromLiberary" type="button" onClick={this.cancelImportClick}><FormattedMessage id='KC0021' /></button>
                                <input type="file" accept="image/png, image/jpeg, image/jpg,image/gif,image/bmp,image/svg+xml" ref={input => this.inputElement = input} onChange={this.onImageChange} className="fileInputField" id="menuUpdate" />
                                <button class="btn btn-secondary yesBtn" id="ImportButton" type="button" onClick={this.handleInputClick}><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- // Import a Picture --> */}
                {/* <!-- imageResizeModal --> */}
                <div class="modal fade imageResizeModal" data-keyboard="true" data-backdrop="static" id="imageResizeModal" tabindex="-1" role="dialog" aria-labelledby="infoModalLabel">
                    <div class="modal-dialog modal-lg imageResizeModal">
                        <div class="modal-content">
                            <div class="modal-header modal-header-danger">
                                <FormattedMessage id="KC1234"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCropPopup}>&nbsp;</button>
                            </div>
                            <div class="modal-body">
                                <form class="imageResizeForm">
                                    <Cropper src={this.state.src} OnCrop={(croppedImageUrl) => this.cropSubmit(croppedImageUrl)} fileName={libraryDetails ? libraryDetails.fileName : ""} fileFormat={libraryDetails ? libraryDetails.fileFormat : ""} cropWidth={cropWidth} cropHeight={cropHeight} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //imageResizeModal --> */}
            </React.Fragment>
        )
    }
}

export default injectIntl(Screensaver)
