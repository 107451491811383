import $ from 'jquery';
import _ from 'lodash';
import React, { Component } from 'react';
import http from '../../service/httpService';
import auth from '../../service/authService';
import { BaseService, UserProfile, unitAPI, UserMgmtAddUser, DeviceRegistration, userRoleURI, UserManagement } from '../../service/api';
import { setaccessCountryId, BASECOUNTRY_ID, setcustomerId, setbasecountryId, THANK_YOU_TEXT_END, THANK_YOU_TEXT } from '../../utils/appConstants';
import csvExUnitRedirect from '../../utils/csvExUnitRedirect'
import { brandUnits } from '../../utils/imgContants';
import authConstants from '../../utils/authConstants';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import AccessModal from '../modal/access-model';
import FLSuccessMoal from '../modal/fl-success-model';
import FLSuccessMoalBackToHome from '../modal/fl-home-success-model';
import queryString from 'query-string';
import { readArchivesFromCompressedResponse } from '../../utils/helpers';
import { loginResponseAction } from '../login/loginUtils';
import KCStore from '../../utils/kcStorage';

export const registerDevice = (inputData, validatePermissions,props) => {
    const response = inputData.res.data;
    const  validatedResponse  = inputData.validatedResponse.validatedResponse;
    let loggedinUser;
    let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
    loggedinUser = loginUser.username;
    const header = {
        'customerid': response.customerId,
        'useremail': loggedinUser,
        'basecountry': BASECOUNTRY_ID
    }
    //this.setState({ loaderIconVisible: true });

    let postData = {
        "customerId": response.customerId,
        "brandId": parseInt(validatedResponse.BRANDID),
        "serialNumber": validatedResponse.SERIALNO.toString().replace(/ /g, ''),
        "assetNumber": '',
        "brandName": validatedResponse.BRAND_NAME,
        "productId": validatedResponse.PRODUCTID,
        "inventoryId": validatedResponse.INVENTORY_ID,
        "registrationCode": validatedResponse.REGISTRATION_CODE,
        "requestType": "unitRegisterAndUpdateCustomerBrand",
        "existingBrandsAccess": KCStore.getItem("brandId"),
    };

    http({
        method: "POST",
        url: unitAPI.addUnit,
        headers: header,
        data: postData
    }).then(async responseValue => {
        //this.setState({ showFirstLogin: false });
        response.allocationResponse = responseValue;
        await callAllocationAPI(response,validatedResponse);
        if (!validatePermissions.org) {
            await triggerEmail(validatedResponse)
            //$('#confirmationRegisteredModal').modal('show');
        } else {
            $('body').removeClass("deviceRegtrnBg");
            $('body').removeClass("loginBg");
            await updateCustBrandAndFeature(props)
        }
    }).catch(err => {
        //this.setState({ loaderIconVisible: false, showFirstLogin: false });
        props.history.replace(`/register-unit`);
    });
}

const callAllocationAPI = (response,validatedRes) => {
    return new Promise(async (res, rej) => {
        const validatedResponse = validatedRes;
        const postData = {
            "componentId": validatedResponse.COMPONENT_ID || '',
            "customer_id": response.customerId,
            "subscriptionType": "SAAS",
            "allocationType": "UPGRADE",
            "unitIds": response.allocationResponse.data.body.respObj.unitPKID || "",
            "chargifyAllocation": {
                "allocation": {
                    "quantity": 1,
                    "memo": validatedResponse.SERIALNO,
                    "accrue_charge": false,
                    "upgrade_charge": "prorated"
                }
            }
        };
        await http({
            method: "POST",
            url: DeviceRegistration.allocationApi,
            headers: {
                'Content-Type': 'application/json',
            },
            data: postData
        }).then(async response => {
            res(response);
        }).catch(err => {
            res(err);
        });
    })
}

const triggerEmail = async (validatedRes) => {
    //this.setState({ loaderIconVisible: true });
    const validatedResponse = validatedRes;
    let loggedinUser;
    let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
    loggedinUser = loginUser.username;

    let postData = {
        "username": loggedinUser,
        "email": loggedinUser,
        "unitId": validatedResponse.SERIALNO
    };

    await http({
        method: "POST",
        url: unitAPI.triggerEmail,
        headers: {
            'Content-Type': 'application/json',
        },
        data: postData
    }).then(response => {
        //this.setState({ loaderIconVisible: false });
    }).catch(err => {
        //this.setState({ loaderIconVisible: false });
    });
}

const updateCustBrandAndFeature = async (props) => {
    let customerId = KCStore.getItem("customerId");
    let roleId = KCStore.getItem("roleId");
    let Urls = userRoleURI.getFeatures + "/" + roleId + "/features?customer=" + customerId + "&is_featurefunction_required=true"
    http.get(Urls,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            data: {}
        }).then(response => {
            let rolefeatures = response.data.data.features;
            KCStore.setItem(authConstants.allFeaturesKey, JSON.stringify(rolefeatures));
            let brandIDS = response.data.data.brandIds;
            KCStore.setItem('brandId', JSON.stringify(brandIDS));
            //this.setState({ loaderIconVisible: false })
            props.history.push(`/unitRegistration`);
        }).catch(err => {

        });
}