import {MEDIA_LIBRARY_LIST_REQUEST,MEDIA_LIBRARY_LIST_SUCCESS,MEDIA_LIBRARY_LIST_FAIL} from '../../../../actions/navigation/mediaManagement/mediaLibraryListActions';
const initialState = {
    loaded: false,
    loading: false,
    loaderIconVisible:false,
    mediaLibraryListInformation: [],
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case MEDIA_LIBRARY_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                loaderIconVisible:false,

                
            }
        case MEDIA_LIBRARY_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                loaderIconVisible:true,
                mediaLibraryListInformation: action.mediaLibraryListInformation,
               
            }
        case MEDIA_LIBRARY_LIST_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: '',

            }
        default:
            return state
    }
}

