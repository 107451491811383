import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { smartGroupAPI, smartTagMangement, SoftwareManagementAPI } from '../../../service/api';
import http from '../../../service/httpService';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import Constants from '../../../woopraConfig/Woopraconstant';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import moment from '../../../utils/momentHelper.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import authService from '../../../service/authService';
import $ from 'jquery';
import TagOrgModal from '../../navigation/smartTag/tagOrgModel';
import OrgTagsDetail from '../../navigation/smartTag/orgTagsDetail';
import { BRAND_ID } from '../../../utils/appConstants';
import { connect } from 'react-redux';
import { setUnit } from '../../../actions/dashboardRenderer/dashboardRenderer';
import { bindActionCreators } from 'redux';

import { Loader } from '@kcui/react/Loader';

import KCStore from '../../../utils/kcStorage';

class SmartTagWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            loaderIconVisible: false,
            smartTag: [],
            searchSmartTag: '',
            selectedSmartTag: [],
            isChangeHappened: false,
            showpopup: [],
            type: 'orgList',
            showOrgPopup: false,
            showStPopUp: false,
        };

    }

    componentDidMount = async () => {
        this.loadSmartTags().then((res) => {
            const currentUnit = this.props?.isFromDashboardRender ? this.props.currentUnit : KCStore.getItem('currentUnit') ? JSON.parse(KCStore.getItem('currentUnit')) : {};
            if (currentUnit && currentUnit.unitTags && currentUnit.unitTags.length) {
                this.setState({ selectedSmartTag: currentUnit.unitTags })
            }
            this.setState({ smartTag: res, loaderIconVisible: false })
        })
    }

    loadSmartTags = () => new Promise((resolve, reject) => {
        this.setState({ loaderIconVisible: true });
        let url = smartTagMangement.getTagList + "UNIT";
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
        if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag = 'yes';
        }
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                customerId: parseInt(KCStore.getItem("customerId")),
                'cgid': KCStore.getItem("custGroupID"),
                'brandid': BRAND_ID,
                'registrationflag': registrationFlag,
            }
        }).then(resp => {
            resolve(resp.data.data)
        }).catch(error => {
            resolve([]);
        })
    })


    onSuccessCloseModal = () => {
        this.setState({
            showPopUp: false,
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    downloadUnitList = (tagName) => {
        this.setState({ loaderIconVisible: true });
        let url = smartTagMangement.getPresignedUrl;
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
        if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag = 'yes';
        }
        url = url + `?registrationFlag=${registrationFlag}&tagName=${tagName}&isView=yes`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'customerid': KCStore.getItem("customerId"),
                'cgid': KCStore.getItem("custGroupID"),
                'brandid': BRAND_ID,
            }, data: {}
        }).then(async response => {
            if (response && response.data && response.data.data) {
                this.setState({ loaderIconVisible: false });
                if (response.data.data.preSignedUrl && response.data.data.preSignedUrl !== '#') {
                    window.location = response.data.data.preSignedUrl;
                }
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    loadTagPopup(showpopup, type, formatMessage) {
        return <div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabindex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <FormattedMessage id="KC2312" />
                    </div>
                    <div className="modal-body">
                        {showpopup && showpopup.map((item, i) => <div className="reportDetailsD">
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li>
                                        <div className={type === "orgList" ? "tagTypeLocation" : "tagTypeUnit"}><span>{item.tagName}</span></div>
                                    </li>
                                </ul>
                            </div>
                            <h6><FormattedMessage id="KC0195" /></h6>
                            <p>{item.description ? item.description : 'NA'}</p>
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li>
                                        <p><FormattedMessage id="KC2313" /> <button type="button" onClick={() => $('#stUnitDetailsModal').modal('hide')} data-toggle="modal" data-target="#orgViewModal" className= {(item.allcgids && item.allcgids.length === 0) || (item.allunitIds && item.allunitIds.length === 0) ? "orgViewIcon btn disabled" :"orgViewIcon btn"}><img src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> {type === 'orgList' ? formatMessage({ id: "KC2306" }) : formatMessage({ id: "KC2307" })}</p>
                                    </li>
                                    {type === 'orgList' ? "" :
                                        <li>
                                            <Link to='#' onClick={() => this.downloadUnitList(item.tagName)} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
                                        </li>}
                                </ul>
                            </div>
                            {(item.allcgids && item.allcgids.length === 0) || (item.allunitIds && item.allunitIds.length === 0) ? <p class="notUsed"><FormattedMessage id='KC2357' /></p> : null}
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li><FormattedMessage id="KC0589" /></li>
                                    <li><FormattedMessage id="KC1278" /></li>
                                </ul>
                                <ul className="tBody">
                                    <li>
                                        <div className="createdEditedDetails">
                                            <ul>
                                                <li><FormattedMessage id="KC0241" />:</li>
                                                <li>{item.createdByName ? item.createdByName : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0136" />:</li>
                                                <li>{item.createdBy ? item.createdBy : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0298" />:</li>
                                                <li>{item.createdAt ? moment.GetDateTimeByFormat(item.createdAt, 'LLL') : ''}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="createdEditedDetails">
                                            <ul>
                                                <li><FormattedMessage id="KC0241" />:</li>
                                                <li>{item.updatedByName ? item.updatedByName : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0136" />:</li>
                                                <li>{item.updatedBy ? item.updatedBy : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0298" />:</li>
                                                <li>{item.UpdatedAt ? moment.GetDateTimeByFormat(item.UpdatedAt, 'LLL') : ''}</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>)}
                    </div>
                    <div className="modal-footer textAlignCenter">
                        <button className="btn btn-secondary okBtn" data-dismiss="modal" onClick={() => this.setState({ showpopup: [], showOrgPopup: false })} type="button"><FormattedMessage id="KC0777" /></button>
                    </div>
                </div>
            </div>
        </div >;
    }


    hanleCheckbox = (id) => {
        let tagArr = this.state.selectedSmartTag;
        if (tagArr.includes(id)) {
            const index = tagArr.findIndex((i) => i === id);
            tagArr.splice(index, 1);
        } else {
            tagArr.push(id);
        }
        this.setState({ selectedSmartTag: tagArr, isChangeHappened: tagArr.length ? true : false })
    }

    onSave = () => {
        this.setState({ loaderIconVisible: true })
        this.triggerAPI().then((res)=>{
            let currentUnit = this.props?.isFromDashboardRender ? this.props.currentUnit : JSON.parse(KCStore.getItem('currentUnit'));
            if(currentUnit && currentUnit.unitTags){
                currentUnit['unitTags'] = this.state.selectedSmartTag
            }
            if(!(this.props?.isFromDashboardRender)){
                KCStore.setItem('currentUnit', JSON.stringify(currentUnit))
            }
            this.setState({
                loaderIconVisible: false,
                success_modal:{
                    open: true,
                    message: 'KC2323' , //KC2323
                }
            })
            if(this.props?.isFromDashboardRender){
                this.props.setUnit(currentUnit);
            }
        });
    }

    triggerAPI() {
        return new Promise((resolve,reject)=>{
            const currentUnit = this.props?.isFromDashboardRender ? this.props.currentUnit : KCStore.getItem('currentUnit') ? JSON.parse(KCStore.getItem('currentUnit')) : {};
            let reqData = {
                unitId: [],
                tags: []
            };
            reqData.unitId.push(currentUnit.ID);
            reqData.tags = this.state.selectedSmartTag;
            http.put(smartTagMangement.putUnitMethod,
                reqData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            )
                .then(function (response) {
                    resolve(response);
                }).catch(function (err) {
                    resolve(err);
                });
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { success_modal, error_modal, smartTag, isChangeHappened, showpopup, type, loaderIconVisible } = this.state;
        let dataList = smartTag;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        const searchedItemInFilter = dataList && dataList.filter(
            (tag) => {
                return tag.tagName.toLowerCase().indexOf(this.state.searchSmartTag.toLowerCase()) !== -1;
            }
        );
        dataList = searchedItemInFilter;
        dataList = dataList.sort((a, b) => (this.state.selectedSmartTag.includes(a.id) && this.state.selectedSmartTag.includes(b.id)) ? 0 : this.state.selectedSmartTag.includes(a.id) ? -1 : this.state.selectedSmartTag.includes(b.id) ? 1 : 0);
        return (
            <React.Fragment>
                {this.props?.isFromDashboardRender ? null : <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>}
                <div class={`colmDi ${this.props?.isFromDashboardRender ? '' : 'borderTop2px'}`}>
                    <h4 class="withBtn"><FormattedMessage id='KC2317' /> 
                    {smartTag && smartTag.length ? <button type="button" class={dataList.length>0 ? `btn btn-default saveBtn`: `btn btn-default saveBtn disabled`} onClick={this.onSave}><FormattedMessage id='KC0123' /></button> : null}
                    </h4>
                    {!loaderIconVisible && smartTag && smartTag.length ?
                    <div class="colmDiVal">
                        <div class="smartTagsAM">
                            <div class="STunitsTable darkTheme">
                                <ul class="tHead">
                                    <li>
                                        <div class="tableSerarchBar">
                                            <input class="form-control tagsSearch" type="text" placeholder={formatMessage({ id: "KC2618" })} onChange={(e) => this.setState({ searchSmartTag: e.target.value })} />
                                        </div>
                                    </li>
                                    <li><FormattedMessage id='KC0325' /></li>
                                    <li><FormattedMessage id='KC2131' /></li>
                                </ul>
                            </div>
                            <div class="STListAMTableSrlBar">
                                <div class="STunitsTable darkTheme">
                                    {dataList && dataList.length ? dataList.map((tag, index) => {
                                        const isChecked = this.state.selectedSmartTag && this.state.selectedSmartTag.length && this.state.selectedSmartTag.includes(tag.id) ? true : false;
                                        return (
                                            <ul key={index} class="tBody">
                                                <li><div class="tagTypeUnit"><span>{tag.tagName}</span></div></li>
                                                <li><button type="button" class="btn stUnitsDetails" onClick={async () => {
                                                    let smartTagObj = { selectedRow: { ...tag }, mode: 'isView', type: 'unitList' }
                                                    await KCStore.setItem('smartTagInfo', JSON.stringify(smartTagObj));
                                                    let showpopup = [];
                                                    showpopup.push(tag);
                                                    this.setState({ showpopup, showOrgPopup: true, type: 'unitList' }, () => {
                                                        $('#stUnitDetailsModal').modal('show');
                                                    });
                                                }} >&nbsp;</button></li>
                                                <li>
                                                    <div className={`customCheckbox customCheckboxWOlabel`}>
                                                        <input id={tag.tagName + 'wlocation'} name={tag.tagName + 'wlocation'} type="checkbox" checked={isChecked} onChange={(e) => this.hanleCheckbox(tag.id)} />
                                                        <label for={tag.tagName + 'wlocation'}></label>
                                                    </div>
                                                </li>
                                            </ul>
                                        )
                                    }) : <div className='alignmentCenter'><FormattedMessage id='KC0791' /></div>}
                                </div>
                            </div>

                        </div>
                    </div>
                 : null}
                </div>
                {loaderIconVisible && this.props?.isFromDashboardRender ? <Loader loader={loaderIconVisible} /> : null}
                {/*  Org tag */}
                {showpopup && showpopup.length ? this.loadTagPopup(showpopup, type, formatMessage) : null}
                {this.state.showOrgPopup ? <TagOrgModal details={showpopup && showpopup.length ? showpopup[0] : {}} /> : null}
                {this.state.showStPopUp ? <OrgTagsDetail closeSTModel={() => this.setState({ showStPopUp: false })} nodeDet={this.state.link} unitDet={this.state.unit} isUnit={this.state.isUnitItemClicked} formBreadCrum={this.formBreadCrum} /> : ""}

                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
            </React.Fragment>
        )
    }
};

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setUnit
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SmartTagWidget));