import axios from 'axios';
import api from '../../../service/api';
import http from '../../../service/httpService';
import {BaseService, LocationInfo} from '../../../service/api';
import { CUSTOMER_ID, BRAND_ID } from '../../../utils/appConstants';

export const LOCATION_GRID_REQUEST = 'LOCATION_GRID_REQUEST';
export const LOCATION_GRID_SUCCESS = 'LOCATION_GRID_SUCCESS';
export const LOCATION_GRID_FAIL = 'LOCATION_GRID_FAIL';



export function getLocationGridInfo (organizationHierarchy = '', cgid = '', cid = '', brandid ='', model = '', machineType = '')  {
    return dispatch => {
        dispatch({
            type: LOCATION_GRID_REQUEST
        })
        
        let cgids = cgid;

        if (organizationHierarchy != "")
        {
            cgids = organizationHierarchy + ',' + cgid;
        }

        http.get(`${LocationInfo.list}`,
                {
                    headers: {
                        'Content-Type':'application/json',
                        'cgid': cgids,  
                        'cid': CUSTOMER_ID === "-1" ? cid : CUSTOMER_ID,  
                        'brandid': brandid ? brandid : BRAND_ID,  
                        'model': model,
                        'machinetype': machineType                        
                    },
                    data:{}
                }
            )
            .then(response => {
                dispatch({
                    type: LOCATION_GRID_SUCCESS,
                    dashboardLocationValue: response.data,
                    
                })
            })
            .catch(error => {
                dispatch({
                    type: LOCATION_GRID_FAIL,
                    error
                })
            })
    }
}
