import React, { Component } from 'react';
import Modal from "react-responsive-modal";
import { FormattedMessage } from 'react-intl';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class GenericModal extends Component {
    nextPath(path) {
        this.props.history.push(path);
    }
    constructor(props) {
        super(props);

        this.onCloseModal = this.onCloseModal.bind(this);
    }

    state = {
        open: false
    };

    onCloseModal = (e, messagetype) => {
        this.props.onGenericCloseModal(messagetype);
    };

    render() {
        const { open, message, messagetype, stringID, values } = this.props;
        return (
            <div style={styles}>
                <Modal open={open} showCloseIcon={false} center onClose={() => { }}>
                    {open && <div className="modal fade confirmationRMModal show" data-keyboard="true" data-backdrop="static" id="SignInSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel" style={{ display: "block" }}>
                        <div class="modal-dialog modal-md">
                            <div class="modal-content">
                                <div class="modal-header modal-header-danger">
                                    {messagetype === "Success"?<FormattedMessage id="KC2632" />:<FormattedMessage id="KC0052" />} <button type="button" class="close" data-dismiss="modal" onClick={(e) => this.onCloseModal(e, messagetype)}>&nbsp;</button>
                                </div>
                                <div class="modal-body">
                                    <div class="reportStatusModal">
                                    <span class="rmStatusIcon">{ messagetype === "Success" ? <img src={require("../../images/icons/iconReportSuccessful.svg").default} /> : messagetype === "Alert" ? <img src={require("../../images/icons/iconAlert.svg").default} /> : messagetype === "Maximum number of units" ? <img src={require("../../images/icons/infoReport.svg").default} /> : <img src={require("../../images/icons/iconReportGenerationFailed.svg").default} />}</span>
                                    <h6>{stringID ? <FormattedMessage id={stringID} values={values} /> : message}</h6>
                                    </div>
                                </div>
                                <div class="modal-footer textAlignCenter">
                                    <button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" type="button" onClick={(e) => this.onCloseModal(e, messagetype)}><FormattedMessage id="KC0777" /></button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </Modal>
            </div>
        );
    }
}
export default GenericModal;