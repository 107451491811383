import { SetLocationImage } from "../../utils/locationResponseBuilder";
import { UnitBuilder } from "../../utils/unitResponseBuilder";
import { BRAND_ID, CUSTOMER_ID } from '../../utils/appConstants';
import http from '../../service/httpService';
import { UnitInfo, dashboardRenderer } from '../../service/api';
import configData from './data.json';
import xpressChefData from './xpresschef.json';
import queryString from 'query-string';
import KCStore from '../../utils/kcStorage';


export const getUnitDetails = (serialNumber) => {
    return new Promise((resolve, rej) => {
        let url = `${UnitInfo.list}`;
        let pageNumber = '?pageNum=1';
        let pageLimit = '&pageSize=1';
        let searchParam = {
            'serialNumber': serialNumber
        };
        url = `${url}${pageNumber}${pageLimit}&searchParams=${btoa(JSON.stringify(searchParam))}`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'cgid': KCStore.getItem("custGroupID"),
                'cid': CUSTOMER_ID,
                'brandid': BRAND_ID,
                'model': "",
                'machinetype': "",
                'sortby': "",
                'sorttype': ""
            },
            data: {}
        }).then(res => {
            if (res?.data?.units?.length) {
                let returnObj = {
                    units: {},
                    location: {}
                }
                const units = UnitBuilder(res.data.units);
                if (units.length) {
                    returnObj['units'] = units[0]
                    if (units[0] != null) {
                        let dynastyAttributes = units[0].dynastyAttributes ? JSON.parse(units[0].dynastyAttributes) : {};
                        if (dynastyAttributes != null) {
                            dynastyAttributes = SetLocationImage(dynastyAttributes);
                            returnObj['location'] = dynastyAttributes
                        }
                    }
                }
                resolve(returnObj);
            } else {
                resolve(res);
            }
        }).catch(err => {
            rej(err);
        });
    })
}

export const getConfigurationDetails = (unitDetails) => {
    return new Promise((resolve, rej) => {
        // resolve(xpressChefData)
        if (!unitDetails || !Object.keys(unitDetails).length) {
            resolve({});
            return;
        }
        let url = `${dashboardRenderer.getConfiguration}`;
        const stringified = queryString.stringify({
            "modelFamily": unitDetails.MODEL_FAMILY_NAME,
            "model": unitDetails.MODEL_NAME,
            "brand": unitDetails.BRAND_NAME
        });
        url = url + (stringified ? `?${stringified}` : '');
        http.get(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {}
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            rej(err);
        });
    })
}