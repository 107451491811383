import { SET_CREATE_PACKAGE_LIST, SET_CREATE_PACKAGE_LIST_LOADING } from '../../../actions/stateActionsData/type';

const initialState = {
    createPackageZip: null ,
    isLoading: false
}

export default function (state = initialState, action) {
    switch(action.type) {
        case SET_CREATE_PACKAGE_LIST:
        return {
            ...state,
            createPackageZip: action.payload
        }
        case SET_CREATE_PACKAGE_LIST_LOADING: 
        return {
            ...state,
            isLoading: action.payload
        }
        default:
        return state;
    }
}

