
import { LocationInfo, BaseService, ReportManagement } from '../api';
import http from '../httpService';


export const getLocationAPIUrl = (unitId, brandId) => {
    //unitId ="530115990";
    let apiUrl = `${LocationInfo.location}${unitId}/${brandId}`;
    return apiUrl;
}

export const getLocationInfo = async(url) => {
    // return axios.get(api.mediaMgmtInfoService);
    let response ={};
    try{
       let result =  await http.get(url, {
        headers: {
        'Content-Type': 'application/json'},
        data:{}
    });
       response = result.data.units;
       return response;
    }
    catch(err){
        debugger;
        return response;
    }
   
}
export const getExpoLatLong = async(simnumber) => {
    // return axios.get(api.mediaMgmtInfoService);
    let response ={};
    try{
         let result =  await http.get(LocationInfo.baseurl+LocationInfo.telefonicaLatLong, {
            headers: {
            'content-Type': 'application/json',
            'icc': simnumber,
            },
            data:{}
    });
         response = result.data.coordinates;
         return response;
    }
    catch(err){
            return err;
    }   
}
/** New service created for expobar steam temperature value display in 5 pillars - asset management screen - hardware */
export const getSteamTemperature = async (unitId) => {
    let response;
    try{
       let result =  await http.get(`${BaseService.root}${ReportManagement.expobarSteamTemperature}`, {
        headers: {
        'Content-Type': 'application/json',
        'unitid': unitId },
        data:{}
    });
       response = result.data.data;
       return response;
    }
    catch(err){
        return response;
    }
}

