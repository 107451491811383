import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';

export class BrandList extends Component {

	getCheckBoxstate(ListCount,SelectedCount) {
		if (SelectedCount === ListCount) {
		return { isSelectAllBrands: true, isIndeterminateSelect: false };
		} else if (SelectedCount === 0 && ListCount !== 0) {
		return { isSelectAllBrands: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
		return { isSelectAllBrands: true, isIndeterminateSelect: true };
		}
	}
    
    render() { 
        let { brandList, isBrandEditable, header } = this.props;
        let checkBoxState = this.getCheckBoxstate(brandList.length,brandList.filter(item => item.isChecked === true ).length)
        let allBrandsSelected = checkBoxState.isSelectAllBrands;
        let allBrandsIndeterminateSelect = checkBoxState.isIndeterminateSelect;

        return (
            <form className="assigntoBrandsForm" style={{ display: brandList.length > 0 ? "block" : " none" }}>
            <div className="assigntoBrands">
              <ul className="tHead">
                <li>{header? <FormattedMessage id={header}/> : <FormattedMessage id="KC0620"/>}</li>
                <li>
                  <div className={ isBrandEditable  ? `customCheckbox ${allBrandsIndeterminateSelect ? 'selectAllDiv' : ''}  customCheckboxWOlabel` : `customCheckbox customCheckboxWOlabel ${allBrandsIndeterminateSelect ? 'selectAllDiv' : ''} disabled ` }>
                    <input id="roleBrandAllSelection" name="roleBrandAllSelection" 
                    type="checkbox" 
                    value={allBrandsSelected} 
                    checked={allBrandsSelected} 
                    onChange={ (e) => isBrandEditable && this.props.isAllBrandSelectionChange(e)}/>
                    <label htmlFor="roleBrandAllSelection">&nbsp;</label>
                  </div>
                </li>
              </ul>
              <ul className="tBody">
                <li>
                  {
                    brandList.map((item, i) => (
                      <div className={ isBrandEditable ? "customCheckbox  customCheckboxWOlabel" : "customCheckbox  customCheckboxWOlabel disabled"} key={i}>
                        <input id={'brandItemNo' + item.ID} name={'brandItemNo' + item.ID} type="checkbox" 
                        value={item.isChecked} 
                        checked={item.isChecked}
                        onChange={(e) => isBrandEditable && this.props.isBrandSelectationChange(e, item)} />
                        <label htmlFor={'brandItemNo' + item.ID}>{item.BRAND_NAME}</label>
                      </div>
                    ))
                  }
                </li>
                <li>&nbsp;</li>
              </ul>
            </div>
          </form>
        );
    }
}
 
BrandList.propTypes = {
    brandList : PropTypes.array.isRequired,
    isBrandEditable : PropTypes.bool.isRequired
}


