import {RESET_CYCLE_REQUEST,RESET_CYCLE_SUCCESS,RESET_CYCLE_FAIL} from '../../../actions/unitDashboard/resetCycleAction';
const initialState = {
    loaded: false,
    loading: false,
    ResetCycleResponse: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case RESET_CYCLE_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case RESET_CYCLE_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                ResetCycleResponse: action.ResetCycleResponse
            }
        case RESET_CYCLE_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgdobuleTaps: 'Server Error',

            }
        default:
            return state
    }
}