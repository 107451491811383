import { smartGroupAPI } from '../../service/api';
import http from '../../service/httpService';
import { SET_UPDATE_HISTORY_DETAILS ,SET_UPDATE_HISTORY_DETAILS_LOADING,  MODEL_FAIL} from '../stateActionsData/type';
import _ from 'lodash';
const setUpdateHistory = ( data ) => ({ type: SET_UPDATE_HISTORY_DETAILS, payload: data});
const setLoader= ( bol ) => ({ type: SET_UPDATE_HISTORY_DETAILS_LOADING, payload: bol});

export const getUpdateHistory =  (sgid)  => dispatch => {
    dispatch( setUpdateHistory(null));
    dispatch( setLoader(true));
    /* api call */
    http.get(smartGroupAPI.smartGroupUpdateHistory,
        {
            headers: {
                'Content-Type': 'application/json',
                sgid:sgid
            }, data: {}
        }
        ).then( response => {
            let { data } = response;
            let units = data && data.units;
            let addedUnits = units &&  units.addedUnits;
            let removedUnits = units && units.removedUnits;
            if( addedUnits ) {
                addedUnits = _.orderBy(addedUnits, ['createdAt'],['desc']);
            }
            
            if(removedUnits ) {
                removedUnits = _.orderBy(removedUnits, ['createdAt'],['desc']);
            }
            // get top 3 records if applicable;
            if( addedUnits && addedUnits.length > 3) {
                addedUnits = addedUnits.slice(0, 3)
            }
            if( removedUnits && removedUnits.length > 3) {
                removedUnits = removedUnits.slice(0, 3)
            }
           
            let _data = { units: {addedUnits,removedUnits }, total_unit_count: data.total_unit_count }
            dispatch( setLoader(false));
            dispatch(setUpdateHistory(_data));
            
        }).catch(error => {
            dispatch( setLoader(false));
            dispatch({
                type: MODEL_FAIL,
            });
        });
}