import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import $ from 'jquery';
import KCStore from '../../../../../utils/kcStorage';
class Units extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            preheat_unit: '',
            previous_preheat_unit: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onPreheatUnitSelect = this.onPreheatUnitSelect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let jsonTemperature = JSON.parse(jsonSettingsFile);

            this.setState({
                preheat_unit: jsonTemperature.units,
                previous_preheat_unit: jsonTemperature.units
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onPreheatUnitSelect = (event) => {
        this.setState({
            preheat_unit: event.target.value
        });
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true
        });
        let { preheat_unit, previous_preheat_unit } = this.state;
        let jsonSettingsFile = KCStore.getItem("librarySettingsData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        ObjSettingsFile.preheat_list && ObjSettingsFile.preheat_list.map((item, i) => {
            if (item.name.en_US) {
                ObjSettingsFile.preheat_list[i].set_point.units = preheat_unit;
                let value = item.set_point.value;
                if (previous_preheat_unit !== preheat_unit) {
                    if (preheat_unit === "degF") {
                        ObjSettingsFile.preheat_list[i].set_point.value = parseFloat(((value * 9/5) + 32).toFixed(3)) > 212 ? Math.round(parseFloat(((value * 9/5) + 32).toFixed(3))) : 0;
                    }
                    else {
                        ObjSettingsFile.preheat_list[i].set_point.value = parseFloat(((value - 32) * 5/9).toFixed(3)) > 0 ? Math.round(parseFloat(((value - 32) * 5/9).toFixed(3))) : 0;
                    }
                }
            }
        })
        ObjSettingsFile.units = preheat_unit;
        let schedulers = ObjSettingsFile.scheduler;
        schedulers && schedulers.map((parent, i) => {
            parent.configurations.map((item, j) => {
                if (item.time) {
                    if (previous_preheat_unit !== preheat_unit) {
                        let value = item.temp;
                        if (preheat_unit === "degF") {
                            item.temp = parseFloat(((value * 9/5) + 32).toFixed(3)) > 211 ? Math.round(parseFloat(((value * 9/5) + 32).toFixed(3))) : 0;
                            ObjSettingsFile.scheduler[i].configurations[j] = item;
                        }
                        else {
                            item.temp = parseFloat(((value - 32) * 5/9).toFixed(3)) > 0 ? Math.round(parseFloat(((value - 32) * 5/9).toFixed(3))) : 0;
                            ObjSettingsFile.scheduler[i].configurations[j] = item;
                        }
                    }
                }
            })
        })
        if (ObjSettingsFile.global_preheat_band) {
            if (previous_preheat_unit !== preheat_unit) {
                if (preheat_unit === "degF") {
                    ObjSettingsFile.global_preheat_band = (ObjSettingsFile.global_preheat_band * 9 / 5);
                }
                else {
                    ObjSettingsFile.global_preheat_band = (ObjSettingsFile.global_preheat_band * 5 / 9);
                }
            }
        }
        KCStore.setItem("librarySettingsData", JSON.stringify(ObjSettingsFile));
        KCStore.setItem("schedulerDetails", JSON.stringify(ObjSettingsFile.scheduler));
        this.props.history.push(`/settingsEditor`);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/preHeat"
        });
    }

    handleParamChange = (e) => {
        if (["form-control preheat_name"].includes(e.target.className)) {
            let preheat_list = [...this.state.preheat_list]
            preheat_list[e.target.dataset.id].name.en_US = e.target.value.trim();
            this.setState({ preheat_list: preheat_list })
        }
        else if (["form-control preheat_value"].includes(e.target.className)) {
            let preheat_list = [...this.state.preheat_list]
            preheat_list[e.target.dataset.id].set_point.value = e.target.value ? parseInt(e.target.value) : 0;
            this.setState({ preheat_list: preheat_list })
        } else {
            this.setState({ [e.target.name]: e.target.value.toUpperCase() })
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        let { preheat_unit, error_modal, success_modal } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                   <FormattedMessage id="KC1300"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            <div class="rcSettingsList">
                                                                <ul>
                                                                    <li></li>
                                                                    <li><FormattedMessage id="KC1301"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onPreheatUnitSelect}>
                                                                            <input id="Centrigrade" type="radio" name="units" value="degC" checked={preheat_unit === "degC" ? true : false} />
                                                                            <label for="Centrigrade" value="degC">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li></li>
                                                                    <li><FormattedMessage id="KC1302"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onPreheatUnitSelect}>
                                                                            <input id="Fahrenheit" type="radio" name="units" value="degF" checked={preheat_unit === "degF" ? true : false} />
                                                                            <label for="Fahrenheit" value="degF">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn btn-default-text confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(Units)
