import React from 'react'
import SmartTagWidget from '../unitManagement/widget/smartTagWidget'
import LiveDiagnostic from '../LiveDiagnostic/liveDiagnostic';


export default function DashboardRendererCustom(props) {
    const { currentUnit, currentLocation, menuName, tabName } = props;
    return (
        <React.Fragment>
            {menuName === "assetdashboard" && tabName === "assetdashboard"
                && (
                    <>
                        <div id="smart_tag" className={`dashboard-renderer dashboard-renderer-span3`}>

                            <div className='gridView'>
                                <div className='colmDi colm12D'>
                                    <SmartTagWidget currentUnit={currentUnit} currentLocation={currentLocation} isFromDashboardRender={true} />

                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                    </>
                )}
            {menuName === "servicedashboard" && tabName === "livediagnostic" &&
                <LiveDiagnostic currentUnit={currentUnit} />}
        </React.Fragment>
    )
}
