import React, { Component } from 'react'

export class TemperatureTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderIconVisible: false,
    }
    this.handleTempTypeChange = this.handleTempTypeChange.bind(this);
  }

  handleTempTypeChange(e) {
    this.props.handleTempTypeChangeProps(e);
  }

  render() {
    let { isTempEditable, selecteditem } = this.props;

    return (
        <select className={isTempEditable ? "form-control celFrhSel" : "form-control celFrhSel disabled"} onChange={this.handleTempTypeChange}>
            <option value="° F" selected={selecteditem === "° F" ? "Selected" : ""}>° F</option>
            <option value="° C" selected={selecteditem === "° C" ? "Selected" : ""}>° C</option>
        </select>
    );
  }
}
