import { Subject } from 'rxjs';
import auth from '../service/authService'

const subject = new Subject();

export const  IsBroadCastJwtTokenExpired = () => subject.next(auth.IsTokenTimeExipired())
export const  IsReceiveJwtTokenExpired = () => subject.asObservable();

export default {
    IsBroadCastJwtTokenExpired,
    IsReceiveJwtTokenExpired
}