import { smartGroupAPI } from '../../service/api';
import http from '../../service/httpService';

import { SET_SMART_GROUP_DELETE_UNIT, SET_SMART_UNIT_DETAILS_LOADING, MODEL_FAIL} from '../stateActionsData/type';
export const setUnitDeleteData = ( data ) => ({ type: SET_SMART_GROUP_DELETE_UNIT, payload: data});
const setLoader= ( bol ) => ({ type: SET_SMART_UNIT_DETAILS_LOADING, payload: bol});

export const  deleteUnitList =  (url, reqData)  => dispatch => {
    // confirm modal 
    dispatch( setUnitDeleteData(null));
    dispatch( setLoader(true));

    http.delete(smartGroupAPI.deleteSmartGrouplist,
        {
          headers: {
              'Content-Type': 'application/json',
            }, data: {...reqData}
        }
        ).then( response => {
            dispatch( setLoader(false));
            if( response.status === 200) {
                dispatch(setUnitDeleteData({...response.data}));
            }
            //sucess modal
        }).catch(error => {
        
            dispatch( setLoader(false));
            dispatch({
                type: MODEL_FAIL,
            });
        });
}

