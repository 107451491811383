import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import { FormattedMessage } from 'react-intl';

export class Cropper extends Component {
  state = {
    src: null,
    crop: {
      unit: 'px',
      aspect: 16 / 16,
      width: this.props.cropWidth,
      height: this.props.cropHeight,
      x: 0,
      y: 0
    },
    blobdata: null,
  };

  componentDidUpdate = async (prevProp) => {
    if (prevProp.src !== this.props.src) {
      this.setState({
        crop: {
          unit: 'px',
          aspect: 16 / 16,
          width: this.props.cropWidth,
          height: this.props.cropHeight,
          x: 0,
          y: 0
        }
      })
    }
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  cropSubmit = (e) => {
    this.props.OnCrop(this.state.croppedImageUrl);
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);  
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        this.props.fileName
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName, ext) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    let fileext = this.props.fileFormat.replace('.','');
    //As Base64 string
    return new Promise((resolve, reject) => {
        const base64Image = canvas.toDataURL('image/' + fileext);
        let image = {};
        let imagebuffer = Buffer.from(base64Image.replace(/^data:image\/[A-Za-z]+;base64,/, ""), 'base64');
        image.data = imagebuffer;
        image.base64 = base64Image;
        image.name = fileName;
        image.size = Buffer.byteLength(imagebuffer);
        image.type = fileext;
        resolve(image);
      }, 'image/' + fileext);

    // As a blob - Not working in IE 11
    // return new Promise((resolve, reject) => {
    //     canvas.toBlob(blob => {
    //       blob.name = fileName;
    //       resolve(blob);
    //     }, 'image/' + fileext, 1);
    //   });
  }

  render() {
    const { crop, croppedImageUrl } = this.state;
    const { src } = this.props;

    return (
      <div className="cropmodal">
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            locked={false}
            minWidth={100}
            minHeight={100}
            keepSelection={true}
          />
        )}
        <div class="modal-footer textAlignCenter">
            <button class="btn btn-secondary cropConfirmButton" type="button" onClick={(e) => this.cropSubmit(e)}><FormattedMessage id="KC1918"/></button> 
        </div>
      </div>
    );
  }
}
