import React, { Component } from 'react';
import Modal from "react-responsive-modal";
import StackedBarChart from '../globalWidgets/stackedBarChart';
import { FormattedMessage } from 'react-intl';

import KCStore from '../../../utils/kcStorage';

import $ from 'jquery';
import _ from 'lodash';

const mappingStringID = {
  "Total Quick Filters Completed": "KC1794",
  "Total Auto Filters Completed": "KC1909",
  "Total Quick Filters Bypassed": "KC1795",
  "Total Auto Filters Bypassed": "KC1910",
}

export class HolderChartPopup extends Component {
  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  state = {
    open: false
  };

  componentDidUpdate() {
    this.setModalMaxHeight();
  }

  setModalMaxHeight() {
    this.$element = $('.frypotInfoModal');
    this.$content = this.$element.find('.frypotInfoModal .modal-content');
    var borderWidth = this.$content.outerHeight() - this.$content.innerHeight();
    var dialogMargin = $(window).width() < 768 ? 20 : 60;
    var contentHeight = $(window).height() - (dialogMargin + borderWidth);
    var headerHeight = this.$element.find('.frypotInfoModal .modal-header').outerHeight() || 0;
    var maxHeight = contentHeight - ((headerHeight) + 70);

    this.$content.css({
      'overflow': 'hidden'
    });

    this.$element
      .find('.frpotInfoScrollBarOuter').css({
        'max-height': maxHeight,
        'overflow-y': 'auto'
      });
  }

  onCloseModal = (e) => {
    this.props.onCloseModal();
  };

  render() {
    const { open, holderID, productList } = this.props;
    let stackedBarChart = null;

		if (productList) {
			stackedBarChart = (<StackedBarChart chartData={productList} allowPopup={false} average={0} chartID={"totalFiletrsCompletedModalBarChartdiv"} filterType={this.props.filterType} dateAxis={true} />);
    }

    // Added for Frymaster McD CR
    const modelName = KCStore.getItem("modelFamilyName");
    const productName = _.upperCase(modelName);
    let filtrationWidgetsName = 'Quick Filters';
    if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
      filtrationWidgetsName = 'Auto Filters'
    }
    
    return (
      <Modal open={open} showCloseIcon={false} center onClose={() => { }}>
        {/* Frypot Info Modal */}
        <div class="modal fade frypotInfoModal show" data-keyboard="true" data-backdrop="static" id="TotalFiltersCompletedModal" tabindex="-1" role="dialog" aria-labelledby="TotalFiltersCompletedModalLabel" style={{ display: "block" }}>
          <div class="modal-dialog modal-lg frypotInfoModal">
            <div class="modal-content">
              <div class="modal-header modal-header-danger">
                {holderID ? holderID : "Frypot"} <button type="button" className="close" data-dismiss="modal" onClick={this.onCloseModal}>&nbsp;</button>
              </div>
              <div class="modal-body chartModalBody">
                <div class="frpotInfoScrollBarOuter">
                  <div class="chartOuterModalDiv">
                    {stackedBarChart}
                    <ul id="filtersChartlegend" class="filtersLegendSection">
                      <li><span></span>{mappingStringID[`Total ${filtrationWidgetsName} Completed`] ? <FormattedMessage id={mappingStringID[`Total ${filtrationWidgetsName} Completed`]}/> : `Total ${filtrationWidgetsName} Completed` }</li>  
                      <li><span></span>{mappingStringID[`Total ${filtrationWidgetsName} Bypassed`] ? <FormattedMessage id={mappingStringID[`Total ${filtrationWidgetsName} Bypassed`]}/> : `Total ${filtrationWidgetsName} Bypassed` }</li>   
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default HolderChartPopup;
