import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { format, addDays } from 'date-fns';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { getCleaningTableInfo } from '../../../actions/reportManagement/cleaningTableAction';
import { NO_RESULT_MSG } from '../../../utils/appConstants';
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import KCStore from '../../../utils/kcStorage';

var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var getMonth = require('date-fns/get_month');
var moment = require('moment');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class CookingTableData extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            type: 'Month',
            filterType: "Month",
            noResultMsg: 'No Results',
            sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
            sd: '',
            ed: '',
            startDate: '',
            endDate: '',
            loaderIconVisible: true,
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount = async () => {
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_NAME;
        this.props.getCleaningTableInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, '105');

        setTimeout(() => {
            this.setState({
                loaderIconVisible: false,
            });
        }, 2000)
    };

    componentDidUpdate = async (prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handlingCookingTableData(this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStart(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    handleChangeStart(filtrationDate) {
        if(this.state.startDate === filtrationDate){
            return;
        }
        this.setState({
            startDate: filtrationDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handlingCookingTableData(selectedtext, 'CookingTableData');
        });
    }

    handleChangeEnd(filtrationDate) {
        if(this.state.endDate === filtrationDate){
            return;
        }
        this.setState({
            endDate: filtrationDate,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            this.handlingCookingTableData(selectedtext, 'CookingTableData');
        });
    }

    onDropDownHandler(e) {
        const { nodeText:text } = e;
        this.setState({type:text, datapickflag: text === 'Date Range', startDate: '', endDate: ''});
        this.handlingCookingTableData(text);
    }

    onSort = sortColumn => {
        this.setState({
            sortColumn: sortColumn
        });
    }

    handlingCookingTableData(text) {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_NAME;
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else if (text === 'Week') {
			weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
			todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			var startDateMonth = getMonth(new Date(weekDateFormatted));
			var endDateMonth = getMonth(new Date(todaysDateFormatted));

			if (startDateMonth !== endDateMonth) {
				weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
			}
		}
        else {
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate, -6), 'YYYY-MM-DD');
        }
        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.props.getCleaningTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, '105');
        }
    }

    render() {
        const { filterTableInfo } = this.props;
        let { sortColumn, type } = this.state;
        let filterTableData = filterTableInfo ? filterTableInfo : {};
        let filterTableContent = filterTableData.content && filterTableData.content.length > 0 ? filterTableData.content : [];
        const displayContents = _.orderBy(filterTableContent, [sortColumn.path], [sortColumn.order]);
        let displayFilterDatas;
        let columns = [
            { path: "", label: "#" },
            { path: "key", labelStringID: "KC0170" },
            { path: "started", labelStringID: "KC0196" },
            { path: "cancelled", labelStringID: "KC0323" },
            { path: "completed", labelStringID: "KC0324" },
            { path: "", labelStringID: "KC0325" },
        ];
        if (Array.isArray(displayContents) && displayContents.length) {
            displayFilterDatas = displayContents.map((el, i) =>
                <React.Fragment key={el.key}>
                    <div className="garlandPrctTable">
                        <ul className="tBody" >
                            <li>{i + 1}</li>
                            <li>{el.key}</li>
                            <li>{el.started}</li>
                            <li>{el.cancelled}</li>
                            <li>{el.completed}</li>
                            <li><a role="button" data-toggle="collapse" draggable="false" data-parent="#garlandPrctAccordion" data-target={`#garlandPrctAcc_` + i} aria-expanded="false" aria-controls={`garlandPrctAcc_` + i} className="btn collapseExpand collapsed">&nbsp;</a></li>
                        </ul>
                    </div>

                    <div id={`garlandPrctAcc_` + i} className="panel-collapse collapse">
                        <div className="panel-body garlandPrctExpTable">
                            {el.details && el.details.map((items, j) =>
                            (<ul className="tBody" key={j}>
                                <li></li>
                                <li><FormattedMessage id="KC1788"/>{items.lane_id}</li>
                                <li>{items.started}</li>
                                <li>{items.cancelled}</li>
                                <li>{items.completed}</li>
                                <li></li>
                            </ul>)
                            )}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else {
            displayFilterDatas = (<div className="noResultMsg"><FormattedMessage id="KC0264"/></div>)
        }
        return (
            <div className="gridViewTable">
                <div className="gridView">
                    <div className="colm12D">
                        <div className="colmDi">
                            <h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
                                <span className="cornerIcon"></span>
                                <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate}
                                    minDateDisable={this.state.sdmindate}
                                    maxDateDisable={this.state.sdmaxdate}
                                    endminDateDisable={this.state.edmindate}
                                    endmaxDateDisable={this.state.edmaxdate}
                                    endDatepara={this.state.endDate}
                                    handleChangeStartProps={(date) => this.handleChangeStart(date)}
                                    handleChangeEndProps={(date) => this.handleChangeEnd(date)}
                                    OnChange={(e) => this.onDropDownHandler(e, "CookingTableData")}
                                    filterType={type}
                                    disableDatePicker={false} />
                            </h4>
                            {/* Cooking Data Table Starts  */}
                            <div className="garlandPrctTableDiv">

                                <div className="garlandPrctTableOuter">
                                    <div className="garlandPrctTable">
                                        <TableHeader
                                            sortColumn={sortColumn}
                                            onSort={this.onSort}
                                            columns={columns} />
                                    </div>
                                    <div className="panel panel-default" id="garlandPrctAccordion" role="tablist" aria-multiselectable="true">
                                        {/* <!-- Header 01 --> */}
                                        {displayFilterDatas}
                                        {/* <!-- Header 01 Content End --> */}
                                    </div>
                                </div>
                            </div>
                            {/* Cooking Data Table Ends */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        filterTableInfo: state.cleaningTableInformation.cleaningTableInformation,
        filterType: state.filterType.filterType,
        currentUnit: state.entityReducer.currentUnit,
        currentLocation: state.entityReducer.currentLocation
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCleaningTableInfo,
        getCurrentLocation,
        getCurrentUnit
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CookingTableData);
