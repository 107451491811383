import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import ErrorMoal from '../../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';

class FrymasterOQS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            disposeOilNow: "",
            disposeOilSoon: "",
            error_modal: {
                open: false,
                message: ""
            },
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                disposeOilNow: ObjSettingsFile.oqs.dispose_oil_now,
                disposeOilSoon: ObjSettingsFile.oqs.dispose_oil_soon
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        let { disposeOilNow, disposeOilSoon } = this.state;
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.oqs) {
            ObjSettingsFile.oqs = {}
        }

        if (disposeOilNow > 255 || disposeOilNow < 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1479"
                }
            });
            return;
        }
        if (disposeOilSoon > 255 || disposeOilSoon < 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1480"
                }
            });
            return;
        }
        ObjSettingsFile.oqs.dispose_oil_now = Number(disposeOilNow);
        ObjSettingsFile.oqs.dispose_oil_soon = Number(disposeOilSoon);
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/fryMasterSettings`);
    }

    onSaveWhileAction = (key, value) => {
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.oqs) {
            ObjSettingsFile.oqs = {};
        }
        ObjSettingsFile.oqs[key] = value;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
    }


    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSetValueChange = (e, key, localStateKey) => {
        const value = e.target.value;
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (value === '' || re.test(value)) {
            this.setState({
                [key]: value
            }, () => {
                this.onSaveWhileAction(localStateKey, value)
            });
        }
    }

    onBackNavigation = () => {
        this.props.history.push(`/fryMasterSettings`);
    }

    handleOnSliderChange = (value, stateKey) => {
        this.setState({
            [stateKey]: value
        })
    }

    handleNextPage = (route) => {
        const { disposeOilNow, disposeOilSoon } = this.state;
        if (disposeOilNow > 255 || disposeOilNow < 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1479"
                }
            });
            return;
        }
        if (disposeOilSoon > 255 || disposeOilSoon < 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1480"
                }
            });
            return;
        }
        this.props.history.push(route);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    render() {
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        const { disposeOilNow, disposeOilSoon, error_modal } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster OQS Header Page */}
                
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster OQS Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster OQS Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC0517"/>
                                </div>

                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li><FormattedMessage id="KC1436"/></li>
                                                        <li class="arrowBtn"><button type="button" class="btn nextButton" onClick={() => this.handleNextPage('/frymasterOQSOilType')}>&nbsp;</button></li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1481"/></li>
                                                        <li class="arrowBtn"><button type="button" class="btn nextButton" onClick={() => this.handleNextPage('/frymasterOQSReadingDisplay')}>&nbsp;</button></li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1482"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                placeholder={formatMessage({ id: 'KC2104' })}
                                                                onChange={(e) => this.onSetValueChange(e, "disposeOilSoon", 'dispose_oil_soon')}
                                                                value={disposeOilSoon}
                                                                class="form-control" />
                                                        </li>
                                                    </ul>
                                                    <ul class="borderNone">
                                                        <li><FormattedMessage id="KC1483"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                placeholder={formatMessage({ id: 'KC2104' })}
                                                                onChange={(e) => this.onSetValueChange(e, "disposeOilNow", 'dispose_oil_now')}
                                                                value={disposeOilNow}
                                                                class="form-control" /></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster OQS Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterOQS)
