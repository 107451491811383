import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const EventContentModal = ({ selectedNotification, addSpaceBetweenComma, intl }) => {
    const { formatMessage } = intl;
    return (
        <div className="modal fade contentViewModal" data-keyboard="true" data-backdrop="static" id="detailsViewModal" tabindex="-1" role="dialog" aria-labelledby="detailsViewModalLabel">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <div className="detailsViewHeader">
                            <ul>
                                <li>{selectedNotification.event_code || ''} | {selectedNotification.event_name || ''}
                                    <div className="eventTypeText">{selectedNotification.event}</div>
                                </li>
                                <li>{selectedNotification.brand}<span className="brandModel">{addSpaceBetweenComma(selectedNotification.model)}</span></li>
                            </ul>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                    </div>
                    <div className="modal-body">
                        {selectedNotification.event_description && <div className="detailsViewOuter">
                            <ul className="tHead">
                                <li><FormattedMessage id="KC0195"/></li>
                            </ul>
                            <ul className="tBody">
                                <li>{selectedNotification.event_description || ''}</li>
                            </ul>
                        </div>}
                        {selectedNotification.resolution && selectedNotification.resolution.length ? <div className="detailsViewOuter">
                            <ul className="tHead">
                                <li><FormattedMessage id="KC1697"/></li>
                            </ul>
                            <ul className="tBody">
                                <li>{selectedNotification.resolution && selectedNotification.resolution.length ? selectedNotification.resolution.map((action, index) => {
                                    return <p className="notificationResolutionStep" key={'action_' + index}>{index + 1}. {action}</p>
                                }) : null}</li>
                            </ul>
                        </div> : null}
                        <div className="detailsViewOuter">
                            <ul className="tHead">
                                <li>{selectedNotification.video_url && selectedNotification.video_url !== "" && <FormattedMessage id="KC1698"/>}</li>
                                <li>{selectedNotification.other_link_url && selectedNotification.other_link_url.website_link && selectedNotification.other_link_url.website_link !== "" && <FormattedMessage id="KC1699"/>}</li>
                            </ul>
                            <ul className="tBody">
                                <li>{selectedNotification.video_url && selectedNotification.video_url !== "" && <button className="btn urlButton" onClick={() => this.openLink(selectedNotification.video_url)} type="button"><img src={require('./../../../images/icons/MovieA.svg').default} alt={ formatMessage({ id: 'KC1698' }) } title={ formatMessage({ id: 'KC1698' }) } /> <FormattedMessage id="KC1700"/></button>}</li>
                                <li>{selectedNotification.other_link_url && selectedNotification.other_link_url.website_link && selectedNotification.other_link_url.website_link !== "" && <button className="btn urlButton" onClick={() => this.openLink(selectedNotification.other_link_url.website_link)} type="button"><img src={require('./../../../images/icons/languageA.svg').default} alt={ formatMessage({ id: 'KC1701' }) } title={ formatMessage({ id: 'KC1701' }) } /> <FormattedMessage id="KC1702"/></button>}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(EventContentModal);