import {AVERAGE_COOK_COUNT_DATA_REQUEST,AVERAGE_COOK_COUNT_DATA_SUCCESS,AVERAGE_COOK_COUNT_DATA_FAIL} from '../../../actions/unitDashboard/averageCookCountAction';
const initialState = {
    loaded: false,
    loading: false,
    averageCookCountData:{}
}

export default function reducer (state = initialState, action = {}) {

    switch (action.type) {
        case AVERAGE_COOK_COUNT_DATA_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
            }
        case AVERAGE_COOK_COUNT_DATA_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                averageCookCountData: action.averageCookCountData
            }
        case AVERAGE_COOK_COUNT_DATA_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
            }
        default:
            return state
    }
}
