import {SET_MENU_LIST, SET_MENU_LIST_LOADING } from '../../../actions/stateActionsData/type';
const initialState = {
    menuList:null,
    isLoading: false
}
export default function(state = initialState, action){
    switch(action.type) {
        case SET_MENU_LIST:
        return {
            ...state,
            menuList: action.payload
        }
        case SET_MENU_LIST_LOADING: 
        return {
            ...state,
            isLoading: action.payload
        }
        default:
        return state;
    }
}