import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFilterbyCountries,getFilterbyRegions,getFilterbyLocations,getFilterbyCustomers,getFilterbyBrands,getFilterbyModels } from './../../../actions/listView/locations/filterbyAction';
import  LocationHeader  from './../../layoutSection/allLocationHeaderLayout';
 

class FilterBy extends Component {

	constructor(props) {
		super(props);
		this.state = {			
			isLoaded: false,			
			IsCountryFilterClear:false,
			selectedCountries:[],
			selectedRegions:[],
			selectedLocations:[],
			selectedCustomers:[],
			selectedBrands:[],
			selectedModels:[],
		};	
		//this.goBack = this.goBack.bind(this);
	}

	componentDidMount(){
		
		let selctedCountryList  =  this.props.selectedCountries	? 	 this.props.selectedCountries	:[];
		let selectedRegionList  =  this.props.selectedRegions ? this.props.selectedRegions :[];
		let selectedLocationList = this.props.selectedLocations ? this.props.selectedLocations :[];
		let selectedCustomerList = this.props.selectedCustomers ? this.props.selectedCustomers :[];
		let selectedBrandList	=	this.props.selectedBrands ? this.props.selectedBrands :[];
		let selectedModelList	=	this.props.selectedModels ? this.props.selectedModels :[];
 
		this.setState({
			selectedCountries: selctedCountryList,
			selectedRegions: selectedRegionList,
			selectedLocations: selectedLocationList,
			selectedCustomers: selectedCustomerList,
			selectedBrands: selectedBrandList,
			selectedModels: selectedModelList,
			locationRedirect: this.props.location
		}); 
	}
	
	
	removeValue = (item) =>
	{
		//Country
		let selctedCountryList  = this.props.selectedCountries	   
		selctedCountryList && selctedCountryList.length > 0 && selctedCountryList.find(c =>{ if(c.cust_gp_id === item.cust_gp_id && c.group_name === item.group_name){
			c.checked = false 
	    }});
		 this.props.getFilterbyCountries(selctedCountryList);

		//Chain
		 let selectedRegionList = this.props.selectedRegions
		 selectedRegionList && selectedRegionList.length > 0 && selectedRegionList.find(c => { if(c.cust_gp_id === item.cust_gp_id  && c.group_name === item.group_name){
			c.checked = false 
		}});		
		this.props.getFilterbyRegions(selectedRegionList);

		//Location
		let selectedLocationList = this.props.selectedLocations
		selectedLocationList && selectedLocationList.length > 0 && selectedLocationList.find(c => { if(c.cust_gp_id === item.cust_gp_id && c.group_name === item.group_name) {
			c.checked = false
		}});
		this.props.getFilterbyLocations(selectedLocationList);

		//Customer
		let selectedCustomerList = this.props.selectedCustomers
		selectedCustomerList && selectedCustomerList.length > 0 && selectedCustomerList.find(c => {if(c.ID === item.ID && c.COMPANY_NAME === item.COMPANY_NAME){
			c.checked = false;
		}})
		this.props.getFilterbyCustomers(selectedCustomerList);

		//Brand
		let selectedBrandList = this.props.selectedBrands
		selectedBrandList && selectedBrandList.length > 0 && selectedBrandList.find(c => {if(c.ID === item.ID && c.brand_name === item.brand_name){
			c.checked = false;
		}})
		this.props.getFilterbyBrands(selectedBrandList);

		//Model
		let selectedModelList = this.props.selectedModels
		selectedModelList && selectedModelList.length > 0 && selectedModelList.find(c => {if(c.ID === item.ID && c.product_name === item.product_name){
			c.checked = false;
		}})
		this.props.getFilterbyModels(selectedModelList);

		 this.setState({
			selectedCountries: selctedCountryList,
			selectedRegions: selectedRegionList,
			selectedLocations: selectedLocationList,
			selectedCustomers: selectedCustomerList,
			selectedBrands:selectedBrandList,
			selectedModels:selectedModelList,
		});

		var d = document.getElementById("spannavNotifiStatus");
		if(selctedCountryList.length > 0 || selectedRegionList.length > 0 || selectedLocationList.length > 0 ||selectedCustomerList.length > 0 ||selectedBrandList.length > 0 || selectedModelList.length > 0)
		{
			d.className = "navNotifiStatus";
		}
		else
		{
			d.className = "";
		}
	}


	ClearAll = ()=>{	
		
		//Country
		let selctedCountryList  =[...this.props.selectedCountries]	
	  	selctedCountryList && selctedCountryList.length>0 && selctedCountryList.map((item, i) =>{ return item.checked=false; });
		this.props.getFilterbyCountries(selctedCountryList);

		//Chain
		let selectedRegionList =[...this.state.selectedRegions]
		selectedRegionList && selectedRegionList.length>0 && selectedRegionList.map((item, i) => { return item.checked=false; });
		this.props.getFilterbyRegions(selectedRegionList);

		//Location
		let selectedLocationList =[...this.state.selectedLocations]
		selectedLocationList && selectedLocationList.length>0 && selectedLocationList.map((item, i) => { return item.checked=false; });
		this.props.getFilterbyLocations(selectedLocationList);

		//Customer
		let selectedCustomerList =[...this.state.selectedCustomers]
		selectedCustomerList && selectedCustomerList.length>0 && selectedCustomerList.map((item, i) => { return item.checked=false; });
		this.props.getFilterbyCustomers(selectedCustomerList);

		//Brand
		let selectedBrandList =[...this.state.selectedBrands]
		selectedBrandList && selectedBrandList.length>0 && selectedBrandList.map((item, i) => { return item.checked=false; });
		this.props.getFilterbyBrands(selectedBrandList);

		//Brand
		let selectedModelList =[...this.state.selectedModels]
		selectedModelList && selectedModelList.length>0 && selectedModelList.map((item, i) => { return item.checked=false; });
		this.props.getFilterbyBrands(selectedModelList);

		this.setState({
			selectedCountries: selctedCountryList,
			selectedRegions: selectedRegionList,
			selectedLocations: selectedLocationList,
			selectedCustomers: selectedCustomerList,
			selectedBrands: selectedBrandList,
			selectedModels: selectedModelList,
		});

		var d = document.getElementById("spannavNotifiStatus");
		d.className = "";
	}	

	render() {

		let { selectedCountries : selctedCountryList }  =  this.state;
		let { selectedRegions : selectedRegionList } = this.state;
		let { selectedLocations: selectedLocationList } = this.state;
		let { selectedCustomers: selectedCustomerList } = this.state;
		let { selectedBrands: selectedBrandList } = this.state;	
		let { selectedModels: selectedModelList } = this.state;	
		let previouspage = "/dashboardLocations"; 

		if (this.props.location.state !== undefined)
		{			
			if (this.props.location.state.mediaHide)
			{
				previouspage= "/assignMediaList";
			}
			else{				
				previouspage = "/" + this.props.location.state.prevPath.previouspage.replace("/","");
			}	

			if (this.props.location.state.prevPath.previouspage=== "/dashBoardLocations")
			{
				this.props.location.state.mediaHide=false;
			}
			else{
				this.props.location.state.mediaHide=true;
			}
		}
		
		return (
				<div>
					<div id="wrapper" className="disableNavLink">
						<div class="toggleTransparent"></div>
						<div class="loadingProgress">
							<div class="loadingProgressIn"></div>
						</div>
						<LocationHeader />
						<div class="navFilterWrapper" style={{display: 'block'}}>
							<div id="filterListDiv">
										<div class="filterHeader">
											<ul>
												<li>&nbsp;</li>
												<li>
													Filter By
												</li> 
												<li>
													<Link to={previouspage} title="Finish">Finish</Link>
												</li>
											</ul>
										</div> 
										<div class="filterListOuter">											
											<div class="filterCategoryTable"> 
												<ul> 
													<li>Country</li>
													<li>  
														{Object.keys(selctedCountryList).map((item, i) => (
														  selctedCountryList[i].checked &&	<div class="selectedCountry alert alert-dismissible fade show" role="alert">												
																<button type="button" class="close" onClick={() => this.removeValue(selctedCountryList[i])}  value ={selctedCountryList[i]} data-dismiss="alert" aria-label="Close">
																	  <span aria-hidden="true"> &times;</span>																 
																 
																</button>																
																{selctedCountryList[i].group_name}																				
															</div>
														   ))} 
													</li>
													<li>														
														<Link to={{pathname:'/locationsView/filterBy/filterByCountry', state : {prevSelectedFilterValue: selctedCountryList, prevPath: {previouspage}} }}  class= {this.props.location.state.mediaHide ? "nextIcon disabled":"nextIcon"} id="CountryDiv">&nbsp;</Link>
													</li>
												</ul> 
												<ul> 
													<li>Sub Group</li>
													<li>																											  														 
													{Object.keys(selectedRegionList).map((item, i) => (
														selectedRegionList[i].checked && <div class="selectedCountry alert alert-dismissible fade show" role="alert">												
																<button value={selectedRegionList[i]}  onClick={(item) => this.removeValue(selectedRegionList[i])}  type="button" class="close" data-dismiss="alert" aria-label="Close">
																	<span aria-hidden="true">&times;</span>																	
																	
																</button>
																{selectedRegionList[i].group_name}				
															</div>
														   ))} 													
													</li>
													
													<li>
														<Link to={{pathname:'/locationsView/filterBy/filterByRegion', state : {prevSelectedFilterValue: selectedRegionList, prevPath: {previouspage}}}} class= {this.props.location.state.mediaHide ? "nextIcon disabled":"nextIcon"}  id="RegionDiv">&nbsp;</Link>
													</li>													
												</ul> 
												<ul> 
													<li>Location</li>
													<li>
													{Object.keys(selectedLocationList).map((item, i) => (
															selectedLocationList[i].checked && <div class="selectedCountry alert alert-dismissible fade show" role="alert">												
																<button value={selectedLocationList[i]} onClick={(item) => this.removeValue(selectedLocationList[i])} type="button" class="close" data-dismiss="alert" aria-label="Close">
																	<span aria-hidden="true">&times;</span>	
																</button>
																{selectedLocationList[i].group_name}				
															</div>
														   ))} 	
													</li>
													<li><Link to={{pathname:'/locationsView/filterBy/filterByLocation', state : {prevSelectedFilterValue: selectedLocationList, prevPath: {previouspage}}}} class= {this.props.location.state.mediaHide ? "nextIcon disabled":"nextIcon"}  id="LocationDiv">&nbsp;</Link></li>
												</ul> 
												<ul> 
													<li>Customer</li>
													<li>
													{Object.keys(selectedCustomerList).map((item, i) => (
															selectedCustomerList[i].checked && <div class="selectedCountry alert alert-dismissible fade show" role="alert">												
																<button value={selectedCustomerList[i]} onClick={(item) => this.removeValue(selectedCustomerList[i])}type="button" class="close" data-dismiss="alert" aria-label="Close">
																	<span aria-hidden="true">&times;</span>	
																</button>
																{selectedCustomerList[i].COMPANY_NAME}				
															</div>
														   ))} 	
													</li>
													<li><Link to={{pathname:'/locationsView/filterBy/filterByCustomer',state : {prevSelectedFilterValue: selectedCustomerList, prevPath: {previouspage} }}} class="nextIcon" id="CustomerDiv">&nbsp;</Link></li>
												</ul> 
												<ul> 
													<li>Brand</li>
													<li>
													{Object.keys(selectedBrandList).map((item, i) => (														
															selectedBrandList[i].checked && <div class="selectedCountry alert alert-dismissible fade show" role="alert">												
																<button  value={selectedBrandList[i]} onClick={(item) => this.removeValue(selectedBrandList[i])} type="button" class="close" data-dismiss="alert" aria-label="Close">
																	<span aria-hidden="true">&times;</span>																
																</button>
																{selectedBrandList[i].BRAND_NAME}				
															</div>
														   ))} 														
													</li>
													<li><Link to={{pathname:'/locationsView/filterBy/filterByBrand',state : {prevSelectedFilterValue: selectedBrandList, prevPath: {previouspage}}}} class="nextIcon" id="CustomerDiv">&nbsp;</Link></li>													
												</ul> 
												<ul> 
													<li>Model</li>
													<li>
													{Object.keys(selectedModelList).map((item, i) => (
															selectedModelList[i].checked && <div class="selectedCountry alert alert-dismissible fade show" role="alert">												
																<button   value={selectedModelList[i]} onClick={(item) => this.removeValue(selectedModelList[i])}  type="button" class="close" data-dismiss="alert" aria-label="Close">
																	<span aria-hidden="true">&times;</span>																	
																</button>
																{selectedModelList[i].MODEL_NAME}				
															</div>
														   ))} 	
													</li>
													<li><Link to={{pathname:'/locationsView/filterBy/filterByModel',state : {prevSelectedFilterValue: selectedModelList, prevPath: {previouspage}}}} class="nextIcon" id="ModelDiv">&nbsp;</Link></li>
												</ul> 
											</div>
											<div class="clearBtnDiv"><button onClick={() => this.ClearAll()}  type="button" class="btn">Clear All</button></div>
											{/* <div class="advancedBtnDiv"><button type="button" class="btn">Advanced Filter Options</button></div> */}
									</div>
								</div>
						</div>
    				</div>
           		 </div>  
				)
			}
		};

		function mapStateToProps(state) {
			return {
				selectedCountries: state.filterInformation.selectedCountries,
				selectedRegions: state.filterInformation.selectedRegions,
				selectedLocations: state.filterInformation.selectedLocations,
				selectedCustomers: state.filterInformation.selectedCustomers,
				selectedBrands: state.filterInformation.selectedBrands,
				selectedModels: state.filterInformation.selectedModels
			}
		}

		function mapDispatchToProps(dispatch) {
			return bindActionCreators({ getFilterbyCountries,getFilterbyRegions,getFilterbyLocations,getFilterbyCustomers,getFilterbyBrands,getFilterbyModels }, dispatch);			
		}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBy);