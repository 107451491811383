import {PURPOSES_REQUEST,PURPOSES_SUCCESS,PURPOSES_FAIL} from '../../../../actions/listView/locations/purposesAction';

const initialState = {
    loaded: false,
    loading: false,
    purposesInformation: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case PURPOSES_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                isServerActionComplete : false
               
            }
        case PURPOSES_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                purposesInformation: action.purposesInformation,
                isServerActionComplete : true
            }
        case PURPOSES_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
                isServerActionComplete : true

            }
        default:
            return state
    }
}