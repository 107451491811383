import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';
import $ from 'jquery';
class ChangeScreensaver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            screen_saver_type: ""
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onScreensaverTypeSelect = this.onScreensaverTypeSelect.bind(this);
        this.closeModel = this.closeModel.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            let jsonlibraryDetails = KCStore.getItem("libraryDetails");
            let libraryDetails = JSON.parse(jsonlibraryDetails);

            this.setState({
                screen_saver_type: ObjSettingsFile.screen_saver.type,
                libraryDetails: libraryDetails
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/screensaver",
            isEdit: false,
            previouspath: "/changeScreensaver"
        });
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true
        });
        let { screen_saver_type } = this.state;
        if (screen_saver_type) {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            ObjSettingsFile.screen_saver.type = screen_saver_type;
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            KCStore.setItem("librarySettingsData", strSettingsFile);
            this.props.history.push(`/screensaver`);
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1320"
                }
            });
        }
    }

    onScreensaverTypeSelect = (e) => {
        this.setState({
            screen_saver_type: e.target.value
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/screensaver`);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    render() {
        let { screen_saver_type, error_modal, success_modal } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1323"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01 fullWidth">
                                                    <ul>
                                                        <li>
                                                            <div class="rcScreensaverList">
                                                                <ul>
                                                                    <li>
                                                                        <span class="ecSSAnimations anBounce"><img src={require("../../../../../images/media/logo.png")}  alt=""/></span>
                                                                    </li>
                                                                    <li><FormattedMessage id="KC1324"/></li>
                                                                    <li>
                                                                        {/* <button type="button" class="btn previewButton">Preview <img src={require("../../../../../images/recipecreation/previewButton.png")}  alt=""/></button> */}
                                                                    </li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onScreensaverTypeSelect}>
                                                                            <input id="Bounce" type="radio" name="animations" value="Bounce" checked={screen_saver_type === "Bounce" ? true : false} />
                                                                            <label for={formatMessage({ id: 'KC1324' })} value="Bounce">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcScreensaverList">
                                                                <ul>
                                                                    <li>
                                                                        <span class="ecSSAnimations anBounceScroll"><img src={require("../../../../../images/media/logo.png")}  alt=""/><img src={require("../../../../../images/media/logo.png")}  alt=""/></span>
                                                                    </li>
                                                                    <li><FormattedMessage id="KC1325"/></li>
                                                                    <li>
                                                                        {/* <button type="button" class="btn previewButton">Preview <img src={require("../../../../../images/recipecreation/previewButton.png")}  alt=""/></button> */}
                                                                    </li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onScreensaverTypeSelect}>
                                                                            <input id="PopBounce" type="radio" name="animations" value="Logo bounce" checked={screen_saver_type === "Logo bounce" ? true : false} />
                                                                            <label for="PopBounce" value="Logo bounce">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcScreensaverList">
                                                                <ul>
                                                                    <li>
                                                                        <span class="ecSSAnimations anPopBounce"><img src={require("../../../../../images/media/logo.png")}  alt=""/></span>
                                                                    </li>
                                                                    <li><FormattedMessage id="KC1326"/></li>
                                                                    <li>
                                                                        {/* <button type="button" class="btn previewButton">Preview <img src={require("../../../../../images/recipecreation/previewButton.png")} alt="" /></button> */}
                                                                    </li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onScreensaverTypeSelect}>
                                                                            <input id="BounceScroll" type="radio" name="animations" value="Pop and Scroll" checked={screen_saver_type === "Pop and Scroll" ? true : false} />
                                                                            <label for="BounceScroll" value="Pop and Scroll">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcScreensaverList">
                                                                <ul>
                                                                    <li>
                                                                        <span class="ecSSAnimations anPopBounce"><img src={require("../../../../../images/recipecreation/scatterImg.png")}  alt=""/></span>
                                                                    </li>
                                                                    <li><FormattedMessage id="KC2246"/></li>
                                                                    <li>
                                                                    </li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onScreensaverTypeSelect}>
                                                                            <input id="Scatter" type="radio" name="animations" value="Scatter" checked={screen_saver_type === "Scatter" ? true : false} />
                                                                            <label for="Scatter" value="Scatter">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(ChangeScreensaver)
