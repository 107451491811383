import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { CustomDropDownList } from '../../controls/customDropDownList';
import { getNumberOfHoldingCycles } from '../../../actions/reportManagement/numberOfHoldingCyclesActions';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { UNIT_BRAND_ID, NO_OF_HOLDING_CYCLES_WIDGET_ID, FRYMASTER_UHCTHD_NO_OF_HOLDING_CYCLES_WIDGET_ID } from '../../../utils/appConstants';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';
import KCStore from '../../../utils/kcStorage';


// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'MM-DD-YYYY');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'MM-DD-YYYY');
let unitID = "";
let brandID = "";
let currentUnit;

class TotalHoldingCycles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            locationInformation: {},
            deviceInformation: {},
            sd: '',
            ed: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            startDateOnTime: "",
            endDateOnTime: "",
            datapickflag: false
        }
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_NO_OF_HOLDING_CYCLES_WIDGET_ID : NO_OF_HOLDING_CYCLES_WIDGET_ID;
        this.props.getNumberOfHoldingCycles("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
    }

    componentDidUpdate = async(prevProp, prevState) => {
        if(this.props.globalDropdownFilter === 'Quarter' || this.props.globalDropdownFilter === 'Year'){
            return;
        }
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleHoldingCycle(this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDateOnTime !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEndOnTime(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDateOnTime !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStartOnTime(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //start Date Change Handler
    handleChangeStartOnTime(onTimeDate) {
        if(this.state.startDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            startDateOnTime: onTimeDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleHoldingCycle(selectedtext);
        });
    }

    //end Date Change Handler
    handleChangeEndOnTime(onTimeDate) {
        if(this.state.endDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            endDateOnTime: onTimeDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleHoldingCycle(selectedtext);
        });
    }

    //dropdown Handler
    onDropDownHandler(e) {
        const { nodeText: text } = e;
        this.setState({ type: text, datapickflag: text === 'Date Range', startDateOnTime: '', endDateOnTime: ''})
        this.handleHoldingCycle(text);
    }

    handleHoldingCycle(text) {
        currentUnit = this.props.currentUnit;
        const { startDateOnTime, endDateOnTime } = this.state;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            todaysDateFormatted = format(endDateOnTime, 'YYYY-MM-DD');
            weekDateFormatted = format(startDateOnTime, 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }
        if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_NO_OF_HOLDING_CYCLES_WIDGET_ID : NO_OF_HOLDING_CYCLES_WIDGET_ID;
            this.props.getNumberOfHoldingCycles(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
        }
    }

    render() {

        const { noOfHoldingCyclesInfo } = this.props;
        const noOfHoldingCyclesInformation = noOfHoldingCyclesInfo.noOfHoldingCyclesResponse ? noOfHoldingCyclesInfo.noOfHoldingCyclesResponse : {};
        let noOfHoldingCycleModel = noOfHoldingCyclesInformation.content;
        let currentValue = 0;
        let commonFilterConsumptionValue = null;

        const isTrend = _.isUndefined(noOfHoldingCycleModel) ? false : _.isObject(_.find(noOfHoldingCycleModel, el => el.key === 'trend'));
        currentValue = noOfHoldingCycleModel && noOfHoldingCycleModel[0] ? noOfHoldingCycleModel[0].value : "";
        //
        let noOfHoldingCyclesWidget = null;
        if (Array.isArray(noOfHoldingCycleModel) && noOfHoldingCycleModel.length) {

            commonFilterConsumptionValue = (<div className="valH1Div"> { noOfHoldingCycleModel[0].value} {(isTrend && this.state.type !== "Date Range") && (<span className={noOfHoldingCycleModel[2].value === '1' ? "valH1Span valHigh" : noOfHoldingCycleModel[2].value === '0' ? "valH1Span valLow" : "valH1Span"}> {noOfHoldingCyclesInformation.unitTime}&nbsp;</span>)}</div>);
            noOfHoldingCyclesWidget = (
                <div class="colmDiVal">
                    { commonFilterConsumptionValue}
                    { (isTrend && this.state.type !== "Date Range") && (<div class="valH5Div">
                    { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }: {noOfHoldingCycleModel[1].value}
                    </div>)}
                </div>
            )
        }
        // Merco Over All Pan Utilization Widget
        let totalHoldingCyclesWidget = null;
        totalHoldingCyclesWidget = (<div class="colmDi">
            <h4><FormattedMessage id='KC0353'/>
                    <span className="cornerIcon"></span>
                <CustomDropDownList translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDateOnTime} maxDateDisable={this.state.sdmaxdate} minDateDisable={this.state.sdmindate}
                    endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateOnTime} handleChangeStartProps={(date) => this.handleChangeStartOnTime(date)} handleChangeEndProps={(date) => this.handleChangeEndOnTime(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.type} />
            </h4>
            {noOfHoldingCyclesWidget}
        </div>)

        return (
            <React.Fragment>
                <div className="colm4D">
                    {totalHoldingCyclesWidget}
                </div>
            </React.Fragment>
        )
    }
};
function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        noOfHoldingCyclesInfo: state.noOfHoldingCyclesResponse,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrentUnit, getCurrentLocation, getNumberOfHoldingCycles }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalHoldingCycles);
