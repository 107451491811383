

import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import SmartTagTopology from './smartTagTopology';
import { FormattedMessage } from 'react-intl';

const TagOrgModal = ({ details, removeState }) => {
    const [detailValue, setDetailValue ] = useState({});

    useEffect(() => {
        if(details){
            setDetailValue(details);
        }
    }, [details])
    
    return (
        <div className="modal fade orgViewModal" data-keyboard="true" data-backdrop="static" id="orgViewModal" tabIndex="-1" role="dialog" aria-labelledby="orgViewModalLabel">
            <div className="modal-dialog modal-lg">
                <div className="modal-content" style={{ minHeight: $(window).height() - 120 }}>
                    <div className="modal-header modal-header-danger">
                    <FormattedMessage id="KC2372" /> <button type="button" className="close" onClick={()=>$('#stUnitDetailsModal').modal('show')} data-dismiss="modal" aria-label="Close" >&nbsp;</button>
                    </div>
                    <div className="modal-body">
                        {detailValue && <SmartTagTopology details={detailValue} isView={true} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TagOrgModal;