import React, { Component } from 'react';
import Modal from "react-responsive-modal";

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class ReportInfoModal extends Component {

    nextPath(path) {
		this.props.history.push(path);
	}
constructor(props){
    super(props);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    state = {
        open: false
    };

    onCloseModal = () => {
      this.props.onCloseModal();
    };

    render() {
        const { open, title, message } = this.props;
       
        return (
            <div style={styles}>
                <Modal open={open} showCloseIcon={false} center onClose={()=>{}}>
                    <div className="modal fade infoModal show" data-keyboard="true" data-backdrop="static" id="detailsInfoModal" tabIndex="-1" role="dialog" style={{ display: "block", paddingRight: "12px" }}>
					<div className="modal-dialog modal-md infoModal">
						<div className="modal-content">
							<div className="modal-header modal-header-danger">
								{title} <button type="button" className="close" data-dismiss="modal" onClose={() => this.onCloseModal()}>&nbsp;</button>
							</div>
							<div className="modal-body">
								<div className="detailsInfoContent">
									<p>{message}Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                </Modal>
            </div>
        );
    }
}
export default ReportInfoModal;