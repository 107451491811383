import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';
require('jquery-mousewheel')

const defaultState = [
    {
        schedule_id: 1,
        start_time: '',
        end_time: '',
        scheduled_day: [],
        rotate_fry_pot: 0,
        frypot_value: []
    }
]

class FrymasterEcoModeSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            scheduleMode: defaultState,
            hourFormat: 'false',
            timeMask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            window.addEventListener('resize', this.updateDimensions);
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            if (ObjSettingsFile.general.time_date) {
                this.setState({
                    hourFormat: ObjSettingsFile.general.time_date.hour_format === 1 ? "true" : "false"
                })
            }
            if (ObjSettingsFile.eco_mode.automatic_eco_mode) {
                const scheduleMode = ObjSettingsFile.eco_mode.automatic_eco_mode.automatic_scheduled_mode;
                this.setState({
                    scheduleMode
                })
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions() {
        $("#scrollDownButton").on("click", function () {
            $(".mCustomScrollbar").mCustomScrollbar("update");
            setTimeout(function(){
                $(".mCustomScrollbar").mCustomScrollbar('scrollTo','bottom');
            },100);
        });
    }

    onConfirmButtonClick = () => {
        let { scheduleMode } = this.state;
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);

        if (!ObjSettingsFile.eco_mode.automatic_eco_mode.automatic_scheduled_mode) {
            ObjSettingsFile.eco_mode.automatic_eco_mode.automatic_scheduled_mode = []
        }

        ObjSettingsFile.eco_mode.automatic_eco_mode.automatic_scheduled_mode = scheduleMode;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterAutomaticEcoMode`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterAutomaticEcoMode`);
    }

    onToggleChange = (e, stateKey) => {
        this.setState({
            [stateKey]: e.target.getAttribute('aria-pressed')
        });
    }

    onCheckValue = (e, key) => {
        this.setState({
            [key]: e.target.checked
        });
    }

    onSetValueChange = (e, key) => {
        this.setState({
            [key]: e.target.value
        });
    }

    checkFrypotCheched = (value, id) => {
        const { scheduleMode } = this.state;
        const currentSchedule = scheduleMode.filter(i => i.schedule_id === id);

        return currentSchedule.length ? currentSchedule[0].frypot_value === value : false;
    }

    updateValues = (value, key, id) => {
        const { scheduleMode } = this.state;
        let updateValue = scheduleMode;
        const findIndex = scheduleMode.findIndex(i => i.schedule_id === id);
        updateValue[findIndex][key] = value;

        let startsWith = value[0];

        this.setState({
            timeMask: [
                this.state.hourFormat === "false" ? /[0-2]/ : /[0-1]/,
                this.state.hourFormat === "true" && startsWith === "1" ? /[0-2]/ : this.state.hourFormat === "false" ? /[0-3]/ : /[0-9]/,
                ':',
                this.state.hourFormat === "true" ? /[0-0]/ : /[0-5]/,
                this.state.hourFormat === "true" ? /[0-0]/ : /[0-9]/
            ], scheduleMode: updateValue
        })
    }


    updateMultiSelectValues = (value, key, id) => {
        const { scheduleMode } = this.state;
        let updateValue = scheduleMode;
        const findIndex = scheduleMode.findIndex(i => i.schedule_id === id);

        if (!updateValue[findIndex][key].includes(value)) {
            updateValue[findIndex][key].push(value);
        } else {
            var index = updateValue[findIndex][key].indexOf(value)
            if (index !== -1) {
                updateValue[findIndex][key].splice(index, 1);
            }
        }

        this.setState({
            scheduleMode: updateValue
        })
    }

    getScheduledId = () => {
        const { scheduleMode } = this.state;

        return Number(scheduleMode[scheduleMode.length - 1]['schedule_id']) + 1

    }
    appendNewRow = () => {
        const { scheduleMode } = this.state;
        let appendRow = scheduleMode;
        const defaultConfig = {
            schedule_id: this.getScheduledId(),
            start_time: '',
            end_time: '',
            scheduled_day: [],
            rotate_fry_pot: 0,
            frypot_value: []
        }
        appendRow.push(defaultConfig);
        this.setState({ scheduleMode: appendRow })
    }

    deleteScheduledRow = (id) => {
        const { scheduleMode } = this.state;
        if (scheduleMode.length === 1) {
            return;
        }
        let deleteRow = scheduleMode.filter(i => i.schedule_id !== id);
        this.setState({ scheduleMode: deleteRow })
    }

    showFrypodValues = (id) => {
        const { scheduleMode } = this.state;
        const currentSchedule = scheduleMode.filter(i => i.schedule_id === id);
        return currentSchedule.length ? currentSchedule[0].rotate_fry_pot : false;
    }


    render() {
        const { formatMessage } = this.props.intl;
        let brandid = KCStore.getItem("selectedbrandID");

        const { scheduleMode, timeMask, hourFormat } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Eco Mode Schedule Header Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Eco Mode Schedule Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1490"/>
                                </div>


                                {/* {Schedule Wrapper} */}
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                {scheduleMode ? scheduleMode.map((ecoMode, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div class="frymasterRCForm">
                                                                <ul class="borderNone">
                                                                    <li><FormattedMessage id="KC0573"/> {ecoMode.schedule_id}</li>
                                                                    <li><button type="button" onClick={() => this.deleteScheduledRow(ecoMode.schedule_id)} class="btn deleteButton">&nbsp;</button></li>
                                                                </ul>
                                                            </div>
                                                            <div class="frymasterRCScdlForm">
                                                                <ul>
                                                                    <li>
                                                                        <label><FormattedMessage id="KC0577"/></label>
                                                                        <InputMask
                                                                            mask={timeMask}
                                                                            maskChar=""
                                                                            placeholder={hourFormat === "true" ? formatMessage({ id: 'KC1973' }) : formatMessage({ id: 'KC0836' })}
                                                                            onChange={(e) => this.updateValues(e.target.value, 'start_time', ecoMode.schedule_id)}
                                                                            value={ecoMode.start_time}
                                                                            class="form-control timeField" />
                                                                    </li>
                                                                    <li>
                                                                        <label>End</label>
                                                                        <InputMask
                                                                            mask={timeMask}
                                                                            maskChar=""
                                                                            placeholder={hourFormat === "true" ? formatMessage({ id: 'KC1973' }) : formatMessage({ id: 'KC0836' })}
                                                                            onChange={(e) => this.updateValues(e.target.value, 'end_time', ecoMode.schedule_id)}
                                                                            value={ecoMode.end_time}
                                                                            class="form-control timeField" />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="frymasterRCForm">
                                                                <ul>
                                                                    <li class="width10px">&nbsp;</li>
                                                                    <li>
                                                                        <div class="scheduledDaysList">
                                                                            {[{ label: "Monday", value: "Monday" }, { label: "Tuesday", value: "Tuesday" }, { label: "Wednesday", value: "Wednesday" }, { label: "Thursday", value: "Thursday" }, { label: "Friday", value: "Friday" }, { label: "Saturday", value: "Saturday" }, { label: "Sunday", value: "Sunday" }].map((frypot, index) => {
                                                                                return (
                                                                                    <div key={index} class="customCheckbox">
                                                                                        <input
                                                                                            id={`${frypot.value}${ecoMode.schedule_id}`}
                                                                                            type="checkbox"
                                                                                            name={`Scheduled${ecoMode.schedule_id}`}
                                                                                            value={frypot.value}
                                                                                            targetId={ecoMode.schedule_id}
                                                                                            onChange={(e) => {
                                                                                                // this.updateValues(e.target.value, 'scheduled_day', ecoMode.schedule_id)
                                                                                                this.updateMultiSelectValues(e.target.value, 'scheduled_day', ecoMode.schedule_id)
                                                                                            }}
                                                                                            checked={ecoMode.scheduled_day.includes(frypot.value)} />
                                                                                        <label for={`${frypot.value}${ecoMode.schedule_id}`}>{frypot.label}</label>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="frymasterRCForm">
                                                                <ul class="borderNone">
                                                                    <li class="width190px"><FormattedMessage id="KC1491"/></li>
                                                                    <li class="smallFormFields">
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <span class={ecoMode.rotate_fry_pot === 0 ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1360"/></span>
                                                                            <Switch
                                                                                onChange={(e) => this.updateValues(e ? 1 : 0,'rotate_fry_pot', ecoMode.schedule_id)}
                                                                                checked={ecoMode.rotate_fry_pot === 1 ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            /> <span class={ecoMode.rotate_fry_pot === 1 ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            {ecoMode.rotate_fry_pot === 1 ? <div class="frymasterRCForm">
                                                                <ul>
                                                                    <li class="width10px">&nbsp;</li>
                                                                    <li>
                                                                        <div class={`scheduledDaysList ${`scheduledDaysList${ecoMode.schedule_id}`}`}>
                                                                            {[{ label: "Frypot 1", value: "Frypot1" }, { label: "Frypot 2", value: "Frypot2" }, { label: "Frypot 3", value: "Frypot3" }, { label: "Frypot 4", value: "Frypot4" }].map((frypot, index) => {
                                                                                return (
                                                                                    <div key={index} class={`customCheckbox ${`customCheckBox${ecoMode.schedule_id}${index}`}`}>
                                                                                        <input
                                                                                            id={frypot.value + index}
                                                                                            type="checkbox"
                                                                                            name={`RotateFrypots${ecoMode.schedule_id}${index}`}
                                                                                            value={frypot.value}
                                                                                            onChange={(e) => {
                                                                                                this.updateMultiSelectValues(e.target.value, 'frypot_value', ecoMode.schedule_id)
                                                                                            }
                                                                                            }
                                                                                            checked={ecoMode.frypot_value.includes(frypot.value)} />
                                                                                        <label for={frypot.value + index}>{frypot.label}</label>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div> : null}
                                                        </div>
                                                    )
                                                }) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" id="scrollDownButton" onClick={() => this.appendNewRow()} class="btn addButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmButton rightAlign">&nbsp;</button>
                                    <button type="button" onClick={() => { this.props.history.push(`/frymasterAutomaticEcoMode`); }} class="btn cancelButton rightAlign">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Eco Mode Schedule Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterEcoModeSchedule)
