import {CLEANING_TABLE_REQUEST,CLEANING_TABLE_SUCCESS,CLEANING_TABLE_FAIL} from '../../../actions/reportManagement/cleaningTableAction';
const initialState = {
    loaded: false,
    loading: false,
    cleaningTableInformation: [],
    filterType: ''
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case CLEANING_TABLE_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case CLEANING_TABLE_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                cleaningTableInformation: action.cleaningTableInformation,
                filterType: action.filterType
            }
        case CLEANING_TABLE_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgHeatMap: 'Server Error',

            }
        default:
            return state
    }
}