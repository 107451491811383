import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import http from '../../service/httpService';
import {managePasswordAPI, BaseService} from '../../service/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FORGET_PASSWORD_CONFIRM_MESSAGE } from '../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { resetLocale } from "../../actions/localeAction/localeAction";
import { encriptData } from '../../utils/dashboardRenderer';

import KCStore from '../../utils/kcStorage';

class ManagePassword extends Component {
	nextPath(path) {		
		this.props.history.push(path);
	}
	
	constructor(props) {
		super(props);
		this.state = {
			currentPassword: '',
			newPassword: '',
			retypeNewPassword: '',
			newPasswordMatched: true,
			isHidden: false,
			// validateCurrentPassword: '',
			currentPasswordMatched: false,
			currentPasswordMismatched: '',
			hideError: false,
			disableError: false,
			strongPwdError: false,
			isFormValidated:false,
			userEmail: "",
			error_modal: {
				open:false,
				message:""
			},
			success_modal: {
				open:false,
				message:""
			},
			emailfromTokan:'',
			loaderIconVisible:false,
		};
		this.currentPasswordChange = this.currentPasswordChange.bind(this);
		// this.validateCurrentPasswordMismatch = this.validateCurrentPasswordMismatch.bind(this);
		this.removeSpacesOnChange = this.removeSpacesOnChange.bind(this);
		this.newPasswordChange = this.newPasswordChange.bind(this);
		this.retypeNewPasswordChange = this.retypeNewPasswordChange.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.passwordOnChange = this.passwordOnChange.bind(this);
		this.newpasswordOnChange = this.newpasswordOnChange.bind(this);
	}
	
	componentDidMount() {
		// this.savePassword();
		document.body.style.backgroundColor = "#F2F2F2";
	}
	
	onSuccessCloseModal() {
		this.setState({			
			success_modal:{
				open:false,
				message: ""
			},
		}); 
		this.props.resetLocale();
		this.props.history.push(`/`) 
		KCStore.removeItem("Access-Token");
	}
	
	currentPasswordChange = (e) => {
		let value = e.target.value;	
		if(value === "") {
			return;
		}	
		if (this.state.currentPassword !== value) {
			this.state.currentPassword = value;
		}
		// let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		// let loggedinPwd = loginUser.password;
		// var decodeVal = Buffer.from(loginUser.password, 'base64')
  		// var encodePassword = decodeVal.toString();
		// loggedinPwd = encodePassword;
		this.setState({
			// validateCurrentPassword: this.state.currentPassword && this.state.currentPassword === loggedinPwd,
			hideError: true 
		})
	}

	removeSpacesOnChange = (e) => {
		var keyCode = e.which || e.keyCode;
		if (keyCode === 32) {
            e.preventDefault();
            return false;
        }
	}

	newPasswordChange = (e) => {
		
		let value = e.target.value;
		this.state.newPassword = value;
		this.checkNewCurrentPwdMismatch();
		if(value === "") {
			this.setState({ strongPwdError: false,
			disableError: false});
			return;
		}
	}

  checkNewCurrentPwdMismatch =() =>{
	var pwdstrength = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?_+=|<,>/.;:!@$%^&*-/]).{8,}$/);
	this.setState({ 
		currentPasswordMismatched : this.state.newPassword && this.state.currentPassword && this.state.newPassword !== this.state.currentPassword,
		passwordStrength : !pwdstrength.test(this.state.newPassword), 
		
		strongPwdError: true,
		disableError: true
	});
}

	retypeNewPasswordChange = (e) => {
		
		let value = e.target.value;			
		this.setState({
			retypeNewPassword : value
		});
		if(value === "") {
			this.setState({
				isFormValidated:false
			});
			return;
	    }
	}

	checkNewPasswordMismatch =() =>{
		this.setState({ 
			newPasswordMatched : this.state.newPassword !== '' && this.state.newPassword && this.state.retypeNewPassword && this.state.newPassword === this.state.retypeNewPassword
		});
  }
	
	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	closeModel =()=>{
		this.setState({
				 isFormValidated:false,
				 error_modal:{
				 open:false,
				 message:""
			 }
		});
	   }
	   passwordOnChange = (e) => {
		let value = e.target.value;
		// let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		// let loggedinPwd = loginUser.password;		
		// var decodeVal = Buffer.from(loginUser.password, 'base64')
  		// var encodePassword = decodeVal.toString();
		// loggedinPwd = encodePassword;
		this.state.currentPassword = value;	
		   // if(this.state.currentPassword === "") {
	// 		this.setState({hideError:false})
	//   	}

		if(this.state.currentPassword === "") {
			this.setState({
				isFormValidated:false, hideError:false,
				// validateCurrentPassword:false, 
			});
		} else {
			this.setState({
				isFormValidated: true
			});
		}
		// if(this.state.currentPassword === loggedinPwd) {
		// 	this.setState({
		// 		isFormValidated: true
		// 	});
		// }
	}
	
	newpasswordOnChange = (e) => {
		let value = e.target.value;
		// let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		// let loggedinPwd = loginUser.password;
		// var decodeVal = Buffer.from(loginUser.password, 'base64')
  		// var encodePassword = decodeVal.toString();
		// loggedinPwd=encodePassword;
		var pwdmatch = new RegExp(/^.*(?=.{8,})(?=.*\d)(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[!@#$%^&;.,:_*]).*$/);
		this.state.newPassword = value
    	if(this.state.newPassword === "") {
			this.setState({strongPwdError:false, disableError:false});
		}
		// if(this.state.newPassword === "" || this.state.newPassword !== loggedinPwd) {
		if(this.state.newPassword === "") {
			this.setState({
				isFormValidated:false
			});
		}
		if(this.state.newPassword === pwdmatch.test(this.state.newPassword)) {
			this.setState({
				isFormValidated: true
			});
		}
	}

	savePassword = async (e) => {
		this.setState({
			loaderIconVisible: true
		});
		let loginUser =   JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		let token = KCStore.getItem('Access-Token');
		let postData = {          
				username: loggedinUser,
				oldPassword:this.state.currentPassword,
				newPassword:this.state.newPassword
			};
			
		const newPayload = await encriptData(JSON.stringify(postData))		
        http({
            method: "PUT",
            url: managePasswordAPI.passwordChange,
			data:{ credential:newPayload},
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': token,
            }											
        }).then( response =>  {
			if(response)
				this.setState({
					loaderIconVisible:false,			
					success_modal:{
						open:true,
						message: response.data.stringID || response.data.message
					},
				   });  
			WoopraEvents(`${Constants.MANAGE_PASSWORD}`)	            
        }).catch(err => {
		
		this.showError(err); 
		}); 
	}

	showError = (err) => {
		try {
			let { response } = err;
			if (response && response.status) {

				let { data } = response;
				let { body } = data;
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: body.message ? body.message : ""
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.message
					}

				});
			}
		} catch (e) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.message
				}

			});
		}
	}
	/**
     * Forget Password handler
     * @param {*} username
     */
	forgetPasswordHanddler = (e) => {
		this.setState({ loaderIconVisible: true });
		let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		let postData = {
			"username": loggedinUser
		}
		http({
			method: "POST",
			url: BaseService.submit_forget_password,
			data: postData,
		}).then(response => {
			let { status, data } = response;
			if (status === 200) {
				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: data.stringID || data.message
					},
				});
				WoopraEvents(`${Constants.FORGET_PASSWORD_REQUEST}`);
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.response.data.message
				}
			})
			WoopraEvents(`${Constants.FORGET_PASSWORD_REQUEST_FAIL}`);
		})
	}


	render() {
		const { formatMessage } = this.props.intl;
		let {  
			currentPassword,
			// validateCurrentPassword,	
			currentPasswordMismatched,
			passwordStrength,
			newPassword,
			retypeNewPassword,
			newPasswordMatched,
			isFormValidated,
			success_modal,
			error_modal,
			loaderIconVisible } = this.state;
			// let loginUser =   JSON.parse(KCStore.getItem("loginInfo"));
			// //let loggedinUser = loginUser.username;
			// let loggedinPwd = loginUser.password;
			// var decodeVal = Buffer.from(loginUser.password, 'base64')
			// var encodePassword = decodeVal.toString();
			// loggedinPwd=encodePassword;
			// if(currentPassword){
			// 	validateCurrentPassword = currentPassword !== '' && currentPassword && currentPassword.length>0 && currentPassword === loggedinPwd;
			// }
			
			if(newPassword){
				currentPasswordMismatched = newPassword && newPassword.length>0 && newPassword !== currentPassword;
			}
			if( retypeNewPassword){
				newPasswordMatched = newPassword && retypeNewPassword && newPassword === retypeNewPassword;
			}else{
				newPasswordMatched = true;
			}
			// isFormValidated = validateCurrentPassword && currentPasswordMismatched && newPasswordMatched 
		// 				  && currentPassword !=='' && newPassword !== '' && retypeNewPassword !== '';

		isFormValidated = currentPassword && currentPasswordMismatched && newPasswordMatched 
						  && currentPassword !=='' && newPassword !== '' && retypeNewPassword !== '';
		return (
            <React.Fragment>
    <div id="adminWrapper">
	
        {/* <!-- Loading Progress --> */}
        <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
            <div className="loadingProgressIn"></div>
        </div>
		<div id="page-content-wrapper">
			<div class="userManagementWrapper">
			<div class="addUserHeader">
				<ul>
					<li>&nbsp;</li>
					<li>
						<FormattedMessage id='KC0108' />
					</li>
					<li className={ isFormValidated  ? "" : "disabled" }> 
						<button type="button" class="btn btn-default-text" data-toggle="modal" data-target="#roleConfirmationOkModal" title={formatMessage({id:'KC0123'})} onClick = {(e) => this.savePassword(e)}><FormattedMessage id='KC0123' /></button>
					</li>
				</ul>
			</div>

			<div class="addUserFormWrapper">
				<div class="addUserForm ulMinHeight">
					<ul> 
						<li>
							<div class="input-group">
								<input type="password" class="form-control" placeholder={formatMessage({id:'KC0125'})} onBlur = {(e) => this.currentPasswordChange(e)} onChange = {(e) => this.passwordOnChange(e)} />
								<span class="requiredField">*</span>
								<div className={this.state.hideError ? 'showUserValidation': 'hideUserValidation'}>
									{/* <span>{ validateCurrentPassword ? <span className="field-validation-success">{"Current Password Matched"}</span> : <span className="field-validation-error">{"Current Password Not Matched"}</span> }</span> */}
								</div>
							</div>
						</li>
						<li>
							<div class="input-group">
								<input type="password" class="form-control" placeholder={formatMessage({id:'KC0126'})} onBlur = {(e) => this.newPasswordChange(e)} onChange= {(e) => this.newpasswordOnChange(e)} onKeyPress = {(e) => this.removeSpacesOnChange(e)} />
								<span class="requiredField">*</span>
								<div className={this.state.strongPwdError ? 'showUserValidation': 'hideUserValidation'}>
								<span>{ passwordStrength ? <span className="field-validation-error"><FormattedMessage id='KC0909'/></span>  : ""}</span>
								</div>
								<div className={this.state.disableError ? 'showUserValidation': 'hideUserValidation'}>
								<span>{ currentPasswordMismatched ? "" : <span className="field-validation-error"><FormattedMessage id='KC1666' /></span> }</span>
								</div>
							</div>
						</li> 
						<li>
							<div class="input-group">
								<input type="password" class="form-control" placeholder={formatMessage({id:'KC0128'})} onBlur = {(e) => this.retypeNewPasswordChange(e)} onChange = {(e) => this.retypeNewPasswordChange(e)} onKeyPress = {(e) => this.removeSpacesOnChange(e)} />
								<span class="requiredField">*</span>
								{ !newPasswordMatched ? <span className="field-validation-error"><FormattedMessage id='KC0129' /></span> :"" }
							</div>
						</li>
						<li>
							
						</li>  
					</ul>
					<div class="requiredNoteDiv"><FormattedMessage id='KC0130' /> <button title={formatMessage({id:'KC0002'})+'?'} class="btn btn-default-text forgotpassword"
													data-toggle="modal" data-target="#confirmationYesNoModal"><FormattedMessage id='KC0002' />?</button></div>
				</div> 
			</div>
			</div>
			</div>
			{/* User Story 55073: KC Portal - Add Forgot Password link in managePassword page */}
			<div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="confirmationYesNoModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                                <div className="modal-dialog modal-md">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="confirmationYesNo">
                                                <p><FormattedMessage id="KC1759"/></p>
                                            </div>
                                        </div>
                                        <div className="modal-footer textAlignCenter">
                                            <button className="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                                            <button className="btn btn-secondary yesBtn" id="resetPasswordYes" data-dismiss="modal" onClick={(e) => this.forgetPasswordHanddler(e)} type="button"><FormattedMessage id='KC0012' /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
		<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel}/>
		<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal}/>
        </div>
		<UserManagementHeader hideUserProfileEditBtn={true} headerName="User Profile" headerNameStringID="KC0106" previousPage="Manage Password" activeClass="UserProfileNav"/>	
    </React.Fragment>
    
          
		)
	}
};

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		resetLocale
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ManagePassword));



