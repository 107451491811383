


import React, { Component } from 'react';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';

class FrymasterLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            primary: 'English',
            secondary: 'English'
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                primary: ObjSettingsFile.general.language.primary || "English",
                secondary: ObjSettingsFile.general.language.secondary || "English",
            })
        } catch (error) {
            //Error Goes Here
        }
    }


    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSettingsClick = (pathname) => {
        this.props.history.push({
            pathname,
            isEdit: false,
            type: "Settings",
            previouspath: "/fryMasterSettings"
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterGeneralSettings`);
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = KCStore.getItem("selectedbrandID");
        const { primary, secondary } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Language Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Language Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Language Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC0920"/>
                                </div>

                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li><FormattedMessage id="KC1430"/></li>
                                                        <li class="arrowBtn">
                                                            {primary}
                                                            <button type="button" onClick={() => this.props.history.push('/frymasterLanguageSelection', { title: 'Primary Language' })} class="btn nextButton">&nbsp;</button></li>
                                                    </ul>
                                                    <ul class="borderNone">
                                                        <li class="width190px"><FormattedMessage id="KC1431"/></li>
                                                        <li class="arrowBtn">
                                                            {secondary}
                                                            <button type="button" onClick={() => this.props.history.push('/frymasterLanguageSelection', { title: 'Secondary Language' })} class="btn nextButton">&nbsp;</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Language Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterLanguage)
