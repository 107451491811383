import React, { Component } from 'react';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import {BRAND_ID} from '../../../utils/appConstants';
import http from '../../../service/httpService';
import { unitAPI, MenuUploadAPI,UserProfile } from '../../../service/api';
import { ModelFamilyBuilder } from '../../../utils/unitResponseBuilder';
import { injectIntl,FormattedMessage } from 'react-intl';
import ErrorMoal from '../../modal/error-model';
import { CUSTOMER_ID,BASECOUNTRY_ID,BRAND_ID_VALUE } from '../../../utils/appConstants';
import { v1 as uuidv1 } from 'uuid';
import { nanoid } from 'nanoid';
import SuccessMoal from '../../modal/success-model';
import KCStore from '../../../utils/kcStorage';
class UploadSelectDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            enableNext: false,
            selectedModelFamilyID: 0
        }
        this.getModelFamilies = this.getModelFamilies.bind(this);
        this.onModelSelection = this.onModelSelection.bind(this);
        this.OnUploadSelectDevice = this.OnUploadSelectDevice.bind(this);
        this.OnBackButtonClick = this.OnBackButtonClick.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#f2f2f2";
        try {
            const {srcfile} = this.state;
            await this.getModelFamilies(srcfile.name).catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    componentWillMount() {
        let { srcfile, previouspath, uploadOption, upload_filename, description, selectedModelFamilyID } = this.props.location;
		this.setState({
			srcfile: srcfile,
			previouspath: previouspath,
			uploadOption: uploadOption,
            upload_filename: upload_filename,
            description: description,
            selectedModelFamilyID: selectedModelFamilyID,
			enableNext: selectedModelFamilyID ? true : false
		});
    }

    getModelFamilies = (srcfilename) => {
        this.setState({
            loaderIconVisible: true
        });

        return new Promise((resolve, reject) => {
            http.get(unitAPI.getModelFamilies,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'brandid': BRAND_ID
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    let modelList = data && data.body ? data.body : [];
                    this.setState({
                        loaderIconVisible: false,
                        data: srcfilename.includes('.zip') ? modelList.filter(m => m.MODEL_FAMILY_NAME.includes('conneX')) : modelList.filter(m => !m.MODEL_FAMILY_NAME.includes('conneX'))
                    });
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    onModelSelection = (item) => {
        KCStore.setItem("selectedFamilyID", item.MODEL_FAMILY_ID.toString());
        KCStore.setItem("selectedbrandID", item.BRAND_ID);
        KCStore.setItem("brandName", item.BRAND_ID === 1 ? "MERRYCHEF" : item.BRAND_ID === 3 ? "FRYMASTER" : "CONVOTHERM");
        this.setState({
            enableNext: true,
            selectedModelFamilyID: item.MODEL_FAMILY_ID
        })
    }

    OnUploadSelectDevice = (e) => {
        const {srcfile, uploadOption, upload_filename, description, selectedModelFamilyID} = this.state;
		
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        if (libraryDetails.fileType === "Legacy") {
            if (selectedModelFamilyID === 1 || selectedModelFamilyID === 2) {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'KC2063'
                    }
                })
            }
            else if (KCStore.getItem("selectedbrandID") === '1') {
                this.legacySubmitAddLibrary();
            }
            else {
                this.props.history.push({
                    pathname: "/assignLegacyMenuToTopology",
                    srcfile: srcfile,
                    uploadOption: uploadOption,
                    upload_filename: upload_filename,
                    description: description,
                    selectedModelFamilyID: selectedModelFamilyID,
                    previouspath: "/uploadSelectDevice"
                });
            }
        }
        else if (KCStore.getItem("selectedbrandID") === '3') {
            if (libraryDetails.fileType === "Recipe") {
                this.props.history.push(`/assignFrymasterRecipeToTopology`);
            }
            else if (libraryDetails.fileType === "CookBook") {
                this.props.history.push(`/assignFrymasterMenuToTopology`);
            }
            else {
                this.props.history.push(`/assignFrymasterSettingsToTopology`);
            }
        }
        else if (KCStore.getItem("selectedbrandID") === '1') {
            this.submitAddLibrary();
        }
        else {
            this.props.history.push({
                pathname: "/libraryUploadTopology",
                srcfile: srcfile,
                uploadOption: uploadOption,
                upload_filename: upload_filename,
                description: description,
                selectedModelFamilyID: selectedModelFamilyID,
                previouspath: "/uploadSelectDevice"
            });
        }
    }

    legacySubmitAddLibrary = async(e,_customId) => {
        let _this = this;
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        
        if (!libraryDetails && !libraryDetails.libraryId) {
            _this.props.history.push(`/libraryList`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = KCStore.getItem("brandName");
            let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            await http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'base_country': KCStore.getItem("basecountry"),
					'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(async response => {                
                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                    "libraryId": libraryDetails.libraryId,
                    "libraryName": libraryDetails.fileName,
                    "libraryFileName": libraryDetails.libraryId + "." + libraryDetails.fileFormat,
                    "libraryFileFormat": libraryDetails.fileFormat,
                    "libraryFileSize": libraryDetails.fileSize,
                    "libraryS3Url": libraryDetails.fileS3Url,
                    "type": "Legacy",
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": libraryDetails.crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": "",
                    "imageFileFormat": "",
                    "imageFileSize": "",
                    "imageS3Url": "",
                    "cgids": [KCStore.getItem("parentCustGroupId") || KCStore.getItem("custGroupID")],
                    "json": "{}",
                    "recipeResources": [],
                    "libraryGuid": uuidv1(),
                    "description": libraryDetails.description
                };

                await http({
                    method: "POST",
                    url: MenuUploadAPI.getLibraryList,
                    data: libraryPostData,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    let { data } = res;
                    let { httpCode } = data;
                    if (httpCode === "HTTP_200") {
                        _this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: "KC1401"
                            },
                        });
                    }
                    else {
                        _this.setState({
                            loaderIconVisible: false
                        })
                    }
                }).catch(err => {
                    _this.setState({
                        loaderIconVisible: false
                    })
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured');
            });
        }
    }

    submitAddLibrary = async(e,_customId) => {
        let _this = this;
		let uploadedfile = this.state.srcfile;
		if ((uploadedfile === undefined) || (uploadedfile === "")) {
			this.props.history.push(`/libraryList`);
		} else {
            this.setState({ loaderIconVisible: true });
            let fileName = uploadedfile.name;
            if (KCStore.getItem("brandName") !== "MERRYCHEF"){
			    fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '');
            }
			let fileextSplit = fileName.split('.');
			let fileNamewithOutExt = fileextSplit[0];
			// let libraryId = fileNamewithOutExt + '_' + Date.now();
            let libraryId = nanoid() + '_' + Date.now();
            libraryId = libraryId.replace(/\s+/g, '');
			let fileSize = uploadedfile.size;
			fileSize = (fileSize / 1024).toFixed(2);
			let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
			let loggedinUser = loginUser.username;
			
            let {uploadOption, upload_filename, description} = this.state;
            let option = "";
            if (uploadOption === "Package") {
                option = ["CookBook","Settings"];
            }
            else {
                option = [uploadOption];
            }

			let postData = {
				"customer_id": CUSTOMER_ID,
				"modelFamilyIds": KCStore.getItem("selectedFamilyID"),
				"BrandId": KCStore.getItem("selectedbrandID"),
				"mediaType": option,
				"FileName": libraryId + '.zip',
				"country": KCStore.getItem("basecountry"),
				"cgid": KCStore.getItem("parentCustGroupId") || KCStore.getItem("custGroupID"),
				"TriggeredBy": loggedinUser,
                "uploadtype": "Upload",
                "name": upload_filename,
                "description": description
			};
			
            this.uploadMenuToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, postData, uploadedfile, libraryId).then(response => {
                this.props.history.push(`/libraryList`);
			}).catch(err => {
				_this.showError(err);
                _this.setState({ loaderIconVisible: false });
			});
		}
    }

    uploadMenuToCloud(url, postData, uploadedfile, libraryId) {
        return new Promise((resolve, reject) => {
            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': "",
                'jsonfilekey': encodeURIComponent(libraryId) + '.zip',
                'audiofilekey': "",
                'isthumbnail': false,
                'encryption' : "true"
            }
            if (KCStore.getItem("brandName") !== "MERRYCHEF"){
                delete headerpresigned.encryption;
                headerpresigned.jsonfilekey = libraryId + '.zip';
            }
            http({
                url: url,
                method: 'GET',
                headers: headerpresigned,
                data: {}
            }).then(response => {
                let { jsonPresignedURL } = response.data.presignedURLs;
                var xhr = new XMLHttpRequest();
                xhr.open("PUT", jsonPresignedURL);
                xhr.setRequestHeader('Content-Type', 'multipart/form-data');
                xhr.onloadend =  (res) => {
                     if(res.target.status == 200){
                                http({
                                    method: "POST",
                                    url:`${MenuUploadAPI.uploadLibraryFile}`,
                                    data: postData,
                                    headers: {
                                        'Content-Type':'application/json'
                                    }
                                }).then(res =>{
                                    let { data } = res;
                                    let { httpCode, message } = data;
                                if(httpCode === "HTTP_200"){                          
                                    resolve(message);
                                    }
                                    else{
                                        reject(res);
                                    }
                                }).catch(err => {
                                    reject(err);
                                });
                            }
                };
                xhr.onerror = (err) =>{
                    reject(err);
                };
                xhr.send(uploadedfile);
            }).catch(err => {
                reject(err);
            });
        });
    }    

    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    OnBackButtonClick = (e) => {
        const {srcfile, uploadOption, upload_filename, description, selectedModelFamilyID} = this.state;
        this.props.history.push({
            pathname: "/menuLibraryUpload",
            srcfile: srcfile,
            uploadOption: uploadOption,
            upload_filename: upload_filename,
            description: description,
            selectedModelFamilyID: selectedModelFamilyID,
            previouspath: "/uploadSelectDevice"
        });
    }

    closeModel = () => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ''
			}
		})
    }
    
    render() {
        let { data: dataList, error_modal, enableNext, selectedModelFamilyID,loaderIconVisible,success_modal } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        const { formatMessage } = this.props.intl;

        let merrychefDataList = [];
        let frymasterDataList = [];
        if (dataList) {
            dataList = ModelFamilyBuilder(dataList);
            merrychefDataList = dataList.filter(item => item.BRAND_ID === 1);
            frymasterDataList = dataList.filter(item => item.BRAND_ID === 3);
        }

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="adminBgHeader">
                            <ul>
                                <li>
                                    <button title={formatMessage({ id: 'KC0989' })} className="backIcon" onClick={(e) => this.OnBackButtonClick(e)}>&nbsp;</button>
                                </li>
                                <li>
                                    <FormattedMessage id="KC1205"/>
					        </li>
                                <li>
                                    <button id="btnnext" className={enableNext ? "btn-default-text activeState" : "disabled border0"} onClick={(e) => this.OnUploadSelectDevice(e)} title={formatMessage({ id: KCStore.getItem("selectedbrandID") === '1'? 'KC0056' :'KC0543' })}><FormattedMessage id= {KCStore.getItem("selectedbrandID") === '1'? 'KC0056' :"KC0543"}/></button>
                                </li>
                            </ul>
                        </div>
                        <div class="menuSelectDeviceTableOuter">
                            <div class="panel panel-default" id="menuSelectDeviceAccordion" role="tablist" aria-multiselectable="true">
                                {/* <!-- Header 01 --> */}
                                {KCStore.getItem("selectedbrandID") === "3" && <div class="menuSelectDeviceTable">
                                    <ul class="tBody">
                                        <li><a role="button" data-toggle="collapse" data-parent="#menuSelectDeviceAccordion" href="#menuSelectDvcAcc1" aria-expanded="true" aria-controls="menuSelectDvcAcc1" class="btn collapseExpand">&nbsp;</a></li>
                                        <li><img src={require("../../../images/logos/Frymaster_Color.svg").default} alt="" /></li>
                                    </ul>
                                </div>}
                                {/* <!-- Header 01 Content --> */}
                               {KCStore.getItem("selectedbrandID") === "3" && <div id="menuSelectDvcAcc1" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="menuSelectDvcAccH1">
                                    <div class="panel-body selectDeviceGrid">
                                        <ul>
                                            {frymasterDataList.map((item, i) => {
                                                return (
                                                    <li onClick={() => this.onModelSelection(item)} key={i}>
                                                        <div class="selectDeviceGridW">
                                                            <span><img alt={item.Brand_Alt} src={item.Brand_Logo} /></span>
                                                            <h5>{item.MODEL_FAMILY_NAME}</h5>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </div>
                                </div>} 
                                {/* <!-- Header 02 --> */}
                                {KCStore.getItem("selectedbrandID") === "1" && <div class="menuSelectDeviceTable">
                                    <ul class="tBody">
                                        <li><a role="button" data-toggle="collapse" data-parent="#menuSelectDeviceAccordion" href="#menuSelectDvcAcc2" aria-expanded="true" aria-controls="menuSelectDvcAcc2" class="btn collapseExpand">&nbsp;</a></li>
                                        <li><img src={require("../../../images/logos/Merrychef_Color.svg").default} alt="" /></li>
                                    </ul>
                                </div>}
                                {/* <!-- Header 02 Content --> */}
                                {KCStore.getItem("selectedbrandID") === "1" && <div id="menuSelectDvcAcc2" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="menuSelectDvcAccH2">
                                    <div class="panel-body selectDeviceGrid">
                                        <ul>
                                            {merrychefDataList.map((item, i) => {
                                                return (
                                                    <li onClick={() => this.onModelSelection(item)} key={i} class={selectedModelFamilyID === item.MODEL_FAMILY_ID ? "selectedList" : ""}>
                                                        <div class="selectDeviceGridW">
                                                            <span><img alt={item.Brand_Alt} src={item.Brand_Logo} /></span>
                                                            <h5>{item.MODEL_FAMILY_NAME}</h5>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </div>
                                </div>}
                                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(UploadSelectDevice)
