import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import http from '../../../../../service/httpService';
import { MenuUploadAPI } from '../../../../../service/api';
import authService from '../../../../../service/authService';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import SuccessMoal from '../../../../modal/success-model';
import { ModelFamilyList } from '../../../../controls/modelFamilyList';
import { CommonDetailViewEdit } from '../../../../controls/commonDetailViewEdit';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../../../utils/appConstants';
import defaultRecipeImage from "../../../../../images/recipecreation/photosPictures.png";
import $ from 'jquery';
import Jimp from 'jimp';
import JSZip from 'jszip';
import KCStore from '../../../../../utils/kcStorage';
require('jquery-mousewheel');
let md5 = require('md5');
var AdmZip = require('adm-zip');
var request = require('request');
var LZString = require('lz-string');
var recipeJSON = require('../../Templates/RecipeJSON');
const HTTP_METHOD = {
	DELETE: 'DELETE'
}

class CookbookAddRecipes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			customerId: parseInt(KCStore.getItem("customerId")),
			data: [],
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			selectedModel: 0,
			selectedRecipeDetails: [],
			selectedlibraries: [],
			search: '',
			previouspath: '',
			selectedCategoryName: ''
		}
		this.handleModelChange = this.handleModelChange.bind(this);
		this.redirect = this.redirect.bind(this);
		this.onBackButtonClick = this.onBackButtonClick.bind(this);
		this.onAddRecipes = this.onAddRecipes.bind(this);
		this.reciperedirect = this.reciperedirect.bind(this);
		this.OnToggleDetails = this.OnToggleDetails.bind(this);
		this.recipeSearchResult = this.recipeSearchResult.bind(this);
	}

	async componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		document.body.style.backgroundColor = "#000000";

		try {
			await this.getLibraryList();
		} catch (err) {
			this.setState({
				loaderIconVisible: false
			});
		}
	}

	updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

	componentDidUpdate = () => {
		this.updateDimensions();
	}

	async getLibraryList() {
		this.setState({
			loaderIconVisible: true,
			previouspath: this.props.history.location.previouspath
		});

		const { username: loggedinUser } = JSON.parse(KCStore.getItem("loginInfo"));

		try {
			const response = await http.get(MenuUploadAPI.getLibraryList, {
				headers: {
					'Content-Type': 'application/json',
					'customerid': CUSTOMER_ID,
					'cgids': KCStore.getItem("custGroupID"),
					'brandid': KCStore.getItem("selectedbrandID"),
					'modelfamilyids': KCStore.getItem("selectedFamilyID"),
					'basecountry': BASECOUNTRY_ID,
					'Type': "Recipe",
					'createdby': loggedinUser
				},
				data: {}
			});

			let libraryList = response.data?.libraryList || [];

			if (response.data?.fetchFromS3) {
				const data = await this.fetchDataFromS3(response.data.presignedS3URL);
				libraryList = data.libraryList;
			}

			const selectedCategoryObj = JSON.parse(KCStore.getItem("selectedCategory"));
			const categoryDetailsObj = JSON.parse(KCStore.getItem("resourceDetails"));

			let recipeDetails = [];
			let categoryName = '';

			if (selectedCategoryObj.subCategory.selected) {
				const foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
				recipeDetails = categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID === selectedCategoryObj.subCategory.ID);
			} else {
				recipeDetails = categoryDetailsObj.filter(c => c.ID === selectedCategoryObj.ID);
			}

			categoryName = recipeDetails[0]?.categoryName || "";
			recipeDetails = recipeDetails[0]?.recipe_items || [];

			libraryList = libraryList.map(item => {
				item.checked = recipeDetails.some(recipeitem => item.ID === recipeitem.ID);
				return item;
			});

			const selectedLibraries = libraryList.filter(c => c.checked);
			const libraryList_new = [...selectedLibraries, ...libraryList.filter(c => !c.checked)];

			this.setState({
				loaderIconVisible: false,
				data: libraryList_new,
				selectedCategoryName: categoryName,
				selectedlibraries: selectedLibraries
			});

		} catch (err) {
			console.error(err);
		}
	}

	async fetchDataFromS3(presignedS3URL) {
		const s3Response = await fetch(presignedS3URL);
		const arrayBuffer = await s3Response.arrayBuffer();

		const zip = await JSZip.loadAsync(arrayBuffer);
		const file = Object.values(zip.files).find(file => !file.dir);
		if (!file) {
			throw new Error('No files found in zip file');
		}
		const content = await file.async('string');
		return JSON.parse(content);
	}

	redirect(path, isEdit = false, previouspath) {
		this.props.history.push({
			pathname: path,
			isEdit: isEdit,
			previouspath: previouspath
		});
	}

	onBackButtonClick() {
		let selectedCategory = KCStore.getItem("selectedCategory");
		let selectedCategoryObj = JSON.parse(selectedCategory);
		let path = selectedCategoryObj.subCategory.selected ? "/cookbookRecipeList" : "/cookbookSubCategoryList";
		this.redirect(path, false, "/cookbookAddRecipes");
	}

	redirectToRecipeCreation() {
		this.redirect("/recipeCreation", false, "/recipeCreation");
	}

	onAddRecipes() {
		let selectedCategoryObj = JSON.parse(KCStore.getItem("selectedCategory"));
		let categoryDetailsObj = JSON.parse(KCStore.getItem("resourceDetails"));

		if (this.state.selectedlibraries && this.state.selectedlibraries.length >= 1024) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC2240"
				}
			});
			return;
		}

		let allRecipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
		allRecipeDetails = allRecipeDetails ? JSON.parse(allRecipeDetails) : [];

		let updatedSelectedRecipes;
		let path;
		let recipeType;
		let foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);

		if (selectedCategoryObj.subCategory.selected) {
			recipeType = 'subcategory';
			path = "/cookbookRecipeList";
			let selectedSubcategoryObj = categoryDetailsObj[foundIndex].subCategory.find(c => c.ID === selectedCategoryObj.subCategory.ID);
			let subcategoryObj = categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID !== selectedCategoryObj.subCategory.ID);
			selectedSubcategoryObj.recipe_items = this.state.selectedlibraries;
			subcategoryObj.push(selectedSubcategoryObj);
			categoryDetailsObj[foundIndex].subCategory = subcategoryObj;
		} else {
			recipeType = 'category';
			path = "/cookbookSubCategoryList";
			let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);
			let categoryObj = categoryDetailsObj.filter(c => c.ID !== selectedCategoryObj.ID);
			selectedCategoryDetailsObj.recipe_items = this.state.selectedlibraries;
			categoryObj.push(selectedCategoryDetailsObj);
			categoryDetailsObj = categoryObj;
		}

		updatedSelectedRecipes = this.state.selectedlibraries.map(v => ({ ...v, allRecipeType: recipeType }));
		allRecipeDetails = allRecipeDetails.filter(x => x.allRecipeType != recipeType);
		let newAllRecipes = [...allRecipeDetails, ...updatedSelectedRecipes];
		const uniqueItems = newAllRecipes.filter((v, i, a) => a.findIndex(t => (t.ID === v.ID)) === i);

		KCStore.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
		localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(uniqueItems)));

		this.props.history.push({
			pathname: path,
			isEdit: false,
			previouspath: "/cookbookAddRecipes"
		});
	}

	onSelectLibrary(e, item) {
		if (this.state.data && this.state.data.length > 0) {
			const libraries = [...this.state.data];
			const index = libraries.indexOf(item);
			libraries[index] = { ...libraries[index] };
			libraries[index].checked = e.target.checked;

			let selectedlibraries = libraries.filter(c => c.checked === true);

			this.setState({
				data: libraries,
				isSelectAllLibrary: selectedlibraries.length === libraries.length,
				selectedlibraries: selectedlibraries
			});
		}
	}

	handleModelChange(e) {
		this.setState({
			selectedModel: e.target.value
		});
	}

	recipeSearchResult(e) {
		this.setState({ search: e.target.value });
	}

	OnToggleDetails = (assignedTopologyDetails) => {
		const { ASSIGNED_CUSTOMER_GROUP, ...rest } = assignedTopologyDetails;
		let filteredArr = [];

		if (KCStore.getItem("brandName") !== "MERRYCHEF") {
			const uniqueValuesSet = new Set();
			filteredArr = ASSIGNED_CUSTOMER_GROUP
				.filter(value => Object.keys(value).length !== 0)
				.filter((obj) => {
					const isPresentInSet = uniqueValuesSet.has(obj.cust_gp_id);
					uniqueValuesSet.add(obj.cust_gp_id);
					return !isPresentInSet;
				});
		}

		this.setState({
			assignedTopologyDetails: { ASSIGNED_CUSTOMER_GROUP: filteredArr, ...rest },
		}, () => {
			$('#detailsInfoModal').modal('show');
		});
	}

	reciperedirect = (type) => {
		const libraryDetails = {
			isEdit: false,
			initialMethod: "Add",
			recipeType: type
		};

		const localStorageItems = {
			selectedGroupID: 0,
			libraryImage: "",
			libraryData: "{}",
			libraryDetails: JSON.stringify(libraryDetails),
			defaultLibraryData: JSON.stringify(recipeJSON.default),
			RecipesListPage: "/cookbookAddRecipes"
		};

		Object.entries(localStorageItems).forEach(([key, value]) => {
			KCStore.setItem(key, value);
		});

		this.props.history.push({
			pathname: "/recipeCreation",
			isEdit: false,
			previouspath: "/cookbookAddRecipes"
		});
	}

	OnEditLibrary = async () => {
		this.setState({
			loaderIconVisible: true
		});
		let { data, getLibraryDetails, selected_item } = this.state;

		if (data && getLibraryDetails) {
			if (data.length != getLibraryDetails.length) {
				let difference = this.getArrayDifferences(data, getLibraryDetails);
				if (difference && difference.length > 0) {
					data.splice(0, data.length, ...getLibraryDetails);
				}
			}
		}

		KCStore.setItem("selectedbrandID", selected_item.BRAND_ID);
		KCStore.setItem("brandName", selected_item.BRAND_NAME);

		await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
			headers: {
				'Content-Type': 'application/json',
				'library_id': encodeURIComponent(selected_item.LIBRARY_ID),
				'library_isedit': true,
				'encryption': "true"
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				let file_path = response.data.presignedURL;
				console.log("file_path", file_path)
				var libraryFiles = [];
				var b64 = "";
				var imageBuffer = null;
				KCStore.setItem("selectedGroupID", selected_item.ASSIGNED_CUSTOMER_GROUP ? Array.prototype.map.call(selected_item.ASSIGNED_CUSTOMER_GROUP, m => m.cust_gp_id).toString() : 0);
				try {
					request.get({ url: file_path, encoding: null }, async (err, res, body) => {
						var zip = new AdmZip(body);
						var zipEntries = zip.getEntries();
						let UpdatedJSONObj = "";
						console.log("zip", zip)
						console.log("zipEntries", zipEntries)

						let validzipEntries = zipEntries.filter(z => z.entryName.charAt(z.entryName.length - 1) !== "/");
						console.log("validzipEntries", validzipEntries)

						validzipEntries.forEach((entry, i) => {
							if (selected_item.TYPE === "CookBook" || (KCStore.getItem("brandName") === "MERRYCHEF" && selected_item.TYPE === "Recipe") || (KCStore.getItem("brandName") === "GARLAND" && selected_item.TYPE === "Recipe")) {

								libraryFiles[i] = {};
								libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '');
								imageBuffer = zip.readFile(entry);

								if (!entry.entryName.includes('.json')) {
									if (selected_item.TYPE === "Recipe") {
										libraryFiles[i].fileBuffer = imageBuffer;
										if (selected_item.IMAGE_FILE_NAME && UpdatedJSONObj && UpdatedJSONObj.image && selected_item.IMAGE_FILE_NAME === UpdatedJSONObj.image.replace('Resources/Images/', '')) {
											libraryFiles[i].fileType = "RecipeImage";
										}
										else {
											if (KCStore.getItem("brandName") === "GARLAND") {
												// libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
												libraryFiles[i].fileType = "Image";
											} else {
												libraryFiles[i].fileType = "StepImage";
											}
										}
									}
									else {
										libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
										libraryFiles[i].fileType = "Image";
									}
								}
								else {
									libraryFiles[i].fileBuffer = imageBuffer;
									UpdatedJSONObj = JSON.parse(imageBuffer);
									b64 = imageBuffer.toString('base64');
									libraryFiles[i].fileType = "JSON";
								}
							}
							else if (selected_item.TYPE === "Settings" && KCStore.getItem("brandName") === "MERRYCHEF") {
								libraryFiles[i] = zip.readFile(entry);

								if (!entry.entryName.includes('.json') && !entry.entryName.includes('.wav')) {
									imageBuffer = Buffer.from(libraryFiles[i]);
									b64 = imageBuffer.toString('base64');
									let fileScreenSaverExt = entry.entryName.substring(entry.entryName.lastIndexOf('.') + 1);
									KCStore.setItem("libraryScreenSaverExt", fileScreenSaverExt);
								}
							}
							else {
								libraryFiles[i] = zip.readFile(entry);

								if (!entry.entryName.includes('.json')) {
									imageBuffer = Buffer.from(libraryFiles[i]);
									b64 = imageBuffer.toString('base64');
								}
							}
						});

						KCStore.setItem("selectedFamilyID", selected_item.MODEL_FAMILY ? Array.prototype.map.call(selected_item.MODEL_FAMILY, m => m.ID).toString() : '');
						if (selected_item.BRAND_NAME === "MERRYCHEF") {
							if (libraryFiles[0] && (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe" || selected_item.TYPE === "Settings")) {
								let libraryData = [];
								if (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe") {
									UpdatedJSONObj && UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
										libraryData[i] = {};
										libraryData[i].index = i;
										libraryData[i].temp = item.actions.set_point.value + item.actions.set_point.units.replace('deg', '');
										let total = 0;
										if (item.duration) {
											let duration = item.duration.replace('PT', '');
											let minutes, seconds = 0;
											if (duration.includes('M')) {
												minutes = duration.substr(0, duration.indexOf('M'))
											}
											if (duration.includes('S')) {
												seconds = duration.substr(duration.indexOf('M') + 1, duration.indexOf('S') - 3)
											}
											total = minutes + ':' + seconds;
										}
										libraryData[i].time = total;
										libraryData[i].fan = item.actions.fan;
										libraryData[i].power = item.actions.microwave;
										libraryData[i].paramDelete = '';
										libraryData[i].instruction = (item.actions.prompt && item.actions.prompt.message) ? item.actions.prompt.message.en_GB : '';
										libraryData[i].instructionShowid = '';
										libraryData[i].instructionDelete = '';
										libraryData[i].instructionVisible = '';
										if (item.actions.prompt && item.actions.prompt.image) {
											libraryData[i].instructionImage = item.actions.prompt.image;
											libraryData[i].instructionImageS3Url = "";

											libraryFiles && libraryFiles.map((libraryitem, j) => {
												if (!libraryitem.fileName.toLowerCase().includes('.json')) {
													if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === item.actions.prompt.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
														libraryData[i].instructionImageb64 = libraryitem.fileBuffer.toString('base64');
														let imageSize = Buffer.byteLength(libraryitem.fileBuffer);
														libraryData[i].instructionImageSize = (imageSize / 1048576).toFixed(2);
													}
												}
											});
										}
									})

									let libraryDetails = {};
									libraryDetails.id = UpdatedJSONObj.id;
									libraryDetails.settings = {};
									libraryDetails.settings.portion = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.portion : 1;
									libraryDetails.settings.state = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.state : "ambient";
									libraryDetails.settings.weight = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.weight : 50;
									KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
								}
								else {
									libraryData = JSON.parse(libraryFiles[0]);
									let schedulers = libraryData.scheduler;
									if (!Array.isArray(schedulers)) {
										libraryData.scheduler = []
									}
									else {
										schedulers.map((parent, i) => {
											parent.configurations.map((item, j) => {
												if (item.type !== "Cold") {
													item.id = j + 1;
												}
												else {
													item.id = 12;
												}
											})
										})
									}

									KCStore.setItem("schedulerDetails", JSON.stringify(libraryData.scheduler))
								}
								KCStore.setItem("libraryData", JSON.stringify(libraryData));
								try {
									KCStore.setItem("defaultLibraryData", JSON.stringify(libraryData));
								}
								catch (err) {
									KCStore.setItem("defaultLibraryData", LZString.compress(JSON.stringify(libraryData)));
								}
							}

							if (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe") {
								//Recipe
								let RecipeImage = libraryFiles.find(l => l.fileType === "RecipeImage");
								imageBuffer = RecipeImage ? RecipeImage.fileBuffer : null;
								if (imageBuffer !== null) {
									Jimp.read(imageBuffer)
										.then(image => {
											image.resize(80, 80, (err, image) => {
												image.getBase64Async('image/png').then(thumbnailb64 => {
													let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
													libraryDetails = libraryDetails ? libraryDetails : {};
													let imageData = {};
													// imageData.imagefileName = selected_item.LIBRARY_NAME + "." + selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													// imageData.imagefileext = selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													// imageData.imagefileNamewithOutExt = selected_item.LIBRARY_NAME;
													const decodedURL = decodeURIComponent(selected_item.IMAGE_S3URL);
													const parts = decodedURL.split('/');
													const fileNameWithExtension = parts[parts.length - 1];
													const [fileName, fileExt] = fileNameWithExtension.split('.');
													imageData.imagefileName = fileNameWithExtension;
													imageData.imagefileext = fileExt;
													imageData.imagefileNamewithOutExt = fileName;
													imageData.imagefileSize = selected_item.IMAGE_FILE_SIZE;
													imageData.imagefilelength = selected_item.LIBRARY_NAME.length;
													imageData.crc = md5(b64, "base64");
													imageData.imageS3Url = selected_item.IMAGE_S3URL;
													libraryDetails.imageData = imageData;
													libraryDetails.id = selected_item.LIBRARY_GUID;
													libraryDetails.recipeName = selected_item.LIBRARY_NAME;
													libraryDetails.isEdit = true;
													libraryDetails.library_id = selected_item.LIBRARY_ID;
													libraryDetails.isImageChanged = false;
													libraryDetails.initialMethod = "Edit";
													libraryDetails.recipeType = selected_item.TYPE;
													KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
													KCStore.setItem("libraryImage", thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
													this.props.history.push({
														pathname: "/recipeCreation",
														previouspath: "/cookbookAddRecipes"
													});
												});
											})
										})
								} else {
									let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
									libraryDetails = libraryDetails ? libraryDetails : {};
									let imageData = {};
									imageData.imagefileName = "";
									imageData.imagefileext = "";
									imageData.imagefileNamewithOutExt = "";
									imageData.imagefileSize = "";
									imageData.imagefilelength = "";
									imageData.crc = "";
									imageData.imageS3Url = "";
									libraryDetails.imageData = imageData;
									libraryDetails.id = selected_item.LIBRARY_GUID;
									libraryDetails.recipeName = selected_item.LIBRARY_NAME;
									libraryDetails.isEdit = true;
									libraryDetails.library_id = selected_item.LIBRARY_ID;
									libraryDetails.isImageChanged = false;
									libraryDetails.initialMethod = "Edit";
									libraryDetails.recipeType = selected_item.TYPE;
									KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
									this.props.history.push({
										pathname: "/recipeCreation",
										previouspath: "/cookbookAddRecipes"
									});
								}
							}
							else if (selected_item.TYPE === "CookBook") {
								let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.cookbookName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.initialMethod = "Edit"

								//CookBook
								let categoryDetailsObj = [];
								UpdatedJSONObj && UpdatedJSONObj.menus && UpdatedJSONObj.menus.map((item, i) => {
									let category = {};
									category.ID = item.id;

									let imageData = null;
									let thumbnailData = null;
									let imageFileFormat = "";
									let imageFileName = "";
									let imageFileSize = "";

									let fileext = item.image.substring(item.image.lastIndexOf('.') + 1);
									let itemFileName = item.image.replace(fileext, "png");

									libraryFiles && libraryFiles.map((libraryitem, j) => {
										if (libraryitem.fileName.includes('tmb_')) {
											if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === itemFileName.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace("/", "")) {
												thumbnailData = libraryitem.fileBuffer;
											}
										}
										else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
											if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === item.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
												imageData = libraryitem.fileBuffer;
												let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
												let fileextSplit = fileName.split('.');
												let fileExt = fileextSplit[1];
												imageFileFormat = "." + fileExt;
												imageFileName = libraryitem.fileName;
												// read json string to Buffer
												let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
												imageFileSize = (fileSize / 1048576).toFixed(2);
											}
										}
									});

									category.imageFileName = imageFileName;
									category.imageFileFormat = imageFileFormat;
									category.imageFileSize = imageFileSize;
									category.imageS3Url = item.imageS3Url ? item.imageS3Url : "";
									category.categoryName = item.name ? item.name.en_GB ? item.name.en_GB : "" : "";
									category.imageData = "";
									category.thumbnailData = thumbnailData;
									category.recipe_items = [];
									item.recipe_items && item.recipe_items.map((recipeitem, k) => {
										let recipe = data.filter(d => d.LIBRARY_ID === recipeitem);
										category.recipe_items[k] = recipe && recipe[0] ? recipe[0] : [];
									});
									let _checkRecipeExist = data.filter(o1 => category.recipe_items.some(o2 => o1.LIBRARY_ID === o2.LIBRARY_ID));
									category.recipe_items = category.recipe_items && category.recipe_items.filter(item => item.length == undefined);
									category.subCategory = [];
									item.menu_items && item.menu_items.map((subitem, l) => {
										let subCategory = {};
										subCategory.ID = subitem.id;

										let thumbnailData = null;
										let imageFileFormat = "";
										let imageFileName = "";
										let imageFileSize = "";

										let fileext = subitem.image.substring(subitem.image.lastIndexOf('.') + 1);
										let itemFileName = subitem.image.replace(fileext, "png");

										libraryFiles && libraryFiles.map((libraryitem, m) => {
											if (libraryitem.fileName.includes('tmb_')) {
												if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === itemFileName.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace("/", "")) {
													thumbnailData = libraryitem.fileBuffer;
												}
											}
											else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
												if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === subitem.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
													imageData = libraryitem.fileBuffer;
													let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
													let fileextSplit = fileName.split('.');
													let fileExt = fileextSplit[1];
													imageFileFormat = "." + fileExt;
													imageFileName = libraryitem.fileName;
													// read json string to Buffer
													let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
													imageFileSize = (fileSize / 1048576).toFixed(2);
												}
											}
										});

										subCategory.imageFileName = imageFileName;
										subCategory.imageFileFormat = imageFileFormat;
										subCategory.imageFileSize = imageFileSize;
										subCategory.imageS3Url = subitem.imageS3Url ? subitem.imageS3Url : "";
										subCategory.categoryName = subitem.name ? subitem.name.en_GB ? subitem.name.en_GB : "" : "";
										subCategory.imageData = "";
										subCategory.thumbnailData = thumbnailData;
										subCategory.recipe_items = [];
										subitem.recipe_items && subitem.recipe_items.map((recipeitem, n) => {
											let recipe = data.filter(d => d.LIBRARY_ID === recipeitem);
											subCategory.recipe_items[n] = recipe && recipe[0] ? recipe[0] : [];
										});
										subCategory.recipe_items = subCategory.recipe_items && subCategory.recipe_items.filter(item => item.length == undefined);
										category.subCategory.push(subCategory);
									});
									categoryDetailsObj.push(category);
								});

								KCStore.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
								KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
								if (selected_item.SETTINGS_LIBRARY_ID !== null || selected_item.SETTINGS_LIBRARY_ID !== 0) {
									await this.OnEdit_For_Setting_Library();
								} else {
									this.props.history.push({
										pathname: "/cookbookEditor",
										previouspath: "/libraryList"
									});
								}
							}
							else {
								//Settings
								let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.settingName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.settings = UpdatedJSONObj.sounds;
								libraryDetails.day = UpdatedJSONObj.scheduler && UpdatedJSONObj.scheduler.length > 0 ? UpdatedJSONObj.scheduler[0].day : "Monday";
								libraryDetails.initialMethod = "Edit"

								KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
								KCStore.setItem("libraryImage", b64);

								this.props.history.push({
									pathname: "/settingsEditor",
									previouspath: "/libraryList"
								});
							}
						}
						else if (this.state.selected_item.BRAND_NAME === "FRYMASTER") {
							if (libraryFiles[0] && this.state.selected_item.TYPE === "Recipe") {
								let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
								let quality_timer = UpdatedJSONObj.settings.quality_timer;
								UpdatedJSONObj.settings.quality_timer = quality_timer ? this.getFormatedTime(quality_timer) : '';
								let drain_timer = UpdatedJSONObj.settings.drain_timer;
								UpdatedJSONObj.settings.drain_timer = drain_timer ? this.getFormatedTime(drain_timer) : '';
								let hold_timer = UpdatedJSONObj.settings.hold_timer;
								UpdatedJSONObj.settings.hold_timer = hold_timer ? this.getFormatedTime(hold_timer) : '';
								let instant_on = UpdatedJSONObj.settings.instant_on;
								UpdatedJSONObj.settings.instant_on = instant_on ? this.getFormatedTime(instant_on) : '';

								UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
									UpdatedJSONObj.ordered_steps[i].duration = item.duration ? this.getFormatedTime(item.duration) : '';
								});

								UpdatedJSONObj.conditional_steps && UpdatedJSONObj.conditional_steps.map((item, i) => {
									let duration = item.actions.prompt.duration;
									UpdatedJSONObj.conditional_steps[i].actions.prompt.duration = duration ? this.getFormatedTime(duration) : '';
									UpdatedJSONObj.conditional_steps[i].condition.property_value = duration ? this.getFormatedTime(duration) : '';
								});

								KCStore.setItem("libraryData", JSON.stringify(UpdatedJSONObj));

								let resourceDetails = UpdatedJSONObj.recipeResources;
								if (UpdatedJSONObj.recipeResources.length > 0) {
									resourceDetails[0].imageData = '';
									resourceDetails[0].thumbnailData = b64;
								}
								KCStore.setItem("resourceDetails", JSON.stringify(resourceDetails));

								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
								libraryDetails.library_id = this.state.selected_item.LIBRARY_ID;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.initialMethod = "Edit"

								KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));

								this.props.history.push({
									pathname: "/frymasterRecipeCreation",
									previouspath: "/libraryList"
								});
							}
							else if (libraryFiles[0] && selected_item.TYPE === "CookBook") {
								let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.cookbookName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.autoMenuChange = UpdatedJSONObj.autoMenuChange;
								libraryDetails.limitedTimeOffer = UpdatedJSONObj.limitedTimeOffer;
								libraryDetails.initialMethod = "Edit";

								//CookBook
								let categoryDetailsObj = [];
								UpdatedJSONObj && UpdatedJSONObj.menus && UpdatedJSONObj.menus.map((item, i) => {
									let category = {};
									category.ID = item.id;
									category.categoryName = item.name ? item.name.en_US ? item.name.en_US : "" : "";
									category.type = "Category";
									category.recipe_items = [];
									item.recipe_items && item.recipe_items.map((recipeitem, k) => {
										let recipe = data.filter(d => d.LIBRARY_ID === recipeitem);
										category.recipe_items[k] = recipe && recipe[0] ? recipe[0] : [];
									});
									categoryDetailsObj.push(category);
								});

								KCStore.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
								KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));

								this.props.history.push({
									pathname: "/FrymasterMenuEditor",
									previouspath: "/libraryList"
								});
							}
							else {
								//Settings
								let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.settingName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.initialMethod = "Edit";

								KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
								KCStore.setItem("libraryData", JSON.stringify(UpdatedJSONObj));
								try {
									KCStore.setItem("defaultLibraryData", JSON.stringify(UpdatedJSONObj));
								}
								catch (err) {
									KCStore.setItem("defaultLibraryData", LZString.compress(JSON.stringify(UpdatedJSONObj)));
								}

								this.props.history.push({
									pathname: "/frymasterSettings",
									previouspath: "/libraryList"
								});
							}
						} else if (this.state.selected_item.BRAND_NAME === "GARLAND") {
							console.log("libraryFiles", libraryFiles)
							const temp_units = UpdatedJSONObj && UpdatedJSONObj.temp_units ? JSON.parse(UpdatedJSONObj.temp_units) : JSON.parse("{\"unit_cooking_method\":\"°C\",\"unit_cooking_steps\":\"°C\"}");

							if (libraryFiles[0] && (selected_item.TYPE === "Recipe")) {
								let libraryData = {};
								if (selected_item.TYPE === "Recipe") {
									const cookingMethodologyMapping = {
										0: 'Steel',
										1: 'Standard',
										2: 'Steel / Water',
										3: 'LRS / Water'
									}
									console.log("UpdatedJSONObj", UpdatedJSONObj)
									libraryData = UpdatedJSONObj;
									let unitCooking = temp_units['unit_cooking_method'] ? temp_units['unit_cooking_method'] : "°C";
									libraryData['unit'] = unitCooking;
									let too_cold_limit = Number(libraryData['too_cold_limit']) !== 0 ? Number(libraryData['too_cold_limit']) / 10 : 0;
									let too_hot_limit = Number(libraryData['too_hot_limit']) !== 0 ? Number(libraryData['too_hot_limit']) / 10 : 0;
									too_cold_limit = too_cold_limit === 0 ? too_cold_limit : unitCooking === "°F" ? this.c2fGrill(too_cold_limit) : too_cold_limit;
									too_hot_limit = too_hot_limit === 0 ? too_hot_limit : unitCooking === "°F" ? this.c2fGrill(too_hot_limit) : too_hot_limit;
									libraryData['too_cold_limit'] = too_cold_limit > 100 ? 100 : too_cold_limit;
									libraryData['too_hot_limit'] = too_hot_limit > 100 ? 100 : too_hot_limit;
									libraryData['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;
									libraryData['cooking_methodology'] = cookingMethodologyMapping[UpdatedJSONObj.cooking_methodology];
									let cookObj = [];
									const promptMapping = {
										'sear': 'Sear',
										'remove in time': 'Remove in time',
										'flip (must ack)': 'Flip (Must Ack)',
										'flip (run time)': 'Flip (Run time)',
										'default done': 'Default Done',
										'platen closing': 'Platen Closing',
										'none': 'None'
									}
									const unitCookingSteps = temp_units['unit_cooking_steps'] ? temp_units['unit_cooking_steps'] : "°C";
									for (let index = 0; index < UpdatedJSONObj.cookingSteps.length; index++) {
										const element = UpdatedJSONObj.cookingSteps[index];
										let obj = {
											cookTime: element.CookTime,
											promptSelection: promptMapping[element.PromptSelection] ? promptMapping[element.PromptSelection] : element.PromptSelection,
											prompt: element.Prompt,
											uniqueId: (new Date()).getTime(),
											flatCooking: element.isFlat === 1 ? 'Yes' : 'No',
											cookingGap: element && element.Gap ? element.Gap / 100000 : 0,
											unit: unitCookingSteps,
											upperPlattenTemp: element.tempUpperPlaten === 0 ? 'Off' : unitCookingSteps === "°F" ? this.c2f(element.tempUpperPlaten / 10) : element.tempUpperPlaten / 10,
											upperPlattenCheck: element.tempUpperPlaten === 0 ? false : true,
											upperPlattenValue: element.tempUpperPlaten === 0 ? '' : unitCookingSteps === "°F" ? this.c2f(element.tempUpperPlaten / 10) : element.tempUpperPlaten / 10,

											grillPlattenTemp: element.tempLowerPlaten === 0 ? 'Off' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerPlaten / 10) : element.tempLowerPlaten / 10,
											grillPlattenCheck: element.tempLowerPlaten === 0 ? false : true,
											grillPlattenValue: element.tempLowerPlaten === 0 ? '' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerPlaten / 10) : element.tempLowerPlaten / 10,

											grillFrontTemp: element.tempLowerFront === 0 ? 'Off' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerFront / 10) : element.tempLowerFront / 10,
											grillFrontCheck: element.tempLowerFront === 0 ? false : true,
											grillFrontValue: element.tempLowerFront === 0 ? '' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerFront / 10) : element.tempLowerFront / 10,
										}
										cookObj.push(obj)
									}
									libraryData['steps'] = cookObj;
								}

								KCStore.setItem("garlandRecipe", JSON.stringify(libraryData));
							}

							if (selected_item.TYPE === "Recipe") {
								console.log("libraryFiles", libraryFiles)
								//Recipe
								let RecipeImage = libraryFiles.find(l => l.fileType === "Image");
								imageBuffer = RecipeImage ? RecipeImage.fileBuffer : null;
								console.log("imageBuffer", imageBuffer)

								if (imageBuffer !== null) {
									return Jimp.read(imageBuffer)
										.then(image => {
											console.log("image", image)
											image.resize(80, 80, (err, image) => {
												image.getBase64Async('image/png').then(thumbnailb64 => {
													let libraryDetails = JSON.parse(KCStore.getItem("garlandRecipe"));
													libraryDetails = libraryDetails ? libraryDetails : {};
													let imageData = {};
													imageData.imagefileName = selected_item.LIBRARY_NAME + "." + selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileext = selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileNamewithOutExt = selected_item.LIBRARY_NAME;
													imageData.imagefileSize = selected_item.IMAGE_FILE_SIZE;
													imageData.imagefilelength = selected_item.LIBRARY_NAME.length;
													imageData.crc = md5(b64, "base64");
													imageData.imageS3Url = selected_item.IMAGE_S3_SIGNED_URL;
													libraryDetails.imageData = imageData;
													libraryDetails.id = selected_item.LIBRARY_GUID;
													libraryDetails.recipeName = selected_item.LIBRARY_NAME;
													libraryDetails.isEdit = true;
													libraryDetails.library_id = UpdatedJSONObj.recipeID;
													libraryDetails.isImageChanged = false;
													libraryDetails.initialMethod = "Edit";
													libraryDetails.recipeType = selected_item.TYPE;
													libraryDetails.imageThumbnailb64 = thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, "");

													KCStore.setItem("garlandRecipe", JSON.stringify(libraryDetails));
													KCStore.setItem("libraryImage", thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
													this.props.history.push({
														pathname: "/garlandRecipeCreation",
														previouspath: "/libraryList"
													});
												});
											})
										}).catch((err) => {
											console.log("catch 1", err)

											let libraryDetails = JSON.parse(KCStore.getItem("garlandRecipe"));
											libraryDetails = libraryDetails ? libraryDetails : {};
											let imageData = {};
											libraryDetails.imageData = imageData;
											libraryDetails.id = selected_item.LIBRARY_GUID;
											libraryDetails.recipeName = selected_item.LIBRARY_NAME;
											libraryDetails.isEdit = true;
											libraryDetails.library_id = selected_item.LIBRARY_ID;
											libraryDetails.isImageChanged = false;
											libraryDetails.initialMethod = "Edit";
											libraryDetails.recipeType = selected_item.TYPE;
											libraryDetails['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

											KCStore.setItem("garlandRecipe", JSON.stringify(libraryDetails));
											this.props.history.push({
												pathname: "/garlandRecipeCreation",
												previouspath: "/libraryList"
											});
										})

								} else {
									let libraryDetails = JSON.parse(KCStore.getItem("garlandRecipe"));
									console.log("libraryDetails parse", libraryDetails)

									libraryDetails = libraryDetails ? libraryDetails : {};
									let imageData = {};
									imageData.imagefileName = "";
									imageData.imagefileext = "";
									imageData.imagefileNamewithOutExt = "";
									imageData.imagefileSize = "";
									imageData.imagefilelength = "";
									imageData.crc = "";
									imageData.imageS3Url = "";
									libraryDetails.imageData = imageData;
									libraryDetails.id = selected_item.LIBRARY_GUID;
									libraryDetails.recipeName = selected_item.LIBRARY_NAME;
									libraryDetails.isEdit = true;
									libraryDetails.library_id = selected_item.LIBRARY_ID;
									libraryDetails.isImageChanged = false;
									libraryDetails.initialMethod = "Edit";
									libraryDetails.recipeType = selected_item.TYPE;
									libraryDetails['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

									KCStore.setItem("garlandRecipe", JSON.stringify(libraryDetails));
									this.props.history.push({
										pathname: "/garlandRecipeCreation",
										previouspath: "/libraryList"
									});
								}
							}

							//Garland Cookbook
							if (libraryFiles[0] && (selected_item.TYPE === "CookBook")) {
								KCStore.setItem("cookBookFileName", selected_item.LIBRARY_NAME);
								await this.getGarlandRecipeDetails()
								let cookBooklibraryData = {};

								if (selected_item.TYPE === "CookBook") {
									const cookingMethodologyMapping = {
										0: 'Steel',
										1: 'Standard',
										2: 'Steel / Water',
										3: 'LRS / Water'
									}
									console.log("UpdatedJSONObj", UpdatedJSONObj)
									cookBooklibraryData = UpdatedJSONObj;
									cookBooklibraryData['unit'] = "°C";
									cookBooklibraryData['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

									let garlandMenus = [];
									for (let intCtr = 0; intCtr < UpdatedJSONObj.menus.length; intCtr++) {
										let element = UpdatedJSONObj.menus[intCtr];
										let objs = {
											imageFileName: element.icon ? element.icon : "",
											imageFileFormat: ".png",
											imageFileSize: "",
											// 	imageS3Url: UploadedImages3url,
											imageData: "",
											thumbnailData: "",
											categoryName: element.menuName ? element.menuName : '',
											ID: element.menuID,
											type: "Category",
											parentID: "",
											selected: false,
											unit: element.unit ? element.unit : "°C",

											grill_Plate_limit: element.preHeatLowerPlaten === 0 ? 'Off' : element.unit === "°F" ? this.c2f(element.preHeatLowerPlaten / 10) : element.preHeatLowerPlaten / 10,
											grillPlateLimitCheck: element.preHeatLowerPlaten === 0 ? false : true,
											grillPlateLimitValue: element.preHeatLowerPlaten === 0 ? '' : element.unit === "°F" ? this.c2f(element.preHeatLowerPlaten / 10) : element.preHeatLowerPlaten / 10,

											grill_Front_limit: element.preHeatLowerFront === 0 ? 'Off' : element.unit === "°F" ? this.c2f(element.preHeatLowerFront / 10) : element.preHeatLowerFront / 10,
											grillFrontLimitCheck: element.preHeatLowerFront === 0 ? false : true,
											grillFrontLimitValue: element.preHeatLowerFront === 0 ? '' : element.unit === "°F" ? this.c2f(element.preHeatLowerFront / 10) : element.preHeatLowerFront / 10,

											upper_Platen_limit: element.preHeatUpperPlaten === 0 ? 'Off' : element.unit === "°F" ? this.c2f(element.preHeatUpperPlaten / 10) : element.preHeatUpperPlaten / 10,
											upperPlatenLimitCheck: element.preHeatUpperPlaten === 0 ? false : true,
											upperPlatenLimitValue: element.preHeatUpperPlaten === 0 ? '' : element.unit === "°F" ? this.c2f(element.preHeatUpperPlaten / 10) : element.preHeatUpperPlaten / 10,
											flat_Cooking: element.is_flat === 1 ? 'Yes' : 'No',
										}
										if (element.icon) {
											let RecipeImage = libraryFiles.find(l => l.fileType === "Image" && l.fileName.includes(element.icon));
											imageBuffer = RecipeImage ? RecipeImage.fileBuffer : null;
											if (imageBuffer !== null) {
												objs.thumbnailData = imageBuffer.replace(/^data:image\/[A-Za-z]+;base64,/, "");
												let fileSize = Buffer.byteLength(RecipeImage.fileBuffer);
												objs.imageFileSize = (fileSize / 1048576).toFixed(2);
											}
										}
										objs.recipe_items = [];
										const prObjToFindRecipe = UpdatedJSONObj && UpdatedJSONObj.product_recognition ? UpdatedJSONObj.product_recognition : {};
										for (let rcCtr = 0; rcCtr < element.recipeList.length; rcCtr++) {
											const isAuto = prObjToFindRecipe && prObjToFindRecipe.recipeID ?
												prObjToFindRecipe.recipeID === element.recipeList[rcCtr]
												: false;
											let recipe = isAuto ? [{
												ID: 0,
												recipeId: prObjToFindRecipe.recipeID,
												LIBRARY_GUID: prObjToFindRecipe && prObjToFindRecipe.recipeID ? prObjToFindRecipe.recipeID : '',
												LIBRARY_ID: prObjToFindRecipe && prObjToFindRecipe.recipeID ? prObjToFindRecipe.recipeID : '',
												recipeName: prObjToFindRecipe && prObjToFindRecipe.recipeName ? prObjToFindRecipe.recipeName : 'Auto',
												LIBRARY_NAME: prObjToFindRecipe && prObjToFindRecipe.recipeName ? prObjToFindRecipe.recipeName : 'Auto',
												CREATED_ON: selected_item.UPDATED_ON || "",
												UPDATED_ON: selected_item.UPDATED_ON || ""
											}] : this.getRecipeByID(element.recipeList[rcCtr])
											if (recipe && recipe.length > 0) {
												let regObj = recipe[0];
												objs.recipe_items.push(regObj)
											}
										}
										garlandMenus.push(objs)
									}
									let libraryDetails = {};
									libraryDetails.id = UpdatedJSONObj.id;
									libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
									libraryDetails.library_id = selected_item.LIBRARY_ID;
									libraryDetails.cookbookName = selected_item.LIBRARY_NAME;
									libraryDetails.crc = md5(b64, "base64");
									libraryDetails.isEdit = true;
									libraryDetails.initialMethod = "Edit";


									KCStore.setItem("resourceDetails", JSON.stringify(garlandMenus));
									KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));

									//Product Recognition			
									if (UpdatedJSONObj.product_recognition) {
										let productRecognitionObj = {};
										let prElement = UpdatedJSONObj.product_recognition;
										productRecognitionObj.unit = prElement.unit ? prElement.unit : "°C";

										let too_cold_limit = Number(prElement.too_cold_limit) !== 0 ? Number(prElement.too_cold_limit) / 10 : 0;
										let too_hot_limit = Number(prElement.too_hot_limit) !== 0 ? Number(prElement.too_hot_limit) / 10 : 0;
										too_cold_limit = too_cold_limit === 0 ? too_cold_limit : prElement.unit === "°F" ? this.c2fGrill(too_cold_limit) : too_cold_limit;
										too_hot_limit = too_hot_limit === 0 ? too_hot_limit : prElement.unit === "°F" ? this.c2fGrill(too_hot_limit) : too_hot_limit;
										productRecognitionObj.tooColdLimitValue = too_cold_limit > 100 ? 100 : too_cold_limit;
										productRecognitionObj.tooHotLimitValue = too_hot_limit > 100 ? 100 : too_hot_limit;

										let prCooking = prElement.cookingSteps ? prElement.cookingSteps[0] : {}
										productRecognitionObj.grill_Plate_limit = prCooking.tempLowerPlaten === 0 ? 'Off' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerPlaten / 10) : prCooking.tempLowerPlaten / 10;
										productRecognitionObj.grillPlateLimitCheck = prCooking.tempLowerPlaten === 0 ? false : true;
										productRecognitionObj.grillPlateLimitValue = prCooking.tempLowerPlaten === 0 ? '' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerPlaten / 10) : prCooking.tempLowerPlaten / 10;

										productRecognitionObj.grill_Front_limit = prCooking.tempLowerFront === 0 ? 'Off' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerFront / 10) : prCooking.tempLowerFront / 10;
										productRecognitionObj.grillFrontLimitCheck = prCooking.tempLowerFront === 0 ? false : true;
										productRecognitionObj.grillFrontLimitValue = prCooking.tempLowerFront === 0 ? '' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerFront / 10) : prCooking.tempLowerFront / 10;

										productRecognitionObj.upper_Platen_limit = prCooking.tempUpperPlaten === 0 ? 'Off' : prElement.unit === "°F" ? this.c2f(prCooking.tempUpperPlaten / 10) : prCooking.tempUpperPlaten / 10;
										productRecognitionObj.upperPlatenLimitCheck = prCooking.tempUpperPlaten === 0 ? false : true;
										productRecognitionObj.upperPlatenLimitValue = prCooking.tempUpperPlaten === 0 ? '' : prElement.unit === "°F" ? this.c2f(prCooking.tempUpperPlaten / 10) : prCooking.tempUpperPlaten / 10;

										productRecognitionObj.cookingMethodology = prCooking.cooking_methodology ? cookingMethodologyMapping[prElement.cooking_methodology] : 'Steel';
										productRecognitionObj.recipeID = prElement.recipeID;
										productRecognitionObj.recipeName = prElement.recipeName ? prElement.recipeName : 'Auto';
										productRecognitionObj.ID = 0;
										productRecognitionObj.recipe_items = [];

										let prCookingRegions = prElement.cookingRegions ? prElement.cookingRegions : [];
										for (let prCtr = 0; prCtr < prCookingRegions.length; prCtr++) {
											let recipe = this.getRecipeByID(prCookingRegions[prCtr].regionRecipeID)
											if (recipe && recipe.length > 0) {
												let regObj = recipe[0];
												regObj.minGap = prCookingRegions[prCtr].gapLowerLimit / 100000;
												regObj.maxGap = prCookingRegions[prCtr].gapUpperLimit / 100000;
												productRecognitionObj.recipe_items.push(regObj)
											}
										}
										KCStore.setItem("selectedPRRecipes", JSON.stringify(productRecognitionObj.recipe_items));
										KCStore.setItem("ProductRecgDetails", JSON.stringify(productRecognitionObj));
									}

								}
								this.props.history.push({
									pathname: "/garlandCookbookCreation",
									previouspath: "/libraryList"
								});
							}

						}
						this.setState({
							loaderIconVisible: false
						});
					});
				}
				catch (ex) {
					this.setState({
						loaderIconVisible: false
					});
				}
			}
		})
			.catch(err => {
				this.setState({
					loaderIconVisible: false
				});
			});
	}

	editRecipeRedirect = (e) => {
		KCStore.setItem("RecipesListPage", "/cookbookAddRecipes");
		this.setState({ selected_item: e }
			, () => {
				this.OnEditLibrary()
			})
	}

	onSaveDetailsPopup = (event) => {
		const { data } = this.state;
		data.forEach((item) => {
			if (item.ID === event.ID) {
				item.DESCRIPTION = event.DESCRIPTION;
				item.ASSIGNED_CUSTOMER_GROUP = event.ASSIGNED_CUSTOMER_GROUP;
				item.UPDATED_ON = event.UPDATED_ON;
				item.UPDATED_BY = event.UPDATED_BY
			}
		})
		this.setState({
			data
		})
	}

	onCloseDetailsPopup = () => {
		this.setState({
			assignedTopologyDetails: null,
		})
	}

	onSuccessCloseModal = () => {
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		})
	}

	OnCancelLibrary = () => {
		$('#deleteModal').modal('hide');
	}

	OnSelectRecipe = (item) => {
		$('#deleteModal').modal('show');
		this.setState({
			selectedRecipeDetails: item && item.ID
		})
	}

	OnDeleteLibrary = async () => {
		const { selectedRecipeDetails } = this.state;
		const { formatMessage } = this.props.intl;

		this.setState({ loaderIconVisible: true });

		const postData = { libraryIds: selectedRecipeDetails };

		try {
			const response = await this.putData(postData, MenuUploadAPI.getLibraryList, HTTP_METHOD.DELETE);

			if (response && response.data && response.data.message && response.data.httpCode === "HTTP_200") {
				await this.getLibraryList();

				let allRecipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
				allRecipeDetails = allRecipeDetails ? JSON.parse(allRecipeDetails) : [];

				allRecipeDetails = allRecipeDetails.filter(item => item.ID !== selectedRecipeDetails);
				localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(allRecipeDetails)));

				let categoryDetails = JSON.parse(KCStore.getItem("resourceDetails"));

				categoryDetails = categoryDetails.map(item => {
					item.recipe_items = item.recipe_items.filter(recipe => recipe.ID !== selectedRecipeDetails);
					item.subCategory = item.subCategory.map(subItem => {
						subItem.recipe_items = subItem.recipe_items.filter(subRecipe => subRecipe.ID !== selectedRecipeDetails);
						return subItem;
					});
					return item;
				});

				KCStore.setItem("resourceDetails", JSON.stringify(categoryDetails));

				this.setState({
					success_modal: {
						open: true,
						message: formatMessage({ id: 'KC2380' })
					},
					loaderIconVisible: false,
				});
			}
		} catch (err) {
			this.showError(err);
		}
	}

	putData = (postData, url, method) => {
		this.setState({ loaderIconVisible: true });

		return new Promise((resolve, reject) => {
			http({ method, url, headers: {}, data: postData })
				.then(res => {
					const { data } = res;
					const { httpCode } = data;

					if (httpCode !== "HTTP_200") {
						reject({ response: { data: res.data } });
					} else {
						resolve(res);
					}
				})
				.catch(err => reject(err));
		});
	}

	performSearch = (dataList, search) => {
		return dataList.filter(recipe => {
			const libraryName = recipe.LIBRARY_NAME?.toLowerCase();
			const description = recipe.DESCRIPTION?.toLowerCase() ?? '';
			const createdInCookbook = recipe.CREATED_IN_COOKBOOK?.toLowerCase();
			const usedInCookbooks = recipe.USED_IN_COOKBOOK?.toLowerCase();
			search = search?.toLowerCase() ?? '';

			return libraryName?.includes(search) || description?.includes(search) || createdInCookbook?.includes(search) || usedInCookbooks?.includes(search);
		});
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { data: dataList = [], loaderIconVisible, selectedCategoryName, search, assignedTopologyDetails, success_modal } = this.state;
		const brandid = KCStore.getItem("selectedbrandID");
		const libraryAuthFeature = authService.getFeature("/libraryList");

		const searchedItemInFilter = this.performSearch(dataList, search);
		const displaySeparator = selectedCategoryName ? ' - ' : '';

		return (
			<React.Fragment>
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div class="menuUpdateWrapper">
						<div class="menuFilterHeader fixedHeader">
							<ul>
								<li>
									<Link to="/libraryList" title={formatMessage({ id: "KC0658" })}><FormattedMessage id='KC0658' /></Link>
									<Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180" /></Link>
								</li>
								<li>
									<ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
									<button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
								</li>
							</ul>
						</div>
						<div className="pageScrollbar">
							<div class="rcDeviceWrapper">
								<div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} alt="" /></div>
								<div class="recipeEditorWrapper">
									<div className="recipeEditorH selectedH">
										{selectedCategoryName} {displaySeparator} {formatMessage({ id: "KC1189" })}
									</div>
									<div class="cbSearchForm">
										<ul>
											<li>
												<div class="cbSearchField">
													<input type="text" class="form-control" onChange={(e) => this.recipeSearchResult(e)} placeholder={formatMessage({ id: 'KC1971' })}></input>
												</div>
											</li>
											<li>
												<button type="button" class="btn cbSearchButton">&nbsp;</button>
											</li>
										</ul>
									</div>
									<div class="reBodyWrapper RCScrollbar withSearchBar">
										<div class="reWrapperTable">
											<div class="reWrapperRow">
												<div class="reWrapperColmn alignTop">
													{
														searchedItemInFilter.map((item, i) => {
															return (
																<div class="cbAddRecipe" key={i}>
																	<ul>
																		<li><img src={item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : defaultRecipeImage}
																			alt="" /></li>
																		<li>{item.LIBRARY_NAME}</li>
																		<li>
																			<button type="button" className="btn menuInfo" data-toggle="modal" data-target="#detailsInfoModal" onClick={() => this.OnToggleDetails(item)}>&nbsp;</button>
																			<a href="#" className="btn menuEditV" onClick={(e) => { this.editRecipeRedirect(item, 'Recipe') }}>&nbsp;</a>
																			<button type="button" className="btn menuDelete" data-toggle="modal" onClick={() => this.OnSelectRecipe(item)}>&nbsp;</button>
																		</li>
																		<li>
																			<div className={libraryAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"} >
																				<input id={item.ID} name="RecipesList" type="checkbox" value={item.checked} checked={item.checked} onChange={(e) => this.onSelectLibrary(e, item)} />
																				<label htmlFor={item.ID}></label>
																			</div>
																		</li>
																	</ul>
																</div>
															)
														})
													}
												</div>
											</div>
										</div>
									</div>
									<div class="recipeEditorF">
										<button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
										<a href="#" onClick={(e) => { this.reciperedirect(e, 'Recipe') }} class="btn rcChefHat" title={formatMessage({ id: 'KC1211' })}>&nbsp;</a>
										<button type="button" onClick={() => { this.onAddRecipes() }} className="btn btn-default-text saveCButton" title={formatMessage({ id: 'KC1252' })}>&nbsp;</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{assignedTopologyDetails ? <CommonDetailViewEdit detailsProps={assignedTopologyDetails} onSave={this.onSaveDetailsPopup} onClose={this.onCloseDetailsPopup} thumbnail={assignedTopologyDetails.TYPE === "Settings" ? "" : assignedTopologyDetails.IMAGE_S3_SIGNED_URL ? assignedTopologyDetails.IMAGE_S3_SIGNED_URL : ""} /> : null}
				<div class="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-body">
								<div class="contentDiv">
									<p><FormattedMessage id="KC2929" /></p>
									<p><FormattedMessage id="KC2930" /></p>
									<p><FormattedMessage id="KC2931" /></p>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancelLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580" /></button>
								<button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDeleteLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611" /></button>
							</div>
						</div>
					</div>
				</div>
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
			</React.Fragment>
		)
	}
}

export default injectIntl(CookbookAddRecipes)
