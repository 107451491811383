import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import * as moment from 'moment';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';

class FrymasterDaylightSavingSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            daylightMonth: '',
            sundayWeekStarting: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        const { daylight = 'DST Start' } = this.props.history.location.state;

        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            if (daylight === "DST Start") {
                let selectedMonth = ObjSettingsFile.general.time_date.day_light_saving.dst_start.month;
                this.setState({
                    daylightMonth: selectedMonth ? selectedMonth : moment(Date.now()).format('MMMM'),
                    sundayWeekStarting: ObjSettingsFile.general.time_date.day_light_saving.dst_start.sunday
                })
            }
            if (daylight === "DST End") {
                let selectedMonth = ObjSettingsFile.general.time_date.day_light_saving.dst_end.month;
                this.setState({
                    daylightMonth: selectedMonth ? selectedMonth : moment(Date.now()).format('MMMM'),
                    sundayWeekStarting: ObjSettingsFile.general.time_date.day_light_saving.dst_end.sunday
                })
            }

        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        let { daylightMonth, sundayWeekStarting } = this.state;
        const { daylight = 'DST Start' } = this.props.history.location.state;

        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);

        if (!ObjSettingsFile.general.time_date) {
            ObjSettingsFile.general.time_date = {}
        }
        if (!ObjSettingsFile.general.time_date.day_light_saving) {
            ObjSettingsFile.general.time_date.day_light_saving = {}
        }

        if (daylight === "DST Start") {
            if (!ObjSettingsFile.general.time_date.day_light_saving.dst_start) {
                ObjSettingsFile.general.time_date.day_light_saving.dst_start = {}
            }
            ObjSettingsFile.general.time_date.day_light_saving.dst_start.month = daylightMonth;
            ObjSettingsFile.general.time_date.day_light_saving.dst_start.sunday = sundayWeekStarting;
        } else {
            if (!ObjSettingsFile.general.time_date.day_light_saving.dst_end) {
                ObjSettingsFile.general.time_date.day_light_saving.dst_end = {}
            }
            ObjSettingsFile.general.time_date.day_light_saving.dst_end.month = daylightMonth;
            ObjSettingsFile.general.time_date.day_light_saving.dst_end.sunday = sundayWeekStarting;
        }
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterDaylightSaving`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterDaylightSaving`);
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = KCStore.getItem("selectedbrandID");

        const { daylight = 'DST Start' } = this.props.history.location.state;
        const { daylightMonth, sundayWeekStarting } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Day light saving time selection Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Day light saving time selection Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")} alt="" />
                            </div>

                            {/* Frymaster Day light saving time selection Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button>{daylight}
                                </div>


                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li class="width90px verticalAlignT"><FormattedMessage id="KC0087"/></li>
                                                        <li class="leftAlign">
                                                            <div class="monthList">
                                                                {[{ label: "KC2047", value: 1 }, { label: "KC2048", value: 2 }, { label: "KC2049", value: 3 }, { label: "KC2050", value: 4 }, { label: "KC2051", value: 5 }, { label: "KC2052", value: 6 }, { label: "KC2053", value: 7 }, { label: "KC2054", value: 8 }, { label: "KC2055", value: 9 }, { label: "KC2056", value: 10 }, { label: "KC2057", value: 11 }, { label: "KC2058", value: 12 }].map((month, index) => {
                                                                    return (
                                                                        <button onClick={() => this.setState({ daylightMonth: month.value })} key={index} type="button" class={`btn ${month.value === daylightMonth ? "active" : ""}`}><FormattedMessage id={month.label}/></button>
                                                                    )
                                                                })}

                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul class="borderNone">
                                                        <li class="width90px verticalAlignT"><FormattedMessage id="KC1444"/></li>
                                                        <li class="sundayList leftAlign">
                                                            {[{ label: "1st", value: 1 }, { label: "2nd", value: 2 }, { label: "3rd", value: 3 }, { label: "4th", value: 4 }, { label: "5th", value: 5 }].map((sundayWeek, index) => {
                                                                return (
                                                                    <div key={index} class="customRadioBtn">
                                                                        <input id={sundayWeek.value} type="radio" name="Sunday" value={sundayWeek.value} onClick={(e) => this.setState({ sundayWeekStarting: Number(e.target.value) })} checked={sundayWeekStarting === sundayWeek.value} />
                                                                        <label for={sundayWeek.value}>{sundayWeek.label}</label>
                                                                    </div>
                                                                )
                                                            })}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Day light saving time selection Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterDaylightSavingSelection)
