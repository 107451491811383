
export const IsTouchDevice = () =>{
    return 'ontouchstart' in document.documentElement
    || navigator.maxTouchPoints > 0
    || navigator.msMaxTouchPoints > 0;
}

export const removeHoverClass = () => {
    if (IsTouchDevice()) { 
        try { 
            for (var si in document.styleSheets) {
                var styleSheet = document.styleSheets[si];
                if (!styleSheet.rules) continue;
    
                for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                    if (!styleSheet.rules[ri].selectorText) continue;
    
                    if (styleSheet.rules[ri].selectorText.match('a.navFilter:hover') || styleSheet.rules[ri].selectorText.match('a.navUser:hover')) {
                        styleSheet.deleteRule(ri);
                    }						   
                }
            }
        } catch (ex) {}
    }
}

export default  {
    IsTouchDevice,
    removeHoverClass
}