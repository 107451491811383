const _ = require('lodash')
const iterateKeys = {
    "country": 2,
    "chain": 3,
    "location": 4,
    "withinlocation": 5
}

const getAllOrgTagDetails = (data) => {
    let arr = [];
    const processLevels = (levels) => {
        levels.forEach((level) => {
            arr.push(level.OrgTagDetails);
            level.level4 && processLevels(level.level4);
            level.level5 && processLevels(level.level5);
        });
    };

    const processLevel2 = (level2) => {
        level2.forEach((l2) => {
            arr.push(l2.OrgTagDetails);
            l2.level3 && processLevel3(l2.level3);
            l2.level4 && processLevels(l2.level4);
        });
    };

    const processLevel3 = (level3) => {
        level3.forEach((l3) => {
            arr.push(l3.OrgTagDetails);
            l3.level4 && processLevels(l3.level4);
            l3.level5 && processLevels(l3.level5);
        });
    };

    data.forEach((l1) => {
        l1.level2 && processLevel2(l1.level2);
        l1.level3 && processLevel3(l1.level3);
    });

    arr = arr.flat();
    arr = _.uniqBy(arr, (obj) => obj.tagName);
    arr = _.orderBy(arr, 'tagName');
    return arr;
};

// Country - level2
const getOrgTagsLevel2 = (data) => {
    let arr = [];
    const processLevel = (level) => {
        if (level) {
            level.forEach((element) => {
                arr.push(element.OrgTagDetails);
                processLevel(element.level4);
                processLevel(element.level5);
            });
        }
    };
    data.dataSource.forEach((item) => {
        arr.push(item.OrgTagDetails);
        processLevel(item.level3);
    });
    arr = arr.flat();
    arr = _.uniqBy(arr, (obj) => obj.tagName);
    arr = _.orderBy(arr, 'tagName');
    return arr;
};


const getOrgTagsLevel3 = (data) => {
    let arr = [];
    const processLevel = (level) => {
        if (level) {
            level.forEach((element) => {
                arr.push(element.OrgTagDetails);
                processLevel(element.level5);
            });
        }
    };
    data.dataSource.forEach((item) => {
        arr.push(item.OrgTagDetails);
        processLevel(item.level4);
    });
    arr = arr.flat();
    arr = _.uniqBy(arr, (obj) => obj.tagName);
    arr = _.orderBy(arr, 'tagName');
    return arr;
}

const getOrgTagsLevel4 = (data) => {
    let arr = [];
    const processLevel = (level) => {
        if (level) {
            level.forEach((element) => {
                arr.push(element.OrgTagDetails);
            });
        }
    };
    data.dataSource.forEach((item) => {
        arr.push(item.OrgTagDetails);
        processLevel(item.level5);
    });
    arr = arr.flat();
    arr = _.uniqBy(arr, obj => obj.tagName);
    arr = _.orderBy(arr, 'tagName');
    return arr;
}

const getOrgTagsLevel5 = (data) => {
    let arr = [];
    data.dataSource.forEach((item) => {
        arr.push(item.OrgTagDetails);
    });
    arr = arr.flat();
    arr = _.uniqBy(arr, obj => obj.tagName);
    arr = _.orderBy(arr, 'tagName');
    return arr;
}


const getOrgTags = (orgValue, nodeinfoObject) => {
    let orgSmartTags = [];
    if (Object.keys(nodeinfoObject).length > 0) {
        if (nodeinfoObject.dataSource && Object.keys(nodeinfoObject.dataSource).length !== 0) {
            if (iterateKeys[nodeinfoObject.field_name] == 2) orgSmartTags.push(getOrgTagsLevel2(nodeinfoObject));
            if (iterateKeys[nodeinfoObject.field_name] == 3) orgSmartTags.push(getOrgTagsLevel3(nodeinfoObject));
            if (iterateKeys[nodeinfoObject.field_name] == 4) orgSmartTags.push(getOrgTagsLevel4(nodeinfoObject));
            if (iterateKeys[nodeinfoObject.field_name] == 5) orgSmartTags.push(getOrgTagsLevel5(nodeinfoObject));
        } else if (Object.keys(nodeinfoObject).length > 0) {
            if (Object.keys(nodeinfoObject[0].category_id == 2)) {
                orgSmartTags.push(getAllOrgTagDetails(orgValue));
            }
        } else {
            orgSmartTags.push(getAllOrgTagDetails(orgValue));
        }
    } else {
        orgSmartTags.push(getAllOrgTagDetails(orgValue));
    }
    orgSmartTags = orgSmartTags.flat();
    return orgSmartTags;
}


export {
    getOrgTags
};


