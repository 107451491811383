import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import KCStore from '../../../../utils/kcStorage';

const getPercentage = value => value !== 0 ? value.toFixed(1) + "%" : "0%";
const getTitleText = (totalUsage, totalQuota) => `API Calls: ${totalUsage}/${totalQuota}`;
const getToolTipMsg = (copied) => `${copied ? 'Copied':'Copy'} to clipboard`;

const DefaultTextLabel = ({value}) => value  || "-";

const IssuedOn = ({date}) =>{
    if(date){
        return moment(date).format("MMM DD, YYYY - HH:mm")
    }
    return "-";
}

export const DescriptionHeader = () => {
    return(
<div class="swaggerHeader">
				<ul>
					<li> 
						<p><FormattedMessage id="KC1608"/></p>
					</li>  
				</ul>
			</div>
    )
}


const MaskedInputBox = ({value, mask, ...rest}) =>{

    const [apiKey, setApiKey] = useState()

    const getMaskedString = (value) =>{
        if(value){
            const str1 = value.substr(0,4);
            const str2 = value.substr(value.length - 4);
            return `${str1}***************${str2}`;
        }
        return "-"
        
    }

    useEffect(()=> {
        if(mask){
            setApiKey(getMaskedString(value))
        }else{
            setApiKey(value)
        }

    },[mask, value])

    return(
        <input value={apiKey} {...rest}/>
    )
}


const KCApiGroup = (props) => {
	const onSelectHandler = (fileVal) => {
		KCStore.setItem('ymlFileName', fileVal);
	}
	return (
		<ul className="tBody">
			<li>{props.index}</li>
			<li>{props.title}</li>
			<li>&nbsp;</li>
			<li><Link to={{ pathname: '/swaggerInterface', fileName: `${props.fileName}` }} onClick={() => onSelectHandler(`${props.swaggerPath}`)} className="btn btn-default" title={props.title}>{<FormattedMessage id="KC1614"/>}</Link></li>
		</ul>
	)
}

export const CycleGraph = (props) => (
	    <ul class="tBody">
				<li>{props.cycle}</li>
				<li> {props.startDateString} to {props.endDateString} </li>
				<li>
					<div class="progress" title={getTitleText(props.totalUsages, props.totalQuota)}>
						<div class="progress-bar bg-info" role="progressbar"
						 style={{width: getPercentage(props.percentageUsed)}} 
						 aria-valuenow={props.percentageUsed} aria-valuemin="0" 
						 aria-valuemax="100">
							{getPercentage(props.percentageUsed)}
					</div>
					</div>
				</li>
		</ul>
);


export const HeaderTitle = () => {
	return (
		<div class="swaggerHeader">
			<ul>
				<li>
					<a href="swaggerlist.html" title="C2C API Developer Portal" class="active">API Management</a>
				</li>
			</ul>
		</div>
	)
}

export const APIKeyDetail = (props) => {
    const [maskSwitch, setMaskSwitch] = useState(1);
    const [clipboardMessage, setClipboardMessage] = useState(getToolTipMsg(false))
    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.secret);
        setClipboardMessage(getToolTipMsg(true))
    }
 
    return (
        <>
            <div class="C2CAPITitle"><FormattedMessage id="KC2581"/></div>
            <div class="APIKeyDetails">
                <ul>
                    <li><FormattedMessage id="KC1609"/></li>
                    <li>
                        <div class="form-group pass">
                            <div class="input-group">

                                <MaskedInputBox mask={maskSwitch} type="text" class="form-control pwd" name="PasswordHash" value={props.secret} readOnly />
                                <div class="input-group-append">
                                    <button class={`btn eyeBtn ${maskSwitch ? '' : 'active'}`} type="button" onClick={() => setMaskSwitch(!maskSwitch)}>
                                        &nbsp;
                                    </button>
                                    <button class="btn copyBtn" type="button" onClick={copyToClipboard} onMouseOut={() => setClipboardMessage(getToolTipMsg(false))} >
                                        <Tooltip padding={5} content={clipboardMessage} tipContentClassName="c2c_api_key">
                                            &nbsp;
                                        </Tooltip>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-default" onClick={props.onGenerateApiKey}>
                            {props.secret ? <FormattedMessage id="KC2578"/>:<FormattedMessage id="KC2920"/>}
                        </button></li>
                </ul>
                <ul>
                    <li><FormattedMessage id="KC2579"/></li>
                    <li><IssuedOn date={props.createdAt}/></li>
                </ul>
                <ul>
                    <li><FormattedMessage id="KC2580"/></li>
                    <li><DefaultTextLabel value={props.expiry}/></li>
                </ul>
            </div>
        </>
    )
} 

export const APIUsageGraph = (props) => {

	return (
		<div>
			<div class="C2CAPITitle"><FormattedMessage id="KC1603"/></div>
			<div class="viewSubscriptionList">
				<ul class="tHead">
					<li>Cycle</li>
					<li><FormattedMessage id="KC0197"/></li>
					<li><FormattedMessage id="KC1605"/></li>
				</ul>
			</div>
			<div class="viewSubscriptionList">
				{props.children}
			</div>
		</div>
	)
}

export const KCApiGroups = () => {

    //let brands =  JSON.parse(KCStore.getItem('brandId'));

	const data = [
		{id:1, title: 'Device Management', fileName: 'deviceManagement', swaggerPath: 'device-management-swagger.yaml' },
		{id:2, title: 'Organization Management', fileName: 'organizationManagement', swaggerPath: 'organization-management-swagger.yaml' },
		{id:3, title: 'File Management', fileName: 'filesManagement', swaggerPath: 'files-management-swagger.yaml' },
		{id:4, title: 'Production Management', fileName: 'productionManagement', swaggerPath: 'production-management-swagger.yaml' },
        {id:5, title: 'Push Data Service Management', fileName: 'pushDataManagement', swaggerPath: 'push-data-configuration-management-swagger.yaml' }
	]
    
    const softedData = data.sort((a, b)=> {
        return a.id - b.id;
    });

	return (
		<>
			<div className="swaggerAPITable">
				<ul className="tHead">
					<li>#</li>
					<li>Kitchen Connect API</li>
					<li>&nbsp;</li>
					<li>&nbsp;</li>
				</ul>
			</div>
			<div className="swaggerAPITable">
				{softedData.map((item, index) => <KCApiGroup {...item} index={index + 1} />)}
			</div>
		</>
	)
}

export const RegenerateConfirmBox = ({open, onConfirm, onClose}) =>{

    return (
        <Modal open={open} showCloseIcon={false} center onClose={onClose}>
            
                    <div className="modal fade confirmationRMModal show" data-keyboard="true" data-backdrop="static" id="confirmationOkModa1" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div class="modal-dialog modal-md loginModal">
                            <div class="modal-content">
                                <div class="modal-header modal-header-danger">
                                    <FormattedMessage id="KC2578"/>
                                </div>
                                <div class="modal-body">                                    
                                    <div class="reportStatusModal">
                                        <div class="smallText"><FormattedMessage id="KC2582"/></div>
                                    </div>
                                </div>
                                <div class="modal-footer textAlignCenter">
                                    <button class="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button" onClick={onClose}><FormattedMessage id="KC0021" /></button>
                                    <button class="btn btn-secondary deleteYesBtn" id="deleteYesBtn" data-dismiss="modal" type="button" onClick={onConfirm}><FormattedMessage id="KC0558" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
               
            </Modal>
    );
}


