import { smartTagMangement } from '../../service/api';
import http from '../../service/httpService';
import _ from 'lodash';
import authService from '../../service/authService';

import { SET_SMART_TAG_DETAILS, SET_SMART_TAG_DETAILS_LOADING, SMART_TAG_FAIL} from '../stateActionsData/type';
import KCStore from '../../utils/kcStorage';

const setUnitData = ( data ) => ({ type: SET_SMART_TAG_DETAILS, payload: data});
const setUnitLoader= ( bol ) => ({ type: SET_SMART_TAG_DETAILS_LOADING, payload: true});

export const  getSmartTagDetails =  (type,_search,flag,BRAND_ID)  => dispatch => {   

    dispatch(setUnitData([]));
    dispatch(setUnitLoader());

    let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
    if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {            
        flag = 'yes';
    } else {
        flag = 'no';
    }
    let url = smartTagMangement.getTagList + type;
    if (_search) {
        url = `${url}${_search}`;
    }

    http.get(url,
        {
          headers: {
            'Content-Type': 'application/json',
            'customerid': KCStore.getItem("customerId"),
            'cgid': KCStore.getItem("custGroupID"),
            'brandid': BRAND_ID,
            'registrationflag':flag,
            }, data: {}
        }
        ).then( response => {
            let { data } = response.data;
            let _data = data;            
            dispatch(setUnitData(_data));
        }).catch(error => {
            dispatch({
                type: SMART_TAG_FAIL,
                payload:false
            });
        });
}