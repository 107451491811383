import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup"
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { FormattedMessage, useIntl } from 'react-intl';

import http from '../../service/httpService';
import auth from '../../service/authService';
import { BaseService, UserManagement, chinaGovRegistrationLink } from '../../service/api';
import FLSuccessMoal from '../modal/fl-success-model';
import FLSuccessMoalBackToHome from '../modal/fl-home-success-model';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import AccessModal from '../modal/access-model';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import SupportButton from '../../components/controls/supportButton'
import { resetLocale, setLocale } from "../../actions/localeAction/localeAction";
import { isCaptchaEnabled } from '../../utils/captcha';
import { footerTextCustom, icpTextCustom } from '../../components/controls/footerComponent';
import { encriptData } from "../../utils/dashboardRenderer";
import { loginAPIUtils } from './loginUtils';
import FormikController from '../../UI_Components/FormikController';
import LanguagePreferenceCommon from '../../components/languageDropdown/LanguagePreferenceCommon';
import Captcha from '../controls/Captcha';

import 'bootstrap/dist/css/bootstrap.min.css';

import KCStore from '../../utils/kcStorage';

const Login = (props) => {
	const intl = useIntl();
	const { formatMessage } = intl;
	const _reCaptchaRef = React.useRef();
	const dispatch = useDispatch();

	const [disableUserInput, setDisableUserInput] = React.useState(false);
	const [rememberme, setRememberMe] = React.useState(false);
	const [isVerified, setIsVerfied] = React.useState(false);
	const [goToConsumer, setGoToConsumer] = React.useState(false);
	const [error_modal, setErrorModal] = React.useState({ open: false, message: '' });
	const [fl_success_modal, setFLSuccessModal] = React.useState({ open: false, message: '' });
	const [fl_home_success_modal, setFLHomeModal] = React.useState({ open: false, message: '' });
	const [success_modal, setSuccessModal] = React.useState({ open: false, message: '' });
	const [access_modal, setAccessModal] = React.useState({ open: false, message: '' });
	const [loaderIconVisible, setLoaderIconVisible] = React.useState(false);
	const [errors, setErrors] = React.useState({});
	const [type, setType] = React.useState('password');
	const [newpwdtype, setNewPwdType] = React.useState('password');
	const [oldpwdtype, setOldPwdType] = React.useState('password');
	const [retypenewpwdtype, setRetypePwdType] = React.useState('password');
	const [showFirstLogin, setShowFirstLogin] = React.useState(false);
	const [maintenanceText, setMaintenanceText] = React.useState('');
	const [initialValues, setInitialValues] = React.useState({
		email: "",
		password: ""
	});
	const [firstLoginValues, setFirstLoginValues] = React.useState({
		emailId: "",
		oldPassword: "",
		newPassword: "",
		reTypePassword: ""
	})
	const langDetails = useSelector(state => state.localeInfo.locale);
	

	


	React.useEffect(() => {
		uiActivities();
		cookieHandle();
		//dispatch(resetLocale()); when we will come back to the login page from anyother page language dropdown not loaded. so commended this line.
		getMaintenanceText();//get Maintenance Text during deployment
		//get Ready Recipe details from brand website
		const search = props.location.search;
		const params = new URLSearchParams(search);
		const readyrecipeids = params.get('ready_recipe');
		const name = params.get('name');
		const encodedEmail = params.get('euc');
		const decodedEmail = atob(encodedEmail);
		const brand = params.get('brand_id');
		const lang = params.get('lang');
		console.log("lang in login == ",lang)
		if(lang) {
			settingLocale(lang)
		}
		
		const model = params.get('equipment_model');
		if (readyrecipeids && (brand === "Convotherm" || brand === "Merrychef")) {
			let objbrandsitedata = { readyrecipeids, name, brand, brandid: brand === "Merrychef" ? 1 : 6, model };
			KCStore.setItem("kcbrandsitedata", JSON.stringify(objbrandsitedata));
			KCStore.setItem("selectedbrandID", objbrandsitedata.brandid);
			KCStore.setItem("brandName", brand ? brand.toUpperCase() : '');
			KCStore.setItem("selected_cgid", '[]');
			KCStore.setItem("selectedFamilyID", '26,27,36'); // allowing only C4, maxx & maxx pro model families
		}

		if (auth.getJwt()) {
			redirect();
		} else if (auth.getRememberMe()) {
			let credentails = auth.getCurrentUser();
			if (credentails != null) {
				setLoaderIconVisible(true);
				setRememberMe(true);
				setInitialValues({
					email: credentails.username,
					password: credentails.password
				})
				auth.login(credentails, true).then(() => {
					setLoaderIconVisible(false);
					redirect();
				}).catch(err => {
					showError(err, credentails.username, credentails.password);
					setLoaderIconVisible(false);
				});
			}
		}
		if (decodedEmail && encodedEmail) {
			setInitialValues({ email: decodedEmail, password: '' });
			setDisableUserInput(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const cookieHandle = () => {
		if (!KCStore.getItem('isCookieAccepted')) {
			$("#cookieWrapper").hide();
			$("#cookieWrapperBtn").show();
			$('.supportButtonWrapper').hide();
		}
	}

	const uiActivities = () => {
		document.body.style.backgroundColor = "#0d3968";
		$('body').removeClass("subscriptionBg");
		$("body").addClass("loginBg");
		$("body").removeClass("modal-open");
		$("body").removeClass("deviceRegtrnBg");
		updateLoginDimensions();
		setTimeout(() => {
			var width = $('.g-recaptcha').parent().width();
			var scale = width / 304;
			$('.g-recaptcha').css('transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
			$('.g-recaptcha').css('transform-origin', '0 0');
			$('.g-recaptcha').css('-webkit-transform-origin', '0 0');
			$('.g-recaptcha').css('display', 'block');
		}, 1000);
	}

	const redirect = () => {
		if (KCStore.getItem("kcbrandsitedata")) {
			let objbrandsitedata = JSON.parse(KCStore.getItem("kcbrandsitedata"));
			if (objbrandsitedata.readyrecipeids) {
				if (objbrandsitedata.brand.toUpperCase() === 'MERRYCHEF') {
					return props.history.push(`/assignLegacyRecipeToTopology`);
				} else if (objbrandsitedata.brand.toUpperCase() === 'CONVOTHERM') {
					return props.history.push(`/assignLegacyCookbookToTopology`);
				}
			}
		} else {
			var urlParams = new URLSearchParams(window.location.search);
			const redirectParam = urlParams.get("redirectUri");
			if (redirectParam === "payment") {
				props.history.push('/subscription/payments');
			} else {
				props.history.push('/dashboardLocations');
			}
		}
	}

	const updateLoginDimensions = () => {
		var windowHeight = $(window).height();
		var welcomTextH = $(".welcomText").height();
		var bgAdminH = $(".bgAdmin").height() - 25;
		var loginButtonH = $(".loginButton > ul:nth-child(2)").height();
		var cookieWrapperH = $(".cookieWrapper").height() + 10;
		//Sign In & Sign Up Modal
		var maintenanceTextH = $(".maintenanceText ").height() + 35;
		var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH) + (loginButtonH) + (cookieWrapperH) + (maintenanceTextH));
		var loginScrollbarHeightS = ((windowHeight) - (welcomTextHbgAdminH)) - 60;
		$(".loginButton > ul:nth-child(1) > li").css("height", loginScrollbarHeightS);
	}

	const onSuccessCloseModal = () => {
		setSuccessModal({ open: false })
		props.history.push(`/dashboardLocations`);
	}

	const onFLSuccessCloseModal = () => {
		setFLSuccessModal({ open: false })
		if (goToConsumer) {
			setGoToConsumer(false)
			props.history.push('/consumer')
		} else {
			props.history.push('/');
		}
	}

	const getMaintenanceText = () => {
		setAccessModal({ open: false })
		http({
			method: "POST",
			url: UserManagement.getMaintenanceText,
			data: { "message": "MAINTENANCE_TEXT" },
		}).then(response => {
			let { status, data } = response;
			if (status === 200) {
				setLoaderIconVisible(false);
				setMaintenanceText(data.data === "NA" ? "" : data.data)
			}
		})
	}

	const accessNowModel = (username) => {
		setAccessModal({ open: false })
		let postData = {
			"type": "request-login-access",
			"user": {
				"email": username
			}
		}

		http({
			method: "POST",
			url: UserManagement.requestAccess,
			data: postData,
		}).then(response => {
			let { status } = response;
			if (status === 200) {
				setLoaderIconVisible(false);
				setSuccessModal({
					open: true,
					message: "Email notification sent to admin." //MLS:TODO
				})
			}
		})
	}

	const accessLaterModel = () => {
		setAccessModal({ open: false })
		props.history.push('/');
	}

	const closeModel = () => {
		setErrorModal({ open: false })
		setFLHomeModal({ open: false })
		setFLSuccessModal({ open: false })

	}

	const togglePwd = () => {
		setType(type === 'password' ? 'text' : 'password')
	}

	const toggleOldPwd = () => {
		setOldPwdType(oldpwdtype === 'password' ? 'text' : 'password')
	}

	const toggleNewPwd = () => {
		setNewPwdType(newpwdtype === 'password' ? 'text' : 'password')
	}

	const toggleRetypeNewPwd = () => {
		setRetypePwdType(retypenewpwdtype === 'password' ? 'text' : 'password')
	}

	const showError = (err, inputdataemailobj, inputdatapwdobj) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (response && response.status === 500) {
				if (data.errorCode === "FIRST-LOGIN") {
					setLoaderIconVisible(false);
					setFLSuccessModal({
						open: true,
						message: 'KC0947'
					})
					setErrorModal({ open: false });
					setFirstLoginValues({ emailId: inputdataemailobj, oldPassword: inputdatapwdobj, newPassword: '', reTypePassword: '' })
					setShowFirstLogin(true);
				} else if (data.responseCode === "KC_ERR_500" && data.message === "User is not active.") {
					setLoaderIconVisible(false);
					setAccessModal({
						open: true,
						message: formatMessage({ id: "KC0903" })
					})
					setFirstLoginValues({ emailId: inputdataemailobj })
				} else {
					setLoaderIconVisible(false);
					setErrorModal({ open: true, message: 'KC0020' });
				}
			}
		} else {
			setSuccessModal({
				open: false,
				message: `You successfully created your personal password! Please Log in` //MLS:TODO
			});
			setLoaderIconVisible(false);
			setShowFirstLogin(false)
		}
	}

	const postComment = (formik) => {
		const { values, isValid } = formik;
		if(isValid && values.email !== "" && values.password !== ""){
			setLoaderIconVisible(true);
			let postData = {
				username: values.email.toLowerCase(),
				password: values.password,
			}
			setFirstLoginValues({ emailId: postData.username, oldPassword: postData.password, newPassword: '', reTypePassword: '' })
			putData(postData, rememberme, postData.username, "login").catch(err => {
				console.log("ERROR in POST = ", err)
				showError(err, postData.username, postData.password);
				return;
			});
		}
	}

	const createPassword = async (formik) => {
		setLoaderIconVisible(true);
		const { emailId, oldPassword, newPassword } = formik.values;
		let postData = { username: emailId, oldPassword, newPassword };
		const newPayload = await encriptData(JSON.stringify(postData))
		http({
			method: "PUT",
			url: BaseService.first_login,
			data: { credential: newPayload },
		}).then(res => {
			let { data } = res;
			let { statusCode } = data;
			if (statusCode === 200) {
				let postDataRes = {
					username: postData.username,
					password: postData.newPassword,
				}
				setShowFirstLogin(false);
				putData(postDataRes, rememberme, postData.username, "createPassword")
			}
		}).catch(err => {
			showSignupError(err);
		});
	}

	const rememberMeCheckboxChange = (e) => {
		setRememberMe(e.currentTarget.checked);
	};

	const showSignupError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				setErrorModal({
					open: true,
					message: 'KC0020'
				});
			}
		} else {
			setSuccessModal({
				open: false,
				message: `You successfully created your personal password! Please Log in` //MLS:TODO
			});
			setLoaderIconVisible(false);
			setShowFirstLogin(false)
		}
	}

	const settingLocale = (data) => {
		dispatch(setLocale(data));
	};

	const putData = (postData, rememberme, username, from) => {
		return new Promise((resolve, reject) => {
			auth.login(postData, rememberme).then(async res => {
				let { status, data: loginData } = res;
				if (status === 200) {
					const payLoadData = {
						'userId': username,
						"roleId": loginData.role,
						"customerId": loginData.customerId,
						"content": "required",
					}
					KCStore.setItem('TandCDetails', JSON.stringify(payLoadData));
					const tandcDetails = await proceedTaCValidation(payLoadData);
					const inputData = { username, from, loginData, res, tandcDetails, rememberme };					
					if (tandcDetails.body.policyAccepted || tandcDetails.body.message === "No data found") {
						await loginAPIUtils(inputData, props.history.push, settingLocale);
					} else {
						KCStore.setItem('loginInfoDet', JSON.stringify(inputData));
						resolve(props.history.push({
							pathname: "/termsandconditions",
							inputData: inputData
						}));
					}
				} else {
					reject({
						res: {
							data: res.data
						}
					});
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	const verifyCallback = (recaptchaToken) => {
		if (recaptchaToken) {
			setIsVerfied(true)
			setErrors({
				...errors,
				recaptcha: ''
			});
		}
	}

	// Woopra Events
	const trackEvent = (e) => {
		WoopraEvents(`${Constants.CREATE_ACCOUNT}`);
	}

	const forgotPass = () => {
		WoopraEvents(`${Constants.FORGOT_PASSWORD}`);
	}

	const acceptCookie = () => {
		KCStore.setItem('isCookieAccepted', true);
		$("#cookieWrapper").show();
		$("#cookieWrapperBtn").hide();
		$('.supportButtonWrapper').show();
	}

	const proceedTaCValidation = async (postdata) => {
		return new Promise(async (res, reject) => {
			let url = BaseService.getTandCdetails;
			await http.post(url, postdata,
				{
					headers: {
						'Content-Type': 'application/json',
					}
				}
			).then(response => {
				if (response && response.data) {
					res(response.data)
				}
			}).catch(err => {
				reject(err);
			});
		})
	}

	const validationSchema = () => {
		return Yup.object().shape({
			email: Yup.string()
				.required(<FormattedMessage id="KC0018" />)
				.email(<FormattedMessage id="KC2654" />),
			password: Yup.string()
				.required(<FormattedMessage id="KC0019" />)
		})
	}

	const firstLoginScheme = () => {
		const test= <FormattedMessage id="KC2923"/>;
		return Yup.object().shape({
			emailId: Yup.string()
				.required(<FormattedMessage id="KC0018" />)
				.email(<FormattedMessage id="KC2654" />),
			oldPassword: Yup.string()
				.required(<FormattedMessage id="KC0019" />),
			newPassword: Yup.string()
				.required(<FormattedMessage id="KC0908" />)
				.notOneOf([Yup.ref('oldPassword'), null], <FormattedMessage id="KC0910" />)
				.min(8, <FormattedMessage id="KC0909" />)
				.matches(/[a-z]+/, formatMessage({ id: 'KC2923' }))
				.matches(/[A-Z]+/, formatMessage({ id: 'KC2924' }))
				.matches(/[@$!%*#?&]+/, formatMessage({ id: 'KC2925' }))
				.matches(/\d+/, formatMessage({ id: 'KC2926' })),
			reTypePassword: Yup.string()
				.required(<FormattedMessage id="KC0019" />)
				.oneOf([Yup.ref('newPassword'), null], <FormattedMessage id="KC0912" />)
		})
	}

	let icpNumber = (icpTextCustom() ? <a target="_blank" href={'https://beian.miit.gov.cn/#/Integrated/recordQuery'} className="footerCookiePolicy" title="浙ICP备2022028829号">{icpTextCustom}</a> : "");

	const firstLogin = <div className="loginButton">
		<Formik
			initialValues={firstLoginValues}
			validationSchema={firstLoginScheme}
			isInitialValid={false}
		>
			{(formik) => (
				<>
					<ul>
						<li>&nbsp;</li>
						<li>
							<div className="loginIWrapper">
								<div className="existingLoginForm">
									<ul>
										<li className={`form-group marginBottom10px`}>
											<FormikController
												className="input-group"
												inputClass="form-control disabled"
												placeholder={formatMessage({ id: "KC0007" })}
												control="input"
												type="email"
												errorClass="required"
												name="emailId" />
										</li>
										<li className="form-group pass">
											<FormikController
												className="input-group"
												inputClass="form-control pwd disabled"
												placeholder={formatMessage({ id: "KC2927" })}
												control="input"
												type={oldpwdtype}
												errorClass="required"
												name="oldPassword"
												isToggleEye={true}
												togglePwd={toggleOldPwd}
												toggleEyeState={oldpwdtype === 'text'} />
										</li>
										<li className="form-group pass">
											<FormikController
												className="input-group"
												inputClass="form-control pwd"
												placeholder={formatMessage({ id: "KC0126" })}
												control="input"
												type={newpwdtype}
												errorClass="required"
												name="newPassword"
												isToggleEye={true}
												togglePwd={toggleNewPwd}
												toggleEyeState={newpwdtype === 'text'} />
										</li>
										<li className="form-group pass">
											<FormikController
												className="input-group"
												inputClass="form-control pwd"
												placeholder={formatMessage({ id: "KC0128" })}
												control="input"
												type={retypenewpwdtype}
												errorClass="required"
												name="reTypePassword"
												isToggleEye={true}
												togglePwd={toggleRetypeNewPwd}
												toggleEyeState={retypenewpwdtype === 'text'} />
										</li>
										<li className="form-group pass">
											<div className="input-group">
												<div className="customCheckbox customCheckboxWlabel">
													<input id="RememberMe" name="RememberMe" type="checkbox" value={rememberme} defaultChecked={rememberme}
														onChange={(e) => rememberMeCheckboxChange(e)} />
													<label htmlFor="RememberMe">
														<FormattedMessage id='KC0009' />
													</label>
												</div>
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
								</div>
							</div>
						</li>
						<li>&nbsp;</li>
					</ul>
					<ul>
						<li></li>
						<li>
							<div className="loginIWrapper">
								<button className={`btn btn-secondary singINBtn ${formik.isValid && formik.dirty ? '' : 'disabled'}`} type="button" id="SignInButton" onClick={() => createPassword(formik)}><FormattedMessage id='KC0023' /></button>
							</div>
						</li>
						<li>&nbsp;</li>
					</ul>
				</>
			)}
		</Formik>
	</div>;

	const loginFunctionality = <div className="loginButton">
		<ul>
			<li>&nbsp;</li>
			<li>
				<div className={showFirstLogin ? "loginIWrapper hideForFirstLogin" : "loginIWrapper"}>
					<div className="existingLoginForm">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							isInitialValid={true}
						>
							{(formik) => (
								<ul>
									<li className={`form-group ${disableUserInput ? 'disabled' : ''}`}>
										<FormikController
											className="input-group"
											inputClass="form-control"
											placeholder={formatMessage({ id: "KC0007" })}
											control="input"
											value={formik.values.email.toLowerCase().trim()}
											type="email"
											errorClass="required"
											name="email" />
									</li>
									<li className="form-group pass">
										<FormikController
											className="input-group"
											inputClass="form-control"
											placeholder={formatMessage({ id: "KC0008" })}
											control="input"
											type={type}
											errorClass="required"
											name="password"
											isToggleEye={true}
											togglePwd={togglePwd}
											toggleEyeState={type === 'text'} />
									</li>
									<li className="form-group pass">
										<div className="customCheckbox customCheckboxWlabel">
											<input id="RememberMe" name="RememberMe" type="checkbox" value={rememberme} defaultChecked={rememberme}
												onChange={(e) => rememberMeCheckboxChange(e)} />
											<label htmlFor="RememberMe">
												<FormattedMessage id='KC0009' />
											</label>
										</div>
										<Link className="forgotpassword" id="ForgotPassword" to="/ForgotPassword" title={formatMessage({ id: 'KC0002' })} onClick={forgotPass}><FormattedMessage id='KC0002' /></Link>
									</li>
									{isCaptchaEnabled() ? <li>
										<div className="g-recaptcha">
											<Captcha ref={_reCaptchaRef} language={langDetails} onChange={verifyCallback} />
										</div>
										<div className="errorMsg">{errors.recaptcha}</div>
									</li> : null}
									<li className={isCaptchaEnabled() ? formik.isValid && formik.dirty && isVerified ? "form-group pass" : "disabled" : formik.isValid && formik.dirty ? "form-group pass" : "disabled"}>
										<button className="btn btn-secondary existingSingINBtn" type="submit" id="existingSignInButton" onClick={() => postComment(formik)}><FormattedMessage id='KC0013' /> </button>
									</li>
								</ul>
							)}
						</Formik>
					</div>
				</div>
			</li>
			<li>&nbsp;</li>
		</ul>
		<ul>
			<li></li>
			<li>
				<div className="loginIWrapper">
					<Link to="/register-unit" title={formatMessage({ id: 'KC0003' })} onClick={(e) => trackEvent(e)}><FormattedMessage id='KC0003' /></Link>
					{chinaGovRegistrationLink.govLink ? <a className="forChinese" href="#" onClick={() => window.open(`//${chinaGovRegistrationLink.govLink}`)}>浙ICP备19012273号-3</a> : ""}
				</div>
			</li>
			<li>&nbsp;</li>
		</ul>
	</div>;

	return (
		<React.Fragment>
			<div className="loginBg">
				<div id="adminWrapper">
					<div id="loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin login-nav">
						<Link className="navbar-brand welbiltLogo" title={formatMessage({ id: 'KC0038' })} to="#">&nbsp;</Link>
						<div id="login-pre">
							<LanguagePreferenceCommon />
						</div>
					</nav>
					<div id="page-wrapper">
						<div className="welcomText">
							<FormattedMessage id='KC0006' />
						</div>
						<div className="maintenanceText">{maintenanceText}</div>
						<div className="LoginBtnWrapper">
							{!showFirstLogin && <div id="loginBtnFormID">{loginFunctionality}</div>}
							{showFirstLogin && <div id="loginBtnChangePassword">{firstLogin}</div>}
						</div>
						{/* Cookie Banner implementation */}
						<div className="cookieWrapper" id="cookieWrapper">
							<div className="footerDiv"><FormattedMessage id='KC2617'/> <a className="footerPrivacyNotice" href={'https://www.welbilt.com/privacy-and-legal-notices#Privacy-Policy'} target="_blank" title="Privacy Notice">Privacy Notice</a> | <a target="_blank" href={'https://www.welbilt.com/privacy-and-legal-notices#Cookies-Policy'} className="footerCookiePolicy" title="Cookie Policy">Cookie Policy</a> {icpTextCustom() ? " | " : ""} {icpNumber}</div>
						</div>
						<div className="cookieWrapperBtn" id="cookieWrapperBtn">
							<ul>
								<li>
									<p><FormattedMessage id='KC2617'/> <a href={'https://www.welbilt.com/privacy-and-legal-notices#Cookies-Policy'} target="_blank" title={formatMessage({ id: 'KC2922' })}><FormattedMessage id='KC2902'/></a>.</p>
								</li>
								<li>
									<button className="btn essentialOnlyBtn" type="button" onClick={acceptCookie} id="essentialOnlyBtn"><FormattedMessage id='KC2913'/></button>
									<button className="btn acceptBtn" type="button" onClick={acceptCookie} id="acceptBtn"><FormattedMessage id='KC0899'/></button>
								</li>
							</ul>
						</div>
						{/* Cookie Banner implementation */}
						<ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={closeModel} />
						<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={onSuccessCloseModal} />
						<FLSuccessMoal open={fl_success_modal.open} message={fl_success_modal.message} stringID={fl_success_modal.message} onCloseModel={closeModel} />
						<AccessModal open={access_modal.open} message={access_modal.message} onAccessNowModel={accessNowModel} onAccessLaterModel={accessLaterModel} />
						<FLSuccessMoalBackToHome open={fl_home_success_modal.open} message={fl_home_success_modal.message} onFLSuccessModel={onFLSuccessCloseModal} />
					</div>
				</div>
				{/*<!-- SignIn Confirmation Modal --> */}
				<div className="modal fade" data-keyboard="false" data-backdrop="static" id="SignInSuccessModal" tabIndex="-1" role="dialog" aria-labelledby="SignInSuccessModalLabel">
					<div className="modal-dialog modal-md loginModal">
						<div className="modal-content">
							<div className="modal-body">
								<div className="singINConfirmation">								
									<FormattedMessage id='KC0914'/>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary signINconfirmationOKBtn" type="button"><FormattedMessage id='KC0777' /></button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<SupportButton subject="KitchenConnect Support Request - Log in" body="Dear User,%0D%0A%0D%0AIf your organization already has a KitchenConnect Account, please contact your account administrator to get access.%0D%0A%0D%0AIf you are facing issues during login, please send us your comments and attach any screenshots that could help our team to better understand your issue and we´ll come back to you.%0D%0A%0D%0A Sincerely,%0D%0AKitchenConnect Team." />
		</React.Fragment>

	)
};

export default Login;