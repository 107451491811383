import { SET_FILTER_DATA, RESET_FILTER_DATA } from './type';
export const COUNTRY_SELECT = 'COUNTRY_SELECT';
export const REGION_SELECT = 'REGION_SELECT';
export const LOCATION_SELECT = 'LOCATION_SELECT';
export const CUSTOMER_SELECT = 'CUSTOMER_SELECT';
export const BRAND_SELECT = 'BRAND_SELECT';
export const MODEL_TYPE_SELECT = 'MODEL_TYPE_SELECT';
export const MODEL_SELECT = 'MODEL_SELECT';
export const GET_DEFAULT_DATA = 'GET_DEFAULT_DATA';

export function getSelectedFilterList (filterKey) {
    return dispatch => {
        dispatch({
            filterKey: filterKey,
            type: GET_DEFAULT_DATA,
            payload: filterKey
        })
    }
}
export function updateSelectedFilterList (data, filterKey) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({
            filterKey: filterKey,
            type: SET_FILTER_DATA,
            payload: data
        });
        resolve(true);
    });
}

export function resetSelectedFilterList (filterKey) {
    return dispatch => {
        dispatch({
            filterKey: filterKey,
            type: RESET_FILTER_DATA,
            payload: null
        })
    }
}