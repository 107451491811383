import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import authService from '../../../service/authService';
import { SoftwareAvailableBrandImage } from '../../../utils/unitResponseBuilder';
import { getSoftwareAvailableUpdates } from '../../../actions/navigation/softwareManagement/softwareAvailableUpdatesActions'
import { getStoreInstalledItem } from '../../../actions/navigation/softwareManagement/storeInstalledItemAction'
import { TableHeader } from '../../controls/tableHeader';
import ErrorMoal from '../../modal/error-model';
import { BRAND_ID_VALUE } from '../../../utils/appConstants'
import _ from 'lodash';
import LoadingPopup from '../../controls/loadingPopup';
import NoRecordsFound from '../../controls/noRecordsFound';
import { MediaManagementAPI } from '../../../service/api';
import ConfirmModal from '../../modal/confirm-model';
import moment from '../../../utils/momentHelper.js';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../utils/kcStorage';

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

class SoftwareLibraryUpdate extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			userData: {},
			userCheckedData: [],
			userSelectAll: false,
			dragItem: {},
			previewfilename: "",
			previewfileformat: "",
			previewurl: "",
			isAdd: true,
			isDelete: false,
			isEdit: false,
			isAddToCompilation: false,
			selectFileTextLine1: "Select Screensaver or ",
			selectFileTextLine2: "Compilation from list",
			compilationSelectionClass: "initialActive",
			isIndeterminateSelect:false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			access_modal: {
                open: false,
                message: ""
            },
		};
		this.closeModel = this.closeModel.bind(this);
		this.cancelDeleteUser = this.cancelDeleteUser.bind(this);
	}
	async componentDidMount() {
		this.setState({loaderIconVisible: true})
		await this.props.getSoftwareAvailableUpdates();
		document.body.style.backgroundColor = "#F2F2F2";
		setTimeout(()=>{
			this.setState({loaderIconVisible: false})
		},3000)
	}

	cancelDeleteUser = () => {

	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data ? data.message : ""
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false,


			});

		}
	}

	deleteSelectedMedia = () => {
		const mediaPreparationList = [...this.state.userCheckedData];
		if (mediaPreparationList && mediaPreparationList.length > 0 && mediaPreparationList != null) {
			let postData = {
				mediaIds: mediaPreparationList.map(function (item) { return item.MEDIA_ID })
			}
			this.setState({
				loaderIconVisible: true
			})
			this.putData(postData, MediaManagementAPI.getMediaBrandCustomer, HTTP_METHOD.DELETE).then(response => {
				if (response && response.data && response.data.message) {
					if (response.data.httpCode === "HTTP_200") {
						this.setState({
							isDelete: false,
							isAdd: true,
							isAddToCompilation: false,
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: response.data.stringID || response.data.message
							},
							isIndeterminateSelect:false,
							userSelectAll:false,
						});
						mediaPreparationList && mediaPreparationList.length > 0 && mediaPreparationList.map(item => {
							item.checked = false;
						});
					}
				}
			}).catch(err => {
				this.showError(err);
			});
		}
		this.removeFileHandler('other');
		this.setState({
			selectFileTextLine1: "Select Screensaver or ",
			selectFileTextLine2: "Compilation from list",
			compilationSelectionClass: "initialActive"
		});
	}

	putData = (postData, url, method) => {
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_200") {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	closeModel() {
		this.setState({
			error_modal: {
				open: false
			}
		})
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	onDrop(e) {
		let userProfileAuthFeature = authService.getFeature("/mediaLibraryList");
		if (userProfileAuthFeature.is_editable === 1) {
			const { dragItem } = this.state;
			const { mediaDraggedItemInfo } = this.props;
			const draggedItem = { type: 'other', item: dragItem }
			if (!mediaDraggedItemInfo.other || mediaDraggedItemInfo.other.length === 0) {
				this.props.mediaDraggedItem(draggedItem);
			}

			this.setState({
				userData: {},
				userSelectAll: false,
				userCheckedData: [],
				isAdd: true,
				isDelete: false,
				isEdit: false,
				isAddToCompilation: false,
				selectFileTextLine1: "Select Screensaver or ",
				selectFileTextLine2: "Compilation from list",
				compilationSelectionClass: "initialActive"
			});
		}
		console.log('onDrop', e);
	}
	onDragOver(e) {
		console.log('onDragOver', e);
	}

	removeFileHandler(type = '') {
		if (type === 'preparations') {
			this.props.mediaDragDeleteItem({ type: 'preparations' });
		} else if (type === 'other') {
			this.props.mediaDragDeleteItem({ type: 'other' });
		}
	}

	handleChange = () => {
		var userSelectAll = !this.state.userSelectAll;
		const { softwareAvailableUpdatesInfo } = this.props;
		softwareAvailableUpdatesInfo && softwareAvailableUpdatesInfo.length > 0 && softwareAvailableUpdatesInfo.map(item => {
			item.checked = userSelectAll;
		});

		if (!this.state.userSelectAll) {
			this.setState({
				userSelectAll: userSelectAll,
				userCheckedData: softwareAvailableUpdatesInfo.Software_List,
				isAdd: false,
				isDelete: true,
				isEdit: false,
				isAddToCompilation: false,
				isIndeterminateSelect: false
			});
		} else {
			this.setState({
				userSelectAll: userSelectAll,
				userCheckedData: [],
				isAdd: true,
				isDelete: false,
				isAddToCompilation: false,
				isIndeterminateSelect: false,
			});
		}
	}

	getCheckBoxstate(ListCount,SelectedCount) {
		if (SelectedCount == ListCount) {
		return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (SelectedCount == 0 && ListCount != 0) {
		return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
		return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange(e, rowData, listCount) {
		this.setState({ dragItem: rowData });
		let userCheckedData = this.state.userCheckedData;
		const findIndex = userCheckedData.findIndex((item) => item.SOFTWARE_ID === rowData.SOFTWARE_ID)
		if (findIndex > -1) {
			userCheckedData.splice(findIndex, 1)
		} else {
			userCheckedData.push(rowData)
		}
		this.setState({
			userCheckedData: userCheckedData,
			...this.getCheckBoxstate(listCount,userCheckedData.length)
		});

		this.isSelectEditDelete(this.state.userCheckedData);
	}

	isSelectEditDelete = (CheckedData) => {
		if (CheckedData && CheckedData.length === 1) {
			this.setState({
				isAdd: false,
				isDelete: true,
				isEdit: true,
				isAddToCompilation: true,
				selectFileTextLine1: "Confirm selection",
				selectFileTextLine2: " ",
				compilationSelectionClass: "active"
			});
		}
		else if (CheckedData && CheckedData.length > 1) {
			this.setState({
				isAdd: false,
				isDelete: true,
				isEdit: true,
				isAddToCompilation: false,
				selectFileTextLine1: "Select Screensaver or ",
				selectFileTextLine2: "Compilation from list",
				compilationSelectionClass: "initialActive"
			});
		}
		else {
			this.setState({
				isAdd: true,
				isDelete: false,
				isEdit: false,
				isAddToCompilation: false,
				selectFileTextLine1: "Select Screensaver or ",
				selectFileTextLine2: "Compilation from list",
				compilationSelectionClass: "initialActive"
			});
		}
	}

	transferAgainListOrOrgView(callOperatorInstallPage) {
		var rolesList = [...this.state.userCheckedData];
		let item;
		if (rolesList.length > 0) {
			item = rolesList[0];
			KCStore.setItem('selectedItemsInfo', JSON.stringify(item));
			KCStore.setItem('softwareModelId',item.MODEL_IDS);
			KCStore.setItem('modelIdValue',item.MODEL_IDS);
			let brandId = `${BRAND_ID_VALUE[item.BRAND]}`;
			this.props.history.push({
				pathname: callOperatorInstallPage?"softwareTransferUnits":"/softwareAssigngroupTopology",
				FILE_NAME: item.FILE_NAME,
				VERSION: item.VERSION,
				UPLOADED_ON: item.UPLOADED_ON,
				brandId: brandId,
				MODEL_NAMES: item.MODEL_NAMES,
				storeInstalledItemInfo: item,
				transferType : callOperatorInstallPage?"smart-group-transfer":"",
			});
		}
	}

	onInstallButtonClickHandler() {
		WoopraEvents(`${Constants.SOFTWARE_TRANSFER}`);
		var rolesList = [...this.state.userCheckedData];
		let item;
		if (rolesList.length > 0) {
			item = rolesList[0];
			KCStore.setItem('selectedItemsInfo', JSON.stringify(item));
			let brandId = `${BRAND_ID_VALUE[item.BRAND]}`;
			let val="";
			// const checkLength = item && item.smart_groups_id ? item.smart_groups_id.split(',') : [] ;
			// if (checkLength.length >0){
			// 	val="smart-group-transfer";
			// 	this.setState({
			// 		access_modal: {
			// 			open: true,
			// 			message: "KC1569",
			// 		},
			// 	});
			// } else {
				KCStore.setItem('softwareModelId',item.MODEL_IDS);
				KCStore.setItem('modelIdValue',item.MODEL_IDS);
				KCStore.setItem('modelFamilyIds',item.MODEL_FAMILY_IDS);
				this.props.history.push({
					pathname: val?"softwareTransferUnits":"/softwareTransferSmartgroupsList",
					FILE_NAME: item.FILE_NAME,
					VERSION: item.VERSION,
					UPLOADED_ON: item.UPLOADED_ON,
					brandId: brandId,
					MODEL_NAMES: item.MODEL_NAMES,
					storeInstalledItemInfo: item,
					transferType : val,
				});
				// if (item.unitRange){
				// 	KCStore.setItem('unitRange', JSON.stringify(item.unitRange));
				// }
			// }
		}
	}

	onAssignSmartGroupsList() {
		WoopraEvents(`${Constants.SOFTWARE_SG_ASSIGN}`);
		var rolesList = [...this.state.userCheckedData];
		let item;
		if (rolesList.length > 0) {
			item = rolesList[0];
			KCStore.setItem('selectedItemsInfo', JSON.stringify(item));
			let brandId = `${BRAND_ID_VALUE[item.BRAND]}`
			this.props.history.push({
				pathname: "/softwareAssignSmartGroupsList",
				FILE_NAME: item.FILE_NAME,
				VERSION: item.VERSION,
				UPLOADED_ON: item.UPLOADED_ON,
				brandId: brandId,
				MODEL_NAMES: item.MODEL_NAMES,
				storeInstalledItemInfo: item,
				smart_groups_ids: item.smart_groups_id,
			});
		}
	}

	//For First Letter Added in Smart Group List
	getFirstLetter(name) {
		if(name){
			var char = name.charAt(0).toUpperCase();
			return char;
		}    
	}

	accessNowModel = (e) => {
        this.transferAgainListOrOrgView(false);
        this.setState({
            access_modal: {
                open: false
            }
        })
    }

    accessLaterModel = () => {
		this.transferAgainListOrOrgView(true);
        this.setState({
            access_modal: {
                open: false
            }
        })
    }

	// Woopra Events
    trackEvent = (e, title = '') => {
        WoopraEvents(`${Constants.SOFTWARE}-${(title || e.target.title)}`);
    }

	render() {
		const { formatMessage } = this.props.intl;
		let { softwareAvailableUpdatesInfo } = this.props;

		const { loaderIconVisible, sortColumn, error_modal, isAdd, isDelete, isEdit,access_modal, userCheckedData } = this.state;
		let userProfileAuthFeature = authService.getFeature("/softwareLibraryList");

		let columns = [
			{ path: "", label: "", isNbSp: true },			
			{ path: "FILE_NAME", labelStringID: "KC0838" },
			{ path: "VERSION", labelStringID: "KC0834" },
			{ path: "MODEL_FAMILY_NAMES", labelStringID: "KC0649" }, 
			{ path: "UPLOADED_ON", labelStringID: "KC0725" },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },			
		];
		// User Story 72565: [Quickwin]: Sorting on Date Fields in CREM Library
		if (sortColumn.path === "UPLOADED_ON") {
			if (sortColumn.order === "desc") {
				softwareAvailableUpdatesInfo.Software_List = softwareAvailableUpdatesInfo.Software_List.sort((d1, d2) => new Date(d2.UPLOADED_ON) - new Date(d1.UPLOADED_ON));
			}
			else {
				softwareAvailableUpdatesInfo.Software_List = softwareAvailableUpdatesInfo.Software_List.sort((d1, d2) => new Date(d1.UPLOADED_ON) - new Date(d2.UPLOADED_ON));
			}
		}
		else{
		softwareAvailableUpdatesInfo.Software_List = _.orderBy(softwareAvailableUpdatesInfo.Software_List, [sortColumn.path], [sortColumn.order]);
		}
		if (softwareAvailableUpdatesInfo.Software_List.length > 0) {
			softwareAvailableUpdatesInfo.Software_List = softwareAvailableUpdatesInfo.Software_List.filter(item => { if (item.BRAND === KCStore.getItem("brandName")) return item });
		} 
		let listCount = softwareAvailableUpdatesInfo.Software_List.length;

		let allowDownload = false;
		let selectedCheckboxCount = 0;
		softwareAvailableUpdatesInfo.Software_List.forEach(item => {
			if (item.checked) {
				selectedCheckboxCount = selectedCheckboxCount + 1;
			}
		});

		if (selectedCheckboxCount > 1) {
			allowDownload = false;
		} else if (selectedCheckboxCount === 1) {
			allowDownload = true;
		}
		const isAllow = userCheckedData && userCheckedData.length ? userCheckedData.length === 1 : false
		return (
			<React.Fragment>
				<div id="adminWrapper">
					<LoadingPopup loading={loaderIconVisible} />
					<div id="page-content-wrapper">
						<div className="softwareUpdateWrapper">
							<div className="softwareUpdateHeader">
								<ul>
									<li>
										<Link to="#" title={formatMessage({ id: 'KC0837' })} className="active" onClick={(e) => this.trackEvent(e, 'Software Library')}><FormattedMessage id="KC0837"/></Link>
										<Link to="/softwareStatus" title={formatMessage({ id: 'KC0523' })} onClick={(e) => this.trackEvent(e, 'Status')}><FormattedMessage id="KC0523"/></Link> 
									</li>
									<li>
										{/* <button onClick={() => this.onAssignSmartGroupsList()} title={formatMessage({ id: 'KC0722' })} className={userProfileAuthFeature.is_editable === 1 ? `btn sgStatus ${isAllow ? "" : "disabled"}` : "btn sgStatus disabled"} ><span className="btnIcon downloadLogbook" /></button> */}
										<button onClick={() => this.onInstallButtonClickHandler()} title={formatMessage({ id: 'KC0839' })} className={userProfileAuthFeature.is_editable === 1 ? `btn transfertoSG ${isAllow ? "" : "disabled"}` : "btn assignMedia disabled"} ></button>
										<button type="button" title={formatMessage({ id: 'KC1723' })} className={userProfileAuthFeature.is_editable === 1 ? `btn edit ${isEdit ? "disabled" : "disabled"}` : "btn edit disabled"}><span className="btnIcon downloadLogbook"></span></button>
										<button type="button" className={userProfileAuthFeature.is_editable === 1 ? `btn delete ${isDelete ? "disabled" : "disabled"}` : "btn delete disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal" title={formatMessage({ id: 'KC0721' })} onClick={() => { }}>&nbsp;</button>
										<button id="btnadd" type="button" className={userProfileAuthFeature.is_editable === 1 ? `btn add ${isAdd ? "disabled" : "disabled"}` : "btn add disabled"} title={formatMessage({ id: 'KC0720' })}>&nbsp;</button>										
									</li>
								</ul>
							</div>
							<div className="availableUpdatesTable">
								<TableHeader
									userMgmtListScreen={true}
									sortColumn={sortColumn}
									onSort={this.onSort}
									selectAllChange={this.handleChange}
									columns={columns}
									isIndeterminate={this.state.isIndeterminateSelect}
									isSelectAll={this.state.userSelectAll}
								/>
							</div>
							<div className="availableUpdatesTableOuter">
								<div className="availableUpdatesTable">
									<div className="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="true">
										{/* <!-- Header 01 --> */}
										{SoftwareAvailableBrandImage(softwareAvailableUpdatesInfo.Software_List && softwareAvailableUpdatesInfo.Software_List).map((item, i) =>
											<React.Fragment>
												<div className="availableUpdatesTable">
													<ul className="tBody">
														<li><button role="button" data-toggle="collapse" data-parent="#availableUpdatesAccordion"
															href={"#availableUpdatesAcc" + item.ID} aria-expanded="false" aria-controls={"availableUpdatesAcc" + item.ID} className="btn collapseExpand collapsed">&nbsp;</button></li>
														<li>{item.FILE_NAME}</li>
														<li>{item.VERSION}</li>
														<li>{item.MODEL_FAMILY_NAMES}</li> 
														<li>{item.UPLOADED_ON ? moment.GetDateTimeByFormat(item.UPLOADED_ON, 'LLL') : ''}</li>
														<li>
															<div className={userProfileAuthFeature.is_editable == 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
																<input id={i + 1} name="mediaPreparationList" type="checkbox" value={item.checked} defaultChecked={item.checked}
																	checked={item.checked}
																	onChange={(e) => this.handleSingleCheckboxChange(e, item, listCount)} />
																<label for={i + 1}></label>
															</div>
														</li>
													</ul>
												</div>
												{/* <!-- Header 01 Content --> */}
												<div id={"availableUpdatesAcc" + item.ID} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"availableUpdatesAccH" + item.ID}>
													<div className="panel-body availableUpdatesTable">
														<ul className="tBody">
															<li>&nbsp;</li>
															<li><b><FormattedMessage id="KC0833"/>:</b>{item.MODEL_NAMES} </li>
															<li>{item.DESCRIPTION}</li>
															<li></li> 
															<li></li>
														</ul>
													</div>
												</div> </React.Fragment>)}
									</div>
								</div>
							</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={softwareAvailableUpdatesInfo.Software_List !== undefined ? softwareAvailableUpdatesInfo.Software_List.length : 0} classname={'norecordsfoundbluetext'} />
							<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
						</div>
					</div>
					<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
						<div className="modal-dialog modal-md loginModal">
							<div className="modal-content" >

								<div className="modal-body">
									<div className="singOutConfirmation">
										<FormattedMessage id="KC0753"/>
                            </div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary signOutNoBtn" onClick={() => this.cancelDeleteUser()} data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
									<button className="btn btn-secondary signOutYesBtn" onClick={() => this.deleteSelectedMedia()} data-dismiss="modal" type="button"><FormattedMessage id="KC0558"/></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<UserManagementHeader headerName="Software Management" headerNameStringID="KC0633" activeClass="softwareNav" />
				<ConfirmModal open={access_modal.open} stringID={access_modal.message} onAccessNowModel={(e) => this.accessNowModel(e)} onAccessLaterModel={(e) => this.accessLaterModel(e)} />
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		softwareAvailableUpdatesInfo: state.softwareAvailableUpdatesInformation.softwareAvailableUpdatesInformation,
		storeInstalledItemInfo: state.storeInstalledItemInformation.storeInstalledItemInformation,

	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getSoftwareAvailableUpdates, getStoreInstalledItem,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SoftwareLibraryUpdate));