import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import $ from 'jquery';
import http from '../../service/httpService';
import TandCView from './tandcView';
import TandCList from './tandcList';
import auth from '../../utils/authHelper';
import { loginAPIUtils, loginResponseAction } from '../login/loginUtils';
import { BaseService, } from '../../service/api';
import { setLocale } from '../../actions/localeAction/localeAction';
import ErrorMoal from '../modal/error-model';
import { FormattedMessage } from 'react-intl';
import { registerDevice } from './tandcUtils';
import LanguagePreferenceCommon from '../languageDropdown/LanguagePreferenceCommon';
import { useSelector } from 'react-redux';
import KCStore from '../../utils/kcStorage';

const TermsAndConditions = (props) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    let logininfo = JSON.parse(KCStore.getItem("loginInfoDet"));
    const inputData = props?.history?.location?.inputData ?  props.history.location.inputData : logininfo;
    const validatePermissions = props.history.location.inputData?.validatePermissions;
    const [tacList, setTacList] = useState([]);
    const [loaderIconVisible, setLoaderIconVisible] = useState(false);
    const [error_modal, setError_modal] = useState({
        open: false,
        message: ""
    });
    const dispatch = useDispatch();
    const langDetails = useSelector(state => state.localeInfo.locale);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

    useEffect(()  =>  { 
        let val = inputData?.tandcDetails ? inputData.tandcDetails?.body?.policy : [];
        if (val?.length) {
            if (val.length>1){
                val[1].selected = true;
            } else {
                val[0].selected = true;
            }
            setTacList(val);
        } else {
            // cancelBtnClick();           
        }        
    }, []);

    useEffect(() => {      
        $("body").addClass("loginBg");        
        fetchData();        
        async function fetchData() {
            let payLoadData = JSON.parse(KCStore.getItem("TandCDetails"));
            payLoadData.langCode = langDetails; //en-US
            const resVal = await proceedTaCValidation(payLoadData);            
            let val = resVal?.body?.policy? resVal.body.policy : [];
            if (val.length>0){
                if (val.length>1){
                    val[1].selected = true;
                } else {
                    val[0].selected = true;
                }
                setTacList(val);            
            }            
        }               
    }, [langDetails]);

    const proceedTaCValidation = async (postdata) => {
		return new Promise(async (res, reject) => {
            setLoaderIconVisible(true);
			let url = BaseService.getTandCdetails;            
			await http.post(url, postdata,
				{
					headers: {
						'Content-Type': 'application/json',
					}
				}
			).then(response => {
                setLoaderIconVisible(false);
				if (response && response.data) {
					res(response.data)
				}
			}).catch(err => {
                setLoaderIconVisible(false);
				reject(err);
			});
		})
	}

    const settingLocale = (data) => {
        dispatch(setLocale(data));
    };

    const closeModel = () => {
        setError_modal({
            open: false,
        })
    };

    const allowUserToLogin = async () => {
        setLoaderIconVisible(true);
        if (inputData.from === "selfSingOn") {
            await loginResponseAction(inputData.username, inputData.res, props.history.push, settingLocale, inputData);
            await registerDevice(inputData, validatePermissions, props);
            if (!validatePermissions.org) {
                setLoaderIconVisible(false);
                $('#confirmationRegisteredModal').modal('show');
            }
        } else if (inputData.from === "SelectPlanPage") {
                await KCStore.setItem('allTandCAccepted', true);
                props.history.push('/select-plan');            
        } else {
            loginAPIUtils(inputData, props.history.push, settingLocale);
        };
    }

    const acceptBtnClick = async (selectedItem) => {
        const acceptPolicy = await acceptTandC(selectedItem);
        if (acceptPolicy?.body?.responseCode !== 200) {
            return;
        } else {
            setTacList((prev) => {
                const newData = prev.map((item => {
                    if (item.id === selectedItem.id) {
                        return { ...item, accepted: true };
                    }
                    return item;
                }))
                let acceptedPolicy = newData.filter(c => c.accepted === true).length + newData.filter(c => c?.isReadOnly).length;
                if (newData.length === acceptedPolicy) {
                    KCStore.removeItem('loginInfoDet');
                    allowUserToLogin();
                }
                if (tacList.length > 1) {
                    getPrevAndNext(selectedItem.id);// to auto select T&C from the left side list
                }                
                return newData;
            })
        }
    };

    const getPrevAndNext = (activeID) => {
        const index = tacList.findIndex((a) => a.id === activeID)
        if (index === -1) {
            return undefined;
        }
        const prev = tacList[index - 1];
        const next = tacList[index + 1];
        const selection = prev ? prev : next;
        
        setTacList((prev) => {
            const newData = prev.map((item => {
                if (item.id === selection.id) {
                    return { ...item, selected: true };
                }
                return { ...item, selected: false };
            }))
            return newData;
        })
        $('#scrollBbuttonE').scrollTop(0);
    }

    const cancelBtnClick = () => {
        auth.deleteLocalSession();
        props.history.push("/");
    };

    const itemClickedInList = (list) => {
        setTacList((prev) => {
            const newData = prev.map((item => {
                if (item.id === list.id) {
                    return { ...item, selected: true };
                }
                return { ...item, selected: false };
            }))
            return newData;
        })
        $('#scrollBbuttonE').scrollTop(0);
    };

    const acceptTandC = async (selectedItem) => {
        setLoaderIconVisible(true);
        const url = BaseService.acceptTandCdetails;
        const postdata = {
            'userId': inputData.username,
            "roleId": inputData.loginData.role,
            "customerId": inputData.loginData.customerId,
            "groupId": selectedItem.groupId,
            "policyId": selectedItem.policyId
        };
        const headerVal = {
            'Content-Type': 'application/json',
        };

        try {
            const response = await http.post(url, postdata, { headers: headerVal });
            if (response?.data) {
                setLoaderIconVisible(false);
                if (response.data?.body?.responseCode !== 200) {
                    setError_modal({
                        open: true,
                        message: response.data?.body?.message ? response.data.body.message : "Something went wrong."
                    })
                }
                return response.data;
            }
            return {};
        } catch (err) {
            setLoaderIconVisible(false);
            return err;
        }
    };

    return (
        <div id="adminWrapper">
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                <Link className="navbar-brand welbiltLogo" title={formatMessage({ id: 'KC0038' })} to="#">&nbsp;</Link>
                <div id="login-pre">
                    <LanguagePreferenceCommon />
                </div>
            </nav>

            <div id="page-wrapper">
                <div className="TCWrapper" style={{ height: `${vh - 95}px` }}>
                    {tacList?.length > 1 ? <TandCList itemClickedInList={itemClickedInList} tacList={tacList?.length > 0 ? tacList : []} /> : ""}
                    <TandCView acceptBtnClick={acceptBtnClick} cancelBtnClick={cancelBtnClick} tacList={tacList?.length > 0 ? tacList : []} />
                </div>
            </div>
            <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={closeModel} />
            {/* <!-- Registered Modal --> */}
            <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationRegisteredModal" tabindex="-1" role="dialog" aria-labelledby="confirmationRegisteredModalLabel">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="confirmationContentDiv">
                                <h5><FormattedMessage id='KC0655' /></h5>
                                <p>The Unit has been registered and a notification has been sent to {inputData && inputData.userName ? inputData.userName : ''} KitchenConnect Admin.</p>
                                <h6>Thanks for your trust in KitchenConnect. </h6>
                            </div>
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn btn-secondary finishBtn" data-dismiss="modal" onClick={() => { $("div").removeClass("modal-backdrop fade show"); props.history.replace('/register-unit') }} type="button"><FormattedMessage id='KC0056' /></button>
                            <button className="btn btn-secondary gotoDashboardBtn" onClick={() => { $("div").removeClass("modal-backdrop fade show"); props.history.push('/dashboardLocations') }} type="button">Go to Dashboard</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TermsAndConditions;
