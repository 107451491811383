import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import ErrorMoal from '../../../../modal/error-model';
import Switch from 'react-toggle-switch';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';

require('jquery-mousewheel')

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


class FrymasterMenuList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            categoryEnabled: true
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onAddMenuButtonClick = this.onAddMenuButtonClick.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onDeleteCategory = this.onDeleteCategory.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.onCategorySelect = this.onCategorySelect.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let categoryDetails = KCStore.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);

            this.setState({
                categoryList: categoryDetailsObj,
                isChanged: categoryDetailsObj.length > 0 ? true : false
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.categoryList,
            result.source.index,
            result.destination.index
        );

        this.setState({
            categoryList: items,
        });
        KCStore.setItem("resourceDetails",JSON.stringify(items));
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    onCategorySelect = (e, item) => {
        KCStore.setItem("selectedCategory", JSON.stringify(item));
        this.props.history.push({
            pathname: "/frymasterMenuAddRecipes",
            isEdit: false,
            previouspath: "/frymasterMenuList"
        });
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            isDeleteCategory: false,
            errorCookbookName: ""
        })
    }

    onDeleteCategory = (e, item) => {
        this.setState({
            selectedCategory: item,
            loaderIconVisible: false,
            isDeleteCategory: true
        });
    }

    onDeleteConfirm = () => {
        this.setState({
            loaderIconVisible: true
        })
        let categoryDetails = KCStore.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let changedCategoryDetailsObj = categoryDetailsObj.filter(c => c.ID !== this.state.selectedCategory.ID);
        KCStore.setItem("resourceDetails", JSON.stringify(changedCategoryDetailsObj));
        this.setState({
            loaderIconVisible: false,
            categoryList: changedCategoryDetailsObj,
            isChanged: changedCategoryDetailsObj.length > 0 ? true : false,
            isDeleteCategory: false
        });
    }

    onAddMenuButtonClick = () => {
        KCStore.setItem("selectedCategory", "{}");
        this.props.history.push({
            pathname: "/frymasterMenuAdd",
            isEdit: false,
            previouspath: "/frymasterMenuList"
        });
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/FrymasterMenuEditor",
            isEdit: false,
            previouspath: "/frymasterMenuList"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() { 
        const { formatMessage } = this.props.intl;
        let { loaderIconVisible, categoryList, isDeleteCategory, categoryEnabled, error_modal } = this.state;
        categoryList = _.orderBy(categoryList);
        let brandid = KCStore.getItem("selectedbrandID");
        
        return (
            <React.Fragment>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button> <FormattedMessage id="KC1394"/>
                                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <DragDropContext onDragEnd={this.onDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                className="frymasterRCForm"
                                                            >
                                                                    {categoryList.map((item, index) => (
                                                                        <Draggable key={item.ID} draggableId={item.categoryName} index={index}>
                                                                            {(provided) => (
                                                                                <ul key={item.ID} ref={provided.innerRef} {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps} class={categoryList.length === index + 1 ? "borderNone" : ""}>
                                                                                    <li onClick={(e) => this.onCategorySelect(e, item)}>{item.categoryName}</li>
                                                                                    <li class="smallFormFields">
                                                                                        <div class="btn-group-toggle floatLeft" data-toggle="buttons"> <FormattedMessage id="KC1405"/> &nbsp;
                                                                                            <Switch onClick={"location.href='#'"}
                                                                                                on={categoryEnabled === "true" ? true : false} className={categoryEnabled === "true" ? "btn btn-secondary active" : "btn btn-secondary"} />
                                                                                                            &nbsp; <FormattedMessage id="KC1360"/> &nbsp;
                                                                                        </div>
                                                                                        <button type="button" class="btn deleteButton" onClick={(e) => this.onDeleteCategory(e, item)}>&nbsp;</button>
                                                                                    </li>
                                                                                </ul>

                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" class="btn addButton" onClick={() => { this.onAddMenuButtonClick() }} >&nbsp;</button> &nbsp;
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                {/* <!-- Delete Confirmation Modal --> */}
                <div className={isDeleteCategory ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isDeleteCategory ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1245"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.onDeleteConfirm()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Delete Confirmation Modal --> */}
                <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterMenuList)
