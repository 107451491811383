import React, { Component } from 'react';
import UserManagementHeader from '../../../layoutSection/userManagementHeader';
import {BRAND_ID} from '../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import http from '../../../../service/httpService';
import { unitAPI } from '../../../../service/api';
import { ModelFamilyBuilder } from '../../../../utils/unitResponseBuilder';
import { orderBy } from 'lodash'
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../utils/kcStorage';

class UploadLegacySelectDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            sortColumn: { path: "MODEL_FAMILY_NAME", order: "asc", sort_color: "#fff" },
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
        }
        this.getModelFamilies = this.getModelFamilies.bind(this);
        this.onModelSelection = this.onModelSelection.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#FFFFFF";
        $("body").removeClass("loginBg");
        try {
            await this.getModelFamilies().catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
        } catch (error) {
            //Error Goes Here
        }
        let { library_name, name, totalfilesize, URL, crc, previouspath } = this.props.location;
        this.setState({
            library_name: library_name,
            name: name,
            totalfilesize: totalfilesize,
            URL: URL,
            crc: crc,
            previouspath: previouspath
        });
    }

    getModelFamilies = () => {
        this.setState({
            loaderIconVisible: true
        });

        return new Promise((resolve, reject) => {
            http.get(unitAPI.getModelFamilies,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'brandid': BRAND_ID
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    let modelList = data && data.body ? data.body : [];
                    this.setState({
                        loaderIconVisible: false,
                        data: modelList
                    });
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    onModelSelection = (item) => {
        KCStore.setItem("selectedFamilyID", item.MODEL_FAMILY_ID.toString());
        KCStore.setItem("selectedbrandID", item.BRAND_ID);
        KCStore.setItem("brandName", item.BRAND_ID === 1 ? "MERRYCHEF" : item.BRAND_ID === 3 ? "FRYMASTER" : "CONVOTHERM");
        this.props.history.push({
            pathname: "/uploadLegacyCookbookToTopology",
            library_name: this.state.library_name,
            name: this.state.name,
            totalfilesize: this.state.totalfilesize,
            URL: this.state.URL,
            crc: this.state.crc,
            previouspath: this.state.previouspath
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, sortColumn } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];

        let merrychefDataList = [];
        let frymasterDataList = [];
        let convothermDataList = []
        if (dataList) {
            dataList = ModelFamilyBuilder(dataList);
            merrychefDataList = dataList.filter(item => item.BRAND_ID === 1);
            frymasterDataList = dataList.filter(item => item.BRAND_ID === 3);
            convothermDataList = dataList.filter(item => item.BRAND_ID === 6 && item.MODEL_FAMILY_NAME !== 'mini');
            merrychefDataList = orderBy(merrychefDataList, [merrychefDataList => merrychefDataList[sortColumn.path] && merrychefDataList[sortColumn.path].toString().toLowerCase()], [sortColumn.order]);
            convothermDataList = orderBy(convothermDataList, [convothermDataList => convothermDataList[sortColumn.path] && convothermDataList[sortColumn.path].toString().toLowerCase()], [sortColumn.order]);
        }

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="adminBgHeader">
                            <ul>
                                <li>
                                    <Link to="/legacyLibraryList" title={formatMessage({ id: 'KC0989' })} className="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id="KC1205"/>
					        </li>
                                <li>
                                    {/* <Link to="/recipeEditor" title="Finish">Finish</Link> */}
                                    {/* <a href="/recipeEditor" title="Finish">Finish</a> */}
                                </li>
                            </ul>
                        </div>
                        <div className="menuSelectDeviceTableOuter">
                            <div className="panel panel-default" id="menuSelectDeviceAccordion" role="tablist" aria-multiselectable="true">
                                {/* <!-- Header 01 --> */}
                                {KCStore.getItem("selectedbrandID") === "3" && <div className="menuSelectDeviceTable">
                                    <ul className="tBody">
                                        <li><a role="button" data-toggle="collapse" data-parent="#menuSelectDeviceAccordion" href="#menuSelectDvcAcc1" aria-expanded="true" aria-controls="menuSelectDvcAcc1" className="btn collapseExpand">&nbsp;</a></li>
                                        <li><img src={require("../../../../images/logos/FRY_blue.svg").default} alt="" /></li>
                                    </ul>
                                </div>}
                                {/* <!-- Header 01 Content --> */}
                               {KCStore.getItem("selectedbrandID") === "3" && <div id="menuSelectDvcAcc1" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="menuSelectDvcAccH1">
                                    <div className="panel-body selectDeviceGrid">
                                        <ul>
                                            {frymasterDataList.map((item) => {
                                                return (
                                                    <li onClick={() => this.onModelSelection(item)}>
                                                        <div class="selectDeviceGridW">
                                                            <span><img alt={item.Brand_Alt} src={item.Brand_Logo} alt=""/></span>
                                                            <h5>{item.MODEL_FAMILY_NAME}</h5>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </div>
                                </div>} 
                                {/* <!-- Header 02 --> */}
                                {KCStore.getItem("selectedbrandID") === "1" && <div className="menuSelectDeviceTable">
                                    <ul className="tBody">
                                        <li><a role="button" data-toggle="collapse" data-parent="#menuSelectDeviceAccordion" href="#menuSelectDvcAcc2" aria-expanded="true" aria-controls="menuSelectDvcAcc2" className="btn collapseExpand">&nbsp;</a></li>
                                        <li><img src={require("../../../../images/logos/MCF_blue.svg").default} alt="" /></li>
                                    </ul>
                                </div>}
                                {/* <!-- Header 02 Content --> */}
                                {KCStore.getItem("selectedbrandID") === "1" && <div id="menuSelectDvcAcc2" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="menuSelectDvcAccH2">
                                    <div className="panel-body selectDeviceGrid">
                                        <ul>
                                            {merrychefDataList.map((item) => {
                                                return (
                                                    <li onClick={() => this.onModelSelection(item)}>
                                                        <div class="selectDeviceGridW">
                                                            <span><img alt={item.Brand_Alt} src={item.Brand_Logo} alt=""  /></span>
                                                            <h5>{item.MODEL_FAMILY_NAME}</h5>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </div>
                                </div>}
                                 {/* <!-- Header 02 --> */}
                                 {KCStore.getItem("selectedbrandID") === "6" && <div className="menuSelectDeviceTable">
                                    <ul className="tBody">
                                        <li><a role="button" data-toggle="collapse" data-parent="#menuSelectDeviceAccordion" href="#menuSelectDvcAcc2" aria-expanded="true" aria-controls="menuSelectDvcAcc2" className="btn collapseExpand">&nbsp;</a></li>
                                        <li><img src={require("../../../../images/logos/CON_blue.svg").default} alt="" /></li>
                                    </ul>
                                </div>}
                                {/* <!-- Header 02 Content --> */}
                                {KCStore.getItem("selectedbrandID") === "6" && <div id="menuSelectDvcAcc2" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="menuSelectDvcAccH2">
                                    <div className="panel-body selectDeviceGrid">
                                        <ul>
                                            {convothermDataList.map((item) => {
                                                return (
                                                    <li onClick={() => this.onModelSelection(item)}>
                                                        <div class="selectDeviceGridW">
                                                            <span><img alt={item.Brand_Alt} src={item.Brand_Logo} alt=""  /></span>
                                                            <h5>{item.MODEL_FAMILY_NAME}</h5>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(UploadLegacySelectDevice)
