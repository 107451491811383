export default {
    FleetDashBoard : "Fleet Dashboard",
    UnitDashBoard : "Unit Dashboard",
    Notifications : "Notifications",
    UserProfile: "User Profile",
    UserManagement : "User  Management",
    UnitRegistration : "Unit Registration",
    EventCenter : "Automated Notification Management",
    HowToPage : "KitchenConnect - How To",
    SiteManagement : "Organization Management",
    Subscription: "Subscription Management",
    Menus: "Menu & Unit Settings",
    HaccpManagement: "HACCP Management",
    ReportManagement: "Report Management",
    C2CAPIDeveloperPortal: "KC API Portal",
    MediaManagement :"Media Management",
    SoftwareManagement:"Software  Management",
    Report: "Reports & Analytics",
    Service: "Service",
    Quality: "Quality Management",
    MediaLibrary: "Media",
    Asset: "Asset",
    EmailErrorReport: "Email Error Report",
    DeviceLogDownloads : "Device Log Downloads",
    ErrorCenter : "Error Center",
    prodSelSetting : "Exclude Products",
    readyRecipe: "Ready Recipe",
    smartTag : "Smart Tag Management",
    liveMonitoring : "Live Monitoring",
    subscriptionManagement: "Subscription Management"
}