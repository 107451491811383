import {MACHINE_TYPE_REQUEST,MACHINE_TYPE_SUCCESS,MACHINE_TYPE_FAIL} from '../../../../actions/listView/locations/machineTypeAction';

const initialState = {
    loaded: false,
    loading: false,
    machineTypeInformation: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case MACHINE_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
                loaded: false,
                isServerActionComplete : action.isCascadeActionRequest
               
            }
        case MACHINE_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                machineTypeInformation: action.machineTypeInformation,
                isServerActionComplete : true
            }
        case MACHINE_TYPE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                errormsg: 'Server Error',
                isServerActionComplete : true
            }
        default:
            return state
    }
}