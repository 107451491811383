import {LOCATION_GRID_REQUEST,LOCATION_GRID_SUCCESS,LOCATION_GRID_FAIL} from '../../../actions/allLocations/locations/dashboardLocationsGridAction';

const initialState = {
    loaded: false,
    loading: false,
    dashboardLocationValue: {},
    errormsg:'',
}

export default function reducer (state = initialState, action = {}) {

    switch (action.type) {
        case LOCATION_GRID_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                errormsg: '',
                isLocationsListResponsecomplete: false
            }
        case LOCATION_GRID_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                dashboardLocationValue : action.dashboardLocationValue,
                errormsg: '',
                isLocationsListResponsecomplete:true
            }
        case LOCATION_GRID_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
                isLocationsListResponsecomplete:true
            }
        default:
            return state
    }
}