import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';

class FrymasterCookingLane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            cookingLane: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                cookingLane: ObjSettingsFile.cooking.lane_configuration.cooking_lane.lane,
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true,
        });
        let { cookingLane } = this.state;
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.cooking.lane_configuration) {
            ObjSettingsFile.cooking.lane_configuration = {};
        }
        if (!ObjSettingsFile.cooking.lane_configuration.cooking_lane) {
            ObjSettingsFile.cooking.lane_configuration.cooking_lane = {};
        }
        ObjSettingsFile.cooking.lane_configuration.cooking_lane.lane = Number(cookingLane);
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterCooking`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterCooking`);
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = KCStore.getItem("selectedbrandID");

        const { cookingLane } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Cooking Lane Header Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Cooking Lane Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Cooking Lane Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1457"/>
                                </div>

                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul class="borderNone">
                                                        <li class="leftAlign">
                                                            <div class="laneConfigList">
                                                                {[{ label: "lane1", value: 0 }, { label: "lane2", value: 1 }, { label: "lane3", value: 2 }, { label: "lane4", value: 3 }, { label: "lane5", value: 4 }, { label: "lane6", value: 5 }].map((lane, index) => {
                                                                    return (
                                                                        <button onClick={() => this.setState({ cookingLane: lane.value })} key={index} type="button" class={`${cookingLane === lane.value ? `${lane.label} active` : lane.label} btn  `}>&nbsp;</button>
                                                                    )
                                                                })}

                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>

                                </div>
                            </div>
                            {/* Frymaster Cooking Lane Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterCookingLane)
