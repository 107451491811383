export const STORE_INSTALLED_ITEM_REQUEST = 'STORE_INSTALLED_ITEM_REQUEST';
export const STORE_INSTALLED_ITEM_SUCCESS = 'STORE_INSTALLED_ITEM_SUCCESS';
export const STORE_INSTALLED_ITEM_FAIL = 'STORE_INSTALLED_ITEM_FAIL';

export function getStoreInstalledItem(item) {
    return dispatch => {
        dispatch({
            type: STORE_INSTALLED_ITEM_SUCCESS,
            storeInstalledItemInformation: item,
         })
    }
}


