import {COUNTRY_SELECT,REGION_SELECT,LOCATION_SELECT, MODEL_SELECT, BRAND_SELECT, CUSTOMER_SELECT} from '../../../../actions/listView/locations/filterbyAction';

const initialState = {
    selectedCountries: [],
    selectedRegions: [],
    selectedLocations:[],
    selectedCustomers:[],
    selectedBrands:[],
    selectedModels:[]
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case COUNTRY_SELECT:
        
            return {
                ...state,
                selectedCountries: action.information
            }
        case REGION_SELECT:
            return {
                ...state,
                selectedRegions: action.information
            }
        case LOCATION_SELECT:
            return {
                ...state,
                selectedLocations: action.information
            }

            case CUSTOMER_SELECT:
            return {
                ...state,
                selectedCustomers: action.information
            }

            case BRAND_SELECT:
            return {
                ...state,
                selectedBrands: action.information
            }

            case MODEL_SELECT:
            return {
                ...state,
                selectedModels: action.information
            }
        default:
            return state
    }
}