import $ from 'jquery';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { smartTagMangement } from '../../../service/api';
import http from '../../../service/httpService';
import { TableHeader } from '../../controls/tableHeader';
import NoRecordsFound from '../../controls/noRecordsFound';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchVisible, searchText,clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import { searchData_function } from '../../globalSearchBar/selectOption';
import { BRAND_ID } from '../../../utils/appConstants';
import TagOrgModal from './tagOrgModel';
import ConfirmModal from '../../modal/confirm-model';
import SuccessMoal from '../../modal/success-model';
import moment from '../../../utils/momentHelper.js';
import authService from '../../../service/authService';
import { getSmartTagDetails } from '../../../actions/smartTag/getSmartTagList';
import KCStore from '../../../utils/kcStorage';

const DisplayProduct = (props) => {
    let typeVal = JSON.parse(KCStore.getItem("smartTagInfo"))
    const { formatMessage } = props.intl;    
    const [loaderIconVisible, setLoaderIconVisible] = useState(false);    
    const [type, setType] = useState(typeVal && typeVal.type ? typeVal.type : "orgList");
    const [orgSTagList, setOrgSTagList] = useState([]);
    const [getCurrentList, setCurrentList] = useState([]);
    const [allSelect, setAllSelect] = useState(false);
    const [isIndeterminateSelect, setIsIndeterminate] = useState(true);
    const [sortColumn, setSortColumn] = useState({ path: "", order: "", sort_color: "#63778a" });
    const [selectedRow, setSelectedRow] = useState([]);
    const [showpopup, setShowPopup] = useState([]);
    const [isEdit, setEdit] = useState(false);
    const [isDelete, setDelete] = useState(false);
    const searchData = useSelector(state => state.advanceSearch.searchData);
    const [access_modal, setAccess_modal] = useState({ open: false, message: "" });
    const [success_modal, setSuccess_modal] = useState({ open: false, message: "" });
    const [registrationFlag, setRegistrationFlag] = useState('no');
    const mounted = useRef();

    useEffect(() => {
        $("div").removeClass("modal-backdrop fade show");
        let smartTagInfo = {
            type: type,
            mode: 'add',
        }
        KCStore.setItem('smartTagInfo', JSON.stringify(smartTagInfo));        
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
        if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {            
            setRegistrationFlag('yes');
        }
        if (!mounted.current) {
            document.body.style.backgroundColor = "#F2F2F2";
            window.addEventListener('resize', updateDimensions);
            updateDimensions();
            props.searchVisible(true);        
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
            props.searchVisible(true);
        }
        let _search = searchData_function(searchData, true);
        props.getSmartTagDetails(type === 'orgList'?'ORG':'UNIT',_search,registrationFlag,BRAND_ID)
        
    }, [type, searchData]);

    const updateDimensions = () => {
        const windowHeight = $(window).height();
        const $tableBody = $('.samrtTagTableSrlBar');
        $tableBody.css('height', windowHeight -  ($tableBody.offset() && $tableBody.offset().top ? ($tableBody.offset().top) : 0));
    }

    useEffect(() => {
        return () => {
            props.searchVisible(false);
            props.searchText({});
        }
    }, []);

    useEffect(() => {
        setOrgSTagList(props.orgSTagList);
        if(props.searchData === undefined || props.searchData.searchtext === undefined) {
            setCurrentList(props.orgSTagList);
        }
    },[props.orgSTagList]);

    const onTabClick = (type) => {
        //setLoaderIconVisible(props.isLoading);
        props.searchText({});
        const objKey = searchData ? Object.keys(searchData) : [];
        if (objKey && objKey.length) {
            for (let index = 0; index < Object.keys(searchData).length; index++) {
                const element = Object.keys(searchData)[index];
                if (element === "searchtext") {
                    $('.cross-button').trigger("click");
                }
                $('.btn-close').trigger("click");
            }
        }
        $('.cross-button').trigger("click");
        if($('.input-group-prepend').find('a.active').length !== 0){
            $('.input-group-prepend').trigger('click');
        }
        setSortColumn({ path: "", order: "", sort_color: "#63778a" });
        setOrgSTagList([]);
        setType(type);
        setEdit(false);
        setDelete(false);        
    }

    const handleChange = (e) => {
        var result = [...orgSTagList];
        result.forEach(r => {
            r.checked = r.editable ? e.target.checked : false;
        });
        let checked = result.filter(c => c.editable === true);
        if (checked.length === result.length) {
            setIsIndeterminate(false);
        }
        setOrgSTagList(result);
        setAllSelect(e.target.checked);
        setEdit(false);
        setDelete(e.target.checked);
    };

    const onSort = sortColumn => {
        setSortColumn(sortColumn);
    }

    const getCheckBoxstate = (ListCount, SelectedCount) => {
        if (SelectedCount == ListCount) {
            setAllSelect(true);
            setIsIndeterminate(false);
        } else if (SelectedCount == 0 && ListCount != 0) {
            setAllSelect(false);
            setIsIndeterminate(false);
        } else if (ListCount >= SelectedCount) {
            setAllSelect(true);
            setIsIndeterminate(true);
        }
    }

    const handleSingleCheckboxChange = async (e, item) => {
        if (orgSTagList && orgSTagList.length > 0) {
            let result = [...orgSTagList];
            const index = result.findIndex(i => i.id === item.id);
            result[index] = { ...result[index] };
            result[index].checked = e.target.checked;
            let checked = result.filter(c => c.checked === true);

            setOrgSTagList(result);
            getCheckBoxstate(result.length, checked.length)
            setEdit(result.filter((i) => i.checked === true).length === 1)
            setDelete(result.filter((i) => i.checked === true).length >= 1)
        }
    }

    const showModelPopup = (item) => {
        let resultArray = [];
        resultArray.push(item);
        let smartTagObj = JSON.parse(KCStore.getItem("smartTagInfo"));
        smartTagObj['mode'] = 'isView';
        smartTagObj['selectedRow'] = item;
        KCStore.setItem('smartTagInfo', JSON.stringify(smartTagObj))
        setSelectedRow(resultArray);
        setShowPopup(resultArray);
    }

    const onEditClick = (item) => {

        let smartTagInfo = {
            type: type,
            mode: 'edit',
            unitTagDetails: {
                TagName: item.tagName,
                TagDescription: item.description
            },
            orgTagDetails: {
                TagName: item.tagName,
                TagDescription: item.description
            },
            selectedRow: item,
        }
        if (type === 'orgList') {
            delete smartTagInfo.unitTagDetails;
        } else {
            delete smartTagInfo.orgTagDetails;
        }
        KCStore.setItem('smartTagInfo', JSON.stringify(smartTagInfo));
        props.history.push({
            pathname: type === 'orgList' ? "/smartTag/createOrgTag" : "/smartTag/createUnitTag",
            state: { availableTagList: getCurrentList }
        })
    }

    const downloadUnitList = () => {
        setLoaderIconVisible(true);
        let url = smartTagMangement.getPresignedUrl;
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
		if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag='yes';
        }
        url = url + `?registrationFlag=${registrationFlag}&tagName=${selectedRow[0].tagName}&isView=yes`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'customerid': KCStore.getItem("customerId"),
                'cgid': KCStore.getItem("custGroupID"),
                'brandid': BRAND_ID,
            }, data: {}
        }).then(async response => {
            if (response && response.data && response.data.data) {
                setLoaderIconVisible(false);
                if (response.data.data.preSignedUrl && response.data.data.preSignedUrl !== '#') {
                    window.location = response.data.data.preSignedUrl;
                }
                $('.loadingProgress').removeAttr('style')
            }
        }).catch(err => {
            setLoaderIconVisible(false);
        });
    }

    const onDeleteClick = () => {
        let selectedIds = [];
        let allTagNames = [];
        orgSTagList.forEach(e => {
            if (e.checked) {
                selectedIds.push(e.id);
                allTagNames.push(e.tagName);
            }
        });
        setAccess_modal({ open: true, message: allTagNames.length === 1 ? allTagNames.toString() : `${type === 'orgList' ? 'OrgChart' : 'Unit'} Smart Tags`});
    }

    const deleteSelectedTag = () => {
        setLoaderIconVisible(true);
        let selectedIds = [];
        let allTagNames = [];
        orgSTagList.forEach(e => {
            if (e.checked === true) {
                selectedIds.push(e.id);
                allTagNames.push(e.tagName);
            }
        });

        let url = smartTagMangement.createOrgTag;
        http.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'tagtype': type === 'orgList' ? 'ORG' : 'UNIT',
            }, data: { 'id': selectedIds }
        }).then(async response => {
            if (response && response.data) {
                setLoaderIconVisible(false);
                setAccess_modal({ open: false });
                let message = formatMessage({ id: 'KC2326' }, { value: type === 'orgList' ? `OrgChart Smart Tag${allTagNames.length === 1 ? '':'s'}` : `Unit Smart Tag${allTagNames.length === 1 ? '':'s'}` });
                setSuccess_modal({ open: true, message });
                setEdit(false);
                setDelete(false);
            }
        }).catch(err => {
            setLoaderIconVisible(false);
        });
    }

    const accessNowModel = (e) => {
        deleteSelectedTag();
    }

    const accessLaterModel = () => {
        setAccess_modal({ open: false });
    }

    const resetState = () => {
        props.clearSearchText();
        KCStore.removeItem('smartTagInfo');
    }

    const onSuccessCloseModal = (e) => {
        setDelete(false);
        setAllSelect(false);
        setIsIndeterminate(false);
        setSuccess_modal({ open: false, message: '' })
        props.getSmartTagDetails(type === 'orgList'?'ORG':'UNIT','',registrationFlag,BRAND_ID);
    }

    $(function () {
        $('[data-toggle="popover"]').popover({
            trigger: 'hover'
        })
    })

    let userProfileAuthFeature = authService.getFeature("/smartTag/tagList");
    let unitRegistrationFeature = authService.getFeature("/addUnit");
    let organizationAuthirizedFeature = authService.getFeature("/organizationManagement");
    let orderId = 1;
    let columns = [
        { path: "smartTagName", labelStringID: "KC2319" },
        { path: "description", labelStringID: "KC0195" },
        { path: "linkedItems", labelStringID: "KC2331" },
        { path: "", labelStringID: "KC0325" },
        { path: "", labelStringID: "KC2371" },
        { path: "", labelStringID: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
    ];
    
    let lagList = orgSTagList;  
    lagList.forEach(item => {
        item.order_id = orderId++;
    });
    if (sortColumn.path === "smartTagName") {
        lagList = _.orderBy(lagList, [lagList => lagList.tagName && lagList.tagName.toString().toLowerCase()], [sortColumn.order]);
    } else if (sortColumn.path === "description") {
        lagList = _.orderBy(lagList, [lagList => lagList.description && lagList.description.toString().toLowerCase()], [sortColumn.order]);
    } else if (sortColumn.path === "linkedItems") {
        lagList = _.orderBy(lagList, [lagList => lagList.linkedItems && lagList.linkedItems], [sortColumn.order]);
    } else {
        lagList = _.orderBy(lagList, [lagList => lagList.tagName && lagList.tagName.toString().toLowerCase()], [sortColumn.order]);
    };
    const {isLoading} = props;
    return (
        <div id="adminWrapper">
            <div className="treeInfoToggleTransparent"></div>
            <div className={loaderIconVisible || isLoading ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <div id="page-content-wrapper" className="withSearchBar">
                <div className="menuUpdateWrapper">
                    <div class="navHeader">
                        <ul>
                            <li>
                                {organizationAuthirizedFeature && (organizationAuthirizedFeature.is_editable === 0 || organizationAuthirizedFeature.is_editable === 1) ? <Link to="/organizationManagement" title={formatMessage({ id: "KC0519" })} onClick={resetState}><FormattedMessage id="KC0519" /></Link> : ""}
                                {unitRegistrationFeature && (unitRegistrationFeature.is_editable === 0 || unitRegistrationFeature.is_editable === 1) ? <Link to="/unitRegistration" title={formatMessage({ id: "KC0631" })} onClick={resetState}><FormattedMessage id="KC0631" /></Link> : ""}
                                <Link to="/smartTag/tagList" title={formatMessage({ id: "KC2318" })} className="active"><FormattedMessage id="KC2318" /></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="reportHeader">                        
                        <ul>
                            <li>
                                <Link to="/smartTag/tagList" title={formatMessage({ id: 'KC2304' })} onClick={(e) => { onTabClick("orgList") }} className={type === "orgList" ? "OrgChartSTNav active" : "OrgChartSTNav"}> <FormattedMessage id="KC2304" /></Link>
                                <Link to="/smartTag/tagList" title={formatMessage({ id: 'KC2304' })} onClick={(e) => { onTabClick("unitList") }} className={type === "unitList" ? "UnitSTNav active" : "UnitSTNav"}><FormattedMessage id="KC2305" /></Link>
                            </li>
                            <li>
                                <Link onClick={() => onDeleteClick()} to="#" className={`btn delete ${isDelete && userProfileAuthFeature.is_editable === 1 ? "" : "disabled"}`}>&nbsp;</Link>
                                {/* <Link onClick={() => onAddClick()} to="#" className={`btn add ${userProfileAuthFeature.is_editable === 1 ? "" : "disabled"}`}>&nbsp;</Link> */}
                                <Link to={{ pathname: type === 'orgList' ? "/smartTag/createOrgTag" : "/smartTag/createUnitTag" }} className={`btn add ${userProfileAuthFeature.is_editable === 1 ? "" : "disabled"}`}>&nbsp;</Link>
                            </li>
                        </ul>
                    </div>

                    <form className="locationTagForm">
                        <div className="stLocationTable">
                            <TableHeader
                                isIndeterminate={isIndeterminateSelect}
                                selectAllChange={handleChange}
                                sortColumn={sortColumn}
                                onSort={onSort}
                                columns={columns}
                                isSelectAll={allSelect} />
                        </div>
                        <div className="samrtTagTableSrlBar">
                            <div className="stLocationTable">
                                {lagList && lagList.length ? lagList.map((item, i) =>
                                    <Fragment key={i}>
                                        <ul className="tBody">
                                            <li>
                                                <div className={type === 'orgList' ? "tagTypeLocation" : "tagTypeUnit"}><span>{item.tagName}</span> </div>
                                                <div className="stLocationSM">
                                                    <p>{item.description}</p>
                                                </div>
                                            </li>
                                            <li><p>{item.description} </p></li>
                                            <li>{item.linkedItems}</li>
                                            <li className="btn stLocationDetails">
                                                <button onClick={(e) => { showModelPopup(item) }} type="button" className="btn stLocationDetails" data-toggle="modal" data-target="#stUnitDetailsModal">&nbsp;</button></li>
                                            <li>
                                                <span data-toggle="popover" title={item.editable && userProfileAuthFeature.is_editable === 1 ? "" :formatMessage({id:'KC2633'})} data-content={item.editable && userProfileAuthFeature.is_editable === 1 ? "" : formatMessage({id:'KC2940'})}>
                                                    <button type="button" onClick={(e) => { onEditClick(item) }} className={`btn stEditPrms ${item.editable && userProfileAuthFeature.is_editable === 1 ? "" : "disabled"}`}>&nbsp;</button>
                                                </span>
                                                <button type="button" onClick={(e) => { onEditClick(item) }} className={`btn stViewPrms ${userProfileAuthFeature.is_editable === 1 ? "" : "disabled"}`}>&nbsp;</button>
                                            </li>
                                            <li data-toggle={item.editable ? "" : "popover"} title={item.editable ? "" : formatMessage({id:'KC2935'})} data-content={item.editable ? "" : formatMessage({id:'KC2941'})}>
                                                <div className={item.editable ? "customCheckbox customCheckboxWOlabel":"customCheckbox customCheckboxWOlabel disabled"}>
                                                    <input id={item.order_id - 1} name={item.tagName} type="checkbox"
                                                        checked={item.checked}
                                                        onChange={(e) => handleSingleCheckboxChange(e, item)} />
                                                    <label htmlFor={item.order_id - 1}></label>
                                                </div>
                                            </li>
                                        </ul>
                                    </Fragment>
                                ) : null}
                            </div>
                            <NoRecordsFound loaderIconVisible={loaderIconVisible || isLoading} length={lagList !== undefined ? lagList.length : 0} classname={'norecordsfoundbluetext'} stringID={searchData && lagList && lagList.length === 0 ? "KC0853" : "KC0205"} />
                        </div>
                    </form>
                </div>
            </div >

            {/* <!-- ST Details Modal --> */}
            < div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabindex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel" >
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header modal-header-danger">
                            <FormattedMessage id="KC2312" />
                        </div>
                        <div className="modal-body">
                            {selectedRow && selectedRow.map((item, i) =>
                                <div className="reportDetailsD" key={`tag_${i}`}>
                                    <div className="createdEditedDetailsO">
                                        <ul className="tHead">
                                            <li>
                                                <div className={type === "orgList" ? "tagTypeLocation" : "tagTypeUnit"} ><span>{item.tagName}</span></div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h6><FormattedMessage id="KC0195" /></h6>
                                    <p>{item.description ? item.description : 'NA'}</p>
                                    <div className="createdEditedDetailsO">
                                        <ul className="tHead">
                                            <li>
                                                <p><FormattedMessage id="KC2313" /> <button type="button" onClick={() => $('#stUnitDetailsModal').modal('hide')} data-toggle="modal" data-target="#orgViewModal" className= {(item.allcgids && item.allcgids.length === 0) || (item.allunitIds && item.allunitIds.length === 0) ? "orgViewIcon btn disabled" :"orgViewIcon btn"}><img src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> {type === 'orgList' ? formatMessage({ id: "KC2306" }) : formatMessage({ id: "KC2307" })}</p>
                                            </li>
                                            {type === 'orgList' ? "" :
                                                <li>
                                                    <Link to='#' onClick={() => downloadUnitList()} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
                                                </li>}
                                        </ul>
                                    </div>
                                    {(item.allcgids && item.allcgids.length === 0) || (item.allunitIds && item.allunitIds.length === 0) ? <p class="notUsed"><FormattedMessage id='KC2357' /></p> : null}
                                    <div className="createdEditedDetailsO">
                                        <ul className="tHead">
                                            <li><FormattedMessage id="KC0589" /></li>
                                            <li><FormattedMessage id="KC1278" /></li>
                                        </ul>
                                        <ul className="tBody">
                                            <li>
                                                <div className="createdEditedDetails">
                                                    <ul>
                                                        <li><FormattedMessage id="KC0241" />:</li>
                                                        <li>{item.createdByName ? item.createdByName : ''}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC0136" />:</li>
                                                        <li>{item.createdBy ? item.createdBy : ''}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC0298" />:</li>
                                                        <li>{item.createdAt ? moment.GetDateTimeByFormat(item.createdAt, 'LLL') : ''}</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="createdEditedDetails">
                                                    <ul>
                                                        <li><FormattedMessage id="KC0241" />:</li>
                                                        <li>{item.updatedByName ? item.updatedByName : ''}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC0136" />:</li>
                                                        <li>{item.updatedBy ? item.updatedBy : ''}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC0298" />:</li>
                                                        <li>{item.UpdatedAt ? moment.GetDateTimeByFormat(item.UpdatedAt, 'LLL') : ''}</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>)}
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn btn-secondary okBtn" data-dismiss="modal" onClick={() => setShowPopup([])} type="button"><FormattedMessage id="KC0777" /></button>
                        </div>
                    </div>
                </div>
            </div >
            {/* <!-- //ST Details Modal --> */}

            {/*  Org tag */}
            {showpopup && showpopup.length ? <TagOrgModal details={showpopup[0]} /> : null}
            <UserManagementHeader headerName={formatMessage({ id: 'KC2318' })} headerNameStringID="KC2318" activeClass="smartTagNav" />
            <ConfirmModal message={access_modal.message} open={access_modal.open} onAccessNowModel={(e) => accessNowModel(e)} onAccessLaterModel={(e) => accessLaterModel(e)} newMessageFormat={true} stringID={type === 'orgList' ? 'KC2324' : 'KC2325'} />
            <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={(e) => onSuccessCloseModal(e)} />
        </div >
    )
};

function mapDispatchToProps(dispatch,) {
    return bindActionCreators({
        searchVisible, searchText,getSmartTagDetails,clearSearchText
    }, dispatch);
}

function mapStateToProps(state,) {
    return {
        searchData: state.advanceSearch.searchData,
        orgSTagList: state.smartTag.smartTagList,
        isLoading:state.smartTag.isLoading,        
    }
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DisplayProduct))