import {TOTAL_DRINKS_LIST_REQUEST,TOTAL_DRINKS_LIST_SUCCESS,TOTAL_DRINKS_LIST_FAIL} from '../../../actions/reportManagement/totalDrinksAction';
const initialState = {
    loaded: false,
    loading: false,
    totalDrinksInformation: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case TOTAL_DRINKS_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                
            }
        case TOTAL_DRINKS_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                totalDrinksInformation: action.totalDrinksInformation
            }
        case TOTAL_DRINKS_LIST_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgTotalDrinks: 'Server Error',

            }
        default:
            return state
    }
}