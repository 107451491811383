import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import $ from 'jquery';
import Slider from 'react-rangeslider'
import InputMask from 'react-input-mask';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class FrymasterMultiSegment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            dynamicdataList: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            disableAddStep: false,
            isChanged: false,
            showDiscardPopup: false,
            time_mask: [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            promptName: '',
            temperature: 0,
            totalCookTime: 0,
            sensitivity: 0,
            filterCountValue: 0,
            filterCountType: 'Global',
            drainTimer: false,
            promptTimeValue: 0,
            acknowledge_mode: false,
            holdTimerValue: 0,
            instantOnValue: 0,
            segmentStartTime: '10:00'
        }
        this.onDeleteStep = this.onDeleteStep.bind(this);
        this.onSavePrompt = this.onSavePrompt.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.addStep = this.addStep.bind(this);
        this.handleParamChange = this.handleParamChange.bind(this);
        this.handleParamSubmit = this.handleParamSubmit.bind(this);
        this.handleParamCheck = this.handleParamCheck.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.handleOnSliderChange = this.handleOnSliderChange.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            window.addEventListener('resize', this.updateDimensions);
            let libraryData = JSON.parse(KCStore.getItem("libraryData"));

            this.setState({ startCookTime: libraryData.settings.quality_timer || '00:00' });

            if (libraryData.length === 4) {
                this.setState({
                    disableAddStep: true
                });
            }

            let steps = []
            libraryData.ordered_steps.map((item, i) => {
                let step = {};
                step.index = i;
                step.temperature = item.settings.set_point.value + 'F';
                step.time = item.duration;
                step.sensitivity = item.settings.sensitivity;
                step.promptDelete = '';
                steps.push(step);
            })

            if (steps.length > 0) {
                this.setState({
                    dynamicdataList: steps,
                    disableAddStep: steps.length > 2 ? true : false
                });
            }
            else {
                this.setState({
                    dynamicdataList: [{ index: 0, temperature: '', time: '', sensitivity: '0', promptDelete: '' }]
                });
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    calculatedSegmentedTime = () => {
        let hours = 0, minutes = 0;

        this.state.dynamicdataList.map((i) => {
            const splitedTime = i.time === "" ? ['00', '00'] : i.time.split(':');
            hours = hours + Number(splitedTime[0]);
            minutes = minutes + Number(splitedTime[1]);
        })

        return minutes >= 60 ? `${hours + 1}:${minutes - 60}` : `${hours}:${minutes}`
    }

    secondsToMinutes(time) {
        return Math.floor(time / 60) + ':' + Math.floor(time % 60);
    }

    calculateRemainingTime = () => {
        let libraryData = JSON.parse(KCStore.getItem("libraryData"));
        const startCookTime = libraryData.settings.quality_timer || '00:00';
        // Split starting cook time in segment
        const splitedTotalTime = startCookTime.split(':');

        // Get all the segment time from this function
        const segmentedTime = this.calculatedSegmentedTime();

        if(segmentedTime.includes('NaN')){
            return startCookTime;
        }

        // Split all segment time and remaining time to calculate the hours and minutes
        const splitSegmentedTime = segmentedTime.split(':');


        const startCookTimeInSec = Number(splitedTotalTime[0]) * 60 + Number(splitedTotalTime[1]);
        const segmentCookTimeInSec = Number(splitSegmentedTime[0]) * 60 + Number(splitSegmentedTime[1]);

        const remainingTimeValue = segmentCookTimeInSec > startCookTimeInSec ? segmentCookTimeInSec - startCookTimeInSec : startCookTimeInSec - segmentCookTimeInSec;

        const timeValue = segmentCookTimeInSec > startCookTimeInSec ? `-${this.secondsToMinutes(remainingTimeValue)}` : this.secondsToMinutes(remainingTimeValue);
        const remainingTime = timeValue.split(':');


        return `${remainingTime[0].length === 1 ? `0${remainingTime[0]}` : remainingTime[0]}:${remainingTime[1].length === 1 ? `0${remainingTime[1]}` : remainingTime[1]}`;

    }


    updateDimensions() {
        $("#scrollDownButton").on("click", function () {
            $(".mCustomScrollbar").mCustomScrollbar("update");
            setTimeout(function(){
                $(".mCustomScrollbar").mCustomScrollbar('scrollTo','bottom');
            },100);
        });
    }

    handleOnSliderChange = (value, idx) => {
        let dynamicdataList = [...this.state.dynamicdataList]
        dynamicdataList[idx]["sensitivity"] = value;
        this.setState({
            dynamicdataList: dynamicdataList,
            isChanged: true
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        this.props.history.push(`/frymasterRecipeCreation`);
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    onBackButtonClick = () => {
        if (!this.state.isChanged) {
            this.props.history.push(`/frymasterRecipeCreation`);
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }

    onSavePrompt = () => {
        this.setState({
            loaderIconVisible: true,
        });

        const remainingTime = this.calculateRemainingTime();
        const splitTime = remainingTime.split(':');

        if (Math.sign(Number(splitTime[0])) === -1 || splitTime[0].includes('-')) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1365"
                }
            });
            return;
        }

        if (this.state.dynamicdataList.length > 0) {
            let emptyInput = false;
            this.state.dynamicdataList.map((item, i) => {
                if (!emptyInput) {
                    emptyInput = !item.temperature.replace('F', '') || !item.time || item.time.includes('_') || !item.sensitivity;
                }
            });

            if (!emptyInput) {
                let libraryData = JSON.parse(KCStore.getItem("libraryData"));
                libraryData.ordered_steps = [];
                this.state.dynamicdataList.map((item, i) => {
                    libraryData.ordered_steps[i] = {};
                    libraryData.ordered_steps[i].duration = item.time;
                    libraryData.ordered_steps[i].settings = {};
                    libraryData.ordered_steps[i].settings.sensitivity = item.sensitivity ? parseInt(item.sensitivity) : 0;
                    libraryData.ordered_steps[i].settings.set_point = {};
                    libraryData.ordered_steps[i].settings.set_point.value = item.temperature.replace('F','') ? parseFloat(item.temperature.replace('F',''), 1) : 0.0;
                    libraryData.ordered_steps[i].settings.set_point.units = 'degF';
                })
                KCStore.setItem("libraryData", JSON.stringify(libraryData));
                this.props.history.push({
                    pathname: "/frymasterRecipeCreation",
                    previouspath: "/frymasterActionPrompt",
                    isEdit: this.state.isEdit
                });
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1206"
                    }
                });
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1366"
                }
            });
        }
    }

    addStep = (e) => {
        const remainingTime = this.calculateRemainingTime();
        const splitTime = remainingTime.split(':');

        if (Math.sign(Number(splitTime[0])) === -1) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1365"
                }
            });
            return;
        }

        this.setState((prevState) => ({
            dynamicdataList: [...prevState.dynamicdataList, { index: prevState.dynamicdataList.length + 1, temperature: '', time: '', sensitivity: '0', promptDelete: '' }],
            isChanged: true
        }));
        if (this.state.dynamicdataList.length > 10) {
            this.setState({
                disableAddStep: true
            });
        }
    }

    handleParamChange = (e) => {
        this.setState({
            isChanged: true
        });
        if (["form-control temperature"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = e.target.value.replace('F','') + 'F'

            this.setState({
                dynamicdataList: dynamicdataList
            })
        }
        else if (["form-control floatRight time"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "").replace("floatRight ", "")] = e.target.value ? (parseInt(e.target.value.replace(':',''))  > 1000 || e.target.value[0] === "1") ? "10:00" : e.target.value : e.target.value;

            this.setState({
                dynamicdataList: dynamicdataList
            })
        }
        else {
            this.setState({ [e.target.name]: e.target.value.toUpperCase() })
        }
    }

    handleParamCheck = (e) => {
        this.setState({
            isChanged: true
        });
    }

    onDeleteStep = (e) => {
        let id = e.target.id.replace('promptDelete-', '');
        if (id) {
            let splicedata = this.state.dynamicdataList;
            splicedata.splice(Math.round(id), 1);
            this.setState({
                dynamicdataList: splicedata,
                isChanged: true
            });
            if (splicedata.length < 12) {
                this.setState({
                    disableAddStep: false
                });
            }
        }
    }

    handleParamSubmit = (e) => { e.preventDefault() }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: basedataList, dynamicdataList, disableAddStep, success_modal, error_modal, loaderIconVisible, showDiscardPopup, time_mask, startCookTime} = this.state;
        basedataList = basedataList && basedataList.length > 0 ? basedataList : [];
        basedataList = basedataList.filter(b => b.PARAMETER_NAME !== "Instruction");
        let brandid = KCStore.getItem("selectedbrandID");

        const horizontalLabels = {
            0: formatMessage({ id: 'KC2038' }),
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: formatMessage({ id: 'KC2039' })
        }

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="rcDeviceWrapper">
                            <div className="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt="" /></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} className="btn backButton">&nbsp;</button> <FormattedMessage id="KC1350"/>
				                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn">
                                                <div class="frymasterRCR threeView">
                                                    <ul>
                                                        <li>
                                                            <label><FormattedMessage id="KC1367"/></label>
                                                            <span class="labelVal">{startCookTime}</span>
                                                        </li>
                                                        <li>
                                                            <label><FormattedMessage id="KC1368"/></label>
                                                            <span class="labelVal">{this.calculateRemainingTime()}</span>
                                                        </li>
                                                        <li>
                                                            <label><FormattedMessage id="KC1369"/></label>
                                                            <span class="labelVal">{this.state.dynamicdataList.length ? this.state.dynamicdataList[0].time === '' ? '00:00' : this.state.dynamicdataList[0].time : '00:00'}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    dynamicdataList.map((val, idx) => {
                                                        let temperatureid = `temperature-${idx}`, timeid = `time-${idx}`, sensitivity = `sensitivity-${idx}`, promptDeleteid = `promptDelete-${idx}`
                                                        return (
                                                            <div>
                                                                <div className="frymasterRCForm" onSubmit={this.handleParamSubmit} onChange={this.handleParamChange} onBlur={this.handleParamCheck} key={idx}>
                                                                    <ul className="borderNone">
                                                                        <li><FormattedMessage id="KC1370"/> {idx + 1}</li>
                                                                        <li>{idx !== 0 && <button name={promptDeleteid}
                                                                                    data-id={idx}
                                                                                    id={promptDeleteid} onClick={(e) => { this.onDeleteStep(e) }} type="button" className="btn deleteButton" title={formatMessage({ id: 'KC1371' })}>&nbsp;</button>}
                                                                        </li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li><FormattedMessage id="KC1289"/></li>
                                                                        <li className="smallFormFields"><input type="text" maxLength="4" name={temperatureid}
                                                                            data-id={idx} value={val.temperature ? val.temperature : 'F'} placeholder={formatMessage({ id: 'KC1980' })}
                                                                            id={temperatureid} className="form-control temperature" /></li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li><FormattedMessage id="KC0197"/></li>
                                                                        <li className="smallFormFields">
                                                                            <InputMask mask={time_mask} maskChar="" placeholder={formatMessage({ id: 'KC2085' })} value={val.time} name={timeid} data-id={idx} id={timeid} className="form-control floatRight time" />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="frymasterRCForm">
                                                                    <ul class="borderNone">
                                                                        <li><FormattedMessage id="KC1351"/></li>
                                                                        <li class="smallFormFields">&nbsp;</li>
                                                                    </ul>
                                                                </div>
                                                                <div class="frymasterRCForm">
                                                                    <ul class="borderNone">
                                                                        <li class="width10px">&nbsp;</li>
                                                                        <li>
                                                                        <div className='slider custom-labels'>
                                                                            <div className='slider-horizontal'>
                                                                        <Slider
                                                                            min={0}
                                                                            max={9}
                                                                            value={val.sensitivity}
                                                                            labels={horizontalLabels}
                                                                            orientation='horizontal'
                                                                            handleLabel={val.sensitivity}
                                                                            onChange={(e) => { this.handleOnSliderChange(e, idx)}}
                                                                        /></div></div>
                                                                        </li>  
                                                                    </ul>  
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="recipeEditorF">
                                    <button type="button" className="btn languageButton">&nbsp;</button>
                                    <button type="button" id="scrollDownButton" onClick={this.addStep} className={disableAddStep ? "btn addButton displaynone" : "btn addButton"}>&nbsp;</button>
                                    <button type="button" onClick={() => { this.onSavePrompt() }} className="btn confirmButton rightAlign">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} className="btn cancelButton rightAlign">&nbsp;</button>
                                </div>
                            </div>
                            <div className="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterMultiSegment)
