export const peakHourConfiguration = {
    2: {
        id: "mercoPeakHour",
        url: "/reportmanagement/report-management/dashboard-service/v1/widget-data",
        configuration: {
            execute: [
                {
                    database: "docdb",
                    query: {
                        collection: "events",
                        operation: [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {
                                                serialNo: { $in: "{{ data.units }}" },
                                                'eventData.event.value': 'holdStart',
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.dateFilterStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.dateFilterEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: {
                                                    hour: { $hour: '$deviceTimestamp' },
                                                    product: '$eventData.productName.value'
                                                },
                                                count: { $sum: 1 }
                                            }
                                        },
                                        {
                                            $project: {
                                                hour: '$_id.hour',
                                                product: '$_id.product',
                                                count: '$count'
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: '$hour',
                                                hour: { $first: '$hour' },
                                                data: {
                                                    $push: {
                                                        product: '$$ROOT.product',
                                                        count: '$$ROOT.count'
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    outputProperty: "peakHour"
                }
            ],
            executionData: {
                "_serverScope": {
                    dateFilterStartValue: "{{data.dateFilterStartValueFormatted}}",
                    dateFilterEndValue: "{{data.dateFilterEndValueFormatted}}"
                }
            }
        }
    },
    12: {
        id: "peak_hour",
        url: "/reportmanagement/report-management/dashboard-service/v1/widget-data",
        configuration: {
            execute: [
                {
                    database: "docdb",
                    query: {
                        collection: "events",
                        operation: [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {
                                                serialNo: { $in: "{{ data.units }}" },
                                                "eventData.event.value": "cookingStop",
                                                "eventData.uiSelectedRecipeName.value": {
                                                    "$exists": true
                                                },
                                                "eventData.processDurationInSeconds.value": {
                                                    "$exists": true
                                                },
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.dateFilterStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.dateFilterEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: {
                                                    hour: { $hour: '$deviceTimestamp' },
                                                    product: '$eventData.uiSelectedRecipeName.value'
                                                },
                                                count: { $sum: 1 }
                                            }
                                        },
                                        {
                                            $project: {
                                                hour: '$_id.hour',
                                                product: '$_id.product',
                                                count: '$count'
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: '$hour',
                                                hour: { $first: '$hour' },
                                                data: {
                                                    $push: {
                                                        product: '$$ROOT.product',
                                                        count: '$$ROOT.count'
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    outputProperty: "peakHour"
                }
            ],
            executionData: {
                "_serverScope": {
                    dateFilterStartValue: "{{data.dateFilterStartValueFormatted}}",
                    dateFilterEndValue: "{{data.dateFilterEndValueFormatted}}"
                }
            }
        }
    }
}