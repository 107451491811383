import _ from 'lodash';
import { unitAPI } from '../../service/api';
import http from '../../service/httpService';
import { GET_BRAND_LIST, GET_BRAND_LIST_SUCCESS, GET_BRAND_LIST_FAIL } from './actionType';

let headers = {
    'Content-Type': 'application/json',
};
//  get list of brands
export const getBrandsList = () => dispatch => {
    dispatch({ type: GET_BRAND_LIST })
    http.get(`${unitAPI.getBrands}`, {
        headers,
        data: {}
    }).then(async response => {
        if (response && response.data) {
            dispatch({ type: GET_BRAND_LIST_SUCCESS, payload: _.orderBy(response.data, ['BRAND_NAME'], ['asc']) })
        } else {
          dispatch({ type: GET_BRAND_LIST_FAIL })
        }
    }).catch(err => {
        console.log('Failed to get brand list: ', err);
        dispatch({ type: GET_BRAND_LIST_FAIL })
    });
}