import {UNITS_GRID_REQUEST,UNITS_GRID_SUCCESS,UNITS_GRID_FAIL} from '../../../actions/allLocations/units/dashboardUnitGrid/dashboardUnitGridAction';

const initialState = {
    loaded: false,
    loading: false,
    dashboardUnitsValue: {},
    errormsg:'',
}

export default function reducer (state = initialState, action = {}) {

    switch (action.type) {
        case UNITS_GRID_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                errormsg: '',
                isUnitsListResponsecomplete : false
            }
        case UNITS_GRID_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                dashboardUnitsValue : action.dashboardUnitsValue,
                errormsg: '',
                isUnitsListResponsecomplete : true
            }
        case UNITS_GRID_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
                isUnitsListResponsecomplete : true
            }
        default:
            return state
    }
}