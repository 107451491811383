import {UNIT_DASHBOARD_SINGLE_DATA_REQUEST,UNIT_DASHBOARD_SINGLE_DATA_SUCCESS,UNIT_DASHBOARD_SINGLE_DATA_FAIL} from '../../../actions/unitDashboard/unitDashboardSingleDataAction';
const initialState = {
    loaded: false,
    loading: false,
    singleData:{}
}

export default function reducer (state = initialState, action = {}) {

    switch (action.type) {
        case UNIT_DASHBOARD_SINGLE_DATA_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
            }
        case UNIT_DASHBOARD_SINGLE_DATA_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                singleData: action.singleData
            }
        case UNIT_DASHBOARD_SINGLE_DATA_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
            }
        default:
            return state
    }
}
