import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';

class Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            selectedModel: 0
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {

        } catch (error) {
            //Error Goes Here
        }
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/timerForTemperature"
        });
    }

    onSettingsClick = (page) => {
        let pathName = "";
        if (page === "ManagerPassword") {
            pathName = "/managerPassword";
        }
        else if (page === "ServicePassword") {
            pathName = "/servicePassword";
        }

        this.props.history.push({
            pathname: pathName,
            isEdit: false,
            previouspath: "/password"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} alt="" /></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1303"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <div class="rcSettingsList02">
                                                        <ul onClick={() => { this.onSettingsClick('ManagerPassword') }}>
                                                            <li><FormattedMessage id="KC1304"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")}  alt=""/></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList02">
                                                        <ul onClick={() => { this.onSettingsClick('ServicePassword') }}>
                                                            <li><FormattedMessage id="KC1305"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")}  alt=""/></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default injectIntl(Password)
