import React, { Component } from 'react'
import { injectIntl,FormattedMessage } from 'react-intl';

class CreatedUserPopup extends Component {
  state = {
    loaderIconVisible: false,
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#000000";
  }

  render() {
    let { created_by, last_edited_by, assignedTopology, description } = this.props;
    const { formatMessage } = this.props.intl;
    
    return (
        <div className="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="detailsInfoModal" tabindex="-1" role="dialog" aria-labelledby="detailsInfoModalLabel">
        <div className="modal-dialog modal-md infoModal">
          <div className="modal-content">
            <div className="modal-header modal-header-danger">
            <FormattedMessage id="KC0325"/> <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
            </div>
            <div className="modal-body">
              <div className="detailsInfoContent">
                <p><FormattedMessage id="KC0687"/></p> 
                  <ul>
                    {assignedTopology.map((subitem, j) =>
                        <li>{subitem.group_name}</li>
                      )}
                </ul>
                <p><FormattedMessage id="KC0605"/></p>
                <h6>{created_by}</h6>
                <p><FormattedMessage id="KC1278"/></p>
                <h6>{last_edited_by}</h6>
                {description && <p><FormattedMessage id="KC0195"/></p>}
                {description && <h6>{description}</h6>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(CreatedUserPopup)
