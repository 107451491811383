import axios from 'axios';
import api from '../../../service/api';
import http from '../../../service/httpService';
import { CUSTOMER_ID, BRAND_ID } from '../../../utils/appConstants';
export const ORG_SUBELEMENT_COUNTREQUEST = 'ORG_SUBELEMENT_COUNTREQUEST';
export const ORG_SUBELEMENT_COUNTSUCCESS = 'ORG_SUBELEMENT_COUNTSUCCESS';
export const ORG_SUBELEMENT_COUNTFAIL = 'ORG_SUBELEMENT_COUNTFAIL';
export const ORG_SUBELEMENT_COUNTRESET = 'ORG_SUBELEMENT_COUNTRESET';

export function getOrganizationSubElementCount(customerGrpId, cgIds) {
    return dispatch => {
        dispatch({
            type: ORG_SUBELEMENT_COUNTREQUEST
        })
        return new Promise((res, rej) => {
            http.get(api.organizaionSubElementCountUrl + customerGrpId,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Cgids": cgIds,
                        'brandids': BRAND_ID
                    },
                    data: {}
                })
                .then(response => {
                    console.log('ORG_VIEWInfo', response.data);
                    dispatch({
                        type: ORG_SUBELEMENT_COUNTSUCCESS,
                        OrganizationSubElementCountInformation: response.data,
                    })
                    res(response.data)
                })
                .catch(error => {
                    dispatch({
                        type: ORG_SUBELEMENT_COUNTFAIL,
                        error
                    })
                    res(error)
                })
        })
    }
}
export function resetOrganizationSubElementCount() {
    return dispatch => { dispatch({
        type: ORG_SUBELEMENT_COUNTRESET
    })}
}


