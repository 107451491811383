// import statements
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Loader } from '@kcui/react/Loader';
import { getUnitStatus, setModalStatus, setPopupStatus, establishDiagnosticConnection, setUnitConnectionStatus, setDiagnosticConnectionStatus, setLiveMonitoringStreamData, setLiveMonitoringData, getDiagnosticConfigFile } from '../../actions/liveDiagnostic/liveDiagnosticAction'
import { liveDiagnosticDuration, liveDiagnosticAction, liveDiagnosticDurationInMinutes, liveDiagnosticTimer, liveDiagnosticStates, liveDiagnosticWebSocketURL, panDateFormat } from '../../utils/dashboardRenderer';
import LiveDiagnosticModal from './liveDiagnosticModal';
import LiveDiagnosticRenderer from './liveDiagnosticRenderer';
import BarLoader from "react-spinners/BarLoader";
import { liveDiagnosticDataConf } from './liveMonitoringConfiguration';
import { compileConfiguration } from '../DashboardRenderers/configuration-compiler';
import { invokePostMethod } from '../DashboardRenderers/dashboardRenderer';
import Tooltip from 'react-tooltip-lite';
import { accessTokenEventEmitter } from '../../service/httpService';
import KCStore from '../../utils/kcStorage';

let counter = 0;

// Live Diagnostic Component
function LiveDiagnostic({ currentUnit }) {

    // State Initialization
    const [loading, setLoading] = React.useState(true);
    const [isMultiUser, setMultiUser] = React.useState(false);
    const [isCurrentConnection, setCurrentConnection] = React.useState(false);

    const intervalIdRef = React.useRef(null);
    const diagnosticRef = React.useRef(null);

    // get current user email to validate
    const currentUser = KCStore.getItem('user');

    // Redux initialization
    const dispatch = useDispatch();
    const modal = useSelector(state => state.liveDiagnostic.modalStatus);
    const unitStatus = useSelector(state => state.liveDiagnostic.unitConnectionStatus);
    const diagnosticStatus = useSelector(state => state.liveDiagnostic.diagnosticConnectionStatus);
    const popupStatus = useSelector(state => state.liveDiagnostic.popupStatus);
    const configuration = useSelector(state => state.liveDiagnostic.diagnosticConfiguration);

    var ws;

    const accessTokenSubscription = accessTokenEventEmitter.subscribe((event) => {
        console.log("event emitted from refresh logic", event);
        ws.close();
        counter = 0;
        webSocketConnection();
    })

    React.useEffect(() => {
        dispatch(setDiagnosticConnectionStatus({}))
        dispatch(setModalStatus(false));
        webSocketConnection(true)
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null
            }
            dispatch(setDiagnosticConnectionStatus({}));
            dispatch(setUnitConnectionStatus(''))
            dispatch(setPopupStatus(''));
            dispatch(setModalStatus(false));
            ws.close();
            counter = 0;
            if (accessTokenSubscription) {
                accessTokenSubscription.unsubscribe();
            }
        };
    }, [])

    React.useEffect(() => {
        if (!diagnosticStatus || !unitStatus) {
            return;
        }
        diagnosticRef.current = diagnosticStatus;
        if (diagnosticStatus?.enableStatusAt) {
            getLiveMonitoringData(moment.utc(diagnosticStatus.enableStatusAt).format(panDateFormat), moment.utc(diagnosticStatus.enableStatusAt).add('minutes', 5).format(panDateFormat));
        }
        if (diagnosticStatus && unitStatus === liveDiagnosticStates.Online) {
            if (diagnosticStatus?.state === liveDiagnosticAction.Enable) {
                if (!configuration[currentUnit.MODEL_FAMILY_NAME] && !configuration[currentUnit.MODEL_NAME] && !configuration[currentUnit.BRAND_NAME]) {
                    dispatch(getDiagnosticConfigFile(currentUnit))
                }
                dispatch(setPopupStatus(''));
                dispatch(setModalStatus(false));
                const timeDiff = moment.utc().diff(moment.utc(diagnosticStatus.enableStatusAt), 'seconds');
                console.log("WebSocket diagnosticStatus timeDiff", timeDiff);
                if (timeDiff < liveDiagnosticDuration) {
                    if (diagnosticStatus.status === 'successful') {
                        setMultiUser(diagnosticStatus?.enabledBy ? diagnosticStatus.enabledBy !== currentUser || !isCurrentConnection : false)
                        if(document.getElementById('runningTimer')){
                            document.getElementById('runningTimer').innerHTML = liveDiagnosticTimer(timeDiff);
                        }
                        if(document.getElementById('runningMultiTimer')){
                            document.getElementById('runningMultiTimer').innerHTML = liveDiagnosticTimer(timeDiff);
                        }
                        dispatch(setPopupStatus(liveDiagnosticStates.SessionSuccess));
                        dispatch(setModalStatus(false));
                        if (intervalIdRef.current) {
                            clearInterval(intervalIdRef.current);
                            intervalIdRef.current = null
                        }
                        intervalIdRef.current = setInterval(() => {
                            const timeDiff = moment.utc().diff(moment.utc(diagnosticStatus.enableStatusAt), 'seconds');
                            if (timeDiff <= liveDiagnosticDuration) {
                                if(document.getElementById('runningTimer')){
                                    document.getElementById('runningTimer').innerHTML = liveDiagnosticTimer(timeDiff);
                                }
                                if(document.getElementById('runningMultiTimer')){
                                    document.getElementById('runningMultiTimer').innerHTML = liveDiagnosticTimer(timeDiff);
                                }
                            } else {
                                if (intervalIdRef.current) {
                                    if(timeDiff > 360 && diagnosticStatus.status === 'successful') {
                                        // Disable Request
                                        endLiveDiagnostic()
                                        clearInterval(intervalIdRef.current);
                                        intervalIdRef.current = null
                                    } else {
                                        clearInterval(intervalIdRef.current);
                                        intervalIdRef.current = null
                                    }
                                }
                            }
                        }, 1000)
                    } else if (diagnosticStatus.status === 'failed') {
                        dispatch(setPopupStatus(liveDiagnosticStates.SessionFailure));
                        dispatch(setModalStatus(true));
                    } else if (diagnosticStatus.status === 'timeout') {
                        dispatch(setPopupStatus(liveDiagnosticStates.SessionWarning));
                        dispatch(setModalStatus(true));
                    } else {
                        dispatch(setPopupStatus(liveDiagnosticStates.EstablishConnection));
                        dispatch(setModalStatus(true));
                    }
                } else {
                    if (diagnosticStatus.status === 'timeout') {
                        dispatch(setPopupStatus(liveDiagnosticStates.SessionWarning));
                        dispatch(setModalStatus(true));
                    } else if (diagnosticStatus.status === 'successful') {
                        // Disable Request
                        if(!diagnosticStatus.disableRequestAt) endLiveDiagnostic()
                        setMultiUser(diagnosticStatus?.enabledBy ? diagnosticStatus.enabledBy !== currentUser || !isCurrentConnection : false);
                        if(document.getElementById('runningTimer')){
                            document.getElementById('runningTimer').innerHTML = liveDiagnosticTimer(liveDiagnosticDuration);
                        }
                        if(document.getElementById('runningMultiTimer')){
                            document.getElementById('runningMultiTimer').innerHTML = liveDiagnosticTimer(liveDiagnosticDuration);
                        }
                        dispatch(setPopupStatus(liveDiagnosticStates.SessionSuccess));
                        dispatch(setModalStatus(false));
                    } else {
                        dispatch(setPopupStatus(liveDiagnosticStates.EstablishConnection));
                        dispatch(setModalStatus(true));
                    }
                }
            } else if (diagnosticStatus?.state === liveDiagnosticAction.Disable) {
                if (diagnosticStatus.status === 'timeout') {
                    dispatch(setPopupStatus(liveDiagnosticStates.SessionWarning));
                    dispatch(setModalStatus(true));
                } else {
                    dispatch(setPopupStatus(unitStatus));
                    dispatch(setModalStatus(true));
                }
            } else {
                dispatch(setPopupStatus(unitStatus));
                dispatch(setModalStatus(true));
            }
        } else if (diagnosticStatus && unitStatus === liveDiagnosticStates.Offline) {
            dispatch(setPopupStatus(unitStatus));
            dispatch(setModalStatus(true));
        }
    }, [diagnosticStatus, unitStatus])

    const webSocketConnection = (isInitialLoad = false) => new Promise(async (resolve, reject) => {
        if ("WebSocket" in window) {
            console.log("WebSocket is supported by your Browser!");
        }
        ws = new WebSocket(`${liveDiagnosticWebSocketURL()}?cloudUnitName=${currentUnit.UNITID}_${currentUnit.BRAND_NAME}&token=${KCStore.getItem('Access-Token')}`);

        ws.onopen = () => {
            setLoading(false)
            if (isInitialLoad) {
                // Web Socket is connected, send data using send()
                let deviceConnectivityStatus = { "action": "GET_CONNECTIVITY_STATUS", "data": { "serialNo": currentUnit.UNITID } };
                ws.send(JSON.stringify(deviceConnectivityStatus));

                let diagnosticSession = { "action": "GET_DIAGNOSTIC_SESSION", "data": { "serialNo": currentUnit.UNITID } };
                ws.send(JSON.stringify(diagnosticSession));
            }
        };
        // Web Socket - Error
        ws.onerror = (error) => {
            console.log('WebSocket error : ', error);
            reject(error);
        };

        // Web Socket - Recive Message
        ws.onmessage = (evt) => {
            var received_msg = evt.data;
            const { data, type = '' } = JSON.parse(received_msg);
            if (type === "CONNECTIVITY_STATUS") {
                if (data) {
                    dispatch(setUnitConnectionStatus(data?.state));
                } else {
                    dispatch(setUnitConnectionStatus(liveDiagnosticStates.Offline))
                }
            }
            if (type === "DIAGNOSTIC_SESSION") {
                if (counter <= 1) {
                    setMultiUser(data?.enabledBy ? data.enabledBy !== currentUser : false);
                    setCurrentConnection(data?.enabledBy ? data.enabledBy === currentUser : false)
                    dispatch(setDiagnosticConnectionStatus(data))
                } else {
                    counter = counter - 1;
                }
            }
            if (type === "DIAGNOSTIC_MODE_STATUS") {
                if (diagnosticStatus.status === 'successful' && data.status === 'failed') {
                    console.log("ignore this mode status")
                } else {
                    const newData = { ...diagnosticRef.current, ...data };
                    if(newData.status === "timeout"){
                        setCurrentConnection(false);
                    }
                    if (newData.enableStatusAt && newData.enabledBy) {
                        if (counter <= 1) {
                            dispatch(setDiagnosticConnectionStatus(newData))
                        } else {
                            counter = counter - 1;
                        }
                    } else {
                        let diagnosticSession = { "action": "GET_DIAGNOSTIC_SESSION", "data": { "serialNo": currentUnit.UNITID } };
                        ws.send(JSON.stringify(diagnosticSession));
                    }
                }
            }

            if (type === "DIAGNOSTIC_DATA") {
                dispatch(setLiveMonitoringStreamData(data))
            }
        };

        // Web Socket - Close Connection
        ws.onclose = (results) => {
            console.log('WebSocket CLOSE CONNECTION RESULTS', results);
            resolve('CLOSE CONNECTION', results);
            if (results?.code === 1001) {
                webSocketConnection();
            }
        };
    })

    const getLiveMonitoringData = async (startTime, endTime) => {
        const unitBrandId = KCStore.getItem('unitBrandId');
        const configForMonitoring = liveDiagnosticDataConf[unitBrandId];
        const id = configForMonitoring.id;
        const url = configForMonitoring.url;
        const configForMonitoringCompilerData = {
            data: {
                serialNo: currentUnit.UNITID,
                startTime,
                endTime
            }
        }
        // Get Compiled Configuration
        const compiledConfiguration = await compileConfiguration(configForMonitoring, configForMonitoringCompilerData);
        try {
            // Call Common Widget API
            const monitoringData = await invokePostMethod(url, { id: id, configuration: compiledConfiguration.configuration });
            dispatch(setLiveMonitoringData(monitoringData.data.data.data.liveData))
        } catch (error) {
            dispatch(setLiveMonitoringData([]))
        }
    }

    const establishConnectionAction = (state) => {
        if(state === liveDiagnosticAction.Enable){
            setCurrentConnection(true);
        }
        setCurrentConnection(true);
        dispatch(establishDiagnosticConnection(state, liveDiagnosticDuration, currentUnit)).then((res) => {
            if (state === liveDiagnosticAction.Enable) {
                if (res?.data) {
                    dispatch(setDiagnosticConnectionStatus(res.data));
                    dispatch(setPopupStatus(liveDiagnosticStates.EstablishConnection));
                    dispatch(setModalStatus(true));
                    getLiveMonitoringData(moment.utc(res.data.enableRequestAt).format(panDateFormat), moment.utc(res.data.enableRequestAt).add('minutes', 5).format(panDateFormat));
                } else {
                    dispatch(setPopupStatus(liveDiagnosticStates.SessionFailure));
                    dispatch(setModalStatus(true));
                }
            }
        }).catch((err) => {
            dispatch(setPopupStatus(liveDiagnosticStates.SessionFailure));
            dispatch(setModalStatus(true));
        })
        if (state === liveDiagnosticAction.Disable) {
            // disable state
            dispatch(setPopupStatus(unitStatus));
            dispatch(setModalStatus(true));
        }
    }

    const endLiveDiagnostic = () => {
        establishConnectionAction(liveDiagnosticAction.Disable);
    }

    const startLiveDiagnostic = () => {
        counter = counter + 1;
        dispatch(setPopupStatus(liveDiagnosticStates.EstablishConnection));
        dispatch(setModalStatus(true));
        establishConnectionAction(liveDiagnosticAction.Enable);
    }

    const extendLiveDiagnostic = () => {
        counter = 1;
        dispatch(setPopupStatus(liveDiagnosticStates.EstablishConnection));
        dispatch(setModalStatus(true));
        establishConnectionAction(liveDiagnosticAction.Enable);
    }

    const UnitOfflineStatus = () => {
        return (
            <React.Fragment>
                <div className='kc-ui-custom-unit-offline-status'>
                    <div className="custom-cancel-div">
                        <img className='custom-cancel-image' onClick={() => {
                        dispatch(setModalStatus(false))
                        dispatch(setPopupStatus(""))
                    }}  src={require("../../images/icons/cancel.png")} alt="Cancel_button" /><br></br>
                    </div>
                    <img className='kc-ui-custom-unit-offline-image' src={require('../../images/icons/liveMonitoringOffline.svg').default} />
                    <h3><FormattedMessage id={'KC2393'} /></h3>
                    <p><FormattedMessage id={'KC2394'} /></p>
                </div>
            </React.Fragment>
        )
    }


    const UnitOnlineStatus = () => {
        return (
            <React.Fragment>
                <div className='kc-ui-custom-unit-status'>
                    <div>
                        <img className='kc-ui-custom-unit-live-monitor-image' src={require('../../images/icons/liveMonitoringStart.svg').default} />
                        <h3><FormattedMessage id={'KC2392'} /></h3>
                        <p><FormattedMessage id={'KC2403'} /></p>
                        <button className='btn kc-ui-custom-button' type="button" onClick={startLiveDiagnostic}><FormattedMessage id='KC0644' /></button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const EstablishConnection = () => {
        return (
            <React.Fragment>
                <div className='kc-ui-custom-unit-status'>
                    <div>
                        <div className='kc-ui-flex'>
                            <img className='kc-ui-custom-unit-image kc-ui-custom-inline' src={require('../../images/icons/establishConnectionComputer.svg').default} />
                            <div class="progress kc-ui-custom-connection-progress kc-ui-custom-inline">
                                <BarLoader
                                    color="#ffbe00"
                                    cssOverride={{
                                        borderRadius: 10
                                    }}
                                    height={10}
                                    speedMultiplier={1}
                                />
                            </div>
                            <img className='kc-ui-custom-unit-image kc-ui-custom-inline' src={require('../../images/icons/establishConnectionDevice.svg').default} />
                        </div>
                        <h3><FormattedMessage id={'KC2395'} /></h3>
                        <p><FormattedMessage id={'KC2404'} /></p>
                        <button className='btn kc-ui-custom-button' type="button" onClick={endLiveDiagnostic}><FormattedMessage id='KC0021' /></button>
                    </div>
                    
                </div>
            </React.Fragment>
        )
    }

    const SessionWarning = () => {
        return (
            <div className='kc-ui-custom-unit-session-warninig-status'>
                <div className='custom-cancel-div'>
                    <img className='custom-cancel-image' onClick={() => {
                        dispatch(setModalStatus(false))
                        dispatch(setPopupStatus(liveDiagnosticStates.SessionWarningNoPopup))
                    }} src={require('../../images/icons/cancel.png')} />
                </div>
                <div>
                    <img className='kc-ui-custom-unit-liveMonitoringExpired-image' src={require('../../images/icons/liveMonitoringExpired.svg').default} /><br></br>
                    <h5><FormattedMessage id={'KC2405'} /></h5>
                    <p className='kc-ui-custom-establish-bottom'><FormattedMessage id={'KC2406'} /></p>
                    <p className='kc-ui-custom-establish-doube-text'><FormattedMessage id={'KC2407'} /></p>
                    <p className='kc-ui-custom-establish-doube-text-bottom'><FormattedMessage id={'KC2408'} values={{ time: liveDiagnosticDurationInMinutes }} /></p>
                </div>
                <button className='btn kc-ui-custom-button' type="button" onClick={extendLiveDiagnostic}><FormattedMessage id='KC2398' values={{ time: liveDiagnosticDurationInMinutes }} /></button>
            </div>
        )
    }

    const SessionFailure = () => {
        return (
            <React.Fragment>
                <div className='kc-ui-custom-unit-status'>
                    <div>
                        <img className='kc-ui-custom-unit-image' src={require('../../images/icons/liveMonitoringSessionFailure.svg').default} />
                        <h3><FormattedMessage id={'KC2399'} /></h3>
                        <p><FormattedMessage id={'KC2400'} /></p>
                    </div>
                    <button className='btn kc-ui-custom-button' type="button" onClick={extendLiveDiagnostic}><FormattedMessage id='KC1117' /></button>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div id="live-diagnostic" className={`dashboard-renderer dashboard-renderer-span6`}>
                <div className='gridView'>
                    <div className='colmDi kc-ui-flex'>
                        <h5 className='kc-ui-custom-live-header-text'><FormattedMessage id='KC2392' /></h5>
                        {(loading || popupStatus === liveDiagnosticStates.Offline) ? null : (popupStatus === liveDiagnosticStates.SessionWarning || popupStatus === liveDiagnosticStates.SessionWarningNoPopup) ?
                            <div className='kc-ui-custom-live-warning-header'><img className='kc-ui-custom-live-session-image' src={require('../../images/icons/sessionExpired.svg').default} />   <FormattedMessage id='KC2410' />    <p onClick={extendLiveDiagnostic} className='kc-ui-custom-restart'><FormattedMessage id='KC2411' /></p></div> :
                            popupStatus === liveDiagnosticStates.SessionSuccess ? isMultiUser ? <Tooltip background='#D9D9D9' content={(
                                <div className='kc-ui-flex'>
                                    <img className='kc-ui-custom-tooltip-image' src={require('../../images/icons/multi-user.svg').default} />
                                    <h6 className='kc-ui-custom-tooltip-text'><FormattedMessage id='KC2396' /></h6>
                                </div>
                            )} direction="down" className="target"><div className={'kc-ui-custom-multi-user-live'}><img className='kc-ui-custom-live-session-image' src={require('../../images/icons/sessionActive.svg').default} />   <FormattedMessage id='KC2409' /> :  <span id="runningMultiTimer">{liveDiagnosticTimer(liveDiagnosticDuration)}</span></div></Tooltip> : <div className={'kc-ui-custom-live-header'}><img className='kc-ui-custom-live-session-image' src={require('../../images/icons/sessionActive.svg').default} />   <FormattedMessage id='KC2409' /> : <span id="runningTimer">{liveDiagnosticTimer(liveDiagnosticDuration)}</span></div> : null}
                    </div>
                </div>
            </div>
            <div id="live-diagnostic-chart" className={`dashboard-renderer dashboard-renderer-span6`}>
                <React.Fragment>
                    <div className='kc-ui-custom-live-diagnostic-grid'>
                        <div className='kc-ui-custom-live-diagnostic'>
                            <div className='gridView'>
                                <div className='colm12D'>
                                    {loading ?
                                        <div className='render-part live-render-part'>
                                            <Loader loader={loading} />
                                        </div>
                                        : <div className='render-part live-render-part-chart'>
                                            {!loading ? <LiveDiagnosticRenderer currentUnit={currentUnit} /> : null}
                                        </div>
                                    }
                                </div>
                            </div>
                            <LiveDiagnosticModal show={!loading && modal && popupStatus !== ''} status={popupStatus}>
                                <div className={`kc-ui-custom-modal kc-ui-custom-modal-${popupStatus}`}>
                                    {popupStatus === liveDiagnosticStates.Online ? <UnitOnlineStatus />
                                        : popupStatus === liveDiagnosticStates.Offline ? <UnitOfflineStatus />
                                            : popupStatus === liveDiagnosticStates.EstablishConnection ? <EstablishConnection />
                                                : popupStatus === liveDiagnosticStates.SessionFailure ? <SessionFailure />
                                                    : popupStatus === liveDiagnosticStates.SessionWarning ? <SessionWarning /> : ''}
                                </div>
                            </LiveDiagnosticModal>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch,) {
    return bindActionCreators({
        getUnitStatus,
        setModalStatus,
        establishDiagnosticConnection
    }, dispatch);
}

function mapStateToProps(state,) {
    return {
        liveDiagnostic: state.liveDiagnostic
    }
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LiveDiagnostic))