import { SET_UPDATE_HISTORY_DETAILS, SET_UPDATE_HISTORY_DETAILS_LOADING} from '../../../actions/stateActionsData/type';

const initialState = {
    unitHistory: null,
    isLoading: false
}

export default function(state = initialState, action){
    switch(action.type) {
        case SET_UPDATE_HISTORY_DETAILS:
        return {
            ...state,
            unitHistory: action.payload
        }
        case SET_UPDATE_HISTORY_DETAILS_LOADING:
        return {
            ...state,
            isLoading: action.payload
        }
        default:
        return state;
    }
}