import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NumberFormat from 'react-number-format';
import { format, addDays } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { getFilterCleaningsData } from '../../../actions/unitDashboard/filterCleaningsAction';
import { UNIT_ID, UNIT_BRAND_ID } from '../../../utils/appConstants';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';
import KCStore from '../../../utils/kcStorage';


var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
class FilterCleanings extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            sd: '',
            ed: '',
            startDate: '',
            endDate: '',
            unitTime: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            datapickflag:false
        }

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount() {
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        if (KCStore.getItem("brandName") === "MERRYCHEF") {
            let widgetID = 11;
            this.props.getFilterCleaningsData("Month", todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID);
        }
        else {
            let widgetID = 54;
            // added for Frymaster McD Filteration
            
            const productName = KCStore.getItem("modelFamilyName");
            this.props.getFilterCleaningsData("Month", todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID, productName);
        }
    }
    componentDidUpdate = async (prevProp, prevState) => { 
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleFiltercleaning(this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStart(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //Total Drinks Produced
    handleChangeStart(date) {
        if(this.state.startDate === date){
            return;
        }
        this.setState({
            startDate: date,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleFiltercleaning(selectedtext);
        });
    }

    handleChangeEnd(date) {
        if(this.state.endDate === date){
            return;
        }
        this.setState({ endDate: date,datapickflag:true }
            ,() => {
                let selectedtext = 'Date Range';
                this.handleFiltercleaning(selectedtext);
            });
    }

    onDropDownHandler(e) {
        const { nodeText: text } = e;
        this.setState({ type: text, datapickflag: text === 'Date Range', startDate: '', endDate: '' });
        this.handleFiltercleaning(text);
    }

    handleFiltercleaning(text) {
        let storageBrandName = KCStore.getItem("brandName");
        let widgetID = (storageBrandName === "MERRYCHEF") ? 11 : 54;
        if (widgetID === 54) {
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
            } else {
                todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
                weekDateFormatted = format(addDays(todaysDate,- 6), 'YYYY-MM-DD');
            }
        }
        else {
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
            } else if (text === 'Week') {
                weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
                todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
    
                var startDateMonth = getMonth(new Date(weekDateFormatted));
                var endDateMonth = getMonth(new Date(todaysDateFormatted));
    
                if (startDateMonth !== endDateMonth)
                {
                    weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
                }
            } else {
                todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
                weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
            }
        }

        if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            const productName = KCStore.getItem("modelFamilyName");
            this.props.getFilterCleaningsData(text, todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID, productName);
        }
    }

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (KCStore.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

    render() {
        const response = this.props.filterCleaningsDataInfo;
        let currentValue = null;
        let priorValue = null;
        let trend = 0;
        let unitTime = '';

        currentValue = response.filterCleaningsData.content !== undefined ? response.filterCleaningsData.content[0].value : 0;
        priorValue = response.filterCleaningsData.content !== undefined && response.filterCleaningsData.content.length > 1 ? response.filterCleaningsData.content[1].value : 0;
        trend = response.filterCleaningsData.content !== undefined && response.filterCleaningsData.content.length > 2 ? response.filterCleaningsData.content[2].value : 0;
        unitTime =  response.filterCleaningsData && response.filterCleaningsData.unitTime ? response.filterCleaningsData.unitTime.toLowerCase() : '';        
        if (KCStore.getItem("timeFormat") === "24"){
            unitTime = unitTime ? moment(unitTime, 'hh:mm A').format('HH:mm'):"";
        }
        return (
            <React.Fragment>
                <div className="colm4D">
                    <div className="colmDi">
                        <h4>{ this.props.widgetnameStringID ? <FormattedMessage id={this.props.widgetnameStringID}/> : this.props.widgetname}
                            &nbsp; <span className="red-txt"></span><span className="cornerIcon"></span>
                            <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.type} />
                        </h4>
                        <div className={this.props.widgetname === "FILTER CLEANINGS" ? "colmDiVal minheight155" : "colmDiVal"}>
                            <div className="valH1Div">{this.returnNumberFormat(Math.round(currentValue))} <span className={this.state.type === "Date Range" ? "displaynone" : Math.round(currentValue) === Math.round(priorValue) ? "valH1Span" : trend === '1' ? "valH1Span valHigh" : trend === '0' ? "valH1Span valLow" : "valH1Span"}>{unitTime}&nbsp;</span></div>
                            <div className={this.state.type === "Date Range" ? "displaynone" : "valH5Div"}>
                                { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }: {this.returnNumberFormat(Math.round(priorValue))}                         
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        filterCleaningsDataInfo: state.filterCleaningsData,
        currentLocation: state.entityReducer.currentLocation,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getFilterCleaningsData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterCleanings);
