import {CUSTOMER_REQUEST,CUSTOMER_SUCCESS,CUSTOMER_FAIL} from '../../../../actions/listView/locations/customerAction';

const initialState = {
    loaded: false,
    loading: false,
    customerInformation: { }
    
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case CUSTOMER_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                isServerActionComplete : action.isCascadeActionRequest
               
            }
        case CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                customerInformation: action.customerInformation,
                isServerActionComplete : true
            }
        case CUSTOMER_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
                isServerActionComplete : true
            }
        default:
            return state
    }
}