import React from "react";
import AddToHomescreen from 'react-add-to-homescreen';

const AddToHomeScreenModal = (props) => {

    // let { addToHome } = props;
   
    // addToHome.handleAddToHomescreenClick = () => {
    //    console.log("Heloooo");
    //   };

    return (
                <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="AddtoHomeScreenModal" tabIndex="-1" role="dialog" aria-labelledby="AddtoHomeScreenModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="addtoHomeScreenDiv">
									Add Welbilt to Home Screen <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                                    <AddToHomescreen title="Add Welbilt to Home Screen" icon="Add Welbilt to Home Screen" />
								</div>
							</div>
						</div>
					</div>
				</div>
    )
}
export default AddToHomeScreenModal;