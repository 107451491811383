
import { dashboardRenderer } from '../../service/api';
import http from '../../service/httpService';


export const establishLiveViewConnectionEvent = (payload) => {
    return new Promise((resolve, rej) => {
        http.post(dashboardRenderer.liveViewModeEventAction, payload).then(response => {
            resolve(response);
        }).catch(err => {
            rej(err);
        });
    })
}

export const getLiveViewConnectionDetails = (SerialNo) => {
    return new Promise((resolve, rej) => {
        http.get(dashboardRenderer.diagnosticDeviceStatus + SerialNo, {
            headers: {
                'Content-Type': 'application/json',
            },
            data: {}
        }).then(diagnosticResponse => {
            resolve(diagnosticResponse);
        }).catch(err => {
            rej(err);
        });
    })
}