import React, { Component, PureComponent } from 'react';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { FormattedMessage } from 'react-intl';
import { reactIntl } from '../../../locale/locale-provider';

import KCStore from '../../../utils/kcStorage';

var moment = require('moment');
am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);
var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let unitID = "";
let currentUnit;

class PeakHoursLineChart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			peakHourChartInformation: [],
			total_unit_count: 0,
			loading: true,
			filterType: "Month",
			peakhour: 0,
			sd: '',
			ed: '',
			startDate: '',
			endDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			datapickflag: false
		}
		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount() {
		let chart = am4core.create("peakHourChart", am4charts.XYChart);
		this.getReportData(this.state.filterType, "", "");
	}

	toggleDatePicker(e) {
		this.setState({ filterType: "Date Range" })
	}

	handleChangeStart(date) {
		this.setState({
			startDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownHandler(e);
		});
	}

	handleChangeEnd(date) {
		this.setState({
			endDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownHandler(e);
		});
	}

	getReportData(text, startDate, date) {
		const header = this.getHeaders(text, startDate, date);
		let url = `${ReportManagement.widgetAPI + '/21/1'}`;
		this.getData(url, header).then(response => {
			let _peakhour = this.findHighestValue(response.peakHourChartInformation);
			this.setState({
				peakhour: _peakhour
			})
			this.renderChartData(response.peakHourChartInformation);
		}).catch(err => {
			//this.renderChartData(err);
		});
	}

	onDropDownHandler(e) {
		const { nodeText:text } = e;
		this.setState({datapickflag: text === 'Date Range'})
		this.handlePeakHourLine(text);
	}

	handlePeakHourLine(text) {
		const { startDate, endDate } = this.state;
		this.setState({ filterType: text })
		if (text === "Date Range") {
			if (startDate !== "" && endDate !== "") {
				this.getReportData(text, startDate, endDate);
			}
		}
		else {
			this.getReportData(text, "", "");
		}
	}

	getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '') {
		currentUnit = this.props.properties.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;

		let filterType;
		switch (type) {
			case 'Week':
				filterType = 'weekly';
				break;
			case 'Year':
				filterType = 'yearly';
				break;
			case 'Day':
				filterType = 'daily';
				break;
			case 'Quarter':
				filterType = 'quarterly';
				break;
			case 'Date Range':
				filterType = 'custom';
				break;
			default:
				filterType = 'monthly';
		}

		let header;

		if (filterType === 'custom' || filterType === 'weekly') {
			if (type === 'Week') {
				startDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
				endDateFormatted = format(todaysDate, 'YYYY-MM-DD');

				var startDateMonth = getMonth(new Date(startDateFormatted));
				var endDateMonth = getMonth(new Date(endDateFormatted));

				if (startDateMonth !== endDateMonth) {
					startDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
				}
			}
			else {
				startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');;
				endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');;
			}

			header = {
				'Content-Type': 'application/json',
				'unitid': unitID,
				'startdate': startDateFormatted,
				'enddate': endDateFormatted,
				'filterType': filterType,
				'showtrend': "false"
			};
		}
		else {
			startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			header = {
				'Content-Type': 'application/json',
				'unitid': unitID,
				'reqdate': startDateFormatted,
				'filterType': filterType,
				'showtrend': "false"
			};
		}

		return header;
	}


	getData(url, header) {

		return new Promise((resolve, reject) => {
			http
				.get(url,
					{
						headers: header,
						data: {}
					})
				.then(response => {
					resolve({
						peakHourChartInformation: response.data.peakHoursChart
					})
				})
				.catch(error => {
					reject({
						peakHourChartInformation: []
					})
				});
		});
	}

	findHighestValue = (data) => {
		let maxTotalCount = 0;
		let peakhour = 0;

		let chartData = data && data.length > 0 ? data : [];
		let separator = KCStore.getItem("thousandSeparator");
		//Find maximum count
		for (let index = 0; index < chartData.length; index++) {
			if (maxTotalCount < chartData[index].totalcount) {
				maxTotalCount = chartData[index].totalcount;
			}
			// Tooltip to show data with . as thousand separator
			let tooltipTotalCount = chartData[index].totalcount;
			chartData[index].tooltipNum = separator === "." ? tooltipTotalCount.toString().replace(',', separator) : tooltipTotalCount;
		}

		if (maxTotalCount) {
			for (let index = 0; index < chartData.length; index++) {
				if (chartData[index].totalcount === maxTotalCount) {
					chartData[index].fillOpacity = 1;
					chartData[index].color = "#00a4f2";
					chartData[index].stroke = "#00a4f2";
					peakhour = chartData[index].hours;
				} else {
					chartData[index].fillOpacity = 1;
					chartData[index].color = "#006b94";
					chartData[index].stroke = "#006b94";
				}
			}
		}
		return peakhour;
	}

	renderChartData = (data) => {

		let response = data && data.length > 0 ? data : [];

		if (response.length > 0) {
			if (KCStore.getItem("timeFormat") === "24"){
				response.map(item => { item.hours = ( item.hours === "24" ? "00" : item.hours); });
			} else {
				response.map(item => { item.hours = (item.hours === "12" ? "12 pm" : item.hours === "24" ? "12 am" : item.hours > 12 ? item.hours - 12 + " pm" : item.hours + " am"); });
			}
		}
		else {
			response = [];
		}

		if (response && response.length > 0) {
			let chart = am4core.create("peakHourChart", am4charts.XYChart);
			chart.data = response;
			this.chart = chart;
			chart.responsive.enabled = true;
			// Create axes
			let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = "hours";
			categoryAxis.renderer.labels.template.fill = "#fefeff";
			categoryAxis.fixedWidthGrid = true;
			categoryAxis.fontSize = 11;
			categoryAxis.fontFamily = "open_sanslight";
			categoryAxis.renderer.line.strokeOpacity = 1;
			categoryAxis.renderer.line.strokeWidth = 1;
			categoryAxis.renderer.line.stroke = am4core.color("#7b7c7f");
			categoryAxis.renderer.autoGridCount = false;
			categoryAxis.renderer.gridCount = 24;
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.minGridDistance = 30;
			categoryAxis.renderer.grid.template.strokeWidth = 0;

			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.minGridDistance = 100;
			valueAxis.renderer.grid.template.stroke = am4core.color("#434549");
			valueAxis.renderer.grid.template.strokeWidth = 1;
			valueAxis.renderer.grid.template.strokeOpacity = 1;
			valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");
			valueAxis.renderer.line.strokeOpacity = 1;
			valueAxis.renderer.line.strokeWidth = 1;
			valueAxis.renderer.line.stroke = am4core.color("#7b7c7f");
			valueAxis.renderer.labels.template.fontSize = 11;
			valueAxis.renderer.labels.template.fontFamily = "open_sanslight";

			var series2 = chart.series.push(new am4charts.LineSeries());
			series2.stroke = am4core.color("#418bc6");
			series2.strokeWidth = 1;
			series2.dataFields.valueY = "totalcount";
			series2.dataFields.categoryX = "hours";
			series2.dataFields.tootltipY = "tooltipNum";
			series2.tooltip.background.fill = am4core.color("#fff");
			series2.tooltipText = `[bold]{tooltipNum} ${reactIntl.formatMessage({ id: 'KC0176' })} [/]`; //TBD  
			series2.tooltip.autoTextColor = false;
			series2.tooltip.label.fill = am4core.color("#4eabf8");
			series2.tooltip.filters.clear();
			series2.tooltip.getFillFromObject = false;
			series2.tooltip.background.fill = am4core.color("#fff");
			series2.tooltip.label.adapter.add("textOutput", function (text, target) {
				if (target.dataItem.dataContext && target.dataItem.dataContext.tooltipNum) {
					return `[bold]${target.dataItem.dataContext.tooltipNum} ${reactIntl.formatMessage({ id: 'KC0176' })} [/]`
				}else {
					return ''
				}
			  });

			var bullet2 = series2.bullets.push(new am4charts.CircleBullet());
			series2.heatRules.push({
				target: bullet2.circle,
				min: 5,
				max: 20,
				property: "radius"
			});
			bullet2.circle.fill = am4core.color("#fff");
			bullet2.circle.stroke = am4core.color("#4eabf8");
			bullet2.circle.strokeWidth = 2;
			bullet2.circle.radius = 3;
			//bullet2.tooltipText = "{count}[/] products";

			/* Add chart cursor */
			chart.cursor = new am4charts.XYCursor();
			chart.cursor.xAxis = categoryAxis;
			chart.cursor.fullWidthLineX = true;
			chart.cursor.lineX.strokeWidth = 0;
			chart.cursor.lineX.fill = am4core.color("#4eabf8");
			chart.cursor.lineX.fillOpacity = 0.1;
		}
		else {
			if (this.chart) {
				this.chart.data = response;
			}
		}

	}

	componentWillUnmount = () => {
		if (this.chart) {
			this.chart.dispose();
		}
	}

	render() {
		let { peakhour } = this.state;
		let newTimeFormat =  <div class="peakhourData">{this.state.peakhour > 12 ? this.state.peakhour - 12 + ":00" : this.state.peakhour + ":00"}<span className="valH1Span">{this.state.peakhour === "24" ? <FormattedMessage id="KC1883"/> : this.state.peakhour >= 12 ? <FormattedMessage id="KC1884"/> : <FormattedMessage id="KC1883"/>}</span></div>  
		if (KCStore.getItem("timeFormat") === "24"){
			newTimeFormat =  <div className="peakhourData"> {peakhour === 24 ? "00:00" : peakhour + ":00"}</div>
		}
		return (
			<div class="gridViewTable">
				<div class="gridView">
					<div class="colm12D">
						<div className="colmDi">
							<h4><FormattedMessage id="KC0042"/>
								<NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.filterType} />
							</h4>
							{newTimeFormat};							
							<div id="peakHourChart"></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PeakHoursLineChart;
