import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SuccessMoal from '../../../../modal/success-model';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../controls/modelFamilyList';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../../../utils/appConstants';
import  LibraryListPopup  from '../../libraryListPopup';
import _ from 'lodash';
import http from '../../../../../service/httpService';
import { MenuUploadAPI, organizationNode } from '../../../../../service/api';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { UserProfile } from '../../../../../service/api';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import ErrorMoal from '../../../../modal/error-model';
import $ from 'jquery';
import KCStore from '../../../../../utils/kcStorage';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

var AdmZip = require('adm-zip');
var request = require('request');
var beautify = require("json-beautify");
var LZString = require('lz-string');

class FrymasterSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            selectedModel: 0,
            isChanged: false,
            showDiscardPopup: false,
            settings_name: '',
            errorSettingsName: '',
            nodeCaptionDetails: "",
            selectedUnitDetails: "",
            modalSettingValue: 'Save',
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.OnImport = this.OnImport.bind(this);
        this.onSettingsNameChange = this.onSettingsNameChange.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let libraryData = JSON.parse(KCStore.getItem("libraryData"));
            let defaultLibraryDetails = "";
            try {
                defaultLibraryDetails = JSON.parse(KCStore.getItem("defaultLibraryData"));
            }
            catch (err) {
                defaultLibraryDetails = JSON.parse(LZString.decompress(KCStore.getItem("defaultLibraryData")));
            }
            let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
            this.setState({
                settings_name: libraryDetails.settingName,
                selectedModel: parseInt(KCStore.getItem("selectedProductID"))
            })
            if (!_.isEqual(defaultLibraryDetails, libraryData)) {
                this.setState({
                    isChanged: true
                })
            }
            if (this.props.history.location.state.getSelectedDynasty) {
                let node = this.props.history.location.state.getSelectedDynasty;
                let selectedUnitDetails = this.props.history.location.state.getSelectedUnitDetails;
                await this.getNodeCaptionDetails(node, selectedUnitDetails);
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    getNodeCaptionDetails = async (val, val1) => {
        this.setState({ loaderIconVisible: true });
        const url = organizationNode.nodeDetails + "96";

        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'cid': KCStore.getItem("customerId"),
                'dynasty': val,
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                let result = response.data;
                let nodeDetails;
                result.map((item, i) => {
                    if (nodeDetails) {
                        if ((result.length - 1) === i) {
                            nodeDetails = nodeDetails + item.node_name;
                        } else {
                            nodeDetails = nodeDetails + item.node_name + "/";
                        }
                    } else {
                        nodeDetails = item.node_name + "/";
                    }
                })

                this.setState({
                    loaderIconVisible: false,
                    nodeCaptionDetails: nodeDetails,
                    selectedUnitDetails: val1,
                });
                // await this.getThingDetails();
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    selectTestDeleteButtonClick = () => {
        this.setState({
            nodeCaptionDetails: null,
            selectedUnitDetails: {},
        });
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            errorSettingsName: ''
        })
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        let path = "/frymasterRecipeEditor";
        if (libraryDetails.isEdit) {
            path = "/libraryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/frymasterSettings"
        });
    }

    onSaveButtonClick = () => {

        let settingsName = this.state.settings_name.trim();
        if (settingsName.length < 4 || settingsName.length > 20) {
            this.setState({
                errorSettingsName: "KC1265"
            })
        }
        else {
            let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
            libraryDetails.settingName = settingsName;
            KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
            if (this.state.modalSettingValue === "Test") {
                this.props.history.push({
                    pathname: "/recipeAssignTopology",
                    previouspath: "/frymasterSettings",
                });
                return;
            }
            this.props.history.push({
                pathname: "/assignFrymasterSettingsToTopology",
                isEdit: false,
                previouspath: "/frymasterSettings"
            });
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSettingsClick = (pathname) => {
        this.props.history.push({
            pathname,
            isEdit: false,
            type: "Settings",
            previouspath: "/frymasterSettings"
        });
    }

    onBackNavigation = () => {
        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
        if (!this.state.isChanged) {
            let path = "/frymasterRecipeEditor";
            if (libraryDetails.isEdit) {
                path = "/libraryList";
            }
            this.props.history.push({
                pathname: path,
                isEdit: false,
                previouspath: "/settingsEditor"
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }

    async OnImport(item) {
        this.setState({
            loaderIconVisible: true
        });
        await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
            headers: {
                'Content-Type': 'application/json',
                'library_id': item.LIBRARY_ID,
                'library_isedit': true
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                let file_path = response.data.presignedURL;
                var libraryFiles = [];
                var b64 = "";
                var imageBuffer = null;
                KCStore.setItem("selectedGroupID", item.CGID);
                try {
                    request.get({ url: file_path, encoding: null }, (err, res, body) => {
                        var zip = new AdmZip(body);
                        var zipEntries = zip.getEntries();

                        zipEntries.forEach((entry, i) => {
                            libraryFiles[i] = zip.readFile(entry);

                            if (!entry.entryName.includes('.json')) {
                                imageBuffer = Buffer.from(libraryFiles[i]);
                                b64 = imageBuffer.toString('base64');
                            }
                        });

                        KCStore.setItem("selectedFamilyID", item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : '');
                        let libraryData = [];
                        libraryData = JSON.parse(libraryFiles[0]);
                        KCStore.setItem("defaultLibraryData", JSON.stringify(libraryData));
                        libraryData.Import = true;
                        KCStore.setItem("libraryData", JSON.stringify(libraryData));
                        let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
                        let md5 = require('md5');
                        let libraryDetails = {};
                        libraryDetails.id = UpdatedJSONObj.id;
                        libraryDetails.version = UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.'));
                        libraryDetails.imagefileName = item.LIBRARY_NAME + "." + item.IMAGE_FILE_FORMAT;
                        libraryDetails.imagefileext = item.IMAGE_FILE_FORMAT;
                        libraryDetails.imagefileNamewithOutExt = item.LIBRARY_NAME;
                        libraryDetails.imagefileSize = item.IMAGE_FILE_SIZE;
                        libraryDetails.imagefilelength = item.LIBRARY_NAME.length;
                        libraryDetails.library_id = item.LIBRARY_ID;
                        libraryDetails.settingName = item.LIBRARY_NAME;
                        libraryDetails.crc = md5(b64, "base64");
                        libraryDetails.isEdit = false;
                        libraryDetails.isImageChanged = true;
                        libraryDetails.initialMethod = "Add";

                        KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));

                        this.setState({
                            pathname: "/frymasterSettings",
                            previouspath: "/libraryList",
                            loaderIconVisible: false,
                            isChanged: true
                        });
                    });
                }
                catch (ex) {
                    this.setState({
                        loaderIconVisible: false
                    });
                }
            }
        })
            .catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
    }

    onSettingsNameChange = (e) => {
        if (e.target.value.match(/^[a-zA-Z0-9 ]*$/)) {
            this.setState({
                settings_name: e.target.value,
                errorSettingsName: ""
            });
        }
        else {
            this.setState({
                errorSettingsName: "KC1266"
            });
        }
    }

    selectTestUnitButtonClick = (e) => {
        e.preventDefault();
        let _this = this;
        let httpMethod;
        _this.setState({
            loaderIconVisible: true
        })
        let brandName = KCStore.getItem("brandName");
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        http({
            method: "GET",
            url: UserProfile.getUserProfileDetails,
            headers: {
                'customer_id': CUSTOMER_ID,
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': KCStore.getItem("basecountry"),
                'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
            }, data: {}
        }).then(async response => {
            let libraryId;
            let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
            if (libraryDetails.isEdit) {
                httpMethod = "PUT";
                libraryId = libraryDetails.library_id;
            } else {
                httpMethod = "POST";
                libraryId = libraryDetails.settingName + '_' + Date.now();
                libraryId = libraryId.replace(/\s+/g, '');
                libraryDetails.library_id = libraryId;
                KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
            }

            let libraryData = KCStore.getItem("libraryData");
            let libraryDataObj = JSON.parse(libraryData);
            libraryDataObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
            libraryDataObj.version = libraryDetails.isEdit ? parseInt(libraryDetails.version) + 1 + ".0.0" : "1.0.0";
            libraryDataObj.edit_date = todaysDateUTCFormatted;
            libraryDataObj.author = response.data.name;
            libraryDataObj.email = loginUser.username;
            delete libraryDataObj.Import;
            // convert JSON object to beautify String
            var jsonStr = beautify(libraryDataObj, null, 2, 80)
            // read json string to Buffer
            const jsonBuffer = Buffer.from(jsonStr);

            let fileSize = Buffer.byteLength(jsonBuffer);
            fileSize = (fileSize / 1048576);

            let md5 = require('md5');
            let crc = md5(jsonBuffer, "base64");
            let libraryPostData = {
                "customerId": CUSTOMER_ID,
                "brandId": BRAND_ID_VALUE[brandName],
                "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                "libraryId": libraryId,
                "libraryName": libraryDetails.settingName,
                "libraryFileName": libraryId + ".json",
                "libraryFileFormat": "json",
                "libraryFileSize": fileSize.toFixed(2),
                "libraryS3Url": "",
                "type": "Settings",
                "createdUser": response.data.name + ' (' + loggedinUser + ')',
                "crc": crc,
                "baseCountry": BASECOUNTRY_ID,
                "imageFileName": "",
                "imageFileFormat": "",
                "imageFileSize": "",
                "imageS3Url": "",
                "cgids": [parseInt(KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : 0)],
                "json": JSON.stringify(libraryDataObj),
                "settings": [],
                "libraryGuid": libraryDataObj.id
            };

            if (libraryDetails.isEdit) {
                delete libraryPostData.customerId;
                delete libraryPostData.createdUser;
                libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
            }
            else {
                libraryPostData.status = "DRAFT";
            }

            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': "",
                'jsonfilekey': jsonBuffer ? libraryId + '.json' : "",
                'audiofilekey': '',
                'isthumbnail': false
            }
            await uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(async response => {
                

                let { selectedUnitDetails } = this.state;

                const unitList = [];
                unitList.push(
                    {
                        "thingArn": selectedUnitDetails.THING,
                        "productId": selectedUnitDetails.ProductId,
                        "cloudUnitName": selectedUnitDetails.CLOUD_UNIT_NAME,
                        "unitTimeZone": selectedUnitDetails.TimeZone_Standard
                    }
                )

                let postData = {
                    "customer": CUSTOMER_ID,
                    "brand": brandName,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "user": loggedinUser,
                    "libraryIds": [libraryId],
                    "country": BASECOUNTRY_ID,
                    "type": "Recipe",
                    "thingList": unitList,
                    "userName": loggedinUser,
                    "isTestPush": true
                };

                let url = MenuUploadAPI.getLibraryUpdateStatus;
                await http.post(url, postData,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                    .then(response => {
                        let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
                        libraryDetails.isEdit = true;
                        KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
                        _this.setState({
                            loaderIconVisible: false,
                            isEdit: true,
                            success_modal: {
                                open: true,
                                message: "KC1227"
                            },
                            nodeCaptionDetails: null,
                            selectedUnitDetails: {},
                        });
                    }).catch(err => {
                        _this.showError(err);
                    });
            }).catch(err => {
                _this.showError(err);
            });
        }).catch(err => {
            _this.setState({
                loaderIconVisible: false
            })
        });
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            let { KC_ERR_400, KC_ERR_500, body } = data;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
    }


    render() {
        let  { error_modal, success_modal, loaderIconVisible, isChanged, showDiscardPopup, settings_name, errorSettingsName, selectedModel } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        let loginInfo = JSON.parse(KCStore.getItem("loginInfo"));
        const { formatMessage } = this.props.intl;
        return (
            <React.Fragment>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                {/* Frymaster Setting Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <Link to="#" title="Status" >Status</Link> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Settings Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../images/logos/frymasterW.png")} />
                            </div>

                            {/* Frymaster Settings Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1190"/>
                                </div>
                                {/* Frymaster Setting List Wrapper */}
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    {[
                                                        { title: "KC1975", imgPath: require("../../../../../images/recipecreation/rightArrow.png"), routePath: '/frymasterGeneralSettings' },
                                                        { title: "KC1445", imgPath: require("../../../../../images/recipecreation/rightArrow.png"), routePath: '/frymasterHeating' },
                                                        { title: "KC0453", imgPath: require("../../../../../images/recipecreation/rightArrow.png"), routePath: '/frymasterCooking' },
                                                        { title: "KC0442", imgPath: require("../../../../../images/recipecreation/rightArrow.png"), routePath: '/frymasterFiltration' },
                                                        { title: "KC0517", imgPath: require("../../../../../images/recipecreation/rightArrow.png"), routePath: '/frymasterOQS' },
                                                        { title: "KC1449", imgPath: require("../../../../../images/recipecreation/rightArrow.png"), routePath: '/frymasterUserAccess' },
                                                        { title: "KC2000", imgPath: require("../../../../../images/recipecreation/rightArrow.png"), routePath: '/frymasterEcoMode' }].map((item, index) => {
                                                            return (
                                                                <div key={index} class="rcSettingsList02">
                                                                    <ul onClick={() => { this.onSettingsClick(item.routePath) }}>
                                                                        <li><FormattedMessage id={item.title}/></li>
                                                                        <li><img src={item.imgPath} /></li>
                                                                    </ul>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" data-toggle="modal" data-target="#saveNameModal" onClick={() => this.setState({ modalSettingValue: 'Save' })} className={isChanged ? "btn btn-default-text saveButton" : "btn btn-default-text saveButton disabled"} title={formatMessage({ id: 'KC1275' })}>&nbsp;</button>
                                    <button type="button" data-toggle="modal" data-target="#saveNameModal" onClick={() => this.setState({ modalSettingValue: 'Test' })} className={loginInfo.rolename === "Public Admin" ? "displaynone" : isChanged ? "btn btn-default-text testButton" : "btn btn-default-text testButton disabled"} title={formatMessage({ id: 'KC1972' })}><FormattedMessage id="KC0698"/></button>
                                </div>
                            </div>
                            {/* Frymaster Settings Footer */}
                            <div class="footerText">Recipe, Cookbook and Settings Functions Only</div>
                            <div className={loginInfo.rolename === "Public Admin" ? "displaynone" : "testOnUnitTable"}>
                                <ul>
                                    <li><img src={require("../../../../../images/recipecreation/assetD.svg").default} /></li>
                                    <li>
                                        <input type="text" className="form-control" value={this.state.nodeCaptionDetails ? this.state.selectedUnitDetails.UNITID + "_" + this.state.selectedUnitDetails.BRAND_NAME + "     " + this.state.nodeCaptionDetails : ""} placeholder={formatMessage({ id: 'KC1276' })}></input>
                                    </li>
                                    <li>
                                        <button type="button" className="btn btn-default-text deleteButton" onClick={() => { this.selectTestDeleteButtonClick() }}>&nbsp;</button>
                                    </li>
                                    <li>
                                        <button type="button" className={this.state.nodeCaptionDetails ? "btn btn-default-text" : "btn btn-default-text disabled"} onClick={(e) => { this.selectTestUnitButtonClick(e) }}><FormattedMessage id="KC1231"/></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={isChanged && showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isChanged && showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                {/* <!-- Import Modal --> */}
                <LibraryListPopup libraryType={'Settings'} OnImportLibrary={(item) => this.OnImport(item)} modelID={selectedModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} /> {/* <!-- //Import Modal --> */}
                {/* <!-- Save Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="saveNameModal" tabindex="-1" role="dialog" aria-labelledby="saveNameModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmationYesNo">
                                    <h5><FormattedMessage id="KC1277"/></h5>
                                    <div class="input-group">
                                        <input class="form-control" type="text" placeholder={formatMessage({ id: 'KC1277' })} value={settings_name} onChange={(e) => this.onSettingsNameChange(e)} />
                                        <div className="errorMsg">{errorSettingsName}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button onClick={() => { this.OnCancel() }} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
                                <button onClick={() => { this.onSaveButtonClick() }} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id="KC0123"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Save Modal --> */}
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterSettings)
