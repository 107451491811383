import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../service/httpService';
import { UnitInfo, UserProfile } from '../../service/api';
import PeakHoursChart from './charts/peakHourChart';
import ProductionLineChart from './charts/productionLineChart';
import { setCurrentUnit } from '../../actions/organizationentity/entityactions';
import ConnectivityChart from './charts/connectivityStatusChart';
import ErrorChart from './charts/errorStatusChart';
import { UnitCard } from '../controls/unitCard';
import AllLocationHeaderLayout from '../layoutSection/allLocationHeaderLayout';
import $ from 'jquery';
import * as _ from 'lodash';
import auth from '../../service/authService';
import appconstants from '../../utils/appConstants';
import { setunitBrandId } from '../../utils/appConstants';
import NoRecordsFound from '../controls/noRecordsFound';
import { UnitBuilder } from '../../utils/unitResponseBuilder';
import { CUSTOMER_ID, BRAND_ID, setBrandId } from '../../utils/appConstants';
import { DropDownListLink } from '../controls/dropDownListLink';
import { setUnit } from '../../actions/dashboardRenderer/dashboardRenderer';
import { searchVisible, searchText } from '../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../globalSearchBar/selectOption';

import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from '../controls/goToPagination';

import KCStore from '../../utils/kcStorage';

var moment = require('moment');
var todaysDate = new Date();

class SpecificLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalPages: null,
            currentPage: 1,
            pageSize: 25,
            sortColumn: { path: "", order: "", sort_color: "#F2F2F2" },
            grpId: '',
            loading: true,
            isLocationsDataLoaded: false,
            datapickflag: false,
            globalDropdownFilter: 'Month',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            searchData: null,
        };
        this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
        };
        window.addEventListener("load", this.updateDimensions());
		window.addEventListener("resize", this.updateDimensions());
        this.setLocatioStorage = this.setLocatioStorage.bind(this);
        this.setBrandList = this.setBrandList.bind(this);
    }

    updateDimensions() {
        const windowHeight = $(window).height();
        const tableBody = $('.pageScrollbar');
        const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
        tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
    }

    async componentDidUpdate(prevState) {
        window.addEventListener("resize", this.updateDimensions());
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({ loading: true,currentPage:1 },()=>{
                const { currentPage, sortColumn } = this.state;
                this.loadData(currentPage, sortColumn);
            });
           
		}
	}

	componentDidMount() {
        KCStore.removeItem('showOfflinePopup');
		KCStore.removeItem('liveMonitoringTracker');
        let _getBrandsList;
        let _getbrandIds = KCStore.getItem('brandId').replace(/[\[\]']+/g, '');
        _getBrandsList = _.isEqual(BRAND_ID.split(',').sort(), _getbrandIds.split(',').sort())
        if(!_getBrandsList) {
            setBrandId(_getbrandIds);
        }
        window.addEventListener("resize", this.updateDimensions());
		this.props.searchVisible(true);
        let currentLocation = KCStore.getItem("currentLocation");
        currentLocation = currentLocation ? JSON.parse(currentLocation) : {};
        let groupId = parseInt(currentLocation.location_id);
        this.setState({ grpId: groupId },()=>{
            const { currentPage, sortColumn } = this.state;
            this.loadData(currentPage, sortColumn);
        })
    }

    componentWillMount() {
        let currentLocation = KCStore.getItem("currentLocation");
        currentLocation = currentLocation ? JSON.parse(currentLocation) : {};
        let groupId = parseInt(currentLocation.location_id);
        this.setState({ grpId: groupId })
    }

    // componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

    onDropDownHandler(e) {
        const filterType = e.nodeText;

        this.setState({
            globalDropdownFilter: filterType
        });
        this.refs.peakHourChart.fetchData(this.refs.peakHourChart.state.brand_id, filterType)
        this.refs.productionHourChart.fetchData(this.refs.productionHourChart.state.brand_id, filterType)
        this.refs.peakHourChart.setState({ datapickflag: filterType === 'Date Range' })
        this.refs.productionHourChart.setState({ datapickflag: filterType === 'Date Range' })
    }


    /**
     * Fetch server data by On-Demand
     * Send current page number
     * Send Sorted details
     */
    loadData = async (currentPage, sortColumn) => {
        try {
            const response = await this.requestData(currentPage, sortColumn);
            await this.setBrandList();
            this.setState({
                data: response.rows,
                totalPages: response.pages,
                currentPage: currentPage,
                loading: response.showLoading,
                isLocationsDataLoaded: response.isLocationsDataLoaded
            });
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    /**
     * When navigate new page this event will return current page number
     * @param {*} currentPage 
     */
    onPageChange = (currentPage) => {
        this.setState({ loading: true });
        this.loadData(currentPage);
    }

    /**
     * When sort the record by On-Demand
     * This event will return the sorted column details
     */
    onSortedChange = (sortColumn) => {
        this.setState({
            sortColumn: sortColumn,
            currentPage: 1
        });
        this.loadData(1, sortColumn);
    }

    /**
     * Business login to fetch units by below constraints
     * 1. Fetch by Brand specific units
     * 2. Fetch by Sorted column details
     * 3. Fetch by page wise
     */
    requestData = async (currentPage, sortColumn) => {
        try {
            const sortData = sortColumn ? sortColumn : this.state.sortColumn;
            const pageSize = this.state.pageSize;
            const sortType = sortData.order;
            const sortBy = sortData.path;
            const page = currentPage - 1;

            let filterModel = this.props.filterlistInformtion.filterModel;
            const ffModel = KCStore.getItem(appconstants.filterKey);
            const filterModel1 = ffModel && JSON.parse(ffModel);
            if (filterModel1) {
                filterModel = filterModel1;
            }

            let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
            let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
            let selectedModelList = filterModel.find(el => el.field_name === 'model');
            let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
            let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
            let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

            let selectedCgids = [];
            let selectedCids = [];
            let selectedWithinLocation = [];
            let selectedBrands = [];
            let selectedModels = [];
            let selectedMachineTypes = [];
            let selectedST = [];

            selectedWithinLocationList.dataSource.map(c => {
                return selectedWithinLocation.push(c.cust_gp_id)
            });

            selectedBrandList.dataSource.map(c => {
                return selectedBrands.push(c.ID)
            });

            selectedModelList.dataSource.map(c => {
                return selectedModels.push(c.ID)
            });

            selectedMachineTypeList.dataSource.map(c => {
                return selectedMachineTypes.push(c.ID)
            });

            if (this.state.grpId) {
                selectedCgids.push(this.state.grpId)
            }

            if (selectedWithinLocationList.dataSource.length > 0) {
                selectedCgids = [];
            }

            selectedWithinLocationList.dataSource.map(c => {
                return selectedCgids.push(c.cust_gp_id)
            });

            selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
                return selectedST.push(c.id)
            });

            selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
                return selectedST.push(c.id)
              });

            this.filterstate = ({
                sCgids: selectedCgids,
                sCids: selectedCids,
                sBrandids: selectedBrands,
                sModels: selectedModels,
                sMachineTypes: selectedMachineTypes,
                sSmartTags: selectedST,
            });

            var d = document.getElementById("spannavNotifiStatus");
            let cgids = "";
            if (selectedCgids.length > 0) {
                cgids = selectedCgids.join(',');
            }

            if (selectedWithinLocation > 0 || selectedST.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
                d.className = "navNotifiStatus";
            }
            else {
                d.className = "";
            }

            let url = `${UnitInfo.list}`;
            let pageNumber = '?pageNum=' + (page + 1);
            let pageLimit = '&pageSize=' + pageSize;

            let order = page * pageSize;

            let apiUrl = url + pageNumber + pageLimit;

            let { searchData } = this.state;

			if (searchData && searchData.searchtext) {
				apiUrl = `${apiUrl}&searchText=${searchData.searchtext}`;
			}

			if (searchData) {
				let searchParam = {};

				Object.keys(searchData).forEach((item) => {
					if (item !== "searchtext") {
						return searchParam[item] = searchData[item];
					}
				})

				if (searchParam && Object.keys(searchParam).length) {
					apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
				}
			}
            let headers = {
                'Content-Type': 'application/json',
                'cgid': cgids ? cgids : "NA",
                'cid': CUSTOMER_ID === "-1" ? selectedCids.join(',') : CUSTOMER_ID,
                'brandid': selectedBrands.length > 0 ? selectedBrands.join(',') : BRAND_ID,
                'model': selectedModels.join(','),
                'machinetype': selectedMachineTypes.join(','),
                'sortby': sortBy,
                'sorttype': sortType
            }
            console.log("selectedST", selectedST);
            if(selectedST && selectedST.length){
                headers['tags'] = selectedST.join(',')
            }
            const response = await http.get(apiUrl,
                {
                    headers,
                    data: {}
                })

            let { units, numberOfPages } = response.data;
            units.map((item, i) => {
                item.order_id = ++order;
            });
            units = UnitBuilder(units);

            return {
                rows: units,
                pages: numberOfPages,
                isLocationsDataLoaded: true,
                showLoading:false,
            };

        } catch (error) {
            return {
                rows: [],
                pages: 0,
                showLoading:false,
            };
        }

    };

    setLocatioStorage = (item) => {
        this.props.setCurrentUnit(item);
        KCStore.setItem('unitID', item.UNITID);
        KCStore.setItem('unitBrandId', item.BRAND_ID);
        KCStore.setItem("brandName", item.BRAND_NAME);
        KCStore.setItem('modelName', item.MODEL_NAME);
		KCStore.setItem('modelFamilyName', item.MODEL_FAMILY_NAME);
        KCStore.setItem('cloudUnitName', item.CLOUD_UNIT_NAME);
        setunitBrandId(item.BRAND_ID ? item.BRAND_ID : "", item.UNITID ? item.UNITID : "", item.MODEL_NAME ? item.MODEL_NAME : "");
        this.getExcludeProductDetails(item.BRAND_ID);
    }

    setRendererLocationStorage = (item) => {
		this.props.setUnit(item);
		KCStore.setItem('unitID', item.UNITID);
		KCStore.setItem("brandName", item.BRAND_NAME);
		KCStore.setItem('unitBrandId', item.BRAND_ID);
		KCStore.setItem('modelName', item.MODEL_NAME);
		KCStore.setItem('modelFamilyName', item.MODEL_FAMILY_NAME);
        KCStore.setItem('cloudUnitName', item.CLOUD_UNIT_NAME);
		setunitBrandId(item.BRAND_ID ? item.BRAND_ID : "", item.UNITID ? item.UNITID : "", item.MODEL_NAME ? item.MODEL_NAME : "");
		this.getExcludeProductDetails(item.BRAND_ID);
	}

    getExcludeProductDetails = async (brandid) => {
        const response = await http.get(UnitInfo.excludeProduct,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'cid': CUSTOMER_ID,
                    'brandid': brandid,
                },
                data: {}
            })
        let excludeProduct = response.data && response.data[0]
        let checklength = excludeProduct && excludeProduct.excludedProductList && excludeProduct.excludedProductList.length ? excludeProduct.excludedProductList.length :0;

        KCStore.setItem('excludedProductList', checklength > 0 ? true : false);
    }

    static getDerivedStateFromProps = (props) => ({...props});

    setBrandList = async () => {
        try {
            let cgids = KCStore.getItem("custGroupID");
			let brandIds = KCStore.getItem("brandId")
            let url = `${UnitInfo.uniqueBrands}`;
            const response = await http.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'cgid': cgids ? cgids : "NA",
                        'cid': CUSTOMER_ID,
						'brandid': BRAND_ID
                    },
                    data: {}
                });
            let brandIDNameArr = response.data.brands.map(function (item) {
                return {
                    BRAND_NAME: item.BRAND_NAME,
                    BRAND_ID: item.BRAND_ID
                }
            });
            let uniqueObjArray = [...new Map(brandIDNameArr.map((item) => [item["BRAND_ID"], item])).values()];
            let brandIDName = uniqueObjArray.sort((a, b) => (a.BRAND_NAME > b.BRAND_NAME) ? 1 : ((b.BRAND_NAME > a.BRAND_NAME) ? -1 : 0));
            KCStore.setItem("availableUnitsBrand", JSON.stringify(brandIDName));
        } catch (error) {
            return error;
        }
    }


    render() {
        const { data, totalPages, currentPage, grpId, loading, isLocationsDataLoaded, globalDropdownFilter } = this.state;
        const { sCgids, sCids, sBrandids, sModels, sMachineTypes, sSmartTags } = this.filterstate;
        const { formatMessage } = this.props.intl;

        let features = auth.getAllFeatures();
        features = features ? features.filter(a => a.feature_category === "Unit Data") : [];

        return (
            <React.Fragment>
                <div id="wrapper">
                    <div className={loading ? "loadingProgress showloader" : "loadingProgress"}>
                        <div className="loadingProgressIn"></div>
                    </div>
                    <AllLocationHeaderLayout activeClass="dashboardNav" filterKey={appconstants.filterKey} history={this.props.history} />
                    <div id="page-content-wrapper" class="withSearchBar">
                        <div className="pageHeader">
                            <ul>
                                <li>
                                    <Link to="#" title={formatMessage({id: 'KC0054'})} className="active"><FormattedMessage id="KC1041"/></Link>
                                </li>
                                <li class="filterDropdown">
                                    <p class="globalFilterText"><FormattedMessage id='KC0097'/></p>
                                    <DropDownListLink translation={true} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.globalDropdownFilter}  disableDatePicker={true}/>
                                </li>
                            </ul>
                        </div>
                        <div class="pageScrollbar">
                        <div className="gridViewTable">
                            <div className="gridView">
                                <div className="colm3D colm3D1">
                                    <div className="colmDi">
                                        <h4><FormattedMessage id='KC0040'/></h4>
                                        {isLocationsDataLoaded && <ConnectivityChart Type={'Unit'} cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} tags={sSmartTags}/>}
                                    </div>
                                </div>
                                <div className="colm3D">
                                    <div className="colmDi">
                                        <h4><FormattedMessage id="KC1042"/></h4>
                                        {isLocationsDataLoaded && <ErrorChart Type={'Unit'} cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} tags={sSmartTags}/>}
                                    </div>
                                </div>
                                <div className="colm3D">
                                    {isLocationsDataLoaded && <PeakHoursChart ref="peakHourChart" cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes}/>}
                                </div>
                                <div className="colm3D colm3D4">
                                    {isLocationsDataLoaded && <ProductionLineChart ref="productionHourChart" cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes}/>}
                                </div>
                            </div>
                        </div>
                        <div className="pageTabHeader">
                            <ul>
                                <li>
                                    <Link to="/dashboardUnits" title={formatMessage({id: 'KC0045'})} className="unitsView active"><FormattedMessage id='KC0045'/></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="allUnitsGrid">
                            <ul>
                                {Object.keys(data).map((item, i) => (
                                    <li key={i}>
                                        <UnitCard unit={data[i]} features={features} setLocatioStorage={this.setLocatioStorage} setRendererLocationStorage={this.setRendererLocationStorage}/>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        </div>
                        </div>
                        <NoRecordsFound loaderIconVisible={!isLocationsDataLoaded} length={data !== undefined ? data.length : 0} classname={'norecordsfound'}  stringID={this.state.searchData && data && data.length === 0 ?  'KC0853' :'KC0205'}/>
							{ totalPages && totalPages > 1 ? <GoToPagination
                            isBlackBackground={true}
                            totalPageNumber={totalPages}
                            pageNumber={currentPage}
                            navigateTo={(p) => this.onPageChange(p)} /> : null }
                    </div>
            </React.Fragment>
        )
    }
};

function mapStateToProps(state) {
    return {
        filterlistInformtion: state.filterModel,
        selectedCountries: state.filterInformation.selectedCountries,
        selectedRegions: state.filterInformation.selectedRegions,
        selectedLocations: state.filterInformation.selectedLocations,
        selectedCustomers: state.filterInformation.selectedCustomers,
        selectedBrands: state.filterInformation.selectedBrands,
        selectedModels: state.filterInformation.selectedModels,
        searchData: state.advanceSearch.searchData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCurrentUnit, searchVisible, searchText, setUnit }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SpecificLocation));
