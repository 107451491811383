import React, { Component } from 'react';
//import logger from 'logging-library';
import FileViewer from 'react-file-viewer';
// import { CustomErrorComponent } from 'custom-error';

export default class DocumentViewers extends Component {
  render() {
    const { docsPath,docType } = this.props;
    return (
      <FileViewer
        fileType={docType}
        filePath={docsPath}
        // errorComponent={CustomErrorComponent}
        onError={this.onError}
        />
    );
  }
  onError(e) {
      console.log("DocViewer",e)
    //logger.logError(e, 'error in file-viewer');
  }
}