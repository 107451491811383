import {CYCLE_RATES_TOTAL_PERIOD_TIME_REQUEST,CYCLE_RATES_TOTAL_PERIOD_TIME_SUCCESS,CYCLE_RATES_TOTAL_PERIOD_TIME_FAIL} from '../../../../actions/reportManagement/utilization/cycleRatesTotalPeriodTimeActions';
const initialState = {
    loaded: false,
    loading: false,
    cycleRatesOffTimeResponse: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case CYCLE_RATES_TOTAL_PERIOD_TIME_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case CYCLE_RATES_TOTAL_PERIOD_TIME_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                cycleRatesTotalPeriodTimeResponse: action.cycleRatesTotalPeriodTimeResponse
            }
        case CYCLE_RATES_TOTAL_PERIOD_TIME_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgTotalPeriodTime: 'Server Error',

            }
        default:
            return state
    }
}