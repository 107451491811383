import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class LocationCard extends Component {

    render() {
        let { location: Location } = this.props;

        let locationsGridErrorStaus = 'allLocationsGridLI errorStaus', locationsGridwarningStaus = 'allLocationsGridLI warningStaus',
            locationsGridofflineStaus = 'allLocationsGridLI offlineStaus', locationsGridNoErrorStaus = 'allLocationsGridLI';

        return (
            <React.Fragment>
                {                
                   <div className={(Location.CriticalOnline) > '0' ? locationsGridErrorStaus :(Location.NonCriticalOnline > '0') ? locationsGridwarningStaus :
                   (Location.CriticalOffline + Location.NonCriticalOffline + Location.NoErrorOffline === Location.total_Units) ? locationsGridofflineStaus : locationsGridNoErrorStaus}>                   
                    <h5> {Location.city} <span>{Location.location_name}</span></h5>
                    <span className="locationsGridImg">
                        <img src={Location.Brand_Logo} alt={Location.Brand_Alt} title={Location.Brand_Title} /></span>
                    <div className="noOfDevice">{Location.total_Units}</div>
                </div>
                }
            </React.Fragment>
        )
    }
}

LocationCard.propTypes = {
    location: PropTypes.object.isRequired
  }
