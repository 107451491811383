import { v4 as uuidv4 } from 'uuid';


const HEADER_VERSION  = 2;
const OVEN_SIZES = 0;
const OVEN_TYPES = 0;
/**
 * It Returns 36bit V4 GIUID
 * @returns 36 Bit 
 */
const getGUID = () => uuidv4();

/**
 * Create CookBook from Selected Recipe Files
 * @param {*} recipeInfo 
 * @param {*} cookBookName
 * @returns 
 */
 export const createDBFile = async(recipeInfo, cookBookName) => {
    try {
        const SQL = { ...window.SQL };
        let db = new SQL.Database();
        db = CreateTables(db);
        const recipes = recipeInfo.recipeDetails;
        const GUID = getGUID();
        db.run("INSERT INTO CbInfo (id_info, versionnumber) VALUES (?,?)", [GUID, HEADER_VERSION]);
        db.run("INSERT INTO Info (id_info, versionnumber, cookbookname, ovensizes, oventypes) VALUES (?,?,?,?,?)", [GUID, HEADER_VERSION,cookBookName,OVEN_SIZES,OVEN_TYPES]);
        for (let index = 0; index < recipes.length; index++) {
            const recipe = recipes[index];
            let tblValues = [];
            const imgResults = await mapAndGetImageData(recipe);
            recipe.imageData = imgResults;
            tblValues = Object.values(imgResults);
            db.run("INSERT INTO Image(id_image, name, type, image) VALUES (?, ?, ?, ?)", tblValues);
            const recipeResults = mapAndGetRecipeData(recipe);
            recipe.recipeData = recipeResults;
            tblValues = Object.values(recipeResults);
            db.run("INSERT INTO Recipe(id_recipe, id_preheat, id_automaticstart, id_image, name, recipetype, ovensize, extrafunctions) VALUES(?, ?, ?, ?, ?, ?, ?, ?)", tblValues);
            insertOvenData(recipe, db);
            await insertCoureData(recipe, db);
        }
        return db;
    } catch (error) {
        console.log('ERROR in CREATE DB FILE', error);
        throw error;
    }
};

/**
 * Create CookBook DB file from
 * 1. Recipe Files
 * 2. CookBook files
 * @param {*} recipeInfo 
 * @param {*} cookBookName
 */
 export const createRCtoCB = async(recipeInfo,cookBookName) => {
    try {
    const SQL = { ...window.SQL };
     const cbUrl = recipeInfo.cookBookS3URL;
     const dbStream = await getDBSream(cbUrl);
     const db = new SQL.Database(dbStream);
     const recipes = recipeInfo.recipeDetails;
     const cbInfoSelectId = getCbInfo(db);
     const verifyTableExist = isTableExist(db);
     if(verifyTableExist){
        const checkInfoTableRecord = isInfoTableCount(db);
        if(checkInfoTableRecord){
            db.run("UPDATE Info SET cookbookname = ?",[cookBookName]);
        }
        else{
            db.run("INSERT INTO Info(id_info, versionnumber, cookbookname, ovensizes, oventypes) VALUES (?,?,?,?,?)", [cbInfoSelectId, HEADER_VERSION,cookBookName,OVEN_SIZES,OVEN_TYPES]);    
        }
    }
    else{
        db.run("CREATE TABLE Info(id_info TEXT, versionnumber TEXT, cookbookname TEXT, ovensizes INTEGER, oventypes INTEGER)")
        db.run("INSERT INTO Info(id_info, versionnumber, cookbookname, ovensizes, oventypes) VALUES (?,?,?,?,?)", [cbInfoSelectId, HEADER_VERSION,cookBookName,OVEN_SIZES,OVEN_TYPES]);
    }
     for (let index = 0; index < recipes.length; index++) {
         const recipe = recipes[index];
         const checkRecipeExist = await isRecipeExist(recipe, db);
         if (!checkRecipeExist) {
         let tblValues = [];
         const imgResults = await mapAndGetImageData(recipe);
         recipe.imageData = imgResults;
         tblValues = Object.values(imgResults);
         db.run("INSERT INTO Image(id_image, name, type, image) VALUES (?, ?, ?, ?)", tblValues);
         const recipeResults = mapAndGetRecipeData(recipe);
         recipe.recipeData = recipeResults;
         tblValues = Object.values(recipeResults);
         db.run("INSERT INTO Recipe(id_recipe, id_preheat, id_automaticstart, id_image, name, recipetype, ovensize, extrafunctions) VALUES(?, ?, ?, ?, ?, ?, ?, ?)", tblValues);
         insertOvenData(recipe, db);
         await insertCoureData(recipe, db);
         }
     }
     return db;
    } catch (error) {
        throw error;
    } 
 };

/**
 * Retrive CBInfo Table - id_info column value
 */
 const getCbInfo = (db) => {
    try {
        let getCbInfoId;
        const stmt = db.exec("SELECT * FROM CbInfo");
        const result = stmt;
        const i = 0;
        if (result[i] && result[i].values[i][i]) {
            getCbInfoId = result[i].values[i][i];
        }
        return getCbInfoId; 
    } catch (error) {
        console.log("ERROR in getCbInfoId----->",error);
        throw error;
    }
 }

 /**
 * To verify Info Table exists from database
 */
const isTableExist = (db) => {
    try {
        let isTableAvailable = false;
        const stmt = db.prepare("SELECT count(*) as COUNT FROM sqlite_master WHERE type=$table AND name=$Info");
        const result = stmt.getAsObject({ $table: 'table', $Info: 'Info' });

        if (result && result.COUNT > 0) {
            isTableAvailable = true;
        }
        stmt.free();
        return isTableAvailable;
    } catch (error) {
        console.log("ERROR in isTableExist----->",error);
        throw error;
    }
}

/**
 * To verify Info Table record count in database
 */
const isInfoTableCount = (db) => {
    try {
        let isInfoCount = false;
        const statement = db.prepare("SELECT count(*) as COUNT FROM Info");
        const result = statement.getAsObject({ $Info: 'Info' });

        if (result && result.COUNT > 0) {
            isInfoCount = true;
        }
        return isInfoCount;
    } catch (error) {
        console.log("ERROR in isInfoTableCount record check----->",error);
        throw error;
    }
}

const isRecipeExist = (recipe, db) => new Promise((resolve, reject) => {
    try {
        let isRecipeFound = false;
        const stmt = db.prepare("SELECT * FROM Recipe WHERE name=:recipeName");
        const result = stmt.getAsObject({ ':recipeName' : recipe.RECIPE_NAME });
        if(result && result.name === recipe.RECIPE_NAME) {
            isRecipeFound = true;
        }  
        stmt.free();
        resolve(isRecipeFound);
    } catch (error) {
        reject(error);
    }
});

//Insert Oven Data
const insertOvenData = (recipe, db) => {
    try {
        for (let index = 0; index < recipe.ovenInstruction.length; index++) {
            const oven = recipe.ovenInstruction[index];
            const ovenResults = mapAndGetOvenData(oven, recipe, index);
            const tblValues = Object.values(ovenResults);
            db.run("INSERT INTO Steps(id_step, id_recipe, id_message, cookingalgorithm, temperature, time, coretemperature, humidificationsettings, fanspeed, extras, extrasAvail, addmoisture, climateControl, position) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)", tblValues);
        }  
    } catch (error) {
        throw error;
    }
};

// Get Oven Data
const mapAndGetOvenData = (oven, recipe, position) => {
    const ovenData = {};
    ovenData.id_step = getGUID();
    ovenData.id_recipe = recipe.recipeData.id_recipe;
    ovenData.id_message = "";
    ovenData.cookingalgorithm = oven.COOKING_METHOD || '';
    ovenData.temperature = oven.TEMPERATURE;
    ovenData.time = oven.TIME;
    ovenData.coretemperature = oven.CORE_TEMPERATURE;
    ovenData.humidificationsettings = oven.HUMIDIFICATION_SETTINGS;
    ovenData.fanspeed = oven.FAN_SPEED;
    ovenData.extras = oven.EXTRAS;
    ovenData.extrasAvail = 0;
    ovenData.addmoisture = oven.ADD_MOISTURE;
    ovenData.climateControl = oven.CLIMATE_CONTROL;
    ovenData.position = oven.POSITION ? oven.POSITION : 0;   
    return ovenData;
}

//Insert Course Data
const insertCoureData = async(recipe, db) => {
    try {
        for (let index = 0; index < recipe.courses.length; index++) {
            const cource = recipe.courses[index];
            const courseId = await checkCourseAndGetCourseId(cource, db);
            const courceResults = mapAndGetCourseData(cource);
            if(!courseId) {
            let tblValues = Object.values(courceResults);
            db.run("INSERT INTO ProductGroup(id_productgroup, id_image, name, position) VALUES(?, ?, ?, ?)", tblValues);
            } else {
                courceResults.id_productgroup = courseId;
            }
            const courseGPResults = mapAndGetCourseGPData(courceResults, recipe);
            let tblValues = Object.values(courseGPResults);
            db.run("INSERT INTO ProductGroupEntry(id_recipe, id_productgroup, trays, position, usergroup) VALUES(?, ?, ?, ?, ?)", tblValues);
        }
    } catch (error) {
        throw error;
    }
}

/**
 * Check Current Cource Grouped with anyother recipe
 * @param {*} course 
 * @param {*} db 
 * @returns 
 */
const checkCourseAndGetCourseId = (course, db) => new Promise((resolve, reject) => {
    try {
        let courseId = "";
        const stmt = db.prepare("SELECT * FROM ProductGroup WHERE name=:courseName");
        const result = stmt.getAsObject({ ':courseName' : course.COURSE_NAME });
        if(result.id_productgroup) {
            courseId = result.id_productgroup;
        }  
        stmt.free();
        resolve(courseId);
    } catch (error) {
        reject(error);
    }
});

// Get Cource Data
const mapAndGetCourseData = (cource) => {
    const courceData = {};
    courceData.id_productgroup = getGUID();
    courceData.id_image = "";
    courceData.name = cource.COURSE_NAME;
    courceData.position = 0;
    return courceData;
};


// Get Cource Group Data
const mapAndGetCourseGPData = (cource, recipe) => {
    const courceGPData = {};
    courceGPData.id_recipe = recipe.recipeData.id_recipe;
    courceGPData.id_productgroup = cource.id_productgroup;
    courceGPData.trays = "";
    courceGPData.position = 0;
    courceGPData.usergroup = 0;
    return courceGPData;
};

// Map and get image data
const mapAndGetImageData = (recipe) => {
    return new Promise(async(resolve, reject) => {
        try {
            const imageData = {};
            imageData.id_image = getGUID();
            imageData.name = recipe.IMAGE_FILE_NAME;
            imageData.type = 0;
            imageData.image = await getImageBlob(recipe);
            resolve(imageData);
        } catch (error) {
            reject(error);
        }
    });
}

// Map and get recipe data
const mapAndGetRecipeData = (recipe) => {
    const recipeData = {};
    recipeData.id_recipe = getGUID();
    recipeData.id_preheat = "";
    recipeData.id_automaticstart = "";
    recipeData.id_image = recipe.imageData.id_image;
    recipeData.name = recipe.RECIPE_NAME;
    recipeData.recipetype = 0;
    recipeData.ovensize = 0;
    recipeData.extrafunctions = 0;
    return recipeData;
}

/**
 * Get Existing CookBook DB file as Stream Buffer
 * @param {*} CBUrl 
 * @returns 
 */
const getDBSream = (CBUrl) => {
    return new Promise((resolve, reject) => {
        try {
            // resolve(`data:image/png;base64,${getGUID()}`);
            var xhr = new XMLHttpRequest();
            xhr.open("GET", CBUrl);
            xhr.onload =  (res) => {
                const binary = new Uint8Array(xhr.response);
                resolve(binary);
            };
            xhr.responseType = "arraybuffer";
            xhr.onerror = (err) => {
                reject(err);
            };
            xhr.send();
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Get Image Blob
 * @param {*} recipe 
 * @returns 
 */
const getImageBlob = (recipe) => {
    return new Promise((resolve, reject) => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", `${recipe.imageS3URL}`);
            xhr.onload =  (res) => {
                const binary = new Uint8Array(xhr.response);
                resolve(binary);
            };
            xhr.responseType = "arraybuffer";
            xhr.onerror = (err) => {
                console.log('ERROR IN getImageBlob', err);
                reject(`data:image/png;base64,${getGUID()}`);
            };
            xhr.send();
        } catch (error) {
            reject(error);
        }

    });
}

/**
 * Upload DB file into S3
 * @param {*} db 
 * @returns 
 */

 export const uploadIntoS3 = (db, s3Url) => {
    return new Promise((resolve, reject) => {
        try {
            const buffer = db.export();
            const url = s3Url;
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", url);
            xhr.setRequestHeader('Content-Type', 'multipart/form-data');
            xhr.onloadend = (res) => {
                resolve(res);
            };
            xhr.onerror = (err) => {
                reject(err);
            };
            xhr.send(buffer);
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Check Valid DB
 * @param {*} db 
 * @returns 
 */

 export const CheckValidDB = (db) => {
    return new Promise(async (resolve, reject) => {
        const SQL = { ...window.SQL };
        const r = new FileReader();
        r.onload = function() {
            try {
            const Uints = new Uint8Array(r.result);
            const DB = new SQL.Database(Uints);
            let recipeData =  DB.exec("SELECT * FROM Recipe"); 
            let stepData = DB.exec("SELECT * FROM Steps");
            if(recipeData && stepData){
                    resolve(true);
                }else{
                    reject(false);
                }
            } catch (error) {
                reject(false);
            }
        }
        r.readAsArrayBuffer(db);
    });
};

/**
 * Create All Tables for Convotherm DB
 * @param {*} db 
 * @returns 
 */
 const CreateTables = (db) => {
    try {
        db.run("CREATE TABLE AutomaticRecentRecipeStart(id_automaticRecipeStart TEXT, day INTEGER, time TEXT, CONSTRAINT PK_AutomaticRecipeStart PRIMARY KEY (id_automaticRecipeStart))");
        db.run("CREATE TABLE AutomaticRecipeStart(id_automaticRecipeStart TEXT, day INTEGER, time TEXT, CONSTRAINT PK_AutomaticRecipeStart PRIMARY KEY (id_automaticRecipeStart))");
        db.run("CREATE TABLE AutomaticWarmRecipeStart(id_automaticRecipeStart TEXT, day INTEGER, time TEXT, CONSTRAINT PK_AutomaticRecipeStart PRIMARY KEY (id_automaticRecipeStart))");
        db.run("CREATE TABLE CbInfo(id_info TEXT, versionnumber TEXT, CONSTRAINT PK_CbInfo PRIMARY KEY (id_info))");
        db.run("CREATE TABLE Info(id_info TEXT, versionnumber TEXT, cookbookname TEXT, ovensizes INTEGER, oventypes INTEGER, CONSTRAINT PK_Info PRIMARY KEY (id_info))")
        db.run("CREATE TABLE Image(id_image TEXT, name TEXT, type INTEGER, image BLOB, CONSTRAINT PK_Icon PRIMARY KEY (id_image), UNIQUE (image))");
        db.run("CREATE TABLE Message(id_message TEXT, text TEXT, action INTEGER, CONSTRAINT PK_Message PRIMARY KEY (id_message))");
        db.run("CREATE TABLE Preheat(id_preheat TEXT, cookingalgorithm INTEGER, holdingtime INTEGER, temperature INTEGER, tolerance INTEGER, CONSTRAINT PK_Preheat PRIMARY KEY (id_preheat))");
        db.run("CREATE TABLE ProductGroup(id_productgroup TEXT, id_image TEXT, name TEXT, position INTEGER, CONSTRAINT PK_ProductGroup PRIMARY KEY (id_productgroup), CONSTRAINT FK_ProductGroup_Icon FOREIGN KEY (id_image) REFERENCES Image(id_image))");
        db.run("CREATE TABLE ProductGroupEntry(id_recipe TEXT, id_productGroup TEXT, trays INTEGER, position INTEGER, usergroup INTEGER, CONSTRAINT FK_ProductGroupEntry_ProductGroup FOREIGN KEY (id_productGroup) REFERENCES ProductGroup(id_productgroup), CONSTRAINT FK_ProductGroupEntry_Recipe FOREIGN KEY (id_recipe) REFERENCES Recipe(id_recipe))");
        db.run("CREATE TABLE RecentRecipe(id_recipe TEXT, id_preheat TEXT, id_automaticstart TEXT, id_image TEXT, name TEXT, recipetype INTEGER, ovensize INTEGER, extrafunctions INTEGER, id_NormalDB TEXT, CONSTRAINT PK_Recipe PRIMARY KEY (id_recipe))");
        db.run("CREATE TABLE Recipe(id_recipe TEXT, id_preheat TEXT, id_automaticstart TEXT, id_image TEXT, name TEXT, recipetype INTEGER, ovensize INTEGER, extrafunctions INTEGER, CONSTRAINT PK_Recipe PRIMARY KEY (id_recipe), CONSTRAINT FK_Recipe_AutomaticRecipeStart FOREIGN KEY (id_automaticstart) REFERENCES AutomaticRecipeStart(id_automaticRecipeStart), CONSTRAINT FK_Recipe_Icon FOREIGN KEY (id_image) REFERENCES Image(id_image), CONSTRAINT FK_Recipe_Preheat FOREIGN KEY (id_preheat) REFERENCES Preheat(id_preheat))");
        db.run("CREATE TABLE Steps(id_step TEXT, id_recipe TEXT, id_message TEXT, cookingalgorithm INTEGER, temperature INTEGER, time INTEGER, coretemperature INTEGER, humidificationsettings INTEGER, fanspeed INTEGER, extras INTEGER, extrasAvail INTEGER, addmoisture INTEGER, climateControl INTEGER, position INTEGER, CONSTRAINT PK_Steps PRIMARY KEY (id_step))");
        db.run("CREATE TABLE WarmRecipe(id_recipe TEXT, id_preheat TEXT, id_automaticstart TEXT, id_image TEXT, name TEXT, recipetype INTEGER, ovensize INTEGER, extrafunctions INTEGER, id_NormalDB TEXT, CONSTRAINT PK_Recipe PRIMARY KEY (id_recipe))");
        return db;
    } catch (error) {
        console.log('ERROR IN DB File Creation', error);
        throw error;
    }
};
