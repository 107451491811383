import {
    GET_BRAND_LIST, GET_BRAND_LIST_FAIL, GET_BRAND_LIST_SUCCESS
} from '../../../actions/connectivityTest/actionType';

const initialState = {
    data: [],
    isFetching: true,
}
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BRAND_LIST:
            return { ...state, isFetching: true };
        case GET_BRAND_LIST_SUCCESS:
            return { ...state, data: action.payload, isFetching: false };
        case GET_BRAND_LIST_FAIL:
            return { ...state };
    }
    return state;
}
