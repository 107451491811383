import http from '../../../service/httpService';
import { LocationInfo } from '../../../service/api';
import * as _ from 'lodash';
import KCStore from '../../../utils/kcStorage';


import { BRAND_ID } from '../../../utils/appConstants';
export const LOCATION_REQUEST = 'LOCATION_REQUEST';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const LOCATION_FAIL = 'LOCATION_FAIL';

export function getLocation (req = '',filterKey,isCascadeActionRequest) {
    return dispatch => {
        dispatch({
            type: LOCATION_REQUEST,
            isCascadeActionRequest : isCascadeActionRequest ? true : false
        })


        let cgIds = '';
        const ffModel = KCStore.getItem(filterKey);
        const filterModel1 = JSON.parse(ffModel) || [];
        if (filterModel1.length) {
            let dataSource = _.find(filterModel1, { field_name: 'chain' }).dataSource
            cgIds = _.isArray(dataSource) && dataSource.length ? dataSource.map(el => el.cust_gp_id).join(',') : '';

            if (cgIds==='')
            {
                let dataSource = _.find(filterModel1, { field_name: 'country' }).dataSource
                cgIds = _.isArray(dataSource) && dataSource.length ? dataSource.map(el => el.cust_gp_id).join(',') : '';
            }
            if (cgIds==='')
            {
                cgIds = KCStore.getItem("custGroupID")
            }
        }
        else
        {             
            cgIds = KCStore.getItem("custGroupID")            
        }

        
        http
            .get(`${LocationInfo.filters}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Type': 4,
                        'cid': KCStore.getItem("customerId"),
                        'cgid': req ? req : cgIds,
                        'brandid': BRAND_ID,
                    },
                    data: {}
                })
            .then(response => {
                dispatch({
                    type: LOCATION_SUCCESS,
                    locationInformation: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: LOCATION_FAIL,
                    error
                })
            })
    }
}
