import {TOPTEN_TABLE_REQUEST,TOPTEN_TABLE_SUCCESS,TOPTEN_TABLE_FAIL} from '../../../actions/reportManagement/topTenTableActoin';
const initialState = {
    loaded: false,
    loading: false,
    time:'test',
    topTenTableInformation: []
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case TOPTEN_TABLE_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case TOPTEN_TABLE_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                time:action.time,
                page:action.page,
                deviceid:action.device,
                topTenTableInformation: action.topTenTableInformation
            }
        case TOPTEN_TABLE_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgTopTenTable: 'Server Error',

            }
        default:
            return state
    }
}