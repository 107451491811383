import {UTILIZATION_TABLE_REQUEST,UTILIZATION_TABLE_SUCCESS,UTILIZATION_TABLE_FAIL} from '../../../../actions/reportManagement/utilization/utilizationTableActions';
import { NO_RESULT_MSG } from '../../../../utils/appConstants';
const initialState = {
    loaded: false,
    loading: false,
    utilizationTableResponse: {},
    errormsgUtilizationTable: '',
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case UTILIZATION_TABLE_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case UTILIZATION_TABLE_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                utilizationTableResponse: action.utilizationTableResponse
            }
        case UTILIZATION_TABLE_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                utilizationTableResponse: {},
                errormsgUtilizationTable: NO_RESULT_MSG,

            }
        default:
            return state
    }
}