import React, { Component } from 'react'
import PropTypes from 'prop-types'
import http from '../../service/httpService';
import { unitAPI } from '../../service/api';

import KCStore from "../../utils/kcStorage";

export class ModelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderIconVisible: false,
      modelList: [],
      error_modal: {
        open: false,
        message: ""
      },
      success_modal: {
        open: false,
        message: ""
      },
    }
    this.getModelList = this.getModelList.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
  }

  componentDidMount = async () => {
    //document.body.style.backgroundColor = "#F2F2F2";

    try {
      await this.getModelList(this.props.brandid).catch(err => {
        this.setState({
          loaderIconVisible: false
        });
      });
    } catch (error) {
      //Error Goes Here
    }
  }

  getModelList = (brandid) => {
    this.setState({
      loaderIconVisible: true
    });

    return new Promise((resolve, reject) => {
      http.get(unitAPI.getModels,
        {
          headers: {
            'Content-Type': 'application/json',
            'brandid': brandid
          },
          data: {}
        }).then(response => {
          let { data } = response;
          let modelList = data && data.body ? data.body : [];
          this.setState({
            loaderIconVisible: false,
            modelList: modelList
          });
          resolve(response);
        }).catch(err => {
          reject(err);
        });
    });
  }

  handleModelChange(e) {
    KCStore.setItem("selectedProductID", e.target.value);
    this.props.handleChangeProps(e);
  }

  render() {

    let { modelList } = this.state;
    let { isModelEditable } = this.props;
    const productid = KCStore.getItem("selectedProductID");

    return (
      <div className="input-group">
        <select className={isModelEditable ? "form-control" : "form-control disabled"} onChange={this.handleModelChange}>
          {modelList.map((item) => {
            let selecteditem = item.PRODUCT_ID.toString() === productid ? "selected" : "";
            return (
              <option value={item.PRODUCT_ID} selected={selecteditem}>{item.MODEL_NAME}</option>
            )
          })}
        </select>
      </div>
    );
  }
}

ModelList.propTypes = {
  modelList: PropTypes.array.isRequired,
  isModelEditable: PropTypes.bool.isRequired
}
