
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import http from '../../../../service/httpService';
import { MenuUploadAPI } from '../../../../service/api';
import ErrorMoal from '../../../modal/error-model';
import SuccessMoal from '../../../modal/success-model';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../layoutSection/userManagementHeader';
import { TableHeader } from '../../../controls/tableHeader';
import { orderBy } from 'lodash'
import { CUSTOMER_ID } from '../../../../utils/appConstants';
import NoRecordsFound from '../../../controls/noRecordsFound';
import { injectIntl,FormattedMessage } from 'react-intl';
import { searchVisible, searchText } from '../../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function } from '../../../globalSearchBar/selectOption';
import KCStore from '../../../../utils/kcStorage';

class LegacyReadyRecipeLibraryList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			customerId: parseInt(KCStore.getItem("customerId")),
			data: [],
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			created_by: '',
			last_edited_by: '',
			uploadOption: 'ReadyRecipe',
			searchData: null
		}
		this.filterstate = {
			sCgids: [],
			sCids: [],
			sBrandids: [],
			sModels: [],
			sMachineTypes: [],
		};
		this.getReadyRecipe = this.getReadyRecipe.bind(this);
		this.OnToggleDetails = this.OnToggleDetails.bind(this);
		this.OnLibraryAdd = this.OnLibraryAdd.bind(this);
	}

	async componentDidMount() {
		this.props.searchVisible(true);
		$('body').removeClass("modal-open");
		$('div').removeClass("modal-backdrop show");
		$('.styles_overlayCenter__YHoO7').addClass("displaynone");
		document.body.style.backgroundColor = "#FFFFFF";
		KCStore.setItem("libraryData", "{}");
		KCStore.setItem("libraryDetails", "{}");
		KCStore.setItem("libraryImage", "");
		KCStore.setItem("schedulerDetails", "{}");
		KCStore.setItem("defaultLibraryData", "{}");
		KCStore.setItem("resourceDetails", "[]");
		KCStore.setItem("selectedCategory", "{}");
		KCStore.setItem("selected_cgid","");
		window.addEventListener('resize', this.updateDimensions());
		$('#UploadLibrary').click(function () { $('#menuUpdate').trigger('click'); });
		await this.getReadyRecipe().catch((err) => {
			let {message,errorCode} = err.response.data;
			if(errorCode == '403100' && message=='Token Expired Or Invalid'){
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1678"
					}
				});
			}else{
				this.setState({
					loaderIconVisible: false
				});
			}
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions());
		$('div').removeClass("modal-backdrop fade show");
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	static getDerivedStateFromProps(props) {
		return { ...props };
	}

	componentDidUpdate(prevState) {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if(curSearchData && (isEqualObj(searchData, curSearchData) === false) ) {
			this.getReadyRecipe();
		}
	}

	updateDimensions() {
		var windowHeight = $(window).height();
		var mediaHeader = $(".menuFilterHeader").height();
		var mediaPageWrapperH = $(".readyRecipeForm").height();
		var mediaPageWrapperHeight = ((windowHeight) - (mediaHeader + mediaPageWrapperH )) - 65;
		$(".readyRecipeSrlTable").css("height", mediaPageWrapperHeight);
	}

	OnToggleDetails = (item) => {
		const { CREATED_BY: created_by, UPDATED_BY: last_edited_by } = item;
		this.setState({
			created_by,
			last_edited_by
		});
	}

	getReadyRecipe = async() => {
		this.setState({
			loaderIconVisible: true
		})
		let url = MenuUploadAPI.getConvoReadyRecipe;
		let { searchData } = this.state;
		//searchData_function
		let _search = await searchData_function(searchData);

		if( _search ){
			url = `${url}?${_search}`;
		}

		return new Promise((resolve, reject) => {
			http.get(url,
				{
					headers: {
						'Content-Type': 'application/json',
						'customerid': CUSTOMER_ID,
						'cgids': KCStore.getItem("custGroupID"),
					},
					data: {}
				}).then(response => {
					let { data } = response;
					let recipeList = data ? data : [];
					recipeList.length > 0 && recipeList.map(item => {
						return item.IMAGE_SIZE = item.IMAGE_SIZE ? parseFloat(item.IMAGE_SIZE, 2) : 0.00;
					});
					this.setState({
						loaderIconVisible: false,
						data: recipeList
					});
					resolve(response);
				}).catch(err => {
					reject(err);
				});
		});
		
	}

	OnLibraryAdd = (item) =>{
		this.setState({
			loaderIconVisible: true
		});
		let kcbrandsitedata = {};
		kcbrandsitedata.readyrecipeids = item.READY_RECIPE_ID.toString();
		KCStore.setItem("kcbrandsitedata",JSON.stringify(kcbrandsitedata));
		let modelFamilyIDs = "";
		if (item.MODEL_FAMILY) {
			modelFamilyIDs =  item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : "";
		}
		KCStore.setItem("selectedFamilyID", modelFamilyIDs);
		KCStore.setItem("selectedbrandID", item.BRAND === "MERRYCHEF" ? 1 : 6);
        KCStore.setItem("brandName", item.BRAND);
		if(item.BRAND === 'MERRYCHEF'){
			this.props.history.push({
				pathname: "/assignLegacyRecipeToTopology",
				previouspath: "/legacyReadyRecipeLibraryList",
			});
        }else if (item.BRAND === 'CONVOTHERM'){
			this.props.history.push({
				pathname: "/assignLegacyCookbookToTopology",
				previouspath: "/legacyReadyRecipeLibraryList",
			});
        }
	}

	openErrorModal = (message) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: true,
				message
			}
		});
	}

	onSuccessCloseModal = () => {
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/legacyLibraryList`);
	}

	closeModel = () => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ''
			}
		})
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	render() {
		let { data: dataList, error_modal, success_modal, loaderIconVisible, sortColumn, created_by, last_edited_by } = this.state;
		dataList = dataList && dataList.length > 0 ? dataList : [];

		let columns = [
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0102" },
			{ path: "", labelStringID: "KC0680" },
			{ path: "Brand", labelStringID: "KC0062" },
			{ path: "", labelStringID: "KC0649" },
			{ path: "RECIPE_NAME", labelStringID: "KC0241" },
			{ path: "IMAGE_SIZE", labelStringID: "KC0682" },
			{ path: "", labelStringID: "KC0325" },
			{ path: "", label: "" },
		];

		if (sortColumn.path === "IMAGE_SIZE") {
			dataList = orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path]], [sortColumn.order]);
		}
		else {
			dataList = orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path].toString().toLowerCase()], [sortColumn.order]);
		}

		const { formatMessage } = this.props.intl;
		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div className="menuUpdateWrapper">
							<div className="menuFilterHeader">
								<ul>
									<li>
										<Link to="/legacyLibraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
										<Link to="/legacyReadyRecipeLibraryList" title={formatMessage({ id: 'KC1181' })} className="active"><FormattedMessage id="KC1181"/></Link>
										<Link to="/menuUpdateStatus" title={formatMessage({ id: "KC0659"})}><FormattedMessage id='KC0659' /></Link>
									</li>
								</ul>
							</div>
							<form className="readyRecipeForm">
								<div className="readyRecipeTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										columns={columns}
										 />
								</div>
								<div className="readyRecipeSrlTable">
									<div className="readyRecipeTable">
										{
											dataList.map((item, i) => {
												return (
													<ul className="tBody" key={i}>
														<li>{i + 1}</li>
														<li><span className="menuType"><img src={require("../../../../images/icons/recipeS.png")} title={item.TYPE === "ReadyRecipe" ? formatMessage({ id: 'KC0640' }) : item.TYPE === "Recipe" ? formatMessage({ id: 'KC1381' }) : item.TYPE === "Settings" ? formatMessage({ id: 'KC1190' }) : item.TYPE === "Legacy" ? formatMessage({ id: 'KC1113' }) : formatMessage({ id: 'KC2009' })} alt="" /></span></li>
														<li><span className="menuPicture"><img src={item.THUMB_NAIL_CONTENT} alt=""/></span></li>
														<li>{item.BRAND}&nbsp;
															<div className="menuSListSM">{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString() : ''}</div>
															<div className="menuSListSMF">{item.RECIPE_NAME}</div>
															<div className="menuSListRS"></div>
															<div className="menuSListAS">{item.IMAGE_SIZE ? item.IMAGE_SIZE.toFixed(2) : "0.00"} mb</div>
														</li>
														<li>{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString() : ''}</li>
														<li>{item.RECIPE_NAME}</li>
														<li>{item.IMAGE_SIZE ? item.IMAGE_SIZE.toFixed(2) : "0.00"}</li>
														<li><button type="button" disabled={true} className="btn btn-default-text menuInfo" data-toggle="modal" data-target="#detailsInfoModal" onClick={() => this.OnToggleDetails(item)}>&nbsp;</button></li>
														<li>
															<button type="button" disabled={item.IS_ADDED_IN_LIBRARY === 1} className="btn btn-default" onClick={() => this.OnLibraryAdd(item)}><FormattedMessage id='KC1499' /></button>
														</li>
													</ul>
												)
											})
										}
									</div>
								<NoRecordsFound loaderIconVisible={loaderIconVisible} length={dataList !== undefined ? dataList.length : 0} classname={'norecordsfoundbluetext'} />
								</div>
							</form>
							<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
							<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
						</div>
					</div>
					<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" history={this.props.history}/>
				</div>
			</React.Fragment>
		)
	}
};

const mapStateToProps = state => {
	return {
		searchData: state.advanceSearch.searchData
	}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchVisible, searchText
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LegacyReadyRecipeLibraryList));
