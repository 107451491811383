import http from '../../../service/httpService';
import { BaseService, ReportManagement } from '../../../service/api';
import { format, addDays } from 'date-fns';
export const UTILIZATION_TABLE_REQUEST = 'UTILIZATION_TABLE_REQUEST';
export const UTILIZATION_TABLE_SUCCESS = 'UTILIZATION_TABLE_SUCCESS';
export const UTILIZATION_TABLE_FAIL = 'UTILIZATION_TABLE_FAIL';

var todaysDate = new Date();

export function getUtilizationTableData(type, fromdate, todate, deviceId, brandId) {
    let url;
    let filterType;
    let header;
    switch (type) {
        case 'Week':
            filterType = 'weekly';
            break;
        case 'Day':
            filterType = 'daily';
            break;
        case 'Date Range':
            filterType = 'custom';
            break;
        default:
            filterType = 'monthly';
    }
    
    if (brandId === 2) {
        url = `${BaseService.root}${ReportManagement.utilizationTableView}`;
    }
    else if (brandId === 11) {
        url = `${BaseService.root}${ReportManagement.uhcUtilizationTableView}`;
    }
    else {
        let widgetId = 61;
        url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brandId}`;
    }

    return dispatch => {
        dispatch({
            type: UTILIZATION_TABLE_REQUEST
        })
        if (brandId === 2) {
            header = {
                'Content-Type': 'application/json',
                'unitid': deviceId,
                'reqdate': fromdate,
                'filtertype': filterType,
                'showtrend': "true",
                'brandid': brandId
            }

            if (filterType === 'weekly' || filterType === 'custom') {
                delete header.reqdate;
                header.startDate = todate;
                header.endDate = fromdate;
                header.showtrend = "false";
            }
        }
        else {
            header = {
                'Content-Type': 'application/json',
                'unitId': deviceId,
                'reqDate': fromdate,
                'filterType': filterType,
                'showtrend': "false",
                'brandId': brandId
            }

            if (filterType === 'weekly' || filterType === 'custom') {
                if (filterType === 'weekly') {
                    todate = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
                    fromdate = format(todaysDate, 'YYYY-MM-DD');
                }
                delete header.reqdate;
                header.startDate = todate;
                header.endDate = fromdate;
                header.showtrend = "false";
            }
        }


        http.get(url,
            {
                headers: header,
                data: {}
            })
            .then(response => {
                dispatch({
                    type: UTILIZATION_TABLE_SUCCESS,
                    utilizationTableResponse: response.data
                })
                return true;
            })
            .catch(error => {
                dispatch({
                    type: UTILIZATION_TABLE_FAIL,
                    error
                })
            })
    }
}