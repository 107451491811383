import { GET_SEARCH_DATA , SEARCH_VISIBLE, SEARCH_BY_SELECT_CATEGORY, CLEAR_GLOBAL_SEARCH} from '../stateActionsData/type';
export const searchData = ( data ) => ({ type: GET_SEARCH_DATA, payload: data});

// search visible action
export const searchVisible = (value) => {
    return{
        type: SEARCH_VISIBLE,
        payload: value
    }
}

// search text action
export const searchText = (value) => {
    return{
        type: GET_SEARCH_DATA,
        payload: value
    }

}

// clear Global search text (when changing other tabs)
export const clearSearchText = () => dispatch => {
    dispatch({ type: CLEAR_GLOBAL_SEARCH });
}