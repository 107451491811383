var settingsJSON = {
    "id": "",
    "edit_date": "",
    "author": "",
    "email": "",
    "mode": "fs",
    "locked": "true",
    "energy_save": "true",
    "favorites_enabled": "true",
    "all_recipes_enabled": "true",
    "visual_warning_enabled": "false",
    "screen_saver": {
        "enabled": "true",
        "time": 1,
        "picture": "",
        "type": "Bounce"
    },
    "audio_volume": 50,
    "sounds": {
        "end_of_cycle": "media/sounds/end_of_cycle.wav",
        "door_open": "media/sounds/doorOpenwarning.wav",
        "pad_press": "media/sounds/keyclick.wav",
        "error": "media/sounds/errorAlert.wav"
    },
    "sound_keypress": "false",
    "date_format": "dd/mm/yyyy hh:mm 24h",
    "languages": {
        "default": "en_GB"
    },
    "passwords": {
        "manager": "MANAGER"
    },
    "scheduler": [
    ],
    "clean_ice_enabled": "false",
    "clean_protector_enabled": "false",
    "clean_signoff_enabled": "false",
    "global_preheat_band": 50,
    "units": "degC",
    "preheat_list":  [
        {
            "name": {
                "en_US": "Preheat OFF"
            },
            "set_point": {
                "units": "degC",
                "value": "OFF"
            }
        }
    ],
    // "preheat_times": []
}

export default settingsJSON
