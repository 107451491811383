
import React, { Component } from 'react';
import Tooltip from 'react-tooltip-lite';
import { FormattedMessage, injectIntl } from 'react-intl';

class TableHeader extends Component {
    state = {}

    raiseSort = path => {
        const sortColumn = { ...this.props.sortColumn };
        if (sortColumn?.path === path)
            sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
        else {
            sortColumn.path = path;
            sortColumn.order = "asc";
        }

        this.props.onSort(sortColumn)
    };

    checkBoxChange = (e) => {
        this.props.selectAllChange(e);
    };

    sortIcon = column => {
        const { sortColumn } = this.props;

        if (column?.path !== sortColumn?.path) return null;
        if (sortColumn.order) {
            if (sortColumn.order === "asc") return <i className="-sort-asc"></i>;

            return <i className="-sort-desc"></i>
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { sortColumn, columns } = { ...this.props };

        let iconStyle = { boxShadow: "none" };
        if (sortColumn?.order) {
            iconStyle = sortColumn.sort_color ? { boxShadow: `inset 0 -1px 0 0 ${sortColumn.sort_color}` } : iconStyle;
        }

        columns.forEach(c => {
            if (c?.path) {
                if (c.path === sortColumn?.path) {
                    c.iconStyle = iconStyle;
                }
                else {
                    c.iconStyle = { boxShadow: "none" };
                }
            }
        });
        return (
            <React.Fragment>
                    <ul className="tHead">
                        {
                            columns.map((column, i) => {

                                if (column?.path)
                                    return <li key={'key_ths_sort' + i} className="table-header" style={column.iconStyle} onClick={() => this.raiseSort(column.path)}>
                                        {(column.tooltipStringID || column.tooltipText) ? <Tooltip content={column.tooltipStringID ? formatMessage({ id: column.tooltipStringID }) : column.tooltipText} direction="up" className="target">{column.labelStringID ? <FormattedMessage id={column.labelStringID}/> : column.label}</Tooltip> : column.labelStringID ? <FormattedMessage id={column.labelStringID}/> : column.label}
                                        {this.sortIcon(column)}
                                    </li>

                                if (column?.path === "" && (!column.isCheckBox || column.isNbSp))
                                    return <li key={'key_ths_label' + i} >
                                        {column.labelStringID ? <FormattedMessage id={column.labelStringID}/> : column.label}
                                    </li>

                                if (column.isNbSp)
                                    return <li key={'key_ths_nbsp' + i}  >
                                        &nbsp;
                                </li>

                                if (column.isCheckBox)
                                    return <li key={'key_ths_checkbox' + i} >
                                        <div className={!column.isCheckBoxActive ? `customCheckbox customCheckboxWOlabel disabled ${this.props.isIndeterminate ? "selectAllDiv" : "" }` :  `customCheckbox customCheckboxWOlabel ${this.props.isIndeterminate ? "selectAllDiv" : "" } `}>
                                            <input id="selectAll" type="checkbox" onChange={(e) => this.checkBoxChange(e)} checked={this.props.isSelectAll} />
                                            <label htmlFor="selectAll">&nbsp;</label>
                                        </div>
                                    </li>
                            })
                        }
                    </ul>
            </React.Fragment>
        );
    }
}


TableHeader.defaultProps = {
    isIndeterminate: false,
  };
  
const withIntl = injectIntl(TableHeader);

export { withIntl as TableHeader };