import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import KCStore from '../../../../../utils/kcStorage';



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};



class DragDropElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let selectedCategory = KCStore.getItem("selectedCategory");
            let selectedCategoryObj = JSON.parse(selectedCategory);

            let categoryDetails = KCStore.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            let recipeDetails = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);

            this.setState({
                selectedCategoryName: selectedCategoryObj.categoryName,
                items: recipeDetails ? recipeDetails.recipe_items : []
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items,
        });
        this.props.onChangeItems(items)
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="cbMenuCategory"
                        >
                            <ul>
                                {this.state.items.map((item, index) => (
                                    <Draggable key={item.ID} draggableId={item.LIBRARY_NAME} index={index}>
                                        {(provided) => (
                                            <li key={item.ID} ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <div class="cbcategoryBox">
                                                    <span class="navIcon"><img src={item.IMAGE_S3_SIGNED_URL} alt="" /></span>
                                                    <span class="navTitle">{item.LIBRARY_NAME}</span>
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                            </ul>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        );
    }
}

export default DragDropElement;