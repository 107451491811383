import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import GenericModal from '../../modal/generic-model';
import { connect } from 'react-redux';
import NoRecordsFound from '../../controls/noRecordsFound';
import { ReportManagement } from '../../../service/api';
import http from '../../../service/httpService';
import moment from '../../../utils/momentHelper.js';
import authService from '../../../service/authService';
import { TableHeader } from '../../controls/tableHeader';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function} from '../../globalSearchBar/selectOption';
import {ReportDetailsModal} from './reportDetailsModal';
import $ from 'jquery';
import KCStore from '../../../utils/kcStorage';

class scheduledReportList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			reportViewerUrl: [],
			isSelectAllReports: false,
			s3KeyVal: "",
			generic_modal: {
                open: false,
                message: "",
                messagetype: ""
            },
			selectAll: false,
			isEditReport: false,
			isDeleteReport: false,
			isDeleted: false,
			isAddReport: true,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			isIndeterminate: false,
			currentPage : 1,
			pageSize: 25,
            totalPages:null,
			reportItem: {},
			searchData: null,
			selected_item : {}
		};
		this.onGenericCloseModal = this.onGenericCloseModal.bind(this);
		this.deleteSelectedReport = this.deleteSelectedReport.bind(this);
		this.OnReportDetailsClick = this.OnReportDetailsClick.bind(this);
		this.OnEditScheduledReport = this.OnEditScheduledReport.bind(this);
		this.OnAddScheduledReport = this.OnAddScheduledReport.bind(this);
	}

	componentDidMount() {
		$("div").removeClass("modal-backdrop fade show");
		this.props.searchVisible(true);
		document.body.style.backgroundColor = "#F2F2F2";
		window.addEventListener('resize', this.updateDimensions());
		this.getReportList();
		KCStore.setItem("selectedbrandID", "0");
		KCStore.setItem("selectedGroupID", "");
		KCStore.setItem("selectedUnitID", "[]");
	}

	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	componentDidUpdate ( prevState ) {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
			this.setState({
				currentPage: 1
			},() =>{
				this.getReportList(1);
			})
		}
	}

	updateDimensions() {
		var windowHeight = $(window).height();
		var reportHeader = $(".reportHeader").height();
		var reportTableH = $(".reportScdlListTable .tHead").height();
		var reportListTableSrlBarH = ((windowHeight) - (reportHeader + reportTableH)) - 70;
		$(".reportScdlTableSrlBar").css("height", reportListTableSrlBarH);
	}

	onGenericCloseModal = (e, messagetype) => {
        if (messagetype === "Success") {
            this.setState({
				generic_modal: {
					open: false
				},
				isAddReport: true,
				isDeleteReport: false,
				isIndeterminate: false,
				isSelectAllReports: false			
			});
        }
        else {
            this.setState({ generic_modal: { open: false }, isAddReport: true, isDeleteReport: false });
        }
    }

	deleteSelectedReport = () => {
		let { data } = this.state;
		let selectedIds = [];
		let scheduledIds = [];
		data.forEach(e => {
			if (e.checked === true) {
				selectedIds.push(e.id);
				scheduledIds.push(e.schedule_id);
			}
		});

		selectedIds = selectedIds ? selectedIds.join(',') : "";
		const headers = {'reportids' : selectedIds, 'scheduled' : 1, 'schedule_id' : scheduledIds};
		this.putData(headers, {}, ReportManagement.deleteReport, 'DELETE').then(response => {
			if (response && response.data && response.data.status) {
				let reportdata = [...this.state.data];
				let reportList = reportdata.filter(item => item.checked !== true);
				this.setState({
					loaderIconVisible: false,
					isEditReport: false,
					isDeleteReport: false,
					isAddReport: true,
					data: reportList,
					generic_modal: {
						open: true,
						message: "KC2120",
						messagetype: "Success"
					}
				});
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				let { status, message } = data;
				this.openErrorModal(status ? status : message);
			}
		} else {
			this.openErrorModal("KC1065");
		}
	}

	openErrorModal = (message) => {
		this.setState({
			loaderIconVisible: false,
			generic_modal: {
				open: true,
				message,
				messagetype: "Error"
			}
		});
	}

	putData = (headers, postData, url, method) => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				headers: headers,
				data: postData
			}).then(res => {
				let { data } = res;
				let { status } = data;
				if (!status) {
					reject({
						response: {
							data: "KC2159"
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	getReportList = async () => {
		this.setState({ loaderIconVisible: true });
		let { searchData } = this.state;
		let url = ReportManagement.reportLibrary;

		//searchData_function
		let _search = await searchData_function(searchData);

		if( _search ){
			url = `${url}?${_search}`;
		}

		const reportsList = await this.reportListApi(url);
		this.setState({
			loaderIconVisible: false,
			data: reportsList ? reportsList : []
		});
	}

	reportListApi = async (url) => {
		let headerValue = {
			'Content-Type': 'application/json',
			'brandid': '',
			'customerid': KCStore.getItem("customerId"),
			'basecountry': KCStore.getItem("basecountry"),
			'is_scheduled': 1,
			'cgids': KCStore.getItem("custGroupID"),
			'userId':KCStore.getItem("user"),
		};		
		return http.get(url, {
			headers: headerValue, data: {}
		}).then(response => {
			if (response && response.data) {
				let reportsList = response.data.data;
				this.setState({ totalPages: response.data.numberOfPages });
				return reportsList;
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false,totalPages : 0 });
		});
	}

	getReportListNoLoader = async (reportId) => {
		let url = ReportManagement.updateReportList;
		const putData = {
			Id: reportId
		};
		const headers = {
			'Content-Type': 'application/json',
		}
		try {
			const updateReports = await http.put(url, putData, { headers });
			return updateReports.data || [];
		} catch (err) {
			console.log("error occured updateReports method", err);
			this.getReportList();
		}
	}

	reportListApi = async (url) => {
		let headerValue = {
			'Content-Type': 'application/json',
			'brandid': '',
			'customerid': KCStore.getItem("customerId"),
			'basecountry': KCStore.getItem("basecountry"),
			'is_scheduled': 1,
			'cgids': KCStore.getItem("custGroupID"),
			'userId':KCStore.getItem("user"),
		};		
		return http.get(url, {
			headers: headerValue, data: {}
		}).then(response => {
			if (response && response.data) {
				let reportsList = response.data.data;
				this.setState({ totalPages: response.data.numberOfPages });
				return reportsList;
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false,totalPages : 0 });
		});
	}

	handleChange = (e) => {
		var reportsList = [...this.state.data];
		reportsList.forEach(r => {
			r.checked = e.target.checked;
		});
		this.isSelectEditDownload(reportsList);
	};

	onSelectReport = (e, item) => {
		if (this.state.data && this.state.data.length > 0) {
			const reportsList = [...this.state.data];
			const index = reportsList.findIndex(el => el.id === item.id);
			reportsList[index] = { ...reportsList[index] };
			reportsList[index].checked = e.target.checked;
			this.setState({
				s3KeyVal: reportsList[index].s3_key,
				selected_item: item
			}, () => this.isSelectEditDownload(reportsList))
		}
	}

	checkDeleteAccess = async (media) => {
		const userListEmail = JSON.parse(KCStore.getItem('childUsersEmail'));
		const { username } = JSON.parse(KCStore.getItem('loginInfo'));
		const roleName = KCStore.getItem('roleName') || '';

		let isDelete = false;
		for (let i = 0; i < media.length; i++) {
			isDelete = true;
			let mediaValue = media[i];
			if(mediaValue.createdBy && mediaValue.createdBy.toString().match('@')){
				isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(mediaValue.createdBy) || username === mediaValue.createdBy ? true : false;
			}else{
				isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
			}
			if (isDelete === false){
				return isDelete;
			}
		}
		return isDelete;
	}

	isSelectEditDownload = async (reportsList) => {
		let selectedReports = reportsList.filter(item => item.checked === true);
		let isEdit, isDelete, isAdd, isIndeterminate, isSelectAllReports;
		
		isEdit = selectedReports.length === 1 ? true : false;
		isDelete = selectedReports.length > 0 ? true : false;
		isAdd = selectedReports.length > 0 ? false : true;
		
		if(selectedReports.length === reportsList.length) {
			isSelectAllReports= true; isIndeterminate = false;
		} else if(selectedReports.length === 0 && reportsList.length !==0){
			isSelectAllReports= false; isIndeterminate = false;
		} else if(reportsList.length > selectedReports.length) {
			isSelectAllReports= true; isIndeterminate = true;
		} else {
			isSelectAllReports= false; isIndeterminate = false;
		}

		this.setState({
			isEditReport: isEdit,
			isDeleteReport: isDelete,
			isSelectAllReports: isSelectAllReports,
			isAddReport: isAdd,
			data: reportsList,
			isIndeterminate: isIndeterminate
		});
	}

	downloadLogViewer = (urls) => {
		if (urls !== '#') {
			window.location = urls;
			this.setState({
				loaderIconVisible: false,
				generic_modal: {
					open: true,
					message: "KC1578",
					messagetype: "Success"
				},
				isEditReport: false,
				isDeleteReport: false,
				isAddReport: true
			});
			WoopraEvents(`${Constants.DOWNLOAD_THE_REPORT_LOG}`)
		}
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	onPageChange = (currentPage) => {
		this.setState({ loaderIconVisible: true, currentPage },()=>{
			this.getReportList();
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		});
	}

	// Woopra Events
	emailReportConfig = () => {
		WoopraEvents(`${Constants.EMAIL_REPORT_CONFIGURATION}`);
	}

	static getDerivedStateFromProps(props) {
		return { ...props };
	}

	OnReportDetailsClick = (item) => {
		KCStore.setItem("selectedGroupID", item.cgids);
		KCStore.setItem("selectedbrandID", item.brand_id);
		KCStore.setItem("pageType", "View");
		let unitids = item.unitids ? item.unitids.split(',') : [];
        let selectedunitidsarr = [];
        for (let x = 0; x < unitids.length; x++) {
            selectedunitidsarr.push({ID : unitids[x], isNodeSelected : false});
        }
		KCStore.setItem("selectedUnitID", JSON.stringify(selectedunitidsarr));
		this.setState({
			reportItem: item
		});
	}

	getOptionsListByBrand = (brandIdValue, options) => {
		KCStore.setItem("selectedbrandID", brandIdValue);
		return new Promise(async(resolve, reject) => {
		await http.get(ReportManagement.getBrandOptions, {
			headers: {
				'Content-Type': 'application/json',
				'brandid': brandIdValue
			},
			data: {}
		}).then(response => {
			let optionsData = response.data.data;
			optionsData && optionsData.length > 0 && optionsData.map((item,i) => {
				let isExits = options.find(o => o.id === item.id);
				if (isExits && i === 0) {
					item.checked = true;
					item.disabled = true;
				}
				else if (isExits) {
					item.checked = true;
					item.disabled = false;
				}
				else {
					item.checked = false;
					item.disabled = false;
				}
			});
			resolve(optionsData);
		}).catch(err => {
			reject(err);
		});
	});
	}

	getUnitListBycgidunitid = (cgids, unitids) => {
		return new Promise(async(resolve, reject) => {
			await http.get(ReportManagement.getUnitListBycgidunitid, {
				headers: {
					'Content-Type': 'application/json',
					'cgids': cgids,
					'unitids': unitids,
					'brandid': KCStore.getItem("selectedbrandID"),
					'customerid': KCStore.getItem("customerId"),
					'basecountry': KCStore.getItem("basecountry"),
				},
				data: {}
			}).then(response => {
				let selectedunitidsarr = response.data && response.data.units ? response.data.units : [];
				// let unitidsData = unitids ? unitids.split(',') : [];
				selectedunitidsarr && selectedunitidsarr.length > 0 && selectedunitidsarr.map((item,i) => {
					// let isExits = unitidsData.find(o => o === item.UNITID);
					// if (isExits) {
						item.isNodeSelected = true;
					// }
					// else {
					// 	item.isNodeSelected = true;
					// }
				});
				resolve(selectedunitidsarr);
			}).catch(err => {
				reject(err);
			});
		});
	}

	OnAddScheduledReport = () => {
		KCStore.setItem("pageType", "Add");
		KCStore.setItem("selectedbrandID", "");
		this.props.history.push({
			pathname: "/newReportWithOptions",
			state: { 
				reportType: 1,
				recipientsEmail: KCStore.getItem("user")
			}
		});
	}

	OnEditScheduledReport = async() => {
		this.setState({ loaderIconVisible: true});
		let { selected_item } = this.state;
		KCStore.setItem("pageType", "Edit");
		KCStore.setItem("selectedGroupID", selected_item.cgids);
		KCStore.setItem("selectedbrandID", selected_item.brand_id);
		
		let selectedoptionsFromReport = await this.getOptionsListByBrand(selected_item.brand_id, selected_item.options);
		let selectedunitidsarr = await this.getUnitListBycgidunitid(selected_item.cgids, selected_item.unitids);
		KCStore.setItem("selectedUnitID", JSON.stringify(selectedunitidsarr));

		this.props.history.push({
			pathname: "/newReportWithOptions",
			previouspath: "/scheduledReportList",
			state: {
				reportType: 1,
				nameFromReport: selected_item.name,
				startDateFromReport: moment.ConvertLocalTimeFromUTCTime(selected_item.start_datetime, "YYYY-MM-DD hh:mm:ss A"),
				startDateFromReportFormat: 'YYYY-MM-DD hh:mm:ss A',
				endDateFromReport: selected_item.end_datetime !== "9999-12-31T00:00:00.000Z" ? moment.ConvertLocalTimeFromUTCTime(selected_item.end_datetime, "YYYY-MM-DD") : "",
				selectedoptionsFromReport: selectedoptionsFromReport,
				generateNow: false,
				scheduleReport: true,
				recipientsEmail: selected_item.recipients_email,
				comment: selected_item.memo,
				selectedRepetitionPattern: selected_item.frequency,
				selectedLastPeriod: selected_item.data_period === "Last" ? selected_item.data_period_frequency: 'daily',
				selectedCurrentPeriod: selected_item.data_period === "Current" ? selected_item.data_period_frequency: 'weekly',
				lastDays: selected_item.data_period === "Current" ? false : true,
				currentDays: selected_item.data_period === "Current" ? true : false,
				lastDaysPeriod: selected_item.data_period_days,
				hourFormat: KCStore.getItem("timeFormat") === "24" ? false : true,
				scheduledHourValue: KCStore.getItem("timeFormat") === "24" ? moment.ConvertLocalTimeFromUTCTime(selected_item.start_datetime, "HH") : moment.ConvertLocalTimeFromUTCTime(selected_item.start_datetime, "hh"),
				scheduledMinsValue: moment.ConvertLocalTimeFromUTCTime(selected_item.start_datetime, "mm"),
				noEndDate: selected_item.end_datetime !== "9999-12-31T00:00:00.000Z" ? false : true,
				endDate: selected_item.end_datetime !== "9999-12-31T00:00:00.000Z" ? true : false,
				AMPMformat: KCStore.getItem("timeFormat") === "24" ? "" : moment.ConvertLocalTimeFromUTCTime(selected_item.start_datetime, "A"),
				isEdit : true,
				id: selected_item.id,
				schedule_id: selected_item.schedule_id
			}
		});
	}

	render() {
		let { isDeleted, generic_modal, loaderIconVisible, data, isEditReport, isSelectAllReports, isAddReport, isDeleteReport, sortColumn, isIndeterminate, currentPage, pageSize, reportItem } = this.state;
		let userProfileAuthFeature = authService.getFeature("/reportList");
		const { formatMessage } = this.props.intl;
		const { locale } = this.props;
		let orderId = 1;
		let columns = [];
		columns = [
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0102" },
			{ path: "name", labelStringID: "KC0241" },
			{ path: "frequency", labelStringID: "KC2116" },
			{ path: "date_created", labelStringID: "KC2117" },
			{ path: "brand_name", labelStringID: "KC0062" },
			{ path: "", labelStringID: "KC0325" },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		data = _.orderBy(data, [sortColumn.path], [sortColumn.order]);

		data.forEach(item => {
			item.order_id = orderId++
		});

		if (isDeleted && isDeleted.message) {
			generic_modal = { open: true, message: isDeleted.message };
		}
		let rowNumber = (currentPage * pageSize) - pageSize;   
		return (
			<div id="adminWrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper" class="withSearchBar">
					<div className="reportWrapper">
						<div className="reportHeader">
							<ul>
								<li>
									<Link to="/reportList" title={formatMessage({id: 'KC2114'})}><FormattedMessage id='KC2114'/></Link>
									<Link to="/scheduledReportList" title={formatMessage({id: 'KC2115'})} className="active"><FormattedMessage id='KC2115'/></Link>
								</li>
								<li>
									<button type="button" title={formatMessage({ id: "KC1723"})} className={userProfileAuthFeature.is_editable === 1 && isEditReport ? "btn btn-default-text edit" : "btn btn-default-text edit disabled"} onClick={() => this.OnEditScheduledReport()}></button>
									<button type="button" title={formatMessage({ id: "KC0524"})} className={userProfileAuthFeature.is_editable === 1 && isDeleteReport ? "btn btn-default-text delete" : "btn btn-default-text delete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal">&nbsp;</button>
									<button type="button" title={formatMessage({ id: "KC2171"})} className={userProfileAuthFeature.is_editable === 1 && isAddReport ? "btn btn-default-text add" : "btn btn-default-text add disabled"} onClick={() => this.OnAddScheduledReport()}>&nbsp;</button>
								</li>
							</ul>
						</div>
						<form className="reportListForm">
						<div className="reportScdlListTable">
							<TableHeader
								sortColumn={sortColumn}
								onSort={this.onSort}
								selectAllChange={this.handleChange}
								columns={columns}
								isSelectAll={isSelectAllReports}
								isIndeterminate={isIndeterminate} />
						</div>
						<div className="reportScdlTableSrlBar">
							<div className="reportScdlListTable">
								{data && data.map((item, i) => (
									<Fragment key={item.id}>
										<ul className="tBody" key={item.id}>
											<li>{rowNumber + i + 1}</li>
											<li><span class="reportType"><img src={require("../../../images/file/iconExcelReport.svg").default} alt="" /></span></li>
											<li><div class="reportListBSM">{item.brand_name}</div>{item.name}{item.filetype}{KCStore.getItem("timeFormat") === "24" ? 
											<div class="reportListSM">{item.next_report_date && item.next_report_date !== "--" ? moment.ConvertLocalTimeFromUTCTime(item.next_report_date, "MMM DD, YYYY HH:mm") : "..."}</div>
											: <div class="reportListSM">{item.next_report_date && item.next_report_date !== "--" ? moment.ConvertLocalTimeFromUTCTime(item.next_report_date, "MMM DD, YYYY hh:mm A") : "..."}</div>}
											</li>
											<li>{item.frequency ? _.upperFirst(item.frequency) : ""}</li>
											{KCStore.getItem("timeFormat") === "24" ? 
											<li>{item.next_report_date && item.next_report_date !== "--" ? moment.ConvertLocalTimeFromUTCTime(item.next_report_date, "MMM DD, YYYY HH:mm") : "..."}</li>
											: <li>{item.next_report_date && item.next_report_date !== "--" ? moment.ConvertLocalTimeFromUTCTime(item.next_report_date, "MMM DD, YYYY hh:mm A") : "..."}</li>}
											<li>{item.brand_name}</li>
											<li><button type="button" className="btn reportDetails" data-toggle="modal" data-target="#reportDetailsModal" onClick={() => this.OnReportDetailsClick(item)}>&nbsp;</button></li>
											<li>
												<div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
													<input id={item.id} name={item.id} type="checkbox"
														checked={item.checked}
														onChange={(e) => this.onSelectReport(e, item)} />
													<label htmlFor={item.id}></label>
												</div>
											</li>
										</ul>
									</Fragment>
								))}
							</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={data !== undefined ? data.length : 0} classname={'norecordsfoundbluetext'} stringID={this.state.searchData && data && data.length === 0 ? "KC0853" : "KC0205"} />
							</div>
						</form>
					</div>
				</div>
				<div className="modal fade confirmationRMModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">  
							<div class="modal-header modal-header-danger">
								<FormattedMessage id="KC2174"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
							</div>
							<div class="modal-body"> 
								<div class="reportStatusModal"> 
									<span class="rmStatusIcon"><img src={require("../../../images/icons/iconDeleteReport.svg").default} /></span>
									<h6><FormattedMessage id="KC2185"/></h6>  
								</div>   
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
								<button className="btn btn-secondary deleteYesBtn" data-dismiss="modal" onClick={() => this.deleteSelectedReport()} type="button"><FormattedMessage id="KC0611"/></button>
							</div>
						</div>
					</div>
				</div>
				<ReportDetailsModal reportDetails={reportItem ? reportItem : []} page={'scheduled'} localeDet = {locale} />
				<GenericModal open={generic_modal.open} stringID={generic_modal.message} messagetype={generic_modal.messagetype} onGenericCloseModal={(e) => this.onGenericCloseModal(e, generic_modal.messagetype)} />
				<UserManagementHeader headerNameStringID='KC1000' headerName="Report Management" activeClass="reportNav" history={this.props.history} />
			</div>
		)
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			searchVisible,
			searchText
	}, dispatch);
}

const mapStateToProps = state => {
	return {
		isDeleted: state.deleteReport.reportDelete,
		searchData: state.advanceSearch.searchData,
		locale: state.localeInfo.locale,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(scheduledReportList));

