import { smartGroupAPI } from '../../service/api';
import http from '../../service/httpService';
import _ from 'lodash';
import KCStore from '../../utils/kcStorage';

import { SET_SMART_UNIT_DETAILS, SET_SMART_UNIT_DETAILS_LOADING, MODEL_FAIL} from '../stateActionsData/type';
const setUnitData = ( data ) => ({ type: SET_SMART_UNIT_DETAILS, payload: data});
const setUnitLoader= ( bol ) => ({ type: SET_SMART_UNIT_DETAILS_LOADING, payload: bol});

export const  getUnitDetails =  (sgid)  => dispatch => {
    let custGroupID = KCStore.getItem("custGroupID");
    dispatch( setUnitData(null));
    dispatch( setUnitLoader(true));

    http.get(smartGroupAPI.createSmartGroupWithUnit,
        {
          headers: {
              'Content-Type': 'application/json',
              'sgid':sgid,
              'cgid': custGroupID
            }, data: {}
        }
        ).then( response => {
            let { data } = response;
            let _data = data;

            if( data && data.units && data.units.length > 0) {
                let units  = _.orderBy(data.units, ['createdAt'],['desc']);
                units = units.slice(0, 3)
                _data = { units, total_unit_count: data.total_unit_count};
            }
            
            dispatch( setUnitLoader(false));
            dispatch(setUnitData(_data));

        }).catch(error => {
            dispatch( setUnitLoader(false));
            dispatch({
                type: MODEL_FAIL,
            });
        });
}