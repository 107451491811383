import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { injectIntl } from 'react-intl';

const FiltersChildComponents = (props) => {
	const { formatMessage } = props.intl;
	let { list } = props;
	let [searchValue, setSearchValue] = useState('');
	let searchList = searchValue === "" ? list : list.filter((e) => e.value.toLowerCase().includes(searchValue.toLowerCase()));

	let countryList = null;
	let unitList = null;
	if (searchList && searchList.length) {
		countryList = (
			searchList.filter((el)=>el && el.type !== "unit").map(el => {
				const ID = el.id + ' '+ el.cust_gp_id + 'key';
				return (
					<div key={ID} class="customCheckbox customCheckboxWlabel">
						<input id={ID} class="chkGroupName" value={el.value} checked={el.isChecked || false} onChange={() => props.changed(el)} name="testgroupName" type="checkbox" />
						{el && el.type ? 
							<label htmlFor={ID}>
								<div className={el.type === 'org' ? "tagTypeLocation" : "tagTypeUnit"}><span>{el.value}</span> </div>
							</label>
							:<label htmlFor={ID}>{el.value}</label>
						}
					</div>
				)
			}
			));
	}
	if (searchList && searchList.length) {
		unitList = (
			searchList.filter((el)=>el && el.type === "unit").map(el => {
				const ID = el.id + ' '+ el.cust_gp_id + 'key';
				return (
					<div key={ID} class="customCheckbox customCheckboxWlabel">
						<input id={ID} class="chkGroupName" value={el.value} checked={el.isChecked || false} onChange={() => props.changed(el)} name="testgroupName" type="checkbox" />
						{el && el.type ? 
							<label htmlFor={ID}>
								<div className={el.type === 'org' ? "tagTypeLocation" : "tagTypeUnit"}><span>{el.value}</span> </div>
							</label>
							:<label htmlFor={ID}>{el.value}</label>
						}
					</div>
				)
			}
			));
	}

	return (
		<React.Fragment>
			<div id="wrapper" className="disableNavLink">
				<div class="toggleTransparent"></div>
				<div class="loadingProgress">
					<div class="loadingProgressIn"></div>
				</div>
				<div style={{ display: 'block' }}>
					<div id="CountryFilter" style={{ display: 'block' }}>
						<div class="filterListOuter">
							<input class="form-control headerFilterSearch" type="text" placeholder={ formatMessage({ id: 'KC0037' }) } onChange={(e) => setSearchValue(e.target.value)} />
							<div class={`countryCheckbox ${searchList && searchList.length > 3 ? `withMorethanThree`: ``}`}>
								{countryList}
							</div>
							<div class={`countryCheckbox ${searchList && searchList.length > 3 ? `withMorethanThree`: ``}`}>
								{unitList}
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}
export default injectIntl(FiltersChildComponents);