import { SET_LOCALE, RESET_LOCALE_INFO, SET_LANGUAGE_LIST } from '../../../actions/localeAction/actionType';
import { DEFAULT_LOCALE } from '../../../locale/constant';

const initialState = {
    languages: [],
    locale: DEFAULT_LOCALE,
    defaultLocale: true,
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case SET_LANGUAGE_LIST:
            return { ...state, languages: action.payload };
        case SET_LOCALE:
            return { ...state, locale: action.payload, defaultLocale: false };
        case RESET_LOCALE_INFO:
            return { ...initialState }
        default:
            return state;
    }
};