import React from "react";
import Offline from "react-offline";

const CheckInternetConnection = (props) => {

    return (
        <Offline>
            {({ isOffline, isOnline }) =>   {
                console.log({isOffline,isOnline})
                return isOffline ? (

                    <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="NoInternetConnectionModal" tabindex="-1" role="dialog" aria-labelledby="NoInternetConnectionModalLabel">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="noInternetConnectionDiv">
                                        No internet connection found. Check your connection or try again.
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                ) :  null;
            }}
        </Offline>
    )
}
export default CheckInternetConnection;