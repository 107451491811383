import * as _ from 'lodash';
import http from '../../../service/httpService';
import { BRAND_ID } from '../../../utils/appConstants';
import { LocationInfo } from '../../../service/api';
import KCStore from '../../../utils/kcStorage';
export const REGION_REQUEST = 'REGION_REQUEST';
export const REGION_SUCCESS = 'REGION_SUCCESS';
export const REGION_FAIL = 'REGION_FAIL';

export function getRegion(req = '', filterKey,isCascadeActionRequest) {
    return dispatch => {
        dispatch({
            type: REGION_REQUEST,
            isCascadeActionRequest : isCascadeActionRequest ? true : false
        })


        let cgIds = '';
        const ffModel = KCStore.getItem(filterKey);
        const filterModel1 = JSON.parse(ffModel) || [];
        if (filterModel1.length) {
            let dataSource = _.find(filterModel1, { field_name: 'country' }).dataSource
            cgIds = _.isArray(dataSource) && dataSource.length ? dataSource.map(el => el.cust_gp_id).join(',') : '';
        }
        if (cgIds==='')
        {
            cgIds = KCStore.getItem("custGroupID")
        }
        
        http
            .get(`${LocationInfo.filters}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Type': 3,
                        'cid': KCStore.getItem("customerId"),
                        'cgid': req ? req : cgIds,
                        'brandid': BRAND_ID,
                    },
                    data: {}
                })
            .then(response => {
                dispatch({
                    type: REGION_SUCCESS,
                    regionInformation: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: REGION_FAIL,
                    error
                })
            })
    }
}
