import http from '../../../service/httpService';
import {LocationInfo} from '../../../service/api';
import { BRAND_ID } from '../../../utils/appConstants';

import KCStore from '../../../utils/kcStorage';

export const COUNTRY_REQUEST = 'COUNTRY_REQUEST';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const COUNTRY_FAIL = 'COUNTRY_FAIL';
export const SELECTED_COUNTRY_LIST ='SELECTED_COUNTRY_LIST';

export function getCountry (req) {
    return dispatch => {
        dispatch({
            type: COUNTRY_REQUEST
        })

              
        http.get(`${LocationInfo.filters}`,
                {
                    headers: {
                        'Content-Type':'application/json',
                        'Type': 2,
                        'cid':KCStore.getItem("customerId"),
                        'cgid': KCStore.getItem("custGroupID"),
                        'brandid': BRAND_ID,
                    },
                    data:{}
                }
                )
            .then(response => {
                dispatch({
                    type: COUNTRY_SUCCESS,
                    countryInformation: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: COUNTRY_FAIL,
                    error
                })
            })
    }
}
 