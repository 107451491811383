import React, { Component } from 'react';
import Modal from "react-responsive-modal";
import { FormattedMessage } from 'react-intl';

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class ErrorMoal extends Component {

    state = {
        open: false
    };

    onCloseModal = () => {
        this.props.onCloseModel();
        //this.props.history.push(`/`);
    };

    render() {
        const { open, message, isHtml=false, stringID } = this.props;
        return (
            <div style={styles}>
                <Modal open={open} showCloseIcon={false} center onClose ={()=>{}}>
                    {open && <div className="modal fade SignInSuccessModal show" data-keyboard="true" data-backdrop="static" id="SignInSuccessModal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog modal-md loginModal">
                            <div className="modal-content">
                                <div className="modal-body">
                                    {isHtml ? <div className="errorModalContent" dangerouslySetInnerHTML={{ __html: stringID || message }} /> :<div className="errorModalContent">{stringID? <FormattedMessage id={stringID}/>: message}</div>}
                                </div>
                                <div className="modal-footer textAlignCenter">
                                    <button className="btn btn-secondary errorModalContentOKBtn" data-dismiss="modal" type="button" onClick={ () => this.onCloseModal()}><FormattedMessage id="KC0777"/></button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </Modal>
            </div>
        );
    }
}
export default ErrorMoal;