import _ from 'lodash';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import http from '../../service/httpService';
import pointerDevice from '../../utils/pointerDeviceFinder';
import { HelpDocumentation } from '../../service/api';
import LanguagePreferenceCommon from '../../components/languageDropdown/LanguagePreferenceCommon';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LogoutForm from "../logout/logoutForm";
import KCStore from '../../utils/kcStorage';

import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';

class HowToPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            isCookieAccepted: KCStore.getItem('isCookieAccepted') ? KCStore.getItem('isCookieAccepted') : false,
            featureList: [],
            howToFeature: [],
            isPublicUser: false
        };
    }

    async componentDidMount() {
        $('body').addClass("helpBg");
        if (!KCStore.getItem('isCookieAccepted')) {
            $("#cookieWrapper").hide();
            $("#cookieWrapperBtn").show();
            $('.supportButtonWrapper').hide();
        }
        this.updateDimesion();
        await this.getToken();
        await this.getUserAccessFeature();
        await this.getHowToFeatures();
        this.setState({ loaderIconVisible: false });
    }

    updateDimesion = () => {
        var windowHeight = $(window).height();
        var gidviewListHeight = ((windowHeight) - 110);
        $(".kcHowToWrapper").css("min-height", gidviewListHeight);
    };

    getUserAccessFeature = async () => {
        const { roleId } = this.state;
        await http({
            method: "GET",
            url: `${HelpDocumentation.userFeatureAuth + roleId + '/help-features'}`,
            headers: {
                'Content-Type': 'application/json',
            }, data: {},
        }).then(async response => {
            if (response && response.data) {
                this.setState({ featureList: response.data.data.features });
                return;
            }
        }).catch(err => {
        });
    };

    // checkAccess = async (feature) => {
    //     const { featureList } = this.state;
    //     const filter = await featureList.filter((i) => i.feature_name.replace(/ /g, '').toLowerCase() === feature.feature_name.replace(/ /g, '').toLowerCase());
    //     return filter.length > 0 ? true : false;
    // }

    processData = async (features) => {
        const obj = Object.keys(features);
        const data = [];
        for (let index = 0; index < obj.length; index++) {
            const element = obj[index];
            let dataObj = {
                'section': element
            };
            dataObj['features'] = features[element];
            data.push(dataObj);
            // if (element === 'General') {
            //     dataObj['features'] = features[element];
            //     data.push(dataObj);
            // } else {
            //     const accessFeature = [];
            //     for (let j = 0; j < features[element].length; j++) {
            //         const featureValue = features[element][j];
            //         const checkAccess = await this.checkAccess(featureValue);
            //         if (checkAccess || (featureValue.feature_name === 'Using Smart Tags for File Transfers')) { 
            //             accessFeature.push(featureValue) 
            //         }
            //     }
            //     dataObj['features'] = accessFeature;
            //     if (accessFeature.length) {
            //         data.push(dataObj);
            //     }
            // }
        }
        return data;
    };

    getHowToFeatures = async () => {
        await http({
            method: "GET",
            url: `${HelpDocumentation.helpFeature}`,
            headers: {
                'Content-Type': 'application/json',
            }, data: {},
        }).then(async response => {
            if (response && response.data) {
                const formattedData = await this.processData(response.data.data);
                this.setState({ howToFeature: this.chunksOfSize(formattedData, 2) });
                return;
            }
        }).catch(err => {
        });
    };

    getToken = async () => {
        var urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("params");
        if (token) {
            if (KCStore.getItem('isCookieAccepted')) {
                KCStore.clear();
                KCStore.setItem('isCookieAccepted', true);
            } else {
                KCStore.clear();
            }
            await http({
                method: "GET",
                url: `${HelpDocumentation.validateHelpToken}`,
                headers: {
                    'Content-Type': 'application/json',
                    token
                }, data: {},
            }).then(async response => {
                if (response && response.data) {
                    this.setState({ isPublicUser: true, roleId: response.data.role_id, customerId: response.data.customer_id });
                    return;
                }
            }).catch(err => {
            });
        } else {
            this.setState({ roleId: KCStore.getItem("roleId"), customerId: KCStore.getItem("customerId") });
            return;
        }
    };

    acceptCookie = () => {
        this.setState({
            isCookieAccepted: true
        });
        KCStore.setItem('isCookieAccepted', true);
        $("#cookieWrapper").show();
        $("#cookieWrapperBtn").hide();
        $('.supportButtonWrapper').show();
    };

    chunksOfSize = (items, size) => {
        const chunks = [];
        items = [].concat(...items);

        while (items.length) {
            chunks.push(
                items.splice(0, size)
            );
        }

        return chunks;
    };

    getFeatureImage = (featureName) => {
        if (featureName === 'Portal Overview') {
            return 'icoPortalOverview.png';
        }
        if (featureName === 'User Profile') {
            return 'icoUserProfile.png';
        }
        if (featureName === 'Unit Dashboard Overview') {
            return 'plugins.svg';
        }
        if (featureName === 'User Management') {
            return 'users.svg';
        }
        if (featureName === 'KC API Portal') {
            return 'swagger.png';
        }
        if (featureName === 'Unit Registration') {
            return 'UnitRegistrationAssignment.svg';
        }
        if (featureName === 'Menu & Unit Settings') {
            return 'menu.svg';
        }
        if (featureName === 'Media Management') {
            return 'cloud.svg';
        }
        if (featureName === 'Software Management') {
            return 'plugins.svg';
        }
        if (featureName === 'Settings Product Selection') {
            return 'settings.svg';
        }
        if (featureName === 'HACCP Management') {
            return 'haccp.svg';
        }
        if (featureName === 'Report Management') {
            return 'report.svg';
        }
        if (featureName === 'Error Center') {
            return 'errors.svg';
        }
        if (featureName === 'Automated Notification Management') {
            return 'notifications.svg';
        }
        if (featureName === 'Organization Management') {
            return 'OrganizationMgmt.svg';
        }
        if (featureName === 'Account Creation') {
            return 'icoAccountCreation.png';
        }
        if (featureName === 'Smart Tag Management') {
            return 'smartTagNew.svg';
        }
        if (featureName === 'Using Smart Tags for File Transfers') {
            return 'transfer.png';
        }
        return 'icoPortalOverview.png';
    };

    showLoginbg = (event, isHidden, isHiddenforFilters) => {
        if (this.state.isHidden === true) {
            this.setState({
                isHidden: !isHidden
            });
            $('#backdropElement').click();
        }
    };

    showLoginForm = (event, isHidden) => {
        this.setState({
            isHidden: !isHidden
        });
        if (this.state.isHidden) {
            $('#backdropElement').click();
        }
        event.preventDefault();
    };

    nextPath(path) {
        this.props.history.push("/mainMenu");
    }

    editInformation() {
        this.props.history.push('/myinformationedit');
    }

    hideLoginForm = (event) => {
        event.preventDefault();
        this.setState({
            isHidden: false
        });
        if (pointerDevice.IsTouchDevice() && event.target) {
            let targetClass = event.target.getAttribute('class');
            if (targetClass && targetClass.indexOf('navFilter') !== -1) {
                this.showFilter(event, false);
            }
        }
    };

    render() {
        const { loaderIconVisible, howToFeature, isPublicUser } = this.state;
        const { formatMessage } = this.props.intl;
        let loginUser = KCStore.getItem("loginInfo") ? JSON.parse(KCStore.getItem("loginInfo")) : { 'username': '' };
        let loggedinUser = loginUser.username;

        let logoutForm = null;
        if (this.state.isHidden) {
            logoutForm = (<LogoutForm hideFlag={this.state.isHidden} hideProfileEditBtn={false} editinfo={this.editInformation} nextPath={this.nextPath} hideLoginForm={(e) => this.hideLoginForm(e)} />);
        }

        return (
            <div id="adminWrapper">
                <div className="toggleTransparent"></div>
                <div className="sideNavToggleTransparent"></div>
                <div className="userToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                {/* <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <a className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" href={isPublicUser ? "/" : "/mainmenu"}>&nbsp;</a>
                </nav> */}
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-Welbilt bgAdmin" onClick={(e) => { this.showLoginbg(e, this.state.isHidden); }}>
                    <div className="navbar-header">
                        <Link className="navbar-brand welbiltRLogo" to={isPublicUser ? "/" : "/mainMenu"} title={formatMessage({ id: 'KC1061' })} >&nbsp;</Link>
                        {/* <a className="navbar-brand welbiltRLogo" title="Welbilt - Bringing innovation to the table" href="mainmenu.html">&nbsp;</a> */}
                        <button className="navbar-brand sideNavBar border0">&nbsp;</button>
                    </div>
                    <ul className="navbar-nav mr-auto titlebarLeft">
                        <li className="nav-item">
                            <Link className="nav-link" to={isPublicUser ? "/" : "/mainMenu"} title={formatMessage({ id: 'KC2583' })} ><FormattedMessage id='KC2583' /></Link>
                        </li>
                    </ul>
                    {isPublicUser ? null : <ul className="navbar-nav mr-auto navbarRight">
                        {/*  Multilingual dropdown*/}
                        <li className="nav-item">
                            <LanguagePreferenceCommon />
                        </li>
                        {/*  Multilingual dropdown*/}
                        <li className="nav-item">
                            <Link className={this.state.isHidden ? "nav-link navUser active" : "nav-link navUser"} title={loggedinUser} to="#" onClick={(e) => { this.showLoginForm(e, this.state.isHidden); }}><span id="spannavNotifiStatus"></span>&nbsp;</Link>
                        </li>
                    </ul>}
                </nav>
                <div id="page-content-wrapper">
                    <div className="kcHowToWrapper">
                        <div className="kcHowToGridOuter">
                            {loaderIconVisible ? null : <h2><FormattedMessage id="KC2584" /></h2>}
                            <div className="kcHowToGrid">
                                <div className="kcHowToTable">
                                    {howToFeature && howToFeature.length ?
                                        howToFeature.map((howToFeature, index) => {
                                            return (
                                                <ul key={index}>
                                                    {howToFeature && howToFeature.length ? howToFeature.map((feature, index) => {
                                                        return (
                                                            <li>
                                                                <h5>{feature.section}</h5>
                                                                <div className="kcHowToList">
                                                                    <ul>
                                                                        {feature.features && feature.features.length ? feature.features.map((list, index) => {
                                                                            const featureImage = this.getFeatureImage(list.feature_name);
                                                                            return (
                                                                                list.featurePresignedUrl && list.featurePresignedUrl !== "" ?
                                                                                    <li key={index}><a target={'_blank'} href={'/viewer?value=' + btoa(list.featurePresignedUrl)}><span><img src={require(`../../images/mainmenu/${featureImage}`)} alt={list.feature_name} title={list.feature_name} /></span>{list.feature_name}</a></li>
                                                                                    // <li key={index}><a target={'_blank'} href={list.url}><span><img src={require(`../../images/mainmenu/${featureImage}`)} alt={list.feature_name} title={list.feature_name} /></span>{list.feature_name}</a></li>
                                                                                    : <li key={index}><span><img src={require(`../../images/mainmenu/${featureImage}`)} alt={list.feature_name} title={list.feature_name} /></span>{list.feature_name}</li>
                                                                            );
                                                                        }) : null}
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        );
                                                    }) : null}
                                                </ul>
                                            );
                                        })
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    {loaderIconVisible ? null : <div className="footerWrapper" id="cookieWrapper">
                        <div className="footerDiv">Copyright &copy; 2022 Welbilt Inc. or its affiliates| All Rights Reserved | Welbilt <a className="footerPrivacyNotice" href={'https://www.welbilt.com/privacy-and-legal-notices#Privacy-Policy'} target="_blank" title="Privacy Notice">Privacy Notice</a> | <a target="_blank" href={'https://www.welbilt.com/privacy-and-legal-notices#Cookies-Policy'} className="footerCookiePolicy" title="Cookie Policy">Cookie Policy</a></div>
                    </div>}
                    <div className="cookieWrapperBtn" id="cookieWrapperBtn">
                        <ul>
                            <li>
                                <p>This website uses essential cookies. We would like to use non-essential cookies so that you are able to use certain features of the web site included but not limited to see
                                    personalized content, switch between site cultures. It’s recommended that you allow all cookies by clicking Accept. Further details on cookies can be found <a href={'https://www.welbilt.com/privacy-and-legal-notices#Cookies-Policy'} target="_blank" title="Privacy Notice">here</a>.</p>
                            </li>
                            <li>
                                <button className="btn essentialOnlyBtn" type="button" onClick={() => this.acceptCookie()} id="essentialOnlyBtn">Essential Only</button>
                                <button className="btn acceptBtn" type="button" onClick={() => this.acceptCookie()} id="acceptBtn">Accept</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div ref={node => { this.pop = node; }}>
                    {logoutForm}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HowToPage));
