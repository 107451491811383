import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class Languages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            language: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onLanguageSelect = this.onLanguageSelect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let jsonLanguage = JSON.parse(jsonSettingsFile).languages;

            this.setState({
                language: jsonLanguage.default ? jsonLanguage.default : 'en_US'
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onLanguageSelect = (event) => {
        this.setState({
            language: event.target.value
        });
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true,
        });
        let { language } = this.state;
        if (language) {
            let jsonSettingsFile = KCStore.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            if (!ObjSettingsFile.languages) {
                ObjSettingsFile.languages = {};
            }
            ObjSettingsFile.languages.default = language;
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            KCStore.setItem("librarySettingsData", strSettingsFile);
            this.props.history.push(`/settingsEditor`);
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1309"
                }
            });
        }
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/temperatureBand"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }
    
    render() {
        let { language, error_modal, success_modal } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        const languages = [
            {
                language: 'English (GB)',
                locale: 'en_GB'
            },
            {
                language: 'English (US)',
                locale: 'en_US'
            },{
                language: 'العربية',
                locale: 'ar_AE'
            },{
                language: 'Čeština',
                locale: 'cs_CZ'
            },{
                language: 'Dansk',
                locale: 'da_DK'
            },{
                language: 'Deutsch',
                locale: 'de_DE'
            },{
                language: 'LATAM Español',
                locale: 'es_LA'
            },{
                language: 'Español',
                locale: 'es_ES'
            },{
                language: 'Suomi',
                locale: 'fi_FI'
            },{
                language: 'Français',
                locale: 'fr_FR'
            },{
                language: 'Canadien français',
                locale: 'fr_CA'
            },{
                language: 'Hrvatski',
                locale: 'hr_HR'
            },{
                language: 'Magyar',
                locale: 'hu_HU'
            },{
                language: 'Italiano',
                locale: 'it_IT'
            },{
                language: '日本語',
                locale: 'ja_JP'
            },{
                language: '한국어',
                locale: 'ko_KR'
            },{
                language: 'Nederlands',
                locale: 'nl_NL'
            },{
                language: 'Bokmål',
                locale: 'nb_NO'
            },{
                language: 'Polski',
                locale: 'pl_PL'
            },{
                language: 'Português',
                locale: 'pt_PT'
            },{
                language: 'Português do Brasil',
                locale: 'pt_BR'
            },{
                language: 'Русский',
                locale: 'ru_RU'
            },{
                language: 'Slovak',
                locale: 'sk_SK'
            },{
                language: 'Slovenčina',
                locale: 'sl_SI'
            },{
                language: 'Svenska',
                locale: 'sv_SE'
            },{
                language: 'Türkçe',
                locale: 'tr_TR'
            },{
                language: '中文（简体）',
                locale: 'zh_CN'
            },{
                language: 'ไทย',
                locale: 'th_TH'
            },{
                language: 'Tiếng Việt',
                locale: 'vi_VN'
            },{
                language: 'Indonesia',
                locale: 'id_ID'
            },{
                language: 'עברית',
                locale: 'he_IL'
            },{
                language: 'Ελληνικά',
                locale: 'el_GR'
            },{
                language: 'Română',
                locale: 'ro_RO'
            },{
                language: 'Українська',
                locale: 'uk_UA'
            },{
                language: 'Melayu',
                locale: 'ms_MY'
            },{
                language: 'हिंदी',
                locale: 'hi_IN'
            },{
                language: 'اردو',
                locale: 'ur_PK'
            }
        ]


       
        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1310"/>
				                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="textWRadioBtnList">
                                                    {languages.map((item, index) => {
                                                        return (
                                                            <ul key={index}>
                                                                <li>{item.language}</li>
                                                                <li>
                                                                    <div class="customRadioBtn">
                                                                        <input id={item.locale} type="radio" name="PrimaryLanguage" value={item.locale} checked={language == item.locale} onChange={(e) => this.onLanguageSelect(e)} />
                                                                        <label for={item.locale}>&nbsp;</label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(Languages);
