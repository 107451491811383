import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../layoutSection/userManagementHeader'
import $ from 'jquery';


class underConstruction extends Component {
	 
	componentDidMount() {
		$("body").removeClass("loginBg");
		$("body").removeClass("modal-open");
		$("body").addClass("mainMenuBg");
	}

	render() {
		return (
			<div id="wrapper">
				<UserManagementHeader headerName="Under Construction" activeClass="UserManagementNav" />
				<div id="page-wrapper">
					<div class="underConstruction">
						<div class="underConstructionImg"></div>
						<h2>Coming Soon</h2>
						<h3>We are still working on it.</h3>
						<div class="clear"></div>
					</div>
				</div>
			</div>
		)
	}
};

export default underConstruction;
