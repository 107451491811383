import { SET_SMART_GROUP_DELETE_UNIT, SET_SMART_UNIT_DETAILS_LOADING } from '../../../actions/stateActionsData/type';
const initialState = {
    unitListDelete:null,
    isLoading: false
}
export default function(state = initialState, action){
    switch(action.type) {
        case SET_SMART_GROUP_DELETE_UNIT:
        return {
            ...state,
            unitListDelete: action.payload
        }
        case SET_SMART_UNIT_DETAILS_LOADING: 
        return {
            ...state,
            isLoading: action.payload
        }
        default:
        return state;
    }
}

