import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import KCStore from '../../../utils/kcStorage';

const CreateOrgSTag = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(false);
    const [tagName, setTagName] = useState("");
    const [tagDescription, setTagDescription] = useState("");
    const [nameAlreadyExist, setNameAlreadyExist] = useState(true);
    const [enableWarningMsg, setEnableWarningMsg] = useState(false);
	const [userCheckedData, setUserCheckedData] = useState({});
    const [getCurrentList, setCurrentList] = useState([]);
    const [mode, setMode] = useState("");
    const [smartTagDetails, setSmartTagDetails] = useState(JSON.parse(KCStore.getItem("smartTagInfo")));
    const [allTagList, setAllTagList] = useState([]);

    const updateDimensions = () => {
        var windowHeight = $(window).height();
        var userMgmtHeader = $(".userMgmtHeader").height();
        var userMgmtListTableH = $(".userMgmtListTable > .tHead").height();
        var pageWrapperP = $("#page-content-wrapper").innerHeight();
        var userMgmtTableSrlBarH = ((windowHeight) - (userMgmtHeader + userMgmtListTableH)) - (pageWrapperP + 5);
        $(".userMgmtTableSrlBar").css("height", userMgmtTableSrlBarH);
    }

    useEffect(() => {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        window.addEventListener('resize', updateDimensions);
        updateDimensions();
        getLocalStorageValue();
        if(props && props.location && props.location.state && props.location.state.availableTagList) {
            setCurrentList(props.location.state.availableTagList)
        }
        if(props && props.location && props.location.state && props.location.state.userCheckedData){
			setUserCheckedData(props.location.state.userCheckedData)
		}
        $('[data-toggle="popover"]').popover({
            trigger: 'hover',
            'placement': 'top'
        });
    }, []);

    const getLocalStorageValue = () => {
        let smartTagInfo = JSON.parse(KCStore.getItem("smartTagInfo"));
        setMode(smartTagInfo && smartTagInfo.mode ? smartTagInfo.mode : "");
        if (smartTagInfo.orgTagDetails) {
            setTagName(smartTagInfo.orgTagDetails.TagName);
            setTagDescription(smartTagInfo.orgTagDetails.TagDescription);
            setNameAlreadyExist(false);
        }
    }

    const setTagDetails = () => {
        let smartTagInfo = JSON.parse(KCStore.getItem("smartTagInfo"));
        smartTagInfo.orgTagDetails = {
            TagName: tagName,
            TagDescription: tagDescription
        }
        KCStore.setItem('smartTagInfo', JSON.stringify(smartTagInfo));
    }

    const checkTagName = (val) => {
        let alreadyExist = getCurrentList && getCurrentList.filter(c => c.tagName.toLowerCase() === val.toLowerCase());
        setNameAlreadyExist(alreadyExist.length > 0 ? true : false);
        setEnableWarningMsg(alreadyExist.length > 0 ? true : false);
    }

    const typeTagName = (e) => {
        let val = e.target.value;
        val = val.replace(/[&\/\\#,+$~%.'":*?<>{}`!@^=|;\[\]]/g, '');
        if(val && (!val.trim() || val.length > 15)){
            return;
        }
        if(smartTagDetails.mode==='edit'){
            let existingTagDetails =  smartTagDetails && smartTagDetails.selectedRow ? smartTagDetails && smartTagDetails.selectedRow : {};
			if (existingTagDetails.editable === false){
				return;
			}
			setTagName(val);
			if (existingTagDetails.tagName === val.trim()){
				return;
			}
		} else {
			setTagName(val);
		}
        setEnableWarningMsg(false);
        setNameAlreadyExist(true);
        checkTagName(val);
    }

    const typeTagDescription = (e) => {
        let val = e.target.value;
        if(val && (!val.trim() || val.length > 250)){            
            return;
        }
        if (smartTagDetails && smartTagDetails.selectedRow  && smartTagDetails.selectedRow.editable === false){
            return;
        }
        setTagDescription(val);
    }

    let validate = false;
    validate = tagName && !nameAlreadyExist;
    return (
        <div id="adminWrapper">
            <div className="toggleTransparent"></div>
            <div className="sideNavToggleTransparent"></div>
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <div id="page-content-wrapper">
                <div className="menuUpdateWrapper">
                    <div className="adminBgHeader">
                        <ul>
                            <li>
                                <Link to="/smartTag/tagList" title= {formatMessage({id:'KC0989'})} className="backIcon">&nbsp;</Link>
                            </li>
                            <li>
                                <FormattedMessage id={mode === 'edit' ? "KC2334" : "KC2320"} />
                            </li>
                            <li>&nbsp;</li>
                        </ul>
                    </div>
                    <div className="formWrapper">
                        <div className="formWrapperL">
                            <div className="formList">
                                <ul>
                                    <li><label className={smartTagDetails && smartTagDetails.selectedRow && !smartTagDetails.selectedRow.editable ? "stPermissionsD" :""} data-toggle="popover" title={smartTagDetails.mode === 'add' || (smartTagDetails.selectedRow && smartTagDetails.selectedRow.editable) ? "" :formatMessage({id: 'KC2633'})} data-content={smartTagDetails.mode === 'add' || (smartTagDetails.selectedRow && smartTagDetails.selectedRow.editable) ? "" : formatMessage({id: 'KC2914'})}><FormattedMessage id="KC2319" /> *</label></li>
                                    <li>
                                        <input maxLength={15} onChange={(e) => typeTagName(e)} value={tagName} type="text" className="form-control" placeholder={formatMessage({id:'KC0597'})}/>
                                        {enableWarningMsg ?
                                            <div className="errorMsg"> <FormattedMessage id="KC2315" /></div> :
                                            <div className="errorMsg">&nbsp;</div>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="formWrapperR">
                            <div className="formList">
                                <ul>
                                    <li><label className={smartTagDetails && smartTagDetails.selectedRow && !smartTagDetails.selectedRow.editable ? "stPermissionsD" : ""} data-toggle="popover" title={smartTagDetails.mode === 'add' || (smartTagDetails.selectedRow && smartTagDetails.selectedRow.editable) ? "" : formatMessage({id:'KC2633'})} data-content={smartTagDetails.mode === 'add' || (smartTagDetails.selectedRow && smartTagDetails.selectedRow.editable) ? "" : formatMessage({id: 'KC2914'})}><FormattedMessage id="KC2321" /></label></li>
                                    <li>
                                        <textarea maxLength={250} onChange={(e) => typeTagDescription(e)} value={tagDescription} className="form-control" rows="2" cols="50" placeholder= {formatMessage({id:'KC0560'})}></textarea>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        <div className="STUplaodForm">
                            <ul>
                                <li>
                                    <Link to={{ pathname: "/smartTag/smartTagTopology", userCheckedData: userCheckedData ? userCheckedData : {}, from: "Org",tagListDetails: allTagList}} onClick={(e) => setTagDetails(e)} className={validate ? "btn selectBtn" : "btn selectBtn disabled"}><FormattedMessage id="KC2308" /></Link>
                                </li>
                            </ul>
                        </div>
                   
                </div>
            </div>
            <UserManagementHeader headerName={formatMessage({ id: 'KC2318' })} headerNameStringID="KC2318" activeClass="smartTagNav" />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        allSTagList: state.smartTag.smartTagList
    };
}

export default injectIntl(connect(mapStateToProps)(CreateOrgSTag))