import React from "react"
import { Field, ErrorMessage } from "formik"

function KCInput(props) {
  const { name, className, inputClass, errorClass, label, isToggleEye, togglePwd, toggleEyeState, isRequired = false, isstartRequired = false, ...rest } = props
  return (
    <div className={className}>
      <label htmlFor={name}> {label}</label>
      <Field name={name} {...rest} className={inputClass} />
      {isRequired && isstartRequired ? <span className="requiredField">*</span> : null}
      {isToggleEye ? <span className="input-group-btn" onClick={togglePwd}>
        <button className="btn input-group-addon reveal" type="button"><i className={toggleEyeState ? "glyphicon glyphicon-eye-close" : "glyphicon glyphicon-eye-open"}></i></button>
      </span> : null}
      <span className={errorClass}><ErrorMessage name={name} /></span>
    </div>
  )
}
export default KCInput