import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const NotificationFlowModal = ({ selectedNotification, addSpaceBetweenComma, getBrandImage, getUnitCount, getBrandUnitImage, internalLoader, workflowDetails, workflowDropdown, sketchNode, setWorkflowId, selectedWorkflowId, intl }) => {
    const { formatMessage } = intl;
    return (
        <div className="modal fade contentViewModal" data-keyboard="true"  data-backdrop="static" id="flowDetailsViewModal" tabindex="-1" role="dialog" aria-labelledby="flowDetailsViewModalLabel">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <FormattedMessage id="KC0861"/> <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                    </div>
                    <div className="modal-header modal-header-danger">
                        <div className="detailsViewHeader">
                            <ul>
                                <li>{selectedNotification.event_code || <FormattedMessage id="KC0858"/>} | {selectedNotification.event_name || <FormattedMessage id="KC0860"/>}
                                    <div className="eventTypeText">
                                        {selectedNotification.event}
                                    </div>
                                    <div className="eventTypeText">
                                        <label><FormattedMessage id="KC0862"/>:</label>
                                        <select className="form-control disabled" disabled="disabled">
                                            {workflowDropdown && workflowDropdown.length ? workflowDropdown.map((worflow) => {
                                                return (
                                                    <option value={worflow.id} selected={worflow.id === selectedWorkflowId} onChange={() => setWorkflowId(worflow.id)}>{worflow.workFlowName}</option>
                                                )
                                            }) : <option value="0" selected>{ formatMessage({ id: 'KC0863' }) }</option>}
                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <span className="brandLogo">
                                        <img src={getBrandImage(selectedNotification.brand ? selectedNotification.brand.toUpperCase() : '')} />
                                    </span>
                                    <span className="brandModel">{addSpaceBetweenComma(selectedNotification.model)}</span>
                                    <div className="notifnFlowDetails">
                                        <button type="button" data-toggle="modal" data-target="#orgViewModal" className="orgViewIcon btn active">
                                            <img src={require('../../../images/mainmenu/OrganizationMgmt.svg').default} />
                                        </button>
                                        {getUnitCount(selectedNotification.model)}/{getUnitCount(selectedNotification.model)}
                                        <span className="brandModelIcon">
                                            <img src={getBrandUnitImage(selectedNotification.brand ? selectedNotification.brand.toUpperCase() : '')} />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="nFlowViewOuter">
                            {internalLoader ? <div className="spinner workflow-spinner"></div> :
                                <ul>
                                    {workflowDetails && workflowDetails.length ? workflowDetails.map((workflow, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <li>
                                                    <span className="timeFlagIcon"><img src={require('../../../images/icons/flagR.png')} /></span>
                                                    <span className="timeFlagText"><FormattedMessage id="KC1703" values={{ duration: workflow.interval || '0' }}/></span>
                                                </li>
                                                <li>
                                                    <div className="nFlowViewList">
                                                        <ul>
                                                            {workflow && workflow.element && workflow.element.nodes ? workflow.element.nodes.map((node, index) => {
                                                                return sketchNode(node, index)
                                                            }) : null}
                                                        </ul>
                                                    </div>
                                                </li>
                                            </React.Fragment>
                                        )
                                    }) : null}
                                </ul>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(NotificationFlowModal);