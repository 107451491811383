import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from 'lodash';

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

class TopTenPieChart extends Component {

	shouldComponentUpdate(nextProps, nextState) {
		if (_.isEqual(this.props.topTenTableInformation, nextProps.topTenTableInformation)) {
			return false;
		}
		this.renderComponent(nextProps.topTenTableInformation);
		return true;
	}

	renderComponent(nextPropsValues) {
		let response = [];
		if (this.props.brandID) {
			if (this.props.brandID === "4") {
				if (nextPropsValues.topTenTableInformation.length > 0) {
					response = nextPropsValues.topTenTableInformation;
				}
			}
			else {
				if (nextPropsValues && nextPropsValues.topTenTableInformation.result && nextPropsValues.topTenTableInformation.result.length > 0) {
					response = nextPropsValues.topTenTableInformation.result;
				}
			}
		}

		if (response && response.length > 0 && !this.chart) // Chart Rendering at first time response
		{
			// Create Pie chart for Top 10 drinks 
			let pie_chart = am4core.create("topSoldDrinkschartdiv", am4charts.PieChart);

			pie_chart.data = response;
			this.chart = pie_chart;

			// Set inner radius
			pie_chart.innerRadius = am4core.percent(30);

			// Add and configure Series
			let pieSeries = pie_chart.series.push(new am4charts.PieSeries());
			pieSeries.dataFields.value = "counts";
			pieSeries.dataFields.category = "productID";
			pieSeries.labels.template.disabled = true;
			pieSeries.ticks.template.disabled = true;
			pieSeries.slices.template.stroke = am4core.color("#000000");
			pieSeries.slices.template.strokeWidth = 1;
			pieSeries.slices.template.strokeOpacity = 1;
			pieSeries.slices.template.propertyFields.fill = "color"

			// This creates initial animation
			pieSeries.hiddenState.properties.opacity = 1;
			pieSeries.hiddenState.properties.endAngle = -90;
			pieSeries.hiddenState.properties.startAngle = -90;

			// Pie Series Tooltip Test and bg color
			pieSeries.tooltip.getFillFromObject = false;
			pieSeries.tooltip.background.fill = am4core.color("#FFFFFF");
			pieSeries.tooltip.label.fill = am4core.color("#000000");
		}
		else { // Chart rendering with only data source
			if (this.chart) {
				this.chart.data = response;
			}
		}
	}

	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}

	render() {
		return (
			<React.Fragment>
				<div id="topSoldDrinkschartdiv"></div>
			</React.Fragment>
		)
	}
};

export default TopTenPieChart;
