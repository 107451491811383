import { unitModels, brandLogo, modelFamily } from './imgContants'
import KCStore from './kcStorage';

const modelLogo = unitModels;
const brandsLogoImg = brandLogo;
const modelFamilyImg = modelFamily;

export const UnitBuilder = (UnitsValue) => {

    UnitsValue = UnitsValue && UnitsValue.length > 0 ? UnitsValue : [];

    UnitsValue.map(item => {
        const brandImageStorage = KCStore.getItem('modelImage') ? JSON.parse(KCStore.getItem('modelImage')) : [];
        const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === item.BRAND_NAME && i.MODEL_NAME === item.MODEL_NAME) : [];

        if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
            item.Brand_Logo = filteredBrandImage[0].LOGO_URL;
            item.Brand_Alt = filteredBrandImage[0].MODEL_NAME;
            item.Brand_Title = filteredBrandImage[0].MODEL_NAME;
        } else {
            let pickBrand = modelLogo.find(board => board.name === item.MODEL_NAME);
            if (pickBrand != null) {
                item.Brand_Logo = pickBrand.img;
                item.Brand_Alt = pickBrand.alt;
                item.Brand_Title = pickBrand.title;
            }
            else {
                item.Brand_Logo = require('../images/unit/DefaultUnit.png');
                item.Brand_Alt = 'Coming Soon';
                item.Brand_Title = 'Coming Soon';
            }
        }
        return item;
    });
    return UnitsValue;
}

export const SoftwareAvailableBrandImage = (Software_List) => {
    const software_List_Clone = [...Software_List];
    const newSoftwareList = software_List_Clone.map(item => {
        let pickBrand = brandsLogoImg.find(board => board.name === item.BRAND);
        if (pickBrand != null) {
            item.Brand_Logo = pickBrand.img;
            item.Brand_Alt = pickBrand.alt;
            item.Brand_Title = pickBrand.title;
        }
        else {
            item.Brand_Logo = require('../images/store/Convenience.svg').default;
        }
        return item;
    });

    return newSoftwareList;
}

export const SelectedBrandImage = (selectedBrand) => {
    let pickBrand = brandsLogoImg.find(board => board.name === selectedBrand);
    let brandImage = {};
    if (pickBrand != null) {
        brandImage.Brand_Logo = pickBrand.img;
        brandImage.Brand_Alt = pickBrand.alt;
        brandImage.Brand_Title = pickBrand.title;
    }
    else {
        brandImage.Brand_Logo = require('../images/store/Convenience.svg').default;
    }
    return brandImage;
};

export const MenuBrandsResponse = (brandList) => {
    const menuBrandList_Clone = [...brandList];
    const brandListValue = menuBrandList_Clone.map((item) => {
        let pickRole = brandsLogoImg.find(r => r.name === item.BRAND_NAME);
        if (pickRole != null) {
            item.img = pickRole.img;
        }
        else {
            item.img = require("../images/logos/FRY_blue.svg").default;
        }
        return item;
    });
    return brandListValue || [];
}

export const ModelFamilyBuilder = (familyValue) => {

    familyValue = familyValue && familyValue.length > 0 ? familyValue : [];

    familyValue.map(item => {
        const brandImageStorage = KCStore.getItem('modelImage') ? JSON.parse(KCStore.getItem('modelImage')) : [];
        const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.MODEL_FAMILY_NAME === item.MODEL_FAMILY_NAME) : [];

        if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
            item.Brand_Logo = filteredBrandImage[0].LOGO_URL;
            item.Brand_Alt = filteredBrandImage[0].MODEL_NAME;
            item.Brand_Title = filteredBrandImage[0].MODEL_NAME;
        }else{
            let pickBrand = modelFamilyImg.find(board => board.name === item.MODEL_FAMILY_NAME);
            if (pickBrand) {
                item.Brand_Logo = pickBrand.img;
                item.Brand_Alt = pickBrand.alt;
                item.Brand_Title = pickBrand.title;
            }
        }
        return item;
    });
    return familyValue;
}
