import {MODEL_REQUEST,MODEL_SUCCESS,MODEL_FAIL} from '../../../actions/unitManagement/ModelInfoAction';

const initialState = {
    loaded: false,
    loading: false,
    deviceInformation: { },
    errormsg:'',
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case MODEL_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
                errormsg: '',
               
            }
        case MODEL_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                deviceInformation: action.deviceInformation,
                errormsg: '',
            }
        case MODEL_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
            }
        default:
            return state
    }
}