import React, { Component } from 'react';
import { getDeviceAPIUrl, getDeviceInfo } from '../../../service/unitManagement/deviceinfo'; 
import KCStore from '../../../utils/kcStorage';

class UnitBrandLogoImg extends Component {
    constructor(props) {
		super(props);
		this.state = { 
            deviceInformation: {}
		}
	}

    componentDidMount = async () => { 
		let deviceUrl = getDeviceAPIUrl( KCStore.getItem("unitID"), KCStore.getItem("brandName"));
		let deviceInfo = await getDeviceInfo(deviceUrl);
		if (deviceInfo ) {
			this.setState({ 
				deviceInformation: deviceInfo
			});
		}
	}

    	render() {
            const unitInformation = this.props.unitlogodata;
            const { deviceInformation } = this.state;
            let deviceOnline = 'Online', deviceOffline = 'Offline',
			deviceOnlineStatus = 'divStatusWithIcon online divStatusWithIconSM',
			deviceOfflineStatus = 'divStatusWithIcon offline divStatusWithIconSM';            
            return (
                <React.Fragment>
                    <div className="colm2D colm2D1" >
            
                        <div className="colmDi">
                            <div className="selectedDiviceImg">
                               <img src={unitInformation.Brand_Logo} alt={unitInformation.Brand_Alt} title={unitInformation.Brand_Title} />
                            </div>
                            <div className={deviceInformation.connected === true ? deviceOnlineStatus : deviceOfflineStatus}>
							{deviceInformation.connected === true ? deviceOnline : deviceOffline}
                            </div>
                        </div> 
                    </div>
                </React.Fragment>
            )
        }

}
export default UnitBrandLogoImg;