import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {Link} from 'react-router-dom';

am4core.useTheme(am4themes_animated);


class ErrorReport extends Component {
    render() {
        return(
            <div>
                <div id="wrapper">  
                <nav class="navbar navbar-expand-lg navbar-dark bg-Welbilt"> 
			  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			  </button>

			  <div class="collapse navbar-collapse" id="navbarContent">
				<ul class="navbar-nav mr-auto navbarLeft">
				  <li class="nav-item">
					<Link class="nav-link" to="#" title="Welbilt Kitchen">
						<span class="navStoreIcon"><img src={require('../../images/store/store01.svg').default} alt="Welbilt Kitchen" title="Welbilt Kitchen"/></span>
						<span class="navStoreText">Welbilt Kitchen <b>Munich City</b></span>
					</Link>
				  </li>
				  <li class="nav-item">
					<Link class="nav-link" to="#" title="Carrara Espresso">
						<span class="navStoreIcon"><img src={require('../../images/unit/unit01.png')} alt="Carrara Espresso" title="Carrara Espresso"/></span> 
						<span class="navStoreText">Carrara Espresso <b>Crem</b></span>
					</Link>
				  </li>  
				</ul> 

				<ul class="navbar-nav navbarLRight">
                    <li class="nav-item">
                        <Link class="nav-link" to="#" title="Serial Number">
                            <span class="deviceSNText">S/N <b>530.115.987</b></span>
                        </Link>
                    </li>
        </ul>

				<ul class="navbar-nav mr-auto navbarRight">
				  <li class="nav-item">
					<Link class="nav-link " title="Sorting" to="#">&nbsp;</Link>
				  </li> 
				  <li class="nav-item">
					<Link class="nav-link navFilter" title="Filter" to="#">&nbsp;</Link>
				  </li> 
				  <li class="nav-item">
					<Link class="nav-link navSearch" title="Search" to="#">&nbsp;</Link>
				  </li> 
				  <li class="nav-item">
					<Link class="nav-link navAlarm" title="Notification" to="#">&nbsp;</Link>
				  </li> 
				  <li class="nav-item">
					<Link class="nav-link navUser" title="User Name" to="#">&nbsp;</Link>
				  </li> 
				</ul> 
			  </div>
		</nav>

        <div id="page-content-wrapper">

            <div class="errorWrapper">
                <div class="errorHeader">
                    <ul>
                        <li>
                            <Link to="/serviceNotes" title="Back" class="backIcon">&nbsp;</Link>
                        </li>
                        <li>
                            Ec_2: Error Report
                        </li>
                        <li>
                            &nbsp;
                        </li>
                        <li>
                            <Link to="/serviceManagement" title="Service Notes" class="cancelIcon">&nbsp;</Link>
                        </li>
                    </ul>
                </div>

                <div class="serviceInfoListOuter">

                    <div class="serviceInfoListL">
                        <h5>Previous Service Notes</h5>
                        <div class="PrvServiceNotesTable">
                            <ul>
                                <li>
                                    <h6>2018-11-01, 10:25</h6>
                                    <h6>Name Surname/Servicer Company </h6>
                                    <p>Milk compartment MC 5000.00 replaced Second line …</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <h6>2018-12-22, 12:30</h6>
                                    <h6>Name Surname/Servicer Company </h6>
                                    <p>Milk compartment MC 5000.00 replaced Second line …</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <h6>2018-01-26, 18:20</h6>
                                    <h6>Name Surname/Servicer Company </h6>
                                    <p>Milk compartment MC 5000.00 replaced Second line …</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <h6>2018-07-17, 23:25</h6>
                                    <h6>Name Surname/Servicer Company </h6>
                                    <p>Milk compartment MC 5000.00 replaced Second line …</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="serviceInfoListR">
                        <h5>&nbsp;</h5>
                        <div class="boxDiv whiteBg">
                            <Link to="#" title="Have you replaced any new Part? 
Please select the installed part from the list below." class="newPart">Have you replaced any new Part? 
Please select the installed part from the list below.</Link>
                        </div>
                        <div class="createReportForm">
                            <ul>
                                <li>
                                    <select class="form-control" tabindex="1">
                                        <option value="">Select Part</option>
                                        <option value="1">Select Part</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="devicegrpCheckbox" tabindex="2">
                                        <div class="customCheckbox customCheckboxWlabel">
                                            <input id="Resolved"  name="groupName" type="checkbox" value="Resolved"/>
                                            <label for="Resolved">Resolved</label>
                                        </div>
                                        <div class="customCheckbox customCheckboxWlabel">
                                            <input id="Open" name="groupName" type="checkbox" value="Open"/>
                                            <label for="Open">Open</label>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <input type="text" placeholder="Leave a quicke note" class="form-control" tabindex="3"/>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="serviceInfoListF">
                        <div class="serviceInfoListR">
                            <div class="createReportFormBtns">
                                <button type="reset" class="btn btn-default-cancel">Cancel</button>
                                <button type="submit" class="btn btn-primary-submit">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
		
		
               </div>

            </div>
        )
    }
};

export default ErrorReport;
