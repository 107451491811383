import { SET_POPUP_STATUS, SET_MODAL_STATUS, SET_DIAGNOSTIC_CONNECTION_STATUS, SET_UNIT_CONNECTION_STATUS, SET_DIAGNOSTIC_CONFIGURATION_FILE, SET_LIVE_MONITORING_STREAM_DATA, SET_LIVE_MONITORING_DATA } from '../../../actions/liveDiagnostic/type';

const initialState = {
    unitConnectionStatus: '',
    diagnosticConnectionStatus: {},
    popupStatus: '',
    modalStatus: false,
    diagnosticConfiguration: {},
    liveMonitoringData: [],
    liveMonitoringStreamData: {}
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_POPUP_STATUS:
            return {
                ...state,
                popupStatus: action.payload,
            }
        case SET_MODAL_STATUS:
            return {
                ...state,
                modalStatus: action.payload,
            }
        case SET_DIAGNOSTIC_CONNECTION_STATUS:
            return {
                ...state,
                diagnosticConnectionStatus: action.payload,
            }
        case SET_UNIT_CONNECTION_STATUS:
            return {
                ...state,
                unitConnectionStatus: action.payload,
            }
        case SET_DIAGNOSTIC_CONFIGURATION_FILE:
            return {
                ...state,
                diagnosticConfiguration: { [action.payload.configurationName]: action.payload.configuration },
            }
        case SET_LIVE_MONITORING_STREAM_DATA:
            return {
                ...state,
                liveMonitoringStreamData: action.payload
            }
        case SET_LIVE_MONITORING_DATA:
            return {
                ...state,
                liveMonitoringData: action.payload
            }
        default:
            return state
    }
}