import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class NoRecordsFound extends Component {

    render() {
        let { loaderIconVisible, length, classname, text, stringID } = this.props;

        return (
            <React.Fragment>
                {loaderIconVisible === false && length === 0 && <div className={classname}>
                    {stringID ? <FormattedMessage id={stringID}/>: (text ? text : <FormattedMessage id="KC0205"/>)}
                </div>}
            </React.Fragment>
        )
    }
}
export default NoRecordsFound