import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import { MenuUploadAPI } from '../../../../../service/api';
import { CUSTOMER_ID, BASECOUNTRY_ID  } from '../../../../../utils/appConstants';
import http from '../../../../../service/httpService';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';
var recipeJSON = require('../../Templates/RecipeJSON');
var settingsJSON = require('../../Templates/SettingsJSON');


class RecipeEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.reciperedirect = this.reciperedirect.bind(this);
        this.cookbookredirect = this.cookbookredirect.bind(this);
        this.settingsredirect = this.settingsredirect.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
        } catch (error) {
            //Error Goes Here
        }
    }

    reciperedirect = (e,type) => {
        KCStore.setItem("selectedGroupID", 0);
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.initialMethod = "Add";
        libraryDetails.recipeType = type;
        KCStore.setItem("libraryImage", "");
        KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
        KCStore.setItem("defaultLibraryData", JSON.stringify(recipeJSON.default));
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeEditor"
        });
    }

    cookbookredirect = () => {
        KCStore.setItem("selectedGroupID", 0);
        KCStore.setItem("resourceDetails", "[]");
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.isImageChanged = false;
        libraryDetails.cookbookName = "";
        libraryDetails.initialMethod = "Add";
        KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
        KCStore.setItem("defaultLibraryData", "{}");
        KCStore.setItem("schedulerDetails", "{}");
        KCStore.setItem("libraryData", "{}");
        this.props.history.push({
            pathname: "/cookbookEditor",
            isEdit: false,
            previouspath: "/recipeEditor"
        });
    }

    settingsredirect = async () => {
        this.setState({
            loaderIconVisible: true
        });
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.settingName = "";
        libraryDetails.initialMethod = "Add";
        return new Promise((resolve, reject) => {
            //Header values are not used in below API so dummy values are passed.
            http.get(MenuUploadAPI.getdefaultLibraryFiles,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'customerid': CUSTOMER_ID,
                        'cgids': KCStore.getItem("custGroupID"),
                        'brandid': KCStore.getItem("selectedbrandID"),
                        'basecountry': BASECOUNTRY_ID
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    let settings = data && data.settings ? data.settings : [];
                    libraryDetails.day = "Monday";
                    libraryDetails.settings = settings;
                    libraryDetails.settings.map((item, i) => {
                        item.isDefault = true;
                        item.s3FileName = item.fileName;
                    })
                    let libraryData = settingsJSON.default;
                    libraryData.sounds = settings;
                    KCStore.setItem("libraryData", JSON.stringify(libraryData));
                    KCStore.setItem("schedulerDetails", JSON.stringify(libraryData.scheduler));
                    KCStore.setItem("selectedGroupID", 0);
                    KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
                    KCStore.setItem("defaultLibraryData", JSON.stringify(libraryData));
                    this.setState({
                        loaderIconVisible: false
                    });
                    this.props.history.push({
                        pathname: "/settingsEditor",
                        previouspath: "/recipeEditor"
                    });
                    resolve(response);
                }).catch(err => {
                    this.setState({
                        loaderIconVisible: false
                    });
                    reject(err);
                });
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        let { data: dataList, loaderIconVisible } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader  headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH">
                                    &nbsp;
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="recipeEditorGrid">
                                                    <ul>
                                                        {/* <li>
                                                            <button type="button" class="btn readyRecipeNav" onClick={(e) => { this.reciperedirect(e, 'ReadyRecipe') }}>Ready Recipe Creation</button>
                                                        </li> */}
                                                        <li>
                                                            <button type="button" class="btn recipeCreationNav" onClick={(e) => { this.reciperedirect(e,'Recipe') }}><FormattedMessage id="KC1211"/></button>
                                                        </li>
                                                        <li>
                                                            <button type="button" class="btn cookbookNav" onClick={(e) => { this.cookbookredirect(e) }}><FormattedMessage id="KC2009"/></button>
                                                        </li>
                                                        <li>
                                                            <button type="button" class="btn settingsNav" onClick={(e) => { this.settingsredirect(e) }}><FormattedMessage id="KC1190"/></button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <span class="easyTouchLogo"><img src={require("../../../../../images/recipecreation/eacyTouchLogo.png")}  alt=""/></span>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(RecipeEditor)
