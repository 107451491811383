import { SET_LOCALE, SET_LANGUAGE_LIST, RESET_LOCALE_INFO } from './actionType';

// SET LIST OF LANG
export const setLanguageList = (data) => dispatch => {
    dispatch({
        type: SET_LANGUAGE_LIST,
        payload: data
    })
}

// CHANGE LOCALE(user selected)
export const changeLocale = (language) => dispatch => {
    dispatch({
        type: SET_LOCALE,
        payload: language.language_code,
    });
};

// INITIAL LOCALE (user)
export const setLocale = (language) => dispatch => {
    dispatch({
        type: SET_LOCALE,
        payload: language,
    });
};

// RESET LOCALE(when user logout)
export const resetLocale = () => dispatch => {
    dispatch({ type: RESET_LOCALE_INFO });
};

