import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReportManagement, smartGroupAPI } from '../../../service/api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import http from '../../../service/httpService';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { getUnitDetails } from '../../../actions/smart-groups/getSmartGroupUnit';
import { getUpdateHistory } from '../../../actions/smart-groups/getSmartGroupUpdateHistory';
import { deleteUnitList, setUnitDeleteData } from '../../../actions/smart-groups/deleteSmartGroupUnit';
import moment from '../../../utils/momentHelper.js';
import { injectIntl,FormattedMessage } from 'react-intl';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import KCStore from '../../../utils/kcStorage';

class MenuSmartGroupsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            unitDetails: null,
            isModalLoading: null,
            loaderIconVisible: false,
            isDeleted: false,
            responseData: [],
            excludedProductList: [],
            unitLoading: false,
            oldProdID: "",
            oldProdName: "",
            curr_Prod_ID: "",
            curr_Prod_Name: "",
            add_mode: false,
            update_mode: false,
            isMultiDelete: false,
            isAdd: false,
            isEdit: false,
            isDelete: false,
            isEdit: false,
            allSelect: false,
            smartGroupInfo: null,
            customerId: null,
            unitSelectFinishBtn: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
        };
        this.deleteSelectedList = this.deleteSelectedList.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
    }

    componentWillMount = () => {
        if (this.props.location && this.props.location.state) {
            this.setState({
                userCheckedData: this.props.location.state.userCheckedData,
            });
        }

        let storeInstalledItemInfo = this.props.storeInstalledItemInfo && this.props.storeInstalledItemInfo.SOFTWARE_ID ? this.props.storeInstalledItemInfo : JSON.parse(KCStore.getItem('selectedItemsInfo'));
        KCStore.setItem('selectedItemsInfo', JSON.stringify(storeInstalledItemInfo))

        let locationInfo = this.props.location && this.props.location.FILE_NAME ? this.props.location : JSON.parse(KCStore.getItem('locationInfo'));
        KCStore.setItem('locationInfo', JSON.stringify(locationInfo))

        this.setState({
            storeInstalledItemInfo: storeInstalledItemInfo,
            locationInfo: locationInfo
        });
    }

    componentDidMount = () => {
        $('body').removeClass("modal-backdrop show");
        document.body.style.backgroundColor = "#F2F2F2";

        if (this.props.location && this.props.location.state) {
            this.setState({
                userCheckedData: this.props.location.state.userCheckedData,
            });
        }
        let storeInstalledItemInfo = this.props.storeInstalledItemInfo && this.props.storeInstalledItemInfo.SOFTWARE_ID ? this.props.storeInstalledItemInfo : JSON.parse(KCStore.getItem('selectedItemsInfo'));
        KCStore.setItem('selectedItemsInfo', JSON.stringify(storeInstalledItemInfo))

        let locationInfo = this.props.location && this.props.location.FILE_NAME ? this.props.location : JSON.parse(KCStore.getItem('locationInfo'));
        KCStore.setItem('locationInfo', JSON.stringify(locationInfo))

        this.setState({
            storeInstalledItemInfo: storeInstalledItemInfo,
            locationInfo: locationInfo
        });

        this.getProductsDetails();         
    }

    getProductsDetails = () => {
        this.setState({
            loaderIconVisible: true,
            allSelect: false,
            isDelete: false,
            isEdit: false,
        });

        let queryString;
        queryString = `customerId=${KCStore.getItem("customerId")}`
        queryString = queryString + `&userId=${KCStore.getItem("user")}`

        let url = `${smartGroupAPI.createSmartGroup}?${queryString}`;
        let excludedProductList;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                this.setState({
                    excludedProductList: response.data,
                    loaderIconVisible: false,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    selectExistingProduct = (e, item) => {
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(r => {
            if (r.checked) {
                this.setState({
                    oldProdID: r.product_id,
                    oldProdName: r.product_name,
                    curr_Prod_ID: r.product_id,
                    curr_Prod_Name: r.product_name,
                    add_mode: false,
                    update_mode: true,
                })
            }
        });
    }

    clickMultiDeleteButton = () => {
        this.setState({ isMultiDelete: true })
    }

    onAddClick = (e, item) => {
        this.setState({
            oldProdID: "",
            oldProdName: "",
            add_mode: true,
            update_mode: false,
        })
    }

    findAddtOrUpdateProduct = () => {
        this.setState({ loaderIconVisible: true })
        if (this.state.add_mode) {
            this.addProductDetails();
            this.setState({ curr_Prod_ID: "", curr_Prod_Name: "" })
        } else if (this.state.update_mode) {
            this.updateProductDetails();
        }
    }

    isSelectAssign = (units) => {
        const checkAllTrue = units.filter(c => c.checked === true);
        this.setState({
            unitSelectFinishBtn: checkAllTrue.length > 0 || false
        });
    }

    addProductDetails = (e, item) => {
        let reqData = {
            "brand_id": KCStore.getItem("selectedbrandID"),
            "brand_name": KCStore.getItem("brandName"),
            "product_id": this.state.curr_Prod_ID,
            "product_name": this.state.curr_Prod_Name,
            "customer_id": KCStore.getItem("customerId"),
            "user_id": KCStore.getItem("user"),
        };
        let url = ReportManagement.excludeProductDetails;
        http.post(url, reqData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                let { data } = response;
                let { message } = data;
                this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,
                    allSelect: false,
                    isDelete: false,
                    isEdit: false,
                    success_modal: {
                        open: true,
                        message: "KC1164"
                    },
                });
            }).catch(err => {
                this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,
                    allSelect: false,
                    isDelete: false,
                    isEdit: false,
                    error_modal: {
                        open: true,
                        message: err.response.data.stringID || err.response.data.message ,
                    },
                });
            });

    }

    updateProductDetails = (e, item) => {
        let reqData = {
            "brand_id": KCStore.getItem("selectedbrandID"),
            "brand_name": KCStore.getItem("brandName"),
            "product_id": this.state.curr_Prod_ID,
            "product_name": this.state.curr_Prod_Name,
            "customer_id": KCStore.getItem("customerId"),
            "user_id": KCStore.getItem("user"),
            "old_product_name": this.state.oldProdName,
            "old_product_id": this.state.oldProdID,
        };
        let url = ReportManagement.excludeProductDetails;
        http.put(url, reqData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                let { data } = response;
                let { httpCode, responseCode, message } = data;
                if (httpCode === "HTTP_200") {
                    this.setState({
                        curr_Prod_ID: "",
                        curr_Prod_Name: "",
                        loaderIconVisible: false,
                        allSelect: false,
                        isDelete: false,
                        isEdit: false,
                        success_modal: {
                            open: true,
                            message: "KC1165"
                        },
                    });
                }
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false,
                    allSelect: false,
                    isDelete: false,
                    isEdit: false,
                    error_modal: {
                        open: true,
                        message: err.response.data.stringID || err.response.data.message ,
                    },
                });
            });

    }

    deleteSelectedProduct = (e, item) => {
        this.setState({ loaderIconVisible: true, allSelect: false })
        let reqData = [];
        let val;
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(r => {
            if (r.checked) {
                val = {
                    "product_id": r.product_id,
                    "product_name": r.product_name,
                }
                reqData.push(val);
            }
        });

        let url = ReportManagement.excludeProductDetails;
        http.delete(url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "brandid": KCStore.getItem("selectedbrandID"),
                    "cid": KCStore.getItem("customerId"),
                    "userid": KCStore.getItem("user"),
                },
                data: reqData,
            }
        )
            .then(response => {
                let { data } = response;
                let { httpCode, responseCode, message } = data;
                if (httpCode === "HTTP_200") {
                    this.setState({
                        curr_Prod_ID: "",
                        curr_Prod_Name: "",
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: "KC0780",
                        },
                    });
                }
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: err.response,
                    },
                });
            });

    }

    prodId_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            curr_Prod_ID: rName,
        })
    }

    prodName_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            curr_Prod_Name: rName,
        })
    }

    onSuccessCloseModal = (e) => {
        this.setState({
            success_modal: {
                open: false
            }
        });
        this.props.actions.setUnitDeleteData(null);
        this.getProductsDetails();
    }

    closeModel = (err) => {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            }
        });
    }

    handleChange = (e) => {
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(r => {
            r.checked = e.target.checked;
        });
        this.setState({
            excludedProductList: rolesList,
            allSelect: e.target.checked,
            isDelete: e.target.checked,
        })

    };

    onSelectRole = (e, item) => {
        if (this.state.excludedProductList && this.state.excludedProductList.length > 0) {
            const rolesList = [...this.state.excludedProductList];
            const index = rolesList.indexOf(item);
            rolesList[index] = { ...rolesList[index] };
            rolesList[index].checked = e.target.checked;
            this.setState({
                excludedProductList: rolesList,
            })

            this.setState({
                allSelect: false,
                isDelete: false,
                isEdit: false,
            });

            let ictr = 0;
            rolesList.forEach(i => {
                if (i.checked === true || i === true) {
                    ictr = ictr + 1
                }
            });

            if (ictr === rolesList.length) {
                this.setState({
                    allSelect: true,
                    isDelete: true
                });
            } else if (ictr > 0) {
                this.setState({
                    isDelete: true,
                });
            } else {
                this.setState({
                    allSelect: false,
                });
            }

            if (ictr === 1) {
                this.setState({
                    isEdit: true,
                });
            }
        }
    }

    editSmartGroup = () => {
        let smartGrpList = [...this.state.excludedProductList];
        let editSmartGrpDetails = smartGrpList.filter(item => item.checked === true);
        this.props.history.push({
            pathname: '/editsmartgrp',
            editSmartGrpRes: editSmartGrpDetails,
            smartGrp_id: editSmartGrpDetails[0].smartGrpId,
        })
    }

    //For First Letter Added in Smart Group List
    getFirstLetter(name) {
        if (name) {
            var char = name.charAt(0).toUpperCase();
            return char;
        }
    }


    deleteSelectedList() {
        let { excludedProductList, customerId } = this.state;
        let selectedIds = [];
        excludedProductList.forEach(e => {
            if (e.checked === true) {
                selectedIds.push(e.smartGrpId);
            }
        });
        customerId = +customerId;
        let req = { id: selectedIds, customerId };

        this.props.actions.deleteUnitList(req);


    }
    static getDerivedStateFromProps(props) {
        return { ...props };
    }

    render() {
        const { formatMessage } = this.props.intl;
        $(document).ready(function () {
            // Nav Toggle
            $('.collapse').off('click').on('click', function () {
                $(this).parents(".tBody").toggleClass('descExp');
            });
        });

        let { isDeleted, unitDetails, isModalLoading, smartGroupInfo, excludedProductList, loaderIconVisible, error_modal, success_modal, allSelect, unitSelectFinishBtn, unitLoading } = this.state;
        let units = unitDetails && unitDetails.units;
        units = UnitBuilder(units);
        let infoUnits = smartGroupInfo && smartGroupInfo.units;
        if (infoUnits) {
            infoUnits.addedUnits = infoUnits.addedUnits ? UnitBuilder(infoUnits.addedUnits) : [];
            infoUnits.removedUnits = infoUnits.removedUnits ? UnitBuilder(infoUnits.removedUnits) : [];
        }

        if (isDeleted && isDeleted.msg) {
            success_modal = { open: true, message: isDeleted.msg };
        }

        const finishBtnClass = ['btn-default-text btn forwardNext'];

        if (unitSelectFinishBtn) {
            finishBtnClass.push('activeState');
        } else {
            finishBtnClass.push('disabled');
        }

        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>

                <div id="page-content-wrapper">
                    <div className="stickyTableHeader">
                        <div className="menuUpdateWrapper">
                            <div className="addUnitHeader">
                                <ul>
                                    <li>
                                        <Link to="/menuLibrary" title={formatMessage({ id: 'KC0989' })} className="backIcon"></Link>
                                    </li>
                                    <li> <FormattedMessage id="KC1780"/> </li>
                                    <li>
                                        <Link to={{
                                            pathname: "/menuTransferUnits",
                                            state: {
                                                userCheckedData: this.state.checkedUnits,
                                                storeInstalledItemInfo: this.state.storeInstalledItemInfo,
                                                locationInfo: this.state.locationInfo,
                                                transferType: "smart-group-transfer",
                                                smartGroupResponse: this.state.excludedProductList,
                                            }}} id="btnfinish" title={formatMessage({ id: 'KC0543' })} ><FormattedMessage id="KC0543"/>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div class="selectedUnitHeader">
                                <ul><li>0 <FormattedMessage id="KC1749"/> / 0 <FormattedMessage id="KC1594"/></li>
                                    <li>
                                        <a title={formatMessage({ id: 'KC0722' })} class="sgStatus active" href="/menuSmartgroupsList"> &nbsp;</a>
                                        <a class="assignunitSite" href="/menuAssigngroupTopology">&nbsp;</a>
                                        <a id="btnAssign" class="assignunitList " href="/menuTransfergroupsList">&nbsp;</a>
                                    </li>
                                </ul>
                            </div>


                            {/* <form className="dspdPrdtsListForm"> */}

                            <div class="orgTableOuter">
                                <div className="orgTable">
                                    <ul className="tHead">
                                        <li>&nbsp;</li>
                                        <li>#</li>
                                        <li><FormattedMessage id="KC0241"/></li>
                                        <li><FormattedMessage id="KC0242"/></li>
                                        <li><FormattedMessage id="KC1779"/></li>
                                        <li><FormattedMessage id="KC0542"/></li>
                                        <li><FormattedMessage id="KC0325"/></li>
                                        <li>
                                            <div class="customCheckbox selectAllDiv">
                                                <input id="selectAll" checked={allSelect} onChange={(e) => this.handleChange(e)} type="checkbox" value="selectAll" />
                                                <label for="selectAll">&nbsp;</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="false">

                                    {excludedProductList.length > 0 && excludedProductList.map((item, i) =>
                                        <div key={i}>
                                            <div class="orgTable">
                                                <ul className="tBody">
                                                    <li><button onClick={() => this.props.actions.getUnitDetails(item.smartGrpId)} role="button" data-toggle="collapse" data-parent="#availableUpdatesAccordion" href={"#availableUpdatesAcc" + item.smartGrpId} aria-expanded="false" aria-controls={"#availableUpdatesAcc" + item.smartGrpId} class="btn collapseExpand collapsed">&nbsp;</button></li>
                                                    <li>{i + 1}</li>
                                                    <li> <span class="sgFL"> {item.smartGroupKeyName?item.smartGroupKeyName:this.getFirstLetter(item.smarGrpName)}</span> {item.smarGrpName} </li>
                                                    <li>
                                                        {item.purpose.map((item1, i) =>
                                                            <span class="purposeTo" key={'item' + i}>
                                                                {item1.purposeName}
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li>{item.groupsize > 0 ? item.groupsize + formatMessage({ id: 'KC0045' }) : ""}</li>
                                                    <li>{moment.GetDateTimeByFormat(item.modified, 'LLL')}</li>
                                                    <li><button onClick={() => this.props.actions.getUpdateHistory(item.smartGrpId)} type="button" class="btn sgInfo" data-toggle="modal" data-target="#detailsInfoModal">&nbsp;</button></li>
                                                    <li>
                                                        <div className={"customCheckbox customCheckboxWOlabel"}>
                                                            <input id={i} name={i} type="checkbox"
                                                                checked={item.checked}
                                                                onChange={(e) => this.onSelectRole(e, item)} />
                                                            <label htmlFor={i}></label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div id={"availableUpdatesAcc" + item.smartGrpId}
                                                data-parent="#availableUpdatesAccordion"
                                                class="panel-collapse collapse"
                                                role="tabpanel" aria-labelledby="availableUpdatesAccH1">

                                                <div class="panel-body orgUnitsList">
                                                    {unitLoading && <div className="text-center"> <FormattedMessage id="KC1151"/></div>}

                                                    {!unitLoading && <>
                                                        <ul class="tHead">
                                                            <li>#</li>
                                                            <li><FormattedMessage id="KC0101"/></li>
                                                            <li><FormattedMessage id="KC0062"/></li>
                                                            <li><FormattedMessage id="KC0102"/></li>
                                                            <li><FormattedMessage id="KC0064"/></li>
                                                            <li><FormattedMessage id="KC0104"/></li>
                                                            <li><FormattedMessage id="KC1152"/></li>
                                                            <li><FormattedMessage id="KC0050"/></li>
                                                            <li><FormattedMessage id="KC0061"/></li>
                                                        </ul>

                                                        {units && units.map((item, i) =>
                                                            <ul class="tBody" key={item.ID}>
                                                                <li> {i + 1} </li>
                                                                <li> <img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
                                                                <li> {item.BRAND_NAME} </li>
                                                                <li> {item.PRODUCT_TYPE} </li>
                                                                <li> {item.MODEL_NAME} </li>
                                                                <li> {item.UNITID} </li>
                                                                <li> {item.PRODUCT_TYPE_ID} </li>
                                                                <li> {item.location_name}</li>
                                                                <li> {item.group_name}</li>
                                                            </ul>)}
                                                    </>}

                                                </div>
                                                {unitLoading === false && <>

                                                    {((units && units.length === 0) || !units) && <div className="text-center"><FormattedMessage id="KC0568"/> </div>}

                                                    {units && units.length > 0 &&
                                                        <>
                                                            {unitDetails && unitDetails.total_unit_count > 3 &&
                                                                <button type="button" class="btn btn-disabled disabled">
                                                                    <span> + {unitDetails.total_unit_count - 3}</span> Units</button>}

                                                            <Link className="btn btn-default" id="AssignUpdate" to={{
                                                                pathname: "/editsmartGrpAssignUpdateList",
                                                                state: {
                                                                    nameFromReport: item.smarGrpName,
                                                                    oldSmartGroupName: item.smarGrpName,
                                                                    selectedoptionsFromReport: "",
                                                                    oldSelectedPurposes: "",
                                                                    sumartGroupID: item.smartGrpId,
                                                                    pageFrom: "smartGroupList",
                                                                }
                                                            }} title={formatMessage({ id: 'KC1651' })}><FormattedMessage id="KC1166"/></Link>
                                                        </>
                                                    }

                                                </>}

                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Update History Modal */}
                <div class="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="detailsInfoModal" tabindex="-1" role="dialog" aria-labelledby="detailsInfoModalLabel">
                    <div class="modal-dialog modal-md infoModal sgModal">
                        {isModalLoading && <div class="modal-content text-center"> <br /><br /><FormattedMessage id="KC1151"/><br /><br /></div>}
                        {!isModalLoading && <div class="modal-content">
                            <div class="modal-header modal-header-danger">
                                <FormattedMessage id="KC1781"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                            </div>
                            <div class="modal-body">
                                <div class="sgModalContent">
                                    <p><FormattedMessage id="KC0565"/></p>
                                    <div class="sgModalUnitsList">
                                        <ul class="tHead">
                                            <li><FormattedMessage id="KC0101"/></li>
                                            <li>&nbsp;</li>
                                            <li><FormattedMessage id="KC0740"/>.</li>
                                            <li><FormattedMessage id="KC0741"/></li>
                                        </ul>

                                        {infoUnits && infoUnits.addedUnits && infoUnits.addedUnits.map((item, i) =>
                                            <ul class="tBody" key={'unit-' + i}>
                                                <li><img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
                                                <li> {item.BRAND_NAME}<span>{item.BRAND_TYPE}</span></li>
                                                <li> {item.UNITID}<span>{item.UNIT_NUMBER}</span></li>
                                                <li> {moment.GetDateTimeByFormat(item.createdAt, 'LLL')}</li>
                                            </ul>
                                        )}
                                    </div>
                                    {infoUnits && infoUnits.addedUnits && infoUnits.addedUnits.length === 0 &&
                                        <div className="text-center"> <FormattedMessage id="KC0568"/></div>
                                    }
                                    <p>Removed</p>
                                    <div class="sgModalUnitsList">
                                        <ul class="tHead">
                                            <li><FormattedMessage id="KC0101"/></li>
                                            <li>&nbsp;</li>
                                            <li><FormattedMessage id="KC0740"/>.</li>
                                            <li><FormattedMessage id="KC0741"/></li>
                                        </ul>
                                        {infoUnits && infoUnits.removedUnits && infoUnits.removedUnits.map((item, i) =>
                                            <ul class="tBody" key={'remove' + i}>
                                                <li><img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
                                                <li> {item.BRAND_NAME}<span>{item.BRAND_TYPE}</span></li>
                                                <li> {item.UNITID}<span>{item.UNIT_NUMBER}</span></li>
                                                <li> {moment.GetDateTimeByFormat(item.createdAt, 'LLL')}</li>
                                            </ul>)}
                                    </div>
                                    {infoUnits && infoUnits.removedUnits && infoUnits.removedUnits.length === 0 && <div className="text-center"> <FormattedMessage id="KC0568"/></div>}
                                </div>
                                {/* {smartGroupInfo && <div className="text-unit-color">+ <span>{smartGroupInfo.total_unit_count} </span>  Units</div>} */}
                            </div>
                        </div>}
                    </div>
                </div>

                {/* Delete Smart Group Unit Modal */}
                <div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
                    <div className="modal-dialog modal-md loginModal">
                        <div className="modal-content" >
                            <div className="modal-body">
                                <div className="singOutConfirmation">
                                    <FormattedMessage id="KC1167"/>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
                                <button className="btn btn-secondary signOutYesBtn" onClick={() => this.deleteSelectedList()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>

                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />                
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({
            getUnitDetails,
            getUpdateHistory,
            deleteUnitList,
            setUnitDeleteData
        }, dispatch)
    }
}

const mapStateToProps = state => {
    return {
        customerId: state.userReducer.UserModel.user.customerId,
        unitDetails: state.smartGroups.unitList,
        unitLoading: state.smartGroups.isLoading,
        smartGroupInfo: state.smartGroupsHistory.unitHistory,
        isModalLoading: state.smartGroupsHistory.isLoading,
        isDeleted: state.smartGroupUnitDelete.unitListDelete
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MenuSmartGroupsList)); 