// Import Statements
import React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDiagnosticConfigFile } from '../../actions/liveDiagnostic/liveDiagnosticAction';
import { ComponentMapping, diagnosticChartDataParser } from '../../utils/dashboardRenderer';
import { compileConfiguration } from '../DashboardRenderers/configuration-compiler';
import clientFunctions from '../DashboardRenderers/client-function/index';
import 'bootstrap/dist/css/bootstrap.min.css';

var widgetMetaData = [];
var rootScope = {};

// Dashboard renderer component
function LiveDiagnosticRenderer(props) {
    // State Initialization
    const [loaderIconVisible, setLoading] = React.useState(true);
    const [currentConfiguration, setCurrentConfiguration] = React.useState(null);
    const [widgetRendererData, setWidgetRendererData] = React.useState([])
    const intl = useIntl();

    // Redux subscriptions
    const dispatch = useDispatch();
    const currentUnit = useSelector(state => state.entityReducer.currentUnit);
    const configuration = useSelector(state => state.liveDiagnostic.diagnosticConfiguration);
    const liveMonitoringData = useSelector(state => state.liveDiagnostic.liveMonitoringData);
    const liveMonitoringStreamData = useSelector(state => state.liveDiagnostic.liveMonitoringStreamData);

    React.useEffect(() => {
        createRootScope();
        return () => {
            rootScope['sessionData'] = [];
            rootScope['liveData'] = [];
        };
    }, [])

    // Trigger configuration if not present
    React.useEffect(() => {
        if (configuration[currentUnit.MODEL_FAMILY_NAME]) {
            setCurrentConfiguration(JSON.parse(JSON.stringify(configuration[currentUnit.MODEL_FAMILY_NAME].configuration)));
        } else if (configuration[currentUnit.MODEL_NAME]) {
            setCurrentConfiguration(JSON.parse(JSON.stringify(configuration[currentUnit.MODEL_NAME].configuration)));
        } else if (configuration[currentUnit.BRAND_NAME]) {
            setCurrentConfiguration(JSON.parse(JSON.stringify(configuration[currentUnit.BRAND_NAME].configuration)));
        } else {
            setLoading(true);
            dispatch(getDiagnosticConfigFile(currentUnit)).then((res) => {
                if (res?.data?.configuration) {
                    setCurrentConfiguration(JSON.parse(JSON.stringify(res.data.configuration.configuration)));
                    setLoading(false);
                }
            }).catch((err) => {
                setLoading(false);
            })
        }
    }, [configuration])

    // Trigger to create the renderer part 
    React.useEffect(() => {
        init();
    }, [currentConfiguration])

    // Trigger to create the rootScope, renderer part 
    React.useEffect(() => {
        setWidgetLoadingValue(true);
        rootScope.sessionData = liveMonitoringData
        setWidgetSessionData();
    }, [liveMonitoringData])

    // Trigger to create the rootScope, renderer part 
    React.useEffect(() => {
        rootScope.liveData = liveMonitoringStreamData
        setWidgetLiveData();
    }, [liveMonitoringStreamData])

    const setWidgetLoadingValue = (value) => {
        setWidgetRendererData((prevData) => {
            const newData = prevData.map((item) => {
                return { ...item, properties: { ...item.properties, loader: value } };
            });
            return newData;
        });
    }

    const setWidgetSessionData = async () => {
        let stateObj = {};
        let stateId = [];
        for (let index = 0; index < widgetMetaData.length; index++) {
            const element = widgetMetaData[index];
            const ID = element.parsedConfiguration.id;
            const type = element.parsedConfiguration.type;
            const parsedData = element.parsedConfiguration.data;
            let responseResult = rootScope.sessionData;
            if (diagnosticChartDataParser[type]) {
                responseResult = diagnosticChartDataParser[type]({
                    _rootScope: rootScope,
                    _widgetScope: element.scope,
                    _sessionData: rootScope.sessionData
                })
            }else{
                responseResult = diagnosticChartDataParser['_default']({
                    _rootScope: rootScope,
                    _widgetScope: element.scope,
                    _liveData: rootScope.liveData
                })
            }
            if (parsedData.session.transformResponse) {
                responseResult = await parsedData.session.transformResponse(
                    {
                        _rootScope: rootScope,
                        _widgetScope: element.scope,
                        _clientFunction: clientFunctions,
                        _clientResponse: responseResult,
                    }
                );
            }
            // compile map to props
            const mapPropsValue = await compileConfiguration(element.rawConfiguration.data.session.mapToProperties, {
                _rootScope: rootScope,
                _widgetScope: element.scope,
                _clientResponse: responseResult,
            }, { executeFunction: false });

            // Update MetaData
            const metaUpdateIndex = widgetMetaData.findIndex((i) => i.parsedConfiguration.id === ID);
            widgetMetaData[metaUpdateIndex].clientResponse = responseResult;
            // Update State
            stateObj[ID] = mapPropsValue;
            stateId.push(ID);
        }
        setWidgetRendererData((prevData) => {
            const newData = prevData.map((item) => {
                if (stateId.includes(item.id)) {
                    return { ...item, properties: { ...item.properties, ...stateObj[item.id], loader: false } };
                }
                return item;
            });
            return newData;
        });
    }

    const setWidgetLiveData = async () => {
        let stateObj = {};
        let stateId = [];
        for (let index = 0; index < widgetMetaData.length; index++) {
            const element = widgetMetaData[index];
            const ID = element.parsedConfiguration.id;
            const type = element.parsedConfiguration.type;
            const parsedData = element.parsedConfiguration.data;
            let responseResult = rootScope.liveData;
            if (diagnosticChartDataParser[type]) {
                responseResult = diagnosticChartDataParser[type]({
                    _rootScope: rootScope,
                    _widgetScope: element.scope,
                    _liveData: rootScope.liveData
                })
            }else{
                responseResult = diagnosticChartDataParser['_default']({
                    _rootScope: rootScope,
                    _widgetScope: element.scope,
                    _liveData: rootScope.liveData
                })
            }
            if (parsedData.live.transformResponse) {
                responseResult = await parsedData.live.transformResponse(
                    {
                        _rootScope: rootScope,
                        _widgetScope: element.scope,
                        _clientFunction: clientFunctions,
                        _clientResponse: responseResult,
                    }
                );
            }
            // compile map to props
            const mapPropsValue = await compileConfiguration(element.rawConfiguration.data.live.mapToProperties, {
                _rootScope: rootScope,
                _widgetScope: element.scope,
                _clientResponse: responseResult,
            }, { executeFunction: false });
            // Update MetaData
            const metaUpdateIndex = widgetMetaData.findIndex((i) => i.parsedConfiguration.id === ID);
            widgetMetaData[metaUpdateIndex].clientResponse = responseResult;
            // Update State
            stateObj[ID] = mapPropsValue;
            stateId.push(ID);
        }
        setWidgetRendererData((prevData) => {
            const newData = prevData.map((item) => {
                if (stateId.includes(item.id)) {
                    return { ...item, properties: { ...item.properties, ...stateObj[item.id], loader: false } };
                }
                return item;
            });
            return newData;
        });
    }


    const init = async () => {
        if (currentConfiguration && Object.keys(currentConfiguration).length) {
            await createWidgetData(currentConfiguration);
        }
    };

    // Rootscope creation
    const createRootScope = () => {
        let rootObj = {
            sessionData: [],
            liveData: [],
            translateText: (id, values = {}) => { return intl.formatMessage({ id }, values) }
        };
        rootScope = { ...rootScope, ...rootObj };
        return rootScope;
    }

    // Set the tab property using parsedconfig and expressions from jsonata 
    const createWidgetData = async (rawConfiguration) => {
        const rawWidgetConfig = rawConfiguration?.widget?.widgets;
        let widgetObj = [];
        let widgetMetaDataObj = [];

        if (rawWidgetConfig?.length) {
            for (let index = 0; index < rawWidgetConfig.length; index++) {
                const widget = rawWidgetConfig[index];
                let scope = { rawConfiguration: { ...widget } };
                let parsedConfig = await compileConfiguration(widget, {
                    _rootScope: rootScope,
                    _widgetScope: scope,
                    _clientFunction: clientFunctions
                }, { executeFunction: false });

                if (parsedConfig.widgetScope) {
                    scope = { ...scope, ...parsedConfig.widgetScope }
                }
                const widgetMetaValue = {
                    rawConfiguration: widget,
                    parsedConfiguration: parsedConfig,
                    scope,
                    clientResponse: {}
                }

                const widgetValue = {
                    id: parsedConfig.id,
                    span: parsedConfig.span,
                    type: parsedConfig.type,
                    widget: ComponentMapping(parsedConfig),
                    properties: parsedConfig.properties,
                };
                widgetObj.push(widgetValue);
                widgetMetaDataObj.push(widgetMetaValue);
            }
        }
        widgetMetaData = widgetMetaDataObj
        setWidgetRendererData(widgetObj);
        return widgetObj;
    }


    return (
        <React.Fragment>
            {widgetRendererData?.length ? [widgetRendererData.map((widgetObj, index) => {
                return (
                    <div key={widgetObj.id + index} id={widgetObj.id} className={`dashboard-renderer dashboard-renderer-span${widgetObj.span}`}>
                        <widgetObj.widget {...widgetObj.properties} />
                    </div>
                )
            })] : loaderIconVisible ? null : 
            <div />}
        </React.Fragment >
    )
}

function mapDispatchToProps(dispatch,) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps(state,) {
    return {
    }
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LiveDiagnosticRenderer))