const WoopraEvents = (event, customProperties) => {
    let eventData;
    let mergeProperties;
    let defaultProperties = {
        url: window.location.href,
        title: window.location.pathname.replace(/\//g, "")
    }

    // Custom Properties
    if (customProperties) {
        mergeProperties = { ...defaultProperties, ...customProperties }
        eventData = window.woopra.track(event, mergeProperties);
        // Default Properties
    } else {
        eventData = window.woopra.track(event, defaultProperties);
    }

    return { eventData };
};

export default WoopraEvents;