
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { $CombinedState, bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { smartTagMangement } from '../../../service/api';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { brandLocations, unitModels } from '../../../utils/imgContants';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { getOrganizationCategoryDetails } from '../../../actions/navigation/organizationmanagement/organizationCategoryDetailsActions'
import { getOrganizationLocationTypes } from '../../../actions/navigation/organizationmanagement/organizationLocationTypesActions'
import { getOrganizationSubElementCount, resetOrganizationSubElementCount } from '../../../actions/navigation/organizationmanagement/organizationSubElementCountActions'
import { getOrganizationUnitsDetails } from '../../../actions/navigation/organizationmanagement/organizationUnitsDetailsActions'
import { deleteOrganizationElement } from '../../../actions/navigation/organizationmanagement/deleteOrganizationElementAction'
import { UserProfile } from '../../../service/api';
import api from '../../../service/api';
import http from '../../../service/httpService';
import $ from 'jquery';
import * as _ from 'lodash';
import { BRAND_ID } from '../../../utils/appConstants';
import { AutoSizer, List } from 'react-virtualized';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';
import { FormattedMessage, injectIntl } from 'react-intl';
import OrgTagsDetail from './orgTagsDetail';
import {readArchivesFromCompressedResponse} from '../../../utils/helpers'
import KCStore from '../../../utils/kcStorage';

var bg = require('../../../images/recipecreation/photoPlaceholder.png');
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;
const brandLocationsMap = {};
brandsLocationLogo.forEach(item => (brandLocationsMap[item.name] = item));
const brandsMap = {};
brandsLogo.forEach(item => (brandsMap[item.name] = item));

class smartTagTopology extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showElementInfo: false,
            showIcons: { editIcon: false, sameLevelIcon: false, nextLevelIcon: false, deleteIcon: false },
            level: 1,
            link: { group_name: '' },
            linkList: {},
            newLinkCreated: false,
            customerGroupType: { id: '1', name: 'Hospital' },
            country_name: '',
            selectedAddress: '',
            isAddressAvailable: false,
            isLoactionTypeAvailable: false,
            loaderIconVisible: true,
            isUnitItemClicked: false,
            unit: {},
            unitActiveObject: {
                unitIds: [],
                activationStatus: 'ACTIVE',
                assetNumber: ''
            },
            cgIds: '',
            imagelink: '',
            imagePreviewUrl: "",
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            access_modal: {
                open: false,
                message: "",
                isHtml: false,
                disabledBtn: false,
            },
            sortlevel: "",
            sorttype: "",
            showUserList: [],
            showSmartGroup: [],
            selectedUnitList: [],
            assetNumber: '',
            searchData: null,
            flattenTreeData: [], /* Contains flatten hierarchy data structure in Array<Array> */
            selectedGroupName: [],
            propsValue: '',
            showStPopUp:false
        };
        this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
        };
        this.treeData = {
            virtualScrollBufferRowCount: 10,
            rowBaseHeight: 80,
            virtualScrollRef: React.createRef(),
            collapsedNodes: {},
            activeNode: {},
            expand: {}
        };
        this.getOrgnanizationView = this.getOrgnanizationView.bind(this);
        this.treeViewRowRenderer = this.treeViewRowRenderer.bind(this);
        this.getTreeViewRowHeight = this.getTreeViewRowHeight.bind(this);
    }

    // clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

    componentDidMount = async (isFIrstTime = true) => {
        window.addEventListener('resize', this.setTreeViewHeight);
        this.props.searchVisible(true);
        document.body.style.backgroundColor = "#F2F2F2";
        // $(".treeViewCell button").click(function(e) {
        //     e.stopPropagation();
        //  })
        if (this.props && this.props.location && this.props.location.userCheckedData) {
            if(this.props.location.from && this.props.location.from === "Unit"){
                const objKeys = Object.keys(this.props.location.userCheckedData);
                for (let index = 0; index < objKeys.length; index++) {
                    const element = objKeys[index];
                    this.onUnitItemClick(null, this.props.location.userCheckedData[element], true)
                }
            }else if(this.props.location.from && this.props.location.from === "Org"){
                const objKeys = Object.keys(this.props.location.userCheckedData);
                for (let index = 0; index < objKeys.length; index++) {
                    const element = objKeys[index];
                    this.treeData.activeNode[element] = this.props.location.userCheckedData[element]
                }
            }else{
                this.setState({ selectedUnitList: this.props.location.userCheckedData })
                for (let index = 0; index < this.props.location.userCheckedData.length; index++) {
                    const element = this.props.location.userCheckedData[index];
                    this.onUnitItemClick(null, element, true)
                }
            }
        }
        let smartTagObj = JSON.parse(KCStore.getItem("smartTagInfo"));
        if(smartTagObj.mode !== 'isView'){
            $("div").removeClass("modal-backdrop fade show");
        }
        if ((smartTagObj.mode === 'isView' || smartTagObj.mode === 'edit') && smartTagObj.type === "orgList") {
            const selectedRow = smartTagObj.type === "unitList" ? smartTagObj.selectedRow && smartTagObj.selectedRow.userUnitIds ? smartTagObj.selectedRow.userUnitIds : [] : smartTagObj.selectedRow &&  smartTagObj.selectedRow.userCGIDs ? smartTagObj.selectedRow.userCGIDs : [] ;
            for (let index = 0; index < selectedRow.length; index++) {
                const element = selectedRow[index];
                this.treeData.activeNode[element] = {}
            }
        }
        if(smartTagObj.mode === 'edit' && smartTagObj.type === "unitList"){
            this.setState({ isUnitItemClicked : true })
        }
        await this.getCustGroupID();

    }

    componentDidUpdate(prevState) {
        /**
         * Tree View header has a dynamic height based on Org API response
         * So set the body height after ORG API success
         */
        this.setTreeViewHeight();
        let { searchData } = prevState;
        let curSearchData = this.state && this.state.searchData;
        if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
            this.getCustGroupID();
        }
    }

    /**
     * To remove the search text when navigating to other screens
     */
    componentWillUnmount() {
        this.props.searchText({});
        this.props.searchVisible(false);
    }

    onSuccessCloseModal = () => {
        this.setState({
            success_modal: {
                open: false
            }
        });

        this.props.history.replace({
            pathname: "/smartTag/tagList",
        })

        // To bring success modal to background, reset z-index back to default value
        $('#orgLoader').css('z-index', '');
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    /**
     * Get Customer Group ID by Customer ID
     */
    getCustGroupID = () => {
        let loggedinUser;
        let custGroupID = KCStore.getItem("custGroupID");
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        loggedinUser = loginUser.username;

        this.setState({ loaderIconVisible: true, })
        try {
            this.getOrgnanizationView(custGroupID);
        } catch (err) {
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        };
    }

    /**
     * Process Org hierarchy data
     * @param {*} orgJSON Hierarchy Org data
     */
    processOrgnanizationJSON = async (orgJSON) => {
        // New Customer node display in organization managment

        const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
        let smartTagObj = JSON.parse(KCStore.getItem("smartTagInfo"));
        await this.handleManualExpandCollapse(5, flattenTreeData,smartTagObj.type === "orgList" ? true : false);
        this.setState({
            linkList: orgJSON,
            loaderIconVisible: false,
            flattenTreeData
        }, () => {
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        });
    }
    /**
     * Convert Encoding UTF8 to binary
     */
    convertToBinary = (string) => {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }

    /**
     * Get Org hierarchy data
     * @param {*} values List of CGIDs
     */
    getOrgnanizationView = async (values) => {
        this.setState({ loaderIconVisible: true, });
        let smartTagObj = JSON.parse(KCStore.getItem("smartTagInfo"));
        const { mode, selectedRow } = smartTagObj;
        const isView = mode === "isView";
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let { searchData } = this.state;
        let headerVal = {
            "Cgids": values,
            'Content-Type': 'application/json',
            'brandids': this.filterstate.sBrandids.length > 0 ? this.filterstate.sBrandids.join(',') : BRAND_ID,
            'useremail': loggedinUser,
        }
        if (isView) {
            headerVal['tagname'] = selectedRow && selectedRow.tagName ? selectedRow.tagName : ''
        }
        if (this.filterstate.sSmartGroups) {
            headerVal['sgid'] = this.filterstate.sSmartGroups;
        }
        if (this.state.sortlevel !== "") {
            headerVal['sortlevel'] = this.state.sortlevel;
        }

        if (this.state.sorttype !== "") {
            headerVal['sorttype'] = this.state.sorttype;
        }        
        let apiUrl = `${UserProfile.get_Orgnanization_View + KCStore.getItem("customerId")}`;
        if (searchData && searchData.searchtext) {
            apiUrl = `${apiUrl}?searchText=${searchData.searchtext.toString()}`;
        }
        if (searchData) {
            let searchParam = {};
            Object.keys(searchData).map((item) => {
                if (item !== "searchtext") {
                    searchParam[item] = searchData[item];
                }
            })
            if (searchParam && Object.keys(searchParam).length) {
                apiUrl = `${apiUrl}?searchParams=${btoa(this.convertToBinary(JSON.stringify(searchParam)))}`;
            }
        }

        await http({
            method: "GET",
            url: apiUrl,
            headers: headerVal, data: {},
        }).then(async response => {
            // if (response.data.fetchFromS3) {
            //     await $.getJSON(response.data.presignedS3URL, (data) => {
            //         this.processOrgnanizationJSON(data);
            //     });
            // }
            // else {
            //     this.processOrgnanizationJSON(response.data);
            // }
            let orgJSON = await readArchivesFromCompressedResponse(response.data);
            this.processOrgnanizationJSON(orgJSON);
            this.setState({
                cgIds: values
            })
        }).catch(err => {
            console.log("Org Compressed Data Error", err);
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    static getDerivedStateFromProps = (props) => ({ ...props });

    /**
     * Check if addess field present in category
     * @param {*} category_id Category ID
     * @returns Object
     */
    checkIsAddressAvailable(category_id) {
        const { OrganizationCategoryDetailsInfo } = this.props;
        let isLoactionTypeAvailable = false;
        let findIndex = OrganizationCategoryDetailsInfo['customer_group_category'].findIndex(
            (item) => {
                if (item.category_id === category_id && item.category_attributes) {
                    if (item.category_attributes.includes('LocationType')) {
                        isLoactionTypeAvailable = true;
                    }
                    if (item.category_attributes.includes('Address')) {
                        return true;
                    }
                }
            })
        if (findIndex > -1) return { addressAvailable: true, locationAvailable: isLoactionTypeAvailable };
        return { addressAvailable: false, locationAvailable: isLoactionTypeAvailable };
    }

    /**
     * Node click handler
     * @param {*} e Event object
     * @param {*} link Node object
     */
    onItemClick(e, link, imagePreviewUrl) {        
        if(!link.sameLevelAccess){
            if(!link.nextLevelAccess){
                return;
            }
        }
        let smartTagInfo = JSON.parse(KCStore.getItem("smartTagInfo"));
        const { mode } = smartTagInfo;
        const isView = mode === "isView";
        if (isView) {
            return;
        }
        if (smartTagInfo.type === 'unitList') {
            return;
        }
        let isAddressAvailable = false;
        let isLoactionTypeAvailable = false;
        const availableObj = this.checkIsAddressAvailable(link.category_id);

        if (availableObj.addressAvailable) {
            isAddressAvailable = true;
        }
        if (availableObj.locationAvailable) {
            isLoactionTypeAvailable = true;
        }
        const { level_id } = link;
        if (level_id === 1) {
            return;
        }
        let { customerGroupType, country_name, newLinkCreated, cgIds } = this.state;
        let selectedAddress = null;
        if (link.dynastyAttributes) {
            selectedAddress = JSON.parse(link.dynastyAttributes);
            if (selectedAddress.location_type_id !== 'undefined') {
                customerGroupType.id = selectedAddress.location_type_id;
                customerGroupType.name = selectedAddress.location_type;
            }
            country_name = selectedAddress.country;
        } else {
            selectedAddress = {
                street: '',
                country: '',
                city: '',
                zipcode: '',
                country_name: '',
            }
            link['selectedAddress'] = selectedAddress;
        }

        let isAddressAvail = false;
        if (level_id !== 2 && level_id !== 5) {
            isAddressAvail = true;
        }

        const showIcons = { editIcon: link.sameLevelAccess, sameLevelIcon: link.sameLevelAccess, nextLevelIcon: link.nextLevelAccess, deleteIcon: link.sameLevelAccess }
        this.setState({
            link: link, level: level_id, showIcons: showIcons, customerGroupType: customerGroupType,
            selectedAddress: selectedAddress, isAddressAvailable: isAddressAvail,
            isLoactionTypeAvailable: isLoactionTypeAvailable, isUnitItemClicked: false, imagelink: link.imagePath, unit: {}, country_name: country_name, imagePreviewUrl: ""
        }, () => {
            if (!newLinkCreated) {
                this.props.getOrganizationSubElementCount(link.cust_gp_id, cgIds);
            } else {
                this.props.resetOrganizationSubElementCount();
            }
        });
        if (imagePreviewUrl) {
            this.setState({ imagePreviewUrl });
        }
        let selectedGroupName = this.state.selectedGroupName;

        if ((this.treeData.activeNode[link.cust_gp_id])) {
            delete this.treeData.activeNode[link.cust_gp_id];
            const newIndex = selectedGroupName.findIndex((i) => i === link.group_name);
            selectedGroupName.splice(newIndex, 1)
            // for (let index = 0; index < Object.keys(this.treeData.activeNode).length; index++) {
            //     const element = Object.keys(this.treeData.activeNode)[index];
            //     const nodeDynasty = this.treeData.activeNode[element].dynasty;
            //     if(nodeDynasty.includes(link.cust_gp_id)){
            //         const newInnerIndex = selectedGroupName.findIndex((i)=>i === this.treeData.activeNode[element].group_name);
            //         selectedGroupName.splice(newInnerIndex)
            //         delete this.treeData.activeNode[element];
            //     }
            // }
        } else {
            selectedGroupName.push(link.group_name);
            // let nodeId = link.dynasty.split(",");
            // nodeId.map((item) => {
            //     const val = this.treeData.activeNode[item]
            //     if (val) {
            //         delete this.treeData.activeNode[Number(val.cust_gp_id)];
            //     }
            // })
            this.treeData.activeNode[link.cust_gp_id] = link;
        }
        this.setState({ selectedGroupName })
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }

    /**
     * Unit node click handler
     * @param {*} e Event
     * @param {*} unit Unit node object
     */
    onUnitItemClick(e, unit, isIniital = false) {
        let smartTagInfo = JSON.parse(KCStore.getItem("smartTagInfo"));
        const { mode } = smartTagInfo;
        const isView = mode === "isView";
        if (isView) {
            return;
        }
        if (smartTagInfo.type === 'orgList') {
            return;
        }
        const { UNITID, BRAND_NAME, smartGroup } = unit;
        this.setState({
            isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6, showSmartGroup: smartGroup
        });
        if (!isIniital) {
            this.props.getOrganizationUnitsDetails(UNITID, BRAND_NAME);
            this.selectedunit(UNITID, BRAND_NAME);
        }
        if (this.treeData.activeNode[UNITID]) {
            delete this.treeData.activeNode[UNITID];
        } else {
            this.treeData.activeNode[UNITID] = unit;
        }
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }

    /**
     * Request to get unit details
     * @param {*} UNITID Unit Serial No
     * @param {*} BRAND_NAME Brand Name
     */
    selectedunit(UNITID, BRAND_NAME) {
        let url = api.organizaionUnitsDetailsUrl + UNITID + '/' + BRAND_NAME;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                let restval = response.data;
                let assetFieldValue = restval.units.AssetNumber;
                const UnitActiveObject = { unitIds: restval.units.id, activationStatus: restval.units.ActivationStatus, assetNumber: assetFieldValue };
                this.setState({ unitActiveObject: UnitActiveObject, assetNumber: assetFieldValue });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }


    /**
     * Unit node selection change handler
     * Collect selected units in an array
     * @param {*} unit Unit data
     */
    onOrgCheckBoxChange = (unit) => {
        const ID = unit.UNITID;
        let { selectedUnitList, showIcons } = this.state;
        const findIndex = selectedUnitList.findIndex((i) => i.UNITID === ID);
        if (findIndex >= 0) {
            selectedUnitList.splice(findIndex, 1);
        } else {
            selectedUnitList.push(unit)
        }
        showIcons['editIcon'] = selectedUnitList.length === 1 ? true : false;
        showIcons['deleteIcon'] = selectedUnitList.length === 1 ? true : false;
        this.setState({ selectedUnitList, showIcons });
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }

    /**
     * Set tree view container height
     */
    setTreeViewHeight() {
        const windowHeight = $(window).height();
        const $treeViewBody = $('.assignOrgTreeWrapper .treeViewBody');
        $treeViewBody.css('height', $treeViewBody.offset() && $treeViewBody.offset().top ? windowHeight - $treeViewBody.offset().top : windowHeight - 0);
    }

    /**
     * Convert hierarchy data structure into flatten array
     * @param {*} nodes Array<HierarchyNode> Array of hierarchy node object
     * @param {*} nodeLevel Node level No
     * @param {*} currentRowIndex Current row index for Tree Data
     * @param {*} flattenTreeData Array<Array> Accumulated flatten tree data
     * @param {*} parentRowIndex Parent Node row index
     * @param {*} parentColIndex Parent Node column index
     * @returns Array<Array>
     */
    convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
        let prevRowIndex = 0;
        const nodeIndexes = [];
        const nodesLength = nodes.length;
        for (let i = 0; i < nodesLength; i++) {
            const node = nodes[i];

            // Set row and column index
            i > 0 && currentRowIndex++;
            const rowIndex = currentRowIndex;
            const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
            const colIndex = level - 1;

            // Set connectors in midway indexes, but not for level 1 and 6
            if ((level > 1 && level < 6) && i > 0) {
                const endRowIndex = rowIndex - 1; // Exclude current row
                let midwayRowsCount = endRowIndex - prevRowIndex;
                if (midwayRowsCount > 0) {
                    const startRowIndex = prevRowIndex + 1; // Exclude start index
                    for (let j = startRowIndex; j <= endRowIndex; j++) {
                        const rowData = flattenTreeData[j] || [];
                        rowData[colIndex] = {
                            __NODE_DATA__: {
                                isConnector: true,
                                rowIndex: j,
                                colIndex,
                                parentRowIndex,
                                parentColIndex
                            }
                        };
                        flattenTreeData[j] = rowData;
                    }
                }
            }

            // Create node object
            const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
            const { [childNodesProp]: childNodes, ...nodeClone } = node;
            const nodeData = {
                isNode: true,
                rowIndex,
                colIndex,
                parentRowIndex,
                parentColIndex,
                hasChildren: !!node.child_count,
                isFirstChild: nodesLength > 1 && i === 0,
                isMidwayChild: i > 0 && i < (nodesLength - 1),
                isLastChild: nodesLength > 1 && i === (nodesLength - 1),
                isOnlyChild: nodesLength === 1
            };
            if (level === 6) {
                const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
                nodeData.smartGroupsName = smartGroupsName;
            }
            nodeClone.__NODE_DATA__ = nodeData;

            // Set node object in respective index
            const rowData = flattenTreeData[rowIndex] || [];
            rowData[colIndex] = nodeClone;
            flattenTreeData[rowIndex] = rowData;
            nodeIndexes.push([rowIndex, colIndex]);

            // Process its children
            if (nodeData.hasChildren) {
                const result = this.convertToFlattenTreeData(
                    childNodes,
                    nodeLevel + 1,
                    currentRowIndex,
                    flattenTreeData,
                    rowIndex,
                    colIndex
                );
                flattenTreeData = result.flattenTreeData;
                flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
                currentRowIndex = result.currentRowIndex;
            }
            prevRowIndex = rowIndex;
        }
        return { flattenTreeData, nodeIndexes, currentRowIndex };
    }

    /**
     * Get Unit Smart Group names for display at Unit Node
     * @param {*} unitNode Unit Node object
     * @returns Array<String> Smart Group names
     */
    getUnitSmartGroupsName(unitNode) {
        let smartGroups = unitNode.smartGroup || [];
        let smartGroupsName = [];
        if (smartGroups.length > 3) {
            smartGroupsName.push({
                sgName: '',
                sgFirstName: smartGroups.length,
            });
        } else if (smartGroups.length > 0) {
            smartGroups.forEach(item => {
                smartGroupsName.push({
                    sgName: item.name,
                    sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                        ? item.smartGroupKeyName
                        : item.name.charAt(0)
                });
            });
        }
        return smartGroupsName;
    }

    /**
     * Get Node object from Hierarchy Data Structure
     * Use this method to retain the props removed during flatten array conversion
     * @param {*} searchNode Node to search
     * @returns Node object
     */
    getNodeFromHierarchyData(searchNode) {
        const dynastyArr = searchNode.dynasty.split(',').map(item => parseInt(item));
        let node = this.state.linkList;
        for (let i = 0; i < dynastyArr.length; i++) {
            const nodes = node[`level${i + 1}`];
            for (let j = 0; j < nodes.length; j++) {
                if (nodes[j].cust_gp_id === dynastyArr[i]) {
                    node = nodes[j];
                    break;
                }
            }
        }
        node = { ...node };
        node.__NODE_DATA__ = { ...searchNode.__NODE_DATA__ };
        return node;
    }

    /**
     * Tree View node expand/collapse click handler
     * @param {*} node Node object
     */
    handleExpandCollapse(node) {
        if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
            this.treeData.collapsedNodes[node.cust_gp_id] = true;
        } else {
            delete this.treeData.collapsedNodes[node.cust_gp_id];
        }
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }

    handleManualExpandCollapse = (levelID, data,makeCollapse) => {
        let smartTagObj = JSON.parse(KCStore.getItem("smartTagInfo"));
        this.treeData.active = levelID
        _.forEach(data, item => {
            _.forEach(item, node => {
                if (node && (node.id || node.ID) && smartTagObj.type === "unitList" && (smartTagObj.mode === "isView" || smartTagObj.mode === "edit")) {
                    const selectedRow = smartTagObj && smartTagObj.selectedRow ? smartTagObj.selectedRow.userUnitIds.map(Number) : [];
                    if(selectedRow && selectedRow.length  && (selectedRow.includes(node.id) || selectedRow.includes(node.ID))){
                        let obj= {
                            UNITID: node.UNITID,
                            id: node.id || "",
                            ID: node.ID || ""
                        }
                        this.treeData.activeNode[node.UNITID] = obj
                    }
                }
                if (node && node.category_id == levelID && node.child_count > 0 && makeCollapse) {
                    if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
                        this.treeData.collapsedNodes[node.cust_gp_id] = true;
                        this.treeData.expand[node.level_id] = true;
                    } else {
                        delete this.treeData.collapsedNodes[node.cust_gp_id];
                        delete this.treeData.expand[node.level_id];
                    }
                }
            })
        })
        if(this.treeData && this.treeData.virtualScrollRef && this.treeData.virtualScrollRef.current && this.treeData.virtualScrollRef.current.recomputeRowHeights){
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        }
    }

    /**
     * Get the Node status like invisible, count node
     * @param {*} node Node object
     * @returns Object Status object
     */
    getNodeStatus(node) {
        const nodeData = node.__NODE_DATA__;
        const dynastyArr = node.dynasty.split(',').map(item => parseInt(item));

        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        dynastyArrCopy.length && dynastyArrCopy.pop(); // Remove parent node ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[node.parent_group_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;
        const parentNode = nodeData.parentRowIndex !== null ? this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex] : null;

        // Active status, Root Node active status calculation
        let isRootActiveNode = false;
        let isActive = false;
        for (const key in this.treeData.activeNode) {
            // if (dynastyArr.indexOf(parseInt(key)) >= 0) {
            if (Number(node.cust_gp_id) === Number(key)) {
                isActive = true;
                break;
            }
        }
        isRootActiveNode = this.treeData.activeNode[node.cust_gp_id]
        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode ? parentNode.child_count : 0,
            isRootActiveNode,
            isActive
        };
    }

    /**
     * Create view elements for Tree View Node
     * @param {*} node Node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getNodeView(node, { key }) {
        let smartTagObj = JSON.parse(KCStore.getItem("smartTagInfo"));
        // For empty or connector
        if (!node) {
            return <div key={key} className="treeViewCell"></div>
        } else if (node.__NODE_DATA__.isConnector) {
            const parentNode = this.state.flattenTreeData[node.__NODE_DATA__.parentRowIndex][node.__NODE_DATA__.parentColIndex];
            const activeClass = this.getNodeStatus(parentNode).isActive ? 'active' : '';
            return <div key={key} className={`treeViewCell connector ${activeClass}`}></div>
        }

        // For node
        const nodeData = node.__NODE_DATA__;

        const status = this.getNodeStatus(node);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell" ></div>
        } else if (status.isCountNode) {
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
            return <div key={key} className={`treeViewCell countCell ${activeClass} ${rootActiveClass}`} >
                <div className="treeViewNodeWrapper">
                    <Link
                        className="treeViewNode"
                        to="#"
                        onClick={(e) => { this.onItemClick(e, this.getNodeFromHierarchyData(node)) }}>
                        {status.displayCount}
                    </Link>
                </div>
            </div>
        }

        const dynastyAttributes = node.dynastyAttributes ? JSON.parse(node.dynastyAttributes) : {};
        const nodeImage = node.imagePath || (brandLocationsMap[dynastyAttributes.location_type]
            ? brandLocationsMap[dynastyAttributes.location_type].img
            : bg);
        const { isFirstChild, isMidwayChild, isLastChild, hasChildren } = nodeData;
        const isCollapsed = this.treeData.collapsedNodes[node.cust_gp_id];
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const hasChildrenClass = hasChildren ? 'hasChildren' : '';
        const activeClass = status.isActive ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        return <div key={key} className={`treeViewCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${hasChildrenClass} ${activeClass} ${rootActiveClass}`} >
            <div className="treeViewNodeWrapper">
                <Link
                    className="treeViewNode"
                    to="#"
                    onClick={(e) => {
                        if (e.defaultPrevented) return
                        e.preventDefault(); 
                        this.onItemClick(e, this.getNodeFromHierarchyData(node), nodeImage) 
                        }}>
                    <img className="nodeImg" src={nodeImage || bg} alt="" />
                    <span className="text-truncate nodeInfo"><b>{node.group_name}</b></span>
                    {node.isOrgTagExist && <button className="btn orgTagedUnits" type="button" onClick={(e) => {
                            if (e.defaultPrevented) return
                            e.preventDefault()
                            this.showSTagInfo(node,"org")
                    }}>&nbsp;</button>}
                </Link>
                {hasChildren ? <button className={`expandCollapseBtn ${isCollapsed ? 'collapsed' : ''}`} onClick={() => this.handleExpandCollapse(node)}></button> : ''}
            </div>
        </div>
    }

    showSTagInfo = (node,type="org") =>{
        this.setState({
            showStPopUp:true,
            isUnitItemClicked:type === "unit" ? true:false,
            unit : type === "unit" ? node:{},
            link : type === "org" ? node:{},
        },()=>{
            $('#unitSmartTagsDetails').modal('show')
        })

    }

    /**
     * Get the Unit Node status like invisible, count node
     * @param {*} unitNode Unit Node object
     * @returns Object Status object
     */
    getUnitNodeStatus(unitNode) {
        const nodeData = unitNode.__NODE_DATA__;
        const parentNode = this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex];
        const dynastyArr = parentNode.dynasty.split(',').map(item => parseInt(item));

        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[parentNode.cust_gp_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;

        // Active status, Root Node active status calculation
        let isRootActiveNode = false;
        let isActive = false;
        let tagDetails = JSON.parse(KCStore.getItem("smartTagInfo"));
        for (const key in this.treeData.activeNode) {
            if (unitNode.UNITID === key) {
                isActive = tagDetails.type === "unitList" ? true : false;
                isRootActiveNode = tagDetails.type === "unitList" ? true : false;
            } else {
                if (dynastyArr.indexOf(parseInt(key)) >= 0) {
                    isActive = tagDetails.type === "unitList" ? true : false;
                    isRootActiveNode = tagDetails.type === "unitList" ? true : false
                }
            }
        }

        // Selection status
        const isSelected =  this.state.selectedUnitList &&  this.state.selectedUnitList.length ? this.state.selectedUnitList.findIndex(item => item.ID === unitNode.UNITID) >= 0 : false;

        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode.child_count,
            isRootActiveNode,
            isActive,
            isSelected
        };
    }

    /**
     * Create view elements for Tree View Unit Node
     * @param {*} unitNode Unit node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getUnitNodeView(unitNode, { key }) {
        let smartTagObj = JSON.parse(KCStore.getItem("smartTagInfo"));
        // For empty
        if (!unitNode) {
            return <div key={key} className="treeViewCell"></div>
        }

        const status = this.getUnitNodeStatus(unitNode);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell"></div>
        } else if (status.isCountNode) {
            const { showElementInfo } = this.state;
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
            return <div key={key} className={`treeViewCell unitCell countCell ${activeClass} ${rootActiveClass}`}>
                <div className="treeViewNodeWrapper">
                    <Link
                        className="treeViewNode"
                        to="#"
                        onDoubleClick={() => { this.setState({ showElementInfo: !showElementInfo }) }}
                        onClick={(e) => { this.onUnitItemClick(e, unitNode) }}>
                        {status.displayCount}
                    </Link>
                </div>
            </div>
        }
        const { showElementInfo } = this.state;
        const brandImageStorage = KCStore.getItem('modelImage') ? JSON.parse(KCStore.getItem('modelImage')) : [];
        const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unitNode.BRAND_NAME && i.MODEL_NAME === unitNode.MODEL_NAME) : [];
        let unitImage = '';
        if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "") {
            unitImage = filteredBrandImage[0].LOGO_URL;
        } else {
            unitImage = brandsMap[unitNode.MODEL_NAME] ? brandsMap[unitNode.MODEL_NAME].img : bg;
        }
        const activeValueKey  = unitNode?.subscription_status !== null && unitNode?.subscription_status !== 'null' ? unitNode.subscription_status === 0 ? 'INACTIVE' : 'ACTIVE' : unitNode.ACTIVATION_STATUS;

        const isUnitInActive = activeValueKey === 'INACTIVE-ONLINE' || activeValueKey === 'INACTIVE';
        const nodeData = unitNode.__NODE_DATA__;
        const { isFirstChild, isMidwayChild, isLastChild } = nodeData;
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const activeClass = status.isActive ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        return <div key={key} className={`treeViewCell unitCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${activeClass} ${rootActiveClass}`}>
            <div className="treeViewNodeWrapper">
                <Link
                    to="#"
                    className={`treeViewNode ${isUnitInActive ? 'inActiveUnitNode' : ''}`}
                    onDoubleClick={() => { this.setState({ showElementInfo: !showElementInfo }) }}
                    onClick={(e) => { 
                        if (e.defaultPrevented) return
                        e.preventDefault(); 
                        this.onUnitItemClick(e, unitNode) }}>
                    <img className="nodeImg" src={unitImage} />
                    <span className="nodeInfo">
                        <b className="d-block text-truncate unitSerialNo">{unitNode.UNITID}</b>
                        <b className="d-block text-truncate">{unitNode.UNIT_NUMBER}</b>
                        <b className="d-block text-truncate">{unitNode.MODEL_NAME}</b>
                    </span>
                        {isUnitInActive ? <span className="inActiveIcon"></span> : ''}
                    {unitNode.isUnitTagExist && <button className="btn tagedUnits" type="button" onClick={(e) => {
                            if (e.defaultPrevented) return
                            e.preventDefault()
                            this.showSTagInfo(unitNode,"unit")
                    }}>&nbsp;</button>}
                    {
                        status.isSelected ?
                            <React.Fragment>
                                <div className="nodeCheckbox">
                                    <input
                                        className="d-none"
                                        id={unitNode.UNITID}
                                        name={unitNode.UNITID}
                                        type="checkbox"
                                        checked={true}
                                        value={unitNode.UNITID}
                                        onChange={(e) => this.onOrgCheckBoxChange(unitNode)} />
                                    <label htmlFor={unitNode.UNITID}></label>
                                </div>
                            </React.Fragment>
                            : ''
                    }
                </Link>
            </div>
        </div>
    }

    /**
     * Check if the row has at least one visible node
     * @param {*} rowData Row Array
     * @returns Boolean - Return true if it has visible node else return false
     */
    doesRowHasVisibleNode(rowData) {
        return rowData.some((node, i) => node && node.__NODE_DATA__.isNode && (i < 5 ? !this.getNodeStatus(node).isInvisible : !this.getUnitNodeStatus(node).isInvisible));
    }

    /**
     * Row render callback for Virtual Scroll component
     * @param {*} param Event object
     * @returns ReactDOM
     */
    treeViewRowRenderer({ index, key, style }) {
        const { flattenTreeData } = this.state;
        const rowData = flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return <div className="treeViewRow" key={key} style={style}></div>
        }
        return <div className="treeViewRow" key={key} style={style}>
            {new Array(5).fill().map((_, j) => this.getNodeView(rowData[j], { key: `${key}_${j}` }))}
            {this.getUnitNodeView(rowData[5], { key: `${key}_5` })}
        </div>
    }

    /**
     * Return the computed Tree View row height
     * Compute the height based on row content
     * @param {*} param Event object
     * @returns Number
     */
    getTreeViewRowHeight({ index }) {
        const rowData = this.state.flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return 0;
        }
        // Compute row height based on unit node
        const unitNode = rowData[5];
        const status = unitNode ? this.getUnitNodeStatus(unitNode) : null;
        let rowBaseHeight = this.treeData.rowBaseHeight;
        if (unitNode && !status.isInvisible && !status.isCountNode) {
            if (unitNode.UNIT_NUMBER && unitNode.MODEL_NAME) {
                rowBaseHeight = rowBaseHeight + 15;
            }
            if (unitNode.__NODE_DATA__.smartGroupsName.length) {
                rowBaseHeight = rowBaseHeight + 22;
            }
        }
        return rowBaseHeight;
    }

    accessNowModel = (e) => {
        this.getSmartGroupID();
        this.setState({
            access_modal: {
                open: false
            }
        })
    }

    accessLaterModel = () => {
        this.setState({
            access_modal: {
                open: false
            }
        })
    }

    onClickFinish = async (e) => {
		this.setState({
			loaderIconVisible: true,
		});
		let selectedNodeList = this.treeData.activeNode;
        let tagDetails = JSON.parse(KCStore.getItem("smartTagInfo"));
        let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let userName = KCStore.getItem("userName");
        let value =  tagDetails.unitTagDetails;
        
        if (tagDetails.type) {
            if (tagDetails.type === "unitList") {
                value = tagDetails.unitTagDetails;
                selectedNodeList = Object.values(selectedNodeList).map(item => item.id || item.ID);
            } else {
                value = tagDetails.orgTagDetails;
                selectedNodeList = Object.keys(selectedNodeList);
            }
        }
        if(!value || !value.TagName){
            this.setState({
				loaderIconVisible: false
			});
            return
        }
        if (tagDetails && tagDetails.selectedRow && tagDetails.selectedRow.unAssignedUnits && tagDetails.selectedRow.unAssignedUnits.length) { selectedNodeList = [...selectedNodeList, ...tagDetails.selectedRow.unAssignedUnits] };

        let allValue = [];
        let userValue = [];
        if(tagDetails && tagDetails.type === "orgList"){
            allValue = tagDetails && tagDetails.selectedRow && tagDetails.selectedRow.allcgids ? tagDetails && tagDetails.selectedRow.allcgids : [];
            userValue = tagDetails && tagDetails.selectedRow && tagDetails.selectedRow.userCGIDs ? tagDetails && tagDetails.selectedRow.userCGIDs : [];
        }else{
            allValue = tagDetails && tagDetails.selectedRow && tagDetails.selectedRow.allunitIds ? tagDetails && tagDetails.selectedRow.allunitIds : [];
            userValue = tagDetails && tagDetails.selectedRow && tagDetails.selectedRow.userUnitIds ? tagDetails && tagDetails.selectedRow.userUnitIds : [];
        }
        let existEle =  allValue && allValue.length ?allValue.filter(
            function(i) {
                return this.indexOf(i) < 0;
            },
            userValue
        ) : [];
        if(existEle && existEle.length){
            selectedNodeList = [...selectedNodeList, ...existEle]
        }
        let postdata = {
            "tagName": value.TagName,
            "description": value && value.TagDescription ? value.TagDescription : "",
            "tagType": tagDetails.type === "unitList" ? "UNIT" : "ORG",
            "createdBy": tagDetails.mode === "edit" ? tagDetails.selectedRow.createdBy : loggedinUser,
            "createdByName": tagDetails.mode === "edit" ? tagDetails.selectedRow.createdByName : userName,
            "updatedBy": loggedinUser,
            "updatedByName": userName,
            "customerGroupId": selectedNodeList,
            "unitId": selectedNodeList,
            "customerId": + KCStore.getItem("customerId"),
        };

		let url = smartTagMangement.createOrgTag;
		if (tagDetails.mode === 'add') {
			this.triggerSmartTag(url, postdata, 'post');
		} else {
			url = smartTagMangement.createOrgTag;
            const id = tagDetails.selectedRow.id;
			this.triggerSmartTag(url+`/${id}`, postdata, 'put');
		}
	}
	
	triggerSmartTag = async (url, postdata, method) => {
		await http[method](url, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						newMessageFormat: true,
						message: method === "post" ? 'KC2316' : 'KC2323'
					},
				});
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.response && err.response.data && err.response.data.error ? err.response.data.error : "KC1110",
				},
			});
		});
	}

    formBreadCrum = (dynasty) => {
        const { flattenTreeData } = this.state;
        let crum = [];
        const dynastyArr = dynasty.split(',');
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.cust_gp_id) {
                    if (dynastyArr.includes(nodeEle.cust_gp_id.toString())) {
                        crum.push(nodeEle.group_name);
                    }
                }
            }
        }
        // return crum && crum.length ? crum.join(' &gt; ') : '';
        return crum && crum.length ? crum.join(' > ') : '';
    }

    render() {
        const { formatMessage } = this.props.intl;
        let smartTagObj = JSON.parse(KCStore.getItem("smartTagInfo"));
        const { mode } = smartTagObj;
        const isView = mode === "isView";
        let { success_modal, error_modal, info_modal, selectedGroupName, flattenTreeData } = this.state;
        const { loaderIconVisible, linkList, access_modal } = this.state;
        let selectedNodeList = this.treeData.activeNode;
        let typeVal = JSON.parse(KCStore.getItem("smartTagInfo"))
        let pathVal = typeVal.type === 'orgList' ? "/smartTag/createOrgTag" : "/smartTag/createUnitTag"
        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map((item, index) => <li key={`catid_${index}`}>
                        <Link className={this.treeData.expand[item.level_id - 1] ? `collapseExpandHeader ${item.level_id - 1 === this.treeData?.active ? "activeArrow" : ''}` : "collapseExpandHeader expanded"} key={item.level_id} to="#" title={formatMessage({ id: 'KC1693' }, { number: item.level_id })} data-target="#doubleClickModal" onClick={() => this.handleManualExpandCollapse(item.level_id - 1, this.state.flattenTreeData,true)} onDoubleClick={() => this.onHeadingDoubleClick(item.level_id, item.category_name)}>{item.category_name}</Link>
                    </li>)
                );
            }
        }
        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id={!isView ? "page-content-wrapper" : ""} className='withSearchBar'>
                    <div className="orgznManagementWrapper">
                        {!isView ? <div className="assignOrgHeader">
                            <ul>
                                <li>
                                    <Link to={{
                                        pathname: pathVal,
                                        state: {
                                            userCheckedData: this.treeData.activeNode
                                        }
                                    }} title={formatMessage({ id: "KC0989" })} className="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id={typeVal.type === 'orgList' ? 'KC2308' : 'KC0711'} />
                                </li>
                                <li >
                                    <Link id="#btnfinish" to="#" className={_.isEmpty(selectedNodeList) ? "btn finishBtn activeState disabled" : "btn finishBtn activeState"} title={formatMessage({ id: "KC0056" })} onClick={(e) => this.onClickFinish(e)}><FormattedMessage id='KC0056' /></Link>
                                </li>
                            </ul>
                        </div> : null}
                        {!isView ? <div className="selectedUnitHeader">
                            <ul>
                                <li>
                                    <span>{Object.keys(this.treeData.activeNode).length}  {typeVal.type === "unitList" ? `Units selected` : `Nodes selected`}</span>
                                    {/* {
                                        selectedGroupName && selectedGroupName.length ? <span className="selectedVal">
                                            {selectedGroupName.join(' , ')} &nbsp;
                                        </span> : null
                                    } */}
                                </li>
                                {typeVal.type === "unitList" ?
                                    <li>
                                        <Link to="#" className="assignunitSite active">&nbsp;</Link>
                                        <Link to={{ pathname: "/smartTag/smartTagUnitList", userCheckedData: this.treeData.activeNode, isUnitItemClicked: this.state.isUnitItemClicked }} id="btnAssign" className="assignunitList" onClick={this.onClickListView}>&nbsp;</Link>
                                    </li> : ""}
                            </ul>
                        </div> : null}
                        <div className="collapseExpandHeader flatten">
                            <ul>
                                {levelHeader}
                            </ul>
                        </div>

                        <div className="assignOrgTreeWrapper">
                            <div className="orgznSiteTree">
                                <div className="treeViewBody">
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                ref={this.treeData.virtualScrollRef}
                                                width={width || 100}
                                                height={height || 100}
                                                overscanRowCount={this.treeData.virtualScrollBufferRowCount}
                                                rowCount={flattenTreeData.length}
                                                rowHeight={this.getTreeViewRowHeight}
                                                rowRenderer={this.treeViewRowRenderer}
                                            />
                                        )}
                                    </AutoSizer>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {this.state.showStPopUp ? <OrgTagsDetail closeSTModel = {()=>this.setState({showStPopUp:false})} nodeDet = {this.state.link} unitDet = {this.state.unit} isUnit = {this.state.isUnitItemClicked} formBreadCrum={this.formBreadCrum}/>: ""}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} newMessageFormat={true} />
                {!isView && <UserManagementHeader headerName="Smart Tag Management" headerNameStringID="KC2318" activeClass="smartTagNav" />}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        OrganizationCategoryDetailsInfo: state.OrganizationCategoryDetailsInformation.OrganizationCategoryDetailsInformation,
        OrganizationLocationTypesInfo: state.OrganizationLocationTypesInformation.OrganizationLocationTypesInformation,
        OrganizationSubElementCountInfo: state.OrganizationSubElementCountInformation.OrganizationSubElementCountInformation,
        OrganizationUnitsDetailsInfo: state.OrganizationUnitsDetailsInformation.OrganizationUnitsDetailsInformation,
        deleteElementInfo: state.deleteElementInformation.deleteElementInformation,
        filterlistInformtion: state.filterModel,
        searchData: state.advanceSearch.searchData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getOrganizationCategoryDetails, getOrganizationLocationTypes,
        getOrganizationSubElementCount, getOrganizationUnitsDetails, resetOrganizationSubElementCount, deleteOrganizationElement, searchVisible, searchText
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(smartTagTopology));