import { GET_CURRENT_LOCATION,
         SET_CURRENT_LOCATION,
         GET_CURRENT_UNIT,
         SET_CURRENT_UNIT } from './type';
import { SetLocationImage } from '../../utils/locationResponseBuilder'

import KCStore from '../../utils/kcStorage';

export const getCurrentLocation = (entityName) =>dispatch =>{
    let lastStoredItem = KCStore.getItem(entityName);
    lastStoredItem = lastStoredItem && JSON.parse(lastStoredItem);
      dispatch({
          type : GET_CURRENT_LOCATION,
          payload : lastStoredItem
      });
  }

export const setCurrentLocation = (data) =>dispatch =>{
    KCStore.setItem("currentLocation", JSON.stringify(data));
    dispatch({
        type : SET_CURRENT_LOCATION,
        payload : data
    });
}

export const getCurrentUnit= (entityName) =>dispatch =>{
    let lastStoredItem = KCStore.getItem(entityName);
    lastStoredItem = lastStoredItem && JSON.parse(lastStoredItem);
      dispatch({
          type : GET_CURRENT_UNIT,
          payload : lastStoredItem
      });
  }

  
export const setCurrentUnit = (data) =>dispatch =>{
    if(data!=null){
        let dynastyAttributes = data.dynastyAttributes ? JSON.parse(data.dynastyAttributes) :{};
        // data.dynastyAttributes = JSON.stringify(dynastyAttributes);
        if(dynastyAttributes != null){
           dynastyAttributes = SetLocationImage(dynastyAttributes);
           KCStore.setItem("currentLocation", JSON.stringify(dynastyAttributes));
        }
    }
    KCStore.setItem("currentUnit", JSON.stringify(data));
    dispatch({
        type : SET_CURRENT_UNIT,
        payload : data
    });
}