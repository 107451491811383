import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { bindActionCreators , compose} from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import DragAndDrop from './DragAndDrop';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { addMenuLibrary, setAddMenuLibrary } from '../../../actions/menuManagement/addNewMenuLibrary';
import { injectIntl,FormattedMessage } from 'react-intl';

var todaysDate = new Date();

class addNewMenuLibrary extends Component {

	constructor(props) {
		super(props);
		this.state = {			
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
				selectedFile: {
					files: [],
						type:null,
						fileName: null ,
						fileFormat: null,
						fileSize: null
			},
			menuName:null,
			versionNo: null,
			applicableModels:null,
			description:null,
			releaseLog: null,
			filetype:"",
		};
		this.UploadmenuFile = this.UploadmenuFile.bind(this);
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onFinishMenuLibrary = this.onFinishMenuLibrary.bind(this);
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		$('div').removeClass("modal-backdrop fade show");
		$('.menuBrowse').click(function () { $('#menuUpdate').trigger('click'); });
		let Max_date = new Date(todaysDate);
		Max_date.setMonth(Max_date.getMonth() + 6)
		this.setState({ maxdate: Max_date });
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		console.log(this.props.history);
		this.props.actions.setAddMenuLibrary(null);
		this.props.history.push('/menuLibrary');

		this.setState({
			success_modal: {
				open: false
			}
		});
	}

	onFinishMenuLibrary(){
		let { selectedFile,  menuName, versionNo, applicableModels, description, releaseLog,filetype} = this.state;
		if( selectedFile &&  selectedFile.files && selectedFile.files.length === 0) {
			this.setState({error_modal :{ message: 'KC1988', open : true} });
		}
		else if ( !menuName) {
			this.setState({error_modal :{ message: 'KC1989', open : true} });

		}
		else if ( !versionNo) {
			this.setState({error_modal :{ message: 'KC1990', open : true} });

		}
		else if ( !applicableModels) {
			this.setState({error_modal :{ message: 'KC1991', open : true} });

		}
		else if ( !description) {
			this.setState({error_modal :{ message: 'KC1992', open : true} });

		}
		else if ( !releaseLog) {
			this.setState({error_modal :{ message: 'KC1993', open : true} })

		}
		else {
			if (filetype === "") {
				if (selectedFile.fileFormat.toLowerCase() === "zip"){
					filetype= "autorunMPDSET";

				} else if (selectedFile.fileFormat.toLowerCase() === "eva"){
					filetype= "evaConfig";
				}
			}
			let req = { ...selectedFile,menuName, versionNo, applicableModels, description, releaseLog,filetype };
			
			this.props.actions.addMenuLibrary(req);

		}
		
    }

	handleDrop = async(files) => {
		this.UploadmenuFile(files);
	}

	UploadmenuFile = (e) => {
		let files;
		files = e && e.target && e.target.files;
		if(files === undefined && e.length > 0) {
			files = e
		}
		
		let selFiles =[];
		
		if( files && files.length > 0 ) {
			selFiles.push(e.target ? e.target.files[0] : files[0])
			let file =  e.target ? e.target.files[0] : files[0];
			let fileName = e.target ? e.target.files[0] && e.target.files[0].name : files[0] && files[0].name;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_').replace(/ /g,'');

			let arr = fileName.split('.');
			let fileExt = arr && arr.pop();
			let menuId = `${arr.join('')}_${+new Date()}`;
			
			if( fileExt === 'zip' ) {
				// set file obj on state
				this.setState({
					selectedFile: { 
						menuId,
						files: selFiles,
						type: arr[0] ? arr[0] : "",
						fileName: fileName ,
						fileFormat: fileExt,
						fileSize: (file.size / (1048576)).toFixed(2)
					}
				});
				
			} else {
				this.setState({
					error_modal: { 
						message: 'KC1994', open : true
					}
				});
			}
		}
		// clear input
		$("#menuUpdate").val('');		
	}

	handleChange( e, key) {
		let value = e && e.target && e.target.value;
		let obj = {};
		obj[key] = value;
		this.setState({ ...obj});	
	}

	selectFileType = (e) => {
		if (e.target.value) {
			this.setState({
				filetype: e.target.value
			});
		}
	}

	static getDerivedStateFromProps= (props) => ({...props});

	render() {
		const { loaderIconVisible, errormsg, isAdded, selectedFile } = this.state;
		let { success_modal, error_modal } = this.state;
		const { formatMessage } = this.props.intl;
		let fileSize;
		if(selectedFile && selectedFile.fileSize ){
			fileSize = selectedFile.fileSize + " " + formatMessage ({ id: "KC2113"});
		}
		if( isAdded) {
			success_modal={open: true, message: isAdded};
		}
		if( loaderIconVisible === true  ) {
			$('.loadingProgress').show();
		} else {
			$('.loadingProgress').hide();

		}
		return (
			<div id="adminWrapper">

				<div className={"loadingProgress showloader"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">
					<div className="softwareUpdateWrapper">
						<div className="addUnitHeader">
							<ul>
								<li>
									<Link to="/menuLibrary" title={formatMessage({ id: 'KC0989' })} className="backIcon">&nbsp;</Link>
								</li>
								<li>
									<FormattedMessage id="KC1995"/>
				            	</li>
								<li>
									<button className="btn-default-text btn activeState" id="btnnext" onClick={() => this.onFinishMenuLibrary()} title={formatMessage({ id: 'KC0543' })}><FormattedMessage id="KC0056"/> </button>
								</li>
							</ul>
						</div>

						<div className="scheduleUpdateWrapper">
							<div className="scheduleUpdateWrapperL">
								<DragAndDrop handleDrop={this.handleDrop} errormsg={errormsg} >
									<span className="menuDrogDroporBrowse"><FormattedMessage id="KC2061"/></span>
									<a href="javascript:void(0);" id="btnBrowse" className="menuBrowse" title=""><FormattedMessage id="KC0664"/></a>
									<input id="menuUpdate" type="file" className="menuUpdateFile" onChange={(e) => this.UploadmenuFile(e)} />
								</DragAndDrop>
							</div>
							<div className="scheduleUpdateWrapperR"> 
				 
								<div className="addNewMenuForm">
									<ul>
										
										<li>
											<label><FormattedMessage id="KC0677"/>*</label> 
											<input readOnly ="true" type="text" className="form-control" placeholder={formatMessage({ id: 'KC0677' })} value ={ this.state.selectedFile.fileName}/>   
										</li> 
										<li>
											<label><FormattedMessage id="KC1144"/>*</label> 
											<input readOnly ="true" type="text" className="form-control" placeholder={formatMessage({ id: 'KC1144' })} value ={ fileSize } />   
										</li>
										<li>
											<label><FormattedMessage id="KC1143"/>*</label> 
											<div class="input-group">
												<select class="form-control" id="selectBrandId" onChange={(e)=> this.selectFileType(e)}>
													{this.state.selectedFile.fileFormat && this.state.selectedFile.fileFormat.toLowerCase() === "eva" ? 
														<option value="evaConfig">evaConfig</option>
													:<>
														<option value="autorunMPDSET">autorunMPDSET</option>
														<option value="MPDSET">MPDSET</option>
													</>}																									
												</select>
											</div>
										</li>
										<li>
											<label><FormattedMessage id="KC1142"/>*</label> 
											<input onChange ={(e) => this.handleChange(e, 'menuName')} type="text" className="form-control" placeholder={formatMessage({ id: 'KC1142' })} value ={ this.state.menuName} />   
										</li>

										<li>
											<label><FormattedMessage id="KC1141"/>*</label> 
											<input onChange ={(e) => this.handleChange(e, 'versionNo')} type="number" className="form-control" value = { this.state.versionNo} placeholder={formatMessage({ id: 'KC1141' })} />   
										</li> 
										<li>
											<label><FormattedMessage id="KC1140"/>*</label> 
											<input type="text" onChange ={(e) => this.handleChange(e, 'applicableModels')} className="form-control" placeholder={formatMessage({ id: 'KC2059' })} value = { this.state.applicableModels} /> 
										</li> 
										<li>
											<label><FormattedMessage id="KC1139"/>*</label> 
											<input type="text" onChange ={(e) => this.handleChange(e, 'releaseLog')} className="form-control" placeholder={formatMessage({ id: 'KC2060' })} value = { this.state.releaseLog} />  
										</li> 
										<li>
											<label><FormattedMessage id="KC0195"/>*</label> 
											<textarea onChange ={(e) => this.handleChange(e, 'description')} className="form-control" rows="4" cols="50"  value ={ this.state.description} placeholder={formatMessage({ id: 'KC0195' })} ></textarea> 
										</li> 
									</ul>
								</div>
				 
			  				</div>
						</div>
					</div>
				</div>
										
				{ (error_modal && error_modal.message) && <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />}
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
			</div>
		)
	}
};

const mapDispatchToProps = (dispatch)  => {
    return {
        actions: bindActionCreators({
			addMenuLibrary,
			setAddMenuLibrary
        }, dispatch)
    }
}

const mapStateToProps = state => {
	console.log(state.addMenuLibrary.isLoading, "state.addMenuLibrary.isLoading");

	return {
		isAdded: state.addMenuLibrary.addMenuLibrary,
		loaderIconVisible : state.addMenuLibrary.isLoading
	}
}

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(addNewMenuLibrary))