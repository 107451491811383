import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UnitsMapView from './unitsMapView';
import AllLocationHeaderLayout from '../layoutSection/allLocationHeaderLayout';
import appconstants from '../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import $ from 'jquery';

const UnitsMap = ({ intl, history }) => {
    useEffect(() => {
        updateDimensions();
    }, []);

    const updateDimensions = () => {
        var windowHeight = $(window).height();
        var pageTabHeader = $(".pageTabHeader").height();
        var mapWrapperHeight = ((windowHeight) - (pageTabHeader)) - 70;
        $("#mapdiv").css("height", mapWrapperHeight);
    };

    return (
        <React.Fragment>
            <div id="wrapper">
                <AllLocationHeaderLayout activeClass="MapViewA" filterKey={appconstants.filterKey} history={history} />
                <div id="page-content-wrapper">
                    <div className="pageTabHeader marginTop2px">
                        <ul>
                            <li>
                                <Link to="/locationsMap" title={intl.formatMessage({ id: 'KC0044' })} className="locationsView"><FormattedMessage id='KC0044' /></Link>
                            </li>
                            <li>
                                <Link to="/unitsMap" title={intl.formatMessage({ id: 'KC0045' })} className="unitsView active"><FormattedMessage id='KC0045' /></Link>
                            </li>
                        </ul>
                    </div>

                    <div id="googleMap" style={{ backgroundColor: '#595959' }}>
                        <div><UnitsMapView /></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default injectIntl(UnitsMap);
