import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HACCPManagementAPI } from '../../../service/api';
import http from '../../../service/httpService';
import SuccessMoal from '../../modal/success-model';
import authService from '../../../service/authService';
import ErrorMoal from '../../modal/error-model';
import $ from 'jquery';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage } from 'react-intl';
import KCStore from '../../../utils/kcStorage';
const moment = require('moment');

$(window).on('beforeunload', function () {
	if($("#RetriveDownload").not(".disabled"))	{        
        $(".loadingProgress").css("display", "none");
    }
});

class ActionsWidget extends Component {
	constructor(props) {
		super(props);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.state = {
			haccpUnitLogDetails: [],
			loaderIconVisible: false,
			haccpViewerUrl: [],
			requestLogDetails: [],
			allowDownload: false,
			allowRetrive: false,
			errorInRetrive: false,
			errMessage: "",
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: "",
				loaderIconVisible: false,
			},
		};
	}

	componentDidMount = () => {
		this.getCloudsDetailsForThink();
	}

	getCloudsDetailsForThink = async () => {		
		let unitID = KCStore.getItem("unitID");		
		const url = HACCPManagementAPI.unitLogDetails + unitID;
		await http.get(url, {
			headers: {
				'Content-Type': 'application/json'
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({					
					haccpUnitLogDetails: response.data,
				},()=>{
					const clouldUnitName = response.data.cloudUnitName ? response.data.cloudUnitName : "";
					this.getUnitLogStatusDetails();
					KCStore.setItem('cloudUnitName',clouldUnitName );
				});	
			}
		}).catch(err => {
			
		});
	}

	getUnitLogStatusDetails = async () => {
		if (window.location.pathname !== "/unitManagement"){
			return
		}
		let custGroupID = KCStore.getItem("custGroupID");
		let storageCustomerId = KCStore.getItem("customerId");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		const { haccpUnitLogDetails } = this.state;
		const url = HACCPManagementAPI.downloadFile + "?unitid=" + haccpUnitLogDetails.cloudUnitName;
		await http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'basecountry': KCStore.getItem("basecountry"),
				'brandid': KCStore.getItem("unitBrandId"),
				'customerid': storageCustomerId,
				'cgids': cgids
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({
					allowRetrive: true,
					haccpViewerUrl: response.data.devices ? response.data.devices : [],
				});

				if (response.data.devices[0].length > 0 && (response.data.devices[0].status === "completed" || response.data.devices[0].status.toLowerCase() === "succeeded")) {
					if(this.interval){
						clearInterval(this.interval);
					}
				}
			}
		}).catch(err => {			
			this.setState({ loaderIconVisible: false, errorInRetrive: false });
			//Allow retrieve ,if No device log found
			if (err.response && err.response.data.responseCode === "HACCP_LG_4004") {				
				this.setState({ loaderIconVisible: false, allowRetrive: true, errMessage: err.response.data.message });				
			}
		});
	}

	triggerTimeout=()=>{
		this.interval = setInterval(() => this.getUnitLogStatusDetails(), 30000);
	}

	toPostRequestLogDetails = async () => {
		this.setState({ loaderIconVisible: true });
		let haccpLogDetails = this.state.haccpUnitLogDetails;
		let thingList = [{
			"thingArn": haccpLogDetails.thingArn,
			"modelName": haccpLogDetails.MODEL_NAME,
			"modelNumber": haccpLogDetails.MODEL_NAME,
			"cloudUnitName": haccpLogDetails.cloudUnitName,
			"unitTimeZone": haccpLogDetails.unitTimeZone,
		}];

		let postdata = {
			thingList: thingList,
			brandid: KCStore.getItem("unitBrandId"),
		}
		let storageCustomerId = KCStore.getItem("customerId");
		const url = HACCPManagementAPI.requestLog;
		await http.post(url, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
					basecountry: KCStore.getItem("basecountry"),
					user: KCStore.getItem("user"),
					customerid: storageCustomerId,
				}
			}
		).then(response => {
			if (response && response.data) {
				this.triggerTimeout();
				this.setState({
					loaderIconVisible: false,
					requestLogDetails: response.data,

					success_modal: {
						open: true,
						message: "KC1841" 
					},
				});
				WoopraEvents(`${Constants.RETRIEVE_BACKUP_LOG}`)
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1337" 
				},
			});
		});
		await this.getUnitLogStatusDetails();
	}

	downloadLogViewer = () => {		
		this.setState({ loaderIconVisible: false });
		if (this.state.haccpViewerUrl !== '#') {
			window.location = this.state.haccpViewerUrl[0].S3Url;	
			this.setState({ loaderIconVisible: false });
			WoopraEvents(`${Constants.DOWNLOAD_BACKUP_LOG}`);			
		}			
	}

	onSuccessCloseModal() {
		this.setState({
			success_modal: {
				open: false,
				message: "KC1841" 
			},
		}, () => {
			this.getUnitLogStatusDetails();
		});
	}

	errorCloseModel() {
		this.setState({
			error_modal: {
				open: false
			}
		})
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}
	
	render() {
		let { haccpUnitLogDetails,  success_modal, loaderIconVisible, error_modal, haccpViewerUrl, } = this.state;

		let status = "No Records";
		let isvisible = false;
		let requestDate = "";
		if (haccpViewerUrl.length > 0) {
			status = haccpViewerUrl[0].status.toLowerCase();
			isvisible = haccpViewerUrl[0].IS_VISIBLE;
			if (haccpViewerUrl[0] && haccpViewerUrl[0].REQUEST_DATE){
				requestDate = moment(haccpViewerUrl[0].REQUEST_DATE).format('LL') + " - " + moment(haccpViewerUrl[0].REQUEST_DATE).format('LT');
			}
		}
		const activeValueKey  = this?.props ? this.props?.unitLocationDetails?.SubscriptionStatus !== null && this.props?.unitLocationDetails?.SubscriptionStatus !== 'null' ? this.props?.unitLocationDetails?.SubscriptionStatus === 0 ? 'INACTIVE' : this.props?.unitLocationDetails?.SubscriptionStatus === 1 ? 'ACTIVE' : this.props?.unitLocationDetails?.ActivationStatus : this.props?.unitLocationDetails?.ActivationStatus : '';

		let showRetriveLog;
		let showOpenLog;
		const brandName = KCStore.getItem("brandName"); //User Story 61552: Merrychef I - Log File retrieval		
		if (brandName === "FRYMASTER" || brandName === "CREM" || brandName === "GARLAND" || brandName === "MERRYCHEF") {
			if (haccpUnitLogDetails && haccpUnitLogDetails.CONNECT_STATE === "Offline" || haccpUnitLogDetails && haccpUnitLogDetails.thingArn === null || activeValueKey !== "ACTIVE") {								
				showRetriveLog = (
				<li>
					<button type="button" class="btn btn-default retrieveBtn disabled">
						<span class="btnIcon retrivalLogBtn"></span> 
						<span class="btnText"><FormattedMessage id="KC0246"/> <b><FormattedMessage id="KC0247"/></b></span> 
						<span class="btnProgress retrieving"></span>
					</button>
				</li>)

				showOpenLog = (
				<li>
					<button type="button" class="btn btn-default disabled">
						<span class="btnIcon DownloadRetriveLog"></span>
						<span class="btnText"><FormattedMessage id="KC0248"/><b><FormattedMessage id="KC0249"/></b></span>
						<span class="btnProgress retrieving" ></span>
					</button>
				</li>);	
				if ((status === "completed" || status.toLowerCase() === "succeeded") && isvisible === 1 ) {
					showOpenLog = (						
					<li>
						<button type="button" class="btn btn-info" onClick={(e) => { this.downloadLogViewer(e) }}>
							<span class="btnIcon DownloadRetriveLog"></span>
							<span class="btnText"><FormattedMessage id="KC0248"/><b>{requestDate}</b></span>
							<span class="btnProgress retrieving" ></span>
						</button>
					</li>)
				}				
			} else if (haccpUnitLogDetails && haccpUnitLogDetails.CONNECT_STATE === "Online" && activeValueKey === "ACTIVE") {
				showRetriveLog = (
				<li>
					<button onClick={(e) => { this.toPostRequestLogDetails(e) }} type="button" class="btn btn-default retrieveBtn">
						<span class="btnIcon retrivalLogBtn"></span>
						<span class="btnText"><FormattedMessage id="KC0246"/> <b><FormattedMessage id="KC0247"/></b></span>
						<span class="btnProgress retrieving" ></span>
					</button>
				</li>);

				showOpenLog = (
				<li>
					<button type="button" class="btn btn-default disabled">
						<span class="btnIcon DownloadRetriveLog"></span>
						<span class="btnText"><FormattedMessage id="KC0248"/><b><FormattedMessage id="KC0249"/></b></span>						
						<span class="btnProgress retrieving" ></span>
					</button>
				</li>)

				if (status === "queued" || status === "pending" || status === "in_progress") {
					showRetriveLog = (
					<li>
						<button type="button" class="btn btn-default retrieveBtn disabled">
							<span class="btnIcon retrivalLogBtn"></span> 
							<span class="btnText"><FormattedMessage id="KC0246"/> <b><FormattedMessage id="KC0247"/></b></span> 
							<span class="btnProgress retrieving" style={{ width: `${status === "queued" ? 15 : 65}%` }}></span>
						</button>
					</li>);
				} else if (status === "failed") {
					showRetriveLog = (
					<li>
						<button onClick={(e) => { this.toPostRequestLogDetails(e) }} type="button" class="btn btn-default retrieveBtn">
							<span class="btnIcon retrivalLogBtn"></span> 
							<span class="btnText"><FormattedMessage id="KC0246"/> <b><FormattedMessage id="KC0247"/></b></span> 
							<span class="btnProgress retrievFailed" style={{ width: `${100}%` }}></span>
						</button>
					</li>);
				}
				
				if ((status === "completed" || status.toLowerCase() === "succeeded") && isvisible === 1 ) {
					showOpenLog = (						
					<li>
						<button type="button" class="btn btn-info" onClick={(e) => { this.downloadLogViewer(e) }}>
							<span class="btnIcon DownloadRetriveLog"></span>
							<span class="btnText"><FormattedMessage id="KC0248"/><b>{requestDate}</b></span>
							<span class="btnProgress retrieving" ></span>
						</button>
					</li>)
				}
			}
		}
		
		let features = {};
    	features = authService.getFeatureByFeatureName("Device Log Downloads");
		let pageRender;//User Story 61552: Merrychef I - Log File retrieval
		if (features && features.featureAvailable  && (brandName === "FRYMASTER" || brandName === "CREM"|| brandName === "GARLAND" || brandName === "MERRYCHEF")) {									
			pageRender = (
				<div class="colm3D colm3D1">
					<div class="colmDi">
						<h4><FormattedMessage id="KC0245"/></h4>
						<div className="colmDiVal">
							<div className="btnListView">
								<ul>
									{showRetriveLog}
									{showOpenLog}
								</ul>
							</div>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={() => this.errorCloseModel()}/>
					<SuccessMoal open={success_modal.open} message={success_modal.message} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				</div>
			);
		} else {
			pageRender = (
				<div class="colm3D colm3D1 disabled">
					<div class="colmDi">
						<h4><FormattedMessage id="KC0245"/></h4>
					</div>
				</div>
			);
		}
		return (
			<React.Fragment>
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				{/* <!-- //Loading Progress --> */}
				{pageRender}
			</React.Fragment>
		)
	}
};
export default ActionsWidget;
