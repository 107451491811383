import React, { Component } from 'react';
import { DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';
import $ from 'jquery';

class GoCardless extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goCardlessForm = React.createRef();
    this.state = {
      token: '',
    };
  }

  async componentDidMount() {
    this.chargifyForm = new window.Chargify();
    await this.getChargifyApiKey();    
  }

  getChargifyApiKey = () => {
    return new Promise((res, rej) => {
      let url = DeviceRegistration.getChargifyApiKey;

      http.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {}
      }).then(async response => {
        if (response.data && response.data.length) {
          await this.loadKCUI(response.data[0]);
          res(true);
        }
      }).catch(err => {
        res(false);
      });
    })
  }

  loadKCUI = async (response) => {
    await this.chargifyForm.load({
      selector: '#chargify-form-goCard',
      currency: 'GBP', //GBP-UK countries list //EUR-Europe countries list
      // publicKey: "chjs_5pvxjy2p7shzwvtvjppgyjd2",
      publicKey: response.public_key,
      type: 'direct_debit',
      "gatewayHandle": "gocardless_uk",      
      "current_vault":"gocardless",
      selectorForGoCardlessHeader: '#gocardless-header',
      selectorForGoCardlessFooter: '#gocardless-footer',
      // serverHost: "https://kc-qa-sandbox-active.chargify.com",
      serverHost: response.server_host,
      style: {
        input: {
          width: '100%',
          background: 'transparent',
          color: '#0D3968',
          fontSize: '18px',
          border: '0px',
          borderBottom: '1px solid #0D3968',
          borderRadius: '0px',
          padding: '5px 15px 5px 0px'
        },
        label: {
          float: 'left',
          clear: 'both',
          color: '#0D3968',
          fontSize: '18px',
          fontFamily: 'sans-serif',
          fontWeight: 'unset',
          margin: '0px 0px 5px',
          lineHeight: '22px'
        },
        message: { paddingTop: '2px', paddingBottom: '1px' }
      },
      fields: {
        firstName: {
          selector: '#chargify-go-card-1',
          label: 'Account Holder First Name *',
          placeholder: 'Account Holder First Name '
        },
        lastName: {
          selector: '#chargify-go-card-2',
          label: 'Account Holder Last Name *',
          placeholder: 'Account Holder Last Name '
        },
        country: {
          selector: '#chargify-go-card-3',
          label: 'Country *'
        },
        bankName: {
          selector: '#chargify-go-card-5',
          label: 'Bank Name *',
          placeholder: 'Bank Name '
        },
        bankIban: {
          selector: '#chargify-go-card-6',
          label: 'IBAN Number *',
          placeholder: 'GBXX - XXXX - XXXX - XXXX',
        },
        accountHolderType: {
          selector: '#chargify-go-card-4',
          label: 'Account Holder Type *'
        },
        branchCode: {
          selector: '#chargify-go-card-8'
        },
        accountNumber: {
          selector: '#chargify-go-card-9',
          label: 'Account Number *',
          placeholder: 'Account Number'
        },
        routingNumber: {
          selector: '#chargify-go-card-7',
        },
      }
    })
  }

  handleSubmit = () => {
    return new Promise(async (res, rej) => {
      this.chargifyForm.token(
        this.goCardlessForm.current,
        (token) => {
          res(this.setState({ token: token }));
        },
        (error) => {
          rej(error);
        }
      );
    })
  }

  componentWillUnmount() {
    this.chargifyForm.unload();
  }

  render() {
    return (
      <form ref={this.goCardlessForm}>
        <div id="chargify-form-goCard"></div>
        <div className="billingForm">
          <ul>
            <li className="colm50">
              <div id="chargify-go-card-1"></div>
            </li>
            <li className="colm50">
              <div id="chargify-go-card-2"></div>
            </li>
          </ul>
          <ul>
            <li className="colm50">
              <div id="chargify-go-card-3"></div>
            </li>
            <li className="colm50">
              <div id="chargify-go-card-4"></div>
            </li>
          </ul>
          <ul>
            <li className="colm50">
              <div id="chargify-go-card-5"></div>
            </li>
            <li className="colm50">
              <div id="chargify-go-card-6"></div>
              <div id="chargify-go-card-9"></div>
            </li>
          </ul>
          <ul>
            <li className="colm50" style={{display:'none'}}>
              <div id="chargify-go-card-7"></div>
            </li>
            <li className="colm50">
              <div id="chargify-go-card-8"></div>
            </li>
          </ul>
        </div>
      </form>
    )
  }
}

export default GoCardless;
