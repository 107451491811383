
import { LOG_OUT } from '../../../actions/logout/type';

export default function(state = {}, action){
    switch(action.type) {
        case LOG_OUT:
        return {
            ...state,
            resetAppLicationState: action.payload
        }

        default:
        return state;
    }
}