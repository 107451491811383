import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { FormattedMessage } from 'react-intl';
import { filterTypeOptionStringIDMap } from '../../locale/constant';
export class PanVsTimeDropDown extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.onDropDownHandler = this.onDropDownHandler.bind(this);
    }

    onDropDownHandler = (e) => {
        this.props.OnChange(e);
    }
    render() {
        let { PanType, translation = false } = this.props;
        PanType = translation ? filterTypeOptionStringIDMap[PanType] : PanType; 
        return (
            <div className="dropdown datepickerBtnGroup">
                <button className="btn btn-secondary dropdown-toggle dateRangeSelection" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 <FormattedMessage id={PanType}/>
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <Link className="dropdown-item" to="#" onClick={(e) => {e.nodeText = 'Day';this.onDropDownHandler(e)}}>{translation ? <FormattedMessage id="KC0085"/> : 'Day'}</Link>
                    <Link className="dropdown-item" to="#" onClick={(e) => {e.nodeText = 'Week';this.onDropDownHandler(e)}}>{translation ? <FormattedMessage id="KC0086"/> : 'Week'}</Link>
                </div>
            </div>
        )
    }
}
export default PanVsTimeDropDown;

