import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import TotalFiltersCompletedBypassed from './frymasterWidgets/TotalFiltersCompletedBypassed';
import { getCurrentUnit } from '../../actions/organizationentity/entityactions';
import SingleDataTrendFilterWidget from '../unitDashboard/globalWidgets/singledatatrendfilterwidget';
import FiltrationTableData from './frymasterWidgets/FiltrationTableData';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import * as _ from 'lodash';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

import KCStore from '../../utils/kcStorage';

am4core.useTheme(am4themes_animated);
var moment = require('moment');
var todaysDate = new Date();
let currentUnit;

class Filtration extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loaderIconVisible: true,
			datapickflag: false,
			isGlobalDropdownChange:false,
			globalDropdownFilter: 'Month',
			globalDropdownFilterStartDate: '',
			globalDropdownFilterEndDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d
		}
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	handleChangeGlobalStart(date) {
		const { currentUnit } = this.props;

		this.setState({
			globalDropdownFilterStartDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			if (currentUnit.BRAND_NAME === "FRYMASTER") {
				this.refs.frymasterQuickFilterCompleted.handleChangeStart(date);
				this.refs.frymasterQuickFilterBypassed.handleChangeStart(date);
				this.refs.frymasterQuickFilterCompailance.handleChangeStart(date);
				this.refs.frmasterQuickFilterFryer.handleChangeStart(date);
			}
			this.onDropDownGlobalHandler(e);
		});
	}

	handleChangeGlobalEnd(date) {
		const { currentUnit } = this.props;

		this.setState({
			globalDropdownFilterEndDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText: selectedtext };
			if (currentUnit.BRAND_NAME === "FRYMASTER") {
				this.refs.frymasterQuickFilterCompleted.handleChangeEnd(date);
				this.refs.frymasterQuickFilterBypassed.handleChangeEnd(date);
				this.refs.frymasterQuickFilterCompailance.handleChangeEnd(date);
				this.refs.frmasterQuickFilterFryer.handleChangeEnd(date);			} 
			this.onDropDownGlobalHandler(e);
		});
	}

	onDropDownGlobalHandler(e) {
		const { currentUnit } = this.props;
		const filterType = e.nodeText;

        this.setState({
            globalDropdownFilter: filterType,
            isGlobalDropdownChange:true,
			datapickflag: filterType === 'Date Range'
        }); 

		if (currentUnit.BRAND_NAME === "FRYMASTER") {

			this.refs.frymasterQuickFilterCompleted.fetchData(this.refs.frymasterQuickFilterCompleted.state.holder_id, filterType)
			this.refs.frymasterQuickFilterCompleted.setState({ filterType, datapickflag: filterType === 'Date Range' });

			this.refs.frymasterQuickFilterBypassed.fetchData(this.refs.frymasterQuickFilterBypassed.state.holder_id, filterType)
			this.refs.frymasterQuickFilterBypassed.setState({ filterType, datapickflag: filterType === 'Date Range' });

			this.refs.frymasterQuickFilterCompailance.fetchData(this.refs.frymasterQuickFilterCompailance.state.holder_id, filterType)
			this.refs.frymasterQuickFilterCompailance.setState({ filterType, datapickflag: filterType === 'Date Range' });
			
			this.refs.frmasterQuickFilterFryer.handleTotalFilterCompleted( filterType)
			this.refs.frmasterQuickFilterFryer.setState({ filterType, datapickflag: filterType === 'Date Range' });
			
			
		}

	}

	//  woopra event
	reportEvent =(e) => {
		WoopraEvents(`${Constants.REPORT_AND_ANALYTICS}-${e.trackEvent}`);
	};

	render() {
		let { loaderIconVisible, isGlobalDropdownChange ,globalDropdownFilter, globalDropdownFilterEndDate, globalDropdownFilterStartDate } = this.state;
		const { formatMessage } = this.props.intl;

		currentUnit = this.props.currentUnit;
		let cleanTabHeader = null;
		// FRYMASTER Tab header display
		if (currentUnit.BRAND_NAME === 'FRYMASTER') {
			cleanTabHeader = (<div class="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({ id: 'KC0175' })} onClick={(e)=>{e.trackEvent="Production";this.reportEvent(e)}} ><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/filtration" title={formatMessage({ id: 'KC0442' })} className="active"><FormattedMessage id="KC0442"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/oilUsage" title={formatMessage({ id: 'KC0443' })} onClick={(e)=>{e.trackEvent="Oil Usage"; this.reportEvent(e)}}><FormattedMessage id="KC0443"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/tpmValue" title={formatMessage({ id: 'KC0444' })} onClick={(e)=>{e.trackEvent="TPM Value";this.reportEvent(e)}}><FormattedMessage id="KC0444"/></Link>
					</li>
					<li class="filterDropdown">
						<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
					</li>
				</ul>
			</div>)
		}


		// Added for Frymaster McD CR
		let filtrationWidgetsName = 'QUICK FILTER';
		let filtrationWidgetNameStringID = "KC0432"; //TOTAL QUICK FILTERS COMPLETED //
		let filtrationWidgetByPassedNameStringID = "KC0458";
		const productName = _.upperCase(currentUnit.MODEL_FAMILY_NAME);
		if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
			filtrationWidgetsName = 'AUTO FILTER';
			filtrationWidgetNameStringID = "KC0490";
			filtrationWidgetByPassedNameStringID = "KC0494";
		}

		let autoQuickFilterWidgetNameStringID = filtrationWidgetsName === "QUICK FILTER" ? "KC0433" : filtrationWidgetsName === "AUTO FILTER" ? "KC0489" : `${filtrationWidgetsName} COMPLIANCE`;
		let autoQuickBypassedFilterWidgetNameStringID = filtrationWidgetsName === "QUICK FILTER" ? "KC0459" : filtrationWidgetsName === "AUTO FILTER" ? "KC0495" : `Total ${filtrationWidgetsName}s completed / Bypassed per Frypot`;
		let chartAutoQuickStringID = filtrationWidgetsName === "QUICK FILTER" ? "KC0460" : filtrationWidgetsName === "AUTO FILTER" ? "KC0496" : `Avg. ${_.lowerCase(filtrationWidgetsName)} completed per frypot`;

		return (
			<React.Fragment>
				<div id="wrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<GlobalHeaderLayout activeClass="reportNav" />
					<div id="page-content-wrapper">
						{/* Reports and Analytics Clean tab header */}
						{cleanTabHeader}
						{/* Level 1 | Widget - 1 (Frymaster | Total Filters Completed) | Widget - 2 (Frymaster | Total Quick Filters Bypassed) | Widgets - 3 (Frymaster | Filters Compliance) */}
						<div class={KCStore.getItem("brandName") === "FRYMASTER" ? "gridViewTable gridViewcolm4DSM" : "displaynone"}>
							<div class="gridView">
								<SingleDataTrendFilterWidget ref="frymasterQuickFilterCompleted" properties={this.props} widgetID="54" widgetName={`TOTAL ${filtrationWidgetsName}S COMPLETED`} widgetNameStringID={filtrationWidgetNameStringID} classValue="colm4D colm4D1" isTimeDisplay="false" />
								<SingleDataTrendFilterWidget ref="frymasterQuickFilterBypassed" properties={this.props} widgetID="64" widgetName={`TOTAL ${filtrationWidgetsName}S BYPASSED`} widgetNameStringID={filtrationWidgetByPassedNameStringID} classValue="colm4D" isTimeDisplay="false" />
								<SingleDataTrendFilterWidget ref="frymasterQuickFilterCompailance" properties={this.props} widgetID="55" widgetName={`${filtrationWidgetsName} COMPLIANCE`} widgetNameStringID={autoQuickFilterWidgetNameStringID} classValue="colm4D colm4D3" symbol="%" isClassification="true" />
							</div>
						</div>
						{/* Level 2 | Widget - 1  (Frymaster) Total Quick Filters completed / Bypassed per Frypot) */}
						<div class={KCStore.getItem("brandName") === "FRYMASTER" ? "gridViewTable" : "displaynone"}>
							<div class="gridView">
								<div class="colm12D">
									<TotalFiltersCompletedBypassed ref="frmasterQuickFilterFryer" properties={this.props} widgetID="69" widgetName={`Total ${filtrationWidgetsName}s completed / Bypassed per Frypot`} widgetNameStringID={autoQuickBypassedFilterWidgetNameStringID} chartTitle={`Avg. ${_.lowerCase(filtrationWidgetsName)} completed per frypot`} chartTitleStringID={chartAutoQuickStringID} disableDatePicker={false} displayFilter={true} />
								</div>
							</div>
						</div>
						{/* Level 3 | Widget - 1  (Frymaster) Filtration Summary Table  */}
						<FiltrationTableData currentUnit={this.props} widgetName="FILTRATION DATA" widgetNameStringID="KC0461" isGlobalDropdownChange={isGlobalDropdownChange}  handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />
					</div>
				</div>
			</React.Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		currentUnit: state.entityReducer.currentUnit
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getCurrentUnit
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Filtration));
