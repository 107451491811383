import { SET_REPORT_DELETE, SET_REPORT_DELETE_LOADING } from '../../../actions/stateActionsData/type';
const initialState = {
    reportDelete:null,
    isLoading: false
}
export default function(state = initialState, action){
    switch(action.type) {
        case SET_REPORT_DELETE:
        return {
            ...state,
            reportDelete: action.payload
        }
        case SET_REPORT_DELETE_LOADING: 
        return {
            ...state,
            isLoading: action.payload
        }
        default:
        return state;
    }
}

