import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import Bootstrap from 'bootstrap';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Link } from "react-router-dom";
import {getTotalErrorsCount} from '../../actions/serviceManagement/errorCountActions';


am4core.useTheme(am4themes_animated);


class TimeLine extends Component {

	componentDidMount() {
      this.props.getTotalErrorsCount(); 
    }
	  

    render() {
		const { ErrorsCountInformation } = this.props.errorsCountInfo;

		
		var errorWidgetCritical;
		var errorWidgetNonCritical;
		if (ErrorsCountInformation.total_errors !== 0) {
			errorWidgetCritical = <div class="statusH4 poorIcon"><span>{ErrorsCountInformation.critical_errors}</span> Errors</div>;
			errorWidgetNonCritical = <div class="statusH4 goodIcon"><span>{ErrorsCountInformation.non_critical_errors}</span> Non-Critical Errors</div>;
		} 
		else {
			errorWidgetCritical = <div class="valH1WStatusDiv noErrorStatus">
			No Errors
			<span class="valH1WStatusSpan">
				&nbsp;
			</span>
		</div>;
			errorWidgetNonCritical = <div></div>; 
			}
		

		return(
            <div>
                <div id="wrapper">
				<nav class="navbar navbar-expand-lg navbar-dark bg-Welbilt">  
				<div class="navbar-header"> 
						<Link class="navbar-brand welbiltRLogo" title="Welbilt - Bringing innovation to the table" to="/mainMenu">&nbsp;</Link>
						<Link className="navbar-brand sideNavBar" to="#">&nbsp;</Link>
					</div>
				<ul class="navbar-nav mr-auto navbarLeft">
				  <li class="nav-item">
					<Link class="nav-link" to="#" title="Welbilt Kitchen">
						<span class="navStoreIcon"><img src={require('../../images/store/store01.svg').default} alt="Welbilt Kitchen" title="Welbilt Kitchen"/></span>
						<span class="navStoreText">Welbilt Kitchen <b>Munich City</b></span>
					</Link>
				  </li>
				  <li class="nav-item">
					<a class="nav-link" href="#" title="Carrara Espresso">
						<span class="navStoreIcon"><img src={require('../../images/unit/unit01.png')} alt="Carrara Espresso" title="Carrara Espresso"/></span> 
						<span class="navStoreText">Carrara Espresso <b>Crem</b></span>
					</a>
				  </li>  
				</ul> 

				<ul class="navbar-nav navbarLRight">
                    <li class="nav-item">
                        <a class="nav-link" href="#" title="Serial Number">
                            <span class="deviceSNText">S/N <b>530.115.987</b></span>
                        </a>
                    </li>
        </ul>

				<ul class="navbar-nav mr-auto navbarRight">
				  <li class="nav-item">
					<a class="nav-link " title="Sorting" href="#">&nbsp;</a>
				  </li> 
				  <li class="nav-item">
					<a class="nav-link navFilter" title="Filter" href="#">&nbsp;</a>
				  </li> 
				  <li class="nav-item">
					<a class="nav-link navSearch" title="Search" href="#">&nbsp;</a>
				  </li> 
				  <li class="nav-item">
					<a class="nav-link navAlarm" title="Notification" href="#">&nbsp;</a>
				  </li> 
				  <li class="nav-item">
					<a class="nav-link navUser" title="User Name" href="#">&nbsp;</a>
				  </li> 
				</ul>  
		</nav>
		
		
		<div id="sidebar-wrapper">
					<ul className="sidebarNav">
						<li className="brandName">
							<Link to="/dashBoardLocations" className="homeNav" title="Welbilt - Kitchen Connect">&nbsp;</Link>
						</li>
						<li>
							<Link to="/unitDashboard" className="dashboardNav" title="Unit Dashboard">&nbsp;</Link>
						</li>
						<li>
							<Link to="/reportManagement" className="reportNav " title="Report Management">&nbsp;</Link>
						</li>
						<li>
							<Link to="/qualityManagement" className="qualityNav" title="Quality Management">&nbsp;</Link>
						</li>
						<li>
							<Link to="/serviceManagement" className="serviceNav active" title="Service Management">&nbsp;</Link>
						</li>
						<li>
							<Link to="/recipesLibrary" className="recipeNav" title="Menu Management">&nbsp;</Link>
						</li>
						<li>
							<Link to="/unitManagement" className="unitNav" title="Unit Management">&nbsp;</Link>
						</li>
					</ul>
            <ul class="sidebarNav bottomNav"> 
                <li>
                    <Link to="#" class="uploadNav">&nbsp;</Link>
                </li>
                <li>
                    <Link to="#" class="menuNav">&nbsp;</Link>
                </li> 
            </ul>
        </div>
			

                <div id="page-content-wrapper">
				
                <div className="pageHeader">
                    <ul>
                        <li>
                            <Link to="/serviceManagement/currentErrors" title="Current Errors" className="active" >Current Errors</Link>
                        </li>
                        <li>
                            <Link to=/*"/serviceManagement/historicErrors"*/"#" title="Historic Errors" >Historic Errors</Link>
                        </li>
                        
                    </ul>
                </div>

				<div class="gridViewTable">   	  
			<div class="gridView">
				<div class="colm3D colm3D1">
					<div class="colmDi">
						<h4>Unit Errors <span class="cornerIcon"></span></h4>  
						<div class="colmDiVal">
						   	{errorWidgetCritical}
							{errorWidgetNonCritical}
							<div class="statusH5" style={{fontSize:'17px',height: '25px',marginTop: '1px', paddingLeft: '42px'}}>{/* <span class="valH1WStatusSpan"> &nbsp;</span> */}300 Historic Errors</div>
						</div>
					</div>
				</div>
				<div class="colm9D colm9D1">
					<div class="colmDi">
						<h4>Warranty Information <span class="cornerIcon"></span></h4> 
						<div class="colmDiVal">
							<div class="">
							  <div class="" style={{width:'70%'}}>&nbsp;</div>
							</div>
							<div class="progressBarBText">&nbsp;</div>
							<div class="remainingFooter">
								<div class="remainingFooterL"><span>&nbsp; </span> </div>
								<div class="remainingFooterR"><button type="button" class="btn btn-default"></button> </div>
							</div>
						</div>
					</div>
				</div> 
			</div>
			</div>                     
                        <div class="pageTabHeader">
                            <ul>
                                <li>
                                    <Link to="/serviceManagement" title="List" class="listView ">List</Link>
                                </li>
                                <li>
                                    <Link to="/serviceManagement/timeLine" title="Timeline" class="timelineView active">Timeline</Link>
                                </li> 
                            </ul>
                        </div>
                    </div>
                </div>
             </div>
             )}
        }
        
            

        function mapStateToProps(state){
            return{
                errorsCountInfo: state.ErrorsCountInformation,
                errorListInfo: state.errorList
            }
        }
        
        function mapDispatchToProps(dispatch){
            return bindActionCreators({getTotalErrorsCount},dispatch);
        }
        export default connect(mapStateToProps,mapDispatchToProps)(TimeLine);