import $ from 'jquery';
import _ from 'lodash';
import React, { Component } from 'react';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import http from '../../service/httpService';
import { BaseService, DeviceRegistration, UserProfile, UserMgmtAddUser,UnitInfo } from '../../service/api';
import csvExUnitRedirect from '../../utils/csvExUnitRedirect';

import { setJwt, getJwt, setLocalStorage } from '../../service/authService';
import authConstants from '../../utils/authConstants';
import authHelper from '../../utils/authHelper';
import { setcustomerId, setbasecountryId, setaccessCountryId, setBrandId } from '../../utils/appConstants';
import { readArchivesFromCompressedResponse } from '../../utils/helpers';
import KCStore from '../../utils/kcStorage';

export default class ListEaasUnits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: "",
            },
            unitDetails: {},
            consumerData: {},
            customerDetails: {},
        };
    }

    async componentDidMount() {
        $('body').addClass("deviceRegtrnBg");
        const consumerData = await (KCStore.getItem("consumerData") ? JSON.parse(KCStore.getItem("consumerData")) : {});
        if (!consumerData) {
            this.setState({
                loaderIconVisible: false
            })
            return;
        }
        this.setState({
            consumerData,
            unitDetails: consumerData.unitsList,
            loaderIconVisible: false
        })
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            },
        });
        KCStore.removeItem('consumerData');
        this.props.history.push('/')
    }

    onSuccessCloseModal = async (e) => {
        this.setState({
            success_modal: {
                open: false,
            },
        })
        KCStore.removeItem('consumerData');
        await this.loginAction()
    }

    getChildUserEmailList = async (customerId, loggedinUser) => {
        const urlVal = UserMgmtAddUser.addUser + "?customerId=" + customerId + "&email=" + loggedinUser;
        await http({
            method: "GET",
            url: urlVal,
            headers: {
                "cgid": '',
                'Content-Type': 'application/json',
            }, data: {},
        }).then(response => {
            let email = [];
            if (response.data && response.data.data && response.data.data !== "No Records Found") {
                for (let i = 0; i < response.data.data.length; i++) {
                    const ele = response.data.data[i];
                    email.push(ele.email);
                }
            }
            KCStore.setItem('childUsersEmail', JSON.stringify(email));
        }).catch(err => {
            KCStore.setItem('childUsersEmail', JSON.stringify([]));
        });
    }

    getUserRole = async (customerId, userName) => {
        http.get(UserProfile.getUserProfileDetails,
            {
                headers: {
                    'customer_id': customerId,
                    'email': userName,
                    'Content-Type': 'application/json'
                }, data: {},
            }).then(response => {
                const { loginInfo } = authConstants;

                let encode_postData = {
                    username: userName,
                    password: ''
                }
                KCStore.setItem(loginInfo, JSON.stringify(encode_postData));
                KCStore.setItem("roleName", response.data.role_name);
                KCStore.setItem("customerName", response.data.customer_name);
            }).catch(err => {
            });
    }

    getCustomerID = (customerID, emailID, baseCountry) => {
        let custGroupIDList = "";
        var setcustGroupIDList = "";
        http({
            method: "GET",
            url: `${UserProfile.get_Cust_GroupID}`,
            headers: {
                'customer_id': customerID,
                'email': emailID,
                'Content-Type': 'application/json',
                'base_country': baseCountry,
                'emulation': false
            }, data: {},
        }).then(response => {
            custGroupIDList = response.data.data;
            custGroupIDList.forEach(p => {
                setcustGroupIDList ? setcustGroupIDList = setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList = p.customer_group_id
            });
            KCStore.setItem("custGroupID", setcustGroupIDList);
            this.getUserStartLevelID(setcustGroupIDList, emailID);
            let accessFeatures = JSON.parse(KCStore.getItem("allFeatures"));
            if (accessFeatures[0].feature_category == "Main Menu Apps") {
                this.props.history.push(`/mainmenu`);
            }
            else {
                /** starts:pwp for exporbar csv redirect*/
                const unitBrandId = KCStore.getItem("csvUnitBrandId");
                const unitId = KCStore.getItem("csvUnitID");
                if (unitId !== null && unitBrandId !== null) {
                    csvExUnitRedirect(unitId, unitBrandId, this.props.history.push)
                }
                else {
                    this.props.history.push('/dashboardLocations');
                }
                /**ends:pwp */
            }
        }).catch(err => {

        });
    }

    getUserStartLevelID = async (values, username) => {
        await http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + KCStore.getItem("customerId")}`,
            headers: {
                "Cgids": values,
                'Content-Type': 'application/json',
                'useremail': username
            }, data: {},
        }).then(async response => {
            // if (response.data.fetchFromS3) {
            //     await $.getJSON(response.data.presignedS3URL, (data) => {
            //         KCStore.setItem("custLevelID", data.start_level_name);
            //         KCStore.setItem("startLevelID", data.start_level_id);
            //     })
            // }
            // else {
            //     KCStore.setItem("custLevelID", response.data.start_level_name);
            //     KCStore.setItem("startLevelID", response.data.start_level_id);
            // }
            let data = await readArchivesFromCompressedResponse(response.data);
            KCStore.setItem("custLevelID", data.start_level_name);
            KCStore.setItem("startLevelID", data.start_level_id);
        }).catch(err => {
        });
    }

    loginAction = async () => {
        const { userBrands, rememberMe, allFeaturesKey, currentFeaturesKey } = authConstants;
        const actionResponse = await JSON.parse(KCStore.getItem("loginConsumserResponse"));
        const { loginData: responseData, username } = actionResponse;

        setJwt(responseData.accessToken);
        setLocalStorage(responseData, true);

        let rolefeatures = responseData.features;
        KCStore.setItem(allFeaturesKey, JSON.stringify(rolefeatures));

        // Store BrandIds of the User
        KCStore.setItem(userBrands, JSON.stringify(responseData.brandIds));
        setBrandId(responseData.brandIds ? responseData.brandIds.join(',') : "");
        http.setJwt(getJwt());

        // Set Features
        let currentFeatures = authHelper.applyPermissions(rolefeatures);
        KCStore.setItem(currentFeaturesKey, JSON.stringify(currentFeatures));

        // rememberMe set to false by default for emulation role
        KCStore.setItem(rememberMe, false);

        KCStore.setItem("customerId", responseData.customerId);
        KCStore.setItem("basecountry", responseData.basecountry);
        KCStore.setItem("accesscountry", responseData.accesscountry);
        KCStore.setItem("roleId", responseData.role);
        KCStore.setItem("timeFormat", responseData.userTimeNumberPreferences.length > 0 && responseData.userTimeNumberPreferences[0].preference_value ? parseInt(responseData.userTimeNumberPreferences[0].preference_value) : parseInt("12"));
        KCStore.setItem("thousandSeparator", responseData.userTimeNumberPreferences.length > 1 && responseData.userTimeNumberPreferences[1].preference_value ? responseData.userTimeNumberPreferences[1].preference_value : ",");
        setcustomerId(responseData.customerId ? responseData.customerId : 1);
        setbasecountryId(responseData.basecountry ? responseData.basecountry : "");
        setaccessCountryId(responseData.accesscountry ? responseData.accesscountry : "");
        KCStore.setItem("user", username);
        this.getCustomerID(responseData.customerId, username, responseData.basecountry);
        await this.getChildUserEmailList(responseData.customerId, username);
        await this.getUserRole(responseData.customerId, username);
        await this.getUnitImage(responseData.customerId);
    }

    getUnitImage = async(customerId) => {
        const urlVal = UnitInfo.unitImage;
        await http({
            method: "GET",
            url: urlVal,
            headers: {
                "brandid" : KCStore.getItem('brandId') ? JSON.parse(KCStore.getItem('brandId')).join(',') : '',
                "cgid": KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : '',
                "cid":customerId,
                'Content-Type': 'application/json',
            }, data: {},
        }).then(response => {
            if(response && response.data ){						
                KCStore.setItem("modelImage", JSON.stringify(response.data.units));
            }
        }).catch(err => {
            KCStore.setItem('modelImage',JSON.stringify([]));
        });
    }

    groupBy = (objectArray, property) => {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            // Add object to list for given key's value
            if (key) acc[key].push(obj);
            return acc;
        }, {});
    }

    formatSerialNumber = (element) => {
        let serialNumbers = '';
        for (let index = 0; index < element.length; index++) {
            const elementValue = element[index];
            serialNumbers = serialNumbers === '' ? elementValue.UNITID : serialNumbers + ',' + elementValue.UNITID;
        }
        return serialNumbers;
    }

    calculateAllocation = async () => {
        const { unitDetails } = this.state;
        const data = await this.groupBy(unitDetails, 'componentId');
        const ObjectKeys = Object.keys(data);

        let allocation = [];
        for (let index = 0; index < ObjectKeys.length; index++) {
            const element = ObjectKeys[index];
            if (element && element !== undefined && element !== "undefined") {
                const serialNumber = await this.formatSerialNumber(data[element]);
                const objValue = {
                    "component_id": element,
                    "quantity": data[element].length,
                    "memo": serialNumber.substring(0, 250) + "..." ,
                    "accrue_charge": false,
                    "upgrade_charge": "prorated"
                }
                allocation.push(objValue)
            }
        }
        return allocation;
    }

    updateAllocationApi = () => {
        return new Promise(async (res, rej) => {
            const { consumerData } = this.state;
            const allocation = await this.calculateAllocation();
            await http({
                method: "POST",
                url: `${BaseService.root}${DeviceRegistration.updateEaasAllocation}`,
                data: {
                    "subscriptionId": consumerData.chargify_subscription_id || '',
                    "chargifyAllocation": {
                        "allocations": allocation
                    },
                    "last_login": consumerData.last_login || '',
                    "allocationType": "UPGRADE",
                    "subscriptionType": "EAAS",
                    "customerId": consumerData.customer_id,
                    "unitsList": consumerData.unitsList,
                    "consumerId": consumerData.consumer_id
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                res(response);
            }).catch(err => {
                res(err)
            });
        })
    }

    updateLastLogin = () => {
        return new Promise(async (res, rej) => {
            const { consumerData } = this.state;
            await http({
                method: "PUT",
                url: `${BaseService.root}${DeviceRegistration.updateEaasLastLogin}`,
                data: {
                    "username": consumerData.email || '',
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                res(response);
            }).catch(err => {
                res(err)
            });
        })
    }

    acceptButton = () => {
        const { consumerData } = this.state;
        this.setState({ loaderIconVisible: true }, async () => {
            if (!consumerData.last_login || consumerData.last_login === "") {
                await this.updateAllocationApi();
            }
            await this.updateLastLogin();
            await this.loginAction();
            KCStore.removeItem('consumerData');
            KCStore.removeItem('loginConsumserResponse');
        })
    }


    render() {
        const { loaderIconVisible, error_modal, success_modal, unitDetails, consumerData } = this.state;

        return (
            <div id="adminWrapper">
                <div className="toggleTransparent"></div>
                <div className="sideNavToggleTransparent"></div>
                <div className="userToggleTransparent"></div>
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <a className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" href="/">&nbsp;</a>
                </nav>

                <div id="page-wrapper">
                    {/* <!-- Register Your Device Form --> */}
                    <div className="eaasWrapper">

                        <div className="thankYouEAAS">
                            <div className="thankYouInEAAS">
                                <div className="thankYouEAASCnt">
                                    <h5>Dear {consumerData && consumerData.userDetails && consumerData.userDetails.name ? consumerData.userDetails.name : ''}</h5>
                                    <p>The following Units have been added to your account since your last Session:</p>
                                </div>

                                <div className="registerUnitLtable">
                                    <ul className="tHead">
                                        <li>#</li>
                                        <li>Serial Number</li>
                                        <li>Brand</li>
                                        <li>Model</li>
                                    </ul>
                                </div>
                                <div className="registerULtableSrlBar">
                                    <div className="registerUnitLtable">
                                        {unitDetails && unitDetails.length ? unitDetails.map((unit, index) => {
                                            return (
                                                <ul key={index} className="tBody">
                                                    <li>{index + 1}</li>
                                                    <li>{unit.UNITID}</li>
                                                    <li>{unit.BRAND_NAME}</li>
                                                    <li>{unit.MODEL_FAMILY_NAME}</li>
                                                </ul>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                                <div className="termsAndConditions">
                                    <div className="emailSend">If you need support please send an email to  <a href="mailto:EaaSMgmt@welbilt.com​">EaaSMgmt@welbilt.com​</a></div>
                                </div>
                            </div>
                            <button type="button" onClick={() => this.acceptButton()} className="btn btn-default signUpBtn">Accept</button>
                        </div>
                    </div>
                    {/* <!-- //Register Your Device Form -->  */}
                </div >
                <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </div >
        )
    }
};
