import axios from 'axios';
import api from '../../service/api';
export const UNIT_DASHBOARD_REQUEST = 'UNIT_DASHBOARD_REQUEST';
export const UNIT_DASHBOARD_SUCCESS = 'UNIT_DASHBOARD_SUCCESS';
export const UNIT_DASHBOARD_FAIL = 'UNIT_DASHBOARD_FAIL';

export function getUnitDashBoard(req) {
    return dispatch => {
        dispatch({
            type: UNIT_DASHBOARD_REQUEST
        })

        axios
            .all([getunitdashboardData(),getunitdashboardproductcount()])
            .then(axios.spread(function (unitInfo, unitproduct) {
                     dispatch({
                     type: UNIT_DASHBOARD_SUCCESS,
                     unitdashboardInformation: unitInfo.data,
                     unitdashboardProductInformation: unitproduct.data[0]

                   })                   
              }))

            .catch(error => {
                console.log(error,'error');
                dispatch({
                    type: UNIT_DASHBOARD_FAIL,
                    error
                })
            })
    }
}

function getunitdashboardData() {
    return axios.get(api.serviceMgmtErrorCount);
  }
  function getunitdashboardproductcount() {
    return axios.get(api.reportMgmtTotalDrinksService);
  }
  