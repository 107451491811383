import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { MenuBrandsResponse } from '../../../utils/unitResponseBuilder';
import { getMediaLibraryList } from '../../../actions/navigation/mediaManagement/mediaLibraryListActions'
import { ReportManagement,UnitInfo } from '../../../service/api';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../utils/kcStorage';

class DisplayProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			brandList: [],
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			unitCount:0,
		};
	}

	getUnitDetails = async () => {
		this.setState({ loaderIconVisible: true });
		let url = `${UnitInfo.connectivityerrorStatusURL}`;
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'cid': KCStore.getItem("customerId"),
				'cgid': KCStore.getItem("custGroupID"), 
				'brandid': 4,
				'filterparam' : 'connect',
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				let unitCounts = 0;
				if (response.data.length>1){
					unitCounts = response.data[0].connectState_count;
					unitCounts = unitCounts + response.data[1].connectState_count;
				} else if (response.data.length>0){
					unitCounts = response.data[0].connectState_count;
				}
				this.setState({
					unitCount: unitCounts,
				});
			}
		}).catch(err => {
		});
	}

	getProductList = () => {
		let brandName = {
			4: "CREM",
			2: "MERCO",
			1: "MERRYCHEF",
			6: "CONVOTHERM",
			3: "FRYMASTER",
			5: 'MANITOWOC ICE',
			7: "DELFIELD",
			8: "MULTIPLEX",
			9: "GARLAND",
			10: "LINCOLN",
		}

		let url = ReportManagement.productListConfig;
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				brandId: 4,
				cid: KCStore.getItem("customerId"),				
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				let dataList = response.data.map(item => {
					item["BRAND_NAME"] = brandName[item["brand_id"]]
					return item
				})
				this.setState({
					brandList: dataList,
					loaderIconVisible: false,
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	componentDidMount = async () => {
		$('body').removeClass("modal-backdrop show");
		document.body.style.backgroundColor = "#F2F2F2";
		await this.getUnitDetails();
		this.getProductList();
	}

	onButtonClickHandler(item) {
		KCStore.setItem("brandName", item.BRAND_NAME);
		KCStore.setItem("selectedbrandID", item.brand_id);
		this.props.history.push(`/productsselection`);
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { brandList, loaderIconVisible } = this.state;
		let branList = [];
		let orderId = 1;
		let menuItemList;
		let errorMsg;
		if (Object.keys(brandList).length > 0 && brandList) {
			branList = MenuBrandsResponse(brandList);
			menuItemList = (
				branList.map((item) =>
					<ul className="tBody" key={item.ID} onClick={() => this.onButtonClickHandler(item)}>						
						{item.brand_id === "4" ? <li>{orderId++}</li> : ""}
						{item.brand_id === "4" ? <li><FormattedMessage id="KC0074"/></li> : ""}
						{item.brand_id === "4" ? <li>{<img src={item.img} alt="" />} </li> : ""}
						{item.brand_id === "4" ? <li>{this.state.unitCount}</li> : ""}
						{item.brand_id === "4" ? <li>{item.display_product_count}</li> : ""}
						{item.brand_id === "4" ? <li><button class="btn forwardNext">&nbsp;</button></li> : ""}						
					</ul> 
				)
			)
		}
		else {
			errorMsg = (
				<div class="menuBrandsTable">
				<NoRecordsFound loaderIconVisible={loaderIconVisible} length={brandList !== undefined ? brandList.length : 0} classname={'norecordsfoundbluetext'} />
				</div>)
		}
		return (
			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper"> 
						<div class="menuUpdateWrapper">
							<div class="menuUpdateHeader">
								<ul>
									<li>
										<Link to="#" title={formatMessage({ id: 'KC0771' })} className="active"><FormattedMessage id="KC0771"/></Link>
									</li>
								</ul>
							</div>

							<div className="prdtsSelcnTableDiv">
								<div className="prdtsSelcnTable">
									<ul className="tHead">
										<li>#</li>
										<li><FormattedMessage id="KC0873"/></li>
										<li><FormattedMessage id="KC2332"/></li>
										<li><FormattedMessage id="KC0045"/></li>
										<li><FormattedMessage id="KC1758"/></li>
										<li>&nbsp;</li>
									</ul>
									{menuItemList}
								</div>
							</div>
							<form className="menuBrandsTableDiv">
								<div className="menuBrandsTable">
									{errorMsg}
								</div>
							</form>
						</div> 
				</div>
				<UserManagementHeader headerName="Settings - Product Selection" headerNameStringID="KC0770" activeClass="settingsNav" />
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		mediaLibraryListInfo: state.mediaLibraryListInformation.mediaLibraryListInformation,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaLibraryList,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DisplayProduct));