import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCurrentUnit } from '../../../actions/organizationentity/entityactions';
import { getDeviceAPIUrl, getDeviceInfo } from '../../../service/unitManagement/deviceinfo';
import { getCurrentLocation } from '../../../actions/organizationentity/entityactions'; 
import { getLocationAPIUrl, getLocationInfo } from '../../../service/unitManagement/locationinfo';
import UnitManagementInfo from './widgetconstants'; 
import { FormattedMessage } from 'react-intl';
import { mappingStringTranslationID } from '../../../locale/constant';

import KCStore from '../../../utils/kcStorage';

am4core.useTheme(am4themes_animated);

class ModelInformationWidget extends Component {

	constructor(props) {
		super(props);
		this.state = { 
			locationInformation: {},
			deviceInformation: {},
			loaderIconVisible: true
		}
	}

	componentDidMount = async () => {

		let locationUrl = getLocationAPIUrl(KCStore.getItem("unitID"), KCStore.getItem("brandName"));
		let locationInfo = await getLocationInfo(locationUrl);

		if (locationInfo) {
			this.setState({
				locationInformation: locationInfo, 
			});
		} 

		let currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		let deviceUrl = getDeviceAPIUrl(KCStore.getItem("unitID"), KCStore.getItem("brandName"));
		let deviceInfo = await getDeviceInfo(deviceUrl);

		if (deviceInfo) {
			this.setState({				 
				deviceInformation: deviceInfo
			});
		}

		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	render() {

		const { deviceInformation,locationInformation } = this.state;
		const {currentUnit} = this.props;
		const unitMngInfo = UnitManagementInfo; 
		const brandName = KCStore.getItem("brandName");				
		let modelInfo = unitMngInfo[brandName].modelInfo;
		let intuitionInfo = unitMngInfo[brandName].intuitionModelInfo;
		const modelFamily = KCStore.getItem("modelFamilyName");
		
		let signal = '1 ';
		let signalimage = (<img src={require('../../../images/icons/wifiSignal1.png')} alt='Carrara Espresso' title='' />);
		let networkType;
		let signalLevel;
		let networkProvider;
		let simCardNumber;
		let imeiNumber;
		let imeiSVNumber;
		if(brandName === "CREM" || brandName === "GARLAND")
		{
			networkType = (deviceInformation.network_type || deviceInformation.networkType) ? (deviceInformation.network_type || deviceInformation.networkType) : deviceInformation.network_type === undefined ? "Awaiting connection" : "N/A";
			signalLevel = (deviceInformation.signal_level || deviceInformation.signalLevel);
		}//User Story 55784: Intuition or 30LB fryer will send network message in below format "networkType":"Wi-Fi/Ethernet/4G/2G/3G"
		else if(brandName === "FRYMASTER" && modelFamily === "Intuition")
		{
			networkType = deviceInformation.networkType ? deviceInformation.networkType : deviceInformation.network_type === undefined ? "Awaiting connection" : "N/A";
			if (deviceInformation.signalStrengthValue && deviceInformation.signalStrengthValue !== undefined) {
				let signalStrength = parseInt(deviceInformation.signalStrengthValue.replace("dbm","").trim());
				signalLevel = signalStrength >= -70 ? "5" 
						: signalStrength >= -85 ? "4" 
						: signalStrength >= -100 ? "3" 
						: signalStrength > -110 && signalStrength < -100 ? "1" 
						: signalStrength < -110 ? "0"
						: "0";
			}
			else {
				signalLevel = "Awaiting connection";
			}
		}
		else if(brandName === "FRYMASTER")
		{
			networkType = deviceInformation.networkType && deviceInformation.networkType === "1" ? "Wi-Fi" : deviceInformation.networkType === "2" ? "Ethernet" : deviceInformation.networkType === "3" ? "GSM" : deviceInformation.network_type === undefined ? "Awaiting connection" : "N/A";
			if (deviceInformation.signalStrengthValue && deviceInformation.signalStrengthValue !== undefined) {
				let signalStrength = parseInt(deviceInformation.signalStrengthValue.replace("dbm","").trim());
				signalLevel = signalStrength >= -70 ? "5" 
						: signalStrength >= -85 ? "4" 
						: signalStrength >= -100 ? "3" 
						: signalStrength > -110 && signalStrength < -100 ? "1" 
						: signalStrength < -110 ? "0"
						: "0";
			}
			else {
				signalLevel = "Awaiting connection";
			}
		}
		else
		{
			networkType = deviceInformation.networkType ? deviceInformation.networkType : deviceInformation.networkType === undefined ? "Awaiting connection" : "N/A";
			signalLevel = deviceInformation.signalLevel;
		}

		//networkProvider,simCardNumber,imeiNumber-these info needs to be shown into All Brands
		if ( deviceInformation.connected === false || deviceInformation.network_type === 'Wi-Fi' || deviceInformation.network_type === "Ethernet" || deviceInformation.networkType === 'Wi-Fi' || deviceInformation.networkType === "Ethernet" || deviceInformation.networkType === "1" || deviceInformation.networkType === "2") {
			networkProvider = "N/A";
			simCardNumber = "N/A";
			imeiNumber = "N/A";
			imeiSVNumber = "N/A";

		} else {
			networkProvider = deviceInformation.networkProvider ? deviceInformation.networkProvider : deviceInformation.networkProvider === undefined ? "Awaiting connection" : "N/A";
			simCardNumber = deviceInformation.simCardNumber ? deviceInformation.simCardNumber : deviceInformation.simCardNumber === undefined ? "Awaiting connection" : "N/A";
			imeiNumber = deviceInformation.imeiNumber ? deviceInformation.imeiNumber : deviceInformation.imeiNumber === undefined ? "Awaiting connection" : "N/A";
			imeiSVNumber = deviceInformation.imeiSVNumber ? deviceInformation.imeiSVNumber : deviceInformation.imeiSVNumber === undefined ? "Awaiting connection" : "N/A";
		}

		if ((networkType === "Wi-Fi" || networkType === "3G" || networkType === "4G" || networkType === "GSM" || networkType === "2G") && deviceInformation.connected === true) //Wi-Fi/Ethernet/3G/4G/2G
		{
			if (signalLevel === '1' || signalLevel === 1) {
				signal = '1 ';
				signalimage = (<img src={require('../../../images/icons/wifiSignal1.png')} alt='Carrara Espresso' title='' />);
			}
			else if (signalLevel === '2' || signalLevel === 2) {
				signal = '2 ';
				signalimage = (<img src={require('../../../images/icons/wifiSignal2.png')} alt='Carrara Espresso' title='' />);
			}
			else if (signalLevel === '3' || signalLevel === 3) {
				signal = '3 ';
				signalimage = (<img src={require('../../../images/icons/wifiSignal3.png')} alt='Carrara Espresso' title='' />);
			}
			else if (signalLevel === '4' || signalLevel === 4) {
				signal = '4 ';
				signalimage = (<img src={require('../../../images/icons/wifiSignal4.png')} alt='Carrara Espresso' title='' />);
			}
			else if (signalLevel === '5' || signalLevel === 5) {
				signal = '5 ';
				signalimage = (<img src={require('../../../images/icons/wifiSignal5.png')} alt='Carrara Espresso' title='' />);
			}
		}
		else if (networkType === "Ethernet" && deviceInformation.connected === true) {
			signal = "N/A";
			signalimage = "";
		}
		else if (deviceInformation.connected === undefined) {
			signal = "Awaiting connection";
			signalimage = "";
		}
		else {
			signal = "N/A";
			signalimage = "";
			networkType = "N/A";
		}

		if (currentUnit.MODEL_NAME !== undefined) {
			var strCamelValueForBrandType = currentUnit.MODEL_NAME
			var splitStr = strCamelValueForBrandType.toLowerCase().split(' ');
			for (var i = 0; i < splitStr.length; i++) {
				if (splitStr[i].length > 0) {
					splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
				}
			}
			strCamelValueForBrandType = splitStr.join(' ');
		}

		if (currentUnit.PRODUCT_NAME !== undefined) {
			var strCamelValueForProdName = currentUnit.PRODUCT_NAME
			var splitStr = strCamelValueForProdName.toLowerCase().split(' ');
			for (var i = 0; i < splitStr.length; i++) {
				if (splitStr[i].length > 0) {
					splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
				}
			}
			strCamelValueForProdName = splitStr.join(' ');
		}

		if (currentUnit.BRAND_NAME !== undefined) {
			var strCamelValueForBrandName = currentUnit.BRAND_NAME
			var splitStr = strCamelValueForBrandName.toLowerCase().split(' ');
			for (var i = 0; i < splitStr.length; i++) {
				if (splitStr[i].length > 0) {
					splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
				}
			}
			strCamelValueForBrandName = splitStr.join(' ');
		}	

		let assetNumber = deviceInformation.assetNumber ? deviceInformation.assetNumber : deviceInformation.assetNumber === undefined ? "Awaiting connection" : "N/A";

		let modelName = currentUnit.MODEL_NAME ? currentUnit.MODEL_NAME : currentUnit.MODEL_NAME === undefined ? "Awaiting connection" : "N/A";

		let softwareVersion = deviceInformation.softwareVersion;
		let jibEmpty = "";
		if (softwareVersion && softwareVersion.configuration && softwareVersion.configuration.jibEmpty) {
			jibEmpty = softwareVersion.configuration.jibEmpty === "0" ? "Low" : softwareVersion.configuration.jibEmpty === "1" ? "Normal" : "N/A";
		}
		else {
			jibEmpty = "Awaiting connection";
		}

		let oqsOilType = "";
		if (softwareVersion && softwareVersion.configuration && softwareVersion.configuration.oqsOilType) {
			oqsOilType = softwareVersion.configuration.oqsOilType !== "" ? softwareVersion.configuration.oqsOilType : "N/A";
		}
		else {
			oqsOilType = "Awaiting connection";
		}

		let commissioningDate = "";
		if (currentUnit.BRAND_NAME === "FRYMASTER" && softwareVersion && softwareVersion.configuration && softwareVersion.configuration.commissioningDate)	
		{
			commissioningDate = format(softwareVersion.configuration.commissioningDate, 'MM/DD/YYYY');
		}
		else {
			if (locationInformation.commissioningDate)	
			{
				commissioningDate = format(locationInformation.commissioningDate, 'MM/DD/YYYY');
			}
			else if (!locationInformation.commissioningDate && deviceInformation.connected === undefined) {
				commissioningDate = "Awaiting connection";
			}
			else {
				commissioningDate = "N/A";
			}
		}

		let macAddress = "";
		if (currentUnit.BRAND_NAME === "FRYMASTER" && deviceInformation)	
		{
			macAddress = deviceInformation.macAddress ? deviceInformation.macAddress : deviceInformation.macAddress === undefined ? "Awaiting connection" : "N/A";
		}
		else {
			macAddress = deviceInformation.MacAddress ? deviceInformation.MacAddress : deviceInformation.MacAddress === undefined ? "Awaiting connection" : "N/A";
		}
		//User Story 47079: KC Portal - Frymaster Intuition model new items
		let energyType = "";
		if (softwareVersion && softwareVersion.configuration && softwareVersion.configuration.energyType) {
			energyType = softwareVersion.configuration.energyType === "1" ? "Gas" : softwareVersion.configuration.energyType === "2" ? "Electric" : "N/A";
		}
		else {
			energyType = "Awaiting connection";
		}

		let filtrationType = "";
		if (softwareVersion && softwareVersion.configuration && softwareVersion.configuration.filtrationType) {
			filtrationType = softwareVersion.configuration.filtrationType === "1" ? "Liquid" : softwareVersion.configuration.filtrationType === "2" ? "Solid" : "N/A";
		}
		else {
			filtrationType = "Awaiting connection";
		}

		return (
			<React.Fragment>
				<div class="colm4D colm4D1">
					<div class="colmDi">
						<h4><FormattedMessage id="KC0216"/></h4>
						<div class="colmDiVal">
							<div class="gridTableView">
								<ul>
									<li>															
										<img src={currentUnit.Brand_Logo} alt={currentUnit.Brand_Alt} title={currentUnit.Brand_Title} />
									</li>
									<li>
										<ul>
											{modelInfo[0].visible ? <li>
												<label>{modelInfo[0].stringID ? <FormattedMessage id={modelInfo[0].stringID}/> : modelInfo[0].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[assetNumber] ? <FormattedMessage id={mappingStringTranslationID[assetNumber]}/> : assetNumber}</span>
											</li> : null }
											{modelInfo[1].visible ? <li>
												<label>{ modelInfo[1].stringID ? <FormattedMessage id={ modelInfo[1].stringID}/> : modelInfo[1].fieldname}</label>												
												<span class="labelVal">{currentUnit.UNITID}</span>
											</li> : null }
											{modelInfo[2].visible ? <li>
												<label>{modelInfo[2].stringID ? <FormattedMessage id={modelInfo[2].stringID}/> : modelInfo[2].fieldname}</label>
												<span class="labelVal">{mappingStringTranslationID[modelName] ? <FormattedMessage id={mappingStringTranslationID[modelName]} /> : modelName}</span>
											</li> : null }
											{modelInfo[3].visible ? <li>											
											<label>{modelInfo[3].stringID ? <FormattedMessage id={modelInfo[3].stringID}/> : modelInfo[3].fieldname}</label>
												<span class="labelVal">{mappingStringTranslationID[networkType] ? <FormattedMessage id={mappingStringTranslationID[networkType]}/> :networkType }</span>
											</li> : null }
											{modelInfo[4].visible ? <li>
												<label>{modelInfo[4].stringID ? <FormattedMessage id={modelInfo[4].stringID}/> : modelInfo[4].fieldname}</label>
												<span class="labelVal wifiSignal">{mappingStringTranslationID[signal] ? <FormattedMessage id={mappingStringTranslationID[signal]}/> :signal } {signalimage} </span>
											</li> : null}
											{modelInfo[5].visible ? <li>
												<label>{modelInfo[5].stringID ? <FormattedMessage id={modelInfo[5].stringID}/> : modelInfo[5].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[macAddress] ? <FormattedMessage id={mappingStringTranslationID[macAddress]}/> :macAddress}</span>
											</li> : null}
											{modelInfo[6].visible ? <li>
												<label>{modelInfo[6].stringID ? <FormattedMessage id={modelInfo[6].stringID}/> : modelInfo[6].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[commissioningDate] ? <FormattedMessage id={mappingStringTranslationID[commissioningDate]}/> : commissioningDate }</span>
											</li> : null}
											{modelFamily === "Intuition" && intuitionInfo[0].visible ? <li>
												<label>{intuitionInfo[0].stringID ? <FormattedMessage id={intuitionInfo[0].stringID}/> : intuitionInfo[0].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[energyType] ? <FormattedMessage id={mappingStringTranslationID[energyType]}/> :energyType }</span>
											</li> : null}
											{modelFamily === "Intuition" && intuitionInfo[1].visible ? <li>
												<label>{intuitionInfo[1].stringID ? <FormattedMessage id={intuitionInfo[1].stringID}/> : intuitionInfo[1].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[filtrationType] ? <FormattedMessage id={mappingStringTranslationID[filtrationType]} /> : filtrationType}</span>
											</li> : null}
											{modelInfo[7].visible ? <li>
												<label>{modelInfo[7].stringID ? <FormattedMessage id={modelInfo[7].stringID}/> : modelInfo[7].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[jibEmpty] ? <FormattedMessage id={mappingStringTranslationID[jibEmpty]}/> :jibEmpty }</span>
											</li> : null}
											{modelInfo[8].visible ? <li>
												<label>{modelInfo[8].stringID ? <FormattedMessage id={modelInfo[8].stringID}/> : modelInfo[8].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[oqsOilType] ? <FormattedMessage id={mappingStringTranslationID[oqsOilType]}/> :oqsOilType }</span>
											</li> : null}
											{modelInfo[9].visible ? <li>
												<label>{modelInfo[9].stringID ? <FormattedMessage id={modelInfo[9].stringID}/> : modelInfo[9].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[networkProvider] ? <FormattedMessage id={mappingStringTranslationID[networkProvider]} /> :networkProvider }</span>
											</li> : null}
											{modelInfo[10].visible ? <li>
												<label>{modelInfo[10].stringID ? <FormattedMessage id={modelInfo[10].stringID}/> : modelInfo[10].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[simCardNumber] ? <FormattedMessage id={mappingStringTranslationID[simCardNumber]}/> :simCardNumber }</span>
											</li> : null}
											{modelInfo[11].visible ? <li>
												<label>{modelInfo[11].stringID ? <FormattedMessage id={modelInfo[11].stringID}/> : modelInfo[11].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[imeiNumber] ? <FormattedMessage id={mappingStringTranslationID[imeiNumber]}/> :imeiNumber}</span>
											</li> : null}	
											{modelInfo[12].visible ? <li>
												<label>{modelInfo[12].stringID ? <FormattedMessage id={modelInfo[12].stringID}/> : modelInfo[12].fieldname}</label>												
												<span class="labelVal">{mappingStringTranslationID[imeiSVNumber] ? <FormattedMessage id={mappingStringTranslationID[imeiSVNumber]}/> : imeiSVNumber}</span>
											</li> : null}										
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)		
	}
};
function mapStateToProps(state) {
	return {
		currentUnit: state.entityReducer.currentUnit,		
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getCurrentUnit,getCurrentLocation }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ModelInformationWidget);
