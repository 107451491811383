import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getCurrentUnit } from '../../../actions/organizationentity/entityactions';
import { FormattedMessage, injectIntl } from 'react-intl';


class DeviceDetailsWidget extends Component {

	constructor(props) {
		super(props);
		this.state = { 
			loaderIconVisible: true
		}
	}

	render() {
		const { currentUnit } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<React.Fragment>
                <div className={this.props.unitDashboradFlag ? "colm8D":"colm10D"}>
					<div class="colmDi">
						<div class="titleH2Div">{currentUnit.BRAND_NAME} <span>{currentUnit.MODEL_NAME}</span></div>
						<div class="titleH4DivCamelLeter">{currentUnit.PRODUCT_TYPE}</div>
						<div class="deviceSNUTCSM">
						<div class="deviceSNText" title={formatMessage({ id: 'KC0983' }) +" " + currentUnit.UNITID}><FormattedMessage id="KC0983"/> <b>{currentUnit.UNITID}</b></div>
                      <div class="UTCText" title={formatMessage({ id: 'KC0256' })+":" +" " + currentUnit.TimeZone_Display}><FormattedMessage id="KC0256"/>: <b> {currentUnit.TimeZone_Display} </b></div>
						</div>
					</div>
				</div>
            </React.Fragment>
		)		
	}
};
function mapStateToProps(state) {
	return {
		currentUnit: state.entityReducer.currentUnit,		 
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getCurrentUnit }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DeviceDetailsWidget));
