import {CYCLE_RATES_OFF_TIME_REQUEST,CYCLE_RATES_OFF_TIME_SUCCESS,CYCLE_RATES_OFF_TIME_FAIL} from '../../../../actions/reportManagement/utilization/cycleRatesOffTimeActions';
const initialState = {
    loaded: false,
    loading: false,
    cycleRatesOffTimeResponse: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case CYCLE_RATES_OFF_TIME_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case CYCLE_RATES_OFF_TIME_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                cycleRatesOffTimeResponse: action.cycleRatesOffTimeResponse
            }
        case CYCLE_RATES_OFF_TIME_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgOffTime: 'Server Error',

            }
        default:
            return state
    }
}