import React from 'react';
import Switch from 'react-switch';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import http from '../../service/httpService.js';
import authService from '../../service/authService.js';
import { UserMgmtAddUser, BaseService } from '../../service/api.js';
import { TableHeader } from '../../components/controls/tableHeader.js';
import NoRecordsFound from '../../components/controls/noRecordsFound.js';
import { BASECOUNTRY_ID } from '../../utils/appConstants.js';
import moment from '../../utils/momentHelper.js';
import WoopraEvents from '../../woopraConfig/WoopraEvents.js';
import Constants from '../../woopraConfig/Woopraconstant.js';
import GoToPagination from '../../components/controls/goToPagination.js';
import KCButton from '../../UI_Components/Button/index.js';
import KCLink from '../../UI_Components/KCLink/index.js';
import KCImage from '../../UI_Components/Image/index.js';
import KCStore from '../../utils/kcStorage'; 

import 'bootstrap/dist/css/bootstrap.min.css';

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

const DeleteConfirmationModal = ({ deleteUser }) => {
	return (
		<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
			<div className="modal-dialog modal-md loginModal">
				<div className="modal-content" >
					<div className="modal-body">
						<div className="singOutConfirmation">
							<FormattedMessage id={'KC0614'} />
						</div>
					</div>
					<div className="modal-footer textAlignCenter">
						<KCButton className="btn btn-secondary signOutNoBtn" data-dismiss="modal"><FormattedMessage id={'KC0021'} /></KCButton>
						<KCButton className="btn btn-secondary signOutYesBtn" onClick={deleteUser} data-dismiss="modal"><FormattedMessage id={'KC0611'} /></KCButton>
					</div>
				</div>
			</div>
		</div>
	)
}

const ResetPasswordModal = ({ resetPasswordHanddler }) => {
	return (
		<div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="confirmationYesNoModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-body">
						<div className="confirmationYesNo">
							<h5><FormattedMessage id={'KC0609'} /></h5>
							<p><FormattedMessage id={'KC0610'} /></p>
						</div>
					</div>
					<div className="modal-footer textAlignCenter">
						<KCButton className="btn btn-secondary noBtn" data-dismiss="modal"><FormattedMessage id={'KC0580'} /></KCButton>
						<KCButton className="btn btn-secondary yesBtn" id="resetPasswordYes" data-dismiss="modal" onClick={(e) => resetPasswordHanddler(e)}><FormattedMessage id={'KC0611'} /></KCButton>
					</div>
				</div>
			</div>
		</div>
	)
}

const UsertListComponent = (props) => {
	const { user, loader, searchData, onSort, totalPages, pageSize, currentPage, allSelect, isIndeterminateSelect, sortColumn, onPageChange, handleChange, setLoading, getUserToState, snackBarToast } = props;
	let userProfileAuthFeature = authService.getFeature("/user/list");

	const { formatMessage } = props.intl;
	const [userEmail, setUserEmail] = React.useState('');

	let rowNumber = (currentPage * pageSize) - pageSize;

	const handleActivation = (item) => {
		setLoading(true);
		const postData = {
			"type": item.is_active === 1 ? "deactivate-user" : "activated-user",
			"user": {
				"email": item.email,
				"is_active": item.is_active === 1 ? 0 : 1,
			}
		}
		putData(postData, UserMgmtAddUser.addUser, HTTP_METHOD.POST).then(response => {
			if (response.data.responseCode === 200) {
				const message = item.is_active === 1 ? formatMessage({ id: 'KC0608' }) : formatMessage({ id: 'KC0607' });
				onPageChange(1)
				snackBarToast(message, 'success').then(() => {
					setLoading(false);
				})
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	const deleteUser = () => {
		setLoading(true);
		let allUsers = user;
		let deletedUsers = allUsers?.filter(item => item.checked === true);
		let checkEmail = deletedUsers?.map(function (item) { return item.email });
		const selectedEmail = JSON.stringify(checkEmail);
		let selfEmail = JSON.parse(selectedEmail);
		let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;

		let Loginuserinformation = [];
		selfEmail?.map(c => {
			if (c === loggedinUser) {
				Loginuserinformation.push(c)
			}
		});

		if (Loginuserinformation && Loginuserinformation.length > 0) {
			let currentuser = deletedUsers.filter(item => item.email === Loginuserinformation);
			deletedUsers = deletedUsers.filter(item => item.email !== currentuser[0].email);
			setLoading(false);
			snackBarToast(formatMessage({ id: "KC1067" }, { name: currentuser[0].name }), 'success')
			return;
		}

		if (deletedUsers && deletedUsers.length > 0 && deletedUsers != null) {
			let postData = {
				users: deletedUsers.map(item => {
					return {
						username: item.email,
						base_country: BASECOUNTRY_ID,
						"Id": item.Id,
						"createdBy": item.createdBy,
					}
				})
			};

			putData(postData, UserMgmtAddUser.deleteUser, HTTP_METHOD.DELETE).then(response => {
				if (response.data.responseCode === 200) {
					const message = formatMessage({ id: 'KC1068' });
					setLoading(false);
					onPageChange(1)
					snackBarToast(message, 'success')
				}
			}).catch(err => {
				setLoading(false);
				snackBarToast(err.message, 'error')
			});
		}
	}

	const putData = (postData, url, method) => {
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData
			}).then(res => {
				let { data } = res;
				let { responseCode } = data;
				if (responseCode !== 200) {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	const resetPasswordHanddler = async (e) => {
		setLoading(true);
		let postData = {
			"username": userEmail
		}
		try {
			const response = await http({
				method: "POST",
				url: BaseService.submit_forget_password,
				data: postData,
			})
			if (response && response.data) {
				if (response.data.responseCode === 200) {
					const message = response?.data?.stringID ? formatMessage({ id: response.data.stringID })  : formatMessage({ id: 'KC2206' });
					setLoading(false);
					snackBarToast(message, 'success')
					WoopraEvents(`${Constants.RESET_PASSWORD_REQUEST_SEND}`);
				} else {					
					const message = response?.data?.stringID ? formatMessage({ id: response.data.stringID })  :  response?.data?.message ? response.data.message : formatMessage({ id: "KC1069" });
					setLoading(false);
					snackBarToast(message, 'success')
					WoopraEvents(`${Constants.RESET_PASSWORD_REQUEST_FAILED}`)
				}
			}
		} catch (error) {
			setLoading(false);
			const errorMsg = error?.response?.data?.stringID ? formatMessage({ id: error.response.data.stringID }) :  error.response.data.message;
			snackBarToast(errorMsg, 'error')
		}
	}

	const callOrgView = () => {
		let selectedUser = user.filter(item => item.checked === true);
		if(!selectedUser?.length){
			return;
		}
		let { name, first_name, last_name, role_id, email } = selectedUser[0]
		let getUserModel = {
			user: {
				name,
				firstName: first_name,
				lastName: last_name,
				email,
				retypeEmail: email,
				country_code: "",
				phoneNumber: "",
				customerId: KCStore.getItem("customerId")
			},
			roleId: role_id,
			userGroup: [],
			userPreference: [],
			countryCodeList: [],
			previousPage: "/user/list"
		};

		getUserToState(getUserModel);
		WoopraEvents(`${Constants.USER_ASSIGN}`);
		props.history.push({
			pathname: '/assignUserToTopology',
			userModel: getUserModel,
			role_id,
			email
		})
	}


	const editUser = () => {
		let userModel = getUserToState();
		const checkedUser = user.filter(item => item.checked === true)
		if(checkedUser.length){
			let { base_country, role_id, email } = checkedUser[0] ;
			KCStore.setItem("userBaseCountry", base_country);
			WoopraEvents(`${Constants.USER_EDIT}`);
			props.history.push({
				pathname: '/user/edit',
				userModel: userModel,
				role_id,
				email
			})
		}
	}

	const isAdd = user?.filter((i) => i.checked === true)?.length === 0;
	const isEdit = user?.filter((i) => i.checked === true)?.length === 1;
	const isDelete = user?.filter((i) => i.checked === true)?.length >= 1;

	let columns = [
		{ path: "", label: "#" },
		{ path: "", label: "", isNbSp: true },
		{ path: "name", labelStringID: "KC0241" },
		{ path: "roleName", labelStringID: "KC0588" },
		{ path: "createdBy", labelStringID: "KC0589", isNbSp: true },
		{ path: "lastLogin", labelStringID: "KC0590" },
		{ path: "activation_status", labelStringID: "KC0536" },
		{ path: "", labelStringID: "KC0591" },
		{ path: "", label: "" },
		{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
	];

	return (
		<div id="page-content-wrapper" className="withSearchBar">
			<div className="userManagementWrapper">
				<div className="userMgmtHeader">
					<ul>
						<li>
							<KCLink 
                                to="/user/list" 
                                className="active" 
                                title={formatMessage({ id: "KC0592" })} >
                                    <FormattedMessage id='KC0592' />
                            </KCLink>
							<KCLink 
                                to="/role/list" 
                                title={formatMessage({ id: "KC0585" })} >
                                    <FormattedMessage id='KC0585' />
                            </KCLink>
						</li>
						<li>
							<React.Fragment>
								<KCButton
									className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text assignunitSite ${isEdit ? "" : "disabled"}` : "btn btn-default-text assignunitSite disabled"} 
									title={formatMessage({ id: "KC0594" })} 
									onClick={callOrgView}/>
								<KCButton 
                                    className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text userEdit ${isEdit ? "" : "disabled"}` : "btn btn-default-text userEdit disabled"} 
                                    title={formatMessage({ id: "KC0595" })} 
                                    onClick={editUser}/>
								<KCButton 
                                    data-toggle="modal" 
                                    data-target="#DeleteConfirmationModal" 
                                    className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text userDelete ${isDelete ? "" : "disabled"}` : "btn btn-default-text userDelete disabled"} 
                                    title={formatMessage({ id: "KC0584" })} />
								<KCLink to={{ pathname: '/user/create', state: { prevPath: "userManagementList" } }} className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text userAdd ${isAdd ? "" : "disabled"}` : "btn btn-default-text userAdd disabled"} draggable="false" title={formatMessage({ id: "KC0593" })}>&nbsp;</KCLink>
							</React.Fragment>
						</li>
					</ul>
				</div>
				<form className="userMgmtListForm">
					<div className="userMgmtListTable">
						<TableHeader
							userMgmtListScreen={true}
							sortColumn={sortColumn}
							onSort={onSort}
							selectAllChange={(e) => handleChange(e, user, 'multi')}
							columns={columns}
							isIndeterminate={isIndeterminateSelect}
							isSelectAll={allSelect} />
					</div>
					<div className="userMgmtTableSrlBar">
						<div className="userMgmtListTable">
							{user?.map((item, i) => {
								var emailValue = Buffer.from(user[i].email);
								var ciphertext = emailValue.toString('base64');
								const url = "/myInformation?user=" + ciphertext;
								return (
									<ul className={item.createdBy === '-' ? "tBody disabled" : "tBody"} key={item.email}>
										<li>{rowNumber + i + 1}</li>
										<li className={item.is_active ? "" : "disabled"}><span className="userDP"><KCImage src={item.img} /></span></li>
										<li className={item.is_active ? "" : "disabled"}><KCLink to={url}>{item.name}</KCLink><span className="userMListRL">{item.roleName}</span></li>
										<li className={item.is_active ? "" : "disabled"}>{item.roleName}</li>
										<li>{item.createdBy}</li>
										<li>{item.lastLogin ? moment.GetDateTimeByFormat(item.lastLogin, 'LLL') : ""}</li>
										<li>{item.activation_status}</li>
										<li>
											<div className="btn-group btn-group-toggle" data-toggle="buttons">
												<Switch
													onChange={(e) => handleActivation(item)}
													checked={item.is_active}
													uncheckedIcon={false}
													checkedIcon={false}
													onColor={'#c5cdd7'}
													offColor={'#ffffff'}
													offHandleColor={'#0d3968'}
													onHandleColor={'#0d3968'}
												/>
											</div>
										</li>
										<li>
											<KCButton value={item.email} onBlur={(e) => setUserEmail(item.email)} onChange={(e) => setUserEmail(item.email)} onClick={(e) => setUserEmail(item.email)} data-toggle="modal" data-target="#confirmationYesNoModal" className="btn btn-default" ><FormattedMessage id={'KC0609'} /></KCButton>
										</li>
										{<li>
											<div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
												<input id={item.order_id - 1} type="checkbox" value={item.createdBy === '-' ? false : item.checked} defaultChecked={item.createdBy === '-' ? false : item.checked}
													checked={item.createdBy === '-' ? false : item.checked}
													onChange={(e) => handleChange(e, item, 'single')} />
												<label htmlFor={item.order_id - 1}></label>
											</div>
										</li>
										}
									</ul>
								)
							})
							}
						</div>
						<NoRecordsFound loaderIconVisible={loader} length={user !== undefined ? user.length : 0} classname={'norecordsfoundbluetext'} stringID={searchData && Object.keys(searchData).length !== 0 && user && user.length === 0 ? "KC0853" : "KC0205"} />
					</div>
					{totalPages && totalPages > 1 ? <GoToPagination
						totalPageNumber={totalPages}
						pageNumber={currentPage}
						navigateTo={onPageChange} /> : null}
					<DeleteConfirmationModal deleteUser={deleteUser} />
					<ResetPasswordModal resetPasswordHanddler={resetPasswordHanddler} />
				</form>
			</div>
		</div>
	)
}

export default injectIntl(UsertListComponent);