import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {Link} from 'react-router-dom';

am4core.useTheme(am4themes_animated);


class CycleRates extends Component {
    render() {
        return(
            <React.Fragment>
                <div id="wrapper">
                <div class="loadingProgress">
                <div class="loadingProgressIn"></div>
                </div>
                <nav class="navbar navbar-expand-lg navbar-dark bg-Welbilt">  
				<div class="navbar-header"> 
					<Link class="navbar-brand welbiltRLogo" title="Welbilt - Bringing innovation to the table" to="/mainMenu">&nbsp;</Link>
                    <Link className="navbar-brand sideNavBar" to="#">&nbsp;</Link>
				</div>
				<ul class="navbar-nav mr-auto navbarLeft">
				  <li class="nav-item">
					<Link class="nav-link" to="#" title="Welbilt Kitchen">
						<span class="navStoreIcon"><img src={require('../../images/store/store01.svg').default} alt="Welbilt Kitchen" title="Welbilt Kitchen"/></span>
						<span class="navStoreText">Welbilt Kitchen <b>New York City</b></span>
					</Link>
				  </li>
				  <li class="nav-item">
					<a class="nav-link" href="#" title="Carrara Espresso">
						<span class="navStoreIcon"><img src={require('../../images/unit/unit01.png')} alt="Carrara Espresso" title="Carrara Espresso"/></span> 
						<span class="navStoreText">Carrara Espresso <b>Crem</b></span>
					</a>
				  </li>  
				</ul> 

				<ul class="navbar-nav navbarLRight">
                    <li class="nav-item">
                        <a class="nav-link" href="#" title="Serial Number">
                            <span class="deviceSNText">S/N <b>530.115.987</b></span>
                        </a>
                    </li>
        </ul>

				<ul class="navbar-nav mr-auto navbarRight">
				  <li class="nav-item">
					<a class="nav-link " title="Sorting" href="#">&nbsp;</a>
				  </li> 
				  <li class="nav-item">
					<a class="nav-link navFilter" title="Filter" href="#">&nbsp;</a>
				  </li> 
				  <li class="nav-item">
					<a class="nav-link navSearch" title="Search" href="#">&nbsp;</a>
				  </li> 
				  <li class="nav-item">
					<a class="nav-link navAlarm" title="Notification" href="#">&nbsp;</a>
				  </li> 
				  <li class="nav-item">
					<a class="nav-link navUser" title="User Name" href="#">&nbsp;</a>
				  </li> 
				</ul>  
		</nav>
		
		
		<div id="sidebar-wrapper">
					<ul className="sidebarNav">
						<li className="brandName">
							<Link to="/dashBoardLocations" className="homeNav" title="Welbilt - Kitchen Connect">&nbsp;</Link>
						</li>
						<li>
							<Link to="/unitDashboard" className="dashboardNav" title="Unit Dashboard">&nbsp;</Link>
						</li>
						<li>
							<Link to="/reportManagement" className="reportNav active " title="Report Management">&nbsp;</Link>
						</li>
						<li>
							<Link to="/qualityManagement" className="qualityNav" title="Quality Management">&nbsp;</Link>
						</li>
						<li>
							<Link to="/serviceManagement" className="serviceNav" title="Service Management">&nbsp;</Link>
						</li>
						<li>
							<Link to="/recipesLibrary" className="recipeNav" title="Menu Management">&nbsp;</Link>
						</li>
						<li>
							<Link to="/unitManagement" className="unitNav" title="Unit Management">&nbsp;</Link>
						</li>
					</ul>
            <ul class="sidebarNav bottomNav"> 
                <li>
                    <Link to="#" class="uploadNav">&nbsp;</Link>
                </li>
                <li>
                    <Link to="#" class="menuNav">&nbsp;</Link>
                </li> 
            </ul>
        </div>
			
 
              <div id="page-content-wrapper">  
				
                <div class="pageHeader">
                    <ul>
                    <li>
                            <Link to="/reportManagement/production" title="Production" >Production</Link>
                        </li>
                        <li>
                            <Link to="#" title="Resource Use">Resource Use</Link>
                        </li>
                        <li>
                            <Link to="#" title="Cycle Rates" className="active">Cycle Rates</Link>
                        </li>
                        <li>
                            <Link to="#" title="Utilization">Utilization</Link>
                        </li>
                    </ul>
                </div>	
                
                
                
                
                <div class="gridViewTable"> 
                <div class="gridView">
				<div class="colm12D">
					<div class="colmDi">
						<h4>Total Brewer Cycles <span class="cornerIcon"></span> 
						<select class="form-control selectedDuration"> 
							<option value="Jan 2018">Jan 2018</option> 
							<option value="Feb 2018">Feb 2018</option>  
							<option value="Mar 2018">Mar 2018</option>  
							<option value="Apr 2018">Apr 2018</option>  
							<option value="May 2018">May 2018</option>  
							<option value="Jun 2018">Jun 2018</option>  
						</select>
						</h4>  
						<div id="TotalBrewerCyclesChartdiv"></div>
					</div>
				</div>
				</div>
			</div>   
            </div>         
            </div>
        </React.Fragment>
        )
    }
};

export default CycleRates;
