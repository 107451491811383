import {ORG_LOCATION_TYPESREQUEST,ORG_LOCATION_TYPESSUCCESS,ORG_LOCATION_TYPESFAIL} from '../../../../actions/navigation/organizationmanagement/organizationLocationTypesActions';
const initialState = {
    loaded: false,
    loading: false,
    OrganizationLocationTypesInformation: {"customer_group_type": []},
   
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case ORG_LOCATION_TYPESREQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
                
            }
        case ORG_LOCATION_TYPESSUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                OrganizationLocationTypesInformation: action.OrganizationLocationTypesInformation,
               
            }
        case ORG_LOCATION_TYPESFAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: '',
                //OrganizationLocationTypesInformation:staticLocationTypesData,
            }
        default:
            return state
    }
}

