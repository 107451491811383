import axios from 'axios';
import api from '../../../service/api';
import http from '../../../service/httpService';
export const ORG_UNITS_DETAILS_REQUEST = 'ORG_UNITS_DETAILS_REQUEST';
export const ORG_UNITS_DETAILS_SUCCESS = 'ORG_UNITS_DETAILS_SUCCESS';
export const ORG_UNITS_DETAILS_FAIL = 'ORG_UNITS_DETAILS_FAIL';

export function getOrganizationUnitsDetails(unitId = 0, brandName = '') {
    return dispatch => {
        dispatch({
            type: ORG_UNITS_DETAILS_REQUEST
        })
        return new Promise((res, rej) => {
            http.get(api.organizaionUnitsDetailsUrl + unitId + '/' + brandName,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {}
                })
                .then(response => {
                    console.log('ORG_VIEWInfo', response.data);
                    dispatch({
                        type: ORG_UNITS_DETAILS_SUCCESS,
                        OrganizationUnitsDetailsInformation: response.data,
                    })
                    res(response.data)
                })
                .catch(error => {
                    dispatch({
                        type: ORG_UNITS_DETAILS_FAIL,
                        error
                    })
                    res(error)
                })
        })
    }
}


// axios.get('/api', {
//     params: {
//       foo: 'bar'
//     }
//   });