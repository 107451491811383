import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuUploadAPI } from '../../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../../service/httpService';
import { setUserDataModel } from '../../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../../modal/success-model';
import ErrorMoal from '../../../modal/error-model';
import UserManagementHeader from '../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewTopology from '../../../../components/controls/viewTopology';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../utils/kcStorage';

const HTTP_METHOD ={
	GET:'GET',
	PUT:'PUT',
	POST:'POST',
	DELETE:'DELETE'
}

class UploadLegacyCookbookToTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/legacyLibraryList`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            linkListValue: {},
            findTreeLevel: "",
            selectValues: {},
            pageLoad: true,
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        $("body").removeClass("loginBg");
        window.addEventListener('resize', this.updateDimensions);
        let { library_name, name, totalfilesize, URL, crc, previouspath } = this.props.location;
        this.setState({
            library_name: library_name,
            name: name,
            totalfilesize: totalfilesize,
            presignedURL: URL,
            crc: crc,
            previouspath: previouspath
        });
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        this.props.history.push({
            pathname: "/legacyLibraryList",
            isEdit: false,
            previouspath: "/assignLegacyCookbookToTopology",
        });
    }

    submitAddLibrary = async(e,_customId) => {
        e.preventDefault();
        this.setState({
            loaderIconVisible: true
        });
        let { library_name, name, totalfilesize, presignedURL, crc } = this.state;
        let cgids = _customId;
        if (library_name && name && totalfilesize && presignedURL && crc) {
            await this.onAddCookbook(library_name, name , totalfilesize, presignedURL, crc, cgids).then((res) => {
                this.setState({
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: "KC2007"
                    },
                });
            }).catch(err => {
                console.log(err);
                this.setState({
                    loaderIconVisible: false
                });
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                success_modal: {
                    open: true,
                    message: "KC1204"
                }
            });
        }
    }

    onAddCookbook = (LIBRARY_NAME, name, totalfilesize, URL, crc, cgids) => {
        let S3Url = URL.split('?')[0];
        let md5 = require('md5');
        return new Promise((resolve, reject) => {
            http({
                method: HTTP_METHOD.POST,
                url: MenuUploadAPI.createConvoCB,
                headers: {},
                data: {
                    "customerId": CUSTOMER_ID,
                    "brandId": 6,
                    "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                    "libraryId": LIBRARY_NAME,
                    "libraryName": name,
                    "libraryFileName": `${LIBRARY_NAME}.db`,
                    "libraryFileFormat": "db",
                    "libraryFileSize": totalfilesize,
                    "libraryS3Url": S3Url,
                    "type": "CookBook",
                    "createdUser":KCStore.getItem("user"),
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "cgids": cgids
                }
            }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }

    changeLoadingStatus=(value)=>{
        this.setState({loaderIconVisible:value})
    }

    static getDerivedStateFromProps = (props) => ({...props});

    render() {
        let { success_modal, error_modal, } = this.state;
        const { loaderIconVisible } = this.state;
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology 
                    title={`KC1677`} 
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    hideBackButton={true} 
                    modelFamilyIds={KCStore.getItem("selectedFamilyID")} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UploadLegacyCookbookToTopology));
