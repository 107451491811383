import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {Link} from 'react-router-dom';

am4core.useTheme(am4themes_animated);


class ErrorDescription extends Component {
    render() {
        return(
            <div>
                <div id="wrapper">  
                <div class="loadingProgress">
						<div class="loadingProgressIn"></div>
					</div>
        <div id="page-content-wrapper">
            <div class="errorWrapper">
                <div class="errorTopHeader">
                    <ul>
                        <li>
                            <Link to="/serviceManagement" title="Back" class="backIcon">&nbsp;</Link>
                        </li>
                        <li>
                           E 00: Error Description
                        </li> 
                        <li>
                            <Link to="/serviceManagement" title="Cancel" class="cancelIcon">&nbsp;</Link>
                        </li>
                    </ul>
                </div>
                <div class="errorHeader"> 
					<Link to="troubleshooting.html" title="Trouble Shooting" class="active">Trouble Shooting</Link> 
					{/* <Link to="servicenotes.html" title="Service Notes">Service Notes</Link>  
					<Link to="servicereport.html" title="Service Report">Service Report</Link>  */}
                </div>
                <div class="serviceInfoListOuter">
                    <div class="serviceInfoListL">
                        <h5>Error Frequency</h5>
                        <div class="errorFrequencyTable">
                            <ul class="tHead">
                                <li>&nbsp;</li>
                                <li>Date</li>
                                <li>Time</li>
                                <li>Error Status</li>
                            </ul>
                            <ul>
                                <li><span class="errorStatusLevel alertLevelIcon"></span></li>
                                <li>November 2, 2019</li>
                                <li>14:02</li>
                                <li>Non-Critical Errors</li>
                            </ul>
                            <ul>
                                <li><span class="errorStatusLevel alertLevelIcon"></span></li>
                                <li>November 2, 2019</li>
                                <li>14:02</li>
                                <li>Non-Critical Errors</li>
                            </ul>
                            <ul>
                                <li><span class="errorStatusLevel alertLevelIcon"></span></li>
                                <li>November 2, 2019</li>
                                <li>14:02</li>
                                <li>Non-Critical Errors</li>
                            </ul>
                            <ul>
                                <li><span class="errorStatusLevel alertLevelIcon"></span></li>
                                <li>November 2, 2019</li>
                                <li>14:02</li>
                                <li>Non-Critical Errors</li>
                            </ul>
                            <ul>
                                <li><span class="errorStatusLevel alertLevelIcon"></span></li>
                                <li>November 2, 2019</li>
                                <li>14:02</li>
                                <li>Non-Critical Errors</li>
                            </ul>
                        </div>
                    </div>
                    <div class="serviceInfoListR">
                        {/* <h5>Manager Action</h5>
                        <div class="boxDiv">
                            <Link to="#" title="Play Video for Trouble Shooting" class="playVideo">Play Video for Trouble Shooting</Link>
                        </div> */}
                        <div class="managerActionTable">
                            <ul class="tHead">
                                <li>&nbsp;</li>
                                <li>Action</li>
                                <li>&nbsp;</li>
                            </ul>
                            <ul>
                                <li>01.</li>
                                <li>
                                    <h6>Manager Action xyz</h6>
                                    <p>The bitterness of poor quality remains long after the sweetness of low price is forgotten.</p>
                                </li>
                                <li><Link to="#" class="editIcon">&nbsp;</Link></li>
                            </ul>
                            <ul>
                                <li>02.</li>
                                <li>
                                    <h6>Manager Action xyz</h6>
                                    <p>The bitterness of poor quality remains long after the sweetness of low price is forgotten.</p>
                                </li>
                                <li><Link to="#" class="editIcon">&nbsp;</Link></li>
                            </ul>
                            <ul>
                                <li>03.</li>
                                <li>
                                    <h6>Manager Action xyz</h6>
                                    <p>The bitterness of poor quality remains long after the sweetness of low price is forgotten.</p>
                                </li>
                                <li><Link to="#" class="editIcon">&nbsp;</Link></li>
                            </ul>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
               </div>
            </div>
        )
    }
};

export default ErrorDescription;
