import http from '../../service/httpService';
import { BaseService, ReportManagement } from '../../service/api';
import { TOTAL_TRAY_WIDGET_ID } from '../../utils/appConstants';
export const TOTAL_TRAY_COUNT_REQUEST = 'TOTAL_TRAY_COUNT_REQUEST';
export const TOTAL_TRAY_COUNT_SUCCESS = 'TOTAL_TRAY_COUNT_SUCCESS';
export const TOTAL_TRAY_COUNT_FAIL = 'TOTAL_TRAY_COUNT_FAIL';

export function getTotalTrayCounts(type, fromdate, todate, deviceId, UNIT_BRAND_ID) {
    let url;
    let filterType;
    let widgetId = TOTAL_TRAY_WIDGET_ID;
    switch (type) {
        case 'Week':
            filterType = 'weekly';
            break;
        case 'Year':
            filterType = 'yearly';
            break;
        case 'Day':
            filterType = 'daily';
            break;
        case 'Quarter':
            filterType = 'quarterly';
            break;
        case 'Date Range':
            filterType = 'custom';
            break;
        default:
            filterType = 'monthly';
    }

    url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;

    return dispatch => {
        dispatch({
            type: TOTAL_TRAY_COUNT_REQUEST
        })

        const header = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'unitId': deviceId,
            'reqDate': fromdate,
            'filtertype': filterType,
            'showtrend': "true"
        }

        if (filterType === 'weekly') {
            delete header.reqdate;
            header.startDate = todate;
            header.enddate = fromdate;
            header.showtrend = "true";
        } 
        else if (filterType === 'custom') {
            delete header.reqdate;
            header.startDate = todate;
            header.enddate = fromdate;
            header.showtrend = "false";
        }

        http.get(url,
            {
                headers: header,
                data: {}
            })
            .then(response => {
                dispatch({
                    type: TOTAL_TRAY_COUNT_SUCCESS,
                    totalTrayCountResponse: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: TOTAL_TRAY_COUNT_FAIL,
                    error
                })
            })
    }
}