import React, { Component, PureComponent } from 'react';
import { format } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { reactIntl } from '../../locale/locale-provider';
am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

var moment = require('moment');

export default class RenderBarChart extends PureComponent {

    constructor(props) {
        super(props)
        this.chart = null;
        this.elementId = this.props.elementId;
        this.property = this.props.property;
        this.content = this.props.content;
        this.filterType = this.props.filterType;
        this.isDecimal = this.props.isDecimal;
    }

    componentDidMount() {
        let chart = am4core.create(this.elementId, am4charts.XYChart);
        let response = this.content;
        if (response.length > 0) {
            response.map(item => { 
                item.count = this.isDecimal && item.count ? item.count.toFixed(1) : item.count;
                item.date = item.day;
                item.day = this.filterType === "Week" ? moment(item.day).format('dddd') : moment(item.day).format('D');
                item.bullet = item.bulletStatus === 1 ? require("../../images/icons/oil.png") : "";
            });
        chart.seriesContainer.zIndex = -1;
        chart.data = response;
        chart.responsive.enabled = true;
        // Set input format for the dates
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
        // Do not crop bullets
        chart.maskBullets = false;
        // Add some spacing on top
        chart.paddingTop = 30;

        // Create axes
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.dataFields.category = this.property.categoryX;
        if (this.filterType === "Week") {
            if (window.screen.width > 650) {
                dateAxis.dateFormats.setKey("day", "EEEE");
            }
            else {
                dateAxis.dateFormats.setKey("day", "EEE");
            }
        }
        else {
            dateAxis.dateFormats.setKey("day", "dd");
        }
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.renderer.labels.template.fill = am4core.color("#FFF");
        dateAxis.renderer.labels.template.fontSize = 10;
        dateAxis.renderer.inside = false;
        dateAxis.renderer.labels.template.disabled = false;
        dateAxis.renderer.grid.template.strokeOpacity = 0;
        dateAxis.renderer.grid.template.location = 0;
        
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.location = 0;
        valueAxis.renderer.minGridDistance = 100;
        // Enable .total and .totalPercent placeholders for stacked series
        valueAxis.calculateTotals = true;
        valueAxis.renderer.inside = false;
        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.min = 0;
        valueAxis.renderer.labels.template.fill = am4core.color("#FFF");
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.grid.template.strokeOpacity = 1;
        valueAxis.renderer.grid.template.stroke = am4core.color("#FFF");
        valueAxis.renderer.grid.template.strokeWidth = 0.3;

        // Setting the Series Name based on the unit brand
        let seriesName = "TPM Value";
        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = this.property.valueY;
        series.dataFields.dateX = this.property.categoryX;
        series.name = seriesName;
        series.columns.template.width = am4core.percent(93);
        series.columns.template.tooltipText = ` [bold]` + seriesName + `\n{dateX.formatDate('MMM dd, yyyy')}[/] : [bold]{valueY}[/]`;
        series.showOnInit = false;
        
        series.tooltip.label.adapter.add("textOutput", function (text, target) {
            if (target.dataItem.dataContext != undefined) { 
                if ((target.dataItem.dataContext.status !== -1)) {
                    return " " + seriesName + " : [bold]" + target.dataItem.dataContext.count + "[/]";
                }
                else {
                    return `${reactIntl.formatMessage({ id: 'KC0444' })}: [bold]N/A`;   
                }
            }
            else {
                return "";
            }
        });

        series.columns.template.properties.fillOpacity = 1;
        
        series.columns.template.adapter.add("fill", function(fill, target) {
            if (target.dataItem && (target.dataItem.dataContext.status === -1 || target.dataItem.dataContext.count === 0)) {
                target.dataItem.valueY = (valueAxis.max <= 1 ? 5 : valueAxis.max) - 0.1;
                return am4core.color("#232629");
            }
            else if (target.dataItem && (target.dataItem.dataContext.status === 0)) {
                return am4core.color("#2a3943");
            }
            else if (target.dataItem && (target.dataItem.dataContext.status === 1)) {
                return am4core.color("#1f6386");
            }
            else if (target.dataItem && (target.dataItem.dataContext.status === 2)) {
                return am4core.color("#FFF");
            }
            else {
              return fill;
            }
          });

        // Series Tooltip Test and bg color
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#FFFFFF");
        series.tooltip.label.fill = am4core.color("#000000");

		let columnTemplate = series.columns.template;
		columnTemplate.strokeWidth = 0;
		columnTemplate.strokeOpacity = 0;

        // Add bullets
        let bullet = series.bullets.push(new am4charts.Bullet());
        let image = bullet.createChild(am4core.Image);
        image.horizontalCenter = "middle";
        image.verticalCenter = "bottom";
        if (window.screen.width > 700) {
            image.width = 30;
            image.height = 30;
            image.dy = 0;
        }
        else {
            image.width = 10;
            image.height = 10;
            image.dy = 0;
        }
        image.y = am4core.percent(100);
        image.propertyFields.href = "bullet";
        image.tooltipText = series.columns.template.tooltipText;

        series.columns.template.events.on("sizechanged", function (ev) {
            if (ev.target.dataItem && ev.target.dataItem.bullets) {
              var height = ev.target.pixelHeight;
              ev.target.dataItem.bullets.each(function(id, bullet) {
                if (height > 0 && (ev.target.dataItem.dataContext.status === -1 || ev.target.dataItem.dataContext.count === 0)) {
                  bullet.dy = valueAxis.max > 5 ? 160 : valueAxis.max > 2 ? 156 : 152;
                }
              });
            }
          });
          
        this.chart = chart;
    }
    else{
        if(this.chart){
            this.chart.data = [];
        }
    }
    }

    componentWillUnmount = () =>{
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div>
                <div id={this.elementId} className="TPMvalueAllFrypotBarChartdiv"> </div>
            </div>
        )
    }

}




