import React, { Component } from 'react';
import http from '../../../service/httpService';
import authService from '../../../service/authService';
import { HACCPManagementAPI } from '../../../service/api';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import { Link } from 'react-router-dom';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import NoRecordsFound from '../../controls/noRecordsFound';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../utils/kcStorage';
let isSelectedAllCheckbox = false;

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

$(window).on('beforeunload', function () {
    if($(".fleet-export-error-report").length>0){
        $("#haccpReport").removeClass("fleet-export-error-report");
        $(".loadingProgress").css("display", "none");
    }
});

class HACCPLogLibrary extends Component {

	constructor(props) {
		super(props);
		this.state = {
			customerId: KCStore.getItem("customerId"),
			haccpMgmtList: [],
			logLibraries: [],
			logLibrarySelectAll: false,
			logLibraryChecked: [],
			editingUser: {},
			isAdd: true,
			isDownload: false,
			isDelete: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			loaderIconVisible: false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			userListFlag: false,
			haccpViewerUrl: '#',
			S3Url: '#',
			S3FileName: '',
			isIndeterminate: false
		};

		this.closeModel = this.closeModel.bind(this);
		this.deleteSelectedLogLibrary = this.deleteSelectedLogLibrary.bind(this);
		this.getHACCPViewerUrl = this.getHACCPViewerUrl.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	updateDimensions() {
		var windowHeight = $(window).height();
		var reportHeader = $(".reportHeader").height();
		var HACCPreportListTable = $(".HACCPreportListTable .tHead").height();
		var reportListTableSrlBarH = ((windowHeight) - (reportHeader + HACCPreportListTable)) - 70;
		$(".reportListTableSrlBar").css("height", reportListTableSrlBarH);
	}
	componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions);
		document.body.style.backgroundColor = "#F2F2F2";
		Promise.all([this.getLogLibraryList(), this.getHACCPViewerUrl()]).then((values) => {
			this.setState({
				loaderIconVisible: false,
			});
			
		});
	}

	getLogLibraryList = () => {
		this.setState({ loaderIconVisible: true });
		let custGroupID = KCStore.getItem("custGroupID");
		let storageCustomerId = KCStore.getItem("customerId");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		return new Promise((resolve, reject) => {
			http.get(HACCPManagementAPI.logLibraryUrl, {
				headers: {
					'Content-Type': 'application/json',
					'basecountry': BASECOUNTRY_ID,
					'brandid': KCStore.getItem("selectedbrandID"),
					'customerid': storageCustomerId,
					'cgids': cgids
				}, data: { Status: 'completed' }
			}).then(response => {
				if (response && response.data) {
					this.setState({
						loaderIconVisible: false,
						haccpMgmtList: response.data && response.data.devices ? response.data.devices : [],
					});
					resolve(response.data);
				}
			}).catch(err => {
				// reject(err);
				this.setState({ loaderIconVisible: false });
			});
		});
	}

	cancelDeleteLog = () => {

	}

	cancelDownloadLog = () => {

	}

	closeModel() {
		this.setState({
			error_modal: {
				open: false
			},
			isIndeterminate: false,
			logLibrarySelectAll: false
		})
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data.message === "The incoming token has expired" ? "KC1065" : (data.stringID || data.message)
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false
			});
		}
	}

	getHACCPViewerUrl = () => {
		return new Promise((resolve, reject) => {
			http.get(HACCPManagementAPI.logViewerUrl, {
				headers: {
					'Content-Type': 'application/json',
				}, data: {}
			}).then(response => {
				if (response && response.data) {
					this.setState({
						haccpViewerUrl: response.data.presignedLogViewerUrl,
					});
					resolve(response.data);
				}
			}).catch(err => {
				this.setState({ loaderIconVisible: false });
			});
		});
	}

	handleChange = () => {
		var logLibrarySelectAll = !this.state.logLibrarySelectAll;
		isSelectedAllCheckbox = logLibrarySelectAll;
		this.setState({ logLibrarySelectAll: logLibrarySelectAll });
		var checkedCopy = [];
		let logLibraries = [...this.state.haccpMgmtList];
		logLibraries.forEach(function (e, index) {
			checkedCopy.push(logLibrarySelectAll);
		});

		logLibraries && logLibraries.length > 0 && logLibraries.map(item => {
			return item.checked = logLibrarySelectAll;
		});

		this.setState({
			logLibraryChecked: checkedCopy,
			haccpMgmtList: logLibraries
		});

		this.isSelectEditDelete(checkedCopy);
	};

	handleSingleCheckboxChange = (e, item, path, order) => {
		isSelectedAllCheckbox = false;
		var checkedCopy = this.state.logLibraryChecked;
		checkedCopy[item.order_id - 1] = !this.state.logLibraryChecked[item.order_id - 1];
		if (checkedCopy[item.order_id - 1] === false) {
			this.setState({ logLibrarySelectAll: false });
		}
		let checkAll = [];

		var logLibraries = [...this.state.haccpMgmtList];
		logLibraries[item.order_id - 1] = { ...logLibraries[item.order_id - 1] };
		logLibraries[item.order_id - 1].checked = e.target.checked;

		checkAll = logLibraries.filter(c => c.checked === false || c.checked === undefined);

		if (checkAll.length === 0) {
			this.setState({
				haccpMgmtList: logLibraries,
				logLibrarySelectAll: true,
				logLibraryChecked: checkedCopy,
				S3Url: item.S3Url,
				S3FileName: item.FILE_NAME,
				isIndeterminate: false
			});
		}
		else {
			this.setState({
				haccpMgmtList: logLibraries,
				logLibrarySelectAll: false,
				logLibraryChecked: checkedCopy,
				S3Url : item.S3Url,
				S3FileName: item.FILE_NAME,
				isIndeterminate: false
			});
		}

		this.isSelectEditDelete(logLibraries, path, order);
	};

	isSelectEditDelete = (logLibraries, path, order) => {
		let ictr = 0;
		var checkedCopy = this.state.logLibraryChecked;
		if (logLibraries.length === 0) {
			this.setState({ logLibrarySelectAll: false, isIndeterminate: false });
			return;
		}
		var logLibraryNames = [...this.state.haccpMgmtList];
		logLibraryNames = _.orderBy(logLibraryNames, path, order);

		logLibraries.forEach(i => {
			if (i.checked === true || i === true) {
				ictr = ictr + 1
			}
		});

		if (ictr === logLibraries.length){
			this.setState({
				isAdd: false,
				isDownload: false,
				isDelete: true,
				isIndeterminate: false,
				logLibrarySelectAll: true
			});
		} else if (ictr > 1) {
			this.setState({
				isAdd: false,
				isDownload: false,
				isDelete: true,
				isIndeterminate: true,
				logLibrarySelectAll: true
			});
		}
		else if (ictr === 0) {
			this.setState({
				isAdd: true,
				isDownload: false,
				isDelete: false,
				isIndeterminate: false,
				logLibrarySelectAll: false
			});
		}
		else if (ictr === 1) {
			this.setState({
				isAdd: false,
				isDownload: true,
				isDelete: true,
				isIndeterminate: true,
				logLibrarySelectAll: true
			});
		}

		if (isSelectedAllCheckbox === true) {
			if (ictr === 0) {
				this.setState({
					isAdd: false,
					isDownload: true,
					isDelete: true,
					isIndeterminate: false
				});
			}
			else if (ictr === 1) {
				this.setState({
					isAdd: false,
					isDownload: true,
					isDelete: true,
					isIndeterminate: false
				});
			}
			else if (ictr > 1) {
				this.setState({
					isAdd: false,
					isDownload: false,
					isDelete: true,
					isIndeterminate: false
				});
			}
		}
	}

	deleteSelectedLogLibrary = () => {
		let allLogLibraries = [...this.state.haccpMgmtList];
		let deletedLogLibraries = allLogLibraries.filter(item => item.checked === true);

		if (deletedLogLibraries && deletedLogLibraries.length > 0 && deletedLogLibraries != null) {
			let postData = {
				idList : deletedLogLibraries.map(function (item) { return item.ID })
			}

			this.setState({
				loaderIconVisible: true
			})

			this.putData(postData, HACCPManagementAPI.logLibraryUrl, HTTP_METHOD.DELETE).then(response => {
				if (response.data.responseCode === 200) {
					let newLogLibraries = allLogLibraries.filter(item => !item.checked || item.checked === false);

					var checkedCopy = [];
					newLogLibraries.forEach(function (e, index) {
						checkedCopy.push(false);
					});

					newLogLibraries && newLogLibraries.length > 0 && newLogLibraries.map(item => {
						return item.checked = false;
					});

					this.setState({
						loaderIconVisible: false,
						logLibraryChecked: checkedCopy,
						logLibrarySelectAll: false,
						haccpMgmtList: newLogLibraries,
						isAdd: true,
						isDownload: false,
						isDelete: false,
						success_modal: {
							open: true,
							message: "KC1575"
						},
						isIndeterminate: false,
					});
					WoopraEvents(`${Constants.DELETE_LOG_LIBRARY}`);
				}
			}).catch(err => {
				this.showError(err);
			});
		}
		this.getLogLibraryList();
	}

	putData = (postData, url, method) => {
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData
			}).then(res => {
				let { data } = res;
				let { responseCode } = data;
				if (responseCode !== 200) {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	downloadSelectedLogLibrary = () => {
		if (this.state.S3Url !== '#') {
			$("#haccpReport").addClass("fleet-export-error-report");
			this.handleDownload(this.state.S3Url,this.state.S3FileName);
			WoopraEvents(`${Constants.DOWNLOAD_SELECTED_LOG_LIBRARY}`);			
		}
	}

	handleDownload = (fileUrl, fileName) => {
		fetch(fileUrl)
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const a = document.createElement('a');
				a.href = url;
				a.download = fileName || 'download.dat';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			})
			.catch(error => console.error('Error downloading file:', error));
	};

	downloadLogViewer = () => {
		if (this.state.haccpViewerUrl !== '#') {
			$("#haccpReport").addClass("fleet-export-error-report");
			window.location = this.state.haccpViewerUrl;
			WoopraEvents(`${Constants.HACCP_LOG_VIEWER}`)
		}
	}

	onSuccessCloseModal = () => {
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			},
			isIndeterminate: false,
			logLibrarySelectAll: false
		});
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	// Woopra Events
	retrieveStatus = () => {
		WoopraEvents(`${Constants.RETRIEVE_STATUS}`);
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { success_modal, error_modal, isAdd, isDownload, isDelete, sortColumn, haccpMgmtList, isIndeterminate } = this.state;
		const { loaderIconVisible } = this.state;
		let userProfileAuthFeature = authService.getFeature("/haccpLogLibrary");
		let orderId = 1;
		let haccpFilteredResponse;
		let columns = [
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0102", isNbSp: true },
			{ path: "FILE_NAME", labelStringID: "KC0677" },
			{ path: "CREATION_DATE", labelStringID: "KC0762", isNbSp: true },
			{ path: "SIZE", labelStringID: "KC0682" },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		haccpMgmtList = _.orderBy(haccpMgmtList, [sortColumn.path], [sortColumn.order]);

		haccpMgmtList.forEach(item => {
			item.order_id = orderId++
		});
		haccpFilteredResponse = haccpMgmtList.filter(el => (el.status === 'completed' || el.status === 'SUCCEEDED'));
		return (
			<div id="adminWrapper">
				<div id="haccpReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div className="reportWrapper">
						<div className="reportHeader">
							<ul>
								<li>
									<Link to="/haccpLogLibrary" title={formatMessage({ id: 'KC0759' })} className="active"><FormattedMessage id="KC0759"/> </Link>
									<Link to="/haccpRetrieveStatus" title={formatMessage({ id: 'KC0760' })} onClick={() => this.retrieveStatus()}><FormattedMessage id="KC0760"/> </Link>
									<button className="btn btn-default-text logViewBtn" onClick={() => this.downloadLogViewer()} type="button" title={formatMessage({ id: 'KC0761' })}><FormattedMessage id="KC0761"/></button>
								</li>
								<li>
									<button type="button" title={formatMessage({ id: 'KC0766' })} className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text download ${isDownload ? "" : "disabled"}` : "btn btn-default-text download disabled"} data-toggle="modal" data-target="#confirmationDownloadModal">&nbsp;</button>
									<button type="button" title={formatMessage({ id: 'KC0764' })} className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text delete ${isDelete ? "" : "disabled"}` : "btn btn-default-text delete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal">&nbsp;</button>
									<Link to="/haccpAssignUpdateList" title={formatMessage({ id: 'KC0763' })} className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text add ${isAdd ? "" : "disabled"}` : "btn btn-default-text add disabled"}></Link>
								</li>
							</ul>
						</div>
						<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
								<div className="modal-dialog modal-md loginModal">
									<div className="modal-content" >
										<div className="modal-body">
											<div className="singOutConfirmation">
												<FormattedMessage id="KC0765"/>
                            				</div>
										</div>
										<div className="modal-footer textAlignCenter">
											<button className="btn btn-secondary signOutNoBtn" onClick={() => this.cancelDeleteLog()} data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
											<button className="btn btn-secondary signOutYesBtn" onClick={() => this.deleteSelectedLogLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
										</div>
									</div>
								</div>
							</div>
							<div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="confirmationDownloadModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
								<div className="modal-dialog modal-md loginModal">
									<div className="modal-content" >
										<div className="modal-body">
											<div className="singOutConfirmation">
												<FormattedMessage id="KC0767"/>
                            				</div>
										</div>
										<div className="modal-footer textAlignCenter">
											<button className="btn btn-secondary signOutNoBtn" onClick={() => this.cancelDownloadLog()} data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
											<button className="btn btn-secondary signOutYesBtn" onClick={() => this.downloadSelectedLogLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
										</div>
									</div>
								</div>
							</div>
						<form className="reportListForm">
							<div className="HACCPreportListTable">
								<TableHeader
									userMgmtListScreen={this.state.userListFlag}
									sortColumn={sortColumn}
									onSort={this.onSort}
									selectAllChange={this.handleChange}
									columns={columns}
									isSelectAll={this.state.logLibrarySelectAll}
									isIndeterminate= {isIndeterminate} />
							</div>
							<div className="reportListTableSrlBar">
								<div className="HACCPreportListTable">
								{
									haccpFilteredResponse.map((item, i) => {
										return (
											<ul className="tBody" key={i}>
												<li>{i + 1}</li>
												<li><span className="reportType"><img src={require("../../../images/file/report_service.svg").default} alt="" /></span></li>
												<li>{item.FILE_NAME}<span className="reportListSM">{item.CREATION_DATE}</span></li>
												<li>{item.CREATION_DATE}</li>
												<li>{item.SIZE}</li>
												<li>
													<div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
														<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
															checked={item.checked}
															onChange={(e) => this.handleSingleCheckboxChange(e, item, [sortColumn.path], [sortColumn.order])} />
														<label htmlFor={item.order_id - 1}></label>
													</div>
												</li>
											</ul>)
									})
								}
							</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={haccpFilteredResponse !== undefined ? haccpFilteredResponse.length : 0} classname={'norecordsfoundbluetextpaddingtop75'} text={formatMessage({ id: 'KC0768' })} />
							</div>
						</form>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
				<UserManagementHeader headerName="HACCP Management" headerNameStringID="KC0636" activeClass="haccpNav" />
			</div>
		)
	}
};

export default (injectIntl(HACCPLogLibrary));
