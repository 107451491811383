import { SET_CONFIGURATION_FILE } from '../../../actions/dashboardRenderer/type';

const initialState = {
    dashboardConfiguration: {},
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_CONFIGURATION_FILE:
            return {
                ...state,
                dashboardConfiguration: { [action.payload.configurationName]: action.payload.configuration },
            }
        default:
            return state
    }
}