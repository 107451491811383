import {MODEL_REQUEST,MODEL_SUCCESS,MODEL_FAIL} from '../../../../actions/listView/locations/modelAction';

const initialState = {
    loaded: false,
    loading: false,
    modelInformation: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case MODEL_REQUEST:
            return {
                ...state,
                loading: true,
                loaded: false,
                isServerActionComplete : action.isCascadeActionRequest
            }
        case MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                modelInformation: action.modelInformation,
                isServerActionComplete : true
            }
        case MODEL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                errormsg: 'Server Error',
                isServerActionComplete : true
            }
        default:
            return state
    }
}