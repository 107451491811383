import React, { Component } from 'react'

export default class TermsAndCondition extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="termsCondtionsDropDn">
                    <label>Select your country:</label>
                    <select className="form-control">
                        <option value="">United States of America</option>
                    </select>
                </div>
                <h4>WELBILT KITCHENCONNECT MASTER SUBSCRIPTIONAGREEMENT (VERSION FEB 2022)</h4>
                {/* <h5>(version December 2021)</h5> */}
                {/* <h6>Introduction to this Privacy Notice</h6> */}
                <p>This Welbilt KitchenConnect Master Subscription Agreement (“Agreement”) is a legally binding agreement between the applicable Welbilt contracting entity specified in Section 13.12 (“Welbilt”, “we”, “us” or “our”) and you or the entity you are representing (“Customer”, “you” or “your”) and governs your access to and use of the Services of Welbilt’s KitchenConnect Platform and IoT offerings. This Agreement also applies if you use the Services in the context of a free trial or if the Services are offered to you for free for a limited or unlimited period of time. You should print off or save a local copy of the Agreement for your records. Definitions of certain capitalized terms used in this Agreement but not otherwise defined herein are listed in Section 14.</p>
                <p>BY CLICKING OR CHECKING THE “ACCEPT” OR “AGREE” BUTTON OR ITS EQUIVALENT WHEN SUCH TERMS ARE PRESENTED ON YOUR SCREEN, YOU REPRESENT THAT (i) YOU ARE LAWFULLY ABLE TO ENTER INTO CONTRACTS INCLUDING BY HAVING OBTAINED THE AGE OF MAJORITY IN THE JURISDICTION FROM WHICH YOU ARE ACCESSING THE SERVICES, AND (ii) YOU ARE DULY AUTHORIZED BY YOUR COMPANY OR YOUR EMPLOYER TO ACCESS AND USE THE SERVICES, AND, IF YOU ARE ACTING ON BEHALF OF AN ENTITY SUCH AS YOUR COMPANY OR YOUR EMPLOYER TO ENTER INTO THIS AGREEMENT, YOU HAVE THE LEGAL AUTHORITY TO ENTER INTO THIS AGREEMENT, AND (iii) YOU ACCEPT THE TERMS AND CONDITIONS OF THIS AGREEMENT AND AGREE THAT YOU ARE, OR THE ENTITY YOU ARE REPRESENTING IS, LEGALLY BOUND BY THEM. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, DO NOT INDICATE YOUR ACCPETANCE VIA THE “ACCEPT” OR “AGREE” BUTTONS AND YOU WILL HAVE NO RIGHT TO, AND MUST NOT ACCESS OR USE, THE SERVICES.</p>
                <h6>1.	Services.</h6>

                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            1.1.	Access and Use. Subject to and conditioned on Customer’s and its Authorized Users’ compliance with the terms and conditions of this Agreement and payment of all fees required pursuant to this Agreement, Welbilt hereby grants Customer a non-exclusive, non-sublicensable, non-transferable right to access and use the Services during the Term, solely for use by Authorized Users solely for Customer’s internal business purposes, and for no other use or purpose.
                        </li>
                        <li>
                            1.2.	Grant of License. Subject to and conditioned on Customer’s and its Authorized Users’ compliance with the terms and conditions of this Agreement and payment of all fees required pursuant to this Agreement, Welbilt hereby grants to Customer a non-exclusive, non-sublicensable, non-transferable license to use the Documentation and Service Components during the Term solely for Customer’s internal business purposes in connection with its use of the Services, and for no other use or purpose.
                        </li>
                        <li>
                            1.3.	Services and System Control. Except as otherwise expressly provided in the Agreement, as between the parties:
                        </li>
                        <div className="termsCondtionsOlTable">
                            <ol>
                                <li>
                                    1.3.1.	Welbilt has and will retain sole control over the operation, provision, maintenance, and management of the Welbilt Materials; and
                                </li>
                                <li>
                                    1.3.2.	Customer has and will retain sole control over the operation, maintenance, and management of, and all access to and use of, the Customer Systems, and sole responsibility for all access to and use of the Welbilt Materials (including misuse or unauthorized use thereof) by any Person by or through the Customer Systems or any other means controlled by Customer or any Authorized User, including any: (i) information, instructions, or materials provided by any of them to the Services or Welbilt; (ii) results obtained from any use of the Services or Welbilt Materials; and (iii) conclusions, decisions, actions, or fees based on such use.
                                </li>
                            </ol>
                        </div>

                        <li>
                            1.4.	Reservation of Rights. Nothing in this Agreement grants any right, title, or interest in or to (including any license under) any Intellectual Property Rights in or relating to the Services, Welbilt Materials, or Third-Party Materials, whether expressly, by implication, estoppel, or otherwise. All right, title, and interest in and to the Services, the Welbilt Materials, and the Third-Party Materials are and will remain with Welbilt and the respective rights holders in the Third-Party Materials.
                        </li>
                        <li>
                            1.5.	Changes. Welbilt reserves the right, in its sole discretion, to make any changes to the Services and Welbilt Materials that it deems necessary or useful to: (a) maintain or enhance: (i) the quality or delivery of Welbilt’s services to its customers; (ii) the competitive strength of or market for Welbilt’s services; or (iii) the Services’ cost, efficiency, or performance; (b) comply with applicable Law; or (c) prevent conduct or activity that Welbilt, in its discretion, deems to involve actual or threatened unauthorized access, fraud, or other unlawful activity.
                        </li>
                        <li>
                            1.6.	Subcontractors. Welbilt may from time to time in its discretion engage third parties (a “Subcontractor”) or Affiliates to perform the Services, in whole or in part. For the avoidance of doubt, Cloud Host is a Subcontractor within the meaning of this Section 1.6.
                        </li>
                        <li>
                            1.7.	Suspension or Termination of Services. Welbilt may, directly or indirectly, and by use of a Welbilt Disabling Device or any other lawful means, suspend, terminate, or otherwise deny Customer’s, any Authorized User’s, or any other Person’s access to or use of all or any part of the Services or Welbilt Materials, without incurring any resulting obligation or liability, if: (a) Welbilt receives a judicial or other governmental demand or order, subpoena, or law enforcement request that expressly or by reasonable implication requires Welbilt to do so; or (b) Welbilt believes, in its reasonable discretion, that: (i) Customer or any Authorized User has failed to comply with any material term of the Agreement or in any manner that does not comply with any material instruction or requirement of the Specifications; (ii) Customer or any Authorized User is, has been, or is likely to be involved in any fraudulent, misleading, or unlawful activities relating to or in connection with any of the Services; (iii) the Agreement expires or is otherwise terminated; (iv) Authorized User is determined to be a competitor of Welbilt or its Affiliates, or (v) a security breach has been detected or is imminent. If permitted by law and reasonably practicable, Welbilt shall endeavor to provide Customer with advance notice of the suspension or termination or, where immediate Services denial is required, notify Customer promptly of the denial. This Section 1.7 does not limit any of Welbilt’s other rights or remedies, whether at law, in equity, or under the Agreement.
                        </li>
                    </ol>
                </div>

                <h6>2.	Use Restrictions.</h6>

                <p>
                    Customer shall not, and shall not permit any other Person to, access or use the Services or Welbilt Materials except as expressly permitted by this Agreement and, in the case of Third-Party Materials, the applicable third-party license agreement. For purposes of clarity and without limiting the generality of the foregoing, Customer shall not, and shall ensure that all Authorized Users will not, except as this Agreement expressly permits:
                </p>

                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            2.1.	copy, modify, or create derivative works or improvements of or based upon the Services or Welbilt Materials;
                        </li>
                        <li>
                            2.2.	rent, lease, lend, sell, sublicense, assign, pledge, distribute, publish, transfer, or otherwise make available any Services or Welbilt Materials to any Person, including on or in connection with the internet or any time-sharing, service bureau, software as a service, cloud, or other technology or service;
                        </li>
                        <li>
                            2.3.	reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to the source code of the Services or Welbilt Materials, in whole or in part;
                        </li>
                        <li>
                            2.4.	bypass or breach any security device or protection used by the Services or Welbilt Materials or access or use the Services or Welbilt Materials other than by an Authorized User through the use of his or her own then valid Access Credentials;
                        </li>
                        <li>
                            2.5.	input, upload, transmit, or otherwise provide to or through the Services or Welbilt Systems, any information or materials that are unlawful or injurious, or contain, transmit, or activate any Harmful Code;
                        </li>
                        <li>
                            2.6.	damage, destroy, disrupt, disable, impair, interfere with, or otherwise impede or harm in any manner the Services, Welbilt Materials, or Welbilt’s provision of services to any third party, in whole or in part;
                        </li>
                        <li>
                            2.7.	remove, delete, alter, or obscure any trademarks, Specifications, Documentation, warranties, or disclaimers, or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from any Services or Welbilt Materials, including any copy thereof;
                        </li>
                        <li>
                            2.8.	access or use the Services or Welbilt Materials in any manner or for any purpose that infringes, misappropriates, or otherwise violates any Intellectual Property Right or other right of any third party, including by any unauthorized access to, misappropriation, use, alteration, destruction, or disclosure of the data of any other Welbilt customer, or that may be harmful to any third party, fraudulent or offensive, or that violates any applicable Law;
                        </li>
                        <li>
                            2.9.	access or use the Services or Welbilt Materials for purposes of competitive analysis of the Services or Welbilt Materials, the development, provision, or use of a competing software service or product or any other purpose that is to Welbilt’s detriment or commercial disadvantage;
                        </li>
                        <li>
                            2.10.	access or use the Services or Welbilt Materials to send spam or otherwise duplicative or unsolicited messages in violation of applicable Laws;
                        </li>
                        <li>
                            2.11.	access or use the Services or Welbilt Materials to send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or violative of third party privacy rights; or
                        </li>
                        <li>
                            2.12.	otherwise access or use the Services or Welbilt Materials beyond the scope of the authorization granted under the Agreement.
                        </li>
                    </ol>
                </div>

                <h6>3.	Customer Obligations.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            3.1.	Customer Systems and Cooperation. Customer shall at all times during the Term: (a) set up, maintain, and operate in good repair and in accordance with the Specifications all Customer Systems on or through which the Services are accessed or used; (b) provide Welbilt Personnel with such access to Customer’s premises and Customer Systems as is necessary for Welbilt to perform the Services in accordance with the Specifications; and (c) provide all cooperation and assistance as Welbilt may reasonably request to enable Welbilt to exercise its rights and perform its obligations under and in connection with the Agreement. Customer shall be responsible for maintaining the security and confidentiality of any Authorized User accounts, access codes, passwords, connectivity standards or protocols, necessary to enable you to obtain access to the Services, and for notifying us of assigned Authorized User accounts that need to be terminated.
                        </li>
                        <li>
                            3.2.	Effect of Customer Failure or Delay. Welbilt is not responsible or liable for any delay or failure of performance caused in whole or in part by Customer’s delay in performing, or failure to perform, any of its obligations under this Agreement (“Customer Failure”).
                        </li>
                        <li>
                            3.3.	Corrective Action and Notice. If Customer becomes aware of any actual or threatened activity prohibited by Section 2, Customer shall, and shall cause the Authorized Users to, immediately: (a) take all reasonable and lawful measures within their respective control that are necessary to stop the activity or threatened activity and to mitigate its effects, including, where applicable, by discontinuing and preventing any unauthorized access to the Services and Welbilt Materials, and requiring unauthorized users to permanently erase from their systems and destroy any data to which any such unauthorized user has gained unauthorized access; and (b) notify Welbilt of any such actual or threatened activity.
                        </li>
                    </ol>
                </div>


                <h6>4.	Data Security and Data Privacy.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            4.1.	Welbilt Systems and Security Obligations. Welbilt’s security standards are developed to ensure compliance with generally accepted industry practices for privacy and data security. Section 5 (Data Backup) of this Agreement remains unaffected by this Section 4.
                        </li>
                        <li>
                            4.2.	Customer Responsibilities. Customer has and will retain sole responsibility for: (a) all Customer Data, including its content and use; (b) all information, instructions, and materials provided to Welbilt by or on behalf of Customer or any Authorized User in connection with the Services; (c) all Customer Systems; (d) the security and use of Customer’s and its Authorized Users’ Access Credentials; and (e) all access to and use of the Services and Welbilt Materials directly or indirectly by or through the Customer Systems or its or the Authorized Users’ Access Credentials or any other means controlled by Customer or any Authorized User, with or without Customer’s knowledge or consent, including any: (i) information, instructions, or materials provided by any of them to the Services or Welbilt; and (ii) results obtained from, and all conclusions, decisions, actions, or fees based on, such access or use. Customer shall employ all physical, administrative, and technical controls, screening, and security procedures and other safeguards necessary to: (a) securely administer the distribution and use of all Access Credentials and protect against any unauthorized access to or use of the Services; and (b) control the content and use of Customer Data, including the uploading or other provision of Customer Data for Processing by the Services. Customer remains solely responsible for Customer Materials and acknowledges and agrees that Welbilt neither controls nor guarantees the accuracy, integrity, legality or quality of any Customer Materials.
                        </li>
                        <li>
                            4.3.	Access and Security. Customer shall employ all physical, administrative, and technical controls, screening, and security procedures and other safeguards necessary to: (a) securely administer the distribution and use of all Access Credentials and protect against any unauthorized access to or use of the Services; and (b) control the content and use of Customer Data, including the uploading or other provision of Customer Data for Processing by the Services.
                        </li>
                        <li>
                            4.4.	Data Privacy. Customer is considered as controller and Welbilt as processor in the context of the applicable data protection legislation for the processing of personal data in the framework of this Agreement and both parties shall comply with all applicable data protection legislation in relation to their obligations under this Agreement, including but not limited to, the GDPR. With regard to Welbilt’s processing of personal data (included in Customer Data) on Customer’s behalf in the meaning of the GDPR, the provisions of Welbilt KitchenConnect Data Processing Addendum shall apply to that processing. To that end, in case of any conflicts between the provisions of Welbilt KitchenConnect Data Processing Addendum and any other provisions of this Agreement, the provisions of Welbilt KitchenConnect Data Processing Addendum shall prevail.
                        </li>
                    </ol>
                </div>

                <h6>5.	Data Backup.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            5.1.	All Services, the Welbilt Systems, and related data are hosted by Cloud Host. Subject to the provisions of Section 5.2 below, the Welbilt Systems are programmed to perform routine data backups as set out in Cloud Host’s backup policy in effect from time to time (the “Backup Policy”). Welbilt will deliver to Customer its then most current backups of Customer Data as and when set forth in the Backup Policy. In the event of any loss, destruction, damage, or corruption of Customer Data caused by the Welbilt Systems or the Services, Welbilt will, as its sole obligation and liability and as Customer’s sole remedy, use commercially reasonable efforts to restore the affected Customer Data from Welbilt’s then most current backup of such Customer Data in accordance with the then current Backup Policy.
                        </li>
                        <li>
                            5.2.	Notwithstanding the provisions of Section 5.1, Customer is responsible for protecting and backing-up the Customer Data. THE SERVICES DO NOT REPLACE THE NEED FOR CUSTOMER TO MAINTAIN REGULAR DATA BACKUPS OR REDUNDANT DATA ARCHIVES. WELBILT HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION, FAILURE TO RECOVER OR RECOVERY OF CUSTOMER DATA OR ANY LOSS OR DISRUPTION CAUSED BY CUSTOMER’S FAILURE TO PROPERLY BACK UP CUSTOMER DATA ON A PERIODIC BASIS.
                        </li>
                    </ol>
                </div>

                <h6>6.Fees and Payment.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            6.1.	Fees. Unless the Subscription Plan selected by the Customer provides for free access to the Services or contains a period for which the use of the Services are free of charge, Welbilt will calculate and charge to Customer fees for the use of the Services as described on the KitchenConnect Website (the “Fees”) using the payment methods we support. The Fees will be invoiced in accordance with the selected Subscription Plan.
                        </li>
                        <li>
                            6.2.	Taxes. All Fees and other amounts payable by Customer under the Agreement are exclusive of taxes, levies, duties, and similar assessments. Customer is responsible for all taxes, levies, duties, and similar assessments, including all value-added, sales, use, and excise taxes, and any other similar taxes of any kind imposed by any governmental or regulatory authority.
                        </li>
                        <li>
                            6.3.	Payment. Customer shall pay all Fees within thirty (30) days after the date of the invoice therefor. Customer shall make all payments hereunder in the currency specified in the invoice. Welbilt uses a third party service provider as a payment intermediary service to process credit and debit card transactions (the “Payment Processor”). Customer hereby consents to and authorizes Welbilt’s designated Payment Processor to charge Customer’s specified credit card, debit card or other payment method for such Fees. Each time Customer submits a transaction through the Payment Processor, Customer also agrees to be bound by Payment Processor’s agreements or policies in place at that time and applicable to third parties utilizing its services to facilitate payments. Welbilt does not request or store any of Customer’s financial information, such as bank routing or account numbers, or debit or credit card account numbers. Customer expressly understands and agrees that Welbilt will not be liable for any payments and monetary transactions that occur through Customer’s use of the Payment Processor’s network. Customer is responsible for all transactions (one-time, recurring, and refunds) processed through the Payment Processor.
                        </li>
                        <li>
                            6.4.	Late Payment. If Customer fails to make any payment when due then, in addition to all other remedies that may be available, Welbilt may charge interest on the past due amount at the rate of 1.5% per month calculated daily and compounded monthly or, if lower, the highest rate permitted under applicable Law. If such failure continue for thirty (30) days following written notice thereof, Welbilt may suspend performance of the Services until all past due amounts and interest thereon have been paid, without incurring any obligation or liability to Customer or any other Person by reason of such suspension. Customer shall reimburse Welbilt for all reasonable costs incurred by Welbilt in collecting any late payments or interest, including attorneys’ fees, court costs, and collection agency fees.
                        </li>
                        <li>
                            6.5.	No Deductions or Setoffs. All amounts payable to Welbilt under the Agreement shall be paid by Customer to Welbilt in full without any setoff, recoupment, counterclaim, deduction, debit, or withholding for any reason, other than any deduction or withholding of tax as may be required by applicable Law.  Except as required by Law, all fees paid are non-refundable.
                        </li>
                        <li>
                            6.6.	Fee Increases. Welbilt may increase Fees or convert a free of charge Subscription Plan for Services to a payable Service, by providing at least thirty (30) days written notice to the Customer. The new Fees shall be applicable at the beginning of the next Renewal Term.

                        </li>
                    </ol>
                </div>

                <h6>7.	Intellectual Property Rights.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            7.1.	Welbilt Materials. All right, title, and interest in and to the Services, the Welbilt Materials, including all Intellectual Property Rights therein, are expressly reserved by and will remain with Welbilt and, with respect to Third-Party Materials, the applicable third-party providers own all right, title, and interest, including all Intellectual Property Rights, in and to the Third-Party Materials. Nothing in this Agreement grants Customer any right, license, interest or authorization in or to (including any license under) any of the Welbilt Materials or Third-Party Materials, or any Intellectual Property Rights therein or relating thereto, whether expressly, by implication, estoppel, or otherwise, except as expressly set forth in Sections 1.1 and 1.2 or the applicable third-party license, in each case subject to section 2. All other rights in and to the Welbilt Materials are expressly reserved by Welbilt. In furtherance of the foregoing, Customer hereby (in advance) unconditionally and irrevocably assigns and transfers to Welbilt all right, title, and interest in and to the Resultant Data, including all Intellectual Property Rights relating thereto. Welbilt hereby accepts such assignment and transfer.
                        </li>
                        <li>
                            7.2.	Customer Materials. As between Customer and Welbilt, Customer is and will remain the sole and exclusive owner of all right, title, and interest in and to all Customer Materials, including all Intellectual Property Rights relating thereto, subject to the rights and permissions granted in Section 7.3.
                        </li>
                        <h6>7.3.	Grant of Rights.</h6>
                        <div className="termsCondtionsOlTable">
                            <ol>
                                <li>
                                    7.3.1.	Customer hereby irrevocably grants Welbilt all rights and permissions in or relating to Customer Materials as are necessary or useful to Welbilt, its Subcontractors, and the Welbilt Personnel to provide the Services and to enforce the Agreement and exercise Welbilt’s, its Subcontractors’, and the Welbilt Personnel’s rights, to perform Welbilt’s, its Subcontractors’, and the Welbilt Personnel’s obligations hereunder, and to provide any other warranty, maintenance, repair, or related services to Customer.
                                </li>
                                <li>
                                    7.3.2.	Customer agrees that in connection with the Services, Welbilt has the right to collect any and all Generated Data. Customer hereby forever grants to Welbilt and its successors and assigns an irrevocable, unconditional, perpetual, royalty-fee, worldwide, non-exclusive, right and license to use, disclose copy, perform, create derivative works of, modify, and otherwise Process the Generated Data and other Customer Data delivered to Welbilt in connection with the Services or otherwise delivered under the Agreement for Welbilt’s and its Affiliates’ business purposes, including creating Resultant Data based, in whole or in part, upon the Generated Data and/or such Customer Data (the “Data License”). Included within the Data License is the right for Welbilt to copy, use, and perform, create derivative works, and otherwise modify the Generated Data and other Customer Data freely in any manner whatsoever, both for Welbilt’s internal business, for the business of others, and for Processing all such Generated Data and other Customer Data to create additional Resultant Data based, in whole or in part, upon the Generated Data and other Customer Data. For clarity, Welbilt also has the right to incorporate any such Resultant Data into Welbilt’s analytics, consulting methodologies, software applications, and other Welbilt Materials for the purpose of all commercial use, sale, and sub-licensing for profit. To accomplish each of the foregoing, Welbilt shall have the right to engage third parties and grant sub-licenses to carry out any activity authorized under the Data License. Notwithstanding anything to the contrary in this Agreement, Welbilt may also retain Customer Data that is subject to the Data License. The confidentiality obligations under this Agreement, however, will survive each such occurrence.
                                </li>
                            </ol>
                        </div>
                    </ol>
                </div>

                <h6>8.	Representations and Warranties.</h6>

                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            8.1.	Mutual Representations and Warranties. Each party represents and warrants to the other party that:
                        </li>
                        <div className="termsCondtionsOlTable">
                            <ol>
                                <li>
                                    8.1.1.	it is duly organized, validly existing, and in good standing as a corporation or other entity under the Laws of the jurisdiction of its incorporation or other organization;
                                </li>
                                <li>
                                    8.1.2.	it has the full right, power, and authority to enter into and perform its obligations and grant the rights, licenses, consents, and authorizations it grants or is required to grant under the Agreement;
                                </li>
                                <li>
                                    8.1.3.	the acceptance and delivery of the Agreement by its representative, who has accepted this Agreement on its behalf, has been duly authorized by all necessary corporate or organizational action of such party;
                                </li>
                                <li>
                                    8.1.4.	when accepted by Customer’s representative, the Agreement will constitute the legal, valid, and binding obligation of such party, enforceable against such party in accordance with its terms;
                                </li>
                                <li>
                                    8.1.5.	it is not located in, under the control of, or a national or resident of an Embargoed Country or Designated National (as each term is defined below); and
                                </li><li>
                                    8.1.6.	it is not a consumer under the applicable Law and not subject to any consumer protection Laws when entering into the Agreement and using the Services.
                                </li>
                            </ol>
                        </div>

                        <li>
                            8.2.	Additional Welbilt Representations and Warranties. Welbilt represents and warrants to Customer that Welbilt will perform the Services using personnel of skill, experience, and qualifications and in a professional and workmanlike manner according to generally recognized industry standards for similar services to meet its obligations under the Agreement.
                        </li>
                        <li>
                            8.3.	Additional Customer Representations and Warranties. Customer represents and warrants to Welbilt that:
                        </li>
                        <div className="termsCondtionsOlTable">
                            <ol>
                                <li>
                                    8.3.1.	Customer’s entering into this Agreement does not and will not conflict with or violate any other agreement Customer may have with any third party or any applicable Laws;
                                </li>
                                <li>
                                    8.3.2.	All Customer Data provided hereunder or processed via the Services is obtained and managed by Customer in compliance with the applicable Laws, including but not limited to any Privacy Laws;
                                </li>
                                <li>
                                    8.3.3.	Customer owns or otherwise has and will have the necessary rights and consents in and relating to the Customer Materials so that, as received, used, or Processed by Welbilt in accordance with the Agreement, they do not and will not infringe, misappropriate, or otherwise violate any Intellectual Property Rights, or any privacy or other rights of any third party or violate any applicable Law; and
                                </li>
                                <li>
                                    8.3.4.	Customer has all rights and licenses necessary to grant Welbilt and its Affiliates and their respective Representatives access to any integrated or linked Customer Systems and the applicable data stored thereon, and the Processing of such data in accordance with this Agreement does not and will not infringe, misappropriate, or otherwise violate any Intellectual Property Rights, or any privacy or other rights of any third party or violate any applicable Law.
                                </li>
                            </ol>
                        </div>
                        <li>
                            8.4.	DISCLAIMER OF WARRANTIES. EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN SECTION 8.1 AND SECTION 8.2, ALL SERVICES AND WELBILT MATERIALS ARE PROVIDED “AS IS” AND “WITH ALL FAULTS”. WELBILT SPECIFICALLY DISCLAIMS ALL OTHER REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, WELBILT MAKES NO WARRANTY OF ANY KIND THAT THE SERVICES OR WELBILT MATERIALS, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER’S OR ANY OTHER PERSON’S REQUIREMENTS, OPERATE ERROR FREE OR WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, OR FREE OF HARMFUL CODE. ALL THIRD-PARTY MATERIALS ARE PROVIDED “AS IS” AND ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY BETWEEN CUSTOMER AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY MATERIALS.
                        </li>
                        <li>
                            8.5.	INTERPRETATION OF THE SERVICES. THE WELBILT MATERIALS AND THIRD PARTY MATERIALS ARE PROVIDED SOLELY AS AN EDUCATIONAL TOOL TO ASSIST CUSTOMER’S UNDERSTANDING AND MANAGEMENT OF CUSTOMER’S FOODSERVICE BUSINESS. THE INFORMATION AND REPORTS THAT CAN BE PROVIDED USING THE WELBILT MATERIALS AND THIRD PARTY-MATERIALS THAT ARE AVAILABLE THROUGH THE SERVICES ARE NOT INTENDED TO BE, AND ARE IN NO WAY, IN SUBSTITUTE OF CUSTOMER’S PROFESSIONAL EXPERIENCE OR JUDGMENT. CUSTOMER IS SOLELY AND EXCLUSIVELY RESPONSIBLE FOR THE INTERPRETATION, USE, AND APPLICATION OF THE SERVICES, ALL OTHER ASPECTS OF THE WELBILT MATERIALS AND ALL THIRD PARTY-MATERIALS, AND ALL INFORMATION DERIVED THEREFROM, IN THE MANAGEMENT AND OPERATION OF CUSTOMER’S FOODSERVICE BUSINESS. WELBILT DOES NOT RECOMMEND OR ENDORSE ANY SPECIFIC PRODUCTS, METHODOLOGIES, PRACTICES, OPINIONS, CONSULTANTS, OR THIRD PARTY-MATERIALS.
                        </li>
                    </ol>
                </div>

                <h6>9.	Indemnification.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            9.1.	Welbilt Indemnification. Welbilt shall indemnify, defend, and hold harmless Customer from and against any and all Losses incurred by Customer resulting from any Action by a third party (other than an Affiliate or Representative of Customer) to the extent that such Action alleges that Customer’s or an Authorized User’s use of the Services (excluding Customer Data and Third-Party Materials) in accordance with the Agreement and the Specifications infringes or misappropriates such third party’s Intellectual Property Rights in the Territory. The foregoing obligation does not apply to the extent that the Action alleging infringement or misappropriation arises from:
                        </li>
                        <div className="termsCondtionsOlTable">
                            <ol>
                                <li>
                                    9.1.1.	access to or use of the Third-Party Materials, Customer Materials, or Welbilt Materials in combination with any hardware, system, software, network, or other materials or services not provided by Welbilt or specified for Customer’s use in the Documentation, unless otherwise expressly permitted by Welbilt in writing;
                                </li>
                                <li>
                                    9.1.2.	modification of the Welbilt Materials other than by Welbilt or its Representatives or with Welbilt’s written approval;
                                </li>
                                <li>
                                    9.1.3.	failure to timely implement any modifications, upgrades, replacements, or enhancements made available to Customer by or on behalf of Welbilt; or
                                </li>
                                <li>
                                    9.1.4.	any act, omission, or other matter described in Section 9.2, whether or not the same results in any Action against, or Losses by, any Welbilt Indemnitee (as defined below).
                                </li>
                            </ol>
                        </div>
                        <li>
                            9.2.	Customer Indemnification. Customer shall indemnify, defend, and hold harmless Welbilt and its Subcontractors and Affiliates, and each of its and their respective officers, directors, employees, agents, successors, and assigns (each, a “Welbilt Indemnitee”) from and against any and all Losses incurred by such Welbilt Indemnitee resulting from any Action by a third party (other than an Affiliate of a Welbilt Indemnitee, but, as the case may be, including Authorized Users) to the extent that such Losses arise out of or result from, or are alleged to arise out of or result from:
                        </li>
                        <div className="termsCondtionsOlTable">
                            <ol>
                                <li>
                                    9.2.1.	Customer Materials, including any use or Processing of Customer Materials by or on behalf of Welbilt, its Affiliates, or its or their respective Representatives in accordance with the Agreement;
                                </li>
                                <li>
                                    9.2.2.	any other materials or information (including any documents, data, specifications, software, content, or technology) provided by or on behalf of Customer or any Authorized User, including Welbilt’s compliance with any specifications or directions provided by or on behalf of Customer or any Authorized User to the extent prepared without any contribution by Welbilt;
                                </li>
                                <li>
                                    9.2.3.	Customer’s breach of any of its representations, warranties, covenants, or obligations under the Agreement;
                                </li>
                                <li>
                                    9.2.4.	any allegation of facts that, if true, would constitute Customer’s breach of any of its representations, warranties, covenants, or obligations under the Agreement; or
                                </li>
                                <li>
                                    9.2.5.	Customer’s or an Authorized User’s use, application, or interpretation of the Services or the Welbilt Materials in Customer’s foodservice business; or
                                </li>
                                <li>
                                    9.2.6.	the gross negligence or willful misconduct of Customer, its Affiliates, or any of its or their respective Representatives.
                                </li>
                            </ol>
                        </div>
                        <li>
                            9.3.	Indemnification Procedure. Each party shall promptly notify the other party in writing of any Action for which such party believes it is entitled to be indemnified pursuant to Section 9.1 or Section 9.2, as the case may be. The party seeking indemnification (the “Indemnitee”) shall cooperate with the other party (the “Indemnitor”) at the Indemnitor’s sole cost and expense. Subject to this Section 9.3, the Indemnitor shall promptly assume control of the defense to handle and defend the same. The Indemnitee may participate in and observe the proceedings at its own cost and expense with counsel of its own choosing. Except as permitted under Section 9.4, the Indemnitor shall not settle any Action on terms or in any manner that adversely affects the rights of any Indemnitee without the Indemnitee’s prior written consent, which shall not be unreasonably withheld or delayed. Notwithstanding the foregoing, if the Indemnitor fails or refuses to assume control of the defense of such Action, the Indemnitee shall have the right, but no obligation, to defend against such Action, including settling such Action after giving notice to the Indemnitor, in each case in such manner and on such terms as the Indemnitee may deem appropriate. The Indemnitee’s failure to perform any obligations under this Section 9.3 will not relieve the Indemnitor of its obligations under this Section 9, except to the extent that the Indemnitor can demonstrate that it has been materially prejudiced as a result of such failure.
                        </li>
                        <li>
                            9.4.	Mitigation. If any of the Services or Welbilt Materials are, or in Welbilt’s opinion are likely to be, claimed to infringe, misappropriate, or otherwise violate any third-party Intellectual Property Right (other than any Action alleging infringement or misappropriation that arises from any act, omission, or other matter described in Sections 9.1.1 through 9.1.4),, or if Customer’s or any Authorized User’s use of the Services or Welbilt Materials is enjoined or threatened to be enjoined, Welbilt may, at its option and sole cost and expense:

                        </li>
                        <div className="termsCondtionsOlTable">
                            <ol>
                                <li>
                                    9.4.1.	obtain the right for Customer to continue to use the Services and Welbilt Materials substantially as contemplated by the Agreement;
                                </li>
                                <li>
                                    9.4.2.	modify or replace the Services and Welbilt Materials, in whole or in part, to seek to make the Services and Welbilt Materials (as so modified or replaced) non-infringing, while providing substantially equivalent features and functionality, in which case such modifications or replacements will constitute Services and Welbilt Materials, as applicable, under the Agreement; or
                                </li>
                                <li>
                                    9.4.3.	in the event that none of the above options are available under commercially reasonable conditions, by written notice to Customer, terminate the Agreement with respect to all or part of the Services and Welbilt Materials, and require Customer to immediately cease any use of the Services and Welbilt Materials or any specified part or feature thereof.
                                </li>
                            </ol>
                        </div>
                        <li>
                            9.5.	Sole Remedy. THIS SECTION  9 SETS FORTH CUSTOMER’S SOLE AND EXCLUSIVE REMEDIES AND WELBILT’S SOLE AND EXCLUSIVE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE SERVICES, WELBILT MATERIALS, OR ANY SUBJECT MATTER OF THE AGREEMENT INFRINGE, MISAPPROPRIATE, OR OTHERWISE VIOLATE ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY.
                        </li>
                    </ol>
                </div>
                <h6>10.	Limitations of Liability.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            10.1.	EXCLUSION OF DAMAGES. IN NO EVENT WILL WELBILT, ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS, SERVICE PROVIDERS, OR SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH THE AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (A) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE, OR PROFIT OR DIMINUTION IN VALUE; (B) IMPAIRMENT, INABILITY TO USE OR LOSS, INTERRUPTION, OR DELAY OF THE SERVICES; (C) LOSS, DAMAGE, CORRUPTION, OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY; (D) COST OF REPLACEMENT GOODS OR SERVICES; (E) LOSS OF GOODWILL OR REPUTATION; OR (F) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
                        </li>
                        <li>
                            10.2.	CAP ON MONETARY LIABILITY. EXCEPT FOR WELBILT’S LIABILITY UNDER SECTION 9, SUBJECT TO WELBILT’S EXCLUSION OF LIABILITY IN SECTION 10.3, IN NO EVENT WILL THE COLLECTIVE AGGREGATE LIABILITY OF WELBILT, ITS AFFILIATES, AND ITS AND THEIR RESPECTIVE LICENSORS, SERVICE PROVIDERS, AND SUPPLIERS ARISING OUT OF OR RELATED TO THE AGREEMENT, WHETHER ARISING UNDER OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY, EXCEED THE TOTAL AMOUNTS PAID BY CUSTOMER TO WELBILT UNDER THE AGREEMENT IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE LIABILITY. THE FOREGOING LIMITATIONS APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                        </li>
                        <li>
                            10.3.	ASSUMPTION OF RISK. CUSTOMER ACKNOWLEDGES AND AGREES THAT CUSTOMER’S USE OF THE SERVICES AND OTHER WELBILT MATERIALS REQUIRES CUSTOMER’S EXPERIENCE AND JUDGEMENT IN INTERPRETING, USING, OR APPLYING THE SERVICES AND WELBILT MATERIALS IN CUSTOMER’S BUSINESS. CUSTOMER ASSUMES ALL RISK RELATED TO ANY AND ALL OF THE FOREGOING. NOTWITHSTANDING THE PROVISIONS OF SECTION 10.2 OR ANY OTHER PROVISION OF THE AGREEMENT, WELBILT SHALL HAVE NO LIABILITY WHATSOEVER FOR ANY DAMAGE OF ANY KIND, WHETHER PERSONAL INJURY, PROPERTY DAMAGE, DAMAGE TO INVENTORY, EXPIRATION OR PERISHING OF FOODSTUFF OR INGREDIENTS, OR ANY OTHER INJURY OR HARM, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, ARISING IN WHOLE OR IN PART FROM THE ACCURACY, AVAILABILITY, OR TIMELINESS OF THE SERVICES OR ANY OTHER WELBILT MATERIALS, OR FROM CUSTOMER’S USE, INTERPRETATION, OR APPLICATION THEREOF.
                        </li>
                        <li>
                            10.4.	ACKNOWLEDGMENTS. EACH PARTY IS A SOPHISTICATED PERSON AND ACKNOWLEDGES AND AGREES THAT THE ALLOCATION OF RISK IN THE AGREEMENT IS REFLECTED IN THE FEES AND EXPENSES CHARGED UNDER THE AGREEMENT, AND THAT HIGHER CHARGES WOULD BE MADE BUT FOR THE LIMITATIONS OF LIABILITY SET FORTH IN THE AGREEMENT, AND THAT THE ALLOCATION OF RISK UNDER THE AGREEMENT IS REASONABLE AND APPROPRIATE UNDER THE CIRCUMSTANCES.
                        </li>
                        <li>
                            10.5.	Limitation on Disclaimers and Liability Exclusions. Certain states and/or jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental, consequential or certain other damages. The disclaimers, limitations of liability, and exclusions set forth herein will not apply to the extent prohibited by applicable Law. See Section 13.15 for certain local Law requirements.
                        </li>
                    </ol>
                </div>

                <h6>11.	Confidentiality.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            11.1.	Confidential Information. In connection with this Agreement, each party (as the “Disclosing Party”) may disclose Confidential Information to the other party (as the “Receiving Party”). Subject to Section 11.2, “Confidential Information” means information in any form or medium that the Disclosing Party considers confidential or proprietary, including information consisting of or relating to the Disclosing Party’s technology, databases (including any data models, structures, non-Customer specific data and aggregated statistical data contained therein), trade secrets, know-how, business operations, plans, strategies, customers, and pricing, in each case whether or not marked, designated, or otherwise identified as ‘confidential’. For clarity, all Welbilt Materials and the financial terms and existence of this Agreement are the Confidential Information of Welbilt.
                        </li>
                        <li>
                            11.2.	Exclusions. Confidential Information does not include information that the Receiving Party can demonstrate by written records: (a) was rightfully known to the Receiving Party without restriction on use or disclosure prior to such information being disclosed by the Disclosing Party; (b) was or becomes generally known by the public other than by the Receiving Party’s or any of its Representatives’ noncompliance with this Agreement or violation of other Law; (c) was or is received by the Receiving Party on a non-confidential basis from a third party that was not or is not, at the time of such receipt, under any obligation to maintain its confidentiality; or (d) was or is independently developed by the Receiving Party without reference to or use of any of the Disclosing Party’s Confidential Information.
                        </li>
                        <li>
                            11.3.	Protection of Confidential Information. As a condition to being provided with any disclosure of or access to Confidential Information, the Receiving Party shall safeguard the Confidential Information from unauthorized use, access, and disclosure, and will not, and will not permit its Representatives to, use or disclose Confidential Information other than as (a) necessary to exercise its rights or perform its obligations under and in accordance with this Agreement, and (b) as otherwise expressly provided under this Agreement. Except as may be permitted under this Agreement, the Receiving Party will not permit access to Confidential Information other than to its Representatives on a need-to-know basis. The Receiving Party will inform each such Representative of the confidential nature of the Confidential Information and the Receiving Party’s obligations under this Section 11. The Receiving Party will ensure its Representatives’ compliance with, and be responsible and liable for any of its Representatives’ non-compliance with, the terms of this Section 11.
                        </li>
                        <li>
                            11.4.	Duration of Obligations. Notwithstanding any other provisions of this Agreement, the Receiving Party’s obligations under this Section 11 with respect to any Confidential Information that constitutes a trade secret under any applicable Law will continue until such time, if ever, as such Confidential Information ceases to qualify for trade secret protection under one or more such applicable Laws other than as a result of any act or omission of the Receiving Party or any of its Representatives.
                        </li>
                        <li>
                            11.5.	Compelled Disclosures. If the Receiving Party is compelled by applicable Law or a valid court order to disclose any Confidential Information then, to the extent permitted by applicable Law, the Receiving Party shall first give prompt written notice to the Disclosing Party and cooperate with the Disclosing Party in any attempt to challenge such subpoena, or to obtain a protective order restricting or limiting such disclosure. If the disclosure is compelled, Receiving Party shall disclose only the minimum necessary portion of Disclosing Party’s Confidential Information and shall use all available means (e.g., disclosure under a protective order) to ensure that such Confidential Information does not become publicly available.
                        </li>
                    </ol>
                </div>

                <h6>12.	Term and Termination.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            12.1.	Term. This Agreement commences on the date on which you accepted the terms of this Agreement via the Welbilt’s KitchenConnect Platform (the “Effective Date”) and continues for the period that you have selected in your Subscription Plan unless terminated earlier in accordance with this Agreement (the “Initial Term”). Upon the expiration of the Initial Term, this Agreement will continue for successive renewal periods in accordance with the selected Subscription Plan (each a “Renewal Term” and, collectively, together with the Initial Term, the “Term”), unless earlier terminated pursuant to this Agreement’s express provisions or unless either party gives the other party written notice of non-renewal at least thirty (30) days prior to the expiration of the Initial Term or then-current Renewal Term.
                        </li>
                        <li>
                            12.2.	Termination. In addition to any other express termination right set forth elsewhere in this Agreement, either party may terminate this Agreement:
                        </li>
                        <div className="termsCondtionsOlTable">
                            <ol>
                                <li>
                                    12.2.1.	effective on written notice to the other party, if the other party materially breaches this Agreement, and such breach: (i) is incapable of cure; or (ii) being capable of cure, remains uncured for thirty (30) days after the non-breaching party provides the breaching party with a detailed written notice of such breach and such termination shall be in addition to any other remedies available at law or in equity; and
                                </li>
                                <li>
                                    12.2.2.	immediately if the other party becomes insolvent, fails to pay its debts or perform its obligations in the ordinary course of business as they mature, admits in writing its insolvency or its inability to pay debts or perform obligation as they mature, makes an assignment for the benefit of creditors, ceases to conduct business in the ordinary course.
                                </li>
                            </ol>
                        </div>
                        <li>
                            12.3.	Effect of Termination or Expiration. Upon any expiration or termination of this Agreement, except as expressly otherwise provided in this Agreement:
                        </li>
                        <div className="termsCondtionsOlTable">
                            <ol>
                                <li>
                                    12.3.1.	all rights, licenses, consents, and authorizations granted by either party to the other hereunder other than the Data License and any other license which expressly or impliedly survives termination will immediately terminate;
                                </li>
                                <li>
                                    12.3.2.	at the request of Customer within three (3) months following the termination or expiration of this Agreement and at Customer’s reasonable expense, Welbilt shall deliver to Customer, or to a party designated in writing by Customer, an up-to-date copy of all Customer Data in a format to be determined by Welbilt; Section 4.4 of Welbilt KitchenConnect Data Processing Addendum shall remain unaffected hereby; After such three (3) month period, and provided that you have not made any such request for return of Customer Data, Welbilt shall have no obligation to maintain or provide any Customer Data and, unless legally prohibited, may thereafter delete Customer Data from our systems;
                                </li>
                                <li>
                                    12.3.3.	each Receiving Party will promptly cease all use of the Confidential Information of the Disclosing Party;
                                </li>
                                <li>
                                    12.3.4.	notwithstanding anything to the contrary in this Agreement, with respect to information and materials then in its possession or control: (i) Welbilt may retain Customer Data indefinitely; and (ii) Customer may retain Documentation and Specifications for as long as the equipment (that has been Equipment in the meaning of this Agreement prior to its disconnection from the Services) is in use in Customer’s business. Welbilt may, but is not obligated to, also retain Customer Data in its backups, archives, and disaster recovery systems until such Customer Data is deleted in the ordinary course.
                                </li>
                                <li>
                                    12.3.5.	Customer shall immediately cease all use of the Services and Welbilt Materials, and Welbilt may disable all Customer and Authorized User access to the Services or the Welbilt Materials, in whole or in part; and
                                </li>
                                <li>
                                    12.3.6.	if Welbilt terminates this Agreement pursuant to Section 12.2.1 or Section 12.2.2, all Fees that would have become payable had this Agreement remained in effect until expiration of the Term will become immediately due and payable.
                                </li>
                            </ol>
                        </div>
                        <li>
                            12.4.	Continuing Rights. After the cancellation, termination, or expiration of this Agreement for any reason, and for the full lifecycle of the equipment that has been Equipment in the meaning of this Agreement prior to its disconnection from the Services, and without prejudice to the Data Licence, in addition to any other continuing rights granted to Welbilt, Welbilt may continue Processing the Generated Data for the purpose of producing Resultant Data and for rendering warranty, maintenance, repair, or related services to Customer.
                        </li>
                    </ol>
                </div>
                <h6>13.	Miscellaneous</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            13.1.	Audit. Customer acknowledges and agrees that Welbilt reserves the right to monitor and audit the access to and use of the Welbilt Materials and the Equipment by Customer and the Authorized Users, whether such monitoring or auditing is performed through embedded technical functionality or by other means.
                        </li>
                        <li>
                            13.2.	Export Control. Customer shall comply with all applicable export laws, restrictions, and regulations of the United States of America as well as those required under the Applicable Law or any applicable Law or from any foreign agency under which authority the Services may fall. Customer agrees to defend Welbilt against, and indemnify and hold Welbilt harmless from any Actions arising out of or related to Customer’s breach of this Section 13.2..
                        </li>
                        <li>
                            13.3.	Anti-Slavery. Each party undertakes, warrants and represents that:
                            <div className="termsCondtionsOlTable">
                                <ol>
                                    <li>
                                        13.3.1.	neither party nor any of its officers, employees, agents or Subcontractors has (a) committed an offence under the Modern Slavery Act 2015 (an MSA Offence); or (b) been notified that it is subject to an investigation relating to an alleged MSA Offence or prosecution under MSA 2015; or (c) is aware if any circumstances within its supply chain that could give rise to an investigation relating to an alleged MSA Offence or prosecution under MSA 2015;
                                    </li>
                                    <li>
                                        13.3.2.	it shall comply with the Modern Slavery Act 2015;
                                    </li>
                                    <li>
                                        13.3.3.	it shall notify the other party immediately in writing if it becomes aware or has reason to believe that it, or any of its officers, employees, agents or Subcontractors have breached or potentially breached any of their obligations under this clause 13.3. Such notice shall set out full details of the circumstances concerning the breach or potential breach of such party’s obligations.
                                    </li>
                                </ol>
                            </div>
                        </li>

                        <li>
                            13.4.	Anti-Bribery. Customer shall comply with applicable anti bribery Laws, including ensuring that it has in place adequate procedures to prevent bribery and use all reasonable endeavors to ensure that all of its personnel, Subcontractors and all others associated with it involved in the performance of this Agreement so comply.
                        </li>
                        <li>
                            13.5.	Relationship of the Parties. The relationship between the parties is that of independent contractors. Nothing contained in the Agreement shall be construed as creating any agency, partnership, joint venture, or other form of joint enterprise, employment, or fiduciary relationship between the parties, and neither party shall have authority to contract for or bind the other party in any manner whatsoever.
                        </li>
                        <li>
                            13.6.	Surviving Terms. Any right or obligation of the parties in the Agreement that, by its nature, should survive termination or expiration of the Agreement, will survive any expiration or termination of the Agreement, including, without limitation, the provisions of Sections 2, 7, 9, 10 and 11.
                        </li>
                        <li>
                            13.7.	Amendment and Waiver. [reserved].
                        </li>
                        <li>
                            13.8.	Assignment. Customer shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its obligations or performance under the Agreement, in each case whether voluntarily, involuntarily, by operation of law, or otherwise, without Welbilt’s prior written consent. Any purported assignment in violation of this Section shall be void. No assignment, delegation, or transfer will relieve Customer of any of its obligations or performance under this Agreement. This Agreement is binding upon and inures to the benefit of the parties hereto and their respective successors and permitted assigns.
                        </li>
                        <li>
                            13.9.	Severability. Whenever possible, each provision of the Agreement shall be interpreted in such a manner as to be effective and valid under Applicable Law. However, if any provision of the Agreement or the application of any provision to any party or circumstance shall be prohibited by or invalid under such Law, such provision shall be reduced in scope to be enforceable if possible. Otherwise, such provision shall be severed from the Agreement without it invalidating the remainder of the provisions of the Agreement.
                        </li>
                        <li>
                            13.10.	Force Majeure. In no event will either party be liable or responsible to the other party, or be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement, except for any obligations to make payments, when and to the extent such failure or delay is caused by any circumstances beyond such party’s reasonable control (a “Force Majeure Event”), including but not limited to acts of God, flood, fire, earthquake or explosion, war, pandemic, supplier shortages, terrorism, invasion, riot or other civil unrest, embargoes or blockades in effect on or after the date of this Agreement, national or regional emergency, strikes, labor stoppages or slowdowns or other industrial disturbances, passage of Law or any action taken by a governmental or public authority, including imposing an embargo, export or import restriction, quota, or other restriction or prohibition or any complete or partial government shutdown, or national or regional shortage of adequate power or telecommunications or transportation. Either party may terminate this Agreement if a Force Majeure Event affecting the other party continues substantially uninterrupted for a period of thirty (30) days or more. Affected Party Obligations. In the event of any failure or delay caused by a Force Majeure Event, the affected party shall give prompt written notice to the other party stating the period of time the occurrence is expected to continue and use diligent efforts to end the failure or delay and minimize the effects of such Force Majeure Event.
                        </li>
                        <li>
                            13.11.	Third Party Rights. Except as specified in this Agreement, this Agreement does not create, confer or purport to confer any benefit or right enforceable by any Person not a party to it except that a Person who is a permitted successor to or assignee of the rights of a party to this Agreement is deemed to be a party to this Agreement.
                        </li>
                        <li>
                            13.12.	Territory, Contracting Entity; Governing Law; Notices and Submission to Jurisdiction.
                        </li>
                        <div className="termsCondtionsTable">
                            <ul className="tHead">
                                <li>Territory - If Customer is domiciled in:</li>
                                <li>Welbilt contracting entity</li>
                                <li>Applicable Law</li>
                                <li>Notices to Welbilt</li>
                                <li>Exclusive jurisdiction of courts in:</li>
                            </ul>
                            <ul className="tBody">
                                <li>The United States of America, Mexico or a country in Central or South America or the Caribbean (excluding Cuba and Venezuela)</li>
                                <li>Cleveland Ranges LLC</li>
                                <li>Delaware Law</li>
                                <li>2227 Welbilt Blvd.
                                    New Port Richey, Fl
                                    34655, USA
                                </li>
                                <li>Florida, Pasco County </li>
                            </ul>
                            <ul className="tBody">
                                <li>Canada</li>
                                <li>Garland Commercial Ranges Limited</li>
                                <li>Ontario and controlling Canadian
                                    federal law.
                                </li>
                                <li>1177 Kamato Road
                                    Mississauga, Ontario
                                    L4W 1X4
                                </li>
                                <li>Ontario, Mississauga </li>
                            </ul>
                            <ul className="tBody">
                                <li>United Kingdom</li>
                                <li>Welbilt UK Limited</li>
                                <li>English Law</li>
                                <li>St Ann’s Wharf 112 quayside Newcastle Upon Tyne NE13DX</li>
                                <li>England </li>
                            </ul>
                            <ul className="tBody">
                                <li>Germany</li>
                                <li>Welbilt Deutschland GmbH

                                </li>
                                <li>German Law</li>
                                <li>Talstraße 35, 82436 Eglfing, Germany</li>
                                <li>Germany, Munich </li>
                            </ul>
                            <ul className="tBody">
                                <li>A Country in the EEA (excluding Germany) </li>
                                <li>Welbilt Deutschland GmbH </li>
                                <li>Dutch Law </li>
                                <li>Talstraße 35, 82436 Eglfing, Germany</li>
                                <li>Courts of Amsterdam, The Netherlands</li>
                            </ul>
                            <ul className="tBody">
                                <li>In Europe (excluding EEA and UK), the Middle East or
                                    Africa (excluding Iran and Syria)
                                </li>
                                <li>Welbilt Deutschland GmbH </li>
                                <li>English Law</li>
                                <li>St Ann’s Wharf 112 quayside Newcastle Upon Tyne NE13DX</li>
                                <li>Germany, Munich</li>
                            </ul>
                        </div>
                        <li>
                            13.13.	Agreement to Applicable Law and Jurisdiction. Each party agrees to the applicable governing law (the “Applicable Law”) above without regard to choice or conflicts of law rules, and to the exclusive jurisdiction of the applicable courts above in respect of this Agreement and any non-contractual obligations arising out of or in connection with it.
                        </li>
                        <li>
                            13.14.	Notices. Except for Legal Notices: (a) Welbilt may provide Customer notice via email to Customer’s contact person on file, and (b) Customer may provide Welbilt notice to the following email address: digitalsupport@welbilt.com.  Such notice is deemed received on the first business day after posting or sending.  Legal Notices must be in writing, are deemed received only upon actual delivery (or refusal), and shall be sent (if to Customer): to the address set forth on in the registration form completed by the Customer when subscribing (or such address as Customer have updated in writing or via the KitchenConnect Platform); and (if to Welbilt): to Welbilt, attn.: General Counsel, 2227 Welbilt Blvd New Port Richey, Fl 34655, USA. “Legal Notices” means any notices alleging breach of the Agreement, or pertaining to an indemnifiable claim. Legal Notices will be deemed to have been served on the 5th day after the date of posting.
                        </li>
                        <li>
                            13.15.	Local Law Requirements:
                            <div className="termsCondtionsOlTable">
                                <ol>
                                    <li>
                                        13.15.1.	Canadian Law. Where this Agreement is governed by Canadian law, the following shall apply:
                                    </li>

                                    <div className="termsCondtionsOlTable">
                                        <ol>
                                            <li>13.15.1.1.	With respect to Section 4.4 of this Agreement, “GDPR” is replaced with “the Personal Information Protection and Electronic Documents Act and all substantially similar provincial privacy laws”, and “personal data” is replaced with “personal information”.
                                            </li>
                                            <li>13.15.1.2.	With respect to Section 13.4 of this Agreement, the term “anti bribery Laws” shall include without limitation the Criminal Code of Canada, RSC 1985, c C-46, and the Canadian Corruption of Foreign Public Officials Act, SC 1998, c 34.
                                            </li>
                                            <li>13.15.1.3.	With respect to Section14.22 of this Agreement, “personal data” is replaced with “personal information”.
                                            </li>
                                            <li>13.15.1.4.	With respect to Section 14.29 of this Agreement, “personal data” is replaced with “personal information”.
                                            </li>
                                            <li>13.15.1.5.	With respect to Section 14.30 of this Agreement, “personal data” is replaced with “personal information”.
                                            </li>
                                            <li>13.15.1.6.	With respect to Section 14.34 of this Agreement, “personal data” is replaced with “personal information”.
                                            </li>
                                            <li>13.15.1.7.	The parties hereto confirm that it is their wish that this Agreement, as well as all other documents related hereto, including Legal Notices, have been and shall be drawn up in the English language only. Les parties confirment leur desir que cet accord ainsi que tous les documents, y compris tous les avis qui sy rattachent, soient rediges en langue anglaise.
                                            </li>
                                        </ol>
                                    </div>

                                    <li>
                                        13.15.2.	English Law. Where this Agreement is governed by English law, the following shall apply:
                                        <div className="termsCondtionsOlTable">
                                            <ol>
                                                <li>
                                                    13.15.2.1.	Disclaimer of Warranties. In addition to Section 8.4, this Agreement constitutes the entire agreement between the parties and supersedes any prior agreement or arrangement in respect of its subject matter and:
                                                </li>
                                                <div className="termsCondtionsOlTable">
                                                    <ol>
                                                        <li>
                                                            13.15.2.1.1.	neither party has entered into this Agreement in reliance upon, and it will have no remedy in respect of, any misrepresentation, representation or statement (whether made by the other party or any other person and whether made to the first party or any other person) which is not expressly set out in this Agreement;
                                                        </li>
                                                        <li>
                                                            13.15.2.1.2.	the only remedies available for any misrepresentation or breach of any representation or statement which was made prior to entry into this Agreement and which is expressly set out in this Agreement will be for breach of contract; and
                                                        </li>
                                                        <li>
                                                            13.15.2.1.3.	nothing in this Section 13.15.2.1 will be interpreted or construed as limiting or excluding the liability of any person for fraud or fraudulent misrepresentation.
                                                        </li>
                                                    </ol>
                                                </div>
                                                <li>
                                                    13.15.2.2.	Limitations of Liability. Nothing in this Agreement will operate to exclude or restrict any liability of a party:
                                                    <div className="termsCondtionsOlTable">
                                                        <ol>
                                                            <li>
                                                                13.15.2.2.1.	that cannot be excluded or restricted in this Agreement in respect of death or personal injury resulting from negligence by operation of Section 2(1) Unfair Contract Terms Act 1977;
                                                            </li>
                                                            <li>
                                                                13.15.2.2.2.	for its fraud or fraudulent misrepresentation or fraud or fraudulent misrepresentation by a person for whom it is vicariously liable;
                                                            </li>
                                                            <li>
                                                                13.15.2.2.3.	for breach of its obligations arising under section 12 Sale of Goods Act 1979;
                                                            </li>
                                                            <li>
                                                                13.15.2.2.4.	for breach of its obligations arising under section 2 Supply of Goods and Services Act 1982;
                                                            </li>
                                                            <li>
                                                                13.15.2.2.5.	for breach of its obligations arising under Section 8 Supply of Goods (Implied Terms) Act 1973;
                                                            </li>
                                                            <li>
                                                                13.15.2.2.6.	arising under Part 1 of the Consumer Protection Act 1987; or
                                                            </li>
                                                            <li>
                                                                13.15.2.2.7.	for any matter for which it is not permitted by law to exclude or limit, or to attempt to exclude or limit, its liability.
                                                            </li>
                                                        </ol>
                                                    </div>
                                                </li>

                                                <li>
                                                    13.15.2.3.	Any liability of a party which falls within clause 13.15.2.2 will not be taken into account in assessing whether the financial limits in Section 10.2 has been reached.
                                                </li>
                                                <li>
                                                    13.15.2.4.	Confidentiality. Sections 11.3 and 11.5 shall not apply and the Receiving Party may disclose Confidential Information where this is (a) required in order for the Receiving Party to report an offence to a law enforcement agency; (b) required in order for the Receiving Party to report misconduct or a serious breach of regulatory requirements to any person with a regulatory responsibility in respect of the matters in question; (c) a protected disclosure by the Receiving Party under the Public Interest Disclosure Act 1998; or (d) required in order for the Receiving Party to cooperate with a criminal investigation or prosecution (a “Regulatory Disclosure”); The Receiving Party will, in the case of a Regulatory Disclosure (a) to the extent that it is reasonably practical and lawful to do so, notify the Disclosing in writing in advance of the circumstances [and content] of the proposed Regulatory Disclosure; and (b) where the Permitted Discloser is not able to inform the Disclosing prior to the making of the Regulatory Disclosure, it will (to the extent lawful) inform the Disclosing Party immediately after the making of the Regulatory Disclosure of the full circumstances of the Regulatory Disclosure and provide details of all Confidential Information that has been disclosed.
                                                </li>

                                            </ol>
                                        </div>
                                    </li>



                                    <li>
                                        13.15.3.	German Law.
                                    </li>
                                    <div className="termsCondtionsOlTable">
                                        <ol>
                                            <li>
                                                13.15.3.1.	Warranties.
                                                <div className="termsCondtionsOlTable">
                                                    <ol>
                                                        <li>
                                                            13.15.3.1.1.	Section 8.4 shall be replaced by the following section: 8.4 Remedies. Customer shall inform Welbilt in writing without undue delay in case the Services are not performed or not in accordance with the quality standard specified in Section 8.2 of this Agreement (“Default”). The Customer shall provide a detailed description of the Default and any useful information to allow Welbilt to remedy the Default within a reasonable period of time. If Welbilt fails to remedy the Default, the Customer may claim refund of any Fees paid during the time period when the Services could not be used or not be used in accordance with the quality standard specified in Section 8.2 of this Agreement. In addition, the Customer may terminate this Agreement if Welbilt fails to remedy the same Default after two notifications or if the Services cannot be used for a period exceeding one (1) month.
                                                        </li>
                                                        <li>
                                                            13.15.3.1.2.	Section 8.5 shall be replaced by the following section: 8.5 Limitations and Exclusions. Customer may claim damages for Defaults only within the scope of Section 10 as modified hereafter under Section 13.15.3.2. Any warranty claim by the Customer shall be excluded in case the Customer does not use the Services in accordance with the terms of this Agreement.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                            <li>
                                                13.15.3.2.	Limitations of Liability. Sections 10 shall be replaced by the following sections:
                                                <div className="termsCondtionsOlTable">
                                                    <ol>
                                                        <li>
                                                            13.15.3.2.1.	Welbilt shall be liable without limitation for intent and gross negligence. With regard to slight negligence, Welbilt's liability shall be limited and restricted to the foreseeable damage that might typically occur under this Agreement if an obligation is violated, the fulfilment of which is essential for the proper performance of this Agreement and on the compliance with which the Customer may regularly rely (cardinal duty). In particular, Welbilt’s liability for any unforeseeable indirect, consequential losses shall be excluded.
                                                        </li>
                                                        <li>
                                                            13.15.3.2.2.	Welbilt shall be liable for cases of initial impossibility only if it had knowledge of the hindrance to performance or if its lack of knowledge is due to gross negligence.
                                                        </li>
                                                        <li>
                                                            13.15.3.2.3.	The foregoing liability limitations or exclusions shall not apply to claims resulting from fraudulent concealment of a defect, acceptance of a guarantee and claims pursuant to the German Product Liability Act (Produkthaftungsgesetz) and to damage arising from injuries to life, body or health.
                                                        </li>
                                                        <li>
                                                            13.15.3.2.4.	Insofar as Welbilt’s liability is excluded or limited, such exclusion or limitation shall also apply to the personal liability of its employees, representatives and vicarious agents.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                            <li>
                                                13.15.3.3.	Warranty and Liability in case of a free use of the Services. In the event that the Customer uses the Services free of charge whether a during a trial, a Subscription Plan offering a free of charge period or any promotional or commercial offers, Sections 8.4, 8.5 and 10 shall be replaced by the following sections:
                                                <div className="termsCondtionsOlTable">
                                                    <ol>
                                                        <li>
                                                            13.15.3.3.1.	Welbilt shall only be liable (i) in cases of intent and gross negligence as well as (ii) for any damages arising from the malicious non-disclosure of a defect. Any other liability is expressly excluded.
                                                        </li>
                                                        <li>
                                                            13.15.3.3.2.	With regard to cases of gross negligence, Welbilt’s total aggregate liability under this Agreement shall be limited to the amount equal to twelve (12) months of Fees which would be payable under an applicable Subscription Plan. Moreover, in cases of gross negligence, any claims shall become statute-barred within two years from their occurrence.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </ol>
                            </div>
                        </li>
                        <li>
                            13.16.	Entire Agreement. This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior and contemporaneous agreements and understandings between the parties with respect to such matters, whether oral or written. No terms stated in any other Customer purchase order, privacy agreement, vendor agreement or other Customer form agreement will be incorporated into or form any part of this Agreement, unless such document is signed by an officer of Welbilt, explicitly references this Agreement, and states that it is intended to modify this Agreement.
                        </li>
                        <li>
                            13.17.	Interpretation. The headings used in the Agreement are used for convenience only and are not to be considered in construing or interpreting the Agreement. The parties agree that the terms and conditions of the Agreement are the result of negotiations between the parties and that the Agreement will not be construed in favor of or against any party by reason of the extent to which any party or its professional advisors participated in the preparation of this Agreement. For purposes of this Agreement, unless the context requires otherwise: (a) the words “include,” “includes,” and “including” are deemed to be followed by the words “without limitation”, and (b) the words “herein,” “hereof,” “hereby,” “hereto,” and “hereunder” refer to the Agreement as a whole.
                        </li>
                    </ol>
                </div>

                <h6>14.	Definitions.</h6>
                <div className="termsCondtionsOlTable">
                    <ol>
                        <li>
                            14.1.	“Access Credentials” means any user name, identification number, password, license or security key, security token, personal identification number, or other security code, method, technology, or device used, alone or in combination, to verify an individual’s identity and authorization to access and use the Services.
                        </li>
                        <li>14.2.	“Action” means any claim, action, cause of action, demand, lawsuit, arbitration, inquiry, audit, notice of violation, proceeding, litigation, citation, summons, subpoena, or investigation of any nature, civil, criminal, administrative, regulatory, or other, whether at law, in equity, or otherwise.
                        </li>
                        <li>14.3.	“Affiliate” of a Person means any other Person that directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with, such Person.  The term “control” means the direct or indirect power to direct or cause the direction of the management and policies of a Person, whether through the ownership of voting securities, by contract, or otherwise.
                        </li>
                        <li>14.4.	“Agreement” means this Welbilt KitchenConnect Agreement including Exhibits or Addenda referred to herein.
                        </li>
                        <li>14.5.	“Authorized User” means individuals who are authorized by the Customer to use the Services, for whom subscriptions to a Service have been ordered, and who have been granted Access Credentials. Authorized Users may include but are not limited to Customer’s employees, consultants, contractors, vendors, franchisees, agents, and third parties with whom the Customer transact business.
                        </li>
                        <li>14.6.	“Backup Policy” has the meaning set forth in Section 5.1.
                        </li>
                        <li>14.7.	“Cloud Host” means the third-party cloud hosting service provider engaged by Welbilt to host the computing resources used to support and deliver the Services and the Welbilt Systems, and to host, store, secure, and deliver all related data.
                        </li>
                        <li>14.8.	“Confidential Information” has the meaning set forth in Section 11.1 of the Agreement.
                        </li>
                        <li>14.9.	“Customer” means the person, firm or company who purchases the Services from Welbilt which is identified in the Agreement.
                        </li>
                        <li>14.10.	“Customer Data” means information, data, and other content, in any form or medium, which is collected, uploaded, or otherwise delivered, directly or indirectly by Customer or an Authorized User by or through the Welbilt Materials. For the avoidance of doubt, Customer Data includes Generated Data, but does not include Resultant Data.
                        </li>
                        <li>14.11.	“Customer Failure” has the meaning set forth in Section 3.2.
                        </li>
                        <li>14.12.	“Customer Materials” means the Customer Data, Customer Systems, and any and all other information, data, documents, materials, works, modifications, customizations, adaptations, add-ons, and other content, devices, methods, processes, hardware, software, and other technologies and inventions, including any technical or functional descriptions, requirements, plans, or reports, that are created by Customer in connection with the Services, or otherwise comprise or relate to the Customer Data or Customer Systems.
                        </li>
                        <li>14.13.	“Customer Systems” means the Customer’s information technology infrastructure, including computers, software, hardware, databases, electronic systems (including database management systems), and networks, whether operated directly by Customer or through the use of third-party services.
                        </li>
                        <li>14.14.	“Designated National” means a national or resident of an Embargoed Country, or any Person that, at any time relevant to this Agreement, is on the U.S. Department of Treasury’s List of Specially Designated Nationals, the U.S. Department of Commerce’s Table of Denial Orders, or any analogous list of the competent authority in the jurisdiction of governing Law established pursuant to Section 13.12 of the Agreement.
                        </li>
                        <li>14.15.	“Disclosing Party” has the meaning set forth in Section 11.1 of the Agreement.
                        </li>
                        <li>14.16.	“Documentation” means any manuals, instructions, or other documents or materials that Welbilt provides or makes available to Customer in any form or medium and which describe the functionality, components, features, or requirements of the Services or other Welbilt Materials, including any aspect of the installation, configuration, integration, operation, use, support, or maintenance thereof.
                        </li>
                        <li>14.17.	“Effective Date” has the meaning set forth in this Agreement.
                        </li>
                        <li>14.18.	“Embargoed Country” means any country as to which the United States or the jurisdiction of governing Law established pursuant to Section 13.12 of this Agreement maintains an embargo at any time relevant to this Agreement.
                        </li>
                        <li>14.19.	“Equipment” means Customer’s industrial kitchen and foodservice equipment activated for the Services.
                        </li>
                        <li>14.20.	“Fees” has the meaning set forth in the Subscription Plan and Section 6.1.
                        </li>
                        <li>14.21.	“GDPR” means the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 called the General Data Protection Regulation (or GDPR).
                        </li>
                        <li>14.22.	“Generated Data” means the data and information created by the data collection, telematics, instruments, sensors, meters, data transmission, and other Internet of Things (IoT) functionalities installed in the Equipment and does not include any personal data.
                        </li>
                        <li>14.23.	“Harmful Code” means any software, hardware, or other technology, device, or means, including any virus, worm, malware, or other malicious computer code, the purpose or effect of which is to (a) permit unauthorized access to, or to destroy, disrupt, disable, distort, or otherwise harm or impede in any manner any (i) computer, software, firmware, hardware, system, or network; or (ii) any application or function of any of the foregoing or the security, integrity, confidentiality, or use of any data Processed thereby; or (b) prevent any authorized party from accessing or using the Services or other Welbilt Materials. Harmful Code does not include any Welbilt Disabling Device.
                        </li>
                        <li>14.24.	“Indemnitee”, “Indemnitor” and “Welbilt Indemnitee” have the meanings set forth in Section 9.
                        </li>
                        <li>14.25.	“Intellectual Property Rights” means any or all of the following and all rights in, arising out of or associated therewith throughout the world:  (a) all inventions (whether or not patentable), improvements thereto and all patents and applications therefor and all reissues, divisions, divisionals, renewals, extensions, provisionals, continuations, continuations-in-part, documents and filings claiming priority to or serving as a basis for priority thereof, (b) works of authorship, copyrights (including computer software programs), copyrights registrations and applications therefor, and all other rights corresponding thereto, (c) trade secrets and all proprietary information, including non-public know how, technology, business methods, technical data and customer lists, and all documentation relating to any of the foregoing, (d) all business names, product names, trademarks, service marks, brand names, or logos, whether registered or unregistered, (e) all industrial designs and any registrations and applications therefor, (f) all databases and data collections and all rights therein, (g) all moral rights of authors and inventors, however denominated, (h) all web sites, domain names, internet addresses or related registrations, and (i) any all similar or equivalent rights to any of the foregoing.
                        </li>
                        <li>14.26.	“Law” means any statute, law, ordinance, regulation, rule, code, order, constitution, treaty, common law, judgment, decree, or other requirement of any federal, state, local, or foreign government or political subdivision thereof, or any arbitrator, court, or tribunal of competent jurisdiction.
                        </li>
                        <li>14.27.	“Losses” means any and all losses, damages, deficiencies, Actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys’ fees and the costs of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers.
                        </li>
                        <li>14.28.	“Person” means an individual, corporation, partnership, joint venture, limited liability entity, governmental authority, unincorporated organization, trust, association, or entity.
                        </li>
                        <li>14.29.	“Personal Data” means that portion of Customer Data that is subject to Privacy Laws.
                        </li>
                        <li>14.30.	“Privacy Laws” means applicable Laws, in any and all relevant jurisdictions worldwide, that relate to (a) the confidentiality, collection, use, handling, processing, protection, destruction, transfer or free movement of personal data, personally identifiable information or customer information, (b) electronic data privacy, (c) trans-border data flow and/or (d) data protection.
                        </li>
                        <li>14.31.	“Process” means to access, monitor, receive, store, and to take any other action or perform any operation or set of operations that the Services are capable of taking or performing on any data, information, or other content. “Processing” and “Processed” have correlative meanings.
                        </li>
                        <li>14.32.	“Receiving Party” has the meaning set forth in Section 11.1 of this Agreement.
                        </li>
                        <li>14.33.	“Representatives” means, with respect to a party, that party’s and its Affiliates’ employees, officers, directors, consultants, agents, independent contractors, service providers, sublicensees, subcontractors, and legal advisors.
                        </li>
                        <li>14.34.	“Resultant Data” means data and information that results from Processing (after its initial collection, upload, or other delivery), in whole or in part, any combination of the Generated Data, any other Customer Data, or any Welbilt Data, related to Customer’s use of the Services that is used by Welbilt in an aggregate and de-identified manner, including to compile statistical and performance information related to the provision and operation of the Services. For the avoidance of doubt, where Resultant Data results from Processing of personal data, the data will have been anonymized in the meaning of Recital 26 of the GDPR and any other applicable Privacy Laws.
                        </li>
                        <li>14.35.	“Service Components” means Welbilt’s downloadable components of the Services and the downloadable components intended for use with the Services, including, without limitation, Welbilt’s downloadable add-ins, plug-ins, applications, modules, interfaces, portals, and other components that enable Customer’s full use and enjoyment of the Services.
                        </li>
                        <li>14.36.	“Services” means Welbilt’s cloud-based data acquisition and storage services and data analytics services as listed in the Subscription Plan, including any modifications thereto.
                        </li>
                        <li>14.37.	“Specifications” means Welbilt’s written documentation, as amended from time to time, that identifies the features, work flow, functionality, input, output, data flows, system requirements, and other requirements for the performance of the Services and content of the Documentation.
                        </li>
                        <li>14.38.	“Subcontractor” has the meaning set forth in Section 1.6.
                        </li>
                        <li>14.39.	“Subscription Plan” means the commercial terms applicable to the Services selected by the Customer on Welbilt’s KitchenConnect Platform when agreeing to this Agreement, such as the description of the Services selected, its term and renewals and the consideration, if any, for such Services.
                        </li>
                        <li>14.40.	“Term”, “Initial Term” and “Renewal Term” have the meanings set forth in Section 12.1 of this Agreement.
                        </li>
                        <li>14.41.	“Territory” means the countries listed under Section 13.12
                        </li>
                        <li>14.42.	“Third-Party Materials” means materials and information, in any form or medium, including any open-source or other software, documents, data, content, specifications, products, equipment, or components of or relating to the Services or the Service Components that are not proprietary to Welbilt.
                        </li>
                        <li>14.43.	“Welbilt” means the legal entity within the Welbilt Inc. group of companies which is identified in the Agreement.
                        </li>
                        <li>14.44.	“Welbilt Data” means all of Welbilt’s data, methods, techniques, formulas, algorithms, and other information, in any form, that is either existing as of the Effective Date of the Agreement or that is independently developed, derived, or acquired by Welbilt after the Effective Date and outside the scope of the Services or Welbilt’s other activities under this Agreement.
                        </li>
                        <li>14.45.	“Welbilt Disabling Device” means any software, hardware, or other technology, device, or means (including any back door, time bomb, time out, drop dead device, software routine, or other disabling device) used by Welbilt or its designee to disable Customer’s or any Authorized User’s access to or use of the Services automatically with the passage of time or under the positive control of Welbilt or its designee.
                        </li>
                        <li>14.46.	“Welbilt’s KitchenConnect Platform” means the Welbilt’s web-site https://welbiltconnect.com/ where the Customer subscribes the Services and logs-into the Welbilt Systems to use the Services.
                        </li>
                        <li>14.47.	“Welbilt Materials” means the Services, Specifications, Documentation, Service Components, Welbilt Data, and Welbilt Systems and any and all other information, data, documents, materials, works, and other content, devices, methods, processes, hardware, software, and other technologies and inventions, including any deliverables, technical or functional descriptions, requirements, plans, or reports, that are provided, used, or developed by Welbilt or any Subcontractor in connection with the Services or otherwise comprise or relate to the Services or Welbilt Systems. For the avoidance of doubt, Welbilt Materials include Resultant Data and any information, data, or other content derived from Welbilt’s monitoring of Customer’s access to or use of the Services, but do not include Customer Data.
                        </li>
                        <li>14.48.	“Welbilt Personnel” means all individuals involved in the performance of Services as employees, agents, or independent contractors of Welbilt or any Subcontractor.
                        </li>
                        <li>14.49.	“Welbilt Systems” means the information technology infrastructure used by or on behalf of Welbilt in performing the Services, including all computers, software, hardware, databases, electronic systems (including database management systems), and networks, whether operated directly by Welbilt or through the use of third-party services.
                        </li>
                    </ol>
                </div>
            </React.Fragment>
        )
    }
}
