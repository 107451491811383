import http from '../../service/httpService';
import {
    ReportManagement
} from '../../service/api';

import KCStore from '../../utils/kcStorage';

export const CLEANING_TABLE_REQUEST = 'CLEANING_TABLE_REQUEST';
export const CLEANING_TABLE_SUCCESS = 'CLEANING_TABLE_SUCCESS';
export const CLEANING_TABLE_FAIL = 'CLEANING_TABLE_FAIL';


export function getCleaningTableInfo(type, fromdate, todate, deviceId, brandid, widgetID='') {
    let url;
    let filterType;
    let widgetId = 47;
    let UNIT_BRAND_ID = 6;
    switch (type) {
        case 'Week':
            filterType = 'weekly';
            break;
        case 'Year':
            filterType = 'yearly';
            break;
        case 'Day':
            filterType = 'daily';
            break;
        case 'Quarter':
            filterType = 'quarterly';
            break;
        case 'Date Range':
            filterType = 'custom';
            break;
        default:
            filterType = 'monthly';
    }
    if (KCStore.getItem("brandName") === 'FRYMASTER') {
        widgetId = 67;
        UNIT_BRAND_ID = 3;
        url = `${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
    }
    else if (KCStore.getItem("brandName") === 'GARLAND') {
        widgetId = 105;
        UNIT_BRAND_ID = 9;
        url = `${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
    } else {
        url = `${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
    }

    return dispatch => {
        dispatch({
            type: CLEANING_TABLE_REQUEST
        })

        let header;
        if ((KCStore.getItem("brandName") === "FRYMASTER" && filterType === 'weekly') || (KCStore.getItem("brandName") === "FRYMASTER" && filterType === 'custom')) {
                header = {
                    'Content-Type': 'application/json',
                    'unitid': deviceId,
                    'startdate': todate,
                    'enddate': fromdate,
                    'filterType': filterType,
                    'brandid': 3
                };
            }
            else {
                header = {
                    'Content-Type': 'application/json',
                    'unitid': deviceId,
                    'reqdate': fromdate,
                    'filterType': filterType,
                    'brandid': 3
                };
            }

        if(KCStore.getItem("brandName") === "CONVOTHERM"){
        if (filterType === 'weekly') {
            header = {
                'Content-Type': 'application/json',
                'unitid': deviceId,
                'startdate': todate,
                'enddate': fromdate,
                'filterType': filterType,
                'showtrend': "false",
            };
        } else {
            header = {
                'Content-Type': 'application/json',
                'unitid': deviceId,
                'reqdate': fromdate,
                'filterType': filterType,
                'showtrend': "false",
            };
        }
    }

    if(KCStore.getItem("brandName") === "GARLAND"){
        if (filterType === 'weekly' || filterType === 'custom') {
            header = {
                'Content-Type': 'application/json',
                'unitid': deviceId,
                'startdate': todate,
                'enddate': fromdate,
                'filterType': filterType,
                'showtrend': "false",
            };
        } else {
            header = {
                'Content-Type': 'application/json',
                'unitid': deviceId,
                'reqdate': fromdate,
                'filterType': filterType,
                'showtrend': "false",
            };
        }
    }

        http
            .get(url, {
                headers: header,
                data: {}
            })
            .then(response => {
                dispatch({
                    type: CLEANING_TABLE_SUCCESS,
                    cleaningTableInformation: response.data,
                    filterType: filterType
                })
            })
            .catch(error => {
                dispatch({
                    type: CLEANING_TABLE_FAIL,
                    error
                })
            })
    }
}