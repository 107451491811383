import {MEDIA_PREPARATION_LIST_REQUEST,MEDIA_PREPARATION_LIST_SUCCESS,MEDIA_PREPARATION_LIST_FAIL,
    MEDIA_SELECTED_LIST_SUCCESS} from '../../../../actions/navigation/mediaManagement/mediaPreparationListAction';
const initialState = {
    loaded: false,
    loading: false,
    mediaPreparationListInformation: {},
    mediaSelectedItems:[],
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case MEDIA_PREPARATION_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
            }
        case MEDIA_PREPARATION_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                mediaPreparationListInformation: action.mediaPreparationListInformation,
            }
        case MEDIA_PREPARATION_LIST_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: '',
                mediaPreparationListInformation: {}
            }
        case MEDIA_SELECTED_LIST_SUCCESS:
            return {
                ...state,
                mediaSelectedItems: action.mediaSelectedItems,
            }
        default:
            return state
    }
}
