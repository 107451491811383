import {MEDIA_COMPILATION_LIST_REQUEST,MEDIA_COMPILATION_LIST_SUCCESS,MEDIA_COMPILATION_LIST_FAIL,
    MEDIA_SELECTED_LIST_SUCCESS} from '../../../../actions/navigation/mediaManagement/mediaCompilationListAction';
const initialState = {
    loaded: false,
    loading: false,
    mediaCompilationListInformation: {},
    mediaSelectedItems:[],
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case MEDIA_COMPILATION_LIST_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: 'true',
            }
        case MEDIA_COMPILATION_LIST_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                mediaCompilationListInformation: action.mediaCompilationListInformation,
            }
        case MEDIA_COMPILATION_LIST_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: '',
            }
        case MEDIA_SELECTED_LIST_SUCCESS:
            return {
                ...state,
                mediaSelectedItems: action.mediaSelectedItems,
            }
        default:
            return state
    }
}
