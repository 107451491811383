import {AVERAGE_HOLDING_CYCLE_REQUEST,AVERAGE_HOLDING_CYCLE_SUCCESS,AVERAGE_HOLDING_CYCLE_FAIL} from '../../../actions/reportManagement/averageHoldingCyclesActions';
const initialState = {
    loaded: false,
    loading: false,
    averageHoldingCyclesResponse: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case AVERAGE_HOLDING_CYCLE_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case AVERAGE_HOLDING_CYCLE_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                averageHoldingCyclesResponse: action.averageHoldingCyclesResponse
            }
        case AVERAGE_HOLDING_CYCLE_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgAvgHoldingCycle: 'Server Error',

            }
        default:
            return state
    }
}