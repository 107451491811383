import React, { Component } from 'react';
import http from '../../../../../service/httpService';
import { MenuUploadAPI } from '../../../../../service/api';
import authService from '../../../../../service/authService';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../controls/modelFamilyList';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../../../utils/appConstants';
import moment from '../../../../../utils/momentHelper.js';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import KCStore from '../../../../../utils/kcStorage';

require('jquery-mousewheel')

class FrymasterMenuAddRecipes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            selectedlibraries: [],
            search: '',
            previouspath: '',
            selectedCategoryName: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.redirect = this.redirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onAddRecipes = this.onAddRecipes.bind(this);
        this.recipeSearchResult = this.recipeSearchResult.bind(this);
    }

    async componentDidMount() {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            await this.getLibraryList().catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    getLibraryList = () => {
		this.setState({
			loaderIconVisible: true,
            previouspath: this.props.history.location.previouspath
		});

		return new Promise((resolve, reject) => {
			http.get(MenuUploadAPI.getLibraryList,
				{
					headers: {
						'Content-Type': 'application/json',
						'customerid': CUSTOMER_ID,
						'cgids': KCStore.getItem("custGroupID"),
						'brandid': KCStore.getItem("selectedbrandID"),
						'modelfamilyids': KCStore.getItem("selectedFamilyID"),
                        'basecountry': BASECOUNTRY_ID,
                        'Type': "Recipe"
					},
					data: {}
				}).then(response => {
					let { data } = response;
                    let libraryList = data && data.libraryList ? data.libraryList : [];
                    
                    let selectedCategory = KCStore.getItem("selectedCategory");
                    let selectedCategoryObj = JSON.parse(selectedCategory);
                    let categoryDetails = KCStore.getItem("resourceDetails");
                    let categoryDetailsObj = JSON.parse(categoryDetails);

                    let recipeDetails = [];
                    let categoryName = '';
                    recipeDetails = categoryDetailsObj.filter(c => c.ID === selectedCategoryObj.ID);
                    categoryName = recipeDetails ? recipeDetails[0].categoryName : "";
                    recipeDetails = recipeDetails ? recipeDetails[0].recipe_items ? recipeDetails[0].recipe_items : [] : [];
                    
                    libraryList.length > 0 && libraryList.map(item => {
                        item.CREATED_ON = moment.ConvertLocalTimeFromUTCTime(item.CREATED_ON, "DD/MM/YYYY");
                        item.checked = false;
                        recipeDetails.length > 0 && recipeDetails.map(recipeitem => {
                            if (item.ID === recipeitem.ID) {
                                item.checked = true;
                            }
                        });
                    });

					this.setState({
						loaderIconVisible: false,
                        data: libraryList,
                        selectedCategoryName: categoryName,
                        selectedlibraries: libraryList.filter(c => c.checked === true)
					});
					resolve(response);
				}).catch(err => {
					reject(err);
				});
		});

    }
    
    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: this.props.history.location.previouspath,
            isEdit: false,
            previouspath: "/frymasterMenuAddRecipes"
        });
    }

    onAddRecipes = () => {
        let selectedCategory = KCStore.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);

        let categoryDetails = KCStore.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);

        let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);
        let categoryObj = categoryDetailsObj.filter(c => c.ID !== selectedCategoryObj.ID);
        selectedCategoryDetailsObj.recipe_items = this.state.selectedlibraries;
        categoryObj.push(selectedCategoryDetailsObj);
        KCStore.setItem("resourceDetails", JSON.stringify(categoryObj));
        this.props.history.push({
            pathname: "/frymasterMenuRecipeList",
            isEdit: false,
            previouspath: "/frymasterMenuAddRecipes"
        });
    }

    onSelectLibrary = (e, item) => {
		if (this.state.data && this.state.data.length > 0) {
			const libraries = [...this.state.data];
			const index = libraries.indexOf(item);
			libraries[index] = { ...libraries[index] };
			libraries[index].checked = e.target.checked;

			let selectedlibraries = libraries.filter(c => c.checked === true);

			this.setState({
				data: libraries,
				isSelectAllLibrary: selectedlibraries.length === libraries.length,
				selectedlibraries: selectedlibraries
			});
		}
    }
    
    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    recipeSearchResult = (e) => {
        this.setState({ search: e.target.value });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible, selectedCategoryName, search } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = KCStore.getItem("selectedbrandID");

        const searchedItemInFilter = dataList && dataList.filter(
            (recipe) => {
                return recipe.LIBRARY_NAME.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }
        );
        dataList = searchedItemInFilter;

        let libraryAuthFeature = authService.getFeature("/libraryList");
        return (
            <React.Fragment>
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                        <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button> {selectedCategoryName}
                				</div>
                                <div class="cbSearchForm"> 
                                    <ul>
                                        <li>
                                            <div class="cbSearchField">
                                                <input type="text" class="form-control" onChange={(e) => this.recipeSearchResult(e)} placeholder={formatMessage({ id: 'KC1971' })}></input>
                                            </div>
                                        </li>
                                        <li>
                                            <button type="button" class="btn cbSearchButton">&nbsp;</button>
                                        </li>
                                    </ul> 
                                </div>	
                                <div class="reBodyWrapper mCustomScrollbar withSearchBar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
                                                <div class="cbAddRecipe">
                                                {
										dataList.map((item, i) => {
                                            return (
                                                    <ul>
                                                        <li><img src={item.IMAGE_S3_SIGNED_URL}  alt=""/></li>
                                                        <li>{item.LIBRARY_NAME}</li>
                                                        <li>{item.CREATED_ON}</li>
                                                        <li>
                                                            <div className={libraryAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"} >
                                                                <input id={item.ID} name="RecipesList" type="checkbox" value={item.checked} checked={item.checked} onChange={(e) => this.onSelectLibrary(e, item)} />
                                                                <label htmlFor={item.ID}></label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    )
                                                    })
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onAddRecipes() }} className="btn nextButton">&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterMenuAddRecipes)
