import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { UserProfile } from '../../../service/api';
import {ReportManagement} from '../../../service/api';
import qs from 'query-string';
import jwt_decode from 'jwt-decode';
import http from '../../../service/httpService';
import { BASECOUNTRY_ID, MERCO_UNIT_REPORT, BY_FRYER_SERIAL_NUMBER, FRYMASTER_UHCTHD_UNIT_REPORT } from '../../../utils/appConstants';
import { Link } from 'react-router-dom';
import KCStore from '../../../utils/kcStorage';

let todaysDate = new Date();
let Yesterdaydate = new Date(todaysDate.setDate(todaysDate.getDate() - 1));
let sixMonthsFromNow = new Date(todaysDate.setMonth(todaysDate.getMonth() - 6));
let custSelctedId;
let savestartDate = sixMonthsFromNow;
let saveendDate = Yesterdaydate;
$(function () {
	$('#startdate').on('focus', function(e) { 
		e.preventDefault();
		$(this).blur();
	});
	$('#enddate').on('focus', function(e) { 
		e.preventDefault();
		$(this).blur();
	});
});

$(window).on('beforeunload', function () {
    if($(".fleet-export-error-report").length>0){
        $("#fleetExportReport").removeClass("fleet-export-error-report");
        $(".loadingProgress").css("display", "none");
    }
});

class newReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			ErrorReport: false,
			FleetReport: false,
			FryerReport: false,
			startDate: sixMonthsFromNow,
			endDate: Yesterdaydate,
			maxdate: Yesterdaydate,
			minDate: sixMonthsFromNow,
			custid: 0,
			userData: {},
			fleetResponse: "",
			brandIdData: ""

		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.getUserProfileInformation = this.getUserProfileInformation.bind(this);
		this.getCustomerName = this.getCustomerName.bind(this);
		this.ResetDatepicker=this.ResetDatepicker.bind(this);
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		this.getUserProfileInformation();
		this.getCustomerName();
		let second_date = new Date(todaysDate);
		second_date.setMonth(second_date.getMonth() + 6)
		this.setState({
			maxdate: second_date, 
			loaderIconVisible: true
		});
	}

	getUserProfileInformation = () => {
		let token = KCStore.getItem("Access-Token");
		let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		var decrypt = jwt_decode(token)
		let customeremail = decrypt.email;
		customeremail = loggedinUser;
		this.setState({ emailfromTokan: loggedinUser, loaderIconVisible: true });
		const values = qs.parse(this.props.location.search);
		if (values.user !== undefined) {
			customeremail = values.user;
			var decVal = Buffer.from(values.user, 'base64')
			var setEmailValue = decVal.toString();
			customeremail = setEmailValue;
			this.setState({ queryString: values.user });
		}

		http.get(UserProfile.getUserProfileDetails,
			{
				headers: {
					'customer_id': KCStore.getItem("customerId"),
					'email': customeremail,
					'Content-Type': 'application/json',
					'base_country': KCStore.getItem("basecountry"),
					'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
				}, data: {},
			}).then(response => {
				this.setState({
					loaderIconVisible: false
				})
				if (response.data.role_name === "Brand Admin") {
					if (KCStore.getItem("brandId").indexOf(2) > -1) {
						$("#ErrorReportdiv").removeClass("displaynone");
						$("#FleetReportdiv").removeClass("displaynone");
					}
					else if (KCStore.getItem("brandId").indexOf(4) > -1) {
						$("#FleetReportdiv").removeClass("displaynone");
					}
					else if (KCStore.getItem("brandId").indexOf(3) > -1) {
						$("#FryerReportdiv").removeClass("displaynone");
					}
				}
				else {
					if (KCStore.getItem("brandId").indexOf(2) > -1) {
						$("#ErrorReportdiv").removeClass("displaynone");
					}
				}
			}).catch(err => {
				console.log('catch exception occured');
				this.setState({ loaderIconVisible: false });
			});
	}

	getCustomerName = () => {								
		http.get(ReportManagement.fleetReportBrndIdAPI,
			{
				headers: {
					'Content-Type': 'application/json',
					'brandid': KCStore.getItem("selectedbrandID")										 				 
				},
				data:{}				
			}).then(response => {
				this.setState({
					//userData: response.data,
					loaderIconVisible: false,
					brandIdData: response.data.customers
				})								
			}).catch(err => {
				console.log('catch exception occured');
				this.setState({ loaderIconVisible: false });
			});

	}


	onChangecust = (e) => {
		let idx = e.target.selectedIndex;		
		let custid = e.target.options[idx].text;
		custSelctedId = Number(e.target.value);
		
		this.setState({ custid: custid });		
	}

	handleChangestart = (date) => {
		savestartDate = date; 
		this.setState({	
			startDate: date,			
		});    
		if(savestartDate > saveendDate) {
			this.setState({	
				 error_modal: {
					 open: true,
					 message: "Start date should be lesser than End date."
				 }
			 });
		 }
	}

	handleChangeend = (date) => {
		saveendDate = date;    
		this.setState({	
			endDate: date
		 });
		if(savestartDate > saveendDate) {
			this.setState({	
				error_modal: {
					open: true,
					message: "End date should be greater than Start date."
				}
			 });
		 } 
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
	}

	ResetDatepicker= (e) => {
		e.preventDefault();
		return false;
	}

	setReport = (event) => {
		if (event.target.value === "ErrorReport") {
			this.setState({
				ErrorReport: true,
				FleetReport: false
			});
			$("#custselect").addClass("displaynone");
			$("#AssignUpdate").text('Next');
		}
		else if (event.target.value === "FleetReport") {
			this.setState({
				ErrorReport: false,
				FleetReport: true
			});
			$("#custselect").removeClass("displaynone");
			$("#AssignUpdate").text('Finish');
		}
		else if (event.target.value === "FryerReport") {
			const { FryerReport } = this.state;
			this.setState({
				FryerReport: !FryerReport,
				ErrorReport: false,
				FleetReport: false
			});
			$("#custselect").removeClass("displaynone");
			$("#AssignUpdate").text('Finish');
		}

		$("#AssignUpdate").addClass("btn-default-text activeState");
		$("#AssignUpdate").removeClass("disabled");
	}

	AssignUpdateList = (e) => {

		//let d = new Date();
		//let n = d.toUTCString();
		const { custid } = this.state;
		let startDateselect = this.state.startDate;
		startDateselect = startDateselect.getFullYear() + '-' + (startDateselect.getMonth() + 1) + '-' + startDateselect.getDate();				

		let endDateselect = this.state.endDate;
		endDateselect = endDateselect.getFullYear() + '-' + (endDateselect.getMonth() + 1) + '-' + endDateselect.getDate();
		
		if (($('#ErrorReport').is(":checked") == false) && ($('#FleetReport').is(":checked") == false) && ($('#FryerReport').is(":checked") == false)) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "Please Select Report Type to proceed."
				}
			});
		}
		else if ($('#ErrorReport').is(":checked") || $('#FryerReport').is(":checked")) {
			this.props.history.push({
				pathname: "/reportAssignUpdateList",
				selectedstartDate: startDateselect,
				selectedendDate: endDateselect
			});
		}
		else if (($('#FleetReport').is(":checked")) && (this.state.custid === 0)) {

			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "Please select customer id to proceed."
				}
			});
		}
		else if (($('#FleetReport').is(":checked")) && (this.state.custid !== 0)) {

			this.setState({
				loaderIconVisible: true
			});				
		let token = KCStore.getItem('Access-Token');
		let feetReportPostData = {          
				startDate: startDateselect,
				endDate: endDateselect,
				timeZone: "abc",
				customerId: custSelctedId,
				brandId: KCStore.getItem("selectedbrandID"),
				customerName: custid
			};
		
        http({
            method: "POST",
            url: ReportManagement.fleetReportAPI,
			data: feetReportPostData,
            headers: {
                'Content-Type': 'application/json',
				'Authorization': token,
				'basecountry': BASECOUNTRY_ID
            }											
        }).then( response =>  {
			
			if(response)
				this.setState({
					loaderIconVisible:false,
					fleetResponse: response.data.fileUrl				
				   });  
	            $("#fleetExportReport").addClass("fleet-export-error-report");
				window.location = response.data.fileUrl;
        }).catch(err => {
		
		this.showError(err); 
		});
		}
		else {
			//this.props.history.push("/reportAssignUpdateList");
		}	
	}
	showError = (err) => {
		try {
			let { response } = err;
			if (response && response.status) {

				let { data } = response;
				let { KC_ERR_500, body } = data;
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: body.message ? body.message : ""
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.message
					}

				});
			}
		} catch (e) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.message
				}

			});
		}
	} 
	

	render() {
		let { success_modal, error_modal, loaderIconVisible, fleetResponse } = this.state;
		const {brandIdData} = this.state;	
		const selectedBrandName = KCStore.getItem("brandName");
		 let selectOption = null;

		if (Array.isArray(brandIdData) && brandIdData.length) {
			selectOption = (
				<select id="custoptions" class="form-control countryCode text-capitalize">
					<option value="0">Select Customer</option>		
					{brandIdData.map(obj => <option value={obj.customer_id}>{obj.customer_name} </option>)}
				</select>
			)
		}
			// let items = null;
			// if (Array.isArray(brandIdData) && brandIdData.length) {
			// items = (brandIdData.map((item, key) =>
			// 	<option key={brandIdData.customer_id}>{brandIdData.customer_name}</option>
			// ));	
			// }
		return (
			
			<div id="adminWrapper">			
				<div id="fleetExportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<div id="page-content-wrapper">
					<div class="reportWrapper">
						<div class="adminBgHeader">
							<ul>
								<li>
									<Link to="/reportList" title="Back" class="backIcon">&nbsp;</Link>
								</li>
								<li className="txtboldmsg">
									New Report
				             	</li>
								<li>
									<a id="AssignUpdate" className="disabled" href="javascript:void(0)" onClick={(e) => this.AssignUpdateList(e)} title="Next">Next</a>
								</li>
							</ul>
						</div>
						<div class="newReportWrapper">
							<div class="newReportWrapperL">
								<div class="reportTypeList" onChange={this.setReport.bind(this)}>
									<h6>Report Type</h6>
									<ul>
										{selectedBrandName === "MERCO" ? 
										<li id="ErrorReportdiv">
											<div class="ErrorReportIcon">
												<img src={require("../../../images/file/report_errors.svg").default} />
											</div>
											<div class="customCheckbox">
												<input id="ErrorReport" checked={this.state.ErrorReport} name="ErrorReport" type="checkbox" value="ErrorReport" />
												<label for="ErrorReport">{MERCO_UNIT_REPORT}</label>
											</div>
										</li>: selectedBrandName === "FRYMASTER-UHCTHD" ? 
										<li id="ErrorReportdiv">
											<div class="ErrorReportIcon">
												<img src={require("../../../images/file/report_errors.svg").default} />
											</div>
											<div class="customCheckbox">
												<input id="ErrorReport" checked={this.state.ErrorReport} name="ErrorReport" type="checkbox" value="ErrorReport" />
												<label for="ErrorReport">{FRYMASTER_UHCTHD_UNIT_REPORT}</label>
											</div>
										</li>:
										<li id="FryerReportdiv">
											<div class="ErrorReportIcon">
												<img src={require("../../../images/file/report_errors.svg").default} />
											</div>
											<div class="customCheckbox">
												<input id="FryerReport" checked={this.state.FryerReport} name="FryerReport" type="checkbox" value="FryerReport" />
												<label for="FryerReport">{BY_FRYER_SERIAL_NUMBER}</label>
											</div>
										</li>}
										<li id="FleetReportdiv" class="displaynone">
											<div class="ErrorReportIcon">
												<img src={require("../../../images/file/report_service.svg").default} />
											</div>
											<div class="customCheckbox">
												<input id="FleetReport" checked={this.state.FleetReport} name="FleetReport" type="checkbox" value="FleetReport" />
												<label for="FleetReport">Fleet Report</label>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="newReportWrapperR">
								<div class="newReportForm">
									<h6>Report Options</h6>
									<ul>
										<li>
											<label>Start Date *</label>
											<div class="input-group">
												<DatePicker id="startdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.startDate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} />
											</div>
										</li>
										<li>
											<label>End Date</label>
											<div class="input-group">
												<DatePicker id="enddate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.endDate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate} />
											</div>
										</li>
									</ul>
								</div>
								<div class="maxDuration">* Maximum duration: 6 months prior.</div>
								{selectedBrandName === " CREM" ? <div id="custselect" value={this.state.custid} onChange={(e) => this.onChangecust(e)} class="newReportForm selectCustomerReport displaynone">
									<ul>
										<li>
											<div class="input-group SelectCustomerGroup">
												{selectOption}
											</div>
										</li>
									</ul>
								</div>:""}
							</div>
						</div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerName="Reports" activeClass="reportNav" />
			</div>
		)
	}
};

export default newReport;

