import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import TpmValueTable from './frymasterWidgets/TpmValueTable';
import TpmValueAllFrypots from './frymasterWidgets/TpmValueAllFrypots';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

import KCStore from '../../utils/kcStorage';

am4core.useTheme(am4themes_animated);
var moment = require('moment');
var todaysDate = new Date();
let unitID = "";
let brandID = "";
let currentUnit;

class TPMValue extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			filterType: "Month",
			noResultMsg: 'No Results',
			IsServerError: false,
			loaderIconVisible: true,
			hideDateRange: true,
			datapickflag: false,
			isGlobalDropdownChange:false,
			globalDropdownFilter: 'Month',
			globalDropdownFilterStartDate: '',
			globalDropdownFilterEndDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d
		};
	}

	componentDidMount = async () => {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : KCStore.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	handleChangeGlobalStart(date) {
		this.setState({
			globalDropdownFilterStartDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownGlobalHandler(e);
		});
	}

	handleChangeGlobalEnd(date) {
		this.setState({
			globalDropdownFilterEndDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext  };
			this.onDropDownGlobalHandler(e);
		});
	}

	onDropDownGlobalHandler(e) {
		const filterType = e.nodeText;

		this.setState({
			globalDropdownFilter: filterType,
			isGlobalDropdownChange:true,
			datapickflag: filterType === 'Date Range'
		});
	}

	 //  woopra event
	 reportEvent =(e) => {
		WoopraEvents(`${Constants.REPORT_AND_ANALYTICS}-${e.trackTitle}`);
	};

	render() {
		let { loaderIconVisible, isGlobalDropdownChange,globalDropdownFilter, globalDropdownFilterEndDate, globalDropdownFilterStartDate } = this.state;
		const { formatMessage } = this.props.intl;
		currentUnit = this.props.currentUnit;
		let utilizationTabHeader = null;
		
		// FRYMASTER TPM VALUE TAB DISPLAY
		if(currentUnit.BRAND_NAME === 'FRYMASTER') {
			utilizationTabHeader = (<div className="pageHeader">
				<ul>
					<li>
						<Link to="/reportManagement/production" title={formatMessage({ id: 'KC0175' })} onClick={(e)=>{e.trackTitle="Production";this.reportEvent(e)}}><FormattedMessage id="KC0175"/></Link>
					</li>
					<li>
                        <Link to="/reportManagement/filtration" title={formatMessage({ id: 'KC0442' })} onClick={(e)=>{e.trackTitle="Filtration";this.reportEvent(e)}}><FormattedMessage id="KC0442"/></Link>
                    </li>
					<li>
						<Link to="/reportManagement/oilUsage" title={formatMessage({ id: 'KC0443' })} onClick={(e)=>{e.trackTitle="Oil Usage";this.reportEvent(e)}}><FormattedMessage id="KC0443"/></Link>
					</li>
					<li>
						<Link to="/reportManagement/tpmValue" title={formatMessage({ id: 'KC0444' })} className="active"><FormattedMessage id="KC0444"/></Link>
					</li>
					<li className="filterDropdown">
						<p className="globalFilterText"><FormattedMessage id="KC0097"/></p>
						<NewDropDownListLink translation={true} startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} disableQuarterFilter={true} disableYearFilter={true} />
					</li>
				</ul>
			</div>)
		}
	
		return (
			<React.Fragment>
				<div id="wrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<GlobalHeaderLayout activeClass="reportNav" />
					<div id="page-content-wrapper">
						{/* Reports and Analytics utilization tab header */}
						{utilizationTabHeader}

						{/* Level 1 | Widgets - 61 (Frymaster | Tpm Value Table  */}
						{<TpmValueTable currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}   globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate} />}
						 {/* Level 2 | Widgets - 62 (Frymaster | Tpm Value All Frypots)  */}
						{<TpmValueAllFrypots properties={this.props} widgetID="62" widgetName="TPM Value All Frypots" widgetNameStringID="KC0486" chartTitle="Avg. cooks" chartTitleStringID="KC1891" disableDayFilter={true} currentUnit={this.props} currentLocation={this.props} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={()=>this.setState({isGlobalDropdownChange:false})}   globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}/>}  
					</div>	
					</div>
			</React.Fragment>
		)
	}
};
function mapStateToProps(state) {
	return {
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getCurrentLocation,
		getCurrentUnit
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TPMValue));
