import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import axios from 'axios';
import { MenuUploadAPI, BaseService, MediaManagementAPI } from '../../../../../service/api';
import { CUSTOMER_ID } from '../../../../../utils/appConstants';
import { v1 as uuidv1 } from 'uuid';
import  LibraryListPopup  from '../../libraryListPopup';
import  MediaListPopup  from '../../mediaListPopup';
import http from '../../../../../service/httpService';
import $ from 'jquery';
import { Cropper } from '../../../../controls/cropper';
import 'react-image-crop/dist/ReactCrop.css';
import Jimp from 'jimp';
import { injectIntl,FormattedMessage } from 'react-intl';
import { nanoid } from 'nanoid'
import KCStore from '../../../../../utils/kcStorage';
var request = require('request');

class CookbookAddCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            categoryName: "",
            categoryImage: null,
            srcfile: {},
            importOption: "Library",
            showCropPopup: false,
            isEditCategory: KCStore.getItem("isEditCategory") || false,
            isImageChanged: false,
            isImageDeleted:false,
            cropWidth: 100,
            cropHeight: 100
        }
        this.onDeleteStep = this.onDeleteStep.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
        this.categoryNameChange = this.categoryNameChange.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onImportTypeSelect = this.onImportTypeSelect.bind(this);
        this.cancelImportClick = this.cancelImportClick.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        if(this.state.isEditCategory){
            let EditCategoryData = JSON.parse(KCStore.getItem("EditCategoryData"));
            this.setState({
                categoryName:EditCategoryData.categoryName,
                imageDatab64:EditCategoryData.thumbnailData,
            })
        }
        document.body.style.backgroundColor = "#000000";
        $('#ImportButton').on('click', function (e) {
            $('#fileInput').trigger('click');
            $('#ConfirmationOfImportModal').modal('hide');
        });
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
        KCStore.removeItem("isEditCategory")
        KCStore.removeItem("EditCategoryData")
        this.props.history.push({
            pathname: "/cookbookEditor",
            isEdit: false,
            previouspath: "/cookbookAddCategory"
        });
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    handleInputClick = (e) => {
        this.inputElement.click();
    }

    categoryNameChange = (e) => {
        // if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            this.setState({
                categoryName: e.target.value
            });
        // }
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/cookbookEditor",
            categoryName: this.state.categoryName,
            categoryImage: this.state.categoryImage,
            srcfile: this.state.srcfile,
            imageData: this.state.imageData,
            imageDataBuffer: this.state.imageDataBuffer,
            imageDatab64: this.state.imageDatab64,
            imageThumbnailBuffer: this.state.imageThumbnailBuffer,
            previouspath: "/cookbookAddCategory",
            isEdit: this.state.isEdit,
            isImageChanged: this.state.isImageChanged
        });
    }

    onDeleteStep = () => {

    }

    onSave = () => {
        this.setState({
            loaderIconVisible: true
        });
        let {isImageChanged} = this.state;
        let categoryDetails = KCStore.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let categoryName = this.state.categoryName.trim();
        if (categoryName.length < 1) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1247" 
                }
            });
        }
        else if (categoryName.replaceAll(' ', '') === 'Press&Go') {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC2364" 
                }
            });
        }
        else if (categoryDetailsObj.length >= 1024) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC2237" 
                }
            });
        }
        else {
            if (!this.state.imageFileFormat) {
                if(this.state.isEditCategory && !isImageChanged){
                    let EditCategoryData = JSON.parse(KCStore.getItem("EditCategoryData")); 
                    let index = categoryDetailsObj.findIndex(e => e.ID === EditCategoryData.ID);
                    let categoryObj = categoryDetailsObj[index]
                    categoryObj.categoryName=categoryName;
                    categoryDetailsObj.splice(index, 1, categoryObj); 
                }
                else if(this.state.isEditCategory && isImageChanged){
                    let EditCategoryData = JSON.parse(KCStore.getItem("EditCategoryData")); 
                    let index = categoryDetailsObj.findIndex(e => e.ID === EditCategoryData.ID);
                    let categoryObj = categoryDetailsObj[index]
                    categoryObj.categoryName=categoryName;
                    categoryObj.type = "Category";
                    categoryObj.parentID = "";
                    categoryObj.imageData = "";
                    categoryObj.imageFileFormat = "";
                    categoryObj.imageFileName = "";
                    categoryObj.imageFileSize = "";
                    categoryObj.imageS3Url = "";
                    categoryObj.thumbnailData = null;
                    categoryObj.selected = false;
                    categoryDetailsObj.splice(index, 1, categoryObj); 
                }
                else {
                    let category = {};
                    category.ID = uuidv1();
                    category.imageFileName = "";
                    category.imageFileFormat = "";
                    category.imageFileSize = "";
                    category.imageS3Url = "";
                    category.categoryName = categoryName;
                    category.thumbnailData = null;
                    category.type = "Category";
                    category.parentID = "";
                    category.selected = false;
                    categoryDetailsObj.push(category);
                }
                KCStore.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
                this.setState({
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: this.state.isEditCategory ? "KC2218" : "KC1248"
                    }
                });
            }
            else {
                // let imagefilekey = categoryName + '_' + Date.now() + this.state.imageFileFormat;
                let imagefilekey = nanoid() + '_' + Date.now() + this.state.imageFileFormat;                
                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey':encodeURIComponent(imagefilekey),                    
                    'jsonfilekey': "",
                    'audiofilekey': "",
                    'isthumbnail': true,
                    'encryption' : "true"
                }
    
                return new Promise((resolve, reject) => {
                    let token = KCStore.getItem('Access-Token');
                    headerpresigned.Authorization = token;
                    axios({
                        url: BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl,
                        method: "GET",
                        headers: headerpresigned,
                        data: {}
                    }).then(response => {
                        let { imagePresignedURL, thumbnailPresignedURL } = response.data.presignedURLs
                        let imageurl = imagePresignedURL;
                        let thumbnailurl = thumbnailPresignedURL;
    
                        let UploadedImages3url = "";
                        if (imageurl) {
                            UploadedImages3url = imageurl.split('?');
                            UploadedImages3url = UploadedImages3url[0];
                        }
                        var xhrthumb = new XMLHttpRequest();
                        xhrthumb.open("PUT", thumbnailurl);
                        xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
                        xhrthumb.onloadend = (res) => {
                            if (res.target.status == 200) {
                                var xhrimage = new XMLHttpRequest();
                                xhrimage.open("PUT", imageurl);
                                xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
                                xhrimage.onloadend = (res) => {
                                    if (res.target.status == 200) {
                                        let categoryDetails = KCStore.getItem("resourceDetails");
                                        let categoryDetailsObj = JSON.parse(categoryDetails);
                                        let category = {};
                                        category.ID = uuidv1();
                                        category.imageFileName = imagefilekey;
                                        category.imageFileFormat = this.state.imageFileFormat;
                                        category.imageFileSize = this.state.imageFileSize;
                                        category.imageS3Url = UploadedImages3url;
                                        category.categoryName = categoryName;
                                        category.imageData = "";
                                        category.thumbnailData = this.state.imageThumbnailb64;
                                        category.type = "Category";
                                        category.parentID = "";
                                        category.selected = false;
                                        if(this.state.isEditCategory){
                                            let EditCategoryData = JSON.parse(KCStore.getItem("EditCategoryData")); 
                                            let index = categoryDetailsObj.findIndex(e => e.ID === EditCategoryData.ID);
                                            let categoryObj = categoryDetailsObj[index]
                                            categoryObj.categoryName=categoryName;
                                            categoryObj.type = "Category";
                                            categoryObj.parentID = "";
                                            categoryObj.imageData = "";
                                            categoryObj.selected = false;
                                            categoryObj.imageFileFormat = this.state.imageFileFormat;
                                            categoryObj.imageFileSize = this.state.imageFileSize;
                                            categoryObj.imageS3Url = UploadedImages3url;
                                            categoryObj.thumbnailData = this.state.imageThumbnailb64;
                                            categoryObj.imageFileName = imagefilekey;
                                            categoryDetailsObj.splice(index, 1, categoryObj); 
                                        }else{
                                            categoryDetailsObj.push(category);
                                        }
                                        KCStore.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
                                        this.setState({
                                            loaderIconVisible: false,
                                            success_modal: {
                                                open: true,
                                                message: this.state.isEditCategory ? "KC2218" : "KC1248" 
                                            }
                                        });
                                    }
                                };
                                xhrimage.onerror = (err) => {
                                    reject(err);
                                };
                                xhrimage.send(this.state.imageDataBuffer);
                            }
                        };
                        xhrthumb.onerror = (err) => {
                            reject(err);
                        };
                        xhrthumb.send(this.state.imageThumbnailBuffer);
                    }).catch(err => {
                        reject(err);
                    });
                });
            }
        }
    }

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                loaderIconVisible: true
            });
            let imageData = this.state.imageData ? this.state.imageData : {};
            let fileSize = event.target.files[0].size;
            fileSize = (fileSize / 1048576).toFixed(2);

            let fileName = event.target.files[0].name;
            fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
            let fileext =  fileName.substring(fileName.lastIndexOf('.') + 1);
            let fileNamewithOutExt = fileName.replace('.'+fileext, "");
            let filelength = fileName.length;

            imageData.imagefileName = fileName;
            imageData.imagefileext = fileext;
            imageData.imagefileNamewithOutExt = fileNamewithOutExt;
            imageData.imagefileSize = fileSize;
            imageData.imagefilelength = filelength;

            let resolutionofimageVideo = 0;

            var FileUploadThumbnail = require('../../../../../utils/fileThumnailProcesser');
            let md5 = require('md5');

            if (fileSize) {
                var width;
                var height;
                let srcfile = event.target.files[0];
                if (fileext === "png" || fileext === "PNG" || fileext === "JPEG" || fileext === "jpeg" || fileext === "JPG" || fileext === "jpg" || fileext === "svg" || fileext === "SVG" || fileext === "gif" || fileext === "GIF" || fileext === "bmp" || fileext === "BMP") {
                    var reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = e.target.result;
                        let imageDataBuffer = Buffer.from(e.target.result.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
                        imageData.crc = md5(imageDataBuffer, "base64");
                        image.onload = (img) => {
                            width = img.target.width;
                            height = img.target.height;
                            resolutionofimageVideo = width + " x " + height;
                            //User Story 86440: MRE - Uploading a picture of minimum 100*100 pixels size in Category/Sub-Category/Recipes - Connex supports all sizes above or below 100*100 pixels.
                            if ((width > 100 || height > 100) && (fileext !== "svg" && fileext !== "SVG" && fileext !== "gif" && fileext !== "GIF" && fileext !== "bmp" && fileext !== "BMP")) {
								this.setState({
									loaderIconVisible: false,
									showCropPopup:true,
									src: reader.result,
                                    cropWidth: width < height ? width : height,
									cropHeight: width < height ? width : height,
									imageData: imageData
								});
								$('#popupTrigger').trigger('click');
							}
                            else {
                                imageData.resolution = resolutionofimageVideo;
                                imageData.category = 'picture';
                                if (srcfile) {
                                    let thumhnail = new FileUploadThumbnail({
                                        maxWidth: 80,
                                        maxHeight: 80,
                                        file: srcfile,
                                        onSuccess: (srcthumb) => {
                                            srcthumb = srcthumb.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                            let imageThumbnailBuffer = Buffer.from(srcthumb, "base64");
                                            let image = new FileUploadThumbnail({
                                                maxWidth: 400,
                                                maxHeight: 400,
                                                file: srcfile,
                                                onSuccess: (src) => {
                                                    src = src.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                                    let imageDataBuffer = Buffer.from(src, "base64");
                                                    this.setState({
                                                        loaderIconVisible: false
                                                    });
                                                    this.setState({
                                                        imageFileName: fileName,
                                                        imageFileFormat: "."+ fileext,
                                                        imageFileSize: fileSize,
                                                        categoryImage: URL.createObjectURL(srcfile),
                                                        srcfile: srcfile,
                                                        imageDataBuffer: imageDataBuffer,
                                                        imageDatab64: src,
                                                        imageData: imageData,
                                                        imageThumbnailBuffer: imageThumbnailBuffer,
                                                        imageThumbnailb64: srcthumb,
                                                        isImageChanged: true
                                                    });
                                                },
                                                onError: function (err) {
                                                    this.setState({
                                                        loaderIconVisible: false
                                                    });
                                                }
                                            }).createThumbnail();
                                        },
                                        onError: function (err) {
                                            this.setState({
                                                loaderIconVisible: false
                                            });
                                        }
                                    }).createThumbnail();
                                }
                            };
                        }
                    }
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "KC1225" 
                        }
                    });
                }
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1249" 
                    }
                });
            }
            event.target.value = '';
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    handleInputClick = (e) => {
        if (this.state.importOption === "Library") {
            $('#PhotoLibraryModal').modal('show');
            $('#ConfirmationOfImportModal').modal('hide');
        }
        else {
            this.inputElement.click();
            $('#ConfirmationOfImportModal').modal('hide');
        }
    }

    closeCropPopup = () => {
		this.setState({
			showCropPopup: false
		});
    }

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    async OnImport(item) {
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
        try {
            let md5 = require('md5');
            let imageData = {};
            imageData.imagefileName = item.FILE_NAME;
            imageData.imagefileext = item.FILE_FORMAT.replace('.','');
            imageData.imagefileNamewithOutExt = item.FILE_NAME.replace(item.FILE_FORMAT, '');
            imageData.imagefileSize = item.FILE_SIZE;
            imageData.imagefilelength = item.FILE_SIZE;

            imageData.resolution = item.RESOLUTION;
            imageData.category = 'picture';
            if(item.FILE_FORMAT === '.svg'){
                var res = await fetch(item.thumbUrl);
                var blob = await res.blob();
                let thumbsrc = await new Promise((resolve, reject) => {
                  var reader  = new FileReader();
                  reader.addEventListener("load", function () {
                      resolve(reader.result);
                  }, false);
                  reader.onerror = () => {
                    return reject(this);
                  };
                  reader.readAsDataURL(blob);
                })
                let imgBuffer =  this.base64ToArrayBuffer(thumbsrc.replace("data:multipart/form-data;base64,", ""))
                imageData.crc = md5(blob, "base64");
                _this.setState({
                    imageFileName: item.FILE_NAME,
                    imageFileFormat: item.FILE_FORMAT,
                    imageFileSize: item.FILE_SIZE,
                    categoryImage: null,
                    srcfile: imgBuffer,
                    imageDataBuffer: imgBuffer,
                    imageDatab64: thumbsrc.replace("data:multipart/form-data;base64,", ""),
                    imageData: imageData,
                    imageThumbnailBuffer: imgBuffer,
                    imageThumbnailb64: thumbsrc.replace("data:multipart/form-data;base64,", ""),
                    isImageChanged: true,
                    loaderIconVisible: false
                });
            }else{
            await http.get(`${MediaManagementAPI.getMediamediapreview}` + item.MEDIA_ID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'type': item.TYPE
                    },
                    data: {}
                })
                .then(response => {
                    request.get({ url: response.data.imageUrl, encoding: null }, (err, res, body) => {
                        let src = body.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                        imageData.crc = md5(body, "base64");
                        Jimp.read(body)
                        .then(image => {
                            image.resize(80, 80, (err, image)  => {
                                image.getBufferAsync('image/png').then(buffer => {
                                    let thumbsrc = buffer.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                    _this.setState({
                                        imageFileName: item.FILE_NAME,
                                        imageFileFormat: item.FILE_FORMAT,
                                        imageFileSize: item.FILE_SIZE,
                                        categoryImage: null,
                                        srcfile: body,
                                        imageDataBuffer: body,
                                        imageDatab64: src,
                                        imageData: imageData,
                                        imageThumbnailBuffer: buffer,
                                        imageThumbnailb64: thumbsrc,
                                        isImageChanged: true,
                                        loaderIconVisible: false
                                    });
                                })
                            })
                        })
                        .catch(err => {
                            // Handle an exception.
                        });
                    })
                })
                .catch(ex => {
                    console.log(ex);
                    _this.setState({
                        loaderIconVisible: false
                    });
                })
            }
        }
        catch (ex) {
            console.log(ex);
            _this.setState({
                loaderIconVisible: false
            });
        }
    }

    async cropSubmit(croppedImage) {
        $('#imageResizeModal').modal('hide');
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
		
        let croppedImageType = croppedImage.type.toLowerCase() === "jpg" ? "jpeg" : croppedImage.type.toLowerCase();
        Jimp.read(croppedImage.data)
        .then(image => {
            image.resize(400, 400, (err, image) => {
                image.getBufferAsync('image/' + croppedImageType).then(imagebuffer => {
                    image.getBase64Async('image/' + croppedImageType).then(imageb64 => {
                        let md5 = require('md5');
                        let fileSize = Buffer.byteLength(imagebuffer);
                        fileSize = (fileSize / 1048576).toFixed(2);
                        _this.state.imageData.resolution = "400 x 400";
                        _this.state.imageData.category = "picture";
                        _this.state.imageData.crc = md5(imagebuffer);
                        _this.state.imageData.fileSize = fileSize;
                        Jimp.read(imagebuffer)
                        .then(thumbnail => {
                            thumbnail.resize(80, 80, (err, thumbnail) => {
                                thumbnail.getBufferAsync('image/' + croppedImageType).then(thumbnailbuffer => {
                                    thumbnail.getBase64Async('image/' + croppedImageType).then(thumbnailb64 => {
                                        _this.setState({
                                            imageFileName: croppedImage.name,
                                            imageFileFormat: "." + croppedImageType.replace('image/',''),
                                            imageFileSize: fileSize,
                                            categoryImage: null,
                                            srcfile: imagebuffer,
                                            imageDataBuffer: imagebuffer,
                                            imageDatab64: imageb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                            imageData: _this.state.imageData,
                                            imageThumbnailBuffer: thumbnailbuffer,
                                            imageThumbnailb64: thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                            isImageChanged: true,
                                            loaderIconVisible: false
                                        });
                                    })
                                })
                            })            
                        })
                    })
                })
            })            
        })
        .catch(err => {
            console.log(err);
            _this.setState({
                loaderIconVisible: false
            });
        })
	}

    onImportTypeSelect = (event) => {
        this.setState({
            importOption: event.target.value
        });
    }

    cancelImportClick = (event) => {
        $('#ConfirmationOfImportModal').modal('hide');
    }

    onClearImage = () => {
        this.setState({
            imageDatab64: null,
            imageThumbnailb64: null,
            isImageDeleted: true,
            isImageChanged: true
        });
    }

    render() {
        let { categoryName, loaderIconVisible, imageDatab64, error_modal, success_modal, imageData, importOption,isEditCategory, cropWidth, cropHeight } = this.state;
        let brandid = KCStore.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader  headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                    <button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#imageResizeModal" onClick={() => { }}>&nbsp;</button>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload disabled" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div className="rcDeviceWrapper">
                            <div className="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} /></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    {isEditCategory ? <FormattedMessage id="KC2921" /> : <FormattedMessage id="KC1242" />}
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div className="rcSaveForm">
                                                    <div className="rcNameDiv">
                                                        <input type="text" maxLength="20" className="form-control" value={categoryName} onChange={(e) => { this.categoryNameChange(e) }} />
                                                        <label><FormattedMessage id="KC1250"/></label>
                                                    </div>
                                                    <div className="importImageDiv editActive">
                                                        <div className="importImage">
                                                            <button type="button" class="btn cancelButton" onClick={() => this.onClearImage()}>&nbsp;</button>
                                                            <div className="menuBrowse" title={formatMessage({ id: 'KC1251' })}>
                                                                {/* <img src={categoryImage ? categoryImage : require("../../../images/recipecreation/photosPictures.png")} /> */}
                                                                <a href="javascript:void(0)" class="menuBrowse" data-toggle="modal" data-target="#ConfirmationOfImportModal" title={formatMessage({ id: 'KC1251' })}><img src={imageDatab64 ? `data:${'image/png'};base64,${imageDatab64}` : require("../../../../../images/recipecreation/photosPictures.png")} /></a></div>
                                                            {/* <input type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp" ref={input => this.inputElement = input} onChange={this.onImageChange} className="menuUpdateFile" id="menuUpdate" /> */}
                                                        </div>
                                                        <label><FormattedMessage id="KC1229"/></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="recipeEditorF">
                                    {/* <button type="button" onClick={() => { this.onBackButtonClick() }} className="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button> */}
                                    <button type="button" onClick={() => { this.onSave() }} className="btn saveCButton" title={formatMessage({ id: 'KC1252' })}>&nbsp;</button>
                                </div>
                            </div>
                            {/* <div className="footerText"><FormattedMessage id="KC1217"/></div> */}
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Confirmation Modal --> */}
                <div class="modal fade selectRecipeModal" data-keyboard="true" data-backdrop="static" id="selectRecipeModal" tabindex="-1" role="dialog" aria-labelledby="selectRecipeModalLabel">
                    <div class="modal-dialog modal-lg">
                        <LibraryListPopup libraryType={'CookBook'} />
                    </div>
                </div>
                {/* <!-- //Confirmation --> */}
                {/* <!-- Import Image Modal --> */}
                <MediaListPopup mediaType={'image'} OnImportImage={(item) => this.OnImport(item)} />
                {/* <!-- //Import Image Modal --> */}
                {/* <!-- Import a Picture Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmationYesNo">
                                    <h5><FormattedMessage id="KC1232"/></h5>
                                    <div class="uploadOption">
                                        <div class="customRadioBtn">
                                            <input id="selectFromLibrary" type="radio" name="uploadOption" value="Library" checked={importOption === "Library" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromLibrary" value="Library"><FormattedMessage id='KC1924' /></label>
                                        </div> 
                                        <div class="customRadioBtn">
                                            <input id="selectFromDevice" type="radio" name="uploadOption" value="Device" checked={importOption === "Device" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromDevice" value="Device"><FormattedMessage id="KC1233"/></label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary noBtn" id="selectFromLiberary" type="button" onClick={this.cancelImportClick}><FormattedMessage id='KC0021' /></button>
                                <input type="file" accept="image/png, image/jpeg, image/jpg,image/gif,image/bmp,image/svg+xml" ref={input => this.inputElement = input} onChange={this.onImageChange} className="fileInputField" id="menuUpdate" />
                                <button class="btn btn-secondary yesBtn" id="ImportButton" type="button" onClick={this.handleInputClick}><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- // Import a Picture --> */}
                {/* <!-- imageResizeModal --> */}
                <div class="modal fade imageResizeModal" data-keyboard="true" data-backdrop="static" id="imageResizeModal" tabindex="-1" role="dialog" aria-labelledby="infoModalLabel">
                    <div class="modal-dialog modal-lg imageResizeModal">
                        <div class="modal-content">
                            <div class="modal-header modal-header-danger">
                                <FormattedMessage id="KC1234"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCropPopup}>&nbsp;</button>
                            </div>
                            <div class="modal-body">
                                <form class="imageResizeForm">
                                    <Cropper src={this.state.src} OnCrop={(croppedImageUrl) => this.cropSubmit(croppedImageUrl)} fileName={imageData ? imageData.imagefileName : ""} fileFormat={imageData ? imageData.imagefileext : ""} cropWidth={cropWidth} cropHeight={cropHeight} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //imageResizeModal --> */}
            </React.Fragment>
        )
    }
}

export default injectIntl(CookbookAddCategory)
