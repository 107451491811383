import { SET_USERMANAGEMENT_ROLE_DATA , SET_USERMANAGEMENT_ROLE_DATA_LOADING} from '../../../actions/stateActionsData/type';
const initialState = {
    loaderIconVisible: false,
    userManageRoleListData: {
        loaderIconVisible: false,
        userMgmtList:[]
    },
}
export default function(state = initialState, action){
    switch(action.type) {
        // Reducer for user management
        case SET_USERMANAGEMENT_ROLE_DATA:
        return {
            ...state,
            userManageRoleListData: action.payload
        }
        case SET_USERMANAGEMENT_ROLE_DATA_LOADING: 
        return {
            ...state,
            loaderIconVisible: action.payload

         }
        default:
        return state;
    }
}

