import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';

class FrymasterFryerDisposeOilType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            disposeOilType: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            if (ObjSettingsFile.general.fryer_configuration) {
                const disposeOilType = ObjSettingsFile.general.fryer_configuration.dispose_oil_type;
                this.setState({
                    disposeOilType: disposeOilType === 0 ? "None" : disposeOilType === 1 ? "Bulk" : disposeOilType === 2 ? "Front" : "SDU",
                })
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        let { disposeOilType } = this.state;
        let jsonSettingsFile = KCStore.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.general.fryer_configuration) {
            ObjSettingsFile.general.fryer_configuration = {}
        }
        ObjSettingsFile.general.fryer_configuration.dispose_oil_type = disposeOilType === "None" ? 0 : disposeOilType === "Bulk" ? 1 : disposeOilType === "Front" ? 2 : 3;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        KCStore.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterConfig`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterConfig`);
    }

    onChangeOilType = (e) => {
        this.setState({
            disposeOilType: e.target.value
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = KCStore.getItem("selectedbrandID");

        const { disposeOilType } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Fryer Dispose Oil Type Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Fryer Dispose Oil Type Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")} alt="" />
                            </div>

                            {/* Frymaster Fryer Dispose Oil Type Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1440"/>
                                </div>


                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="textWRadioBtnList">
                                                    {["KC1375", "KC2042", "KC2045", "KC2046"].map((disposeOil, index) => {
                                                        return (
                                                            <ul key={index}>
                                                                <li><FormattedMessage id={disposeOil}/></li>
                                                                <li>
                                                                    <div class="customRadioBtn">
                                                                        <input id={disposeOil} type="radio" name="FreshOilType" value={disposeOil} onChange={(e) => this.onChangeOilType(e)} checked={disposeOil === disposeOilType} />
                                                                        <label for={disposeOil}>&nbsp;</label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Fryer Dispose Oil Type Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterFryerDisposeOilType)
