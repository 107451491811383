import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, MenuUploadAPI } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import _ from 'lodash';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { format } from 'date-fns';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import { injectIntl } from 'react-intl';
import ViewTopology from '../../../components/controls/viewTopology';
import KCStore from '../../../utils/kcStorage';
class AssignCopyLibraryTopology extends Component {

    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            copyDetails: {}
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    componentWillMount = () => {
        let copyDetails = JSON.parse(KCStore.getItem("copyLibrary"));
        this.setState({
            copyDetails
        });
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : data.message ? data.message  : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : data.message ? data.message  : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        this.updateDimensions();
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        this.props.history.push({
            pathname: "/copyLibrary",
            previouspath: "/assignCopyLibraryToTopology"
        });
    }

    submitAddLibrary = (e, _customId) => {
        const { copyDetails } = this.state;
		this.setState({
			loaderIconVisible: true
		});
		var todaysDate = new Date();
		let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');
		let getLibraryName = copyDetails.fileName;
		let getLibraryDescription = copyDetails.description;
		return new Promise((resolve, reject) => {
			let current_Item = copyDetails;
			let postData = {
				libraryId: current_Item.LIBRARY_ID,
				libraryName: getLibraryName,
				description: getLibraryDescription,
				createdBy: current_Item.CREATED_BY,
				localDateTime: todaysDateFormatted,
				modelFamilyIds: copyDetails.copyModel,
                "cgids": _customId
			}
			let url = `${MenuUploadAPI.createCopyLibrary}`
			http({
				method: 'POST',
				url: url,
				data: postData,
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(async (res) => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_200") {
					reject({
						response: {
							data: res.data
						}
					});
					this.setState({
						loaderIconVisible: false
					});
				} else {
					resolve(res);
					this.props.history.push(`/libraryList`);
				}
			}).catch(err => {
				reject(err);
				this.setState({
					loaderIconVisible: false
				});
			});
		});
	}

    changeLoadingStatus = (value) => {
        this.setState({ loaderIconVisible: value })
    }

    render() {
        let { success_modal, error_modal } = this.state;
        const { loaderIconVisible } = this.state;
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology
                    title={`Select Node/Unit`}
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    onBackClickHandler={this.onBackClickHandler}
                    modelFamilyIds={KCStore.getItem("selectedFamilyID")}
                    preSelect={true}
                    restrictUnitSelect={true} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignCopyLibraryTopology));