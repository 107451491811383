import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import http from '../../../../../service/httpService';
import axios from 'axios';
import { UserProfile, MenuUploadAPI, MediaManagementAPI, BaseService, organizationNode } from '../../../../../service/api';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import $ from 'jquery';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import  LibraryListPopup  from '../../libraryListPopup';
import  MediaListPopup  from '../../mediaListPopup';
import { Cropper } from '../../../../controls/cropper';
import 'react-image-crop/dist/ReactCrop.css';
import Jimp from 'jimp';
import Slider from 'react-rangeslider'
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { v1 as uuidv1 } from 'uuid';
import { injectIntl,FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import KCStore from '../../../../../utils/kcStorage';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')
var beautify = require("json-beautify");

var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

var AdmZip = require('adm-zip');
var request = require('request');
var LZString = require('lz-string');

class FrymasterRecipeCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(KCStore.getItem("customerId")),
            selectedModel: 0,
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            disableAddStep: false,
            isChanged: false,
            isEdit: false,
            isImport: false,
            showDiscardPopup: false,
            time_mask: [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            drain_time_mask: [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            hold_time_mask: [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            instanton_time_mask: [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            importOption: "Library",
            showCropPopup: false,
            recipeName: '',
            imageFileName: '',
            temperature: '0',
            totalCookTime: '',
            sensitivity: 0,
            weightedcookcount: 0,
            drainTimer: 'false',
            drainTimerValue: 0,
            holdTimer: 'false',
            holdTimerValue: 0,
            instantOn: 'false',
            instantOnValue: '',
            exit_mode: '',
            schedule: 'false',
            recipeSegmentType: 'Single',
            nodeCaptionDetails: "",
            selectedUnitDetails: "",
        }
        this.onSaveRecipe = this.onSaveRecipe.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.OnImport = this.OnImport.bind(this);
        this.onDrainTimerToggle = this.onDrainTimerToggle.bind(this);
        this.onHoldTimerToggle = this.onHoldTimerToggle.bind(this);
        this.onInstantOnToggle = this.onInstantOnToggle.bind(this);
        this.onTemperatureChange = this.onTemperatureChange.bind(this);
        this.onTotalCookTimeChange = this.onTotalCookTimeChange.bind(this);
        this.onSensitivityChange = this.onSensitivityChange.bind(this);
        this.onWeightedCookCountChange = this.onWeightedCookCountChange.bind(this);
        this.onDrainTimerValueChange = this.onDrainTimerValueChange.bind(this);
        this.onHoldTimerValueChange = this.onHoldTimerValueChange.bind(this);
        this.onInstantOnValueChange = this.onInstantOnValueChange.bind(this);
        this.onAddActionPrompt = this.onAddActionPrompt.bind(this);
        this.handleOnSliderChange = this.handleOnSliderChange.bind(this);
        this.onExitModeChange = this.onExitModeChange.bind(this);
        this.onScheduleChange = this.onScheduleChange.bind(this);
        this.onImportTypeSelect = this.onImportTypeSelect.bind(this);
        this.cancelImportClick = this.cancelImportClick.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.onClickSchedule = this.onClickSchedule.bind(this);
        this.onBasketLiftChange = this.onBasketLiftChange.bind(this);
        this.onPushStartChange = this.onPushStartChange.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $('#ImportButton').on('click', function (e) {
            $('#fileInput').trigger('click');
            $('#ConfirmationOfImportModal').modal('hide');
        });
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            window.addEventListener('resize', this.updateDimensions);
            let jsonRecipeFile = KCStore.getItem("libraryData");
            let ObjRecipe = JSON.parse(jsonRecipeFile);
            let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
            let defaultLibraryDetails = "";
            try {
                defaultLibraryDetails = JSON.parse(KCStore.getItem("defaultLibraryData"));
            }
            catch (err) {
                defaultLibraryDetails = JSON.parse(LZString.decompress(KCStore.getItem("defaultLibraryData")));
            }

            this.setState({
                recipeName: ObjRecipe.name.en_US,
                imageFileName: ObjRecipe.image.replace("Resources/Images/", ""),
                temperature: ObjRecipe.recipeSegmentType === "Single" ? ObjRecipe.ordered_steps[0] ? ObjRecipe.ordered_steps[0].settings.set_point.value + ' F' : ' F' : ' F',
                totalCookTime: ObjRecipe.settings.quality_timer ? ObjRecipe.settings.quality_timer : '',
                sensitivity: ObjRecipe.recipeSegmentType === "Single" ? ObjRecipe.ordered_steps[0] ? ObjRecipe.ordered_steps[0].settings.sensitivity : 0 : 0,
                weightedcookcount: ObjRecipe.settings.weighted_cook_count,
                drainTimer: ObjRecipe.settings.drain_timer ? "true" : "false",
                drainTimerValue: ObjRecipe.settings.drain_timer ? ObjRecipe.settings.drain_timer : '',
                holdTimer: ObjRecipe.settings.hold_timer ? "true" : "false",
                holdTimerValue: ObjRecipe.settings.hold_timer ? ObjRecipe.settings.hold_timer : '',
                instantOn: ObjRecipe.settings.instant_on ? "true" : "false",
                instantOnValue: ObjRecipe.settings.instant_on ? ObjRecipe.settings.instant_on : '',
                basket_lift: ObjRecipe.settings.basketLiftSize,
                push_start: ObjRecipe.settings.push_start,
                exit_mode: ObjRecipe.settings.exit_mode,
                schedule: ObjRecipe.settings.schedule.mode,
                isEdit: libraryDetails.isEdit,
                isImport: libraryDetails.isImport,
                recipeSegmentType: ObjRecipe.recipeSegmentType,
                isChanged: !_.isEqual(defaultLibraryDetails, ObjRecipe),
                selectedModel: parseInt(KCStore.getItem("selectedProductID"))
            })
            if (this.props.history.location.state.getSelectedDynasty) {
                let node = this.props.history.location.state.getSelectedDynasty;
                let selectedUnitDetails = this.props.history.location.state.getSelectedUnitDetails;
                await this.getNodeCaptionDetails(node, selectedUnitDetails);
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions() {
        $("#scrollDownButton").on("click", function () {
            $(".recipeCreationWrapper").animate({ scrollTop: $('.recipeCreationWrapper').prop("scrollHeight") }, 1000);
        });
    }

    onRecipeNameChange = (e) => {
        if (e.target.value.match(/^[a-zA-Z0-9 ]*$/)) {
            this.setState({
                recipeName: e.target.value,
                isChanged: true,
            });
        }
        else {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1219"
                }
            });
        }
    }

    onDrainTimerToggle = (e) => {
        this.setState({
            drainTimer: e.toString(),
            isChanged: true,
        });
    }

    onHoldTimerToggle = (e) => {
        this.setState({
            holdTimer: e.toString(),
            isChanged: true,
        });
    }

    onInstantOnToggle = (e) => {
        this.setState({
            instantOn: e.toString(),
            isChanged: true,
        });
    }

    onTemperatureChange = (e) => {
        this.setState({
            temperature: parseInt(e.target.value.replace(' ', '').replace('F', '') ? e.target.value.replace(' ', '').replace('F', '') : 0) + ' F',
            isChanged: true,
        });
    }

    onTotalCookTimeChange = (e) => {
        let startsWith = e.target.value[0] === "1";
        this.setState({
            time_mask: [
                /[0-1]/,
                startsWith ? /[0-0]/ : /[0-9]/,
                ':',
                startsWith ? /[0-0]/ : /[0-5]/,
                startsWith ? /[0-0]/ : /[0-9]/
            ],
            totalCookTime: e.target.value,
            isChanged: true,
        })
    }

    onSensitivityChange = (e) => {
        this.setState({
            sensitivity: e.target.value,
            isChanged: true,
        });
    }

    onWeightedCookCountChange = (e) => {
        if (e.target.value.match(/^[0-9]*$/)) {
            this.setState({
                weightedcookcount: e.target.value,
                isChanged: true,
            });
        }
        else {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1341"
                }
            });
        }
    }

    onDrainTimerValueChange = (e) => {
        let startsWith = e.target.value[0] === "1";
        this.setState({
            drain_time_mask: [
                /[0-1]/,
                startsWith ? /[0-0]/ : /[0-9]/,
                ':',
                startsWith ? /[0-0]/ : /[0-5]/,
                startsWith ? /[0-0]/ : /[0-9]/
            ],
            drainTimerValue: e.target.value,
            isChanged: true,
        });
    }

    onHoldTimerValueChange = (e) => {
        let startsWith = e.target.value[0] === "1";
        this.setState({
            hold_time_mask: [
                /[0-1]/,
                startsWith ? /[0-0]/ : /[0-9]/,
                ':',
                startsWith ? /[0-0]/ : /[0-5]/,
                startsWith ? /[0-0]/ : /[0-9]/
            ],
            holdTimerValue: e.target.value,
            isChanged: true,
        });
    }

    onInstantOnValueChange = (e) => {
        let startsWith = e.target.value[0] === "1";
        this.setState({
            instanton_time_mask: [
                /[0-1]/,
                startsWith ? /[0-0]/ : /[0-9]/,
                ':',
                startsWith ? /[0-0]/ : /[0-5]/,
                startsWith ? /[0-0]/ : /[0-9]/
            ],
            instantOnValue: e.target.value,
            isChanged: true,
        });
    }

    onExitModeChange = (e) => {
        this.setState({
            exit_mode: e.target.value,
            isChanged: true,
        });
    }

    onBasketLiftChange = (e) => {
        this.setState({
            basket_lift: e.target.value,
            isChanged: true,
        });
    }

    onPushStartChange = (e) => {
        this.setState({
            push_start: e.toString(),
            isChanged: true,
        });
    }

    onScheduleChange = (e) => {
        this.setState({
            schedule: e.toString(),
            isChanged: true,
        });
    }

    onAddActionPrompt = () => {
        this.props.history.push(`/frymasterActionPrompt`);
    }

    onClickSchedule = () => {
        this.props.history.push(`/frymasterRecipeSchedule`);
    }

    handleOnSliderChange = value => {
        this.setState({
            sensitivity: value,
            isChanged: true,
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        if (this.state.isEdit) {
            this.props.history.push(`/libraryList`);
        }
        else {
            this.props.history.push(`/frymasterRecipeEditor`);
        }
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    onBackButtonClick = () => {
        if (!this.state.isChanged) {
            if (this.state.isEdit) {
                this.props.history.push(`/libraryList`);
            }
            else {
                this.props.history.push(`/frymasterRecipeEditor`);
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }

    getFormatedTime(time) {
        let total = 0;
        if (time) {
            let duration = time.replace('PT', '');
            let minutes, seconds = 0;
            if (duration.includes('M')) {
                minutes = duration.substr(0, duration.indexOf('M'))
            }
            if (duration.includes('S')) {
                seconds = duration.substr(duration.indexOf('M') + 1, duration.indexOf('S') - 3)
            }
            total = minutes + ':' + seconds;
        }
        return total;
    }

    onSaveRecipe = (actiontype) => {
        let { imageFileName, imageFileFormat, recipeName, temperature, totalCookTime, sensitivity, weightedcookcount, drainTimer, drainTimerValue, holdTimer, holdTimerValue, instantOn, instantOnValue, basket_lift, push_start, exit_mode, schedule, recipeSegmentType } = this.state;
        let jsonRecipeFile = KCStore.getItem("libraryData");
        let ObjRecipe = JSON.parse(jsonRecipeFile);
        temperature = temperature.replace(' ', '').replace('F', '') ? parseFloat(temperature.replace(' ', '').replace('F', ''), 1) : 0.0;
        if (!recipeName.trim()) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1342"
                }
            })
        }
        else if (recipeSegmentType === "Single" && (temperature !== 0 && (temperature < 212 || temperature > 527))) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1328"
                }
            });
        }
        else if (!totalCookTime || totalCookTime === "00:00" || totalCookTime.includes('_')) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1343"
                }
            })
        }
        else if (drainTimer === "true" && (drainTimerValue === "" || drainTimerValue === "00:00" || drainTimerValue.includes('_'))) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1344"
                }
            })
        }
        else if (holdTimer === "true" && (holdTimerValue === "" || holdTimerValue === "00:00" || holdTimerValue.includes('_'))) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1345"
                }
            })
        }
        else if (instantOn === "true" && (instantOnValue === "" || instantOnValue === "00:00" || instantOnValue.includes('_'))) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1346"
                }
            })
        }
        else if (schedule === "true" && ObjRecipe.settings.schedule.start_date === "" && actiontype !== "Prompt" && actiontype !== "Schedule") {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1347"
                }
            })
        } 
        else {
            this.setState({
                loaderIconVisible: true,
            });
            ObjRecipe.name.en_US = recipeName.trim();
            if (ObjRecipe.recipeSegmentType === "Single") {
                ObjRecipe.ordered_steps[0] = {}
                ObjRecipe.ordered_steps[0].duration = totalCookTime;
                ObjRecipe.ordered_steps[0].settings = {};
                ObjRecipe.ordered_steps[0].settings.sensitivity = sensitivity;
                ObjRecipe.ordered_steps[0].settings.set_point = {}
                ObjRecipe.ordered_steps[0].settings.set_point.value = temperature;
                ObjRecipe.ordered_steps[0].settings.set_point.units = "degF";
            }
            ObjRecipe.settings.quality_timer = totalCookTime;
            ObjRecipe.settings.weighted_cook_count = parseInt(weightedcookcount);
            ObjRecipe.settings.drain_timer = drainTimer === "true" ? drainTimerValue : 0;
            ObjRecipe.settings.hold_timer = holdTimer === "true" ? holdTimerValue : 0;
            ObjRecipe.settings.instant_on = instantOn === "true" ? instantOnValue : 0;
            ObjRecipe.settings.basketLiftSize = basket_lift;
            ObjRecipe.settings.push_start = push_start;
            ObjRecipe.settings.exit_mode = exit_mode;
            ObjRecipe.settings.schedule.mode = schedule;
            if (schedule === "false") {
                ObjRecipe.settings.schedule.start_date = "";
                ObjRecipe.settings.schedule.end_date = "";
            }
            KCStore.setItem("libraryData", JSON.stringify(ObjRecipe));

            if (imageFileName && ObjRecipe.image.replace("Resources/Images/", "") !== imageFileName) {
                let imagefilekey = imageFileName.replace(imageFileFormat, '') + '_' + Date.now() + imageFileFormat;
                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': imagefilekey,
                    'jsonfilekey': '',
                    'audiofilekey': "",
                    'isthumbnail': true
                }

                return new Promise((resolve, reject) => {
                    let token = KCStore.getItem('Access-Token');
                    headerpresigned.Authorization = token;
                    axios({
                        url: BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl,
                        method: "GET",
                        headers: headerpresigned,
                        data: {}
                    }).then(response => {
                        let { imagePresignedURL, thumbnailPresignedURL } = response.data.presignedURLs
                        let imageurl = imagePresignedURL;
                        let thumbnailurl = thumbnailPresignedURL;

                        let UploadedImages3url = "";
                        if (imageurl) {
                            UploadedImages3url = imageurl.split('?');
                            UploadedImages3url = UploadedImages3url[0];
                        }
                        var xhrthumb = new XMLHttpRequest();
                        xhrthumb.open("PUT", thumbnailurl);
                        xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
                        xhrthumb.onloadend = (res) => {
                            if (res.target.status === 200) {
                                var xhrimage = new XMLHttpRequest();
                                xhrimage.open("PUT", imageurl);
                                xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
                                xhrimage.onloadend = (res) => {
                                    if (res.target.status === 200) {
                                        let jsonRecipeFile = KCStore.getItem("libraryData");
                                        let ObjRecipe = JSON.parse(jsonRecipeFile);
                                        ObjRecipe.image = "Resources/Images/" + imagefilekey;
                                        KCStore.setItem("libraryData", JSON.stringify(ObjRecipe));

                                        let resourceDetails = KCStore.getItem("resourceDetails");
                                        let resourceDetailsObj = JSON.parse(resourceDetails);
                                        let resource = {};
                                        resource.fileName = imageFileName;
                                        resource.fileFormat = this.state.imageFileFormat;
                                        resource.fileSize = this.state.imageFileSize;
                                        resource.s3Url = UploadedImages3url;
                                        resource.s3FileName = imagefilekey;
                                        resource.imageData = this.state.imageDatab64;
                                        resource.thumbnailData = this.state.imageThumbnailb64;
                                        resource.type = "image";
                                        resource.isDefault = false;
                                        resourceDetailsObj = [];
                                        resourceDetailsObj.push(resource);
                                        KCStore.setItem("resourceDetails", JSON.stringify(resourceDetailsObj));
                                        this.setState({
                                            loaderIconVisible: false
                                        });
                                        if (actiontype === "Review") {
                                            this.props.history.push(`/frymasterRecipeReview`);
                                        }
                                        else if (actiontype === "Prompt") {
                                            this.props.history.push(`/frymasterActionPrompt`);
                                        }
                                        else if (actiontype === "Schedule") {
                                            this.props.history.push(`/frymasterRecipeSchedule`);
                                        }
                                        else if (actiontype === "Segments") {
                                            this.props.history.push(`/frymasterMultiSegment`);
                                        }
                                        else {
                                            this.props.history.push(`/assignFrymasterRecipeToTopology`);
                                        }
                                    }
                                };
                                xhrimage.onerror = (err) => {
                                    reject(err);
                                };
                                xhrimage.send(this.state.imageDataBuffer);
                            }
                        };
                        xhrthumb.onerror = (err) => {
                            reject(err);
                        };
                        xhrthumb.send(this.state.imageThumbnailBuffer);
                    }).catch(err => {
                        reject(err);
                    });
                });
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                });
                if (actiontype === "Test") {
                    this.props.history.push({
                        pathname: "/recipeAssignTopology",
                        previouspath: "/frymasterRecipeCreation",
                    });
                    return;
                }
                if (actiontype === "Review") {
                    this.props.history.push(`/frymasterRecipeReview`);
                }
                else if (actiontype === "Prompt") {
                    this.props.history.push(`/frymasterActionPrompt`);
                }
                else if (actiontype === "Schedule") {
                    this.props.history.push(`/frymasterRecipeSchedule`);
                }
                else if (actiontype === "Segments") {
                    this.props.history.push(`/frymasterMultiSegment`);
                }
                else {
                    this.props.history.push(`/assignFrymasterRecipeToTopology`);
                }
            }
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    handleInputClick = (e) => {
        if (this.state.importOption === "Library") {
            $('#PhotoLibraryModal').modal('show');
            $('#ConfirmationOfImportModal').modal('hide');
        }
        else {
            this.inputElement.click();
            $('#ConfirmationOfImportModal').modal('hide');
        }
    }

    closeCropPopup = () => {
        this.setState({
            showCropPopup: false
        });
    }

    async OnImport(item) {
        this.setState({
            loaderIconVisible: true
        });
        await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
            headers: {
                'Content-Type': 'application/json',
                'library_id': item.LIBRARY_ID,
                'library_isedit': true
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                let file_path = response.data.presignedURL;
                var libraryFiles = [];
                var b64 = "";
                var imageBuffer = null;
                KCStore.setItem("selectedGroupID", item.CGID);
                try {
                    request.get({ url: file_path, encoding: null }, (err, res, body) => {
                        var zip = new AdmZip(body);
                        var zipEntries = zip.getEntries();

                        zipEntries.forEach((entry, i) => {
                            libraryFiles[i] = zip.readFile(entry);

                            if (!entry.entryName.includes('.json')) {
                                imageBuffer = Buffer.from(libraryFiles[i]);
                                b64 = imageBuffer.toString('base64');
                            }
                        });

                        KCStore.setItem("selectedFamilyID", item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : '');
                        if (item.BRAND_NAME === "FRYMASTER") {
                            let UpdatedJSONObj = JSON.parse(libraryFiles[0]);

                            UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
                                UpdatedJSONObj.ordered_steps[i].duration = item.duration ? this.getFormatedTime(item.duration) : '';
                            });

                            UpdatedJSONObj.conditional_steps && UpdatedJSONObj.conditional_steps.map((item, i) => {
                                let duration = item.actions.prompt.duration;
                                UpdatedJSONObj.conditional_steps[i].actions.prompt.duration = duration ? this.getFormatedTime(duration) : '';
                            });

                            KCStore.setItem("libraryData", JSON.stringify(UpdatedJSONObj));

                            let resourceDetails = UpdatedJSONObj.recipeResources;
                            resourceDetails[0].imageData = '';
                            resourceDetails[0].thumbnailData = b64;
                            KCStore.setItem("resourceDetails", JSON.stringify(resourceDetails));

                            let md5 = require('md5');
                            let libraryDetails = {};
                            libraryDetails.id = UpdatedJSONObj.id;
                            libraryDetails.version = UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.'));
                            libraryDetails.library_id = item.LIBRARY_ID;
                            libraryDetails.crc = md5(b64, "base64");
                            libraryDetails.isEdit = false;
                            libraryDetails.isImport = true;
                            libraryDetails.initialMethod = "Add";

                            KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));

                            this.setState({
                                recipeName: UpdatedJSONObj.name.en_US,
                                imageFileName: UpdatedJSONObj.image.replace("Resources/Images/", ""),
                                temperature: UpdatedJSONObj.recipeSegmentType === "Single" ? UpdatedJSONObj.ordered_steps[0] ? UpdatedJSONObj.ordered_steps[0].settings.set_point.value + ' F' : ' F' : ' F',
                                totalCookTime: UpdatedJSONObj.settings.quality_timer ? this.getFormatedTime(UpdatedJSONObj.settings.quality_timer) : '',
                                sensitivity: UpdatedJSONObj.recipeSegmentType === "Single" ? UpdatedJSONObj.ordered_steps[0] ? UpdatedJSONObj.ordered_steps[0].settings.sensitivity : 0 : 0,
                                weightedcookcount: UpdatedJSONObj.settings.weighted_cook_count,
                                drainTimer: UpdatedJSONObj.settings.drain_timer ? "true" : "false",
                                drainTimerValue: UpdatedJSONObj.settings.drain_timer ? this.getFormatedTime(UpdatedJSONObj.settings.drain_timer) : '',
                                holdTimer: UpdatedJSONObj.settings.hold_timer ? "true" : "false",
                                holdTimerValue: UpdatedJSONObj.settings.hold_timer ? this.getFormatedTime(UpdatedJSONObj.settings.hold_timer) : '',
                                instantOn: UpdatedJSONObj.settings.instant_on ? "true" : "false",
                                instantOnValue: UpdatedJSONObj.settings.instant_on ? this.getFormatedTime(UpdatedJSONObj.settings.instant_on) : '',
                                basket_lift: UpdatedJSONObj.settings.basketLiftSize,
                                push_start: UpdatedJSONObj.settings.push_start,
                                exit_mode: UpdatedJSONObj.settings.exit_mode,
                                schedule: UpdatedJSONObj.settings.schedule.mode,
                                isEdit: false,
                                isImport: true,
                                isChanged: true,
                                loaderIconVisible: false
                            });
                        }
                    });
                }
                catch (ex) {
                    this.setState({
                        loaderIconVisible: false
                    });
                }
            }
        })
            .catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
            });
    }

    async OnMediaImport(item) {
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
        try {
            let md5 = require('md5');
            let imageData = {};
            imageData.imagefileName = item.FILE_NAME;
            imageData.imagefileext = item.FILE_FORMAT.replace('.', '');
            imageData.imagefileNamewithOutExt = item.FILE_NAME.replace(item.FILE_FORMAT, '');
            imageData.imagefileSize = item.FILE_SIZE;
            imageData.imagefilelength = item.FILE_SIZE;

            imageData.resolution = item.RESOLUTION;
            imageData.category = 'picture';
            await http.get(`${MediaManagementAPI.getMediamediapreview}` + item.MEDIA_ID,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {}
                })
                .then(response => {
                    request.get({ url: response.data.imageUrl, encoding: null }, (err, res, body) => {
                        let src = body.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                        imageData.crc = md5(body, "base64");
                        Jimp.read(body)
                            .then(image => {
                                image.resize(80, 80, (err, image) => {
                                    image.getBufferAsync('image/png').then(buffer => {
                                        let thumbsrc = buffer.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                        _this.setState({
                                            imageFileName: item.FILE_NAME,
                                            imageFileFormat: item.FILE_FORMAT,
                                            imageFileSize: item.FILE_SIZE,
                                            categoryImage: null,
                                            srcfile: body,
                                            imageDataBuffer: body,
                                            imageDatab64: src,
                                            imageData: imageData,
                                            imageThumbnailBuffer: buffer,
                                            imageThumbnailb64: thumbsrc,
                                            isImageChanged: true,
                                            loaderIconVisible: false
                                        });
                                    })
                                })
                            })
                            .catch(err => {
                                // Handle an exception.
                            });
                    })
                })
                .catch(ex => {
                    _this.setState({
                        loaderIconVisible: false
                    });
                })
        }
        catch (ex) {
            _this.setState({
                loaderIconVisible: false
            });
        }
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    async cropSubmit(croppedImage) {
        $('#imageResizeModal').modal('hide');
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });
        
        let croppedImageType = croppedImage.type.toLowerCase() === "jpg" ? "jpeg" : croppedImage.type.toLowerCase();
        Jimp.read(croppedImage.data)
        .then(image => {
            image.resize(400, 400, (err, image) => {
                image.getBufferAsync('image/' + croppedImageType).then(imagebuffer => {
                    image.getBase64Async('image/' + croppedImageType).then(imageb64 => {
                        let md5 = require('md5');
                        let fileSize = Buffer.byteLength(imagebuffer);
                        fileSize = (fileSize / 1048576).toFixed(2);
                        _this.state.imageData.resolution = "400 x 400";
                        _this.state.imageData.category = "picture";
                        _this.state.imageData.crc = md5(imagebuffer);
                        _this.state.imageData.fileSize = fileSize;
                        Jimp.read(imagebuffer)
                        .then(thumbnail => {
                            thumbnail.resize(80, 80, (err, thumbnail) => {
                                thumbnail.getBufferAsync('image/' + croppedImageType).then(thumbnailbuffer => {
                                    thumbnail.getBase64Async('image/' + croppedImageType).then(thumbnailb64 => {
                                        _this.setState({
                                            imageFileName: croppedImage.name,
                                            imageFileFormat: "." + croppedImageType.replace('image/', ''),
                                            imageFileSize: fileSize,
                                            categoryImage: null,
                                            srcfile: imagebuffer,
                                            imageDataBuffer: imagebuffer,
                                            imageDatab64: imageb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                            imageData: _this.state.imageData,
                                            imageThumbnailBuffer: thumbnailbuffer,
                                            imageThumbnailb64: thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                            isImageChanged: true,
                                            loaderIconVisible: false
                                        });
                                    })
                                })            
                            })
                        })
                    })
                })
            })
        })
        .catch(err => {
            _this.showError(err);
        })
    }

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                loaderIconVisible: true
            });
            let imageData = this.state.imageData ? this.state.imageData : {};
            let fileSize = event.target.files[0].size;
            fileSize = (fileSize / 1048576).toFixed(2);

            let fileName = event.target.files[0].name;
            fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
            let fileextSplit = fileName.split('.');
            let fileext = fileextSplit[1];
            let fileNamewithOutExt = fileextSplit[0];
            let filelength = fileName.length;

            imageData.imagefileName = fileName;
            imageData.imagefileext = fileext;
            imageData.imagefileNamewithOutExt = fileNamewithOutExt;
            imageData.imagefileSize = fileSize;
            imageData.imagefilelength = filelength;

            let resolutionofimageVideo = 0;

            var FileUploadThumbnail = require('../../../../../utils/fileThumnailProcesser');
            let md5 = require('md5');

            if (fileSize) {
                var width;
                var height;
                let srcfile = event.target.files[0];
                if (fileext === "png" || fileext === "PNG" || fileext === "JPEG" || fileext === "jpeg" || fileext === "JPG" || fileext === "jpg") {
                    var reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = e.target.result;
                        let imageDataBuffer = Buffer.from(e.target.result.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
                        imageData.crc = md5(imageDataBuffer, "base64");
                        image.onload = (img) => {
                            width = img.target.width;
                            height = img.target.height;
                            resolutionofimageVideo = width + " x " + height;
                            if ((width < 400 || height < 400)) {
                                this.setState({
                                    loaderIconVisible: false,
                                    error_modal: {
                                        open: true,
                                        message: "KC1224"
                                    }
                                });
                            }
                            else if ((width > 400 || height > 400)) {
                                this.setState({
                                    loaderIconVisible: false,
                                    showCropPopup: true,
                                    src: reader.result,
                                    imageData: imageData
                                });
                                $('#popupTrigger').trigger('click');
                            }
                            else {
                                imageData.resolution = resolutionofimageVideo;
                                imageData.category = 'picture';
                                if (srcfile) {
                                    let thumhnail = new FileUploadThumbnail({
                                        maxWidth: 80,
                                        maxHeight: 80,
                                        file: srcfile,
                                        onSuccess: (srcthumb) => {
                                            srcthumb = srcthumb.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                            let imageThumbnailBuffer = Buffer.from(srcthumb, "base64");
                                            let image = new FileUploadThumbnail({
                                                maxWidth: 400,
                                                maxHeight: 400,
                                                file: srcfile,
                                                onSuccess: (src) => {
                                                    src = src.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                                    let imageDataBuffer = Buffer.from(src, "base64");
                                                    this.setState({
                                                        loaderIconVisible: false
                                                    });
                                                    this.setState({
                                                        imageFileName: fileName,
                                                        imageFileFormat: "." + fileext,
                                                        imageFileSize: fileSize,
                                                        categoryImage: URL.createObjectURL(srcfile),
                                                        srcfile: srcfile,
                                                        imageDataBuffer: imageDataBuffer,
                                                        imageDatab64: src,
                                                        imageData: imageData,
                                                        imageThumbnailBuffer: imageThumbnailBuffer,
                                                        imageThumbnailb64: srcthumb,
                                                        isImageChanged: true
                                                    });
                                                },
                                                onError: function (err) {
                                                    this.showError(err);
                                                }
                                            }).createThumbnail();
                                        },
                                        onError: function (err) {
                                            this.showError(err);
                                        }
                                    }).createThumbnail();
                                }
                            };
                        }
                    }
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "KC1225"
                        }
                    });
                }
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1249"
                    }
                });
            }
        }
    }

    cancelImportClick = (event) => {
        $('#ConfirmationOfImportModal').modal('hide');
    }

    onImportTypeSelect = (event) => {
        this.setState({
            importOption: event.target.value
        });
    }

    getNodeCaptionDetails = async (val, val1) => {
        this.setState({ loaderIconVisible: true });
        const url = organizationNode.nodeDetails + "96";

        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'cid': KCStore.getItem("customerId"),
                'dynasty': val,
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                let result = response.data;
                let nodeDetails;
                result.map((item, i) => {
                    if (nodeDetails) {
                        if ((result.length - 1) === i) {
                            nodeDetails = nodeDetails + item.node_name;
                        } else {
                            nodeDetails = nodeDetails + item.node_name + "/";
                        }
                    } else {
                        nodeDetails = item.node_name + "/";
                    }
                })

                this.setState({
                    loaderIconVisible: false,
                    nodeCaptionDetails: nodeDetails,
                    selectedUnitDetails: val1,
                });
                // await this.getThingDetails();
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    selectTestDeleteButtonClick = () => {
        this.setState({
            nodeCaptionDetails: null,
            selectedUnitDetails: {},
        });
    }

    selectTestUnitButtonClick = (e) => {
        e.preventDefault();
        let _this = this;
        let libraryData = KCStore.getItem("libraryData");
        let libraryDataObj = JSON.parse(libraryData);
        let httpMethod;

        if (!libraryDataObj && !libraryDataObj.name.en_US) {
            _this.props.history.push(`/frymasterRecipeEditor`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = KCStore.getItem("brandName");
            let loginUser = JSON.parse(KCStore.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'base_country': KCStore.getItem("basecountry"),
					'emulation': KCStore.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(async response => {
                let libraryId;
                let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
                if (libraryDetails.isEdit) {
                    httpMethod = "PUT";
                    libraryId = libraryDetails.library_id;
                } else {
                    httpMethod = "POST";
                    libraryId = libraryDataObj.name.en_US + '_' + Date.now();
                    libraryId = libraryId.replace(/\s+/g, '');
                    libraryDetails.library_id = libraryId;
                    KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
                }

                libraryDataObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
                libraryDataObj.version = libraryDetails.isEdit ? parseInt(libraryDetails.version) + 1 + ".0.0" : "1.0.0";
                libraryDataObj.edit_date = todaysDateUTCFormatted;
                libraryDataObj.author = response.data.name;
                libraryDataObj.email = loginUser.username;
                let quality_timer = libraryDataObj.settings.quality_timer;
                libraryDataObj.settings.quality_timer = quality_timer ? "PT" + quality_timer.split(':')[0] + "M" + quality_timer.split(':')[1] + "S" : 0;
                let drain_timer = libraryDataObj.settings.drain_timer;
                libraryDataObj.settings.drain_timer = drain_timer ? "PT" + drain_timer.split(':')[0] + "M" + drain_timer.split(':')[1] + "S" : 0;
                let hold_timer = libraryDataObj.settings.hold_timer;
                libraryDataObj.settings.hold_timer = hold_timer ? "PT" + hold_timer.split(':')[0] + "M" + hold_timer.split(':')[1] + "S" : 0;
                let instant_on = libraryDataObj.settings.instant_on;
                libraryDataObj.settings.instant_on = instant_on ? "PT" + instant_on.split(':')[0] + "M" + instant_on.split(':')[1] + "S" : 0;

                libraryDataObj.ordered_steps && libraryDataObj.ordered_steps.map((item, i) => {
                    libraryDataObj.ordered_steps[i].duration = item.duration ? "PT" + item.duration.split(':')[0] + "M" + item.duration.split(':')[1] + "S" : 0;
                });

                libraryDataObj.conditional_steps && libraryDataObj.conditional_steps.map((item, i) => {
                    let actionduration = item.actions.prompt.duration;
                    let conditionduration = item.condition.property_value;
                    libraryDataObj.conditional_steps[i].condition.property_value = conditionduration ? ((parseInt(conditionduration.split(':')[0]) * 60) + parseInt(conditionduration.split(':')[1])) : 0;
                    libraryDataObj.conditional_steps[i].actions.prompt.duration = actionduration ? "PT" + actionduration.split(':')[0] + "M" + actionduration.split(':')[1] + "S" : 0;
                });

                let resourceDetailsData = KCStore.getItem("resourceDetails");
                let resourceDetailsObj = JSON.parse(resourceDetailsData);

                let imageSize = 0.00;
                resourceDetailsObj.map((item, i) => {
                    imageSize = imageSize + item.fileSize ? parseFloat(item.fileSize) : 0.00;
                })

                let resources = resourceDetailsObj;
                delete resources.Import;
                resources && resources.map((item, i) => {
                    delete item.imageData;
                    delete item.thumbnailData;
                });

                libraryDataObj.recipeResources = resources;

                if (libraryDataObj.recipeSegmentType === "Multi") {
                    delete libraryDataObj.settings.set_point;
                }

                // convert JSON object to beautify String
                var jsonStr = beautify(libraryDataObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);
                let md5 = require('md5');
                let crc = md5(jsonBuffer, "base64");

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": KCStore.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": libraryDataObj.name.en_US,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + imageSize).toFixed(2),
                    "libraryS3Url": "",
                    "type": "Recipe",
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": resources[0] ? resources[0].s3FileName : "",
                    "imageFileFormat": resources[0] ? resources[0].fileFormat.replace('.', '') : "",
                    "imageFileSize": resources[0] ? resources[0].fileSize : "0.00",
                    "imageS3Url": resources[0] ? resources[0].s3Url : "",
                    "cgids": [parseInt(KCStore.getItem("custGroupID") ? KCStore.getItem("custGroupID") : 0)],
                    "json": JSON.stringify(libraryDataObj),
                    "recipeResources": [],
                    "libraryGuid": libraryDataObj.id
                };

                if (libraryDetails.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }
                else {
                    libraryPostData.status = "DRAFT";
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': "",
                    'jsonfilekey': libraryId + '.json',
                    'audiofilekey': "",
                    'isthumbnail': false
                }

                await uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(async response => {

                    let { selectedUnitDetails } = this.state;

                    const unitList = [];
                    unitList.push(
                        {
                            "thingArn": selectedUnitDetails.THING,
                            "productId": selectedUnitDetails.ProductId,
                            "cloudUnitName": selectedUnitDetails.CLOUD_UNIT_NAME,
                            "unitTimeZone": selectedUnitDetails.TimeZone_Standard
                        }
                    )

                    let postData = {
                        "customer": CUSTOMER_ID,
                        "brand": brandName,
                        "brandId": BRAND_ID_VALUE[brandName],
                        "user": loggedinUser,
                        "libraryIds": [libraryId],
                        "country": BASECOUNTRY_ID,
                        "type": "Recipe",
                        "thingList": unitList,
                        "userName": loggedinUser,
                        "isTestPush": true
                    };

                    let url = MenuUploadAPI.getLibraryUpdateStatus;
                    await http.post(url, postData,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                        .then(response => {
                            let libraryDetails = JSON.parse(KCStore.getItem("libraryDetails"));
                            libraryDetails.isEdit = true;
                            KCStore.setItem("libraryDetails", JSON.stringify(libraryDetails));
                            _this.setState({
                                loaderIconVisible: false,
                                isEdit: true,
                                success_modal: {
                                    open: true,
                                    message: "KC1227"
                                },
                                nodeCaptionDetails: null,
                                selectedUnitDetails: {},
                            });
                        }).catch(err => {
                            _this.showError(err);
                        });


                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
            });
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: basedataList, imageFileName, success_modal, error_modal, loaderIconVisible, showDiscardPopup, time_mask, drain_time_mask, hold_time_mask, instanton_time_mask, imageData, importOption, recipeSegmentType } = this.state;
        let { recipeName, temperature, totalCookTime, sensitivity, weightedcookcount, drainTimer, drainTimerValue, holdTimer, holdTimerValue, instantOn, instantOnValue, exit_mode, schedule, basket_lift, push_start, selectedModel } = this.state;
        basedataList = basedataList && basedataList.length > 0 ? basedataList : [];
        basedataList = basedataList.filter(b => b.PARAMETER_NAME !== "Instruction");
        let brandid = KCStore.getItem("selectedbrandID");
        let loginInfo = JSON.parse(KCStore.getItem("loginInfo"));

        const horizontalLabels = {
            0: formatMessage({ id: 'KC2038' }),
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: formatMessage({ id: 'KC2039' })
        }

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                    <button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#imageResizeModal" onClick={() => { }}>&nbsp;</button>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt="" /></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1348"/>
				                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li><span class="required">*</span><FormattedMessage id="KC0241"/></li>
                                                        <li><input type="text" class="form-control" value={recipeName} onChange={(e) => this.onRecipeNameChange(e)} /></li>
                                                    </ul>
                                                    <ul>
                                                        <li>&nbsp;&nbsp;<FormattedMessage id="KC1996"/></li>
                                                        <li class="arrowBtn">{imageFileName}<button type="button" data-toggle="modal" data-target="#ConfirmationOfImportModal" class="btn nextButton">&nbsp;</button></li>
                                                    </ul>
                                                    {recipeSegmentType === "Single" && <ul>
                                                        <li><span class="required">*</span><FormattedMessage id="KC1289"/></li>
                                                        <li class="smallFormFields"><input type="text" class="form-control" placeholder={formatMessage({ id: 'KC1980' })} maxLength="5" value={temperature} onChange={(e) => this.onTemperatureChange(e)} /></li>
                                                    </ul>}
                                                    <ul>
                                                        <li><span class="required">*</span><FormattedMessage id="KC1349"/></li>
                                                        <li class="smallFormFields"><InputMask mask={time_mask} maskChar="" placeholder={formatMessage({ id: 'KC2085' })} value={totalCookTime} class="form-control" onChange={(e) => this.onTotalCookTimeChange(e)} /></li>
                                                    </ul>
                                                    {recipeSegmentType === "Multi" && <ul>
                                                        <li class="width190px">&nbsp;&nbsp;<FormattedMessage id="KC1350"/></li>
                                                        <li class="arrowBtn"><button type="button" onClick={() => { this.onSaveRecipe('Segments') }} class="btn nextButton">&nbsp;</button></li>
                                                    </ul>}
                                                </div>
                                                {recipeSegmentType === "Single" && <div class="frymasterRCForm">
                                                    <ul class="borderNone padding0px">
                                                        <li>&nbsp;&nbsp;<FormattedMessage id="KC1351"/></li>
                                                        <li class="smallFormFields">&nbsp;</li>
                                                    </ul>
                                                </div>}
                                                {recipeSegmentType === "Single" && <div class="frymasterRCForm">
                                                    <ul>
                                                        <li class="width10px">&nbsp;</li>
                                                        <li>
                                                            <div className='slider custom-labels'>
                                                                <div className='slider-horizontal'>
                                                                    
                                                                    <Slider
                                                                        min={0}
                                                                        max={9}
                                                                        value={sensitivity}
                                                                        labels={horizontalLabels}
                                                                        orientation='horizontal'
                                                                        handleLabel={sensitivity}
                                                                        onChange={this.handleOnSliderChange}
                                                                    /></div></div>
                                                        </li>
                                                    </ul>
                                                </div>}
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li>&nbsp;&nbsp;<FormattedMessage id="KC1352"/></li>
                                                        <li class="smallFormFields"><input type="text" class="form-control" maxLength="1" value={weightedcookcount} onChange={(e) => this.onWeightedCookCountChange(e)} /></li>
                                                    </ul>
                                                    <ul>
                                                        <li class="width190px">&nbsp;&nbsp;<FormattedMessage id="KC1353"/></li>
                                                        <li class="arrowBtn"><button type="button" onClick={() => { this.onSaveRecipe('Prompt') }} class="btn nextButton">&nbsp;</button></li>
                                                    </ul>
                                                    <ul>
                                                        <li>&nbsp;&nbsp;<FormattedMessage id="KC1354"/></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle floatLeft" data-toggle="buttons"> <FormattedMessage id="KC0580"/> &nbsp;
                                                            <Switch
                                                                onChange={(e) => this.onDrainTimerToggle(e)}
                                                                checked={drainTimer === "true" ? true : false}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                onColor={'#64c832'}
                                                                offColor={'#c4c1c0'}
                                                                offHandleColor={'#ffffff'}
                                                                onHandleColor={'#ffffff'}/> &nbsp; <FormattedMessage id="KC0611"/> &nbsp;
                                                            </div>
                                                            <InputMask mask={drain_time_mask} maskChar="" placeholder={formatMessage({ id: 'KC2085' })} value={drainTimerValue} class="form-control" onChange={(e) => this.onDrainTimerValueChange(e)} />
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>&nbsp;&nbsp;<FormattedMessage id="KC1355"/></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle floatLeft" data-toggle="buttons"> <FormattedMessage id="KC0580"/> &nbsp;
                                                            <Switch
                                                                onChange={(e) => this.onHoldTimerToggle(e)}
                                                                checked={holdTimer === "true" ? true : false}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                onColor={'#64c832'}
                                                                offColor={'#c4c1c0'}
                                                                offHandleColor={'#ffffff'}
                                                                onHandleColor={'#ffffff'}/> &nbsp; <FormattedMessage id="KC0611"/> &nbsp;
                                                            </div>
                                                            <InputMask mask={hold_time_mask} maskChar="" placeholder={formatMessage({ id: 'KC2085' })} value={holdTimerValue} class="form-control" onChange={(e) => this.onHoldTimerValueChange(e)} />
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>&nbsp;&nbsp;<FormattedMessage id="KC1356"/></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle floatLeft" data-toggle="buttons"> <FormattedMessage id="KC0580"/> &nbsp;
                                                            <Switch
                                                                onChange={(e) => this.onInstantOnToggle(e)}
                                                                checked={instantOn === "true" ? true : false}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                onColor={'#64c832'}
                                                                offColor={'#c4c1c0'}
                                                                offHandleColor={'#ffffff'}
                                                                onHandleColor={'#ffffff'}/> &nbsp; <FormattedMessage id="KC0611"/> &nbsp;
                                                            </div>
                                                            <InputMask mask={instanton_time_mask} maskChar="" placeholder={formatMessage({ id: 'KC2085' })} value={instantOnValue} class="form-control" onChange={(e) => this.onInstantOnValueChange(e)} />
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    <ul class="borderNone padding0px">
                                                        <li className="verticalAlignT">&nbsp;&nbsp;<FormattedMessage id="KC1357"/></li>
                                                        <li class="smallFormFields">&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li class="width10px">&nbsp;</li>
                                                        <li className="leftAlign">
                                                            <div class="customRadioBtn">
                                                                <input id="full" type="radio" name="BasketList" value="full" checked={basket_lift === "full" ? true : false} onChange={(e) => this.onBasketLiftChange(e)} />
                                                                <label for="full" value="full"><FormattedMessage id="KC1999"/></label>
                                                            </div>
                                                            <div class="customRadioBtn">
                                                                <input id="half" type="radio" name="BasketList" value="half" checked={basket_lift === "half" ? true : false} onChange={(e) => this.onBasketLiftChange(e)} />
                                                                <label for="half" value="half"><FormattedMessage id="KC1998"/></label>
                                                            </div>
                                                            <div class="customRadioBtn">
                                                                <input id="choice" type="radio" name="BasketList" value="choice" checked={basket_lift === "choice" ? true : false} onChange={(e) => this.onBasketLiftChange(e)} />
                                                                <label for="choice" value="choice"><FormattedMessage id="KC1997"/></label>
                                                            </div>
                                                            <div class="customRadioBtn">
                                                                <input id="none" type="radio" name="BasketList" value="none" checked={basket_lift === "none" ? true : false} onChange={(e) => this.onBasketLiftChange(e)} />
                                                                <label for="none" value="none"><FormattedMessage id="KC1375"/></label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li class="width245px">&nbsp;&nbsp;<FormattedMessage id="KC1358"/></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle" data-toggle="buttons"> 1&nbsp;
                                                            <Switch
                                                                onChange={(e) => this.onPushStartChange(e)}
                                                                checked={push_start === "true" ? true : false}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                onColor={'#64c832'}
                                                                offColor={'#c4c1c0'}
                                                                offHandleColor={'#ffffff'}
                                                                onHandleColor={'#ffffff'}/>&nbsp;2
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    <ul class="borderNone padding0px">
                                                        <li className="verticalAlignT">&nbsp;&nbsp;<FormattedMessage id="KC1359"/></li>
                                                        <li className="smallFormFields">&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li class="width10px">&nbsp;</li>
                                                        <li className="leftAlign">
                                                            <div class="customRadioBtn">
                                                                <input id="Cooking" type="radio" name="ExitMode" value="Cooking" checked={exit_mode === "Cooking" ? true : false} onChange={(e) => this.onExitModeChange(e)} />
                                                                <label for="Cooking" value="Cooking"><FormattedMessage id="KC0453"/></label>
                                                            </div>
                                                            <div class="customRadioBtn">
                                                                <input id="Off" type="radio" name="ExitMode" value="Off" checked={exit_mode === "Off" ? true : false} onChange={(e) => this.onExitModeChange(e)} />
                                                                <label for="Off" value="Off"><FormattedMessage id="KC1360"/></label>
                                                            </div>
                                                            <div class="customRadioBtn">
                                                                <input id="Filter" type="radio" name="ExitMode" value="Filter" checked={exit_mode === "Filter" ? true : false} onChange={(e) => this.onExitModeChange(e)} />
                                                                <label for="Filter" value="Filter"><FormattedMessage id="KC0996"/></label>
                                                            </div>
                                                            <div class="customRadioBtn">
                                                                <input id="EcoMode" type="radio" name="ExitMode" value="Eco Mode" checked={exit_mode === "Eco Mode" ? true : false} onChange={(e) => this.onExitModeChange(e)} />
                                                                <label for="EcoMode" value="Eco Mode"><FormattedMessage id="KC2000"/></label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    <ul class="borderNone">
                                                        <li>&nbsp;&nbsp;<FormattedMessage id="KC1361"/></li>
                                                        <li class="arrowBtn">
                                                            <div class="btn-group-toggle floatLeft" data-toggle="buttons"> <FormattedMessage id="KC0580"/>&nbsp;
                                                            <Switch onClick={(e) => this.onScheduleChange(e)}
                                                                    on={schedule === "true" ? true : false} className={schedule === "true" ? "btn btn-secondary active" : "btn btn-secondary"} />
                                                                &nbsp;<FormattedMessage id="KC0611"/>
                                                            </div>
                                                            <button type="button" onClick={() => { this.onSaveRecipe('Schedule') }} className={schedule === "true" ? "btn nextButton" : "btn displaynone"}>&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    {/* <span class="required"> *required Data</span> */}
                                    <button type="button" onClick={() => this.onSaveRecipe('Test')} class={loginInfo.rolename === "Public Admin" ? "displaynone" : "btn testButton rgtTestBtn"} title={formatMessage({ id: 'KC1972' })}><FormattedMessage id="KC0698"/></button>
                                    <button type="button" class="btn saveButton rightAlign" onClick={() => { this.onSaveRecipe('Save') }}>&nbsp;</button>
                                    <button type="button" class="btn reviewButton" onClick={() => { this.onSaveRecipe('Review') }}><FormattedMessage id="KC1362"/></button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                            <div className={loginInfo.rolename === "Public Admin" ? "displaynone" : "testOnUnitTable"}>
                                <ul>
                                    <li><img src={require("../../../../../images/recipecreation/assetD.svg").default} alt="" /></li>
                                    <li>
                                        <input type="text" className="form-control" value={this.state.nodeCaptionDetails ? this.state.selectedUnitDetails.UNITID + "_" + this.state.selectedUnitDetails.BRAND_NAME + "     " + this.state.nodeCaptionDetails : ""} placeholder={formatMessage({ id: 'KC1276' })}></input>
                                    </li>
                                    <li>
                                        <button type="button" className="btn btn-default-text deleteButton" onClick={() => { this.selectTestDeleteButtonClick() }}>&nbsp;</button>
                                    </li>
                                    <li>
                                        <button type="button" className={this.state.nodeCaptionDetails ? "btn btn-default-text" : "btn btn-default-text disabled"} onClick={(e) => { this.selectTestUnitButtonClick(e) }}><FormattedMessage id="KC1231"/></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Import Modal --> */}
                <LibraryListPopup libraryType={'Recipe'} OnImportLibrary={(item) => this.OnImport(item)} modelID={selectedModel} />
                {/* <!-- //Import Modal --> */}
                {/* <!-- Import Image Modal --> */}
                <MediaListPopup mediaType={'image'} OnImportImage={(item) => this.OnMediaImport(item)} />
                {/* <!-- //Import Image Modal --> */}
                {/* <!-- Import a Picture Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmationYesNo">
                                    <h5><FormattedMessage id="KC1232"/></h5>
                                    <div class="uploadOption">
                                        <div class="customRadioBtn">
                                            <input id="selectFromLibrary" type="radio" name="uploadOption" value="Library" checked={importOption === "Library" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromLibrary" value="Library"><FormattedMessage id="KC0658"/></label>
                                        </div>
                                        <div class="customRadioBtn">
                                            <input id="selectFromDevice" type="radio" name="uploadOption" value="Device" checked={importOption === "Device" ? true : false} onClick={this.onImportTypeSelect} />
                                            <label for="selectFromDevice" value="Device"><FormattedMessage id="KC1233"/></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary noBtn" id="selectFromLiberary" type="button" onClick={this.cancelImportClick}><FormattedMessage id="KC0021"/></button>
                                <input type="file" accept="image/png, image/jpeg, image/jpg" ref={input => this.inputElement = input} onChange={this.onImageChange} className="fileInputField" id="menuUpdate" />
                                <button class="btn btn-secondary yesBtn" id="ImportButton" type="button" onClick={this.handleInputClick}><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- // Import a Picture --> */}
                {/* <!-- imageResizeModal --> */}
                <div class="modal fade imageResizeModal" data-keyboard="true" data-backdrop="static" id="imageResizeModal" tabindex="-1" role="dialog" aria-labelledby="infoModalLabel">
                    <div class="modal-dialog modal-lg imageResizeModal">
                        <div class="modal-content">
                            <div class="modal-header modal-header-danger">
                                <FormattedMessage id="KC1234"/> <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCropPopup}>&nbsp;</button>
                            </div>
                            <div class="modal-body">
                                <form class="imageResizeForm">
                                    <Cropper src={this.state.src} OnCrop={(croppedImageUrl) => this.cropSubmit(croppedImageUrl)} fileName={imageData ? imageData.imagefileName : ""} fileFormat={imageData ? imageData.imagefileext : ""} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //imageResizeModal --> */}
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterRecipeCreation)
