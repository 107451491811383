import http from '../../service/httpService';
import { BaseService, ReportManagement } from '../../service/api';
export const OVERALL_PAN_UTILIZATION_REQUEST = 'OVERALL_PAN_UTILIZATION_REQUEST';
export const OVERALL_PAN_UTILIZATION_SUCCESS = 'OVERALL_PAN_UTILIZATION_SUCCESS';
export const OVERALL_PAN_UTILIZATION_FAIL = 'OVERALL_PAN_UTILIZATION_FAIL';

export function getOverallPanUtilization(type, fromdate, todate, deviceId, brandId) {
    let url;
    let filterType;
    switch (type) {
        case 'Week':
            filterType = 'weekly';
            break;
        case 'Day':
            filterType = 'daily';
            break;
        case 'Date Range':
            filterType = 'custom';
            break;
        default:
            filterType = 'monthly';
    }

    if (brandId === 11) {
        url = `${BaseService.root}${ReportManagement.uhcOverAllPanUtilization}`;
    }
    else {
        url = `${BaseService.root}${ReportManagement.overAllPanUtilization}`;
    }
    
    return dispatch => {
        dispatch({
            type: OVERALL_PAN_UTILIZATION_REQUEST
        })

        const header = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'unitid': deviceId,
            'reqdate': fromdate,
            'filtertype': filterType,
            'showtrend': "true",
            'brandid': brandId
        }

        if (filterType === 'weekly') {
            delete header.reqDate;
            header.startDate = todate;
            header.endDate = fromdate;
            header.showtrend = "false";
        } else if (filterType === 'custom') {
            delete header.reqDate;
            header.startDate = todate;
            header.endDate = fromdate;
            header.showtrend = "false";
        }

        http.get(url,
            {
                headers: header,
                data: {}
            })
            .then(response => {
                dispatch({
                    type: OVERALL_PAN_UTILIZATION_SUCCESS,
                    overAllPanUtilizationResponse: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: OVERALL_PAN_UTILIZATION_FAIL,
                    error
                })
            })
    }
}