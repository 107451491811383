import $ from 'jquery';
import React, { useEffect } from 'react';
import moment from '../../utils/momentHelper.js';
import { FormattedMessage } from 'react-intl';
import { pdfjs, Document, Page } from 'react-pdf'
import { useState } from 'react';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// Load from CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
 
};

const TandCView = ({ tacList, isView = false, acceptBtnClick, cancelBtnClick }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState();
    const slectedItem = tacList ? tacList.find((i) => i.selected === true) : {};  
    const pdfURL = slectedItem?.documentPath

    /* 
    To set scroll height dynamically 
    calculation : vh - (remaining element in view)
    */
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    function getHeight(fullHeight,profilePage) {        
        if (fullHeight) { // without footer
            if(profilePage){
                return vh - 260 + 70 - 55;
            } else {
                return vh - 260 + 70; // 100 + 80 + 50 + 30
            }            
        } else {
            return vh - 350 + 70; // for footer
        }

    }
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        console.log(nextNumPages);
        setNumPages(nextNumPages);
        setPageNumber(1); // Navigation
    }
    // Navigation
    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
    function previousPage() {
        changePage(-1);
    }
    function nextPage() {
        changePage(1);
    }

    // let  viewerUrl = pdfPath;
    useEffect(() => {
        $("#TCacceptBtn").addClass("disabledAcceptBtn");
        $('#scrollBbuttonE').scroll(function () {
            if ($(this).scrollTop() + $(this).innerHeight() >= ($(this)[0].scrollHeight)-10) {                
                if(slectedItem?.accepted){
                    $("#TCacceptBtn").addClass("disabledAcceptBtn");
                } else {
                    $("#TCacceptBtn").removeClass("disabledAcceptBtn");           
                }                
            }
        });
    }, [slectedItem]);

    return (
        <div className="termsPricingConditionsWrapper" style={{ height: isView ? `${vh - 100 - 47}px` : `${vh - 100}px` }}>
            {/* <div className="welbiltLogoC">
                <img src={slectedItem?.logoUrl} alt="Welbilt - Kitchen Connect" title="Welbilt - Kitchen Connect" />
                {isView && !slectedItem?.isReadOnly ? <button className={`btn ${slectedItem?.accepted ? 'acceptedBtn disabled' : 'unacceptedBtn disabled'}`} type="button">{slectedItem?.accepted ? <FormattedMessage id="KC2911" /> : <FormattedMessage id="KC2912" />}</button> : null}
            </div> */}
            <div className="TCHeadingWrapper">
                <div>
                    <div className="KCTermsCh2Title">
                        {slectedItem?.title ? slectedItem.title.toUpperCase() : ""} 
                        {/* <span>
                            <FormattedMessage id="KC2896"/>: {moment.GetDateTimeByFormat(slectedItem && slectedItem.effectiveDate, 'LL')}
                        </span> */}
                    </div>
                    <div className="viewDate">
                        <FormattedMessage id="KC2896"/>: {moment.GetDateTimeByFormat(slectedItem && slectedItem.effectiveDate, 'LL')}
                    </div>
                </div>
                {isView && !slectedItem?.isReadOnly ?
                    <div className="TCView">
                        <button className={`btn ${slectedItem?.accepted ? 'acceptedBtn disabled' : 'unacceptedBtn disabled'}`} type="button">{slectedItem?.accepted ? <FormattedMessage id="KC2911" /> : <FormattedMessage id="KC2912" />}</button>
                    </div> : null}               
            </div>
            <div className="termsPricingConditionsO">
                {/* <div className="termsPricingConditions" id="scrollBbuttonE" dangerouslySetInnerHTML={{ __html: slectedItem && slectedItem.content ? slectedItem.content : "" }}/> */}
                {/* 27vh & 37vh */}
                <div className="termsPricingConditions" style={{ height: getHeight(isView || slectedItem?.accepted || slectedItem?.isReadOnly,isView) }} id="scrollBbuttonE"> 
                    {/* <Document file={pdfURL} options={options} loading = {() => (<div>Loading...</div>)} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                size={'A4'}
                                scale={1.2}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                loading = {() => (<div></div>)} 
                            />
                        ))}
                    </Document>                             */}
                    <div dangerouslySetInnerHTML={{ __html: slectedItem && slectedItem.content ? slectedItem.content : "" }}></div>
                </div>
                {isView || slectedItem?.accepted || slectedItem?.isReadOnly ? null : <div className="KCTermsCConfirmF">
                    <div className="KCTermsCConfirm">
                        <FormattedMessage id="KC2884" /><br/> 
                        <a href="javascript:void(0)" onclick="return false;"><FormattedMessage id="KC2885"/></a>
                    </div>
                    <button onClick={() => acceptBtnClick(slectedItem)} id="TCacceptBtn" className= "btn acceptBtn disabledAcceptBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0899"/></button>
                    {/* <button onClick={cancelBtnClick} className="btn cancelBtn" data-dismiss="modal" type="button">Cancel</button> */}
                </div>}
            </div>
        </div>
    );
}
export default TandCView;
