import React from 'react';
import Routes from './routers/routes';
import MainLayout from './layout/mainLayout';
import CheckInternetConnection from './components/controls/checkInternetConnection';
import $ from 'jquery';
import ReactGA from 'react-ga';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { projectTitleCustom } from './components/controls/footerComponent';
import authConstants from './utils/authConstants';
import authHelper from './utils/authHelper';
import Snackbar from './UI_Components/Snackbar';
import { snackbarClosingSecond } from './components/navigation/organizationManagement/organizationManagement';
import { useSelector } from 'react-redux';
import SnackbarProvider from 'react-simple-snackbar'

import KCStore from './utils/kcStorage';

registerLocale('es', es);

ReactGA.initialize('UA-136516950-3');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const [snackBarObj, setSnackBarObj] = React.useState({ message: '', isActive: false, state: '', seconds: snackbarClosingSecond })
  const snackbarState = useSelector(state => state.snackbar.snackbarValue);

  React.useEffect(() => {
    $("#NoInternetConnectionModal").modal("show");
    document.title = projectTitleCustom();
    const { refreshToken, tokenExpireTime } = authConstants;
    window.onunload = function (e) {
      const newTabCount = KCStore.getItem('tabsOpen')
      if (newTabCount !== null && newTabCount > 0) {
        KCStore.setItem('tabsOpen', parseInt(newTabCount) - parseInt(1))
      }
    }
    let isExpired, emulation, rememberMe;
    let token = KCStore.getItem(refreshToken);
    let expTime = KCStore.getItem(tokenExpireTime);
    if (expTime != null) {
      let expireTime = new Date(expTime);
      let currentTime = new Date();
      isExpired = currentTime > expireTime;
      emulation = KCStore.getItem('emulation');
      rememberMe = KCStore.getItem('RememberMe');
    }

    KCStore.refresh = KCStore.refresh != null ? KCStore.refresh : 0;
    const _prevValue = KCStore.refresh;
    KCStore.setItem("refresh", parseInt(KCStore.refresh) + 1);
    const _currentValue = KCStore.refresh;
    const tabsOpen = KCStore.getItem('tabsOpen')
    if (tabsOpen == null || tabsOpen == 0) {
      KCStore.setItem('tabsOpen', 1)
      if (_prevValue != _currentValue) {
        if ((!rememberMe && isExpired) || (!token && isExpired)) {
          authHelper.deleteLocalSession();
          window.location.href = '/';
        }
      }
    } else {
      KCStore.setItem('tabsOpen', parseInt(tabsOpen) + parseInt(1))
      const newTabCount = KCStore.getItem('tabsOpen')
      if (newTabCount !== null && newTabCount > 1) {
        if ((rememberMe && token) || (token && !emulation && !isExpired)) { }
        else if ((!rememberMe && isExpired) || (!token && isExpired)) {
          authHelper.deleteLocalSession();
          window.location.href = '/';
        }
      }
    }

    /* To Disable Inspect Element */
    $(document).bind("contextmenu", function (e) {
      e.preventDefault();
    });
    $(document).keydown(function (e) {
      if (e.which === 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'P'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'S'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'H'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'E'.charCodeAt(0)) {
        return false;
      }
    });
  }, [])

  React.useEffect(() => {
    setSnackBarObj(snackbarState);
  }, [snackbarState]);

  return (
    <SnackbarProvider>
      <Snackbar snackBarObj={snackBarObj} />
      <CheckInternetConnection />
      <MainLayout />
      <Routes />
    </SnackbarProvider>
  );

}

export default App;
