import {PEAKHOUR_BARCHART_REQUEST,PEAKHOUR_BARCHART_SUCCESS,PEAKHOUR_BARCHART_FAIL} from '../../../actions/reportManagement/peakHourChartAction';
const initialState = {
    loaded: false,
    loading: false,
    time:'test',
    peakHourChartInformation: []
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case PEAKHOUR_BARCHART_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case PEAKHOUR_BARCHART_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                time:action.time,
                page:action.page,
                deviceid:action.device,
                peakHourChartInformation: action.peakHourChartInformation
            }
        case PEAKHOUR_BARCHART_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgPeakHour: 'Server Error',

            }
        default:
            return state
    }
}