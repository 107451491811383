import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import  pointerDevice from '../utils/pointerDeviceFinder';
import  backDrop from '../events/backdropEvent';

require('../css/welbilt.css');
require('../css/welbiltmediascreen.css');
function modalScrollbarSet() {
	var windowHeight = $(window).height();
	// Common Modal
	var modalHeader = $(".modal-header").height(); 
	var modalFooter = $(".modal-footer").height(); 
	var modalScrollbarHeightS = ((modalFooter) - (windowHeight)); 
	var modalScrollbarHeightH = ((modalHeader) - (modalScrollbarHeightS)); 
	$(".modal-body").css("max-height", modalScrollbarHeightH); 
	//Sign In & Sign Up Modal
	var modalScrollbarHeightL = ((windowHeight) - 185);  
	$("#SignInModal .modal-body, #SignUpModal .modal-body").css("max-height", modalScrollbarHeightL); 
}
	
$(window).on('load',function () { 
	modalScrollbarSet();
	loginScrollbarSet();
}); 
$(window).on('resize',function() { 
	modalScrollbarSet();
	loginScrollbarSet();
}); 

function loginScrollbarSet() {
	var windowHeight = $(window).height(); 
	var welcomTextH = $(".welcomText").height();
	var bgAdminH = $(".bgAdmin").height() - 25; 
	
	var loginButtonH = $(".loginButton > ul:nth-child(2)").height();
	var cookieWrapperH = $(".cookieWrapper ").height() + 10;
	var maintenanceTextH = $(".maintenanceText ").height() + 35;
	var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH) + (loginButtonH) + (cookieWrapperH) + (maintenanceTextH)); 
	var loginScrollbarHeightS = ((windowHeight) -(welcomTextHbgAdminH)) - 60;   
	$(".loginButton > ul:nth-child(1) > li").css("height", loginScrollbarHeightS);  
}


class MainLayout extends Component {

constructor(props) {
		super(props);			
		this.state = {
			popupVisible: false			
		}
    }
	
	componentDidMount() {
		window.scrollTo(0,0);
		var sideNavBar = document.querySelector('.sideNavBar');
		sideNavBar && sideNavBar.addEventListener('click', this.showSideNavebar);
		sideNavBar && sideNavBar.addEventListener('touchend', this.showSideNavebar);
		if(document.getElementById("backdropElement")){
			backDrop.hideBackdrop();
		}
	}

	/**
	 * Active side nav bar in small devices
	 */
	showSideNavebar = (e) =>{
		$(document).find('#wrapper').first().addClass('navActive');
		if(!document.getElementById("backdropElement")){
		var backElem = backDrop.showBackDrop();	
		backElem.style.left = '230px';
		backElem.style.zIndex = 1031;
		backElem.addEventListener('click', this.hideSideNavBar);
		backElem.addEventListener('touchend', this.hideSideNavBar);
		}
		e.preventDefault();
	}

	/**
	 * Hide side navigation bar
	 */
	hideSideNavBar = (e) =>{
		$(document).find('#wrapper').first().removeClass('navActive');	
		backDrop.hideBackdrop();
		e.preventDefault();
	}
	
	render() {
	const { flagstate } = this.props;
	
	if (!flagstate)
	{
		$("body").removeClass("modal-open");
	}
	else
	{
		$("body").addClass("modal-open");
	}

		$(document).ready(function() {	
			function dynamicWhiteBg() {
				var windowHgt = $('html').height();	
				var headerHgt = $('#navbarContent').height();
				var whiteBgElHgt = windowHgt - headerHgt;	
				$('.conatinerWhiteBg').height(whiteBgElHgt);
			}
			dynamicWhiteBg();
			$(window).resize(function() { 
				dynamicWhiteBg();
			});
			$('#selectAll').click(function() {
				if($(this).is(':checked')) {										
					$(this).parent().parent().parent().parent().find('.tBody input').prop('checked', true);
				}
				else {     
					$(this).parent().parent().parent().parent().find('.tBody input').prop('checked', false);
				}
			});
		});
		return (
			<React.Fragment>
			
			{/* <div>commented by Surya & Muthupandi this id wrapper for navigation logic since wrapper function is applying here</div>
				<div id="wrapper">*/}
				<div className="sideNavToggleTransparent"></div>
				<div className={flagstate ? "toggleTransparent showFade" : "toggleTransparent" }></div>
						<div className="loadingProgress">
						<div className="loadingProgressIn"></div>
						</div> 
				{/*</div> */}
				{			  
				               
				              }
			</React.Fragment>)
		}
	};

export default MainLayout;

function resizeReCaptcha() {
	var width = $('.g-recaptcha').parent().width();
	var scale = width / 304;
	$('.g-recaptcha').css('transform', 'scale(' + scale + ')');
	$('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
	$('.g-recaptcha').css('transform-origin', '0 0');
	$('.g-recaptcha').css('-webkit-transform-origin', '0 0');
	$('.g-recaptcha').css('display', 'block');
};
$(document).ready(function () {
	$(window).on('resize', function () {
		resizeReCaptcha();
	});	
	resizeReCaptcha();
	pointerDevice.removeHoverClass();
});
