import http from '../../../service/httpService';
import api from '../../../service/api';
export const DELETE_ELEMENT_REQUEST = 'DELETE_ELEMENT_REQUEST';
export const DELETE_ELEMENT_SUCCESS = 'DELETE_ELEMENT_SUCCESS';
export const DELETE_ELEMENT_FAIL = 'DELETE_ELEMENT_FAIL';
export const DELETE_ELEMENT_RESET = 'DELETE_ELEMENT_RESET';

export function deleteOrganizationElement(customerGrpId, userEmail, custId) {
    return async (dispatch) => {
        dispatch({
            type: DELETE_ELEMENT_REQUEST
        })
        return new Promise((res, rej) => {
            http.delete(api.delete_Organization_Element + customerGrpId,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        useremail: userEmail,
                        customerid: custId
                    },
                    data: {}
                })
                .then(response => {
                    console.log('delete_Info', response.data);
                    dispatch({
                        type: DELETE_ELEMENT_SUCCESS,
                        deleteElementInformation: response.data,
                    })
                    res(response.data)
                })
                .catch(error => {
                    dispatch({
                        type: DELETE_ELEMENT_FAIL,
                        error
                    })
                    res(error)
                })
        })
    }
}
