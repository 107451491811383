import {CYCLE_RATES_ON_TIME_REQUEST,CYCLE_RATES_ON_TIME_SUCCESS,CYCLE_RATES_ON_TIME_FAIL} from '../../../actions/unitDashboard/cycleRatesOnTimeActions';
const initialState = {
    loaded: false,
    loading: false,
    cycleRatesOnTimeResponse: { }
}

export default function reducer (state = initialState, action = {}) {
    switch (action.type) {
        case CYCLE_RATES_ON_TIME_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true
                
            }
        case CYCLE_RATES_ON_TIME_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                cycleRatesOnTimeResponse: action.cycleRatesOnTimeResponse
            }
        case CYCLE_RATES_ON_TIME_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsgOnTime: 'Server Error',

            }
        default:
            return state
    }
}