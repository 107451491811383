import {FILTER_COMPLIANCE_DATA_REQUEST,FILTER_COMPLIANCE_DATA_SUCCESS,FILTER_COMPLIANCE_DATA_FAIL} from '../../../actions/unitDashboard/filterComplianceAction';
const initialState = {
    loaded: false,
    loading: false,
    filterComplianceData:{}
}

export default function reducer (state = initialState, action = {}) {

    switch (action.type) {
        case FILTER_COMPLIANCE_DATA_REQUEST:
            return {
                ...state,
                loading: false,
                loaded: true,
            }
        case FILTER_COMPLIANCE_DATA_SUCCESS:
            return {
                ...state,
                loading: true,
                loaded: false,
                filterComplianceData: action.filterComplianceData
            }
        case FILTER_COMPLIANCE_DATA_FAIL:
            return {
                ...state,
                loading: true,
                loaded: true,
                errormsg: 'Server Error',
            }
        default:
            return state
    }
}
