import React, { Component } from 'react';
import Slider from 'react-rangeslider'
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import ErrorMoal from '../../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import KCStore from '../../../../../../utils/kcStorage';
class FrymasterScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            screen_brightness: 10,
            screen_saver_brightness: 0,
            screen_saver_timeout: 0,
            error_modal: {
                open: false,
                message: ""
            }
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onScreenSaverTimeoutChange = this.onScreenSaverTimeoutChange.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                screen_brightness: ObjSettingsFile.general.screen.brightness ? ObjSettingsFile.general.screen.brightness : 10,
                screen_saver_brightness: ObjSettingsFile.general.screen.saver.brightness ? ObjSettingsFile.general.screen.saver.brightness : 0,
                screen_saver_timeout: ObjSettingsFile.general.screen.saver.timeout
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true,
        });
        let { screen_brightness, screen_saver_brightness, screen_saver_timeout } = this.state;
        if (screen_saver_timeout > 7200 || screen_saver_timeout < 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1422"
                }
            });
        }
        else {
            let jsonSettingsFile = KCStore.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            if (!ObjSettingsFile.general.screen) {
                ObjSettingsFile.general.screen = {};
            }
            ObjSettingsFile.general.screen.brightness = Number(screen_brightness);

            if (!ObjSettingsFile.general.screen.saver) {
                ObjSettingsFile.general.screen.saver = {}
            }
            ObjSettingsFile.general.screen.saver.brightness = Number(screen_saver_brightness);
            ObjSettingsFile.general.screen.saver.timeout = Number(screen_saver_timeout);
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            KCStore.setItem("libraryData", strSettingsFile);
            this.props.history.push(`/frymasterGeneralSettings`);
        }
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSettingsClick = (pathname) => {
        this.props.history.push({
            pathname,
            isEdit: false,
            type: "Settings",
            previouspath: "/fryMasterSettings"
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterGeneralSettings`);
    }

    handleOnSliderChange = (value, stateKey) => {
        this.setState({
            [stateKey]: value
        })
    }

    onScreenSaverTimeoutChange = (e) => {
        if (e.target.value.match(/^[0-9]*$/)) {
            this.setState({
                screen_saver_timeout: e.target.value
            });
        }
        else {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1423"
                }
            });
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = KCStore.getItem("selectedbrandID");
        const horizontalLabels = {
            10: formatMessage({ id: 'KC2079' }),
            100: formatMessage({ id: 'KC2080' }),
        }
        const { screen_brightness, screen_saver_timeout, screen_saver_brightness, error_modal } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Screen Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        {/* FryMaster Screen Page Content */}
                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>
                            {/* Frymaster Screen Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1424"/>
                                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li className="width245px"><FormattedMessage id="KC1425"/></li>
                                                        <li>
                                                            <div className='slider custom-labels'>
                                                                <div className='slider-horizontal rangeslider-height-level'>
                                                                    {/* <div className='double-label-slider'> */}
                                                                    <Slider
                                                                        min={10}
                                                                        max={100}
                                                                        value={screen_brightness}
                                                                        labels={horizontalLabels}
                                                                        orientation='horizontal'
                                                                        handleLabel={screen_brightness}
                                                                        onChange={(e) => { this.handleOnSliderChange(e, 'screen_brightness') }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li className="width245px"><FormattedMessage id="KC1426"/></li>
                                                        <li class="smallFormFields">
                                                            <input type="text" class="form-control" maxLength="4" placeholder={formatMessage({ id: 'KC2040' })} value={screen_saver_timeout} onChange={(e) => this.onScreenSaverTimeoutChange(e)} />
                                                        </li>
                                                    </ul>
                                                    <ul className="borderNone">
                                                        <li className="width245px"><FormattedMessage id="KC1427"/></li>
                                                        <li>
                                                            <div className='slider custom-labels'>
                                                                <div className='slider-horizontal rangeslider-height-level'>
                                                                    <Slider
                                                                        min={10}
                                                                        max={100}
                                                                        value={screen_saver_brightness}
                                                                        labels={horizontalLabels}
                                                                        orientation='horizontal'
                                                                        handleLabel={screen_saver_brightness}
                                                                        onChange={(e) => { this.handleOnSliderChange(e, 'screen_saver_brightness') }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>

                                </div>
                            </div>
                            {/* Frymaster Screen Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterScreen)
